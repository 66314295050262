import React from 'react';

import { format } from 'date-fns'
import { ru } from 'date-fns/locale'

import styles from '../right-block.module.scss';

type CellTitles = {
    [title: string]: string;
};

interface DatesProps {
    cellTitles: CellTitles;
    entity: any;
}

export const Dates: React.FC<DatesProps> = ({ cellTitles, entity }) => {

    return (
        <div className={styles.datesContainer}>
            {entity && Object.entries(cellTitles).map(([title, value]) =>
                <span className={styles.dates}>{title}{format(new Date(entity[value]), 'dd MMM yyyy г. в HH:mm', { locale: ru })}</span>)}
        </div>
    )
}
