import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { v4 } from "uuid";
import { toJS } from "mobx";
import { api, SignalRService } from "shared";

import { store } from "store";
import { Section } from "store/store";
import { synchroiser } from "synchroiser";
import authStore from "AuthStore";

import { LeftNavigation, RightNavigation } from "./components";

import { FavoriteSection, ISectionSelectItem } from "./data/navigation-data";
import { LoadingState } from "entities/ListStore";
import userEntity from "entities/user/User";

import styles from "./navigation-panel.module.scss";

export const Navigation = observer(function () {
	const [favorites, setFavorites] = useState<FavoriteSection[]>([]);
	const [allSections, setAllSections] = useState<ISectionSelectItem[]>([]);

	const [signalR, setSignalR] = useState<SignalRService | null>(null);
	const loadingState = useRef(LoadingState.NotAsked);

	useEffect(() => {
		const loadSectionsAndFavorites = async () => {
			await getSectionsWithStore();
			await getFavoritesWithStore();
		};
		loadSectionsAndFavorites();

		userEntity.load(authStore.userId!);
	}, [store.sections]);

	useEffect(() => {
		setPropertyIsFavoriteInSections(allSections, favorites);
	}, [favorites]);

	useEffect(() => {
		setSignalR(initSignalR());
		return () => {
			signalR?.stopConnection();
		};
	}, []);

	/**
	 * Инициализирует сервис SignalR и подписывается на событие "SectionCreated". При отработке события SectionCreated вызывается подгрузка разделов, что позволяет видеть новые разделы в выпадающем списке без перезагрузки страницы
	 * @returns SignalRService|null Объект сервиса SignalR или null, если инициализация не удалась.
	 */
	function initSignalR(): SignalRService | null {
		const signalRService = new SignalRService("SubscribeToSectionEvents", v4());
		signalRService.startConnection();
		signalRService.addEventListener("SectionCreated", async (eventEntity: Section) => {
			const sectionInPanelExist = allSections.some((section) => section.id === eventEntity.id);
			if (!sectionInPanelExist) {
				await synchroiser.getSectionsList();
				//TODO разобраться с дублированием. Мы можем обойтись без лишних запросов(synchroiser.getSectionsList()) но в таком случае запись дублируется в выпадающем списке разделов
				// const updatedSections = toJS(store.sections)
				// updatedSections.push(eventEntity)
				// store.sections = updatedSections
			}
		});
		return signalRService;
	}

	const setPropertyIsFavoriteInSections = (allSections: ISectionSelectItem[], favorites: FavoriteSection[]) => {
		if (allSections.length !== 0) {
			const newSections = allSections.map((section) => {
				if (!section.tag) {
					return section;
				}
				const favorite = favorites.find((f) => f.sectionId === section.id);
				return { ...section, IsFavorite: favorite !== undefined };
			});
			setAllSections(newSections);
		}
	};

	const handleClickToFavorites = async (item: ISectionSelectItem) => {
		let result;
		if (loadingState.current !== LoadingState.Loading) {
			loadingState.current = LoadingState.Loading;
			if (item.IsFavorite) {
				result = await api.http.favoriteSection.favoritesByUserId(authStore.userId!, item.id as string).delete();
			} else {
				result = await api.http.favoriteSection.favoriteSections().post({
					UserId: authStore.userId,
					SectionId: item.id
				});
			}
			const data = result.data;
			if (data.success === true) {
				await loadFavorites();
			}

			loadingState.current = LoadingState.Loaded;
		}
	};

	const loadFavorites = async () => {
		const result = await api.http.favoriteSection.favoriteSections(authStore.userId!).get();
		const data = result.data;

		if (data.success === true) {
			setFavorites(data.data);
		}
	};

	const getFavoritesWithStore = async () => {
		const favoriteSection = store.sections.filter((x) => x.isFavorite === true);
		if (favoriteSection.length !== 0) {
			const mapperFavorites = favoriteSection.map((item) => {
				return {
					userId: authStore.userId!,
					sectionId: item.id,
					section: item
				};
			});
			setFavorites(mapperFavorites);
		}
	};

	const getSectionsWithStore = async () => {
		var newSections = store.sections.map((item: any) => {
			return { ...item, tag: true };
		});
		setAllSections(newSections);
	};

	return (
		<div className={styles.navigation}>
			<LeftNavigation
				favorites={toJS(favorites).sort((a, b) => {
					if (a.section.displayValue < b.section.displayValue) {
						return -1;
					}
					if (a.section.displayValue > b.section.displayValue) {
						return 1;
					}
					return 0;
				})}
				sections={allSections}
				handleClickToFavorites={handleClickToFavorites}
			/>
			<RightNavigation userEmail={userEntity.entity.email} userName={userEntity.entity.name} />
		</div>
	);
});
