import { Api } from "shared/api/class-api";

class StaticGroups extends Api {
	basicPath: string;
	constructor() {
		super();
		this.basicPath = "/api/StaticGroup";
	}
	staticGroupCreate = () => {
		this.setPath(`${this.basicPath}/create`);
		return this;
	};
	staticGroupUpdate = () => {
		this.setPath(`${this.basicPath}/update`);
		return this;
	};
	staticGroupDelete = (groupId: string) => {
		this.setPath(`${this.basicPath}/delete?id=${groupId}`);
		return this;
	};
	excludeRecords = () => {
		this.setPath(`${this.basicPath}/exclude-records`);
		return this;
	};
	getFilterTree = (userId: string, entityName: string) => {
		this.setPath(`${this.basicPath}/getfiltertree?userId=${userId}&entityName=${entityName}`);
		return this;
	};
	createFolder = () => {
		this.setPath(`${this.basicPath}/createfolder`);
		return this;
	};

	updateFolder = () => {
		this.setPath(`${this.basicPath}/updatefolder`);
		return this;
	};

	deleteFolder = (folderId: string) => {
		this.setPath(`${this.basicPath}/deletefolder?id=${folderId}`);
		return this;
	};
}

const staticGroup = new StaticGroups();

export { staticGroup };
