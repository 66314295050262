import { useCallback } from "react";
import { observer } from "mobx-react-lite";

import { Button, ButtonStyle } from "components";

import { Ellipse } from "shared";

import styles from "./detail-master-header.module.css";

interface IDetailDesignerHeader {
	onSaveClick: () => void;
	onCancelClick: () => void;
	title: string;
}

export const DetailMasterHeader = observer((props: IDetailDesignerHeader) => {
	return (
		<div className={styles.detailDesignerHead}>
			<div className={styles.leftHeader}>
				<span className={styles.headCaption}>Дизайнер детали</span>
				<Ellipse />
				<span className={styles.greyHeadCaption}>{props.title}</span>
			</div>

			<div className={styles.rightHeader}>
				<div className={styles.buttonsBlock}>
					<Button caption={"Сохранить"} onClick={props.onSaveClick} style={ButtonStyle.Success} />
					<Button caption={"Отменить"} onClick={props.onCancelClick} style={ButtonStyle.Subtle} />
				</div>
			</div>
		</div>
	);
});
