import { isNumber } from "lodash";

import { dispatcher, store } from "store";

import SavedFilter from "entities/filter/SavedFilter";
import { FilterStore } from "entities/filter/FilterStore";
import { Filter, FilterTree, SavedFilterFolder, StaticGroupFolder } from "types/entity";
import { MacrosType } from "entities/filter/IFilter";
import DateMacroses from "features/filter/simpleFilter/data/DateMacroses";
import authStore from "AuthStore";
export class FilterController {

    getFilter = (): Filter | null => {

        const findEntity = store.entities.find(entity => entity.id === store.currentEntityId)?.entity;
        if (findEntity) {
            return findEntity.filter;
        }
        return null;
    };

    getFilterTree = (): FilterTree | null => {

        const findEntity = store.entities.find(entity => entity.id === store.currentEntityId)?.entity;
        if (findEntity) {
            return findEntity.filterTree;
        }
        return null;
    };

    getMacros = (macros: MacrosType): string | null => {
        let result: string | null = null;
        DateMacroses?.forEach(dataMacros => {
            const multilevelItem = dataMacros.multilevelItems?.find((item: any) => {
                return (isNumber(item.id) && item.id === macros)
            })
            if (multilevelItem !== undefined) {
                result = dataMacros.name + ": " + multilevelItem.name;
            }
        });

        return result;
    }

    getNewSavedFilter = (): SavedFilter | null => {
        const entity = dispatcher.entity.get();
        if (!entity) {
            return null;
        }
        const newFilter = new SavedFilter(entity.entityName);
        newFilter.filterInfo = new FilterStore(entity.entityName, null, null);
        newFilter.oldFilter = newFilter.filterInfo.serialize();
        return newFilter;
    };

    getNewFilterTree = (entityName?: string): FilterTree | null => {
        const entity = dispatcher.entity.get();
        if (!entity && !entityName) {
            return null;
        }

        const savedFilterFolder: SavedFilterFolder = {
            id: null,
            parentFolderId: null,
            name: null,
            filters: [],
            childFilterFolders: [],
            isRoot: false,
            entityName: entityName!,
            userId: authStore.userId,
        }

        const staticGroupFolder: StaticGroupFolder = {
            id: null,
            parentFolderId: null,
            name: null,
            groups: [],
            childGroupFolders: [],
            isRoot: false,
            entityName: entityName!,
        }


        const filterTree = {
            savedFilterTree: savedFilterFolder,
            staticGroupFolderTree: staticGroupFolder
        }

        return filterTree;
    };

}

export const filterController = new FilterController();
