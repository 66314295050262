import { DragDetailBlock, DragFieldBlock, DragFieldBlockProps, DragFieldGroupBlock } from "./drag-blocks";

import { GridItem } from "types/entity";
import { ColumnType } from "entities/ColumnType";


export const multiTypeDragBlocks = {
	[ColumnType.Numerator]: ((props: DragFieldBlockProps) => {
		return <DragFieldBlock
			element={props.element}
			icon={props.icon}
			width={props.width}
			onEditField={props.onEditField}
			onDeleteField={props.onDeleteField}
		/>;
	}),
	[ColumnType.String]: ((props: DragFieldBlockProps) => {
		return <DragFieldBlock
			element={props.element}
			icon={props.icon}
			width={props.width}
			onEditField={props.onEditField}
			onDeleteField={props.onDeleteField}
		/>;
	}),
	[ColumnType.Boolean]: ((props: DragFieldBlockProps) => {
		return <DragFieldBlock
			element={props.element}
			icon={props.icon}
			width={props.width}
			onEditField={props.onEditField}
			onDeleteField={props.onDeleteField}
		/>;
	}),
	[ColumnType.Integer]: ((props: DragFieldBlockProps) => {
		return <DragFieldBlock
			element={props.element}
			icon={props.icon}
			width={props.width}
			onEditField={props.onEditField}
			onDeleteField={props.onDeleteField}
		/>;
	}),
	[ColumnType.Decimal]: ((props: DragFieldBlockProps) => {
		return <DragFieldBlock
			element={props.element}
			icon={props.icon}
			width={props.width}
			onEditField={props.onEditField}
			onDeleteField={props.onDeleteField}
		/>;
	}),
	[ColumnType.DateTime]: ((props: DragFieldBlockProps) => {
		return <DragFieldBlock
			element={props.element}
			icon={props.icon}
			width={props.width}
			onEditField={props.onEditField}
			onDeleteField={props.onDeleteField}
		/>;
	}),
	[ColumnType.Date]: ((props: DragFieldBlockProps) => {
		return <DragFieldBlock
			element={props.element}
			icon={props.icon}
			width={props.width}
			onEditField={props.onEditField}
			onDeleteField={props.onDeleteField}
		/>;
	}),
	[ColumnType.Time]: ((props: DragFieldBlockProps) => {
		return <DragFieldBlock
			element={props.element}
			icon={props.icon}
			width={props.width}
			onEditField={props.onEditField}
			onDeleteField={props.onDeleteField}
		/>;
	}),
	[ColumnType.Lookup]: ((props: DragFieldBlockProps) => {
		return <DragFieldBlock
			element={props.element}
			icon={props.icon}
			width={props.width}
			onEditField={props.onEditField}
			onDeleteField={props.onDeleteField}
		/>;
	}),
	[ColumnType.Double]: ((props: DragFieldBlockProps) => {
		return <DragFieldBlock
			element={props.element}
			icon={props.icon}
			width={props.width}
			onEditField={props.onEditField}
			onDeleteField={props.onDeleteField}
		/>;
	}),
	[ColumnType.Guid]: ((props: DragFieldBlockProps) => {
		return <DragFieldBlock
			element={props.element}
			icon={props.icon}
			width={props.width}
			onEditField={props.onEditField}
			onDeleteField={props.onDeleteField}
		/>;
	}),
	[ColumnType.FieldGroup]: ((props: {
		element: GridItem,
		targetZone?: string,
		rowQuality?: number,
		onEditField: (columnId: string) => void,
		onDeleteField: (gridItemId: string) => void
	}) => {
		return <DragFieldGroupBlock
			id={props.element?.gridItemId}
			elements={props.element.groupFieldsConfig?.inner?.items ?? []}
			groupName={props.element.groupFieldsConfig?.title ?? "EMPTY NAME"}
			groupField={props.element}
			targetZone={props.targetZone}
			rowQuality={props.rowQuality}
			onEditField={props.onEditField}
			onDeleteField={props.onDeleteField}
		/>;
	}),
	[ColumnType.Detail]: ((props: { element: GridItem }) => {
		return <DragDetailBlock
			element={props.element}
		/>;
	}),
};