import styles from "./store-button.module.css";

function StoreButton(props: { title: string, className?: string, onClick: () => void }) {
    let classNames = `${styles.body} `;
    if (props.className)
        classNames += `${props.className} `;

    return (
        <div className={classNames} onClick={props.onClick}>
            <span>{props.title}</span>
        </div>
    );
}

export default StoreButton;