import { useCallback, useEffect, useState } from "react";
import classNames from "classnames";

import { dispatcher } from "store";
import { ModalData } from "../modal-viewer";

import styles from "./../modal-viewer.module.scss";

const TIME_FOR_HIDDEN = 3000;
const TIME_FOR_DELETE = 5000;

export function NotificationCenter(props: ModalData) {
	const [isOpen, setIsOpen] = useState<boolean>(true);
	const [timer, setTimer] = useState<NodeJS.Timeout | null>();

	const styleName = classNames(`${styles.notification} ${styles.center}`, {
		[`${styles.closeNotification} `]: !isOpen,
	});

	const handleClick = useCallback(() => {
		if (props.allowDefaultClick) {
			if (timer) {
				clearTimeout(timer);
			}
			setIsOpen(false);
			dispatcher.modal.remove(props.id);
		}
	}, [timer, props.allowDefaultClick]);

	const handleMouseEnter = useCallback(() => {
		if (props.allowTimer) {
			if (timer) {
				clearTimeout(timer);
			}
			setIsOpen(true);
		}
	}, [timer, props.allowTimer]);

	const handleMouseLeave = useCallback(() => {
		if (props.allowTimer) {
			setTimer(setTimeout(() => {
				setIsOpen(false);
				setTimeout(() => {
					dispatcher.modal.remove(props.id);
				}, TIME_FOR_DELETE);
			}, TIME_FOR_HIDDEN));
			if (timer) {
				clearTimeout(timer);
			}
		}
	}, [timer, props.allowTimer]);

	useEffect(() => {
		if (props.allowTimer) {
			if (isOpen) {
				setTimer(setTimeout(() => {
					setIsOpen(false);
					setTimeout(() => {
						dispatcher.modal.remove(props.id);
					}, TIME_FOR_DELETE);
				}, TIME_FOR_HIDDEN));
			}
			if (timer) {
				clearTimeout(timer);
			}
		}
	}, [isOpen, props.allowTimer]);

	const styleNameBackdrop = classNames({
		[`${styles.backdropWhite}`]: props.withBackdrop,
	});

	return (<>
		<div className={styleNameBackdrop} />
		<div
			className={styleName}
			onClick={handleClick}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			{props.layout}
		</div>
	</>

	);
};