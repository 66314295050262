import { observer } from 'mobx-react-lite'
import { Checkbox } from 'sale-bridge-ui-kit'
import fieldConfigurationStore, {
	FieldStoreEnums,
} from '../field-configuration-store'
import { useMemo } from 'react';
import { ColumnType } from 'entities/ColumnType';
import { NumeratorConfigurationEnums } from '../numerator-configuration';
export const RenderRequiredCheckbox = observer(() => {


  const handleChangeChecked = (value: boolean) => {
			fieldConfigurationStore.setValue(FieldStoreEnums.isRequired, value);
			if(fieldConfigurationStore.fieldType === ColumnType.Numerator && value){
				fieldConfigurationStore.setNumeratorConfig(NumeratorConfigurationEnums.FillWhenPageOpened, value);
			}
	};

  const checkboxIsDisabled = useMemo(() => {
    return fieldConfigurationStore.getIsDisabledForRequiredCheckBox()
},[fieldConfigurationStore.isViewColumn])

	return (
		<>
			<Checkbox
				size='small'
				value={fieldConfigurationStore.isRequired}
				onChangeChecked={handleChangeChecked}
				variant='default'
				disabled={checkboxIsDisabled}
				description='При сохранении записи поле не может быть пустым'
				title='Обязательное поле'
			/>
		</>
	)
})
