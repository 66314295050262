import styles from "../../Select.module.css";
import stylesMini from "../SelectWithSearchMini.module.css";

export enum SelectWitSerarchStyles{
    Primary,
    Opacity
}

export interface ISelectStyles{
    input:IInputStyles;
}

interface IInputStyles{
    classNames:string;
    focus:string;
    invalid:string;
    success:string;
}

const Styles = {
    [SelectWitSerarchStyles.Primary]: {
        input: {
            classNames: `${stylesMini.inputPrimary} `,
            focus: `${stylesMini.focusPrimary} `,
            invalid: `${stylesMini.invalidPrimary} `,
            success: `${stylesMini.successPrimary} `
        }
    },
    [SelectWitSerarchStyles.Opacity]:{
        input: {
            classNames: `${stylesMini.inputOpacity} `,
            focus: "",
            invalid: ``,
            success: ""
        }
    }
}

export default Styles;