import { observer } from "mobx-react";
import { useCallback, useMemo, useState } from "react";
import classNames from "classnames";
import { toJS } from "mobx";

import { Button } from "sale-bridge-ui-kit";

import { BusinessRule } from "types/entity";

import { CloseMaxi, DragIcon } from "shared";

import styles from "./business-rule-change-priority-popup.module.scss";

type Props = {
	businessRules: BusinessRule[];
	save: (newRules: BusinessRule[]) => void;
	close: () => void;
};

type DragState = {
	draggedId: string;
	draggedOrder: number;
	overOrder: number;
	overZone: string | null;
	placeholderOrder: number;
};

export const BusinessRuleChangePriorityPopup = observer((props: Props) => {
	const [draggbleRules] = useState<BusinessRule[]>(props.businessRules);
	const [dragState, setDragState] = useState<DragState>({
		draggedId: "",
		draggedOrder: -1,
		overOrder: -1,
		overZone: null,
		placeholderOrder: -1
	});
	const { draggedOrder, overOrder, overZone, placeholderOrder, draggedId } = dragState;

	const handleCancel = useCallback(() => {
		props.close();
	}, [props.close]);

	const handleSave = useCallback(() => {
		props.save(draggbleRules);
	}, [toJS(draggbleRules)]);

	/**
	 * @description срабатывает при поднятии перемещаемого элемента
	 **/
	const handleDragStart = useCallback(
		(e: React.DragEvent<HTMLDivElement>) => {
			const order = parseInt(e.currentTarget.dataset.dragIndex ?? "", 10);
			if (isNaN(order)) {
				return;
			}
			setDragState({ ...dragState, draggedId: e.currentTarget.id, draggedOrder: order });
		},
		[dragState, toJS(draggbleRules)]
	);

	/**
	 * @description если заполнитель находится непосредственно перед (==draggedIndex) или непосредственно после (===draggedindex + 1),
	 *  то нет необходимости его показывать, поскольку мы ничего не перемещаем
	 **/
	function setNewPlaceholderOrder(newPlaceholderOrder: number, draggedOrder: number) {
		if (newPlaceholderOrder === draggedOrder || newPlaceholderOrder === draggedOrder + 1) {
			newPlaceholderOrder = -1;
		}
		return newPlaceholderOrder;
	}

	/**
	 * @description обновить только если placeholderOrder изменился
	 **/
	function updatePlaceholderOrder(newState: DragState, newPlaceholderOrder: number, nonFunctionalConditionOnlyForDisplay: boolean) {
		if (placeholderOrder !== newPlaceholderOrder || nonFunctionalConditionOnlyForDisplay) {
			newState.placeholderOrder = newPlaceholderOrder;
			setDragState({ ...newState });
		}
	}

	/**
	 * @description срабатывает при перетаскивании перемещаемого элемента
	 **/
	const handleDragOver = useCallback(
		(e: React.DragEvent<HTMLDivElement>) => {
			const rect = e.currentTarget.getBoundingClientRect();

			const y = e.clientY - rect.top;

			const newOverOrder = parseInt(e.currentTarget.dataset.dragIndex ?? "", 10);
			if (isNaN(newOverOrder)) {
				return;
			}
			const newOverZone = y <= rect.height / 2 ? "top" : "bottom";
			const newState = { ...dragState, overOrder: newOverOrder, overZone: newOverZone };
			let newPlaceholderOrder = newOverZone === "top" ? newOverOrder : newOverOrder + 1;
			newPlaceholderOrder = setNewPlaceholderOrder(newPlaceholderOrder, draggedOrder);

			const nonFunctionalConditionOnlyForDisplay = overOrder !== newOverOrder || overZone !== newOverZone;
			updatePlaceholderOrder(newState, newPlaceholderOrder, nonFunctionalConditionOnlyForDisplay);
		},
		[dragState, toJS(draggbleRules), draggedOrder, overOrder, overZone]
	);

	/**
	 * @description перемещение элемента вниз
	 **/
	function bottomDrag(draggbleRule: BusinessRule) {
		draggbleRules.splice(draggedOrder - 1, 1);
		draggbleRules.splice(placeholderOrder - 2, 0, draggbleRule);
		draggbleRules
			?.filter((item: any) => item.priority < placeholderOrder && item.priority > draggedOrder)
			.forEach((item: any) => item.priority--);
		draggbleRule.priority = placeholderOrder - 1;
	}
	/**
	 * @description перемещение элемента вверх
	 **/
	function topDrag(draggbleRule: BusinessRule) {
		draggbleRules.splice(draggedOrder - 1, 1);
		draggbleRules.splice(placeholderOrder - 1, 0, draggbleRule);
		draggbleRules
			?.filter((item: any) => item.priority >= placeholderOrder && item.priority < draggedOrder)
			.forEach((item: any) => item.priority++);
		draggbleRule.priority = placeholderOrder;
	}

	/**
	 * @description срабатывает при опускании перемещаемого элемента
	 **/
	const handleDragEnd = useCallback(
		(e: React.DragEvent<HTMLDivElement>) => {
			if (placeholderOrder !== -1) {
				const draggbleAvatar = draggbleRules.find((item: BusinessRule) => item.id === draggedId);
				if (!draggbleAvatar) {
					return;
				}
				if (placeholderOrder > draggedOrder) {
					bottomDrag(draggbleAvatar);
				} else {
					topDrag(draggbleAvatar);
				}
			}
			const updater = { draggedOrder: -1, placeholderOrder: -1, overOrder: -1, overZone: null, draggedId: "" };
			setDragState({ ...updater });
		},
		[dragState, draggedOrder, toJS(draggbleRules), placeholderOrder, draggedId]
	);

	const rulesMapping = useMemo(() => {
		if (draggbleRules) {
			const parametersArray = draggbleRules.map((rule: BusinessRule) => {
				const ruleClasses = classNames(styles.ruleWrapper, {
					[`${styles.rulerapperDragging}`]: rule.priority === draggedOrder
				});

				return (
					<div
						className={ruleClasses}
						id={rule.id}
						data-drag-index={rule.priority}
						draggable
						onDragStart={handleDragStart}
						onDragOver={handleDragOver}
						onDragEnd={handleDragEnd}
					>
						<DragIcon />
						<span>{`[${rule.priority}] ${rule.ruleName}`}</span>
					</div>
				);
			});
			if (placeholderOrder !== -1) {
				parametersArray?.splice(placeholderOrder - 1, 0, <div className={styles.dragSeparator} />);
			}
			return parametersArray;
		}
		return <></>;
	}, [toJS(draggbleRules), placeholderOrder]);

	return (
		<div className={styles.dialog}>
			<div className={styles.header}>
				<span className={styles.title}>Порядок срабатывания</span>
				<CloseMaxi className={styles.closeButton} onClick={handleCancel} />
			</div>
			<div className={styles.dialogBody}>
				<div className={styles.bodyInfo}>
					<span className={styles.bodyInfoBlack}>
						Если несколько бизнес-правил срабатывают по одному и тому же условию, рекомендуется задать порядок их выполнения. В
						других случаях это необязательно.
					</span>
					<span className={styles.bodyInfoGrey}>
						Например, создано три бизнес-правила в следующем порядке: первое — «A», второе — «B» и третье — «C». Для первых двух
						бизнес-правил условия срабатывания одинаковы. В этом случае сначала сработает «A», а затем — «B».
					</span>
					<span className={styles.bodyInfoGrey}>
						Результат последнего выполненного бизнес-правила останется на странице редактирования записи.
					</span>
					<span className={styles.bodyInfoBlack}>Порядок указан в [...] и изменяется при перетаскивании названия правила.</span>
				</div>
				<div className={styles.rulesBlock}>{rulesMapping}</div>
			</div>
			<div className={styles.dialogFooter}>
				<Button text="Отменить" size="small" variant="default" border link={false} loading={false} onClick={handleCancel} />
				<Button text="Сохранить" size="small" variant="primary" border link={false} loading={false} onClick={handleSave} />
			</div>
		</div>
	);
});
