import { observer } from "mobx-react-lite";
import { MouseEvent, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import FileStores from "./fileStore/FileStores";

import NavigationTitle from "./NavigationTitle";
import SystemUsersMainPanel from "./systemUsers/SystemUsersMainPanel";
import OrgStructureMainPanel from "./orgStructure/OrgStructureMainPanel";
import LookupMainPanel from "./lookups/LookupMainPanel";
import CurrentMainPanel from "./currents/CurrentMainPanel";
import { LicenseManagerMainPanelInProgress } from "./licenses/LicenseManagerMainPanel";
import InvoicesMainPanel from "./invoices/InvoicesMainPanel";
import ProductMainPanel from "./products/ProductMainPanel";
import FunctionalRolesMainPanel from "./functionalRoles/FunctionalRolesMainPanel";
import AccessRightsMainPanel from "./accessRights/AccessRightsMainPanel";
import DeduplicationRules from "./deduplication/DeduplicationRules";
import { InputSearch, SettingsSelect, ButtonStyle, Button } from "components";
import { SideMenu, Button as ButtonUiKit } from "sale-bridge-ui-kit";

import {
	MenuItems,
	DataSettingEnums,
	UserEnums,
	SettingsPath,
	getPath,
	SystemEnums,
	AutomationEnums,
	AccountEnums,
	Menu,
	UsersSettings,
	SystemSettings,
	AutomationSettings,
	AccountSettings,
	DataSettings
} from "./data/Fields";
import { SectionWizzardMode } from "pages/section-wizzard/section-wizzard";
import { Item } from "types";

import { DefaultLogoUsers, SectionWizzard } from "shared";
import { DefaultSystemLogo } from "shared";
import { DefaultAutoLogo } from "shared";
import { DefaultAccountLogo } from "shared";
import { TwoUsersIcon } from "shared";
import { GroupUsersIcon } from "shared";
import { RolesUsersIcon } from "shared";
import { DataIconBlue } from "shared";

import styles from "./settings.module.scss";

const Settings = observer(function () {
	const [selected, setSelected] = useState<string>("");
	const [tab, setTab] = useState<JSX.Element>(<DefaultMainPanel />);
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		navigateTo(location.pathname);
	}, [location.pathname]);

	function setNavigate(path: string, tab: JSX.Element) {
		navigate(path);
		setTab(tab);
	}

	//TODO прикрутить менеджер лицензий
	function onChangeValue(id: string) {
		if (id === DataSettingEnums.Import) navigate("/" + DataSettingEnums.Import);
		else {
			switch (id) {
				case UserEnums.SystemUsers: {
					setSelected(UserEnums.SystemUsers);
					setNavigate(SettingsPath + "/" + MenuItems.UserSettings + "/" + UserEnums.SystemUsers, <SystemUsersMainPanel />);
					break;
				}
				case UserEnums.OrgStructure: {
					setSelected(UserEnums.OrgStructure);
					setNavigate(SettingsPath + "/" + MenuItems.UserSettings + "/" + UserEnums.OrgStructure, <OrgStructureMainPanel />);
					break;
				}
				case UserEnums.FunctionalRoles: {
					setSelected(UserEnums.FunctionalRoles);
					setNavigate(
						SettingsPath + "/" + MenuItems.UserSettings + "/" + UserEnums.FunctionalRoles,
						<FunctionalRolesMainPanel />
					);
					break;
				}
				case UserEnums.AccessRights: {
					setSelected(UserEnums.FunctionalRoles);
					setNavigate(SettingsPath + "/" + MenuItems.UserSettings + "/" + UserEnums.AccessRights, <AccessRightsMainPanel />);
					break;
				}
				case SystemEnums.Lookups: {
					setSelected(SystemEnums.Lookups);
					setNavigate(SettingsPath + "/" + MenuItems.SystemSettings + "/" + SystemEnums.Lookups, <LookupMainPanel />);
					break;
				}
				case AutomationEnums.Current: {
					setSelected(AutomationEnums.Current);
					setNavigate(SettingsPath + "/" + MenuItems.AutomationSettings + "/" + AutomationEnums.Current, <CurrentMainPanel />);
					break;
				}
				case AccountEnums.Invoices: {
					setSelected(AccountEnums.Invoices);
					setNavigate(SettingsPath + "/" + MenuItems.AccountSettings + "/" + AccountEnums.Invoices, <InvoicesMainPanel />);
					break;
				}
				case AccountEnums.Products: {
					setSelected(AccountEnums.Products);
					setNavigate(SettingsPath + "/" + MenuItems.AccountSettings + "/" + AccountEnums.Products, <ProductMainPanel />);
					break;
				}
				case AccountEnums.Manager: {
					setSelected(AccountEnums.Manager);
					setNavigate(
						SettingsPath + "/" + MenuItems.AccountSettings + "/" + AccountEnums.Manager,
						<LicenseManagerMainPanelInProgress />
					);
					break;
				}
				case DataSettingEnums.Store: {
					setNavigate(SettingsPath + "/" + MenuItems.DataSettings + "/" + DataSettingEnums.Store, <FileStores />);
					break;
				}
				case DataSettingEnums.Deduplication: {
					setSelected(DataSettingEnums.Deduplication);
					setNavigate(SettingsPath + "/" + MenuItems.DataSettings + "/" + DataSettingEnums.Deduplication, <DeduplicationRules />);
					break;
				}
				default: {
					break;
				}
			}
		}

		setSelected(id.toString());
	}
	//TODO прикрутить менеджер лицензий
	function navigateTo(value: string) {
		switch (value) {
			case SettingsPath + "/" + MenuItems.UserSettings + "/" + UserEnums.SystemUsers: {
				setSelected(UserEnums.SystemUsers);
				setTab(<SystemUsersMainPanel />);
				break;
			}
			case SettingsPath + "/" + MenuItems.UserSettings + "/" + UserEnums.OrgStructure: {
				setSelected(UserEnums.OrgStructure);
				setTab(<OrgStructureMainPanel />);
				break;
			}
			case SettingsPath + "/" + MenuItems.UserSettings + "/" + UserEnums.FunctionalRoles: {
				setSelected(UserEnums.FunctionalRoles);
				setTab(<FunctionalRolesMainPanel />);
				break;
			}
			case SettingsPath + "/" + MenuItems.UserSettings + "/" + UserEnums.AccessRights: {
				setSelected(UserEnums.AccessRights);
				setTab(<AccessRightsMainPanel />);
				break;
			}
			case SettingsPath + "/" + MenuItems.SystemSettings + "/" + SystemEnums.Lookups: {
				setSelected(SystemEnums.Lookups);
				setTab(<LookupMainPanel />);
				break;
			}
			case SettingsPath + "/" + MenuItems.AutomationSettings + "/" + AutomationEnums.Current: {
				setSelected(AutomationEnums.Current);
				setTab(<CurrentMainPanel />);
				break;
			}
			case SettingsPath + "/" + MenuItems.AccountSettings + "/" + AccountEnums.Manager: {
				setSelected(AccountEnums.Manager);
				//  setTab( <LicenseManagerMainPanel />)
				setTab(<LicenseManagerMainPanelInProgress />);
				// setTab(<></>)
				break;
			}
			case SettingsPath + "/" + MenuItems.AccountSettings + "/" + AccountEnums.Invoices: {
				setSelected(AccountEnums.Invoices);
				setTab(<InvoicesMainPanel />);
				break;
			}
			case SettingsPath + "/" + MenuItems.AccountSettings + "/" + AccountEnums.Products: {
				setSelected(AccountEnums.Products);
				setTab(<ProductMainPanel />);
				break;
			}

			case SettingsPath + "/" + MenuItems.DataSettings + "/" + DataSettingEnums.Store: {
				setSelected(DataSettingEnums.Store);
				setTab(<FileStores />);
				break;
			}

			case SettingsPath + "/" + MenuItems.DataSettings + "/" + DataSettingEnums.Deduplication: {
				setSelected(DataSettingEnums.Deduplication);
				setTab(<DeduplicationRules />);
				break;
			}

			case SettingsPath + "/" + MenuItems.UserSettings: {
				setTab(<DefaultUserPanel />);
				break;
			}
			case SettingsPath + "/" + MenuItems.SystemSettings: {
				setTab(<DefaultSystemPanel />);
				break;
			}
			case SettingsPath + "/" + MenuItems.AutomationSettings: {
				setTab(<DefaultAutoPanel />);
				break;
			}
			case SettingsPath + "/" + MenuItems.AccountSettings: {
				setTab(<DefaultAccountPanel />);
				break;
			}
			case SettingsPath + "/" + MenuItems.DataSettings: {
				setTab(<DefaultDataPanel />);
				break;
			}
			case SettingsPath: {
				setTab(<DefaultMainPanel />);
				break;
			}

			default: {
				break;
			}
		}
	}

	return (
		<div className={styles.settings}>
			<SectionLeftSettings selected={selected} onChangeValue={onChangeValue} />
			{tab}
		</div>
	);
});

const SectionLeftSettings = observer(function (props: { selected?: string; onChangeValue: (itemId: string) => void }) {
	let [searchValue, setSearchValue] = useState("");

	function search(s: string) {
		setSearchValue(s);
	}
	return (
		<div className={styles.sectionLeft}>
			{/* TODO дизайнеры пока убрали поиск */}
			{/* <InputSearch
				placeholder="Поиск настроек..."
				value={searchValue}
				onChangeValue={search}
				classNameInput={styles.search}
			/> */}
			<div className={styles.settingsSelectPanel}>
				<SideMenu /* Настройка пользователей*/
					size="small"
					title={Menu[0].name}
					items={UsersSettings}
					selectedId={props.selected ?? null}
					onChange={props.onChangeValue}
				/>

				<SideMenu /* Настройки системы*/
					size="small"
					title={Menu[1].name}
					items={SystemSettings}
					selectedId={props.selected ?? null}
					onChange={props.onChangeValue}
				/>
				{/* TODO дизайнеры пока убрали этот пункт меню */}
				{/* <SideMenu //Автоматизация
					size="small"
					title={Menu[2].name}
					items={AutomationSettings}
					selectedId={props.selected ?? null}
					onChange={props.onChangeValue}
				/> */}

				<SideMenu /* Управление аккаунтом*/
					size="small"
					title={Menu[3].name}
					items={AccountSettings}
					selectedId={props.selected ?? null}
					onChange={props.onChangeValue}
				/>

				<SideMenu /* Данные в системе*/
					size="small"
					title={Menu[4].name}
					items={DataSettings}
					selectedId={props.selected ?? null}
					onChange={props.onChangeValue}
				/>
				<div className={styles.settingsSelectPanelFooter}>
					<div className={styles.settingsSelectPanelDivider} />
					<ButtonUiKit
						text="Дизайнер разделов"
						size="small"
						variant="secondary"
						border={false}
						link={false}
						loading={false}
						onClick={() => {
							window.open(`/sectionMaster/${SectionWizzardMode.NewSection}/new`, "_blank");
						}}
						leftIcon="Tools"
						isFillParent
						iconSize="16"
					/>
					<span className={styles.settingsSelectPanelSectionWizzardTitle}>
						Создайте собственный раздел: <br />
						стадийная и объектная модели, <br />
						условия перехода, отображение полей
					</span>
				</div>
			</div>
		</div>
	);
});
export const DefaultMainPanel = observer(function () {
	const navigate = useNavigate();
	return (
		<div className={styles.defaultMainPanel}>
			<NavigationTitle />
			<div className={styles.stringFrame}>
				<Button /* Настройка пользователей*/
					className={styles.defaultFrame}
					firstIcon={<DefaultLogoUsers />}
					caption={Menu[0].name}
					classNameCaption={styles.defaultTitle}
					style={ButtonStyle.DefaultSettings}
					classNameContent={styles.gapDefaultButton}
					onClick={() => {
						navigate(getPath(MenuItems.UserSettings));
					}}
				/>
				<Button /* Настройки системы*/
					className={styles.defaultFrame}
					firstIcon={<DefaultSystemLogo />}
					caption={Menu[1].name}
					classNameCaption={styles.defaultTitle}
					style={ButtonStyle.DefaultSettings}
					classNameContent={styles.gapDefaultButton}
					onClick={() => {
						navigate(getPath(MenuItems.SystemSettings));
					}}
				/>
			</div>
			<div className={styles.stringFrame}>
				<Button /* Автоматизация*/
					className={styles.defaultFrame}
					firstIcon={<DefaultAutoLogo />}
					caption={Menu[2].name}
					classNameCaption={styles.defaultTitle}
					style={ButtonStyle.DefaultSettings}
					classNameContent={styles.gapDefaultButton}
					onClick={() => {
						navigate(getPath(MenuItems.AutomationSettings));
					}}
				/>

				<Button /* Управление аккаунтом*/
					className={styles.defaultFrame}
					firstIcon={<DefaultAccountLogo />}
					caption={Menu[3].name}
					classNameCaption={styles.defaultTitle}
					style={ButtonStyle.DefaultSettings}
					classNameContent={styles.gapDefaultButton}
					onClick={() => {
						navigate(getPath(MenuItems.AccountSettings));
					}}
				/>
			</div>
			<div className={styles.stringFrame}>
				<Button /* Данные*/
					className={styles.defaultFrame}
					firstIcon={<DataIconBlue width={50} height={50} />}
					caption={Menu[4].name}
					classNameCaption={styles.defaultTitle}
					style={ButtonStyle.DefaultSettings}
					classNameContent={styles.gapDefaultButton}
					onClick={() => {
						navigate(getPath(MenuItems.DataSettings));
					}}
				/>
			</div>
		</div>
	);
});
export const DefaultUserPanel = observer(function () {
	const navigate = useNavigate();
	return (
		<div className={styles.defaultMainPanel}>
			<NavigationTitle />
			<div className={styles.stringFrame}>
				<Button /* Пользователи системы */
					className={styles.defaultFrame}
					firstIcon={<TwoUsersIcon />}
					caption={UsersSettings[0].name}
					classNameCaption={styles.defaultTitle}
					style={ButtonStyle.DefaultSettings}
					classNameContent={styles.gapDefaultButton}
					onClick={() => {
						navigate(SettingsPath + "/" + MenuItems.UserSettings + "/" + UserEnums.SystemUsers);
					}}
				/>
				<Button /* Структура организации */
					className={styles.defaultFrame}
					firstIcon={<GroupUsersIcon />}
					caption={UsersSettings[1].name}
					classNameCaption={styles.defaultTitle}
					style={ButtonStyle.DefaultSettings}
					classNameContent={styles.gapDefaultButton}
					onClick={() => {
						navigate(SettingsPath + "/" + MenuItems.UserSettings + "/" + UserEnums.OrgStructure);
					}}
				/>
				<Button /* Функциональные роли */
					className={styles.defaultFrame}
					firstIcon={<RolesUsersIcon />}
					caption={UsersSettings[2].name}
					classNameCaption={styles.defaultTitle}
					style={ButtonStyle.DefaultSettings}
					classNameContent={styles.gapDefaultButton}
					onClick={() => {
						navigate(SettingsPath + "/" + MenuItems.UserSettings + "/" + UserEnums.FunctionalRoles);
					}}
				/>
				<Button /* Права доступа */
					className={styles.defaultFrame}
					// firstIcon={<RolesUsersIcon />}
					caption={UsersSettings[3].name}
					classNameCaption={styles.defaultTitle}
					style={ButtonStyle.DefaultSettings}
					classNameContent={styles.gapDefaultButton}
					onClick={() => {
						navigate(SettingsPath + "/" + MenuItems.UserSettings + "/" + UserEnums.AccessRights);
					}}
				/>
			</div>
		</div>
	);
});
export const DefaultSystemPanel = observer(function () {
	const navigate = useNavigate();
	const handleClick = useCallback(() => {
		navigate(SettingsPath + "/" + MenuItems.SystemSettings + "/" + SystemEnums.Lookups);
	}, [SettingsPath, MenuItems.SystemSettings, SystemEnums.Lookups]);
	return (
		<div className={styles.defaultMainPanel}>
			<NavigationTitle />
			<div className={styles.stringFrame}>
				<Button /* Справочники */
					className={styles.defaultFrame}
					// firstIcon={<TwoUsersIcon />}
					caption={SystemSettings[0].name}
					classNameCaption={styles.defaultTitle}
					style={ButtonStyle.DefaultSettings}
					classNameContent={styles.gapDefaultButton}
					onClick={handleClick}
				/>
			</div>
		</div>
	);
});

export const DefaultDataPanel = observer(function () {
	const navigate = useNavigate();
	const handleClickDeStore = useCallback(() => {
		navigate(SettingsPath + "/" + MenuItems.DataSettings + "/" + DataSettingEnums.Store);
	}, [SettingsPath, MenuItems.DataSettings, DataSettingEnums.Store]);
	const handleClickDedublication = useCallback(() => {
		navigate(SettingsPath + "/" + MenuItems.DataSettings + "/" + DataSettingEnums.Deduplication);
	}, [SettingsPath, MenuItems.DataSettings, DataSettingEnums.Deduplication]);
	return (
		<div className={styles.defaultMainPanel}>
			<NavigationTitle />
			<div className={styles.stringFrame}>
				<Button /* Импорт */
					className={styles.defaultFrame}
					// firstIcon={<TwoUsersIcon />}
					caption={DataSettings[0].name}
					classNameCaption={styles.defaultTitle}
					style={ButtonStyle.DefaultSettings}
					classNameContent={styles.gapDefaultButton}
					onClick={() => {
						navigate("/import");
					}}
				/>
				<Button /* Хранение данных */
					className={styles.defaultFrame}
					// firstIcon={<TwoUsersIcon />}
					caption={DataSettings[1].name}
					classNameCaption={styles.defaultTitle}
					style={ButtonStyle.DefaultSettings}
					classNameContent={styles.gapDefaultButton}
					onClick={handleClickDeStore}
				/>
				<Button /* Правила дедубликации */
					className={styles.defaultFrame}
					// firstIcon={<TwoUsersIcon />}
					caption={DataSettings[2].name}
					classNameCaption={styles.defaultTitle}
					style={ButtonStyle.DefaultSettings}
					classNameContent={styles.gapDefaultButton}
					onClick={handleClickDedublication}
				/>
			</div>
		</div>
	);
});

export const DefaultAutoPanel = observer(function () {
	const navigate = useNavigate();
	return (
		<div className={styles.defaultMainPanel}>
			<NavigationTitle />
			<div className={styles.stringFrame}>
				<Button /* Текущие */
					className={styles.defaultFrame}
					// firstIcon={<TwoUsersIcon />}
					caption={AutomationSettings[0].name}
					classNameCaption={styles.defaultTitle}
					style={ButtonStyle.DefaultSettings}
					classNameContent={styles.gapDefaultButton}
					onClick={() => {
						navigate(SettingsPath + "/" + MenuItems.AutomationSettings + "/" + AutomationEnums.Current);
					}}
				/>
			</div>
		</div>
	);
});
export const DefaultAccountPanel = observer(function () {
	const navigate = useNavigate();
	return (
		<div className={styles.defaultMainPanel}>
			<NavigationTitle />
			<div className={styles.stringFrame}>
				<Button /* Менеджер лицензий */
					className={styles.defaultFrame}
					// firstIcon={<TwoUsersIcon />}
					caption={AccountSettings[0].name}
					classNameCaption={styles.defaultTitle}
					style={ButtonStyle.DefaultSettings}
					classNameContent={styles.gapDefaultButton}
					onClick={() => {
						navigate(SettingsPath + "/" + MenuItems.AccountSettings + "/" + AccountEnums.Manager);
					}}
				/>
				<Button /* Счета */
					className={styles.defaultFrame}
					// firstIcon={<TwoUsersIcon />}
					caption={AccountSettings[1].name}
					classNameCaption={styles.defaultTitle}
					style={ButtonStyle.DefaultSettings}
					classNameContent={styles.gapDefaultButton}
					onClick={() => {
						navigate(SettingsPath + "/" + MenuItems.AccountSettings + "/" + AccountEnums.Invoices);
					}}
				/>
				<Button /* Продукты */
					className={styles.defaultFrame}
					// firstIcon={<TwoUsersIcon />}
					caption={AccountSettings[2].name}
					classNameCaption={styles.defaultTitle}
					style={ButtonStyle.DefaultSettings}
					classNameContent={styles.gapDefaultButton}
					onClick={() => {
						navigate(SettingsPath + "/" + MenuItems.AccountSettings + "/" + AccountEnums.Products);
					}}
				/>
			</div>
		</div>
	);
});

export default Settings;
