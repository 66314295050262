import { DraftDecorator } from "draft-js";
import { CompositeDecorator, ContentBlock, ContentState } from "draft-js";

import { EntityType } from "../config/config";

type LinkProps = {
    children: React.ReactNode;
    contentState: ContentState;
    entityKey: string;
};

export const Link = (props: LinkProps) => {
    const { url } = props.contentState.getEntity(props.entityKey).getData();

    const handleClick = () => {
        window.open(url, "_blank");
    };

    return (
        <a
            href={url}
            style={{
                color: `var(--color-indigo-600)`,
                textDecoration: `underline`,
                cursor: `pointer`,
            }}
            onClick={handleClick}
        >
            {props.children}
        </a>
    );
};

export function findLinkEntities(
    contentBlock: ContentBlock,
    callback: (start: number, end: number) => void,
    contentState: ContentState
): void {
    contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        return (
            entityKey ? contentState.getEntity(entityKey).getType() === EntityType.LINK : false
        );
    }, callback);
}

export const LinkDecorator: DraftDecorator = {
    strategy: findLinkEntities,
    component: Link,
};

export const createLinkDecorator = () =>
    new CompositeDecorator([
        {
            strategy: findLinkEntities,
            component: Link,
        },
    ]);

