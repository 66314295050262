import { Api } from "shared/api/class-api";
import { EntityNameType } from "types/entity";

class Entity extends Api {
	basicPath: string;
	constructor() {
		super();
		this.basicPath = "/api/Entity";
	}

	createEntity = () => {
		this.setPath(`${this.basicPath}/create`);
		return this;
	};
	mergeEntity = () => {
		this.setPath(`${this.basicPath}/merge`);
		return this;
	};
	getViewColumnByEntityName = () => {
		this.setPath(`${this.basicPath}/view-column`);
		return this;
	};
	updateEntity = () => {
		this.setPath(`${this.basicPath}/update`);
		return this;
	};
	deleteInfo = () => {
		this.setPath(`${this.basicPath}/deleteInfo`);
		return this;
	};
	editTitle = () => {
		this.setPath(`${this.basicPath}/editTitle`);
		return this;
	};
	deleteRecord = () => {
		this.setPath(`${this.basicPath}/delete`);
		return this;
	};

	dublicateRecord = () => {
		this.setPath(`${this.basicPath}/dublicate`);
		return this;
	};
	getRecord = () => {
		this.setPath(`${this.basicPath}/get`);
		return this;
	};
	getRecordWithColumns = () => {
		this.setPath(`${this.basicPath}/getWithColumns`);
		return this;
	};
	getViewColumn = () => {
		this.setPath(`${this.basicPath}/view-column`);
		return this;
	};
	recordsList = () => {
		this.setPath(`${this.basicPath}/list`);
		return this;
	};
	recordsListWithColumns = () => {
		this.setPath(`${this.basicPath}/listWithColumns`);
		return this;
	};
	entityInfo = () => {
		this.setPath(`${this.basicPath}/entityinfo`);
		return this;
	};
	entityData = () => {
		this.setPath(`${this.basicPath}/entitydata`);
		return this;
	};
	addEntityInfo = () => {
		this.setPath(`${this.basicPath}/addentityinfo`);
		return this;
	};
	editEntityInfo = () => {
		this.setPath(`${this.basicPath}/editentityinfo`);
		return this;
	};
	entityDataList = () => {
		this.setPath(`${this.basicPath}/entitydatalist`);
		return this;
	};
	entitySectionDataList = () => {
		this.setPath(`${this.basicPath}/entitysectiondatalist`);
		return this;
	};
	entityInfoLikedFrom = () => {
		this.setPath(`${this.basicPath}/entityInfoLikedFrom`);
		return this;
	};
	entitylookupdatalist = () => {
		this.setPath(`${this.basicPath}/entitylookupdatalist`);
		return this;
	};
	entitySectionAndLookupDataList = () => {
		this.setPath(`${this.basicPath}/entity-data-list-section-and-lookup`);
		return this;
	};
	getCustomerLookup = () => {
		this.setPath(`${this.basicPath}/getCustomerLookup`);
		return this;
	};
	checkExistEntityName = (tableName: string) => {
		this.setPath(`${this.basicPath}/check-exist-entityname?tableName=${tableName}`);
		return this;
	};
	checkExistEntityTitle = (titleName: string, entityNameType: EntityNameType) => {
		this.setPath(`${this.basicPath}/check-exist-entitytitle?titleName=${titleName}&entityNameType=${entityNameType}`);
		return this;
	};
	entityCount = () => {
		this.setPath(`${this.basicPath}/entitycount`);
		return this;
	};
	sqlTest = () => {
		this.setPath(`${this.basicPath}/sql-test`);
		return this;
	};
	testHandlerCreate = () => {
		this.setPath(`${this.basicPath}/test-handler-create`);
		return this;
	};
	checkDuplicate = () => {
		this.setPath(`${this.basicPath}/checkduplicate`);
		return this;
	};
	testbuilder = () => {
		this.setPath(`${this.basicPath}/testbuilder`);
		return this;
	};
}

const entity = new Entity();

export { entity };
