export enum FileStorages {
	Db = "063fe1ec-163a-46f1-82d7-56a95cfb882c",
	S3 = "0398420d-ea00-4d25-a6db-4dd1be57a18a"
}

export enum FileStoragesNames {
	Db = "База данных",
	S3 = "Облачное хранилище S3",
	OTHER = "Sharepoint (другое)"
}
