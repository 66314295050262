import { dispatcher, store } from "store";

import { getStagesColumnName } from "synchroiser/lib";

import IFilter from "entities/filter/IFilter";
import { FilterStore } from "entities/filter/FilterStore";

import { api } from "shared";

export async function getStageRecordsQuantity(entityName: string, stageId: string) {
	const gridItems = dispatcher.sectionWizzard.getAllGridItems();

	const stageFieldName = getStagesColumnName(gridItems);

	const currentEntityName = store.sections.find((section: any) => section.id === store.currentEntityId)?.entityName;

	const filter = new FilterStore(
		currentEntityName!,
		(dispatcher.entity.get()?.entity.filter?.savedFilter?.filterInfo as IFilter) ?? null,
		null
	);

	return api.http.entity.entityCount().post({
		entityName: currentEntityName,
		filter: filter.serialize().filters?.length ? filter.serialize() : null,
		kanbanColumn: {
			columnName: stageFieldName,
			columnValue: stageId
		},
		staticGroupId: dispatcher.entity.get()?.entity.filter?.staticGroup?.id ?? null
	});
}
