import { WithFilters, WithoutFilters } from "./variants";

import styles from "./variants/style.module.scss"

type Props = {
    hasFilters: boolean;
};

export function EmptyContent(props: Props) {
    return (
        <div className={styles.container}>
            {
                props.hasFilters ?
                    <WithFilters /> :
                    <WithoutFilters />
            }
        </div>
    )
}