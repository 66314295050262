import { makeAutoObservable } from "mobx";
import { v4 } from "uuid";
import { isNull } from "lodash";

import { api } from "shared";
import { dispatcher } from "store";
import { synchroiser } from "synchroiser";

import { Item } from "types";
import { BlockedColumnNames, Column, DetailConfig, EntityNameType, Grid, GridItem, ItemType, VirtualDetail } from "types/entity";
import { ERROR_DETAIL_EXIST_NAME, ERROR_DETAIL_EXIST_TITLE, ValidationState, HIDE_ELEMENT_POSITION } from "pages/section-wizzard/data/data";
import { validateRequired, validateSchema } from "entities/Validation";
import { ColumnType } from "entities/ColumnType";
import { IGridItem } from "components/select/types";

export enum DetailStoreField {
	isEditExistDetail = "isEditExistDetail",
	detailId = "detailId",
	detail = "detail",
	detailTitle = "detailTitle",
	detailName = "detailName",
	detailColumn = "detailColumn",
	entityColumn = "entityColumn",
	detailVisibleColumn = "detailVisibleColumn",
	header = "header",
	isVisibleFieldSettingsButton = "isVisibleFieldSettingsButton",
	isDidabledSaveExistObjButton = "isDidabledSaveExistObjButton",
	isDidabledSaveNewObjButton = "isDidabledSaveNewObjButton",
	isDidabledSaveDetailButton = "isDidabledSaveDetailButton",
	newObjectForDetail = "newObjectForDetail",
	newDetailTitle = "newDetailTitle",
	newObjectTitle = "newObjectTitle",
	newDetailName = "newDetailName",
	newObjectName = "newObjectName",
	linkWithLookup = "linkWithLookup",
	newLookupColumnTitle = "newLookupColumnTitle",
	newLookupColumnName = "newLookupColumnName",
	detailList = "detailList",
	columnDetailList = "columnDetailList",
	columnList = "columnList",
	lookupList = "lookupList",
	objectList = "objectList",
	inner = "inner",
	detailIsLoading = "detailIsLoading"
}

class DetailConfigurationStore {
	detailIsLoading: boolean = false;
	detailId: string | undefined = undefined;
	isEditExistDetail: boolean = false;

	detail: Item | null = null;
	detailTitle: string = "";
	detailName: string = "";
	detailColumn: Item | null = null;
	entityColumn: Item | null = null;
	detailVisibleColumn: string = "";

	header: string = "По существующему объекту";
	detailList: Item[] = [];
	columnList: Item[] = [];
	columnDetailList: Item[] = [];
	objectList: Item[] = [];
	lookupList: Item[] = [];

	isVisibleFieldSettingsButton: boolean = false;
	isDisabledSaveExistObjButton: boolean = true;
	isDisabledSaveNewObjButton: boolean = true;
	isDisabledSaveDetailButton: boolean = true;

	/*Настройки по сущ-му объекту */
	newObjectForDetail: Item | null = null;
	newDetailTitle: string = "";

	/*Настройки по новому объекту */
	newObjectTitle: string = "";
	newDetailName: string = "";
	newObjectName: string = "";
	linkWithLookup: Item | null = null;
	newLookupColumnTitle: string = "";
	newLookupColumnName: string = "";

	inner: GridItem[] | null = null;
	boxFields: GridItem[] = [];
	virtualDetail: VirtualDetail | null = null;

	validation: { [key: string]: ValidationState };
	hasChanges: boolean = false;

	x: number = HIDE_ELEMENT_POSITION;
	y: number = HIDE_ELEMENT_POSITION;

	constructor() {
		makeAutoObservable(this);
		this.validation = {
			detail: {
				isInvalid: false,
				error: ""
			},
			detailTitle: {
				isInvalid: false,
				isNotUnique: false,
				error: ""
			},

			detailName: {
				isInvalid: false,
				error: ""
			},

			detailColumn: {
				isInvalid: false,
				error: ""
			},
			entityColumn: {
				isInvalid: false,
				error: ""
			},
			newObjectForDetail: {
				isInvalid: false,
				error: ""
			},
			newDetailTitle: {
				isInvalid: false,
				error: ""
			},
			newObjectTitle: {
				isInvalid: false,
				error: ""
			},
			newDetailName: {
				isInvalid: false,
				error: ""
			},
			newObjectName: {
				isInvalid: false,
				error: ""
			},
			linkWithLookup: {
				isInvalid: false,
				error: ""
			},
			newLookupColumnTitle: {
				isInvalid: false,
				error: ""
			},
			newLookupColumnName: {
				isInvalid: false,
				error: ""
			}
		};
	}

	async getCongigurationById(id: string) {
		const items = dispatcher.sectionWizzard.getAllGridItems();
		const details = items.filter((item) => item.type === ItemType.Detail);
		if (details.length > 0) {
			const finded = details.find((detail) => detail.gridItemId === id);
			if (finded) {
				this.detailTitle = finded.detailConfig?.detailTitle ?? "";
				this.detailName = finded.detailConfig?.detailName ?? "";
				await this.loadColumns(finded.detailConfig?.entityName!);
				this.detail = {
					id: finded.detailConfig?.detailName!,
					name: finded.detailConfig?.detailName!,
					displayValue: finded.detailConfig?.detailTitle,
					entityName: finded.detailConfig?.entityName
				};
				const findColumn = this.columnDetailList.find((column) => column.name === finded.detailConfig?.detailColumn);
				if (findColumn) {
					this.detailColumn = {
						id: findColumn?.id!,
						name: findColumn?.name!,
						displayValue: findColumn?.title ?? findColumn?.displayValue
					};
				} else {
					const gritItem = finded.detailConfig?.inner?.items.find(
						(column) => column.fieldConfig?.columnName == finded.detailConfig?.detailColumn
					);
					this.detailColumn = {
						id: gritItem?.fieldConfig?.columnId!,
						name: gritItem?.fieldConfig?.columnName!,
						displayValue: gritItem?.fieldConfig?.columnTitle
					};
				}

				this.inner = finded.detailConfig?.inner?.items ?? [];
			}
			this.validFieldSettingsButton();
		}
	}

	async isDetailSection() {
		let req = await api.http.entity.entitySectionDataList().get();
		const tables: any[] = req?.data.data;
		const finded = tables.find((element) => element.id === this.detail?.id);
		if (finded) {
			return finded.isSection;
		} else {
			const findedSection = tables.find((element) => element.entityName === this.detail?.entityName);
			if (findedSection) {
				return findedSection.isSection;
			}
		}
		return false;
	}

	setValue(fieldName: string, value: string | boolean | Item | [] | null | Item[] | GridItem[] | undefined) {
		this.hasChanges = true;
		Reflect.set(this, fieldName, value);
	}

	setValueWithoutTrackingChanges(fieldName: string, value: string | boolean | null | number) {
		Reflect.set(this, fieldName, value);
	}

	setCurrentSection() {
		const finded = this.lookupList.find((section) => section.name === dispatcher.sectionWizzard.getSectionWizzard()?.systemName);
		if (finded) {
			this.setValue(DetailStoreField.linkWithLookup, finded);
		}
	}
	setNames() {
		this.setValue(DetailStoreField.newLookupColumnTitle, dispatcher.sectionWizzard.getSectionWizzard()?.entityTitle!);
		this.setValue(DetailStoreField.newLookupColumnName, dispatcher.sectionWizzard.getSectionWizzard()?.systemName!);
	}

	setDefaultColumnId() {
		const findedId = this.columnList.find((column) => column.name === "Id");
		if (findedId) {
			this.setValue(DetailStoreField.entityColumn, findedId);
		}
	}

	async loadAllObjects() {
		let req = await api.http.entity.entityDataList().get();
		const tables: any[] = req?.data.data;
		this.objectList = [];
		this.detailList = [];
		this.lookupList = [];

		const currentSection = {
			id: v4(),
			name: dispatcher.sectionWizzard.getSectionWizzard()?.systemName!,
			displayValue: dispatcher.sectionWizzard.getSectionWizzard()?.entityTitle
		};
		tables.forEach((entity) => {
			this.objectList.push({
				id: entity.id,
				name: entity.entityName,
				displayValue: entity.entityTitle
			});

			this.detailList.push({
				id: entity.id,
				name: entity.entityName,
				displayValue: entity.entityTitle
			});
			this.lookupList.push({
				id: entity.id,
				name: entity.entityName,
				displayValue: entity.entityTitle
			});
		});
		if (!this.lookupList.find((section) => section.name === currentSection.name)) {
			this.lookupList.push(currentSection);
		}
	}

	async loadSectionColumns() {
		this.columnList = [];
		const allFields = dispatcher.sectionWizzard.getAllGridItems().filter((item) => item.fieldConfig);
		allFields?.forEach((field) => {
			if (field.fieldConfig?.isLookup || field.fieldConfig?.columnType === ColumnType.Guid) {
				this.columnList.push({
					id: field.fieldConfig?.columnId!,
					name: field.fieldConfig?.columnName!,
					displayValue: field.fieldConfig?.columnTitle
				});
			}
		});
	}
	async loadColumns(entityName: string) {
		const post = { entityName: entityName };
		let req = await api.http.entity.entityInfo().post(post);
		const columns: any[] = req?.data.data.entityInfoResultItems;
		this.columnDetailList = [];

		if (columns && columns.length > 0) {
			columns.forEach((column) => {
				if (column.isLookup) {
					this.columnDetailList.push({
						id: column.columnId,
						name: column.columnName,
						displayValue: column.columnTitle
					});
				}
			});
		}
	}

	loadColumnsFromNewLookup(column: Item) {
		const columns: GridItem[] = dispatcher.sectionWizzard.getAllGridItems();
		this.columnDetailList = [];
		columns.forEach((column) => {
			if (
				column.fieldConfig?.isLookup ||
				column.fieldConfig?.columnName === "Id" ||
				column.fieldConfig?.columnName === "CreatedBy" ||
				column.fieldConfig?.columnName === "ModifiedBy"
			) {
				this.columnDetailList.push({
					id: column.fieldConfig?.columnId,
					name: column.fieldConfig?.columnName,
					displayValue: column.fieldConfig?.columnTitle
				});
			}
		});
		this.columnDetailList.push(column);
	}

	saveByExists() {
		const newDetail: Item = {
			id: this.newObjectForDetail?.id ?? v4(),
			name: this.newObjectForDetail?.name ?? "",
			displayValue: this.newDetailTitle
		};
		this.virtualDetail = null;
		this.detailList.push(newDetail);
		this.detail = newDetail;
		this.detailTitle = this.newDetailTitle;
		this.hasChanges = true;

		if (this.newObjectForDetail?.name) this.loadColumns(this.newObjectForDetail?.name);
	}

	saveNewObject(entityColumn: Item) {
		const newDetail: Item = {
			id: v4(),
			name: this.newObjectName,
			displayValue: this.newDetailTitle
		};
		const newVirtualDetail: VirtualDetail = {
			entityName: this.newObjectName,
			entityTitle: this.newObjectTitle,
			entityLinkColumn: this.linkWithLookup?.name ?? "",
			entityLinkColumnName: this.newLookupColumnName,
			entityLinkColumnTitle: this.newLookupColumnTitle
		};
		const newColumn: Item = {
			id: v4(),
			name: this.newLookupColumnName,
			displayValue: this.newLookupColumnTitle
		};
		this.columnDetailList.push(newColumn);
		this.virtualDetail = newVirtualDetail;

		this.detailList.push(newDetail);
		this.detail = newDetail;
		this.detailTitle = this.newDetailTitle;
		this.detailName = this.newObjectName;
		this.detailColumn = newColumn;
		this.entityColumn = entityColumn;
		this.hasChanges = true;
	}

	getDetailConfigForOldDetail() {
		const detailConfig: DetailConfig = {
			entityName: this.detail?.name ?? "",
			detailName: this.detailName,
			detailTitle: this.detailTitle,
			detailColumn: this.detailColumn?.name ?? "",
			entityColumn: this.entityColumn?.name ?? "",
			inner: {
				items: this.inner!
			},
			virtualDetail: this.virtualDetail ?? null
		};
		return detailConfig;
	}

	convertToGridItem(column: Column): GridItem {
		return {
			x: -1,
			y: 0,
			gridItemId: v4(),
			width: 1,
			height: 1,
			fieldConfig: column
		};
	}

	async getEntityColumns(data: any) {
		const response = await api.http.entity.entityInfo().post(data);
		return response.data.data.entityInfoResultItems;
	}

	async getGridItemForNewDetail(linkColumn?: IGridItem) {
		const newBoxFields = [
			{
				x: -1,
				y: 0,
				width: 1,
				height: 1,
				type: 0,
				gridItemId: v4(),
				fieldConfig: {
					columnId: v4(),
					columnName: "Id",
					columnTitle: "Id",
					columnType: ColumnType.Guid,
					hasIndex: false,
					isLookup: false,
					isRequired: true,
					uneditable: false,
					isViewColumn: false,
					lookupTable: null,
					specializations: {
						tag: 0,
						properties: {
							additionalProp1: "",
							additionalProp2: "",
							additionalProp3: ""
						}
					}
				}
			},
			{
				x: -1,
				y: 0,
				width: 1,
				height: 1,
				type: 0,
				gridItemId: v4(),
				fieldConfig: {
					columnId: v4(),
					columnName: "CreatedOn",
					columnTitle: "Дата создания",
					columnType: ColumnType.DateTime,
					hasIndex: false,
					isLookup: false,
					isRequired: true,
					uneditable: false,
					isViewColumn: false,
					lookupTable: null,
					specializations: {
						tag: 0,
						properties: {
							additionalProp1: "",
							additionalProp2: "",
							additionalProp3: ""
						}
					}
				}
			},
			{
				x: -1,
				y: 0,
				width: 1,
				height: 1,
				type: 0,
				gridItemId: v4(),
				fieldConfig: {
					columnId: v4(),
					columnName: "ModifiedOn",
					columnTitle: "Дата изменения",
					columnType: ColumnType.DateTime,
					hasIndex: false,
					isLookup: false,
					isRequired: true,
					uneditable: false,
					lookupTable: null,
					isViewColumn: false,
					specializations: {
						tag: 0,
						properties: {
							additionalProp1: "",
							additionalProp2: "",
							additionalProp3: ""
						}
					}
				}
			},
			{
				x: -1,
				y: 0,
				width: 1,
				height: 1,
				type: 0,
				gridItemId: v4(),
				fieldConfig: {
					columnId: v4(),
					columnName: "CreatedBy",
					columnTitle: "Создал",
					columnType: ColumnType.Lookup,
					hasIndex: false,
					isLookup: true,
					isRequired: false,
					uneditable: false,
					isViewColumn: false,
					lookupTable: "User",
					specializations: {
						tag: 0,
						properties: {
							additionalProp1: "",
							additionalProp2: "",
							additionalProp3: ""
						}
					}
				}
			},
			{
				x: -1,
				y: 0,
				width: 1,
				height: 1,
				type: 0,
				gridItemId: v4(),
				fieldConfig: {
					columnId: v4(),
					columnName: "ModifiedBy",
					columnTitle: "Изменил",
					columnType: ColumnType.Lookup,
					hasIndex: false,
					isLookup: true,
					isRequired: false,
					uneditable: false,
					isViewColumn: false,
					lookupTable: "User",
					specializations: {
						tag: 0,
						properties: {
							additionalProp1: "",
							additionalProp2: "",
							additionalProp3: ""
						}
					}
				}
			}
		];
		let grid: Grid = {
			items: []
		};
		if (this.inner && this.inner.length > 0) {
			grid.items = this.inner;
		} else if (this.virtualDetail) {
			grid.items = newBoxFields;
			if (linkColumn) {
				const sectionColumn: GridItem = {
					x: -1,
					y: 0,
					width: 1,
					height: 1,
					type: 0,
					gridItemId: linkColumn.gridItemId!,
					fieldConfig: {
						columnId: linkColumn.columnId!,
						columnName: linkColumn.name,
						columnTitle: linkColumn.displayValue,
						columnType: ColumnType.Lookup,
						hasIndex: false,
						isLookup: true,
						isRequired: false,
						uneditable: false,
						isViewColumn: false,
						lookupTable: this.linkWithLookup?.name ?? this.linkWithLookup?.entityName ?? this.virtualDetail.entityLinkColumn,
						specializations: {
							tag: 0,
							properties: {
								additionalProp1: "",
								additionalProp2: "",
								additionalProp3: ""
							}
						}
					}
				};
				grid.items.push(sectionColumn);
			} else {
				const sectionColumn: GridItem = {
					x: -1,
					y: 0,
					width: 1,
					height: 1,
					type: 0,
					gridItemId: v4(),
					fieldConfig: {
						columnId: v4(),
						columnName: this.virtualDetail.entityLinkColumnName,
						columnTitle: this.virtualDetail.entityLinkColumnTitle,
						columnType: ColumnType.Lookup,
						hasIndex: false,
						isLookup: true,
						isRequired: false,
						isViewColumn: false,
						uneditable: false,
						lookupTable: this.linkWithLookup?.name ?? this.linkWithLookup?.entityName ?? this.virtualDetail.entityLinkColumn
					}
				};
				grid.items.push(sectionColumn);
			}
			this.boxFields = grid.items;
		} else {
			const isSectionThisDetail = await this.isDetailSection();
			if (!isSectionThisDetail) {
				const getEntityColumnsData = {
					entityName: this.detail?.name
				};
				const response = await api.http.entity.entityInfo().post(getEntityColumnsData);
				const columns: Column[] = response.data.data.entityInfoResultItems;
				const filteredBoxFields = columns.filter(
					(column) => BlockedColumnNames.findIndex((name) => name === column.columnName) > -1
				);
				this.boxFields = filteredBoxFields.map((field) => this.convertToGridItem(field));
				grid.items = columns.map((column) => this.convertToGridItem(column));
			}
		}

		const detailConfig: DetailConfig = {
			entityName: this.detail?.name ?? "",
			detailName: this.detailName,
			detailTitle: this.detailTitle,
			detailColumn: this.detailColumn?.name ?? "",
			entityColumn: this.entityColumn?.name ?? "",
			inner: grid,
			virtualDetail: this.virtualDetail ?? null
		};
		const gridItem: GridItem = {
			x: this.x,
			y: this.y,
			gridItemId: v4(),
			width: 2,
			height: 1,
			type: ItemType.Detail,
			detailConfig: detailConfig
		};
		return gridItem;
	}

	async createConfigForDetail() {
		const currentTabIndex = dispatcher.sectionWizzard.getSectionWizzard()?.reactorConfig.tabs.currentTab ?? 0;
		const item = await this.getGridItemForNewDetail();
		dispatcher.sectionWizzard.createNewGridItemInTab(item, currentTabIndex);
		this.resetConfiguration();
	}

	updateConfigForDetail(detailId: string) {
		const currentTabIndex = dispatcher.sectionWizzard.getSectionWizzard()?.reactorConfig.tabs.currentTab ?? 0;
		let findedIndex = -1;
		const findedItem = dispatcher.sectionWizzard
			.getSectionWizzard()
			?.reactorConfig.tabs.tabsConfig[currentTabIndex].grid.items.find((item, index) => {
				if (item.gridItemId === detailId) {
					findedIndex = index;
					return item;
				}
			});
		if (findedItem && findedIndex != -1) {
			const detailConfig = {
				...findedItem.detailConfig!,
				detailTitle: this.detailTitle
			};
			findedItem.detailConfig = detailConfig;
		}
		this.resetConfiguration();
	}

	async validateDetailTitle() {
		const isNotUnique = await synchroiser.checkExistEntityTitle(this.detailTitle, EntityNameType.Tables);
		this.validation.detailTitle.isNotUnique = isNotUnique;
		if (isNotUnique) {
			this.validation.detailTitle.isInvalid = false;
			this.validation.detailTitle.error = ERROR_DETAIL_EXIST_TITLE;
		} else {
			validateRequired(this.detailTitle, this.validation.detailTitle);
		}
	}

	async validateDetailSystemName() {
		const isNotUnique = await synchroiser.checkExistEntityName(this.detailName);
		if (isNotUnique) {
			this.validation.detailName.isInvalid = isNotUnique;
			this.validation.detailName.error = ERROR_DETAIL_EXIST_NAME;
		} else if (validateSchema(this.detailName, this.validation.detailName)) {
			this.validation.detailName.isInvalid = false;
			this.validation.detailName.error = "";
		}
		return !this.validation.detailName.isInvalid;
	}

	validExistObjectDetail() {
		let newObjectForDetailValid = false;
		let newDetailTitleValid = false;
		if (!this.validation.newObjectForDetail.isInvalid && !isNull(this.newObjectForDetail)) {
			newObjectForDetailValid = true;
		}
		if (!this.validation.newDetailTitle.isInvalid && this.newDetailTitle.length > 0) {
			newDetailTitleValid = true;
		}

		if (newObjectForDetailValid && newDetailTitleValid) {
			this.isDisabledSaveExistObjButton = false;
		} else {
			this.isDisabledSaveExistObjButton = true;
		}
	}

	validNewObjectDetail() {
		let newObjectTitleValid = false;
		let newDetailTitleValid = false;
		let newDetailNameValid = false;
		let newObjectNameValid = false;
		let linkWithLookupValid = false;
		let newLookupColumnTitleValid = false;
		let newLookupColumnNameValid = false;

		if (!this.validation.newObjectTitle.isInvalid && !isNull(this.newObjectTitle)) {
			newObjectTitleValid = true;
		}
		if (!this.validation.newDetailTitle.isInvalid && this.newDetailTitle.length > 0) {
			newDetailTitleValid = true;
		}
		if (!this.validation.newDetailName.isInvalid && this.newDetailName.length > 0) {
			newDetailNameValid = true;
		}
		if (!this.validation.newObjectName.isInvalid && this.newObjectName.length > 0) {
			newObjectNameValid = true;
		}
		if (!this.validation.linkWithLookup.isInvalid && !isNull(this.linkWithLookup)) {
			linkWithLookupValid = true;
		}
		if (!this.validation.newLookupColumnTitle.isInvalid && !isNull(this.newLookupColumnTitle)) {
			newLookupColumnTitleValid = true;
		}
		if (!this.validation.newLookupColumnName.isInvalid && !isNull(this.newLookupColumnName)) {
			newLookupColumnNameValid = true;
		}

		if (
			newObjectTitleValid &&
			newDetailTitleValid &&
			newDetailNameValid &&
			newObjectNameValid &&
			linkWithLookupValid &&
			newLookupColumnTitleValid &&
			newLookupColumnNameValid
		) {
			this.isDisabledSaveNewObjButton = false;
		} else {
			this.isDisabledSaveNewObjButton = true;
		}
	}

	async validFieldSettingsButton() {
		let detailValid = false;
		let detailTitleValid = false;
		let detailNameValid = false;

		if (!this.validation.detail.isInvalid && !isNull(this.detail)) {
			detailValid = true;
		}
		if (!this.validation.detailTitle.isInvalid && this.detailTitle.length > 0) {
			detailTitleValid = true;
		}
		if (!this.validation.detailName.isInvalid && this.detailName.length > 0) {
			detailNameValid = true;
		}

		if (detailValid && detailTitleValid && detailNameValid) {
			if (await this.isDetailSection()) {
				this.isVisibleFieldSettingsButton = false;
			} else {
				this.isVisibleFieldSettingsButton = true;
			}
		} else {
			this.isVisibleFieldSettingsButton = false;
		}
	}

	validDetail() {
		let detailValid = false;
		let detailTitleValid = false;
		let detailNameValid = false;
		let detailColumnValid = false;
		let entityColumnValid = false;

		if (!this.validation.detail.isInvalid && !isNull(this.detail)) {
			detailValid = true;
		}
		if (!this.validation.detailTitle.isInvalid && this.detailTitle.length > 0) {
			detailTitleValid = true;
		}
		if (!this.validation.detailName.isInvalid && this.detailName.length > 0) {
			detailNameValid = true;
		}
		if (!this.validation.detailColumn.isInvalid && !isNull(this.detailColumn)) {
			detailColumnValid = true;
		}
		if (!this.validation.entityColumn.isInvalid && !isNull(this.entityColumn)) {
			entityColumnValid = true;
		}

		if (detailValid && detailTitleValid && detailNameValid && detailColumnValid && entityColumnValid) {
			this.isDisabledSaveDetailButton = false;
		} else {
			this.isDisabledSaveDetailButton = true;
		}
	}

	resetExistObjectConfiguration() {
		this.hasChanges = false;
		this.newObjectForDetail = null;
		this.newDetailTitle = "";
		this.validation.newObjectForDetail = {
			isInvalid: false,
			error: ""
		};
		this.validation.newDetailTitle = {
			isInvalid: false,
			error: ""
		};
	}
	resetNewObjectConfiguration() {
		this.hasChanges = false;
		this.newObjectTitle = "";
		this.newDetailTitle = "";
		this.newDetailName = "";
		this.newObjectName = "";
		this.linkWithLookup = null;
		this.newLookupColumnName = "";
		this.newLookupColumnTitle = "";

		this.validation.newObjectTitle = {
			isInvalid: false,
			error: ""
		};
		this.validation.newDetailTitle = {
			isInvalid: false,
			error: ""
		};
		this.validation.newDetailName = {
			isInvalid: false,
			error: ""
		};
		this.validation.newObjectName = {
			isInvalid: false,
			error: ""
		};
		this.validation.linkWithLookup = {
			isInvalid: false,
			error: ""
		};
		this.validation.newLookupName = {
			isInvalid: false,
			error: ""
		};
		this.validation.newLookupTitle = {
			isInvalid: false,
			error: ""
		};
	}
	resetConfiguration() {
		this.hasChanges = false;

		this.detailIsLoading = false;
		this.detailId = undefined;
		this.isEditExistDetail = false;

		this.detail = null;
		this.detailTitle = "";
		this.detailName = "";
		this.detailColumn = null;
		this.entityColumn = null;

		this.newObjectForDetail = null;
		this.newDetailTitle = "";

		this.newObjectTitle = "";
		this.newDetailName = "";
		this.newObjectName = "";
		this.linkWithLookup = null;
		this.newLookupColumnName = "";
		this.newLookupColumnTitle = "";

		this.inner = [];
		this.virtualDetail = null;

		this.isVisibleFieldSettingsButton = false;

		for (const key in this.validation) {
			this.validation[key].isInvalid = false;
			this.validation[key].isNotUnique = false;
			this.validation[key].error = "";
		}
	}

	async loadAll() {
		this.detailIsLoading = true;
		this.isVisibleFieldSettingsButton = false;
		await this.loadAllObjects();
		await this.loadSectionColumns();
		if (this.detailId) {
			this.setValue(DetailStoreField.isDidabledSaveDetailButton, true);
			await this.getCongigurationById(this.detailId);
			this.setValue(DetailStoreField.isEditExistDetail, true);
		}
		this.validFieldSettingsButton();
		this.setDefaultColumnId();
		this.detailIsLoading = false;
	}
}

const detailConfigurationStore = new DetailConfigurationStore();

export default detailConfigurationStore;
