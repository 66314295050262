import { observer } from "mobx-react-lite";
import { observable } from "mobx";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import axios from "axios";

import authStore from "AuthStore";
import { api } from "shared";

import { Field, Input, Button, LoadingCircle } from "components";

import userEntity from "entities/user/User";

import { LogoSalebridge } from "shared";

import styles from "./invite.module.css";

enum LoadingState {
	None,
	Loading,
	Loaded
}

interface INewPasswordForm {
	password: string;
	passwordConfirm: string;
	passValid: boolean;
	errorMessage: string | "";
	isLoadState: LoadingState;
	passwordInvalid: boolean;
	passwordConfirmInvalid: boolean;
	passwordIsNeedCorrect: boolean;
	passwordConfirmIsNeedCorrect: boolean;
	validatePassword: () => void;
	savePassword: () => void;
	keyDown: (event: any) => void;
}

const NewPasswordForm = observable<INewPasswordForm>({
	password: "",
	passwordConfirm: "",
	passValid: true,
	errorMessage: "",
	isLoadState: LoadingState.Loaded,
	passwordInvalid: false,
	passwordConfirmInvalid: false,
	passwordIsNeedCorrect: false,
	passwordConfirmIsNeedCorrect: false,

	keyDown: (event: any) => {
		if (event.code === "Enter") {
			NewPasswordForm.savePassword();
		}
	},
	validatePassword() {
		NewPasswordForm.password = NewPasswordForm.password;
		NewPasswordForm.passwordConfirm = NewPasswordForm.passwordConfirm;
		if (NewPasswordForm.password.length === 0) {
			NewPasswordForm.errorMessage = "Не заполнено обязательное поле";
			NewPasswordForm.passValid = false;
			NewPasswordForm.passwordInvalid = true;
			NewPasswordForm.passwordIsNeedCorrect = false;
			return;
		}
		if (NewPasswordForm.password !== NewPasswordForm.passwordConfirm) {
			NewPasswordForm.passwordConfirm = NewPasswordForm.passwordConfirm;
			NewPasswordForm.errorMessage = "Пароли не совпадают";
			NewPasswordForm.passValid = false;
			NewPasswordForm.passwordConfirmInvalid = true;
			NewPasswordForm.passwordConfirmIsNeedCorrect = false;
			return;
		}
		NewPasswordForm.errorMessage = "";
		NewPasswordForm.passValid = true;
		NewPasswordForm.passwordInvalid = false;
		NewPasswordForm.passwordConfirmInvalid = false;
		NewPasswordForm.passwordConfirmIsNeedCorrect = true;
		NewPasswordForm.passwordIsNeedCorrect = true;
	},

	savePassword: async () => {
		if (!NewPasswordForm.passValid) return;
		try {
			NewPasswordForm.passwordConfirmIsNeedCorrect = true;
			NewPasswordForm.passwordIsNeedCorrect = true;
			NewPasswordForm.isLoadState = LoadingState.Loading;
			NewPasswordForm.errorMessage = "";

			let requestData = {
				password: NewPasswordForm.password,
				name: userEntity.entity.email
			};
			const result = await axios.post("/api/User/authenticate", requestData);

			window.location.href = window.location.hash + "/settings";

			const data = result.data;
			if (data.success === true) {
				const token = data.accessToken;
				const expires = data.expires;

				document.removeEventListener("keydown", NewPasswordForm.keyDown);
				authStore.logIn(token, expires);
			} else {
				console.error("Something went wrong :(", data.message);
			}
		} catch (error: any) {
			if (error.response.status === 401) {
				NewPasswordForm.errorMessage = "Something went wrong :(";
			}
		} finally {
			NewPasswordForm.isLoadState = LoadingState.Loaded;
		}
	}
});

const Invite = observer(() => {
	const id = useParams<{ id: string }>();
	const f = NewPasswordForm;
	const navigate = useNavigate();

	useEffect(() => {
		getUser();
		document.removeEventListener("keydown", f.keyDown);
		document.addEventListener("keydown", f.keyDown);
	}, []);

	function validateInvite() {
		if (userEntity.entity.isActive) {
			localStorage.setItem("inactiveLink", "true");
			navigate("/login");
		}
	}

	async function getUser() {
		const data = { userId: id.id };
		f.isLoadState = LoadingState.Loading;
		try {
			// const loadResult = await api.post("/api/User/getuser", data);
			const loadResult = await api.http.user.getUser().post(data);
			if (loadResult.data.success) {
				userEntity.entity.deserialize(loadResult.data.data);
			}
		} catch (error) {
			console.error();
			navigate("/login");
		} finally {
			f.isLoadState = LoadingState.Loaded;
		}
	}
	const isLoading = f.isLoadState === LoadingState.Loading;

	let fieldClassNames = styles.inputField + " ";
	let inputClass = styles.input + " ";

	if (isLoading) {
		fieldClassNames += styles.textLoading;
		inputClass += styles.textLoading;
	}

	validateInvite();

	return (
		<>
			{!isLoading && (
				<div className={styles.container}>
					<div className={styles.square}>
						<div className={styles.header}>
							<div className={styles.logoContainer}>
								<LogoSalebridge />
							</div>
							<span className={styles.newUser}>Новый пользователь</span>
							<span className={styles.emailUser}>{userEntity.entity.email}</span>
						</div>
						<div className={styles.inputPanel}>
							<Field caption="Придумайте пароль" name={"firstUserPassword"} className={fieldClassNames}>
								<Input
									className={inputClass}
									id="firstPassword"
									value={f.password}
									placeholder={""}
									onChangeValue={(e) => {
										f.password = e;
										f.validatePassword();
									}}
									isDisabled={isLoading}
									isNeedCorrect={f.passwordIsNeedCorrect}
									isInvalid={f.passwordInvalid}
									isPassword={true}
								/>
							</Field>
							<Field caption="Подтвердите пароль" name={"secondUserPassword"} className={fieldClassNames}>
								<Input
									className={inputClass}
									id="secondPassword"
									value={f.passwordConfirm}
									placeholder={""}
									onChangeValue={(e) => {
										f.passwordConfirm = e;
										f.validatePassword();
									}}
									isDisabled={isLoading}
									isNeedCorrect={f.passwordConfirmIsNeedCorrect}
									isPassword={true}
									isInvalid={f.passwordConfirmInvalid}
								/>
							</Field>
						</div>
						<div className={styles.buttonContainer}>
							<Button
								className={styles.loginBtn}
								caption={isLoading ? <LoadingCircle /> : "Сохранить и войти в систему"}
								onClick={async () => {
									f.passwordInvalid = true;
									f.passwordConfirmInvalid = true;
									f.validatePassword();
									await f.savePassword();
								}}
							/>
						</div>
						{f.errorMessage && <ErrorMessage message={f.errorMessage} />}
					</div>
				</div>
			)}
		</>
	);
});

function ErrorMessage(props: { message: string }) {
	return (
		<div className={styles.ErrorBody}>
			<ul className={styles.titleError}>
				<li>Ошибка паролей</li>
				<li>{props.message}</li>
			</ul>
		</div>
	);
}
export default Invite;
