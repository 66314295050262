import { MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { v4 } from "uuid";
import classNames from "classnames";

import { dispatcher, store } from "store";
import { Position, modalController } from "features/modals/controller";

import { Button, Icon, ButtonDropdown } from "sale-bridge-ui-kit";

import { ActionEnums, actionsSectionWizzard } from "./data/data";
import { StandartItemGroup } from "sale-bridge-ui-kit/dist/components/button-dropdown/button-dropdown";

import { Ellipse, Warning } from "shared";

import styles from "./section-wizzard-header.module.scss";

export const SectionWizzardHeader = observer(
	(props: { isViewing: boolean; isViewingToggle: () => void; beforeUnloadHandler: (event: any) => string; saveClick: () => void }) => {
		const DELETE_PADDING_TICKS = 5;
		const [tick, setTick] = useState<number>(0);
		const [clicked, setClicked] = useState<boolean>(false);
		const refSetInterval = useRef<NodeJS.Timeout>();
		const [idModal] = useState<string>(v4());

		const cardActions: Array<StandartItemGroup> = [
			{
				header: "",
				items: [
					{
						id: actionsSectionWizzard[0].id.toString(),
						name: actionsSectionWizzard[0].name,
						isDisable: true
					}
				]
			},
			{
				header: "",
				items: [
					{
						id: actionsSectionWizzard[1].id.toString(),
						name: actionsSectionWizzard[1].name,
						icon: "Import",
						iconSize: "16"
					},
					{
						id: actionsSectionWizzard[2].id.toString(),
						name: actionsSectionWizzard[2].name,
						icon: "Export",
						iconSize: "16"
					}
				]
			},
			{
				header: "",
				items: [
					{
						id: actionsSectionWizzard[3].id.toString(),
						name: actionsSectionWizzard[3].name,
						icon: "Delete",
						iconSize: "small",
						isRed: true
					}
				]
			}
		];

		//TODO описать действия в разделе при нажатии на "Дизайнер раздела", раскрывающийся список
		const handleClickToAction = useCallback((id: string) => {}, []);

		const sectionWizzardHeadTitle = useMemo(() => {
			if (!props.isViewing) {
				return (
					<ButtonDropdown
						size="small"
						position="down"
						standartItems={cardActions}
						onClickStandartItem={handleClickToAction}
						childrenButton={
							<Button
								text="Дизайнер раздела"
								size="small"
								variant="default"
								border={false}
								link={false}
								loading={false}
								rightIcon="ChevronDown"
								iconSize="16"
								onClick={() => {}}
							/>
						}
					/>
				);
			} else {
				return (
					<>
						<span className={styles.headCaption}>Дизайнер раздела</span>
						<Ellipse />
						<span className={styles.greyHeadCaption}>Предварительный просмотр</span>
					</>
				);
			}
		}, [props.isViewing, cardActions]);

		const getViewingButton = useMemo(() => {
			if (!props.isViewing) {
				return (
					<Button
						text="Предпросмотр страницы редактирования"
						onClick={props.isViewingToggle}
						size="small"
						variant="secondary"
						border={false}
						link={false}
						loading={false}
						iconSize="16"
						leftIcon="WindowSidebarFill"
					/>
				);
			} else {
				return (
					<Button
						text="Вернуться в дизайнер раздела"
						onClick={props.isViewingToggle}
						size="small"
						variant="secondary"
						border={false}
						link={false}
						loading={false}
					/>
				);
			}
		}, [props.isViewing, props.isViewingToggle]);

		const handleClickSaveButton = useCallback(async () => {
			closeFuncWithOutConfirm();
			props.saveClick();
			dispatcher.kanbanCardDesign.resetHasChanges();
		}, [props, dispatcher.kanbanCardDesign]);

		const startInterval = useCallback(() => {
			refSetInterval.current = setInterval(() => {
				setTick((tick) => tick - 1);
			}, 1000);
		}, [tick, refSetInterval.current]);

		const closeAll = useCallback(() => {
			modalController.modalRemove(idModal);
			setClicked(false);
			window.close();
			store.removeEntity(store.currentEntityId);
			clearInterval(refSetInterval.current);
		}, [idModal, refSetInterval.current, store.currentEntityId]);

		useEffect(() => {
			if (tick == DELETE_PADDING_TICKS) {
				startInterval();
			}
			if (tick < DELETE_PADDING_TICKS + 1 && tick > 0 && clicked) {
				modalController.modalRemove(idModal);
				modalController.notificationAdd({
					id: idModal,
					position: Position.CENTER,
					layout: layoutNotification,
					withBackdrop: false
				});
			}
			if (tick == 0 && clicked) {
				closeAll();
			}
		}, [tick]);

		const cancelTimer = () => {
			clearInterval(refSetInterval.current);
			modalController.modalRemove(idModal);
		};
		const closeFuncWithOutConfirm = useCallback(() => {
			modalController.modalRemove(idModal);
		}, []);

		const layoutNotification = useMemo(() => {
			return (
				<>
					<div className={styles.notificationClose}>{`Вкладка браузера закроется автоматически через ${tick} сек.`}</div>
					<div className={styles.verticalDivider}></div>
					<div className={styles.notificationCancel} onClick={cancelTimer}>
						Отменить
					</div>
				</>
			);
		}, [tick]);

		const onClickNoSave = useCallback(() => {
			window.removeEventListener("beforeunload", props.beforeUnloadHandler);
			setClicked(true);
			setTick(DELETE_PADDING_TICKS);
			closeFuncWithOutConfirm();
			dispatcher.kanbanQuickView.resetHasChanges();
			modalController.notificationAdd({ id: idModal, position: Position.CENTER, layout: layoutNotification, withBackdrop: true });
		}, [tick, layoutNotification]);

		const layoutConfirm = useMemo(() => {
			return (
				<div className={styles.dialog}>
					<div className={styles.header}>
						<span className={styles.title}>Сохранить изменения?</span>
						<Warning />
					</div>
					<div className={styles.dialogFooter}>
						<Button
							text="Назад"
							onClick={closeFuncWithOutConfirm}
							size="small"
							variant="default"
							border={false}
							link={false}
							loading={false}
						/>
						<Button
							text="Не сохранять"
							onClick={onClickNoSave}
							size="small"
							variant="danger"
							border={false}
							link={false}
							loading={false}
						/>
						<Button
							text="Сохранить"
							onClick={handleClickSaveButton}
							size="small"
							variant="success"
							border={false}
							link={false}
							loading={false}
						/>
					</div>
				</div>
			);
		}, []);

		const handleClickCloseButton = useCallback(() => {
			if (dispatcher.sectionWizzard.getSectionWizzard()?.hasChanges) {
				modalController.popupAdd({ id: idModal, layout: layoutConfirm, closeFunc: closeFuncWithOutConfirm });
			} else {
				closeAll();
			}
		}, [dispatcher.sectionWizzard.getSectionWizzard()?.hasChanges]);

		return (
			<div className={styles.sectionWizzardHead}>
				<div className={styles.leftHeader}>{sectionWizzardHeadTitle}</div>

				<div className={styles.rightHeader}>
					{getViewingButton}
					{!props.isViewing && (
						<div className={styles.buttonsBlock}>
							<Button
								text="Сохранить раздел"
								size="small"
								variant="primary"
								border={false}
								link={false}
								loading={false}
								leftIcon="SaveSolid"
								iconSize="16"
								onClick={handleClickSaveButton}
							/>
							<Button
								text="Закрыть"
								size="small"
								variant="backless"
								link={false}
								loading={false}
								onClick={handleClickCloseButton}
								border
							/>
						</div>
					)}
				</div>
			</div>
		);
	}
);
