import { makeAutoObservable } from 'mobx'

/**
 * Класс Stopwatch представляет собой секундомер для записи времени.
 * Он может быть запущен, приостановлен и остановлен, с отслеживанием прошедшего времени.
 *  @property {string} recordId - Уникальный идентификатор записи.
 *  @property { number | null} startTime - Время (в миллисекундах), когда секундомер был запущен.
 *  @property { number} elapsedTime - Общее время (в миллисекундах), прошедшее с момента начала отсчета, включая время, прошедшее до паузы. Это значение обновляется при каждом вызове метода pause, когда секундомер останавливается. Хранит суммарное время, которое прошло с момента первого старта секундомера, за исключением времени, когда он находился на паузе. Используется для отображения текущего времени на дисплее и для расчета общего времени при остановке секундомера.
 *  @property { number | null} isRunning - Флаг, указывающий на текущее состояние секундомера — запущен он или приостановлен.
 */
export default class Stopwatch {
	recordId: string
	startTime: number | null = null
	elapsedTime: number = 0
	isRunning: boolean = false

	/**
	 * @param {string} recordId - Уникальный идентификатор записи, к которой привязан секундомер.
	 */
	constructor(recordId: string) {
		this.recordId = recordId
		makeAutoObservable(this)
	}

	/**
	 * Запускает секундомер. Если секундомер уже запущен, метод ничего не делает.
	 */
	start() {
		if (!this.isRunning) {
			this.isRunning = true
			this.startTime = Date.now()
		}
	}

	/**
	 * Приостанавливает секундомер. Если секундомер не запущен, метод ничего не делает.
	 */
	pause() {
		if (this.isRunning) {
			this.elapsedTime += Date.now() - (this.startTime ?? 0)
			this.isRunning = false
			this.startTime = null
		}
	}

	/**
	 * Сбрасывает секундомер в исходное состояние.
	 */
	reset() {
		this.elapsedTime = 0
		this.startTime = null
		this.isRunning = false
	}

	/**
	 * Останавливает секундомер, возвращает затраченное время в минутах и сбрасывает его.
	 * @returns {number} Затраченное время в минутах.
	 */
	stop(): number {
		this.pause()
		const minutes = Math.floor(this.elapsedTime / 60000)
		this.reset()
		return minutes
	}

	/**
	 * Возвращает текущее время секундомера в миллисекундах.
	 * @returns {number} Текущее время секундомера в миллисекундах.
	 */
	getTime() {
		if (this.isRunning && this.startTime) {
			return this.elapsedTime + (Date.now() - this.startTime)
		}
		return this.elapsedTime
	}

	/**
	 * Сериализует состояние секундомера для хранения.
	 * @returns {Object} Объект, представляющий текущее состояние секундомера.
	 */
	serialize() {
		return {
			recordId: this.recordId,
			startTime: this.startTime,
			elapsedTime: this.elapsedTime,
			isRunning: this.isRunning,
		}
	}

	/**
	 * Десериализует объект в экземпляр класса Stopwatch.
	 * @param {Object} data - Объект, содержащий состояние секундомера.
	 * @returns {Stopwatch} Экземпляр класса Stopwatch.
	 */
	static deserialize(data: any) {
		const stopwatch = new Stopwatch(data.recordId)
		stopwatch.startTime = data.startTime
		stopwatch.elapsedTime = data.elapsedTime
		stopwatch.isRunning = data.isRunning
		return stopwatch
	}
}
