import { observer } from "mobx-react-lite";
import { useState } from "react";

import {
	ChooseAnotherFile,
	IfYouNeedBigFile,
	LoadFileCaption,
	LoadOrMoveFile,
	MaxFileSize,
	MoveHere,
	xlsxFormat
} from "pages/import/data/import-constants";

import { UploadFile } from "shared";
import { File } from "shared";
import { Close } from "shared";

import styles from "./file-upload.module.css";
import buttonStyles from "components/button/button.module.css";

interface FileUploadProps {
	onSelectFile: (file: File[]) => void;
	onClearFile: () => void;
	formats: string[];
	files: File | null;
}

const FileUpload = observer((props: FileUploadProps) => {
	const [dragging, setDragging] = useState(false);
	const files = props.files;

	const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		setDragging(true);
	};

	const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		setDragging(false);
	};

	const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
	};

	const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		setDragging(false);

		const newFiles = Array.from(event.dataTransfer.files);
		props.onSelectFile(newFiles);
	};

	return (
		<div className={styles.upload} onDragEnter={handleDragEnter}>
			{dragging && (
				<div
					className={styles.over}
					onDragEnter={handleDragEnter}
					onDragOver={handleDragOver}
					onDragLeave={handleDragLeave}
					onDrop={handleDrop}
				>
					<span>{MoveHere}</span>
				</div>
			)}
			{files ? (
				<WithFile onClearFile={props.onClearFile} onSelectFile={props.onSelectFile} file={files} formats={props.formats} />
			) : (
				<NoneFile formats={props.formats} onSelectFile={props.onSelectFile} />
			)}
		</div>
	);
});

interface NoneFileProps {
	onSelectFile: (file: File[]) => void;
	formats: string[];
}

function NoneFile(props: NoneFileProps) {
	function onChangeHandler(e: React.ChangeEvent<HTMLInputElement>) {
		const file = Array.from(e.target!.files!);
		props.onSelectFile(file);
	}

	return (
		<div className={styles.content}>
			<UploadFile />
			<div className={styles.infoBlock}>
				<span className={styles.title}>{LoadOrMoveFile}</span>
				<span className={styles.tip}>Поддерживаемые форматы: {props.formats.join(", ")}</span>
				<div className={styles.infoTool}>
					<span className={styles.tooltip}>{IfYouNeedBigFile}</span>
					<span className={styles.tip}>{MaxFileSize}</span>
				</div>
			</div>
			<label className={`${buttonStyles.whiteLink} ${styles.buttonFile}`}>
				<input className={styles.inputFile} type="file" onChange={onChangeHandler} accept={props.formats.join(", ")} />
				<div className={styles.buttonText}>{LoadFileCaption}</div>
			</label>
		</div>
	);
}

interface WithFileProps {
	onSelectFile: (file: File[]) => void;
	onClearFile: () => void;
	file: File;
	formats: string[];
}

function WithFile(props: WithFileProps) {
	function onChangeHandler(e: React.ChangeEvent<HTMLInputElement>) {
		const file = Array.from(e.target!.files!);
		props.onSelectFile(file);
	}

	function clear() {
		props.onClearFile();
	}

	return (
		<div className={styles.content} style={{ paddingTop: "25px" }}>
			<File />
			<div className={styles.fileName}>
				<span>{props.file.name}</span>
				<Close onClick={clear} style={{ stroke: `var(--color-gray-400)` }} />
			</div>
			{props.file.type === xlsxFormat && (
				<div className={styles.tip} style={{ textAlign: "center", marginBottom: "4px" }}>
					Импорт данных всегда будет производиться с первого листа загруженного файла
				</div>
			)}
			<label className={`${buttonStyles.whiteLink} ${styles.buttonFile}`}>
				<input className={styles.inputFile} type="file" onChange={onChangeHandler} accept={props.formats.join(", ")} />
				<div className={styles.buttonText}>{ChooseAnotherFile}</div>
			</label>
		</div>
	);
}

export default FileUpload;
