export const popularPasswords = ["qwerty", "qwerty1", "123456", "654321",
    "666666", "123456789", "12345678", "87654321",
    "111111", "abc123", "abc123!", "password",
    "password1", "Password1!", "Pass@123", "1234567890",
    "secret", "Qwerty123", "qwerty123", "dragon",
    "1q2w3e", "unknown", "Aa123456", "P@ssw0rd",
    "Supervisor", "a123456", "123123", "123321",
    "1234567", "000000", "1000000", "qwertyuiop",
    "1q2w3e4r5t", "1q2w3e4r", "7777777", "asdasd",
    "555555", "zxcvbnm", "12345zz", "iloveyou",
    "monkey", "1qaz!QAZ", "!QAZ1qaz", "!QAZ2wsx"
]