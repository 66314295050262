import { isUndefined } from "lodash";
import { IReactionDisposer, autorun, makeAutoObservable, observable, observe, reaction, toJS } from "mobx";
import { v4 } from "uuid";

import { validateRequired } from "entities/Validation";

import { ValidationState } from "pages/section-wizzard/data/data";
import { MaskState, NumeratorConfig, NumeratorMaskConditions, NumeratorMaskItem, NumeratorMaskSettingsParameter, NumeratorMaskSettingsParameterType, NumeratorWorkingPeriod } from "types/entity";
import { isString } from "lodash";
import { EditableConditionType } from "./tabs/conditions/components/edit-numerator-condition/data";
import { Item } from "types";

export const KEY_SEPARATOR = '_';

export enum NumeratorConfigurationEnums {
    NumeratorWorkingPeriod = "numeratorWorkingPeriod",
    FillWhenPageOpened = "fillWhenPageOpened",
    MaskItems = "maskItems",
    Conditions = "conditions",
}

export enum MaskValidationKeys {
    valueLength = "_valueLength",
    step = "_step",
    startValue = "_startValue",
    column = "_column",
    lookupTableColumn = "_lookupTableColumn",
}

export const DefaultMaskParametrs: NumeratorMaskSettingsParameter[] = [{
    id: v4(),
    order: 1,
    type: NumeratorMaskSettingsParameterType.Number,
    numberParameter: {
        valueLength: 4,
        step: 1,
        startValue: '0001'
    }
}]

const initialValidation = {
    mask: {},
    condition: {
        name: {
            isInvalid: false,
            error: ""
        },
        columnName: {
            isInvalid: false,
            error: ""
        },
        columnValue: {
            isInvalid: false,
            error: ""
        },
        mask: {
            isInvalid: false,
            error: ""
        }
    }
};


type Validation = {
    mask: { [key: string]: ValidationState },
    condition: { [key: string]: ValidationState }
}

export class NumeratorConfigurationState {
    numeratorWorkingPeriod: NumeratorWorkingPeriod = NumeratorWorkingPeriod.WithoutExpirationDate;
    fillWhenPageOpened: boolean = false;
    maskItems: NumeratorMaskItem[] = [
        {
            id: v4(),
            name: 'Маска 1',
            maskState: MaskState.Default,
            parameters: DefaultMaskParametrs
        }
    ];
    conditions: NumeratorMaskConditions[] = [];

    maskOldValues: NumeratorMaskItem[] = [];

    disposer: IReactionDisposer | null = null;

    validation: Validation;

    constructor() {
        this.validation = initialValidation;
        makeAutoObservable(this);
    }
    

    isMaskUsedInCondition(maskId: string): boolean {
        return !isUndefined(this.conditions.find(condition => condition.maskId === maskId));
    }

    isMaskChanged(maskId: string): boolean {
        const currentMask = JSON.stringify(this.maskItems.find(item => item.id === maskId));
        const oldValue = JSON.stringify(this.maskOldValues.find(item => item.id === maskId));
        return currentMask !== oldValue;
    }

    getIdOfInvalidParameters(maskId: string): string[] {
        const currentMaskParameters = this.maskItems.find(item => item.id === maskId)?.parameters;
        if (!currentMaskParameters) {
            return [];
        }
        currentMaskParameters?.forEach(parameter => {
            if (parameter.type == NumeratorMaskSettingsParameterType.Number) {
                this.validateByType(NumeratorMaskSettingsParameterType.Number, `${parameter.id}${MaskValidationKeys.valueLength}`, parameter.numberParameter?.valueLength.toString());
                this.validateByType(NumeratorMaskSettingsParameterType.Number, `${parameter.id}${MaskValidationKeys.step}`, parameter.numberParameter?.step.toString());
                this.validateByType(NumeratorMaskSettingsParameterType.Number, `${parameter.id}${MaskValidationKeys.startValue}`, parameter.numberParameter?.startValue.toString());
                return
            }
            if (parameter.type == NumeratorMaskSettingsParameterType.ColumnValue) {
                this.validateByType(NumeratorMaskSettingsParameterType.ColumnValue, `${parameter.id}${MaskValidationKeys.column}`, parameter.columnValueParameter?.columnId.toString());
                if (parameter.columnValueParameter?.isLookup) {
                    this.validateByType(NumeratorMaskSettingsParameterType.ColumnValue, `${parameter.id}${MaskValidationKeys.lookupTableColumn}`, parameter.columnValueParameter?.lookupTableColumnId?.toString());
                }
                return;
            }
            if (parameter.type == NumeratorMaskSettingsParameterType.Text) {
                this.validateByType(NumeratorMaskSettingsParameterType.Text, `${parameter.id}`, parameter.textParameter?.text);
                return;
            }
        })
        const arrayOfId = Object.entries(this.validation.mask).filter(([key, value]) => value.isInvalid).map(([key, value]) => key.split(KEY_SEPARATOR)[0]);

        arrayOfId.sort((a, b) => (currentMaskParameters.find(parameter => parameter.id === a)!.order > currentMaskParameters.find(parameter => parameter.id === b)!.order) ? 1 : -1);

        return arrayOfId;
    }

    updateMasksState() {
        this.maskItems.forEach(maskItem => {
            if (maskItem.maskState == MaskState.Default) {
                return;
            }
            if (this.conditions.find(condition => condition.maskId === maskItem.id)) {
                maskItem.maskState = MaskState.Condition;
                return;
            }
            maskItem.maskState = MaskState.Deactivated;
        })
    }

    setValue(fieldName: string, value: string | boolean | number) {
        Reflect.set(this, fieldName, value);
    }

    getNumeratorConfig(): NumeratorConfig {
        return {
            numeratorWorkingPeriod: this.numeratorWorkingPeriod,
            fillWhenPageOpened: this.fillWhenPageOpened,
            maskSettings: {
                items: this.maskItems,
                conditions: this.conditions
            },
        }
    }
    getDefaultMask(maskId: string, name?: string) {
        const maskCount = this.maskItems.length;
        return {
            id: maskId,
            name: name ?? `Маска ${maskCount + 1}`,
            maskState: MaskState.Deactivated,
            parameters: DefaultMaskParametrs
        }
    }

    addMask(newMask: NumeratorMaskItem): void {
        this.maskItems.push(newMask);
    }

    editMask(mask: NumeratorMaskItem): void {
        let itemIndex = 0;
        const oldMask = this.maskItems.find((item, index) => {
            if (item.id === mask.id) {
                itemIndex = index;
                return item
            }
        });
        if (oldMask) {
            this.maskItems[itemIndex] = mask;
        }
    }

    deleteMask(maskId: string): void {
        if (this.isMaskUsedInCondition(maskId)) {
            this.conditions = this.conditions.filter(condition => condition.maskId !== maskId);
        }
        this.maskItems.splice(this.maskItems.findIndex(item => item.id === maskId), 1);
    }

    setDefaultMaskState(maskId: string) {
        const oldDefaultMask = this.maskItems.find(item => item.maskState === MaskState.Default);
        const newDefaultMask = this.maskItems.find(item => item.id === maskId)!;
        if (oldDefaultMask) {
            oldDefaultMask.maskState = this.isMaskUsedInCondition(oldDefaultMask.id) ? MaskState.Condition : MaskState.Deactivated;
        }
        newDefaultMask.maskState = MaskState.Default;
    }

    validateByType(type: NumeratorMaskSettingsParameterType, key: string, value: string | undefined) {
        switch (type) {
            case NumeratorMaskSettingsParameterType.Number:
                const numberKey = `${KEY_SEPARATOR}${key.split(KEY_SEPARATOR)[1]}`;
                switch (numberKey) {
                    case MaskValidationKeys.valueLength:
                        this.validation.mask[key] = {
                            isInvalid: isUndefined(value) || value.length == 0 || Number(value) <= 0 || Number(value) > 10 || isNaN(Number(value)) ,
                            error: (isUndefined(value) || value.length == 0) ? 'Заполните это поле' : (Number(value) <= 0) ? 'Введите значение больше 0' :  (Number(value) > 10) || isNaN(Number(value)) ? 'Укажите число меньше 10' : undefined
                        }
                        break;
                    case MaskValidationKeys.step:
                        this.validation.mask[key] = {
                            isInvalid: isUndefined(value) || value.length == 0 || Number(value) <= 0,
                            error: (isUndefined(value) || value.length == 0) ? 'Заполните это поле' : (Number(value) <= 0) ? 'Введите значение больше 0' :  undefined
                        }
                        break;
                    case MaskValidationKeys.startValue:
                        this.validation.mask[key] = {
                            isInvalid: isUndefined(value) || value.length == 0,
                            error: 'Заполните это поле'
                        }
                        break;
                }
                break;
            case NumeratorMaskSettingsParameterType.Text:
                this.validation.mask[`${key}`] = {
                    isInvalid: isUndefined(value) || value.length == 0,
                    error: 'Заполните это поле'
                }
                break;
            case NumeratorMaskSettingsParameterType.ColumnValue:
                this.validation.mask[`${key}`] = {
                    isInvalid: isUndefined(value) || value.length == 0,
                    error: 'Заполните это поле'
                }
                break;
        }
    }

    changeMaskValidation(maskId: string): void {
        const validationMaskItems: {
            [key: string]: ValidationState;
        } = {};
        const currentMask = structuredClone(toJS(this.maskItems.find(item => item.id === maskId)));//JSON.stringify(this.maskItems.find(item => item.id === maskId));

        currentMask?.parameters.forEach((param: NumeratorMaskSettingsParameter) => {
            if (param.type == NumeratorMaskSettingsParameterType.Number) {
                validationMaskItems[`${param.id}${MaskValidationKeys.valueLength}`] = {
                    isInvalid: this.validation.mask[`${param.id}${MaskValidationKeys.valueLength}`]?.isInvalid ?? false,
                    error: this.validation.mask[`${param.id}${MaskValidationKeys.valueLength}`]?.error ?? undefined,
                }
                validationMaskItems[`${param.id}${MaskValidationKeys.step}`] = {
                    isInvalid: this.validation.mask[`${param.id}${MaskValidationKeys.step}`]?.isInvalid ?? false,
                    error: this.validation.mask[`${param.id}${MaskValidationKeys.step}`]?.error ?? undefined,
                }
                validationMaskItems[`${param.id}${MaskValidationKeys.startValue}`] = {
                    isInvalid: this.validation.mask[`${param.id}${MaskValidationKeys.startValue}`]?.isInvalid ?? false,
                    error: this.validation.mask[`${param.id}${MaskValidationKeys.startValue}`]?.error ?? undefined,
                }

            }
            else if (param.type == NumeratorMaskSettingsParameterType.ColumnValue) {
                validationMaskItems[`${param.id}${MaskValidationKeys.column}`] = {
                    isInvalid: this.validation.mask[`${param.id}${MaskValidationKeys.column}`]?.isInvalid ?? false,
                    error: this.validation.mask[`${param.id}${MaskValidationKeys.column}`]?.error ?? undefined,
                };
                if (param.columnValueParameter?.isLookup) {
                    validationMaskItems[`${param.id}${MaskValidationKeys.lookupTableColumn}`] = {
                        isInvalid: this.validation.mask[`${param.id}${MaskValidationKeys.lookupTableColumn}`]?.isInvalid ?? false,
                        error: this.validation.mask[`${param.id}${MaskValidationKeys.lookupTableColumn}`]?.error ?? undefined,
                    };
                }
            }
            else validationMaskItems[param.id] = {
                isInvalid: this.validation.mask[`${param.id}`]?.isInvalid ?? false,
                error: this.validation.mask[`${param.id}`]?.error ?? undefined,
            }
        })
        this.validation.mask = validationMaskItems;
    }



    startTrackingChanges(maskId: string): void {
        let oldParameters: {} | undefined = undefined;
        const currentMask = this.maskItems.find(item => item.id === maskId)

        this.disposer = reaction(() => currentMask?.parameters.map(parameter => ({
            type: parameter.type,
            column: parameter.columnValueParameter?.columnId,
            lookupColumn: parameter.columnValueParameter?.lookupTableColumnId
        })), (value) => {
            if (JSON.stringify(value) !== JSON.stringify(oldParameters)) {
                oldParameters = structuredClone(value);
                this.changeMaskValidation(maskId);
            }
        });
    }

    dispose() {
        this.disposer?.();
    }

    initOldMaskItems(): void {
        this.maskOldValues = structuredClone(toJS(this.maskItems));
    }

    saveCondition(isEdit: boolean, condition: EditableConditionType) {
        if (isEdit) {
            const index = this.conditions.findIndex(item => item.id === condition.id);
            if (index !== -1) {
                this.conditions[index] = {
                    id: condition.id,
                    name: condition.name,
                    columnTitle: condition.column?.columnTitle!,
                    columnName: condition.column?.columnName!,
                    columnValue: condition.columnValue,
                    maskId: condition.mask?.id!
                };
            }
        } else {
            const newConditions: NumeratorMaskConditions[] = [...this.conditions];
            newConditions.push({
                id: condition.id,
                name: condition.name,
                columnTitle: condition.column?.columnTitle ?? "",
                columnName: condition.column?.columnName ?? "",
                columnValue: condition.columnValue,
                maskId: condition.mask?.id ?? ""
            })
            this.conditions = newConditions;
        }
        this.resetConditionSettings();
    }

    deleteCondition(conditionId: string): void {
        this.conditions.splice(this.conditions.findIndex(item => item.id === conditionId), 1);
    }

    conditionValidationNotUniq(name: string, value:  string | Item, id?: string) {
        this.validation.condition.columnName = { isInvalid: false, error: '' }
        this.validation.condition.columnValue = { isInvalid: false, error: '' }
        this.conditions.forEach(condition => {
            if (condition.columnName === name) {
                if (condition.id !== id && (isString(value) && condition.columnValue === value) ||
                    (!isString(value) && condition.columnValue.id === value.id)) {
                    this.validation.condition.columnName = { isInvalid: true, error: 'Такое условие уже существует' }
                    this.validation.condition.columnValue = { isInvalid: true, error: 'Такое условие уже существует' }
                }
            }
        })
    }

    conditionValidation(condition: EditableConditionType): boolean {
        this.resetValidation();
        this.conditionValidationNotUniq(condition.column?.columnTitle!, condition.columnValue);
        let columnNameValid = true;
        let valueValid = true;
        if (!this.validation.condition.columnName.isInvalid) {
            columnNameValid = validateRequired(condition.column?.columnTitle, this.validation.condition.columnName, true);
            valueValid = validateRequired(condition.columnValue, this.validation.condition.columnValue, true);
        } else {
            columnNameValid = this.validation.condition.columnName.isInvalid;
            valueValid = this.validation.condition.columnValue.isInvalid;
        }
        const nameValid = validateRequired(condition.name, this.validation.condition.name);
        const maskValid = validateRequired(condition.mask, this.validation.condition.mask, true);
        return nameValid && columnNameValid && valueValid && maskValid;
    }

    resetValidation() {
        this.validation = initialValidation;
    }

    resetMaskSettings() {
        this.maskItems = structuredClone(toJS(this.maskOldValues));
    }

    resetConditionSettings() {
        this.resetValidation();
    }

    resetConfiguration() {
        this.numeratorWorkingPeriod = NumeratorWorkingPeriod.WithoutExpirationDate;
        this.fillWhenPageOpened = false;
        this.maskItems = [
            {
                id: v4(),
                name: 'Маска 1',
                maskState: MaskState.Default,
                parameters: DefaultMaskParametrs
            }
        ];
        this.conditions = [];
        this.dispose();
        this.resetValidation();
    }
}

export const numeratorConfigurationState = new NumeratorConfigurationState();
