import { isEmpty } from "lodash";
import { observer } from "mobx-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import { dispatcher } from "store";
import { synchroiser } from "synchroiser";

import Reactor from "module/reactor/Reactor";

import { DetailSectionCard } from "../detail-section-card/detail-section-card";
import { NotFoundPage } from "pages/error-page/error-page";

import { DetailDesignerType, GridItem, LoadingState } from "types/entity";
import { SkeletonFullView } from "features/skeleton-full-view";

//НЕ ДЕЛАТЬ АВТОМАТИЧЕСКОЕ ВЫРАВНИВАНИЕ (SHIFT+ALT+F), т.к. нарушается считывание строки из парсера
export const DetailCard = observer(() => {
	const [detailDesigner, setDetailDesigner] = useState<DetailDesignerType | null>(null);
	const location = useLocation();

	useEffect(() => {
		loadRowAsync();
	}, []);

	useEffect(() => {
		setDetailDesigner(null);
		if (location?.state?.isDetail && synchroiser.loadingState != LoadingState.Loading) {
			dispatcher.fullView.switchEntityAndRow(location?.state?.detailEntityName, location.state.detailRowId);
			if (!location?.state?.isSectionDetail) {
				loadRowAsync();
			}
		}
	}, [location.state]);

	//НЕ ДЕЛАТЬ АВТОМАТИЧЕСКОЕ ВЫРАВНИВАНИЕ (SHIFT+ALT+F), т.к. нарушается считывание строки из парсера

	const loadRowAsync = useCallback(async () => {
		if (dispatcher.entity.get()?.entityName && location.state.detailRowId) {
			const detailFromConfig = await synchroiser.getDetailFromConfig(location.state.detailRowId);
			setDetailDesigner(detailFromConfig);
		}
	}, [dispatcher.entity.get(), detailDesigner]);

	//НЕ ДЕЛАТЬ АВТОМАТИЧЕСКОЕ ВЫРАВНИВАНИЕ (SHIFT+ALT+F), т.к. нарушается считывание строки из парсера

	const xmlParser = useMemo(() => {
		return detailDesigner && !location?.state?.isSectionDetail
			? `
                <Program>
                    <Module>
                        <Service name="main"/>                        
                    </Module>

                    <Render direction="column" height="100%">
                        <DetailFullView 
                            entityTitle="value:main:entityTitle"
                            viewColumn="${location.state.entityViewColumn.displayValue}"  
                            detailTitle="value:main:detailTitle"
                            navigationToEntity="/singlePage/{{value:main:entityName}}" 
                            navigationToRecord="/singlePage/{{value:main:entityName}}/{{value:main:viewColumnId}}" 
                            onSave="func:synchroiser:updateRow" 
                            onCancel="func:dispatcher.currentRow:rollback"
                        >
                            <Flex gap="10" direction="column" height="100%">
                                <Content>                                        
                                    <Grid columns="1fr 1fr" gap="10" height="auto" padding="35px 40px 0">
                                        ${detailDesigner?.detailConfig?.inner?.items
											.map((item: GridItem) => {
												if (item.x === -1 || item.y == -1) {
													return null;
												}
												return item.fieldConfig && `<Field field='${JSON.stringify(item)}' />`;
											})
											.filter((item) => item !== null)}
                                    </Grid>                                            
                                </Content>
                            </Flex>
                        </DetailFullView>               
                    </Render>
                                            
                    <Script>
                    (
                        class MainDetailService{
                            entityTitle = "${location.state.entityTitle}";
                            entityName = "${location.state.entityName?.toLowerCase()}";
                            detailTitle = "${detailDesigner?.detailConfig?.detailTitle}";
                            viewColumnId ="${location.state.entityViewColumn.id}";
                        
                            constructor(){
                            
                            }
                        
                        }
                        
                    )
                </Script>
                </Program>
                    
            `
			: "";
	}, [location.state, detailDesigner]);

	if (location?.state?.isSectionDetail) {
		return <DetailSectionCard />;
	}

	if (
		(synchroiser.loadingState == LoadingState.Loading && !detailDesigner) ||
		synchroiser.loadingState == LoadingState.Loading ||
		isEmpty(xmlParser)
	) {
		return <SkeletonFullView />;
	}

	if (synchroiser.loadingState == LoadingState.Error || !location.state.entityTitle || !location.state.entityName) {
		return <NotFoundPage />;
	}

	return <Reactor xml={xmlParser} />;
});
