export interface FavoriteSection {
	userId: string
	sectionId: string
	section: any
}

export type ISectionSelectItem = {
    id: number | string;
    displayValue?: string;
    entityName?: string;
    IsFavorite?: boolean;
    classNames?: string;
    isDisabled?: boolean;
    isLocked?: boolean;
    tag?: boolean;
}

export const enum ProfileItemType {
    PersonalSettings = 'Персональные настройки',
    Profile = 'Профиль',
    Logout = 'Выйти'
}
export const enum DotsItemType {
    Workspace = 'Рабочий стол',
    SectionWizard = 'Дизайнер раздела',
    Sales = 'Продажи',
    Docs = 'Документооборот'
}