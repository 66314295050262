import { Item } from "types";

import { CreateGroup, DeleteIcon, Duplicate, FolderCreate, FolderMove, Rename } from "shared";

export enum FolderActionEnum {
	CreateGroup = "CreateGroup",
	CreateFolder = "CreateFolder",
	MoveFolder = "MoveFolder",
	RenameFolder = "RenameFolder",
	DeleteFolder = "DeleteFolder"
}

export enum ItemActionEnum {
	EditItem = "EditItem",
	FavouriteItem = "FavouriteItem",
	MoveItem = "MoveItem",
	DuplicateItem = "DuplicateItem",
	RenameItem = "RenameItem",
	DeleteItem = "DeleteItem"
}

export const FilterFolderActions: Item[] = [
	{
		id: FolderActionEnum.CreateFolder,
		name: "Создать папку",
		icon: <FolderCreate />
	},
	{
		id: FolderActionEnum.MoveFolder,
		name: "Переместить в...",
		icon: <FolderMove />
	},
	{
		id: FolderActionEnum.RenameFolder,
		name: "Переименовать",
		isSeparator: true,
		icon: <Rename />
	},

	{
		id: FolderActionEnum.DeleteFolder,
		name: "Удалить папку",
		isRed: true,
		icon: <DeleteIcon />
	}
];

export const GroupFolderActions: Item[] = [
	{
		id: FolderActionEnum.CreateGroup,
		name: "Создать группу",
		icon: <CreateGroup />
	},
	{
		id: FolderActionEnum.CreateFolder,
		name: "Создать папку",
		icon: <FolderCreate />
	},
	{
		id: FolderActionEnum.MoveFolder,
		name: "Переместить в...",
		icon: <FolderMove />
	},
	{
		id: FolderActionEnum.RenameFolder,
		name: "Переименовать",
		isSeparator: true,
		icon: <Rename />
	},
	{
		id: FolderActionEnum.DeleteFolder,
		name: "Удалить папку",
		isRed: true,
		icon: <DeleteIcon />
	}
];

export const StaticGroupActions: Item[] = [
	{
		id: ItemActionEnum.MoveItem,
		name: "Переместить ...",
		icon: <FolderMove />
	},
	{
		id: ItemActionEnum.DuplicateItem,
		name: "Дублировать",
		icon: <Duplicate />
	},
	{
		id: ItemActionEnum.RenameItem,
		name: "Переименовать",
		isSeparator: true,
		icon: <Rename />
	},
	{
		id: ItemActionEnum.DeleteItem,
		name: "Удалить",
		isRed: true,
		icon: <DeleteIcon />
	}
];
