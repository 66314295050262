import { Api } from "shared/api/class-api";

class Tags extends Api {
	basicPath: string;
	constructor() {
		super();
		this.basicPath = "/api/Tags";
	}

	createTag = () => {
		this.setPath(`${this.basicPath}/create`);
		return this;
	};
	updateTag = () => {
		this.setPath(`${this.basicPath}/update`);
		return this;
	};
	deleteTag = () => {
		this.setPath(`${this.basicPath}/delete`);
		return this;
	};
	getTag = () => {
		this.setPath(`${this.basicPath}/get`);
		return this;
	};
	tagsList = () => {
		this.setPath(`${this.basicPath}/list`);
		return this;
	};
}

const tags = new Tags();

export { tags };
