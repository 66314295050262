import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { v4 } from "uuid";
import classNames from "classnames";

import { Zone } from "modules/DND/zone";
import { Element } from "modules/DND/element";
import detailMasterState from "features/detail-master/detail-master-state";
import detailFieldConfigurationPopupState from "../detail-field-configuration-popup/detail-field-configuration-popup-state";
import { modalController } from "features/modals";
import { store } from "store";

import { DragFieldBlock } from "pages/section-wizzard/components/drag-zone/multi-type-drag-block/drag-blocks";
import { DetailFieldConfigurationPopup } from "../detail-field-configuration-popup";
import { Button, ButtonStyle } from "components";

import { GridItem, ItemType } from "types/entity";
import { Types } from "modules/DND/type";
import { ColumnType } from "entities/ColumnType";
import { DEFAULT_WARNING_TEXT } from "pages/section-wizzard/data/data";

import TypeIcon from "features/type-icon/type-icon";
import { DragIcon, Warning } from "shared";

import styles from "./detail-master-drag-zone.module.css";

export interface IDragElement {
	id: string;
	title: string;
	x: number;
	y: number;
	inner?: {
		items: Array<IDragElement>;
	};
	width: number;
	height: number;
	type: any;
	fieldType: ItemType | undefined;
}

const CELL_HEIGHT = 48;

const POST_INIT_TIME = 500;

const MINIMAL_ROW_QUALITY = 11;

export const DetailMasterDragZone = observer((props: { grid: GridItem[]; isNew: boolean }) => {
	const [rowQualityByGridList, setRowQualityByGridList] = useState(MINIMAL_ROW_QUALITY);
	const [rowQualityByScreen, setRowQualityByScreen] = useState(MINIMAL_ROW_QUALITY);

	const [cellLayoutWidth, setCellLayoutWidth] = useState(0);
	const [targetElementViewLayout, setTargetElementViewLayout] = useState<JSX.Element>(<></>);

	const [zoneId] = useState(v4());
	const [idConfirm] = useState<string>(v4());
	const [idModal] = useState<string>(v4());

	const container = useRef<HTMLDivElement>(null);

	const grid = useMemo(() => {
		return props.grid;
	}, [toJS(props.grid)]);

	useEffect(() => {
		const updateContainerSize = () => {
			if (container.current) {
				setRowQualityByScreen(Math.floor(container.current.getBoundingClientRect().height / CELL_HEIGHT));
				setCellLayoutWidth(container.current.getBoundingClientRect().width / 2 - 20);
			}
		};
		updateContainerSize();
		window.addEventListener("resize", updateContainerSize);
		return () => {
			window.removeEventListener("resize", updateContainerSize);
		};
	}, []);

	useEffect(() => {
		if (grid.length > 0 && grid.find((item) => item.x !== -1 && item.y !== -1)) {
			const maxYElement = grid.reduce((maxYElement, currentElement) =>
				maxYElement.y > currentElement.y ? maxYElement : currentElement
			);
			setRowQualityByGridList(maxYElement.y + 1);
		}
	}, [toJS(grid)]);

	const handleDrop = useCallback(
		({
			elementId,
			sourceZone,
			targetZone,
			MATRIX
		}: {
			elementId: string | null;
			sourceZone: string | null;
			targetZone: string | null;
			type: Types;
			sourceData?: any;
			[key: string]: any;
		}) => {
			if (MATRIX.cellY === Math.max(rowQualityByGridList, rowQualityByScreen, MINIMAL_ROW_QUALITY)) {
				setRowQualityByGridList(Math.max(rowQualityByGridList, rowQualityByScreen, MINIMAL_ROW_QUALITY) + 1);
			}

			const dragElement = grid.find(({ gridItemId }) => gridItemId === elementId);
			if (dragElement) {
				const gridItem: GridItem = {
					...dragElement,
					x: MATRIX.cellX,
					y: MATRIX.cellY,
					gridItemId: dragElement?.gridItemId,
					width: dragElement?.width,
					height: dragElement?.height
				};
				detailMasterState.updateGridItem(gridItem);
			}
		},
		[toJS(grid), rowQualityByGridList, rowQualityByScreen]
	);

	const handleUp = useCallback(
		({ elementId }: { elementId: string }) => {
			if (props.grid) {
				const gridItem = props.grid.find((item) => item.gridItemId === elementId);
				if (gridItem) {
					const fieldClassName = classNames(styles.fieldWrapper, styles.targetElementViewLayout, {
						[`${styles.fillField} `]: gridItem?.fieldConfig?.columnTitle?.length ?? false
					});
					setTargetElementViewLayout(
						<div style={{ width: `calc(${cellLayoutWidth}px * 0.4)` }} key={gridItem.gridItemId} className={fieldClassName}>
							<DragIcon />
							<TypeIcon type={gridItem?.fieldConfig?.columnType ?? "GroupField"} />
							<span className={styles.targetElementViewTitle}>{gridItem.fieldConfig?.columnTitle}</span>
						</div>
					);
				}
			}
		},
		[cellLayoutWidth, toJS(grid)]
	);

	const closeAllModals = useCallback(() => {
		store.modals.map((modal) => {
			if (modal.id === idModal || modal.id === idConfirm) {
				modalController.modalRemove(modal.id);
			}
		});
		detailFieldConfigurationPopupState.resetConfiguration();
		store.options.isDisabledConstructorInSectionWizzard = false;
	}, [store.modals]);

	const closeConfirm = useCallback(() => {
		modalController.modalRemove(idConfirm);
	}, []);

	const warningConfirm = useMemo(() => {
		return (
			<div className={styles.warningDialog}>
				<div className={styles.warningHeader}>
					<span className={styles.warningTitle}>Внимание</span>
					<Warning />
				</div>
				<div className={styles.warningDialogBody}>
					<span className={styles.title}>{DEFAULT_WARNING_TEXT}</span>
				</div>
				<div className={styles.dialogFooter}>
					<Button caption="Вернуться к редактированию" onClick={closeConfirm} style={ButtonStyle.Subtle} />
					<Button caption="Да, отменить" onClick={closeAllModals} style={ButtonStyle.Danger} />
				</div>
			</div>
		);
	}, [closeAllModals, closeConfirm]);

	const closeFuncWithConfirm = useCallback(() => {
		modalController.popupAdd({ id: idConfirm, layout: warningConfirm, closeFunc: closeConfirm });
	}, [closeConfirm, idConfirm, warningConfirm]);

	const handleSaveEditedField = useCallback((newItem: GridItem) => {
		detailMasterState.updateFieldConfig(newItem);
	}, []);

	const handleEditField = useCallback(
		(columnId: string) => {
			const finded = grid.find((item) => item.fieldConfig?.columnId === columnId);
			modalController.popupAdd({
				id: idModal,
				layout: (
					<DetailFieldConfigurationPopup
						fieldType={finded?.fieldConfig?.columnType as ColumnType}
						close={closeFuncWithConfirm}
						closeAll={closeAllModals}
						position={{ cellX: -1, cellY: -1 }}
						columnId={columnId}
						onSave={handleSaveEditedField}
					/>
				),
				closeFunc: closeFuncWithConfirm
			});
		},
		[toJS(grid)]
	);

	const handleDeleteField = useCallback(
		(gridItemId: string) => {
			let canDelete = true;
			grid.forEach((item) => {
				if (gridItemId === item.gridItemId) {
					if (item.fieldConfig) {
						{
							if (detailMasterState.isBoxField(item.fieldConfig.columnId)) {
								canDelete = false;
							} else canDelete = true;
						}
					}
				}
			});

			const finded = grid.find((item) => item.gridItemId === gridItemId);
			if (props.isNew && canDelete) {
				detailMasterState.deleteGridItem(finded?.gridItemId!);
			} else {
				const newItem: GridItem = {
					...(finded as unknown as GridItem),
					gridItemId: finded?.gridItemId ?? "",
					x: -1,
					y: -1
				};
				detailMasterState.hideGridItem(newItem.gridItemId);
			}
		},
		[toJS(grid), props.isNew]
	);

	const elements = useMemo(() => {
		return grid?.map((element) => {
			const fieldId: string = element.gridItemId;
			const gridItem: GridItem = {
				gridItemId: element.gridItemId,
				x: element?.groupFieldsConfig ? 1 : element.x,
				y: element.y,
				width: element.width,
				height: element.height,
				type: ItemType.Field,
				fieldConfig: element.fieldConfig
			};

			const width: string = (cellLayoutWidth * gridItem.width - 20).toString();
			return (
				<Element key={fieldId} id={fieldId} x={gridItem.x} y={gridItem.y} width={gridItem.width} height={gridItem.height}>
					<DragFieldBlock
						element={gridItem}
						icon={<TypeIcon type={element.fieldConfig?.columnType} />}
						width={`${width}px`}
						boxColumns={detailMasterState.boxFields}
						onEditField={handleEditField}
						onDeleteField={handleDeleteField}
					/>
				</Element>
			);
		});
	}, [cellLayoutWidth, toJS(grid), toJS(detailMasterState.boxFields)]);

	const cellLayout = useMemo(() => {
		if (cellLayoutWidth > 0) {
			return (
				<div style={{ width: cellLayoutWidth - 10, height: `${CELL_HEIGHT}px` }}>
					<div className={styles.fieldWrapper}></div>
				</div>
			);
		} else {
			return <></>;
		}
	}, [cellLayoutWidth]);

	return (
		<div ref={container} className={styles.dragZone}>
			<Zone
				id={zoneId}
				config={{
					targetElementViewLayout: targetElementViewLayout,
					cellLayout: cellLayout,
					placeholderLayout: cellLayout,
					predictLayout: <div className={styles.predictLayout} />,
					width: 2,
					height: Math.max(rowQualityByGridList, rowQualityByScreen, MINIMAL_ROW_QUALITY)
				}}
				type={Types.MATRIX}
				onDrop={handleDrop}
				postInitTime={POST_INIT_TIME}
				onUp={handleUp}
			>
				{elements || []}
			</Zone>
		</div>
	);
});
