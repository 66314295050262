import { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { v4 } from "uuid";
import { toJS } from "mobx";

import { dispatcher } from "store";

import { modalController } from "features/modals";

import { TabsWithPagination } from "../tabs-with-pagination/tabs-with-pagination";
import { DragZone } from "../drag-zone/drag-zone";
import NewTabConfiguration from "./new-tab-configuration/new-tab-configuration";

import { DisplayedPanel, GridItem, TabId, TabState, TabsConfig } from "types/entity";
import { ITab } from "pages/section-wizzard/data/data";

import { Icon } from "sale-bridge-ui-kit";

import styles from "./tabs-panel.module.scss";

const TabDemoData = observer(() => {
	return (
		<div className={styles.demoData}>
			<span className={styles.demoCaption}>Здесь будут демо данные</span>
		</div>
	);
});

const WysiwygDemoData = observer(() => {
	return (<div className={styles.wysiwygDemoData}>
		<Icon name='PencilSquareFill' size='96' className={styles.pencilSquareFill} />
	</div>
	);
});

export const TabsPanel = observer((props: {
	isAdded?: boolean,
	positionField?: GridItem | null,
	onMoveBoxTab?: (id: string, value: boolean, currentDisplayedPanel?: DisplayedPanel) => void,
}) => {
	const [currentTab, setCurrentTab] = useState<JSX.Element>(<TabDemoData />);
	const [idModal] = useState<string>(v4());

	const sectionWizzard = useMemo(() => {
		return dispatcher.entity.get()?.entity.sectionWizzard;
	}, [dispatcher.entity.get()?.entity.sectionWizzard]);

	const tabsConfig = useMemo(() => {
		return sectionWizzard?.reactorConfig.tabs.tabsConfig;
	}, [toJS(sectionWizzard?.reactorConfig.tabs.tabsConfig)]);

	const additional = useMemo(() => {
		return sectionWizzard?.reactorConfig.tabs.additionalPanel
	}, [toJS(sectionWizzard?.reactorConfig.tabs.additionalPanel)])

	const notBoxTab = useCallback((tab: TabsConfig) => {
		return {
			tabIndex: tab.tabIndex,
			tabName: tab.tabName,
			caption: tab.tabTitle,
			action: () => {
				setCurrentTab(
					<div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
						<DragZone />
					</div>
				);
			}
		};
	}, []);

	const closeFuncWithOutConfirm = useCallback(() => {
		modalController.modalRemove(idModal);
	}, []);

	const deleteTab = useCallback((tabName: string) => {
		dispatcher.sectionWizzard.deleteTabByName(tabName);
	}, []);

	const renameTab = useCallback((tab: ITab) => {
		const currentTabConfig = tabsConfig?.find(findedTab => findedTab.tabName === tab.tabName);
		if (currentTabConfig) {
			const newTab: TabsConfig = {
				...currentTabConfig,
				tabTitle: tab.caption,
			};
			dispatcher.sectionWizzard.setTabByName(newTab)
		}
		else {
			if (additional![tab.tabName as TabId]) {
				dispatcher.sectionWizzard.setNewTitleAdditionalTab(tab.tabName as TabId, tab.caption)
			}
		}
	}, [toJS(tabsConfig), additional]);

	const onAddTabClick = useCallback(() => {
		modalController.popupAdd({
			id: idModal,
			layout: <NewTabConfiguration
				close={closeFuncWithOutConfirm}
			/>,
			closeFunc: closeFuncWithOutConfirm
		});
	}, []);

	const tabs = useMemo(() => {
		const resultTabs: ITab[] = [];
		if (sectionWizzard?.displayedPanel === DisplayedPanel.Main) {
			tabsConfig?.forEach(tab => {
				resultTabs.push(notBoxTab(tab));
			});
		}
		if (additional) {
			const additionalTabsAsArray = Object.entries(additional);
			const filteredAdditionalTabs = additionalTabsAsArray.filter(([key, value]) => sectionWizzard?.displayedPanel === DisplayedPanel.Main ?
				value.state === TabState.EnabledOnPrimary :
				value.state === TabState.EnabledOnAuxiliary).map(([key, value]) => key);
			filteredAdditionalTabs.forEach((item) => {
				const findTab = additional[item as TabId];
				if (findTab)
					resultTabs.push({
						tabIndex: findTab.index,
						tabName: item,
						caption: findTab.title,
						action: () => {
							setCurrentTab((item as TabId) === TabId.Wysiwyg ? <WysiwygDemoData /> : <TabDemoData />);
						}
					});
			});
		}
		const sortedTabs = resultTabs.sort((a, b) => {
			return a.tabIndex - b.tabIndex;
		});

		return sortedTabs;

	}, [toJS(tabsConfig), sectionWizzard?.displayedPanel, additional]);


	if (tabs.length) {
		return (
			<div className={styles.tabsPanel}>
				{props.isAdded ?
					<TabsWithPagination
						items={tabs}
						className={styles.tabs}
						renameTab={renameTab}
						onAddClick={onAddTabClick}
						onDeleteClick={deleteTab}
						onMoveBoxTab={props.onMoveBoxTab}
					/>
					: <TabsWithPagination
						items={tabs}
						className={styles.tabs}
						renameTab={renameTab}
						onDeleteClick={deleteTab}
						onMoveBoxTab={props.onMoveBoxTab}
					/>
				}
				<div className={styles.tabsPanelBody}>
					{currentTab}
				</div>
			</div>
		);
	} else { return <></> }
});
