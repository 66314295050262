import { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { action, toJS } from "mobx";
import { toNumber } from "lodash";

import { dispatcher } from "store";

import gridParametersState from "../grid-parameters-state";

import { InputNumber, InputStyleName } from "components";

import { VisibleColumn } from "types/entity";

import styles from "./display-order.module.scss";


export const DisplayOrder = observer(() => {

    useEffect(() => {
        gridParametersState.initOrderedColumns();
    }, [])

    const handleOrderChange = action(useCallback((value: string, column: VisibleColumn) => {
        if (value.length == 0) {
            column.indexX = -1;
            return;
        }
        
        if (gridParametersState.orderedColumns.find(orderedColumn => orderedColumn.indexX === toNumber(value))) {
            gridParametersState.duplicateColumn = { ...column, indexX: toNumber(value) };
        }
        column.indexX = toNumber(value);
    }, [toJS(gridParametersState.checkedColumns), toJS(gridParametersState.visibleColumns), toJS(dispatcher.entity.get()?.entity.columns)]));

    const handleFocusOut = action(useCallback(() => {
        gridParametersState.sortOrderedColumns();
    }, []));

    return (
        <div className={styles.displayOrder}>
            <span className={styles.description}>Присвойте колонкам номера. Номер «1» соответствует крайней левой колонке.</span>
            <div className={styles.displayOrderBody}>
                {
                    gridParametersState.orderedColumns?.map((column) => {
                        return (
                            <div className={styles.orderList}>
                                <InputNumber
                                    inputStyle={InputStyleName.Base}
                                    className={styles.inputNumber}
                                    value={column.indexX == -1 ? null : column.indexX}
                                    onChangeValue={(value: string) => { handleOrderChange(value, column) }}
                                    placeholder=""
                                    onFocusOut={handleFocusOut}
                                />
                                <span className={styles.columnTitle}>{column.columnTitle}</span>
                            </div>

                        )
                    })
                }
            </div>
        </div>
    );
})
