import styles from './input.module.css';

export enum InputStyleName {
    Base,
    BaseWithoutBorder,
    FullViewInput,
    LookupValue,
    QuickViewInput,
    AdvancedFilter
}

export const InputStyles = {
    [InputStyleName.Base]: {
        parentInput: `${styles.parentInput} `,
        classNames: `${styles.input} ${styles.baseInput} `,
        focus: `${styles.baseInputFocus} `,
        input: `${styles.baseInputText} `,
        disabled: `${styles.disabledBaseInput} `,
        invalid: `${styles.invalid} `,
        success: `${styles.successPrimary} `,
        errorMessage: `${styles.errorMessage} `,

    },
    [InputStyleName.BaseWithoutBorder]: {
        parentInput: `${styles.parentInput} `,
        classNames: `${styles.input} `,
        focus: `${styles.baseInputFocus} `,
        input: `${styles.baseInputText} `,
        disabled: `${styles.disabledInput} `,
        invalid: `${styles.invalid} `,
        success: `${styles.successPrimary} `,
        errorMessage: `${styles.errorMessage} `,

    },
    [InputStyleName.FullViewInput]: {
        parentInput: `${styles.fullViewParentInput} `,
        classNames: `${styles.fullViewInputWrapper} `,
        focus: `${styles.fullViewInputFocus} `,
        input: `${styles.fullViewText} `,
        disabled: `${styles.disabledInput} `,
        invalid: `${styles.invalid} `,
        success: ``,
        errorMessage: `${styles.errorMessage} `,

    },
    [InputStyleName.LookupValue]: {
        parentInput: `${styles.parentInput} `,
        classNames: `${styles.input} ${styles.lookupValueWrapper} `,
        focus: ``,
        input: `${styles.text28pxHeight} `,
        disabled: ``,
        invalid: ``,
        success: ``,
        errorMessage: ``,

    },
    [InputStyleName.QuickViewInput]: {
        parentInput: `${styles.parentInput} `,
        classNames: `${styles.input} ${styles.borderForQuickViewInput} `,
        focus: ``,
        input: `${styles.text28pxHeight} `,
        disabled: `${styles.disabledQuickViewInput} `,
        invalid: `${styles.invalid} `,
        success: ``,
        errorMessage: `${styles.errorMessage} `,

    },
    [InputStyleName.AdvancedFilter]: {
        parentInput: `${styles.parentInput} `,
        classNames: `${styles.input} ${styles.advancedFilter} `,
        focus: `${styles.advancedFilterFocus} `,
        input: `${styles.advancedFilterInput} `,
        disabled: ``,
        invalid: ``,
        success: ``,
        errorMessage: ``,

    }
}
