import { observer } from "mobx-react";
import { useEffect, useMemo, useState } from "react";
import classNames from "classnames";

import { NotStartedExport, ProgressBar } from "./components";

import { IFormat } from "entities/IItem";
import exportStore, { ResultStates } from "./core/export-store";

import styles from "./export-block.module.css";

/**
 * Интерфейс блока экспорта
 * 
 * @interface IExportBlock
 * @property {string} pageTitle - Заголовок раздела, для которого происходит экспорт
 * @property {string} schema - Название таблицы по которой происходит экспорт
 * @property {IFormat[]} formats - Массив форматов для экспорта
 * @property {string} title - Заголовок блока экспорта
 * @property {number} choosingCount - Количество выбранных записей для экспорта
 * @property {number} allCount - Общее количество записей
 * @property {() => void} onClickClose - Функция-обработчик клика закрытия блока Экспорта
 * @property {() => void} close - Функция закрытия блока Экспорта
 */
interface IExportBlock {
    pageTitle: string;
    schema: string;
    formats: IFormat[];
    title: string;
    choosingCount: number;
    allCount: number;
    onClickClose: () => void;
    close: () => void;
}

const ExportBlock = observer(function (props: IExportBlock) {

    useEffect(() => {
        exportStore.setValue(props.formats, "formats");
        exportStore.setValue(props.schema, "schema");
        const initColumns = async () => { await exportStore.initColumnsByEntity(); };
        initColumns();
        exportStore.setValue(props.pageTitle, "pageTitle");
    }, []);

    const drawPanel = useMemo(() => {
        if (exportStore.exportResult?.state === ResultStates.NotStarted) {
            return (
                <NotStartedExport
                    pageTitle={props.pageTitle}
                    schema={props.schema}
                    title={props.title}
                    choosingCount={props.choosingCount}
                    allCount={props.allCount}
                    close={props.onClickClose}
                />);
        } else if (exportStore.exportResult?.state === ResultStates.Progress) {
            return (
                <div className={styles.wrapper}>
                    <ProgressBar percent={exportStore.exportResult?.percent} />
                </div>
            );
        } else {
            setTimeout(() => {
                props.close();
            }, 1000);
            exportStore.stopSignalR();
            exportStore.setValue(
                { state: ResultStates.NotStarted, percent: 0 },
                "exportResult"
            );
        }
    }, [props, exportStore.exportResult?.percent, exportStore.exportResult?.state]);

    return <>{drawPanel}</>;
});

export default ExportBlock;

