import { Button, Hint } from "sale-bridge-ui-kit";

type ControlButtonsProps = {
	time: number;
	minutes: string | number;
	seconds: string | number;
	isRunning: boolean;
	isPausing: boolean;
	onStart: () => void;
	onPause: () => void;
	onStop: () => void;
}

/**
 * ControlButtons компонент для управления секундомером.
 * Отображает кнопки для старта, паузы и остановки секундомера.
 * @param {number} props.time - Текущее время секундомера в миллисекундах.
 * @param {string | number} props.minutes - Текущее время секундомера в минутах.
 * @param {string | number} props.seconds - Текущее время секундомера в секундах.
 * @param {boolean} props.isRunning - Флаг, указывающий, запущен ли секундомер в текущий момент.
 * @param {boolean} props.isPausing - Флаг, указывающий, стоит ли таймер на паузе в текущий момент.
 * @param {() => void} props.onStart - Функция, которая будет вызвана при старте секундомера.
 * @param {() => void} props.onPause - Функция, которая будет вызвана при паузе секундомера.
 * @param {() => void} props.onStop - Функция, которая будет вызвана при остановке секундомера.
 * @returns {JSX.Element} Компонент кнопок управления.
 */

export const ControlButtons: React.FC<ControlButtonsProps> = ({
	time,
	minutes,
	seconds,
	isRunning,
	isPausing,
	onStart,
	onPause,
	onStop,
}) => {
	return (
		<>
			{isRunning || isPausing ?
				<>
					<Button
						text={time > 0 ? `${minutes}:${seconds}` : '00:00'}
						size='small'
						variant={isRunning ? 'secondary' : 'cautionSecondary'}
						border={false}
						link={false}
						loading={false}
						onClick={() => { }}
						isFillParent
					/>
					<Hint hintBody={isPausing ? 'Возобновить' : 'Поставить на паузу'} startPosition="top">
						<Button
							text=''
							leftIcon={isRunning ? 'PauseCircleFill' : 'PlayCircleFill'}
							iconSize='16'
							size='small'
							variant={isRunning ? 'secondary' : 'cautionSecondary'}
							border={false}
							link={false}
							loading={false}
							onClick={isPausing ? onStart : onPause}
						/>
					</Hint>
					<Hint hintBody='Остановить и залогировать' startPosition="top">
						<Button
							text=''
							leftIcon='StopCircleFill'
							iconSize='16'
							size='small'
							variant={isRunning ? 'secondary' : 'cautionSecondary'}
							border={false}
							link={false}
							loading={false}
							onClick={onStop}
						/>
					</Hint>
				</>
				:
				<Button
					text='Включить секундомер'
					size='small'
					variant='default'
					border
					iconSize='16'
					leftIcon='Stopwatch'
					link={false}
					loading={false}
					onClick={onStart}
					isFillParent
				/>
			}
		</>
	)
}
