import {action, toJS} from 'mobx'

import {DirectionRelativeElement, type Element, store} from '../../../model/store'
import {type Types} from '../../../type'

/**
 *
 * @param x - pageX включая elementShifting
 * @param shifting
 * @param upendElementId
 * @param elements
 */
export function calculateTargetElement({x, shifting, upendElementId, elements}: {
    x: number;
    shifting: number;
    upendElementId: string;
    elements: Record<string, Element<Types.HORIZONTAL_COLUMN>>;
}): string | null {
    const entries = Object.entries(elements).sort((a, b) => {
        if ((a[1].pagePosition?.x ?? 0) > (b[1].pagePosition?.x ?? 0)) {
            return 1
        } else if (a[1].pagePosition?.x === b[1].pagePosition?.x) {
            return 0
        } else {
            return -1
        }
    })

    const currentElementFullInfo = entries.find((element) => element[1].id === upendElementId);

    if (!currentElementFullInfo) {
        return null;
    }

    const currentElement = currentElementFullInfo[1]

    let predictIndex = 0;

    const sourcePositionUppedElement =  elements[upendElementId].pagePosition!.x;
    let isRightDirection = false;

    const center = x - shifting;

    isRightDirection = sourcePositionUppedElement < center;
    store._movingDirection = isRightDirection ? DirectionRelativeElement.RIGHT : DirectionRelativeElement.LEFT;

    entries.forEach((entre, index) => {
        if (entre[1].pagePosition?.x !== undefined) {

            if (entre[1].id === upendElementId) {
                return;
            }

            const leftBorderElement = center;
            const rightBorderElement =  center + (currentElement.pagePosition!.width);

            if (!isRightDirection && (leftBorderElement > (entre[1].pagePosition.x + (entre[1].pagePosition.width / 2)))) {
                predictIndex = entre[1].position.x + 1;
            } else if (isRightDirection && (rightBorderElement > (entre[1].pagePosition.x + (entre[1].pagePosition.width / 2)))) {
                predictIndex = entre[1].position.x + 1;
            }
        }
    })

    const targetCopy = toJS(entries.find((element) => element[1].position.x === predictIndex))

    if (!targetCopy) {
        action('set direction relative element right', () => {            
            store._directionRelativeElement = DirectionRelativeElement.RIGHT
        })()
        return entries[entries.length - 1][0]
    }
    action('set direction relative element left', () => {
        store._directionRelativeElement = DirectionRelativeElement.LEFT
    })()

    return targetCopy[0] ?? null
}
