import { dispatcher } from "store/store-dipatcher/dispatcher";

import { IGridItem } from "components/select/types";
import { AdditionalField, CardDesign } from "types/entity";

export class KanbanCardDesignController {

    /**
     * @description Получение объекта CardDesign
     * @returns объект QuickViewDesign или undefined, если такого объекта нет
     */
    getCardDesign(): CardDesign | undefined {
        return dispatcher.sectionWizzard.getSectionWizzard()?.kanbanConfig?.cardDesign;
    };

    /**
     * @description Получение viewColumn раздела
     */
    getHeader = (): AdditionalField | null => {
        const sectionWizzard = dispatcher.sectionWizzard.getSectionWizzard();
        let newValue = null;
        if (sectionWizzard) {
            const id = sectionWizzard.viewColumnId;
            if (id) {
                dispatcher.sectionWizzard.getAllGridItems().forEach((item) => {
                    if (item.fieldConfig?.columnId === id)
                        newValue = item.fieldConfig;
                });
            }
        }
        return newValue;
    };

    /**
     * @description Метод для получения массива существующих полей из конфига ДР
     * @returns массив существующих полей
     */
    getExistFields = (): IGridItem[] => {
        const rightColumns: Array<IGridItem> = [];
        dispatcher.sectionWizzard.getAllGridItems().forEach(item => {
            let find = rightColumns.find(col => col.columnId === item.fieldConfig?.columnId);
            const findedFieldInBlocks = this.getCardDesign()?.additionalFields?.find(col => col.columnId === item.fieldConfig?.columnId);
            if (find) {
                let findIndex = rightColumns.findIndex(col => col.columnId === item.fieldConfig?.columnId);
                if (findIndex !== -1) {
                    find = {
                        ...find,
                        isDisabled: false,
                        isLocked: false
                    };
                    rightColumns[findIndex] = find;
                }
            }
            else {
                if (item) {
                    if (item.fieldConfig) {
                        if (findedFieldInBlocks || item.fieldConfig.columnId === dispatcher.sectionWizzard.getSectionWizzard()?.viewColumnId) {
                            rightColumns.push({
                                gridItemId: item.gridItemId,
                                columnId: item.fieldConfig.columnId,
                                name: item.fieldConfig.columnName,
                                displayValue: item.fieldConfig.columnTitle,
                                columnType: item.fieldConfig.columnType,
                                isDisabled: false,
                                isLocked: false,
                                isRequired: item.fieldConfig.isRequired
                            });
                        } else {
                            rightColumns.push({
                                gridItemId: item.gridItemId,
                                columnId: item.fieldConfig.columnId,
                                name: item.fieldConfig.columnName,
                                displayValue: item.fieldConfig.columnTitle,
                                columnType: item.fieldConfig.columnType,
                                isDisabled: true,
                                isLocked: true,
                                isRequired: item.fieldConfig.isRequired
                            });
                        }

                    }


                }
            }

            if (item.groupFieldsConfig?.inner?.items) {
                item.groupFieldsConfig.inner.items.forEach(innerItem => {
                    if (!innerItem.fieldConfig) {
                        return;
                    }
                    let find = rightColumns.find(col => col.id === innerItem.fieldConfig?.columnId);
                    const findedInnerFromGroupFieldInBlocks = this.getCardDesign()?.additionalFields?.find(col => col.gridItemId === innerItem.gridItemId);
                    if (find) {
                        let findIndex = rightColumns.findIndex(col => col.id === innerItem.gridItemId);
                        find = {
                            ...find,
                            isDisabled: false,
                            isLocked: false
                        };
                        rightColumns[findIndex] = find;
                    }
                    else {
                        if (findedInnerFromGroupFieldInBlocks || item.fieldConfig?.columnId === dispatcher.sectionWizzard.getSectionWizzard()?.viewColumnId) {
                            rightColumns.push({
                                gridItemId: innerItem.fieldConfig.columnId,
                                columnId: item.fieldConfig?.columnId,
                                name: innerItem.fieldConfig.columnName,
                                displayValue: innerItem.fieldConfig.columnTitle,
                                columnType: innerItem.fieldConfig.columnType,
                                isDisabled: false,
                                isLocked: false,
                                isRequired: innerItem.fieldConfig.isRequired
                            });
                        } else {
                            rightColumns.push({
                                gridItemId: innerItem.gridItemId,
                                columnId: item.fieldConfig?.columnId,
                                name: innerItem.fieldConfig.columnName,
                                displayValue: innerItem.fieldConfig.columnTitle,
                                columnType: innerItem.fieldConfig.columnType,
                                isDisabled: true,
                                isLocked: true,
                                isRequired: innerItem.fieldConfig.isRequired
                            });
                        }


                    }
                });
            }
        });

        return rightColumns;
    };
}

export const kanbanCardDesignController = new KanbanCardDesignController();