import { setRecordParams } from "./api";
import { getStageRecordsQuantity } from "../../../../../single-page-synchroiser/get-stage-records-quantity";
import { singlePageSynchroiser } from "../../../../../single-page-synchroiser/single-page-synchroiser";
import { dispatcher } from "store";
import { synchroiser } from "synchroiser";
import { action } from "mobx";
import { lowerFirst } from "lodash";

import { recalculatePositionFromStage, recalculatePositionOneStage } from "./store-setters/recalculate-position-from-stage";

import { SYS_POSITION } from "../../../../../constants";
import { getRecordsListForOneStage } from "pages/single-page/single-page-synchroiser/get-records-list-for-one-stage";
import { KanbanPageStage } from "../../data";

/**
 * @description Метод отвечает за перемещение записи между стадиями. Он догружает записи при переносе, обновляет все записи "to" стадии при переносе. Он обновляет кол-во стадий в стадии from и стадии to.
 * @param entityName - Имя раздела
 * @param stageFromId - id стадии, куда перенести запись
 * @param stageToId - id стадии, откуда перенести запись
 * @param recordId - id переносимой записи
 * @param recordNewSysPosition - Новое значение sysPosition записи
 * @param stages - Список всех стадий
 * @param options - Настройки перехода, содержат информацию, влияющую на логику изменения параметров записи(stageId, sysPosition)
 * @param options.hasSort - Выбрана ли сортировка
 * @param options.sameStage - Переходит ли перенос записи внутри одной стадии. Если да, то меняется только sysPosition
 * @param options.uploadedRecordsStageQuantity - Кол-во подгруженных записей в стадии from. Используется для предотвращения запросов на получение новых записей стадии, в которой их больше нет
 */
export async function movementService(
	entityName: string,
	stageFromId: string,
	stageToId: string,
	recordId: string,
	recordNewSysPosition: number,
	stages: KanbanPageStage[],
	options: {
		hasSort: boolean;
		sameStage: boolean;
		uploadedRecordsStageQuantity: number;
	}
) {
	// TODO Можно оптимизировать + разнеси по функциям
	const allGridItems = dispatcher.sectionWizzard.getAllGridItems();
	const stageFieldsName = allGridItems.find((gridItem) => gridItem.fieldConfig?.specializations?.properties.stageField)?.fieldConfig
		?.columnName;

	dispatcher.currentRow.switch(recordId);
	const stage = stages.find((stage) => stage.id === stageToId);

	const selectedStage = {
		id: stage?.id,
		name: stage?.name
	};

	if (options.hasSort) {
		if (options.sameStage) {
			dispatcher.currentRow.switch("");
			return;
		} else {
			action("set record stage", () => {
				if (dispatcher.currentRow.get() && stageFieldsName) {
					recalculatePositionFromStage(stageToId, recordNewSysPosition);
					dispatcher.currentRow.set(SYS_POSITION, recordNewSysPosition, false);
					dispatcher.currentRow.set(lowerFirst(stageFieldsName), selectedStage);
				}
			})();

			singlePageSynchroiser.setStageQuantityRecords(stageToId, singlePageSynchroiser.getStageQuantityRecords(stageToId) + 1);
			singlePageSynchroiser.setStageQuantityRecords(stageFromId, singlePageSynchroiser.getStageQuantityRecords(stageFromId) - 1);
			await synchroiser.updateRow();

			const newQuantityReq = await getStageRecordsQuantity(entityName, stageToId);
			const quantityReq = await getStageRecordsQuantity(entityName, stageFromId);

			singlePageSynchroiser.setStageQuantityRecords(stageToId, newQuantityReq.data.data ?? 0);
			singlePageSynchroiser.setStageQuantityRecords(stageFromId, quantityReq.data.data ?? 0);

			await getRecordsListForOneStage(stageToId, recordId);

			if (options.uploadedRecordsStageQuantity >= 0) {
				// 0 - records in server quantity
				dispatcher.currentRow.switch("");
				return;
			} else {
				singlePageSynchroiser.stageMovementCompensator(entityName, stageToId, options.uploadedRecordsStageQuantity);
			}
		}
	} else {
		action("set record stage", () => {
			if (dispatcher.currentRow.get() && stageFieldsName) {
				if (stageToId !== stageFromId) {
					recalculatePositionFromStage(stageToId, recordNewSysPosition);
				} else {
					recalculatePositionOneStage(stageToId, recordNewSysPosition, dispatcher.currentRow.get());
				}
				dispatcher.currentRow.set(SYS_POSITION, recordNewSysPosition, false);
				dispatcher.currentRow.set(lowerFirst(stageFieldsName), selectedStage);
			}
		})();
		await synchroiser.updateRow();

		singlePageSynchroiser.setStageQuantityRecords(stageToId, singlePageSynchroiser.getStageQuantityRecords(stageToId) + 1);
		singlePageSynchroiser.setStageQuantityRecords(stageFromId, singlePageSynchroiser.getStageQuantityRecords(stageFromId) - 1);
		const newQuantityReq = await getStageRecordsQuantity(entityName, stageToId);
		const quantityReq = await getStageRecordsQuantity(entityName, stageFromId);

		singlePageSynchroiser.setStageQuantityRecords(stageToId, newQuantityReq.data.data ?? 0);
		singlePageSynchroiser.setStageQuantityRecords(stageFromId, quantityReq.data.data ?? 0);
		if (options.uploadedRecordsStageQuantity >= 0) {
			// 0 - records in server quantity
			dispatcher.currentRow.switch("");
			return;
		} else {
			singlePageSynchroiser.stageMovementCompensator(entityName, stageToId, options.uploadedRecordsStageQuantity);
		}
	}
	dispatcher.currentRow.switch("");
}
