import { useEffect, useState } from "react";

import { ButtonStyle, Button, ITab, ITabStore } from "components";

import styles from "./display-tabs.module.css";



function DisplayTabs(props: {
    items: ITab[],
    className?: string,
    tabStore?: ITabStore,
    styleButton?: ButtonStyle,
    useLocalStorage?: boolean,
    localStorageKey?: string,
    selected?: string
}) {
    const [selected, setSelected] = useState<number>(props.selected ? props.items.findIndex(x => x.caption === props.selected) : 0);

    useEffect(() => {
        if (props.useLocalStorage && props.localStorageKey) {
            setSelected(Number(localStorage.getItem(props.localStorageKey)));
            props.items[Number(localStorage.getItem(props.localStorageKey))].action?.call(null, props.tabStore);

        }
        else if (!props.selected) props.items[0].action?.call(null, props.tabStore);

    }, []);

    function onClick(index: number) {

        setSelected(index);

        props.items[index].action?.call(null, props.tabStore);
        if (props.useLocalStorage && props.localStorageKey) {
            if (localStorage.getItem(props.localStorageKey))
                localStorage.removeItem(props.localStorageKey);
            localStorage.setItem(props.localStorageKey, index.toString());
        }
    }

    let className = `${styles.tab} `;
    if (props.className) {
        className += props.className;
    }


    return (
        <div className={className}>
            <div className={styles.itemBar}>

                {
                    props.items.map(
                        (el: ITab, i: number) => {
                            return (
                                el.tooltipCaption ?
                                    <div className={styles.actionsTool} key={i}>
                                        <span className={styles.tooltip}>
                                            {el.tooltipCaption}
                                        </span>
                                        <Button
                                            key={i}
                                            caption={el.caption}
                                            classNameCaption={selected === i ? styles.itemCaption + " " + styles.selected : styles.itemCaption}
                                            className={selected === i ? styles.item + " " + styles.selected : styles.item}
                                            firstIcon={el.icon}
                                            classNameFirstIcon={styles.icon}
                                            style={props.styleButton}
                                            styleButton={!el.caption ? { width: "30px" } : {}}
                                            onClick={() => { onClick(i) }}
                                        />
                                    </div>
                                    :
                                    <Button
                                        key={i}
                                        caption={el.caption}
                                        classNameCaption={selected === i ? styles.itemCaption + " " + styles.selected : styles.itemCaption}
                                        className={selected === i ? styles.item + " " + styles.selected : styles.item}
                                        firstIcon={el.icon}
                                        classNameFirstIcon={styles.icon}
                                        style={props.styleButton}
                                        styleButton={!el.caption ? { width: "30px" } : {}}
                                        onClick={() => { onClick(i) }}
                                    />

                            );
                        })
                }
            </div>
        </div>
    );
}

export default DisplayTabs;