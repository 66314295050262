import { observer } from "mobx-react-lite";
import { bindingInXml } from "module/reactor/binding/binding";
import IProps from "../../IProps";
import styles from "./Label.module.css";

interface LabelProps extends IProps {
    size?: number;
}
function Label(props: LabelProps) {
    const size = props.size ? props.size + "px" : undefined;

    let classNames = `${styles.wrapper} ${props.className}`;

    return (
        <span className={classNames} style={{ fontSize: size }}>{bindingInXml(props.services, props.value)}</span>
    )
}

export default Label;