import { MacrosType } from "entities/filter/IFilter";
import { Item } from "types";

const DateMacroses : Item[] = [
    {
        id:"0",
        name:"Указать точную дату",
    },
    {
        id:"1",
        name:"День",
        multilevelItems: [
            {
                name: "Вчера",
                id:MacrosType.Yesterday
            },
            {
                name: "Сегодня",
                id:MacrosType.Today
            },
            {
                name: "Завтра",
                id:MacrosType.Tomorrow
            }
        ]
    },
    {
        id:"2",
        name:"Неделя",
        multilevelItems: [
            {
                name: "Предыдущая",
                id:MacrosType.PreviousWeek
            },
            {
                name: "Текущая",
                id:MacrosType.CurrentWeek
            },
            {
                name: "Следующая",
                id:MacrosType.NextWeek
            }
        ]
    },
    {
        id:"3",
        name:"Месяц",
        multilevelItems: [
            {
                name: "Предыдущий",
                id:MacrosType.PreviousMonth
            },
            {
                name: "Текущий",
                id:MacrosType.CurrentMonth
            },
            {
                name: "Следующий",
                id:MacrosType.NextMonth
            }
        ]
    },
    {
        id:"4",
        name:"Квартал",
        multilevelItems: [
            {
                name: "Предыдущий",
                id:MacrosType.PreviousQuarter
            },
            {
                name: "Текущий",
                id:MacrosType.CurrentQuarter
            },
            {
                name: "Следующий",
                id:MacrosType.NextQuarter
            }
        ]
    },
    {
        id:"5",
        name:"Год",
        multilevelItems: [
            {
                name: "Предыдущий",
                id:MacrosType.PreviousYear
            },
            {
                name: "Текущий",
                id:MacrosType.CurrentYear
            },
            {
                name: "Следующий",
                id:MacrosType.NextYear
            }
        ]
    },
]

export default DateMacroses;