import { observer } from "mobx-react";

import { LeftBlockDragZone } from "./left-block-drag-zone";
import { LeftBlockHeader } from "./left-block-header";
import { Comments } from "./comments";

import { AdditionalInfoOfField } from "../../quick-view-settings/data";
import { AdditionalField } from "types/entity";

import styles from "./constructor-quick-view-left-block.module.scss";

type LeftAdditionalFieldsBlockProps = {
    header: AdditionalField | null;
    commentsEnable: boolean;
    leftBlockAdditionalFields: Array<AdditionalField>;
    leftBlockAdditionalFieldsZoneId: string;
    onChangeLeftBlockAdditionalFields: (sourceZone: string | null, targetZone: string | null, additionalField: AdditionalField, newPosition: number) => void;
    onDeleteFieldFromLeftBlock: (gridItemId: string, isLeftFieldBlock?: boolean) => void;
    getAdditionalInfoOfField: (columnId: string) => AdditionalInfoOfField;
    height: number | undefined
}

export const LeftAdditionalFieldsBlock = observer((props: LeftAdditionalFieldsBlockProps) => {

    return (
        <div className={styles.leftBody}>
            <LeftBlockHeader header={props.header} additionalInfoOfField={props.getAdditionalInfoOfField(props.header!.columnId)} />
            <LeftBlockDragZone
                leftBlockAdditionalFields={props.leftBlockAdditionalFields}
                leftBlockAdditionalFieldsZoneId={props.leftBlockAdditionalFieldsZoneId}
                onChangeLeftBlockAdditionalFields={props.onChangeLeftBlockAdditionalFields}
                onDeleteFieldFromLeftBlock={props.onDeleteFieldFromLeftBlock}
                getAdditionalInfoOfField={props.getAdditionalInfoOfField}
                height={props.height}
                commentsEnable={props.commentsEnable}
            />
            {props.commentsEnable && <Comments />}
        </div>
    );
});