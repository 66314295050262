import { observable } from "mobx";
import { observer } from "mobx-react-lite";
import { SyntheticEvent, useEffect, useRef, useState } from "react";
import { DragDropContext, DragStart, Draggable, DropResult, Droppable } from "react-beautiful-dnd";

import { IQuickViewFilld } from "features/quick-view-block/constants/constants";
import SkeletonKanban from "./skeleton/skeleton-kanban";
import QuickViewBlock from "features/quick-view-block/quick-view-block";
import { Button, ButtonStyle, Dialog, EmptyGrid, ICardData, WarningDialog } from "components";
import Card from "components/card/card";

import Entity, { IEntityStore } from "entities/Entity";
import ListStore, { CanbanColumn, LoadingState } from "entities/ListStore";
import { SortDirection } from "entities/ISort";

import styles from "./kanban.module.css";

interface IStageColumn {
    id: string;
    name: string;
    order: number;
    successful: boolean;
    end: boolean;
    createdOn: string;
    modifiedOn: string;
    color: string | null;
}

interface IPriorityColumn {
    id: string;
    name: string;
    order: number;
}

interface KanbanCards {
    allCards: ICardData[];
    loadingState: LoadingState;
    setLoadingState: (newLoadingState: LoadingState) => void;
    addCards: (newCards: ICardData[]) => void;
    newCards: (newCards: ICardData[]) => void;
    load: (stage: IStageColumn, isStartStage: boolean, isFinifhStage: boolean, listStore: ListStore, getCardsData: (data: any[]) => ICardData[]) => void;
    loadMore: (stage: IStageColumn, listStore: ListStore, getCardsData: (data: any[]) => ICardData[]) => void;
    isLoading: () => boolean;
    isLoaded: () => boolean;
}

interface KanbanCardPositionItem {
    priorityId: string;
    priorityOrder: number;
    minPosition?: number;
    maxPosition?: number
}

const template = observable<KanbanCards>({
	allCards: [],
	loadingState: LoadingState.NotAsked,
	setLoadingState: (newLoadingState: LoadingState) => {
		template.loadingState = newLoadingState;
	},
	addCards: (newCards: ICardData[]) => {
		let templateArray = template.allCards;
		newCards.forEach((value, index) => {
			let card = templateArray.find(x => (x.id === value.id && x.stageId === value.stageId));

			if (card === undefined) template.allCards.push(value);
		});
		template.allCards.sort((a, b) => { return a.position - b.position; });
	},
	newCards: (newCards: ICardData[]) => {
		template.allCards = newCards;
	},
	load: async (stage: IStageColumn, isStartStage: boolean, isFinifhStage: boolean, listStore: ListStore, getCardsData: (data: any[]) => ICardData[]) => {

		if (isStartStage) {
			template.newCards([]);
			template.setLoadingState(LoadingState.Loading);
		}

		let canbanColumn = {
			columnName: "Stage",
			columnValue: stage.id
		} as CanbanColumn;

		const sort = {
		    columnPath: "position",
		    direction: SortDirection.Ascending
		}

		await listStore.load(listStore.filter, listStore.staticGroupId, 10, sort, canbanColumn);
		let cards = (getCardsData(listStore!.data) as ICardData[]).sort((a, b) => { return a.position - b.position; });
		template.addCards(cards);

		if (isFinifhStage && listStore.isLoaded) template.setLoadingState(LoadingState.Loaded);
	},
	loadMore: async (stage: IStageColumn, listStore: ListStore, getCardsData: (data: any[]) => ICardData[]) => {		

		let blocks = template.allCards.reduce((group: { [stageId: string]: ICardData[] }, block) => {
			const { stageId } = block;
			group[stageId] = group[stageId] ?? [];
			group[stageId].push(block);
			return group;
		}, {});
		
		const canbanColumn = {
			columnName: "Stage",
			columnValue: stage.id
		} as CanbanColumn;

		const sort = {
		    columnPath: "position",
		    direction: SortDirection.Ascending
		}

		await listStore.loadMore(listStore.filter, 10, sort, canbanColumn, blocks[stage.id].length);
		let cards = (getCardsData(listStore!.data) as ICardData[]);//.sort((a, b) => { return a.priorityOrder - b.priorityOrder; })
		template.addCards(cards);


	},
	isLoading: (): boolean => template.loadingState === LoadingState.Loading,
	isLoaded: (): boolean => template.loadingState === LoadingState.Loaded,
});

const KanbanHead = observer(function (props: { stage: IStageColumn, count: number }) {
	const { stage, count } = props;
	return (
		<div className={styles.kanbanHead}>
			<span className={styles.tooltip}>{stage.name}</span>
			<div className={styles.kanbanHeader}>
				<div key={stage.name} id={stage.id} className={styles.headerCell} >
					<span className={styles.headerCaption}>{stage.name.toUpperCase()}</span>
					<div className={styles.headerFooter}>

						<>
							<div className={styles.ellipse} style={{ margin: "0 10px" }}></div>
							<span>{count}</span>
						</>

					</div>
				</div>
			</div>




		</div>

	);
});

const Kanban = observer(function (props: {
    stages: IStageColumn[],
    listStore: ListStore,
    listStagesStore: ListStore,
    listPriorityStore: ListStore,
    entity: Entity<IEntityStore>,
    field: IQuickViewFilld[],
    infoFields: IQuickViewFilld[],
    stageField: IQuickViewFilld,
    getCardsData(data: any[]): ICardData[]
}) {
	const [selected, setSelected] = useState<string | null>(null);
	const [quickView, setQuickView] = useState<JSX.Element>(<></>);

	const [startColumn, setStartColumn] = useState<string | null>(null);
	const [selectedId, setSelectedId] = useState<string | null>(null);
	let cards = template.allCards;
	const [selectId, setSelectId] = useState<string>("");
	const [isSaved, setSaved] = useState<boolean>(false);
	const contextRef = useRef<DragDropContext>(null);


	const defaultLimit = 0;
	const allRecords = 0;


	const listStore = props.listStore;
	const listStagesStore = props.listStagesStore;
	const listPriorityStore = props.listPriorityStore;

	const refSetTimeout = useRef<NodeJS.Timeout>();
	const ref = useRef<HTMLDivElement>(null);

	const state = useRef({
		isScrolling: false,
		startX: 0,
		scrollLeft: 0
	});


	function onMouseMove(e: any) {
		if (ref && ref.current && !ref.current.contains(e.target)) {
			return;
		}
		e.preventDefault();

		if (state.current.isScrolling) {
			if (ref.current !== null) {
				const slider = ref.current;
				const x = e.pageX - slider.offsetLeft;
				const walkX = (x - state.current.startX) * 1.5;
				slider.scrollLeft = state.current.scrollLeft - walkX;
			}

		}

	};

	function onMouseUp(e: any) {
		if (ref && ref.current && !ref.current.contains(e.target)) {
			return;
		}
		e.preventDefault();
		document.body.style.cursor = "default";
		state.current = {
			...state.current,
			isScrolling: false
		};

	};

	function onMouseDown(e: any) {
		if (ref && ref.current && ref.current.contains(e.target) && e.target.className.toLowerCase().includes("card")) {
			return;
		}
		e.preventDefault();
		if (ref.current) {
			const slider = ref.current;
			const startX = e.pageX - slider.offsetLeft;
			const scrollLeft = slider.scrollLeft;

			document.body.style.cursor = "grabbing";

			state.current = {
				isScrolling: true,
				startX: startX,
				scrollLeft: scrollLeft
			};
		}

	};

	useEffect(() => {
		listStagesStore?.load(null, null, allRecords);
		listPriorityStore?.load(null,null, allRecords);
	}, []);

	let stages = listStagesStore.data.slice().sort((a, b) => { return a.order - b.order; }) as IStageColumn[];
	let priorities = listPriorityStore.data.slice().sort((a, b) => { return a.order - b.order; }) as IPriorityColumn[];

	async function save() {
		// let saved = await saleEntity.save();
		// setSaved(saved);
		refSetTimeout.current = setTimeout(() => { setSaved(false); }, 4000);
	}
	function resetSelected() {
		setSelected(null);
		setQuickView(<></>);
	}

	async function onClick(id: string) {
		setSelected(id);
		// setQuickView(<SkeletonQuickView />)
		// let loading = await props.entity.load(id);
		// if (loading) {
		//     setQuickView(
		//         <QuickViewBlock
		//             schema={listStore.schema}
		//             id={id}
		//             entity={props.entity}
		//             fields={props.field}
		//             infoFields={props.infoFields}
		//             stageField={props.stageField}
		//             pageTitle={props.entity.entity.displaySchema! + ' / ' + Reflect.get(props.entity.entity, "name") as string}
		//             quickViewTitle={Reflect.get(props.entity.entity, "title") as string ?? Reflect.get(props.entity.entity, "name") as string}
		//             closeQuick={resetSelected}
		//             load={() => listStore.load(null, defaultLimit)}
		//         />)
		// }
		// else setQuickView(<SkeletonQuickView />)
	}



	// let cardsData: any[] | null = null;

	// useEffect(() => {
	//     console.log("use2");
	//     console.log(template.allCards);


	//     // if (listStore!.isLoaded && listStagesStore.isLoaded) {

	//     //     cardsData = (props.getCardsData(listStore!.data) as ICardData[]).sort((a, b) => { return a.priorityOrder - b.priorityOrder; });
	//     //     console.log(cardsData);

	//     //     template.addCards(cardsData);

	//     //     console.log(template.allCards);

	//     //     template.addCards(cardsData);

	//     //     console.log(template.allCards);
	//     //     setCards(cardsData);
	//     // }
	// }, [listStore!.isLoaded && listStagesStore.isLoaded])


	const onDragEnd = async (result: DropResult) => {
		setStartColumn(null);
		setSelectedId(null);
		const { destination, source, draggableId } = result;

		if (!destination) {
			return;
		}

		if (
			destination.droppableId === source.droppableId &&
            destination.index === source.index
		) {
			return;
		}

		const start = stages.find(x => x.id === source.droppableId);
		const finish = stages.find(x => x.id === destination.droppableId);

		if (start && finish && cards) {
			if (start === finish) {

				const currentStageCards = Array.from(cards.filter(x => x.stageId === start.id));
				const startCard = currentStageCards[source.index];
				let finishCard = currentStageCards[destination.index];

				if (!finishCard) {
					finishCard = currentStageCards[currentStageCards.length - 1];
				}

				const currentPosition = finishCard.position;

				if (startCard.priorityOrder === finishCard.priorityOrder) {
					let cardsForUpdatePosition;

					if (startCard.position < finishCard.position) {
						cardsForUpdatePosition = currentStageCards.filter(x => x.position <= currentPosition);
						cardsForUpdatePosition.forEach((item, index) => {
							item.position--;
						});
					}
					else {
						cardsForUpdatePosition = currentStageCards.filter(x => x.position >= currentPosition);
						cardsForUpdatePosition.forEach((item, index) => {
							item.position++;
						});
					}

					startCard.position = currentPosition;
				}
				else {
					return;
				}

				const remainingCards = cards.filter(x => x.stageId !== start.id);
				const newCardsInColumn = cards.filter(x => x.stageId === start.id);
				const [removed] = newCardsInColumn.splice(source.index, 1);

				newCardsInColumn.splice(destination.index, 0, removed);

				const updatePosition = {
					entityName: props.entity.entity.schema,
					values: [
						{
							propertyName: "Id",
							propertyValue: draggableId
						},
						{
							propertyName: "Position",
							propertyValue: currentPosition
						}
					]
				};



				const newCards = remainingCards.concat(newCardsInColumn.sort((a, b) => { return a.position - b.position; }));
				template.newCards(newCards);

				await props.entity.updateStage(updatePosition);

				return;
			}

			const startStageCards = Array.from(cards.filter(x => x.stageId === start.id));
			const finishStageCards = Array.from(cards.filter(x => x.stageId === finish.id));
			const startCard = startStageCards[source.index];
			let finishCard = finishStageCards[destination.index];

			if (!finishCard) {
				finishCard = finishStageCards[finishStageCards.length - 1];
			}

			let newPosition = 0;

			if (finishStageCards.length > 0) {
				const hasCurrentPriority = finishStageCards.some(f => f.priorityOrder === startCard.priorityOrder);
				if (hasCurrentPriority) {
					newPosition = finishStageCards.filter(f => f.priorityOrder == startCard.priorityOrder).reduce((minValue, currentCardData) => {
						if (currentCardData.position < minValue.position) {
							return currentCardData;
						} else {
							return minValue;
						}
					}).position;
				}
				else {
					let kanbanPositions: KanbanCardPositionItem[] = [];

					priorities.forEach((item, index) => {
						const cards = finishStageCards.filter(f => f.priorityOrder == item.order);
						if (cards.length > 0) {
							const kanbanPositionItem: KanbanCardPositionItem = { priorityId: item.id, priorityOrder: item.order };
							kanbanPositionItem.minPosition = cards.reduce((minValue, currentCardData) => {
								if (currentCardData.position < minValue.position) {
									return currentCardData;
								} else {
									return minValue;
								}
							}).position;

							kanbanPositionItem.maxPosition = cards.reduce((maxValue, currentCardData) => {
								if (currentCardData.position > maxValue.position) {
									return currentCardData;
								} else {
									return maxValue;
								}
							}).position;

							kanbanPositions.push(kanbanPositionItem);
						}
					});

					const maxPosition = kanbanPositions
						.filter(p => p.priorityOrder < startCard.priorityOrder)
						.sort((a, b) => b.priorityOrder - a.priorityOrder)
						.find(p => true)?.maxPosition;

					if (maxPosition !== undefined) {
						newPosition = maxPosition + 1;
					}
				}
			}

			const cardsForUpdatePosition = finishStageCards.filter(x => x.position >= newPosition);
			cardsForUpdatePosition.forEach((item, index) => {
				item.position++;
			});

			startCard.position = newPosition;
			const remainingCards = cards.filter(x => x.stageId !== start.id && x.stageId !== finish.id);
			const startCardIds = Array.from(cards.filter(x => x.stageId === source.droppableId));
			startCardIds.splice(source.index, 1);
			const finishTaskIds = Array.from(cards.filter(x => x.stageId === finish.id));
			const removed = cards.find(x => x.id === draggableId);
			const updatedRemoved = { ...removed!, stageId: finish.id };
			finishTaskIds.splice(destination.index, 0, updatedRemoved);

			const updateStage = {
				entityName: props.entity.entity.schema,
				values: [
					{
						propertyName: "Id",
						propertyValue: draggableId
					},
					{
						propertyName: "StageId",
						propertyValue: finish.id
					}
				]
			};


			const newCards = remainingCards.concat(startCardIds.sort((a, b) => { return a.position - b.position; }), finishTaskIds.sort((a, b) => { return a.position - b.position; }));
			template.newCards(newCards);


			await props.entity.updateStage(updateStage);

			if (finish.id === "bac5a0ac-12b9-4c68-be87-ed16c545849a") {
				// saleEntity.new(new Sale());
				// console.log(saleEntity)
				// setOpened(true);
			}


		}

	};



	const onDragStart = (start: DragStart) => {
		// start.type.preventDefault();
		const { source, draggableId } = start;
		setStartColumn(() => source.droppableId);
		setSelectedId(draggableId);
		setSelectId(draggableId);
		state.current = {
			...state.current,
			isScrolling: false,
		};
	};

	// let stage = listStagesStore.data.slice().sort((a, b) => { return a.order - b.order; }) as IStageColumn[]
	let stagesCount = stages.length;

	return (
		<div style={{ display: "flex", position: "relative", flexGrow: 2, marginTop: "10px", overflow: "hidden", marginBottom: "10px" }}>
			<div
				className={styles.kanban}
				ref={ref}
				onMouseDown={onMouseDown}
				onMouseUp={onMouseUp}
				onMouseMove={onMouseMove}
				style={
					state.current.isScrolling ? { cursor: "pointer" } : {}
				}
			>

				<DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart} ref={contextRef} >
					{/* <KanbanHead stage={stage} data={cards} count={stagesCount} /> */}
					{/* <div className={styles.kanbanBody}> */}
					{
						stages.map((stage, index) => {
							// const filteredCards = cards?.filter(
							//     cards => cards.stageId === stage.id,
							// ) as ICardData[];

							return (
								<Column key={stage.id}
									// data={listStore!.data}
									listStore={listStore}
									getCardsData={props.getCardsData}
									isStartStage={index == 0}
									isFinifhStage={index == (stages.length - 1)}


									stage={stage}
									selected={selected!}
									onClick={onClick}
									resetSelected={resetSelected}
									// cardData={filteredCards}
									selectedId={selectedId}
									startColumn={startColumn}
									count={stagesCount}
								/>

							);
						})
					}

					{/* </div> */}
				</DragDropContext>
			</div>
			{(template.allCards.length === 0 && template.isLoaded()) && <div className={styles.emptyKanban}><EmptyGrid /></div>}
			{/* {quickView} */}
			{selected &&
                <QuickViewBlock
                	schema={listStore.schema}
                	id={selected}
                	entity={props.entity}
                	fields={props.field}
                	infoFields={props.infoFields}
                	stageField={props.stageField}
                	pageTitle={props.entity.entity.displaySchema! + " / " + Reflect.get(props.entity.entity, "name") as string}
                	quickViewTitle={Reflect.get(props.entity.entity, "title") as string ?? Reflect.get(props.entity.entity, "name") as string}
                	closeQuick={resetSelected}
                	load={() => listStore.load(null,null, defaultLimit)}
                />
			}
			{selectId &&
                <>
                	{/* <Dialog title={"Новая продажа"} isOpen={saleEntity.openedDialog} close={true} dialogFooterButtons={
                		<>
                			<Button caption={"Создать позднее"} onClick={saleEntity.cancelChanges} style={ButtonStyle.Subtle} />
                			<Button caption={"Сохранить"} onClick={save} style={ButtonStyle.Success} />
                		</>
                	}>
                		<LeadToSale schema={listStore.schema} id={selectId} entity={props.entity} />
                	</Dialog>
                	<WarningDialog isOpen={saleEntity.openedWarningDialog} onBackClick={saleEntity.setWarningDialog} onCancelClick={saleEntity.cancelChanges} /> */}
                </>
			}

		</div>

	);
});

const Column = observer(function (props: {
    // data: any[] | null, cardData: ICardData[] | null, 
    listStore: ListStore, getCardsData(data: any[]): ICardData[],
    isStartStage: boolean, isFinifhStage: boolean,
    stage: IStageColumn, selected?: string, onClick?: (id: string) => void, resetSelected: () => void, startColumn?: string | null,
    selectedId?: string | null, count: number
}) {
	const { stage, listStore, getCardsData, isStartStage, isFinifhStage } = props;

	// const cards = props.cardData;
	const [enabled, setEnabled] = useState(false);
	const wrapperRef = useRef<HTMLDivElement>(null);

	const [cards, setCards] = useState<ICardData[] | null>(null);


	useEffect(() => {
		// template.load(stage, isStartStage, isFinifhStage, listStore, getCardsData);

		// load();
		const animation = requestAnimationFrame(() => setEnabled(true));

		return () => {
			cancelAnimationFrame(animation);
			setEnabled(false);
		};
	}, []);


	useEffect(() => {
		// console.log("useColumn2");

		// if (template.getStagesCount()==stagesCount) {
		template.load(stage, isStartStage, isFinifhStage, listStore, getCardsData);
		// template.newCards([])
		// load();
		// }
	}, [listStore!.filter]);



	if (template.isLoading()) {
		return (
			<div className={styles.loading}>
				{[...Array(8)].map((item, index) => <SkeletonKanban numCards={index % 2 + 2} />)}
			</div>
		);
	}

	function trackScrolling(e: SyntheticEvent<HTMLDivElement>) {
		const wrappedElement = e.target as HTMLDivElement;
		if (Math.round(wrappedElement.scrollHeight - wrappedElement.scrollTop) <= wrappedElement.clientHeight + 1 && listStore!.isLoaded) {

			// listStore?.loadMore(listStore.filter, defaultLimit, sort.current)
			template.loadMore(stage, listStore, getCardsData);
			console.log("trackScrolling грузим порцию");
		}
	}
	if (!enabled) {
		return null;
	}

	// function resetSelected() {
	//     props.resetSelected();
	//     document.removeEventListener("click", (e) => { handleClick(e, null) });
	// }


	function handleClick(event: Event, id: string | null) {
		console.log("handleClick ", id, (wrapperRef.current != null && (event.target as Node).nodeName !== "svg" && (event.target as Node).nodeName !== "path"),
			(!((!wrapperRef.current?.contains(event.target as Node) || wrapperRef.current?.isEqualNode(event.target as Node))/* && !isQuickView*/)),
			(wrapperRef.current?.contains(event.target as Node) && id !== null));

		if (wrapperRef.current != null && (event.target as Node).nodeName !== "svg" && (event.target as Node).nodeName !== "path") {
			if (!((!wrapperRef.current.contains(event.target as Node) || wrapperRef.current.isEqualNode(event.target as Node))/* && !isQuickView*/)) {
				if (wrapperRef.current.contains(event.target as Node) && id !== null) {
					if (props.onClick) {
						props.onClick(id);
					}
					document.removeEventListener("click", (e) => { handleClick(e, null); });
				}
			}
		}


	}

	function onClick(id: string) {
		if (props.onClick) {
			props.onClick(id);
		}

		// document.addEventListener("click", (e) => { handleClick(e, id) });
	}


	let classNames = `${styles.kanbanColumn} `;
	return (
		<div className={classNames} ref={wrapperRef} id={stage.id} >
			<KanbanHead stage={stage} count={template.allCards.filter(x => x.stageId === stage.id).length} />
			{template.allCards.length > 0 &&
                <Droppable droppableId={stage.id}>
                	{(provided, snapshot) => {
                		return (
                			<div {...provided.droppableProps} ref={provided.innerRef} className={styles.kanbanColumnBody}
                				style={{
                					backgroundColor: (snapshot.isDraggingOver && props.startColumn) ? ((stage.id === props.startColumn) ? "var(--color-gray-25)" : "var(--color-green-50)") : (props.startColumn) ? ((stage.id === props.startColumn) ? "var(--color-gray-25)" : "var(--color-indigo-50)") : "var(--color-gray-25)",
                					border: (snapshot.isDraggingOver && props.startColumn) ? ((stage.id === props.startColumn) ? "none" : "2px dashed var(--color-green-500)") : (props.startColumn) ? ((stage.id === props.startColumn) ? "none" : "2px dashed var(--color-indigo-600)") : "none"
                				}}
                				onScroll={trackScrolling}
                			>

                				{
                					template.allCards.filter(x => x.stageId === stage.id)?.map((card, index) => (
                						<Draggable key={card.id} draggableId={card.id} index={index} >
                							{(provided1) => {
                								let className = `${styles.card} `;

                								if (props.selectedId !== null && props.selectedId !== undefined) {
                									if (card.id !== props.selectedId && stage.id !== props.startColumn)
                										className += `${styles.cardNotVisible} `;
                								}

                								return (

                									<div
                										{...provided1.draggableProps}
                										{...provided1.dragHandleProps}
                										ref={provided1.innerRef}
                										className={className}
                										key={card.id}
                									>

                										<Card
                											data={card}
                											isSelected={card.id === props.selected}
                											onClick={() => { onClick(card.id); }}
                											count={props.count}
                										/>

                									</div>

                								);
                							}}
                						</Draggable>
                					))
                				}
                				{provided.placeholder}
                			</div>
                		);
                	}}
                </Droppable >}
		</div >
	);
});
export default Kanban;

