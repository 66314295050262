export const MB = 20;
export const TwentyMBInBytes = 20000000;
export const Timeout = 3500;
export const MaxColumnsCount = 10;
export const formats = [".csv", ".xlsx"];

export const xlsxFormat = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

export const HeadTable = 'Куда загрузить данные?'
export const LoadOrMoveFile = 'Загрузите файл или перетащите его сюда'
export const IfYouNeedBigFile = 'Если необходимо загрузить файл размером более 20 МБ, пожалуйста, обратитесь в поддержку'
export const MaxFileSize = 'Максимальный размер: ' + MB + 'МБ'
export const LoadFileCaption = 'Загрузить файл с компьютера'
export const ChooseAnotherFile = 'Выбрать другой файл'
export const MoveHere = 'Переместите файл сюда'

export const ChooseOnefileError = 'Выберите один файл'
export const BigFileSizeError = 'Размер файла не должен превышать 20 МБ'
export const IncorrectFormatError = 'Неподдерживаемый формат'

//toolTitles
export const Step1Tool = 'Выберите, куда импортировать данные, и загрузите файл'
export const Step2Tool = 'Настройте соответствие для обязательных колонок раздела'
export const Step3Tool = 'Выберите колонку дедубликации и действие при обнаружении дублей'

//функция получения формата файла из названия
export function ext(name: string) {
    return name.match(/\.([^.]+)$/)?.[1]
}

export interface ISelectItem {
    displayValue: string;
    value: any;
    columnType?: string;
    id?: string;
}

export enum ResultStates {
    Progress,
    Success,
    Failure,
    NotStarted
}

export interface IImportResult {
    state: ResultStates;
    percent: number;
    importedRow: number;
    rowCount: number;
}