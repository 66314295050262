enum FilterColumnType {
    Integer,
    Float,
    Boolean,
    String,
    DateTime,
    Time,
    Lookup,
    Guid,
    Date,
    Entity,
    Double,
    Owner,
    Stage,
    Amount,
    MobilePhone,
    Name,
}

export enum ColumnType {
    String = "String",
    Boolean = "Boolean",
    Integer = "Int32",
    Decimal = "Decimal",
    DateTime = "DateTime",
    Date = "Date",
    Time = "Time",
    Lookup = "Lookup",
    Double = "Double",
    Guid = "Guid",
    FieldGroup = "FieldGroup",
    Detail = "Detail",
    Numerator = "Numerator"
}


export default FilterColumnType;