import { EntityType } from "types/entity";

import { PersonIcon } from "shared";
import { Account } from "shared";
import { PersonWithCart } from "shared";
import { Cart } from "shared";

function getEntityIcons(name: string) {
	switch (name) {
		case EntityType.Contact:
			return <PersonIcon />;
		case EntityType.Account:
			return <Account />;
		case EntityType.Lead:
			return <PersonWithCart />;
		case EntityType.Sale:
			return <Cart />;
		default:
			return <></>;
	}
}

export default getEntityIcons;
