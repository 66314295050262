import { Icon } from "sale-bridge-ui-kit";

import styles from "../style.module.scss";

export function WithFilters() {
    return (
        <div className={styles.infoBlock}>
            <Icon name="PicturesNotFound" size="64" />
            <div className={styles.textContainer}>
                <span className={styles.mainText}>Ничего не найдено</span>
                <span className={styles.description}>Проверьте, нет ли опечатки в запросе или попробуйте уточнить его.</span>
            </div>
        </div>
    )
}