
export enum MenuHeaders {
    SectionSettings = "sectionSettings",
    StagesConstructor = "stagesConstructor",
    KanbanSettings = "kanbanSettings",
    Automatization = "automatization",
}

export const Menu = [
    {
        id: MenuHeaders.SectionSettings,
        name: "Настройка раздела"
    },
    {
        id: MenuHeaders.StagesConstructor,
        name: "Конструктор стадий"
    },
    {
        id: MenuHeaders.KanbanSettings,
        name: "Настройки доски kanban"
    },
    {
        id: MenuHeaders.Automatization,
        name: "Автоматизация"
    },
];

