import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";

import { Button, ButtonStyle, Input, InputSearch, InputStyleName, SelectStyleName, SelectStyles } from "components";

import { Item } from "types";
import { ISelectProps } from "../types";

import { ArrowToDown, Cross } from "shared";

import stylesBase from "../select.module.css";
import styles from "./search-select.module.css";

function SearchSelect(props: ISelectProps) {
	const [isOpened, setOpened] = useState(false);
	const [noMatches, setNoMatches] = useState(false);
	const [ulElement, setUlElement] = useState<HTMLElement>();
	const [liSelected, setLiSelected] = useState<HTMLLIElement>();
	const [isTab, setIsTab] = useState(false);

	let index = useRef<number>(-1);
	const [searchValue, setSearchValue] = useState("");
	const wrapperRef = useRef<HTMLDivElement>(null);
	const inputElement = useRef<HTMLDivElement>(null);

	const selectStyle = SelectStyles[props.selectStyle ?? SelectStyleName.Base];
	const inputStyle = props.inputStyle ?? InputStyleName.BaseWithoutBorder;

	const clearButtonStyle = useMemo(() => {
		return props.isListDelay ? { marginRight: `10px` } : {};
	}, [props.isListDelay]);
	const clearButtonVisible = useMemo(() => {
		return props.value ? true : false;
	}, [props.value]);
	const firstIcon = useMemo(() => {
		if (props.iconOpened && isOpened) return props.iconOpened;
		else return props.firstIcon;
	}, [props.iconOpened, props.firstIcon]);

	const wrapperClassNames = classNames({
		[`${selectStyle.classNames} `]: true,
		[`${selectStyle.classNames} ${props.className} `]: props.className
	});
	const listClassNames = classNames({
		[`${selectStyle.list} `]: true,
		[`${props.classNameList} `]: props.classNameList,
		[`${styles.visible} `]: isOpened
	});
	const wrapperInputClassNames = classNames({
		[`${selectStyle.input} `]: true,
		[`${props.classNameInput} `]: props.classNameInput,
		[`${selectStyle.disabledInput} `]: props.isDisabled,
		[`${selectStyle.invalid} `]: props.isInvalid && !isOpened,
		[`${selectStyle.focusInput} `]: isOpened,
		[`${props.classNameOpenList} `]: props.classNameOpenList && isOpened
	});
	const inputClassNames = classNames({
		[`${stylesBase.inputSelect}`]: true,
		[`${props.classNameInput}`]: props.classNameInput
	});
	const buttonClassNames = classNames({
		[`${props.classNameButton} `]: props.classNameButton,
		[`${props.classNameOpenList} `]: props.classNameOpenList && isOpened
	});
	const firstIconClassNames = classNames({
		[`${styles.iconButton} `]: true,
		[`${styles.close} `]: props.isRotateFirstIcon && isOpened,
		[`${props.classNameIcon} `]: props.classNameIcon
	});
	const secondIconClassNames = classNames({
		[`${styles.iconButton} `]: true,
		[`${styles.close} `]: props.isRotateSecondIcon && isOpened
	});
	const dropdownClassNames = classNames({
		[`${stylesBase.selectButton} `]: true,
		[`${stylesBase.close}`]: isOpened
	});

	let error = null;
	if (props.isInvalid === true && !isOpened) {
		if (props.invalidMessage !== undefined && props.invalidMessage.length > 1) {
			error = <div className={selectStyle.errorMessage}>{props.invalidMessage}</div>;
		}
	}

	const hideMenu = useCallback(() => {
		setSearchValue("");
		setOpened(false);
		if (props.onFocusOut) props.onFocusOut();
		setLiSelected(undefined);
	}, [props.onFocusOut, isOpened, searchValue]);

	useEffect(() => {
		window.addEventListener("scroll", hideMenu);
		return () => {
			window.removeEventListener("scroll", hideMenu);
		};
	}, []);

	const getInputValue = useMemo(() => {
		return props.value
			? props.value.title
				? props.value.title
				: props.value.displayValue
				? props.value.displayValue
				: props.value.name
			: "";
	}, [props.value]);

	const search = useCallback(
		(value: string) => {
			setSearchValue(value);
			if (props.onItemsLoad !== undefined) {
				props.onItemsLoad(value).then(function (response: any) {
					const count = response;
					if (value.length > 0 && count == 0) {
						setNoMatches(true);
					} else {
						setNoMatches(false);
					}
				});
			}
		},
		[props.onItemsLoad, noMatches, searchValue]
	);

	const handleClick = useCallback(
		(event: Event) => {
			if (wrapperRef.current != null && !wrapperRef.current.contains(event.target as Node)) {
				hideMenu();
			}
		},
		[wrapperRef.current]
	);

	const openMenu = useCallback(async () => {
		if (props.isDisabled) return;
		if (props.onItemsLoad !== undefined) {
			props.onItemsLoad(null);
		}
		setOpened(true);
	}, [props.isDisabled, props.onItemsLoad, props.items]);

	const onClick = useCallback(() => {
		if (props.isDisabled) return;
		setIsTab(false);
		if (!isOpened) {
			index.current = -1;
			openMenu();
			document.addEventListener("click", handleClick);
		} else {
			hideMenu();
			document.removeEventListener("click", handleClick);
		}
	}, [props.isDisabled, props.onItemsLoad, isOpened]);

	const selectItem = useCallback(
		(item: Item | null) => {
			if (!item?.isDisabled && !item?.isLocked) {
				props.onChangeValue(item);
				hideMenu();
			}
		},
		[props.onChangeValue]
	);

	const keyup = useCallback(
		(event: React.KeyboardEvent<HTMLDivElement>) => {
			if (props.isDisabled) return;
			if (event.key === "Tab") {
				setIsTab(true);
				if (isOpened) {
					hideMenu();
					document.removeEventListener("click", handleClick);
				} else openMenu();
			} else {
				setIsTab(false);
				if (event.key === "Enter") {
					if (liSelected) {
						props.items.forEach((item) => {
							if (liSelected.id.toString() === item.id.toString()) selectItem(item);
						});
					}
				}
			}
		},
		[props.isDisabled, isOpened, liSelected, selectItem, hideMenu, openMenu]
	);

	const itemKeydown = useCallback(
		(event: React.KeyboardEvent<HTMLUListElement>) => {
			if (ulElement) {
				const maxLength = ulElement.getElementsByTagName("li").length;
				const firstElementFromList = ulElement.getElementsByTagName("li")[0];
				let nextElementFromList = null;
				if (event.key === "ArrowDown") {
					index.current = index.current + 1;
					if (liSelected) {
						nextElementFromList = ulElement.getElementsByTagName("li")[index.current];
						if (typeof nextElementFromList && index.current <= maxLength - 1!) {
							if (
								Math.round(nextElementFromList.offsetTop + nextElementFromList.offsetHeight) >=
								ulElement?.clientHeight + ulElement?.scrollTop
							)
								ulElement?.scrollBy({ top: nextElementFromList.offsetHeight });
							setLiSelected(nextElementFromList);
						} else {
							ulElement?.scrollTo(0, 0);
							index.current = 0;
							setLiSelected(firstElementFromList);
						}
					} else {
						setLiSelected(firstElementFromList);
					}
				}

				if (event.key === "ArrowUp") {
					index.current = index.current - 1;
					if (liSelected) {
						nextElementFromList = ulElement!.getElementsByTagName("li")[index.current];

						if (typeof nextElementFromList !== undefined && index.current >= 0) {
							if (nextElementFromList.offsetTop <= ulElement?.scrollTop)
								ulElement?.scrollBy({ top: -nextElementFromList.offsetHeight });
							setLiSelected(nextElementFromList);
						} else {
							ulElement?.scrollTo(0, ulElement.scrollHeight);
							index.current = maxLength - 1;
							setLiSelected(ulElement.getElementsByTagName("li")[index.current]);
						}
					} else {
						index.current = maxLength - 1;
						setLiSelected(ulElement.getElementsByTagName("li")[maxLength - 1]);
						ulElement?.scrollTo(0, ulElement.scrollHeight);
					}
				}
			}
		},
		[ulElement, liSelected, index.current]
	);

	const setPositionsContextMenu = useCallback(
		(element: HTMLUListElement) => {
			if (element && wrapperRef.current !== null) {
				const position = element.getBoundingClientRect();
				if (!props.notTranslate) {
					let winh = window.innerHeight;
					let raz = winh - position.bottom - 15;
					if (position.bottom > winh) {
						element.style.transform =
							"translate(" + wrapperRef.current!.children?.item(0)!.getBoundingClientRect().width + "px, " + raz + "px)";
					}
					if (position.right > window.innerWidth) {
						element.style.left = -position.width + wrapperRef.current?.getBoundingClientRect().width! + "px";
					}
				}
				if (inputElement.current) {
					const wrapperPosition = inputElement.current.getBoundingClientRect();
					let minWidth = wrapperPosition.width;
					element.style.minWidth = minWidth + "px";
				}
			}
			setUlElement(element);
		},
		[wrapperRef.current, props.notTranslate, inputElement.current]
	);

	//TODO возможно понадобится проверка isTab далее в этом месте
	const handleOnBlur = useCallback(
		(event: React.FocusEvent<HTMLDivElement, Element>) => {
			// if (isTab)
			if (event.relatedTarget) {
				if (!event.currentTarget.contains(event.relatedTarget)) {
					hideMenu();
				}
			}
		},
		[hideMenu]
	);

	const listItem = useCallback(
		(item: Item) => {
			return (
				<>
					{item.icon && <div className={styles.icon}>{item.icon}</div>}
					<div className={styles.listItemCaption}>
						<span className={styles.listItemName}>
							{item.title ? item.title : item.displayValue ? item.displayValue : item.name}
						</span>
						{item.note && <span className={styles.listItemNote}>{item.note}</span>}
					</div>
				</>
			);
		},
		[props.items]
	);

	const mappingList = useMemo(() => {
		return (
			props.items !== undefined &&
			props.items.map((item, i) => {
				const listItemClassNames = classNames(styles.listItem, {
					[`${styles.listItemDisabled} `]: item.isDisabled || item.isLocked,
					[`${styles.exit} `]: item.isRed,
					[`${item.classNames} `]: item.classNames,
					[`${styles.choose} `]: liSelected && liSelected.id.toString() === item.id.toString()
				});

				return (
					<li key={`${item.id}`} id={`${item.id}`} className={listItemClassNames} onClick={() => selectItem(item)}>
						{listItem(item)}
					</li>
				);
			})
		);
	}, [props.items, listItem, selectItem, liSelected]);

	return (
		<div ref={wrapperRef} className={wrapperClassNames} onKeyUp={keyup} onKeyDown={keyup} onBlur={handleOnBlur}>
			{props.isInput ? (
				<div ref={inputElement} className={wrapperInputClassNames}>
					<Input
						key={"searchSelectInput"}
						tabIndex={-1}
						value={getInputValue}
						placeholder={props.placeholder ?? "Выберите значение..."}
						onChangeValue={() => {}}
						onClick={onClick}
						className={inputClassNames}
						isInvalid={props.isInvalid}
						isLightning={props.isLightning}
						inputStyle={inputStyle}
					/>
					{isOpened && (
						<Button
							onClick={(e) => {
								props.onChangeValue(null);
							}}
							firstIcon={<Cross />}
							style={ButtonStyle.Icon}
							className={styles.clearButton}
							styleButton={clearButtonStyle}
							isVisible={clearButtonVisible}
						/>
					)}

					{!props.isListDelay && (
						<Button firstIcon={<ArrowToDown />} style={ButtonStyle.Icon} className={dropdownClassNames} onClick={onClick} />
					)}
				</div>
			) : (
				<Button
					classNameFirstIcon={firstIconClassNames}
					classNameSecondIcon={secondIconClassNames}
					className={buttonClassNames}
					firstIcon={firstIcon}
					secondIcon={props.secondIcon}
					caption={props.value || props.value ? props.value.name : props.name}
					iconOnRight={props.isRightIcon}
					style={props.styles}
					onClick={onClick}
					onFocus={props.onFocus}
					onBlur={props.onBlur}
					selected={isOpened}
				/>
			)}
			<ul className={listClassNames} ref={setPositionsContextMenu} onKeyDown={itemKeydown}>
				<InputSearch
					key={"InputSearch"}
					value={searchValue}
					placeholder={"Поиск"}
					onChangeValue={search}
					style={{ marginBottom: `5px` }}
					focus={isOpened}
				/>
				{mappingList}

				{(noMatches || props.items === undefined) && <span className={styles.noMatches}>Совпадений не найдено</span>}
			</ul>
			{error}
		</div>
	);
}

export default SearchSelect;
