export enum EntityType {
    LINK = 'LINK',
}

export enum InlineStyle {
    BOLD = 'BOLD',
    ITALIC = 'ITALIC',
    UNDERLINE = 'UNDERLINE',
    STRIKETHROUGH = 'STRIKETHROUGH',

    GRAYBLUE900 = 'GRAYBLUE900',
    GRAYBLUE500 = 'GRAYBLUE500',
    WHITE = 'WHITE',
    BLUE900 = 'BLUE900',
    BLUE500 = 'BLUE500',
    BLUE100 = 'BLUE100',
    CYAN900 = 'CYAN900',
    CYAN500 = 'CYAN500',
    CYAN100 = 'CYAN100',
    GREEN900 = 'GREEN900',
    GREEN500 = 'GREEN500',
    GREEN100 = 'GREEN100',
    ORANGE900 = 'ORANGE900',
    ORANGE500 = 'ORANGE500',
    ORANGE100 = 'ORANGE100',
    RED900 = 'RED900',
    RED500 = 'RED500',
    RED100 = 'RED100',
    PURPLE900 = 'PURPLE900',
    PURPLE500 = 'PURPLE500',
    PURPLE100 = 'PURPLE100',

    INDIGO600 = 'INDIGO600',
}

export enum TextStyles {
    BOLD = 'BOLD',
    ITALIC = 'ITALIC',
    UNDERLINE = 'UNDERLINE',
    STRIKETHROUGH = 'STRIKETHROUGH',
}

export const mappingColors = new Map<InlineStyle, string>();
mappingColors.set(InlineStyle.GRAYBLUE900, 'var(--color-grayBlue-900)');
mappingColors.set(InlineStyle.BLUE900, 'var(--color-textEditor-blue-900)');
mappingColors.set(InlineStyle.CYAN900, 'var(--color-textEditor-cyan-900)');
mappingColors.set(InlineStyle.GREEN900, 'var(--color-textEditor-green-900)');
mappingColors.set(InlineStyle.ORANGE900, 'var(--color-textEditor-orange-900)');
mappingColors.set(InlineStyle.RED900, 'var(--color-textEditor-red-900)');
mappingColors.set(InlineStyle.PURPLE900, 'var(--color-textEditor-purple-900)');
mappingColors.set(InlineStyle.GRAYBLUE500, 'var(--color-grayBlue-500)');
mappingColors.set(InlineStyle.BLUE500, 'var(--color-textEditor-blue-500)');
mappingColors.set(InlineStyle.CYAN500, 'var(--color-textEditor-cyan-500)');
mappingColors.set(InlineStyle.GREEN500, 'var(--color-textEditor-green-500)');
mappingColors.set(InlineStyle.ORANGE500, 'var(--color-textEditor-orange-500)');
mappingColors.set(InlineStyle.RED500, 'var(--color-textEditor-red-500)');
mappingColors.set(InlineStyle.PURPLE500, 'var(--color-textEditor-purple-500)');
mappingColors.set(InlineStyle.WHITE, 'var(--color-white)');
mappingColors.set(InlineStyle.BLUE100, 'var(--color-textEditor-blue-100)');
mappingColors.set(InlineStyle.CYAN100, 'var(--color-textEditor-cyan-100)');
mappingColors.set(InlineStyle.GREEN100, 'var(--color-textEditor-green-100)');
mappingColors.set(InlineStyle.ORANGE100, 'var(--color-textEditor-orange-100)');
mappingColors.set(InlineStyle.RED100, 'var(--color-textEditor-red-100)');
mappingColors.set(InlineStyle.PURPLE100, 'var(--color-textEditor-purple-100)');

export const CUSTOM_STYLE_MAP = {
    [InlineStyle.GRAYBLUE900]: {
        color: 'var(--color-grayBlue-900)',
    },
    [InlineStyle.GRAYBLUE500]: {
        color: 'var(--color-grayBlue-500)',
    },
    [InlineStyle.WHITE]: {
        color: 'var(--color-white)',
    },
    [InlineStyle.BLUE900]: {
        color: 'var(--color-textEditor-blue-900)',
    },
    [InlineStyle.BLUE500]: {
        color: 'var(--color-textEditor-blue-500)',
    },
    [InlineStyle.BLUE100]: {
        color: 'var(--color-textEditor-blue-100)',
    },
    [InlineStyle.CYAN900]: {
        color: 'var(--color-textEditor-cyan-900)',
    },
    [InlineStyle.CYAN500]: {
        color: 'var(--color-textEditor-cyan-500)',
    },
    [InlineStyle.CYAN100]: {
        color: 'var(--color-textEditor-cyan-100)',
    },
    [InlineStyle.GREEN900]: {
        color: 'var(--color-textEditor-green-900)',
    },
    [InlineStyle.GREEN500]: {
        color: 'var(--color-textEditor-green-500)',
    },
    [InlineStyle.GREEN100]: {
        color: 'var(--color-textEditor-green-100)',
    },
    [InlineStyle.ORANGE900]: {
        color: 'var(--color-textEditor-orange-900)',
    },
    [InlineStyle.ORANGE500]: {
        color: 'var(--color-textEditor-orange-500)',
    },
    [InlineStyle.ORANGE100]: {
        color: 'var(--color-textEditor-orange-100)',
    },
    [InlineStyle.RED900]: {
        color: 'var(--color-textEditor-red-900)',
    },
    [InlineStyle.RED500]: {
        color: 'var(--color-textEditor-red-500)',
    },
    [InlineStyle.RED100]: {
        color: 'var(--color-textEditor-red-100)',
    },
    [InlineStyle.PURPLE900]: {
        color: 'var(--color-textEditor-purple-900)',
    },
    [InlineStyle.PURPLE500]: {
        color: 'var(--color-textEditor-purple-500)',
    },
    [InlineStyle.PURPLE100]: {
        color: 'var(--color-textEditor-purple-100)',
    },
    [InlineStyle.INDIGO600]: {
        color: 'var(--color-indigo-600)',
    },

};
