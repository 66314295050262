import { useState } from "react";
import { observer } from "mobx-react-lite";

import { FlexColumn, Info, FlexRow, Button, ButtonStyle } from "components"
import DataBaseStoreModal from "./ui/DataBaseStoreModal";

import cloudStoreService from "../../core/CloudStoreService";
import { LoadStatus } from "../cloudStore/CloudStore";
import dataBaseStore from "../../core/DataBaseStoreService";

function DataBaseStore(props: { isActive: boolean }) {
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const store = dataBaseStore;

    const storeS3Loading = cloudStoreService.loadResult;

    function modalOpen() {
        setModalOpen(true);
    }

    function modalClose() {
        setModalOpen(false);
    }

    return (
        <FlexColumn gap={10}>
            <Info title={"Дополнительная информация"} text={"Хранение файлов в базе данных системы. Все новые загружаемые файлы будут храниться в данном хранилище."} />
            {
                storeS3Loading.state === 0 &&
                <LoadStatus percent={storeS3Loading.percent} />
            }
            {!props.isActive &&
                <FlexRow gap={10}>
                    <Button caption={"Переключиться на это хранилище"} style={ButtonStyle.Primary} onClick={modalOpen} />
                </FlexRow>
            }
            <DataBaseStoreModal isOpen={isModalOpen} onClose={modalClose} onSwithStore={(val) => store.swithStore(val)} />
        </FlexColumn>
    )
}

export default observer(DataBaseStore);