import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { action, toJS } from "mobx";
import { v4 } from "uuid";
import classNames from "classnames";

import { dispatcher, store, selector } from "store";
import { synchroiser } from "synchroiser";
import { singlePageSynchroiser } from "pages/single-page/single-page-synchroiser/single-page-synchroiser";

import NewFilter from "./new-filter/new-filter";

import SavedFilter from "entities/filter/SavedFilter";
import { Position, modalController } from "features/modals";
import RightExpression from "features/advanced-filter/filter-conditions/right-expression/right-expression";
import { AdvancedFilterConst } from "features/section-head/data/constants";

import { Button, ButtonStyle } from "components";
import { Button as ButtonUikit } from "sale-bridge-ui-kit";
import { Field } from "sale-bridge-ui-kit";
import { Icon } from "sale-bridge-ui-kit";
import { IFilterStore } from "entities/filter/FilterStore";
import { ComparisonType } from "entities/filter/IFilter";
import { Item } from "types";
import { LoadingState } from "types/entity";
import { getIconUikit } from "../data/IconSetting";

import { ArrowToDown } from "shared";

import styles from "./SimpleFilter.module.scss";
import stylesBase from "components/select/select.module.css";

function SimpleFilter(props: { size: "small" | "medium" | "large" }) {
	const entityName = useParams().entityName;
	const [idModal] = useState<string>(v4());
	const [idNotification] = useState<string>(v4());
	const [showBody, setShowBody] = useState(false);
	const [filter, setFilter] = useState<SavedFilter | null>(null);
	const wrapperRef = useRef<HTMLDivElement>(null);
	const infoLabelText = "Перейдите в расширенный фильтр, чтобы настроить фильтрацию по нескольким колонкам, используя условия И и ИЛИ.";

	const visibleSaveAsNew = useMemo(
		() => filter !== null && filter.id !== null && filter.hasChanges,
		[filter, filter?.id, filter?.hasChanges]
	);

	const isFavorite = useMemo(
		() => dispatcher.entity.get()?.entity.filter?.savedFilter?.isFavorite,
		[dispatcher.entity.get()?.entity.filter?.savedFilter?.isFavorite]
	);

	const placeholderClassNames = classNames(styles.placeholder, {
		[`${styles.placeholder} ${styles.apply}`]: dispatcher.entity.get()?.entity.filter?.placeholder
	});

	const selectButtonClassName = classNames(stylesBase.selectButton, {
		[`${stylesBase.selectButton} ${stylesBase.close}`]: showBody
	});

	const bodyClassNames = classNames(styles.body, {
		[`${styles.visible}`]: showBody
	});

	const selectBlockTopClassName = classNames(styles.selectBlockTop, {
		[`${styles.selectBlockTop} ${styles.withoutFooter}`]:
			filter &&
			filter.filterInfo!.filters.length > 0 &&
			filter.filterInfo?.filters[0].comparisonType !== null &&
			(filter.filterInfo?.filters[0].comparisonType == ComparisonType.IsNotNull ||
				filter.filterInfo?.filters[0].comparisonType == ComparisonType.IsNull)
	});

	const resetSavedFilter = useCallback(() => {
		if (filter && filter.id !== null) {
			filter.setValue(null, "id");
			filter.setValue(null, "filterName");
			filter.setValue(false, "isFavorite");
		}
	}, [filter]);

	const closeConfirm = useCallback((e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
		e?.stopPropagation();
		modalController.modalRemove(idModal);
	}, []);

	const addNotification = useCallback(() => {
		if (synchroiser.loadingState === LoadingState.Error && synchroiser.errorMessage) {
			const layoutNotificationWithError = <div className={styles.errorNotification}>{synchroiser.errorMessage}</div>;

			modalController.notificationAdd({
				id: idNotification,
				position: Position.CENTER,
				layout: layoutNotificationWithError,
				allowDefaultClick: true,
				allowTimer: true
			});

			const entity = dispatcher.entity.get();
			if (entity) {
				const savedFilter = new SavedFilter(entity.entityName);
				savedFilter.deserialize(entity.entity.filter?.savedFilter as SavedFilter);
				setFilter(savedFilter);
			}
		}
	}, [synchroiser.errorMessage, idNotification]);

	const hendleClearPlaceholderClick = useCallback(
		async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			event.stopPropagation();
			hideMenu();
			dispatcher.filter.setSavedFilter(null);
			dispatcher.filter.setStaticGroup(null);
			dispatcher.filter.setPlaceholder();
			dispatcher.entity.onChangeCheckedAll(false);
			await singlePageSynchroiser.applyFilter(entityName ?? "");
			addNotification();
		},
		[entityName, addNotification]
	);

	const onKeyUp = useCallback(
		(event: KeyboardEvent) => {
			if (showBody && !store.modals.find(({ id }) => id === id)) {
				if (event.key === "Enter") {
					applyFilter();
				} else if (event.key === "Escape") {
					hideMenu();
				}
			}
		},
		[showBody, toJS(store.modals)]
	);

	useEffect(() => {
		const entity = dispatcher.entity.get();
		if (entity) {
			if (entity.entity.filter?.savedFilter) {
				const savedFilter = new SavedFilter(entity.entityName);
				savedFilter.deserialize(entity.entity.filter?.savedFilter);
				setFilter(savedFilter);
			} else {
				setFilter(selector.filter.getNewSavedFilter());
			}
		}
	}, [dispatcher.entity.get(), dispatcher.entity.get()?.entity.filter?.savedFilter]);

	useEffect(() => {
		if (showBody && !store.modals.find(({ id }) => id === id)) {
			document.body.addEventListener("keypress", onKeyUp);
			return () => {
				document.body.removeEventListener("keypress", onKeyUp);
			};
		}
	}, [onKeyUp]);

	useEffect(() => {
		if (!store.modals.find(({ id }) => id === idNotification)) {
			action("loading successful", () => {
				synchroiser.loadingState = LoadingState.NotAsked;
				synchroiser.errorMessage = null;
			})();
		}
	}, [store.modals.length]);

	function hideMenu() {
		setShowBody(false);
		document.removeEventListener("mousedown", handleClick);
	}

	const handleClick = action((event: Event) => {
		if (wrapperRef.current === null) {
			return;
		}
		if (!wrapperRef.current.contains(event.target as Node) && filter) {
			hideMenu();
			if (filter.id) {
				filter.cancel();
			} else if (selector.filter.getFilter()?.savedFilter) {
				filter.deserialize(selector.filter.getFilter()?.savedFilter as SavedFilter);
			} else {
				setFilter(selector.filter.getNewSavedFilter());
			}
		} else if (wrapperRef.current.contains(event.target as Node) && (event.target as Node).textContent === "Добавить поле") {
			document.removeEventListener("mousedown", handleClick);
		}
	});

	function onSelectOpen() {
		if (filter?.filterInfo?.filters.length === 0) {
			filter.filterInfo.addFilter();
		}
		setShowBody(true);
		document.addEventListener("mousedown", handleClick);
	}

	const addCondition = useCallback(() => {
		hideMenu();
		const advancedFilter = new SavedFilter(filter?.entityName!);
		advancedFilter.deserialize(filter as SavedFilter);
		advancedFilter.setValue(filter!.filterInfo, "filterInfo");
		filter!.cancel();

		advancedFilter.setValue(false, "noNeedReaction");
		advancedFilter.setValue(true, "hasChanges");
		advancedFilter.filterInfo?.addFilter();
		AdvancedFilterConst.setAdvancedFilter(advancedFilter);
		AdvancedFilterConst.setOpenAdvancedFilterTree(true);
	}, [filter]);

	const applyFilter = useCallback(async () => {
		if (filter?.filterInfo?.filters[0].isValid) {
			hideMenu();
			if (filter?.id && !filter.hasChanges) {
				await synchroiser.getFilter(filter?.id);
				addNotification();
				return;
			}
			resetSavedFilter();
			dispatcher.filter.setSavedFilter(filter);
			singlePageSynchroiser.applyFilter(dispatcher.entity.get()?.entityName ?? "");
			addNotification();
		}
	}, [filter, filter?.id, filter?.hasChanges, addNotification, resetSavedFilter]);

	const save = useCallback(
		async (filterName: string | null) => {
			if (filter?.filterInfo?.filters[0].isValid && filterName) {
				if (filter.id == null || filterName) {
					filter.setValue(filterName, "filterName");
				}
				hideMenu();
				await filter.save().then(async () => {
					dispatcher.filter.setSavedFilter(filter);
					await synchroiser.getEntityWithFilter();
					await synchroiser.getSavedFilterFolderTree();
				});

				addNotification();
			}
		},
		[filter, addNotification]
	);

	const addPopup = useCallback(() => {
		resetSavedFilter();
		modalController.popupAdd({
			id: idModal,
			layout: <NewFilter onSave={save} close={closeConfirm} />,
			closeFunc: closeConfirm
		});
	}, [idModal, save, resetSavedFilter, closeConfirm]);

	const saveOrUpdate = useCallback(async () => {
		if (filter && filter.id !== null) {
			hideMenu();
			await filter.update(false);
			await singlePageSynchroiser.applyFilter(entityName ?? "");
			addNotification();
		} else {
			addPopup();
		}
	}, [filter, entityName, addNotification, addPopup]);

	const addToFavorite = useCallback(async () => {
		if (filter) {
			filter.disposeAutorun();
			filter.setValue(!filter.isFavorite, "isFavorite");
			if (filter.id !== null) {
				await filter.update(true);
				addNotification();
			} else {
				addPopup();
			}
		}
	}, [filter]);

	const onButtonClick = useCallback(() => {
		if (!filter || !filter.filterInfo) {
			return;
		}
		if (filter.filterInfo.filters.length < 2) {
			if (showBody) {
				hideMenu();
			} else {
				onSelectOpen();
			}
		} else {
			const advancedFilter = new SavedFilter(filter.entityName!);
			advancedFilter.deserialize(filter);
			AdvancedFilterConst.setAdvancedFilter(advancedFilter);
			AdvancedFilterConst.setOpenAdvancedFilterTree(true);
		}
	}, [filter, showBody]);

	//TODO разблокировать, когда статические группы переедут на новый store
	if (selector.filter.getFilter()?.staticGroup) {
		return (
			<div tabIndex={0} className={`${styles.placeholder} ${styles.apply}` /**placeholderClassNames */}>
				<ApplyPlaceholder onClearClick={hendleClearPlaceholderClick} size={props.size} />
			</div>
		);
	}
	const buttonLabel = classNames(styles.buttonLabel, {
		[styles[`buttonLabel${props.size}`]]: props.size
	});

	const placeholderLabel = classNames(styles.placeholderLabel, {
		[styles[`placeholderLabel${props.size}`]]: props.size
	});

	const infoLabelTextStyle = classNames(styles.infoLabelText, {
		[styles[`placeholderLabel${props.size}`]]: props.size
	});

	return (
		<div ref={wrapperRef} className={styles.wrapper}>
			{selector.filter.getFilter()?.placeholder ? (
				<div tabIndex={0} className={buttonLabel} onClick={onButtonClick}>
					<ApplyPlaceholder onClearClick={hendleClearPlaceholderClick} size={props.size} />
				</div>
			) : (
				<>
					<div tabIndex={0} className={placeholderClassNames} onClick={onButtonClick}>
						<span className={placeholderLabel}>Быстрый фильтр</span>
						<Button
							onClick={onButtonClick}
							firstIcon={<ArrowToDown />}
							style={ButtonStyle.Icon}
							className={selectButtonClassName}
						/>
					</div>
				</>
			)}
			{filter?.filterInfo?.filters[0] && (
				<div className={bodyClassNames}>
					<div className={styles.selectBlock}>
						<div className={selectBlockTopClassName}>
							<div className={styles.selectPropertyBlock}>
								<SelectProperty filter={filter!.filterInfo!.filters[0]} size={props.size} />
							</div>
							<div className={styles.comparisons}>
								<Comparisons templateConditions={filter!.filterInfo!.filters[0]} size={props.size} />
							</div>
						</div>
						{!(
							filter.filterInfo?.filters[0].comparisonType == ComparisonType.IsNotNull ||
							filter.filterInfo?.filters[0].comparisonType == ComparisonType.IsNull
						) && (
							<RightExpression
								isDisabled={
									!filter.filterInfo?.filters[0].property.propertyValue.value ||
									!filter.filterInfo?.filters[0].comparisonType
								}
								filter={filter.filterInfo!.filters[0]}
								size={props.size}
							/>
						)}
						<div>
							<ButtonUikit
								text="Перейти в расширенный фильтр"
								size={props.size}
								variant="default"
								border
								link
								loading={false}
								onClick={addCondition}
							/>
							<div className={infoLabelTextStyle}>{infoLabelText}</div>
						</div>
					</div>

					<div className={styles.simpleFilterFooter}>
						<ButtonUikit
							text="Применить"
							size={props.size}
							variant={!filter.filterInfo!.filters[0].isValid ? "disabled" : "secondary"}
							leftIcon="KeyboardEnter"
							border={false}
							link={false}
							loading={false}
							onClick={applyFilter}
						/>
						<div className={styles.footerRight}>
							<ButtonUikit
								text={filter.filterInfo && filter.id !== null ? "Обновить" : "Сохранить"}
								size={props.size}
								variant={!filter.filterInfo!.filters[0].isValid || !filter.hasChanges ? "disabled" : "secondary"}
								leftIcon="Save"
								border={false}
								link={false}
								loading={false}
								onClick={saveOrUpdate}
							/>
							<div className={styles.stroke} />
							<ButtonUikit
								text={isFavorite ? "Убрать из Избранного" : "Добавить в Избранное"}
								size={props.size}
								variant={!filter.filterInfo!.filters[0].isValid ? "disabled" : "secondary"}
								leftIcon="EmptyStar"
								border={false}
								link={false}
								loading={false}
								onClick={addToFavorite}
							/>
							{visibleSaveAsNew && (
								<ButtonUikit
									text={"Сохранить как новый"}
									size={props.size}
									variant={!filter.filterInfo!.filters[0].isValid ? "disabled" : "secondary"}
									leftIcon="EmptyStar"
									border={false}
									link={false}
									loading={false}
									onClick={addPopup}
								/>
							)}
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

export default observer(SimpleFilter);

const Comparisons = observer((props: { templateConditions: IFilterStore; size: "small" | "medium" | "large" }) => {
	const [searchValue, setSearchValue] = useState("");
	let comparisonItems = props.templateConditions.comparators;
	let value = props.templateConditions.comparisonType;

	const itemStyle = classNames(styles.item, {
		[styles[`item${props.size}`]]: props.size
	});

	const handleChange = useCallback(
		(value: Item | null) => {
			props.templateConditions.selectComparator(value);
		},
		[comparisonItems]
	);

	function getComparatorDisplayValue() {
		const comparator = comparisonItems.find((item) => item.id === value);
		return comparator;
	}

	const dropdownItems = useMemo(() => {
		return [
			{
				layout: <></>,
				items: comparisonItems.map((item) => (
					<div key={item.id} className={itemStyle} onClick={() => handleChange(item)}>
						<span>{item.name}</span>
					</div>
				))
			}
		];
	}, [toJS(comparisonItems)]);

	return (
		<Field
			type="select"
			size={props.size}
			label="Операция"
			placeholder={props.templateConditions.property.propertyValue.value ? "" : "Выберите колонку"}
			labelPosition="vertical"
			textVariant="outlined"
			isRequired
			value={getComparatorDisplayValue()?.name ?? ""}
			onChange={() => {}}
			items={dropdownItems}
			isDisabled={!props.templateConditions.property.propertyValue.value}
			searchValue={searchValue} //TODO чтобы заработал поиск нужно в items сделать filter по searchValue
			onChangeSearchValue={setSearchValue}
		/>
	);
});

const SelectProperty = observer((props: { filter: IFilterStore; size: "small" | "medium" | "large" }) => {
	const [searchValue, setSearchValue] = useState("");

	const startValue = props.filter.property.propertyValue.value;
	const property = props.filter.property;
	const propertyItems = property.items;

	const itemStyle = classNames(styles.item, {
		[styles[`item${props.size}`]]: props.size
	});

	const handleChange = useCallback(
		(value: Item | null) => {
			props.filter.selectAttributeOrDetail(value ? propertyItems.find((item) => item.name === value!.name)! : null);
			if (!value && props.filter.property.propertyValue.value) {
				props.filter.property.propertyValue.value.displayValue = "";
			}
		},
		[toJS(propertyItems), props.filter.property.propertyValue.value]
	);

	const dropdownItems = useMemo(() => {
		return [
			{
				layout: <></>,
				items: property.items
					.filter((item) => item.displayValue?.toLowerCase().includes(searchValue?.toLowerCase()))
					.map((item) => (
						<div key={item.id} className={itemStyle} onClick={() => handleChange(item)}>
							<div className={styles.iconElement}>
								{getIconUikit(property.proprtiesInfo[property.items.indexOf(item)]?.type, props.size)}
							</div>
							<span>{item.displayValue}</span>
						</div>
					))
			}
		];
	}, [toJS(property.items), searchValue, props.size]);

	const handleFieldChange = () => {
		handleChange(null);
	};

	return (
		<Field
			type="select"
			size={props.size}
			label="Название колонки"
			placeholder="Выберите колонку"
			labelPosition="vertical"
			textVariant="outlined"
			isRequired
			isClearing
			value={startValue?.displayValue ?? ""}
			onChange={handleFieldChange}
			items={dropdownItems}
			searchValue={searchValue}
			onChangeSearchValue={setSearchValue}
		/>
	);
});

const ApplyPlaceholder = observer(
	(props: { onClearClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void; size: "small" | "medium" | "large" }) => {
		const buttonLabel = classNames(styles.buttonLabel, {
			[styles[`buttonLabel${props.size}`]]: props.size
		});

		return (
			<>
				<span className={styles.tooltip}>{dispatcher.entity.get()?.entity.filter?.placeholder}</span>
				<span className={buttonLabel}>{dispatcher.entity.get()?.entity.filter?.placeholder}</span>

				<Button
					onClick={props.onClearClick}
					firstIcon={<Icon name="Clear" size={props.size} className={styles.clearButton} />}
					style={ButtonStyle.Icon}
				/>
			</>
		);
	}
);
