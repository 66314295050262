import { EditorApi, useEditor } from './use-editor';
import { observer } from 'mobx-react-lite';
import { createContext, useContext } from 'react';

const TextEditorContext = createContext<EditorApi | undefined>(undefined);

export const useEditorApi = () => {
    const context = useContext(TextEditorContext);
    if (!context) {
        throw new Error('useEditorApi must be used within TextEditorProvider');
    }
    return context;
}

export const TextEditorProviderWithStartText = observer(function (props: { children: any, text?: string }) {
    let editorApi = useEditor(props.text);
    return (
        <TextEditorContext.Provider value={editorApi}>
            {props.children}
        </TextEditorContext.Provider>
    )
}
)
export const TextEditorProviderWithoutStartText = observer(function (props: { children: any}) {
    let editorApi = useEditor()
    return (
        <TextEditorContext.Provider value={editorApi}>
            {props.children}
        </TextEditorContext.Provider>
    )
});
