import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { api } from "shared";
import { templateStore } from "app";

import { Button, ButtonStyle, WarningDialog, LoadingCircle } from "components";
import ImportProgress from "./widgets/import-progress/import-progress";

import importStore from "./core/import-store";
import TemplateStoreKeys from "entities/TemplateStoreKeys";
import { Step1Tool, Step2Tool, Step3Tool } from "./data/import-constants";
import { EntityType } from "types/entity";

import { GoBack } from "shared";

import styles from "./import-page.module.css";

function ImportPage() {
	const page = useRef<HTMLDivElement>(null);

	useEffect(() => {
		async function getTable() {
			await importStore.loadTables("");
			await importStore.loadRequiredSetting();
			const entity = templateStore.get<EntityType>(TemplateStoreKeys.import);
			let findEntity = importStore.entities.find((e) => e.name == entity);
			if (findEntity) importStore.setEntity(findEntity);
		}
		importStore.new();
		getTable();
	}, []);

	useEffect(() => {
		page.current?.scrollTo(0, 0);
	});

	return (
		<>
			<Loader isLoading={importStore.isLoading}>
				<div ref={page} className={styles.wrraper}>
					<div className={styles.body}>
						<ImportProgress
							step={importStore.steps}
							stepName={importStore.stage.stageName}
							importResult={importStore.importResult}
						/>
						<ImportStage />
						{importStore.steps !== 4 && <ToolBar step={importStore.steps} />}
					</div>
				</div>
			</Loader>
		</>
	);
}

function Loader(props: { isLoading: boolean; children: any }) {
	if (props.isLoading) {
		return <div className={styles.loadingContainer}>{props.children}</div>;
	}

	return props.children;
}

const ImportStage = observer(() => {
	return <>{importStore.stage.render()}</>;
});

const ToolBar = observer((props: { step: number }) => {
	const navigate = useNavigate();
	const [openedBackWarningDialog, setOpenedBackWarningDialog] = useState(false);
	const [openedCloseWarningDialog, setOpenedCloseWarningDialog] = useState(false);

	let toolTitle = "";
	if (props.step === 1) toolTitle = Step1Tool;
	else if (props.step === 2) toolTitle = Step2Tool;
	else if (props.step === 3) toolTitle = Step3Tool;

	function onBackClick() {
		if (props.step === 2) {
			setOpenedBackWarningDialog(true);
		} else importStore.back();
	}

	function base64ToArrayBuffer(base64: string) {
		var binaryString = window.atob(base64);
		var binaryLen = binaryString.length;
		var bytes = new Uint8Array(binaryLen);
		for (var i = 0; i < binaryLen; i++) {
			var ascii = binaryString.charCodeAt(i);
			bytes[i] = ascii;
		}
		return bytes;
	}
	async function onExampleFileClick() {
		const entityName = importStore.entity?.name;

		try {
			// const response = await api.getWithParams(
			//     `/api/Import/example?entityName=${entityName}`,
			//     {
			//         responseType: "blob",
			//     }
			// );
			const response = await api.http.importFile.importExample(entityName!).getWithParams({ responseType: "blob" });

			if (response.data.success === true) {
				let a = document.createElement("a");
				let file = new Blob([base64ToArrayBuffer(response.data.data.fileData)]);
				a.href = URL.createObjectURL(file);
				a.download = response.data.data.fileName;
				a.click();
				return true;
			} else {
				console.log(response.data.error.message);
			}
			return false;
		} catch (e) {
			console.log("Что-то пошло не так");
			console.log(e);
			return false;
		}
	}

	return (
		<div className={styles.toolPanel}>
			{props.step === 1 ? (
				<>
					{importStore.entity !== null && (
						<Button
							caption={"Скачать пример файла для импорта"}
							className={styles.downloadExample}
							style={ButtonStyle.Link}
							onClick={() => onExampleFileClick()}
						/>
					)}
				</>
			) : (
				<Button
					caption={"Назад"}
					className={styles.downloadExample}
					style={ButtonStyle.Link}
					onClick={() => onBackClick()}
					firstIcon={<GoBack />}
				/>
			)}
			<WarningDialog
				value={"Вы действительно хотите вернуться на предыдущий шаг?\nТекущий прогресс будет утерян."}
				valueReturn={"Отменить"}
				valueDelete={"Да, вернуться на шаг назад"}
				isOpen={openedBackWarningDialog}
				onBackClick={() => {
					setOpenedBackWarningDialog(false);
				}}
				onCancelClick={() => {
					importStore.back();
					setOpenedBackWarningDialog(false);
				}}
			/>
			<div className={styles.actionButtons}>
				<span className={styles.cancelButton} onClick={() => setOpenedCloseWarningDialog(true)}>
					Отменить
				</span>

				<div className={styles.buttonTool}>
					{importStore.isLoading ? (
						<Button
							firstIcon={<LoadingCircle />}
							className={styles.buttonWithCircle}
							style={ButtonStyle.Primary}
							onClick={() => {}}
							isDisabled={true}
						/>
					) : (
						<>
							{!importStore.stage.valid() && (
								<span className={styles.tooltip}>
									<span> {toolTitle} </span>
								</span>
							)}
							<Button
								caption={"Продолжить"}
								style={ButtonStyle.Primary}
								onClick={() => importStore.next()}
								isDisabled={!importStore.stage.valid()}
							/>
						</>
					)}
				</div>
			</div>
			<WarningDialog
				value={"Вы действительно хотите покинуть страницу импорта?\nТекущий прогресс будет утерян."}
				valueReturn={"Отменить"}
				valueDelete={"Да, покинуть"}
				isOpen={openedCloseWarningDialog}
				onBackClick={() => {
					setOpenedCloseWarningDialog(false);
				}}
				onCancelClick={() => {
					navigate(-1);
					setOpenedCloseWarningDialog(false);
					importStore.new();
				}}
			/>
		</div>
	);
});

export default observer(ImportPage);
