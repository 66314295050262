import { observer } from "mobx-react-lite";
import { useCallback, useMemo, useState } from "react";
import { v4 } from "uuid";

import { singlePageSynchroiser } from "pages/single-page/single-page-synchroiser/single-page-synchroiser";
import { modalController, Position } from "features/modals";

import { Button, Field } from "sale-bridge-ui-kit";

import { ModalType } from "features/modals/viewer/modal-viewer";

import { CloseMaxi } from "shared";

import styles from "./time-logging-popup.module.scss";

type TimeLoggingPopupProps = {
	recordId: string;
	recordTitle: string;
	timeFromStopwatch?: string;
	close: () => void;
};

export const TimeLoggingPopup = observer((props: TimeLoggingPopupProps) => {
	const [idNotification] = useState<string>(v4());
	const [comment, setComment] = useState<string>("");
	const [time, setTime] = useState<string>(props.timeFromStopwatch ?? "");

	const handleCancel = useCallback(() => {
		props.close();
	}, [props.close]);

	const handleChangeComment = useCallback((value: string) => {
		setComment(value);
	}, []);

	const handleChangeTime = useCallback((value: string) => {
		setTime(value);
	}, []);

	const handleSave = useCallback(() => {
		const textWithoutLineBreaks = comment.replace(/(?:\r\n|\r|\n)/g, "\\n");

		singlePageSynchroiser
			.timeLogging(props.recordId, time, textWithoutLineBreaks)
			.then(() => {
				modalController.notificationAdd({
					id: idNotification,
					position: Position.CENTER,
					layout: <>{`Время залогировано: ${formattedTime}`}</>,
					allowDefaultClick: true,
					allowTimer: true
				});
			})
			.catch(() => {
				modalController.notificationAdd({
					id: idNotification,
					position: Position.CENTER,
					type: ModalType.ERROR_NOTIFICATION,
					layout: <>Что-то пошло не так. Проверьте интернет-соединение и попробуйте ещё раз</>
				});
			});

		handleCancel();
	}, [props, comment, time]);

	const handleAddTime = useCallback(
		(timeToAdd: string) => {
			const currentTime = Number(time);
			const additionalTime = Number(timeToAdd);

			setTime((currentTime + additionalTime).toString());
		},
		[time]
	);

	const buttonVariant = useMemo(() => {
		return Number(time) > 0 ? "primary" : "disabled";
	}, [time]);

	const formattedTime = useMemo(() => {
		const totalMinutes = Number(time);
		const hours = Math.floor(totalMinutes / 60);
		const mins = totalMinutes % 60;

		return `${hours}ч ${mins}мин`;
	}, [time]);

	const buttonsData = [
		{ text: "+ 1 мин", value: "1" },
		{ text: "+ 5 мин", value: "5" },
		{ text: "+ 10 мин", value: "10" },
		{ text: "+ 15 мин", value: "15" },
		{ text: "+ 30 мин", value: "30" },
		{ text: "+ 1 час", value: "60" },
		{ text: "+ 1 ч 30 мин", value: "90" },
		{ text: "+ 2 часа", value: "120" }
	];

	return (
		<div className={styles.dialog}>
			<div className={styles.header}>
				<span className={styles.title}>Залогировать время</span>
				<CloseMaxi className={styles.closeButton} onClick={handleCancel} />
			</div>

			<div className={styles.dialogBody}>
				<p className={styles.recordTitle}>{props.recordTitle}</p>

				<div>
					<Field
						type="number"
						subType="integer"
						placeholder="0"
						size="small"
						textVariant="outlined"
						value={time}
						onChange={handleChangeTime}
						isRequired
						label="Время в минутах"
					/>

					<div className={styles.info}>{`Время в часах: ${formattedTime}`}</div>

					<p className={styles.additionalInfo}>Нажимайте на значения для сложения</p>

					<div className={styles.buttonBlocks}>
						{buttonsData.map((button, index) => (
							<Button
								key={index}
								text={button.text}
								variant="secondary"
								size="small"
								onClick={() => handleAddTime(button.value)}
								border={false}
								link={false}
								loading={false}
							/>
						))}
					</div>
				</div>
				<div className={styles.commentBlock}>
					<span className={styles.info}>Комментарий</span>
					<Field
						type="multiline"
						placeholder="Любая дополнительная информация"
						size="small"
						textVariant="outlined"
						value={comment}
						onChange={handleChangeComment}
						counter={200}
					/>
				</div>
				<div className={styles.divider}></div>
			</div>
			<div className={styles.dialogFooter}>
				<Button text="Отменить" size="small" variant="default" border link={false} loading={false} onClick={handleCancel} />
				<Button text="Сохранить" size="small" variant={buttonVariant} border link={false} loading={false} onClick={handleSave} />
			</div>
		</div>
	);
});
