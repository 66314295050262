import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo, useState } from "react";
import { toJS } from "mobx";
import classNames from "classnames";

import { api, itemToLookup } from "shared";

import { HierarchicalSelect, HierarchicalStyles } from "features/filter/simpleFilter/ui/MacrosSelect";
import { Field } from "sale-bridge-ui-kit";

import DateMacroses from "features/filter/simpleFilter/data/DateMacroses";
import { booleanItems } from "features/filter/simpleFilter/data/DefaultValue";
import { FieldValidationState, Item } from "types";

import { DirectorSimpleFilter, IPropertyFilter } from "app";
import { IFilterStore } from "entities/filter/FilterStore";
import { loadEntityWithColumns } from "app/services/lookup/LookupService";
import { DataValueType, ComparisonType, ExpressionType, MacrosType } from "entities/filter/IFilter";

import styles from "./right-expression.module.scss";

interface ValueProps {
	filter: IFilterStore;
	isDisabled?: boolean;
	isAdvancedFilter?: boolean;
	size?: "small" | "medium" | "large";
	validation?: FieldValidationState;
	setRightExpression?: (filter: IFilterStore, value: any) => void;
}

export const RightExpression = observer((props: ValueProps) => {
	const type = props.filter.property.propertyValue.type;
	const comparisonValue = props.filter.comparisonType;
	const isAdvancedFilter = props.isAdvancedFilter ?? false;

	if (!props.isDisabled) {
		if (type === DataValueType.Entity || type === DataValueType.Lookup) {
			return <EntityValue {...props} isAdvancedFilter={isAdvancedFilter} />;
		}

		if (type === DataValueType.DateTime && comparisonValue) {
			if (comparisonValue === ComparisonType.Between || comparisonValue === ComparisonType.NotBetween) {
				return <BetweenDateTimeValue {...props} isAdvancedFilter={isAdvancedFilter} />;
			}
			return <DateTimeSelect {...props} isAdvancedFilter={isAdvancedFilter} />;
		}

		if (type === DataValueType.Date && comparisonValue) {
			if (comparisonValue === ComparisonType.Between || comparisonValue === ComparisonType.NotBetween) {
				return <BetweenDateValue {...props} isAdvancedFilter={isAdvancedFilter} />;
			}
			return <DateSelect {...props} isAdvancedFilter={isAdvancedFilter} />;
		}

		if (type === DataValueType.Time && comparisonValue) {
			if (comparisonValue === ComparisonType.Between || comparisonValue === ComparisonType.NotBetween) {
				return <BetweenTimeValue {...props} isAdvancedFilter={isAdvancedFilter} />;
			}
			return <TimeSelect {...props} isAdvancedFilter={isAdvancedFilter} />;
		}

		if (type === DataValueType.Boolean) {
			return <BooleanValue {...props} isAdvancedFilter={isAdvancedFilter} />;
		}

		if ((type === DataValueType.Integer || type === DataValueType.Long) && comparisonValue) {
			if (comparisonValue === ComparisonType.Between || comparisonValue === ComparisonType.NotBetween) {
				return <BetweenIntLongValue {...props} isAdvancedFilter={isAdvancedFilter} />;
			}

			return <IntLongValue {...props} isAdvancedFilter={isAdvancedFilter} />;
		}

		if (type === DataValueType.Decimal && comparisonValue) {
			if (comparisonValue === ComparisonType.Between || comparisonValue === ComparisonType.NotBetween) {
				return <BetweenDecimalValue {...props} isAdvancedFilter={isAdvancedFilter} />;
			}
			return <DecimalValue {...props} isAdvancedFilter={isAdvancedFilter} />;
		}

		if (type === DataValueType.Text) {
			return <TextValue {...props} isAdvancedFilter={isAdvancedFilter} />;
		}
	}
	return <TextValue {...props} isAdvancedFilter={isAdvancedFilter} />;
});

export default RightExpression;

const EntityValue = observer((props: ValueProps) => {
	const [items, setItems] = useState<Item[]>([]);
	const [searchValue, setSearchValue] = useState("");

	const itemStyle = classNames(styles.item, {
		[styles[`item${props.size}`]]: props.size
	});

	async function handleLookupTableChange(lookupTable?: string) {
		setItems([]);
		setSearchValue("");
		if (lookupTable) {
			const response = await api.http.entity.getViewColumnByEntityName().post(JSON.stringify(lookupTable));

			const property: IPropertyFilter = {
				DisplayName: "",
				Name: response?.data.data ?? "Name",
				Type: DataValueType.Text
			};

			const filter = DirectorSimpleFilter.CreateAttribute("", ComparisonType.Contain, property, lookupTable);

			const data = await loadEntityWithColumns(lookupTable, filter);
			setItems(data.records as Item[]);
		}
	}

	const handleChange = useCallback(
		(item: Item | null) => {
			if (props.setRightExpression) {
				props.setRightExpression(props.filter, itemToLookup(item));
			} else {
				props.filter.setRightExpression(itemToLookup(item));
			}
			setSearchValue("");
		},
		[props.filter, props.setRightExpression]
	);

	useEffect(() => {
		handleLookupTableChange(props.filter.property.propertyValue.lookupTable);
	}, [props.filter.property.propertyValue.lookupTable]);

	const handleFieldChange = () => {
		handleChange(null);
	};

	const dropdownItems = useMemo(() => {
		return [
			{
				layout: <></>,
				items: items
					.filter((item) => item.displayValue?.toLowerCase().includes(searchValue?.toLowerCase()))
					.map((item) => (
						<div className={itemStyle} onClick={() => handleChange(item)}>
							<span>{item.displayValue}</span>
						</div>
					))
			}
		];
	}, [toJS(items), searchValue]);

	return (
		<Field
			type="select"
			size={props.size ?? "medium"}
			placeholder="Выберите колонку"
			labelPosition="vertical"
			textVariant="outlined"
			isRequired
			isClearing
			value={props.filter.rightExpression?.parameter.value?.name ?? props.filter.rightExpression?.parameter.value?.title ?? ""}
			onChange={handleFieldChange}
			items={dropdownItems}
			searchValue={searchValue}
			onChangeSearchValue={setSearchValue}
			alert={props.validation?.isInvalid ? "error" : undefined}
			tooltipBody={props.validation?.error}
			startTooltipPosition="top left"
			tooltipTrigger="hover"
			isTooltipDisplayed={props.validation?.isInvalid}
		/>
	);
});

const TimeSelect = observer((props: ValueProps) => {
	const [value, setValue] = useState<string>(props.filter.rightExpression?.parameter.value?.name ?? "");

	const handleChange = useCallback(
		(value: string) => {
			if (props.setRightExpression) {
				props.setRightExpression(props.filter, value);
			} else {
				props.filter.setRightExpression(value);
			}
			setValue(value);
		},
		[props.filter]
	);

	return (
		<Field
			type="time"
			size={props.size ?? "medium"}
			isRequired
			value={value ?? ""}
			info="Укажите время"
			onChange={handleChange}
			textVariant="outlined"
			alert={props.validation?.isInvalid ? "error" : undefined}
			tooltipBody={props.validation?.error}
			startTooltipPosition="top left"
			tooltipTrigger="hover"
			isTooltipDisplayed={props.validation?.isInvalid}
		/>
	);
});

const BetweenTimeValue = observer((props: ValueProps) => {
	const valueBetween = props.filter.rightExpression?.parameter.value;
	const [valueStart, setValueStart] = useState<string>(valueBetween.start ?? "");
	const [valueEnd, setValueEnd] = useState<string>(valueBetween.end ?? "");

	const handleChangeStart = useCallback(
		(value: string) => {
			updateFilter(value, valueBetween.end);
			setValueStart(value);
		},
		[props.filter, valueStart, valueEnd]
	);

	const handleChangeEnd = useCallback(
		(value: string) => {
			updateFilter(valueBetween.start, value);
			setValueEnd(value);
		},
		[props.filter, valueStart, valueEnd]
	);

	function updateFilter(start: string | null, end: string | null) {
		if (props.setRightExpression) {
			props.setRightExpression(props.filter, { start: start, end: end });
		} else {
			props.filter.setRightExpression({ start: start, end: end });
		}
	}

	return (
		<div className={styles.between}>
			<Field
				type="time"
				size={props.size ?? "medium"}
				isRequired
				value={valueStart ?? ""}
				info="Укажите время начала"
				onChange={handleChangeStart}
				textVariant="outlined"
				alert={props.validation?.isInvalid ? "error" : undefined}
				tooltipBody={props.validation?.error}
				startTooltipPosition="top left"
				tooltipTrigger="hover"
				isTooltipDisplayed={props.validation?.isInvalid}
			/>
			<span className={styles.tir}>и</span>
			<Field
				type="time"
				size={props.size ?? "medium"}
				isRequired
				value={valueEnd ?? ""}
				info="Укажите время завершения"
				onChange={handleChangeEnd}
				textVariant="outlined"
				alert={props.validation?.isInvalid ? "error" : undefined}
				tooltipBody={props.validation?.error}
				startTooltipPosition="top left"
				tooltipTrigger="hover"
				isTooltipDisplayed={props.validation?.isInvalid}
			/>
		</div>
	);
});

const BetweenDateValue = observer((props: ValueProps) => {
	const valueBetween = props.filter.rightExpression?.parameter.value;
	const [valueStart, setValueStart] = useState<string>(valueBetween.start ?? "");
	const [valueEnd, setValueEnd] = useState<string>(valueBetween.end ?? "");

	const handleChangeStart = useCallback(
		(value: string) => {
			updateFilter(value, valueBetween.end);
			setValueStart(value);
		},
		[props.filter, valueStart, valueEnd]
	);

	const handleChangeEnd = useCallback(
		(value: string) => {
			updateFilter(valueBetween.start, value);
			setValueEnd(value);
		},
		[props.filter, valueStart, valueEnd]
	);

	function updateFilter(start: string | null, end: string | null) {
		if (props.setRightExpression) {
			props.setRightExpression(props.filter, { start: start, end: end });
		} else {
			props.filter.setRightExpression({ start: start, end: end });
		}
	}

	return (
		<div className={styles.between}>
			<Field
				type="date"
				size={props.size ?? "medium"}
				isRequired
				value={valueStart ?? ""}
				info="Укажите дату начала"
				onChange={handleChangeStart}
				textVariant="outlined"
				alert={props.validation?.isInvalid ? "error" : undefined}
				tooltipBody={props.validation?.error}
				startTooltipPosition="top left"
				tooltipTrigger="hover"
				isTooltipDisplayed={props.validation?.isInvalid}
			/>
			<span className={styles.tir}>и</span>
			<Field
				type="date"
				size={props.size ?? "medium"}
				isRequired
				value={valueEnd ?? ""}
				info="Укажите дату завершения"
				onChange={handleChangeEnd}
				textVariant="outlined"
				alert={props.validation?.isInvalid ? "error" : undefined}
				tooltipBody={props.validation?.error}
				startTooltipPosition="top left"
				tooltipTrigger="hover"
				isTooltipDisplayed={props.validation?.isInvalid}
			/>
		</div>
	);
});

const DateTimeSelect = observer((props: ValueProps) => {
	const [value, setValue] = useState<string>(props.filter.rightExpression?.parameter.value?.name ?? "");

	const handleChange = useCallback(
		(value: string) => {
			if (props.setRightExpression) {
				props.setRightExpression(props.filter, value);
			} else {
				props.filter.setRightExpression(value);
			}
			setValue(value);
		},
		[props.filter, props.setRightExpression]
	);

	return (
		<Field
			type="dateTime"
			size={props.size ?? "medium"}
			isRequired
			value={value ?? ""}
			info="Укажите дату и время"
			onChange={handleChange}
			textVariant="outlined"
			alert={props.validation?.isInvalid ? "error" : undefined}
			tooltipBody={props.validation?.error}
			startTooltipPosition="top left"
			tooltipTrigger="hover"
			isTooltipDisplayed={props.validation?.isInvalid}
		/>
	);
});

const BetweenDateTimeValue = observer((props: ValueProps) => {
	const valueBetween = props.filter.rightExpression?.parameter.value;
	const [valueStart, setValueStart] = useState<string>(valueBetween.start ?? "");
	const [valueEnd, setValueEnd] = useState<string>(valueBetween.end ?? "");

	const handleChangeStart = useCallback(
		(value: string) => {
			updateFilter(value, valueBetween.end);
			setValueStart(value);
		},
		[props.filter, valueStart, valueEnd]
	);

	const handleChangeEnd = useCallback(
		(value: string) => {
			updateFilter(valueBetween.start, value);
			setValueEnd(value);
		},
		[props.filter, valueStart, valueEnd]
	);

	function updateFilter(start: string | null, end: string | null) {
		if (props.setRightExpression) {
			props.setRightExpression(props.filter, { start: start, end: end });
		} else {
			props.filter.setRightExpression({ start: start, end: end });
		}
	}

	return (
		<div className={styles.betweenDT}>
			<Field
				type="dateTime"
				size={props.size ?? "medium"}
				isRequired
				value={valueStart ?? ""}
				info="Укажите дату и время начала"
				onChange={handleChangeStart}
				textVariant="outlined"
				alert={props.validation?.isInvalid ? "error" : undefined}
				tooltipBody={props.validation?.error}
				startTooltipPosition="top left"
				tooltipTrigger="hover"
				isTooltipDisplayed={props.validation?.isInvalid}
			/>
			<span className={styles.tir}>и</span>
			<Field
				type="dateTime"
				size={props.size ?? "medium"}
				isRequired
				value={valueEnd ?? ""}
				info="Укажите дату и время завершения"
				onChange={handleChangeEnd}
				textVariant="outlined"
				alert={props.validation?.isInvalid ? "error" : undefined}
				tooltipBody={props.validation?.error}
				startTooltipPosition="top left"
				tooltipTrigger="hover"
				isTooltipDisplayed={props.validation?.isInvalid}
			/>
		</div>
	);
});

const DateSelect = observer((props: ValueProps) => {
	const styles = useMemo(
		() => (props.isAdvancedFilter ? HierarchicalStyles.AdvancedFilter : HierarchicalStyles.Opacity),
		[props.isAdvancedFilter]
	);

	function getMacros(macros: MacrosType) {
		let result: Item | undefined;
		DateMacroses?.forEach((i) => {
			let multilevelItem = i.multilevelItems?.find((v: any) => {
				return typeof v.id === "number" && v.id === macros;
			});
			if (multilevelItem !== undefined) {
				result = multilevelItem;
			}
		});
		return result;
	}

	const macros =
		props.filter.rightExpression?.type === ExpressionType.Macros ? getMacros(props.filter.rightExpression?.macros!) : undefined;
	let value = macros !== undefined ? macros : props.filter.rightExpression?.parameter.value;

	const [item, setItem] = useState<Item>(props.filter.rightExpression?.parameter.value);

	if (value === null || typeof value === "string" || value?.id === "0") {
		if (value?.id === "0") {
			if (props.setRightExpression) {
				props.setRightExpression(props.filter, null);
			} else {
				props.filter.setRightExpression(null);
			}
		}
		return <DateValue {...props} />;
	}

	if (value === undefined) {
		value = item;
	}

	function onChange(item: Item) {
		setItem(item);
		if (props.setRightExpression) {
			props.setRightExpression(props.filter, item);
		} else {
			props.filter.setRightExpression(item);
		}
	}

	return (
		<HierarchicalSelect
			items={DateMacroses}
			value={value}
			nullableValue={<DateValue {...props} />}
			onChange={onChange}
			onItemsLoad={() => {}}
			styles={styles}
			placeholder={"Выберите период"}
			size={props.size}
		/>
	);
});

const DateValue = observer((props: ValueProps) => {
	const [value, setValue] = useState<string>(props.filter.rightExpression?.parameter.value?.name ?? "");

	const handleChange = useCallback(
		(value: string) => {
			if (props.setRightExpression) {
				props.setRightExpression(props.filter, value);
			} else {
				props.filter.setRightExpression(value);
			}
			setValue(value);
		},
		[props.filter, props.setRightExpression]
	);

	return (
		<Field
			type="date"
			size={props.size ?? "medium"}
			value={value ?? ""}
			info="Укажите дату"
			onChange={handleChange}
			textVariant="outlined"
			alert={props.validation?.isInvalid ? "error" : undefined}
			tooltipBody={props.validation?.error}
			startTooltipPosition="top left"
			tooltipTrigger="hover"
			isTooltipDisplayed={props.validation?.isInvalid}
		/>
	);
});

const BooleanValue = observer((props: ValueProps) => {
	const itemStyle = classNames(styles.item, {
		[styles[`item${props.size}`]]: props.size
	});

	const value = useMemo(
		() => booleanItems.find((item) => item.name === props.filter.rightExpression?.parameter.value)?.displayValue,
		[toJS(booleanItems), props.filter.rightExpression?.parameter.value]
	);

	const handleChange = useCallback(
		(item: Item | null) => {
			if (props.setRightExpression) {
				props.setRightExpression(props.filter, item ? item.name : null);
			} else {
				props.filter.setRightExpression(item ? item.name : null);
			}
		},
		[props.filter, toJS(booleanItems), props.setRightExpression]
	);

	const dropdownItems = useMemo(
		() => [
			{
				layout: <></>,
				items: booleanItems.map((item) => (
					<div
						key={item.id}
						className={itemStyle}
						onClick={() => {
							handleChange(item);
						}}
					>
						<span>{item.displayValue}</span>
					</div>
				))
			}
		],
		[toJS(booleanItems), handleChange]
	);

	return (
		<Field
			type="select"
			size={props.size ?? "medium"}
			placeholder={value ? "" : "Выберите колонку"}
			labelPosition="vertical"
			textVariant="outlined"
			isRequired
			isClearing
			value={value ?? ""}
			onChange={() => {
				handleChange(null);
			}}
			items={dropdownItems}
			alert={props.validation?.isInvalid ? "error" : undefined}
			tooltipBody={props.validation?.error}
			startTooltipPosition="top left"
			tooltipTrigger="hover"
			isTooltipDisplayed={props.validation?.isInvalid}
		/>
	);
});

const IntLongValue = observer((props: ValueProps) => {
	const [value, setValue] = useState<string>(props.filter.rightExpression?.parameter.value ?? "");

	const handleChange = useCallback(
		(value: string) => {
			if (props.setRightExpression) {
				props.setRightExpression(props.filter, value);
			} else {
				props.filter.setRightExpression(value);
			}
			setValue(value);
		},
		[props.filter, props.setRightExpression]
	);
	return (
		<Field
			type="number"
			size={props.size ?? "medium"}
			placeholder={"0"}
			labelPosition="vertical"
			textVariant="outlined"
			subType="integer"
			isRequired
			isDisabled={props.isDisabled}
			value={value ?? ""}
			onChange={handleChange}
			alert={props.validation?.isInvalid ? "error" : undefined}
			tooltipBody={props.validation?.error}
			startTooltipPosition="top left"
			tooltipTrigger="hover"
			isTooltipDisplayed={props.validation?.isInvalid}
		/>
	);
});

const BetweenIntLongValue = observer((props: ValueProps) => {
	const valueBetween = props.filter.rightExpression?.parameter.value;
	const [valueStart, setValueStart] = useState<string>(valueBetween.start ?? "");
	const [valueEnd, setValueEnd] = useState<string>(valueBetween.end ?? "");

	const handleChangeStart = useCallback(
		(value: string) => {
			updateFilter(value, valueBetween.end);
			setValueStart(value);
		},
		[props.filter, valueStart, valueEnd]
	);

	const handleChangeEnd = useCallback(
		(value: string) => {
			updateFilter(valueBetween.start, value);
			setValueEnd(value);
		},
		[props.filter, valueStart, valueEnd]
	);

	function updateFilter(start: string | null, end: string | null) {
		if (props.setRightExpression) {
			props.setRightExpression(props.filter, { start: start, end: end });
		} else {
			props.filter.setRightExpression({ start: start, end: end });
		}
	}

	return (
		<div className={styles.between}>
			<Field
				type="number"
				size={props.size ?? "medium"}
				placeholder="0"
				labelPosition="vertical"
				textVariant="outlined"
				subType="integer"
				isRequired
				isDisabled={props.isDisabled}
				value={valueStart ?? ""}
				onChange={handleChangeStart}
				alert={props.validation?.isInvalid ? "error" : undefined}
				tooltipBody={props.validation?.error}
				startTooltipPosition="top left"
				tooltipTrigger="hover"
				isTooltipDisplayed={props.validation?.isInvalid}
			/>
			<span className={styles.tir}>—</span>
			<Field
				type="number"
				size={props.size ?? "medium"}
				placeholder="0"
				labelPosition="vertical"
				textVariant="outlined"
				subType="integer"
				isRequired
				isDisabled={props.isDisabled}
				value={valueEnd ?? ""}
				onChange={handleChangeEnd}
				alert={props.validation?.isInvalid ? "error" : undefined}
				tooltipBody={props.validation?.error}
				startTooltipPosition="top left"
				tooltipTrigger="hover"
				isTooltipDisplayed={props.validation?.isInvalid}
			/>
		</div>
	);
});

const DecimalValue = observer((props: ValueProps) => {
	const [value, setValue] = useState<string>("");

	const handleChange = useCallback(
		(value: string) => {
			if (props.setRightExpression) {
				props.setRightExpression(props.filter, value);
			} else {
				props.filter.setRightExpression(value);
			}
			setValue(value);
		},
		[props.filter, props.setRightExpression]
	);

	return (
		<Field
			type="number"
			size={props.size ?? "medium"}
			placeholder={"0"}
			labelPosition="vertical"
			textVariant="outlined"
			subType="decimal"
			isRequired
			isDisabled={props.isDisabled}
			value={value ?? ""}
			onChange={handleChange}
			alert={props.validation?.isInvalid ? "error" : undefined}
			tooltipBody={props.validation?.error}
			startTooltipPosition="top left"
			tooltipTrigger="hover"
			isTooltipDisplayed={props.validation?.isInvalid}
		/>
	);
});

const BetweenDecimalValue = observer((props: ValueProps) => {
	const valueBetween = props.filter.rightExpression?.parameter.value;
	const [valueStart, setValueStart] = useState<string>(valueBetween.start ?? "");
	const [valueEnd, setValueEnd] = useState<string>(valueBetween.end ?? "");

	const handleChangeStart = useCallback(
		(value: string) => {
			updateFilter(value, valueBetween.end);
			setValueStart(value);
		},
		[props.filter, valueStart, valueEnd]
	);

	const handleChangeEnd = useCallback(
		(value: string) => {
			updateFilter(valueBetween.start, value);
			setValueEnd(value);
		},
		[props.filter, valueStart, valueEnd]
	);

	function updateFilter(start: string | null, end: string | null) {
		if (props.setRightExpression) {
			props.setRightExpression(props.filter, { start: start, end: end });
		} else {
			props.filter.setRightExpression({ start: start, end: end });
		}
	}

	return (
		<div className={styles.between}>
			<Field
				type="number"
				size={props.size ?? "medium"}
				placeholder={"0"}
				labelPosition="vertical"
				textVariant="outlined"
				subType="decimal"
				isRequired
				isDisabled={props.isDisabled}
				value={valueStart ?? ""}
				onChange={handleChangeStart}
				alert={props.validation?.isInvalid ? "error" : undefined}
				tooltipBody={props.validation?.error}
				startTooltipPosition="top left"
				tooltipTrigger="hover"
				isTooltipDisplayed={props.validation?.isInvalid}
			/>
			<span className={styles.tir}>—</span>
			<Field
				type="number"
				size={props.size ?? "medium"}
				placeholder={"0"}
				labelPosition="vertical"
				textVariant="outlined"
				subType="decimal"
				isRequired
				isDisabled={props.isDisabled}
				value={valueEnd ?? ""}
				onChange={handleChangeEnd}
				alert={props.validation?.isInvalid ? "error" : undefined}
				tooltipBody={props.validation?.error}
				startTooltipPosition="top left"
				tooltipTrigger="hover"
				isTooltipDisplayed={props.validation?.isInvalid}
			/>
		</div>
	);
});

const TextValue = observer((props: ValueProps) => {
	const [value, setValue] = useState<string>(props.filter.rightExpression?.parameter.value ?? "");

	const handleChange = useCallback(
		(value: string) => {
			if (props.setRightExpression) {
				props.setRightExpression(props.filter, value);
			} else {
				props.filter.setRightExpression(value);
			}
			setValue(value);
		},
		[props.filter, props.setRightExpression]
	);

	return (
		<Field
			type="text"
			size={props.size ?? "medium"}
			placeholder={props.isDisabled ? "Выберите колонку" : "Введите значение"}
			labelPosition="vertical"
			textVariant="outlined"
			isRequired
			isClearing
			isDisabled={props.isDisabled}
			value={value ?? ""}
			onChange={handleChange}
			alert={props.validation?.isInvalid ? "error" : undefined}
			tooltipBody={props.validation?.error}
			startTooltipPosition="top left"
			tooltipTrigger="hover"
			isTooltipDisplayed={props.validation?.isInvalid}
		/>
	);
});
