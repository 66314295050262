import { isObject, lowerFirst } from "lodash";

import { GridItem } from "types/entity";
import {toJS} from "mobx";

/**
 * @description Метод для получения значения столбца записи по columnId Для оптимизации использует передаваемые в него массивы не доставая их самостоятельно
 * @param rows - dispatcher.entity.get().rows
 * @param items - dispatcher.sectionWizzard.getAllGridItems()
 * @param rowId - id записи, значение которой нужно получить
 * @param columnId - columnId колонки которой значение нужно получить
 */
export function getRowValueByColumnId(rows: Array<{[key: string]: { displayValue: string, id: string } | string | null}>, items: Array<GridItem>, rowId: string, columnId: string): string | null {
    const row = rows.find(row => row.id === rowId);
    const result = row ? row[lowerFirst(items.find((item) => item.fieldConfig?.columnId === columnId)?.fieldConfig?.columnName) ?? ""] : null;
    return isObject(result) ? result.displayValue : result;
}

/**
 * @description Метод для получения значения столбца записи по columnName Для оптимизации использует передаваемые в него массивы не доставая их самостоятельно
 * @param rows - dispatcher.entity.get().rows
 * @param name - columnName колонки которой значение нужно получить
 */
export function getRowValueByName(rows: Array<{[key: string]: string | null}>, name: string): string | null {
    const row = rows.find((row) => row.columnName === name);

    return row ? row[name] ?? null : null;
}

/**
 * @description Метод для получения значения и типа столбца записи по columnId Для оптимизации использует передаваемые в него массивы не доставая их самостоятельно
 * @param rows - dispatcher.entity.get().rows
 * @param items - dispatcher.sectionWizzard.getAllGridItems()
 * @param rowId - id записи, значение которой нужно получить
 * @param columnId - columnId колонки которой значение нужно получить
 */
export function getColumnValueAndTypeByColumnId(rows: Array<{[key: string]: { displayValue: string, id: string } | string | null}>, items: Array<GridItem>, rowId: string, columnId: string): { value: string | null, type: string } | null {
    const row = rows.find(row => row.id === rowId);
    const item = items.find((item) => item.fieldConfig?.columnId === columnId)?.fieldConfig;

    if (!item) {
        return null;
    }

    const result = row ? row[lowerFirst(item?.columnName) ?? ""] : null;
    return {
        value: isObject(result) ? result.displayValue : result,
        type: item?.columnType ?? "",
    }
}