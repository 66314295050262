import { observer } from "mobx-react";
import { useCallback, useState } from "react";

import { Button, Field } from "sale-bridge-ui-kit";

import { ValidationState } from "pages/section-wizzard/data/data";
import { validateRequired } from "entities/Validation";

import { CloseMaxi } from "shared";

import styles from "./business-rule-info-popup.module.scss";

type Props = {
	name: string;
	systemName: string;
	comment: string;
	save: (name: string, comment: string) => void;
	close: () => void;
};

export const BusinessRuleInfoPopup = observer((props: Props) => {
	const [ruleName, setRuleName] = useState<string>(props.name);
	const [comment, setComment] = useState<string>(props.comment);

	const [ruleNameValidation, setRuleNameValidation] = useState<ValidationState>({
		isInvalid: false,
		error: ""
	});

	const handleCancel = useCallback(() => {
		props.close();
	}, [props.close]);

	const handleChangeName = useCallback((value: string) => {
		setRuleName(value);
	}, []);

	const handleFocusOutName = useCallback(() => {
		const validation: ValidationState = { ...ruleNameValidation };
		validateRequired(ruleName, validation);
		setRuleNameValidation(validation);
	}, [ruleNameValidation, ruleName]);

	const handleChangeComment = useCallback((value: string) => {
		setComment(value);
	}, []);

	const handleSave = useCallback(() => {
		props.save(ruleName, comment);
	}, [ruleName, comment]);

	return (
		<div className={styles.dialog}>
			<div className={styles.header}>
				<span className={styles.title}>Сведения о бизнес-правиле</span>
				<CloseMaxi className={styles.closeButton} onClick={handleCancel} />
			</div>
			<div className={styles.dialogBody}>
				<Field
					type="text"
					size="small"
					label="Название"
					textVariant="outlined"
					value={ruleName}
					onChange={handleChangeName}
					alert={ruleNameValidation.isInvalid ? "error" : undefined}
					onBlur={handleFocusOutName}
					isClearing
					isRequired
				/>
				<Field
					type="text"
					size="small"
					label="Системное название"
					textVariant="outlined"
					value={props.systemName}
					onChange={() => {}}
					isClearing
					isRequired
					isDisabled
				/>
				<Field
					type="multiline"
					size="small"
					label="Комментарий"
					textVariant="outlined"
					placeholder="Добавьте описание для бизнес-правила"
					counter={200}
					value={comment}
					onChange={handleChangeComment}
				/>
			</div>
			<div className={styles.dialogFooter}>
				<Button text="Отменить" size="small" variant="default" border link={false} loading={false} onClick={handleCancel} />
				<Button
					text="Сохранить"
					size="small"
					variant={ruleNameValidation.isInvalid ? "disabled" : "primary"}
					border
					link={false}
					loading={false}
					onClick={handleSave}
				/>
			</div>
		</div>
	);
});
