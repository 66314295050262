import Entity, { IEntityData, IEntityStore } from 'entities/Entity';
import { FieldValidationState, validateRequired } from 'entities/Validation';
import { action, makeAutoObservable, makeObservable, observable } from 'mobx';
import { Item } from 'types';

export class License implements IEntityStore {
    schema: string = "License";
    id: string = "";
    name: string = "";
    startDate: string = "";
    finishDate: string = "";
    countLic: number = 0;
    createdOn: string = "";
    validation: { [key: string]: FieldValidationState };

    constructor() {
        makeObservable(this, {
            name: observable,
            startDate: observable,
            finishDate: observable,
            countLic: observable,
            setValue: action,
            validate: action,
            resetValidate: action,
            deserialize: action
        })

        this.validation = {
            name: {
                isInvalid: false
            },
        }
    }

    setValue(value: string | boolean | Item | null, fieldName: string) {
        Reflect.set(this, fieldName, value);
    }

    // @action
    validate(): boolean {
        if (!validateRequired(this.name, this.validation.name)) {
            return false;
        }
        return true;
    }

    resetValidate() {
        this.validation = {
            name: {
                isInvalid: false
            },
        }
    }

    // @action
    deserialize(p: any) {
        if (Object.values(p).length == 2 && Object.values(p)[0] === this.schema) {
            p.values.map((item: IEntityData) => {
                let name = item.propertyName.charAt(0).toLowerCase() + item.propertyName.slice(1);
                console.log(name,item.propertyValue);                
                Reflect.set(this, name, item.propertyValue);
            })
        }
        else {
            this.id = p.id;
            this.name = p.name ?? "";
            this.startDate = p.startDate ?? "";
            this.finishDate = p.finishDate ?? "";
            this.countLic = p.countLic ?? 0;
            this.createdOn = p.createdOn;
        }

    }

    serialize() {
        let values = Reflect.ownKeys(this).filter(x => !x.toString().toLowerCase().includes('schema') && !(this.id === "" && x === 'id') && x !== 'validation' && x != "isChecked" && !x.toString().endsWith('edOn')).map((item) => {
            let name = null;
            let value = null;
            if (typeof Reflect.get(this, item) === 'object') {
                name = (item.toString().charAt(0).toUpperCase() + item.toString().slice(1)) + "Id";
                value = ((Reflect.get(this, item) as Item) !== null) ? (Reflect.get(this, item) as Item).id : null;
            }
            else {
                name = item.toString().charAt(0).toUpperCase() + item.toString().slice(1);
                value = Reflect.get(this, item) === "" ? null : Reflect.get(this, item);
            }
            return {
                propertyName: name,
                propertyValue: value
            }
        }) as IEntityData[];
        values.pop();
        return {
            entityName: this.schema!,
            values: values
        }
    }

    handleError(error: string): void {
        if (error === "name_is_empty") {
            // this.validName = false;
            // this.errorName = "";
        }
    }
}


const license = new License();

const licenseEntity = new Entity<License>(license);

export default licenseEntity;