interface Props {
  children: JSX.Element
  id: string
  x: number
}

export function Element (props: Props) {
  return (
        <>
            {props.children}
        </>
  )
}
