import IFilter, { ComparisonType, DataValueType } from "entities/filter/IFilter";
import FilterColumnType from "entities/ColumnType";

export function createSearchFilter(lookupTable: string, searchValue: string | null, currentViewColumnName: string): IFilter | null {
	if (!searchValue) return null;

	return {
		schema: lookupTable,
		isEnabled: true,
		type: 1,
		comparisonType: ComparisonType.Contain,
		attribute: currentViewColumnName ?? "Name",
		attributeType: FilterColumnType.String,
		rightExpression: {
			type: 0,
			parameter: {
				dataValueType: DataValueType.Text,
				value: searchValue
			}
		},
		filters: [],
		logicalOperation: 0,
		detail: ""
	};
}
