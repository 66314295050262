import { action, makeObservable, observable } from "mobx";

import functionalRolesMainPanelStore from "pages/settings/functionalRoles/FunctionalRolesMainPanelStore";
import orgStructureMainPanelStore from "pages/settings/orgStructure/OrgStructureMainPanelStore";
import { api } from "shared";

import FilterColumnType from "entities/ColumnType";
import { userStore } from "entities/user/UsersSectionStore";
import RootUserRoles from "entities/userRole/RootUserRoles";
import UserRole, { UserRoleType } from "entities/userRole/UserRole";

export const enum rightsItemsIds {
	FuncRoles = 1,
	OrgRoles = 2,
	Users = 3
}
export const RightsItems = [
	{
		id: rightsItemsIds.FuncRoles,
		name: "Функциональные роли"
	},
	{
		id: rightsItemsIds.OrgRoles,
		name: "Организационные роли"
	},
	{
		id: rightsItemsIds.Users,
		name: "Пользователи"
	}
];

interface IAccessRightOperation {
	id: string;
	role?: string | null;
	user?: string | null;
	roleId?: string | null;
	userId?: string | null;
	canCreate: boolean;
	canEdit: boolean;
	canRead: boolean;
	canDelete: boolean;
	position?: number;
	entityId?: string;
	checked: boolean;
}

interface IAccessRightRecord {
	id: string;
	creatorRole?: string | null;
	creatorUser?: string | null;
	getterRole?: string | null;
	getterUser?: string | null;
	creatorRoleId?: string | null;
	creatorUserId?: string | null;
	getterRoleId?: string | null;
	getterUserId?: string | null;
	canEdit: boolean;
	canRead: boolean;
	canDelete: boolean;
	position?: number;
	entityId?: string;
	checked: boolean;
}

export class AccessRightLinkStore {
	dataOperation: IAccessRightOperation[];
	dataRecord: IAccessRightRecord[];
	dataGridOperation: any[];
	dataGridRecord: any[];
	checkRecord: boolean;
	checkOperation: boolean;
	setSelectedNameRole: string | null;
	setSelectedRoleId: string | null;
	selectedValueAuthor: string | any;
	selectedValueReciev: string | any;
	userRoleType: UserRoleType;
	userRole: UserRole | null;
	valueSelectRole: any;
	rootUserRole: RootUserRoles;
	userNameList: any[];
	userGrid: boolean;
	activeAuthor: boolean;
	activeReciever: boolean;
	isActiveModal: boolean;
	isActiveModalAuthor: boolean;
	isActiveModalReciever: boolean;
	selectedUser: string | null;
	selectedUserId: string | null;
	roleAuthorId: string | null;
	roleRecieverId: string | null;
	userAuthorId: string | null;
	userRecieverId: string | null;
	modalRecordActive: boolean;
	selected: any;

	constructor() {
		makeObservable(this, {
			dataOperation: observable,
			dataRecord: observable,
			dataGridOperation: observable,
			dataGridRecord: observable,
			checkRecord: observable,
			checkOperation: observable,
			setSelectedNameRole: observable,
			setSelectedRoleId: observable,
			selectedValueAuthor: observable,
			selectedValueReciev: observable,
			userRoleType: observable,
			userRole: observable,
			valueSelectRole: observable,
			rootUserRole: observable,
			userNameList: observable,
			userGrid: observable,
			activeAuthor: observable,
			activeReciever: observable,
			isActiveModal: observable,
			isActiveModalAuthor: observable,
			isActiveModalReciever: observable,
			selectedUser: observable,
			selectedUserId: observable,
			roleAuthorId: observable,
			roleRecieverId: observable,
			userAuthorId: observable,
			userRecieverId: observable,
			modalRecordActive: observable,
			selected: observable,
			setValue: action
		});

		this.dataOperation = [];
		this.dataRecord = [];
		this.dataGridOperation = [];
		this.dataGridRecord = [];
		this.checkOperation = false;
		this.checkRecord = false;
		this.setSelectedNameRole = "";
		this.setSelectedRoleId = "";
		this.selectedValueAuthor = "";
		this.selectedValueReciev = "";
		this.userRoleType = UserRoleType.FunctionalRole;
		this.userRole = new UserRole();
		this.valueSelectRole = "";
		this.rootUserRole = new RootUserRoles();
		this.userNameList = [];
		this.userGrid = false;
		this.activeAuthor = false;
		this.activeReciever = false;
		this.isActiveModal = false;
		this.isActiveModalAuthor = false;
		this.isActiveModalReciever = false;
		this.selectedUser = "";
		this.selectedUserId = "";
		this.roleAuthorId = "";
		this.roleRecieverId = "";
		this.userAuthorId = "";
		this.userRecieverId = "";
		this.modalRecordActive = false;
		this.selected = "";
	}

	ColumnsRecord = [
		{
			name: "creatorRole",
			caption: "Автор записи",
			width: 20,
			type: FilterColumnType.String,
			getLink: (id: string) => "",
			sortColumn: "creatorRole"
		},
		{
			name: "getterRole",
			caption: "Получатель права",
			width: 30,
			type: FilterColumnType.String,
			getLink: (id: string) => "",
			sortColumn: "getterRole"
		},
		{
			name: "canRead",
			caption: "Чтение",
			width: 20,
			type: FilterColumnType.Boolean,
			sortColumn: "canRead",
			check: true,
			onChecked: (entityId: any, checked: boolean, id: string) => this.canReadRecord(entityId, checked, id)
		},
		{
			name: "canEdit",
			caption: "Редактирование",
			width: 20,
			type: FilterColumnType.Boolean,
			sortColumn: "canEdit",
			check: true,
			onChecked: (entityId: any, checked: boolean, id: string) => this.canEditRecord(entityId, checked, id)
		},
		{
			name: "canDelete",
			caption: "Удаление",
			width: 10,
			type: FilterColumnType.Boolean,
			sortColumn: "canDelete",
			check: true,
			onChecked: (entityId: any, checked: boolean, id: string) => this.canDeleteRecord(entityId, checked, id)
		}
	];

	ColumnsOperation = [
		{
			name: "role",
			caption: "Роль/Пользователь",
			width: 30,
			type: FilterColumnType.String,
			sortColumn: "role"
		},
		{
			name: "canCreate",
			caption: "Создание",
			width: 15,
			type: FilterColumnType.Boolean,
			sortColumn: "canCreate",
			check: true,
			onChecked: (entityId: any, checked: boolean, id: string) => this.canCreateOperation(entityId, checked, id)
		},
		{
			name: "canRead",
			caption: "Чтение",
			width: 15,
			type: FilterColumnType.Boolean,
			sortColumn: "canRead",
			check: true,
			onChecked: (entityId: any, checked: boolean, id: string) => this.canReadOperation(entityId, checked, id)
		},
		{
			name: "canEdit",
			caption: "Редактирование",
			width: 15,
			type: FilterColumnType.Boolean,
			sortColumn: "canEdit",
			check: true,
			onChecked: (entityId: any, checked: boolean, id: string) => this.canEditOperation(entityId, checked, id)
		},
		{
			name: "canDelete",
			caption: "Удаление",
			width: 25,
			type: FilterColumnType.Boolean,
			sortColumn: "canDelete",
			check: true,
			onChecked: (entityId: any, checked: boolean, id: string) => this.canDeleteOperation(entityId, checked, id)
		}
	];

	setValue(value: any, fieldName: string) {
		Reflect.set(this, fieldName, value);
	}

	async getOperationRights(entityId: string) {
		let data = null;
		if (entityId) {
			const response = await api.http.administration.recordsRight(entityId).post(data);
			data = response.data;
		}
		if (data) {
			this.setValue(data, "dataOperation");
			this.getDataToGridOperation();
		}
	}

	async getRecordRights(entityId: string) {
		let data = null;
		if (entityId) {
			const response = await api.http.administration.recordsRight(entityId).post(data);
			data = response.data;
		}
		if (data) {
			this.setValue(data, "dataRecord");
			this.getDataToGridRecord();
		}
	}

	getDataToGridOperation() {
		let newDataToGrid: any[] = [];
		this.dataOperation.forEach((e) => {
			newDataToGrid.push({
				id: e.id,
				entityId: e.entityId,
				role: this.getRoleAndUser(e.role, e.user),
				canRead: e.canRead,
				canCreate: e.canCreate,
				canDelete: e.canDelete,
				canEdit: e.canEdit,
				checked: e.checked
			});
		});
		this.setValue(newDataToGrid, "dataGridOperation");
	}

	setCheckedByIdToDataGridOperation(id: string, value: boolean) {
		let newDataToGrid: any[] = [];
		this.dataOperation.forEach((e) => {
			if (e.id === id)
				newDataToGrid.push({
					id: e.id,
					entityId: e.entityId,
					role: this.getRoleAndUser(e.role, e.user),
					canRead: e.canRead,
					canCreate: e.canCreate,
					canDelete: e.canDelete,
					canEdit: e.canEdit,
					checked: value
				});
			else
				newDataToGrid.push({
					id: e.id,
					entityId: e.entityId,
					role: this.getRoleAndUser(e.role, e.user),
					canRead: e.canRead,
					canCreate: e.canCreate,
					canDelete: e.canDelete,
					canEdit: e.canEdit,
					checked: e.checked
				});
		});
		this.setValue(newDataToGrid, "dataGridOperation");
	}

	getRoleAndUser(role: string | null | undefined, user: string | null | undefined) {
		return role ?? user;
	}

	getDataToGridRecord() {
		let newDataToGrid: any[] = [];
		this.dataRecord.forEach((e) => {
			newDataToGrid.push({
				id: e.id,
				entityId: e.entityId,
				creatorRole: this.getRoleAndUser(e.creatorRole, e.creatorUser),
				getterRole: this.getRoleAndUser(e.getterRole, e.getterUser),
				canRead: e.canRead,
				canDelete: e.canDelete,
				canEdit: e.canEdit,
				checked: e.checked
			});
		});
		this.setValue(newDataToGrid, "dataGridRecord");
	}

	setCheckedByIdToDataGridRecord(id: string, value: boolean) {
		let newDataToGrid: any[] = [];
		this.dataGridRecord.forEach((e) => {
			if (e.id === id)
				newDataToGrid.push({
					id: e.id,
					entityId: e.entityId,
					creatorRole: this.getRoleAndUser(e.creatorRole, e.creatorUser),
					getterRole: this.getRoleAndUser(e.getterRole, e.getterUser),
					canRead: e.canRead,
					canDelete: e.canDelete,
					canEdit: e.canEdit,
					checked: value
				});
			else
				newDataToGrid.push({
					id: e.id,
					entityId: e.entityId,
					creatorRole: this.getRoleAndUser(e.creatorRole, e.creatorUser),
					getterRole: this.getRoleAndUser(e.getterRole, e.getterUser),
					canRead: e.canRead,
					canDelete: e.canDelete,
					canEdit: e.canEdit,
					checked: e.checked
				});
		});
		this.setValue(newDataToGrid, "dataGridRecord");
	}

	async canCreateOperation(entityId: string, check: boolean, id: string) {
		const data = { entityId: entityId, id: id, check: check };
		await api.http.administration.operationCheckCanCreate().post(data);
		this.getOperationRights(entityId);
	}

	async canReadOperation(entityId: string, check: boolean, id: string) {
		const data = { entityId: entityId, id: id, check: check };
		await api.http.administration.operationCheckCanRead().post(data);
		this.getOperationRights(entityId);
	}

	async canDeleteOperation(entityId: string, check: boolean, id: string) {
		const data = { entityId: entityId, id: id, check: check };
		await api.http.administration.operationCheckCanDelete().post(data);
		this.getOperationRights(entityId);
	}

	async canEditOperation(entityId: string, check: boolean, id: string) {
		const data = { entityId: entityId, id: id, check: check };
		await api.http.administration.operationCheckCanEdit().post(data);
		this.getOperationRights(entityId);
	}

	async canReadRecord(entityId: string, check: boolean, id: string) {
		const data = { entityId: entityId, id: id, check: check };
		await api.http.administration.recordCheckCanRead().post(data);
		this.getRecordRights(entityId);
	}

	async canDeleteRecord(entityId: string, check: boolean, id: string) {
		const data = { entityId: entityId, id: id, check: check };
		await api.http.administration.recordCheckCanDelete().post(data);
		this.getRecordRights(entityId);
	}

	async canEditRecord(entityId: string, check: boolean, id: string) {
		const data = { entityId: entityId, id: id, check: check };
		await api.http.administration.recordCheckCanEdit().post(data);
		this.getRecordRights(entityId);
	}

	async checkByRecords(check: boolean, entityId: string) {
		await api.http.administration.administrationByRecords().post({ entityId: entityId, enable: check });
		this.getCheckRecord(entityId);
	}

	checkByOperation = async (check: boolean, entityId: string) => {
		await api.http.administration.administrationByEntityOperationsEnable().post({ entityId: entityId, enable: check });
		this.getCheckOperation(entityId);
	};

	async getCheckRecord(entityId: string) {
		let getCheck = null;
		if (entityId) {
			const response = await api.http.administration.getRecord(entityId).get();
			getCheck = response.data;
		}
		if (getCheck) {
			this.setValue(getCheck.data, "checkRecord");
		}
	}

	async getCheckOperation(entityId: string) {
		let getCheck = null;
		if (entityId) {
			const response = await api.http.administration.getOperations(entityId).get();
			getCheck = response.data;
		}
		if (getCheck) {
			this.setValue(getCheck.data, "checkOperation");
		}
	}

	onClick(value: UserRole) {
		if (value) {
			functionalRolesMainPanelStore.setValue(value, "userRole");
			functionalRolesMainPanelStore.setUsersById(value.data!.id);
			functionalRolesMainPanelStore.loadOrgRoles(value.data?.id!);
		} else {
			userStore.load(null);
			functionalRolesMainPanelStore.setValue(userStore.data, "filteredUserStore");
			functionalRolesMainPanelStore.setValue(null, "userRole");
			functionalRolesMainPanelStore.setValue([], "orgRolesStore");
		}
	}

	getUser() {
		userStore.load(null);
		const users = userStore.data;
		const userList = users.map((user) => ({
			id: user.id,
			name: user.name
		}));
		this.setValue(userList, "userNameList");
	}
	handleSelectUserRole(userRole: UserRole) {
		this.setValue(userRole, "userRole");
		this.setValue(userRole.data?.name!, "setSelectedNameRole");
		this.setValue(userRole.data?.id!, "setSelectedRoleId");
	}

	onSelectRole(event: any) {
		if (event.id === rightsItemsIds.FuncRoles) {
			this.setValue(false, "userGrid");
			this.setValue(event.name, "valueSelectRole");
			this.setValue(UserRoleType.FunctionalRole, "userRoleType");
			if (functionalRolesMainPanelStore.userRole) {
				this.setValue(functionalRolesMainPanelStore.userRole, "userRole");
			}
		} else if (event.id === rightsItemsIds.OrgRoles) {
			this.setValue(false, "userGrid");
			this.setValue(event.name, "valueSelectRole");
			this.setValue(UserRoleType.OrganizationRole, "userRoleType");
			if (orgStructureMainPanelStore.userRole) {
				this.setValue(orgStructureMainPanelStore.userRole, "userRole");
			}
		} else if (event.id === rightsItemsIds.Users) {
			this.setValue(event.name, "valueSelectRole");
			this.setValue(true, "userGrid");
			this.getUser();
		}
	}

	async createOperation(entityId: string) {
		const roleId = this.setSelectedRoleId;
		if (!this.modalRecordActive) {
			if (this.setSelectedNameRole && this.setSelectedRoleId && !this.selectedUserId) {
				const data = {
					roleId: roleId,
					userId: null,
					canCreate: false,
					canEdit: false,
					canRead: false,
					canDelete: false,
					position: 0,
					entityId: entityId
				};
				await api.http.administration.entityOperations().post(data);
				this.setValue("", "setSelectedNameRole");
				this.setValue("", "setSelectedRoleId");
				this.setValue(false, "isActiveModal");
				this.getOperationRights(entityId);
			} else if (this.selectedUser && this.selectedUserId) {
				const data = {
					roleId: null,
					userId: this.selectedUserId,
					canCreate: false,
					canEdit: false,
					canRead: false,
					canDelete: false,
					position: 0,
					entityId: entityId
				};
				await api.http.administration.entityOperations().post(data);
				this.setValue("", "selectedUser");
				this.setValue("", "selectedUserId");
				this.setValue(false, "isActiveModal");
				this.getOperationRights(entityId);
			}
		} else {
			this.addRecord();
		}
	}
	async deleteOperation(id: string) {
		try {
			await api.http.administration.deleteEntityOperations(id).delete();
		} catch (error) {
			console.error(error);
		}
	}

	addRecord() {
		if (this.isActiveModalAuthor && this.modalRecordActive && !this.isActiveModalReciever) {
			this.setValue("", "selectedValueAuthor");
			if (this.isActiveModal) {
				if (this.setSelectedNameRole && this.setSelectedRoleId) {
					this.setValue(this.setSelectedNameRole, "selectedValueAuthor");
					this.setValue(this.setSelectedRoleId, "roleAuthorId");
					this.setValue("", "setSelectedNameRole");
					this.setValue("", "setSelectedRoleId");
					this.setValue("", "selectedUser");
					this.setValue("", "selectedUserId");
					this.activeModalEnable(false);
				} else if (this.selectedUser && this.selectedUserId) {
					this.setValue(this.selectedUser, "selectedValueAuthor");
					this.setValue(this.selectedUserId, "userAuthorId");
					this.setValue("", "setSelectedNameRole");
					this.setValue("", "setSelectedRoleId");
					this.setValue("", "selectedUser");
					this.setValue("", "selectedUserId");
					this.activeModalEnable(false);
				}
			}
			this.openModalAuthor(false);
		} else if (this.isActiveModalReciever && !this.isActiveModalAuthor) {
			this.selectedValueReciev = "";
			if (this.isActiveModal) {
				if (this.setSelectedNameRole && this.setSelectedRoleId) {
					this.setValue(this.setSelectedNameRole, "selectedValueReciev");
					this.setValue(this.setSelectedRoleId, "roleRecieverId");
					this.setValue("", "setSelectedNameRole");
					this.setValue("", "setSelectedRoleId");
					this.setValue("", "selectedUser");
					this.setValue("", "selectedUserId");
					this.activeModalEnable(false);
				} else if (this.selectedUser && this.selectedUserId) {
					this.setValue(this.selectedUser, "selectedValueReciev");
					this.setValue(this.selectedUserId, "userRecieverId");
					this.setValue("", "setSelectedNameRole");
					this.setValue("", "setSelectedRoleId");
					this.setValue("", "selectedUser");
					this.setValue("", "selectedUserId");
					this.activeModalEnable(false);
				}
			}
			this.openModalReciever(false);
		}
	}
	async createRecord(entityId: string) {
		if (!this.isActiveModal) {
			if (this.selectedValueAuthor && this.selectedValueReciev) {
				const data = {
					creatorRoleId: this.roleAuthorId,
					creatorUserId: this.userAuthorId,
					getterRoleId: this.roleRecieverId,
					getterUserId: this.userRecieverId,
					canEdit: false,
					canRead: false,
					canDelete: false,
					position: 0,
					entityId: entityId
				};
				await api.http.administration.entityRecords().post(data);
				this.openModalRecord(false);
				this.setValue("", "selectedValueAuthor");
				this.setValue("", "selectedValueReciev");
				this.getRecordRights(entityId);
			}
		}
	}
	async deleteRecord(id: string) {
		try {
			await api.http.administration.deleteEntityRecords(id).delete();
		} catch (error) {
			console.error(error);
		}
	}

	activeModalEnable(data: boolean) {
		this.setValue(data, "isActiveModal");
	}

	openModalAuthor(data: boolean) {
		this.setValue(data, "isActiveModalAuthor");
	}

	openModalReciever(data: boolean) {
		this.setValue(data, "isActiveModalReciever");
	}

	openModalRecord(data: boolean) {
		this.setValue(data, "modalRecordActive");
		if (!data) {
			this.setValue("", "selectedValueAuthor");
			this.setValue("", "selectedValueReciev");
		}
	}

	handleValueClick = (user: any) => {
		this.setValue(user["name"], "selectedUser");
		this.setValue(user["id"], "selectedUserId");
		this.setValue(user, "selected");
	};
}

const accessRightLink = new AccessRightLinkStore();

export default accessRightLink;
