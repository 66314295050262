import { makeAutoObservable } from 'mobx'
import Stopwatch from '../models/stopwatch'
import { sessionStore } from 'store'

/**
 * Store для управления коллекцией секундомеров (Stopwatch).
 */
class StopwatchStore {
	/**
	 * @type {Map<string, Stopwatch>}
	 * Коллекция секундомеров, где ключ - recordId, а значение - экземпляр Stopwatch.
	 */
	stopwatches: Map<string, Stopwatch> = new Map()

	constructor() {
		makeAutoObservable(this)
		this.loadStopwatchesFromStorage()
	}

	/**
	 * Запускает секундомер для указанного recordId.
	 * Если секундомер не существует, он будет создан.
	 * @param {string} recordId - Уникальный идентификатор записи.
	 */
	startStopwatch(recordId: string) {
		if (!this.stopwatches.has(recordId)) {
			const stopwatch = new Stopwatch(recordId)
            stopwatch.start();
			this.stopwatches.set(recordId, stopwatch)
		} else {
			this.stopwatches.get(recordId)?.start()
		}
		this.saveStopwatchesToStorage()
	}

	/**
	 * Приостанавливает секундомер для указанного recordId.
	 * @param {string} recordId - Уникальный идентификатор записи.
	 */
	pauseStopwatch(recordId: string) {
		this.stopwatches.get(recordId)?.pause()
		this.saveStopwatchesToStorage()
	}

	/**
	 * Останавливает секундомер и возвращает затраченное время в минутах.
	 * @param {string} recordId - Уникальный идентификатор записи.
	 * @returns {number} Затраченное время в минутах.
	 */
	stopStopwatch(recordId: string): number {
		const stopwatch = this.stopwatches.get(recordId);		
		if (stopwatch) {
			const minutes = stopwatch.stop()
			this.saveStopwatchesToStorage()
			return minutes
		}
		return 0
	}

	/**
	 * Удаляет секундомер из коллекции.
	 * @param {string} recordId - Уникальный идентификатор записи.
	 */
	removeStopwatch(recordId: string) {
		this.stopwatches.delete(recordId)
		this.saveStopwatchesToStorage()
	}

	/**
	 * Сохраняет все секундомеры в LocalStorage.
	 */
	saveStopwatchesToStorage() {
		const stopwatchesData = Array.from(this.stopwatches.values()).map(
			stopwatch => stopwatch.serialize(),
		)
		sessionStore.setDataToLocalStorage('stopwatches', JSON.stringify(stopwatchesData));
	}

	/**
	 * Загружает секундомеры из LocalStorage и восстанавливает их состояние.
	 */
	loadStopwatchesFromStorage() {
		const stopwatchesData = JSON.parse(
			sessionStore.getDataFromLocalStorage('stopwatches') || '[]',
		)
		stopwatchesData.forEach((data: any) => {
			const stopwatch = Stopwatch.deserialize(data)
			this.stopwatches.set(stopwatch.recordId, stopwatch)
		})
	}
}

export const stopwatchStore = new StopwatchStore()
