import { Api } from "../../../class-api";

class Section extends Api {
	basicPath: string;
	constructor() {
		super();
		this.basicPath = "/api/Section";
	}

	entitySectionDataList = () => {
		this.setPath(`${this.basicPath}/section-list`);
		return this;
	};
	section = (sectionId?: string) => {
		this.setPath(`${this.basicPath}/section/${sectionId}`);
		return this;
	};
}

const section = new Section();

export { section };
