import styles from "components/select/select.module.css";
import stylesHierarchical from "../ui/hierarchical-select.module.scss";

export enum HierarchicalStyles {
    Primary,
    Seccond,
    Opacity,
    AdvancedFilter
}

export interface IHierarchicalSelectStyles {
    value: IValueStyles;
}

interface IValueStyles {
    classNames: string;
    focus: string;
    invalid: string;
    // success: string;
}

const Styles = {
    [HierarchicalStyles.Primary]: {
        value: {
            classNames: `${stylesHierarchical.inputPrimary} `,
            focus: `${stylesHierarchical.focusPrimary} `,
            invalid: `${stylesHierarchical.invalidPrimary} `,
            // success: `${stylesHierarchical.successPrimary} `
        }
    },
    [HierarchicalStyles.Seccond]: {
        value: {
            classNames: `${styles.inputWrapper} `,
            focus: "",
            invalid: `${styles.invalid} `,
            // success: ""
        }
    },
    [HierarchicalStyles.Opacity]: {
        value: {
            classNames: `${stylesHierarchical.inputOpacity} `,
            focus: "",
            invalid: ``,
            // success: ""
        }
    },
    [HierarchicalStyles.AdvancedFilter]: {
        value: {
            classNames: `${stylesHierarchical.advancedFilter} `,
            focus: ` ${stylesHierarchical.advancedFilterFocus} `,
            invalid: ``,
        }
    },
}

export default Styles;