import { IGridItem } from "components/select/types";
import { AdditionalInfoOfField } from "../../quick-view-designer/components/quick-view-settings/data";

export function additionalInfoOfFieldsCardDesigner(gridItemsArray: Array<IGridItem>): Map<string, AdditionalInfoOfField> {
    const map = new Map<string, AdditionalInfoOfField>();
    gridItemsArray.forEach((gridItem, index) => {
        map.set(gridItem.columnId ?? index.toString(), {
            columnType: gridItem?.columnType,
            columnTitle: gridItem?.displayValue,
            isRequired: gridItem?.isRequired,
            columnName: gridItem.name
        })
    })
    return map;
}