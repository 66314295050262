import { observer } from "mobx-react-lite";
import { useCallback, useMemo, useState } from "react";
import { v4 } from "uuid";
import { toJS } from "mobx";

import { modalController } from "features/modals";
import fieldConfigurationStore from "../../../field-configuration-store";

import { EditNumeratorPopup } from "../edit-numerator-popup";
import { ConditionElement, EditNumeratorConditionPopupBody } from "./components";
import { Button, Hint } from "sale-bridge-ui-kit";

import { MaskState } from "types/entity";

import { EmptyStateNumeratorCondition } from "shared";

import styles from "./conditions.module.scss";

export const Conditions = observer(() => {
	const [idModal] = useState<string>(v4());

	const closeConfirm = useCallback((e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
		e?.stopPropagation();
		modalController.modalRemove(idModal);
	}, []);

	const handleAddCondition = useCallback(() => {
		modalController.popupAdd({
			id: idModal,
			layout: (
				<EditNumeratorPopup close={closeConfirm}>
					<EditNumeratorConditionPopupBody close={closeConfirm} />
				</EditNumeratorPopup>
			),
			closeFunc: closeConfirm
		});
	}, []);

	const isDisabledAddCondition = useMemo(() => {
		if (fieldConfigurationStore.numeratorConfig.maskItems.find((mask) => mask.maskState !== MaskState.Default)) return false;
		else return true;
	}, [toJS(fieldConfigurationStore.numeratorConfig.maskItems)]);

	return (
		<div className={styles.numeratorConditions}>
			{fieldConfigurationStore.numeratorConfig.conditions.length == 0 ? (
				<>
					<EmptyStateNumeratorCondition />
					<div className={styles.emptyStateCaption}>
						Условий для нумератора пока нет.
						<br />
						Нажмите на кнопку, чтобы добавить
					</div>
				</>
			) : (
				fieldConfigurationStore.numeratorConfig.conditions.map((condition) => (
					<ConditionElement key={condition.id} condition={condition} />
				))
			)}
			{isDisabledAddCondition ? (
				<Hint hintBody="Добавьте ещё одну маску заполнения" startPosition="top">
					<Button
						text="Добавить условие"
						size="small"
						variant={isDisabledAddCondition ? "disabled" : "secondary"}
						border={false}
						link={false}
						loading={false}
						leftIcon="PlusSquareFill"
						onClick={handleAddCondition}
					/>
				</Hint>
			) : (
				<Button
					text="Добавить условие"
					size="small"
					variant={isDisabledAddCondition ? "disabled" : "secondary"}
					border={false}
					link={false}
					loading={false}
					leftIcon="PlusSquareFill"
					onClick={handleAddCondition}
				/>
			)}
		</div>
	);
});
