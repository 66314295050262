import { ColumnType } from "entities/ColumnType";
import { FieldValidationState, Item } from "types";

export function getFieldTitle(nameField: string) {
	switch (nameField) {
		case "name": {
			return "Название условия";
		}
		case "columnName": {
			return "Если";
		}
		case "columnValue": {
			return "Равно";
		}
		case "mask": {
			return "То применить";
		}
	}
}

export const TextProps = {
	type: "text",
	textVariant: "outlined",
	counter: 50,
	placeholder: "Значение",
	isClearing: true
};

export const SelectProps = {
	type: "select",
	textVariant: "outlined",
	placeholder: "Значение",
	isClearing: true,
	counter: undefined,
	items: []
};

export const BooleanProps = {
	type: "boolean"
};

export const DateProps = {
	type: "date",
	textVariant: "outlined"
};

export const DateTimeProps = {
	type: "dateTime",
	textVariant: "outlined"
};

export const TimeProps = {
	type: "time",
	textVariant: "outlined"
};

export const DecimalProps = {
	type: "number",
	textVariant: "outlined",
	subType: "decimal"
};

export const IntegerProps = {
	type: "number",
	textVariant: "outlined",
	subType: "integer"
};

export const fieldPropsUiKitByType: Record<string, any> = {
	[ColumnType.Guid]: TextProps,
	[ColumnType.String]: TextProps,
	[ColumnType.Boolean]: BooleanProps,
	[ColumnType.Lookup]: SelectProps,
	[ColumnType.Date]: DateProps,
	[ColumnType.DateTime]: DateTimeProps,
	[ColumnType.Time]: TimeProps,
	[ColumnType.Integer]: IntegerProps,
	[ColumnType.Decimal]: DecimalProps,
	[ColumnType.Double]: DecimalProps
};

export type AutoFieldByTypeProps = {
	onChange: (value: string | Item) => void;
	columnId: string | undefined;
	columnValue: any;
	columnType: ColumnType | undefined;
	lookupTable: string | undefined | null;
	isVirtualLookup: boolean;
	virtualLookupValues: Item[];
	isVisibleLabel: boolean;
	columnValueIsInvalid?: boolean;
	isDisabled: boolean;
	validation?: FieldValidationState;
};
