import { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";

import { v4 } from "uuid";
import { store } from "store";
import { modalController } from "features/modals";
import detailConfigurationStore, { DetailStoreField } from "./detail-configuration-store";

import { Button } from "sale-bridge-ui-kit";
import { DetailMaster } from "features/detail-master";
import { DetailFields, DetailLinkField } from "./components";

import { IGridItem } from "components/select/types";
import { ColumnType } from "entities/ColumnType";
import { ModalType } from "features/modals/viewer/modal-viewer";
import { GridItem } from "types/entity";
import { INFO_DETAIL } from "../field-configuration/types";

import { CloseMaxi } from "shared";
import TypeIcon from "features/type-icon/type-icon";

import styles from "./detail-configuration.module.scss";

export const DetailConfiguration = observer(
	(props: { detailId?: string; close: () => void; closeAll: () => void; position: { cellX: number; cellY: number } }) => {
		const [idDetailMaster] = useState<string>(v4());

		useEffect(() => {
			detailConfigurationStore.setValue(DetailStoreField.detailId, props.detailId);
			detailConfigurationStore.loadAll();
		}, []);

		useEffect(() => {
			detailConfigurationStore.setValueWithoutTrackingChanges("x", props.position.cellX);
			detailConfigurationStore.setValueWithoutTrackingChanges("y", props.position.cellY);
		}, [props.position.cellX, props.position.cellY]);

		const handleSaveClick = useCallback(async () => {
			if (props.detailId) {
				detailConfigurationStore.updateConfigForDetail(props.detailId);
			} else {
				await detailConfigurationStore.createConfigForDetail();
			}

			props.closeAll();
		}, [props.closeAll, detailConfigurationStore.detailTitle]);

		const handleClose = useCallback(() => {
			if (detailConfigurationStore.hasChanges) {
				props.close();
			} else {
				props.closeAll();
			}
		}, [props.close]);

		const closeDetailMasterModal = useCallback(() => {
			modalController.modalRemove(idDetailMaster);
			store.options.isDisabledConstructorInSectionWizzard = false;
		}, []);

		const getLinkColumn = useCallback(() => {
			const findedLookup = detailConfigurationStore.lookupList.find(
				(lookup) => lookup.name === detailConfigurationStore.virtualDetail?.entityLinkColumn
			);

			let linkField: IGridItem = {
				name: "",
				displayValue: ""
			};
			if (findedLookup) {
				linkField = {
					gridItemId: v4(),
					columnId: v4(),
					name: detailConfigurationStore.detailColumn?.name!,
					displayValue: findedLookup.displayValue ?? findedLookup.title ?? detailConfigurationStore.detailColumn?.name!,
					icon: <TypeIcon type={ColumnType.Lookup} />
				};
			}
			return linkField;
		}, [detailConfigurationStore.virtualDetail?.entityLinkColumn, detailConfigurationStore.detailColumn]);

		const saveGridForDetail = useCallback((grid: GridItem[]) => {
			detailConfigurationStore.setValue(DetailStoreField.inner, grid);
			closeDetailMasterModal();
			detailConfigurationStore.validDetail();
		}, []);

		const handleFieldSettingsButtonClick = useCallback(async () => {
			store.options.isDisabledConstructorInSectionWizzard = true;
			let gridItem: GridItem = {
				x: -1,
				y: 0,
				gridItemId: "",
				width: 0,
				height: 0
			};

			if (props.detailId) {
				const config = detailConfigurationStore.getDetailConfigForOldDetail();
				modalController.popupAdd({
					id: idDetailMaster,
					type: ModalType.LARGE_POPUP,
					layout: (
						<DetailMaster
							close={closeDetailMasterModal}
							detailConfig={config}
							boxFields={detailConfigurationStore.boxFields}
							onSave={saveGridForDetail}
						/>
					),
					closeFunc: closeDetailMasterModal
				});
			} else {
				const column = getLinkColumn();
				if (column.gridItemId) {
					gridItem = await detailConfigurationStore.getGridItemForNewDetail(column);
				} else {
					gridItem = await detailConfigurationStore.getGridItemForNewDetail();
				}
				modalController.popupAdd({
					id: idDetailMaster,
					type: ModalType.LARGE_POPUP,
					layout: (
						<DetailMaster
							close={closeDetailMasterModal}
							detailConfig={gridItem.detailConfig}
							boxFields={detailConfigurationStore.boxFields}
							onSave={saveGridForDetail}
						/>
					),
					closeFunc: closeDetailMasterModal
				});
			}
		}, [idDetailMaster, getLinkColumn, detailConfigurationStore.boxFields]);

		const dialogFooterButtons = useMemo(
			() => (
				<div className={styles.dialogFooter}>
					<div className={styles.dialogFooterDivider} />
					<div className={styles.footerButtonsBlock}>
						{!detailConfigurationStore.detailIsLoading && (
							<div className={styles.leftFooterButtonsBlock}>
								{detailConfigurationStore.isVisibleFieldSettingsButton && (
									<Button
										text="Настроить поля в детали"
										size="small"
										variant="secondary"
										border={false}
										link={false}
										loading={false}
										onClick={handleFieldSettingsButtonClick}
									/>
								)}
							</div>
						)}
						<div className={styles.rightFooterButtonsBlock}>
							<Button
								text="Отменить"
								size="small"
								variant="backless"
								border
								link={false}
								loading={false}
								onClick={handleClose}
							/>
							<Button
								text="Сохранить"
								size="small"
								variant={detailConfigurationStore.isDisabledSaveDetailButton ? "disabled" : "primary"}
								border
								link={false}
								loading={false}
								onClick={handleSaveClick}
							/>
						</div>
					</div>
				</div>
			),
			[
				detailConfigurationStore.isDisabledSaveDetailButton,
				detailConfigurationStore.isVisibleFieldSettingsButton,
				detailConfigurationStore.detailIsLoading
			]
		);

		return (
			<>
				{detailConfigurationStore.detailIsLoading && (
					<div className={styles.modalOverlay}>
						<svg className={styles.circleLoader} viewBox="0 0 50 50">
							<circle
								className={styles.circle}
								cx="25"
								cy="25"
								r="20"
								fill="none"
								stroke={`var(--color-gray-300)`}
								strokeWidth="3"
							/>
						</svg>
					</div>
				)}
				<div className={styles.headerModal}>
					<span className={styles.titleModal}>Деталь</span>
					<CloseMaxi className={styles.closeButton} onClick={handleClose} />
				</div>
				<div className={styles.dialogBody}>
					<div className={styles.infoContainer}>
						<span className={styles.infoCaption}>{INFO_DETAIL}</span>
					</div>
					<DetailFields detailId={props.detailId} close={props.close} closeAll={props.closeAll} />
					<DetailLinkField />
				</div>
				{dialogFooterButtons}
			</>
		);
	}
);
