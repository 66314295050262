import { api } from "shared";

import IFilter from "entities/filter/IFilter";

export async function loadLookup(table: string, filter: IFilter | null, limit?: number): Promise<any[] | null> {
	const data: any = {
		filter: filter,
		offset: 0,
		limit: limit,
		entityName: table,

		canbanColumn: null,
		columnNames: [],
		sort: null,
		staticGroupId: null
	};

	try {
		const items = await api.http.entity.recordsListWithColumns().post(data);
		return items.data.data.records;
	} catch (error) {
		console.error("An error occurred:", error);
		return null;
	}
}

export async function loadEntity(table: string, filter?: IFilter): Promise<any[]> {
	const data: any = {
		filter: filter,
		offset: 0,
		limit: 7,
		entityName: table
	};
	const items = await api.http.entity.recordsList().post(data);
	return items.data.data;
}

export async function loadEntityWithColumns(table: string, filter?: IFilter, columnNames?: Array<string>): Promise<any> {
	const data: any = {
		filter: filter,
		offset: 0,
		columnNames: columnNames ?? [],
		entityName: table
	};
	const items = await api.http.entity.recordsListWithColumns().post(data);
	return items.data.data;
}
