import { action, computed, makeObservable, observable } from "mobx";

import { api } from "shared";

import FileEntity from "./FileEntity";
import { LoadingState } from "entities/ListStore";
import { ImportStep } from "entities/import/type/ImportStep";
import { IErrorUploadFile } from "entities/import/type/IErrorUploadFile";

export default class ImportStore {
	schema: string;
	data: FileEntity[];
	importStep: ImportStep;
	loadingState: LoadingState;
	error?: string;
	errorCode?: string;
	uploading: boolean;
	count: number;
	maxCount: number;
	checkedList: FileEntity[];
	errorUploadFile: IErrorUploadFile[];
	fileName: string;
	moreFiles: boolean;
	maxFileSize: number;

	constructor(schema: string) {
		makeObservable(this, {
			data: observable,
			importStep: observable,
			loadingState: observable,
			error: observable,
			count: observable,
			checkedList: observable,
			errorUploadFile: observable,
			setValue: action,
			isLoading: computed,
			isLoaded: computed,
			isError: computed,
			isUploadStep: computed,
			isLoadingStep: computed,
			isReadyToUpload: computed,
			load: action,
			upload: action,
			download: action,
			deleteFile: action,
			getMaxFileSize: action
		});
		this.schema = schema;
		this.data = [];
		this.importStep = ImportStep.Viewing;
		this.loadingState = LoadingState.NotAsked;
		this.error = undefined;
		this.uploading = false;
		this.count = 0;
		this.checkedList = [];
		this.errorUploadFile = [];
		this.maxCount = 0;
		this.fileName = "";
		this.moreFiles = false;
		this.maxFileSize = 0;
	}

	setValue(value: string | boolean | LoadingState | any, fieldName: string) {
		Reflect.set(this, fieldName, value);
	}

	get isLoading(): boolean {
		return this.loadingState === LoadingState.Loading;
	}

	//@computed
	get isLoaded(): boolean {
		return this.loadingState === LoadingState.Loaded;
	}

	get isError(): boolean {
		return this.loadingState === LoadingState.Failed || this.importStep === ImportStep.Failed;
	}

	get isUploadStep(): boolean {
		return (
			(this.importStep === ImportStep.Viewing ||
				this.importStep === ImportStep.Completed ||
				(this.importStep === ImportStep.LoadFile && this.count > 0)) &&
			this.count > 0
		);
	}

	get isLoadingStep(): boolean {
		return this.importStep === ImportStep.LoadFile;
	}

	get isReadyToUpload(): boolean {
		return (this.importStep === ImportStep.Upload || this.count === 0) && this.importStep !== ImportStep.LoadFile;
	}

	async uploadFiles(entityId: string, files: FileList) {
		const bitesInMBite = 1024 * 1024;
		if (files && files.length > 0) {
			if (files.length > this.maxCount) {
				this.setValue(`Выберите не более ${this.maxCount} файлов для одновременной загрузки`, "error");
				this.moreFiles = true;
				return;
			}
		}
		const promises = Array.from(files).map(async (file) => {
			const upload = await this.upload(entityId, file);
			return {
				upload: upload,
				fileName: file.name,
				errorMessage: this.error,
				fileSize: `${(file.size / bitesInMBite).toFixed(1)} МБ`
			};
		});
		const uploadFiles = await Promise.all(promises);
		const notUploadedFiles = await uploadFiles.filter((file) => file!.upload === false);
		this.load(entityId);
		this.setValue(ImportStep.Viewing, "importStep");
		this.setValue(notUploadedFiles, "errorUploadFile");
	}

	async upload(entityId: string, file: File): Promise<boolean> {
		this.importStep = ImportStep.Upload;
		const bitesInMBite = 1024 * 1024;

		const maxFileSizeInBytes = this.maxFileSize * bitesInMBite;

		if (file.size > maxFileSizeInBytes) {
			this.fileName = file.name;
			this.setValue(ImportStep.LoadFile, "importStep");
			this.setValue("Слишком большой файл. Максимальный размер: 10Мб.", "error");
			return false;
		}

		try {
			const data = new FormData();
			data.append("entityName", this.schema);
			data.append("entityId", entityId);
			data.append("fileData", file);
			this.setValue(ImportStep.LoadFile, "importStep");
			const response = await api.http.file.uploadFile().post(data, { headers: { "Content-Type": file.type } });

			if (response.data.success === true) {
				await this.load(entityId);
				this.setValue(ImportStep.Viewing, "importStep");
				return true;
			} else {
				this.fileName = file.name;
				this.handleResponseError(response.data.error);
				this.setValue(ImportStep.LoadFile, "importStep");
				return false;
			}
		} catch (e: any) {
			this.handleResponseError(e.response?.data?.error);
			this.moreFiles = false;
			this.setValue(ImportStep.LoadFile, "importStep");
			return false;
		}
	}

	handleResponseError(error: any) {
		switch (error?.errorCode) {
			case 1:
				this.setValue("Неподдерживаемые форматы файла: : .mp3, .exe, .msi ", "error");
				break;
			case 3:
				this.setValue("Слишком большой файл. Максимальный размер: 10Мб.", "error");
				break;
			default:
				this.setValue(error?.message || "Неизвестная ошибка", "error");
				break;
		}
	}

	base64ToArrayBuffer = (base64: string) => {
		var binaryString = window.atob(base64);
		var binaryLen = binaryString.length;
		var bytes = new Uint8Array(binaryLen);
		for (var i = 0; i < binaryLen; i++) {
			var ascii = binaryString.charCodeAt(i);
			bytes[i] = ascii;
		}
		return bytes;
	};

	handleFileDataType = (ext: string) => {
		switch (ext) {
			case "pdf":
				return "application/pdf";
			case "jpg":
				return "image/jpeg";
			case "jpeg":
				return "image/jpeg";
			case "png":
				return "image/png";
			case "tiff":
				return "image/tiff";
			case "docx":
				return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
			default:
				return "";
		}
	};

	async download(fileId: string): Promise<boolean> {
		try {
			const response = await api.http.file.downloadFile(fileId).get();
			if (response.data.success === true) {
				this.downloadFile(response.data);
				return true;
			} else {
				this.error = response.data.error.message;
			}
			return false;
		} catch (e) {
			this.setValue(ImportStep.Viewing, "importStep");
			this.error = "Что-то пошло не так";
			console.error(e);
			return false;
		}
	}
	async downloadFile(response: any) {
		try {
			let a = document.createElement("a");
			let file = new Blob([this.base64ToArrayBuffer(response.data.fileData)], {
				type: this.handleFileDataType(
					response.data.fileName.toString().substring(response.data.fileName.toString().lastIndexOf(".") + 1)
				)
			});
			a.href = URL.createObjectURL(file);
			a.download = response.data.fileName;
			a.click();
		} catch (e) {
			this.setValue(ImportStep.Viewing, "importStep");
			this.error = "Что-то пошло не так";
			console.error(e);
			return false;
		}
	}

	async deleteFile(fileIds: string | string[], entityId: string): Promise<boolean> {
		try {
			if (typeof fileIds === "string") {
				const response = await api.http.file.deleteFile(fileIds).delete();
				if (response.data.success === true) {
					this.load(entityId);
					this.setValue(ImportStep.Viewing, "importStep");
					return true;
				} else {
					this.error = response.data.error.message;
				}
			} else {
				const response = await api.http.file.deleteList().deleteWithData(fileIds);
				if (response.data.success === true) {
					this.load(entityId);
					this.checkedList = [];
					return true;
				} else this.setValue(response.data.error.message, "error");
			}
			this.setValue(ImportStep.Viewing, "importStep");
			return false;
		} catch (e) {
			this.setValue(ImportStep.Viewing, "importStep");
			console.error(e);
			return false;
		}
	}

	async getMaxFileSize() {
		try {
			const response = await api.http.file.maxFileSize().get();

			if (response.status === 200) {
				this.setValue(response.data.data, "maxFileSize");
			} else {
				this.setValue(response.error, "error");
			}
		} catch (e) {
			console.error(e);
		}
	}

	//@action
	async load(entityId: string) {
		this.setValue(LoadingState.Loading, "loadingState");

		const response = await api.http.file.fileList(this.schema, entityId).get();
		const responseMaxCount = await api.http.file.filesMaxCount().get();

		if (responseMaxCount && responseMaxCount.data) {
			this.maxCount = responseMaxCount.data.data;
		}

		if (response.status === 200) {
			this.setValue(LoadingState.Loaded, "loadingState");
			if (response.data.length === 0) this.importStep = ImportStep.Upload;
			this.data = response.data.data;
			this.count = this.data.length;
			this.data = response.data.data.map((item: any) => {
				return {
					...item,
					createdOn: new Date(Date.parse(item.createdOn)),
					modifiedOn: new Date(Date.parse(item.modifiedOn))
				};
			});
			this.data.sort((a, b) => {
				if (a.createdOn! < b.createdOn!) {
					return 1;
				}
				if (a.createdOn! > b.createdOn!) {
					return -1;
				}
				return 0;
			});
		} else {
			this.setValue(LoadingState.Failed, "loadingState");

			const errorMessage = response.data?.error?.message ?? "Сервис файлов временно недоступен";
			this.setValue(errorMessage, "error");
			this.setValue(response.status, "errorCode");
		}
	}
}
