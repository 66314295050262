import { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { Editor } from "@tinymce/tinymce-react";
import { Editor as TinyMCEEditor, TinyMCE } from "tinymce";
import { toJS } from "mobx";

import { dispatcher, store } from "store";

import { binding, bindingInXml } from "module/reactor/binding/binding";

import IProps from "../../IProps";

import styles from "./wysiwyg-editor.module.scss";
import { LowFirst } from "shared";
export interface WysiwygEditorProps extends IProps {
	wysiwygEditorColumn: string;
}

const WysiwygEditor = (props: WysiwygEditorProps) => {
	const editorRef = useRef<TinyMCEEditor | null>(null);
	const wysiwygEditorColumn = binding(props.services, props.wysiwygEditorColumn);
	const entity = dispatcher.currentRow.get()!;

	const cellTitle = LowFirst(wysiwygEditorColumn);
	const value = entity[cellTitle];

	const wysiwygEditorClassName = classNames(`${styles.wysiwygEditorClassName} `, {
		[`${props.className}`]: props.className
	});

	useEffect(() => {
		/** @description необходимо для сброса текста по нажатию на кнопку "Отменить" */
		if (editorRef.current && editorRef.current.getContent() !== value) {
			editorRef.current.setContent(value ?? "", { no_events: true });
			editorRef.current.undoManager.clear();
		}
	}, [value]);

	const handleEditorChange = useCallback(
		(editValue: string, editor: TinyMCEEditor) => {
			dispatcher.currentRow.set(LowFirst(wysiwygEditorColumn), editValue, toJS(value) !== editValue);
		},
		[value, wysiwygEditorColumn]
	);

	const handleInit = useCallback(
		(event: any, editor: TinyMCEEditor) => {
			editorRef.current = editor;
		},
		[editorRef.current]
	);

	return (
		<div className={wysiwygEditorClassName}>
			<Editor
				tinymceScriptSrc="/tinymce/tinymce.min.js"
				licenseKey="your-license-key"
				onInit={handleInit}
				onEditorChange={handleEditorChange}
				value={value}
				init={{
					height: "100%",
					menubar: false,
					resize: false,
					plugins: [
						"autosave",
						"table",
						"lists",
						"link",
						"accordion",
						"codesample",
						"image",
						"quickbars",
						"help",
						"emoticons",
						"insertdatetime",
						"fullscreen",
						"wordcount"
					],
					toolbar:
						"undo redo |" +
						"blocks |" +
						"bold italic forecolor |" +
						"alignleft aligncenter alignright alignjustify |" +
						"bullist numlist |" +
						"table |" +
						"link |" +
						"accordion |" +
						"codesample |" +
						"image |" +
						"removeformat |" +
						"emoticons |" +
						"insertdatetime |" +
						"wordcount |" +
						"fullscreen |" +
						"help",
					content_style: `body { font-size:14px }`,

					table_toolbar:
						"tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol", //действия для работы с таблицей
					lists_indent_on_tab: false, //запрещает табуляцию у списков
					link_context_toolbar: true, // разрешает пользователю тыкать на ссылку без использования CTRL
					details_initial_state: "collapsed", //скрывает все аккордионы при загрузке редактора
					details_serialized_state: "collapsed" //скрывает все аккордионы при сохранении редактора
				}}
			/>
		</div>
	);
};

export default WysiwygEditor;
