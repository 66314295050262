import authStore from "AuthStore";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import classnames from "classnames";
import { v4 } from "uuid";

import SectionRoute from "entities/SectionRoute";

import { loginState } from "./login-state";

import { Button, Field } from "sale-bridge-ui-kit";
import { ChangePassword } from "pages/change-password/change-password";
import ErrorMessage from "./ErrorMessage/ErrorMessage";
import { modalController, Position } from "features/modals";
import { ModalType, ModalViewer } from "features/modals/viewer/modal-viewer";

import { ERROR_5XX, INVATION_EXPIRED, USERNAME_OR_PASSWORD_IS_INCORRECT } from "./login-consts";
import { LoadingState } from "types/entity";

import { LogoSalebridge } from "shared";

import styles from "./login.module.scss";

const Login = observer(() => {
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		if (authStore.userId) {
			authStore.logOut();
		}
		document.addEventListener("keydown", loginState.keyDown);
		return () => document.removeEventListener("keydown", loginState.keyDown);
	}, []);

	const isLoading = useMemo(() => loginState.loadingState === LoadingState.Loading, [loginState.loadingState]);

	const errorMesage = useMemo(
		() =>
			loginState.validation.username.error === USERNAME_OR_PASSWORD_IS_INCORRECT ||
			loginState.validation.password.error === USERNAME_OR_PASSWORD_IS_INCORRECT ? (
				<>
					Пользователь не найден.
					<br />
					Проверьте правильность заполнения
				</>
			) : undefined,
		[loginState.validation?.username?.error, loginState.validation?.password?.error]
	);

	useEffect(() => {
		if (loginState.errorStatus === ERROR_5XX) {
			modalController.notificationAdd({
				id: v4(),
				type: ModalType.ERROR_NOTIFICATION,
				position: Position.CENTER,
				layout: <div>Сервис временно недоступен. Попробуйте позже.</div>,
				allowTimer: true,
				allowDefaultClick: true,
				withBackdrop: false
			});
		}
	}, [loginState.errorStatus]);

	const handleClick = useCallback(async () => {
		try {
			await loginState.logIn();
			if (loginState.isInvalid) {
				return;
			}
			if (!authStore.tokenExpiration || new Date().getTime() > new Date(Date.parse(authStore.tokenExpiration)).getTime()) {
				return;
			}

			if (location.pathname === "/" || location.pathname === "/login") {
				navigate(`/${SectionRoute.Settings}`);
			}
		} catch (error) {
			console.error("Ошибка при логине:", error);
		}
	}, [authStore.tokenExpiration, navigate]);

	const containerClassName = classnames(styles.container, {
		[`${styles.containerDisabled}`]: isLoading
	});

	if (localStorage.getItem("inactiveLink") === "true") {
		loginState.errorStatus = INVATION_EXPIRED;
		localStorage.removeItem("inactiveLink");
	}

	return (
		<>
			{loginState.needChangePassword ? (
				<ChangePassword userId={loginState.userId} userName={loginState.userName} />
			) : (
				<div className={styles.wrapper}>
					<div className={containerClassName}>
						<div className={styles.header}>
							<LogoSalebridge />
							<span className={styles.title}>Войти в систему</span>
						</div>
						<div className={styles.inputPanel}>
							<Field
								type="text"
								size="small"
								label="Электронная почта"
								labelPosition="vertical"
								textVariant="outlined"
								value={loginState.userName}
								onChange={loginState.setUsername}
								alert={loginState.validation.username.isInvalid ? "error" : undefined}
								tooltipBody={errorMesage ?? loginState.validation.username.error}
								tooltipTrigger="hover&focus"
								startTooltipPosition="left middle"
								isTooltipDisplayed={loginState.validation.username.isInvalid}
							/>
							<Field
								type="password"
								size="small"
								label="Пароль"
								labelPosition="vertical"
								textVariant="outlined"
								value={loginState.password}
								onChange={loginState.setPassword}
								isNew={false}
								alert={loginState.validation.password.isInvalid ? "error" : undefined}
								tooltipBody={errorMesage ?? loginState.validation.password.error}
								tooltipTrigger="hover&focus"
								startTooltipPosition="left middle"
								isTooltipDisplayed={loginState.validation.password.isInvalid}
							/>
						</div>
						<div className={styles.actionContainer}>
							<Button
								text="Войти"
								size="medium"
								variant="primary"
								onClick={handleClick}
								link={false}
								loading={isLoading}
								border
								isFillParent
							/>
							<Button
								text="Вспомнить пароль"
								size="medium"
								variant={isLoading ? "disabled" : "default"}
								onClick={() => {}}
								link={false}
								loading={false}
								border
								isFillParent
							/>
						</div>
						{loginState.errorStatus && <ErrorMessage errorStatus={loginState.errorStatus} />}
					</div>
				</div>
			)}
			<ModalViewer />
		</>
	);
});

export default Login;
