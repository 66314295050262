import { observer } from "mobx-react-lite";

import { Checkbox } from "sale-bridge-ui-kit";
import { FieldSelect } from "../field-select/field-select";

import { ButtonStyle } from "components";

import styles from "./checkbox-group.module.scss";

/**
 *@description Интерфейс пропсов компонента ToggleWithOptions
 * @param {string} nameOfFieldSelect - Поле FieldSelect name
 * @param {CheckboxGroupItemProps[]} items - Поля для чекбоксов
 * @param {(id: string, value: boolean) => void} onChangeChecked - Что происходит при выборе элемента
 * @example Пример options: CheckboxGroupItemProps[] = [
        { id: '', title: 'Нейтральный', value: true, description: 'Например, «Задача выполнена»' },
        { id: '', title: 'Положительный', value: false, description: 'Например, лид: «Перевод в продажи»' },
        { id: '', title: 'Отрицательный', value: false, description: 'Например, лид: «Дисквалифицирован»' }
    ]
 */

export type CheckboxGroupItemProps = {
    id: string,
    value: boolean,
    title: string,
    description: string
}

interface CheckboxGroupProps {
    items: CheckboxGroupItemProps[],
    nameOfFieldSelect: string,
    onChangeChecked:(id: string, value: boolean) => void
}

export const CheckboxGroup = observer((props: CheckboxGroupProps) => {
    return (
        <FieldSelect
            name={props.nameOfFieldSelect}
            buttonStyle={ButtonStyle.Settings}
        >
            <div className={styles.checkboxGroup}>
                {props.items.map((item, index) => <Checkbox
                    key={index}
                    size={"small"}
                    value={item.value}
                    title={item.title}
                    description={item.description}
                    onChangeChecked={(value: boolean) => {
                        props.onChangeChecked(item.id, value)
                    }}
                />)}
            </div>
        </FieldSelect>
    );
});