import { CSSProperties, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { store } from "store/store";
import { Reactions, SubscribeType } from "types";
import { dispatcher } from "store";


export interface Props {
    children: ReactNode;
    uuid: string;
    type: string;
    customReaction?: Map<string, CSSProperties>
}

//TODO пример использования лежит в src\pages\settings\currents\CurrentMainPanel.tsx
function SubscribeWrapper(props: Props) {

	const [attenuation, setAttenuation] = useState(false);
	const [styles, setStyles] = useState<CSSProperties>({});

	const reactions = useMemo(() => {
		if (props.type === SubscribeType.CUSTOM) {
			return props.customReaction?.get(props.type);
		} else {
			return Reactions.get(props.type);
		}
	}, [props]);

	const handleMouseEnter = useCallback(() => {
		dispatcher.reactions.reset(props.uuid);
	}, []);

	useEffect(() => {
		setTimeout(() => {setAttenuation(true);}, 3000);
	}, []);

	useEffect(() => {
		dispatcher.subscribers.add(props.uuid);
	}, [props.uuid]);

	useEffect(() => {
	}, [store.reactionSubscribers[props.uuid]]);

	// TODO ВЕРНУТЬ unmouting


	useEffect(() => {
		setStyles({
			// @ts-ignore
			...reactions[store.reactionSubscribers[props.uuid]],
		});
	}, [store.reactionSubscribers[props.uuid], reactions]);

	useEffect(() => {
		if (attenuation) {
			setStyles({ ...styles, backgroundColor: "white" });
		}
	}, [attenuation]);

	return (
		<div style={styles} onMouseEnter={handleMouseEnter}>
			{props.children}
		</div>
	);
}

export default observer(SubscribeWrapper);
