import { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";

import { Button, ButtonStyle, SettingsSelect } from "components";
import { Button as ButtonUiKit } from "sale-bridge-ui-kit";

import { Menu, MenuHeaders } from "./data/data";
import { Item } from "types";
import { MenuState } from "pages/section-wizzard/data/data";

import { ChevronLeft } from "shared";

import styles from "./section-wizzard-left-panel.module.scss";

interface ISectionWizzardLeftPanelProps {
	sectionSettingsItems: Item[];
	stagesConstructorItems: Item[];
	kanbanSettingsItems: Item[];
	automatizationItems: Item[];
	selected?: string | null;
	onChangeValue: (item: Item | null) => void;
	useLocalStorage?: boolean;
	localStorageKey?: string;
}

export const SectionWizzardLeftPanel = observer((props: ISectionWizzardLeftPanelProps) => {
	const [menuState, setMenuState] = useState(MenuState.hide);

	const leftPanelClasses = classNames(`${styles.sectionLeft} `, {
		[`${styles.open} `]: menuState === MenuState.open
	});
	const hideButtonClasses = classNames(`${styles.hideButton} `, {
		[`${styles.openButton} `]: menuState === MenuState.open
	});

	useEffect(() => {
		if (props.useLocalStorage && props.localStorageKey) {
			setMenuState(Number(localStorage.getItem(props.localStorageKey)));
		}
	}, []);

	const onClickHideOrOpenMenu = useCallback(() => {
		const newValue = menuState === MenuState.open ? MenuState.hide : MenuState.open;
		setMenuState(newValue);
		if (props.useLocalStorage && props.localStorageKey) {
			if (localStorage.getItem(props.localStorageKey)) localStorage.removeItem(props.localStorageKey);
			localStorage.setItem(props.localStorageKey, newValue as unknown as string);
		}
	}, [menuState, props]);

	const hideButtonIcon = useMemo(() => {
		const iconClassName = classNames(styles.leftPanelIcon, {
			[`${styles.openIcon} `]: menuState === MenuState.hide
		});
		return (
			<div className={iconClassName}>
				<ChevronLeft />
			</div>
		);
	}, [menuState]);

	const menu = useMemo(() => {
		return (
			menuState === MenuState.open && (
				<>
					<SettingsSelect /* Настройка раздела */
						name={Menu.find((item) => item.id === MenuHeaders.SectionSettings)!.name}
						items={props.sectionSettingsItems}
						onChangeValue={props.onChangeValue}
						selected={props.selected}
						styles={ButtonStyle.Settings}
						classNameButton={styles.headSelect}
						className={styles.settingsSelect}
					/>

					<SettingsSelect /* Конструктор стадий */
						name={Menu.find((item) => item.id === MenuHeaders.StagesConstructor)!.name}
						items={props.stagesConstructorItems}
						onChangeValue={props.onChangeValue}
						selected={props.selected}
						styles={ButtonStyle.Settings}
						classNameButton={styles.headSelect}
						className={styles.settingsSelect}
					/>
					<SettingsSelect /* Настройки доски kanban */
						name={Menu.find((item) => item.id === MenuHeaders.KanbanSettings)!.name}
						items={props.kanbanSettingsItems}
						onChangeValue={props.onChangeValue}
						selected={props.selected}
						styles={ButtonStyle.Settings}
						classNameButton={styles.headSelect}
						className={styles.settingsSelect}
					/>
					<SettingsSelect /* Автоматизация */
						name={Menu.find((item) => item.id === MenuHeaders.Automatization)!.name}
						items={props.automatizationItems}
						onChangeValue={props.onChangeValue}
						selected={props.selected}
						styles={ButtonStyle.Settings}
						classNameButton={styles.headSelect}
						className={styles.settingsSelect}
					/>
					<div className={styles.devButton}>
						<div className={styles.divider} />
						<ButtonUiKit
							text="Режим разработчика"
							size="small"
							variant="disabled"
							border={false}
							link={false}
							loading={false}
							onClick={() => {}}
							leftIcon="Code"
							iconSize="16"
							isFillParent
						/>
						<span className={styles.devInfo}>Перейти в режим разработчика</span>
					</div>
				</>
			)
		);
	}, [menuState, props, props.sectionSettingsItems, props.stagesConstructorItems, props.kanbanSettingsItems]);

	return (
		<div className={leftPanelClasses}>
			<Button firstIcon={hideButtonIcon} className={hideButtonClasses} onClick={onClickHideOrOpenMenu} />
			{menu}
		</div>
	);
});
