import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";

import { dispatcher, store } from "store";
import { synchroiser } from "synchroiser";

import { ContextMenu } from "components";
import { contextMenuStore } from "components/context-menu";
import { KanbanContextMenuActions, KanbanPage } from "./components";

export const Kanban = observer((
    props: {
        onOpenQuickView: (recordId: string, name?: string) => void;
    }
) => {
    const stageModel = dispatcher.sectionWizzard.getSectionWizzard()?.stageModelConfig;
    const kanbanData = useMemo(synchroiser.getKanbanData, [
        toJS(store.rows?.map((record) => toJS(Object.values(record)))),
        store.priorities
    ]);
    const { kanbanContextMenuActions } = KanbanContextMenuActions();

    if (kanbanData === null || stageModel === null) {
        return null;
    }

    if (!stageModel) {
        return null;
    }

    return (
        <>
            <KanbanPage
                stages={kanbanData.stages}
                onOpen={props.onOpenQuickView}
                size="small"
                kanbanConfig={{
                    cardDesign: dispatcher.sectionWizzard.getSectionWizzard()!.kanbanConfig!.cardDesign,
                    quickViewDesign: dispatcher.sectionWizzard.getSectionWizzard()!.kanbanConfig!.quickViewDesign,
                }}
                stageModel={stageModel}
            />
            {contextMenuStore.isOpen &&
                <ContextMenu contextMenuOptions={kanbanContextMenuActions} />
            }
        </>
    )
})