import { SortDirection } from "entities/ISort";

export const useSortableData = (array: any[], key: string, direction: SortDirection) => {
	return array.slice().sort((a: any, b: any) => {
		if (direction === SortDirection.Descending) {
			return a[key] < b[key] ? 1 : -1;
		} else if (direction === SortDirection.Ascending) {
			return a[key] > b[key] ? 1 : -1;
		}
		return 0;
	});
};
