import { useCallback, useEffect } from "react";
import { observer } from "mobx-react-lite";

import { UpFirst } from "shared";
import fieldGroupConfigurationStore from "./field-group-configuration-store";

import { Field, Button } from "sale-bridge-ui-kit";

import { CloseMaxi } from "shared";

import styles from "./field-group-configuration.module.scss";

export const FieldGroupConfiguration = observer(
	(props: {
		fieldGroupId?: string;
		close: () => void;
		closeAll: () => void;
		position: { cellX: number; cellY: number };
		onSave?: () => void;
	}) => {
		useEffect(() => {
			if (props.fieldGroupId) {
				fieldGroupConfigurationStore.getConfigurationById(props.fieldGroupId);
			}
		}, [props.fieldGroupId]);

		useEffect(() => {
			fieldGroupConfigurationStore.setValueWithoutTrackingChanges("x", props.position.cellX);
			fieldGroupConfigurationStore.setValueWithoutTrackingChanges("y", props.position.cellY);
		}, [props.position.cellX, props.position.cellY]);

		const handleSave = useCallback(() => {
			if (!fieldGroupConfigurationStore.isDisabledSaveButton) {
				if (props.fieldGroupId) {
					fieldGroupConfigurationStore.saveFieldGroup(props.fieldGroupId);
				} else {
					fieldGroupConfigurationStore.saveFieldGroup();
				}
				props.closeAll();
				if (props.onSave) {
					props.onSave();
				}
			}
		}, [props, fieldGroupConfigurationStore.isDisabledSaveButton]);

		const handleClose = useCallback(() => {
			if (fieldGroupConfigurationStore.hasChanges) {
				props.close();
			} else {
				props.closeAll();
			}
		}, [props.close, fieldGroupConfigurationStore.hasChanges]);

		const handleTitleChange = useCallback((value: string) => {
			fieldGroupConfigurationStore.setValue("name", value);
			fieldGroupConfigurationStore.validateTitle();
			fieldGroupConfigurationStore.validGroup();
		}, []);

		const handleSystemNameChange = useCallback((value: string) => {
			fieldGroupConfigurationStore.setValue("systemName", UpFirst(value));
			fieldGroupConfigurationStore.validateSystemName();
			fieldGroupConfigurationStore.validGroup();
		}, []);

		return (
			<>
				<div className={styles.headerModal}>
					<span className={styles.titleModal}>Новая группа полей</span>
					<CloseMaxi className={styles.closeButton} onClick={handleClose} />
				</div>
				<div className={styles.dialogBody}>
					<Field
						type="text"
						size="small"
						label="Название"
						labelPosition="vertical"
						textVariant="outlined"
						placeholder="Введите значение"
						isRequired
						value={fieldGroupConfigurationStore.name}
						counter={50}
						isClearing
						onChange={handleTitleChange}
						alert={fieldGroupConfigurationStore.validation.name.isInvalid ? "error" : undefined}
					/>
					<Field
						type="text"
						size="small"
						label="Системное название"
						labelPosition="vertical"
						textVariant="outlined"
						placeholder="Название колонки в базе данных"
						isRequired
						value={fieldGroupConfigurationStore.systemName}
						counter={50}
						isClearing
						onChange={handleSystemNameChange}
						alert={fieldGroupConfigurationStore.validation.systemName.isInvalid ? "error" : undefined}
					/>
				</div>
				<div className={styles.footerButtonsBlock}>
					<Button text="Отменить" size="small" variant="backless" border link={false} loading={false} onClick={handleClose} />
					<Button
						text="Сохранить"
						size="small"
						variant={fieldGroupConfigurationStore.isDisabledSaveButton ? "disabled" : "primary"}
						border
						link={false}
						loading={false}
						onClick={handleSave}
					/>
				</div>
			</>
		);
	}
);
