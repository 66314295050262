import { useState } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";

import Avatar from "features/avatar/avatar-generator";
import { TextEditorProviderWithStartText } from "features/text-editor/config/context";
import { NotEditableText } from "features/text-editor/not-editable-text";
import { Button, WarningDialog } from "components";

import { ICommentData } from "../types/types";

import styles from "./../comments-block.module.css";


const CommentItem = observer((props: ICommentData) => {
    const [open, setOpen] = useState<boolean>(false)
    let wrapperClassNames = classNames({
        [`${props.classNameCommentItem} `]: props.classNameCommentItem,
        [`${styles.commentItemWrapper} `]: !props.classNameCommentItem
    });
    return (
        <>
            <div className={wrapperClassNames}>
                <CommentItemHeader modifiedOn={props.modifiedOn!} createdOn={props.createdOn!} userName={props.userName} />
                <TextEditorProviderWithStartText text={props.text}>
                    <NotEditableText text={props.text} />
                    {props.isOwner && (
                        <div className={styles.button}>
                            <Button
                                className={styles.grayButton}
                                caption="Изменить"
                                onClick={() => props.onUpdateComments(props.id, props.userId)}
                            />
                            <li className={styles.point} />
                            <Button
                                className={styles.grayButton}
                                caption="Удалить"
                                onClick={() => { setOpen(true) }
                                }
                            />
                        </div>
                    )}
                </TextEditorProviderWithStartText>
            </div>
            {<WarningDialog valueDelete="Удалить" valueReturn="Отмена" value={'Комментарий будет удален без возможности\nвосстановления'} isOpen={open} onCancelClick={() => props.onDeleteComments(props.id!, props.userId)} onBackClick={() => setOpen(false)} />}
        </>
    )
})

export default CommentItem;

const CommentItemHeader = observer((props: { modifiedOn: string, createdOn: string, userName: string }) => {
    let createdDateHeader = new Date(props.createdOn).toLocaleString('ru-Ru', { year: "numeric", month: "short", day: "numeric", hour: 'numeric', hour12: false, minute: '2-digit' });
    let modifiedDateHeader = new Date(props.modifiedOn).toLocaleString('ru-Ru', { year: "numeric", month: "short", day: "numeric", hour: 'numeric', hour12: false, minute: '2-digit' });
    let createdDateWithSeconds = new Date(props.createdOn).toLocaleString('ru-Ru', { year: "numeric", month: "short", day: "numeric", hour: 'numeric', hour12: false, minute: '2-digit', second: '2-digit' });
    let modifiedDateWithSeconds = new Date(props.modifiedOn).toLocaleString('ru-Ru', { year: "numeric", month: "short", day: "numeric", hour: 'numeric', hour12: false, minute: '2-digit', second: '2-digit' });

    return (
        <div style={{ display: "flex", alignItems: 'center', position: 'relative' }}>
            <div>
                <Avatar className={styles.avatar} name={props.userName} />
            </div>
            <div className={styles.userComment}>
                {props.userName}
            </div>
            <div className={styles.dateComment}>
                {createdDateHeader}
            </div>
            {createdDateWithSeconds !== modifiedDateWithSeconds &&
                <div className={styles.editTool} >
                    <span className={styles.tooltip}>
                        <span> {modifiedDateHeader} </span>
                    </span>
                    <div className={styles.editComment}>
                        Изменено
                    </div>

                </div>
            }
        </div>

    )
})
