import { makeAutoObservable } from "mobx";

import ISearchItem from "entities/search/model/SearchItem";
import { EntityType } from "types/entity";
import { GetSchemes } from "../data/SearchSettings";
import { api } from "shared";

class SearchStore {
	entities: ISearchItem[] = [];
	isLoaded: boolean = false;
	isLoadedBatch: boolean = true;
	isError: boolean = false;
	errorMessage: string = "";
	filtres: string[] = [EntityType.Account, EntityType.Contact, EntityType.Lead, EntityType.Sale];
	count: number = 0;
	currentBatch = 0;
	batchSize = 30;

	constructor() {
		makeAutoObservable(this);
	}

	async load(value: string, customFilters?: string[]) {
		this.isLoaded = false;
		this.isError = false;
		this.currentBatch = 0;
		const data = {
			schemes: GetSchemes(customFilters ?? this.filtres),
			searchValue: value,
			batch: this.currentBatch,
			batchSize: this.batchSize
		};
		const result = await api.http.globalSearcher.search().post(data);
		this.isLoaded = true;
		//TODO доделать после рефакторинга глобального поиска
		/*    if(result.status === 200){
				console.log(result.data.data);
			    
				this.entities = result.data.data;
				this.count = result.data.count;
				this.currentBatch++;
			}
			else{
				this.isError = true;
				this.errorMessage = result.statusText;
			}*/
		/*this.entities = result.data.data;
				this.count = result.data.data.count;
				this.currentBatch++;*/
	}

	async loadMore(value: string) {
		this.isError = false;
		this.isLoadedBatch = false;

		const data = {
			schemes: GetSchemes(this.filtres),
			searchValue: value,
			batch: this.currentBatch,
			batchSize: this.batchSize
		};
		const result = await api.http.globalSearcher.search().post(data);

		this.isLoadedBatch = true;
		//TODO доделать после рефакторинга глобального поиска
		/*   if(result.status === 200){
			   this.entities = this.entities.concat(result.data.data);
			   this.count = result.data.count;
			   this.currentBatch++;
		   }
		   else{
			   this.isError = true;
			   this.errorMessage = result.statusText;
		   }*/
		/*this.entities = this.entities.concat(result.data.data);
		this.count = result.data.data.count;
		this.currentBatch++;*/
	}
}

const searchStore = new SearchStore();

export default searchStore;
