import { Api } from "shared/api/class-api";

class Email extends Api {
	basicPath: string;
	constructor() {
		super();
		this.basicPath = "/api/Email";
	}

	emailsInfo = (id?: string) => {
		if (id) {
			this.setPath(`${this.basicPath}/emails/${id}`);
		} else {
			this.setPath(`${this.basicPath}/emails`);
		}
		return this;
	};
	sendEmail = (id?: string) => {
		if (id) {
			this.setPath(`${this.basicPath}/send/${id}`);
		} else {
			this.setPath(`${this.basicPath}/send`);
		}
		return this;
	};
	emailSenders = (id?: string) => {
		if (id) {
			this.setPath(`${this.basicPath}/senders/${id}`);
		} else {
			this.setPath(`${this.basicPath}/senders`);
		}
		return this;
	};
}

const email = new Email();

export { email };
