import { Icon } from "sale-bridge-ui-kit";

import styles from "../style.module.scss";

export function WithoutFilters() {
    return (
        <div className={styles.infoBlock}>
            <Icon name="PicturesEmptyPage" size="64" />
            <div className={styles.textContainer}>
                <span className={styles.mainText}>Здесь пусто</span>
                <span className={styles.description}>На странице пока нет информации.</span>
            </div>
        </div>
    )
}