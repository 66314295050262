import { isNull, isUndefined } from "lodash";
import { makeAutoObservable } from "mobx";
import { v4 } from "uuid";

import { dispatcher } from "store";

import { FilterStore, IFilterStore } from "entities/filter/FilterStore";
import { FilterType } from "entities/filter/IFilter";
import { FieldValidationState, Item } from "types";
import { Action, ActionType, BusinessRule } from "types/entity";

const ERROR_MESSAGE = "Заполните это поле.";

export type BusinessRuleEditorValidation = { field: FieldValidationState; comparison: FieldValidationState; value: FieldValidationState };
export class BusinessRuleEditorStore {
	businessRule: BusinessRule | null = null;
	isNew: boolean = false;
	conditionFilterStore: IFilterStore | null = null;
	validation: Map<string, BusinessRuleEditorValidation> | null = null;

	constructor() {
		this.businessRule = null;
		this.isNew = false;
		makeAutoObservable(this);
	}

	/**
	 * @description Метод для валидации всех полей во всех блоках
	 */
	validate() {
		this.validation = new Map();
		const isConditionValid = this.validateOfConditions(this.conditionFilterStore);
		const isActionsValid = this.validateOfActions(this.businessRule?.ruleSettings.actions);
		const isElseActionsValid = this.validateOfActions(this.businessRule?.ruleSettings.elseActions);
		return isConditionValid && isActionsValid && isElseActionsValid;
	}

	/**
	 * @description Метод для валидации блока "Если"
	 * @param condition - условие для валидации
	 * @returns валиден блок "Если" или нет
	 */
	validateOfConditions(condition: IFilterStore | null): boolean {
		if (!condition) {
			return false;
		}
		condition.filters?.forEach((condition: IFilterStore) => {
			if (condition.type == FilterType.Group && condition.filters) {
				if (condition.filters.length > 0) {
					this.validateOfConditions(condition);
					return;
				}
			}
			if (condition.type == FilterType.Attribute) {
				this.validation?.set(condition.id, {
					field: {
						isInvalid: typeof condition.attribute === "string" && (condition.attribute as string)?.length === 0,
						error: ERROR_MESSAGE
					},
					comparison: { isInvalid: isNull(condition.comparisonType) || condition.comparators.length == 0, error: ERROR_MESSAGE },
					value: {
						isInvalid: !condition.isValid,
						error:
							condition.rightExpression?.parameter.value?.start > condition.rightExpression?.parameter.value?.end
								? "Проверьте значения в полях."
								: ERROR_MESSAGE
					}
				});
			}
		});
		return condition.filters?.every((condition: IFilterStore) => {
			if (condition.type == FilterType.Attribute) {
				return (
					!(typeof condition.attribute === "string" && (condition.attribute as string)?.length === 0) &&
					!isNull(condition.comparisonType) &&
					condition.isValid
				);
			}
		});
	}

	/**
	 * @description Метод для валидации блока "То"/"Иначе"
	 * @param actions - массив условий для валидации
	 * @returns валиден блок "То"/"Иначе" или нет
	 */
	validateOfActions(actions: Action[] | undefined): boolean {
		if (!actions) {
			return false;
		}
		actions?.forEach((action: Action) => {
			this.validation?.set(action.id, {
				field: {
					isInvalid: isNull(action.field) || (action.field as string)?.length === 0,
					error: ERROR_MESSAGE
				},
				comparison: { isInvalid: isNull(action.actionType), error: ERROR_MESSAGE },
				value: {
					isInvalid: isNull(action.value) || (action.value as string)?.length === 0,
					error: ERROR_MESSAGE
				}
			});
		});
		return actions?.every((action: Action) => {
			return !(isNull(action.field) || (action.field as string)?.length === 0) && !isNull(action.actionType) && !isNull(action.value);
		});
	}

	/**@description метод предназначен для того, чтобы добавить пустые предзаполненные строки для действий бизнес-правила */
	init(): void {
		this.conditionFilterStore =
			dispatcher.sectionWizzard.getSectionWizzard()?.systemName && this.businessRule
				? new FilterStore(
						dispatcher.sectionWizzard.getSectionWizzard()!.systemName,
						this.businessRule.ruleSettings.conditions,
						null
				  )
				: null;
		if (isNull(this.businessRule?.ruleSettings.conditions)) {
			this.conditionFilterStore?.addFilter();
		}
		if (this.businessRule?.ruleSettings.actions.length === 0) {
			this.businessRule.ruleSettings.actions.push({
				id: v4(),
				field: null,
				actionType: null,
				value: null
			});
		}
	}

	setIsNewBusinessRule(value: boolean): void {
		this.isNew = value;
	}

	setBusinessRule(rule: BusinessRule): void {
		this.businessRule = rule;
	}

	/**@description метод предназначен для того, чтобы включить/отключить бизнес-правило */
	setEnabledBusinessRule(value: boolean): void {
		if (this.businessRule) {
			this.businessRule.enabled = value;
		}
	}

	/**@description метод предназначен для того, чтобы восстановить изначальную настройку бизнес-правила */
	setOldVariantBusinessRule(): void {
		if (this.businessRule) {
			const finded = dispatcher.sectionWizzard.getBusinessRules()?.find((rule: BusinessRule) => rule.id === this.businessRule?.id);
			if (finded) {
				this.setBusinessRule(JSON.parse(JSON.stringify(finded)));
			}
		}
	}

	/* Действия с Conditions */

	/**
	 * @description Метод для изменения "Поле" у условия из блока "Если"
	 * @param filter - изменяемый фильтр(конкретное условие)
	 * @param value - новое значение
	 */
	setConditionField(filter: IFilterStore, value: Item | null) {
		filter!.selectAttributeOrDetail(value ? filter!.property.items.find((item) => item.name === value!.name)! : null);
		this.resetConditionValidationFromBlock(filter.id, "field");
	}

	/**
	 * @description Метод для изменения "Условие" у условия из блока "Если"
	 * @param filter - изменяемый фильтр(конкретное условие)
	 * @param value - новое значение
	 */
	setConditionComparator(filter: IFilterStore, value: Item | null) {
		filter?.selectComparator(value);
		this.resetConditionValidationFromBlock(filter.id, "comparison");
	}

	/**
	 * @description Метод для изменения значения у условия из блока "Если"
	 * @param filter - изменяемый фильтр(конкретное условие)
	 * @param value - новое значение
	 */
	setRightExpression(filter: IFilterStore, value: any) {
		filter?.setRightExpression(value);
		this.resetConditionValidationFromBlock(filter.id, "value");
	}

	setConditions() {
		if (this.businessRule && this.conditionFilterStore) {
			const serialFilter = this.conditionFilterStore.serialize();
			this.businessRule.ruleSettings.conditions = serialFilter;
		}
	}

	duplicateCondition(filter: IFilterStore) {
		const duplicate = new FilterStore("", filter.serialize(), filter.parent);
		const filterIndex = filter?.parent?.filters.findIndex((filterOfParent) => filterOfParent.id === filter.id);
		if (!isUndefined(filterIndex) && filterIndex > -1) {
			filter?.parent?.filters.splice(filterIndex + 1, 0, duplicate);
		}
	}

	convertToGroupCondition(filter: IFilterStore) {
		const filterIndex = filter?.parent?.filters.findIndex((filterOfParent) => filterOfParent.id === filter.id);

		if (!isUndefined(filterIndex) && filterIndex > -1) {
			const deletedElements = filter?.parent?.filters.splice(filterIndex, 1);
			filter?.parent?.addGroup(filterIndex);
			const addedGroup = filter?.parent?.filters[filterIndex];
			if (addedGroup && deletedElements) {
				deletedElements?.forEach((deletedElement) => {
					deletedElement.parent = addedGroup;
					addedGroup.filters.push(deletedElement);
				});
			}
		}
	}

	convertToCondition(filter: IFilterStore) {
		filter?.filters.forEach((filterInGroup) => (filterInGroup.parent = filter.parent));

		const deletedElements = filter?.filters.slice();
		const filterIndex = filter?.parent?.filters.findIndex((filterOfParent) => filterOfParent.id === filter.id);
		if (!isUndefined(filterIndex) && filterIndex > -1) {
			filter?.parent?.filters.splice(filterIndex, 1);
			deletedElements?.forEach((deletedElement, index) => {
				filter?.parent?.filters.splice(filterIndex! + index, 0, deletedElement);
			});
		}
	}

	deleteCondition(filter: IFilterStore) {
		const filterIndex = filter?.parent?.filters.findIndex((filterOfParent) => filterOfParent.id === filter.id);
		if (!isUndefined(filterIndex) && filterIndex > -1) {
			filter?.parent?.filters.splice(filterIndex, 1);
		}
	}

	/* Действия с Actions и ElseActions */
	setFieldToAction(value: Item | null, actionId: string) {
		if (this.businessRule) {
			const findAction = this.businessRule.ruleSettings.actions.find((currentAction) => currentAction.id === actionId);
			if (findAction) {
				findAction.field = value?.id.toString() ?? null;

				this.resetConditionValidationFromBlock(actionId, "field");
			}
		}
	}
	setActionTypeToAction(value: Item | null, actionId: string, needResetValidation: boolean = false) {
		if (this.businessRule) {
			const findAction = this.businessRule.ruleSettings.actions.find((currentAction) => currentAction.id === actionId);
			if (findAction) {
				findAction.actionType = (value?.id as ActionType) ?? null;
				if (needResetValidation) {
					this.resetConditionValidationFromBlock(actionId, "comparison");
				}
			}
		}
	}
	setValueToAction(value: string | Item | null, actionId: string, needResetValidation: boolean = false) {
		if (this.businessRule) {
			const findAction = this.businessRule.ruleSettings.actions.find((currentAction) => currentAction.id === actionId);
			if (findAction) {
				findAction.value = value ?? null;
				if (needResetValidation) {
					this.resetConditionValidationFromBlock(actionId, "value");
				}
			}
		}
	}

	deleteAction(actionId: string) {
		if (this.businessRule) {
			const findActionIndex = this.businessRule.ruleSettings.actions.findIndex((currentAction) => currentAction.id === actionId);
			this.businessRule.ruleSettings.actions.splice(findActionIndex, 1);
		}
	}

	setFieldToElseAction(value: Item | null, actionId: string) {
		if (this.businessRule) {
			const findAction = this.businessRule.ruleSettings.elseActions.find((currentAction) => currentAction.id === actionId);
			if (findAction) {
				findAction.field = value?.id.toString() ?? null;
				this.resetConditionValidationFromBlock(actionId, "field");
			}
		}
	}
	setActionTypeToElseAction(value: Item | null, actionId: string, needResetValidation: boolean = false) {
		if (this.businessRule) {
			const findAction = this.businessRule.ruleSettings.elseActions.find((currentAction) => currentAction.id === actionId);
			if (findAction) {
				findAction.actionType = (value?.id as ActionType) ?? null;
				if (needResetValidation) {
					this.resetConditionValidationFromBlock(actionId, "comparison");
				}
			}
		}
	}
	setValueToElseAction(value: string | Item | null, actionId: string, needResetValidation: boolean = false) {
		if (this.businessRule) {
			const findAction = this.businessRule.ruleSettings.elseActions.find((currentAction) => currentAction.id === actionId);
			if (findAction) {
				findAction.value = value ?? null;
				if (needResetValidation) {
					this.resetConditionValidationFromBlock(actionId, "value");
				}
			}
		}
	}

	deleteElseAction(actionId: string) {
		if (this.businessRule) {
			const findActionIndex = this.businessRule.ruleSettings.elseActions.findIndex((currentAction) => currentAction.id === actionId);
			this.businessRule.ruleSettings.elseActions.splice(findActionIndex, 1);
		}
	}

	addNewEmptyAction(to: "actions" | "elseActions") {
		if (this.businessRule) {
			if (to === "actions") {
				this.businessRule.ruleSettings.actions.push({
					id: v4(),
					field: null,
					actionType: null,
					value: null
				});
			} else {
				this.businessRule.ruleSettings.elseActions.push({
					id: v4(),
					field: null,
					actionType: null,
					value: null
				});
			}
		}
	}

	/**@description метод предназначен для того, чтобы удалить пустые предзаполненные строки для действий бизнес-правила */
	deleteEmptyActions(): void {
		this.businessRule?.ruleSettings.actions.forEach((action) => {
			if (isNull(action.field)) {
				this.deleteAction(action.id);
			} else if (isNull(action.actionType)) {
				this.deleteAction(action.id);
			}
		});
		this.businessRule?.ruleSettings.elseActions.forEach((elseAction) => {
			if (isNull(elseAction.field)) {
				this.deleteElseAction(elseAction.id);
			} else if (isNull(elseAction.actionType)) {
				this.deleteElseAction(elseAction.id);
			}
		});
	}

	saveBusinessRuleToConfig(): void {
		if (!this.validate()) {
			return;
		}
		this.setConditions();
		this.deleteEmptyActions();
		if (this.isNew) {
			dispatcher.sectionWizzard.addNewBusinessRule(this.businessRule);
		} else {
			dispatcher.sectionWizzard.updateBusinessRule(this.businessRule);
		}
	}

	deleteBusinessRuleFromConfig(): void {
		dispatcher.sectionWizzard.deleteBusinessRule(this.businessRule?.id);
		this.resetConfiguration();
	}

	resetConfiguration() {
		this.businessRule = null;
		this.conditionFilterStore = null;
		this.validation = null;
	}

	/**
	 * @description Метод для сброса валидации конкретного поля у конкретного условия в любом блоке
	 * @param id - id валидации
	 * @param validationField - название поля валидации (поле,условие/действие,значение)
	 */
	resetConditionValidationFromBlock(id: string, validationField: string) {
		const currentValidation = this.validation?.get(id) as any;
		if (!currentValidation) {
			return;
		}
		currentValidation[validationField] = { ...currentValidation[validationField], isInvalid: false };
	}
}

export const businessRuleEditorStore = new BusinessRuleEditorStore();
