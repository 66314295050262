import { useRef, useState } from "react";

import Styles, { ISelectStyles, SelectWitSerarchStyles } from "./core/StylesSystem";
import { Button, ButtonStyle, SearchSelect } from "components";

import { binding } from "module/reactor/binding/binding";
import { IItem } from "entities/IItem";

import { ArrowToDown } from "shared";

import styles from "./SelectWithSearchMini.module.css";
import stylesBase from "components/select/select.module.css";

interface SelectWithSearchMiniProps {
	items: string;
	item: string;
	onChange: string;

	className?: string;
	placeholder?: string;

	services: Map<string, any>;
}

function defaultRender(value: IItem): JSX.Element {
	return <span className={styles.displayValue}>{value.displayValue}</span>;
}

function SelectWithSearchMini(props: SelectWithSearchMiniProps) {
	const wrapperRef = useRef<HTMLDivElement>(null);
	const [showItems, setShowItems] = useState(false);

	const [search, setSearch] = useState<string>("");

	const dropdownStyles: ISelectStyles = Styles[SelectWitSerarchStyles.Primary];
	const renderFunction = defaultRender;

	const items = binding(props.services, props.items);
	const value = binding(props.services, props.item);
	const onChange = binding(props.services, props.onChange);

	let classNames = `${stylesBase.wrapper} ${styles.wrapper} ${props.className}`;
	let listClassNames = `${stylesBase.list} ${styles.list}`;
	let inputClassNames = dropdownStyles.input.classNames;
	let listItemClassNames = `${stylesBase.listItem} `;

	if (props.items && props.items!.length > 0 && showItems) {
		listClassNames += " " + stylesBase.visible;
	}

	if (showItems) {
		inputClassNames += dropdownStyles.input.focus;
	}

	function hideMenu() {
		setShowItems(false);
		document.removeEventListener("click", handleClick);
	}

	function handleClick(event: Event) {
		if (wrapperRef.current != null && !wrapperRef.current.contains(event.target as Node)) {
			hideMenu();
		}
	}

	const handleItemClick = (selectedItem: IItem) => {
		setShowItems(false);
		onChange(selectedItem);
	};

	function onSelectOpen() {
		//if(props.onItemsLoad) props.onItemsLoad();
		setShowItems(true);
		document.addEventListener("click", handleClick);
	}

	const onButtonClick = () => {
		if (showItems) {
			hideMenu();
		} else {
			onSelectOpen();
		}
	};

	return (
		<div ref={wrapperRef} className={classNames}>
			<div className={inputClassNames} onClick={onButtonClick}>
				<div className={styles.item}>{renderFunction(value)}</div>
				<Button
					onClick={onButtonClick}
					firstIcon={<ArrowToDown />}
					style={ButtonStyle.Icon}
					className={showItems ? stylesBase.selectButton + " " + stylesBase.close : stylesBase.selectButton}
				/>
			</div>
			<ul className={listClassNames}>
				{/* <SearchSelect value={search} placeholder={""} onChangeValue={(value)=>{setSearch(value)}} className={styles.searchWrapper} classNameInput={styles.searchInput}/> */}
				<div className={styles.itemsContainer}>
					{items
						?.filter((i: any) => i.displayValue.startsWith(search))
						.map((item: IItem) => (
							<li key={item.value} className={listItemClassNames} onClick={() => handleItemClick(item)}>
								{renderFunction(item)}
							</li>
						))}
				</div>
			</ul>
		</div>
	);
}

export default SelectWithSearchMini;
