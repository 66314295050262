import React, { useMemo } from 'react';
import { toJS } from 'mobx';

import { dispatcher } from 'store';

import { SaveCancelButtons } from '../../save-cancel-buttons';
import { ActionButtons } from '../../action-buttons';

import styles from '../right-block.module.scss';
import { Stopwatch } from 'features/stopwatch';

interface ActionsProps {
    handleSave: () => void
    handleCancel: () => void
    hasChanges: boolean
    recordId: string
}

export const Actions: React.FC<ActionsProps> = ({
    handleSave, handleCancel, hasChanges, recordId,
}) => {

    const sectionWizzard = useMemo(() => dispatcher.entity.get()?.entity.sectionWizzard, [toJS(dispatcher.entity.get()?.entity.sectionWizzard)]);

    const recordTitle  = useMemo(() => {
        return dispatcher.currentRow.getTitleByRowId(recordId)
    }, [recordId])

    return (
        <>
            <SaveCancelButtons handleSave={handleSave} handleCancel={handleCancel} hasChanges={hasChanges} />
            <div className={styles.divider} />
            <ActionButtons recordId={recordId} />
            {sectionWizzard?.hasTimer &&
                <>
                    <div className={styles.divider} />
                    <Stopwatch recordId={recordId}  recordTitle={recordTitle} view='large' />
                </>
            }
        </>
    )
}
