import { dispatcher} from "store";

import {ColumnSpecializationType,GridItem} from "types/entity";

export class SectionWizzardController {
    getPriorityFieldBySpecialization = (): GridItem | undefined => {
        return dispatcher.sectionWizzard.getAllGridItems().find(field => field.fieldConfig?.specializations?.tag == ColumnSpecializationType.KanbanField
            && field.fieldConfig?.specializations.properties["priorityField"] === "true");
    }

    getStageFieldBySpecialization = (): GridItem | undefined => {
        return dispatcher.sectionWizzard.getAllGridItems().find(field => field.fieldConfig?.specializations?.tag == ColumnSpecializationType.KanbanField
			&& field.fieldConfig?.specializations.properties["stageField"] === "true");
    }

    getSysFlagColumn = (): GridItem | undefined => {
        return dispatcher.sectionWizzard.getAllGridItems().find(field => field.fieldConfig?.specializations?.tag == ColumnSpecializationType.Flag
			&& field.fieldConfig?.specializations.properties["sysFlagColumn"] === "true");
    }

    getSysFlagDateColumn = (): GridItem | undefined => {
        return dispatcher.sectionWizzard.getAllGridItems().find(field => field.fieldConfig?.specializations?.tag == ColumnSpecializationType.Flag
			&& field.fieldConfig?.specializations.properties["sysFlagDateColumn"] === "true");
    }
}

export const sectionWizzardController = new SectionWizzardController();
