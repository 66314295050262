import Entity, { IEntityData, IEntityStore } from 'entities/Entity';
import { FieldValidationState, validateRequired } from 'entities/Validation';
import { action,  makeObservable, observable } from 'mobx';
import { Item } from 'types';



export class User implements IEntityStore {
    schema: string = "User";
    id: string = "";
    login: string = "";
    surName: string = "";
    middleName: string = "";
    giveName: string = "";
    password: string = "";
    phone: string = "";
    email: string = "";
    position: string = "";
    birthDate: string = "";
    userRole: Item | null = null;
    department: Item | null = null;
    gender: Item | null = null;
    name: string = "";
    isChecked: boolean = false;
    isActive: boolean = false;

    validation: { [key: string]: FieldValidationState };
    isValid:boolean =false;

    constructor() {
        makeObservable(this, {
            login: observable,
            surName: observable,
            middleName: observable,
            giveName: observable,
            password: observable,
            phone: observable,
            email: observable,
            position: observable,
            birthDate: observable,
            userRole: observable,
            department: observable,
            gender: observable,
            name: observable,
            validation: observable,
            setValue: action,
            validate: action,
            resetValidate: action,
            deserialize: action,
            isChecked: observable,
            isActive: observable,
            isValid: observable,
        })

        this.validation = {
            login: {
                isInvalid: false
            },
            surName: {
                isInvalid: false
            },
            giveName: {
                isInvalid: false
            },
            email: {
                isInvalid: false
            },
            phone: {
                isInvalid: false
            },
            password: {
                isInvalid: false
            },
        }
    }

    setValue(value: string | boolean | Item | null, fieldName: string) {
        Reflect.set(this, fieldName, value);
        
        let emailValid = !this.validation.email.isInvalid && this.validation.email.error?.length ===0;
        this.isValid = this.validate() && emailValid
    }

    // @action
    validate(): boolean {
        if (!validateRequired(this.login, this.validation.login)) {
            return false;
        }
        if (!validateRequired(this.surName, this.validation.surName)) {
            return false;
        }
        if (!validateRequired(this.giveName, this.validation.giveName)) {
            return false;
        }
        if (!validateRequired(this.email, this.validation.email)) {
            return false;
        }

        if (!validateRequired(this.password, this.validation.password)) {
            return false;
        }
        return true;
    }

    resetValidate() {
        this.validation = {
            login: {
                isInvalid: false
            },
            surName: {
                isInvalid: false
            },
            giveName: {
                isInvalid: false
            },
            email: {
                isInvalid: false
            },
            phone: {
                isInvalid: false
            },
        }
    }

    // @action
    deserialize(p: any) {
        if (Object.values(p).length == 2 && Object.values(p)[0] === this.schema) {
            p.values.map((item: IEntityData) => {
                let name = item.propertyName.charAt(0).toLowerCase() + item.propertyName.slice(1);
                console.log(name, item.propertyValue);

                Reflect.set(this, name, item.propertyValue);
            })
        }
        else {
            this.id = p.id;
            this.login = p.login ?? "";
            this.surName = p.surName ?? "";
            this.middleName = p.middleName ?? "";
            this.giveName = p.giveName ?? "";
            this.password = p.password ?? "";
            this.phone = p.phone ?? "";
            this.email = p.email ?? "";
            this.position = p.position ?? "";
            this.birthDate = p.birthDate ?? "";
            this.userRole = p.userRole;
            this.department = p.department;
            this.gender = p.gender;
            this.name = p.name ?? "";
            this.isActive = p.isActive ?? false;
        }

    }

    serialize() {
        let values = Reflect.ownKeys(this).filter(x => !x.toString().toLowerCase().includes('schema') && !(this.id === "" && x === 'id') && x !== 'validation' && x != "isChecked" && !x.toString().endsWith('edOn')).map((item) => {
            let name = null;
            let value = null;
            if (typeof Reflect.get(this, item) === 'object') {
                name = (item.toString().charAt(0).toUpperCase() + item.toString().slice(1)) + "Id";
                value = ((Reflect.get(this, item) as Item) !== null) ? (Reflect.get(this, item) as Item).id : null;
            }
            else {
                name = item.toString().charAt(0).toUpperCase() + item.toString().slice(1);
                value = Reflect.get(this, item) === "" ? null : Reflect.get(this, item);
            }
            if (name === 'Phone' && value !== null) {
                value = (value as string).replace(/\D/g, '');
                if (value[0] !== '7')
                    value = value.charAt(0).replace(value[0], '7') + value.toString().slice(1);

            }
            if (name === 'Name')
                value = this.surName + " " + this.giveName + " " + this.middleName;
            return {
                propertyName: name,
                propertyValue: value
            }
        }) as IEntityData[];
        values.pop();
        return {
            entityName: this.schema!,
            values: values
        }
    }

    handleError(error: string): void {
        if (error === "20") {
            this.validation.email.isInvalid = true;
            this.validation.email.error = "Пользователь с такой почтой уже существует";
        }
    }
}


const user = new User();

const userEntity = new Entity<User>(user);

export default userEntity;