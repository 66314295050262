import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Editor } from "draft-js";
import classNames from "classnames";

import { CUSTOM_STYLE_MAP } from "./config/config";
import { useEditorApi } from "./config/context";

import styles from "./text-editor.module.css";

export const NotEditableText = observer(function (props: { className?: string, text?: string }) {
    const { editorState, fromHtml } = useEditorApi();
    let wrapper = classNames({
        [`${styles.notClickableEditor} ` + props.className]: props.className,
        [`${styles.notClickableEditor} `]: true
    });
    
    useEffect(() => {
        if (props.text) fromHtml(props.text)
    }, [props.text]);

    return (
        <div className={wrapper}>
            <Editor
                editorState={editorState}
                onChange={() => { }}
                customStyleMap={CUSTOM_STYLE_MAP}
            />
        </div>

    );
})
