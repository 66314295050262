import FilterColumnType from "entities/ColumnType";

export enum FilterType {
	Group,
	Attribute,
	Detail
}

export enum LogicalOperation {
	And,
	Or
}

export enum ComparisonType {
	Between = -1,
	IsNull,
	IsNotNull,
	Equal,
	NotEqual,
	Less,
	LessOrEqual,
	Greater,
	GreaterOrEqual,
	StartWith,
	NotStartWith,
	Contain,
	NotContain,
	EndWith,
	NotEndWith,
	In,
	NotIn,
	NotBetween
}

export enum DataValueType {
	Guid,
	Boolean,
	Text,
	Integer,
	Float,
	DateTime,
	Time,
	Lookup,
	Long,
	Entity,
	// TODO Затычки P.S сохраните такой же порядок
	Date,
	Decimal,
	Collection
}

export interface IParameter {
	dataValueType: DataValueType;
	value: any;
}

export enum ExpressionType {
	Const,
	Macros
}

export enum MacrosType {
	Yesterday,
	Today,
	Tomorrow,
	PreviousDay,
	PreviousWeek,
	CurrentWeek,
	NextWeek,
	PreviousMonth,
	CurrentMonth,
	NextMonth,
	PreviousQuarter,
	CurrentQuarter,
	NextQuarter,
	PreviousYear,
	CurrentYear,
	NextYear,
	EveryYearToday,
	EveryYearTodayPlusDaysOffset,
	EveryYearNextNDays,
	EveryYearPreviousNDays,
	PreviousHalfYear,
	CurrentHalfYear,
	NextHalfYear
}

export interface IExpression {
	type: ExpressionType;
	parameter: IParameter;
	macros?: MacrosType;
}

export enum AggregationType {
	Exists,
	NotExists,
	Count,
	Sum,
	Average,
	Min,
	Max
}

export interface IFilter {
	schema: string;
	isEnabled: boolean;
	type: FilterType;

	// group fields
	filters?: IFilter[];
	logicalOperation: LogicalOperation;

	comparisonType: ComparisonType;
	attribute: string;
	attributeType?: FilterColumnType;
	rightExpression?: IExpression;

	//detail fields
	detail: string;
	detailAggregationType?: AggregationType;
	detailFilter?: IFilter;

	includedIds?: string[];
	excludedIds?: string[];
	aggregationColumn?: string;
	isActive?: boolean;
}

export default IFilter;
