import { action, toJS } from "mobx";
import { v4 } from "uuid";

import { dispatcher } from "store/store-dipatcher/dispatcher";
import { selector } from "store/store-selector";

import { ColumnSpecializationType, GridItem, HEXColors, ResultHEXColors, ResultType, StageModel, StageSetting, Stages } from "types/entity";
import { isNull } from "lodash";
import { ColumnType } from "entities/ColumnType";
import { MAIN_TAB_NAME } from "pages/section-wizzard/data/data";
import { useSortableData } from "shared/hooks/use-sortable-data";
import { SortDirection } from "entities/ISort";

export class StageModelController {
	/**
	 * @description Инициализация конфига стадийной модели.
	 */
	init = (stageLookupTitle: string, stageLookupName: string, detailedResultLookupName?: string): void => {
		const stageId = v4();
		const sectionWizzard = dispatcher.entity.get()?.entity.sectionWizzard;
		if (sectionWizzard) {
			const basicStageModel: StageModel = {
				stageLookupTitle: stageLookupTitle,
				stageLookupName: stageLookupName,
				detailedResultLookupName: detailedResultLookupName ?? "",
				stages: [
					{
						id: stageId,
						name: "Новая стадия"
					}
				],
				detailResults: [],
				stageSettings: [
					{
						id: v4(),
						stageId: stageId,
						color: HEXColors.Blue,
						isHidden: false,
						order: 0,
						resultType: null
					}
				],
				movingRules: []
			};
			sectionWizzard.stageModelConfig = basicStageModel;

			const virtualLookup = {
				entityTitle: stageLookupTitle,
				systemName: stageLookupName,
				isLookup: true,
				columnInfo: [
					{
						columnId: v4(),
						columnName: "Name",
						columnTitle: "Название",
						columnType: ColumnType.String,
						isLookup: false,
						isLink: false,
						lookupTable: null,
						isRequired: true,
						hasIndex: false,
						specializations: null
					}
				],
				virtualLookupValues: []
			};
			const gridItem: GridItem = {
				x: -1,
				y: -1,
				gridItemId: v4(),
				width: 1,
				height: 1,
				fieldConfig: {
					columnId: v4(),
					columnName: stageLookupName,
					columnType: ColumnType.Lookup,
					columnTitle: "Стадия",
					virtualLookup: virtualLookup,
					lookupTable: stageLookupName,
					isLookup: true,
					hasIndex: true,
					isRequired: true,
					uneditable: false,
					isViewColumn: false,
					specializations: {
						tag: ColumnSpecializationType.KanbanField,
						properties: {
							stageField: "true"
						}
					}
				}
			};
			dispatcher.sectionWizzard.createNewGridItemInTab(
				gridItem,
				sectionWizzard.reactorConfig.tabs.tabsConfig.find((tab) => tab.tabName === MAIN_TAB_NAME)?.tabIndex
			);
		}
	};

	setStageModelLookupSystemName = action((stageModelLookupSystemName: string): void => {
		const sectionWizzard = dispatcher.entity.get()?.entity.sectionWizzard;
		if (sectionWizzard?.stageModelConfig?.stageLookupName) {
			sectionWizzard.stageModelConfig!.stageLookupName = stageModelLookupSystemName;
		}
	});

	setStageModelLookupName = action((stageModelLookupName: string): void => {
		const sectionWizzard = dispatcher.entity.get()?.entity.sectionWizzard;
		if (sectionWizzard?.stageModelConfig?.stageLookupTitle) {
			sectionWizzard.stageModelConfig!.stageLookupTitle = stageModelLookupName;
		}
	});

	/**
	 * @description получение названия стадии по id.
	 */
	getNameStageById = (stageId: string): string => {
		return dispatcher.entity.get()?.entity.sectionWizzard?.stageModelConfig?.stages.find((stage: Stages) => stage.id === stageId)
			?.name!;
	};
	/**
	 * @description получение параметров стадии по id.
	 */
	getStageSettingsById = (stageId: string): StageSetting | undefined => {
		return dispatcher.entity
			.get()
			?.entity.sectionWizzard?.stageModelConfig?.stageSettings.find((setting: StageSetting) => setting.stageId === stageId);
	};

	/**
	 * @description Изменение цвета стадии.
	 */
	setColor = (stageId: string, color: HEXColors | string): void => {
		const settings = dispatcher.entity
			.get()
			?.entity.sectionWizzard?.stageModelConfig?.stageSettings.find((setting: StageSetting) => setting.stageId === stageId);
		if (settings) {
			settings.color = color;
		}
	};

	/**
	 * @description Изменение названия стадии.
	 */
	setStageName = (stageId: string, name: string): void => {
		const settings = dispatcher.entity
			.get()
			?.entity.sectionWizzard?.stageModelConfig?.stages.find((info: Stages) => info.id === stageId);
		if (settings) {
			settings.name = name;
		}
	};

	/**
	 * @description Изменение типа результата стадии.
	 */
	setStageType = (stageId: string, stageType: ResultType | null): void => {
		const settings = dispatcher.entity
			.get()
			?.entity.sectionWizzard?.stageModelConfig?.stageSettings.find((setting: StageSetting) => setting.stageId === stageId);
		if (settings) {
			settings.resultType = stageType;
		}
	};

	/**
	 * @description Изменение названий стадий по их order.
	 */
	changeStageNames = (value?: string): void => {
		const allStages = selector.stageModels.getAll();
		allStages?.forEach((stage) => {
			if (value) this.setStageName(stage.id, value);
		});
	};

	/**
	 * @description Перемещение элементов по id на позицию position.
	 */
	moveToById = (stageId: string, position: number): void => {
		const sectionWizard = dispatcher.entity.get()?.entity.sectionWizzard;
		if (!sectionWizard) {
			return;
		}

		const stageModelConfig = sectionWizard.stageModelConfig;
		if (!stageModelConfig) {
			return;
		}

		const stageSettings = useSortableData(toJS(stageModelConfig.stageSettings), "order", SortDirection.Ascending);
		if (!stageSettings) {
			return;
		}

		const movingSetting = stageSettings.find((setting: StageSetting) => setting.stageId === stageId);

		if (!movingSetting || movingSetting.order === position) {
			return;
		}

		const oldPosition = movingSetting.order;

		// Если старая позиция элемента больше новой позиции
		if (oldPosition > position) {
			// Увеличиваем порядковый номер всех элементов, находящихся между новой и старой позициями элемента
			stageSettings.forEach((setting: StageSetting) => {
				if (setting.order >= position && setting.order < oldPosition) {
					setting.order++;
				}
			});
		} else {
			// Если старая позиция элемента меньше новой позиции
			// Уменьшаем порядковый номер всех элементов, находящихся между старой и новой позициями элемента
			stageSettings.forEach((setting: StageSetting) => {
				if (setting.order > oldPosition && setting.order <= position) {
					setting.order--;
				}
			});
		}

		movingSetting.order = position;

		// Вызываем метод для обновления стадий
		this.changeStageNames();

		stageModelConfig.stageSettings = stageSettings;
	};

	/**
	 * @description Создаёт правила перехода "все стадии".
	 */
	private createAllMovingRulesForNewStage(newId: string) {
		const stages = dispatcher.entity.get()?.entity.sectionWizzard?.stageModelConfig?.stages;
		if (stages) {
			stages.forEach((stage) => {
				dispatcher.stageModel.addMoveRule(newId, stage.id as string);
				dispatcher.stageModel.addMoveRule(stage.id as string, newId);
			});
		}
	}

	/**
	 * @description Добавление новой пустой стадии по индексу.
	 */
	addStage = action("add stage", (order: number, resultType: ResultType | null, position?: number): string => {
		const stageId = v4();
		const stageNumber = this.getNewStageNumber();
		const newStage: Stages = {
			id: stageId,
			name: `Новая стадия ${stageNumber}`
		};
		let color: HEXColors | ResultHEXColors | string = HEXColors.Blue;
		if (!isNull(resultType)) {
			color = ResultHEXColors.Null;
		}
		const newStageSetting: StageSetting = {
			id: v4(),
			stageId: stageId,
			color: color,
			isHidden: false,
			order: position ?? order,
			resultType: resultType
		};

		dispatcher.entity.get()?.entity.sectionWizzard?.stageModelConfig?.stages.push(newStage);
		dispatcher.entity.get()?.entity.sectionWizzard?.stageModelConfig?.stageSettings.push(newStageSetting);
		this.createAllMovingRulesForNewStage(stageId);
		this.moveToById(newStageSetting.stageId, order);
		return newStage.id;
	});

	private getNewStageNumber() {
		const stages = dispatcher.entity.get()?.entity.sectionWizzard?.stageModelConfig?.stages;
		let maxNumber = 0;
		if (stages) {
			stages.forEach((obj) => {
				const number = parseInt(obj.name.split(" ")[2]);

				if (number > maxNumber) {
					maxNumber = number;
				}
			});
		}

		return maxNumber + 1;
	}

	/**
	 * @description удаление стадии по её id.
	 */
	deleteStage = (stageId: string): void => {
		const stageModel = dispatcher.entity.get()?.entity.sectionWizzard?.stageModelConfig;
		if (stageModel) {
			const deletedStageOrder = stageModel.stageSettings.find((stage) => stage.stageId === stageId)?.order;
			const newStageSettings = stageModel.stageSettings.filter((stage) => stage.stageId !== stageId);
			newStageSettings.forEach((stage) => {
				if (deletedStageOrder && stage.order > deletedStageOrder) {
					stage.order--;
				}
			});
			stageModel.stageSettings = newStageSettings;
			const newStages = stageModel.stages.filter((stage) => stage.id !== stageId);
			stageModel.stages = newStages;
			const newMovingRules = stageModel.movingRules.filter((rule) => rule.stageFromId !== stageId || rule.stageToId !== stageId);
			stageModel.movingRules = newMovingRules;
		}
		this.changeStageNames();
	};

	/**
	 * @description скрытие стадии.
	 */
	hideStage = (stageId: string): void => {
		const stageModel = dispatcher.entity.get()?.entity.sectionWizzard?.stageModelConfig;
		if (stageModel) {
			const newStageSetting = stageModel.stageSettings.find((stage) => stage.stageId === stageId);
			if (newStageSetting) {
				newStageSetting.isHidden = true;
			}
		}
	};

	/**
	 * @description появление стадии.
	 */
	viewStage = (stageId: string): void => {
		const stageModel = dispatcher.entity.get()?.entity.sectionWizzard?.stageModelConfig;
		if (stageModel) {
			const newStageSetting = stageModel.stageSettings.find((stage) => stage.stageId === stageId);
			if (newStageSetting) {
				newStageSetting.isHidden = false;
			}
		}
	};

	/**
	 * @description добавление нового правила перехода.
	 */
	addMoveRule(stageFrom: string, stageTo: string) {
		if (stageFrom.length > 0 && stageTo.length > 0) {
			const newMoveRule = {
				id: v4(),
				stageFromId: stageFrom,
				stageToId: stageTo
			};
			const stageModel = dispatcher.entity.get()?.entity.sectionWizzard?.stageModelConfig;
			const duplicateRule = stageModel?.movingRules.find((rule) => rule.stageFromId === stageFrom && rule.stageToId === stageTo);
			if (stageModel && !duplicateRule) {
				stageModel.movingRules.push(newMoveRule);
			}
		}
	}

	/**
	 * @description удаление правила перехода.
	 */
	deleteRuleById(stageFrom: string, stageTo: string) {
		const stageModel = dispatcher.entity.get()?.entity.sectionWizzard?.stageModelConfig;
		if (stageModel) {
			const newRules = stageModel.movingRules.filter((rule) => rule.stageFromId !== stageFrom && rule.stageToId !== stageTo);
			stageModel.movingRules = newRules;
		}
	}
}

export const stageModelController = new StageModelController();
