import { useCallback } from "react";

import { Button } from "sale-bridge-ui-kit";

import { CloseMaxi } from "shared";

import styles from "./warning-popup.module.scss";

export const ClosePopup = (props: { closeConfirm: () => void; onClose: () => void }) => {
	const handleClose = useCallback(() => {
		props.onClose();
		props.closeConfirm();
	}, [props]);

	return (
		<>
			<div className={styles.dialogHeader}>
				<span>Выйти без сохранения?</span>
				<CloseMaxi className={styles.dialogCloseButton} onClick={props.closeConfirm} />
			</div>
			<div className={styles.dialogBody}>
				<span>Есть несохранённые данные.</span>
			</div>
			<div className={styles.dialogFooter}>
				<Button
					text="Закрыть без сохранения"
					size="small"
					variant="default"
					border
					link={false}
					loading={false}
					onClick={handleClose}
				/>
				<Button
					text="Назад к редактированию"
					size="small"
					variant="primary"
					border
					link={false}
					loading={false}
					onClick={props.closeConfirm}
				/>
			</div>
		</>
	);
};
