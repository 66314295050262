import { observer } from "mobx-react";
import { isUndefined } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Clue, Hint } from "sale-bridge-ui-kit";
import { StandartItemGroup } from "sale-bridge-ui-kit/dist/components/button-dropdown/button-dropdown";

import { FilterStore, IFilterStore } from "entities/filter/FilterStore";
import {
	businessRuleEditorStore,
	BusinessRuleEditorValidation
} from "pages/section-wizzard/pages/business-rules/business-rule-editor-store";

import { DropdownItemsId, convertToConditionStandartItem, convertToGroupStandartItem, initDropdownData, regroupStandartItem } from "./data";
import { BlockGroup } from "..";

import { FilterType } from "entities/filter/IFilter";

import styles from "./condition-builder.module.scss";

export const ConditionBuilder = observer((props: { getValidation?: (id: string) => BusinessRuleEditorValidation | undefined }) => {
	const [dropdownItems, setDropdownItems] = useState<StandartItemGroup[]>(initDropdownData);

	const initDropdownItems = useCallback(
		(condition: IFilterStore) => {
			const newItems: StandartItemGroup[] = JSON.parse(JSON.stringify(initDropdownData)) as StandartItemGroup[];

			if (businessRuleEditorStore.conditionFilterStore) {
				//Условие содержится на первом уровне?
				const isCurrentConditionOnFirstDepth = condition.parent?.id === businessRuleEditorStore.conditionFilterStore.id;

				if (isCurrentConditionOnFirstDepth) {
					//Если условие в БП единственное и является не группой
					if (
						businessRuleEditorStore.conditionFilterStore.filters.length == 1 &&
						businessRuleEditorStore.conditionFilterStore.filters[0].type == FilterType.Attribute
					) {
						newItems[1].items[0].isDisable = true;
					}
					//Условие является группой?
					if (condition.type == FilterType.Group) {
						//В группе содержится больше одного условия?
						const isConditionHaveMoreThanOneAttribute = condition.filters.length > 1;
						if (isConditionHaveMoreThanOneAttribute) {
							//Среди условий группы есть хотя бы 1 группа ?
							const isConditionHaveGroup = !isUndefined(condition.filters.find((filter) => filter.type == FilterType.Group));
							if (!isConditionHaveGroup) {
								newItems[0].items.push(convertToGroupStandartItem);
							}
						} else {
							//Это условие в группе является группой ?
							const isConditionChildGroup = condition.filters[0].type == FilterType.Group;
							if (isConditionChildGroup) {
								newItems[0].items.splice(1, 1, regroupStandartItem);
							} else {
								newItems[0].items.push(convertToGroupStandartItem);
								newItems[0].items.push(convertToConditionStandartItem);
							}
						}
					} else {
						newItems[0].items.push(convertToGroupStandartItem);
					}
				} else {
					//Условие содержится на втором уровне?
					const isCurrentConditionOnSecondDepth =
						condition.parent?.parent?.id === businessRuleEditorStore.conditionFilterStore.id;
					if (isCurrentConditionOnSecondDepth) {
						//Условие является группой?
						if (condition.type == FilterType.Group) {
							//В группе содержится больше одного условия?
							const isConditionHaveMoreThanOneAttribute =
								condition.filters.filter((filter) => filter.type == FilterType.Attribute).length > 1;
							if (!isConditionHaveMoreThanOneAttribute) {
								newItems[0].items.push(convertToConditionStandartItem);
							}
						} else {
							newItems[0].items.push(convertToGroupStandartItem);
						}
					}
				}
			}

			setDropdownItems(newItems);
		},
		[businessRuleEditorStore.conditionFilterStore]
	);

	const handleChangeDropdownItems = useCallback((id: string, filter: IFilterStore) => {
		switch (id) {
			case DropdownItemsId.Duplicate:
				businessRuleEditorStore.duplicateCondition(filter);
				break;
			case DropdownItemsId.ConvertToGroup:
				businessRuleEditorStore.convertToGroupCondition(filter);
				break;
			case DropdownItemsId.ConvertToCondition:
			case DropdownItemsId.Regroup:
				businessRuleEditorStore.convertToCondition(filter);
				break;
			case DropdownItemsId.Delete:
				businessRuleEditorStore.deleteCondition(filter);
				break;
		}
	}, []);

	if (!businessRuleEditorStore.conditionFilterStore) {
		return null;
	}

	return (
		<div className={styles.conditionBuilder}>
			<div className={styles.conditionBuilderHeader}>
				<span>Блок «ЕСЛИ»</span>
				<Hint
					hintBody="Укажите условия, при наступлении которых выполнится бизнес-правило. Для работы правила нужно как минимум одно условие."
					startPosition="left"
				>
					<Clue textClue="Что здесь указывать" size="small" />
				</Hint>
			</div>
			<BlockGroup
				depth={businessRuleEditorStore.conditionFilterStore.depth}
				filter={businessRuleEditorStore.conditionFilterStore}
				initDropdownItems={initDropdownItems}
				dropdownItems={dropdownItems}
				onClickDropDown={handleChangeDropdownItems}
				getValidation={props.getValidation}
			/>
		</div>
	);
});
