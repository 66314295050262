import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo, useState } from "react";

import { api, debounce, LowFirst } from "shared";

import { Field } from "sale-bridge-ui-kit";
import { Item } from "types";

import { loadLookup } from "app/services/lookup/LookupService";
import { ItemGroup } from "sale-bridge-ui-kit/dist/components/dropdown/dropdown";
import { createSearchFilter, getRecordWithColumns, useLinkSelect } from "./lib";

import styles from "./style.module.scss";

export type Props = {
	columnTitle?: string | undefined;
	prompt?: string | undefined;
	isRequired?: boolean | undefined;
	value: Item | null;
	onChange: (value: Item | null) => void;
	lookupTable?: string | null | undefined;
	variant: "standart" | "outlined";
	labelPosition?: "horizontal" | "vertical";
	labelVariant?: "black" | "gray";
	inputLink?: string;
	linkTarget?: string;
	isDisabled?: boolean;
};
const DEBOUNCE_TIME = 250;

export const AdapterSelect = observer(function (props: Props) {
	const [searchValue, setSearchValue] = useState("");
	const [value, setValue] = useState<Item | null>(props.value);
	const [items, setItems] = useState<Item[]>([]);
	const [currentViewColumnName, setCurrentViewColumnName] = useState<string>("");

	const link = useLinkSelect(props.lookupTable ?? "", props?.value?.id as string);

	const handleSearchValue = useCallback(
		async (searchValue: string | null) => {
			setSearchValue(searchValue ?? "");
			if (props.lookupTable) {
				const filter = createSearchFilter(props.lookupTable, searchValue, currentViewColumnName);

				const items = await loadLookup(props.lookupTable, filter);
				if (items) {
					setItems(items);
					return items.length;
				} else {
					return -1;
				}
			}
		},
		[props.lookupTable, currentViewColumnName]
	);

	const debouncedHandleSearch = useCallback(debounce(handleSearchValue, DEBOUNCE_TIME), [handleSearchValue]);

	const handleSearch = useCallback(
		(value: string) => {
			setSearchValue(value);
			debouncedHandleSearch(value);
		},
		[debouncedHandleSearch]
	);
	useEffect(() => {
		handleSearchValue("");
	}, []);

	const loadViewValue = useCallback(async () => {
		setValue(props.value);
		if (props.lookupTable && props.value?.id && !props.value?.displayValue) {
			const recordId = props.value.id as string;
			const getRecordWithColumnsResponse = await getRecordWithColumns(props.lookupTable, recordId);
			const viewColumnValue = getRecordWithColumnsResponse.data.data[LowFirst(currentViewColumnName)];
			if (viewColumnValue) {
				setValue({ id: recordId, displayValue: viewColumnValue } as Item);
			}
		}
	}, [handleSearchValue, props.lookupTable, props.value]);

	useEffect(() => {
		loadViewValue();
	}, [loadViewValue]);

	useEffect(() => {
		async function fetchViewColumnName() {
			if (!currentViewColumnName && props.lookupTable) {
				try {
					const getViewColumnResponse = await api.http.entity.getViewColumnByEntityName().post(JSON.stringify(props.lookupTable));

					const viewColumn = getViewColumnResponse.data.data;

					if (viewColumn) {
						setCurrentViewColumnName(viewColumn);
					}
				} catch (error) {
					console.error("Error fetching view column name:", error);
				}
			}
		}

		fetchViewColumnName();
	}, [currentViewColumnName, props.lookupTable]);

	const formatItems = (items: Item[]): Array<ItemGroup> => {
		return [
			{
				layout: <></>,
				items: items.map((item) => (
					<div key={item.id} onClick={() => props.onChange(item)} className={styles.item}>
						<span>{item.name ?? item.displayValue}</span>
					</div>
				))
			}
		];
	};

	const dropdownItems = useMemo(() => {
		return formatItems(items);
	}, [items]);

	return (
		<Field
			type="select"
			size="small"
			label={props.columnTitle ?? ""}
			labelPosition={props.labelPosition ?? "vertical"}
			labelVariant={props.labelVariant}
			isRequired={props.isRequired}
			isClearing
			value={value?.title ?? value?.displayValue ?? value?.name ?? ""}
			info={props.prompt}
			onChange={() => props.onChange(null)}
			items={dropdownItems}
			textVariant={props.variant}
			searchValue={searchValue}
			onChangeSearchValue={handleSearch}
			inputLink={link}
			linkTarget={props.linkTarget}
			isDisabled={props.isDisabled}
		/>
	);
});
