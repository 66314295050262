import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";

import { Loader } from "pages/import/controls";
import importStore from "pages/import/core/import-store";
import { debounce } from "shared";
import { StoreButton } from "pages/import/controls";
import { ResultStates } from "pages/import/data/import-constants";

import { File } from "shared";

import styles from "./finish.module.css";

const start = debounce(() => {
	importStore.startImport();
}, 100);

function Finish() {
	useEffect(() => {
		start();
	}, []);

	if (importStore.importResult?.state === ResultStates.Failure) {
		return <ImportFail />;
	} else if (importStore.importResult?.state === ResultStates.Success) {
		return <ImportComplite />;
	} else {
		return <Loader currentPercent={importStore.importResult?.percent ? importStore.importResult.percent * 100 : 0} />;
	}
}

function ImportComplite() {
	const navigate = useNavigate();

	return (
		<div className={styles.wrapper}>
			<div className={styles.container}>
				{importStore.entity?.displayValue ? (
					<span className={styles.title}>Данные загружены в раздел [{importStore.entity?.displayValue}]</span>
				) : (
					<span className={styles.title}>Данные загружены в раздел</span>
				)}
				<span className={styles.link}>Скачать логи</span>
				<div className={styles.fileName}>
					<File />
					<span>{importStore.file?.name}</span>
				</div>
			</div>
			<div className={styles.actionButtons}>
				<StoreButton
					title="Импортировать еще один файл"
					onClick={() => {
						importStore.new();
					}}
				/>
				<StoreButton
					title="Завершить"
					onClick={() => {
						importStore.new();
						navigate(-1);
					}}
				/>
			</div>
		</div>
	);
}

function ImportFail() {
	const navigate = useNavigate();

	return (
		<div className={styles.wrapper}>
			<div className={styles.container}>
				<p className={styles.title}>При импорте возникла непредвиденная ошибка.</p>
				<p className={styles.title}>
					Пожалуйста, попробуйте снова или{" "}
					<span className={styles.link} style={{ fontSize: "24px" }}>
						напишите нам в поддержку.
					</span>
				</p>
			</div>
			<div className={styles.actionButtons}>
				<StoreButton
					title="Настроить импорт заново"
					onClick={() => {
						importStore.new();
					}}
				/>
				<StoreButton
					title="Завершить"
					onClick={() => {
						importStore.new();
						navigate(-1);
					}}
				/>
			</div>
		</div>
	);
}

export default observer(Finish);
