import classNames from "classnames";

import { MaskState } from "types/entity";

import styles from '../../masks.module.scss'


export const MaskLabel = (props: { type: MaskState }) => {
    let styleType = '';
    if (props.type === MaskState.Default) {
        styleType = 'default';
    } else if (props.type === MaskState.Condition) {
        styleType = 'condition';
    } else styleType = 'notUsed';

    const classes = classNames(styles.label, styles[`${styleType}Label`]);
    switch (props.type) {
        case MaskState.Default: {
            return <div className={classes}>
                <span>По умолчанию</span>
            </div>
        }
        case MaskState.Deactivated: {
            return <div className={classes}>
                <span>Не используется</span>
            </div>
        }
        case MaskState.Condition: {
            return <div className={classes}>
                <span>По условию</span>
            </div>
        }
        default: return <></>
    }
}