import { CSSProperties, useMemo } from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";

import { store } from "store";
import { Position } from "../controller";
import { NotificationCenter, NotificationError, NotificationLeft } from "./notifications-folder";
import { Popup } from "./popup";


export enum ModalType {
	NOTIFICATION = "NOTIFICATION",
	ERROR_NOTIFICATION = "ERROR_NOTIFICATION",
	POPUP = "POPUP",
	LARGE_POPUP = "LARGE_POPUP",
	QUICK_VIEW = "QUICK_VIEW",
}

export type ModalData = {
	id: string;
	type?: ModalType;
	position?: Position;
	queue?: number;
	layout?: JSX.Element;
	style?: CSSProperties;
	allowTimer?: boolean;
	allowDefaultClick?: boolean;
	closeFunc?: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
	withBackdrop?: boolean;
}

export const ModalViewer = observer(() => {
	const padding = 64;
	const notificationStyle = (index: number) => { return { bottom: `calc(${padding * index}px + 40px)` }; };

	const notificationsLeft = useMemo(() => {
		let notificationsLeft: ModalData[] = [];

		store.modals.forEach((modal, index) => {
			if (modal.modal.type === ModalType.NOTIFICATION && modal.modal.position === Position.LEFT) {
				notificationsLeft.push({ id: modal.id, layout: modal.modal.layout, queue: index });
			}
		});

		return notificationsLeft.map((notification, index) => {
			<NotificationLeft
				key={notification.id}
				{...notification}
				style={notificationStyle(index)}
			/>
		});
	}, [toJS(store.modals)]);

	const notificationsCenter = useMemo(() => {
		let notificationsCenter: ModalData[] = [];

		store.modals.forEach((modal, index) => {
			if (modal.modal.type === ModalType.NOTIFICATION && modal.modal.position === Position.CENTER) {
				notificationsCenter.push({
					id: modal.id, layout: modal.modal.layout,
					allowTimer: modal.modal.allowTimer, allowDefaultClick: modal.modal.allowDefaultClick,
					withBackdrop: modal.modal.withBackdrop,
				});
			}
		});

		return notificationsCenter.map((notification) => {
			return <NotificationCenter
				key={notification.id}
				{...notification}
			/>
		});
	}, [toJS(store.modals)]);

	const notificationsError = useMemo(() => {
		
		const errors = store.modals.reduce<ModalData[]>((errors, modal) => {
			if (modal.modal.type === ModalType.ERROR_NOTIFICATION) {
				errors.push({
					id: modal.id,
					layout: modal.modal.layout,
					position: modal.modal.position,
					withBackdrop: modal.modal.withBackdrop,
				});
			}
			return errors;
		}, []);

		return errors.map((notification) => {
			return <NotificationError
				key={notification.id} 
				{...notification}
			/>
		});
	}, [toJS(store.modals)]);

	const popUp = useMemo(() => {
		let popups: ModalData[] = [];

		store.modals.forEach((modal, index) => {
			if (modal.modal.type === ModalType.POPUP || modal.modal.type === ModalType.LARGE_POPUP || modal.modal.type === ModalType.QUICK_VIEW) {
				popups.push({ layout: modal.modal.layout, id: modal.id, closeFunc: modal.modal.closeFunc, type: modal.modal.type });
			}
		});

		return popups.map((popup, index) => (<Popup
			key={index}
			closeFunc={popup.closeFunc}
			type={popup.type ?? ModalType.POPUP}
		>
			{popup.layout}
		</Popup>
		));
	}, [toJS(store.modals)]);

	return (<>
		{notificationsLeft}
		{notificationsCenter}
		{notificationsError}
		{popUp}
	</>);
});