import ISearchItem from "entities/search/model/SearchItem";
import { Link } from "react-router-dom";
import styles from "./SearchItem.module.css";

interface ISearchCardProps {
    icon: JSX.Element;
    entity: ISearchItem;
}

function SearchItem(props: ISearchCardProps) {
    function getHrefBySchema() {
        switch (props.entity.schema) {
            case ("Contact"):
                {
                    return "/contacts";
                }
            case ("Lead"):
                {
                    return "/leads";
                }
            case ("Sale"):
                {
                    return "/sales";
                }
            default:
                {
                    return "/accounts";
                }
        }
    }
    function onClick() {
        let href = window.location.origin;
        href+=getHrefBySchema();
        href+="/"+props.entity.id;
        window.location.assign(href);
    }
    return (
        <div className={styles.body}>
            {props.icon}
            <div className={styles.info} onClick={onClick}>
                <span className={styles.title}>{props.entity.name}</span>
                <span className={styles.items}>
                    <span> — </span>
                    {props.entity.columns.map(i => <InfoItem value={i} />)}
                </span>
                
            </div>
        </div>
    )
}

function InfoItem(props: { value: string }) {
    return (
        <span className={styles.test}>{props.value} </span>
    )
}

export default SearchItem;