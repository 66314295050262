import { makeAutoObservable } from "mobx";
import { v4 } from "uuid";
import { api } from "shared";
import { isEmpty, isUndefined } from "lodash";

import { dispatcher, selector } from "store";
import { validateRequired, validateSchema } from "entities/Validation";
import { synchroiser } from "synchroiser";

import { ILookup } from "entities/Entity";
import { ILookupData } from "pages/settings/data/Fields";
import { ColumnType } from "entities/ColumnType";
import { VirtualLookup } from "./types";
import {
	ColumnSpecializationType,
	EntityColumnSpecialization,
	EntityNameType,
	FieldConfig,
	GridItem,
	ItemType,
	NumeratorMaskConditions,
	Properties
} from "types/entity";
import {
	DEFAULT_ERROR_VALUE,
	ERROR_LOOKUP_EXIST_NAME,
	ERROR_LOOKUP_EXIST_TITLE,
	HIDE_ELEMENT_POSITION,
	ERROR_VALUE_EXIST_NAME,
	ERROR_VALUE_EXIST_TITLE,
	LookupType,
	ValidationState
} from "pages/section-wizzard/data/data";
import IFilter from "entities/filter/IFilter";
import { NumeratorConfigurationState } from "./numerator-configuration";

export enum FieldStoreEnums {
	columnId = "columnId",
	fieldType = "fieldType",
	defaultValue = "defaultValue",
	systemName = "systemName",
	title = "title",
	hasIndex = "hasIndex",
	isRequired = "isRequired",
	isSetDefaultData = "isSetDefaultData",
	prompt = "prompt",
	rounding = "rounding",
	lookupType = "lookupType",
	selectedLookupDefaultValue = "selectedLookupDefaultValue",
	selectedLookup = "selectedLookup",
	specializations = "specializations",
	virtualLookupValues = "virtualLookupValues",
	numeratorConfig = "numeratorConfig",
	uneditable = "uneditable",
	isViewColumn = "isViewColumn"
}

const initialVirtualLookup: VirtualLookup = {
	entityTitle: "",
	systemName: "",
	columnInfo: [
		{
			columnId: v4(),
			columnName: "Name",
			columnTitle: "Название",
			columnType: ColumnType.String,
			isLookup: false,
			isLink: false,
			lookupTable: null,
			isRequired: true,
			hasIndex: false,
			specializations: null
		}
	],
	isLookup: true,
	virtualLookupValues: [{ id: v4(), name: "" }]
};

const initialValidation = {
	title: {
		isInvalid: false,
		isNotUnique: false,
		error: ""
	},
	systemName: {
		isInvalid: false,
		error: ""
	},

	lookup: {
		isInvalid: false,
		error: ""
	},
	virtualLookupSystemName: {
		isInvalid: false,
		error: ""
	},
	virtualLookupTitle: {
		isInvalid: false,
		isNotUnique: false,
		error: ""
	},

	defaultValue: {
		isInvalid: false,
		error: ""
	},
	rounding: {
		isInvalid: false,
		error: ""
	}
};
class FieldConfigurationStore {
	fieldIsLoading: boolean = false;
	hasChanges: boolean = false;

	columnId: string = "";
	fieldType: ColumnType = ColumnType.String;
	defaultValue: any = "";

	x: number = HIDE_ELEMENT_POSITION;
	y: number = HIDE_ELEMENT_POSITION;

	systemName: string = "";
	title: string = "";
	hasIndex: boolean = false;
	isRequired: boolean = false;
	isSetDefaultData: boolean = false;
	uneditable: boolean = false;
	prompt: string = "";
	specializations: EntityColumnSpecialization = { tag: ColumnSpecializationType.Double, properties: {} };

	rounding: string = "";

	validation: { [key: string]: ValidationState };

	lookupType: LookupType | string = "";

	//Настройки выпадающих списоков
	lookups: ILookupData[] = [];
	selectedLookup: ILookupData | null = null;

	selectedLookupData: ILookup[] = [];
	selectedLookupDefaultValue: ILookup | null = null;

	//Виртуальные справочники
	virtualLookup: VirtualLookup;

	//нумератор
	numeratorConfig: NumeratorConfigurationState;

	isViewColumn: boolean = false;

	constructor() {
		makeAutoObservable(this);
		this.virtualLookup = initialVirtualLookup;
		this.numeratorConfig = new NumeratorConfigurationState();
		this.validation = initialValidation;
	}

	/**
	 * Метод сохраняет конфигурацию поля после его добавления или редактирования на хосте
	 * @param value Информация о поле и его расположении на холсте
	 */
	saveConfiguration(value: { targetZone?: string | null; columnId: string }) {
		const currentTabIndex = dispatcher.entity.get()?.entity.sectionWizzard?.reactorConfig.tabs.currentTab ?? 0;
		const fieldGroup = dispatcher.sectionWizzard
			.getSectionWizzard()
			?.reactorConfig.tabs.tabsConfig[currentTabIndex].grid.items.find((item) => item.gridItemId === value.targetZone);
		const currentFieldConfig = this.createFieldConfig();

		if (fieldGroup?.groupFieldsConfig?.inner?.items) {
			this.updateInnerFieldConfig(fieldGroup, currentFieldConfig, value.columnId);
		}

		this.updateMatchingFieldConfig(value.columnId, currentFieldConfig, currentTabIndex);

		const findItemInField = this.findItemInFieldConfig(value.columnId, currentTabIndex);
		const findGroup = this.findGroupFieldConfig(value.columnId, currentTabIndex);

		if (!this.updateFieldConfig(findItemInField, findGroup, currentFieldConfig)) {
			this.addNewGridItem(value.targetZone, currentTabIndex, currentFieldConfig);
		}

		this.updateBasedOnConfiguration();
		this.resetConfiguration();
	}

	private createFieldConfig(): FieldConfig {
		return {
			columnId: this.columnId,
			columnName: this.systemName,
			columnType: this.fieldType,
			columnTitle: this.title,
			defaultValue: this.defaultValue,
			rounding: this.rounding,
			prompt: this.prompt,
			uneditable: this.uneditable,
			specializations: this.specializations,
			numeratorConfig: this.fieldType === ColumnType.Numerator ? this.numeratorConfig.getNumeratorConfig() : null,
			virtualLookup: this.lookupType === LookupType.NewLookup ? this.virtualLookup : null,
			lookupTable: this.lookupType === LookupType.NewLookup ? this.virtualLookup.systemName : this.selectedLookup?.entityName!,
			isLookup: this.fieldType === ColumnType.Lookup,
			hasIndex: this.hasIndex,
			isRequired: this.isRequired,
			isViewColumn: this.isViewColumn
		};
	}

	private updateInnerFieldConfig(fieldGroup: GridItem, fieldConfig: FieldConfig, columnId: string) {
		const item = fieldGroup.groupFieldsConfig!.inner!.items.find((innerItem) => innerItem?.fieldConfig?.columnId === columnId);
		if (item?.fieldConfig) {
			dispatcher.sectionWizzard.getAllGridItems().forEach((equalItems) => {
				if (equalItems?.fieldConfig?.columnId === columnId) {
					equalItems.fieldConfig = fieldConfig;
				}
			});
		}
	}
	private updateMatchingFieldConfig(columnId: string, fieldConfig: FieldConfig, currentTabIndex: number) {
		const itemsWithEqualColumnId = dispatcher.sectionWizzard
			.getAllGridItems()
			.filter((item) => item.fieldConfig?.columnId === columnId);
		itemsWithEqualColumnId?.forEach((item) => {
			const target = dispatcher.entity
				.get()
				?.entity.sectionWizzard?.reactorConfig.tabs.tabsConfig[currentTabIndex].grid.items.find(
					(checkElem) =>
						checkElem?.groupFieldsConfig?.inner?.items.find((innerItem) => innerItem.gridItemId === item.gridItemId) ||
						checkElem.gridItemId === item.gridItemId
				);
			if (target?.fieldConfig) {
				target.fieldConfig.columnTitle = this.title;
				target.fieldConfig.columnName = this.systemName;
			} else if (target?.groupFieldsConfig?.inner) {
				const innerTarget = target.groupFieldsConfig.inner.items.find((innerItem) => innerItem.gridItemId === item.gridItemId);
				if (innerTarget?.fieldConfig) {
					innerTarget.fieldConfig = fieldConfig;
				}
			}
		});
	}

	private findItemInFieldConfig(columnId: string, currentTabIndex: number): any {
		return dispatcher.entity
			.get()
			?.entity.sectionWizzard?.reactorConfig.tabs.tabsConfig[currentTabIndex].grid.items.find(
				(item) => item.fieldConfig?.columnId === columnId
			);
	}

	private findGroupFieldConfig(columnId: string, currentTabIndex: number): any {
		return dispatcher.entity
			.get()
			?.entity.sectionWizzard?.reactorConfig.tabs.tabsConfig[currentTabIndex].grid.items.find(
				(item) => item.groupFieldsConfig?.groupFieldId === columnId
			);
	}

	private updateFieldConfig(findItemInField: any, findGroup: any, fieldConfig: FieldConfig): boolean {
		let flag = false;
		if (findItemInField) {
			findItemInField.fieldConfig = fieldConfig;
			flag = true;
		} else if (findGroup) {
			const innerItems = findGroup.groupFieldsConfig?.inner?.items;
			if (innerItems) {
				for (let indexItem = 0; indexItem < innerItems?.length; indexItem++) {
					if (innerItems[indexItem].fieldConfig?.columnId === fieldConfig.columnId) {
						innerItems[indexItem].fieldConfig = fieldConfig;
						flag = true;
					}
				}
			}
		}
		return flag;
	}

	private addNewGridItem(targetZone: string | null | undefined, currentTabIndex: number, fieldConfig: FieldConfig) {
		const item: GridItem = {
			x: this.x,
			y: this.y,
			gridItemId: v4(),
			width: 1,
			height: 1,
			type: ItemType.Field,
			fieldConfig: fieldConfig
		};
		const result = dispatcher.sectionWizzard.createNewGridItemInGroup(targetZone ?? "", currentTabIndex, item);
		if (!result) {
			dispatcher.sectionWizzard.createNewGridItemInTab(item, currentTabIndex);
		}
	}

	/**
	 * Метод предназначен для дополнительных внешних воздействий при сохранении поля. Например установки ViewColumn в SectionWizzard
	 */
	updateBasedOnConfiguration() {
		if (this.isViewColumn && (this.fieldType === ColumnType.String || this.fieldType === ColumnType.Numerator)) {
			dispatcher.sectionWizzard.changeObserver(dispatcher.sectionWizzard.setViewColumnId(this.columnId));
		}

		if (
			!this.isViewColumn &&
			(this.fieldType === ColumnType.String || this.fieldType === ColumnType.Numerator) &&
			this.columnId === dispatcher.sectionWizzard.getViewColumnId()
		) {
			dispatcher.sectionWizzard.changeObserver(dispatcher.sectionWizzard.setViewColumnId(null));
		}
	}

	getIsDisabledForRequiredCheckBox() {
		const priorityField = selector.sectionWizzard.getPriorityFieldBySpecialization();
		const stageField = selector.sectionWizzard.getStageFieldBySpecialization();

		if (
			(priorityField && priorityField.fieldConfig?.columnId === this.columnId) ||
			(stageField && stageField.fieldConfig?.columnId === this.columnId)
		) {
			return true;
		}

		return this.isViewColumn;
	}

	getIsDisabledForSystemName() {
		const priorityField = selector.sectionWizzard.getPriorityFieldBySpecialization();
		const stageField = selector.sectionWizzard.getStageFieldBySpecialization();
		const isNumerator = this.fieldType === ColumnType.Numerator;

		if (
			(priorityField && priorityField.fieldConfig?.columnId === this.columnId) ||
			(stageField && stageField.fieldConfig?.columnId === this.columnId) ||
			(isNumerator && this.fieldIsExistInDB())
		) {
			return true;
		}

		return false;
	}

	getIsDisabledForLookupObject() {
		const priorityField = selector.sectionWizzard.getPriorityFieldBySpecialization();
		const stageField = selector.sectionWizzard.getStageFieldBySpecialization();

		if (
			(priorityField && priorityField.fieldConfig?.columnId === this.columnId) ||
			(stageField && stageField.fieldConfig?.columnId === this.columnId)
		) {
			return true;
		}

		return false;
	}

	/**
	 * Возвращает информацию о том существует ли поле в БД
	 * @returns boolean
	 */
	fieldIsExistInDB() {
		const existcolumns = dispatcher.entity.get()?.entity.columns;
		if (existcolumns) {
			let target = existcolumns.find((x) => x.columnId === this.columnId);
			return !isUndefined(target);
		}
		return false;
	}

	initNumerator(): void {
		const currentViewColumn = dispatcher.sectionWizzard.getViewColumnId();
		if (currentViewColumn && currentViewColumn !== this.columnId) {
			this.uneditable = true;
		} else {
			if (this.columnId) {
				this.isViewColumn = true;
				this.isRequired = true;
				this.numeratorConfig.fillWhenPageOpened = true;
			}
		}
	}

	/**
	 * Метод определяет существует ли текущая колонка в SectionWizzard в базе
	 * @returns boolean
	 */
	isOldNumeratorField(): boolean {
		const gridItems = dispatcher.sectionWizzard.getAllGridItemsOfOldSectionWizzard();
		return !isUndefined(gridItems.find((gridItem) => gridItem.fieldConfig?.columnId === this.columnId));
	}

	/**
	 * Метод определяет является ли текущая колонка ViewColumn в SectionWizzard
	 * @returns boolean
	 */
	checkIsViewColumn(): boolean {
		return this.columnId === dispatcher.sectionWizzard.getSectionWizzard()?.viewColumnId;
	}

	resetViewColumn() {
		dispatcher.sectionWizzard.setViewColumnId(null);
	}

	setValue(fieldName: string, value: string | boolean | null | number | ILookup[]) {
		this.hasChanges = true;
		Reflect.set(this, fieldName, value);
	}

	setValueWithoutTrackingChanges(fieldName: string, value: string | boolean | null | number) {
		Reflect.set(this, fieldName, value);
	}

	setSpecialization(fieldName: string, tag: ColumnSpecializationType, properties: Properties) {
		this.hasChanges = true;
		Reflect.set(this, fieldName, { tag, properties });
	}

	setNumeratorConfig(fieldName: string, value: string | boolean | null | number | ILookup[] | NumeratorMaskConditions[]) {
		this.hasChanges = true;
		Reflect.set(Reflect.get(this, FieldStoreEnums.numeratorConfig), fieldName, value);
	}

	isValidDate(date: string) {
		return !isNaN(Date.parse(date));
	}

	isValidGuidValue(value: string): boolean {
		const guidPattern = /^[{]?[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}[}]?$/;
		return guidPattern.test(value);
	}

	isConfigurationValid(): boolean {
		const isTitleValid = !this.validation.title.isInvalid && this.title.length !== 0;
		const isSystemNameValid = !this.validation.systemName.isInvalid && this.systemName.length !== 0;

		if (this.fieldType === ColumnType.Decimal) {
			const roundingValid = !this.validation.rounding.isInvalid && this.rounding.length !== 0;
			return isTitleValid && isSystemNameValid && this.validation.defaultValue && roundingValid;
		}

		if (this.fieldType === ColumnType.Lookup) {
			const lookupValid = !this.validation.lookup.isInvalid;

			if (this.lookupType === LookupType.ExistLookup) {
				return isTitleValid && isSystemNameValid && this.validation.defaultValue && lookupValid && this.selectedLookup !== null;
			} else if (this.lookupType === LookupType.NewLookup) {
				let virtualLookupValuesValid = true;
				this.virtualLookup.virtualLookupValues.forEach((value) => {
					if (value.name.length <= 0 || value.name === "") virtualLookupValuesValid = false;
				});

				const virtualLookupValid =
					!this.validation.virtualLookupSystemName.isInvalid &&
					!this.validation.virtualLookupTitle.isInvalid &&
					this.virtualLookup.entityTitle.length !== 0 &&
					this.virtualLookup.systemName.length !== 0 &&
					virtualLookupValuesValid;
				return isTitleValid && isSystemNameValid && this.validation.defaultValue && virtualLookupValid;
			}
		}
		return isTitleValid && isSystemNameValid && !this.validation.defaultValue.isInvalid;
	}

	checkExistTitleInConfig(title: string) {
		return dispatcher.sectionWizzard.getAllGridItems().find((item) => item.fieldConfig?.columnTitle === title) ?? false;
	}
	checkExistNameInConfig(name: string) {
		return dispatcher.sectionWizzard.getAllGridItems().find((item) => item.fieldConfig?.columnName === name) ?? false;
	}

	validateTitle() {
		const isNotUnique = dispatcher.sectionWizzard.checkExistFieldTitle(this.title);
		this.validation.title.isNotUnique = isNotUnique;
		if (isNotUnique || this.checkExistTitleInConfig(this.title)) {
			this.validation.title.isInvalid = false;
			this.validation.title.error = ERROR_VALUE_EXIST_TITLE;
		} else {
			validateRequired(this.title, this.validation.title);
		}
	}

	validateSystemName() {
		const isNotUnique = dispatcher.sectionWizzard.checkExistFieldName(this.systemName);
		if (isNotUnique || this.checkExistNameInConfig(this.systemName)) {
			this.validation.systemName.isInvalid = isNotUnique;
			this.validation.systemName.error = ERROR_VALUE_EXIST_NAME;
		} else {
			validateSchema(this.systemName, this.validation.systemName);
		}
	}
	checkExistVirtualTitleInConfig(title: string) {
		return dispatcher.sectionWizzard.getAllGridItems().find((item) => item.fieldConfig?.virtualLookup?.entityTitle === title) ?? false;
	}
	checkExistVirtualNameInConfig(name: string) {
		return dispatcher.sectionWizzard.getAllGridItems().find((item) => item.fieldConfig?.virtualLookup?.systemName === name) ?? false;
	}

	async validateNewLookupTitle() {
		if (this.checkExistVirtualTitleInConfig(this.virtualLookup.entityTitle)) {
			this.validation.virtualLookupTitle.isNotUnique = true;
			this.validation.virtualLookupTitle.isInvalid = false;
			this.validation.virtualLookupTitle.error = ERROR_LOOKUP_EXIST_TITLE;
		} else {
			if (this.lookupType === LookupType.NewLookup) {
				const isNotUnique = await synchroiser.checkExistEntityTitle(this.virtualLookup.entityTitle, EntityNameType.Lookups);
				this.validation.virtualLookupTitle.isNotUnique = isNotUnique;
				if (isNotUnique) {
					this.validation.virtualLookupTitle.isInvalid = false;
					this.validation.virtualLookupTitle.error = ERROR_LOOKUP_EXIST_TITLE;
				} else {
					validateRequired(this.virtualLookup.entityTitle, this.validation.virtualLookupTitle);
				}
			} else {
				this.validation.virtualLookupTitle.isNotUnique = false;
				validateRequired(this.virtualLookup.entityTitle, this.validation.virtualLookupTitle);
			}
		}
	}

	async validateNewLookupName() {
		if (this.checkExistVirtualNameInConfig(this.virtualLookup.systemName)) {
			this.validation.virtualLookupSystemName.isInvalid = true;
			this.validation.virtualLookupSystemName.error = ERROR_LOOKUP_EXIST_NAME;
		} else {
			if (this.lookupType === LookupType.NewLookup) {
				const isNotUnique = await synchroiser.checkExistEntityName(this.virtualLookup.systemName);
				if (isNotUnique) {
					this.validation.virtualLookupSystemName.isInvalid = isNotUnique;
					this.validation.virtualLookupSystemName.error = ERROR_LOOKUP_EXIST_NAME;
				} else {
					validateSchema(this.virtualLookup.systemName, this.validation.virtualLookupSystemName);
				}
			} else {
				validateSchema(this.virtualLookup.systemName, this.validation.virtualLookupSystemName);
			}
		}
	}

	setInvalidDefaultValue(value: boolean) {
		if (value) {
			this.validation.defaultValue.isInvalid = value;
			this.validation.defaultValue.error = DEFAULT_ERROR_VALUE;
		} else {
			this.validation.defaultValue.isInvalid = false;
			this.validation.defaultValue.error = "";
		}
	}

	validateDefaultValue() {
		switch (this.fieldType) {
			case ColumnType.String:
				if (typeof this.defaultValue !== "string") {
					this.setInvalidDefaultValue(true);
				} else {
					this.setInvalidDefaultValue(false);
				}
				break;
			case ColumnType.Integer:
				const isPureNumber = /^\d+$/.test(this.defaultValue);
				if (isPureNumber || isEmpty(this.defaultValue)) {
					this.setInvalidDefaultValue(false);
				} else {
					this.setInvalidDefaultValue(true);
				}
				break;
			case ColumnType.Decimal:
				const validDfaultValue = /^-?\d+(\.\d+)?$/.test(this.defaultValue) || this.defaultValue === "";
				if (validDfaultValue) {
					this.setInvalidDefaultValue(false);
				} else {
					this.setInvalidDefaultValue(true);
				}

				break;
			case ColumnType.Lookup:
				if (!this.isValidGuidValue(this.defaultValue)) {
					this.setInvalidDefaultValue(false);
				} else {
					this.setInvalidDefaultValue(true);
				}
				break;
			case ColumnType.DateTime:
				if (!this.isValidDate(this.defaultValue)) {
					this.setInvalidDefaultValue(false);
				} else {
					this.setInvalidDefaultValue(true);
				}
				break;
			case ColumnType.Boolean:
				if (this.defaultValue === "true" || this.defaultValue === "false" || this.defaultValue === "") {
					this.setInvalidDefaultValue(false);
				} else {
					this.setInvalidDefaultValue(true);
				}
				break;
			default:
				this.setInvalidDefaultValue(true);
		}
	}

	convertToLookupDataArray(dataArray: any): ILookup[] {
		return dataArray.map((dataItem: any) => ({
			id: dataItem.id,
			name: dataItem.name,
			title: dataItem.displayValue ?? dataItem.name
		}));
	}

	setLookups(data: ILookupData[]) {
		this.lookups = data;
	}

	/**
	 * Метод подгружает справочники и разделы для источника данных у справочного поля
	 */
	async loadLookups() {
		let req = await api.http.entity.entitySectionAndLookupDataList().get();
		this.setLookups(req?.data.data);
		return req?.data.data;
	}

	setLookupData(data: any) {
		let convertedData = this.convertToLookupDataArray(data);
		this.selectedLookupData = convertedData;
	}

	/**
	 * Метод подгружает значение справочника для того что отобразить в выпадающем списке
	 * @param lookupName Системное название справочника
	 */
	async loadLookupData(lookupName: string, filter: IFilter | null) {
		let req = await api.http.entity.recordsListWithColumns().post({
			entityName: lookupName,
			columnNames: [],
			filter: filter
		});
		this.setLookupData(req?.data.data.records);
		return req?.data.data.records;
	}

	async getLookupConfigurationByColumn(column: GridItem) {
		const isVirtualLookup = column.fieldConfig?.virtualLookup !== null;
		this.lookupType = isVirtualLookup ? LookupType.NewLookup : "";

		await this.loadLookups();
		if (!isVirtualLookup) {
			await this.loadLookupData(column.fieldConfig?.lookupTable!, null);
		}

		this.selectedLookup = this.lookups.find((lookup) => lookup.entityName === column.fieldConfig?.lookupTable) ?? null;
		this.virtualLookup = column.fieldConfig?.virtualLookup!;
		this.selectedLookupDefaultValue = this.getSelectedLookupData(isVirtualLookup);
	}

	getNumeratorConfigurationByColumn(column: GridItem) {
		if (column.fieldConfig?.numeratorConfig) {
			this.numeratorConfig.fillWhenPageOpened = column.fieldConfig.numeratorConfig.fillWhenPageOpened;
			this.numeratorConfig.maskItems = column.fieldConfig.numeratorConfig.maskSettings.items;
			this.numeratorConfig.conditions = column.fieldConfig.numeratorConfig.maskSettings.conditions;
			this.numeratorConfig.numeratorWorkingPeriod = column.fieldConfig.numeratorConfig.numeratorWorkingPeriod;
		}
	}

	setParametersByColumn(column: GridItem, columnId: string) {
		this.columnId = columnId;
		if (column.fieldConfig) {
			this.systemName = column.fieldConfig.columnName ?? "";
			this.fieldType = column.fieldConfig.columnType as ColumnType;
			this.title = column.fieldConfig.columnTitle ?? "";
			this.hasIndex = column.fieldConfig.hasIndex ?? "";
			this.isRequired = column.fieldConfig.isRequired ?? "";
			this.defaultValue = column.fieldConfig.defaultValue;
			this.rounding = column.fieldConfig.rounding ?? "";
			this.prompt = column.fieldConfig.prompt ?? "";
			this.uneditable = column.fieldConfig.uneditable;
			this.specializations = column.fieldConfig.specializations ?? { tag: ColumnSpecializationType.Double, properties: {} };
		}
	}

	async getConfigurationById(columnId: string) {
		this.fieldIsLoading = true;
		const currentTabIndex = dispatcher.entity.get()?.entity.sectionWizzard?.reactorConfig.tabs.currentTab ?? 0;
		const column = dispatcher.entity
			.get()
			?.entity.sectionWizzard?.reactorConfig.tabs.tabsConfig[currentTabIndex].grid.items.find(
				(item) => item.fieldConfig?.columnId === columnId
			);

		const currentViewColumn = dispatcher.sectionWizzard.getViewColumnId();

		if (column && column.fieldConfig) {
			this.setParametersByColumn(column, columnId);
			this.isViewColumn = currentViewColumn === columnId;
			if (this.fieldType === ColumnType.Lookup) {
				await this.getLookupConfigurationByColumn(column);
			} else if (this.fieldType === ColumnType.Numerator) {
				this.getNumeratorConfigurationByColumn(column);
			}
		} else {
			let innerColumn: GridItem = {
				x: 0,
				y: 0,
				gridItemId: "",
				width: 0,
				height: 0
			};

			dispatcher.entity
				.get()
				?.entity.sectionWizzard?.reactorConfig.tabs.tabsConfig[currentTabIndex].grid.items.forEach((groupField) =>
					groupField.groupFieldsConfig?.inner?.items.forEach((item) => {
						if (item.fieldConfig?.columnId === columnId) {
							innerColumn = item;
						}
					})
				);

			if (innerColumn.gridItemId.length > 0 && innerColumn.fieldConfig) {
				this.setParametersByColumn(innerColumn, columnId);
				this.isViewColumn = innerColumn.fieldConfig.isViewColumn;

				if (innerColumn?.fieldConfig) {
					if (this.fieldType === ColumnType.Lookup) {
						await this.getLookupConfigurationByColumn(innerColumn);
					} else if (this.fieldType === ColumnType.Numerator) {
						this.getNumeratorConfigurationByColumn(innerColumn);
					}
				}
			}
		}

		if (column) {
			this.hasChanges = false;
		}
		this.fieldIsLoading = false;
	}
	/**
	 * Метод предназначен для того чтобы получить значение по умолчанию для справочного поля буд то по существующему или по новому справочнику.
	 */
	getSelectedLookupData(isVirtualLookup: boolean): ILookup | null {
		const selectedData = isVirtualLookup
			? this.virtualLookup.virtualLookupValues.find((item) => item.id === this.defaultValue)
			: this.selectedLookupData.find((item) => item.id === this.defaultValue);
		return selectedData || null;
	}

	resetConfiguration() {
		this.hasChanges = false;
		this.fieldType = ColumnType.String;
		this.defaultValue = "";
		this.systemName = "";
		this.title = "";
		this.hasIndex = false;
		this.isRequired = false;
		this.isSetDefaultData = false;
		this.uneditable = false;
		this.prompt = "";
		this.lookupType = "";
		this.rounding = "";
		this.specializations = { tag: ColumnSpecializationType.Double, properties: {} };

		this.validation.defaultValue.isInvalid = false;
		this.validation.defaultValue.error = "";

		this.selectedLookup = null;
		this.selectedLookupData = [];
		this.selectedLookupDefaultValue = null;

		this.virtualLookup = initialVirtualLookup;

		this.numeratorConfig.resetConfiguration();
		this.isViewColumn = false;

		for (const key in this.validation) {
			this.validation[key].isInvalid = false;
			this.validation[key].isNotUnique = false;
			this.validation[key].error = "";
		}
	}
}

const fieldConfigurationStore = new FieldConfigurationStore();

export default fieldConfigurationStore;
