import { NumeratorWorkingPeriod } from "types/entity";

export const itemsForPeriodDropdown = [
    {
        id: NumeratorWorkingPeriod.WithoutExpirationDate,
        text: 'Без срока действия'
    },
    {
        id: NumeratorWorkingPeriod.Day,
        text: 'День'
    },
    {
        id: NumeratorWorkingPeriod.Week,
        text: 'Неделя'
    },
    {
        id: NumeratorWorkingPeriod.Month,
        text: 'Месяц'
    },
    {
        id: NumeratorWorkingPeriod.Year,
        text: 'Год'
    },
]