import { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { v4 } from "uuid";

import { dispatcher, store } from "store";
import Reactor from "module/reactor/Reactor";
import { Position, modalController } from "features/modals";

import { SkeletonFullView } from "features/skeleton-full-view";

import { GridItem, SectionWizzard, TabId } from "types/entity";

import styles from "./preview-full-view.module.scss";

const Circle = () => {
	return (
		<div className={styles.loaderWrapper}>
			<div className={styles.rotate}>
				<svg className={styles.circleLoader} viewBox="0 0 40 40">
					<circle
						className={styles.circle}
						cx="20"
						cy="20"
						r="15"
						fill="none"
						stroke={`var(--color-indigo-600)`}
						strokeWidth="2"
					></circle>
				</svg>
			</div>
		</div>
	);
};

const NotificationViewingLayout = () => {
	return (
		<>
			<Circle />
			<div className={styles.noCursor}>Подготовка предварительного просмотра...</div>
		</>
	);
};

const NotificationViewedLayout = () => {
	return (
		<div className={styles.notification}>
			<div className={styles.noCursor}>Предварительный просмотр настроен</div>
		</div>
	);
};

export const PreviewFullView = observer((props: { sectionWizzard: SectionWizzard | null | undefined }) => {
	const [isPreviewLoading, setIsPreviewLoading] = useState<boolean>(true);
	const [idLoadingNotification] = useState<string>(v4());
	const [idLoadedNotification] = useState<string>(v4());

	const addLoadingNotificaion = useCallback(() => {
		modalController.notificationAdd({
			id: idLoadingNotification,
			position: Position.CENTER,
			layout: <NotificationViewingLayout />
		});
	}, [idLoadingNotification]);

	const addLoadedNotificaion = useCallback(() => {
		modalController.notificationAdd({
			id: idLoadedNotification,
			position: Position.CENTER,
			layout: <NotificationViewedLayout />,
			allowTimer: true
		});
	}, [idLoadedNotification]);

	const findPanelTitle = useCallback(
		(tabId: string) => {
			const additional = dispatcher.sectionWizzard.getAdditionalFromConfig();
			if (additional) {
				const tab = additional[tabId as TabId];
				if (tab) return tab.title;
			} else return "";
		},
		[dispatcher.sectionWizzard.getAdditionalFromConfig()]
	);

	const viewColumnTitle = useMemo(() => {
		return dispatcher.sectionWizzard.getViewColumnTitle();
	}, [dispatcher.sectionWizzard.getSectionWizzard()?.viewColumnId]);

	const viewAdditionalPanel = useMemo(() => {
		return dispatcher.sectionWizzard.getViewAdditional();
	}, [dispatcher.sectionWizzard.getViewAdditional()]);

	useEffect(() => {
		if (!store.modals.find((modal) => modal.id === idLoadingNotification)) {
			addLoadingNotificaion();
		}
	}, []);

	useEffect(() => {
		if (props.sectionWizzard)
			setTimeout(() => {
				setIsPreviewLoading(false);
			}, 1000);
	}, [props.sectionWizzard]);

	useEffect(() => {
		if (isPreviewLoading) {
			if (store.modals.find((modal) => modal.id === idLoadedNotification)) {
				modalController.modalRemove(idLoadedNotification);
			}
		} else {
			store.modals.forEach((modal) => modalController.modalRemove(modal.id));
			addLoadedNotificaion();
		}
	}, [isPreviewLoading]);

	const xmlParser = useMemo(
		() =>
			props.sectionWizzard
				? `
        <Program>
            <Module>
                <Service name="main"/>
            </Module>

            <Render direction="column" height="100%">
                <PreviewFullView 
                    entityTitle="value:main:entityTitle" 
                    viewColumn="${viewColumnTitle}" 
                >
                    
                        <Grid columns="${viewAdditionalPanel ? "60% 40%" : "1fr"}" rows="1fr" gap="10">
                            <Content>
                                <Tabs activeTab="{{value:main:mainTab}}" setActiveTab="func:main:onChangeMainTab">
                                    ${props.sectionWizzard.reactorConfig.tabs.tabsConfig.map((tabConfig: any) => {
										return `<Tab caption="${tabConfig.tabTitle}" name="${tabConfig.tabName}">
                                                <Grid columns="1fr 1fr" gap="10" height="auto" padding="0 40px"> 
                                                        ${tabConfig.grid.items
															.map((item: GridItem) => {
																if (item.x === -1 || item.y == -1) {
																	return null;
																}
																return (
																	item.fieldConfig && `<PreviewField field='${JSON.stringify(item)}' />`
																);
															})
															.filter((item: GridItem | null) => item !== null)}
                                                    
                                                    ${tabConfig.grid.items.map((item: GridItem) => {
														return (
															item.groupFieldsConfig &&
															`<FieldGroup title="${item.groupFieldsConfig.title}"  x="${item.x}" y="${
																item.y
															}"> 
                                                                ${item.groupFieldsConfig.inner?.items.map((item: GridItem) => {
																	return (
																		item.fieldConfig &&
																		`<PreviewField field='${JSON.stringify(item)}' />`
																	);
																})}                                                                
                                                            </FieldGroup>`
														);
													})}
                                                </Grid>
                                            </Tab>
                                            `;
									})}
                                    ${Object.keys(props.sectionWizzard.reactorConfig.tabs.additionalPanel)
										.filter(
											(panel) => (props.sectionWizzard?.reactorConfig.tabs.additionalPanel as any)[panel].state === 0
										)
										.map((panel) => {
											return `<Tab caption="${findPanelTitle(panel)}" name="${panel}">
                                                <Label>${findPanelTitle(panel)}</Label>
                                            </Tab>`;
										})}                        
                                </Tabs> 
                            </Content>
                            ${
								viewAdditionalPanel &&
								`<Content>
                                    <Tabs activeTab="{{value:main:additionalTab}}" setActiveTab="func:main:onChangeAdditionalTab">
                                        ${Object.keys(props.sectionWizzard.reactorConfig.tabs.additionalPanel)
											.filter(
												(panel) =>
													(props.sectionWizzard?.reactorConfig.tabs.additionalPanel as any)[panel].state === 1
											)
											.map((panel) => {
												return `<Tab caption="${findPanelTitle(panel)}" name="${panel}">
                                                    <Label>${findPanelTitle(panel)}</Label>
                                                </Tab>`;
											})}                        
                                    </Tabs> 
                                </Content>`
							}
                        </Grid>
                  
                </PreviewFullView>               
            </Render>

            <Script>
            (
                class MainService{
                    tabsConfig=${JSON.stringify(props.sectionWizzard.reactorConfig.tabs.tabsConfig)};
                    mainTab="${props.sectionWizzard.reactorConfig.tabs.tabsConfig[0].tabName}";
                    additionalTab="${
						Object.keys(props.sectionWizzard.reactorConfig.tabs.additionalPanel).filter(
							(panel) => (props.sectionWizzard?.reactorConfig.tabs.additionalPanel as any)[panel].state === 1
						)[0]
					}";
                    entityTitle="${props.sectionWizzard.entityTitle}";
                    entityName="${props.sectionWizzard.systemName}";

                    constructor(){

                    }

                    onChange(item){
                        this.value = item;
                    }

                    onChangeMainTab(tabName){
                        this.mainTab = tabName;
                    }

                    onChangeAdditionalTab(tabName){
                        this.additionalTab = tabName;
                    }

                }
            )
            </Script>
        </Program>
    `
				: "",
		[props.sectionWizzard, viewColumnTitle]
	);

	if (!isPreviewLoading) {
		return <Reactor xml={xmlParser} />;
	} else {
		return <SkeletonFullView />;
	}
});
