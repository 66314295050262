import styles from "./right-block-footer-dates.module.scss";


type RightBlockFooterDatesProps = {
    createdOnTitle:string;
    modifiedOnTitle:string;
}

export const RightBlockFooterDates= (props: RightBlockFooterDatesProps) => {
    return (
        <div className={styles.footer}>
            <span className={styles.dates}>{`Значение поля [${props.createdOnTitle}]`}</span>
            <span className={styles.dates}>{`Значение поля [${props.modifiedOnTitle}]`}</span>
        </div>
    );
};