import ImportStore from "entities/import/ImportStore";
import { observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";
import styles from "./viewing-files.module.css";

import { AttachFileGreyMini, CheckboxDone } from "shared";
import { DeleteIcon } from "shared";
import { Download } from "shared";
import { MoreEllipsis } from "shared";
import { EditMarkerLined } from "shared";
import { CheckMarker } from "shared";
import { SortOldToNew } from "shared";

import FileEntity from "entities/import/FileEntity";
import { action } from "mobx";
import CheckBox from "components/check-box/check-box";
import Select from "components/select/select";
import WarningDialog from "components/dialog/warning-dialog/warning-dialog";
import { uploadFileHelper } from "../core/upload-file-helper";
import { ImportStep } from "entities/import/type/ImportStep";
import { Item } from "types";

const ViewingFiles = observer((props: { importStore: ImportStore; entityId: string }) => {
	const loadFile = props.importStore.importStep === ImportStep.LoadFile && props.importStore.count > 0;
	props.importStore.checkedList = [];

	return (
		<div style={loadFile ? { opacity: "0.4", userSelect: "none" } : {}}>
			<Grid importStore={props.importStore} entityId={props.entityId} />
		</div>
	);
});

const Grid = observer(function (props: { importStore: ImportStore; entityId: string }) {
	const checkedAll = useRef(false);
	const importStore = props.importStore;
	const [sortData, setSortData] = useState<FileEntity[]>([]);
	const [fromNewToOld, setFromNewToOld] = useState(true);
	const [openedWarningDialog, setOpenedWarningDialog] = useState(false);
	const [fileId, setFileId] = useState<string | null>(null);

	useEffect(() => {
		setSortData(props.importStore.data);
	}, [props.importStore.data]);

	function DeleteButton() {
		return (
			<>
				<div className={styles.ellipse} />
				<div
					className={styles.deleteActionButton}
					onClick={() => {
						setOpenedWarningDialog(true);
					}}
				>
					<DeleteIcon />
					Удалить ({importStore.checkedList.length})
				</div>
			</>
		);
	}

	function AttachButton() {
		return (
			<>
				<div className={styles.ellipse} />
				<label className={styles.attachActionButton}>
					<input className={styles.inputFile} type="file" multiple onChange={attachFile} />
					<AttachFileGreyMini /> Прикрепить еще...
				</label>
			</>
		);
	}

	const onChangeChecked = action((file: FileEntity, check: boolean) => {
		const result: FileEntity[] = [];
		if (importStore.checkedList.length > 0) {
			importStore.checkedList.forEach((item) => {
				if (item !== file) result.push(item);
			});
		}
		if (check) result.push(file);
		importStore.checkedList = result;
	});

	const onChangeCheckedAll = () => {
		checkedAll.current = importStore.checkedList.length > 0 ? false : !checkedAll.current;
		importStore.data.forEach((item) => {
			onChangeChecked(item, checkedAll.current);
		});
	};

	const downloadAll = async () => {
		if (props.importStore) {
			if (importStore.checkedList && importStore.checkedList.length == 0) {
				importStore.data.forEach(async (item) => {
					await props.importStore?.download(item.id);
				});
			} else {
				importStore.checkedList.forEach(async (item) => {
					await props.importStore?.download(item.id);
				});
			}
		}
	};

	const deleteFiles = async () => {
		if (props.importStore) {
			if (importStore.checkedList)
				if (importStore.checkedList.length > 0) {
					let deleteItems: string[] = [];
					importStore.checkedList.forEach(async (item) => {
						deleteItems.push(item.id);
					});
					await props.importStore.deleteFile(deleteItems, props.entityId);
					checkedAll.current = false;
				} else if (fileId) await props.importStore.deleteFile(fileId, props.entityId);
		}
	};

	const attachFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
		uploadFileHelper(props.entityId, e.target.files, props.importStore);
	};

	const sort = () => {
		let reverse = [...sortData].reverse();
		setSortData(reverse);
		setFromNewToOld(!fromNewToOld);
	};

	return (
		<>
			<div className={styles.fileGrid}>
				<div className={styles.actions}>
					<div className={styles.actionButtons}>
						<div
							className={
								checkedAll.current || importStore.checkedList.length > 0
									? styles.cancelSelectAllActionButton
									: styles.selectAllActionButton
							}
							onClick={onChangeCheckedAll}
						>
							{checkedAll.current || importStore.checkedList.length > 0 ? <CheckboxDone /> : <CheckMarker />}
							{checkedAll.current || importStore.checkedList.length > 0 ? "Отменить выделение" : "Выделить все"}
						</div>
						<div className={styles.ellipse} />
						<div className={styles.downloadActionButton} onClick={downloadAll}>
							<Download />
							{importStore.checkedList.length > 0 ? `Скачать (${importStore.checkedList.length})` : "Скачать все"}
						</div>
						{importStore.checkedList.length > 0 && <DeleteButton />}
						{importStore.checkedList.length === 0 && <AttachButton />}
					</div>
					<div className={styles.sortAction}>
						Сортировка:
						<div className={styles.sort} onClick={sort}>
							<SortOldToNew style={!fromNewToOld ? { transform: "scaleY(-1)" } : {}} />
							{fromNewToOld ? "от новых к старым" : "от старых к новым"}
						</div>
					</div>
				</div>
				{!importStore.isError &&
					sortData.map((row) => {
						return (
							<Row
								key={row["id"]}
								row={row}
								onClick={() => {}}
								checked={importStore.checkedList.findIndex((x) => x.id === row.id) > -1 ? true : false}
								onChangeChecked={onChangeChecked}
								importStore={importStore}
								entityId={props.entityId}
								deleteFile={(id: string) => {
									setOpenedWarningDialog(true);
									setFileId(id);
								}}
							/>
						);
					})}
			</div>
			<WarningDialog
				value={
					(importStore.checkedList.length > 0 ? "Файлы будут удалены " : "Файл будет удален ") + "без возможности восстановления."
				}
				valueReturn={"Отмена"}
				valueDelete={"Удалить"}
				isOpen={openedWarningDialog}
				onBackClick={() => {
					setOpenedWarningDialog(false);
				}}
				onCancelClick={deleteFiles}
			/>
		</>
	);
});

const Row = observer(function (props: {
	row: FileEntity;
	onClick?: (row: any) => void;
	checked: boolean;
	onChangeChecked: (file: FileEntity, check: boolean) => void;
	importStore?: ImportStore;
	entityId: string;
	deleteFile: (id: string) => void;
}) {
	let r = props.row;

	function handleChange(value: boolean) {
		props.onChangeChecked(r, value);
	}

	async function onChangeValue(value: Item | null) {
		if (value) {
			if (value.id === ItemsId.Delete) {
				props.deleteFile(r.id);
			}
		}
	}

	let classNames = `${styles.row} `;

	if (props.checked) {
		classNames += `${styles.activeRow} `;
	}

	return (
		<div className={classNames} /*onClick={onClick} */>
			<CheckBox
				className={styles.checkbox}
				icon={<CheckboxDone className={styles.checkboxChecked} />}
				classNameIcon={styles.checkboxChecked}
				checked={props.checked}
				onChangeChecked={handleChange}
			/>

			<div key={r.id} className={styles.rowWithBorder}>
				<div className={styles.cell}>
					<div className={styles.file}>
						<span className={styles.fileName}>{r.fileName}</span>
						<div key={r.id} className={styles.fileInfo}>
							{/* <span>Дата загрузки: {r.createdOn?.toString()}</span> */}
							<span>
								Дата загрузки:{" "}
								{r.createdOn?.toLocaleString("ru-Ru", {
									year: "numeric",
									month: "short",
									day: "numeric",
									timeZone: "utc",
									hour: "numeric",
									hour12: false,
									minute: "2-digit"
								})}
							</span>
							<div className={styles.ellipse} />
							<span>Загрузил: #Пользователь, загрузивший файл#</span>
						</div>
					</div>
				</div>
				<div
					className={styles.downloadButton}
					onClick={async () => {
						if (props.importStore) {
							let saved = await props.importStore.download(r.id);
							console.log(saved);
						}
					}}
				>
					<Download />
					Скачать
				</div>

				<Select
					items={items}
					className={styles.select}
					classNameButton={styles.selectButton}
					classNameOpenList={styles.selectButtonOpen}
					classNameList={styles.selectList}
					onChangeValue={onChangeValue}
					firstIcon={<MoreEllipsis />}
					isPositionLeft={true}
				/>
			</div>
		</div>
	);
});

enum ItemsId {
	RenameFile = "0cd15cef-0b11-427c-b27d-1f8506b77821",
	Delete = "28e3c0d0-ed00-40b9-b8b1-9a20aecd5845"
}

const items: Item[] = [
	{
		id: ItemsId.RenameFile,
		name: "Переименовать",
		icon: <EditMarkerLined />
	},
	{
		id: ItemsId.Delete,
		name: "Удалить",
		icon: <DeleteIcon />,
		isRed: true
	}
];

export default ViewingFiles;
