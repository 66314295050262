import classNames from "classnames";

import { Icon } from "sale-bridge-ui-kit";

import styles from "./navigation-button.module.scss";

type NavigationButtonProps = {
    size: 'small' | 'medium' | 'large',
    leftIcon?: string,
    rightIcon?: string,
    caption: string,
    isSelected?: boolean,
    children?: JSX.Element,
    onClick: () => void,
    className?: string
}
export function NavigationButton(props: NavigationButtonProps) {

    const wrapClasses = classNames(styles.wrapper, {
        [`${styles.selected}`]: props.isSelected,
        [props.className ?? '']: props.className
    });

    return (
        <div className={wrapClasses} onClick={props.onClick}>
            {props.leftIcon && <Icon name={props.leftIcon} size={props.size} />}
            {props.caption.length > 0 && <span>{props.caption}</span>}
            {props.rightIcon && <Icon name={props.rightIcon} size={props.size} />}
            {props.children}
        </div>
    );
}
