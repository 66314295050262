import { IMessage } from "components/kanban-components/components/card/card";
import { KanbanConfig, QuickViewDesign } from "types/entity";

export const SCROLL_ZONE_PERCENT = 0.2;
export const SCROLL_STEP = 20;
export const MAX_COLUMN_FOR_ADAPTATION_WIDTH = 4;
export const MIN_WIDTH_COLUMN = 300;
export const PADDINGS_COUNT = 2;
export const COLUMNS_GAP = 4;

export type KanbanPageStage = {
    id: string;
    name: string;
    color: string;
    isVisible: boolean;
    allowTo: Array<string>;
    cards: Array<KanbanPageCardProps>;
}

export type KanbanData = {
    stages: Array<KanbanPageStage>;
    allowQuickActions: boolean;
    quickView: QuickViewDesign | null;
}

export type KanbanPageColumnProps = {
    isDropDisabled: boolean;
    columnWidth: number;
    size: 'small' | 'medium' | 'large';
    position: "first" | "last" | "intermediate";
    kanbanConfig: KanbanConfig;
    column: KanbanPageStage;
    draggingCardId?: string;
    setDraggingCardId?: React.Dispatch<React.SetStateAction<string>>;
};

export type KanbanPageCardProps = {
    index: number;
    name: string;
    id: string;
    color: string;
    fields: Record<string, string>;
    sysFlag: boolean;
    isSelected: boolean;
    isReturned?: boolean
    sysPosition: number;
    userFields:  Record<string, string>;
    priority: string;
    size: 'small' | 'medium' | 'large';
    kanbanConfig: KanbanConfig;
    //TODO возможно эти методы можно будет реализовать внутри самой карточки
    onOpen?: (id: string) => void;
    onFlaged?: (message: IMessage) => void;
    draggingCardId?: string;
    setDraggingCardId?: React.Dispatch<React.SetStateAction<string>>;
}