import { useMemo } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { dispatcher } from 'store';

import { Button } from 'sale-bridge-ui-kit';
import { SimpleFilter } from 'features/filter';

import { AdvancedFilterConst } from 'features/section-head/data/constants';

import styles from '../single-page-head.module.scss';

export const SinglePageTopLeftHead = observer(function (props: {
    size: 'small' | 'medium' | 'large',
    onAddClick: () => void,
    onExtendedFilterClick: () => void
}) {
    const entity = useMemo(() => {
        return dispatcher.entity.get()
    }, [dispatcher.entity.get()]);

    const titleClasses = classNames(styles.title, {
        [styles[`title${props.size}`]]: props.size,
    });
    
    return (
        <div className={styles.leftBlock}>
            <div className={styles.block}>
                <Button
                    text='Добавить'
                    size={props.size}
                    variant='primary'
                    border
                    leftIcon='Add'
                    link={false}
                    loading={false}
                    onClick={props.onAddClick}
                />
                <div className={titleClasses} onClick={() => document.location.reload()}>
                    {entity?.entityTitle ?? 'Раздел'}
                </div>

            </div>
            <div className={styles.divider}></div>
            <div className={styles.block}>
                <Button
                    text='Расширенный'
                    size={props.size}
                    variant={AdvancedFilterConst.isOpenAdvancedFilter ? 'secondary' : 'default'}
                    border
                    leftIcon={AdvancedFilterConst.isOpenAdvancedFilter ? 'Clear' : 'Filter'}
                    link={false}
                    loading={false}
                    onClick={props.onExtendedFilterClick}
                />
                <SimpleFilter size={props.size} />
            </div>
        </div>
    );
});
