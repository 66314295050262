import classNames from "classnames";

import styles from "components/stage/stage-actions.module.scss";

export function StageAction(props:{actionName?: string, icon?: JSX.Element, [key: string]:any}) {
	const actionNameWrapper = classNames(styles.actionNameWrapper,
		{[`${props.className}`]: props.className }
	);
    const actionIconStyle = classNames(styles.actionIconStyle,
		{[`${props.actionNameIcon}`]: props.actionIconStyle }
	);
    const actionNameStyle = classNames(styles.actionNameStyle,
		{[`${props.actionNameStyle}`]: props.actionNameStyle }
	);
    return (
        <div className={actionNameWrapper}>
            <div className={actionIconStyle}>
                {props.icon}
            </div>
            <div className={actionNameStyle}>
                {props.actionName}
            </div>
        </div>
    );
};
