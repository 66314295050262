import styles from "./skeleton-kanban.module.css";

function Card() {
	let cardHeader = styles.cardHeader + " ";
	let item = styles.item + " " + styles.itemShort;

	return (<div className={styles.card}>
		<div className={cardHeader}></div>
		<div className={styles.title}></div>
		<div className={item}></div>
		<div className={styles.item}></div>
		<div className={styles.cardFooter}></div>
	</div>);
}

function SkeletonKanban(props: { numCards: number }) {

	return (
		<div className={styles.columnBody}>
			<div className={`${styles.columnHeader} ${styles.skeleton}`}></div>
			<div className={styles.cards}>
				{[...Array(props.numCards)].map((item, index) => <Card />)}
			</div>
		</div >
	);
}

export default SkeletonKanban;
