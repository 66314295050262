import { observer } from "mobx-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { toJS } from "mobx";
import { v4 } from "uuid";
import { isNull, isNumber } from "lodash";

import { AdditionalInfoOfField } from "../../../quick-view-settings/data";
import { BlockWithFields } from "../../block-with-fields";
import { ConstructorQuickViewDragField } from "../../constructor-quick-view-drag-field";

import TypeIcon from "features/type-icon/type-icon";

import { Element } from "modules/DND/element";
import { Zone } from "modules/DND/zone";

import { Types } from "modules/DND/type";
import { AdditionalField } from "types/entity";

import styles from "./left-block-drag-zone.module.scss";

type LeftBlockDragZoneProps = {
    leftBlockAdditionalFields: Array<AdditionalField>;
    leftBlockAdditionalFieldsZoneId: string;
    onChangeLeftBlockAdditionalFields: (sourceZone: string | null, targetZone: string | null, additionalField: AdditionalField, newPosition: number) => void;
    onDeleteFieldFromLeftBlock: (gridItemId: string, isLeftFieldBlock?: boolean) => void;
    getAdditionalInfoOfField: (columnId: string) => AdditionalInfoOfField;
    height: number | undefined;
    commentsEnable: boolean;
}

const CELL_HEIGHT = 44;

const MINIMAL_ROW_QUALITY = 1;

export const LeftBlockDragZone = observer((props: LeftBlockDragZoneProps) => {
    const [id] = useState<string>(v4());
    const ref = useRef<HTMLDivElement>(null);
    const [targetElementViewLayout, setTargetElementViewLayout] = useState<JSX.Element>(<></>);
    const [cellLayoutWidth, setCellLayoutWidth] = useState(0);
    const [rowQualityByZoneList, setRowQualityByZoneList] = useState(MINIMAL_ROW_QUALITY);
    const [rowQualityByScreen, setRowQualityByScreen] = useState(MINIMAL_ROW_QUALITY);

    const leftBlockAdditionalFields = useMemo(() => props.leftBlockAdditionalFields, [toJS(props.leftBlockAdditionalFields)]);

    const heightCount = useMemo(() => {
        return Math.max(rowQualityByZoneList, rowQualityByScreen, MINIMAL_ROW_QUALITY);
    }, [rowQualityByZoneList, rowQualityByScreen]);

    const zoneElements = useMemo(() => {
        return leftBlockAdditionalFields?.map((element) => {
            return <Element
                key={element.gridItemId}
                id={element.gridItemId}
                x={1}
                y={element.order}
                width={1}
                height={1}
            >
                <ConstructorQuickViewDragField
                    gridItemId={element.gridItemId}
                    additionalInfoOfField={props.getAdditionalInfoOfField(element.columnId)}
                    icon={<TypeIcon type={props.getAdditionalInfoOfField(element.columnId).columnType} />}
                    onDeleteField={(gridItemId: string) => { props.onDeleteFieldFromLeftBlock(gridItemId, true) }}
                />
            </Element>

        })
    }, [toJS(leftBlockAdditionalFields), cellLayoutWidth]);

    useEffect(() => {
        const updateContainerSize = () => {
            if (document.getElementById(id)) {
                setRowQualityByScreen((Math.floor(document.getElementById(id)!.getBoundingClientRect().height / (CELL_HEIGHT))));
                setCellLayoutWidth(document.getElementById(id)!.getBoundingClientRect().width);
            }
        };
        updateContainerSize();
        window.addEventListener('resize', updateContainerSize);
        return () => {
            window.removeEventListener('resize', updateContainerSize);
        };
    }, []);

    useEffect(() => {
        if (leftBlockAdditionalFields && leftBlockAdditionalFields.length > 0 && leftBlockAdditionalFields.find((item) => item.order !== -1)) {
            const maxYElement = leftBlockAdditionalFields.reduce((maxOrderElement, currentElement) => maxOrderElement.order > currentElement.order ? maxOrderElement : currentElement);
            setRowQualityByZoneList(maxYElement.order + 1);
        }
        if (leftBlockAdditionalFields && leftBlockAdditionalFields.length == 0) {
            setRowQualityByZoneList(MINIMAL_ROW_QUALITY);
        }
    }, [toJS(leftBlockAdditionalFields)]);

    const handleUp = useCallback(({ elementId }: { elementId: string }) => {
        if (leftBlockAdditionalFields) {
            const zoneItem = leftBlockAdditionalFields.find((item) => item.gridItemId === elementId);
            if (zoneItem) {
                setTargetElementViewLayout(
                    <ConstructorQuickViewDragField
                        gridItemId={zoneItem.gridItemId}
                        additionalInfoOfField={props.getAdditionalInfoOfField(zoneItem.columnId)}
                        icon={<TypeIcon type={props.getAdditionalInfoOfField(zoneItem.columnId).columnType} />}
                        className={styles.targetElementViewLayout}
                    />
                );
            }
        }
    }, [cellLayoutWidth, toJS(leftBlockAdditionalFields)]);

    const handleDrop = useCallback(({
        elementId,
        sourceZone,
        targetZone,
        MATRIX
    }: {
        elementId: string | null;
        sourceZone: string | null;
        targetZone: string | null;
        type: Types;
        sourceData?: any;
        [key: string]: any;
    }) => {
        if (MATRIX.cellY === heightCount) {
            setRowQualityByZoneList(heightCount + 1);
        }
        if (leftBlockAdditionalFields) {

            const dragElement = leftBlockAdditionalFields.find(({ gridItemId }) => gridItemId === elementId);
            if (dragElement) {
                const additionalField: AdditionalField = {
                    ...dragElement,
                    order: MATRIX.cellY,
                }
                props.onChangeLeftBlockAdditionalFields(sourceZone, targetZone, additionalField, MATRIX.cellY);
            }
        }
    }, [heightCount, toJS(leftBlockAdditionalFields)]);

    const cellLayout = useMemo(() => {
        return (
            <div style={{ width: `${cellLayoutWidth}px`, height: `${CELL_HEIGHT}px` }} >
                <div className={styles.fieldWrapper} />
            </div>
        );
    }, [cellLayoutWidth]);

    const zoneConfig = useMemo(() => {
        return {
            targetElementViewLayout: targetElementViewLayout,
            cellLayout: cellLayout,
            placeholderLayout: cellLayout,
            predictLayout: <div className={styles.predictLayout} />,
            width: 1,
            height: heightCount,
        };
    }, [targetElementViewLayout, cellLayout, heightCount]);

    const getMaxHeight = useMemo(() => {
        if (isNumber(props.height)) {
            const paddings = 258; //паддинги для проверки: 40 * 2 + 16 * 2 + 12 + 20 * 3 + 72 + 2
            const paddingsWithComments = 404; //паддинги для проверки: 40 * 2 + 16 * 3 + 12 + 20 * 3 + 72 + 2 + 130
            if (props.commentsEnable) {
                return props.height - paddingsWithComments;
            }
            else {
                return props.height - paddings;
            }
        }
        return null
    }, [props.height, props.commentsEnable]);

    /**
     * @description максимальная высота округляется до ближашего к 50ти значения, чтобы не обрезались ячейки (44px height + 6px gap)
     */
    const roundingMaxHeight = getMaxHeight && Math.round(getMaxHeight / 50) * 50;

    useEffect(() => {
        if (!isNull(getMaxHeight)) {
            ref.current?.style.setProperty('--max-height', `${roundingMaxHeight}px`);
        }
    }, [ref.current, getMaxHeight])

    return (
        <BlockWithFields headerTitle='Вспомогательные поля'>
            <div
                ref={ref}
                id={id}
                className={styles.scrollZone}
            >
                <Zone
                    id={props.leftBlockAdditionalFieldsZoneId}
                    config={zoneConfig}
                    type={Types.MATRIX}
                    onDrop={handleDrop}
                    onUp={handleUp}
                >
                    {zoneElements ?? <></>}
                </Zone>
            </div>
        </BlockWithFields>
    );
});