import { action, IObjectDidChange, Lambda, makeObservable, observable, observe } from "mobx";

import ListStore, { LoadingState } from "entities/ListStore";
import { api, UpFirst } from "shared";

import FilterColumnType from "./ColumnType";
import { SortDirection } from "./ISort";
import { FieldValidationState } from "./Validation";
import { Item } from "types";

import { ComparisonType, DataValueType } from "entities/filter/IFilter";

export enum SavingState {
	NotAsked,
	Saving,
	Saved,
	Failed
}

export interface IEntityStore {
	id: string;
	modifiedOn?: string;
	deserialize: (p: any) => void;
	serialize: () => any;
	validate(): boolean;
	resetValidate: () => void;
	schema: string;
	displaySchema?: string;
	handleError(error: string): void;
	check?: number;
	validation: { [key: string]: FieldValidationState };
	setValue: (value: string | Item, fieldName: string) => void;
}

export interface IEntityData {
	propertyName: string;
	propertyValue: string | boolean;
}

export interface ILookup {
	id: string;
	name: string;
	isSection?: boolean;
}

export default class Entity<T extends IEntityStore> {
	oldValues: any;
	static oldPath: string = "";
	hasChanges: boolean;
	isNew: boolean;
	openedDialog: boolean = false;
	openedWarningDialog: boolean = false;
	loadingState: LoadingState;
	savingState: SavingState;
	entity: T;
	lookups: { [schema: string]: ILookup[] };

	constructor(defaultEntity: T) {
		makeObservable(this, {
			hasChanges: observable,
			isNew: observable,
			openedDialog: observable,
			openedWarningDialog: observable,
			loadingState: observable,
			savingState: observable,
			entity: observable,
			lookups: observable,
			new: action,
			openDialogWithData: action,
			cancelChanges: action,
			cancelClick: action,
			setWarningDialog: action,
			save: action,
			load: action,
			delete: action,
			setLookup: action,
			loadLookups: action,
			setValue: action,
			updateStage: action
		});

		this.entity = defaultEntity;
		this.oldValues = defaultEntity.serialize();
		this.hasChanges = false;
		this.isNew = false;
		this.loadingState = LoadingState.NotAsked;
		this.savingState = SavingState.NotAsked;
		this.lookups = {};

		this.disposer = observe(this.entity, (change) => this.hangeHandler(change));
	}

	hangeHandler(change: IObjectDidChange<any>) {
		if (this.noNeedReaction) {
			this.noNeedReaction = false;
			return;
		}
		this.hasChanges = true;
	}

	get isLoading(): boolean {
		return this.loadingState === LoadingState.Loading;
	}

	get isLoaded(): boolean {
		return this.loadingState === LoadingState.Loaded;
	}

	get isError(): boolean {
		return this.loadingState === LoadingState.Failed;
	}

	get isSaving(): boolean {
		return this.savingState === SavingState.Saving;
	}

	get isSaved(): boolean {
		return this.savingState === SavingState.Saved;
	}

	// @action
	new(newEntity: T) {
		// this.entity.deserialize(this.oldValues);
		this.oldValues = newEntity.serialize();
		this.openedDialog = true;
		this.entity = newEntity;
		this.isNew = true;
		this.loadingState = LoadingState.Loaded;
	}
	newValueInLookup(newEntity: T) {
		// this.entity.deserialize(this.oldValues);
		this.oldValues = newEntity.serialize();
		// this.openedDialog = true;
		this.entity = newEntity;
		this.isNew = true;
		this.loadingState = LoadingState.Loaded;
	}
	async checkDublicates() {
		const values = this.entity.serialize();
		// const response = await api.post("/api/Entity/checkduplicate", values);
		const response = await api.http.entity.checkDuplicate().post(values);

		if (response?.data.success) {
			return response.data;
		} else {
			return null;
		}
	}

	openDialogWithData(newEntity: T) {
		this.oldValues = newEntity.serialize();
		this.openedDialog = true;
		this.entity = newEntity;
		this.loadingState = LoadingState.Loaded;
	}

	// @action
	cancelChanges = () => {
		this.disposer?.call(this);
		this.entity.deserialize(this.oldValues);
		this.hasChanges = false;
		this.openedDialog = false;
		this.openedWarningDialog = false;
		this.entity.resetValidate();
		this.disposer = observe(this.entity, (change) => this.hangeHandler(change));
	};

	cancelClick = () => {
		if (this.entity.serialize() && JSON.stringify(this.oldValues) !== JSON.stringify(this.entity.serialize())) {
			this.setWarningDialog();
		} else this.cancelChanges();
	};

	setWarningDialog = () => {
		this.openedWarningDialog = !this.openedWarningDialog;
	};

	// @action
	async save(): Promise<boolean> {
		if (!this.entity.validate()) {
			return false;
		}
		const isNew = this.isNew;

		this.savingState = SavingState.Saving;
		const data = this.entity.serialize();
		let response;

		if (isNew) {
			// response = await api.post("/api/Entity/create", data);
			response = await api.http.entity.createEntity().post(data);
			if (response) {
				if (response.data !== undefined && response.data !== undefined) {
					this.entity.id = response.data;
				}
			}
		} else {
			// response = await api.post("/api/Entity/update", data);
			response = await api.http.entity.updateEntity().post(data);
		}

		if (response && response.data.success === true) {
			this.savingState = SavingState.Saved;
			this.setValue(false, "isNew");
			this.setValue(false, "hasChanges");
			this.setValue(false, "openedDialog");
			await this.load(this.entity.id);
			this.disposer = observe(this.entity, (change) => this.hangeHandler(change));

			return true;
		} else {
			this.isNew = isNew;
			this.savingState = SavingState.Failed;
			this.entity.handleError(response.data.error.errorCode.toString());
		}

		return false;
	}

	noNeedReaction: boolean = true;
	disposer?: Lambda;

	setValue(value: string | boolean | LoadingState | SavingState, fieldName: string) {
		Reflect.set(this, fieldName, value);
	}
	// @action
	async load(id: string): Promise<boolean> {
		this.isNew = false;
		this.setValue(LoadingState.Loading, "loadingState");
		// this.loadingState = LoadingState.Loading;
		const data = {
			entityName: this.entity.schema,
			entityId: id
		};

		// const loadResult = await api.post("/api/Entity/get", data);
		const loadResult = await api.http.entity.getRecord().post(data);

		if (loadResult.data.success && loadResult.data) {
			this.setValue(LoadingState.Loaded, "loadingState");
			// this.loadingState = LoadingState.Loaded;
			this.oldValues = loadResult.data.data;
			this.entity.deserialize(this.oldValues);
			this.setValue(false, "hasChanges");
		} else {
			this.setValue(LoadingState.Failed, "loadingState");
			// this.loadingState = LoadingState.Failed;
			return false;
		}

		return true;
	}

	async updateStage(data: any): Promise<boolean> {
		this.savingState = SavingState.Saving;
		// let response = await api.post("/api/Entity/update", data);
		let response = await api.http.entity.updateEntity().post(data);

		if (response && response.data.success === true) {
			this.savingState = SavingState.Saved;
			this.isNew = false;
			this.setValue(false, "hasChanges");
			return true;
		} else {
			this.savingState = SavingState.Failed;
			this.entity.handleError(response.data.error);
		}

		return false;
	}

	// @action
	async delete(schema?: string, id?: string): Promise<boolean> {
		this.savingState = SavingState.Saving;
		// let response = await api.post("/api/Entity/delete", {
		// 	entityName: schema ? schema : this.entity.schema,
		// 	entityId: id ? id : this.entity.id
		// });
		let response = await api.http.entity.deleteRecord().post({
			entityName: schema ? schema : this.entity.schema,
			entityId: id ? id : this.entity.id
		});

		if (response && response.data.success === true) {
			this.savingState = SavingState.Saved;
			this.setValue(false, "hasChanges");
			return true;
		} else {
			this.savingState = SavingState.Failed;
			this.entity.handleError(response.data.error);
		}
		return false;
	}

	setLookup(schema: string, data: any[]) {
		this.lookups[schema] = data;
	}

	async loadLookups(
		schema: string,
		value: string | null | undefined,
		attribute?: string | null,
		isSort?: boolean,
		sortObject?: any | undefined,
		firstTop?: number
	): Promise<number> {
		if (!this.lookups[schema] || (this.lookups[schema] && value === undefined)) {
			this.lookups[schema] = [];
			const lookup = new ListStore(schema);
			let sort = sortObject ?? null;
			let filter = null;
			if (value !== undefined && value !== null) {
				filter = {
					schema: this.entity.schema,
					isEnabled: true,
					type: 1,
					comparisonType: ComparisonType.Contain,
					attribute: attribute ?? "Name",
					attributeType: FilterColumnType.String,
					rightExpression: {
						type: 0,
						parameter: {
							dataValueType: DataValueType.Text,
							value: value
						}
					},
					filters: [],
					logicalOperation: 0,
					detail: ""
				};
			}
			if (isSort && sortObject === undefined) {
				sort = {
					columnPath: "Order",
					direction: SortDirection.Ascending
				};
			}
			await lookup.load(filter, null, firstTop ?? 10, sort);
			this.setLookup(schema, lookup.data);
			return lookup.data.length;
		} else if (this.lookups[schema] && value !== undefined) {
			this.lookups[schema] = [];
			const lookup = new ListStore(schema);
			let filter = null;
			let sort = null;

			if (value !== null) {
				filter = {
					schema: this.entity.schema,
					isEnabled: true,
					type: 1,
					comparisonType: ComparisonType.Contain,
					attribute: attribute ?? "Name",
					attributeType: FilterColumnType.String,
					rightExpression: {
						type: 0,
						parameter: {
							dataValueType: DataValueType.Text,
							value: value
						}
					},
					filters: [],
					logicalOperation: 0,
					detail: ""
				};
			}
			if (isSort) {
				sort = {
					columnPath: "Order",
					direction: SortDirection.Ascending
				};
			}
			await lookup.load(filter, null, firstTop ?? 10, sort);
			this.setLookup(schema, lookup.data);

			return lookup.data.length;
		}
		return this.lookups[schema].length;
	}

	// @action
	async createNewSchema(fields: any[]): Promise<boolean> {
		if (!this.entity.validate()) {
			return false;
		}
		this.savingState = SavingState.Saving;

		// let response = await api.post("/api/Entity/addentityinfo", {
		// 	entityTitle: UpFirst(this.entity.displaySchema!),
		// 	entityName: UpFirst(this.entity.schema),
		// 	isLookup: true,
		// 	entityInfoItem: fields
		// });
		let response = await api.http.entity.addEntityInfo().post({
			entityTitle: UpFirst(this.entity.displaySchema!),
			entityName: UpFirst(this.entity.schema),
			isLookup: true,
			entityInfoItem: fields
		});

		if (response && response.data.success === true) {
			this.savingState = SavingState.Saved;
			this.setValue(false, "isNew");
			this.setValue(false, "hasChanges");
			this.setValue(false, "openedDialog");
			this.disposer = observe(this.entity, (change) => this.hangeHandler(change));
			return true;
		} else {
			this.savingState = SavingState.Failed;
			this.entity.handleError(response.data.error);
		}

		return false;
	}
}
