import { makeAutoObservable } from "mobx";
import * as signalR from "@microsoft/signalr";

import { eventBus } from "app/services";
import { api } from "shared";

import FileStoreEvents from "../types/FileStoreEvents";
import { getSignalRUrl } from "shared";
import { FileStorages } from "entities/FileStorages";

export enum CloudStoreState {
	NotConnecting = "NotConnecting",
	Connecting = "Connecting",
	Connected = "Connected",
	Error = "Error",
	Switch = "Switch"
}

export enum LoadStates {
	Progress = 0,
	Success = 1,
	Waiting = 3,
	Failure = 4
}

export interface ILoadResult {
	state: LoadStates;
	percent: number;
}

class CloudStoreService {
	endpoint: string = "";
	accessKey: string = "";
	secretKey: string = "";
	bucketName: string = "";

	loadResult: ILoadResult = { state: LoadStates.Waiting, percent: 0 };
	connection: signalR.HubConnection | null = null;

	state: CloudStoreState = CloudStoreState.NotConnecting;

	constructor() {
		makeAutoObservable(this);
		this.getSettings();
		this.health();
		this.initSignalR();

		eventBus.registerHandler(FileStoreEvents.Swithing, (event) => this.swithingHandler(event));
	}

	async initSignalR() {
		let url = getSignalRUrl();
		this.connection = new signalR.HubConnectionBuilder().withUrl(`${url}/transferhub`).build();

		await this.connection.start();

		await this.connection.invoke("JoinRoom", "Transfer");
		this.connection.on("Receive", (data) => {
			cloudStoreService.loadResult = { state: data.state, percent: data.precent };
		});
	}

	swithingHandler(event: boolean) {
		if (event) {
			this.state = CloudStoreState.Switch;
		} else {
			this.health();
		}
	}

	setEndpoint(value: string) {
		this.endpoint = value;
	}
	setAccessKey(value: string) {
		this.accessKey = value;
	}
	setSecretKey(value: string) {
		this.secretKey = value;
	}
	setBucketName(value: string) {
		this.bucketName = value;
	}

	async health() {
		const response = await api.http.file.fileHealth().get();
		if (response && response.data.Success && response.data) {
			this.state = CloudStoreState.Connected;
		}
	}

	async uploadSettings() {
		this.state = CloudStoreState.Connecting;
		const response = await api.http.file.editS3().post({
			endpoint: this.endpoint,
			accessKey: this.accessKey,
			secretKey: this.secretKey,
			bucketName: this.bucketName
		});

		if (response.data.success) {
			this.state = CloudStoreState.Connected;
		} else {
			this.state = CloudStoreState.Error;
		}
	}

	async swithStore(isMoveFile: boolean) {
		const response = await api.http.file.editSource().put({ sourceId: FileStorages.S3, needTransferFiles: isMoveFile });
		eventBus.dispatchEvent(FileStoreEvents.Switch, FileStorages.S3);
	}

	async getSettings() {
		//TODO раскомментировать, когда с бэка будут приходить актуальные данные по этим полям (не работает, если fileService упал)
		/* 		const response = await api.http.httpApi.file.sourceInfo().get();
		this.accessKey = response.data.s3ConnectionSettings.accessKey;
		this.bucketName = response.data.s3ConnectionSettings.bucketName;
		this.endpoint = response.data.s3ConnectionSettings.endpoint;
		this.secretKey = response.data.s3ConnectionSettings.secretKey; */
	}
}

const cloudStoreService = new CloudStoreService();

export default cloudStoreService;
