import { useCallback } from "react";
import classNames from "classnames";

import styles from "./color-panel-item.module.scss";


export const ColorPanelItem = (props: { [key: string]: any }) => {
	const colorItemWrapper = classNames(styles.colorItem, {
		[`${styles.isDisabled}`]: props.isDisabled,
		[`${styles.isChecked}`]: props.isChecked,
		[`${props.className}`]: props.className,
	}
	);

	const handleClick = useCallback(() => {
		if (!props.isDisabled) {
			props.onClick?.(props.color)
		}
	}, [props]);

	return (
		<div
			className={colorItemWrapper}
			style={{ "--background": props.color } as React.CSSProperties}
			onClick={handleClick}
		/>
	);

};