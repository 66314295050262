// import { Warning } from "shared";
import { Warning } from "shared";
import { Button, ButtonStyle, Modal } from "components";
import { EventDialog } from "./show-dialog";

import styles from "components/dialog/warning-dialog/warning-dialog.module.css";
import dialogStyles from "components/dialog/dialog.module.css";

export function DialogWindow(props: EventDialog) {
	return (
		<Modal>
			<div className={styles.backdrop} />
			<div className={styles.dialog}>
				<div className={styles.header}>
					<span className={styles.title}>{props.title}</span>
					<Warning className={dialogStyles.closeButton} />
				</div>
				<div className={styles.dialogBody}>{props.value}</div>
				<div className={dialogStyles.dialogFooter}>
					<Button caption={props.cancelButton.title} onClick={props.cancelButton.action} style={ButtonStyle.Subtle} />
					<Button caption={props.okButton.title} onClick={props.okButton.action} style={ButtonStyle.Danger} />
				</div>
			</div>
		</Modal>
	);
}
