import styles from "./flex-column.module.css";


interface FlexColumnProps{
    gap?:number;
    children:any;
    justify?:"center" | "flex-start" | "flex-end" | "space-between";
}

function FlexColumn(props:FlexColumnProps){
    return (
        <div style={{gap:props.gap + "px", justifyContent:props.justify}} className={styles.flex}>
            {props.children}
        </div>
    )
}

export default FlexColumn;