import { useCallback, useEffect, useRef, useState } from "react";

import { Dropdown } from "sale-bridge-ui-kit";
import { NavigationButton } from "../navigation-button";

import { ItemGroup } from "sale-bridge-ui-kit/dist/components/dropdown/dropdown";

interface NavigationSelectProps {
    size: 'small' | 'medium' | 'large',
    position: "up" | "down" | "right-up" | "right-down"
    iconName?: string,
    items: Array<ItemGroup>,
    childrenButton?: JSX.Element
}

export function NavigationSelect(props: NavigationSelectProps) {
    const idComponent = 'navigation-select';
    const [isOpened, setOpened] = useState(false);
    const buttonRef = useRef<HTMLDivElement>(null);
    const [quantity, setQuantity] = useState<number>(0);

    useEffect(() => {
        setQuantity(props.items.length);
    }, [props.items]);


    const handleClick = useCallback((event: any) => {
        const node = document.getElementById(idComponent);
        if (node && node.contains(event.target as Node)) return;
        if (props.childrenButton && props.childrenButton?.props.id === event.target.id) return
        setOpened(false);
    }, [isOpened]);

    const handleClickButton = useCallback(() => {
        if (!isOpened) {
            document.addEventListener("click", handleClick);
        } else {
            document.removeEventListener("click", handleClick);
        }
        setOpened(!isOpened);
    }, [isOpened]);

    return (
        <div id={idComponent}>
            <div ref={buttonRef} onClick={handleClickButton}>
                <NavigationButton
                    size={props.size}
                    isSelected={isOpened}
                    onClick={handleClickButton}
                    caption=""
                    leftIcon={props.iconName}
                    children={props.childrenButton}
                />
            </div>
            {isOpened &&
                <Dropdown
                    size='small'
                    variant='button'
                    position={props.position}
                    items={props.items}
                    quantity={quantity}
                    hasSearch={false}
                    parentWidth={buttonRef.current?.getBoundingClientRect().width}
                    parentHeight={buttonRef.current?.getBoundingClientRect().height}
                />
            }
        </div>
    );
}
