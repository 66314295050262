import { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";

import Avatar from "features/avatar/avatar-generator";
import DisplayTabs from "features/display-tabs/display-tabs";
import { InputSearch, Dialog, Button, ButtonStyle, WarningDialog, Field, Input, InputPhone, InputDate, Select } from "components";

import { generalInformationData, personalizationData, securityData, privacyData, defaultData, deactivateUserData } from "./data/fields";
import { User } from "entities/user/User";
import authStore from "AuthStore";
import Entity from "entities/Entity";
import { validateEmail, validatePhoneNumber } from "entities/Validation";

import { EditProfile } from "shared";
import { ArrowToRight } from "shared";

import styles from "./profile.module.css";

const Profile = observer(() => {
	const user = useParams<{ id: string }>();
	const [searchValue, setSearchValue] = useState("");
	const [isSaved, setSaved] = useState<boolean>(false);

	const entity = useRef(new Entity<User>(new User()));

	useEffect(() => {
		entity.current.load(user.id ?? authStore.userId!);
		entity.current.loadLookups("City", undefined);
		entity.current.loadLookups("Gender", undefined);
	}, [user.id]);

	async function save() {
		let saved = await entity.current.save();
		setSaved(saved);
	}

	return (
		<div className={styles.propfilePage}>
			<div className={styles.propfileInformation}>
				<InputSearch
					className={styles.inputSearch}
					value={searchValue}
					placeholder={"Найти настройки"}
					onChangeValue={(value: string) => {
						setSearchValue(value);
					}}
				/>
				<ProfileDetail entity={entity.current} />
				<ProfileBlock title={"Общая информация"} body={generalInformationData(entity.current)} />

				{/* my  */}
				{user.id === undefined && <ProfileBlock title={"Персонализация"} body={personalizationData} />}

				<ProfileBlock title={"Безопасность"} body={securityData} />

				{/* my  */}
				{user.id === undefined && <ProfileBlock title={"Приватность"} body={privacyData} />}

				<ProfileBlock title={"Последнее в работе"} body={defaultData} />
				<ProfileBlock title={"Команды"} body={defaultData} />
				<ProfileBlock title={"Организационная структура"} body={defaultData} />

				{/* admin on user  */}
				{user.id !== undefined && <ProfileBlock title={"Опасная зона"} body={deactivateUserData} />}

				<Dialog
					title={"Данные о пользователе"}
					isOpen={entity.current.openedDialog}
					dialogFooterButtons={
						<Button caption={"Сохранить"} onClick={save} style={ButtonStyle.Primary} className={styles.saveButtonDialog} />
					}
					onClick={entity.current.cancelClick}
					className={styles.dialog}
				>
					<DialogContent entity={entity.current} />
				</Dialog>
				<WarningDialog
					isOpen={entity.current.openedWarningDialog}
					onBackClick={entity.current.setWarningDialog}
					onCancelClick={entity.current.cancelChanges}
					value={"Есть несохраненные изменения.\nОтменить редактирование?"}
				/>
			</div>
		</div>
	);
});

const ProfileDetail = observer((props: { entity: Entity<User> }) => {
	return (
		<div className={styles.profile}>
			<Avatar name={props.entity.entity.name} className={styles.avatar} />
			<div className={styles.profileName}>
				<span className={styles.name}>{props.entity.entity.name}</span>
				<span className={styles.email}>{props.entity.entity.email}</span>
			</div>
			<Button
				style={ButtonStyle.Icon}
				firstIcon={<EditProfile />}
				className={styles.editProfileButton}
				onClick={() => {
					props.entity.openDialogWithData(props.entity.entity);
				}}
			/>
		</div>
	);
});

interface IProfileBlock {
	title: string;
	body?: {
		icon: JSX.Element;
		caption: string;
		content?: string;
		isNotMoving?: boolean;
		classNameCaption?: string;
	}[];
	inDevelopment?: boolean;
}

const ProfileBlock = observer((props: IProfileBlock) => {
	return (
		<div className={styles.profileBlock}>
			<div className={styles.profileBlockTitle}>{props.title}</div>
			{props.body!.map((item) => {
				let classNameCaption = item.content ? styles.profileBlockDataCaption : styles.profileBlockDataContent;
				if (item.classNameCaption) classNameCaption += " " + item.classNameCaption;
				return (
					<>
						<div className={styles.profileBlockBody}>
							{item.icon}
							<div className={styles.profileBlockData}>
								<span className={classNameCaption}>{item.caption}</span>
								<span className={styles.profileBlockDataContent}>{item.content}</span>
							</div>
							{!item.isNotMoving && <ArrowToRight className={styles.profileBlockMove} />}
						</div>
						<div className={styles.profileBlockDataDevider} />
					</>
				);
			})}
		</div>
	);
});

const DialogContent = observer((props: { entity: Entity<User> }) => {
	// TODO реализовать блок Город и Часовые пояса
	return (
		<>
			<div className={styles.dialogContent}>
				<MeansOfCommunication entity={props.entity} />
			</div>
			<div className={styles.dialogContent}>
				<FioContent entity={props.entity} />
			</div>
			<div className={styles.dialogContent}>
				<DepartmentContent entity={props.entity} />
			</div>

			{/* <div className={styles.dialogContent}>
                <LocalityContent entity={props.entity} />
            </div> */}
		</>
	);
});

const MeansOfCommunication = observer((props: { entity: Entity<User> }) => {
	const tabs = [
		{
			action: () => {
				props.entity.entity.setValue(props.entity.lookups["Gender"][0], "gender");
			},
			caption: props.entity.lookups["Gender"][0].name[0].toUpperCase()
		},
		{
			action: () => {
				props.entity.entity.setValue(props.entity.lookups["Gender"][1], "gender");
			},
			caption: props.entity.lookups["Gender"][1].name[0].toUpperCase()
		}
	];
	return (
		<>
			<Field name={"email"} caption={"Email"}>
				<Input
					value={props.entity.entity.email}
					placeholder={"example@web.com"}
					onChangeValue={(value: string) => {
						props.entity.entity.setValue(value, "email");
					}}
					isInvalid={props.entity.entity.validation.email.isInvalid}
					onFocusOut={() => validateEmail(props.entity.entity.email, props.entity.entity.validation.email)}
				/>
			</Field>
			<Field name={"phone"} caption={"Мобильный телефон"}>
				<InputPhone
					value={props.entity.entity.phone}
					placeholder={"+7 (123) 456-78-90"}
					onChangeValue={(value: string) => {
						props.entity.entity.setValue(value, "phone");
					}}
					isNeedCorrect={true}
					isInvalid={props.entity.entity.validation.phone.isInvalid}
					invalidMessage={props.entity.entity.validation.phone.error}
					onFocusOut={() => validatePhoneNumber(props.entity.entity.phone, props.entity.entity.validation.phone)}
				/>
			</Field>
			<div className={styles.meansOfCommunicationDetail}>
				<Field name={"gender"} caption={"Пол"}>
					<DisplayTabs items={tabs} styleButton={ButtonStyle.Icon} selected={props.entity.entity.gender?.name[0].toUpperCase()} />
				</Field>

				<Field name={"birthday"} caption={"Дата рождения"}>
					<InputDate
						value={props.entity.entity.birthDate}
						onChangeValue={(value: string) => {
							props.entity.entity.setValue(value, "birthDate");
						}}
						isBaseInput={true}
					/>
				</Field>
			</div>
		</>
	);
});

const FioContent = observer((props: { entity: Entity<User> }) => {
	return (
		<>
			<Field name={"fio"} caption={"Фамилия, имя и отчество"}>
				<div className={styles.fioContent}>
					<Input
						value={props.entity.entity.surName}
						placeholder={"Фамилия"}
						onChangeValue={(value: string) => {
							props.entity.entity.setValue(value, "surName");
						}}
					/>
					<Input
						value={props.entity.entity.giveName}
						placeholder={"Имя"}
						onChangeValue={(value: string) => {
							props.entity.entity.setValue(value, "giveName");
						}}
					/>
					<Input
						value={props.entity.entity.middleName}
						placeholder={"Отчество"}
						onChangeValue={(value: string) => {
							props.entity.entity.setValue(value, "middleName");
						}}
					/>
				</div>
			</Field>
		</>
	);
});

const DepartmentContent = observer((props: { entity: Entity<User> }) => {
	return (
		<>
			<Field name={"department"} caption={"Отдел"}>
				<Select
					items={props.entity.lookups["Department"]}
					onChangeValue={(value) => {
						props.entity.entity.setValue(value!, "department");
					}}
					isInput={true}
					onItemsLoad={async () => {
						return await props.entity.loadLookups("Department", undefined);
					}}
					value={props.entity.entity.department}
				/>
			</Field>
			<Field name={"position"} caption={"Должность"}>
				<Input
					value={props.entity.entity.position}
					placeholder={""}
					onChangeValue={(value: string) => {
						props.entity.entity.setValue(value, "position");
					}}
				/>
			</Field>
		</>
	);
});

// TODO реализовать LocalityContent

// const LocalityContent = observer((props: { entity: Entity<User> }) => {

//     return (
//         <>
//             <Field name={"city"} caption={"Город"}>
//                 <SelectFiltering
//                     items={props.entity.lookups['City']}
//                     onChangeValue={(value) => {
//                         props.entity.entity.setValue(value!, "city");
//                     }}
//                     isInput={true}
//                     className={styles.fullViewBasicInformationDataInput + " " + styles.input}
//                     onItemsLoad={async (s) => { return await props.entity.loadLookups('City', s) }}
//                     value={ }
//                 />

//             </Field>
//             <Field name={"timeZone"} caption={"Часовой пояс"}>
//                 <SelectFiltering
//                     items={props.entity.lookups['TimeZone']}
//                     onChangeValue={(value) => {
//                         props.entity.entity.setValue(value!, "timeZone");
//                     }}
//                     isInput={true}
//                     className={styles.fullViewBasicInformationDataInput + " " + styles.input}
//                     onItemsLoad={async (s) => { return await props.entity.loadLookups('TimeZone', s) }}
//                     value={ }
//                 />

//             </Field>
//         </>
//     )
// });

export default Profile;
