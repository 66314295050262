import styles from "./main-panel.module.css";

function MainPanel(props:React.HTMLAttributes<HTMLDivElement>){
    return(
        <div className={styles.panel} {...props}> 
            {props.children}
        </div>
    )
}

export default MainPanel;