import IProps from "../../IProps";
import styles from "./Content.module.css";

interface ContentProps extends IProps {
    height: number;
}

function Content(props: ContentProps) {
    const height = props.height ? props.height + "px" : undefined;

    return (
        <div className={styles.body + " " + props.className} style={{ height: height }}>
            {props.children}
        </div>
    )
}

export default Content;