import { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";

import { lowerCase } from "lodash";
import { UpFirst } from "shared";

import detailConfigurationStore, { DetailStoreField } from "../detail-configuration-store";
import { validateRequired } from "entities/Validation";

import { Field, Button } from "sale-bridge-ui-kit";

import { Item } from "types";

import { CloseMaxi } from "shared";

import styles from "../detail-configuration.module.scss";

export const ExistObjectConfiguration = observer((props: { close: () => void; closeAll: () => void; onSave: () => void }) => {
	const [searchValue, setSearchValue] = useState("");
	const handleObjectChange = useCallback((value: Item | null) => {
		detailConfigurationStore.setValue(DetailStoreField.newObjectForDetail, value);
		validateRequired(detailConfigurationStore.newObjectForDetail, detailConfigurationStore.validation.newObjectForDetail, true);
		detailConfigurationStore.validExistObjectDetail();
	}, []);

	const handleDetailHeaderChange = useCallback((value: string) => {
		detailConfigurationStore.setValue(DetailStoreField.newDetailTitle, UpFirst(value));
		validateRequired(detailConfigurationStore.newDetailTitle, detailConfigurationStore.validation.newDetailTitle);
		detailConfigurationStore.validExistObjectDetail();
	}, []);

	const handleCancel = useCallback(() => {
		if (detailConfigurationStore.hasChanges) {
			props.close();
		} else {
			props.closeAll();
		}
		detailConfigurationStore.resetExistObjectConfiguration();
	}, [props, detailConfigurationStore.hasChanges]);

	const handleSave = useCallback(() => {
		detailConfigurationStore.saveByExists();
		validateRequired(detailConfigurationStore.detailTitle, detailConfigurationStore.validation.detailTitle);
		validateRequired(detailConfigurationStore.detail, detailConfigurationStore.validation.detail, true);
		props.close();
		props.onSave();
	}, [props]);

	const dialogFooterButtons = useMemo(() => {
		return (
			<>
				<Button text="Отменить" size="small" variant="backless" border link={false} loading={false} onClick={handleCancel} />
				<Button
					text="Сохранить"
					size="small"
					variant={detailConfigurationStore.isDisabledSaveExistObjButton ? "disabled" : "primary"}
					border
					link={false}
					loading={false}
					onClick={handleSave}
				/>
			</>
		);
	}, [detailConfigurationStore.isDisabledSaveExistObjButton]);

	const objectItems = useMemo(() => {
		const items: Item[] = [];
		detailConfigurationStore.objectList.forEach((item: any) => {
			const displayValue = lowerCase(item.displayValue);
			if (displayValue.includes(lowerCase(searchValue))) items.push(item);
		});
		return [
			{
				layout: <></>,
				items: items.map((item) => (
					<div className={styles.selectItem} onClick={() => handleObjectChange(item)}>
						<span>{item.displayValue}</span>
					</div>
				))
			}
		];
	}, [toJS(detailConfigurationStore.objectList), searchValue]);

	return (
		<>
			<div className={styles.headerModal}>
				<span className={styles.titleModal}>Настройки новой детали</span>
				<CloseMaxi className={styles.closeButton} onClick={handleCancel} />
			</div>
			<div className={styles.dialogBody}>
				<div className={styles.fieldsBlock}>
					<Field
						type="select"
						size="small"
						label="Объект"
						labelPosition="vertical"
						textVariant="outlined"
						placeholder="Значение поля"
						isRequired
						info="Список всех таблиц в системе"
						value={detailConfigurationStore.newObjectForDetail?.displayValue ?? ""}
						items={objectItems}
						onChange={() => {}}
						alert={detailConfigurationStore.validation.newObjectForDetail.isInvalid ? "error" : undefined}
						searchValue={searchValue}
						onChangeSearchValue={setSearchValue}
					/>
					<Field
						type="text"
						size="small"
						label="Название детали"
						labelPosition="vertical"
						textVariant="outlined"
						placeholder="Название поля на странице"
						info="Название детали, которое будет отображаться на странице записи"
						isRequired
						value={detailConfigurationStore.newDetailTitle}
						counter={50}
						isClearing
						onChange={handleDetailHeaderChange}
						alert={detailConfigurationStore.validation.newDetailTitle.isInvalid ? "error" : undefined}
					/>
				</div>
			</div>
			<div className={`${styles.dialogFooter} ${styles.rightFooterButtonsBlock}`}>{dialogFooterButtons}</div>
		</>
	);
});
