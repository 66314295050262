import { observer } from "mobx-react-lite";
import { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { toJS } from "mobx";

import { contextMenuStore } from ".";

import { ContextMenuElement } from "./context-menu-element";

import { IContextMenuOption } from "components/grid/data/data";
import { Coordinate } from "types/item";

import styles from "./context-menu.module.scss";

const GAP_FROM_CURSOR = 6;

/**
 * @description Компонент Контекстное меню
 * @param contextMenuOptions элементы контекстного меню
 */
export const ContextMenu = observer((props: { contextMenuOptions: IContextMenuOption[] }) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const [size, setSize] = useState<DOMRect | null>(null);
    
    const cursorPosition = useMemo(() => toJS(contextMenuStore.cursorPosition), [toJS(contextMenuStore.cursorPosition)])

    useEffect(() => {
        if (contextMenuStore.cursorPosition) {
            document.addEventListener("click", handleClickEventListener, { capture: true });
        } else {
            document.removeEventListener("click", handleClickEventListener, { capture: true });
        }
    }, [contextMenuStore.isOpen])
    useEffect(() => {
        setSize(ref.current?.getBoundingClientRect() ?? null)
    }, [ref.current?.getBoundingClientRect()?.width, ref.current?.getBoundingClientRect()?.height])

    const handleReset = useCallback(() => {
        contextMenuStore.reset();
    }, [])

    const handleClickEventListener = useCallback((event: Event) => {
        if (ref.current != null && !ref.current.contains(event.target as Node)) {
            contextMenuStore.resetCallBack();
            handleReset();

        }
    }, [ref.current])

    const options = useMemo(() => {
        if (props.contextMenuOptions) {
            return props.contextMenuOptions.map((option: IContextMenuOption, index) =>
                <>
                    <ContextMenuElement
                        element={option}
                        handleClick={() => { option.action(); handleReset() }}
                        index={index}
                    />
                    {option.isDivider && <div className={styles.divider} />}
                </>
            )
        }
        else return []
    }, [toJS(props.contextMenuOptions)])

    const position = useMemo(() => {
        if (!size || !cursorPosition) {
            return;
        }

        const fixingPosition: Coordinate = JSON.parse(JSON.stringify(cursorPosition));

        if (cursorPosition.x <= (window.innerWidth - (size.width + GAP_FROM_CURSOR))) {
            fixingPosition.x -= GAP_FROM_CURSOR;
        } else {
            fixingPosition.x -= size.width - GAP_FROM_CURSOR;
        }

        if (cursorPosition.y <= (window.innerHeight - (size.height + GAP_FROM_CURSOR))) {
            fixingPosition.y -= GAP_FROM_CURSOR;
        } else {
            fixingPosition.y -= size.height - GAP_FROM_CURSOR;
        }
        return fixingPosition;
    }, [cursorPosition, contextMenuStore.isOpen, size])

    const contextMenuStyle: CSSProperties | undefined = useMemo(() => {
        if (!position) {
            return;
        }
        return {
            top: position?.y,
            left: position?.x,
            visibility: contextMenuStore.isOpen ? 'visible' : 'hidden'
        }
    }, [contextMenuStore.isOpen, position])

    return (
        <div ref={ref} className={styles.wrapper} style={contextMenuStyle}>
            {options}
        </div>
    );
});