import { useCallback } from "react";
import { observer } from "mobx-react-lite";

import detailFieldConfigurationPopupState, { FieldStoreEnums } from "../detail-field-configuration-popup-state";

import { Checkbox } from "sale-bridge-ui-kit";

import { ColumnType } from "entities/ColumnType";
import { ColumnSpecializationType } from "types/entity";

import styles from "../detail-field-configuration-popup.module.scss";

export const CheckboxesBlock = observer(() => {

    const renderIndexCheckbox = () => {
        return (
            <>
                <Checkbox
                    size="small"
                    value={detailFieldConfigurationPopupState.hasIndex}
                    onChangeChecked={(value: boolean) => detailFieldConfigurationPopupState.setValue(FieldStoreEnums.hasIndex, value)}
                    variant="default"
                    description='Отметьте, чтобы поиск по этому полю выполнялся быстрее'
                    title='Индексируемое поле'
                />
            </>
        );
    };

    const handleMultilineChange = useCallback((value: string) => {
        detailFieldConfigurationPopupState.setSpecialization(
            FieldStoreEnums.specializations,
            ColumnSpecializationType.Multiline,
            { "multiline": value }
        );
    }, []);

    const renderMultilineCheckbox = () => {
        return (
            <Checkbox
                size="small"
                value={detailFieldConfigurationPopupState.specializations.properties.multiline === "true"}
                onChangeChecked={(value) => handleMultilineChange(value.toString())}
                variant="default"
                description='Отметьте, если предполагается, что значение поля будет занимать более 5 слов'
                title='Многострочное поле'
            />
        );
    };

    return (
        <div className={styles.checkboxesBlock}>
            {detailFieldConfigurationPopupState.fieldType === ColumnType.Boolean ?
                renderIndexCheckbox() :
                <>
                    <Checkbox
                        size="small"
                        value={detailFieldConfigurationPopupState.isRequired}
                        onChangeChecked={(value: boolean) => detailFieldConfigurationPopupState.setValue(FieldStoreEnums.isRequired, value) }
                        variant="default"
                        description='При сохранении записи поле не может быть пустым'
                        title='Обязательное поле'
                    />
                    <div className={styles.checkboxesDivider} />
                    {renderIndexCheckbox()}
                    {detailFieldConfigurationPopupState.fieldType !== ColumnType.DateTime
                        && detailFieldConfigurationPopupState.fieldType !== ColumnType.Date
                        && detailFieldConfigurationPopupState.fieldType !== ColumnType.Time
                        && detailFieldConfigurationPopupState.fieldType !== ColumnType.Lookup &&
                        <div className={styles.checkboxesDivider} />
                    }
                    {detailFieldConfigurationPopupState.fieldType === ColumnType.String ? renderMultilineCheckbox() : null}
                </>
            }
        </div>
    );
});