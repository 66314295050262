import { makeAutoObservable } from "mobx";

import { api } from "shared";
import ListStore from "entities/ListStore";
// import { licenseStore } from "entities/license/LicensesSectionStore";

class LicenseManagerMainPanelStore {
	entityCount: number = 0;
	// counter: number = 0;
	licenseStore: ListStore | undefined;

	constructor() {
		makeAutoObservable(this);
		// licenseStore.load(null);
		// this.licenseStore = licenseStore;
	}

	async setEntityCount() {
		try {
			const param: any = {
				entityName: "License"
			};
			// let url = "api/Entity/entitycount";
			// const data = await api.post(url, param).then(res => res.data);
			const response = await api.http.entity.entityCount().post(param);
			const data = response.data;
			if (data !== null) {
				this.entityCount = data;
			} else {
				console.log("Something went wrong :(");
				console.log(data.message);
			}
		} catch (error: any) {
			var message = error.response.message;
			console.error("An error occurred:", error);
		} finally {
		}
	}

	// async clearCheckedList() {
	//     var delElems = this.userStore!.includedIds.splice(0, this.userStore!.includedIds.length);
	// }

	async setValue(value: string | number, fieldName: string) {
		Reflect.set(this, fieldName, value);
	}

	// async setActualCount() {
	//     Reflect.set(this, "counter", this.userStore?.includedIds.length)
	// }
}

const licenseManagerMainPanelStore = new LicenseManagerMainPanelStore();

export default licenseManagerMainPanelStore;
