import { useCallback } from "react";
import { observer } from "mobx-react-lite";
import { UpFirst } from "shared";

import detailMasterState from "features/detail-master/detail-master-state";
import detailFieldConfigurationPopupState, { FieldStoreEnums } from "../detail-field-configuration-popup-state";

import { Field } from "sale-bridge-ui-kit";

export const BasicSettings = observer(() => {
	const handleTitleChange = useCallback((value: string) => {
		detailFieldConfigurationPopupState.setValue(FieldStoreEnums.title, value);
		detailFieldConfigurationPopupState.validateTitle(detailMasterState.gridItems);
	}, []);

	const handleSystemNameChange = useCallback((value: string) => {
		detailFieldConfigurationPopupState.setValue(FieldStoreEnums.systemName, value);
		detailFieldConfigurationPopupState.validateSystemName(detailMasterState.gridItems);
	}, []);

	return (
		<>
			<Field
				type="text"
				size="small"
				label="Заголовок"
				labelPosition="vertical"
				textVariant="outlined"
				placeholder="Название поля на странице"
				isRequired
				value={detailFieldConfigurationPopupState.title}
				info="Название поля, которое вы будете видеть на странице"
				counter={50}
				isClearing
				onChange={handleTitleChange}
				alert={detailFieldConfigurationPopupState.validation.title.isInvalid ? "error" : undefined}
			/>
			<Field
				type="text"
				size="small"
				label="Системное название"
				labelPosition="vertical"
				textVariant="outlined"
				placeholder="Название колонки в базе данных"
				isRequired
				value={detailFieldConfigurationPopupState.systemName}
				info="Название колонки, которое будет отображаться в базе данных"
				counter={50}
				isClearing
				onChange={(value: string) => {
					handleSystemNameChange(UpFirst(value));
				}}
				alert={detailFieldConfigurationPopupState.validation.systemName.isInvalid ? "error" : undefined}
			/>
		</>
	);
});
