import classNames from "classnames";
import { action, toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";

import { SortDirection } from "entities/ISort";

import { dispatcher } from "store";
import { synchroiser } from "synchroiser";

import { CheckBox, ContextMenu } from "components";
import { contextMenuStore } from "components/context-menu";
import { modalController } from "features/modals";
import SkeletonGrid from "../skeleton/skeleton-grid";
import GridParametersPopup from "./grid-parameters-popup";

import { ColumnSpecializationType, LoadingState } from "types/entity";
import { Coordinate } from "types/item";
import { GeneralizedGridProps, GridHeadProps, GridRowProps, IGeneralizedColumn } from "../data/data";
import { Value } from "../data";

import { GridHeadDividerVertical, SettingsDefault, SortOldToNew } from "shared";

import styles from "./generalized-grid.module.scss";

const SPACE_FOR_NECESSARY_ELEMENTS = 65; //чтобы грамотно вычислять ширину столбцов с учетом чекбокса и иконки настройки

const MIN_WIDTH_OF_COLUMN = 150;

const GridHead = observer(function (props: GridHeadProps) {
	const [idModal] = useState<string>(v4());
	const headerClassNames = useMemo(() => (props.isDetailGrid ? styles.detailTableHeader : styles.tableHeader), [props.isDetailGrid]);

	const resize = useMemo(
		(): {
			pageX: number;
			startCurrentWidth: number;
			currentColumnId: string | null;
		} => ({
			pageX: 0,
			startCurrentWidth: 0,
			currentColumnId: null
		}),
		[]
	);

	useEffect(() => {
		document.addEventListener("mousemove", handleMouseMove);
		document.addEventListener("mouseup", handleMouseUp);
		return () => {
			document.removeEventListener("mousemove", handleMouseMove);
			document.removeEventListener("mouseup", handleMouseUp);
		};
	}, []);

	const handleChange = useCallback(
		(value: boolean) => {
			props.onChangeChecked(value);
		},
		[props.onChangeChecked]
	);

	const closeConfirm = useCallback((e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
		e?.stopPropagation();
		modalController.modalRemove(idModal);
	}, []);

	const handleSettingsGridClick = useCallback(() => {
		modalController.popupAdd({
			id: idModal,
			layout: <GridParametersPopup entity={props.entity} close={closeConfirm} />,
			closeFunc: closeConfirm
		});
	}, [idModal, props.entity]);

	const handleMouseDown = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>, currentColumnId: string) => {
		const width = document.getElementById(currentColumnId)?.offsetWidth;

		if (!width) {
			return;
		}

		document.body.style.cursor = "col-resize";
		document.body.style.userSelect = "none";

		if (document.getElementById("root")) {
			document.getElementById("root")!.style.pointerEvents = "none";
		}

		resize.currentColumnId = currentColumnId;
		resize.pageX = e.pageX;
		resize.startCurrentWidth = width;
	}, []);

	const handleMouseUp = useCallback(
		(e: MouseEvent) => {
			document.body.style.cursor = "";
			document.body.style.userSelect = "";
			if (document.getElementById("root")) {
				document.getElementById("root")!.style.pointerEvents = "";
			}

			//TODO убрать присваивание новой ширины, если потребуется моментальное отображение результата resize, когда будет реализована подгрузка частями
			if (document.getElementById(resize.currentColumnId!)) {
				props.setColumnWidth(
					resize.currentColumnId!,
					Number(document.getElementById(resize.currentColumnId!)!.style.width.split("px")[0])
				);
			}
			resize.currentColumnId = null;
			resize.pageX = 0;
			resize.startCurrentWidth = 0;
		},
		[resize, props.setColumnWidth]
	);

	const handleMouseMove = action(
		useCallback(
			(e: MouseEvent) => {
				if (resize.pageX == 0 && resize.startCurrentWidth == 0 && resize.currentColumnId === null) {
					return;
				}
				document.body.style.cursor = "col-resize";

				const direction = e.pageX > resize.pageX ? "right" : "left";

				let diffs = 0;
				let finalCurentWidth = 0;
				if (direction === "right") {
					diffs = e.pageX - resize.pageX;
					finalCurentWidth = diffs + resize.startCurrentWidth;
				} else {
					diffs = resize.pageX - e.pageX;
					finalCurentWidth = resize.startCurrentWidth - diffs;
				}

				if (!document.getElementById(resize.currentColumnId!) || finalCurentWidth < MIN_WIDTH_OF_COLUMN) {
					return;
				}

				document.getElementById(resize.currentColumnId!)!.style.width = `${finalCurentWidth}px`;
				//TODO возможно раскомментить,если потребуется моментальное отображение результата resize, когда будет реализована подгрузка частями
				// props.setColumnWidth(resize.currentColumnId!,finalCurentWidth);
			},
			[resize]
		)
	);

	return (
		<div className={headerClassNames}>
			<SettingsDefault className={styles.settingGrid} onClick={handleSettingsGridClick} />
			<CheckBox className={styles.checkBoxGrid} checked={props.checkedAll} onChangeChecked={handleChange} />
			{props.columns.map((column: IGeneralizedColumn, index) => {
				const width = column.spanX!;
				return (
					<div
						key={column.columnId}
						id={column.columnId}
						className={styles.headerCell}
						style={{ width: `${width}px`, minWidth: `${MIN_WIDTH_OF_COLUMN}px` }}
					>
						<div className={styles.headerCaption}>{column.columnTitle}</div>
						<SortOldToNew
							className={styles.sortIcon}
							onClick={() => {
								props.onSortClick(column);
							}}
						/>
						{index < props.columns.length - 1 && (
							<div
								className={styles.verticalDividerContainer}
								onMouseDown={(e) => {
									handleMouseDown(e, column.columnId);
								}}
							>
								<GridHeadDividerVertical />
							</div>
						)}
					</div>
				);
			})}
		</div>
	);
});

export const EmptyGrid = () => {
	const history = useNavigate();
	return (
		<div className={styles.emptyWrapper}>
			<div className={styles.emptyTitle}>Ничего не найдено.</div>
			<div className={styles.emptySubTitle}>
				<span
					className={styles.emptyLink}
					onClick={() => {
						history("new");
					}}
				>
					Создайте{" "}
				</span>
				запись или проверьте настройки фильтра
			</div>
		</div>
	);
};

const GeneralizedGrid = observer(function (props: GeneralizedGridProps) {
	const defaultLimit = 30;
	const allRecords = 0;

	const gridClassNames = useMemo(() => (props.isDetailGrid ? styles.detailGrid : styles.grid), [props.isDetailGrid]);
	const tableClassNames = useMemo(() => (props.isDetailGrid ? styles.detailTable : styles.table), [props.isDetailGrid]);

	const checkedAll = useMemo(() => {
		if (props.entity.entity.excludedIds.length > 0) {
			return false;
		} else {
			return props.entity.entity.isCheckedAll;
		}
	}, [toJS(props.entity?.entity?.excludedIds), props.entity?.entity?.isCheckedAll]);

	const visibleColumns = useMemo(
		() => props.entity.entity.visibleColumns.slice().sort((a, b) => (a.indexX > b.indexX ? 1 : b.indexX > a.indexX ? -1 : 0)),
		[toJS(props.entity.entity.visibleColumns)]
	);

	const dataMapping = useMemo(
		() =>
			props.entity.entity.rows.length > 0 &&
			visibleColumns.length > 0 &&
			props.entity.entity.rows.map((row) => {
				return (
					<Row
						key={row["id"]}
						entity={props.entity}
						row={row}
						visibleColumns={visibleColumns}
						isDetailGrid={props.isDetailGrid}
						onChangeCheckedAll={props.onChangeCheckedAll}
						onDoubleRowClick={props.onDoubleRowClick}
						sysFlagField={props.sysFlagField}
					/>
				);
			}),
		[toJS(props.entity.entity.rows), toJS(props.contextMenuOptions), toJS(visibleColumns)]
	);

	//TODO подгрузка
	function trackScrolling(e: SyntheticEvent<HTMLDivElement>) {
		// const wrappedElement = e.target as HTMLDivElement;
		// if (Math.round(wrappedElement.scrollHeight - wrappedElement.scrollTop) <= wrappedElement.clientHeight + 1 && listStore!.isLoaded) {
		// 	listStore?.loadMore(listStore.filter, defaultLimit, sort.current);
		// }
	}

	const onSortClick = useCallback(async (column: IGeneralizedColumn) => {
		const isSameColumn = props.entity.entity.sort.columnPath.split(".")[0] === column.columnName;
		const isKanbanPriorityField =
			column.specializations?.tag === ColumnSpecializationType.KanbanField &&
			column?.specializations.properties["priorityField"] === "true";

		if (isSameColumn) {
			toggleSortDirection();
		} else {
			updateSortForNewColumn(column, isKanbanPriorityField);
		}

		dispatcher.entity.set(props.entity);
		await synchroiser.getEntity();
	}, []);

	const toggleSortDirection = () => {
		props.entity.entity.sort.direction =
			props.entity.entity.sort.direction === SortDirection.Ascending ? SortDirection.Descending : SortDirection.Ascending;
	};

	const updateSortForNewColumn = (column: IGeneralizedColumn, isKanbanPriorityField: boolean) => {
		if (isKanbanPriorityField) {
			props.entity.entity.sort.columnPath = `${column.columnName}.Order`;
		} else {
			props.entity.entity.sort = {
				columnPath: column.columnName,
				direction: SortDirection.Ascending
			};
		}
	};

	const setColumnWidth = useCallback((columnId: string, width: number) => {
		synchroiser.updateVisibleColumnWidth(columnId, width);
	}, []);

	//TODO Skeleton грида (synchroiser.loadingState надо отлавливать чтобы подхватывалось его состояние измененное)
	if (synchroiser.loadingState == LoadingState.Loading) return <SkeletonGrid />;

	//TODO обработка ошибок
	return (
		<div className={gridClassNames}>
			<GridHead
				entity={props.entity}
				columns={visibleColumns}
				checkedAll={checkedAll}
				onChangeChecked={props.onChangeCheckedAll}
				onSortClick={onSortClick}
				setColumnWidth={setColumnWidth}
				isDetailGrid={props.isDetailGrid}
			/>
			<div className={tableClassNames} onScroll={trackScrolling}>
				{dataMapping}
				{(props.entity.entity.rows.length === 0 || visibleColumns.length === 0) && (
					<div>
						<EmptyGrid />
					</div>
				)}
				{/* {listStore?.isError && <div>{listStore.error}</div>} */}
				{props.contextMenuOptions && contextMenuStore.isOpen && <ContextMenu contextMenuOptions={props.contextMenuOptions!} />}
			</div>
		</div>
	);
});

const Row = observer(function (props: GridRowProps) {
	const navigate = useNavigate();

	const checkedRow = useMemo(
		() =>
			props.entity.entity.includedIds.findIndex((x) => x.id === props.row.id) > -1 ||
			(props.entity.entity.isCheckedAll && props.entity.entity.excludedIds.findIndex((x) => x.id === props.row.id) == -1),
		[props.entity.entity.includedIds, props.entity.entity.isCheckedAll, props.entity.entity.excludedIds]
	);

	const handleChange = useCallback(
		(value: boolean) => {
			dispatcher.entity.onChangeChecked(
				value,
				props.row,
				dispatcher.entity.get()?.id === props.entity.id ? undefined : props.entity.entity
			);
		},
		[props.row]
	);

	const handleClick = useCallback(
		(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			handleChange(!checkedRow);
			if (props.onClick) {
				props.onClick(props.row);
			}
		},
		[checkedRow, props.onClick, props.row, props.row.id]
	);

	const handleDoubleClick = useCallback(
		(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			if (props.onDoubleRowClick) {
				props.onDoubleRowClick(props.row.id, event);
				return;
			}
			navigate(props.row.id);
		},
		[props.row]
	);

	const checkRows = useCallback(
		(row: any) => {
			if (!props.entity.entity.isCheckedAll) {
				if (!props.entity?.entity.includedIds.find((cell) => cell.id === row.id)) {
					props.onChangeCheckedAll(false);
					// dispatcher.entity.onChangeCheckedAll(false,dispatcher.entity.get()?.id === props.entity.id ? undefined : props.entity.entity);
					dispatcher.entity.onChangeChecked(
						true,
						row,
						dispatcher.entity.get()?.id === props.entity.id ? undefined : props.entity.entity
					);
				}
			}
		},
		[toJS(props.entity?.entity.includedIds), props.entity.entity.isCheckedAll]
	);

	const handleClickContextMenu = useCallback(
		(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			event.preventDefault();
			contextMenuStore.reset();
			contextMenuStore.openMenu();
			const newPosition: Coordinate = {
				x: event.pageX,
				y: event.pageY
			};
			contextMenuStore.initContextMenu(newPosition, props.row.id, () => handleChange(false));
			checkRows(props.row);
		},
		[props.row]
	);

	const rowClassNames = classNames(
		styles.tableRow,
		checkedRow
			? {
					[`${styles.activeDataTableRow}`]: props.sysFlagField && !props.row[props.sysFlagField],
					[`${styles.activeFlaggedDataTableRow}`]: props.sysFlagField && props.row[props.sysFlagField]
			  }
			: { [`${styles.flaggedDataTableRow}`]: props.sysFlagField && props.row[props.sysFlagField] }
	);

	return (
		<div
			id={props.row.id}
			className={rowClassNames}
			onContextMenu={handleClickContextMenu}
			onClick={handleClick}
			onDoubleClick={handleDoubleClick}
		>
			<div className={styles.settingGrid} />
			<CheckBox className={styles.checkBoxGrid} checked={checkedRow} onChangeChecked={handleChange} />

			{props.visibleColumns.map((column: any) => {
				return (
					<div
						key={column.columnName}
						className={styles.cell}
						style={{ width: `${column.spanX!}px`, minWidth: `${MIN_WIDTH_OF_COLUMN}px` }}
					>
						<Value row={props.row} column={column} width={`${column.spanX!}px`} entityName={props.entity.entityName} />
					</div>
				);
			})}
		</div>
	);
});

export default GeneralizedGrid;
