import { ComparisonType, DataValueType } from "entities/filter/IFilter";
import { Item } from "types";

type IComparisonsGroup = {
    [key: number]: Item[];
};

const ComparisonsGroup: IComparisonsGroup = {
    [DataValueType.Text]: [
        { id: ComparisonType.NotContain, name: "Не содержит" },
        { id: ComparisonType.Contain, name: "Содержит" },
        { id: ComparisonType.Equal, name: "Равно" },
        { id: ComparisonType.NotEqual, name: "Не равно" },
        { id: ComparisonType.IsNotNull, name: "Заполнено" },
        { id: ComparisonType.IsNull, name: "Не заполнено" }
    ],
    [DataValueType.Integer]: [
        { id: ComparisonType.Greater, name: "Больше" },
        { id: ComparisonType.GreaterOrEqual, name: "Больше или равно" },
        { id: ComparisonType.Less, name: "Меньше" },
        { id: ComparisonType.LessOrEqual, name: "Меньше или равно" },
        { id: ComparisonType.Equal, name: "Равно" },
        { id: ComparisonType.NotEqual, name: "Не равно" },
        { id: ComparisonType.Between, name: "Входит в диапазон, включая" },
        { id: ComparisonType.NotBetween, name: "Не входит в диапазон" },
        { id: ComparisonType.IsNotNull, name: "Заполнено" },
        { id: ComparisonType.IsNull, name: "Не заполнено" }
    ],
    [DataValueType.Float]: [
        { id: ComparisonType.Greater, name: "Больше" },
        { id: ComparisonType.GreaterOrEqual, name: "Больше или равно" },
        { id: ComparisonType.Less, name: "Меньше" },
        { id: ComparisonType.LessOrEqual, name: "Меньше или равно" },
        { id: ComparisonType.Equal, name: "Равно" },
        { id: ComparisonType.NotEqual, name: "Не равно" },
        { id: ComparisonType.Between, name: "Входит в диапазон, включая" },
        { id: ComparisonType.NotBetween, name: "Не входит в диапазон" },
        { id: ComparisonType.IsNotNull, name: "Заполнено" },
        { id: ComparisonType.IsNull, name: "Не заполнено" }
    ],
    [DataValueType.Long]: [
        { id: ComparisonType.Greater, name: "Больше" },
        { id: ComparisonType.GreaterOrEqual, name: "Больше или равно" },
        { id: ComparisonType.Less, name: "Меньше" },
        { id: ComparisonType.LessOrEqual, name: "Меньше или равно" },
        { id: ComparisonType.Equal, name: "Равно" },
        { id: ComparisonType.NotEqual, name: "Не равно" },
        { id: ComparisonType.Between, name: "Входит в диапазон, включая" },
        { id: ComparisonType.NotBetween, name: "Не входит в диапазон" },
        { id: ComparisonType.IsNotNull, name: "Заполнено" },
        { id: ComparisonType.IsNull, name: "Не заполнено" }
    ],
    [DataValueType.Decimal]: [
        { id: ComparisonType.Greater, name: "Больше" },
        { id: ComparisonType.GreaterOrEqual, name: "Больше или равно" },
        { id: ComparisonType.Less, name: "Меньше" },
        { id: ComparisonType.LessOrEqual, name: "Меньше или равно" },
        { id: ComparisonType.Equal, name: "Равно" },
        { id: ComparisonType.NotEqual, name: "Не равно" },
        { id: ComparisonType.Between, name: "Входит в диапазон, включая" },
        { id: ComparisonType.NotBetween, name: "Не входит в диапазон" },
        { id: ComparisonType.IsNotNull, name: "Заполнено" },
        { id: ComparisonType.IsNull, name: "Не заполнено" }
    ],
    [DataValueType.DateTime]: [
        { id: ComparisonType.Greater, name: "Позднее" },
        { id: ComparisonType.GreaterOrEqual, name: "Позднее или равно" },
        { id: ComparisonType.Less, name: "Раньше" },
        { id: ComparisonType.LessOrEqual, name: "Раньше или равно" },
        { id: ComparisonType.Equal, name: "Равно" },
        { id: ComparisonType.NotEqual, name: "Не равно" },
        { id: ComparisonType.Between, name: "Входит в диапазон, включая" },
        { id: ComparisonType.NotBetween, name: "Не входит в диапазон" },
        { id: ComparisonType.IsNotNull, name: "Заполнено" },
        { id: ComparisonType.IsNull, name: "Не заполнено" }
    ],
    [DataValueType.Boolean]: [{ id: ComparisonType.Equal, name: "Равно" }],
    [DataValueType.Lookup]: [
        { id: ComparisonType.Equal, name: "Равно" },
        { id: ComparisonType.NotEqual, name: "Не равно" },
        { id: ComparisonType.IsNotNull, name: "Заполнено" },
        { id: ComparisonType.IsNull, name: "Не заполнено" }
    ],
    [DataValueType.Guid]: [{ id: ComparisonType.Equal, name: "Равно" }],
    [DataValueType.Time]: [
        { id: ComparisonType.Greater, name: "Позднее" },
        { id: ComparisonType.GreaterOrEqual, name: "Позднее или равно" },
        { id: ComparisonType.Less, name: "Раньше" },
        { id: ComparisonType.LessOrEqual, name: "Раньше или равно" },
        { id: ComparisonType.Equal, name: "Равно" },
        { id: ComparisonType.NotEqual, name: "Не равно" },
        { id: ComparisonType.Between, name: "Входит в диапазон, включая" },
        { id: ComparisonType.NotBetween, name: "Не входит в диапазон" },
        { id: ComparisonType.IsNotNull, name: "Заполнено" },
        { id: ComparisonType.IsNull, name: "Не заполнено" }
    ],
    [DataValueType.Date]: [
        { id: ComparisonType.Greater, name: "Позднее" },
        { id: ComparisonType.GreaterOrEqual, name: "Позднее или равно" },
        { id: ComparisonType.Less, name: "Раньше" },
        { id: ComparisonType.LessOrEqual, name: "Раньше или равно" },
        { id: ComparisonType.Equal, name: "Равно" },
        { id: ComparisonType.NotEqual, name: "Не равно" },
        { id: ComparisonType.Between, name: "Входит в диапазон, включая" },
        { id: ComparisonType.NotBetween, name: "Не входит в диапазон" },
        { id: ComparisonType.IsNotNull, name: "Заполнено" },
        { id: ComparisonType.IsNull, name: "Не заполнено" }
    ]
};

export default ComparisonsGroup;
