import { useCallback, useMemo } from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";

import { dispatcher } from "store";

import { InputSearch } from "components";
import { ConstructorSourcePanel } from "../constructor-source-panel";
import { CheckboxGroup, CheckboxGroupItemProps } from "pages/section-wizzard/components";
import { Switcher } from "sale-bridge-ui-kit";

import { IGridItem } from "components/select/types";
import { MoreTitle, NewFields } from "pages/section-wizzard/data/data";
import { DisplayedPanel, TabId, TabState } from "types/entity";
import { SwitchElement } from "sale-bridge-ui-kit/dist/components/switcher/switcher";

import styles from "./constructor-right-panel.module.scss";


const ConstructorRightPanel = observer((props: {
	sourcePanelElements: Array<IGridItem>,
	onMoveBoxTab: (id: string, value: boolean, currentDisplayedPanel?: DisplayedPanel) => void
}) => {
	const sectionWizzard = useMemo(() => {
		return dispatcher.entity.get()?.entity.sectionWizzard;
	}, [dispatcher.entity.get()?.entity.sectionWizzard]);

	const tabsConfig = useMemo(() => {
		return sectionWizzard?.reactorConfig.tabs.tabsConfig.filter(elem => elem);
	}, [sectionWizzard?.reactorConfig.tabs.tabsConfig.filter(elem => elem)]);

	const displayedPanel = useMemo(() => {
		return sectionWizzard?.displayedPanel;
	}, [sectionWizzard?.displayedPanel]);

	const additionalPanelTabs = useMemo(() => {
		return sectionWizzard?.reactorConfig.tabs.additionalPanel;
	}, [sectionWizzard?.reactorConfig.tabs.additionalPanel]);

	const searchValue = useMemo(() => {
		return sectionWizzard?.searchValue ?? "";
	}, [sectionWizzard?.searchValue]);

	const handleSearchChange = useCallback((value: string) => {
		dispatcher.sectionWizzard.setSearchValue(value);
	}, [searchValue]);

	const getСheckedToogle = useCallback((settingId: TabId) => {
		if (sectionWizzard?.displayedPanel === DisplayedPanel.Main) {
			return dispatcher.sectionWizzard.getAdditionalFromConfig()![settingId].state === TabState.EnabledOnPrimary;
		}
		else {
			return dispatcher.sectionWizzard.getAdditionalFromConfig()![settingId].state === TabState.EnabledOnAuxiliary;
		}

	}, [tabsConfig, additionalPanelTabs, displayedPanel]);

	const tabs: CheckboxGroupItemProps[] = useMemo(() => {
		const additional = dispatcher.sectionWizzard.getAdditionalFromConfig();
		return [
			{
				id: TabId.Files,
				title: additional![TabId.Files].title,
				value: getСheckedToogle(TabId.Files),
				description: 'Загрузка и скачивание файлов'
			},
			{
				id: TabId.Comments,
				title: additional![TabId.Comments].title,
				value: getСheckedToogle(TabId.Comments)!,
				description: 'Отправка и редактирование комментариев'
			},
			{
				id: TabId.Chronology,
				title: additional![TabId.Chronology].title,
				value: getСheckedToogle(TabId.Chronology)!,
				description: 'Отслеживание настроенных изменений в записи'
			},
			{
				id: TabId.Wysiwyg,
				title: additional![TabId.Wysiwyg].title,
				value: getСheckedToogle(TabId.Wysiwyg)!,
				description: 'Редактор текста: инструменты форматирования, вставка таблиц'
			},
		];
	}, [tabsConfig, additionalPanelTabs, displayedPanel]);

	const rightSettings = useMemo(() => {
		return <div className={styles.rightSettingsWrapper}>
			<ConstructorSourcePanel
				tabs={tabs}
				newFields={NewFields}
				sourcePanelElements={props.sourcePanelElements}
				onMoveBoxTab={props.onMoveBoxTab}
			/>
		</div>;
	}, [toJS(props.sourcePanelElements), toJS(tabs), props.onMoveBoxTab]);

	const choosingPanel = useMemo(() => {
		if (displayedPanel === DisplayedPanel.Main) {
			return <>
				<InputSearch
					value={searchValue}
					placeholder="Поиск в существующих полях"
					onChangeValue={handleSearchChange}
					className={styles.search}
					classNameInput={styles.search}
				/>
				<div className={styles.divider}></div>
				{rightSettings}
			</>;
		}
		else {
			return <>
				<div className={styles.divider} />
				<CheckboxGroup items={tabs} nameOfFieldSelect={MoreTitle} onChangeChecked={props.onMoveBoxTab} />
			</>;
		}

	}, [searchValue, rightSettings, displayedPanel]);

	const switchPanel = useCallback((value: string) => {
		dispatcher.sectionWizzard.switchDisplayedPanel(value);
	}, []);

	const switcherElements: Array<SwitchElement> = [
		{
			id: DisplayedPanel.Main as unknown as string,
			text: 'Левая панель',
		}, {
			id: DisplayedPanel.Additional as unknown as string,
			text: 'Правая панель',
		}
	]
	return (
		<div className={styles.constructorRightPanel}>
			<Switcher
				size='small'
				elements={switcherElements}
				value={dispatcher.sectionWizzard.getSectionWizzard()?.displayedPanel as unknown as string}
				onSwitch={switchPanel}
			/>
			{choosingPanel}
		</div>
	);
});

export default ConstructorRightPanel;