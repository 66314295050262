import { FilterStore } from "entities/filter/FilterStore";
import { api } from "shared";

const BASIC_UPDATE_RECORDS_STAGE = 30;

export async function getRecordsListByStageName(
	needColumns: string[],
	currentEntityName: string | undefined,
	filter: FilterStore,
	stageFieldName: string | null,
	stageId: string,
	sort: any,
	staticGroupId: string | null | undefined
) {
	return api.http.entity.recordsListWithColumns().post({
		columnNames: needColumns,
		entityName: currentEntityName,
		filter: filter.serialize().filters?.length ? filter.serialize() : null,
		canbanColumn: {
			columnName: stageFieldName,
			columnValue: stageId
		},
		offset: 0,
		limit: BASIC_UPDATE_RECORDS_STAGE,
		sort: sort ?? null,
		staticGroupId: staticGroupId ?? null
	});
}
