import { Api } from "shared/api/class-api";

class Flag extends Api {
	basicPath: string;
	constructor() {
		super();
		this.basicPath = "/api/Flags";
	}

	updateFlag = () => {
		this.setPath(`${this.basicPath}/updateFlag`);
		return this;
	};
}

const flag = new Flag();

export { flag };
