import React from "react";
import { LoadCircle } from "shared";

import styles from "./loading-circle.module.css";

const LoadingCircle: React.FC = () => {
	return <LoadCircle className={styles.circle} />;
};

export default LoadingCircle;
