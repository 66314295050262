import { observer } from "mobx-react";

import styles from "./section.module.css";

interface SectionProps {
    title: string,
    children?: JSX.Element[] | JSX.Element
}

export const Section = observer((props: SectionProps) => {

    return (
        <div className={styles.sectionWrapper}>
            <span className={styles.sectionHeader}>{props.title}</span>
            {props.children}
        </div>
    );
});
