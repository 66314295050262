import {
  ContentState,
  DraftEntityMutability,
  EditorState,
  RichUtils,
  SelectionState,
  Modifier,
} from 'draft-js';
import { useCallback, useState, useMemo } from 'react';

import { HTMLtoState, stateToHTML } from '../services/convert';
import { createLinkDecorator } from '../link';

import { EntityType, InlineStyle, mappingColors } from './config';


export type EditorApi = {
  editorState: EditorState;
  onChange: (state: EditorState) => void;
  toHtml: () => string;
  fromHtml: (html: string) => void;
  toggleInlineStyle: (inlineStyle: InlineStyle) => void;
  hasInlineStyle: (inlineStyle: InlineStyle) => boolean;
  hasColorInlineStyle: () => boolean;
  addLink: (url: any) => void;
  removeLink: (selection: SelectionState) => void;
  setEntityData: (entityKey: string, data: Record<string, string>) => void;
  reset: () => void;
  newLine: () => void;
  moveFocusToEnd: () => void;
};

export const useEditor = (html?: string): EditorApi => {
  const decorator = createLinkDecorator();
  const [editorState, setEditorState] = useState(() =>
    html
      ? EditorState.createWithContent(HTMLtoState(html), decorator)
      : EditorState.createEmpty(decorator)
  );

  const onChange = useCallback((newState: EditorState) => {
    setEditorState(newState);
  }, []);

  //Доработать в случае если вернемся к обрезанию пробелов до и после текста. Использовать в onChange
  const trimEditorState = (currentEditorState: EditorState) => {
    const currentContent = currentEditorState.getCurrentContent();
    const newContent = currentContent.getBlockMap().reduce((accumulator, block) => {
      const key = block!.getKey();
      const text = block!.getText();
      const trimmedLeft = text.trimLeft();
      const trimmedRight = text.trimRight();
      const offset = text.length - trimmedLeft.length;

      const textToReplaceLeft = new SelectionState({
        anchorKey: key,
        focusKey: key,
        anchorOffset: 0,
        focusOffset: offset,
      });

      const leftTrimmedContent = Modifier.replaceText(accumulator!, textToReplaceLeft, '');

      const textToReplacRight = new SelectionState({
        anchorKey: key,
        focusKey: key,
        anchorOffset: trimmedRight.length - offset,
        focusOffset: text.length - offset,
      });

      return Modifier.replaceText(leftTrimmedContent, textToReplacRight, '');
    }, currentContent);

    return EditorState.push(currentEditorState, newContent, 'remove-range');
  };

  const toHtml = useCallback(() => {
    return stateToHTML(editorState.getCurrentContent());
  }, [editorState]);

  const fromHtml = useCallback(
    (html: string) => {
      setEditorState(EditorState.createWithContent(HTMLtoState(html), decorator));
    },
    [editorState]
  );

  const toggleInlineStyle = useCallback(
    (inlineStyle: InlineStyle) => {
      setEditorState((currentState) => {
        return RichUtils.toggleInlineStyle(currentState, inlineStyle);
      });
    },
    [editorState]
  );

  const hasInlineStyle = useCallback(
    (inlineStyle: InlineStyle) => {
      const currentStyle = editorState.getCurrentInlineStyle();
      return currentStyle.has(inlineStyle);
    },
    [editorState]
  );

  const hasColorInlineStyle = useCallback(() => {
    const currentStyle = editorState.getCurrentInlineStyle();
    let flag = false;
    mappingColors.forEach((val, key) => {
      if (currentStyle.has(key)) flag = true;
    });
    return flag;
  }, [editorState]);

  const setEntityData = useCallback((entityKey: string, data: { [key: string]: any }) => {
    setEditorState((currentState) => {
      const content = currentState.getCurrentContent();
      const contentStateUpdated = content.mergeEntityData(entityKey, data);
      return EditorState.push(currentState, contentStateUpdated, 'apply-entity');
    });
  }, []);

  const addEntity = useCallback(
    (
      entityType: EntityType,
      data: Record<string, string>,
      mutability: DraftEntityMutability
    ) => {
      setEditorState((currentState) => {
        const contentState = currentState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(entityType, mutability, data);
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newState = EditorState.set(currentState, {
          currentContent: contentStateWithEntity,
        });
        return RichUtils.toggleLink(newState, newState.getSelection(), entityKey);
      });
    },
    []
  );

  const addLink = useCallback(
    (url: any) => {
      addEntity(EntityType.LINK, { url: url }, 'MUTABLE');
      setEditorState((currentState) =>
        RichUtils.toggleInlineStyle(currentState, InlineStyle.INDIGO600)
      );
    },
    [addEntity]
  );

  const removeLink = useCallback((selection: SelectionState) => {
    setEditorState((currentState) => {
      return RichUtils.toggleLink(currentState, selection, null);
    });
  }, []);

  const reset = useCallback(() => {
    const newEditorState = EditorState.push(
      editorState,
      ContentState.createFromText(''),
      'insert-characters'
    );
    setEditorState(newEditorState);
  }, []);

  const newLine = useCallback(() => {
    setEditorState(RichUtils.insertSoftNewline(editorState));
  }, [editorState]);

  const moveFocusToEnd = useCallback(() => {
    setEditorState((currentState) => {
      return EditorState.moveFocusToEnd(currentState);
    });
  }, []);

  return useMemo(
    () => ({
      editorState,
      onChange,
      toHtml,
      fromHtml,
      toggleInlineStyle,
      hasInlineStyle,
      hasColorInlineStyle,
      addLink,
      removeLink,
      setEntityData,
      reset,
      newLine,
      moveFocusToEnd,
    }),
    [
      editorState,
      onChange,
      toHtml,
      fromHtml,
      toggleInlineStyle,
      hasInlineStyle,
      hasColorInlineStyle,
      addLink,
      removeLink,
      setEntityData,
      reset,
      newLine,
      moveFocusToEnd,
    ]
  );
};
