import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";

import { InputStyleName, InputStyles } from "components";

import { Locked } from "shared";
import { EyeNotHidden } from "shared";
import { EyeHidden } from "shared";
import { Lightning } from "shared";

import styles from "./input.module.css";

export interface IInputProps extends React.HTMLAttributes<HTMLElement> {
	name?: string;
	value: string | null;
	placeholder: string;
	isPassword?: boolean;
	isInvalid?: boolean;
	isDisabled?: boolean;
	isDisabledLocked?: boolean; //нужно ли отображение замка, когда поле disabled
	isNotUnique?: boolean;
	autoComplete?: boolean;
	invalidMessage?: string;
	classNameInput?: string;
	classNameInputFocus?: string;
	classNameParent?: string;
	invalidMessageSmall?: boolean;
	size?: number | null;
	onChangeValue: (value: string) => void;
	onFocusOut?: () => void;
	maxLength?: number;
	isPlaceholderDelay?: boolean; //нужна ли задержка отображения рlaceholder до 1 введенного знака
	isBaseInput?: boolean;
	isNeedCorrect?: boolean;
	focus?: boolean;
	isLightning?: boolean;
	inputStyle?: InputStyleName;
}

function Input(props: IInputProps) {
	const [focusOut, setFocusOut] = useState<boolean>(false);
	const [focus, setFocus] = useState<boolean>(false);
	let result = props.isNeedCorrect ? <div style={{ width: "34px" }}></div> : <></>;
	const ref = useRef<HTMLInputElement>(null);
	const [inputType, setInputType] = useState<string>("text");
	const inputStyle = InputStyles[props.inputStyle ?? InputStyleName.Base];

	const wrapperClassNames = classNames({
		[`${inputStyle.classNames} `]: true,
		[`${props.className} `]: props.className,
		[`${props.classNameInputFocus}`]: focus && props.classNameInputFocus,
		[`${inputStyle.focus}`]: focus && !props.classNameInputFocus,
		[`${styles.disabled} `]: props.isDisabled && props.isDisabledLocked,
		[`${inputStyle.invalid} `]: props.isInvalid,
		[`${styles.isNotUnique}`]: props.isNotUnique,
		[`${styles.small} `]: props.invalidMessageSmall
	});
	const inputClassNames = classNames({
		[`${inputStyle.input} `]: true,
		[`${styles.delay}`]: props.isPlaceholderDelay,
		[`${inputStyle.disabled}`]: props.isDisabled,
		[`${props.classNameInput}`]: props.classNameInput
	});

	let icon = null;

	if (props.isDisabled && props.isDisabledLocked) {
		icon = <Locked className={styles.iconResult} />;
	}

	let error = null;
	if (props.isInvalid === true) {
		if (props.invalidMessage !== undefined && props.invalidMessage.length > 1) {
			error = <div className={inputStyle.errorMessage}>{props.invalidMessage}</div>;
		}
	} else if (props.isNotUnique === true) {
		if (props.invalidMessage !== undefined && props.invalidMessage.length > 1) {
			error = <div className={styles.isNotUniqueText}>{props.invalidMessage}</div>;
		}
	}

	if (props.isLightning === true) {
		result = (
			<div className={styles.iconLightningResult}>
				<Lightning className={styles.iconResult} />
				<span className={styles.tooltip}>
					<span style={{ color: `var(--color-yellow-500)`, textAlign: "center" }}>
						Автоматизация
						<br />
					</span>
					Создание продажи из лида
				</span>
			</div>
		);
	}

	useEffect(() => {
		if (props.focus) ref.current!.focus();
	}, [props.focus]);

	useEffect(() => {
		if (props.isPassword) {
			setInputType("password");
		}
	}, []);

	function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
		props.onChangeValue(event.target.value);
	}

	function handleClick(event: React.MouseEvent<HTMLInputElement, MouseEvent>) {
		setFocusOut(false);
		setFocus(true);
		ref.current!.focus();
		if (props.onClick) props.onClick(event);
	}

	function handleFocus(event: React.FocusEvent<HTMLInputElement, Element>) {
		setFocusOut(false);
		setFocus(true);
	}

	function handleOnBlur() {
		if (props.onFocusOut) props.onFocusOut();
		if (!props.isInvalid || (props.value && props.value.length > 0)) {
			setFocusOut(true);
		}
		setFocus(false);
	}

	return (
		<div className={inputStyle.parentInput + " " + props.classNameParent}>
			<div className={wrapperClassNames}>
				<input
					ref={ref}
					id={props.id ?? "input"}
					name={props.name}
					type={inputType}
					className={inputClassNames}
					value={props.value ?? ""}
					onChange={handleChange}
					placeholder={props.placeholder}
					onBlur={handleOnBlur}
					autoComplete={props.autoComplete === true ? "on" : props.isPassword ? "new-password" : "off"}
					disabled={props.isDisabled}
					maxLength={props.maxLength}
					size={props.size!}
					style={props.style}
					onMouseOver={props.onMouseOver}
					onClick={handleClick}
					onFocus={handleFocus}
					onKeyUp={props.onKeyUp}
				/>
				{props.isPassword && props.value && (
					<div className={styles.eyeContainer}>
						{inputType === "password" ? (
							<EyeHidden
								className={styles.eye}
								onClick={() => {
									setInputType("text");
								}}
							/>
						) : (
							<EyeNotHidden
								className={styles.eye}
								onClick={() => {
									setInputType("password");
								}}
							/>
						)}
					</div>
				)}
				{icon ?? result}
			</div>
			{error}
		</div>
	);
}

export default Input;
