import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";

import { CheckboxDoneGreen } from "shared";
import { VerticalDivider } from "shared";
import { ErrorPainted } from "shared";

import styles from "./status-bar.module.css";

interface IStatusBarProps extends React.HTMLAttributes<HTMLElement> {
	isOpen: boolean;
	changeOpen?: (value: boolean) => void;
	eventName?: string;
	onClick?: () => void;
	path?: string;
	isError?: boolean;
	errorMessage?: string;
}

function StatusBar(props: IStatusBarProps) {
	const [isCopied, setCopied] = useState<boolean>(false);
	const refSetTimeout = useRef<NodeJS.Timeout>();

	useEffect(() => {
		if (props.isOpen) {
			refSetTimeout.current = setTimeout(() => {
				if (props.changeOpen) props.changeOpen(false);
			}, 4000);
		}
	}, [props.isOpen]);

	if (!props.isOpen) {
		return null;
	}

	let classNameCopyLink = `${styles.link} `;
	if (isCopied) classNameCopyLink += `${styles.copied} `;

	function copyHref() {
		setCopied(true);
		setTimeout(() => {
			setCopied(false);
		}, 4000);
		let href = window.location.href + "/" + props.path;
		if (navigator.clipboard)
			navigator.clipboard.writeText(href).catch((e) => {
				console.warn(e);
			});
		else {
			var textarea = document.createElement("textarea");
			textarea.textContent = href;
			textarea.style.position = "fixed";
			document.body.appendChild(textarea);
			textarea.select();
			try {
				document.execCommand("copy");
			} catch (ex) {
				console.warn("Copy to clipboard failed.", ex);
			} finally {
				document.body.removeChild(textarea);
			}
		}
	}

	let className = `${styles.statusBar} `;
	if (props.isError || props.errorMessage) {
		className += styles.errorStatusBar;
	} else if (props.path === undefined) {
		className += styles.withoutPath;
	}

	return (
		<div
			className={className}
			style={props.style}
			onMouseOver={() => {
				clearTimeout(refSetTimeout.current);
				if (props.changeOpen) props.changeOpen(true);
			}}
			onMouseLeave={() => {
				if (props.isOpen)
					refSetTimeout.current = setTimeout(() => {
						if (props.changeOpen) props.changeOpen(false);
					}, 4000);
			}}
		>
			{props.isError || props.errorMessage ? (
				<>
					<ErrorPainted />
					<div className={styles.errorInfo}>
						<span className={styles.errorTitle}>{props.eventName ?? "Ошибка"}</span>
						{props.errorMessage && <span className={styles.errorMessage}>{props.errorMessage}</span>}
					</div>
				</>
			) : (
				<>
					<div className={styles.leftBar}>
						<CheckboxDoneGreen />
						{props.eventName ?? "Запись создана"}
					</div>
					{props.path && (
						<>
							<VerticalDivider className={styles.verticalDevider} />
							<div className={styles.rightBar}>
								<NavLink className={styles.link} to={props.path}>
									Открыть
								</NavLink>
								<div className={styles.ellipse}></div>
								<div className={classNameCopyLink} onClick={copyHref}>
									{isCopied ? <>Ссылка скопирована!</> : <>Копировать ссылку</>}
								</div>
							</div>
						</>
					)}
				</>
			)}
		</div>
	);
}

export default StatusBar;
