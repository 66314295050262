import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { toJS } from "mobx";

import { dispatcher } from "store";
import { singlePageSynchroiser } from "pages/single-page/single-page-synchroiser/single-page-synchroiser";

import { Button, ButtonGroup, Switcher, ButtonDropdown } from "sale-bridge-ui-kit";

import ISort, { SortDirection } from "entities/ISort";
import { Column, ColumnSpecializationType } from "types/entity";
import { switchingElements } from "../constants";
import { ViewMode } from "pages/single-page/type";
import { StandartItemGroup } from "sale-bridge-ui-kit/dist/components/button-dropdown/button-dropdown";

import styles from "../single-page-head.module.scss";

export const SortButtonKanbanSwitcher = (props: {
	size: "small" | "medium" | "large";
	onSwitchViewMode: (value: ViewMode) => void;
	viewModeValue: ViewMode;
	setColumnTitle: React.Dispatch<React.SetStateAction<string>>;
	sort: React.RefObject<ISort>;
	columnTitle: string;
}) => {
	const { entityName } = useParams();
	const [searchValueForSort, setSearchValueForSort] = useState<string>("");

	const entity = useMemo(() => dispatcher.entity.get(), [dispatcher.entity.get()]);

	const columns = useMemo(() => entity?.entity.columns, [toJS(entity?.entity.columns)]);

	/**
	 * @description для отрисовки сортировки из LocalStorage
	 */
	useEffect(() => {
		const bufferedColumnPath = entity?.entity.sort?.columnPath.split(".")[0];
		const bufferedSortColumn = columns?.find((column) => column.columnName.toLowerCase() === bufferedColumnPath?.toLowerCase());
		if (bufferedSortColumn) {
			handleChangeSortColumn(bufferedSortColumn);
		}
	}, []);

	const sortingColumns = useMemo((): Array<StandartItemGroup> => {
		const items: Array<StandartItemGroup> = [
			{
				header: "",
				items: [
					{
						id: "handSort",
						name: "Ручная сортировка"
					}
				]
			}
		];
		if (columns) {
			columns
				.filter((column) => column.columnTitle.toLowerCase().includes(searchValueForSort.toLowerCase()))
				.forEach((column) => {
					return items[0].items.push({
						id: column.columnId,
						name: column.columnTitle
					});
				});
		}
		return items;
	}, [toJS(columns), searchValueForSort]);

	const handleChangeSortColumn = useCallback(
		(column: Column) => {
			props.setColumnTitle(column.columnTitle);
			if (props.sort.current) {
				if (
					column.specializations?.tag === ColumnSpecializationType.KanbanField &&
					column?.specializations.properties["priorityField"] === "true"
				) {
					props.sort.current.columnPath = column.columnName + "." + "Order";
				} else {
					props.sort.current.columnPath = column.columnName;
				}

				const sort = {
					columnPath: props.sort.current.columnPath,
					direction: props.sort.current.direction
				};
				dispatcher.entity.setSort(sort);
				singlePageSynchroiser.setSortInLocalStorage(sort, entity?.entityName);
			}

			singlePageSynchroiser.applyFilter(entityName ?? "");
		},
		[entityName, entity, props]
	);

	const handleSortingColumnClick = useCallback(
		(id: string) => {
			setSearchValueForSort("");
			if (id === "handSort") {
				props.setColumnTitle("");
				dispatcher.entity.setSort(null);
				singlePageSynchroiser.setSortInLocalStorage(null, entity?.entityName);
				singlePageSynchroiser.applyFilter(entityName ?? "");
			} else {
				const findColumn = columns?.find((column) => column.columnId === id);
				if (findColumn) {
					handleChangeSortColumn(findColumn);
				}
			}
		},
		[props, toJS(columns), entity]
	);

	const handleChangeSortDirection = useCallback(() => {
		if (props.sort.current) {
			if (props.sort.current.direction === SortDirection.Ascending) {
				props.sort.current.direction = SortDirection.Descending;
			} else {
				props.sort.current.direction = SortDirection.Ascending;
			}

			const sort = {
				columnPath: props.sort.current.columnPath,
				direction: props.sort.current.direction
			};

			dispatcher.entity.setSort(sort);
			singlePageSynchroiser.setSortInLocalStorage(sort, dispatcher.entity.get()?.entityName);
		}
		singlePageSynchroiser.applyFilter(entityName ?? "");
		setSearchValueForSort("");
	}, [entityName]);

	const handleSwitch = useCallback(
		(value: ViewMode) => {
			props.onSwitchViewMode(value);
			setSearchValueForSort("");
		},
		[props]
	);

	return (
		<div className={styles.rightBlock}>
			<div className={styles.block}>
				{props.viewModeValue === ViewMode.KANBAN &&
					(props.columnTitle && Object.keys(dispatcher.entity.get()?.entity.sort || {}).length > 0 ? (
						<ButtonGroup>
							<Button
								text=""
								size={props.size}
								variant="secondary"
								border={false}
								leftIcon={
									props.sort.current && props.sort.current.direction === SortDirection.Ascending
										? "Ascending"
										: "Descending"
								}
								link={false}
								loading={false}
								onClick={handleChangeSortDirection}
							/>
							<ButtonDropdown
								size={props.size}
								position="right-down"
								standartItems={sortingColumns}
								onClickStandartItem={handleSortingColumnClick}
								hasSearch
								searchValue={searchValueForSort}
								onChangeSearchValue={(value: string) => setSearchValueForSort(value)}
								childrenButton={
									<Button
										text={props.columnTitle}
										size={props.size}
										variant="secondary"
										border={false}
										link={false}
										loading={false}
										onClick={() => {}}
									/>
								}
							/>
						</ButtonGroup>
					) : (
						<ButtonDropdown
							size="small"
							position="down"
							standartItems={sortingColumns}
							onClickStandartItem={handleSortingColumnClick}
							hasSearch
							searchValue={searchValueForSort}
							onChangeSearchValue={(value: string) => setSearchValueForSort(value)}
							childrenButton={
								<Button
									text="Сортировка"
									size={props.size}
									variant="default"
									border
									leftIcon="ArrowDownUp"
									link={false}
									loading={false}
									onClick={() => {}}
								/>
							}
						/>
					))}
				<Switcher
					size={props.size}
					value={props.viewModeValue}
					onSwitch={handleSwitch as (value: string) => void}
					elements={switchingElements}
				/>
			</div>
		</div>
	);
};
