import { useMemo } from 'react';
import { toJS } from 'mobx';

import { store } from 'store';

import { StandartItem, StandartItemGroup } from 'sale-bridge-ui-kit/dist/components/button-dropdown/button-dropdown';


export const FavoriteFiltersItems = (props: { searchValueForFavoriteFilters: string }) => {
    const favoriteFiltersItems = useMemo((): Array<StandartItemGroup> => {
        if (store.favoriteFilters) {
            const filters: Array<StandartItem> = [];
            store.favoriteFilters.filter(filter => filter.filterName.toLowerCase().includes(props.searchValueForFavoriteFilters.toLowerCase()))
                .forEach((favoriteFilter, i) => {
                    return filters.push({
                        id: favoriteFilter.id,
                        name: favoriteFilter.filterName ?? '',
                    })
                })
            return [
                {
                    header: '',
                    items: filters
                }
            ];
        } else {
            return [
                {
                    header: '',
                    items: []
                }
            ];
        }
    }, [toJS(store.favoriteFilters), props.searchValueForFavoriteFilters]);

    return { favoriteFiltersItems };
};