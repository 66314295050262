import { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { v4 } from "uuid";

import { dispatcher } from "store";

import { modalController } from "features/modals";
import NewRecordItem from "./new-record-item/new-record-item";

import { Button, ButtonStyle, DataGrid, Toggle } from "components";
import FilterColumnType from "entities/ColumnType";
import { RecordOperation, RecordRightLevel } from "types/entity";

import styles from "../access-rights.module.css";
import { toJS } from "mobx";

type RecordGridItems = {
    id: string;
    authorName: string | null;
    granteeName: string | null;
    canRead: boolean;
    canEdit: boolean;
    canDelete: boolean;
}

const AccessRightsAdminRecords = observer(() => {
    const [idModal] = useState<string>(v4());

    const sectionWizzard = useMemo(
        () => {
            return dispatcher.entity.get()?.entity.sectionWizzard;
        }, [dispatcher.entity.get()?.entity.sectionWizzard]
    );

    const adminByRecords = useMemo(() => {
        return sectionWizzard?.accessRightsConfig.adminByRecords;
    }, [dispatcher.entity.get()?.entity.sectionWizzard?.accessRightsConfig.adminByRecords]);

    const recordItems = useMemo(() => {
        
        let filteredData: RecordGridItems[] = [];
        const dispatcherRecordItems = dispatcher.entity.get()?.entity.sectionWizzard?.accessRightsConfig.adminByRecords.recordItems;

        if (dispatcherRecordItems) {
            const groupRecordsIds = dispatcherRecordItems.reduce<string[]>((resultArray, item) => {
                if (!resultArray.find(v => v === item.groupRecordsId)) {
                    resultArray.push(item.groupRecordsId);
                }
                return resultArray;
            }, []);

            filteredData = groupRecordsIds.reduce<RecordGridItems[]>((resultArray, recordGridItem) => {
                const data = dispatcherRecordItems.filter(item => item.groupRecordsId === recordGridItem);

                const recordOperationRead = data.find(item => item.operation == RecordOperation.Read);
                const recordOperationEdit = data.find(item => item.operation == RecordOperation.Edit);
                const recordOperationDelete = data.find(item => item.operation == RecordOperation.Delete);

                resultArray.push({
                    id: recordGridItem,
                    authorName: data[0].authorName,
                    granteeName: data[0].granteeName,
                    canRead: recordOperationRead ? recordOperationRead.rightLevel == RecordRightLevel.Granted : false,
                    canEdit: recordOperationEdit ? recordOperationEdit.rightLevel == RecordRightLevel.Granted : false,
                    canDelete: recordOperationDelete ? recordOperationDelete.rightLevel == RecordRightLevel.Granted : false,
                });
                return resultArray;
            }, []);
        };

        return filteredData;
    }, [toJS(dispatcher.entity.get()?.entity.sectionWizzard?.accessRightsConfig.adminByRecords.recordItems)]);

    const columnsOperation = useMemo(() => [
        {
            name: "authorName",
            caption: "Автор записи",
            width: 20,
            type: FilterColumnType.String,
            sortColumn: "authorName",
        },
        {
            name: "granteeName",
            caption: "Получатель права",
            width: 30,
            type: FilterColumnType.String,
            sortColumn: "granteeName",
        },
        {
            name: "canRead",
            caption: "Чтение",
            width: 20,
            type: FilterColumnType.Boolean,
            sortColumn: "canRead",
            check: true,
            onChecked: (entityId: any, checked: boolean, id: string) => { dispatcher.sectionWizzard.setRecordActions(id, RecordOperation.Read, checked); }
        },
        {
            name: "canEdit",
            caption: "Редактирование",
            width: 20,
            type: FilterColumnType.Boolean,
            sortColumn: "canEdit",
            check: true,
            onChecked: (entityId: any, checked: boolean, id: string) => { dispatcher.sectionWizzard.setRecordActions(id, RecordOperation.Edit, checked); }
        },
        {
            name: "canDelete",
            caption: "Удаление",
            width: 10,
            type: FilterColumnType.Boolean,
            sortColumn: "canDelete",
            check: true,
            onChecked: (entityId: any, checked: boolean, id: string) => { dispatcher.sectionWizzard.setRecordActions(id, RecordOperation.Delete, checked); }
        }
    ], [toJS(recordItems)]);

    const handleEnableRecords = useCallback((value: boolean) => {
        dispatcher.sectionWizzard.setEnableAdminByRecords(value);
    }, []);

    const closeFuncWithOutConfirm = useCallback(() => {
        modalController.modalRemove(idModal);
    }, []);

    const handleAddClick = useCallback(() => {
        modalController.popupAdd({
            id: idModal,
            layout: <NewRecordItem close={closeFuncWithOutConfirm} />,
            closeFunc: closeFuncWithOutConfirm
        });
    }, []);

    return (
        <div className={styles.accessRightsBlock}>
            <div className={styles.toogleHeader}>
                <Toggle checked={adminByRecords?.isEnabled ?? false} onCheck={handleEnableRecords} />
                <span>Использовать доступ по записям</span>
            </div>
            <span className={styles.recordTitle}>Раздача прав в зависимости от автора записи</span>
            <div className={styles.grid}>
                <DataGrid columns={columnsOperation} otherData={recordItems} />
            </div>

            <Button style={ButtonStyle.Indigo} className={styles.addButton} onClick={handleAddClick} caption="Добавить" />
        </div>
    );
});

export default AccessRightsAdminRecords;
