import { useCallback } from "react";

import { Button } from "sale-bridge-ui-kit";

import { CloseMaxi } from "shared";

import styles from "./warning-popups.module.scss";

export const ResetModal = (props: { closeConfirm: () => void; onReset: () => void }) => {
	const handleReset = useCallback(() => {
		props.onReset();
		props.closeConfirm();
	}, [props]);

	return (
		<>
			<div className={styles.modalHeader}>
				<span>Сбросить настройки?</span>
				<CloseMaxi className={styles.modalCloseButton} onClick={props.closeConfirm} />
			</div>
			<div className={styles.modalBody}>
				<span>Останется один параметр нумерации: [Порядковый номер], заполненный по умолчанию.</span>
			</div>
			<div className={styles.modalFooter}>
				<Button text="Сбросить" size="small" variant="default" border link={false} loading={false} onClick={handleReset} />
				<Button text="Вернуться" size="small" variant="primary" border link={false} loading={false} onClick={props.closeConfirm} />
			</div>
		</>
	);
};

export const CancelModal = (props: { closeConfirm: () => void; onCancel: () => void }) => {
	const handleCancel = useCallback(() => {
		props.onCancel();
		props.closeConfirm();
	}, [props]);

	return (
		<>
			<div className={styles.modalHeader}>
				<span>Выйти без сохранения?</span>
				<CloseMaxi className={styles.modalCloseButton} onClick={props.closeConfirm} />
			</div>
			<div className={styles.modalBody}>
				<span>Все несохранённые изменения будут утеряны.</span>
			</div>
			<div className={styles.modalFooter}>
				<Button text="Выйти" size="small" variant="default" border link={false} loading={false} onClick={handleCancel} />
				<Button text="Вернуться" size="small" variant="primary" border link={false} loading={false} onClick={props.closeConfirm} />
			</div>
		</>
	);
};

export const SaveWithChangesModal = (props: { closeConfirm: () => void; onSave: () => void }) => {
	const handleSave = useCallback(() => {
		props.onSave();
		props.closeConfirm();
	}, [props]);

	return (
		<>
			<div className={styles.modalHeader}>
				<span>Внимание</span>
				<CloseMaxi className={styles.modalCloseButton} onClick={props.closeConfirm} />
			</div>
			<div className={styles.modalBody}>
				<span>В поле [Нумератор] внесены изменения — это значит, что после сохранения раздела нумерация начнётся заново.</span>
				<p>Сохранить настройки поля?</p>
			</div>
			<div className={styles.modalFooter}>
				<Button text="Отменить" size="small" variant="default" border link={false} loading={false} onClick={props.closeConfirm} />
				<Button text="Сохранить" size="small" variant="primary" border link={false} loading={false} onClick={handleSave} />
			</div>
		</>
	);
};
