import { useCallback } from "react";

import { Button } from "sale-bridge-ui-kit";

import { CloseMaxi } from "shared";

import styles from "./popup.module.scss";

export const DeleteCommentPopup = (props: {
	onDelete: () => void;
	closeConfirm: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}) => {
	const handleDeleteComment = useCallback(() => {
		props.onDelete();
		props.closeConfirm();
	}, [props]);

	return (
		<>
			<div className={styles.headerModal}>
				<span className={styles.titleModal}>Удалить комментарий?</span>
				<CloseMaxi className={styles.closeButton} onClick={() => props.closeConfirm()} />
			</div>
			<div className={styles.dialogBody}>
				<span className={styles.warningTitle}>Это действие невозможно отменить.</span>
			</div>
			<div className={styles.dialogFooter}>
				<Button size="small" onClick={handleDeleteComment} text="Удалить" variant="default" border link={false} loading={false} />
				<Button size="small" onClick={props.closeConfirm} text="Назад" variant="primary" link={false} loading={false} border />
			</div>
		</>
	);
};
