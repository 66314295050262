import { toJS } from "mobx";
import { observer } from "mobx-react";
import { useCallback, useEffect, useMemo, useState } from "react";

import { dispatcher } from "store";

import { ButtonStyle } from "components";
import { FieldSelect } from "pages/section-wizzard/components";
import { Checkbox, Hint } from "sale-bridge-ui-kit";

import { IFormat } from "entities/IItem";
import { StageFullInfo } from "types/entity";

import styles from "../settings-panel.module.scss";

export const MovingRules = observer((props: { allStages: StageFullInfo[], idStage: string }) => {
    const ALL_STAGES = 'allStages';
    const [allStages, setAllStages] = useState<StageFullInfo[]>(props.allStages);

    const [stagesToItems, setStagesToItems] = useState<IFormat[]>([]);
    const [stagesFromItems, setStagesFromItems] = useState<IFormat[]>([]);

    const [isFromCheckedAll, setFromCheckedAll] = useState<boolean>(false);
    const [isToCheckedAll, setToCheckedAll] = useState<boolean>(false);
    const [isFromPratly, setFromPratly] = useState<boolean>(false);
    const [isToPratly, setToPratly] = useState<boolean>(false);

    useEffect(() => {
        setAllStages(props.allStages);
    }, [props.allStages]);

    const currentStage = useMemo(() => {
        return allStages.find((stage: StageFullInfo) => stage.id === props.idStage)
    }, [toJS(allStages), props.idStage]);

    const checkAllCheckbox = useCallback((list: IFormat[]) => {
        let checkedAll = true;
        list.forEach(item => {
            if (item.id !== ALL_STAGES) {
                if (!item.checked) {
                    checkedAll = false;
                }
            }
        });
        return checkedAll;
    }, []);

    const setActionAllCheckbox = useCallback((stageType: 'to' | 'from', listItems: IFormat[]) => {
        if (listItems.length <= 1) {
            listItems[0].checked = false;
            setFromPratly(false);
            setFromCheckedAll(false);
            setToPratly(false);
            setToCheckedAll(false);
            return;
        }
        const checkAll = checkAllCheckbox(listItems);
        if (checkAll) {
            listItems[0].checked = true;
            if (stageType === 'from') {
                if (isFromPratly && isFromCheckedAll) {
                    setFromPratly(false);
                }

            } else if (isToPratly && isToCheckedAll) {
                listItems[0].checked = true;
                setToPratly(false);
            }
        } else {
            if (stageType === 'from' && isFromPratly && isFromCheckedAll) {
                listItems[0].checked = true;
            }
            if (stageType === 'to' && isToPratly && isToCheckedAll) {
                listItems[0].checked = true;
            }
        }
        return listItems;
    }, [isFromPratly, isFromCheckedAll, isToPratly, isToCheckedAll]);

    const generateCheckedStages = useCallback((stageType: 'to' | 'from') => {
        const listItems: IFormat[] = [];
        const stagesWithoutCurrent = allStages.filter((stage) => stage.id !== currentStage?.id);
        const allStagesItem = {
            id: ALL_STAGES,
            name: 'Все стадии',
            checked: false
        }
        listItems.push(allStagesItem);
        stagesWithoutCurrent.forEach(stage => {
            let isChecked = false;
            if (stageType === 'to') {
                if (currentStage?.stagesTo?.includes(stage.id)) {
                    isChecked = true;
                }
            }
            else if (stageType === 'from') {
                if (currentStage?.stagesFrom?.includes(stage.id)) {
                    isChecked = true;
                }
            }
            listItems.push({
                id: stage.id,
                name: stage.name,
                checked: isChecked
            })
        });
        setActionAllCheckbox(stageType, listItems);
        return listItems;
    }, [checkAllCheckbox, toJS(allStages), currentStage]);

    const setCheckedAll = useCallback((stageType: 'to' | 'from', checked: boolean) => {
        if (stageType === 'to') {
            const findedAllStages = stagesToItems[0];
            stagesToItems.forEach(stage => stage.checked = checked ?? !findedAllStages?.checked);
            if (checked) {
                setToCheckedAll(true);
                stagesToItems.forEach(stage => {
                    if (stage.id !== ALL_STAGES) {
                        dispatcher.stageModel.addMoveRule(currentStage!.id, stage.id as string);
                    }
                })
            } else {
                setToCheckedAll(false);
                currentStage?.stagesTo.forEach(stage => {
                    dispatcher.stageModel.deleteRuleById(currentStage.id, stage);
                })
            }

        } else if (stageType === 'from') {
            const findedAllStages = stagesFromItems[0];
            stagesFromItems.forEach(stage => stage.checked = checked ?? !findedAllStages?.checked);
            if (checked) {
                setFromCheckedAll(true);
                stagesFromItems.forEach(stage => {
                    if (stage.id !== ALL_STAGES) {
                        dispatcher.stageModel.addMoveRule(stage.id as string, currentStage!.id);
                    }
                })
            } else {
                setFromCheckedAll(false);
                currentStage?.stagesFrom.forEach(stage => {
                    dispatcher.stageModel.deleteRuleById(stage, currentStage.id);
                })
            }

        }
    }, [toJS(stagesToItems), toJS(stagesFromItems), toJS(currentStage?.stagesTo), toJS(currentStage?.stagesFrom)]);


    const changeCheckedStageByType = useCallback((stageType: 'to' | 'from', checked: boolean | undefined, stageId: string | null) => {
        if (stageType === 'to') {
            const index = stagesToItems.findIndex(stage => stage.id === stageId);
            if (index !== -1) {
                stagesToItems[index].checked = checked ?? false;
            }
        }
        else if (stageType === 'from') {
            const index = stagesFromItems.findIndex(stage => stage.id === stageId);
            if (index !== -1) {
                stagesFromItems[index].checked = checked ?? false;
            }
        }
    }, [toJS(stagesToItems), toJS(stagesFromItems)]);

    const handleStagesToSelect = useCallback((stageId: string, checkedValue?: boolean | undefined) => {
        if (currentStage) {
            if (stageId === ALL_STAGES) {
                if (isToPratly && isToCheckedAll) {
                    setCheckedAll('to', true);
                } else {
                    setCheckedAll('to', checkedValue ?? false);
                }
                setToPratly(false);
            }
            else {
                if (!checkedValue) {
                    dispatcher.stageModel.deleteRuleById(currentStage.id, stageId);
                }
                else {
                    dispatcher.stageModel.addMoveRule(currentStage.id, stageId);
                }
                changeCheckedStageByType('to', checkedValue, stageId);
                if (checkAllCheckbox(stagesToItems)) {
                    stagesToItems[0].checked = true;
                    setToPratly(false);
                    setToCheckedAll(true);
                } else {
                    setToPratly(true);
                    const newValues = stagesToItems.filter(stage => stage.checked);
                    newValues.forEach(value => {
                        dispatcher.stageModel.addMoveRule(currentStage.id, value.id as string);
                    })
                }
            }
        }
    }, [currentStage?.id, toJS(currentStage?.stagesTo), toJS(stagesToItems), isToCheckedAll]);

    const handleStagesFromSelect = useCallback((stageId: string, checkedValue?: boolean | undefined) => {
        if (currentStage) {
            if (stageId === ALL_STAGES) {
                if (isFromPratly && isFromCheckedAll) {
                    setCheckedAll('from', true);
                } else {
                    setCheckedAll('from', checkedValue ?? false);
                }
                setFromPratly(false);
            }
            else {
                if (!checkedValue) {
                    dispatcher.stageModel.deleteRuleById(stageId, currentStage.id);
                }
                else {
                    dispatcher.stageModel.addMoveRule(stageId, currentStage.id);
                }
                changeCheckedStageByType('from', checkedValue, stageId);
                if (checkAllCheckbox(stagesFromItems)) {
                    stagesFromItems[0].checked = true;
                    setFromPratly(false);
                    setFromCheckedAll(true);
                } else {
                    setFromPratly(true);
                    const newValues = stagesFromItems.filter(stage => stage.checked);
                    newValues.forEach(value => {
                        dispatcher.stageModel.addMoveRule(value.id as string, currentStage.id);
                    })
                }
            }
        }
    }, [currentStage?.id, toJS(currentStage?.stagesFrom), toJS(stagesFromItems), isFromCheckedAll, isFromPratly]);

    const updateValues = useCallback(() => {
        const generatingStagesTo = generateCheckedStages('to');
        const generatingStagesFrom = generateCheckedStages('from');
        setStagesToItems(generatingStagesTo);
        setStagesFromItems(generatingStagesFrom);
    }, [toJS(allStages), currentStage]);

    useEffect(() => {
        if (currentStage) {
            updateValues();
        }
    }, [props.idStage, currentStage]);

    const stageSettings = useMemo(() => {
        return dispatcher.entity.get()?.entity.sectionWizzard?.stageModelConfig?.stageSettings;
    }, [dispatcher.entity.get()?.entity.sectionWizzard?.stageModelConfig?.stageSettings]);


    const getStageStyle = (stageId: string): React.CSSProperties => {
        const stageSetting = stageSettings?.find(setting => setting.stageId === stageId);
        return stageSetting && stageSetting.isHidden ? { opacity: 0.5 } : {};
    };

    const getIsHidden = (stageId: string): boolean => {
        const stageSetting = stageSettings?.find(setting => setting.stageId === stageId);
        return stageSetting?.isHidden ?? false
    };

    return (<>
        <FieldSelect
            name='С каких стадий можно переходить'
            buttonStyle={ButtonStyle.Settings}
            className={styles.fieldSelectInMovingRules}
        >
            <div className={styles.movingRuleContainer}>
                {stagesFromItems.map(stage => {
                    const style = getStageStyle(stage.id ?? '');
                    const isHidden = getIsHidden(stage.id ?? '')
                    return (
                        <Hint
                            key={stage.id}
                            hintBody="Стадия скрыта"
                            startPosition="left"
                            isHintDisplayed={isHidden}>

                            <div key={stage.id} style={style}>
                                <Checkbox
                                    size="small"
                                    value={Boolean(stage.checked)}
                                    onChangeChecked={(value: boolean) => handleStagesFromSelect(stage.id!, value)}
                                    title={stage.name}
                                    pratly={(stage.id === ALL_STAGES && isFromPratly) ?? false}
                                />
                                {(stage.id === ALL_STAGES && stagesFromItems.length > 1) && <div className={styles.movingRuleDivider} />}
                            </div>
                        </Hint>
                    );

                })}
            </div>
        </FieldSelect>

        <FieldSelect
            name='На какие стадии можно переходить'
            buttonStyle={ButtonStyle.Settings}
            className={styles.fieldSelectInMovingRules}
        >
            <div className={styles.movingRuleContainer}>
                {stagesToItems.map(stage => {
                    const style = getStageStyle(stage.id ?? '');
                    const isHidden = getIsHidden(stage.id ?? '')
                    return (
                        <Hint
                            key={stage.id}
                            hintBody="Стадия скрыта"
                            startPosition="left"
                            isHintDisplayed={isHidden}>

                            <div key={stage.id} style={style}>
                                <Checkbox
                                    size="small"
                                    value={Boolean(stage.checked)}
                                    onChangeChecked={(value: boolean) => handleStagesToSelect(stage.id!, value)}
                                    title={stage.name}
                                    pratly={(stage.id === ALL_STAGES && isToPratly) ?? false}
                                />
                                {(stage.id === ALL_STAGES && stagesToItems.length > 1) && <div className={styles.movingRuleDivider} />}
                            </div>
                        </Hint>
                    );

                })}
            </div>
        </FieldSelect>
    </>)
});