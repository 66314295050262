import Column from "../column/deduplication-rule-column";
import { ICustomColumn } from "components";

import importStore from "pages/import/core/import-store";

const columns: ICustomColumn[] = [
    {
        name: "columnName",
        caption: "",
        width: "48px",
        onChange: (value: any) => {
            if (value.value) {
                importStore.result.upsertSettings.addKey(value.column);
            }
            else {
                importStore.result.upsertSettings.deleteKey(value.column);
            }
        },
        render: ((column: ICustomColumn, value: any) => {
            return <Column column={column} value={value} />
        }),
    },
    {
        name: "columnTitle",
        caption: "Колонки"
    }

];

export { columns }