import { observer } from "mobx-react-lite";
import { Field } from "sale-bridge-ui-kit";

export type Props = {
	columnName: string | undefined;
	columnTitle?: string | undefined;
	prompt?: string | undefined;
	isRequired?: boolean | undefined;
	rounding?: string | undefined;
	value: string;
	onChange: (value: string | null) => void;
	variant: 'standart' | 'outlined'
	labelPosition?: 'horizontal' | 'vertical';
	labelVariant?: 'black' | 'gray';
	isDisabled?: boolean;
}

export const DateTime = observer(function (props: Props) {
	return (
		<Field
			type='dateTime'
			size='small'
			label={props.columnTitle ?? ""}
			labelPosition={props.labelPosition ?? "vertical"}
			labelVariant={props.labelVariant}
			isRequired={props.isRequired}
			value={props.value ?? ""}
			info={props.prompt}
			onChange={props.onChange}
			textVariant={props.variant}
			isDisabled={props.isDisabled}
		/>
	);
});
