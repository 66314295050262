import { v4 } from "uuid";

import { modalController } from "features/modals";
import { dispatcher } from "store";

import { Button } from "sale-bridge-ui-kit";
import { BusinessRuleInfoPopup } from "../business-rule-info-popup";

import { BusinessRule } from "types/entity";
import { IContextMenuOption } from "components/grid/data/data";

import { CloseMaxi } from "shared";

import styles from "./rules-context-menu.module.scss";

type Props = {
	businessRules: BusinessRule[];
	checkedIds: string[];
	changeOrder: () => void;
	deleteRules: () => void;
	onClickToEditRule: () => void;
};

export function RulesContextMenu(props: Props): IContextMenuOption[] {
	const { businessRules, checkedIds, deleteRules } = props;
	const modalId = v4();

	function handleClose() {
		modalController.modalRemove(modalId);
	}

	function updateRuleInfo(newName: string, newComment: string) {
		if (businessRules.length > 0 && checkedIds.length === 1) {
			const rule = businessRules.find((rule) => checkedIds[0] === rule.id);
			if (rule) {
				const updated: BusinessRule = {
					...rule,
					ruleName: newName,
					ruleDescription: newComment
				};
				dispatcher.sectionWizzard.updateBusinessRule(updated);
				handleClose();
			}
		}
	}

	function handleInfoActionClick() {
		if (businessRules.length > 0 && checkedIds.length === 1) {
			const rule = businessRules.find((rule) => checkedIds[0] === rule.id);
			if (rule) {
				modalController.popupAdd({
					id: modalId,
					layout: (
						<BusinessRuleInfoPopup
							name={rule.ruleName}
							systemName={rule.ruleName}
							comment={rule.ruleDescription}
							save={updateRuleInfo}
							close={handleClose}
						/>
					),
					closeFunc: handleClose
				});
			}
		}
	}

	function handleChangeEnable(value: boolean) {
		if (businessRules.length > 0 && checkedIds.length === 1) {
			const finded = businessRules.find((rule) => rule.id === checkedIds[0]);
			if (finded) {
				dispatcher.sectionWizzard.updateBusinessRule({ ...finded, enabled: value });
			}
		}
	}

	const deleteLayoutPopup = (
		<div className={styles.dialog}>
			<div className={styles.header}>
				<span className={styles.title}>{`Удалить правил${checkedIds.length > 1 ? "а" : "о"}?`}</span>
				<CloseMaxi className={styles.closeButton} onClick={handleClose} />
			</div>
			<div className={styles.dialogFooter}>
				<Button text="Отменить" size="small" variant="default" border link={false} loading={false} onClick={handleClose} />
				<Button
					text="Удалить"
					size="small"
					variant="danger"
					border
					link={false}
					loading={false}
					onClick={() => {
						deleteRules();
						handleClose();
					}}
				/>
			</div>
		</div>
	);

	function handleDeleteActionClick() {
		if (businessRules.length > 0) {
			modalController.popupAdd({
				id: modalId,
				layout: deleteLayoutPopup,
				closeFunc: handleClose
			});
		}
	}

	function contextMenuOptionsWhenOneChecked(): IContextMenuOption[] {
		let enableAction: IContextMenuOption = {
			caption: "Включить",
			iconName: "ToggleOn",
			size: "16",
			action: () => {
				handleChangeEnable(true);
			},
			isDivider: false
		};
		if (businessRules.length > 0 && checkedIds.length === 1) {
			const finded = businessRules.find((rule) => rule.id === checkedIds[0]);
			if (finded) {
				enableAction = {
					...enableAction,
					caption: finded.enabled ? "Отключить" : "Включить",
					iconName: finded.enabled ? "ToggleOff" : "ToggleOn",
					action: () => {
						handleChangeEnable(finded.enabled ? false : true);
					}
				};
			}
		}
		return [
			{
				caption: "Редактировать",
				iconName: "Pencil",
				size: "16",
				action: () => {
					props.onClickToEditRule();
				},
				isDivider: false
			},
			enableAction,
			{
				caption: "Изменить порядок",
				iconName: "Integer",
				size: "small",
				action: () => {
					props.changeOrder();
				},
				isDivider: false
			},
			{
				caption: "Посмотреть сведения",
				iconName: "Info",
				size: "small",
				action: () => {
					handleInfoActionClick();
				},
				isDivider: true
			},
			{
				caption: "Удалить",
				iconName: "Trash",
				size: "16",
				action: () => {
					handleDeleteActionClick();
				},
				isDivider: false
			}
		];
	}

	function contextMenuOptionsWhenSomeChecked(): IContextMenuOption[] {
		return [
			{
				caption: "Изменить порядок",
				iconName: "Integer",
				size: "small",
				action: () => {
					props.changeOrder();
				},
				isDivider: true
			},
			{
				caption: "Удалить",
				iconName: "Trash",
				size: "16",
				action: () => {
					handleDeleteActionClick();
				},
				isDivider: false
			}
		];
	}

	if (checkedIds.length > 1) {
		return contextMenuOptionsWhenSomeChecked();
	}
	return contextMenuOptionsWhenOneChecked();
}
