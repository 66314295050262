import { useCallback } from "react";

import { Button, ButtonStyle } from "components";

import { Warning } from "shared";

import styles from "pages/section-wizzard/components/tabs-with-pagination/tabs-with-pagination.module.scss";

export function ConfirmModal(props: { close: () => void; delete: () => void }) {
	const WARNING_TEXT = "Вы действительно хотите удалить стадию?\n Отменить это действие будет невозможно";

	return (
		<div className={styles.warningDialog}>
			<div className={styles.warningHeader}>
				<span className={styles.warningTitle}>Внимание</span>
				<Warning />
			</div>
			<div className={styles.warningDialogBody}>
				<span className={styles.title}>{WARNING_TEXT}</span>
			</div>
			<div className={styles.dialogFooter}>
				<Button caption="Вернуться к редактированию" onClick={props.close} style={ButtonStyle.Subtle} isDisabled={false} />
				<Button caption="Удалить" onClick={props.delete} style={ButtonStyle.Danger} isDisabled={false} />
			</div>
		</div>
	);
}
