import { v4 } from "uuid";

import { ColumnType } from "entities/ColumnType";
import { VisibleColumn } from "types/entity";

export const infoBusinessRules =
	"Бизнес-правила — логика на странице редактирования записи. Бизнес-правила изменяют свойства и заполнение полей на странице. Для работы бизнес-правила необходимо настроить как минимум одно условие и одно действие.";

export const standartBusinessRulesColumns: VisibleColumn[] = [
	{
		customerColumnTitle: "",
		indexX: 1,
		indexY: 0,
		spanX: 100,
		spanY: 0,
		columnId: v4(),
		columnName: "RuleName",
		columnTitle: "Название",
		columnType: ColumnType.String,
		hasIndex: false,
		isLookup: false,
		isLink: false,
		isRequired: false,
		uneditable: false,
		isViewColumn: true,
		lookupTable: null
	},
	{
		customerColumnTitle: "",
		indexX: 2,
		indexY: 0,
		spanX: 50,
		spanY: 0,
		columnId: v4(),
		columnName: "Enabled",
		columnTitle: "Активно",
		columnType: ColumnType.Boolean,
		hasIndex: false,
		isLookup: false,
		isLink: false,
		isRequired: false,
		uneditable: false,
		isViewColumn: false,
		lookupTable: null
	},
	{
		customerColumnTitle: "",
		indexX: 3,
		indexY: 0,
		spanX: 100,
		spanY: 0,
		columnId: v4(),
		columnName: "CreatedOn",
		columnTitle: "Дата создания",
		columnType: ColumnType.Date,
		hasIndex: false,
		isLookup: false,
		isLink: false,
		isRequired: false,
		uneditable: false,
		isViewColumn: false,
		lookupTable: null
	},
	{
		customerColumnTitle: "",
		indexX: 4,
		indexY: 0,
		spanX: 100,
		spanY: 0,
		columnId: v4(),
		columnName: "CreatedById",
		columnTitle: "Автор",
		columnType: ColumnType.String,
		hasIndex: false,
		isLookup: false,
		isLink: false,
		isRequired: false,
		uneditable: false,
		isViewColumn: false,
		lookupTable: null
	},
	{
		customerColumnTitle: "",
		indexX: 5,
		indexY: 0,
		spanX: 100,
		spanY: 0,
		columnId: v4(),
		columnName: "ModifiedById",
		columnTitle: "Кто изменил",
		columnType: ColumnType.String,
		hasIndex: false,
		isLookup: false,
		isLink: false,
		isRequired: false,
		uneditable: false,
		isViewColumn: false,
		lookupTable: null
	}
];
