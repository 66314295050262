import styles from "./entity-button.module.css";

interface IEntityButton{
    icon:JSX.Element;
    title: string;
    onClick:()=>void;
    selected?: boolean;
}

function EntityButton(props:IEntityButton){
    let className = styles.body;
    if(props.selected){
        className += " " + styles.selected;
    }

    return(
        <div onClick={props.onClick} className={className}>
            {props.icon}
            <span>{props.title}</span>
        </div>
    )
}

export default EntityButton;