import { useCallback, useEffect, useState, useRef } from "react";
import { observer } from "mobx-react";

import { Entity } from "store/store";
import { synchroiser } from "synchroiser";
import { dispatcher } from "store";

import SavedFilter from "entities/filter/SavedFilter";

import { Button, ButtonStyle, Dropdown, SearchSelect } from "components";

import { Item } from "types";

import { ArrowToDownMini } from "shared";

import styles from "features/section-head/section-head.module.css";

const SectionHeadFooter = observer(function (props: { entity: Entity | undefined }) {
	const [items, setItems] = useState<Item[]>([]);
	const constItems = useRef<Item[]>([]);

	const hendleItemsLoad = useCallback(
		async (searchValue: string | null) => {
			if (searchValue === null || searchValue.length === 0) {
				await getItems();
				return constItems.current.length;
			}
			const newItems = constItems.current.filter((x) => x.name?.includes(searchValue));

			setItems(newItems);
			return newItems.length;
		},
		[items, constItems]
	);

	const getItems = useCallback(async () => {
		const items = await synchroiser.getFiltersList();
		constItems.current = items;
		setItems(items);
	}, []);

	const applyFavoriteFilter = useCallback(async (favoriteFilter: SavedFilter) => {
		if (favoriteFilter?.filterInfo?.filters[0].rightExpression) {
			dispatcher.filter.setSavedFilter(favoriteFilter);
			await synchroiser.getEntityWithFilter();
		}
	}, []);

	const applyFilter = useCallback(async (filter: Item | null) => {
		if (filter) {
			synchroiser.getFilter(filter.id.toString());
		}
	}, []);

	return (
		<div className={styles.sectionBlockFooter}>
			<div>
				<Dropdown
					items={items}
					name={"Сохраненные фильтры"}
					styles={ButtonStyle.IndigoSelect}
					onItemsLoad={hendleItemsLoad}
					value={null}
					onChangeValue={applyFilter}
					secondIcon={<ArrowToDownMini />}
					isRotateSecondIcon={true}
				/>

				{props.entity?.entity.filter?.favoriteFilters.map((favoriteFilter, i) => {
					return (
						<>
							{i === 0 && <div className={styles.ellipse} />}
							<Button
								caption={favoriteFilter.filterName ?? ""}
								className={styles.favoriteFiltersButton}
								style={ButtonStyle.IndigoSelect}
								selected={props.entity?.entity.filter?.savedFilter?.id === favoriteFilter.id!}
								onClick={() => {
									applyFavoriteFilter(favoriteFilter);
								}}
							/>
						</>
					);
				})}
			</div>
			{props.entity?.entity.quality !== undefined && <RowCount count={props.entity?.entity.quality} />}
		</div>
	);
});

function RowCount(props: { count: number }) {
	return (
		<div>
			<span className={styles.rowCountLabel}>Количество:</span>
			<span className={styles.rowCountValue}>{props.count}</span>
		</div>
	);
}

export default SectionHeadFooter;
