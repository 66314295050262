import { action, makeObservable, observable, runInAction } from "mobx";

import { api } from "shared";

import UserRole, { UserRoleType } from "./UserRole";
export class RootUserRoles {
	rootUserRoles: UserRole[];

	constructor() {
		makeObservable(this, {
			rootUserRoles: observable,
			getTreeOfOrganizationRoles: action,
			getTreeOFunctionalRoles: action
		});

		this.rootUserRoles = [];
	}
	async loadByType(roleType: UserRoleType) {
		if (roleType === UserRoleType.FunctionalRole) this.getTreeOFunctionalRoles();
		else this.getTreeOfOrganizationRoles();
	}

	async getTreeOfOrganizationRoles() {
		const response = await api.http.administration.getTreeOfOrganizationRoles().get();
		let request = response.data;
		runInAction(() => {
			if (request) {
				this.rootUserRoles = request.rootUserRoles;
			}
		});
	}
	async getTreeOFunctionalRoles() {
		const response = await api.http.administration.getTreeOfFunctionalRoles().get();
		let request = response.data;

		runInAction(() => {
			if (request) {
				this.rootUserRoles = request.rootUserRoles;
			}
		});
	}

	async addFolder(data: any) {
		const resopnse = await api.http.administration.roles().post(data);
		let request = resopnse.data;
		if (request.success) {
			await this.loadByType(data.roleType);
			return true;
		} else return false;
	}
	async renameFolder(data: any, type: UserRoleType) {
		const request = await api.http.administration.roles().put(data);
		if (request.data.success) {
			await this.loadByType(type);
			return true;
		} else return false;
	}
	async deleteFolder(id: string, type: UserRoleType) {
		const request = await api.http.administration.roles(id).delete();
		if (request.data.success) {
			await this.loadByType(type);
			return true;
		} else return false;
	}

	async getRolesByType(type: UserRoleType) {
		const request = await api.http.administration.getRolesByType(type).get();
		if (request.data.success) {
			return request.data.data;
		} else return null;
	}

	async createLeaderRole(name: string, roleType: UserRoleType, childRoleId: string) {
		let data = {
			name: name,
			roleType: roleType,
			childRoleId: childRoleId
		};
		const request = await api.http.administration.leadRoles().post(data);
		if (request.data.success) {
			await this.loadByType(roleType);
			return true;
		} else return false;
	}
	async deleteLeaderRole(id: string, roleType: UserRoleType) {
		const request = await api.http.administration.deleteLeadRole(id).delete();
		if (request.data.success) {
			await this.loadByType(roleType);
			return true;
		} else return false;
	}
}

export const rootUserRoles = new RootUserRoles();

export default RootUserRoles;
