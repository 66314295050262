import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { lowerFirst } from "lodash";

import { dispatcher, selector } from "store";
import { NEW_RECORD } from "synchroiser/synchroiser";

import { Button, ButtonGroup, Hint } from "sale-bridge-ui-kit";

import { QuickActionType, QuickActionTypeName } from "types/entity";
import { Stopwatch } from "features/stopwatch";
import { stopwatchStore } from "features/stopwatch/store";


export const ActionButtonsFullView = observer((props: {
    isCopied: boolean,
    copyLink: () => void,
    flagClick: () => void,
    logTimeClick: () => void,
    stopwatchClick: () => void,
}) => {
    const row = useMemo(() => dispatcher.currentRow.get()!, [toJS(dispatcher.currentRow.get())]);
    const isNew = useMemo(() => row?.id === NEW_RECORD, [row?.id]);
    const sectionWizzard = useMemo(() => dispatcher.entity.get()?.entity.sectionWizzard, [toJS(dispatcher.entity.get()?.entity.sectionWizzard)]);
    const sysFlagField = useMemo(() => lowerFirst(selector.sectionWizzard.getSysFlagColumn()?.fieldConfig?.columnName), [selector.sectionWizzard.getSysFlagColumn()]);

    const recordTitle  = useMemo(() => {
        return dispatcher.currentRow.getTitle()
    }, [row.id])


    const isExistStopwatch = useMemo(() =>
        stopwatchStore.stopwatches.get(row.id) ?? false
        , [row.id, stopwatchStore.stopwatches.get(row.id)],
    )

    const variantLinkButton = useMemo(() => {
        if (isNew) {
            return 'disabled';
        } else if (props.isCopied) {
            return 'successSecondary';
        } else {
            return 'secondary';
        }
    }, [isNew, props.isCopied]);

    const variantDefaultButton = useMemo(() => {
        if (isNew) {
            return 'disabled';
        } else {
            return 'secondary';
        }
    }, [isNew]);

    const iconFlagButton = useMemo(() => {
        let leftIconFlagButton: "Flag" | "FlagSolid" = "Flag";
        if (sysFlagField && row[lowerFirst(sysFlagField)]) {
            leftIconFlagButton = "FlagSolid";
        }
        return leftIconFlagButton;
    }, [sysFlagField, row, row[lowerFirst(sysFlagField)]]);

    const iconLinkButton = useMemo(() => props.isCopied ? 'ButtonSuccessCheck' : 'Link', [props.isCopied]);

    const hintTextFlagButton = useMemo(() => {
        if (row[lowerFirst(sysFlagField)]) {
            return 'Снять флажок'
        } else {
            return QuickActionTypeName[QuickActionType.Flag].title
        }
    }, [sysFlagField, row, row[lowerFirst(sysFlagField)]]);

    const hintTextCopyButton = useMemo(() => {
        if (props.isCopied) {
            return 'Ссылка скопирована'
        } else {
            return QuickActionTypeName[QuickActionType.CopyLink].title
        }
    }, [props.isCopied]);


    const actionButtons = useMemo((): JSX.Element[] => {
        const buttons: JSX.Element[] = [
            <Hint hintBody={hintTextCopyButton}>
                <Button
                    text=""
                    onClick={props.copyLink}
                    leftIcon={iconLinkButton}
                    size='small'
                    variant={variantLinkButton}
                    link={false}
                    loading={false}
                    border={false}
                />
            </Hint>
        ];

        if (sectionWizzard?.hasFlag) {
            buttons.push(
                <Hint hintBody={hintTextFlagButton}>
                    <Button
                        text=""
                        onClick={props.flagClick}
                        leftIcon={iconFlagButton}
                        iconSize="16"
                        size='small'
                        variant={variantDefaultButton}
                        link={false}
                        loading={false}
                        border={false}
                    />
                </Hint>
            );
        }

        if (sectionWizzard?.hasTimeLogging) {
            buttons.push(
                <Hint hintBody={QuickActionTypeName[QuickActionType.LogTime].title}>
                    <Button
                        text=""
                        onClick={props.logTimeClick}
                        leftIcon='Clock'
                        iconSize="16"
                        size='small'
                        variant={variantDefaultButton}
                        link={false}
                        loading={false}
                        border={false}
                    />
                </Hint>
            );
        }

        if (sectionWizzard?.hasTimer) {
            if (isExistStopwatch) {
                buttons.push(
                    <Stopwatch recordTitle={recordTitle} recordId={row.id} view='small' />
                );
            }
            else {
                buttons.push(
                    <Hint hintBody={QuickActionTypeName[QuickActionType.Stopwatch].title}>
                        <Button
                            text=""
                            onClick={props.stopwatchClick}
                            leftIcon='Stopwatch'
                            iconSize="16"
                            size='small'
                            variant={variantDefaultButton}
                            link={false}
                            loading={false}
                            border={false}
                        />
                    </Hint>
                );
            }
        }

        return buttons;
    }, [variantLinkButton, variantDefaultButton, iconFlagButton, iconLinkButton,
        sectionWizzard?.hasFlag, sectionWizzard?.hasTimeLogging, sectionWizzard?.hasTimer,
        props, hintTextFlagButton, hintTextCopyButton, row.id, isExistStopwatch
    ]);


    return (
        <ButtonGroup>
            {actionButtons}
        </ButtonGroup>
    );
});

