import { useMemo } from "react";

import { dispatcher } from "store";

import { StandartItemGroup } from "sale-bridge-ui-kit/dist/components/button-dropdown/button-dropdown";

export const ActionsComponent = () => {

    const entity = useMemo(() => {
        return dispatcher.entity.get()
    }, [dispatcher.entity.get()]);

    const defaultActions: Array<StandartItemGroup> = [
        {
            header: '',
            items: [
                {
                    id: 'exportAll',
                    name: 'Экспортировать всё...',
                    icon: 'Export',
                    iconSize: '16'
                },
                {
                    id: 'import',
                    name: 'Импортировать...',
                    icon: 'Import',
                    iconSize: '16',
                    isDisable: true
                }
            ]
        },
        {
            header: '',
            items: [
                {
                    id: 'editSection',
                    name: 'Редактировать раздел',
                    icon: 'Tools',
                    iconSize: '16'
                }
            ]
        }
    ];


    const actionsIfChecked: Array<StandartItemGroup> = [
        {
            header: '',
            items: [
                {
                    id: 'exportWithCount',
                    name: `Экспортировать (${dispatcher.entity.get()?.entity.countOfChecked})`,
                    icon: 'Export',
                    iconSize: '16'
                },
                {
                    id: 'import',
                    name: 'Импортировать...',
                    icon: 'Import',
                    iconSize: '16',
                    isDisable: true
                }
            ]
        },
        {
            header: '',
            items: [
                {
                    id: 'delete',
                    name: `Удалить (` + entity?.entity.countOfChecked + `)`,
                    icon: 'Trash',
                    iconSize: '16',
                    isDisable: entity?.entity.countOfChecked ? entity?.entity.countOfChecked < 1 : false,
                    isRed: true
                }
            ]
        }
    ];

    return { defaultActions, actionsIfChecked };
};