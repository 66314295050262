import { bindingInXml } from "module/reactor/binding/binding";

interface FORProps{
    range:string;
    children:any;
    services:Map<string, any>;
}

function FOR(props:FORProps){
    const range = Number(bindingInXml(props.services, props.range));
    const rows = [];

    for (let i = 0; i < range; i++) {
        rows.push(props.children);
    }

    return (
        <>{rows}</>
    )
}

export default FOR;