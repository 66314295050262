import { useMemo } from "react";
import styles from "./progress-bar.module.css";

interface ProgressBarProps {
    steps: number;
    currentStep: number;
    color?: string;
}

function ProgressBar(props: ProgressBarProps) {
    const { steps, currentStep } = props;
    const dividers = useMemo<JSX.Element[]>(() => {
        let dividers: JSX.Element[] = [];

        for (let i = 0; i < currentStep; i++) {
            if (i === 0) continue;
            dividers.push(
                <div
                    key={i}
                    className={styles.divider}
                    style={{
                        left: `${(i / (steps)) * 100}%`,
                    }}
                >
                </div>
            )
        }

        return dividers;
    }, [currentStep, steps])

    return (
        <div className={styles.wrapper}>
            <div className={styles.back}>
                {dividers}
                <div
                    className={styles.progressbar}
                    style={{
                        width: `${(currentStep / (steps)) * 100}%`,
                        backgroundColor: props.color
                    }}
                />
            </div>
        </div>
    );
}

export default ProgressBar;
