import { observer } from "mobx-react-lite";

import { AutomatizationHeader } from "./components";

import { Icon } from "sale-bridge-ui-kit";

import styles from "./automatization-template.module.scss";

type AutomatizationTemplateProps = {
    children: JSX.Element;
    title: string,
    info: string,
    titleHeader: string,
    onAdd: () => void,
    onClose: () => void
}
export const AutomatizationTemplate = (props: AutomatizationTemplateProps) => {

    return (
        <div className={styles.wrapper}>
            <AutomatizationHeader title={props.titleHeader} onAdd={props.onAdd} />
            <div className={styles.infoIsland}>
                <div className={styles.infoIslandHead}>
                    <span className={styles.infoIslandTitle}>Что такое «бизнес-правила»</span>
                    <Icon name='Close' size="small" className={styles.closeButton} />
                </div>
                <span className={styles.infoIslandText}>{props.info}</span>
            </div>
            {props.children}
        </div>
    );
};
