import { v4 } from "uuid";
import { action, toJS } from "mobx";
import { isArray, isNull, isUndefined } from "lodash";

import { dispatcher, selector, store } from "store";

import { validateRequired, validateSchema } from "pages/section-wizzard/validation/validation";

import { ColumnType } from "entities/ColumnType";
import { MAIN_TAB_NAME, SettingName } from "pages/section-wizzard/data/data";
import {
	AdditionalField,
	AdditionalPanel,
	BlockedColumnNames,
	BusinessRule,
	ColumnSpecializationType,
	DisplayedPanel,
	EntityColumnSpecialization,
	EntityNameType,
	FieldConfig,
	GridItem,
	ItemType,
	KanbanConfig,
	Navbar,
	OperationItem,
	OptionPage,
	QuickActionType,
	RecordItem,
	RecordOperation,
	RecordRightLevel,
	SectionWizzard,
	SystemColumns,
	TabId,
	TabsConfig,
	TabState
} from "types/entity";
import { synchroiser } from "synchroiser";
import { VirtualLookupValues } from "pages/section-wizzard/pages/constructor/configurations/field-configuration/types";
import { Item } from "types";
import { constants } from "crypto";
import authStore from "AuthStore";
import { FilterStore } from "entities/filter/FilterStore";

export class SectionWizzardController {
	getSectionWizzard = (): SectionWizzard | null => {
		const findEntity = store.entities.find((entity) => entity.id === store.currentEntityId)?.entity;
		if (findEntity) {
			return findEntity.sectionWizzard;
		}
		return null;
	};

	getOldSectionWizzard = (): SectionWizzard | null => {
		const findEntity = store.entities.find((entity) => entity.id === store.currentEntityId)?.entity;
		if (findEntity) {
			return findEntity.oldValueOfSectionWizzard;
		}
		return null;
	};

	setHasChanges = action((value: boolean): void => {
		const sectionWizzard = this.getSectionWizzard();
		if (sectionWizzard) sectionWizzard.hasChanges = value;
	});

	/**
	 * @description функция-обертка decorator
	 **/
	changeObserver = (func: Function): Function => {
		return (...atributes: Array<any>) => {
			// @ts-ignore
			const result = func(...atributes);

			this.setHasChanges(true);
			return result;
		};
	};

	generateKanbanConfig = action(() => {
		const sectionWizzard = dispatcher.sectionWizzard.getSectionWizzard();
		if (sectionWizzard && !sectionWizzard.kanbanConfig) {
			const basicKanbanModel: KanbanConfig = {
				cardDesign: {
					userAvatarEnable: false,
					quickActionEnable: false,
					additionalFields: [],
					userFields: [],
					selectedQuickActions: [
						{
							action: QuickActionType.CopyLink,
							isEnabled: true,
							isHidden: false
						},
						{
							action: QuickActionType.OpenInNewTab,
							isEnabled: true,
							isHidden: false
						},
						{
							action: QuickActionType.Flag,
							isEnabled: false,
							isHidden: !dispatcher.sectionWizzard.getSectionWizzard()?.hasFlag
						},
						{
							action: QuickActionType.LogTime,
							isEnabled: false,
							isHidden: !dispatcher.sectionWizzard.getSectionWizzard()?.hasTimeLogging
						},
						{
							action: QuickActionType.Stopwatch,
							isEnabled: false,
							isHidden: !dispatcher.sectionWizzard.getSectionWizzard()?.hasTimer
						}
					]
				},
				quickViewDesign: {
					quickViewEnable: true,
					leftBlockAdditionalFields: [],
					rightBlockAdditionalFields: [],
					commentsEnable: false
				}
			};
			sectionWizzard.kanbanConfig = basicKanbanModel;
		}
	});

	generateNew = action((): SectionWizzard => {
		const sectionWizzardConfig: SectionWizzard = {
			optionPage: OptionPage.GlobalSettings,
			entityTitle: "",
			systemName: "",
			hasStageModel: false,
			kanbanIsEnabled: false,
			hasCalendar: false,
			hasSpecificFinish: false,
			hasFlag: false,
			hasTimeLogging: false,
			hasTimer: false,
			displayedPanel: DisplayedPanel.Main,
			viewColumnId: null,
			searchValue: null,
			reactorPage: "",
			reactorConfig: {
				navbar: {
					left: "",
					center: "",
					right: ""
				},
				tabs: {
					tabsConfig: [
						{
							tabIndex: 0,
							tabName: MAIN_TAB_NAME,
							tabTitle: "Основная информация",
							grid: {
								items: [
									{
										x: -1,
										y: 0,
										width: 1,
										height: 1,
										type: 0,
										gridItemId: v4(),
										fieldConfig: {
											columnId: v4(),
											columnName: SystemColumns.Id,
											columnTitle: "Id",
											columnType: ColumnType.Guid,
											hasIndex: false,
											isLookup: false,
											isRequired: true,
											uneditable: false,
											isViewColumn: false,
											lookupTable: ""
										}
									},
									{
										x: -1,
										y: 0,
										width: 1,
										height: 1,
										type: 0,
										gridItemId: v4(),
										fieldConfig: {
											columnId: v4(),
											columnName: SystemColumns.CreatedOn,
											columnTitle: "Дата создания",
											columnType: ColumnType.DateTime,
											hasIndex: false,
											isLookup: false,
											isRequired: true,
											uneditable: false,
											isViewColumn: false,
											lookupTable: ""
										}
									},
									{
										x: -1,
										y: 0,
										width: 1,
										height: 1,
										type: 0,
										gridItemId: v4(),
										fieldConfig: {
											columnId: v4(),
											columnName: SystemColumns.ModifiedOn,
											columnTitle: "Дата изменения",
											columnType: ColumnType.DateTime,
											hasIndex: false,
											isLookup: false,
											isRequired: true,
											uneditable: false,
											isViewColumn: false,
											lookupTable: ""
										}
									},
									{
										x: -1,
										y: 0,
										width: 1,
										height: 1,
										type: 0,
										gridItemId: v4(),
										fieldConfig: {
											columnId: v4(),
											columnName: SystemColumns.ExternalId,
											columnTitle: "Внешний Id",
											columnType: ColumnType.String,
											hasIndex: false,
											isLookup: false,
											isRequired: false,
											uneditable: false,
											isViewColumn: false,
											lookupTable: ""
										}
									},
									{
										x: -1,
										y: 0,
										width: 1,
										height: 1,
										type: 0,
										gridItemId: v4(),
										fieldConfig: {
											columnId: v4(),
											columnName: SystemColumns.CreatedBy,
											columnTitle: "Кем создано",
											columnType: ColumnType.Lookup,
											hasIndex: false,
											isLookup: true,
											isRequired: false,
											uneditable: false,
											isViewColumn: false,
											lookupTable: "User"
										}
									},
									{
										x: -1,
										y: 0,
										width: 1,
										height: 1,
										type: 0,
										gridItemId: v4(),
										fieldConfig: {
											columnId: v4(),
											columnName: SystemColumns.ModifiedBy,
											columnTitle: "Кем изменено",
											columnType: ColumnType.Lookup,
											hasIndex: false,
											isLookup: true,
											isRequired: false,
											uneditable: false,
											isViewColumn: false,
											lookupTable: "User"
										}
									}
								]
							}
						}
					],
					additionalPanel: {
						comments: {
							state: TabState.Disabled,
							index: -1,
							title: SettingName.Comments
						},
						files: {
							state: TabState.Disabled,
							index: -1,
							title: SettingName.Files
						},
						chronology: {
							state: TabState.Disabled,
							index: -1,
							title: SettingName.Chronology
						},
						wysiwyg: {
							state: TabState.Disabled,
							index: -1,
							title: SettingName.Wysiwyg
						}
					},
					currentTab: 0
				}
			},
			accessRightsConfig: {
				adminByOperation: {
					isEnabled: false,
					operationItems: []
				},
				adminByRecords: {
					isEnabled: false,
					recordItems: []
				}
			},
			hasChanges: false,
			stageModelConfig: null,
			kanbanConfig: null,
			businessRules: null
		};
		return sectionWizzardConfig;
	});

	checkExistFieldName = (fieldName: string): boolean => {
		const sectionWizzard = this.getSectionWizzard();
		let checker = false;
		if (sectionWizzard) {
			sectionWizzard.reactorConfig.tabs.tabsConfig.forEach((tab) => {
				tab.grid.items.forEach((gridItem) => {
					if (gridItem.groupFieldsConfig) {
						/* Проверяем все поля из группы полей */
						const findedItem = gridItem.groupFieldsConfig?.inner?.items.find(
							(gridItemInGroup) => gridItemInGroup.fieldConfig?.columnName === fieldName
						);
						if (findedItem) {
							checker = true;
							return;
						}
					} else if (gridItem.fieldConfig) {
						/* Проверяем поле просто на холсте */
						if (gridItem.fieldConfig.columnName === fieldName) {
							checker = true;
							return;
						}
					}
				});
			});
		}
		return checker;
	};

	checkExistFieldTitle = (fieldTitle: string): boolean => {
		const sectionWizzard = this.getSectionWizzard();
		let checker = false;
		if (sectionWizzard) {
			sectionWizzard.reactorConfig.tabs.tabsConfig.forEach((tab) => {
				tab.grid.items.forEach((gridItem) => {
					if (gridItem.groupFieldsConfig) {
						/* Проверяем все поля из группы полей */
						const findedItem = gridItem.groupFieldsConfig?.inner?.items.find(
							(gridItemInGroup) => gridItemInGroup.fieldConfig?.columnTitle === fieldTitle
						);
						if (findedItem) {
							checker = true;
							return;
						}
					} else if (gridItem.fieldConfig) {
						/* Проверяем поле просто на холсте */
						if (gridItem.fieldConfig.columnTitle === fieldTitle) {
							checker = true;
							return;
						}
					}
				});
			});
		}
		return checker;
	};

	checkExistGroupFieldName = (groupName: string): boolean => {
		const sectionWizzard = this.getSectionWizzard();
		let checker = false;
		if (sectionWizzard) {
			sectionWizzard.reactorConfig.tabs.tabsConfig.forEach((tab) => {
				const groups = tab.grid.items.filter((item) => item.groupFieldsConfig);
				const finded = groups.find((group) => group.groupFieldsConfig?.name === groupName);
				if (finded) {
					checker = true;
					return;
				}
			});
		}
		return checker;
	};

	checkExistGroupFieldTitle = (groupTitle: string): boolean => {
		const sectionWizzard = this.getSectionWizzard();
		let checker = false;
		if (sectionWizzard) {
			sectionWizzard.reactorConfig.tabs.tabsConfig.forEach((tab) => {
				const groups = tab.grid.items.filter((item) => item.groupFieldsConfig);
				const finded = groups.find((group) => group.groupFieldsConfig?.title === groupTitle);
				if (finded) {
					checker = true;
					return;
				}
			});
		}
		return checker;
	};

	checkExistTabName = (tabName: string): boolean => {
		const sectionWizzard = this.getSectionWizzard();
		let checker = false;
		if (sectionWizzard) {
			sectionWizzard.reactorConfig.tabs.tabsConfig.forEach((tab) => {
				if (tab.tabName === tabName) {
					checker = true;
					return;
				}
			});
		}
		return checker;
	};

	checkExistTabTitle = (tabTitle: string): boolean => {
		const sectionWizzard = this.getSectionWizzard();
		let checker = false;
		if (sectionWizzard) {
			sectionWizzard.reactorConfig.tabs.tabsConfig.forEach((tab) => {
				if (tab.tabTitle === tabTitle) {
					checker = true;
					return;
				}
			});
		}
		return checker;
	};

	checkExistGridItemByPosition = (x: number, y: number, type: ItemType): boolean => {
		const sectionWizzard = this.getSectionWizzard();
		let checker = false;
		if (sectionWizzard) {
			const tabs = sectionWizzard.reactorConfig.tabs;
			tabs.tabsConfig[tabs.currentTab].grid.items.forEach((item) => {
				if (type === ItemType.Field) {
					if (x === item.x && y === item.y) {
						checker = true;
						return;
					}
				} else if (type === ItemType.Detail || type === ItemType.FieldGroup) {
					if (y === item.y) {
						checker = true;
						return;
					}
				}
			});
		}
		return checker;
	};

	checkExistGridItemInFieldGroupByPosition = (x: number, y: number, groupId: string): boolean => {
		let checker = false;
		this.getAllGridItems().forEach((item) => {
			if (item.gridItemId === groupId || item.groupFieldsConfig?.groupFieldId === groupId) {
				item.groupFieldsConfig?.inner?.items.forEach((itemInGroup) => {
					if (x === itemInGroup.x && y === itemInGroup.y) {
						checker = true;
						return;
					}
				});
			}
		});
		return checker;
	};

	getViewColumnTitle = (): string => {
		const sectionWizzard = this.getSectionWizzard();
		let newValue = "Название записи";
		if (sectionWizzard) {
			const id = sectionWizzard.viewColumnId;
			if (id) {
				this.getAllGridItems().forEach((item) => {
					if (item.fieldConfig?.columnId === id) newValue = item.fieldConfig?.columnTitle!;
				});
			}
		}
		return newValue;
	};

	getViewColumnId = (): string | null => {
		const sectionWizzard = this.getSectionWizzard();
		if (sectionWizzard) {
			return sectionWizzard.viewColumnId;
		}
		return null;
	};

	excludeGridItemsWithSpecializations = (gridItems: GridItem[], excludedSpecializations?: EntityColumnSpecialization[]): GridItem[] => {
		const excludedItems: GridItem[] = [];
		gridItems.forEach((item) => {
			const currentItemSpecializations = item.fieldConfig?.specializations;
			if (excludedSpecializations && !isUndefined(currentItemSpecializations) && !isNull(currentItemSpecializations)) {
				const specializationsByTag = excludedSpecializations.filter(
					(specialization) => specialization.tag === currentItemSpecializations.tag
				);
				specializationsByTag?.forEach((specialization) => {
					Object.keys(specialization.properties).forEach((property) => {
						if (currentItemSpecializations.properties[property] === specialization.properties[property]) {
							excludedItems.push(item);
						}
					});
				});
			}
		});
		const excludedItemsAsSet = new Set(excludedItems);
		const filteringItems = gridItems.filter((element) => !excludedItemsAsSet.has(element));
		return filteringItems;
	};

	findGridItemsWithSpecializations = (gridItems: GridItem[], findedSpecializations?: EntityColumnSpecialization[]): GridItem[] => {
		const findedItems: GridItem[] = [];
		gridItems.forEach((item) => {
			const currentItemSpecializations = item.fieldConfig?.specializations;
			if (findedSpecializations && !isUndefined(currentItemSpecializations) && !isNull(currentItemSpecializations)) {
				const specializationsByTag = findedSpecializations.filter(
					(specialization) => specialization.tag === currentItemSpecializations.tag
				);
				specializationsByTag?.forEach((specialization) => {
					Object.keys(specialization.properties).forEach((property) => {
						if (currentItemSpecializations.properties[property] === specialization.properties[property]) {
							findedItems.push(item);
						}
					});
				});
			}
		});
		return findedItems;
	};

	getGridItemsFromConfig = (tabIndex: number): GridItem[] => {
		const sectionWizzard = this.getSectionWizzard();
		if (sectionWizzard) {
			return sectionWizzard.reactorConfig.tabs.tabsConfig[tabIndex]?.grid.items ?? [];
		}
		return [];
	};

	getAdditionalFromConfig = (): AdditionalPanel | undefined => {
		return this.getSectionWizzard()?.reactorConfig.tabs.additionalPanel;
	};

	getViewAdditional = (): boolean => {
		const sectionWizzard = this.getSectionWizzard();
		let findVisibleTab = false;
		if (sectionWizzard) {
			const arrayOfAdditional = Object.entries(sectionWizzard?.reactorConfig.tabs.additionalPanel!);
			arrayOfAdditional.forEach(([key, tabParams]) => {
				if (tabParams.state === TabState.EnabledOnAuxiliary) {
					findVisibleTab = true;
					return;
				}
			});
		}
		return findVisibleTab;
	};

	getAllGridItems = (): GridItem[] | [] => {
		const sectionWizzard = this.getSectionWizzard();
		if (sectionWizzard) {
			const allItems: GridItem[] = [];
			sectionWizzard.reactorConfig?.tabs.tabsConfig.map((tab) => {
				tab.grid.items.map((item) => {
					allItems.push(item);
					if (item.groupFieldsConfig?.inner?.items) {
						item.groupFieldsConfig?.inner?.items.forEach((innerItem) => {
							allItems.push(innerItem);
						});
					}
				});
			});
			return allItems;
		}
		return [];
	};

	getFieldConfigOfAllGridItems = (): FieldConfig[] | [] => {
		const array: FieldConfig[] = [];
		this.getAllGridItems().forEach((gridItem) => {
			if (gridItem.groupFieldsConfig) {
				gridItem.groupFieldsConfig.inner?.items.forEach((innerItem) => {
					if (innerItem.fieldConfig) {
						array.push(innerItem.fieldConfig);
					}
				});
			}
			if (gridItem.fieldConfig) {
				array.push(gridItem.fieldConfig);
			}
		});
		return array;
	};

	getAllGridItemsOfOldSectionWizzard = (): GridItem[] | [] => {
		const sectionWizzard = this.getOldSectionWizzard();
		if (sectionWizzard) {
			const allItems: GridItem[] = [];
			sectionWizzard.reactorConfig?.tabs.tabsConfig.map((tab) => {
				tab.grid.items.map((item) => {
					allItems.push(item);
					if (item.groupFieldsConfig?.inner?.items) {
						item.groupFieldsConfig?.inner?.items.forEach((innerItem) => {
							allItems.push(innerItem);
						});
					}
				});
			});
			return allItems;
		}
		return [];
	};

	/**
	 * @description находит последний индекс для добавления нового таба
	 */
	getLastIndexForAddTab = (): number => {
		let maxIndex = -1;
		const sectionWizzard = this.getSectionWizzard();
		if (sectionWizzard) {
			const additional = this.getAdditionalFromConfig();
			if (sectionWizzard.displayedPanel === DisplayedPanel.Main) {
				sectionWizzard.reactorConfig.tabs.tabsConfig.forEach((tab) => {
					if (tab.tabIndex > maxIndex) maxIndex = tab.tabIndex;
				});
			}
			if (additional) {
				const additionalTabsAsArray = Object.entries(additional);
				if (sectionWizzard.displayedPanel === DisplayedPanel.Main) {
					additionalTabsAsArray.filter(([key, value]) => value.state === TabState.EnabledOnPrimary);
				} else {
					additionalTabsAsArray.filter(([key, value]) => value.state === TabState.EnabledOnAuxiliary);
				}
				additionalTabsAsArray.forEach(([key, value]) => {
					if (value.index > maxIndex) {
						maxIndex = value.index;
					}
				});
			}
		}
		return maxIndex;
	};

	/**
	 * @description проверяет является ли таб коробочным
	 **/
	isBoxTab = action((tabId: string): boolean => {
		if (tabId === TabId.Chronology || tabId === TabId.Comments || tabId === TabId.Files || tabId === TabId.Wysiwyg) return true;
		else return false;
	});

	/**
	 * @description проверяет является ли поле коробочным
	 **/
	isBoxField = action((columnName: string): boolean => {
		return BlockedColumnNames.find((name) => name === columnName) ? true : false;
	});

	/**
	 * @description Нужно для того чтобы не удалять поля из конфига при создании нового раздела.
	 */
	isPriorityOrStageField = action((fieldConfig: FieldConfig): boolean => {
		return (
			(fieldConfig.specializations?.tag === ColumnSpecializationType.KanbanField &&
				fieldConfig?.specializations?.properties["priorityField"] === "true") ||
			fieldConfig?.specializations?.properties["stageField"] === "true"
		);
	});

	/**
	 * @description Проверка соответствия поля тегу и специализациям. Проверяет содержит ли поле необходимый тег и переданные специализации.
	 */
	isSpecificField = action(
		(fieldConfig: FieldConfig, tag: ColumnSpecializationType, targetProperties: Record<string, string>): boolean => {
			if (fieldConfig.specializations?.tag === tag) {
				for (const propertyKey in targetProperties) {
					if (fieldConfig.specializations.properties[propertyKey] !== targetProperties[propertyKey]) {
						return false;
					}
				}
				return true;
			}
			return false;
		}
	);

	setOptionPage = action((optionPage: OptionPage): void => {
		const sectionWizzard = this.getSectionWizzard();
		if (sectionWizzard) sectionWizzard.optionPage = optionPage;
	});

	setEntityTitle = this.changeObserver(
		action((name: string): void => {
			const sectionWizzard = this.getSectionWizzard();
			if (sectionWizzard) sectionWizzard.entityTitle = name;
		})
	);

	setSystemName = this.changeObserver(
		action((name: string): void => {
			const sectionWizzard = this.getSectionWizzard();
			if (sectionWizzard) sectionWizzard.systemName = name;
		})
	);

	setHasStageModel = this.changeObserver(
		action((value: boolean): void => {
			const sectionWizzard = this.getSectionWizzard();
			if (sectionWizzard) sectionWizzard.hasStageModel = value;
		})
	);

	setHasFlag = this.changeObserver(
		action((value: boolean): void => {
			const sectionWizzard = this.getSectionWizzard();
			if (sectionWizzard) {
				sectionWizzard.hasFlag = value;
				//TODO Нужно в случае если мигратор не отработает
				// dispatcher.kanbanCardDesign.initSelectedQuickActionsInConfig();

				const flagSelectedQuickAction = sectionWizzard.kanbanConfig?.cardDesign.selectedQuickActions?.find(
					(selectedQuickAction) => selectedQuickAction.action == QuickActionType.Flag
				);
				if (flagSelectedQuickAction) {
					flagSelectedQuickAction.isHidden = !value;
					flagSelectedQuickAction.isEnabled = false;
				}
				//TODO временное решение по удалению юзеров из массива, если его длина >3
				if (sectionWizzard.kanbanConfig && sectionWizzard.kanbanConfig.cardDesign.userFields.length > 3) {
					const deletedUserAvatars = (
						JSON.parse(JSON.stringify(sectionWizzard.kanbanConfig.cardDesign.userFields)) as AdditionalField[]
					).splice(3);
					deletedUserAvatars.forEach((deletedUserAvatar) =>
						dispatcher.kanbanCardDesign.setUserAvatarsEnable(
							false,
							deletedUserAvatar.columnId,
							sectionWizzard.kanbanConfig!.cardDesign.userFields
						)
					);
				}
			}
		})
	);

	setHasTimeLogging = this.changeObserver(
		action((value: boolean): void => {
			const sectionWizzard = this.getSectionWizzard();
			if (sectionWizzard) {
				sectionWizzard.hasTimeLogging = value;

				//TODO Нужно в случае если мигратор не отработает
				// dispatcher.kanbanCardDesign.initSelectedQuickActionsInConfig();

				const timeLoggingSelectedQuickAction = sectionWizzard.kanbanConfig?.cardDesign.selectedQuickActions?.find(
					(selectedQuickAction) => selectedQuickAction.action == QuickActionType.LogTime
				);
				if (timeLoggingSelectedQuickAction) {
					timeLoggingSelectedQuickAction.isHidden = !value;
					timeLoggingSelectedQuickAction.isEnabled = false;
				}
			}
		})
	);

	setHasTimer = this.changeObserver(
		action((value: boolean): void => {
			const sectionWizzard = this.getSectionWizzard();
			if (sectionWizzard) {
				sectionWizzard.hasTimer = value;
				//TODO Нужно в случае если мигратор не отработает
				// dispatcher.kanbanCardDesign.initSelectedQuickActionsInConfig();

				const timerSelectedQuickAction = sectionWizzard.kanbanConfig?.cardDesign.selectedQuickActions?.find(
					(selectedQuickAction) => selectedQuickAction.action == QuickActionType.Stopwatch
				);
				if (timerSelectedQuickAction) {
					timerSelectedQuickAction.isHidden = !value;
					timerSelectedQuickAction.isEnabled = false;
				}
			}
		})
	);

	setKanbanIsEnabled = this.changeObserver(
		action((value: boolean): void => {
			const sectionWizzard = this.getSectionWizzard();
			if (sectionWizzard) sectionWizzard.kanbanIsEnabled = value;
		})
	);

	setHasCalendar = this.changeObserver(
		action((value: boolean): void => {
			const sectionWizzard = this.getSectionWizzard();
			if (sectionWizzard) sectionWizzard.hasCalendar = value;
		})
	);

	setHasSpecificFinish = this.changeObserver(
		action((value: boolean): void => {
			const sectionWizzard = this.getSectionWizzard();
			if (sectionWizzard) sectionWizzard.hasSpecificFinish = value;
		})
	);

	switchDisplayedPanel = this.changeObserver((panel: DisplayedPanel): void => {
		const sectionWizzard = this.getSectionWizzard();
		if (sectionWizzard) sectionWizzard.displayedPanel = panel;
	});

	setViewColumnId = this.changeObserver(
		action((displayedColumnId: string): void => {
			const sectionWizzard = this.getSectionWizzard();
			if (sectionWizzard) sectionWizzard.viewColumnId = displayedColumnId;
		})
	);

	setAdditionalTabsOrder = this.changeObserver(
		action((tabId: TabId, orderIndex: number): void => {
			const sectionWizzard = this.getSectionWizzard();
			if (sectionWizzard) {
				const tabParams = sectionWizzard.reactorConfig.tabs.additionalPanel[tabId];
				tabParams.index = orderIndex;
			}
		})
	);

	setStateInAdditionalPanel = this.changeObserver(
		action((tabId: TabId, tabState: TabState): void => {
			const sectionWizzard = this.getSectionWizzard();
			if (sectionWizzard) {
				const tabParams = sectionWizzard.reactorConfig.tabs.additionalPanel[tabId];
				tabParams.state = tabState;
				if (tabState === TabState.Disabled) {
					tabParams.index = -1;
				}
				if (tabState === TabState.EnabledOnAuxiliary) {
					tabParams.index = 0;
				}
			}
		})
	);

	setNewTitleAdditionalTab = this.changeObserver(
		action((tabId: TabId, newtitle: string): void => {
			const sectionWizzard = this.getSectionWizzard();
			if (sectionWizzard) {
				const additional = sectionWizzard.reactorConfig.tabs.additionalPanel;
				if (additional) {
					const findAdditionalTab = additional[tabId];
					if (findAdditionalTab) {
						findAdditionalTab.title = newtitle;
					}
				}
			}
		})
	);

	setSearchValue = this.changeObserver(
		action((value: string): void => {
			const sectionWizzard = this.getSectionWizzard();
			if (sectionWizzard) sectionWizzard.searchValue = value;
		})
	);

	setNavbar = this.changeObserver(
		action((position: Navbar): void => {
			const sectionWizzard = this.getSectionWizzard();
			if (sectionWizzard) sectionWizzard.reactorConfig.navbar = position;
		})
	);

	createNewTab = this.changeObserver(
		action((name: string, title: string, index: number): void => {
			const sectionWizzard = this.getSectionWizzard();
			if (sectionWizzard) {
				const newTab: TabsConfig = {
					tabIndex: index,
					tabName: name,
					tabTitle: title,
					grid: {
						items: []
					}
				};
				sectionWizzard.reactorConfig.tabs.tabsConfig.push(newTab);
			}
		})
	);

	setTabByName = this.changeObserver(
		action((tabConfig: TabsConfig): void => {
			const sectionWizzard = this.getSectionWizzard();
			if (sectionWizzard) {
				let tab = sectionWizzard.reactorConfig.tabs.tabsConfig.find((tab) => tab.tabName === tabConfig.tabName);
				if (tab) {
					let findedItemIndex = sectionWizzard.reactorConfig.tabs.tabsConfig.findIndex((elem) => elem.tabName === tab?.tabName);
					sectionWizzard.reactorConfig.tabs.tabsConfig[findedItemIndex] = tabConfig;
				}
			}
		})
	);

	createDuplicateGridItemInTab = this.changeObserver(
		action((targetZone: string, gridItem: GridItem, tabIndex: number): void => {
			const sectionWizzard = this.getSectionWizzard();

			const groupField = sectionWizzard?.reactorConfig.tabs.tabsConfig[tabIndex].grid.items.find(
				(item) => item.gridItemId === targetZone
			);
			if (groupField?.groupFieldsConfig?.inner?.items && isArray(groupField?.groupFieldsConfig?.inner.items)) {
				groupField.groupFieldsConfig.inner.items.push(gridItem);
				return;
			}
			if (sectionWizzard) {
				const duplicateGridItem = {
					...gridItem,
					gridItemId: v4()
				};
				sectionWizzard.reactorConfig.tabs.tabsConfig[tabIndex].grid.items.push(duplicateGridItem);
			}
		})
	);

	addGridItemByTabIndex = this.changeObserver(
		action((gridItem: GridItem, tabIndex: number): void => {
			const sectionWizzard = this.getSectionWizzard();
			if (sectionWizzard) {
				sectionWizzard.reactorConfig.tabs.tabsConfig[tabIndex].grid.items.push(gridItem);
			}
		})
	);

	/**
	 * @description скрывает поле с холста (задает ему координаты -1)
	 * @param gridItemId - id элемента, поле в котором, необходимо скрыть
	 * @param tabIndex - индекс таба, с которого скрыть
	 * @param groupId - id группы, с которой необходимо скрыть поле
	 **/
	hideField = this.changeObserver(
		action((gridItemId: string, tabIndex: number, groupId?: string): void => {
			const sectionWizzard = this.getSectionWizzard();
			if (!sectionWizzard) {
				return;
			}

			const tab = sectionWizzard.reactorConfig.tabs.tabsConfig[tabIndex];

			if (groupId) {
				const group = this.findGroup(tab, groupId);
				if (group) {
					this.hideFieldInGroup(group, gridItemId, tabIndex, groupId);
				}
			} else {
				this.hideFieldInTab(tab, gridItemId, tabIndex);
			}
		})
	);

	/**
	 * @description Найти группу по идентификатору
	 * @param tab - таб, в котором искать группу
	 * @param groupId - идентификатор группы
	 * @returns Найденная группа или undefined
	 */
	private findGroup(tab: TabsConfig, groupId: string) {
		return tab.grid.items.find((item) => item.gridItemId === groupId || item.groupFieldsConfig?.groupFieldId === groupId);
	}

	/**
	 * @description Скрыть поле в группе
	 * @param group - группа, в которой скрыть поле
	 * @param gridItemId - id поля, которое необходимо скрыть
	 * @param tabIndex - индекс таба, с которого скрыть
	 * @param groupId - id группы, с которой необходимо скрыть поле
	 */
	private hideFieldInGroup(group: GridItem, gridItemId: string, tabIndex: number, groupId: string) {
		const findInGroup = group.groupFieldsConfig!.inner?.items.find((itemInGroup) => itemInGroup.gridItemId === gridItemId);
		if (findInGroup) {
			const columnId = findInGroup.fieldConfig?.columnId;
			const isDuplicate = this.isDuplicateField(columnId!, gridItemId, tabIndex, groupId);

			if (isDuplicate) {
				this.deleteDuplicate(gridItemId, tabIndex, groupId);
			} else {
				findInGroup.x = -1;
				findInGroup.y = -1;
			}
		}
	}

	/**
	 * @description Скрыть поле в табе
	 * @param tab - таб, в котором скрыть поле
	 * @param gridItemId - id поля, которое необходимо скрыть
	 * @param tabIndex - индекс таба, с которого скрыть
	 */
	private hideFieldInTab(tab: TabsConfig, gridItemId: string, tabIndex: number) {
		const findedItem = tab.grid.items.find((item) => item.gridItemId === gridItemId);
		if (findedItem) {
			const columnId = findedItem.fieldConfig?.columnId;
			const isDuplicate = this.isDuplicateField(columnId!, gridItemId, tabIndex);

			if (isDuplicate) {
				this.deleteDuplicate(gridItemId, tabIndex);
			} else {
				findedItem.x = -1;
				findedItem.y = -1;
			}
		}
	}

	/**
	 * @description Проверить, является ли поле дубликатом
	 * @param columnId - идентификатор колонки
	 * @param gridItemId - идентификатор поля
	 * @param tabIndex - индекс таба
	 * @param groupId - идентификатор группы (необязательный)
	 * @returns true, если поле является дубликатом, иначе false
	 */
	private isDuplicateField(columnId: string, gridItemId: string, tabIndex: number, groupId?: string): boolean {
		const tab = this.getSectionWizzard()?.reactorConfig.tabs.tabsConfig[tabIndex];
		if (!tab) {
			return false;
		}

		const isDuplicateInTab = tab.grid.items.find((item) => item.gridItemId !== gridItemId && item.fieldConfig?.columnId === columnId);
		const group = groupId ? this.findGroup(tab, groupId) : undefined;
		const isDuplicateInGroup = group?.groupFieldsConfig!.inner?.items.find(
			(itemInGroup) => itemInGroup.gridItemId !== gridItemId && itemInGroup.fieldConfig?.columnId === columnId
		);

		return !!isDuplicateInTab || !!isDuplicateInGroup;
	}

	/**
	 * @description Метод удаляет дубликат поля, чтобы не засорять конфиг. Этот метод необходимо применять если поле которое мы скрываем не единственное в конфиге, в ином случае просто меняем координаты на x= -1, y =-1
	 */
	private deleteDuplicate = action("deleteDuplicate", (gridItemId: string, tabIndex: number, groupFieldId?: string | undefined) => {
		this.deleteGridItemFromTab(gridItemId, tabIndex, groupFieldId);
	});

	setCurrentTab = action((index: number): void => {
		const sectionWizzard = this.getSectionWizzard();
		if (sectionWizzard) sectionWizzard.reactorConfig.tabs.currentTab = index;
	});

	/**
	 * @description заменяет переданные значения элемента по его координатам
	 * @param tabIndex
	 * @param gridItem - список полей для изменения
	 **/
	setGridItemByTabIndex = this.changeObserver(
		action((tabIndex: number, gridItem: GridItem, targetZone?: string): void => {
			const sectionWizzard = this.getSectionWizzard();
			if (sectionWizzard) {
				if (sectionWizzard.reactorConfig.tabs.tabsConfig[tabIndex].grid.items.find((item) => item.gridItemId === targetZone)) {
					this.createDuplicateGridItemInTab(targetZone ?? "", gridItem, tabIndex);
					return;
				}

				let findedItemInGroupIndex = -1;
				let gridItemIndex = 0;
				const gridItems = sectionWizzard.reactorConfig.tabs.tabsConfig[tabIndex]?.grid.items;

				/* Поиск элемента в группе полей */
				for (let itemIndex = 0; itemIndex < gridItems.length; itemIndex++) {
					findedItemInGroupIndex =
						gridItems[itemIndex].groupFieldsConfig?.inner?.items.findIndex(
							(innerItem) =>
								(innerItem.fieldConfig?.columnId || innerItem.groupFieldsConfig?.groupFieldId) === gridItem.gridItemId
						) ?? -1;
					if (findedItemInGroupIndex !== -1) gridItemIndex = itemIndex;
				}

				if (findedItemInGroupIndex !== -1) {
					/* Элемент содержится в группе полей */
					if (gridItems[gridItemIndex]) {
						gridItems[gridItemIndex].groupFieldsConfig!.inner!.items[findedItemInGroupIndex] = {
							...sectionWizzard.reactorConfig.tabs.tabsConfig[tabIndex].grid.items[gridItemIndex],
							...gridItem
						};
					}
				} else {
					/* Поиск элемента на холсте */
					const findedItemIndex = sectionWizzard.reactorConfig.tabs.tabsConfig[tabIndex].grid.items.findIndex(
						(item) => item?.gridItemId === gridItem.gridItemId
					);

					if (findedItemIndex !== -1) {
						/* Элемент лежит на холсте */
						sectionWizzard.reactorConfig.tabs.tabsConfig[tabIndex].grid.items[findedItemIndex] = {
							...sectionWizzard.reactorConfig.tabs.tabsConfig[tabIndex].grid.items[findedItemIndex],
							...gridItem
						};
					} else {
						/* Добавление на холст нового элемента */
						sectionWizzard.reactorConfig.tabs.tabsConfig[tabIndex].grid.items.push(gridItem);
					}
				}
			}
		})
	);

	deleteTabByName = this.changeObserver(
		action((tabName: string): void => {
			const sectionWizzard = this.getSectionWizzard();
			if (sectionWizzard) {
				const mainTabIndex = sectionWizzard.reactorConfig.tabs.tabsConfig.findIndex((tab) => tab.tabName === MAIN_TAB_NAME);
				const mainTabItems = this.getGridItemsFromConfig(mainTabIndex);
				const findDeletedTab = sectionWizzard.reactorConfig.tabs.tabsConfig.find((tab) => tab.tabName === tabName);
				if (findDeletedTab) {
					findDeletedTab.grid.items.forEach((item) => {
						if (item.fieldConfig) {
							const findedById = mainTabItems.find(
								(gridItemInMain) => gridItemInMain.fieldConfig?.columnId === item.fieldConfig?.columnId
							);
							if (!findedById) {
								const newItem = {
									...item,
									x: -1,
									y: -1
								};
								this.addGridItemByTabIndex(newItem, mainTabIndex);
							}
						}
					});
				}
				const indexFindedTab = sectionWizzard.reactorConfig.tabs.tabsConfig.findIndex((tab) => tab.tabName === tabName);
				const currentTabIndex = sectionWizzard.reactorConfig.tabs.currentTab;
				if (indexFindedTab === currentTabIndex && currentTabIndex !== 0) {
					this.setCurrentTab(currentTabIndex - 1);
				}
				const newTabs = sectionWizzard.reactorConfig.tabs.tabsConfig.filter((tab) => tab.tabName !== tabName);
				sectionWizzard.reactorConfig.tabs.tabsConfig = newTabs;
			}
		})
	);

	/**
	 * @description Проверяет является ли цель group field
	 */
	itFieldGroup = (tabIndex: number, targetZoneId: string): boolean => {
		return Boolean(
			this.getSectionWizzard()?.reactorConfig.tabs.tabsConfig[tabIndex].grid.items.find((item) => item.gridItemId === targetZoneId)
		);
	};

	createPriorityLookup = async () => {
		const sectionWizzard = this.getSectionWizzard();
		if (sectionWizzard) {
			const tabIndex = sectionWizzard.reactorConfig.tabs.tabsConfig.find((tab) => tab.tabName === MAIN_TAB_NAME)?.tabIndex;
			let name = `${sectionWizzard.systemName}Priorities`;
			let title = `Приоритеты для раздела ${sectionWizzard.entityTitle}`;
			const isNotUniqueSystemName = await synchroiser.checkExistEntityName(name);
			if (isNotUniqueSystemName) {
				//TODO сделать автонкремент отдельной функцией, которая будет работать для всех полей (на данный моментЖ приоритет, флаг, дата флага, позиция)
				name = `${sectionWizzard.systemName}Priorities2`;
			}
			const isNotUniqueEntityTitle = await synchroiser.checkExistEntityTitle(title, EntityNameType.Lookups);
			if (isNotUniqueEntityTitle) {
				title = `Приоритеты для раздела ${sectionWizzard.entityTitle} (2)`;
			}

			const lookupValues: Array<VirtualLookupValues> = [
				{
					id: v4(),
					name: "Критический",
					HEXTextColor: "EE46BC",
					Order: 1
				},
				{
					id: v4(),
					name: "Высокий",
					HEXTextColor: "F04438",
					Order: 2
				},
				{
					id: v4(),
					name: "Средний",
					HEXTextColor: "F79009",
					Order: 3
				},
				{
					id: v4(),
					name: "Низкий",
					HEXTextColor: "2970FF",
					Order: 4
				}
			];

			const virtualLookup = {
				entityTitle: title,
				systemName: name,
				isLookup: true,
				columnInfo: [
					{
						columnId: v4(),
						columnName: "Name",
						columnTitle: "Название",
						columnType: ColumnType.String,
						isLookup: false,
						isLink: false,
						lookupTable: null,
						isRequired: true,
						hasIndex: false,
						specializations: null
					},
					{
						columnId: v4(),
						columnName: "Order",
						columnTitle: "Порядок",
						columnType: ColumnType.Integer,
						isLookup: false,
						isLink: false,
						lookupTable: null,
						isRequired: true,
						hasIndex: false,
						specializations: null
					},
					{
						columnId: v4(),
						columnName: "HEXTextColor",
						columnTitle: "Цвет",
						columnType: ColumnType.String,
						isLookup: false,
						isLink: false,
						lookupTable: null,
						isRequired: true,
						hasIndex: false,
						specializations: null
					}
				],
				virtualLookupValues: lookupValues
			};
			const gridItem: GridItem = {
				x: -1,
				y: -1,
				gridItemId: v4(),
				width: 1,
				height: 1,
				fieldConfig: {
					columnId: v4(),
					columnName: name,
					columnType: ColumnType.Lookup,
					columnTitle: "Приоритет",
					virtualLookup: virtualLookup,
					lookupTable: name,
					isLookup: true,
					hasIndex: true,
					isRequired: true,
					uneditable: false,
					isViewColumn: false,
					specializations: {
						tag: ColumnSpecializationType.KanbanField,
						properties: {
							priorityField: "true"
						}
					}
				}
			};
			this.createNewGridItemInTab(gridItem, tabIndex);
			return gridItem;
		}
	};

	setPriorityAndStageSystemName = action((systemName: string) => {
		const priorityField = selector.sectionWizzard.getPriorityFieldBySpecialization();
		if (priorityField && priorityField?.fieldConfig && priorityField?.fieldConfig?.virtualLookup) {
			const name = `${systemName}Priorities`;
			priorityField.fieldConfig = {
				...priorityField.fieldConfig!,
				columnName: name,
				lookupTable: name,
				virtualLookup: {
					...priorityField.fieldConfig!.virtualLookup!,
					systemName: name
				}
			};
		}

		const stageField = selector.sectionWizzard.getStageFieldBySpecialization();
		if (stageField && stageField?.fieldConfig && stageField?.fieldConfig?.virtualLookup) {
			const name = `${systemName}Stage`;
			stageField.fieldConfig = {
				...stageField.fieldConfig!,
				columnName: name,
				lookupTable: name,
				virtualLookup: {
					...stageField.fieldConfig!.virtualLookup!,
					systemName: name
				}
			};
		}
	});

	setPriorityAndStageEntityTitle = action((entityTitle: string) => {
		const priorityField = selector.sectionWizzard.getPriorityFieldBySpecialization();
		if (priorityField && priorityField?.fieldConfig && priorityField?.fieldConfig?.virtualLookup) {
			const title = `Приоритеты для раздела ${entityTitle}`;
			priorityField.fieldConfig = {
				...priorityField.fieldConfig!,
				virtualLookup: {
					...priorityField.fieldConfig!.virtualLookup!,
					entityTitle: title
				}
			};
		}

		const stageField = selector.sectionWizzard.getStageFieldBySpecialization();
		if (stageField && stageField?.fieldConfig && stageField?.fieldConfig?.virtualLookup) {
			const title = `${entityTitle} Cтадийная модель`;
			stageField.fieldConfig = {
				...stageField.fieldConfig!,
				virtualLookup: {
					...stageField.fieldConfig!.virtualLookup!,
					entityTitle: title
				}
			};
		}
	});

	createPositionWhenStageIsTrue = async () => {
		const sectionWizzard = this.getSectionWizzard();
		if (sectionWizzard) {
			const tabIndex = sectionWizzard.reactorConfig.tabs.tabsConfig.find((tab) => tab.tabName === MAIN_TAB_NAME)?.tabIndex;
			const name = `SysPosition`;
			let title = `Позиция`;
			const isNotUniqueEntityTitle = this.checkExistFieldTitle(title);
			if (isNotUniqueEntityTitle) {
				title = `Позиция(2)`;
			}
			const gridItem: GridItem = {
				x: -1,
				y: -1,
				gridItemId: v4(),
				width: 1,
				height: 1,
				fieldConfig: {
					columnId: v4(),
					columnName: name,
					columnType: ColumnType.Integer,
					columnTitle: title,
					lookupTable: null,
					isLookup: false,
					hasIndex: true,
					uneditable: false,
					isRequired: false,
					isViewColumn: false,
					specializations: {
						tag: ColumnSpecializationType.KanbanField,
						properties: {
							sysOrderField: "true"
						}
					}
				}
			};
			this.createNewGridItemInTab(gridItem, tabIndex);
			return gridItem;
		}
	};

	/**
	 * @description Добавление полей SysFlagDate и SysFlag в конфиг при включении тогла Флажка
	 */
	createFlagFieldsInConfig = () => {
		const sectionWizzard = this.getSectionWizzard();
		if (sectionWizzard) {
			const tabIndex = sectionWizzard.reactorConfig.tabs.tabsConfig.find((tab) => tab.tabName === MAIN_TAB_NAME)?.tabIndex;

			const flagName = `SysFlag`;
			let flagTitle = `Флажок`;
			const flagDateName = `SysFlagDate`;
			let flagDateTitle = `Дата флажка`;

			const isNotUniqueFlagTitle = this.checkExistFieldTitle(flagTitle);
			const isNotUniqueFlagDateTitle = this.checkExistFieldTitle(flagDateTitle);
			if (isNotUniqueFlagTitle) {
				flagTitle = `Флажок(2)`;
			}
			if (isNotUniqueFlagDateTitle) {
				flagDateTitle = `Дата флажка(2)`;
			}

			const flagGridItem: GridItem = {
				x: -1,
				y: -1,
				gridItemId: v4(),
				width: 1,
				height: 1,
				fieldConfig: {
					columnId: v4(),
					columnName: flagName,
					columnType: ColumnType.Boolean,
					columnTitle: flagTitle,
					lookupTable: null,
					isLookup: false,
					hasIndex: false,
					uneditable: true,
					isRequired: false,
					isViewColumn: false,
					specializations: {
						tag: ColumnSpecializationType.Flag,
						properties: {
							sysFlagColumn: "true"
						}
					}
				}
			};

			const flagDategridItem: GridItem = {
				x: -1,
				y: -1,
				gridItemId: v4(),
				width: 1,
				height: 1,
				fieldConfig: {
					columnId: v4(),
					columnName: flagDateName,
					columnType: ColumnType.DateTime,
					columnTitle: flagDateTitle,
					lookupTable: null,
					isLookup: false,
					hasIndex: false,
					uneditable: true,
					isRequired: false,
					isViewColumn: false,
					specializations: {
						tag: ColumnSpecializationType.Flag,
						properties: {
							sysFlagDateColumn: "true"
						}
					}
				}
			};
			this.createNewGridItemInTab(flagGridItem, tabIndex);
			this.createNewGridItemInTab(flagDategridItem, tabIndex);
		}
	};

	/**
	 * @description Метод для удаления полей SysFlagDate и SysFlag из конфига при выключении тогла Флажка
	 */
	deleteFlagFieldsFromConfig = () => {
		const sectionWizzard = this.getSectionWizzard();
		if (sectionWizzard) {
			const mainPanelTabIndex = sectionWizzard?.reactorConfig.tabs.tabsConfig.find((tab) => tab.tabName === MAIN_TAB_NAME)?.tabIndex;
			const flagColumnFined = sectionWizzard?.reactorConfig.tabs.tabsConfig
				.find((tab) => tab.tabName === MAIN_TAB_NAME)
				?.grid.items.find(
					(item) =>
						item.fieldConfig?.specializations?.tag === ColumnSpecializationType.Flag &&
						item.fieldConfig.specializations.properties["sysFlagColumn"] === "true"
				);
			if (flagColumnFined) {
				dispatcher.sectionWizzard.deleteGridItemFromTab(flagColumnFined.gridItemId, mainPanelTabIndex);
			}

			const flagDateColumnFined = sectionWizzard?.reactorConfig.tabs.tabsConfig
				.find((tab) => tab.tabName === MAIN_TAB_NAME)
				?.grid.items.find(
					(item) =>
						item.fieldConfig?.specializations?.tag === ColumnSpecializationType.Flag &&
						item.fieldConfig.specializations.properties["sysFlagDateColumn"] === "true"
				);
			if (flagDateColumnFined) {
				dispatcher.sectionWizzard.deleteGridItemFromTab(flagDateColumnFined.gridItemId, mainPanelTabIndex);
			}
		}
	};

	private autoPull = action(
		"auto put item",
		({ tabIndex, item, sourceId, itSameGrid }: { tabIndex: number; item: GridItem; sourceId?: string; itSameGrid?: boolean }): void => {
			if (sourceId) {
				const gridGroupFiled = this.getSectionWizzard()?.reactorConfig.tabs.tabsConfig[tabIndex]?.grid.items.find(
					(innerItem: GridItem) => innerItem.gridItemId === sourceId
				)?.groupFieldsConfig?.inner;
				if (gridGroupFiled) {
					if (itSameGrid) {
						const indexItem = gridGroupFiled.items.findIndex(
							(innerItem: GridItem) =>
								(innerItem.gridItemId ?? innerItem.gridItemId) === (item.gridItemId ?? innerItem.gridItemId)
						);
						gridGroupFiled.items[indexItem] = item;
					} else {
						gridGroupFiled.items = gridGroupFiled.items.filter(
							(innerItem: GridItem) =>
								(innerItem.gridItemId ?? innerItem.gridItemId) !== (item.gridItemId ?? innerItem.gridItemId)
						);
					}
				}
			} else {
				const grid = this.getSectionWizzard()?.reactorConfig.tabs.tabsConfig[tabIndex]?.grid;
				if (grid) {
					if (itSameGrid) {
						const indexItem = grid.items.findIndex(
							(innerItem: GridItem) => (innerItem.gridItemId ?? innerItem.gridItemId) === (item.gridItemId ?? item.gridItemId)
						);
						grid.items[indexItem] = item;
					} else {
						grid.items = grid.items.filter((innerItem: GridItem) => innerItem.gridItemId !== item.gridItemId);
					}
				}
			}
		}
	);

	putInZone = action("put in zone", (tabIndex: number, item: GridItem) => {
		this.getSectionWizzard()?.reactorConfig.tabs.tabsConfig[tabIndex].grid.items.push(item);
	});

	putInGroupField = action("put in group field", (tabIndex: number, groupFieldId: string, item: GridItem) => {
		this.getSectionWizzard()
			?.reactorConfig.tabs.tabsConfig[tabIndex]?.grid.items.find((innerItem: GridItem) => innerItem.gridItemId === groupFieldId)
			?.groupFieldsConfig?.inner?.items.push(item);
	});

	/**
	 * @description Автоматически удаляет и кладет элемент в zone и groupField
	 */
	autoPut = this.changeObserver(
		action(
			"auto put item",
			({
				tabIndex,
				targetId,
				sourceId,
				item
			}: {
				tabIndex: number;
				targetId: string;
				sourceId: string | null;
				item: GridItem;
			}): void => {
				const targetIsGroupField = this.itFieldGroup(tabIndex, targetId);
				const sourceIsGroupField = sourceId && this.itFieldGroup(tabIndex, sourceId);

				if (targetIsGroupField) {
					if (targetId !== sourceId) {
						this.putInGroupField(tabIndex, targetId, item);
					}
					if (sourceIsGroupField) {
						this.autoPull({ tabIndex, item, sourceId, itSameGrid: targetId === sourceId });
					} else {
						this.autoPull({ tabIndex, item });
					}
				} else {
					if (sourceIsGroupField) {
						this.putInZone(tabIndex, item);
						this.autoPull({ tabIndex, item, sourceId });
					} else {
						/**
						 * @description При перемещении в одном и том же grid, функционал по перемещению на себя берет autoPull, ибо он имеет функционал глубокого перебора grid
						 * В случае если будет не удобно, написать новый метод, replace или move
						 */
						this.autoPull({ tabIndex, item, itSameGrid: true });
					}
				}
			}
		)
	);

	/**
	 * @description Удаление элементов грида из Существующих полей
	 * @param gridItemId Id элемента грида
	 * @param tabIndex Индекс таба
	 * @param groupFieldId Id группы полей
	 */
	deleteGridItemFromTab = this.changeObserver(
		action((gridItemId: string, tabIndex: number, groupFieldId?: string | undefined): void => {
			const sectionWizzard = this.getSectionWizzard();
			if (sectionWizzard) {
				let findedGroupIndex = -1;
				const findGroup = sectionWizzard.reactorConfig.tabs.tabsConfig[tabIndex]?.grid.items?.find((item, index) => {
					if (item.gridItemId === groupFieldId) {
						findedGroupIndex = index;
						return item;
					}
				});
				if (findGroup && findedGroupIndex !== -1 && findGroup.groupFieldsConfig?.inner?.items) {
					const fieldItemIndex = findGroup.groupFieldsConfig.inner.items.findIndex((item) => item.gridItemId === gridItemId);
					if (fieldItemIndex > -1) {
						/* Элемент находится в группе */
						findGroup.groupFieldsConfig?.inner?.items.splice(fieldItemIndex, 1);
					}
				} else {
					const fieldItemIndex = sectionWizzard.reactorConfig.tabs.tabsConfig[tabIndex]?.grid.items.findIndex(
						(item) => item.gridItemId === gridItemId
					);
					if (fieldItemIndex > -1) {
						/* Элемент находится на холсте */
						sectionWizzard.reactorConfig.tabs.tabsConfig[tabIndex]?.grid.items.splice(fieldItemIndex, 1);
					}
				}
			}
		})
	);

	/**
	 * @description Удаление группы полей
	 * @param gridItemId Id элемента грида
	 * @param isNew раздел новый или редактируется
	 */
	deleteFieldGroupFromTab = this.changeObserver(
		action((gridItemId: string, isNew: boolean): void => {
			const sectionWizzard = this.getSectionWizzard();
			if (sectionWizzard) {
				const currentTabIndex = sectionWizzard.reactorConfig.tabs.currentTab;
				const group = this.getAllGridItems().find((item) => item.gridItemId === gridItemId);
				if (group) {
					if (!isNew) {
						group.groupFieldsConfig?.inner?.items.forEach((item) => {
							const movingItem = {
								...item,
								x: -1,
								y: -1
							};
							this.addGridItemByTabIndex(movingItem, currentTabIndex);
						});
					} else {
						group.groupFieldsConfig?.inner?.items.forEach((item) => {
							this.deleteGridItemFromTab(item.gridItemId, currentTabIndex);
						});
					}
					this.deleteGridItemFromTab(gridItemId, currentTabIndex);
				}
			}
		})
	);

	createNewGridItemInTab = this.changeObserver(
		action((item: GridItem, tabIndex: number): void => {
			const sectionWizzard = this.getSectionWizzard();
			if (sectionWizzard) {
				sectionWizzard.reactorConfig.tabs.tabsConfig[tabIndex]?.grid.items.push(item);
			}
		})
	);

	createNewGridItemInGroup = this.changeObserver(
		action("Создать новый элемент внутри группы полей", (groupId: string, tabIndex: number, item: GridItem) => {
			const sectionWizard = this.getSectionWizzard();
			if (sectionWizard) {
				const gridItems = sectionWizard.reactorConfig.tabs.tabsConfig[tabIndex].grid.items;
				const indexFieldGroup = gridItems.findIndex(
					(element) => element.gridItemId === groupId || element.groupFieldsConfig?.groupFieldId === groupId
				);
				if (indexFieldGroup > -1) {
					gridItems[indexFieldGroup]?.groupFieldsConfig?.inner?.items.push(item);
					return true;
				}
			} else false;
		})
	);

	/**
	 * @description меняет тогл "Использовать доступ по операциям" в правах доступа
	 * @param isEnabled - значение тогла
	 **/
	setEnableAdminByOperation = this.changeObserver(
		action((isEnabled: boolean): void => {
			const sectionWizzard = this.getSectionWizzard();
			if (sectionWizzard) {
				sectionWizzard.accessRightsConfig.adminByOperation.isEnabled = isEnabled;
			}
		})
	);

	/**
	 * @description добавляет значения в массив OperationItems в правах доступа
	 * @param operationItem - элемент массива OperationItems
	 **/
	setAdminByOperationItems = this.changeObserver(
		action((operationItem: OperationItem): void => {
			const sectionWizzard = this.getSectionWizzard();
			if (sectionWizzard) {
				sectionWizzard.accessRightsConfig.adminByOperation.operationItems.push(operationItem);
			}
		})
	);

	/**
	 * @description меняет доступы по операциям в правах доступа
	 * @param id - id записи
	 * @param operation - название операции
	 **/
	setOperationActions = this.changeObserver(
		action((id: string, operation: string, value: boolean): void => {
			const sectionWizzard = this.getSectionWizzard();
			if (sectionWizzard) {
				const operationItem = sectionWizzard.accessRightsConfig.adminByOperation.operationItems.find((item) => item.id === id);

				if (operationItem) {
					(operationItem as any)[operation] = value;
					const operationItemIndex = sectionWizzard.accessRightsConfig.adminByOperation.operationItems.findIndex(
						(item) => item.id === id
					);
					sectionWizzard.accessRightsConfig.adminByOperation.operationItems.splice(operationItemIndex, 1, operationItem);
				}
			}
		})
	);

	/**
	 * @description меняет тогл "Использовать доступ по записям" в правах доступа
	 * @param isEnabled - значение тогла
	 **/
	setEnableAdminByRecords = this.changeObserver(
		action((isEnabled: boolean): void => {
			const sectionWizzard = this.getSectionWizzard();
			if (sectionWizzard) {
				sectionWizzard.accessRightsConfig.adminByRecords.isEnabled = isEnabled;
			}
		})
	);

	/**
	 * @description добавляет 3 значения в массив RecordItem в правах доступа (для чтения, редактирования и удаления)
	 * @param recordItems - массив RecordItem
	 **/
	setAdminByRecordItems = this.changeObserver(
		action((recordItems: RecordItem[]): void => {
			const sectionWizzard = this.getSectionWizzard();
			if (sectionWizzard) {
				sectionWizzard.accessRightsConfig.adminByRecords.recordItems.push(...recordItems);
			}
		})
	);

	/**
	 * @description меняет доступы по операциям в правах доступа
	 * @param id - id записи
	 * @param operation - операция
	 **/
	setRecordActions = this.changeObserver(
		action((id: string, operation: RecordOperation, value: boolean): void => {
			const sectionWizzard = this.getSectionWizzard();

			if (sectionWizzard) {
				const groupRecordsIds = sectionWizzard.accessRightsConfig.adminByRecords.recordItems.filter(
					(item) => item.groupRecordsId === id
				);

				const recordItem = groupRecordsIds.find((item) => item.operation == operation);
				if (recordItem) {
					recordItem.rightLevel = value ? RecordRightLevel.Granted : RecordRightLevel.Deny;
					const recordItemIndex = sectionWizzard.accessRightsConfig.adminByRecords.recordItems.findIndex(
						(item) => item.id === recordItem.id
					);
					sectionWizzard.accessRightsConfig.adminByRecords.recordItems.splice(recordItemIndex, 1, recordItem);
				}
			}
		})
	);

	/**
	 * @description получает бизнес-правила
	 **/
	getBusinessRules = action((): Array<BusinessRule> | null | undefined => {
		const sectionWizzard = this.getSectionWizzard();
		return sectionWizzard?.businessRules;
	});

	/**
	 * @description обновление всех значений бизнес-правила
	 * @param newRules - обновленный массив бп, которым необходимо заменить все старые значения
	 **/
	setBusinessRules = this.changeObserver(
		action((newRules: BusinessRule[]): void => {
			const sectionWizzard = this.getSectionWizzard();
			if (sectionWizzard) {
				sectionWizzard.businessRules = newRules;
			}
		})
	);

	/**
	 * @description генерирует стартовые данные для БП
	 **/
	generateBusinessRules = this.changeObserver(
		action((): void => {
			const sectionWizzard = this.getSectionWizzard();
			if (sectionWizzard && !sectionWizzard.businessRules) {
				this.setBusinessRules([]);
			}
		})
	);

	/**
	 * @description генерирует новое бизнес-правило, без сохранения
	 * @param ruleName - название бп
	 * @param ruleDescription - описание бп
	 **/
	generateNewBusinessRule = this.changeObserver(
		action((ruleName: string, ruleDescription: string): BusinessRule | null => {
			const sectionWizzard = this.getSectionWizzard();
			if (sectionWizzard && authStore.userId) {
				const id = v4();
				const businessRule: BusinessRule = {
					id: id,
					ruleName: ruleName,
					ruleDescription: ruleDescription,
					priority: this.getBusinessRules() ? this.getBusinessRules()!.length + 1 : 1,
					enabled: false,
					ruleSettings: {
						conditions: null,
						actions: [],
						elseActions: []
					},
					createdOn: new Date().toISOString(),
					createdById: authStore.userId,
					modifiedById: authStore.userId
				};
				return businessRule;
			}
			return null;
		})
	);

	/**
	 * @description добавляет новый БП в конфиг
	 * @param businessRule - объект бп, который требуется добавить в конфиг
	 **/
	addNewBusinessRule = this.changeObserver(
		action((businessRule: BusinessRule): void => {
			const sectionWizzard = this.getSectionWizzard();
			if (sectionWizzard) {
				sectionWizzard.businessRules?.push(businessRule);
			}
		})
	);

	/**
	 * @description обновление значений бизнес-правила
	 * @param newRule - обновленный объект бп, по айди которого нужно среди имеющихся найти и обновить старый бп
	 **/
	updateBusinessRule = this.changeObserver(
		action((newRule: BusinessRule): void => {
			const sectionWizzard = this.getSectionWizzard();

			if (sectionWizzard && sectionWizzard?.businessRules) {
				const index = sectionWizzard.businessRules.findIndex((rule) => rule.id === newRule.id);
				if (index >= 0) {
					sectionWizzard.businessRules[index] = { ...newRule };
				}
			}
		})
	);

	/**
	 * @description удаление бп
	 * @param ruleId - id бп, который необходимо удалить
	 **/
	deleteBusinessRule = this.changeObserver(
		action((ruleId: string): void => {
			const sectionWizzard = this.getSectionWizzard();

			if (sectionWizzard && sectionWizzard?.businessRules) {
				const index = sectionWizzard.businessRules.findIndex((rule) => rule.id === ruleId);
				if (index >= 0) {
					sectionWizzard.businessRules.splice(index, 1);
				}
			}
		})
	);
}

export const sectionWizzardController = new SectionWizzardController();
