import axios from "axios";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";
import classnames from "classnames";
import { isUndefined } from "lodash";

import { Position, modalController } from "features/modals";
import { ModalType, ModalViewer } from "features/modals/viewer/modal-viewer";
import { NewPasswordFormState } from "./change-password-state";

import { debounce } from "shared";
import { Button, Field } from "sale-bridge-ui-kit";

import { LoadingState } from "types/entity";

import { LogoSalebridge } from "shared";

import styles from "./change-password.module.scss";

const DEBOUNCE_DELAY = 500;

export const ChangePassword = observer((props: { userId: string; userName: string }) => {
	const { userId, userName } = props;
	const navigate = useNavigate();
	const passwordForm = useRef<NewPasswordFormState>(new NewPasswordFormState());

	const isLoading = useMemo(
		() => (passwordForm.current ? passwordForm.current.loadingState === LoadingState.Loading : false),
		[passwordForm.current?.loadingState]
	);

	const containerClassName = classnames(styles.container, {
		[`${styles.containerDisabled}`]: isLoading
	});

	useEffect(() => {
		document.addEventListener("keydown", keyDown);
		return () => {
			document.removeEventListener("keydown", keyDown);
		};
	}, []);

	useEffect(() => {
		if (passwordForm.current?.loadingState == LoadingState.Error) {
			modalController.notificationAdd({
				id: v4(),
				type: ModalType.ERROR_NOTIFICATION,
				position: Position.CENTER,
				layout: <>Что-то пошло не так</>
			});
		}
	}, [passwordForm.current?.loadingState]);

	const changePassword = useCallback(async () => {
		let requestData = {
			userId: userId,
			newPassword: passwordForm.current?.password
		};

		const result = await axios.post("/api/User/change-password", requestData);
		if (result.data) {
			await passwordForm.current!.savePassword(userName);
		} else {
			navigate("/login");
		}
	}, [userId, userName, passwordForm.current?.password]);

	const debouncedValidate = useCallback(
		debounce(() => {
			if (passwordForm.current) {
				passwordForm.current!.validatePasswordForProgressBar();
			}
		}, DEBOUNCE_DELAY),
		[passwordForm.current]
	);

	const handleChangePassword = useCallback(
		(value: string) => {
			passwordForm.current!.changePassword = value;
			debouncedValidate();
		},
		[passwordForm]
	);

	const handleChangePasswordConfirm = useCallback(
		(value: string) => {
			passwordForm.current!.changePasswordConfirm = value;
		},
		[passwordForm]
	);

	const handleChangePasswordLogin = useCallback(async () => {
		if (passwordForm.current?.isInvalid) {
			return;
		}
		await changePassword();
	}, [passwordForm]);

	const keyDown = useCallback(
		(event: any) => {
			if (!isLoading && event.code === "Enter") {
				handleChangePasswordLogin();
			}
		},
		[handleChangePasswordLogin, isLoading]
	);

	if (!passwordForm.current) {
		return null;
	}

	return (
		<div className={styles.wrapper}>
			<div className={containerClassName}>
				<div className={styles.header}>
					<LogoSalebridge />
					<span className={styles.title}>Новый пароль</span>
				</div>

				<div className={styles.inputPanel}>
					<Field
						type="password"
						value={passwordForm.current?.password ?? ""}
						onChange={handleChangePassword}
						textVariant="outlined"
						labelPosition="vertical"
						size="small"
						label="Пароль"
						info="Пароль должен содержать cтрочные и заглавные латинские буквы, цифры и специальные символы ?=.[!@#$%^&()_+]"
						progressBarState={passwordForm.current.progressBar}
						alert={passwordForm.current?.validation.password.isInvalid ? "error" : undefined}
						tooltipBody={passwordForm.current?.validation.password.error}
						startTooltipPosition="left middle"
						tooltipTrigger="hover&focus"
						isTooltipDisplayed={
							passwordForm.current?.validation.password.isInvalid &&
							!isUndefined(passwordForm.current?.validation.password.error)
						}
						isNew
					/>
					<Field
						type="password"
						value={passwordForm.current!.passwordConfirm ?? ""}
						onChange={handleChangePasswordConfirm}
						textVariant="outlined"
						labelPosition="vertical"
						size="small"
						label="Подтвердите пароль"
						alert={passwordForm.current?.validation.passwordConfirm.isInvalid ? "error" : undefined}
						tooltipBody={passwordForm.current?.validation.passwordConfirm.error}
						startTooltipPosition="left middle"
						tooltipTrigger="hover&focus"
						isTooltipDisplayed={passwordForm.current?.validation.passwordConfirm.isInvalid}
						isNew={false}
					/>
				</div>

				<div className={styles.buttonContainer}>
					<Button
						text="Сохранить и войти"
						size="medium"
						variant={isLoading ? "disabled" : "primary"}
						border
						link={false}
						loading={isLoading}
						onClick={handleChangePasswordLogin}
						isFillParent
					/>
				</div>
			</div>
			<ModalViewer />
		</div>
	);
});
