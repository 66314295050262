import styles from  "./panel-list.module.css";

interface IPanelListProps{
    className?:string;
    children:any;
}

function PanelList(props:IPanelListProps){
    const className = styles.panel + " " + props.className;
    
    return(
        <div className={className}>
            {props.children}
        </div>
    )
}

export default PanelList;