import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo, useState } from "react";
import { v4 } from "uuid";
import { toJS } from "mobx";
import { isNull, isUndefined } from "lodash";

import { modalController, Position } from "features/modals";
import { dispatcher } from "store";

import { AutomatizationTemplate } from "pages/section-wizzard/components/automatization-template";
import { UniversalGrid } from "components/grid/universal-grid";
import { BusinessRuleChangePriorityPopup, BusinessRuleEditor, CreateBusinessRulePopup, RulesContextMenu } from "./components";

import { infoBusinessRules, standartBusinessRulesColumns } from "./data";
import { BusinessRule, VisibleColumn } from "types/entity";
import { businessRuleEditorStore } from "./business-rule-editor-store";
import { ColumnType } from "entities/ColumnType";

export const BusinessRules = observer(() => {
	const [modalId] = useState<string>(v4());
	const [notificationId] = useState<string>(v4());
	const [checkedAll, setCheckedAll] = useState<boolean>(false);
	const [checkedIds, setCheckedIds] = useState<string[]>([]);
	const [rulesColumns, setRulesColumns] = useState<VisibleColumn[]>(standartBusinessRulesColumns);

	const sectionWizzard = useMemo(() => {
		return dispatcher.sectionWizzard.getSectionWizzard();
	}, [dispatcher.sectionWizzard.getSectionWizzard]);

	//TODO any[]: по сути это type BusinessRule, но с добавленным checked для грида
	const rulesRowsWithCheckbox = useMemo(() => {
		if (sectionWizzard?.businessRules && sectionWizzard?.businessRules.length > 0) {
			return sectionWizzard?.businessRules.map((rule: BusinessRule) => ({
				...rule,
				checked: Boolean(checkedIds.find((id) => id === rule.id) ?? false)
			}));
		}
		return [];
	}, [toJS(sectionWizzard?.businessRules), toJS(checkedIds)]);

	useEffect(() => {
		if (!sectionWizzard?.businessRules) {
			dispatcher.sectionWizzard.generateBusinessRules();
		}
	}, []);

	const handleModalClose = useCallback(() => {
		modalController.modalRemove(modalId);
	}, []);

	const handleNotificationClose = useCallback(() => {
		modalController.modalRemove(notificationId);
	}, [notificationId]);

	const handleCreateBusinessRule = useCallback(
		(ruleName: string, comment: string) => {
			const newRule = dispatcher.sectionWizzard.generateNewBusinessRule(ruleName, comment);
			businessRuleEditorStore.setIsNewBusinessRule(true);
			businessRuleEditorStore.setBusinessRule({ ...newRule });
		},
		[toJS(sectionWizzard?.businessRules)]
	);

	const handleAdd = useCallback(() => {
		modalController.popupAdd({
			id: modalId,
			layout: <CreateBusinessRulePopup onClose={handleModalClose} onSave={handleCreateBusinessRule} />,
			closeFunc: handleModalClose
		});
	}, [modalId]);

	const handleChangeSpanX = useCallback(
		(columnId: string, spanX: number) => {
			let findIndex = -1;
			const findedColumn = rulesColumns.find((column, index) => {
				if (column.columnId === columnId) {
					findIndex = index;
					return column;
				}
			});
			if (findedColumn && findIndex !== -1) {
				const newColumns: VisibleColumn[] = [];
				rulesColumns.forEach((column, index) => {
					if (index === findIndex) {
						newColumns.push({
							...column,
							spanX: spanX
						});
					} else {
						newColumns.push(column);
					}
				});
				setRulesColumns(newColumns);
			}
		},
		[toJS(rulesColumns)]
	);

	const handleCheckedAll = useCallback(
		(value: boolean) => {
			setCheckedAll(value);
			setCheckedIds([]);
			const newCheckedIds: string[] = [];
			rulesRowsWithCheckbox.forEach((row: any) => {
				if (value) {
					newCheckedIds.push(row.id);
				}
				row.checked = value;
			});
			setCheckedIds(newCheckedIds);
		},
		[toJS(rulesRowsWithCheckbox), toJS(checkedIds)]
	);

	const handleChangeRowChecked = useCallback(
		(row: any, value: boolean) => {
			const newCheckedIds: string[] = [];
			checkedIds.forEach((id) => newCheckedIds.push(id));
			rulesRowsWithCheckbox.forEach((currentRow: any) => {
				if (currentRow.id === row.id) {
					currentRow.checked = value;
					if (!checkedIds.find((checkedId) => checkedId === row.id) && value) {
						newCheckedIds.push(currentRow.id);
						if (rulesRowsWithCheckbox.length === newCheckedIds.length) {
							setCheckedAll(true);
						}
					} else if (checkedIds.find((checkedId) => checkedId === row.id) && !value) {
						const index = newCheckedIds.findIndex((id) => id === row.id);
						if (index >= 0) {
							setCheckedAll(false);
							newCheckedIds.splice(index, 1);
						}
					}
				}
			});
			setCheckedIds(newCheckedIds);
		},
		[toJS(rulesRowsWithCheckbox), toJS(checkedIds)]
	);

	const handleSortClick = useCallback((column: VisibleColumn) => {}, []);

	const addNotificationAfterDelete = useCallback(
		(length: number) => {
			const text = length > 1 ? "Бизнес-правила удалены" : "Бизнес-правило удалено";
			modalController.notificationAdd({
				id: notificationId,
				layout: <>{text}</>,
				position: Position.CENTER,
				closeFunc: handleNotificationClose,
				allowTimer: true,
				allowDefaultClick: true
			});
		},
		[notificationId]
	);

	const deleteCheckedRules = useCallback(() => {
		if (sectionWizzard?.businessRules) {
			const length = checkedIds.length;
			checkedIds.forEach((checkedId) => dispatcher.sectionWizzard.deleteBusinessRule(checkedId));
			setCheckedIds([]);
			setCheckedAll(false);
			addNotificationAfterDelete(length);
		}
	}, [toJS(sectionWizzard?.businessRules), toJS(checkedIds)]);

	const handleClickToEditRule = useCallback(() => {
		if (sectionWizzard?.businessRules) {
			if (checkedIds.length === 1) {
				const finded = sectionWizzard.businessRules.find((rule) => rule.id === checkedIds[0]);
				if (finded) {
					businessRuleEditorStore.setIsNewBusinessRule(false);
					businessRuleEditorStore.setBusinessRule(JSON.parse(JSON.stringify(finded)));
				}
			}
		}
	}, [toJS(sectionWizzard?.businessRules), toJS(checkedIds)]);

	const changeRulePriorities = useCallback((newRules: BusinessRule[]) => {
		dispatcher.sectionWizzard.setBusinessRules(newRules);
		handleModalClose();
	}, []);

	/**@description Метод добавляет в отображаемые колонки колонку Порядок срабатывания*/
	const generateColumnsWithOrder = useCallback(
		(oldColumns: VisibleColumn[]) => {
			if (!oldColumns.find((column) => column.columnName === "Priority")) {
				const newColumns: VisibleColumn[] = [
					{
						customerColumnTitle: "",
						indexX: 0,
						indexY: 0,
						spanX: 50,
						spanY: 0,
						columnId: v4(),
						columnName: "Priority",
						columnTitle: "Порядок срабатывания",
						columnType: ColumnType.Integer,
						hasIndex: false,
						isLookup: false,
						isLink: false,
						isRequired: false,
						uneditable: false,
						isViewColumn: false,
						lookupTable: null
					}
				];
				oldColumns.forEach((column) => newColumns.push(column));
				return newColumns;
			}
			return oldColumns;
		},
		[toJS(sectionWizzard?.businessRules), toJS(rulesColumns)]
	);

	const handleClickToChangeRulesOrder = useCallback(() => {
		setRulesColumns(generateColumnsWithOrder(rulesColumns));
		if (sectionWizzard?.businessRules) {
			modalController.popupAdd({
				id: modalId,
				layout: (
					<BusinessRuleChangePriorityPopup
						businessRules={sectionWizzard?.businessRules}
						save={changeRulePriorities}
						close={handleModalClose}
					/>
				),
				closeFunc: handleModalClose
			});
		}
	}, [toJS(sectionWizzard?.businessRules), toJS(rulesColumns), changeRulePriorities, generateColumnsWithOrder]);

	if (!isUndefined(businessRuleEditorStore.businessRule) && !isNull(businessRuleEditorStore.businessRule)) {
		return <BusinessRuleEditor />;
	}

	return (
		<AutomatizationTemplate
			title="Что такое «бизнес-правила»"
			info={infoBusinessRules}
			titleHeader="Бизнес-правила"
			onAdd={handleAdd}
			onClose={handleModalClose}
			children={
				<UniversalGrid
					view="checkbox"
					rows={rulesRowsWithCheckbox}
					isColumnSettingsVisible
					isCheckboxAllVisible
					isCheckedAll={checkedAll}
					visibleColumns={rulesColumns}
					contextMenuOptions={
						sectionWizzard?.businessRules && checkedIds.length > 0
							? RulesContextMenu({
									businessRules: sectionWizzard?.businessRules ?? [],
									checkedIds: checkedIds,
									changeOrder: handleClickToChangeRulesOrder,
									deleteRules: deleteCheckedRules,
									onClickToEditRule: handleClickToEditRule
							  })
							: undefined
					}
					onChangeColumnWidth={handleChangeSpanX}
					onSortClick={handleSortClick}
					onChangeCheckedAll={handleCheckedAll}
					onChangeRowChecked={handleChangeRowChecked}
					onClickEmpty={handleAdd}
					onDoubleRowClick={handleClickToEditRule}
				/>
			}
		/>
	);
});
