import { IContextMenuOption } from "components/grid/data/data";
import { makeAutoObservable } from "mobx";
import { Coordinate } from "types/item";

export class ContextMenuStore {
    isOpen: boolean = false;
    recordId: string | null = null;
    cursorPosition: Coordinate | null = null;
    resetCallBack: () => void = () => { };

    constructor() {
        makeAutoObservable(this);
    }

    openMenu() {
        this.isOpen = true;
    }

    initContextMenu(cursorPosition: Coordinate | null, recordId: string, resetCallBack?: () => void) {
        this.cursorPosition = cursorPosition;
        this.recordId = recordId;
        if (resetCallBack) {
            this.resetCallBack = resetCallBack;
        }
    }

    reset() {
        this.isOpen = false;
        this.recordId = null;
        this.cursorPosition = null;
        this.resetCallBack = () => { };
    }

}

export const contextMenuStore = new ContextMenuStore();