import { v4 } from "uuid";
import { toJS } from "mobx";
import { dispatcher } from "store";
import { useCallback, useMemo } from "react";

import { ExistFieldsBlock, NewFieldsBlock } from "../../../../components/blocks";
import { CheckboxGroup, CheckboxGroupItemProps } from "pages/section-wizzard/components";

import { Types } from "modules/DND/type";
import { MoreTitle } from "pages/section-wizzard/data/data";
import { IGridItem } from "components/select/types";
import { BlockedColumnNames, DisplayedPanel, GridItem, ItemType } from "types/entity";

interface ConstructorSourcePanelProps {
    tabs: CheckboxGroupItemProps[],
    newFields: Array<IGridItem>,
    sourcePanelElements: Array<IGridItem>,
    onMoveBoxTab: (id: string, value: boolean, currentDisplayedPanel?: DisplayedPanel) => void
}
type DNDOnDropEvent = {
    elementId: string | null;
    sourceZone: string | null;
    targetZone: string | null;
    type: Types,
    sourceData?: any;
    [key: string]: any;
}

export const ConstructorSourcePanel = (props: ConstructorSourcePanelProps) => {

    const sectionWizzard = useMemo(() => {
        return dispatcher.entity.get()?.entity.sectionWizzard;
    }, [dispatcher.entity.get()?.entity.sectionWizzard]);

    const getAllGridItems = useMemo(() => {
        return dispatcher.sectionWizzard.getAllGridItems();
    }, [toJS(dispatcher.sectionWizzard.getAllGridItems())]);

    const currentTabIndex = useMemo(() => {
        return sectionWizzard?.reactorConfig.tabs.currentTab ?? 0;
    }, [sectionWizzard?.reactorConfig.tabs.currentTab]);

    const itemsInConfig = useMemo(() => {
        return sectionWizzard?.reactorConfig.tabs.tabsConfig[currentTabIndex]?.grid?.items;
    }, [toJS(sectionWizzard?.reactorConfig.tabs.tabsConfig[currentTabIndex]?.grid?.items)]);


    const handleZoneDropExistField = useCallback((value: DNDOnDropEvent) => {
        const findedItem = getAllGridItems.find(item => item.fieldConfig?.columnId === value.elementId);
        if (findedItem) {
            const gridItem: GridItem = {
                ...findedItem,
                x: value.MATRIX.cellX,
                y: value.MATRIX.cellY,
                type: ItemType.Field,
                gridItemId: v4()
            };
            if (itemsInConfig?.find((item) => item.gridItemId === value.targetZone) &&
                !dispatcher.sectionWizzard.checkExistGridItemInFieldGroupByPosition(value.MATRIX.cellX, value.MATRIX.cellY, value.targetZone!)) {
                if (BlockedColumnNames.findIndex(name => name === gridItem.fieldConfig?.columnName) > -1) {
                    dispatcher.sectionWizzard.setGridItemByTabIndex(currentTabIndex, gridItem, value.targetZone ?? "");
                } else {
                    if (itemsInConfig.find(item => item.fieldConfig?.columnId === value.elementId)) {
                        dispatcher.sectionWizzard.createDuplicateGridItemInTab(value.targetZone ?? "", gridItem, currentTabIndex);
                    } else {
                        dispatcher.sectionWizzard.createNewGridItemInGroup(value.targetZone ?? "", currentTabIndex, gridItem);
                    }
                }
            }
            else if (!dispatcher.sectionWizzard.checkExistGridItemByPosition(value.MATRIX.cellX, value.MATRIX.cellY, ItemType.Field)) {
                if (BlockedColumnNames.findIndex(name => name === gridItem.fieldConfig?.columnName) > -1) {
                    dispatcher.sectionWizzard.setGridItemByTabIndex(currentTabIndex, gridItem, value.targetZone ?? "");
                } else {
                    const findedItem = itemsInConfig?.find(item => item.fieldConfig?.columnId === value.elementId);
                    if (findedItem) {
                        if (findedItem.x < 0 || findedItem.y < 0) {
                            const gridItem: GridItem = {
                                ...findedItem,
                                x: value.MATRIX.cellX,
                                y: value.MATRIX.cellY,
                                type: ItemType.Field,
                            };
                            dispatcher.sectionWizzard.setGridItemByTabIndex(currentTabIndex, gridItem);
                        } else {
                            dispatcher.sectionWizzard.createDuplicateGridItemInTab(value.targetZone ?? "", gridItem, currentTabIndex);
                        }
                    } else {
                        dispatcher.sectionWizzard.addGridItemByTabIndex(gridItem, currentTabIndex);
                    }
                }
            }
        }
    }, [currentTabIndex, toJS(itemsInConfig), toJS(getAllGridItems)]);

    return (
        <>
            <NewFieldsBlock items={props.newFields} />
            <ExistFieldsBlock
                items={props.sourcePanelElements}
                onDrop={handleZoneDropExistField}
            />
            <CheckboxGroup items={props.tabs} nameOfFieldSelect={MoreTitle} onChangeChecked={props.onMoveBoxTab} />
        </>
    );
};