import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { useCallback, useMemo } from "react";

import { stopwatchStore } from "features/stopwatch/store";

import { Button, ButtonGroup, Hint } from "sale-bridge-ui-kit";
import { Stopwatch } from "features/stopwatch";

import { QuickActionType, QuickActionTypeName } from "types/entity";

import styles from '../card/card.module.scss';


export const CardQuickActions = observer((props: {
    cardId: string,
    cardTitle: string,
    size: 'small' | 'medium' | 'large',
    isCopied: boolean,
    flagged: boolean,
    quickActions: Array<QuickActionType>,
    onActionButtonClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, action: QuickActionType) => Promise<void>
}) => {

    const isExistStopwatch = useMemo(
        () => stopwatchStore.stopwatches.get(props.cardId) ?? false,
        [stopwatchStore.stopwatches.get(props.cardId)],
    )

    const actionsClasses = classNames(styles.actions, {
        [`${styles.linkCopied} `]: props.isCopied
    });

    

    return <>
        {!isExistStopwatch ? <div className={actionsClasses}>
            <ButtonGroup>
                {
                    props.quickActions?.map((action) => {
                        const hint = (props.isCopied && action == QuickActionType.CopyLink) ? 'Ссылка скопирована!' :
                            (action == QuickActionType.Flag && props.flagged) ? "Убрать флажок" :
                                QuickActionTypeName[action].title;

                        const icon = action == QuickActionType.CopyLink ? props.isCopied ? 'ButtonSuccessCheck' : 'Link' :
                            action == QuickActionType.OpenInNewTab ? 'OpenNewWindow' :
                                action == QuickActionType.Flag ? props.flagged ? 'FlagSolid' : 'Flag' :
                                    action == QuickActionType.LogTime ? 'Clock' :
                                        action == QuickActionType.Stopwatch ? 'Stopwatch' :
                                            undefined;

                        return <Hint key={action} hintBody={hint} startPosition="top" >
                            <Button
                                key={action}
                                leftIcon={icon}
                                onClick={(event) => props.onActionButtonClick(event, action)}
                                text=''
                                size={props.size}
                                iconSize={(action != QuickActionType.CopyLink && action != QuickActionType.OpenInNewTab) ? '16' : undefined}
                                variant={(action == QuickActionType.CopyLink && props.isCopied) ? 'successSecondary' : 'secondary'}
                                border={false}
                                link={false}
                                loading={false}
                            />
                        </Hint>
                    }) ?? []
                }

            </ButtonGroup>
        </div>
            : <Stopwatch recordTitle={props.cardTitle} recordId={props.cardId} view='small' />
        }
    </>
});