import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { isNull, isUndefined } from "lodash";
import { DragDropContext, DragStart, DropResult } from "react-beautiful-dnd";
import { v4 } from "uuid";

import { dispatcher } from "store";
import { singlePageSynchroiser } from "../../../single-page-synchroiser/single-page-synchroiser";
import { movementService } from "./lib";
import { businessRulesEngine } from "features/business-rules-engine";

import { getWidthColumn } from "./lib/get-width-column";
import { Position, modalController } from "features/modals";
import { NotificationRecordWithSort } from "./notifications/notification-record-with-sort";
import { NotificationRecordDenied } from "./notifications/notification-record-denied";

import { KanbanPageColumn } from "./kanban-page-column";

import { IMessage } from "components/kanban-components/components/card/card";
import { KanbanConfig, StageModel } from "types/entity";
import { KanbanPageStage, MAX_COLUMN_FOR_ADAPTATION_WIDTH, MIN_WIDTH_COLUMN } from "./data";

import styles from "./kanban-page.module.scss";

type KanbanProps = {
	stages: KanbanPageStage[];
	size: "small" | "medium" | "large";
	kanbanConfig: KanbanConfig;
	stageModel: StageModel;
	onOpen: (recordId: string, name?: string) => void;
};

/*@example
    cards = [
        {
            name: 'card1',
            id: 'card1',
            color: "#F00",
            flagged: false,
            showActions: true,
            isSelected: false,
            fields: ['text', 'number', 'name', 'loogical'],
            users: ['Ivanov', 'Petrov'],
        },
        {
            name: 'card2',
            id: 'card2',
            color: "#F00",
            flagged: false,
            showActions: true,
            isSelected: false,
            fields: ['text', 'number'],
            users: ['Sidorov', 'Kozlov'],
        }
    ]
    props.items=[
        {
            cards: cards,
            stageName: "stage1",
            order: 1,
            id: "id1",
            allowTo: ["id1", "id3", "id4", "id5"],
            color: "#F00",
        },
        {
            cards: cards,
            stageName: "stage2",
            order: 1,
            id: "id2",
            allowTo: ["id1", "id3", "id4", "id5"],
            color: "#F00",
        }
    ]
*/

export const KanbanPage = observer((props: KanbanProps) => {
	const entityName = useParams().entityName;
	const [idNotification] = useState<string>(v4());
	const kanbanContainerRef = useRef<HTMLDivElement>(null);
	const [draggbleCardId, setDraggbleCardId] = useState<string | null>(null);
	const [columnWidth, setColumnWidth] = useState(MIN_WIDTH_COLUMN);
	const [enableStages, setEnableStages] = useState<string[]>([]);
	const [draggingCardId, setDraggingCardId] = useState<string>("");

	const stages = useMemo((): KanbanPageStage[] => props.stages, [props.stages]);

	useEffect(() => {
		if (stages && stages.length <= MAX_COLUMN_FOR_ADAPTATION_WIDTH) {
			const containerWidth = kanbanContainerRef.current?.getBoundingClientRect().width;
			if (containerWidth) {
				setColumnWidth(getWidthColumn(containerWidth, stages.length));
			}
		}
	}, [toJS(stages)]);

	//TODO реализовать метод открытия quickView (возможно внутри компонента KanbanPageCard)
	const handleOpenCardView = useCallback((id: string) => {}, []);

	//TODO реализовать метод флага (возможно внутри компонента KanbanPageCard)
	const handleFlaggedCard = useCallback((message: IMessage) => {}, []);

	const repositionRecordDeniedWithSourceStage = useCallback(
		(sourceStageId: string) => {
			const sourceName = stages.find((stage) => stage.id === sourceStageId)?.name;
			modalController.notificationAdd({
				id: idNotification,
				position: Position.CENTER,
				layout: <NotificationRecordDenied sourceName={sourceName} />,
				allowDefaultClick: true,
				allowTimer: true
			});
		},
		[idNotification, toJS(stages)]
	);

	const repositionRecordDenied = useCallback(() => {
		modalController.notificationAdd({
			id: idNotification,
			position: Position.CENTER,
			layout: <NotificationRecordDenied sourceName={undefined} />,
			allowDefaultClick: true,
			allowTimer: true
		});
	}, [idNotification]);

	const updateCardFlag = (cardId: string) => {
		singlePageSynchroiser.setCardCanceledId = cardId;
	};

	//TODO необходимо узнавать, что перемещение в рамках одной стадии и выводить
	const repositionRecordWithSort = useCallback((): void => {
		modalController.notificationAdd({
			id: idNotification,
			position: Position.CENTER,
			layout: <NotificationRecordWithSort entityName={entityName} />,
			allowDefaultClick: true,
			allowTimer: true
		});
	}, [idNotification, entityName]);

	//TODO для NotificationRecordDenied и NotificationRecordWithSort неоходима информация о том, куда перемещалась стадия, но handleDragEnd не возвращает такую информацию
	const handleDragEnd = useCallback(
		async ({ destination, source, draggableId }: DropResult) => {
			setDraggbleCardId(null);
			setEnableStages([]);
			const hasSort = Object.keys(dispatcher.entity.get()?.entity.sort || {}).length > 0;

			//TODO необходимо узнавать, что перемещение в рамках одной стадии и выводить
			// repositionRecordWithSort();
			// return;

			if (hasSort && !destination) {
				repositionRecordDenied();
				return;
			}

			if (!destination || !stages) {
				repositionRecordDeniedWithSourceStage(source.droppableId);
				updateCardFlag(draggableId);
				return;
			}

			const start = stages.find((x) => x.id === source.droppableId);
			const finish = stages.find((x) => x.id === destination.droppableId);

			if (!start || !finish) {
				return;
			}
			const sameColumn = start === finish;

			if (hasSort && sameColumn) {
				repositionRecordWithSort();
				return;
			}

			const currentUploadedRecordsStageQuantity = props.stages.find((stage) => stage.id === source.droppableId)?.cards.length ?? 0;

			singlePageSynchroiser.setDraggedRecord(draggableId);

			const currentEntity = dispatcher.entity.get();

			await movementService(
				currentEntity?.entityName!,
				source.droppableId,
				destination.droppableId,
				draggableId,
				destination.index,
				stages,
				{
					hasSort: hasSort,
					sameStage: sameColumn,

					uploadedRecordsStageQuantity: currentUploadedRecordsStageQuantity
				}
			);
			businessRulesEngine.dispose();
		},
		[toJS(stages), entityName, toJS(props.stages), dispatcher.entity.get()?.entity.sort]
	);

	const handleDragStart = useCallback(
		(start: DragStart) => {
			const { source, draggableId } = start;
			setDraggbleCardId(draggableId);
			const movingRulesFrom = props.stageModel.movingRules.filter((movingRule) => movingRule.stageFromId === source.droppableId);
			const enableStages = movingRulesFrom?.map((movingRule) => movingRule.stageToId);
			enableStages?.push(source.droppableId);

			setEnableStages(enableStages ?? []);
			setDraggingCardId("");
			businessRulesEngine.setSectionWizzard(dispatcher.sectionWizzard.getSectionWizzard());
			businessRulesEngine.startTrackingChanges(draggableId);
		},
		[toJS(props.stageModel), dispatcher.entity.get()?.entity.sort, props.stageModel.movingRules]
	);

	return (
		<div ref={kanbanContainerRef} className={styles.kanbanWrapper}>
			<DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
				{props.kanbanConfig &&
					stages &&
					stages.map((column, index) => {
						const position = index === 0 ? "first" : index === stages.length - 1 ? "last" : "intermediate";

						const isDropDisabled =
							!isNull(draggbleCardId) && isUndefined(enableStages.find((enableStage) => enableStage === column.id));
						return (
							<KanbanPageColumn
								id={column.id}
								key={column.id}
								onOpen={props.onOpen}
								isDropDisabled={isDropDisabled}
								columnWidth={columnWidth}
								size={props.size}
								position={position}
								kanbanConfig={props.kanbanConfig}
								column={column}
								draggingCardId={draggingCardId}
								setDraggingCardId={setDraggingCardId}
							/>
						);
					})}
			</DragDropContext>
		</div>
	);
});
