import styles from "../../select.module.css";
import stylesMini from "../ui/hierarchical-select.module.css";

export enum HierarchicalStyles{
    Primary,
    Seccond,
    Opacity
}

export interface IHierarchicalSelectStyles{
    value:IValueStyles;
}

interface IValueStyles{
    classNames:string;
    focus:string;
    invalid:string;
    success:string;
}

const Styles = {
    [HierarchicalStyles.Primary]: {
        value: {
            classNames: `${stylesMini.inputPrimary} `,
            focus: `${stylesMini.focusPrimary} `,
            invalid: `${stylesMini.invalidPrimary} `,
            success: `${stylesMini.successPrimary} `
        }
    },
    [HierarchicalStyles.Seccond]: {
        value: {
            classNames: `${styles.inputWrapper} `,
            focus: "",
            invalid: `${styles.invalid} `,
            success: ""
        }
    },
    [HierarchicalStyles.Opacity]:{
        value: {
            classNames: `${stylesMini.inputOpacity} `,
            focus: "",
            invalid: ``,
            success: ""
        }
    }
}

export default Styles;