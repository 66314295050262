
import { makeAutoObservable } from "mobx";
import axios from "axios";
import { isUndefined } from "lodash";

import { synchroiser } from "synchroiser";
import { LoadingState } from "types/entity";
import { FieldValidationState } from "types";
import { ProgressBarState } from "sale-bridge-ui-kit/dist/components/field/fields";
import { popularPasswords } from "./data";
import authStore from "AuthStore";
import { loginState } from "pages/login/login-state";


/* ---------------- средней сложности регулярка --------------------- */
/**@description старая версия регулярки */
// - (?=.*[a-z]) - должна содержать хотя бы одну строчную букву
// - (?=.*[A-Z]) - должна содержать хотя бы одну заглавную букву
// - (?=.*\d) - должна содержать хотя бы одну цифру
// - (?=.*[!@#$%^&()_+?=.]) - должна содержать хотя бы один из следующих символов: ! @ # $ % ^ & ( ) _ + ? = .
// - [A-Za-z\d!@#$%^&()_+?=.]{6,} - должна состоять только из латинских букв (строчных и заглавных), цифр и специальных символов ! @ # $ % ^ & ( ) _ + ? = . и иметь длину не менее 6 символов
// const regexForPasswordValidate = /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&()_+?=.])[A-Za-z\d!@#$%^&()_+?=.]{6,}/g;

/**@description новая версия регулярки */
// - Содержит хотя бы одну букву.
// - Содержит хотя бы одну цифру.
// - Имеет длину как минимум 8 символов (включая буквы и цифры).
// const regexForPasswordValidate = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
const regexForPasswordValidate = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;

/* ---------------- регулярка "надежный пароль" --------------------- */
/**@description старая версия регулярки */
//Условие для пароля: Пароль должен содержать цифры И буквы верхнего и нижнего регистра И специальные символы. 
//Количество заглавных латинских букв И цифр в таком пароле ≥2.
// const regexForPasswordSuccessState = /(?=.*[A-Z].*[A-Z])(?=.*\d.*\d)(?=.*[!@#$%^&()_+?=.])[A-Za-z\d!@#$%^&()_+?=.]{6,}/g;

/**@description новая версия регулярки */
// - Строка должна содержать хотя бы одну букву (латинскую, как прописную, так и строчную).
// - Строка должна содержать хотя бы одну цифру.
// - Строка должна содержать хотя бы один специальный символ из набора !@#$%^&*()_+{}|”:?><~=;-`',.«»‘’≠—→←∞↑‰©↓ß”“„₽£°§≈✓€®™ѣѵіѳ′.
// - Длина строки должна быть не менее 8 символов.
const regexForPasswordSuccessState = /^(?=.*[A-Za-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}|”:?><~=;-`',.«»‘’≠—→←∞↑‰©↓ß”“„₽£°§≈✓€®™ѣѵіѳ′])[A-Za-z\d!@#$%^&*()_+{}|”:?><~=;-`',.«»‘’≠—→←∞↑‰©↓ß”“„₽£°§≈✓€®™ѣѵіѳ′]{8,}$/;

/* ---------------- регулярка "очень надёжный пароль" --------------------- */
/**@description старая версия регулярки */
//Условие для пароля: Пароль должен содержать цифры И буквы верхнего и нижнего регистра И специальные символы. 
//Количество спецсимволов в таком пароле ≥3.
// const regexForPasswordFullState = /(?=.*[!@#$%^&()_+].*[!@#$%^&()_+].*[!@#$%^&()_+?=.])[A-Za-z\d!@#$%^&()_+?=.]{6,}/g;

/**@description новая версия регулярки */
// - Содержит хотя бы одну строчную букву (a-z)
// - Содержит хотя бы одну заглавную букву (A-Z)
// - Содержит хотя бы одну цифру (0-9)
// - Содержит хотя бы один специальный символ (например, !@#$%^&*()_+{}|”:?><~=;-`',.«»‘’≠—→←∞↑‰©↓ß”“„₽£°§≈✓€®™ѣѵіѳ′)
// - Имеет длину минимум 12 символов
const regexForPasswordFullState = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}|”:?><~=;-`',.«»‘’≠—→←∞↑‰©↓ß”“„₽£°§≈✓€®™ѣѵіѳ′])[A-Za-z\d!@#$%^&*()_+{}|”:?><~=;-`',.«»‘’≠—→←∞↑‰©↓ß”“„₽£°§≈✓€®™ѣѵіѳ′]{12,}$/;

const MIN_SYMBOLS = 6;
export class NewPasswordFormState {
    password: string = '';
    passwordConfirm: string = '';
    errorMessage: string = '';
    loadingState: LoadingState = LoadingState.NotAsked;
    progressBar: ProgressBarState = 'null';

    validation: { [key: string]: FieldValidationState };

    constructor() {
        this.validation = {
            password: {
                isInvalid: false,
            },
            passwordConfirm: {
                isInvalid: false,
            }
        }
        makeAutoObservable(this);
    }

    get isInvalid() {
        this.validation = {
            password: {
                isInvalid: false,
                error: ''
            },
            passwordConfirm: {
                isInvalid: false,
                error: ''
            }
        }
        if (this.password.length === 0) {
            this.validation.password.error = 'Поле обязательно для заполнения';
            this.validation.password.isInvalid = true;
            return true;
        }

        if (this.password.length < MIN_SYMBOLS) {
            this.validation.password.error = 'Пароль должен содержать минимум 8 символов';
            this.validation.password.isInvalid = true;
            return true;
        }
        const mediumInvalid = this.password.replace(regexForPasswordValidate, '').length > 0;
        const successInvalid = this.password.replace(regexForPasswordSuccessState, '').length > 0;
        const fullInvalid = this.password.replace(regexForPasswordFullState, '').length > 0;

        if (mediumInvalid && successInvalid && fullInvalid) {
            this.validation.password.error = 'Пароль должен содержать cтрочные и заглавные латинские буквы, цифры и специальные символы';
            this.validation.password.isInvalid = true;
            return true;
        }

        if (this.passwordConfirm.length === 0) {
            this.validation.passwordConfirm.error = 'Поле обязательно для заполнения';
            this.validation.passwordConfirm.isInvalid = true;
            return true;
        }

        if (this.password !== this.passwordConfirm) {
            this.validation.passwordConfirm.error = 'Пароли не совпадают';
            this.validation.password.error = 'Пароли не совпадают';
            this.validation.passwordConfirm.isInvalid = true;
            this.validation.password.isInvalid = true;
            return true;
        }

        return false;
    }

    set changePassword(value: string) {
        this.password = value;
    }

    set changePasswordConfirm(value: string) {
        this.passwordConfirm = value;
    }

    validatePasswordForProgressBar() {
        if (this.password.length === 0) {
            this.progressBar = 'null';
            return;
        }
        if (this.password.length < MIN_SYMBOLS) {
            this.progressBar = 'shortPassword';
            return;
        }

        const mediumPassword = this.password.replace(regexForPasswordValidate, '').length === 0;
        const success = this.password.replace(regexForPasswordSuccessState, '').length === 0;
        const fullPassword = this.password.replace(regexForPasswordFullState, '').length === 0;
        const badPassword = popularPasswords.includes(this.password) || (!mediumPassword && !success && !fullPassword);

        if (fullPassword) {
            this.progressBar = 'fullPassword';
            return;
        }

        if (success) {
            this.progressBar = 'success';
            return;
        }

        if (mediumPassword) {
            this.progressBar = 'mediumPassword';
            return;
        }

        if (badPassword) {
            this.progressBar = 'badPassword';
            return;
        }
    }

    savePassword = async (userName: string) => {
        if (this.isInvalid) {
            return;
        }
        try {
            this.loadingState = LoadingState.Loading;
            this.errorMessage = ''

            let requestData = {
                password: this.password,
                name: userName,
            }
            const result = await axios.post('/api/User/authenticate', requestData)

            await synchroiser.auth(userName, this.password);

            const data = result.data;
            if (data.success === true) {
                const token = data.accessToken
                const expires = data.expires

                authStore.logIn(token, expires);
                this.loadingState = LoadingState.Successful;
                window.location.href = window.location.hash + '/settings';
                loginState.reset();

            } else {
                this.loadingState = LoadingState.Error;
                console.error('Something went wrong :(');
            }
        } catch (error: any) {
            this.loadingState = LoadingState.Error;
            console.error(error);
        }
    }
}