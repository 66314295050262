import { Link } from "react-router-dom";

import styles from "./button.module.css";

export enum ButtonStyle {
    Primary = 0,
    Icon,
    IconIndigo,
    Subtle,
    SubtleFilters,
    Link,
    Success,
    Danger,
    Settings,
    DefaultSettings,
    TextEditor,
    Indigo,
    SectionWizzard,
    LightBlue,
    DragGrayButton,
    IndigoSelect,
    GrayBlue300
}

export interface IButtonProps extends React.DOMAttributes<HTMLButtonElement> {
    caption?: string | JSX.Element;
    classNameCaption?: string;
    firstIcon?: JSX.Element;
    secondIcon?: JSX.Element;
    isRotateSecondIcon?: boolean;
    isOpened?: boolean;
    className?: string;
    classNameContent?: string;
    classNameFirstIcon?: string;
    classNameSecondIcon?: string;
    selected?: boolean;
    maxWidthCaption?: number;

    fullWidth?: boolean;
    isDisabled?: boolean;
    style?: ButtonStyle;
    styleButton?: React.CSSProperties | undefined;
    isVisible?: boolean;
    iconOnRight?: boolean;
    to?: string;
}

function Button(props: IButtonProps) {

    let classNames = `${styles.button} `;
    let classNameFirstIcon = `${styles.icon} `;
    let classNameSecondIcon = `${styles.secondIcon} `;
    let classNamesCaption = `${styles.caption} `;
    let classNamesContent = `${styles.content} `;

    if (props.isVisible !== undefined && !props.isVisible) {
        classNames += `${styles.visible} `
    }

    switch (props.style) {
        case ButtonStyle.Primary:
            classNames += `${styles.primary} `;
            if (props.selected) classNames += `${styles.primarySelected} `;
            classNameFirstIcon += `${styles.svg} `;
            classNameSecondIcon += `${styles.svg} `;
            break;

        case ButtonStyle.Icon:
            classNames += `${styles.iconButton} `;
            break;
        case ButtonStyle.IconIndigo:
            classNames += `${styles.iconButton} ${styles.iconIndigoButton} `;
            if (props.selected) classNames += `${styles.iconIndigoButtonSelected} `;
            classNameFirstIcon += `${styles.svg} `;
            classNameSecondIcon += `${styles.svg} `;
            break;
        case ButtonStyle.Subtle:
            classNames += `${styles.subtle} `;
            if (props.selected) classNames += `${styles.subtleSelected} `;
            classNameFirstIcon += `${styles.svg} `;
            classNameSecondIcon += `${styles.svg} `;
            break;

        case ButtonStyle.SubtleFilters:
            classNames += `${styles.subtle} `;
            if (props.selected) classNames += `${styles.subtleFiltersSelected} `;
            classNameFirstIcon += `${styles.svg} `;
            classNameSecondIcon += `${styles.svg} `;
            break;

        case ButtonStyle.Link:
            classNames += `${styles.whiteLink} `;
            classNameFirstIcon += `${styles.svg} `;
            classNameSecondIcon += `${styles.svg} `;
            break;

        case ButtonStyle.Success:
            classNames += `${styles.success} `;
            if (props.selected) classNames += `${styles.successSelected} `;
            classNameFirstIcon += `${styles.svg} `;
            classNameSecondIcon += `${styles.svg} `;
            break;
        case ButtonStyle.Danger:
            classNames += `${styles.danger} `;
            classNameFirstIcon += `${styles.svg} `;
            classNameSecondIcon += `${styles.svg} `;
            break;
        case ButtonStyle.Settings:
            classNames += `${styles.settings} `;
            classNameFirstIcon += `${styles.svg} `;
            classNameSecondIcon += `${styles.svg} `;
            classNamesCaption += `${styles.settingsCaption}`;
            break;
        case ButtonStyle.DefaultSettings:
            classNames += `${styles.defaultSettings} `;
            classNameFirstIcon += `${styles.svg} `;
            classNameSecondIcon += `${styles.svg} `;
            break;
        case ButtonStyle.TextEditor:
            classNames += `${styles.textEditor} `;
            classNameFirstIcon += `${styles.svg} `;
            classNameSecondIcon += `${styles.svg} `;
            classNamesContent += `${styles.gap8px} `;
            if (props.selected) classNames += `${styles.activeTextEditor} `;
            break;
        case ButtonStyle.Indigo:
            classNames += `${styles.indigoButton} `;
            classNamesContent += `${styles.leftContent} `;
            classNameFirstIcon += `${styles.svg} `;
            classNameSecondIcon += `${styles.svg} `;
            break;
        case ButtonStyle.SectionWizzard:
            classNames += `${styles.sectionWizzard} `;
            classNameFirstIcon += `${styles.svg} `;
            classNameSecondIcon += `${styles.svg} `;
            classNamesContent += `${styles.leftContent} `;
            break;
        case ButtonStyle.LightBlue:
            classNames += `${styles.lightBlueButton} `;
            break;
        case ButtonStyle.DragGrayButton:
            classNames += `${styles.dragGrayButton} `;
            classNameFirstIcon += `${styles.svg} `;
            classNameSecondIcon += `${styles.svg} `;
            classNamesContent += `${styles.dragGrayButtonContent} `;
            break;

        case ButtonStyle.Indigo:
            classNames += `${styles.indigoButton} `;
            classNamesContent += `${styles.leftContent} `;
            classNameFirstIcon += `${styles.svg} `;
            classNameSecondIcon += `${styles.svg} `;
            break;
        case ButtonStyle.IndigoSelect:
            classNames += `${styles.indigoSelectButton} `;
            if (props.selected) classNames += `${styles.indigoSelectButtonSelected} `;
            classNameFirstIcon += `${styles.svg} `;
            classNameSecondIcon += `${styles.svg} `;
            classNamesCaption += `${styles.indigoSelectButtonCaption}`;
            break;
        case ButtonStyle.GrayBlue300:
            classNames += `${styles.grayBlue300} `;
            classNameFirstIcon += `${styles.svg} `;
            classNameSecondIcon += `${styles.svg} `;
            if (props.selected) classNames += `${styles.grayBlue300Selected} `;
            break;
        default:
            classNames += ``
            break;
    }

    if (props.iconOnRight === true) {
        classNameFirstIcon += `${styles.rightIcon} `;
    }
    else {
        classNameFirstIcon += `${styles.leftIcon} `;
    }

    if (props.isDisabled === true) {
        classNames += `${styles.disabled} `
    }

    if (props.classNameFirstIcon) {
        classNameFirstIcon += props.classNameFirstIcon;
    }

    if (props.classNameSecondIcon) {
        classNameSecondIcon += props.classNameSecondIcon;
    }

    if (props.isRotateSecondIcon && props.isOpened) {
        classNameSecondIcon += `${styles.close} `;
    }

    if (props.className) {
        classNames += props.className;
    }
    if (props.classNameCaption) {
        classNamesCaption += props.classNameCaption;
    }
    if (props.classNameContent) {
        classNamesContent += props.classNameContent;
    }
    if (props.to) {
        return (
            <Link to={props.to} className={classNames}>
                <div className={classNamesContent}>

                    {props.firstIcon &&
                        <div className={classNameFirstIcon}>
                            {props.firstIcon}
                        </div>
                    }

                    {props.caption &&
                        <span className={classNamesCaption} style={{ maxWidth: `${props?.maxWidthCaption}px` }}>{props.caption}</span>
                    }

                    {props.secondIcon &&
                        <div className={classNameSecondIcon}>
                            {props.secondIcon}
                        </div>
                    }

                </div>
            </Link>
        );
    }

    return (
        <button
            className={classNames}
            onClick={props.onClick}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            onMouseDown={props.onMouseDown}
            onMouseLeave={props.onMouseLeave}
            onMouseEnter={props.onMouseEnter}
            style={props.styleButton}
        >
            <div className={classNamesContent}>

                {props.firstIcon &&
                    <div className={classNameFirstIcon}>
                        {props.firstIcon}
                    </div>
                }

                {props.caption &&
                    <span className={classNamesCaption} style={{ maxWidth: `${props?.maxWidthCaption}px` }}>{props.caption}</span>
                }

                {props.secondIcon &&
                    <div className={classNameSecondIcon} >
                        {props.secondIcon}
                    </div>
                }

            </div>
        </button>
    );
}

export default Button;