import { observer } from "mobx-react-lite";

import { InputHeader } from "./inputs/input-header/input-header";

import styles from "./input.module.scss";
import classNames from "classnames";


export interface InputHeaderProps extends React.HTMLAttributes<HTMLElement> {
    name: string,
    caption: string,
    required?: boolean,
    infoCaption?: string,
	noBorder?: boolean,
};
/**
 * @description InputWrapper обертка для полей input
 * @argument InputHeader - шапка
 * @argument props.children - можно передать любой компонент
 * @param name название input
 * @param caption текст шапки
 * @param required обязательность *
 * @param infoCaption текст подсказки 
 * @param noBorder отсутствие border
 */

export const InputWrapper = observer((props: InputHeaderProps) => {
	const wrapperBorderStyle = classNames(`${styles.wrapper} ${styles.borderBottom}`,
		{ [`${styles.wrapper} ${styles.borderNone}`]: props.noBorder },
	);

	return (
		<div className={styles.container}>
			<InputHeader 
				name={props.name} 
				caption={props.caption}
				required={props.required} 
				infoCaption={props.infoCaption}
			/>
			<div className={wrapperBorderStyle}>
				{props.children}
			</div>
		</div>
	);
});