import { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";

import { Entity, dispatcher } from "store";

import DisplayedColumns from "./displayed-columns";
import gridParametersState from "./grid-parameters-state";
import DisplayOrder from "./display-order";

import { ButtonStyle, Button, Tabs, ITab, Hint, HintDirection } from "components";

import { CloseMaxi } from "shared";

import styles from "./grid-parameters-popup.module.scss";
import { toJS } from "mobx";

interface GridParametersPopupProps {
	entity: Entity | undefined;
	close: () => void;
}

export const GridParametersPopup = observer((props: GridParametersPopupProps) => {
	const [tabBody, setTabBody] = useState<JSX.Element | null>(<DisplayedColumns />);

	useEffect(() => {
		gridParametersState.initColumns(props.entity ? [...props.entity.entity.visibleColumns] : [], props.entity);
	}, [props.entity]);

	const handleSave = useCallback(() => {
		props.close();
		gridParametersState.save();
	}, [props.close]);

	const handleCancel = useCallback(() => {
		gridParametersState.reset();
		props.close();
	}, [props.close]);

	const items: ITab[] = useMemo(
		() => [
			{
				caption: "Отображение",
				action: () => {
					setTabBody(<DisplayedColumns />);
				}
			},
			{
				caption: "Порядок",
				action: () => {
					setTabBody(<DisplayOrder />);
				}
			}
		],
		[gridParametersState]
	);

	return (
		<div className={styles.dialog}>
			<div className={styles.header}>
				<span className={styles.title}>Параметры таблицы</span>
				<CloseMaxi className={styles.closeButton} onClick={handleCancel} />
			</div>
			<div className={styles.dialogBody}>
				<Tabs items={items} />
				{tabBody}
			</div>
			<div className={styles.divider} />
			<div className={styles.dialogFooter}>
				<Button caption="Отменить" onClick={handleCancel} style={ButtonStyle.Subtle} />
				{gridParametersState.isNoneSelected ? (
					<Hint tooltip="Выберите хотя бы одну колонку" direction={HintDirection.Up}>
						<Button
							caption="Сохранить"
							onClick={handleSave}
							style={ButtonStyle.Primary}
							isDisabled={gridParametersState.isNoneSelected}
						/>
					</Hint>
				) : (
					<Button
						caption="Сохранить"
						onClick={handleSave}
						style={ButtonStyle.Primary}
						isDisabled={gridParametersState.isNoneSelected}
					/>
				)}
			</div>
		</div>
	);
});
