import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { v4 } from "uuid";
import classNames from "classnames";
import { isUndefined } from "lodash";

import { dispatcher, store } from "store";
import { modalController } from "features/modals";
import {
	FieldConfigurationWrapper,
	detailConfigurationStore,
	fieldConfigurationStore,
	fieldGroupConfigurationStore
} from "pages/section-wizzard/pages/constructor";

import { Zone } from "modules/DND/zone";
import { Element } from "modules/DND/element";
import { multiTypeDragBlocks } from "./multi-type-drag-block";
import { Button, ButtonStyle } from "components";

import { ColumnType } from "entities/ColumnType";
import { ColumnSpecializationType, GridItem, ItemType } from "types/entity";
import { Types } from "modules/DND/type";
import { DEFAULT_WARNING_TEXT } from "pages/section-wizzard/data/data";

import TypeIcon from "features/type-icon/type-icon";
import { DragIcon, Warning } from "shared";

import styles from "./drag-zone.module.css";

export interface IDragElement {
	id: string;
	title: string;
	x: number;
	y: number;
	inner?: {
		items: Array<IDragElement>;
	};
	width: number;
	height: number;
	type: any;
	fieldType: ItemType | undefined;
}

const CELL_HEIGHT = 48;

const POST_INIT_TIME = 500;

const MINIMAL_ROW_QUALITY = 11;

export const DragZone = observer(() => {
	const [gridItems, setGridItems] = useState<GridItem[]>([]);
	const [zoneGrid, setZoneGrid] = useState<JSX.Element[]>([]);

	const [rowQualityByGridList, setRowQualityByGridList] = useState(MINIMAL_ROW_QUALITY);
	const [rowQualityByScreen, setRowQualityByScreen] = useState(MINIMAL_ROW_QUALITY);

	const [cellLayoutWidth, setCellLayoutWidth] = useState(0);
	const [targetElementViewLayout, setTargetElementViewLayout] = useState<JSX.Element>(<></>);

	const [zoneId] = useState(v4());
	const [idConfirm] = useState<string>(v4());
	const [idModal] = useState<string>(v4());

	const container = useRef<HTMLDivElement>(null);

	const sectionWizard = useMemo(() => {
		return dispatcher.sectionWizzard.getSectionWizzard();
	}, [toJS(dispatcher.sectionWizzard.getSectionWizzard())]);

	const currentTabIndex = useMemo(() => {
		return sectionWizard?.reactorConfig.tabs.currentTab ?? 0;
	}, [sectionWizard?.reactorConfig.tabs.currentTab]);

	const isNew = useMemo(() => {
		return dispatcher.entity.get()?.isNew;
	}, [dispatcher.entity.get()?.isNew]);

	const heightCount = useMemo(() => {
		return Math.max(rowQualityByGridList, rowQualityByScreen, MINIMAL_ROW_QUALITY);
	}, [rowQualityByGridList, rowQualityByScreen]);

	const itemsInConfig = useMemo(() => {
		return sectionWizard?.reactorConfig.tabs.tabsConfig.find((tabConfig) => tabConfig.tabIndex == currentTabIndex)?.grid.items;
	}, [toJS(sectionWizard?.reactorConfig.tabs.tabsConfig.find((tabConfig) => tabConfig.tabIndex == currentTabIndex)?.grid.items)]);

	const closeConfirm = useCallback(() => {
		modalController.modalRemove(idConfirm);
	}, []);

	const closeAllModals = useCallback(() => {
		store.modals.map((modal) => {
			modalController.modalRemove(modal.id);
		});
		fieldConfigurationStore.resetConfiguration();
		detailConfigurationStore.resetConfiguration();
		fieldGroupConfigurationStore.resetConfiguration();
	}, [store.modals]);

	const warningConfirm = useMemo(() => {
		return (
			<div className={styles.warningDialog}>
				<div className={styles.warningHeader}>
					<span className={styles.warningTitle}>Внимание</span>
					<Warning />
				</div>
				<div className={styles.warningDialogBody}>
					<span className={styles.title}>{DEFAULT_WARNING_TEXT}</span>
				</div>
				<div className={styles.dialogFooter}>
					<Button caption="Вернуться к редактированию" onClick={closeConfirm} style={ButtonStyle.Subtle} />
					<Button caption="Да, отменить" onClick={closeAllModals} style={ButtonStyle.Danger} />
				</div>
			</div>
		);
	}, [closeAllModals, closeConfirm, sectionWizard]);

	const closeFuncWithConfirm = useCallback(() => {
		modalController.popupAdd({ id: idConfirm, layout: warningConfirm, closeFunc: closeConfirm });
	}, [idConfirm]);

	const closeOnFocusModal = () => {
		if (fieldConfigurationStore.hasChanges) {
			closeFuncWithConfirm();
		} else {
			closeAllModals();
		}
	};

	const updateZoneElements = useCallback(() => {
		if (itemsInConfig && container.current) {
			var cloneItems = JSON.parse(JSON.stringify(itemsInConfig)) as GridItem[];
			setGridItems(cloneItems);
			if (itemsInConfig.length > 0 && itemsInConfig.find((item) => item.x !== -1 && item.y !== -1)) {
				const maxYElement = itemsInConfig.reduce((maxYElement, currentElement) =>
					maxYElement.y > currentElement.y ? maxYElement : currentElement
				);
				setRowQualityByGridList(maxYElement.y + 1);
			}
			setZoneGrid(zoneElements(cloneItems, container.current.getBoundingClientRect().width / 2));
		}
	}, [toJS(itemsInConfig), container.current]);

	const handleEditField = useCallback(
		(columnId: string) => {
			let finded = itemsInConfig?.find((item) => item.fieldConfig?.columnId === columnId);
			if (!finded) {
				itemsInConfig?.forEach((groupField) =>
					groupField.groupFieldsConfig?.inner?.items.forEach((item) => {
						if (item.fieldConfig?.columnId === columnId) {
							finded = item;
						}
					})
				);
			}
			if (finded) {
				fieldConfigurationStore.getConfigurationById(columnId);
				modalController.popupAdd({
					id: idModal,
					layout: (
						<FieldConfigurationWrapper
							fieldType={finded?.fieldConfig?.columnType as ColumnType}
							position={{ cellX: -1, cellY: -1 }}
							columnId={columnId}
							close={closeFuncWithConfirm}
							closeAll={closeAllModals}
						/>
					),
					closeFunc: closeOnFocusModal
				});
			}
		},
		[idModal, toJS(itemsInConfig)]
	);

	const handleDeleteField = useCallback(
		(gridItemId: string) => {
			itemsInConfig?.forEach((item) => {
				if (item.groupFieldsConfig) {
					const findInGroup = item.groupFieldsConfig.inner?.items.find((itemInGroup) => itemInGroup.gridItemId === gridItemId);
					if (findInGroup) {
						if (findInGroup.fieldConfig) {
							const isFlagField = dispatcher.sectionWizzard.isSpecificField(
								findInGroup.fieldConfig,
								ColumnSpecializationType.Flag,
								{ sysFlagColumn: "true" }
							);
							const isFlagDateField = dispatcher.sectionWizzard.isSpecificField(
								findInGroup.fieldConfig,
								ColumnSpecializationType.Flag,
								{ sysFlagDateColumn: "true" }
							);

							if (
								!dispatcher.sectionWizzard.isBoxField(findInGroup.fieldConfig.columnName) &&
								!dispatcher.sectionWizzard.isPriorityOrStageField(findInGroup.fieldConfig) &&
								!isFlagField &&
								!isFlagDateField &&
								isNew
							) {
								dispatcher.sectionWizzard.deleteGridItemFromTab(findInGroup.gridItemId, currentTabIndex, item.gridItemId);
							} else {
								dispatcher.sectionWizzard.hideField(findInGroup.gridItemId, currentTabIndex, item.gridItemId);
							}
						}
					} else if (gridItemId === item.gridItemId) {
						if (item.fieldConfig) {
							const isFlagField = dispatcher.sectionWizzard.isSpecificField(item.fieldConfig, ColumnSpecializationType.Flag, {
								sysFlagColumn: "true"
							});
							const isFlagDateField = dispatcher.sectionWizzard.isSpecificField(
								item.fieldConfig,
								ColumnSpecializationType.Flag,
								{
									sysFlagDateColumn: "true"
								}
							);

							if (
								!dispatcher.sectionWizzard.isBoxField(item.fieldConfig.columnName) &&
								!dispatcher.sectionWizzard.isPriorityOrStageField(item.fieldConfig) &&
								!isFlagField &&
								!isFlagDateField &&
								isNew
							) {
								dispatcher.sectionWizzard.deleteGridItemFromTab(item.gridItemId, currentTabIndex);
								if (sectionWizard?.viewColumnId === item?.fieldConfig?.columnId) {
									dispatcher.sectionWizzard.setViewColumnId(null);
								}
							} else {
								dispatcher.sectionWizzard.hideField(item.gridItemId, currentTabIndex);
							}
						}
					}
				}
			});
		},
		[currentTabIndex, isNew, toJS(itemsInConfig), sectionWizard?.viewColumnId]
	);

	const fieldElement = useCallback((gridItem: GridItem, width: number) => {
		const resultWidth: string = (width * gridItem.width - 20).toString();
		const type = gridItem?.fieldConfig?.columnType as ColumnType;
		const GridItem = multiTypeDragBlocks[type] ?? ((props: any) => <></>);
		return (
			<GridItem
				element={gridItem}
				width={`${resultWidth}px`}
				icon={<TypeIcon type={gridItem.fieldConfig?.columnType} />}
				onEditField={handleEditField}
				onDeleteField={handleDeleteField}
			/>
		);
	}, []);

	const groupElement = useCallback((gridItem: GridItem, width: number) => {
		const gridItemClassName = classNames(styles.fieldWrapper, styles.bigGridItemWrap);
		const resultWidth: string = (width * gridItem.width - 20).toString();
		let rowQuality = 1;
		if (gridItem.groupFieldsConfig?.inner?.items && gridItem.groupFieldsConfig?.inner?.items.length > 0) {
			const maxYElement = gridItem.groupFieldsConfig?.inner?.items.reduce((maxYElement, currentElement) =>
				maxYElement.y > currentElement.y ? maxYElement : currentElement
			);
			rowQuality = isUndefined(maxYElement) || maxYElement.y === -1 ? 1 : maxYElement.y + 1;
		}
		const GridItem = multiTypeDragBlocks[ColumnType.FieldGroup] ?? ((props: any) => <></>);
		return (
			<div style={{ width: `${resultWidth}px` }} className={gridItemClassName}>
				<GridItem element={gridItem} rowQuality={rowQuality} onEditField={handleEditField} onDeleteField={handleDeleteField} />
			</div>
		);
	}, []);

	const detailElement = useCallback((gridItem: GridItem, width: number) => {
		const resultWidth: string = (width * gridItem.width - 20).toString();
		const GridItem = multiTypeDragBlocks[ColumnType.Detail] ?? ((props: any) => <></>);
		const gridItemClassName = classNames(styles.fieldWrapper, styles.bigGridItemWrap, styles.detailWrap);
		return (
			<div style={{ width: `${resultWidth}px` }} className={gridItemClassName}>
				<GridItem element={gridItem} />
			</div>
		);
	}, []);

	const zoneElements = useCallback(
		(elements: GridItem[], width: number) => {
			return elements.map((element) => {
				return (
					<Element
						key={element.gridItemId}
						id={element.gridItemId}
						x={element.x}
						y={element.y}
						width={element.width}
						height={element.height}
					>
						{element.type === ItemType.Detail
							? detailElement(element, width)
							: element.type === ItemType.FieldGroup
							? groupElement(element, width)
							: fieldElement(element, width)}
					</Element>
				);
			});
		},
		[detailElement, groupElement, fieldElement]
	);

	useEffect(() => {
		const updateContainerSize = () => {
			if (container.current) {
				setRowQualityByScreen(Math.floor(container.current.getBoundingClientRect().height / CELL_HEIGHT));
				setCellLayoutWidth(container.current.getBoundingClientRect().width / 2);
			}
		};
		const createZoneElements = () => {
			if (itemsInConfig && container.current) {
				var cloneItems = JSON.parse(JSON.stringify(itemsInConfig)) as GridItem[];
				setGridItems(cloneItems);
				setZoneGrid(zoneElements([...itemsInConfig], container.current!.getBoundingClientRect().width / 2));
			}
		};
		updateContainerSize();
		createZoneElements();
		window.addEventListener("resize", updateContainerSize);
		return () => {
			window.removeEventListener("resize", updateContainerSize);
		};
	}, []);

	useEffect(() => {
		if (!store.options.isDisabledConstructorInSectionWizzard) {
			if (itemsInConfig) {
				if (JSON.stringify(itemsInConfig) !== JSON.stringify(gridItems)) {
					updateZoneElements();
				}
			}
		}
	}, [toJS(itemsInConfig)]);

	const handleDrop = useCallback(
		({
			elementId,
			sourceZone,
			targetZone,
			MATRIX
		}: {
			elementId: string | null;
			sourceZone: string | null;
			targetZone: string | null;
			type: Types;
			sourceData?: any;
			[key: string]: any;
		}) => {
			if (MATRIX.cellY === heightCount) {
				setRowQualityByGridList(heightCount + 1);
			}
			if (itemsInConfig) {
				const inFieldGroup = itemsInConfig.find(
					({ gridItemId, groupFieldsConfig }) => (gridItemId ?? groupFieldsConfig?.groupFieldId) === targetZone
				);
				const dragElement = itemsInConfig.find(({ gridItemId }) => gridItemId === elementId);
				if (
					!dispatcher.sectionWizzard.checkExistGridItemByPosition(
						MATRIX.cellX,
						MATRIX.cellY,
						dragElement?.type ?? ItemType.Field
					) ||
					(itemsInConfig.find((item) => item.gridItemId === targetZone) &&
						!dispatcher.sectionWizzard.checkExistGridItemInFieldGroupByPosition(MATRIX.cellX, MATRIX.cellY, targetZone!))
				) {
					if (inFieldGroup) {
						if (dragElement && dragElement.type !== ItemType.Detail && dragElement.type !== ItemType.FieldGroup) {
							const gridItem: GridItem = {
								...dragElement,
								x: MATRIX.cellX,
								y: MATRIX.cellY,
								gridItemId: dragElement?.gridItemId,
								width: dragElement?.width,
								height: dragElement?.height
							};
							dispatcher.sectionWizzard.autoPut({
								tabIndex: currentTabIndex ?? 0,
								targetId: targetZone || "",
								sourceId: sourceZone,
								item: gridItem
							});
						} else {
							const innerDragElement = inFieldGroup?.groupFieldsConfig?.inner?.items.find(
								({ gridItemId }) => gridItemId === elementId
							);
							if (innerDragElement) {
								const gridItem: GridItem = {
									...innerDragElement,
									x: MATRIX.cellX,
									y: MATRIX.cellY,
									gridItemId: innerDragElement?.gridItemId,
									width: innerDragElement?.width,
									height: innerDragElement?.height
								};
								dispatcher.sectionWizzard.autoPut({
									tabIndex: currentTabIndex ?? 0,
									targetId: targetZone || "",
									sourceId: sourceZone,
									item: gridItem
								});
							}
						}
					} else {
						if (dragElement) {
							const gridItem: GridItem = {
								...dragElement,
								x: MATRIX.cellX,
								y: MATRIX.cellY,
								gridItemId: dragElement?.gridItemId,
								width: dragElement?.width,
								height: dragElement?.height
							};
							dispatcher.sectionWizzard.autoPut({
								tabIndex: currentTabIndex ?? 0,
								targetId: targetZone || "",
								sourceId: sourceZone,
								item: gridItem
							});
						}
					}
				}
			}
		},
		[heightCount, toJS(itemsInConfig), currentTabIndex]
	);

	const handleUp = useCallback(
		({ elementId }: { elementId: string }) => {
			if (itemsInConfig) {
				const gridItem = itemsInConfig.find((item) => item.gridItemId === elementId);
				if (gridItem) {
					const fieldClassName = classNames(`${styles.fieldWrapper} ${styles.targetElementViewLayout} `, {
						[`${styles.fillField} `]:
							gridItem?.fieldConfig?.columnTitle?.length ??
							gridItem.detailConfig?.detailTitle ??
							gridItem.groupFieldsConfig?.title ??
							false
					});

					const itemType = gridItem.detailConfig
						? ColumnType.Detail
						: gridItem.groupFieldsConfig
						? ColumnType.FieldGroup
						: gridItem.fieldConfig?.columnType;
					const itemTitle =
						gridItem.fieldConfig?.columnTitle ?? gridItem.detailConfig?.detailTitle ?? gridItem.groupFieldsConfig?.title;
					setTargetElementViewLayout(
						<div style={{ width: `calc(${cellLayoutWidth}px * 0.4)` }} key={gridItem.gridItemId} className={fieldClassName}>
							<DragIcon />
							<TypeIcon type={itemType} />
							<span className={styles.targetElementTitle}>{itemTitle}</span>
						</div>
					);
				}
			}
		},
		[cellLayoutWidth, toJS(itemsInConfig)]
	);

	const cellLayout = useMemo(() => {
		return (
			<div style={{ width: cellLayoutWidth - 10, height: `${CELL_HEIGHT}px` }}>
				<div className={styles.fieldWrapper} />
			</div>
		);
	}, [cellLayoutWidth]);

	const zoneConfig = useMemo(() => {
		return {
			targetElementViewLayout: targetElementViewLayout,
			cellLayout: cellLayout,
			placeholderLayout: cellLayout,
			predictLayout: <div className={styles.predictLayout} />,
			width: 2,
			height: heightCount
		};
	}, [targetElementViewLayout, cellLayout, heightCount]);

	return (
		<>
			{!store.options.isDisabledConstructorInSectionWizzard ? (
				<div ref={container} style={{ height: "100%" }}>
					<Zone
						id={zoneId}
						config={zoneConfig}
						type={Types.MATRIX}
						postInitTime={POST_INIT_TIME}
						onDrop={handleDrop}
						onUp={handleUp}
					>
						{zoneGrid}
					</Zone>
				</div>
			) : (
				<></>
			)}
		</>
	);
});
