import { ItemDropdown } from "components/item-dropdown";
import { Item } from "types";

import styles from "./hierarchical-item.module.css";

function HierarchicalItem(props: { value: Item, onClick: (item: Item) => void, isCanOpen?: boolean}): JSX.Element {
    const value = props.value;
    
    if (value.multilevelItems===undefined) {
        return (
            <span onClick={() => props.onClick(value)} className={styles.displayValue}>{value.name}</span>
        )
    }
    
    function render(value: Item): JSX.Element {
        return <HierarchicalItem value={value} onClick={props.onClick} />
    }
    
    return (
        <ItemDropdown
            items={value.multilevelItems}
            value={<span>{value.name}</span>}
            onItemClick={(val)=>props.onClick(val)}
            renderFunction={(val)=>render(val)}
            isCanOpen={props.isCanOpen}
        />
    )
}

export default HierarchicalItem;