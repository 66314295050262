import { observer } from "mobx-react";
import { useCallback } from "react";
import { toJS } from "mobx";
import { isUndefined } from "lodash";
import classNames from "classnames";

import { Button, Clue, Hint } from "sale-bridge-ui-kit";
import { ActionBlock } from "../block-condition";

import { Action } from "types/entity";
import { Item } from "types";
import { DropdownItemsId } from "../data";
import { BusinessRuleEditorValidation } from "pages/section-wizzard/pages/business-rules/business-rule-editor-store";

import styles from "./actions-builder.module.scss";

export const ActionsBuilder = observer(
	(props: {
		block: "action" | "elseAction";
		actions: Action[];
		setField: (value: Item | null, elseActionId: string) => void;
		setActionType: (value: Item | null, elseActionId: string, needResetValidation?: boolean) => void;
		setValue: (value: string | Item | null, actionId: string, needResetValidation?: boolean) => void;
		onAdd: () => void;
		getValidation?: (id: string) => BusinessRuleEditorValidation | undefined;
	}) => {
		const wrapStyles = classNames(styles.actions, {
			[styles.doBlockColor]: props.block === "action",
			[styles.elseBlockColor]: props.block === "elseAction"
		});

		const headerStyles = classNames({
			[styles.doBlockHeader]: props.block === "action",
			[styles.elseBlockHeader]: props.block === "elseAction"
		});

		const handleChangeDropdownItems = useCallback(
			(id: string, action: Action) => {
				const actionIndex = props.actions.findIndex((currentAction) => currentAction.id === action.id);
				if (isUndefined(actionIndex) || actionIndex == -1) {
					return;
				}
				switch (id) {
					case DropdownItemsId.Duplicate:
						const duplicate = toJS(action);
						props.actions.splice(actionIndex + 1, 0, duplicate);
						break;
					case DropdownItemsId.Delete:
						props.actions.splice(actionIndex, 1);
						break;
				}
			},
			[toJS(props.actions)]
		);

		return (
			<div className={wrapStyles}>
				<div className={styles.actionsHeader}>
					<span className={headerStyles}>{props.block === "action" ? "Блок «ТО»" : "Блок «ИНАЧЕ»"}</span>
					<Hint
						hintBody={
							props.block === "action"
								? "Укажите действия, которые будут выполнены, если условия в блоке «ЕСЛИ» верны. Для работы правила нужно как минимум одно действие."
								: "Укажите действия, которые будут выполнены, если условия в блоке «ЕСЛИ» не верны."
						}
						startPosition="left"
					>
						<Clue textClue="Что здесь указывать" size="small" />
					</Hint>
				</div>
				{props.actions.length > 0 && (
					<div className={styles.actionList}>
						{props.actions.map((action, index) => (
							<ActionBlock
								key={index}
								action={action}
								handleChangeDropDown={handleChangeDropdownItems}
								setField={props.setField}
								setActionType={props.setActionType}
								setValue={props.setValue}
								validation={props.getValidation?.(action.id)}
							/>
						))}
					</div>
				)}
				<Button
					text="Добавить условие"
					onClick={props.onAdd}
					size="small"
					variant="backless"
					leftIcon="Add"
					link={false}
					loading={false}
					border={false}
				/>
			</div>
		);
	}
);
