import { DeleteIcon, Export, Import, ViewHistory } from "shared";
import { Item } from "types";

export enum ActionEnums {
	ViewHistory,
	Import,
	Export,
	CancelChanges
}

export const actionsSectionWizzard = [
	{
		id: ActionEnums.ViewHistory,
		name: "Показать историю изменений"
	},
	{
		id: ActionEnums.Import,
		name: "Импортировать из файла..."
	},
	{
		id: ActionEnums.Export,
		name: "Экспортировать"
	},
	{
		id: ActionEnums.CancelChanges,
		name: "Сбросить текущие изменения"
	}
];
