import Entity from "entities/Entity";
import { User } from "entities/user/User";
import GetFormattedPhone from "entities/GetFormattedPhone";

import {
	Fio,
	PhonePlain,
	Department,
	TimeZone,
	ThemeDesign,
	Notifications,
	Password,
	Authentication,
	PrivacyIcon,
	ProfileNoName,
	DeactivateUser,
	Position
} from "shared";

import styles from "./../profile.module.css";

export const generalInformationData = (entity: Entity<User>) => {
	return [
		{
			icon: <Fio />,
			caption: "ФИО",
			content: entity.entity.name,
			isNotMoving: true
		},
		{
			icon: <PhonePlain />,
			caption: "Мобильный телефон",
			content: GetFormattedPhone(entity.entity.phone),
			isNotMoving: true
		},
		{
			icon: <Department />,
			caption: "Отдел",
			content: "Отдел продаж",
			isNotMoving: true
		},
		{
			icon: <Position />,
			caption: "Должность",
			content: entity.entity.position,
			isNotMoving: true
		},
		{
			icon: <TimeZone />,
			caption: "Часовой пояс",
			content: "+4 GMT, Самара",
			isNotMoving: true
		}
	] as {
		icon: JSX.Element;
		caption: string;
		content?: string;
		isNotMoving?: boolean;
		classNameCaption?: string;
	}[];
};

export const personalizationData = [
	{
		icon: <ThemeDesign />,
		caption: "Тема оформления"
	},
	{
		icon: <Notifications />,
		caption: "Уведомления"
	}
];

export const securityData = [
	{
		icon: <Password />,
		caption: "Изменить или сбросить пароль"
	},
	{
		icon: <Authentication />,
		caption: "Двухфакторная аутентификация"
	}
];

export const privacyData = [
	{
		icon: <PrivacyIcon />,
		caption: "Видимость личной информации"
	}
];

export const defaultData = [
	{
		icon: <ProfileNoName />,
		caption: "#В разработке#"
	},
	{
		icon: <ProfileNoName />,
		caption: "#В разработке#"
	}
];

export const deactivateUserData = [
	{
		icon: <DeactivateUser />,
		caption: "Деактивировать пользователя",
		classNameCaption: styles.deactivateUser
	}
];
