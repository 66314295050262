import { action, makeAutoObservable, makeObservable, observable } from "mobx";
import { v4 } from "uuid";

import { dispatcher } from "store/store-dipatcher/dispatcher";
import { selector } from "store/store-selector";

import { AdditionalField, ColumnSpecializationType, KanbanConfig, QuickActionType, QuickViewDesign } from "types/entity";


export class KanbanQuickViewController {

	oldKanbanQuickViewConfig: QuickViewDesign | null = null;
	hasChanges: boolean;

	constructor() {
		makeAutoObservable(this);
		this.hasChanges =  false;
}
	/**
	* @description Инициализация конфига канбана.
	*/
	init = (): void => {
		const sectionWizzard = dispatcher.sectionWizzard.getSectionWizzard();
		if (sectionWizzard && !sectionWizzard.kanbanConfig) {
			const basicKanbanModel: KanbanConfig = {
				cardDesign: {
					userAvatarEnable: false,
					quickActionEnable: false,
					additionalFields: [],
					userFields: [],
					selectedQuickActions: [
						{
							action: QuickActionType.CopyLink,
							isEnabled: true,
							isHidden: false
						},
						{
							action: QuickActionType.OpenInNewTab,
							isEnabled: true,
							isHidden: false
						},
                        {
							action: QuickActionType.Flag,
							isEnabled: false,
							isHidden: !dispatcher.sectionWizzard.getSectionWizzard()?.hasFlag
						},
						{
							action: QuickActionType.LogTime,
							isEnabled: false,
							isHidden: !dispatcher.sectionWizzard.getSectionWizzard()?.hasTimeLogging
						},
						{
							action: QuickActionType.Stopwatch,
							isEnabled: false,
							isHidden: !dispatcher.sectionWizzard.getSectionWizzard()?.hasTimer
						},
					]
				},
				quickViewDesign: {
					quickViewEnable: true,
					leftBlockAdditionalFields: [],
					rightBlockAdditionalFields: [],
					commentsEnable: false
				}
			}
			sectionWizzard.kanbanConfig = basicKanbanModel;

		}
		this.oldKanbanQuickViewConfig = (JSON.parse(JSON.stringify(selector.kanbanQuickView.getQuickViewDesign())) as QuickViewDesign) ?? null;
	};

	/**
	 * @description Метод для изменения массива полей правого блока без изменения заблокированных полей
	 * @param newRightBlockAdditionalFields - новый массив полей для правгого блока
	 */
	setRightBlockAdditionalFields = action((newRightBlockAdditionalFields: AdditionalField[]): void => {
		if (selector.kanbanQuickView.getQuickViewDesign()) {
			const blockedFields = selector.kanbanQuickView.getQuickViewDesign()!.rightBlockAdditionalFields.filter(field => field.isBlocked);
			const newArray = blockedFields.concat(newRightBlockAdditionalFields);
			selector.kanbanQuickView.getQuickViewDesign()!.rightBlockAdditionalFields = newArray;
		}
	})

	/**
	 * @description Метод для деактивации/активации QuickView
	 * @param value
	 */
	setQuickViewEnable = action((value: boolean): void => {
		if (selector.kanbanQuickView.getQuickViewDesign()) {
			selector.kanbanQuickView.getQuickViewDesign()!.quickViewEnable = value;
		}
	})

	/**
	 * @description Метод для деактивации/активации комментариев в QuickView
	 * @param value 
	 */
	setCommentsEnable = action((value: boolean): void => {
		if (selector.kanbanQuickView.getQuickViewDesign()) {
			this.hasChanges = true;
			selector.kanbanQuickView.getQuickViewDesign()!.commentsEnable = value;
		}
	})

	/**
	 * @description Метод для обновления полей в QuickView
	 * @param field - новое/обновленное поле, которое необходимо добавить/обновить
	 * @param newPosition - позиция, на которую необходимо поставить поле
	 * @param isLeftFieldBlock - флаг, отвечающий за определения блока полей для изменения (левого или правого)
	 */
	updateAdditionalField = action((field: AdditionalField, newPosition: number, isLeftFieldBlock?: boolean): void => {
		let blockAdditionalFields = isLeftFieldBlock ? selector.kanbanQuickView.getQuickViewDesign()?.leftBlockAdditionalFields : selector.kanbanQuickView.getRightFieldsWithoutBlocked();
		if (!blockAdditionalFields) {
			return;
		}
		const findedIndex = blockAdditionalFields.findIndex(item => item.gridItemId === field.gridItemId);
		const duplicatePosition = blockAdditionalFields.findIndex(item => item.order === newPosition);

		if (findedIndex !== -1) {
			const oldPosition = blockAdditionalFields[findedIndex].order;

			blockAdditionalFields = blockAdditionalFields.map((leftField, index) => {
				if (oldPosition < newPosition) {
					if ((duplicatePosition > -1 && index > (oldPosition - 1) && index <= (newPosition - 1))
						|| (index > (oldPosition - 1) && index < (newPosition - 1))
					) {
						const newField: AdditionalField = {
							...leftField,
							order: leftField.order - 1
						}
						return newField
					}
				}
				else {
					if ((duplicatePosition > -1 && index >= (newPosition - 1) && index < (oldPosition - 1))
						|| (index > (newPosition - 1) && index < (oldPosition - 1))) {
						const newField: AdditionalField = {
							...leftField,
							order: leftField.order + 1
						}
						return newField
					}
				} return leftField;
			})
			if (newPosition == (blockAdditionalFields.length + 1)) {
				blockAdditionalFields[findedIndex] = { ...field, order: field.order - 1 };
			}
			else {
				blockAdditionalFields[findedIndex] = field;
			}
		}
		else {
			if (duplicatePosition > -1) {
				blockAdditionalFields = blockAdditionalFields.map((leftField, index) => {
					if (index >= duplicatePosition) {
						const newField: AdditionalField = {
							...leftField,
							order: leftField.order + 1
						}
						return newField
					}
					return leftField;
				})

			}
			blockAdditionalFields.push(field);
		}
		blockAdditionalFields.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));

		if (isLeftFieldBlock) {
			selector.kanbanQuickView.getQuickViewDesign()!.leftBlockAdditionalFields = blockAdditionalFields;
		} else {
			this.setRightBlockAdditionalFields(blockAdditionalFields);
		}
	})

	/**
	 * @description Метод для создания дубля поля в QuickView
	 * @param field - поле, которое необходимо добавить в качестве дубля
	 * @param newPosition - позиция, на которую необходимо поставить дубль
	 * @param isLeftFieldBlock - флаг, отвечающий за определения блока полей для дубля (левого или правого)
	 */
	createDuplicateAdditionalField = action((field: AdditionalField, newPosition: number, isLeftFieldBlock?: boolean): void => {
		let blockAdditionalFields = isLeftFieldBlock ? selector.kanbanQuickView.getQuickViewDesign()?.leftBlockAdditionalFields : selector.kanbanQuickView.getRightFieldsWithoutBlocked();
		if (!blockAdditionalFields) {
			return;
		}
		const duplicatePosition = blockAdditionalFields.findIndex(item => item.order === newPosition);
		const duplicateAdditionalField: AdditionalField = {
			columnId: field.columnId!,
			gridItemId: v4(),
			order: newPosition
		}
		if (duplicatePosition > -1) {
			blockAdditionalFields = blockAdditionalFields.map((leftField, index) => {
				if (index >= duplicatePosition) {
					const newField: AdditionalField = {
						...leftField,
						order: leftField.order + 1
					}
					return newField
				}
				return leftField;
			})

		}
		blockAdditionalFields.push(duplicateAdditionalField);
		blockAdditionalFields.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
		if (isLeftFieldBlock) {
			selector.kanbanQuickView.getQuickViewDesign()!.leftBlockAdditionalFields = blockAdditionalFields;
		} else {
			this.setRightBlockAdditionalFields(blockAdditionalFields);
		}
	})

	/**
	 * @description Метод для создания/обновления полей или создания дубля в QuickView
	 * @param field - новое/обновленное поле, которое необходимо добавить/обновить/создать дубль
	 * @param newPosition - позиция, на которую необходимо поставить поле
	 * @param isLeftFieldBlock - флаг, отвечающий за определения блока полей для изменения (левого или правого)
	 */
	setAdditionalField = (field: AdditionalField, newPosition: number, isLeftFieldBlock?: boolean): void => {
		const leftBlockAdditionalFields = isLeftFieldBlock ? selector.kanbanQuickView.getQuickViewDesign()?.leftBlockAdditionalFields : selector.kanbanQuickView.getRightFieldsWithoutBlocked();
		if (!leftBlockAdditionalFields) {
			return;
		}
		this.hasChanges = true;
		if (leftBlockAdditionalFields.find(item => item.columnId === field.columnId && (item.order > 0))) {
			this.createDuplicateAdditionalField(field, newPosition, isLeftFieldBlock);
		} else {
			this.updateAdditionalField(field, newPosition, isLeftFieldBlock);
		}

	}

	/**
	 * @description Метод для удаления поля из QuickView
	 * @param gridItemId - id поля для удаления
	 * @param isLeftFieldBlock - флаг, отвечающий за определения блока полей для изменения (левого или правого)
	 */
	deleteField = action((gridItemId: string, isLeftFieldBlock?: boolean): void => {
		let blockAdditionalFields = isLeftFieldBlock ? selector.kanbanQuickView.getQuickViewDesign()?.leftBlockAdditionalFields : selector.kanbanQuickView.getRightFieldsWithoutBlocked();
		if (!blockAdditionalFields) {
			return;
		}
		const findedIndex = blockAdditionalFields.findIndex(item => item.gridItemId === gridItemId);
		if (findedIndex > -1) {
			this.hasChanges = true;
			blockAdditionalFields = blockAdditionalFields.map((field, index) => {
				if (index > findedIndex) {
					const newField: AdditionalField = {
						...field,
						order: field.order - 1
					}
					return newField
				}
				return field;
			});
			blockAdditionalFields.splice(findedIndex, 1);
			if (isLeftFieldBlock) {
				selector.kanbanQuickView.getQuickViewDesign()!.leftBlockAdditionalFields = blockAdditionalFields;
			} else {
				this.setRightBlockAdditionalFields(blockAdditionalFields);
			}
		}
	})

	/**
	 * @description Метод для сброса изменений в QuickView
	 */
	resetQuickViewSettings = (): void => {
		const sectionWizzard = dispatcher.sectionWizzard.getSectionWizzard();
		if (sectionWizzard?.kanbanConfig && this.oldKanbanQuickViewConfig) {
			sectionWizzard.kanbanConfig!.quickViewDesign = JSON.parse(JSON.stringify(this.oldKanbanQuickViewConfig));
			this.resetHasChanges();
		}
	}

	resetHasChanges = action((): void => {
		this.hasChanges = false;
		
		const sectionWizzard = dispatcher.sectionWizzard.getSectionWizzard();
		if (sectionWizzard?.kanbanConfig && this.oldKanbanQuickViewConfig){
			this.oldKanbanQuickViewConfig = JSON.parse(JSON.stringify(sectionWizzard.kanbanConfig!.quickViewDesign));
		}

	})
}

export const kanbanQuickViewController = new KanbanQuickViewController();