import FilterColumnType from "entities/ColumnType";

export const ErrorDownloadColumns = [
    {
        name: "fileName",
        caption: "Название",
        type: FilterColumnType.String,
        width: 38,
    },
    {
        name: "fileSize",
        caption: "Размер",
        type: FilterColumnType.String,
        width: 14,
    },
    {
        name: "errorMessage",
        caption: "Причина",
        type: FilterColumnType.String,
        width: 48,
    },
];