import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { useCallback, useMemo } from "react";
import { lowerFirst } from "lodash";

import { dispatcher, selector } from "store";
import { NEW_RECORD } from "synchroiser/synchroiser";
import { stopwatchStore } from "features/stopwatch/store";

import { Button, ButtonDropdown } from "sale-bridge-ui-kit";

import { QuickActionType, QuickActionTypeName } from "types/entity";
import { StandartItemGroup } from "sale-bridge-ui-kit/dist/components/button-dropdown/button-dropdown";
import {
    copyEntityAction, copyLinkAction, deleteEntityAction, logTimeAction,
    pauseStopwatchAction, resumeStopwatchAction, startStopwatchAction, stopStopwatchAction
} from "../data";


export const ActionsDropdownFullView = observer((props: {
    copyLink: () => void,
    flagClick: () => void,
    logTimeClick: () => void,
    stopwatchStartClick: () => void,
    stopwatchPauseClick: () => void,
    stopwatchStopClick: () => void,
}) => {
    const row = useMemo(() => dispatcher.currentRow.get()!, [toJS(dispatcher.currentRow.get())]);
    const isNew = useMemo(() => row?.id === NEW_RECORD, [row?.id]);
    const sectionWizzard = useMemo(() => dispatcher.entity.get()?.entity.sectionWizzard, [toJS(dispatcher.entity.get()?.entity.sectionWizzard)]);
    const sysFlagField = useMemo(() => lowerFirst(selector.sectionWizzard.getSysFlagColumn()?.fieldConfig?.columnName), [selector.sectionWizzard.getSysFlagColumn()]);

    const isExistStopwatch = useMemo(() =>
        stopwatchStore.stopwatches.get(row.id) ?? false
        , [row.id, stopwatchStore.stopwatches.get(row.id)],
    )

    const actions = useMemo((): Array<StandartItemGroup> => {
        const items: Array<StandartItemGroup> = [
            {
                header: '',
                items: [copyLinkAction, copyEntityAction]
            }
        ];
        if (sectionWizzard?.hasFlag) {
            items[0].items.push(
                {
                    id: 'flag',
                    name: row[lowerFirst(sysFlagField)] ? 'Снять флажок...' : QuickActionTypeName[QuickActionType.Flag].title,
                    icon: row[lowerFirst(sysFlagField)] ? 'FlagSolid' : "Flag",
                    iconSize: '16'
                }
            );
        }

        if (sectionWizzard?.hasTimeLogging) {
            items[0].items.push(logTimeAction);
        }
        if (sectionWizzard?.hasTimer) {
            if (!isExistStopwatch) {
                items[0].items.push(startStopwatchAction);
            } else if (isExistStopwatch.isRunning) {
                items.push(
                    {
                        header: '',
                        items: [pauseStopwatchAction, stopStopwatchAction]
                    }
                );
            } else {
                items.push(
                    {
                        header: '',
                        items: [resumeStopwatchAction, stopStopwatchAction]
                    }
                );
            }
        }

        items.push({
            header: '',
            items: [deleteEntityAction]
        });
        return items;
    }, [props, row ? row[lowerFirst(sysFlagField)] : '', isExistStopwatch, isExistStopwatch ? isExistStopwatch.isRunning : '',
        sectionWizzard?.hasFlag, sectionWizzard?.hasTimeLogging, sectionWizzard?.hasTimer
    ]);

    const handleClickToAction = useCallback((id: string) => {
        switch (id) {
            case copyLinkAction.id: {
                props.copyLink();
                return;
            }
            case copyEntityAction.id: {
                //TODO реализовать действие по нажатию на Дублирование
                return;
            }
            case 'flag': {
                props.flagClick();
                return;
            }
            case logTimeAction.id: {
                props.logTimeClick();
                return;
            }
            case startStopwatchAction.id || resumeStopwatchAction.id: {
                props.stopwatchStartClick();
                return;
            }
            case pauseStopwatchAction.id: {
                props.stopwatchPauseClick();
                return;
            }
            case stopStopwatchAction.id: {
                props.stopwatchStopClick();
                return;
            }
            case deleteEntityAction.id: {
                //TODO реализовать действие по нажатию на Удалить
                return;
            }
        }
    }, [props]);

    return <ButtonDropdown
        size='small'
        position='right-down'
        isDisabled={isNew}
        standartItems={actions}
        onClickStandartItem={handleClickToAction}
        childrenButton={
            <Button
                text='Действия'
                onClick={() => { }}
                size='small'
                variant={isNew ? 'disabled' : 'default'}
                leftIcon="KebabVertical"
                rightIcon="Dropdown"
                link={false}
                loading={false}
                border
            />
        }
    />
});

