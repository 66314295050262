interface AttributeElements{
    [key:string]:string;
}

class NodeElement{
    type:string = "";
    attribute: AttributeElements = {};
    value:string | undefined;
    children: NodeElement[] = [];
}

export default NodeElement;