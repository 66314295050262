import { isUndefined } from "lodash";

import { dispatcher, selector } from "store";

import { getPriorityColumnName, getStagesColumnName } from "synchroiser/lib";

import { SYS_POSITION } from "../constants";

export function getNeedColumns() {
    const gridItems = dispatcher.sectionWizzard.getAllGridItems();
    const entity = dispatcher.entity.get()?.entity;

    if (!entity) {
        return null;
    }

    const stageFieldsName = getStagesColumnName(gridItems) as string;
    const priorityFieldsName = getPriorityColumnName(gridItems) as string;
    const sysFlagName = selector.sectionWizzard.getSysFlagColumn()?.fieldConfig?.columnName;

    let needColumns: Array<string> = [];
    needColumns = dispatcher.sectionWizzard.getSectionWizzard()?.kanbanConfig?.cardDesign.additionalFields.map((item) => gridItems.find((gridItem) => gridItem.fieldConfig?.columnId === item.columnId)?.fieldConfig?.columnName ?? "") ?? []
    needColumns = [...needColumns, ...dispatcher.sectionWizzard.getSectionWizzard()?.kanbanConfig?.cardDesign.userFields.map((item) => gridItems.find((gridItem) => gridItem.fieldConfig?.columnId === item.columnId)?.fieldConfig?.columnName ?? "") ?? []]

    needColumns.push(stageFieldsName);
    needColumns.push(priorityFieldsName);
    if (isUndefined(needColumns.find(needColumn => needColumn.toLowerCase() === SYS_POSITION.toLowerCase()))) {
        needColumns.push(SYS_POSITION);
    }
    if (sysFlagName && isUndefined(needColumns.find(needColumn => needColumn.toLowerCase() === sysFlagName.toLowerCase()))) {
        needColumns.push(sysFlagName);
    }
    needColumns = Array.from(new Set(needColumns))

    return needColumns;
}