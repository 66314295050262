import Modal from "components/modal/modal";

import styles from "./skeleton-quick-view.module.css";

function SkeletonQuickView() {
    return (
        <Modal>
            <div className={styles.backdrop}></div>
            <div className={styles.wrapper}>
                <div className={styles.header}>
                    <div className={styles.roundSmall}></div>
                </div>
                <div className={styles.buttonsWrapper}>
                    <div className={styles.buttonsWrapperPart}>
                        <div className={styles.item}></div>
                        <div className={styles.itemShort}></div>
                    </div>
                    <div className={styles.buttonsWrapperPart}>
                        <div className={styles.itemLong}></div>
                        <div className={styles.itemLong}></div>
                    </div>
                </div>
                <div className={styles.bodyMain}>
                    <div className={styles.bodyLeft}>
                        <div className={styles.itemVeryLong}></div>
                        <div className={styles.headerLeft}>
                            <div className={styles.itemMed}></div>
                            <div className={styles.itemMed}></div>
                        </div>
                        <div className={styles.mainBodyLeft}>
                            <div className={styles.halfLeft}>
                                <div className={styles.itemShort}></div>
                                <div className={styles.item}></div>
                                <div className={styles.itemShort}></div>
                                <div className={styles.itemShort}></div>
                                <div className={styles.item}></div>
                                <div className={styles.itemShort}></div>
                            </div>
                            <div className={styles.halfRight}>
                                <div className={styles.item}></div>
                                <div className={styles.item}></div>
                                <div className={styles.itemShort}></div>
                                <div className={styles.item}></div>
                                <div className={styles.itemLong}></div>
                                <div className={styles.item}></div>
                            </div>
                        </div>
                        <div className={styles.itemVeryLong}></div>
                        <div className={styles.footerLeft}>
                            <div className={styles.round}></div><div className={styles.comment}></div>
                        </div>
                    </div>
                    <div className={styles.bodyRight}>
                        <div className={styles.halfRight}>
                            <div className={styles.itemShort}></div>
                            <div className={styles.itemShort}></div>
                            <div className={styles.itemShort}></div>
                            <div className={styles.itemShort}></div>
                        </div>
                        <div className={styles.halfRight}>
                            <div className={styles.item}></div>
                            <div className={styles.item}></div>
                            <div className={styles.itemShort}></div>
                            <div className={styles.item}></div>
                        </div>
                    </div>
                </div>
            </div >
        </Modal >

    )
}

export default SkeletonQuickView;