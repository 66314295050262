import { useCallback, useMemo, useState } from "react"
import { observer } from "mobx-react-lite"
import { toJS } from "mobx"
import classNames from "classnames"

import Avatar from "features/avatar/avatar-generator"

import { AdditionalField } from "types/entity"
import { Props } from "../card-designer/card-designer"

import styles from "./dragging-avatars.module.scss"

type DragState = {
    draggedId: string;
    draggedOrder: number;
    overOrder: number;
    overZone: string | null;
    placeholderOrder: number;
}

export const DraggingAvatars = observer(function (props: Props) {
    const [dragState, setDragState] = useState<DragState>({
        draggedId: '',
        draggedOrder: -1,
        overOrder: -1,
        overZone: null,
        placeholderOrder: -1
    });
    const { draggedOrder, overOrder, overZone, placeholderOrder, draggedId } = dragState;

    const avatarText = classNames(styles.text, styles.avatarText);

    /**
     * @description срабатывает при поднятии аватара
     **/
    const handleAvatarDragStart = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        const order = parseInt(e.currentTarget.dataset.dragIndex ?? "", 10)
        if (isNaN(order)) {
            return;
        }
        setDragState(({ ...dragState, draggedId: e.currentTarget.id, draggedOrder: order }));
    }, [dragState, toJS(props.usersAvatars)]);

    /**
     * @description если заполнитель находится непосредственно перед (==draggedIndex) или непосредственно после (===draggedindex + 1),
     *  то нет необходимости его показывать, поскольку мы ничего не перемещаем
     **/
    function setNewPlaceholderOrder(newPlaceholderOrder: number, draggedOrder: number) {
        if ((newPlaceholderOrder === draggedOrder || newPlaceholderOrder === draggedOrder + 1)) {
            newPlaceholderOrder = -1;
        }
        return newPlaceholderOrder;
    }

    /**
     * @description обновить только если placeholderOrder изменился
     **/
    function updatePlaceholderOrder(newState: DragState, newPlaceholderOrder: number, nonFunctionalConditionOnlyForDisplay: boolean) {
        if (placeholderOrder !== newPlaceholderOrder || nonFunctionalConditionOnlyForDisplay) {
            newState.placeholderOrder = newPlaceholderOrder;
            setDragState({ ...newState });
        }
    }

    /**
     * @description срабатывает при перетаскивании аватара
     **/
    const handleAvatarDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        const rect = e.currentTarget.getBoundingClientRect();

        const x = e.clientX - rect.left;

        const newOverOrder = parseInt(e.currentTarget.dataset.dragIndex ?? "", 10)
        if (isNaN(newOverOrder)) {
            return;
        }
        const newOverZone = x <= rect.width / 2 ? 'left' : 'right';
        const newState = { ...dragState, overOrder: newOverOrder, overZone: newOverZone }
        let newPlaceholderOrder = newOverZone === 'left' ? newOverOrder : (newOverOrder + 1);
        newPlaceholderOrder= setNewPlaceholderOrder(newPlaceholderOrder, draggedOrder);

        const nonFunctionalConditionOnlyForDisplay = overOrder !== newOverOrder || overZone !== newOverZone;
        updatePlaceholderOrder(newState, newPlaceholderOrder, nonFunctionalConditionOnlyForDisplay);
    }, [dragState, toJS(props.usersAvatars), draggedOrder, overOrder, overZone]);

    /**
     * @description перемещение элемента вправо
     **/
    function rightDrag(draggbleAvatar: AdditionalField) {
        props.usersAvatars.splice((draggedOrder - 1), 1);
        props.usersAvatars.splice((placeholderOrder - 2), 0, draggbleAvatar);
        props.usersAvatars?.filter(item => item.order < placeholderOrder && item.order > draggedOrder).forEach(avatar => avatar.order--);
        draggbleAvatar.order = placeholderOrder - 1;
    }
    /**
    * @description перемещение элемента влево
    **/
    function leftDrag(draggbleAvatar: AdditionalField) {
        props.usersAvatars.splice((draggedOrder - 1), 1);
        props.usersAvatars.splice((placeholderOrder - 1), 0, draggbleAvatar);
        props.usersAvatars?.filter(item => item.order >= placeholderOrder && item.order < draggedOrder).forEach(avatar => avatar.order++);
        draggbleAvatar.order = placeholderOrder;
    }

    /**
     * @description срабатывает при опускании аватара
     **/
    const handleAvatarDragEnd = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        if (placeholderOrder !== -1) {
            const draggbleAvatar = props.usersAvatars.find(avatar => avatar.columnId === draggedId);
            if (!draggbleAvatar) {
                return;
            }
            if (placeholderOrder > draggedOrder) {
                rightDrag(draggbleAvatar);

            } else {
                leftDrag(draggbleAvatar)
            }
        }
        const updater = { draggedOrder: -1, placeholderOrder: -1, overOrder: -1, overZone: null, draggedId: '' };
        setDragState({ ...updater });
    }, [dragState, draggedOrder, toJS(props.usersAvatars), placeholderOrder, draggedId]);

    function avatarsMapping() {
        const parametersArray = props.usersAvatars.map((avatar, index: number) => {
            const fieldInfo = props.additionalInfoOfField.get(avatar.columnId);

            const avatarClasses = classNames(styles.avatarWrapper, {
                [`${styles.avatarWrapperDragging}`]: avatar.order === draggedOrder
            });

            return (
                <>
                    <div
                        className={avatarClasses}
                        id={avatar.columnId}
                        data-drag-index={avatar.order}
                        draggable
                        onDragStart={handleAvatarDragStart}
                        onDragOver={handleAvatarDragOver}
                        onDragEnd={handleAvatarDragEnd}
                    >
                        <Avatar name={fieldInfo?.columnTitle ?? ""} />

                    </div>
                    {index < props.usersAvatars.length - 1 ?
                        <div className={styles.divider} /> : <></>
                    }
                </>
            );
        })
        if (placeholderOrder !== -1) {
            parametersArray?.splice(
                (placeholderOrder - 1),
                0,
                <div className={styles.predict} />
            );
        }
        return parametersArray
    }

    return (
        <>
            {props.usersAvatarEnabled &&
                <>
                    <div key={'avatars'} className={avatarText}>Аватары пользователей</div>
                    <div className={styles.avatarsBlock}>
                        {avatarsMapping()}
                    </div>
                </>
            }
        </>
    )
});