import classNames from "classnames";

import styles from "./hint.module.css";

export enum HintDirection {
    Up,
    Down
}
export interface HintProps extends React.HTMLAttributes<HTMLElement> {
    tooltip: string;
    direction?: HintDirection
}

function Hint(props: HintProps) {
    const hintContainerClassName = classNames(styles.hintContainer, {
        [`${props.className}`]: props.className
    });

    const hintClassName = classNames(styles.hint, {
        [`${styles.hintUp}`]: props.direction == HintDirection.Up,
    });

    return (
        <div className={hintContainerClassName}>
            <span className={hintClassName}>
                {props.tooltip}
            </span>
            {props.children}
        </div>

    );
}

export default Hint;