import { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";

import { v4 } from "uuid";
import { isUndefined, lowerCase } from "lodash";

import fieldConfigurationStore, { FieldStoreEnums } from "./field-configuration-store";
import {
	validateRequired,
	validateRounding
} from "entities/Validation";

import { Field } from "sale-bridge-ui-kit";
import { BasicSettings, CheckboxesBlock, LookupConfiguration } from "./components";

import FilterColumnType, { ColumnType } from "entities/ColumnType";
import { ColumnSpecializationType } from "types/entity";
import IFilter, { ComparisonType, DataValueType } from "entities/filter/IFilter";
import { ILookup } from "entities/Entity";
import { ILookupData } from "pages/settings/data/Fields";
import { LookupType } from "pages/section-wizzard/data/data";
import { getInfoTitle } from "./types";

import styles from "./field-configuration.module.scss";

interface FieldConfigurationProps {
	fieldType?: ColumnType;
	columnId?: string;
}

export const FieldConfiguration = observer((props: FieldConfigurationProps) => {

	useEffect(() => {
		if (props.fieldType) {
			fieldConfigurationStore.setValueWithoutTrackingChanges(FieldStoreEnums.fieldType, props.fieldType);
		}
		if (!props.columnId) {
			fieldConfigurationStore.setValueWithoutTrackingChanges(FieldStoreEnums.columnId, v4());
		}
	}, []);

	return <>
		{fieldConfigurationStore.lookupType === LookupType.NewLookup ?
			<LookupConfiguration /> :
			<DefaultConfiguration columnId={props.columnId} />
		}
	</>
});


const DefaultConfiguration = observer((props: { columnId?: string }) => {
	const [defValues, setDefValues] = useState<ILookup[]>([]);
	const [searchValue, setSearchValue] = useState('');
	const [searchValueDefault, setSearchValueDefault] = useState('');

	const fieldTypeIsLookup = useMemo(() => {
		return fieldConfigurationStore.fieldType === ColumnType.Lookup;
	}, [fieldConfigurationStore.fieldType]);

	const fieldTypeIsDecimal = useMemo(() => {
		return fieldConfigurationStore.fieldType === ColumnType.Decimal;
	}, [fieldConfigurationStore.fieldType]);

	const fieldTypeIsBoolean = useMemo(() => {
		return fieldConfigurationStore.fieldType === ColumnType.Boolean;
	}, [fieldConfigurationStore.fieldType]);

	const handleLookupValuesLoad = useCallback(async (value: string | null) => {
		try {
			const items = await fieldConfigurationStore.loadLookups();
			if (items) {
				const newItems: ILookupData[] = [];
				items.filter((lookup: any) => {
					const entityTitle = lowerCase(lookup.entityTitle);
					if (entityTitle.includes(lowerCase(value!)))
						newItems.push(lookup);
				});
				fieldConfigurationStore.setLookups(newItems);
				return newItems.length
			}
			else return -1
		}
		catch (error) {
			console.error(error)
		}
	}, []);

	const handleDefaultValuesLoad = useCallback(async (value: string | null) => {
		let filter: IFilter | null = null;
		if (value && fieldConfigurationStore.selectedLookup) {
			filter = {
				schema: fieldConfigurationStore.selectedLookup.entityName,
				isEnabled: true,
				type: 1,
				comparisonType: ComparisonType.Contain,
				attribute: "Name",
				attributeType: FilterColumnType.String,
				rightExpression: {
					type: 0,
					parameter: {
						dataValueType: DataValueType.Text,
						value: value
					}
				},
				filters: [],
				logicalOperation: 0,
				detail: ""
			};
		}
		try {
			if (fieldConfigurationStore.selectedLookup) {
				const items = await fieldConfigurationStore.loadLookupData(fieldConfigurationStore.selectedLookup.entityName, filter);
				if (items) {
					setDefValues(items);
					return items.length
				}
				else return -1
			}
		}
		catch (error) {
			console.error(error)
		}
	}, [defValues, fieldConfigurationStore.selectedLookup]);

	useEffect(() => {
		async function load() {
			await handleLookupValuesLoad(null);
		}
		if (fieldConfigurationStore.fieldType === ColumnType.Lookup) {
			load()
		}
	}, []);

	useEffect(() => {
		async function load() {
			await handleDefaultValuesLoad(null);
		}
		if (props.columnId) {
			load()
		}
	}, [fieldConfigurationStore.selectedLookup]);

	const handleRoundingChange = useCallback((value: string) => {
		fieldConfigurationStore.setValue(FieldStoreEnums.rounding, value);
		fieldConfigurationStore.setSpecialization(FieldStoreEnums.specializations, ColumnSpecializationType.Double, { "rounding": value });

		validateRounding(
			fieldConfigurationStore.specializations.properties["rounding"].replace(/\s+/g, ''),
			fieldConfigurationStore.validation.rounding,
		);
	}, []);

	const handleLookupChange = useCallback(async (value: any) => {
		fieldConfigurationStore.setValue(FieldStoreEnums.selectedLookup, value);
		//Не подгружаем значения для разделов
		if (fieldConfigurationStore.selectedLookup?.entityName && !fieldConfigurationStore.selectedLookup.isSection) {
			await handleDefaultValuesLoad(null);
		}
		fieldConfigurationStore.setValue(FieldStoreEnums.selectedLookupDefaultValue, null);
		validateRequired(
			fieldConfigurationStore.selectedLookup,
			fieldConfigurationStore.validation.lookup,
			true
		);
	}, []);

	const handleLookupDefaultValueChange = useCallback(async (value: any) => {
		fieldConfigurationStore.setValue(FieldStoreEnums.selectedLookupDefaultValue, value);
		if (value !== null) {
			fieldConfigurationStore.setValue(FieldStoreEnums.defaultValue, fieldConfigurationStore.selectedLookupDefaultValue!.id);
		} else {
			fieldConfigurationStore.setValue(FieldStoreEnums.defaultValue, value);
		}

	}, []);

	const handleDefaultValueChange = useCallback((value: string) => {
		fieldConfigurationStore.setValue(FieldStoreEnums.defaultValue, value);
		fieldConfigurationStore.validateDefaultValue();
	}, []);

	const roundingField = useMemo(() =>
		fieldTypeIsDecimal && <div className={styles.roundingField}>
			<Field
				type='number'
				size='small'
				label='Количество знаков после запятой'
				labelPosition="vertical"
				textVariant='outlined'
				subType="integer"
				placeholder="0"
				info='Столько знаков после запятой сможет ввести пользователь'
				value={fieldConfigurationStore.specializations.properties["rounding"] ?? ''}
				onChange={handleRoundingChange}
				alert={fieldConfigurationStore.validation.rounding.isInvalid ? 'error' : undefined}
				tooltipBody={fieldConfigurationStore.validation.rounding.error}
				startTooltipPosition='left middle'
				tooltipTrigger='hover&focus'
				isTooltipDisplayed={fieldConfigurationStore.validation.rounding.isInvalid && !isUndefined(fieldConfigurationStore.validation.rounding.error)}
			/>
			<span className={styles.infoRoundingField}>Максимальное значение: 15</span>
		</div>
		, [fieldTypeIsDecimal, fieldConfigurationStore.specializations.properties["rounding"],
			fieldConfigurationStore.validation.rounding.isInvalid, fieldConfigurationStore.validation.rounding.error
		]);

	const lookupItems = useMemo(() => {
		return [
			{
				layout: <></>,
				items: fieldConfigurationStore.lookups
					.filter(lookup =>
						lookup.entityTitle?.toLowerCase().includes(searchValue?.toLowerCase())
					)
					.map(item =>
						<div className={styles.selectItem} onClick={() => handleLookupChange(item)}>
							<span>{item.entityTitle}</span>
						</div>
					)
			}
		]
	}, [toJS(fieldConfigurationStore.lookups), searchValue]);

	const entityTitle = useMemo(() => {
		return (!fieldConfigurationStore.fieldIsLoading && fieldConfigurationStore.selectedLookup?.entityTitle)
			? fieldConfigurationStore.selectedLookup?.entityTitle
			: '';
	}, [fieldConfigurationStore.fieldIsLoading, fieldConfigurationStore.selectedLookup?.entityTitle]);

	const objectFieldIsDisabled = useMemo(() => {
		return fieldConfigurationStore.fieldIsExistInDB()
	}, [fieldConfigurationStore.columnId]);

	const handleClearLookupValue = useCallback(() => {
		fieldConfigurationStore.setValue(FieldStoreEnums.selectedLookup, null);
		fieldConfigurationStore.setValue(FieldStoreEnums.selectedLookupDefaultValue, null);
	}, [fieldConfigurationStore.selectedLookupDefaultValue, fieldConfigurationStore.selectedLookup]);

	const lookupField = useMemo(() => {
		if (fieldTypeIsLookup) {
			return (
				<Field
					type='select'
					size='small'
					label='Справочник'
					labelPosition="vertical"
					textVariant='outlined'
					isDisabled={objectFieldIsDisabled}
					info='Список всех справочников и разделов в системе'
					value={entityTitle}
					isClearing
					items={lookupItems}
					onChange={handleClearLookupValue}
					alert={fieldConfigurationStore.validation.lookup.isInvalid ? 'error' : undefined}
					searchValue={searchValue}
					onChangeSearchValue={setSearchValue}
					placeholder='Значение поля'
				/>
			);
		}
		return null;
	}, [fieldTypeIsLookup, fieldConfigurationStore.selectedLookup, fieldConfigurationStore.lookups, objectFieldIsDisabled, searchValue]);

	const defaultValItems = useMemo(() => {
		return [
			{
				layout: <></>,
				items: defValues.map(item =>
					<div className={styles.selectItem} onClick={() => handleLookupDefaultValueChange(item)}>
						<span>{item.name}</span>
					</div>
				)
			}
		]
	}, [toJS(defValues)]);

	const handleClearDefaultValue = useCallback(() => {
		fieldConfigurationStore.setValue(FieldStoreEnums.selectedLookupDefaultValue, null)
	}, [fieldConfigurationStore.selectedLookupDefaultValue]);

	const selectedLookupDefaultValueName = useMemo(() => {
		return (!fieldConfigurationStore.fieldIsLoading && fieldConfigurationStore.selectedLookupDefaultValue?.name)
			? fieldConfigurationStore.selectedLookupDefaultValue?.name
			: '';
	}, [fieldConfigurationStore.fieldIsLoading, fieldConfigurationStore.selectedLookupDefaultValue?.name]);

	const lookupDefaultValueField = useMemo(() => {
		return fieldTypeIsLookup &&
			fieldConfigurationStore.fieldType !== ColumnType.DateTime &&
			!fieldConfigurationStore.selectedLookup?.isSection &&
			(
				<Field
					type='select'
					size='small'
					label='Значение по умолчанию'
					labelPosition="vertical"
					textVariant='outlined'
					info='Значение, которым заполнится поле при создании записи'
					value={selectedLookupDefaultValueName}
					isClearing
					items={defaultValItems}
					onChange={handleClearDefaultValue}
					searchValue={searchValueDefault}
					onChangeSearchValue={setSearchValueDefault}
				/>
			);
	}, [fieldTypeIsLookup, fieldConfigurationStore.fieldType, fieldConfigurationStore.selectedLookup,
		toJS(defaultValItems), fieldConfigurationStore.selectedLookupDefaultValue, searchValueDefault]);

	const defaultValueField = useMemo(() => {
		if (!fieldTypeIsLookup &&
			fieldConfigurationStore.fieldType !== ColumnType.DateTime &&
			fieldConfigurationStore.fieldType !== ColumnType.Date &&
			fieldConfigurationStore.fieldType !== ColumnType.Time &&
			fieldConfigurationStore.fieldType !== ColumnType.Boolean
		) {
			return (
				<Field
					type='text'
					size='small'
					label='Значение по умолчанию'
					labelPosition="vertical"
					textVariant='outlined'
					info='Значение, которым заполнится поле при создании записи'
					value={fieldConfigurationStore.defaultValue}
					onChange={handleDefaultValueChange}
					alert={fieldConfigurationStore.validation.defaultValue.isInvalid ? 'error' : undefined}
				/>
			);
		}
		return null;
	}, [fieldTypeIsLookup, fieldConfigurationStore.fieldType, fieldConfigurationStore.defaultValue,
		fieldConfigurationStore.validation.defaultValue.isInvalid]);

	const booleanField = useMemo(() => {
		if (fieldTypeIsBoolean) {
			return (
				<Field
					type='boolean'
					size='small'
					label='Значение по умолчанию'
					labelPosition="vertical"
					textVariant='outlined'
					isDisabled={objectFieldIsDisabled}
					info='Значение, которым заполнится поле при создании записи'
					title="Нет"
					value={fieldConfigurationStore.defaultValue ?? false}
					onChange={(value: boolean) =>
						fieldConfigurationStore.setValue(FieldStoreEnums.defaultValue, value)
					}
				/>
			);
		}
		return null;
	}, [fieldTypeIsBoolean, fieldConfigurationStore.fieldType, fieldConfigurationStore.defaultValue]);

	return (
		<>
			{getInfoTitle()}
			<div className={styles.fieldsBlock}>
				<BasicSettings />
				{roundingField}
				{lookupField}
				{lookupDefaultValueField}
				{defaultValueField}
				{booleanField}
				<Field
					type='text'
					size='small'
					label='Подсказка'
					labelPosition="vertical"
					textVariant='outlined'
					info='То, что вы читаете прямо сейчас 🙂'
					value={!fieldConfigurationStore.fieldIsLoading ? fieldConfigurationStore.prompt : ''}
					onChange={(value: string) => {
						fieldConfigurationStore.setValue(FieldStoreEnums.prompt, value);
					}}
					placeholder='Значение поля'
				/>
				<CheckboxesBlock />
			</div>
		</>
	);
});
