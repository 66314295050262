import { CommentBodyVariant, CommentInput, CommentProvider, CommentsBody } from "features/comments";

import styles from "./comments-block.module.css";

//TODO старое, пока оставляю
// function СommentsBlock2() {

//     return (
//         <div className={styles.wrapper}>
//             <Comments
//                 className={styles.commentsWrapper}
//                 classNameTabContent={styles.tabContent}
//                 classNameTextEditor={styles.textEditor}
//                 commentsAndInputClassName={styles.commentsAndInputBlock}
//             />
//         </div>
//     )
// }


export function СommentsBlock() {

    return (
        <div className={styles.wrapper}>
            <CommentProvider>
                <CommentsBody blockVariant={CommentBodyVariant.FullView} />
                <CommentInput blockVariant={CommentBodyVariant.FullView} />
            </CommentProvider>
        </div>
    )
}

export default СommentsBlock;