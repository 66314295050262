import { FieldValidationState, validateRequired, validateSchema } from 'entities/Validation';
import { action, makeObservable, observable } from 'mobx';
import { ILookupData } from 'pages/settings/data/Fields';


export class LookupInfo {
    id?: string = "";
    columnName: string = "";
    columnTitle: string = "";
    columnType: string = "";
    isLookup?: boolean = false;
    lookupTable?: ILookupData;
    isRequired?: boolean = false;
    hasIndex?: boolean = false;

    validation: { [key: string]: FieldValidationState };

    constructor() {
        makeObservable(this, {
            id: observable,
            columnName: observable,
            columnTitle: observable,
            columnType: observable,
            isLookup: observable,
            lookupTable: observable,
            isRequired: observable,
            hasIndex: observable,
            validation: observable,
            validate: action,
            setValue: action,
            resetValidate: action,
        })

        this.validation = {
            columnName: {
                isInvalid: false
            },
            columnTitle: {
                isInvalid: false
            },
            columnType: {
                isInvalid: false
            },
            lookupTable: {
                isInvalid: false
            },

        }
    }


    setValue(value: string | ILookupData | boolean, fieldName: string) {
        Reflect.set(this, fieldName, value)
    }

    // @action
    validate(fields: LookupInfo[]): boolean {
        let res: boolean[] = [];
        if (!validateRequired(this.columnType, this.validation.columnType, true, true)) {
            res.push(false);
        }
        if (this.columnType === "Guid") {
            if (!validateRequired(this.lookupTable, this.validation.lookupTable, true, true)) {
                res.push(false);
            }
        }
        if (!validateRequired(this.columnTitle, this.validation.columnTitle)) {
            res.push(false);
        }
        if (!validateSchema(this.columnName, this.validation.columnName, fields)) {
            res.push(false);
        }
        return !res.includes(false)
    }

    resetValidate() {
        this.validation = {
            columnName: {
                isInvalid: false,
                error: ""
            },
            columnTitle: {
                isInvalid: false,
                error: ""
            },
            columnType: {
                isInvalid: false,
                error: ""
            },
            lookupTable: {
                isInvalid: false,
                error: ""
            }
        }
    }

}


const lookupInfo = new LookupInfo();

export default lookupInfo;