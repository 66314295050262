import { DataValueType } from "entities/filter/IFilter";
import { IconSettings } from "../core/IconSettings";

import { Lookup } from "shared";
import { Clocks } from "shared";
import { Numbers } from "shared";
import { TextIcon } from "shared";
import { Filter } from "shared";
import { CheckMarkerSquare } from "shared";
import { Icon } from "sale-bridge-ui-kit";
import React from "react";

export const IconSettingString: IconSettings[] = [
	{
		types: [DataValueType.Boolean],
		icon: <Icon name={"Boolean"} size={"medium"} />
	},
	{
		types: [DataValueType.DateTime],
		icon: <Icon name={"DateTime"} size={"medium"} />
	},
	{
		types: [DataValueType.Date],
		icon: <Icon name={"Date"} size={"medium"} />
	},
	{
		types: [DataValueType.Decimal],
		icon: <Icon name={"Decimal"} size={"medium"} />
	},
	{
		types: [DataValueType.Guid],
		icon: <Icon name={"Guid"} size={"medium"} />
	},
	{
		types: [DataValueType.Integer],
		icon: <Icon name={"Integer"} size={"medium"} />
	},
	{
		types: [DataValueType.Lookup],
		icon: <Icon name={"Lookup"} size={"medium"} />
	},
	{
		types: [DataValueType.Text],
		icon: <Icon name={"String"} size={"medium"} />
	},
	{
		types: [DataValueType.Time],
		icon: <Icon name={"Time"} size={"medium"} />
	}
];

export function getIconUikit(type: DataValueType | undefined, size: "small" | "medium" | "large"): JSX.Element {
	const iconElement = IconSettingString.find((i) => i.types.some((t) => t === type))?.icon;

	if (iconElement) {
		return React.cloneElement(iconElement, { size });
	}

	return <Icon name={"Filter"} size={size} />;
}

export const IconSetting: IconSettings[] = [
	{
		types: [DataValueType.Lookup, DataValueType.Entity],
		icon: <Lookup />
	},
	{
		types: [DataValueType.Integer, DataValueType.Float],
		icon: <Numbers />
	},
	{
		types: [DataValueType.DateTime],
		icon: <Clocks />
	},
	{
		types: [DataValueType.Text],
		icon: <TextIcon />
	},
	{
		types: [DataValueType.Boolean],
		icon: <CheckMarkerSquare />
	}
];

export function getIcon(type: DataValueType | undefined): JSX.Element {
	const icon = IconSetting.find((i) => i.types.some((t) => t === type))?.icon;

	if (icon) return icon;

	return <Filter />;
}
