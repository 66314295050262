import { useMemo } from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";

import fieldConfigurationStore from "../../../../../field-configuration-store";

import { Button } from "sale-bridge-ui-kit";

import { NumeratorMaskItem } from "types/entity";

import { CloseMaxi } from "shared";

import styles from "../../masks.module.scss";

export const DeleteMaskModal = observer(
	(props: {
		item: NumeratorMaskItem;
		deleteMask: () => void;
		backClick: (e?: React.MouseEvent<HTMLElement, MouseEvent> | React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
	}) => {
		const conditions = useMemo(
			() => fieldConfigurationStore.numeratorConfig.conditions.filter((condition) => condition.maskId === props.item.id),
			[props.item.id, toJS(fieldConfigurationStore.numeratorConfig.conditions)]
		);

		return (
			<>
				<div className={styles.deleteModalHeader}>
					<span>Удалить маску?</span>
					<CloseMaxi className={styles.modalCloseButton} onClick={props.backClick} />
				</div>
				<div className={styles.deleteModalBody}>
					{fieldConfigurationStore.numeratorConfig.isMaskUsedInCondition(props.item.id)
						? `Эта маска используется в условной нумерации. Вместе с маской связанное с ней условие 
                        ${conditions
							.map((condition, index) => `[${condition.name}]${index !== conditions.length - 1 ? ", " : ""}`)
							.join("")}
                        будет так же удалено.`
						: "Это действие невозможно отменить."}
				</div>
				<div className={styles.deleteModalFooter}>
					<Button text="Удалить" size="small" variant="default" border link={false} loading={false} onClick={props.deleteMask} />
					<Button text="Вернуться" size="small" variant="primary" border link={false} loading={false} onClick={props.backClick} />
				</div>
			</>
		);
	}
);
