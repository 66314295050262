import { observer } from "mobx-react";
import { useCallback, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, createMemoryRouter } from "react-router-dom";
import { v4 } from "uuid";
import { isEmpty, isNull, isUndefined } from "lodash";

import { dispatcher } from "store";
import { synchroiser } from "synchroiser";

import { LowFirst } from "shared";

import exportStore from "features/export-block/core/export-store";
import { modalController } from "features/modals";

import { Button, ButtonStyle, GeneralizedGrid } from "components";

import { DetailBodyType } from "../data/detail-data";
import { IContextMenuOption } from "components/grid/data/data";

import { Warning } from "shared";

import styles from "./detail-body.module.scss";

const DetailBody = observer((props: DetailBodyType) => {
	const [idModal] = useState<string>(v4());
	const wrapperRef = useRef<HTMLDivElement>(null);
	const rowIdRef = useRef<string | null>(null);
	const navigate = useNavigate();
	const location = useLocation();

	const exportRecords = useCallback(() => {
		props.openExportBlock(true);
		exportStore.initSignalR();
	}, []);

	const closeConfirm = useCallback((e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
		e?.stopPropagation();
		modalController.modalRemove(idModal);
	}, []);

	const openDetail = useCallback(() => {
		if (props.detailEntitiesStore.entity) {
			const viewColumnName = LowFirst(
				dispatcher.entity.get()?.entity?.columns.find((column) => column.columnId === props.viewColumnId)!.columnName!
			);
			const title = dispatcher.currentRow.get()[viewColumnName];
			const detailRowId = rowIdRef.current ?? props.detailEntitiesStore.entity?.entity.includedIds[0].id;
			const entityViewColumn = {
				displayValue: title,
				id: dispatcher.currentRow.get()?.id
			};

			const state = {
				isDetail: true,
				isSectionDetail: props.detailEntitiesStore.isSectionDetail,
				detailRowId: detailRowId,
				detailEntityName: props.detailEntitiesStore.entityName,
				entityViewColumn: entityViewColumn,
				entityName: props.entityName,
				entityTitle: props.entityTitle,
				detailLinkColumn: props.detailEntitiesStore.filterColumn
			};
			dispatcher.entity.set(props.detailEntitiesStore.entity);
			dispatcher.entity.switchById(props.detailEntitiesStore.entity.id);

			//TODO изменить в дальнейшем, поскольку нарушается последовательность шагов назад.
			const replace: boolean = !isEmpty(location.state) && !isNull(location.state) && !isUndefined(location.state);
			navigate(".", { state: state, replace: replace });
		}
	}, [props.detailEntitiesStore.entity, rowIdRef.current]);

	const handleDelete = useCallback(() => {
		const warningConfirm = (
			<div className={styles.warningDialog}>
				<div className={styles.warningHeader}>
					<span className={styles.warningTitle}>Внимание</span>
					<Warning />
				</div>
				<div className={styles.warningDialogBody}>
					<span className={styles.warningText}>
						{"Вы действительно хотите удалить выбранные записи?\nЭто действие невозможно отменить"}
					</span>
				</div>
				<div className={styles.dialogFooter}>
					<Button caption="Отмена" onClick={closeConfirm} style={ButtonStyle.Subtle} isDisabled={false} />
					<Button caption="Удалить" onClick={deleteRecords} style={ButtonStyle.Danger} isDisabled={false} />
				</div>
			</div>
		);

		modalController.popupAdd({ id: idModal, layout: warningConfirm, closeFunc: closeConfirm });
	}, [props.detailEntitiesStore]);

	const deleteRecords = useCallback(async () => {
		if (props.detailEntitiesStore?.entity) {
			await synchroiser.deleteRecords(props.detailEntitiesStore.entity);
			await props.detailEntitiesStore.load();
		}
		closeConfirm();
	}, [props.detailEntitiesStore]);

	const handleCheckAll = useCallback(
		(value: boolean) => {
			dispatcher.entity.onChangeCheckedAll(value, props.detailEntitiesStore.entity?.entity);
		},
		[props.detailEntitiesStore.entity]
	);

	const handleDoubleRowClick = useCallback(
		(rowId: string, event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			rowIdRef.current = rowId;
			openDetail();
		},
		[props.detailEntitiesStore.entity]
	);

	const contextMenuWhenOneCheckedElement: IContextMenuOption[] = useMemo(() => {
		return [
			{
				caption: "Открыть запись",
				iconName: "OpenInWindow",
				size: "16",
				action: openDetail,
				isDivider: true
			},
			{
				caption: "Удалить",
				iconName: "Trash",
				size: "16",
				action: handleDelete,
				isDivider: false
			}
		];
	}, []);

	const contextMenuWhenMoreCheckedElements: IContextMenuOption[] = useMemo(() => {
		return [
			{
				caption: "Экспортировать выбранное...",
				iconName: "Export",
				size: "16",
				action: exportRecords,
				isDivider: false
			},
			{
				caption: "Удалить",
				iconName: "Trash",
				size: "16",
				action: handleDelete,
				isDivider: false
			}
		];
	}, []);

	const contextMenuOptions: IContextMenuOption[] = useMemo(() => {
		if (
			props.detailEntitiesStore.entity?.entity.isCheckedAll ||
			(props.detailEntitiesStore.entity?.entity.includedIds && props.detailEntitiesStore.entity?.entity.includedIds.length! > 1)
		) {
			return contextMenuWhenMoreCheckedElements;
		} else {
			return contextMenuWhenOneCheckedElement;
		}
	}, [props.detailEntitiesStore.entity?.entity.isCheckedAll, props.detailEntitiesStore.entity?.entity.includedIds.map((item) => item)]);

	return (
		<div className={styles.detailBody} ref={wrapperRef}>
			<GeneralizedGrid
				entity={props.detailEntitiesStore.entity!}
				isDetailGrid={true}
				onChangeCheckedAll={handleCheckAll}
				contextMenuOptions={contextMenuOptions}
				onDoubleRowClick={handleDoubleRowClick}
			/>
		</div>
	);
});

export default DetailBody;
