import { observer } from "mobx-react-lite";
import { action } from "mobx";

import { CheckBox, Input, InputDate, InputNumber, InputStyleName, SearchSelect, SelectStyleName, WarningDialog } from "components";
import { SyntheticEvent, useEffect, useRef, useState } from "react";
import { ColumnTypeName } from "pages/settings/data/Fields";

import ListStore from "entities/ListStore";
import Entity, { IEntityStore } from "entities/Entity";
import ISort from "entities/ISort";
import { Lookup } from "entities/lookup/Lookup";
import { loadEntity } from "app/services/lookup/LookupService";
import { DirectorSimpleFilter } from "app";
import { ComparisonType, DataValueType } from "entities/filter/IFilter";

import { getValue, SortOldToNew, SaveAction, DeleteIcon } from "shared";
import { LowFirst } from "shared";

import styles from "./grid.module.css";

export interface IColumn {
	columnName: string;
	columnTitle: string;
	columnType: ColumnTypeName;
	isLookup?: boolean;
	lookupTable?: string;
	width?: number;
	sortColumn?: string;
	canSort?: boolean;
	value?: any;
}

const Header = observer(function (props: { columns: IColumn[]; onSortClick: (column: IColumn) => void }) {
	return (
		<div className={styles.dataTableHeader} style={{ paddingLeft: "60px", paddingRight: "30px" }}>
			{props.columns.map((column: IColumn, i: number) => {
				return (
					<>
						<div
							key={column.columnName}
							id={column.columnTitle}
							className={styles.headerCell}
							style={{ width: `calc(${column.width! + "%"} - 20px)` }}
						>
							<div className={styles.headerCaption}>{column.columnTitle}</div>
							{
								<SortOldToNew
									className={styles.sortIcon}
									onClick={() => {
										props.onSortClick(column);
									}}
								/>
							}
						</div>
					</>
				);
			})}
		</div>
	);
});

const defaultLimit = 30;

const EditableGrid = observer(function (props: {
	columns: IColumn[];
	listStore: ListStore;
	isCheckBox?: boolean;
	clickedNew?: boolean;
	entityName: string;
	entityTitle: string;
	cancelNewEntity: () => void;
	clickedId: string;
	sort: ISort;
}) {
	const listStore = props.listStore;
	const clickedNew = props.clickedNew;
	const [clickedId, setClickedId] = useState(props.clickedId);
	let sort = useRef<ISort>(props.sort);
	const [entities, setEntities] = useState<Entity<Lookup>[]>([]);
	let [newEntity, setNewEntity] = useState<Entity<Lookup>>(new Entity<Lookup>(new Lookup()));

	useEffect(() => {
		if (listStore) listStore?.load(listStore.filter, null, defaultLimit, sort.current);
	}, []);
	useEffect(() => {
		if (clickedNew) {
			newEntity.entity.setValue(props.columns, "columns");
			newEntity.entity.setValue(props.entityTitle, "displaySchema");
			newEntity.entity.setValue(props.entityName, "schema");
			setClickedId(newEntity.entity.id);
		}
	}, [clickedNew]);

	useEffect(() => {
		if (listStore && listStore!.isLoaded) {
			let array: Entity<Lookup>[] = [];

			listStore?.data.map((row) => {
				let newEn = new Entity<Lookup>(new Lookup());
				newEn.entity.setValue(props.columns, "columns");
				newEn.entity.setValue(row.id, "id");
				newEn.entity.setValue(props.entityTitle, "displaySchema");
				newEn.entity.setValue(props.entityName, "schema");
				array.push(newEn);
			});
			setEntities(array);
		}
	}, [listStore!.isLoaded]);

	function clearNewEntity() {
		setNewEntity(new Entity<Lookup>(new Lookup()));
		props.cancelNewEntity();
	}

	function setClickedNull() {
		setClickedId("");
	}

	function trackScrolling(e: SyntheticEvent<HTMLDivElement>) {
		const wrappedElement = e.target as HTMLDivElement;
		if (Math.round(wrappedElement.scrollHeight - wrappedElement.scrollTop) <= wrappedElement.clientHeight + 1 && listStore!.isLoaded) {
			listStore?.loadMore(null, defaultLimit, sort.current);
		}
	}
	function onSortClick(column: IColumn) {
		if (sort.current.columnPath == column.columnName) {
			if (sort.current.direction == 0) {
				sort.current.direction = 1;
			} else {
				sort.current.direction = 0;
			}
		} else {
			sort.current = {
				columnPath: column.columnName,
				direction: 0
			};
		}
		listStore.load(listStore.filter, null, defaultLimit, sort.current);
	}

	function handleClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
		const target = e.target as HTMLElement;
		if (
			target.className &&
			typeof target.className === "string" &&
			(target.className.includes("grid_dataTableRow") ||
				target.className.includes("grid_value") ||
				target.className.includes("input") ||
				target.className.includes("select"))
		) {
			return;
		} else if (props.clickedNew) {
			clearNewEntity();
		} else setClickedNull();
	}

	//TODO переделать на новый скелетон
	// if (listStore && listStore!.isLoading) {
	// 	return (
	// 		<div className={styles.loading} />
	// 	);
	// }

	return (
		<div
			className={styles.dataGrid}
			style={{ marginTop: "15px" }}
			onClick={(e) => {
				handleClick(e);
			}}
		>
			<Header columns={props.columns} onSortClick={onSortClick} />
			<div className={styles.dataTable} onScroll={trackScrolling}>
				{clickedNew && (
					<Row
						key={newEntity.entity.id}
						columns={props.columns}
						row={newEntity.entity}
						onClick={(id: string) => {
							setClickedId(id);
						}}
						isCheckBox={props.isCheckBox}
						checked={false}
						onChangeChecked={listStore.onChangeChecked}
						listStore={props.listStore!}
						clickedId={clickedId}
						entityName={props.entityName}
						entityTitle={props.entityTitle}
						setClickedNull={setClickedNull}
						isNew={clickedNew}
						cancelNewEntity={clearNewEntity}
						sort={sort.current}
						entity={newEntity}
					/>
				)}
				{listStore &&
					!listStore?.isError &&
					listStore?.data.map((row) => {
						return (
							<Row
								key={row["id"]}
								columns={props.columns}
								row={row}
								onClick={(id: string) => {
									setClickedId(id);
								}}
								isCheckBox={props.isCheckBox}
								isUnread={row.isUnread}
								checked={
									listStore.includedIds.findIndex((x) => x.id === row.id) > -1 ||
									(listStore.isCheckedAll && listStore.excludedIds.findIndex((x) => x.id === row.id) == -1)
										? true
										: false
								}
								onChangeChecked={listStore.onChangeChecked}
								listStore={props.listStore!}
								clickedId={clickedId}
								entityName={props.entityName}
								entityTitle={props.entityTitle}
								setClickedNull={setClickedNull}
								isNew={false}
								cancelNewEntity={clearNewEntity}
								sort={sort.current}
								entity={entities.find((x) => x.entity.id === row.id)!}
							/>
						);
					})}
				{listStore?.isError && <div>{listStore.error}</div>}
			</div>
		</div>
	);
});

const Row = observer(function (props: {
	columns: IColumn[];
	row: any;
	onClick?: (row: any) => void;
	isCheckBox?: boolean;
	isUnread?: boolean;
	checked: boolean;
	onChangeChecked: (checked: boolean, entity: IEntityStore) => void;
	listStore: ListStore;
	clickedId: string;
	entityName: string;
	entityTitle: string;
	setClickedNull: () => void;
	isNew: boolean;
	cancelNewEntity?: () => void;
	sort: ISort;
	entity: Entity<Lookup>;
}) {
	const { columns, row, entity, isNew } = props;
	const [openedWarningDialog, setOpenedWarningDialog] = useState(false);

	function onClick(id: string) {
		entity.entity.deserialize(row);
		if (id !== props.clickedId) {
			props.cancelNewEntity!();
			if (props.onClick) {
				props.onClick(id);
			}
		}
	}

	function handleChange(value: boolean) {
		props.onChangeChecked(value, entity!.entity);
	}

	async function saveItems() {
		try {
			if (entity.entity.id === "") entity.isNew = true;
			await entity.save();
		} catch (error: any) {
			if (error.response.status === 401) {
			}
		} finally {
			props.cancelNewEntity!();
			props.setClickedNull();
			props.listStore?.load(props.listStore.filter, null, defaultLimit, props.sort);
		}
	}

	function copyItem() {}

	async function deleteItem() {
		try {
			if (isNew) {
				props.cancelNewEntity!();
			} else await entity.delete(props.entityName, entity.entity.id);
			props.listStore?.load(props.listStore.filter, null, defaultLimit, props.sort);
		} catch (error: any) {
			if (error.response.status === 401) {
				console.log(error.response);
			}
		} finally {
			props.setClickedNull();
			setOpenedWarningDialog(false);
		}
	}

	let classNames = `${styles.dataTableRow} `;

	if (props.checked) {
		classNames += `${styles.activeEditableTableRow} `;
	}

	return (
		<div
			className={classNames}
			onClick={() => onClick(row.id)}
			style={props.clickedId === row.id ? { borderBottom: "1px solid var(--color-indigo-600)" } : {}}
		>
			{props.isCheckBox && (
				<CheckBox
					className={styles.checkBoxGrid}
					checked={props.checked}
					onChangeChecked={handleChange}
					block={props.clickedId === row.id ? true : undefined}
				/>
			)}
			{/* <div className={styles.unreadGridDetailIcon}>
                    {props.isUnread &&
                        <Unread />
                    }
                </div> */}
			{columns.map((column, i) => {
				return <Value row={row} clicked={row.id === props.clickedId} column={column} entity={entity?.entity} />;
			})}
			{row.id === props.clickedId && <Actions save={saveItems} copy={copyItem} delete={() => setOpenedWarningDialog(true)} />}
			<WarningDialog
				value={"Данная запись будет удалена без возможности восстановления.\nУдалить?"}
				valueReturn={"Отмена"}
				valueDelete={"Удалить"}
				isOpen={openedWarningDialog}
				onBackClick={() => {
					setOpenedWarningDialog(false);
				}}
				onCancelClick={deleteItem}
			/>
		</div>
	);
});

const Value = observer(function (props: { row: any; column: IColumn; clicked: boolean; entity: Lookup }) {
	const { clicked, column, row, entity } = props;
	let focusCell = useRef<boolean>(false);

	const [value, setValue] = useState(row[LowFirst(column.columnName)]);

	function setValueInRow(value: any) {
		setValue(value);
		if (column.columnName.endsWith("Id")) {
			row[LowFirst(column.columnName.slice(0, -2))] = value;
			row[LowFirst(column.columnName)] = value.id;
		} else row[LowFirst(column.columnName)] = value;
	}

	useEffect(() => {
		if (column.columnName.endsWith("Id") && column.columnName !== "Id") {
			setValue(row[LowFirst(column.columnName.slice(0, -2))]);
		}
	}, []);

	return (
		<div
			key={column.columnName}
			className={styles.cell}
			onClick={() => {
				focusCell.current = true;
			}}
			style={{ width: `calc(${column.width! + "%"} - 20px)` }}
		>
			{clicked === true && LowFirst(column.columnName) != "id" && !column.columnName.endsWith("edOn") ? (
				<ValueEditable column={column} entity={entity} value={value} setValue={setValueInRow} focusCell={focusCell.current} />
			) : (
				<span className={styles.value}>
					<ValueWithType column={column} value={value} />
				</span>
			)}
		</div>
	);
});

function ValueWithType(props: { column: IColumn; value: any }) {
	const { column, value } = props;

	if (column.isLookup) {
		return <>{getValue(value)}</>;
	}

	switch (column.columnType) {
		case ColumnTypeName.Logical:
			return <CheckBox checked={value} onChangeChecked={() => {}} />;
		default:
			return <>{value}</>;
	}
}

function ValueEditable(props: { column: IColumn; entity: Lookup; value: any; setValue: (value: any) => void; focusCell: boolean }) {
	const { column, entity, value, setValue, focusCell } = props;
	const [entities, setEntities] = useState<any[]>([]);

	function setValueInEntity(val: any, columnName: string) {
		setValue(val);
		entity.setValueInColumn(val, columnName);
	}

	if (column.isLookup) {
		return (
			<SearchSelect
				items={entities}
				className={styles.searchSelect}
				value={value}
				onChangeValue={(val: any) => {
					setValue(val);
					entity.setValueInColumn(val.id, column.columnName);
				}}
				onItemsLoad={async (value) => {
					const schema = column.lookupTable!;
					const filter = DirectorSimpleFilter.CreateAttribute(
						value,
						ComparisonType.Contain,
						{ Name: "Name", Type: DataValueType.Text, DisplayName: "" },
						schema
					);
					setEntities(await loadEntity(schema, value ? filter : undefined));
					return new Promise(() => 1);
				}}
				isInput={true}
				selectStyle={SelectStyleName.LookupValue}
				inputStyle={InputStyleName.BaseWithoutBorder}
			/>
		);
	}

	switch (props.column.columnType) {
		case ColumnTypeName.Text:
			return (
				<Input
					value={value}
					onChangeValue={action((val: any) => {
						setValueInEntity(val, column.columnName);
					})}
					placeholder=""
					focus={focusCell}
					inputStyle={InputStyleName.LookupValue}
				/>
			);
		case ColumnTypeName.Logical:
			return (
				<CheckBox
					checked={value}
					onChangeChecked={(val: any) => {
						setValueInEntity(val, column.columnName);
					}}
				/>
			);
		case ColumnTypeName.DateTime:
			return (
				<InputDate
					value={value}
					onChangeValue={(val: any) => {
						setValueInEntity(val, column.columnName);
					}}
					className={styles.editableGridInput}
				/>
			);
		case ColumnTypeName.Decimal:
			return (
				<InputNumber
					value={value}
					placeholder={""}
					onChangeValue={(val: any) => {
						setValueInEntity(val, column.columnName);
					}}
					className={styles.editableGridInput}
				/>
			);
		case ColumnTypeName.Integer:
			return (
				<InputNumber
					value={value}
					placeholder={""}
					onChangeValue={(val: any) => {
						setValueInEntity(val, column.columnName);
					}}
					className={styles.editableGridInput}
				/>
			);
		default:
			return <span className={styles.value}> {value} </span>;
	}
}

const Actions = observer(function (props: { save: () => void; copy: () => void; delete: () => void }) {
	return (
		<div className={styles.editableRowActions}>
			<div className={styles.actionsTool}>
				<span className={styles.tooltip}>
					<span> Сохранить </span>
				</span>
				<SaveAction onClick={props.save} />
			</div>
			<div className={styles.actionsTool}>
				<span
					className={styles.tooltip}
					style={{
						marginRight: "5px",
						marginLeft: "-25px"
					}}
				>
					<span> Удалить </span>
				</span>
				<DeleteIcon onClick={props.delete} className={styles.deleteButton} />
			</div>
		</div>
	);
});

export default EditableGrid;
