import { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import { v4 } from "uuid";
import { isNull, lowerCase } from "lodash";

import detailConfigurationStore, { DetailStoreField } from "../detail-configuration-store";
import { modalController } from "features/modals";
import { UpFirst } from "shared";
import { validateRequired } from "entities/Validation";

import { Field, Button, ButtonDropdown } from "sale-bridge-ui-kit";
import { ExistObjectConfiguration } from "./exist-object-configuration";
import { NewObjectConfiguration } from "./new-object-configuration";

import { Item } from "types";
import { StandartItem, StandartItemGroup } from "sale-bridge-ui-kit/dist/components/button-dropdown/button-dropdown";

import styles from "../detail-configuration.module.scss";

export const DetailFields = observer((props: { detailId?: string; close: () => void; closeAll: () => void }) => {
	const [idModal] = useState<string>(v4());
	const [searchValue, setSearchValue] = useState("");
	const [isSaveNewDetail, setSaveNewDetail] = useState<boolean>(false);

	const closeFuncWithOutConfirm = useCallback(() => {
		modalController.modalRemove(idModal);
	}, []);

	const handleSaveConfigWithNewLookup = useCallback(() => {
		detailConfigurationStore.resetNewObjectConfiguration();
		detailConfigurationStore.validDetail();
		detailConfigurationStore.setDefaultColumnId();
		setSaveNewDetail(true);
	}, []);

	const handleSaveConfigWithExistLookup = useCallback(() => {
		detailConfigurationStore.resetExistObjectConfiguration();
		detailConfigurationStore.validDetail();
		setSaveNewDetail(false);
	}, []);

	const handleDetailColumnsLoad = useCallback(
		async (value: string | null) => {
			try {
				if (detailConfigurationStore.detail) {
					await detailConfigurationStore.loadColumns(detailConfigurationStore.detail.name);
					const items: Item[] = [];
					if (value) {
						detailConfigurationStore.columnDetailList.forEach((item: any) => {
							const displayValue = lowerCase(item.displayValue);
							if (displayValue.includes(lowerCase(value))) items.push(item);
						});
						detailConfigurationStore.setValue(DetailStoreField.columnDetailList, items);
						return items.length;
					} else return detailConfigurationStore.columnDetailList.length;
				} else return -1;
			} catch (error) {
				console.error(error);
			}
		},
		[detailConfigurationStore.columnDetailList, detailConfigurationStore.detail]
	);

	const handleColumnsLoadFromNewDetail = useCallback(
		(value: string | null, column: Item) => {
			detailConfigurationStore.loadColumnsFromNewLookup(column);
			const items: Item[] = [];
			if (value) {
				detailConfigurationStore.columnDetailList.forEach((item: any) => {
					const displayValue = lowerCase(item.displayValue);
					if (displayValue.includes(lowerCase(value))) items.push(item);
				});
				detailConfigurationStore.setValue(DetailStoreField.columnDetailList, items);
				return items.length;
			} else return detailConfigurationStore.columnDetailList.length;
		},
		[detailConfigurationStore.columnDetailList]
	);

	const handleDetailColumnLoad = useCallback(
		(value: string | null) => {
			if (isSaveNewDetail) {
				handleColumnsLoadFromNewDetail(value, detailConfigurationStore.detailColumn!);
			} else {
				handleDetailColumnsLoad(value);
			}
		},
		[isSaveNewDetail, detailConfigurationStore.entityColumn]
	);

	function validate() {
		detailConfigurationStore.validFieldSettingsButton();
		detailConfigurationStore.validDetail();
	}

	const handleDetailChange = useCallback(
		(value: Item | null) => {
			detailConfigurationStore.setValue(DetailStoreField.detail, value);
			if (!isNull(value) && value.name) {
				detailConfigurationStore.loadColumns(value.name);
			}
			handleDetailColumnLoad(null);
			setSaveNewDetail(false);
			validateRequired(detailConfigurationStore.detail, detailConfigurationStore.validation.detail, true);
			validate();
		},
		[detailConfigurationStore.columnList]
	);

	const handleDetailTitleChange = useCallback((value: string) => {
		detailConfigurationStore.setValue(DetailStoreField.detailTitle, value);
		detailConfigurationStore.validateDetailTitle();
		validate();
	}, []);

	const handleDetailNameChange = useCallback(async (value: string) => {
		detailConfigurationStore.setValue(DetailStoreField.detailName, UpFirst(value));
		await detailConfigurationStore.validateDetailSystemName();
		validate();
	}, []);

	const detailItems = useMemo(() => {
		const items: Item[] = [];
		detailConfigurationStore.detailList.forEach((item: any) => {
			const displayValue = lowerCase(item.displayValue);
			if (displayValue.includes(lowerCase(searchValue))) items.push(item);
		});
		return [
			{
				layout: <></>,
				items: items.map((item) => (
					<div className={styles.selectItem} onClick={() => handleDetailChange(item)}>
						<span>{item.displayValue}</span>
					</div>
				))
			}
		];
	}, [toJS(detailConfigurationStore.detailList), searchValue]);

	const addDetailButtonItems = useMemo(
		(): Array<StandartItemGroup> => [
			{
				header: "",
				items: [
					{ id: "existsObj", name: "По существующему объекту" },
					{ id: "newObj", name: "По новому объекту" }
				]
			}
		],
		[]
	);

	const handleClickToButtonAdd = useCallback((id: string) => {
		if (id === "existsObj") {
			detailConfigurationStore.setValue(DetailStoreField.header, "По существующему объекту");
			modalController.popupAdd({
				id: idModal,
				layout: (
					<ExistObjectConfiguration
						close={closeFuncWithOutConfirm}
						closeAll={props.closeAll}
						onSave={handleSaveConfigWithExistLookup}
					/>
				),
				closeFunc: closeFuncWithOutConfirm
			});
		} else {
			detailConfigurationStore.setValue(DetailStoreField.header, "По новому объекту");
			modalController.popupAdd({
				id: idModal,
				layout: (
					<NewObjectConfiguration
						close={closeFuncWithOutConfirm}
						closeAll={props.closeAll}
						onSave={handleSaveConfigWithNewLookup}
					/>
				),
				closeFunc: closeFuncWithOutConfirm
			});
		}
	}, []);

	return (
		<div className={styles.fieldsBlock}>
			<div className={styles.detailBlock}>
				<Field
					type="select"
					size="small"
					label="Выберите деталь"
					labelPosition="vertical"
					textVariant="outlined"
					placeholder="Выберите деталь"
					info="Список всех объектов в системе, не являющихся справочниками"
					value={detailConfigurationStore.detail?.displayValue ?? ""}
					items={detailItems}
					isRequired
					onChange={() => {}}
					alert={detailConfigurationStore.validation.detail.isInvalid ? "error" : undefined}
					searchValue={searchValue}
					onChangeSearchValue={setSearchValue}
				/>
				{!props.detailId && (
					<ButtonDropdown
						size="small"
						position="down"
						standartItems={addDetailButtonItems}
						onClickStandartItem={handleClickToButtonAdd}
						childrenButton={
							<Button
								leftIcon="Add"
								rightIcon="Dropdown"
								size="small"
								text="Создать новую деталь"
								variant="secondary"
								onClick={() => {}}
								link={false}
								loading={false}
								border={false}
							/>
						}
					/>
				)}
			</div>
			<Field
				type="text"
				size="small"
				label="Заголовок детали"
				labelPosition="vertical"
				textVariant="outlined"
				placeholder="Заголовок таблицы детали в базе данных"
				info="Название детали, которое будет отображаться на странице записи"
				isRequired
				value={detailConfigurationStore.detailTitle}
				counter={50}
				isClearing
				onChange={handleDetailTitleChange}
				// disabled={isDisabled}
				alert={detailConfigurationStore.validation.detailTitle.isInvalid ? "error" : undefined}
			/>
			<Field
				type="text"
				size="small"
				label="Системное название детали"
				labelPosition="vertical"
				textVariant="outlined"
				placeholder="Название таблицы детали в базе данных"
				info="Уникальный идентификатор детали в системе"
				isRequired
				value={detailConfigurationStore.detailName}
				counter={50}
				isClearing
				onChange={handleDetailNameChange}
				// disabled={isDisabled}
				alert={detailConfigurationStore.validation.detailName.isInvalid ? "error" : undefined}
			/>
		</div>
	);
});
