import { StageActionProps } from "./stage-actions";

import { CreateActivity, SendEmail, ActionEditRecord, AddRectangle } from "shared";

export const stageActionPropsArray: StageActionProps[] = [
	{ icon: <CreateActivity />, actionName: "Создать активность", action: () => {} },
	{ icon: <SendEmail />, actionName: "Отправить письмо", action: () => {} },
	{ icon: <AddRectangle />, actionName: "Запустить процесс", action: () => {} },
	{ icon: <ActionEditRecord />, actionName: "Открыть запись", action: () => {} }
];
