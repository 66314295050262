import { observer } from "mobx-react";

import { CommentBodyVariant, CommentInput, CommentProvider, CommentsBody } from "features/comments";
import { Header } from "./components";
import { Fields } from "..";

import CommentEntity from "entities/CommentEntity";

import { GridItem } from "types/entity";

import styles from "./left-block-body.module.scss";

export interface LeftBlockBodyProps {
	fields: GridItem[];
	commentsEnable: boolean;
	commentEntity: CommentEntity | null;
	title: string;
	schema: string;
	recordId: string;
	onCommentSubmit: (text: string) => void;
}

export const LeftBlockBody = observer(function (props: LeftBlockBodyProps) {
	return (
		<div className={styles.wrapper}>
			<CommentProvider customUpdate={props.onCommentSubmit}>
				<>
					<div className={styles.scrollableContainer}>
						<Header header={props.title} schema={props.schema} id={props.recordId} />
						<Fields items={props.fields} blockPosition="left" />
						{props.commentEntity && props.commentsEnable ? (
							<div className={styles.commentsBlock}>
								<span className={styles.commentHeader}>Комментарии</span>
								<CommentsBody
									entityName={props.schema}
									recordId={props.recordId}
									blockVariant={CommentBodyVariant.QuickView}
								/>
							</div>
						) : (
							<></>
						)}
					</div>
					{props.commentEntity && props.commentsEnable && <CommentInput blockVariant={CommentBodyVariant.QuickView} />}
				</>
			</CommentProvider>
		</div>
	);
});
