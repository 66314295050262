import { observer } from "mobx-react";
import { CSSProperties, useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { v4 } from "uuid";

import authStore from "AuthStore";
import { dispatcher, selector } from "store";
import { synchroiser } from "synchroiser";
import FilterBuilder from "app/services/filter/FilterBuilder";

import StaticGroupFolderTreeItem from "./static-group-folder-tree-item";

import { Position, modalController } from "features/modals";
import MovingFolder from "../../moving-folder/moving-folder";
import { LayoutDeleteConfirm, RenameLayout } from "features/advanced-filter/filter-popups";

import { Select, ButtonStyle } from "components";

import { Item } from "types";
import { FolderActionEnum, GroupFolderActions } from "../utils/data";
import { LoadingState, StaticGroup, StaticGroupFolder } from "types/entity";

import { ArrowToDown, Folder, MoreEllipsisVertical } from "shared";

import styles from "../filter-folder/filter-folder-tree.module.css";

const StaticGroupFolderTree = observer(function (props: { staticGroupFolder: StaticGroupFolder }) {
	const [idModal] = useState<string>(v4());
	const [idNotification] = useState<string>(v4());
	const [isOpened, setOpened] = useState(false);
	const [selectStyle, setSelectStyle] = useState<CSSProperties | undefined>(undefined);

	const arrowToDownClassNames = classNames(styles.folderArrow, {
		[`${styles.open} `]: isOpened
	});

	const listClassNames = classNames(styles.folderList, {
		[`${styles.visible}`]: isOpened
	});

	const addErrorNotification = useCallback(() => {
		if (synchroiser.loadingState === LoadingState.Error && synchroiser.errorMessage) {
			const layoutNotificationWithError = <div className={styles.notification}>{synchroiser.errorMessage}</div>;

			modalController.notificationAdd({
				id: idNotification,
				position: Position.CENTER,
				layout: layoutNotificationWithError,
				allowDefaultClick: true,
				allowTimer: true
			});
		}
	}, [synchroiser.errorMessage]);

	useEffect(() => {
		if (props.staticGroupFolder.isRoot) {
			setOpened(true);
		}
	}, [props.staticGroupFolder.isRoot]);

	const closeConfirm = useCallback((e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
		e?.stopPropagation();
		modalController.modalRemove(idModal);
	}, []);

	const onMouseEnter = useCallback(() => {
		setSelectStyle({ visibility: "visible" });
	}, []);

	const onMouseLeave = useCallback(() => {
		setSelectStyle({ visibility: "hidden" });
	}, []);

	const openFolder = useCallback(() => {
		setOpened(!isOpened);
	}, [isOpened]);

	const handleChangeValue = useCallback(
		(value: Item | null) => {
			if (value) {
				switch (value.id) {
					case FolderActionEnum.CreateGroup:
						modalController.popupAdd({
							id: idModal,
							layout: (
								<RenameLayout
									dialogTitle="Новая статическая группа"
									captionSuccessfulButton="Сохранить"
									startName={null}
									onRename={createGroup}
									onClose={closeConfirm}
								/>
							),
							closeFunc: closeConfirm
						});
						break;
					case FolderActionEnum.CreateFolder:
						modalController.popupAdd({
							id: idModal,
							layout: (
								<RenameLayout
									dialogTitle="Новая папка"
									captionSuccessfulButton="Сохранить"
									startName={null}
									onRename={createFolder}
									onClose={closeConfirm}
								/>
							),
							closeFunc: closeConfirm
						});
						break;
					case FolderActionEnum.MoveFolder:
						modalController.popupAdd({
							id: idModal,
							layout: (
								<MovingFolder
									savedFilterFolder={null}
									staticGroupFolder={props.staticGroupFolder}
									savedFilter={null}
									staticGroup={null}
									onClose={closeConfirm}
								/>
							),
							closeFunc: closeConfirm
						});
						break;
					case FolderActionEnum.RenameFolder:
						modalController.popupAdd({
							id: idModal,
							layout: (
								<RenameLayout
									dialogTitle="Переименовать папку"
									startName={props.staticGroupFolder.name}
									onRename={updateFolder}
									onClose={closeConfirm}
								/>
							),
							closeFunc: closeConfirm
						});
						break;
					case FolderActionEnum.DeleteFolder:
						modalController.popupAdd({
							id: idModal,
							layout: (
								<LayoutDeleteConfirm
									delete={deleteFolder}
									dialogBody={
										<>
											Вы уверены, что хотите удалить «{props.staticGroupFolder.name}»?
											<br />
											Содержимое папки также будет удалено.
										</>
									}
									closeConfirm={closeConfirm}
								/>
							),
							closeFunc: closeConfirm
						});
						break;
				}
			}
		},
		[props.staticGroupFolder]
	);

	const createGroup = useCallback(
		async (groupName: string | null) => {
			if (groupName) {
				const currentEntity = dispatcher.entity.get();
				if (currentEntity) {
					const newGroup: StaticGroup = {
						userId: authStore.userId,
						entityName: dispatcher.entity.get()?.entityName!,
						name: groupName,
						filter: null,
						folderId: props.staticGroupFolder.isRoot ? null : props.staticGroupFolder.id!
					};

					const filter = new FilterBuilder(currentEntity.entityName);

					const currentSavedFilter = selector.filter.getFilter()?.savedFilter;
					if (currentSavedFilter && currentSavedFilter.filterInfo && currentEntity.entity.includedIds.length == 0) {
						filter.filter = currentSavedFilter.filterInfo.serialize();
					}

					filter.filter.includedIds = currentEntity.entity.includedIds.map((e) => e.id);
					filter.filter.excludedIds = currentEntity.entity.excludedIds.map((e) => e.id);
					newGroup.filter = filter.filter;

					await synchroiser.saveStaticGroup(newGroup).then(async () => {
						if (synchroiser.loadingState !== LoadingState.Error) {
							dispatcher.entity.onChangeCheckedAll(false);
							await synchroiser.getStaticGroupFolderTree();
						}
					});
				}
			}
		},
		[props.staticGroupFolder, dispatcher.entity.get()]
	);

	const createFolder = useCallback(
		async (folderName: string | null) => {
			const staticGroupFolder: StaticGroupFolder = {
				parentFolderId: props.staticGroupFolder.id!,
				name: folderName,
				groups: [],
				childGroupFolders: [],
				isRoot: false,
				entityName: dispatcher.entity.get()!.entityName
			};

			await synchroiser.saveFilterFolder(null, staticGroupFolder).then(() => {
				if (synchroiser.loadingState !== LoadingState.Error) {
					props.staticGroupFolder.childGroupFolders.push(staticGroupFolder);
				}
			});
			addErrorNotification();
		},
		[props.staticGroupFolder, synchroiser.loadingState]
	);

	const updateFolder = useCallback(
		async (folderName: string | null) => {
			const updatedFolder = { ...props.staticGroupFolder, name: folderName } as StaticGroupFolder;
			await synchroiser.updateFilterFolder(null, updatedFolder).then(() => {
				if (synchroiser.loadingState !== LoadingState.Error) {
					props.staticGroupFolder.name = folderName;
				}
			});
			addErrorNotification();
		},
		[props.staticGroupFolder, synchroiser.loadingState]
	);

	const flatten = useCallback((staticGroupFolder: StaticGroupFolder): StaticGroup[] => {
		return staticGroupFolder.childGroupFolders.reduce<StaticGroup[]>((acc, item) => {
			return [...item.groups, ...flatten(item)];
		}, []);
	}, []);

	const deleteFolder = useCallback(async () => {
		if (props.staticGroupFolder.id) {
			await synchroiser.deleteFolder(null, props.staticGroupFolder).then(async () => {
				if (synchroiser.loadingState !== LoadingState.Error) {
					const groupsArray =
						props.staticGroupFolder.groups.length > 0
							? [...props.staticGroupFolder.groups, ...flatten(props.staticGroupFolder)]
							: flatten(props.staticGroupFolder);
					if (groupsArray.find(({ id }) => id === selector.filter.getFilter()?.staticGroup?.id)) {
						dispatcher.filter.setStaticGroup(null);
						dispatcher.filter.setPlaceholder();
						await synchroiser.getEntityWithFilter();
						addErrorNotification();
					}
				}
			});
		}
	}, [props.staticGroupFolder]);

	return (
		<>
			<div onClick={openFolder} className={styles.folderName} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
				<ArrowToDown className={arrowToDownClassNames} />
				<Folder style={{ minWidth: "fit-content" }} />
				<span>{props.staticGroupFolder.name}</span>
				<Select
					items={props.staticGroupFolder.isRoot ? [GroupFolderActions[0], GroupFolderActions[1]] : GroupFolderActions}
					onChangeValue={handleChangeValue}
					styles={ButtonStyle.IconIndigo}
					firstIcon={<MoreEllipsisVertical />}
					className={styles.selectFolder}
					classNameButton={styles.selectFolderButton}
					isPositionLeft={true}
					style={selectStyle}
					onClick={(e) => {
						e.stopPropagation();
					}}
				/>
			</div>
			{isOpened && (
				<ul className={listClassNames}>
					{props.staticGroupFolder?.groups.map((item) => {
						return <StaticGroupFolderTreeItem item={item} />;
					})}
					{props.staticGroupFolder.childGroupFolders.map((item, i) => {
						return (
							<li key={i} className={styles.childFolder}>
								<StaticGroupFolderTree staticGroupFolder={item} />
							</li>
						);
					})}
				</ul>
			)}
		</>
	);
});

export default StaticGroupFolderTree;
