import { observer } from "mobx-react-lite";
import { isUndefined, toNumber } from "lodash";
import { Field } from "sale-bridge-ui-kit";

export type Props = {
	columnName: string | undefined;
	columnTitle?: string | undefined;
	prompt?: string | undefined;
	isRequired?: boolean | undefined;
	rounding?: string | undefined;
	value: string;
	isDisabled?: boolean;
	onChange: (value: string) => void;
	variant: "standart" | "outlined";
	labelPosition?: "horizontal" | "vertical";
	labelVariant?: "black" | "gray";
};

export const Decimal = observer(function (props: Props) {
	return (
		<>
			{!isUndefined(props.value) && (
				<Field
					type="number"
					subType="decimal"
					size="small"
					label={props.columnTitle ?? ""}
					labelPosition={props.labelPosition ?? "vertical"}
					labelVariant={props.labelVariant}
					isRequired={props.isRequired}
					value={props.value?.toString()}
					info={props.prompt}
					floor={toNumber(props.rounding)}
					onChange={props.onChange}
					isDisabled={props.isDisabled}
					placeholder=""
					textVariant={props.variant}
				/>
			)}
		</>
	);
});
