import { StageAction } from "./stage-action";

export type StageActionProps = {
    icon: JSX.Element;
    actionName: string;
    action: () => void;
};

export function StageActions(props: { [key: string]: any }) {
    return (
        props.stageActionProps.map(({ icon, actionName }: StageActionProps, index: number) => (
            <StageAction key={index} icon={icon} actionName={actionName} />
        ))
    )
};