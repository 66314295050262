import { GridItem } from "types/entity";

/**
 * @description Метод для получения columnName в которых искать id текущих стадии записей
 * @param items - dispatcher.sectionWizzard.getAllGridItems()
 */
export function getStagesColumnName(items: Array<GridItem>): string | null {
    return items.find((gridItem) => gridItem.fieldConfig?.specializations?.properties.stageField)?.fieldConfig?.columnName ?? null;
}

/**
 * @description Метод для получения columnName в которых искать id текущих приоритетов записей
 * @param items - dispatcher.sectionWizzard.getAllGridItems()
 */
export function getPriorityColumnName(items: Array<GridItem>): string | null {
    return items.find((gridItem) => gridItem.fieldConfig?.specializations?.properties.priorityField)?.fieldConfig?.columnName ?? null;
}