import * as React from 'react';
import * as ReactDOM from 'react-dom';

import './modal.css';

class Modal extends React.Component<any, any> {
    private el: HTMLDivElement;

    constructor(props: any) {
        super(props);
        this.el = document.createElement('div');
        this.el.className = "modal";
    }

    public componentDidMount() {
        document.body.appendChild(this.el);
        document.body.classList.add("modal-opened");
    }

    public componentWillUnmount() {
        document.body.removeChild(this.el);
        document.body.classList.remove("modal-opened");
    }
    public render() {
        return ReactDOM.createPortal(this.props.children, this.el);
    }
}

export default Modal;
