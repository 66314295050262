import { makeAutoObservable } from "mobx";

interface IRequestStore{
    mappingProperties:IMappingProperties[];
    tableName:string;
    upsertSettings: IUpsertSettings;

    setTable: (table:string) => void;
}

interface IMappingProperties{
    columnInFile: string;
    columnInModel: string;
}

interface IUpsertSettings{
    tableName:string;
    keyFields:Set<string>;
    operation:UpsertOperation;
    mode:UpsertMode;

    setMode:(mode:UpsertMode) => void;
    setOperation:(operation:UpsertOperation) => void;

    deleteKey:(key:string)=>void;
    addKey:(key:string)=>void;
}

enum UpsertOperation{
    And,
    Or
}

enum UpsertMode
{
    Skip,
    Update,
    Enrich
}

class RequestStore implements IRequestStore{
    mappingProperties: IMappingProperties[] = [];
    tableName: string;
    upsertSettings: IUpsertSettings;

    constructor(tableName:string){
        this.tableName = tableName;
        this.upsertSettings = new UpsertSettings(tableName);
        makeAutoObservable(this);
    }

    setTable(table:string|""){
        this.tableName = table;
        this.upsertSettings.tableName = table;
    }
}

class UpsertSettings implements IUpsertSettings{
    tableName: string;
    keyFields: Set<string> = new Set();
    operation: UpsertOperation = UpsertOperation.And;
    mode: UpsertMode = UpsertMode.Update;

    constructor(tableName:string){
        this.tableName = tableName;
        makeAutoObservable(this);
    }

    setMode(mode:UpsertMode){
        this.mode = mode;
    }

    setOperation(operation:UpsertOperation){
        this.operation = operation;
    }

    deleteKey(key:string){
        this.keyFields.delete(key);
    }

    addKey(key:string){
        this.keyFields.add(key);
    }
}

export type {IRequestStore}
export { UpsertOperation, UpsertMode, RequestStore}