import { DraggableProvidedDraggableProps, DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { observer } from "mobx-react-lite";
import { useState } from "react";

import { Button, ButtonStyle } from "../../components";
import Avatar from "features/avatar/avatar-generator";
import CopyHref from "entities/CopyHref";

import { AddFlag, AddTask, CopyLink, OpenInNewWindow, CheckboxDoneGreen } from "shared";
// import { AddFlag } from "shared";
// import { AddTask } from "shared";
// import { CopyLink } from "shared";
// import { OpenInNewWindow } from "shared";
// import { CheckboxDoneGreen } from "shared";

import styles from "./card.module.css";

enum ActionsId {
	CopyLink = 1,
	AddTask,
	AddFlag,
	OpenInNewWindow
}

interface ICardItems {
	icon?: JSX.Element;
	caption: string;
}

export interface ICardData {
	schema: string;
	id: string;
	stageId: string;
	priorityOrder: number;
	priority: string;
	priorityColor: string;
	cardTitle: string;
	cardItems: ICardItems[];
	createdOn: string;
	modifiedOn?: Date;
	owner: string;
	position: number;
}

interface ICardProps {
	data: ICardData;
	classname?: string;
	isSelected?: boolean;
	onClick?: () => void;
	messeges?: number;
	ref?: (element: HTMLElement | null) => void;
	dragHandleProps?: DraggableProvidedDragHandleProps | null | undefined;
	draggableProps?: DraggableProvidedDraggableProps;
	count: number;
}

const Card = observer(function (props: ICardProps) {
	const [selected, setSelected] = useState<any | null>(null);
	const [iconCopyLink, setIconCopyLink] = useState<JSX.Element | null>(null);
	let className = `${styles.card} `;

	let classNameActions = `${styles.actions} ${styles.visible} `;

	if (selected !== null) {
		if (iconCopyLink !== null && selected.id == ActionsId.CopyLink) classNameActions += `${styles.linkCopied} `;
		else classNameActions += `${styles.active} `;
	}

	function getDate(date: string) {
		return new Date(Date.parse(date)).toLocaleDateString("ru-Ru");
	}

	if (props.isSelected) {
		className += `${styles.selected} `;
		classNameActions += `${styles.selectedActions} `;
	}
	if (props.classname) className += props.classname;

	function hoverOn(id: any) {
		setSelected(id);
	}
	function hoverOff() {
		setSelected(null);
		setIconCopyLink(null);
	}

	return (
		<div className={className} onClick={props.onClick} ref={props.ref} {...props.dragHandleProps} {...props.draggableProps}>
			<div className={styles.cardHeader}>
				<div className={styles.priority} style={{ backgroundColor: props.data.priorityColor }}>
					{props.data.priority}
				</div>
			</div>
			<div className={styles.title}>{props.data.cardTitle}</div>
			<div className={styles.items}>
				{props.data.cardItems.map((item) => {
					return (
						<>
							{item.caption !== undefined && (
								<div className={styles.item}>
									{item.icon}
									<span>{item.caption}</span>
								</div>
							)}
						</>
					);
				})}
			</div>
			<div className={styles.cardFooter}>
				<div className={styles.avatarTool}>
					<span className={styles.tooltip}>Ответственный: {props.data.owner}</span>
					<Avatar name={props.data.owner} />
				</div>
				<div className={classNameActions}>
					{actionButtons.map((item, i) => {
						let className = styles.footerButton;
						if (selected !== null) {
							if (selected.left && selected.id < item.id) {
								className += ` ${styles.visibleButton} `;
							}
							if (!selected.left && selected.id > item.id) {
								className += ` ${styles.visibleButton} `;
							}
							if (selected.id == item.id) {
								className += ` ${styles.activeButton} `;
							}
						}

						return (
							<>
								{selected !== null && selected.id == item.id && (
									<span
										className={styles.footerButtonCaption}
										style={item.left ? { left: item.distance } : { right: item.distance }}
									>
										{iconCopyLink !== null && selected.id == ActionsId.CopyLink ? "ссылка скопирована!" : item.caption}
									</span>
								)}
								<Button
									key={item.id}
									firstIcon={item.icon}
									secondIcon={item.id == ActionsId.CopyLink ? <CheckboxDoneGreen /> : <></>}
									classNameFirstIcon={iconCopyLink !== null && item.id == ActionsId.CopyLink ? styles.linkCopiedIcon : ""}
									classNameSecondIcon={
										iconCopyLink !== null && item.id == ActionsId.CopyLink
											? styles.linkCopiedIconVisible
											: styles.linkCopiedIcon
									}
									style={ButtonStyle.Icon}
									className={className}
									onMouseEnter={() => {
										hoverOn(item);
									}}
									onMouseLeave={hoverOff}
									onClick={(e) => {
										e.stopPropagation();
										if (item.id == ActionsId.CopyLink && iconCopyLink === null) setIconCopyLink(<CheckboxDoneGreen />);

										if (item.id === ActionsId.CopyLink) {
											CopyHref(props.data.id);
										}
										if (item.id === ActionsId.OpenInNewWindow) {
											window.open(window.location.href + "/" + props.data.id);
										}
									}}
								/>
							</>
						);
					})}
				</div>
			</div>
		</div>
	);
});

function Count(props: { count: number }) {
	return <div className={styles.count}>{props.count}</div>;
}

const actionButtons = [
	{
		id: ActionsId.CopyLink,
		icon: <CopyLink />,
		caption: "скопировать ссылку",
		left: true,
		distance: "34px"
	},
	{
		id: ActionsId.AddTask,
		icon: <AddTask />,
		caption: "создать задачу",
		left: true,
		distance: "70px"
	},
	{
		id: ActionsId.AddFlag,
		icon: <AddFlag />,
		caption: "добавить флаг",
		left: false,
		distance: "73px"
	},
	{
		id: ActionsId.OpenInNewWindow,
		icon: <OpenInNewWindow />,
		caption: "открыть в нов. вкладке",
		left: false,
		distance: "31px"
	}
];

export default Card;
