import { useEffect, useState } from "react";
import CheckBox from "components/check-box/check-box";
import HorizontalField from "components/field/horizontal-field";
import IFilterItem from "../../model/FilterItem";
import styles from "./FilterItem.module.css";

interface IFilterItemProps{
    entity: IFilterItem;
    onChangeChecked: (table:string, value: boolean) => void;
    filtres:string[];
}

function FilterItem(props:IFilterItemProps){
    const [isChecked, setChecked] = useState<boolean>(true);

    useEffect(()=>{
        if(props.filtres.find(f => f === props.entity.table)) setChecked(true);
        else setChecked(false);
    }, [props.filtres])

    function onChangeChecked(value: boolean){
        setChecked(value);
        props.onChangeChecked(props.entity.table, value);
    }

    return(
        <div>
            <HorizontalField caption={props.entity.title} name={props.entity.table} className={styles.filter}>
                <CheckBox checked={isChecked} onChangeChecked={onChangeChecked} />
            </HorizontalField>
        </div>
    )
}

export default FilterItem;