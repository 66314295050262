import { action } from 'mobx'

import { store } from './store'

import { Types } from '../type'

enum status {
  NOT_EXIST_IN_MODEL = 'NOT_EXIST_IN_MODEL',
  NOT_EXIST_IN_CHILDREN = 'NOT_EXIST_IN_CHILDREN',
  ACTUAL = 'ACTUAL',
}

export function autoSyncZoneWithStore (zoneId: string, zoneType: Types, elements: any[]): void {
  const keys = Object.keys(store.zones[zoneId].elements)

  const actions = elements.map(elements => {
    if (keys.includes(elements.props.id)) {
      return {
        id: elements.props.id,
        status: status.ACTUAL
      }
    } else {
      return {
        id: elements.props.id,
        status: status.NOT_EXIST_IN_MODEL
      }
    }
  })

  keys.forEach((elementId) => {
    if (!elements.find(element => element.props.id === elementId)) {
      actions.push({
        id: elementId,
        status: status.NOT_EXIST_IN_CHILDREN
      })
    }
  })
  actions.forEach(elementStatus => {
    if (elementStatus.status === status.ACTUAL) {
      autoIndexingElementsByTypes(zoneId, zoneType, elements.find(searchElement => searchElement.props.id === elementStatus.id))
    } else if (elementStatus.status === status.NOT_EXIST_IN_MODEL) {
      autoIndexingElementsByTypes(zoneId, zoneType, elements.find(searchElement => searchElement.props.id === elementStatus.id))
    } else {
      delete store.zones[zoneId].elements[elementStatus.id]
    }
  })
}

function indexingMatrixElement (zoneId: string, element: any): void {
  action('add matrix element', (element: any) => {
    store.zones[zoneId].elements[element.props.id] = {
      layout: element,
      id: element.props.id,
      sourceData: element.props?.sourceData ?? null,
      position: {
        x: element.props.x - 1,
        y: element.props.y - 1
      },
      size: {
        width: element.props.width,
        height: element.props.height
      }
    }
  })(element)
}

function indexingHorizontalColumnElement (zoneId: string, element: any): void {
  action('add horizontal element element', (element: any) => {
    store.zones[zoneId].elements[element.props.id] = {
      layout: element,
      id: element.props.id,
      position: {
        x: element.props.x
      },
      pagePosition: {
        x: 0,
        y: 0,
        width: 0,
        height: 0
      }
    }
  })(element)
}

const actions = {
  [Types.MATRIX]: indexingMatrixElement,
  [Types.HORIZONTAL_COLUMN]: indexingHorizontalColumnElement
}

export function autoIndexingElementsByTypes (zoneId: string, zoneType: Types, element: JSX.Element): void {
  actions[zoneType]?.(zoneId, element)
}
