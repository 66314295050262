import { observer } from "mobx-react";

import { RightBlockDragZone } from "./right-block-drag-zone";
import { RightBlockFooterDates } from "./right-block-footer-dates";
import { AdditionalInfoOfField } from "../../quick-view-settings/data";

import { AdditionalField } from "types/entity";

import styles from "./constructor-quick-view-right-block.module.scss";


type RightAdditionalFieldsBlockProps = {
    rightBlockAdditionalDragFields: Array<AdditionalField>;
    rightBlockAdditionalBlockedFields: Array<AdditionalField>;
    rightBlockAdditionalFieldsZoneId: string;
    onChangeRightBlockAdditionalFields: (sourceZone: string | null, targetZone: string | null, additionalField: AdditionalField, newPosition: number) => void;
    onDeleteFieldFromRightBlock: (gridItemId: string, isLeftFieldBlock?: boolean) => void;
    getAdditionalInfoOfField: (columnId: string) => AdditionalInfoOfField
    createdOnTitle: string;
    modifiedOnTitle: string;
}

export const RightAdditionalFieldsBlock = observer((props: RightAdditionalFieldsBlockProps) => {
    return (
        <div className={styles.rightBody}>
            <RightBlockDragZone
                rightBlockAdditionalDragFields={props.rightBlockAdditionalDragFields}
                rightBlockAdditionalBlockedFields={props.rightBlockAdditionalBlockedFields}
                rightBlockAdditionalFieldsZoneId={props.rightBlockAdditionalFieldsZoneId}
                onChangeRightBlockAdditionalFields={props.onChangeRightBlockAdditionalFields}
                onDeleteFieldFromRightBlock={props.onDeleteFieldFromRightBlock}
                getAdditionalInfoOfField={props.getAdditionalInfoOfField}
            />
            <div className={styles.horizontalDivider} />
            <RightBlockFooterDates createdOnTitle={props.createdOnTitle} modifiedOnTitle={props.modifiedOnTitle} />
        </div>
    );
});