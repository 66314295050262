import { observer } from "mobx-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { toJS } from "mobx";
import { v4 } from "uuid";
import { isUndefined } from "lodash";

import { dispatcher } from "store";
import { synchroiser } from "synchroiser";
import { LowFirst } from "shared";
import { businessRulesEngine } from "features/business-rules-engine";
import { CommentEntity } from "entities/CommentEntity";

import { modalController } from "features/modals";
import { Button, Icon } from "sale-bridge-ui-kit";
import { LeftBlockBody } from "./components/left-block-body";
import { RightBlock } from "./components/right-block";

import { GridItem, SystemColumns } from "types/entity";

import styles from "./quick-view.module.scss";

export interface QuickViewProps {
	schema: string;
	recordId: string;
	recordName?: string;
	stageId: string;
	onClose: () => void;
}

export const QuickView = observer(function (props: QuickViewProps) {
	const [sectionTitle, setSectionTitle] = useState<string>("");
	const [title, setTitle] = useState<string>("");
	const [commentEntity, setCommentEntity] = useState<CommentEntity | null>(null);
	const [loading, setLoading] = useState<boolean>(true);
	const [commentText, setCommentText] = useState("");
	const [idModal] = useState<string>(v4());

	const record = useMemo(() => dispatcher.currentRow.get(), [dispatcher.currentRow.get()]);

	const sectionWizzard = useMemo(() => businessRulesEngine.virtualSectionWizzard, [businessRulesEngine.virtualSectionWizzard]);

	const quickViewDesign = useMemo(() => sectionWizzard?.kanbanConfig?.quickViewDesign, [sectionWizzard?.kanbanConfig?.quickViewDesign]);

	const handleClickKey = useCallback(
		(event: KeyboardEvent) => {
			if (event.key !== "Escape") {
				return;
			}
			if (commentText.length > 0) {
				openConfirm();
				return;
			}
			setCommentText("");
			props.onClose();
			dispatcher.currentRow.rollback();
		},
		[props.onClose, commentText]
	);

	useEffect(() => {
		const loadRowAsync = async () => {
			setLoading(true);

			const gridItems = dispatcher.sectionWizzard.getAllGridItems();
			const row = dispatcher.entity.get()?.entity.rows.find((row) => row.id === props.recordId);

			let needColumns: Array<string> = [];

			Object.entries(row).map(([fieldName, fieldValue], index) => {
				needColumns.push(LowFirst(fieldName));
			});
			dispatcher.sectionWizzard.getSectionWizzard()?.kanbanConfig?.quickViewDesign.leftBlockAdditionalFields.forEach((item) => {
				const columnName = gridItems.find((gridItem) => gridItem.fieldConfig?.columnId === item.columnId)?.fieldConfig?.columnName;
				if (columnName) {
					needColumns.push(LowFirst(columnName));
				}
			});

			needColumns.push(LowFirst(SystemColumns.CreatedOn));
			needColumns.push(LowFirst(SystemColumns.ModifiedOn));

			needColumns = Array.from(new Set(needColumns));

			await synchroiser.getRow(props.schema, props.recordId, needColumns);
			dispatcher.fullView.switchEntityAndRow(props.schema, props.recordId);
			setLoading(false);
		};
		loadRowAsync();
	}, [props.schema, props.recordId]);

	useEffect(() => {
		document.addEventListener("keydown", handleClickKey);
		return () => document.removeEventListener("keydown", handleClickKey);
	}, [handleClickKey]);

	useEffect(() => {
		const entityTitle = sectionWizzard?.entityTitle;
		const systemName = sectionWizzard?.systemName;
		if (!isUndefined(entityTitle)) {
			setSectionTitle(entityTitle);
		}
		if (!isUndefined(props.recordName)) {
			setTitle(props.recordName);
		}
		if (!isUndefined(systemName)) {
			if (quickViewDesign?.commentsEnable) {
				const loadComments = async () => {
					const quickComment = new CommentEntity(systemName, props.recordId);
					setCommentEntity(quickComment);
					await quickComment.loadComments(true);
				};
				loadComments();
			}
		}
	}, [sectionWizzard, quickViewDesign?.commentsEnable, props.recordName, props.recordId]);

	useEffect(() => {
		if (record) {
			const gridItem = dispatcher.sectionWizzard
				.getAllGridItems()
				.find((item) => item.fieldConfig?.columnId === sectionWizzard?.viewColumnId);
			if (gridItem) {
				const cellName = gridItem?.fieldConfig?.columnName;
				if (cellName) {
					setTitle(record[LowFirst(cellName)]);
				}
			}
		}
	}, [record, sectionWizzard]);

	const leftFields = useMemo(() => {
		let fields: GridItem[] =
			quickViewDesign?.leftBlockAdditionalFields?.reduce<GridItem[]>((array, field) => {
				const finded = businessRulesEngine.virtualGridItems.find((gridItem) => gridItem.fieldConfig?.columnId === field.columnId);
				if (finded) {
					array.push(finded);
				}
				return array;
			}, []) ?? [];
		return fields;
	}, [toJS(quickViewDesign?.leftBlockAdditionalFields), toJS(businessRulesEngine.virtualSectionWizzard)]);

	const rightFields = useMemo(() => {
		let fields: GridItem[] =
			quickViewDesign?.rightBlockAdditionalFields?.reduce<GridItem[]>((array, field) => {
				const finded = businessRulesEngine.virtualGridItems.find((gridItem) => gridItem.fieldConfig?.columnId === field.columnId);
				if (finded) {
					array.push(finded);
				}
				return array;
			}, []) ?? [];
		return fields;
	}, [toJS(quickViewDesign?.rightBlockAdditionalFields), toJS(businessRulesEngine.virtualSectionWizzard)]);

	const backToComment = useCallback(() => {
		modalController.modalRemove(idModal);
	}, []);

	const cancelEdit = useCallback(() => {
		backToComment();
		setCommentText("");
		props.onClose();
		dispatcher.currentRow.rollback();
	}, []);

	const confirm = useMemo(() => {
		return (
			<div className={styles.confirm}>
				<div className={styles.confirmHeader}>
					<span className={styles.confirmTitle}>Внимание</span>
					<div onClick={backToComment}>
						<Icon name="Close" size="medium" />
					</div>
				</div>
				<div className={styles.confirmDialogBody}>
					<span className={styles.confirmText}>{`Есть не добавленный комментарий.`}</span>
				</div>
				<div className={styles.confirmFooter}>
					<Button
						text="Отменить редактирование"
						size="small"
						variant="default"
						border
						link={false}
						loading={false}
						onClick={cancelEdit}
					/>
					<Button
						text="Вернуться"
						size="small"
						variant="primary"
						border={false}
						link={false}
						loading={false}
						onClick={backToComment}
					/>
				</div>
			</div>
		);
	}, [backToComment, cancelEdit]);

	const openConfirm = useCallback(() => {
		modalController.popupAdd({ id: idModal, layout: confirm, closeFunc: cancelEdit });
	}, [idModal, confirm]);

	const handleCommentSubmit = useCallback((text: string) => {
		setCommentText(text);
	}, []);

	if (!record || (quickViewDesign?.commentsEnable && !commentEntity) || loading) {
		return (
			<>
				<div onClick={props.onClose} className={styles.quickViewBackground} />
				<div className={styles.quickViewWrapper}>
					<div className={styles.wrapHeader}>
						<div className={styles.wrapLeftHeader}>
							<span>{sectionTitle} </span>
							<Icon name="ChevronRight" size="small" />
						</div>
						<div className={styles.wrapRightHeader} onClick={props.onClose}>
							<Icon name="Close" size="small" />
						</div>
					</div>
					<div className={styles.uploadFieldWrapper}>
						<circle className={styles.circleField}>
							<Icon name="LayoutSidebarReverse" size="large" className={styles.LayoutSidebarReverseIcon} />
						</circle>
					</div>
				</div>
			</>
		);
	}

	return (
		<>
			<div onClick={props.onClose} className={styles.quickViewBackground} />
			<div className={styles.quickViewWrapper}>
				<div className={styles.wrapHeader}>
					<div className={styles.wrapLeftHeader}>
						<span>{sectionTitle} </span>
						<Icon name="ChevronRight" size="small" />
						<span>{title}</span>
					</div>
					<div className={styles.wrapRightHeader} onClick={props.onClose}>
						<Icon name="Close" size="small" />
					</div>
				</div>

				<div className={styles.quickViewBody}>
					<div className={styles.quickViewLeftBody}>
						<LeftBlockBody
							fields={leftFields}
							commentsEnable={quickViewDesign?.commentsEnable ?? false}
							commentEntity={commentEntity}
							title={title}
							schema={props.schema}
							recordId={props.recordId}
							onCommentSubmit={handleCommentSubmit}
						/>
					</div>
					<div className={styles.verticalDivider} />
					<div className={styles.quickViewRightBody}>
						<RightBlock fields={rightFields} onClose={props.onClose} />
					</div>
				</div>
			</div>
		</>
	);
});
