import { observer } from "mobx-react-lite";
import { useCallback, useState } from "react";
import { v4 } from "uuid";

import { Types, Zone } from "modules/DND/horizontal-column";
import { Stage, stageActionPropsArray } from "components";

import { HEXColors } from "types/entity";

import styles from "./drag-stages.module.css"

interface DragStagesProps {
    stages: any[];
    heightForMovementElement: number
}

const Replacer = observer(() => {
    return (
        <div className={styles.replacer}> </div>
    );
});

export const DragStages = observer((props: DragStagesProps) => {
    const [zoneId] = useState(v4());

    const placeholder = useCallback((title: string) => {
        return <Stage
            stageActionProps={stageActionPropsArray}
            name={title}
            color={HEXColors.Blue}
            onHidden={handleHidden}
            onDelete={handleDelete}
            className={styles.placeholderOpacity}
        />
    }, []);

    const movementElement = useCallback((title: string) => {
        return <Stage
            stageActionProps={stageActionPropsArray}
            name={title}
            color={HEXColors.Blue}
            onHidden={handleHidden}
            onDelete={handleDelete}
            className={styles.movementElementOpacity}
            height={props.heightForMovementElement}
        />
    }, [props.heightForMovementElement]);

    const handleHidden = useCallback(() => {

    }, []);
    const handleDelete = useCallback(() => {

    }, []);

    const handleDrop = useCallback((value: {
        zoneId: string;
        currentElementIndex: number;
        targetElementIndex: number;
    }) => {

    }, []);

    return (
        <Zone
            id={zoneId}
            placeholder={placeholder('stage с того места где лежала')}
            movementElement={movementElement('stage схватили')}
            predict={<Replacer />}
            type={Types.MATRIX}
            onDrop={handleDrop}
        >
            {props.stages}
        </Zone>
    );
});