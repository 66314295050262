import { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import { toJS } from "mobx";

import { v4 } from "uuid";
import { UpFirst } from "shared";

import detailFieldConfigurationPopupState, { FieldStoreEnums } from "../detail-field-configuration-popup-state";

import { Button, ButtonStyle } from "components";
import { Button as LibButton, Field } from "sale-bridge-ui-kit";
import { CheckboxesBlock } from "./checkboxes-block";

import { BasicSettings } from "./basic-settings";
import { NewLookupTabs, getInfoTitle } from "pages/section-wizzard/pages/constructor/configurations/field-configuration/types";

import styles from "../detail-field-configuration-popup.module.scss";

export const LookupConfiguration = observer(() => {
	const [tab, setTab] = useState(NewLookupTabs.LookupTab);
	const [selected, setSelected] = useState<number>(0);
	const [searchValue, setSearchValue] = useState("");

	const basicSettingsMemo = useMemo(() => <BasicSettings />, []);

	const handleVirtualLookupDefaultValueChange = useCallback(
		async (value: any) => {
			detailFieldConfigurationPopupState.setValue(FieldStoreEnums.selectedLookupDefaultValue, value);
			if (value !== null) {
				detailFieldConfigurationPopupState.setValue(
					FieldStoreEnums.defaultValue,
					detailFieldConfigurationPopupState.selectedLookupDefaultValue!.id
				);
			} else {
				detailFieldConfigurationPopupState.setValue(FieldStoreEnums.defaultValue, value);
			}
		},
		[detailFieldConfigurationPopupState.selectedLookupDefaultValue]
	);

	const defValues = useMemo(() => {
		return [
			{
				layout: <></>,
				items: detailFieldConfigurationPopupState.virtualLookup.virtualLookupValues.map((item) => (
					<div className={styles.selectItem} onClick={() => handleVirtualLookupDefaultValueChange(item)}>
						<span>{item.name}</span>
					</div>
				))
			}
		];
	}, [toJS(detailFieldConfigurationPopupState.virtualLookup.virtualLookupValues)]);

	const defaultVirtualValueField = useMemo(() => {
		const value = detailFieldConfigurationPopupState.selectedLookupDefaultValue?.name ?? "";
		return (
			<Field
				type="select"
				size="small"
				label="Значение по умолчанию"
				labelPosition="vertical"
				textVariant="outlined"
				info="Значение, которым заполнится поле при создании записи"
				value={value}
				items={defValues}
				onChange={() => {}}
				searchValue={searchValue} //ToDo чтобы заработал поиск нужно в items сделать filter по searchValue
				onChangeSearchValue={setSearchValue}
			/>
		);
	}, [toJS(defValues), detailFieldConfigurationPopupState.selectedLookupDefaultValue, searchValue]);

	const removeBadVirtualLookupValues = () => {
		detailFieldConfigurationPopupState.virtualLookup.virtualLookupValues =
			detailFieldConfigurationPopupState.virtualLookup.virtualLookupValues.filter((item) => item.name.trim() !== "");
	};

	const handleChangeTab = (tabIndex: NewLookupTabs) => {
		//TODO раскомментировать если потребуется такая проверка при новых кейсах
		// if (tab === NewLookupTabs.LookupTab) {
		//     if (detailFieldConfigurationPopupState.virtualLookup.entityTitle.trim() === ""
		//         || detailFieldConfigurationPopupState.virtualLookup.systemName.trim() === "") {
		//         return;
		//     }
		// }
		removeBadVirtualLookupValues();
		setSelected(tabIndex);
		setTab(tabIndex);
	};
	const handleAddVirtualLookupValue = () => {
		if (detailFieldConfigurationPopupState.virtualLookup.virtualLookupValues.length > 0) {
			const lastIndex = detailFieldConfigurationPopupState.virtualLookup.virtualLookupValues.length - 1;
			if (detailFieldConfigurationPopupState.virtualLookup.virtualLookupValues[lastIndex].name.trim() === "") {
				return;
			}
		}
		detailFieldConfigurationPopupState.virtualLookup.virtualLookupValues.push({ id: v4(), name: "" });
	};

	const onChangeVirtualLookupValue = (value: string, index: number) => {
		detailFieldConfigurationPopupState.virtualLookup.virtualLookupValues[index].name = value;
	};

	const handleTitleChange = useCallback(async (value: string) => {
		detailFieldConfigurationPopupState.virtualLookup.entityTitle = value;
		await detailFieldConfigurationPopupState.validateNewLookupTitle();
	}, []);

	const handleVirtualLookupSystemNameChange = useCallback(async (value: string) => {
		detailFieldConfigurationPopupState.virtualLookup.systemName = UpFirst(value);
		await detailFieldConfigurationPopupState.validateNewLookupName();
	}, []);

	const handleRemoveVirtualLookupValue = (index: number) => {
		const valueToRemove = detailFieldConfigurationPopupState.virtualLookup.virtualLookupValues[index];

		if (valueToRemove.id === detailFieldConfigurationPopupState.defaultValue) {
			return;
		}
		detailFieldConfigurationPopupState.virtualLookup.virtualLookupValues.splice(index, 1);
	};

	const itemClasses = useCallback(
		(i: number) => {
			return classNames(`${styles.tabButton} `, {
				[`${styles.selected} `]: selected === i
			});
		},
		[selected]
	);

	return (
		<>
			{getInfoTitle()}
			<div className={styles.tabContainer}>
				<Button
					key="newLookupSettingTab"
					caption="Справочник"
					onClick={() => handleChangeTab(NewLookupTabs.LookupTab)}
					className={itemClasses(NewLookupTabs.LookupTab)}
					style={ButtonStyle.DragGrayButton}
				/>
				<Button
					key="settingFieldTab"
					caption="Настройки поля"
					onClick={() => handleChangeTab(NewLookupTabs.FieldSettingsTab)}
					className={itemClasses(NewLookupTabs.FieldSettingsTab)}
					style={ButtonStyle.DragGrayButton}
				/>
			</div>
			{tab == NewLookupTabs.LookupTab ? (
				<>
					<Field
						type="text"
						size="small"
						label="Название справочника"
						labelPosition="vertical"
						textVariant="outlined"
						isRequired
						value={detailFieldConfigurationPopupState.virtualLookup.entityTitle}
						placeholder="Название справочника"
						info="Название нового справочника"
						counter={50}
						isClearing
						onChange={handleTitleChange}
						alert={detailFieldConfigurationPopupState.validation.virtualLookupTitle.isInvalid ? "error" : undefined}
					/>
					<div className={styles.fieldsPadding} />
					<Field
						type="text"
						size="small"
						label="Системное название"
						labelPosition="vertical"
						textVariant="outlined"
						isRequired
						value={detailFieldConfigurationPopupState.virtualLookup.systemName}
						placeholder="Название таблицы в базе данных"
						info="Название, которое будет отображаться в базе данных"
						counter={50}
						isClearing
						onChange={handleVirtualLookupSystemNameChange}
						alert={detailFieldConfigurationPopupState.validation.virtualLookupSystemName.isInvalid ? "error" : undefined}
					/>

					<div className={styles.lookupValuesTitleWrap}>
						<span className={styles.lookupValuesTitle}>Значения справочника</span>
						<span className={styles.lookupValuesInfoCaption}>
							Наполните справочник. Обращаем внимание, что будут добавлены только текстовые значения.
						</span>
						{/* TODO добавить сюда подсказку: Как добавить не текстовые значения */}
					</div>
					<div className={styles.valueItemsInLookup}>
						{detailFieldConfigurationPopupState.virtualLookup.virtualLookupValues.map((item, index) => (
							<div className={styles.item} key={index}>
								<Field
									type="text"
									size="small"
									label=""
									textVariant="outlined"
									isRequired
									value={item.name}
									placeholder="Значение поля"
									info="Название, которое будет отображаться в базе данных"
									counter={50}
									isClearing
									onChange={(value: string) => {
										onChangeVirtualLookupValue(value, index);
									}}
								/>
								<LibButton
									text=""
									size="small"
									variant="dangerSecondary"
									border={false}
									link={false}
									loading={false}
									leftIcon="Delete"
									onClick={(e) => handleRemoveVirtualLookupValue(index)}
								/>
							</div>
						))}
					</div>
					<LibButton
						text="Добавить"
						size="small"
						variant="secondary"
						border={false}
						link={false}
						loading={false}
						leftIcon="PlusSquareFill"
						onClick={handleAddVirtualLookupValue}
					/>
				</>
			) : (
				<>
					<div className={styles.fieldsSettingsLookup}>
						{basicSettingsMemo}
						{defaultVirtualValueField}
						<Field
							type="text"
							size="small"
							label="Подсказка"
							labelPosition="vertical"
							textVariant="outlined"
							info="То, что вы читаете прямо сейчас 🙂"
							value={detailFieldConfigurationPopupState.prompt}
							onChange={(value: string) => {
								detailFieldConfigurationPopupState.setValue(FieldStoreEnums.prompt, value);
							}}
						/>
					</div>
					<CheckboxesBlock />
				</>
			)}
		</>
	);
});
