import { useCallback, useEffect, useMemo, useState } from "react";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { v4 } from "uuid";
import classNames from "classnames";

import { dispatcher, selector } from "store";
import { synchroiser } from "synchroiser";

import { modalController } from "features/modals";

import { StageModelSettings, ConfigurationStageModel } from ".";

import { Button, ButtonStyle } from "components";
import { SettingsPanel } from "./components";

import { LookupType } from "pages/section-wizzard/data/data";

import styles from "./stage-model.module.scss"



enum StageModelStep {
    Step1 = 'ChooseLookup',
    Step2 = 'StageSettings'
}

export const StageModel = observer((props: { [key: string]: any }) => {
    const [selectedStage, setSelectedStage] = useState('');
    const [currentStep, setCurrentStep] = useState<StageModelStep>(StageModelStep.Step1);
    const [idModal] = useState<string>(v4());

    const stageModel = useMemo(() => {
        return toJS(dispatcher.sectionWizzard.getSectionWizzard()?.stageModelConfig);
    }, [toJS(dispatcher.sectionWizzard.getSectionWizzard()?.stageModelConfig)]);

    const sectionWizzard = useMemo(() => {
        return toJS(dispatcher.sectionWizzard.getSectionWizzard());
    }, [toJS(dispatcher.sectionWizzard.getSectionWizzard())]);
    /**
     * @description если стадийная модель уже настроена, перейти на второй шаг
     */
    useEffect(() => {
        if (stageModel && stageModel.stages.length > 0) {
            setCurrentStep(StageModelStep.Step2)
        }
    }, [stageModel]);

    const handleChooseStage = useCallback((choosingStage: string) => {
        setSelectedStage(choosingStage);
    }, []);

    const closeFuncWithOutConfirm = useCallback(() => {
        modalController.modalRemove(idModal);
    }, [idModal]);

    const handleSave = useCallback((title: string, name: string) => {
        dispatcher.stageModel.init(title, name);
        dispatcher.sectionWizzard.setHasStageModel(true);
        setSelectedStage(selector.stageModels.getAll().find(({ order }) => order == 0)?.id ?? '');
        setCurrentStep(StageModelStep.Step2);
    }, []);

    const notValidLayout = useMemo(() => {
        return (
            <div className={styles.dialog}>
                <div className={styles.header}>
                    <span className={styles.title}>Внимание</span>
                </div>
                <div className={styles.dialogBody}>
                    <span>Проверьте, заполнены ли поля:</span>
                    <ul className={styles.listWarning}>
                        <li>«Название», «Системное название» в общих настройках</li>
                    </ul>
                </div>
                <div className={styles.dialogFooter}>
                    <Button
                        caption="Закрыть"
                        onClick={closeFuncWithOutConfirm}
                        style={ButtonStyle.Primary} />
                </div>
            </div>
        );
    }, []);

    const notValidSystemName = useMemo(() => {
        return (
            <div className={styles.dialog}>
                <div className={styles.header}>
                    <span className={styles.title}>Внимание</span>
                </div>
                <div className={styles.dialogBody}>
                    <ul className={styles.listWarning}>
                        <li>Справочник стадийной модели с таким системным названием уже существует. Пожалуйста, выберите другое системное название раздела.</li>
                    </ul>
                </div>
                <div className={styles.dialogFooter}>
                    <Button
                        caption="Закрыть"
                        onClick={closeFuncWithOutConfirm}
                        style={ButtonStyle.Primary} />
                </div>
            </div>
        );
    }, [closeFuncWithOutConfirm]);

    const handleClickNextStep = useCallback(async (option: LookupType) => {
        if (option === LookupType.NewLookup) {
            if (sectionWizzard?.systemName !== "" && sectionWizzard?.entityTitle !== "") {
                if (await synchroiser.checkExistEntityName(sectionWizzard?.systemName + "Stage")) {
                    modalController.popupAdd({
                        id: idModal,
                        layout: notValidSystemName,
                        closeFunc: closeFuncWithOutConfirm
                    });
                } else {
                    handleSave(`${sectionWizzard?.entityTitle} Cтадийная модель`, `${sectionWizzard?.systemName}Stage`);
                }
            } else
                modalController.popupAdd({
                    id: idModal,
                    layout: notValidLayout,
                    closeFunc: closeFuncWithOutConfirm
                });
        }
    }, [idModal, handleSave, closeFuncWithOutConfirm, sectionWizzard?.systemName, sectionWizzard?.entityTitle, notValidLayout, notValidSystemName]);

    const handleClosePanel = useCallback(() => {
        setSelectedStage('');
    }, []);

    const stages = useMemo(() =>
        currentStep === StageModelStep.Step1 ? []
            : toJS(selector.stageModels.getAll()),
        [currentStep, currentStep === StageModelStep.Step1 ? [] : toJS(selector.stageModels.getAll())]
    );

    const content = useMemo(() => {
        const step1WrapClasses = classNames(`${styles.wrapper} ${styles.lookupStep}`);
        const step2WrapClasses = classNames(`${styles.wrapper}`, {
            [`${styles.settingsIsOpened} `]: selectedStage,
        });

        switch (currentStep) {
            case (StageModelStep.Step2): {
                return <div className={step2WrapClasses}>
                    <StageModelSettings
                        stages={stages}
                        onChooseStage={handleChooseStage}
                        handleMoveFinallyStage={(oldPosition, newPosition) => {
                            dispatcher.stageModel.moveToById(stages.find(stage => stage.order === oldPosition)?.id ?? "", newPosition);
                        }}
                        handleMoveIntermediateStage={(oldPosition, newPosition) => {
                            dispatcher.stageModel.moveToById(stages.find(stage => stage.order === oldPosition)?.id ?? "", newPosition);
                        }}
                        selectedStageId={selectedStage}
                    />
                    {selectedStage && stageModel && (
                        <SettingsPanel
                            idStage={selectedStage}
                            allStages={stages}
                            onCloseSettingsPanel={handleClosePanel}
                            stage={stageModel}
                            useLocalStorage
                            localStorageKey="openedStageSettingsPanel"
                        />
                    )}
                </div>
            }
            default: {
                return <div className={step1WrapClasses}>
                    <ConfigurationStageModel onNextStep={handleClickNextStep} size='small' />
                </div>
            }
        }
    }, [currentStep, selectedStage, handleChooseStage, stageModel, handleClosePanel, handleClickNextStep, stages]);

    return (<>{content}</>);
});