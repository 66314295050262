import { useCallback, useMemo, useState } from "react";
import { v4 } from "uuid";
import { toJS } from "mobx";

import { modalController } from "features/modals";
import fieldConfigurationStore from "../../../../field-configuration-store";

import { Button, ButtonDropdown } from "sale-bridge-ui-kit";
import { EditNumeratorPopup } from "../../edit-numerator-popup";
import { EditNumeratorConditionPopupBody } from "./edit-numerator-condition";

import { NumeratorMaskConditions } from "types/entity";
import { StandartItemGroup } from "sale-bridge-ui-kit/dist/components/button-dropdown/button-dropdown";

import { CloseMaxi } from "shared";

import styles from "../conditions.module.scss";

const kebabItems: Array<StandartItemGroup> = [
	{
		header: "",
		items: [
			{
				id: "edit",
				name: "Редактировать",
				icon: "Edit",
				iconSize: "small"
			}
		]
	},
	{
		header: "",
		items: [
			{
				id: "delete",
				name: "Удалить",
				icon: "Delete",
				iconSize: "small",
				isRed: true
			}
		]
	}
];

const WarningBody = (props: { condition: NumeratorMaskConditions; closeConfirm: (e?: any) => void }) => {
	const handleDeleteCondition = useCallback(() => {
		fieldConfigurationStore.numeratorConfig.deleteCondition(props.condition.id);
		props.closeConfirm();
	}, [props]);

	return (
		<>
			<div className={styles.headerModal}>
				<span className={styles.titleModal}>Удалить условие?</span>
				<CloseMaxi className={styles.closeButton} onClick={props.closeConfirm} />
			</div>
			<div className={styles.dialogBody}>
				<span className={styles.warningTitle}>Это действие невозможно отменить.</span>
			</div>
			<div className={styles.dialogFooter}>
				<Button size="small" onClick={handleDeleteCondition} text="Удалить" variant="default" border link={false} loading={false} />
				<Button
					size="small"
					onClick={props.closeConfirm}
					text="Вернуться"
					variant="primary"
					border={false}
					link={false}
					loading={false}
				/>
			</div>
		</>
	);
};

export const ConditionElement = (props: { condition: NumeratorMaskConditions }) => {
	const [idModal] = useState<string>(v4());
	const [idConfirm] = useState<string>(v4());

	const closeModal = useCallback((e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
		e?.stopPropagation();
		modalController.modalRemove(idModal);
	}, []);

	const closeConfirm = useCallback((e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
		e?.stopPropagation();
		modalController.modalRemove(idConfirm);
	}, []);

	const handleEditCondition = useCallback(
		(event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			if (event && (event.target as HTMLElement).closest(`#buttonDropdownId`)) {
				return;
			}
			//TODO возможно всплывёт и понадобится, пока что не актуально, т.к. в ButtonDropdown не передаётся больше id элемента
			// const node = document.getElementById(props.condition.id);
			// if (node && node.contains(event?.target as Node)) return;
			modalController.popupAdd({
				id: idModal,
				layout: (
					<EditNumeratorPopup close={closeModal}>
						<EditNumeratorConditionPopupBody close={closeModal} conditionId={props.condition.id} />
					</EditNumeratorPopup>
				),
				closeFunc: closeModal
			});
		},
		[idModal, props.condition.id, closeModal]
	);

	const handleDeleteCondition = useCallback(() => {
		modalController.popupAdd({
			id: idConfirm,
			layout: <WarningBody condition={props.condition} closeConfirm={closeConfirm} />,
			closeFunc: closeConfirm
		});
	}, [idConfirm, props.condition, closeConfirm]);

	const handleKebabClick = useCallback((id: string) => {
		switch (id) {
			case "edit": {
				handleEditCondition();
				return;
			}
			case "delete": {
				handleDeleteCondition();
				return;
			}
		}
	}, []);

	const getMaskNameById = useCallback(
		(maskId: string) => {
			return fieldConfigurationStore.numeratorConfig.maskItems.find((maskItem) => maskItem.id === maskId)?.name;
		},
		[toJS(fieldConfigurationStore.numeratorConfig.maskItems)]
	);

	const conditionCaption = useMemo(
		() =>
			`Если [${props.condition.columnName}] = 
        «${props.condition.columnValue.displayValue ?? props.condition.columnValue.name ?? props.condition.columnValue ?? ""}»,
        то применить [${getMaskNameById(props.condition.maskId)}]`,
		[props.condition]
	);

	return (
		<div className={styles.numeratorConditionWrapper} onClick={handleEditCondition}>
			<div className={styles.numeratorConditionContent}>
				<span className={styles.numeratorConditionName}>{props.condition.name}</span>
				<span className={styles.numeratorCondition}>{conditionCaption}</span>
			</div>
			<div id="buttonDropdownId">
				<ButtonDropdown
					size="small"
					position="right-down"
					standartItems={kebabItems}
					onClickStandartItem={handleKebabClick}
					childrenButton={
						<Button
							text=""
							size="small"
							variant="default"
							border={false}
							link={false}
							loading={false}
							leftIcon="KebabVertical"
							onClick={() => {}}
						/>
					}
				/>
			</div>
		</div>
	);
};
