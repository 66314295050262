import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";
import { toJS } from "mobx";

import { Checkbox, Item, RadioGroup, RadioItem } from "sale-bridge-ui-kit";

import { IFormat } from "entities/IItem";

import styles from "./checkbox-with-options.module.scss"

/**
 *@description Интерфейс пропсов компонента ToggleWithOptions
 * @param {string} caption - Поле HorizontalField caption
 * @param {string} description - Поле HorizontalField infoCaption
 * @param {(value: boolean) => void} onClickCheckbox - Что происходит при выборе элемента checkbox
 * @param {boolean} checkboxValue - Значение checkbox
 * @param {(value: string) => void} onSelect - Что происходит при выборе элемента RadioButton
 * @param {boolean} disabled - Доступен ли toggle
 * @param {IFormat[]} options - Типы результатов стадии
 * @example Пример options: IFormat[] = [
        { id: '', name: 'Нейтральный', checked: true, info: 'Например, «Задача выполнена»' },
        { id: '', name: 'Положительный', checked: false, info: 'Например, лид: «Перевод в продажи»' },
        { id: '', name: 'Отрицательный', checked: false, info: 'Например, лид: «Дисквалифицирован»' }
    ]
 */
interface ICheckboxWithOptions {
    caption: string;
    description: string;
    onClickCheckbox: (value: boolean) => void;
    checkboxValue: boolean;
    onSelect: (value: string) => void;
    disabled?: boolean;
    options: IFormat[]
}

export const CheckboxWithOptions = observer((props: ICheckboxWithOptions) => {
    const [checkboxValue, setCheckboxValue] = useState<boolean>(props.checkboxValue);

    useEffect(() => {
        setCheckboxValue(props.checkboxValue);
    }, [props.checkboxValue])

    const checkedOption = useMemo(() => {
        const checkedOption = props.options.find(option => option.checked);
        if (checkedOption) {
            return checkedOption.id;
        }
        return props.options[0].id
    }, [props.options.find(option => option.checked)]);

    const radioItems: Array<JSX.Element> = useMemo(() =>
        props.options.map((option) =>
            <>
                <RadioItem
                    id={option.id!}
                    size='small'
                    isSelected={option.checked}
                    variant='default'
                    onSelect={props.onSelect}
                >
                    <Item size='small' text={option.name} description={option.info} />
                </RadioItem>
                <div className={styles.padding} />
            </>
        )
        , [toJS(props.options)]);

    return (
        <div className={styles.wrapper}>
            <Checkbox
                size='small'
                value={checkboxValue}
                onChangeChecked={props.onClickCheckbox}
                variant='default'
                description="Отметьте, если требуется конечная стадия"
                title="Стадия является результатом"
            />
            {checkboxValue &&
                <div className={styles.additionalToggles}>
                    <div className={styles.divider} />
                    <RadioGroup value={checkedOption!} size='small' onSelect={props.onSelect}>
                        {radioItems}
                    </RadioGroup>
                </div>
            }
        </div>
    );
});