import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { cloneDeep, isArray, random } from "lodash";

import { store } from "../model/store";

import { Types } from "../type";

import { Layer } from "../layer";

import { Matrix } from "./zones";

import styles from "./style.module.css";
import { autorun, observe, toJS } from "mobx";
import { dispatcher } from "../../../store";
import placeholder from "lodash/fp/placeholder";

// TODO Добавлять готовые зоны
const zones = {
	[Types.MATRIX]: Matrix,
};

type Props = {
	onUp?: ({ elementId }: {elementId: string}) => void;
    onMove?: () => {};
    id: string;
    prefilter?: (a: any, b: any) => void;
    type: Types;
    config: {
		targetElementViewLayout?: JSX.Element,
        cellLayout: JSX.Element;
        placeholderLayout?: JSX.Element;
        predictLayout?: JSX.Element;
        sourceElement?: JSX.Element;
        height?: number;
        width?: number;
    };
    children: JSX.Element | Array<JSX.Element>;
    replaceEnable?: boolean;
    onDrop?: (value: {
        elementId: string | null;
		sourceZone: string | null;
        targetZone: string | null;
        type: Types,
		sourceData?: any;
		[key: string]: any;
    } & {
        [key in Types]: {
            [key: string]: any;
        }
    }) => void;
	postInitTime?: number;
	cursor?: string;
}

const Zone = observer((props: Props) => {
	const [cellSize, setCellSize] = useState<{
        width: number,
        height: number
    }>({ width: 0, height: 0 });
	const [zone, setZone] = useState({});
	const [elementSize, setElementSize] = useState<{ width: number, height: number } | null>(null);
	const [elementCenter, setElementCenter] = useState({ x: 0, y: 0 });
	const [hasMove, setHasMove] = useState(false);
	const [targetZone, setTargetZone] = useState<null | string>(null);
	const [targetCell, setTargetCell] = useState({ x: -1, y: -1 });
	const [selectedElement, setSelectedElement] = useState<string | null>(null);

	const container = useRef<HTMLInputElement>(null);
	const cellExample = useRef<HTMLInputElement>(null);

	/**
	 * @description Удаление zone из store при unmounting компоненты
	 */
	useEffect(() => {
		return () => {
			store.deleteZone(props.id);
		};
	}, [props.id]);

	/**
     * @description Определение размера контейнера zone
     */
	useEffect(() => {
		if (container.current) {
			// size не обновляются
			const size = container.current?.getBoundingClientRect();
			store.setZone(props.id, {
				size: {
					startX: size.x,
					startY: size.y,
					endX: size.x + size.width,
					endY: size.y + size.height,
				},
				config: {
					cellSize: {
						width: store?._zones[props.id]?.config?.cellSize?.width ?? 0,
						height: store?._zones[props.id]?.config?.cellSize?.height ?? 0,
					},
					type: Types.MATRIX,
					width: props.config.width,
					height: props.config.height,
				},
				elements: store.zones[props.id]?.elements || {}
			});
		}
	}, [props.config.height, props.config.width, props.id, container.current?.offsetHeight]);

	/**
	 * @description Создание маски ячеек
	 */
	useEffect(() => {

	}, []);

	useEffect(() => {
		let resizeObserver = new ResizeObserver((e) => {
			const size = container.current?.getBoundingClientRect();
			if (size) {
				store.setZoneSize({
					zoneId: props.id,
					startX: size!.x,
					startY: size!.y,
					width: size!.width,
					height: size!.height
				});
			}
		});

		if (container.current) {
			resizeObserver.observe(container.current);

			return ()=> {
				container.current && resizeObserver.unobserve(container.current);
			};
		}

		return;
	}, [props.id, props.children, container.current?.offsetHeight]);

	useEffect(() => {
		if (props.postInitTime) {
			setTimeout(() => {
				if (container.current) {
					// size не обновляются
					const size = container.current?.getBoundingClientRect();
					store.setZone(props.id, {
						size: {
							startX: size.x,
							startY: size.y,
							endX: size.x + size.width,
							endY: size.y + size.height,
						},
						config: {
							cellSize: {
								width: store?._zones[props.id]?.config?.cellSize?.width ?? 0,
								height: store?._zones[props.id]?.config?.cellSize?.height ?? 0,
							},
							type: props.type,
							width: props.config.width,
							height: props.config.height,
						},
						elements: store.zones[props.id]?.elements || {}
					});
				}
			}, props.postInitTime);
		}
	}, [props.config.height, props.config.width, props.id, props.postInitTime, props.type, props.config.placeholderLayout]);

	useEffect(() => {
		// if (container.current) {
		// 	const size = container.current?.getBoundingClientRect();
		//
		// 	store.setZoneSize({ zoneId: props.id, startX: size.x, startY: size.y, width: size.width, height: size.height });
		// }
	}, [container.current?.getBoundingClientRect()]);

	/**
     * @description Определение размера ячейки/cell
     */
	useEffect(() => {
		if (cellExample?.current?.children[0]) {
			const { width, height } = cellExample.current?.children[0].getBoundingClientRect();
			setCellSize({ width, height });

			store.setZoneCellSize({ id: props.id, width, height });
		}
	}, [props.config.cellLayout, props.id, cellExample.current?.children[0]]);

	/**
     * @description Постановка позиций для elements в модель
     */
	useEffect(() => {
		const children = props.children;
		if (!children) {
			return;
		}

		store.zones[props.id].elements = {};

		if (isArray(children)) {
			children.forEach((element) => {
				store.zones[props.id].elements[element.props.id] = {
					layout: element,
					id: element.props.id,
					sourceData: element.props?.sourceData ?? null,
					position: {
						x: element.props.x - 1,
						y: element.props.y - 1,
					},
					size: {
						width: element.props.width,
						height: element.props.height,
					}
				};
			});
		} else {
			store.zones[props.id].elements[children.props.id] = {
				layout: children,
				id: children.props.id,
				sourceData: children.props?.sourceData ?? null,
				position: {
					x: children.props.x - 1,
					y: children.props.y - 1,
				},
				size: {
					width: children.props.width,
					height: children.props.height,
				}
			};
		}
	}, [props.children, props.id]);

	/**
     * @description Отслеживание изменения zones
     */
	useEffect(() => {
		setZone(toJS(store.zones[props.id]));
	}, [props.id, props.children]);

	const CurrentZone = useMemo(() => zones[props.type], [props.type]);

	const moveElement = useCallback(() => {

		setSelectedElement(null);
		store.resetSelectedElement();
		store.setHasMove(false);
	}, []);

	const setSelectedElementId = useCallback((value: string | null) => {
		setSelectedElement(value);
		store.setSelectedElement(value);
	}, []);

	const setTargetCellWithDebounce = useCallback((value: { x: number, y: number }) => {
		if (value.x === targetCell.x && value.y === targetCell.y) {
			return;
		}

		store.setTargetCell(value);
		setTargetCell(value);
	}, [targetCell.x, targetCell.y]);

	const [test, setTest] = useState<any>([]);

	useEffect(() => {
		setInterval(() => {
			setTest(store._maskCells.map((mask) => {
				return (
					<div style={{
						position: "fixed",
						top: `${mask.startY}px`,
						left: `${mask.startX}px`,
						opacity: 0.1,
						width: `${mask.endX - mask.startX}px`,
						height: `${mask.endY - mask.startY}px`,
						background: `rgb(${Math.random() * 200}, ${Math.random() * 200}, ${Math.random() * 200})`,
					}}>

					</div>
				);
			}));
		}, 1000);
	}, []);

	return (
		<>
			{cellSize.width === 0 && (<span ref={cellExample}>{props.config.cellLayout}</span>)}
			<div
				ref={container}
				className={styles.zone}
			>
				{<CurrentZone
					{...props}
					zone={zone}
					targetCell={targetCell}
					targetZone={targetZone}
					selectedElement={selectedElement}
					config={{ ...props.config, cellSize: cellSize }}
					setElemCenter={setElementCenter}
					setHasMove={setHasMove}
					setSelectedElementId={(elementId) => {
						setSelectedElementId(elementId);
						store.setSelectedElement(elementId);
					}}
					setElementSize={setElementSize}
					elementSize={elementSize || { width: 1, height: 1 }}
					onUp={props.onUp}
					replaceEnable
				/>}
			</div>
			<Layer
				predictLayout={props.config.targetElementViewLayout ?? <></>}
				bufferedElementId={selectedElement}
				id={props.id}
				hasMove={hasMove}
				elementCenter={elementCenter}
				setTargetZone={(zoneId) => {
					setTargetZone(zoneId);
					store.setTargetZoneId(zoneId);
				}}
				setCenterElement={setElementCenter}
				setTargetCell={setTargetCellWithDebounce}
				moveElement={moveElement}
				setSelectedElement={(value: string | null) => {
					setSelectedElement(value);
					store.setSelectedElement(value);
				}}
				setHasMove={setHasMove}
				apiMethodOnDrop={(): void => {
					props.onDrop?.({
						elementId: selectedElement,
						targetZone: targetZone,
						sourceZone: props.id,
						type: props.type,
						MATRIX: {
							cellX: targetCell.x + 1,
							cellY: targetCell.y + 1,
						},
						sourceData: store.getElementSourceData(selectedElement || "")
					});
				}}
				cursor={props.cursor}
			/>
		</>
	);
});

export { Zone };