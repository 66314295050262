import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useToggle } from "usehooks-ts";
import { v4 } from "uuid";

import { dispatcher } from "store";
import { synchroiser } from "synchroiser";

import { validateRequired, validateSchema } from "../../../validation/validation";

import { Field } from "sale-bridge-ui-kit";
import { FieldIsland, HeadFieldsBlock } from "../global-settings";

import { UpFirst } from "shared";
import { ERROR_VALUE_EXIST_SECTION_NAME, ERROR_VALUE_EXIST_SECTION_TITLE, ValidationState } from "pages/section-wizzard/data/data";
import { EntityNameType } from "types/entity";

import styles from "../global-settings.module.scss";

const maxLengthSymbols = 50;

export const MainInfo = observer((props: { checkWarningForSetting: () => void }) => {
	const sectionWizzard = useMemo(() => {
		return dispatcher.entity.get()?.entity.sectionWizzard;
	}, [dispatcher.entity.get()?.entity.sectionWizzard]);

	const [entityTitle, setEntityTitle] = useState(sectionWizzard?.entityTitle ?? "");
	const [systemName, setSystemName] = useState(sectionWizzard?.systemName ?? "");
	const [isDisabledSystemSectionName, disabledSystemSectionNameToggle, setDisabledSystemSectionName] = useToggle();

	const [entityTitleState, setEntityTitleState] = useState<ValidationState>({
		isInvalid: false,
		isNotUnique: false,
		error: ""
	});
	const [systemNameState, setSystemNameState] = useState<ValidationState>({
		isInvalid: false,
		isNotUnique: false,
		error: ""
	});

	useEffect(() => {
		const dispEntity = dispatcher.entity.get();
		if (!dispEntity?.isNew) {
			setDisabledSystemSectionName(true);
		} else setDisabledSystemSectionName(false);
	}, [dispatcher.entity.get()]);

	const checkExistTitle = useCallback(async () => {
		if (entityTitle.length > 0 && (await synchroiser.checkExistEntityTitle(entityTitle, EntityNameType.Sections))) {
			const notUniqueState: ValidationState = {
				isInvalid: false,
				isNotUnique: true,
				error: ERROR_VALUE_EXIST_SECTION_TITLE
			};
			setEntityTitleState(notUniqueState);
		} else setEntityTitleState(validateRequired(entityTitle));
	}, [entityTitle, entityTitleState]);

	const checkExistName = useCallback(async () => {
		if (systemName.length > 0 && (await synchroiser.checkExistEntityName(systemName))) {
			const notUniqueState: ValidationState = {
				isInvalid: false,
				isNotUnique: true,
				error: ERROR_VALUE_EXIST_SECTION_NAME
			};
			setSystemNameState(notUniqueState);
		} else setSystemNameState(validateSchema(systemName));
	}, [systemName, systemNameState]);

	const onFocusOutFromEntityTitleInput = useCallback(async () => {
		await checkExistTitle();
		dispatcher.sectionWizzard.setEntityTitle(entityTitle);
		dispatcher.sectionWizzard.setPriorityAndStageEntityTitle(entityTitle);
		props.checkWarningForSetting();
	}, [entityTitle, entityTitleState, props.checkWarningForSetting]);

	const onFocusOutFromSystemNameInput = useCallback(async () => {
		dispatcher.sectionWizzard.setSystemName(systemName);
		dispatcher.sectionWizzard.setPriorityAndStageSystemName(systemName);
		await checkExistName();
		props.checkWarningForSetting();
	}, [systemName, systemNameState, props.checkWarningForSetting]);

	const onChangeEntityTitle = useCallback(
		(value: string) => {
			if (entityTitle.length <= 50) {
				setEntityTitle(value);
				setEntityTitleState({
					isInvalid: false,
					isNotUnique: false,
					error: ""
				});
			}
		},
		[entityTitle]
	);

	const onChangeSystemName = useCallback(
		(value: string) => {
			if (systemName.length <= 50) {
				dispatcher.stageModel.setStageModelLookupSystemName(`${value}Stage`);
				dispatcher.stageModel.setStageModelLookupName(`${value} стадийная модель`);
				setSystemName(UpFirst(value));
				setSystemNameState({
					isInvalid: false,
					isNotUnique: false,
					error: ""
				});
			}
		},
		[systemName]
	);

	const entityTitleErrorMessage = useMemo(() => {
		return entityTitleState.isInvalid ? (
			entityTitleState.error
		) : entityTitleState.isNotUnique ? (
			<>
				Раздел с таким названием уже существует.
				<br />
				Выберите другое название
			</>
		) : undefined;
	}, [toJS(entityTitleState)]);

	const nameField = useMemo(
		() => (
			<FieldIsland fieldInfo="Название раздела в системе.">
				<Field
					type="text"
					size="small"
					id={v4()}
					textVariant="outlined"
					value={entityTitle}
					onChange={onChangeEntityTitle}
					counter={maxLengthSymbols}
					label="Название раздела"
					labelPosition="horizontal"
					alert={entityTitleState.isInvalid || entityTitleState.isNotUnique ? "error" : undefined}
					onBlur={onFocusOutFromEntityTitleInput}
					tooltipBody={entityTitleErrorMessage}
					startTooltipPosition="top left"
					tooltipTrigger="hover&focus"
					isTooltipDisplayed={(entityTitleState.isInvalid || entityTitleState.isNotUnique) ?? false}
					isClearing
					isRequired
				/>
			</FieldIsland>
		),
		[entityTitle, toJS(entityTitleState), entityTitleErrorMessage]
	);

	const systemNameErrorMessage = useMemo(() => {
		return systemNameState.isInvalid ? (
			systemNameState.error
		) : systemNameState.isNotUnique ? (
			<>
				Таблица в базе данных с таким названием уже существует.
				<br />
				Выберите другое системное название
			</>
		) : undefined;
	}, [toJS(systemNameState)]);

	const systemNameField = useMemo(
		() => (
			<FieldIsland fieldInfo="Название таблицы в базе данных. Допустимые символы: латинские буквы без пробелов, цифры, символ подчеркивания и дефис.">
				<Field
					type="text"
					size="small"
					id={v4()}
					textVariant="outlined"
					value={systemName}
					onChange={onChangeSystemName}
					counter={maxLengthSymbols}
					label="Системное название раздела"
					labelPosition="horizontal"
					onBlur={onFocusOutFromSystemNameInput}
					alert={systemNameState.isInvalid || systemNameState.isNotUnique ? "error" : undefined}
					isDisabled={isDisabledSystemSectionName}
					tooltipBody={systemNameErrorMessage}
					startTooltipPosition="top left"
					tooltipTrigger="hover&focus"
					isTooltipDisplayed={(systemNameState.isInvalid || systemNameState.isNotUnique) ?? false}
					isClearing
					isRequired
				/>
			</FieldIsland>
		),
		[systemName, toJS(systemNameState), isDisabledSystemSectionName, systemNameErrorMessage]
	);

	return (
		<div className={styles.blockSettings}>
			<HeadFieldsBlock caption="Ключевая информация" />
			{nameField}
			{systemNameField}
		</div>
	);
});
