import { useCallback, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { observer } from "mobx-react";

import { Entity } from "store/store";
import { dispatcher } from "store";

import { Button, ButtonStyle } from "components";

import { AdvancedFilterConst, MaxWidth } from "features/section-head/data/constants";
import { SimpleFilter } from "features/filter/simpleFilter";

import { Add, AllFilters, VerticalDividerShort } from "shared";

import styles from "features/section-head/section-head.module.css";

const LeftSectionHead = observer(function (props: { entity: Entity | undefined }) {
	const [isSaved, setSaved] = useState<boolean>(false);
	const [isBlockSaveOption, setIsBlockSaveOption] = useState<boolean>(false);
	const maxWidth = MaxWidth.maxwidth;
	const widthRef = useRef<HTMLDivElement>(null);
	const refSetTimeout = useRef<NodeJS.Timeout>();
	const history = useNavigate();

	//TODO функция сохранения новой записи
	// async function save() {
	//     if (isBlockSaveOption) return;
	//     setIsBlockSaveOption(true);
	//     let saved = await props.entity.save();
	//     setSaved(saved);
	//     refSetTimeout.current = setTimeout(() => { setSaved(false); setIsBlockSaveOption(false); }, 4000);
	// }

	const entity = useMemo(() => {
		return dispatcher.entity.get();
	}, [dispatcher.entity.get()]);

	const handleAddClick = useCallback(() => {
		if (entity) {
			history("new");
		}
	}, [entity]);

	return (
		<div className={styles.left} style={maxWidth > 0 ? { maxWidth: `calc(100% - ${maxWidth + 10}px)` } : {}}>
			<div className={styles.left} ref={widthRef} style={{ marginRight: "10px" }}>
				<Button firstIcon={<Add className={styles.addButton} />} style={ButtonStyle.Icon} onClick={handleAddClick} />
				<div className={styles.title} onClick={() => document.location.reload()}>
					{props.entity?.entityTitle}
				</div>
				<VerticalDividerShort className={styles.divider} />
				<Button
					className={styles.allFilters}
					style={ButtonStyle.IconIndigo}
					firstIcon={<AllFilters />}
					onClick={() => {
						AdvancedFilterConst.setOpenAdvancedFilterTree(!AdvancedFilterConst.isOpenAdvancedFilterTree, true);
					}}
					selected={AdvancedFilterConst.isOpenAdvancedFilterTree}
				/>
			</div>
			<div
				className={styles.filters}
				style={
					widthRef.current?.getBoundingClientRect().width
						? { maxWidth: `calc(100% - ${widthRef.current?.getBoundingClientRect().width + 10}px)` }
						: {}
				}
			>
				<SimpleFilter size="small" />
			</div>
		</div>
	);
});

export default LeftSectionHead;
