import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useParams } from "react-router";

import { synchroiser } from "synchroiser";
import { sessionStore } from "store";

import Settings from "pages/settings/Settings";
import ImportPage from "pages/import/import-page";
import SectionWizzard from "pages/section-wizzard/section-wizzard";
import { SinglePage } from "pages/single-page/single-page";
import { SectionCard } from "pages/section-card/section-card";
import Profile from "pages/profile/profile";
import { DetailMaster } from "features/detail-master";
import { NotFoundPage } from "pages/error-page/error-page";

import SectionRoute from "entities/SectionRoute";
import { Navigation } from "features/navigation/navigation-panel";
import { ModalViewer } from "features/modals";
import { DialogHandler } from "app/events";

import ProgressBar from "features/full-view/files/progress-bar/progress-bar";

import { InProgress } from "shared";

import styles from "./App.module.css";

//TODO объявить переменную куда будем складывать классы
export const App = observer(function () {
	const [init, setInit] = useState<boolean>(false);
	const pathFragments = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		if (Object.keys(pathFragments).length === 0) {
			navigate(`/${SectionRoute.Settings}`);
		}
	}, [navigate, pathFragments]);

	useEffect(() => {
		if (sessionStore.checkAuth()) {
			synchroiser.setWithoutSessionStoreValues();
			synchroiser.getSectionsList()?.then(() => {
				setInit(true);
			});
		} else {
			setInit(true);
		}
	}, []);

	//TODO решить проблему с токеном и авторизацией
	// useEffect(() => {
	// 	if (store.session.accessToken) {
	// 		setInit(true);
	// 	}
	// }, [store.session.accessToken]);

	//TODO FIX-раскоментить как пофиксим руты
	// useEffect(() => {
	// 	const path = document.location.pathname;
	// 	synchroiser.getCurrentTarget(path.split("/"));
	// }, []);

	return init ? (
		<>
			<Routes>
				<Route path={`/${SectionRoute.Settings}/*`} element={<ComponentsWithNavigation component={<Settings />} />} />
				<Route
					path={`/${SectionRoute.Workspace}`}
					element={<ComponentsWithNavigation component={<InProgress className={styles.loading} />} />}
				/>
				<Route path={`/${SectionRoute.User}/*`} element={<ComponentsWithNavigation component={<Profile />} />} />
				<Route path={`${SectionRoute.Import}`} element={<ComponentsWithNavigation component={<ImportPage />} />} />
				<Route path={`${SectionRoute.Import}/:entity`} element={<ComponentsWithNavigation component={<ImportPage />} />} />
				<Route path={"/sectionMaster/:mode/:entityName"} element={<SectionWizzard />} />
				<Route path={"/singlePage/:entityName"} element={<ComponentsWithNavigation component={<SinglePage />} />} />
				<Route path={"/singlePage/:entityName/:id"} element={<ComponentsWithNavigation component={<SectionCard />} />} />
				<Route path={"/detailMaster/:mode/:entityName"} element={<DetailMaster />} />
				<Route path={"*"} element={<ComponentsWithNavigation component={<NotFoundPage />} />} />
			</Routes>
			<DialogHandler />
			<ModalViewer />
		</>
	) : (
		<ProgressBar text="Пожалуйста, подождите..." />
	);
});

const ComponentsWithNavigation = observer(function (props: { component: JSX.Element }) {
	return (
		<div className={styles.app} id="target">
			<Navigation />
			<div className={styles.appContent}>{props.component}</div>
		</div>
	);
});
