import { Api } from "shared/api/class-api";

class File extends Api {
	basicPath: string;
	constructor() {
		super();
		this.basicPath = "/api/File";
	}

	uploadFile = () => {
		this.setPath(`${this.basicPath}/upload`);
		return this;
	};
	downloadFile = (id: string) => {
		this.setPath(`${this.basicPath}/download/${id}`);
		return this;
	};
	deleteFile = (fileId: string) => {
		this.setPath(`${this.basicPath}/delete/${fileId}`);
		return this;
	};
	deleteList = () => {
		this.setPath(`${this.basicPath}/deletelist`);
		return this;
	};
	fileList = (entityName: string, entityId: string) => {
		this.setPath(`${this.basicPath}/fileList/${entityName}/${entityId}`);
		return this;
	};
	maxFileSize = () => {
		this.setPath(`${this.basicPath}/maxfilesize`);
		return this;
	};
	filesMaxCount = () => {
		this.setPath(`${this.basicPath}/filesMaxCount`);
		return this;
	};
	fileHealth = () => {
		this.setPath(`${this.basicPath}/health`);
		return this;
	};
	editS3 = () => {
		this.setPath(`${this.basicPath}/editS3`);
		return this;
	};
	editSource = () => {
		this.setPath(`${this.basicPath}/editSource`);
		return this;
	};
	sourceInfo = () => {
		this.setPath(`${this.basicPath}/sourceInfo`);
		return this;
	};
	taskrunning = () => {
		this.setPath(`${this.basicPath}/taskrunning`);
		return this;
	};
}

const file = new File();

export { file };
