import { DataValueType } from "entities/filter/IFilter";

function parseDataValueType(propertyTypeName:string){
    switch (propertyTypeName) {
        case "Guid":
            return DataValueType.Guid;
        case "Boolean":
            return DataValueType.Boolean;
        case "String":
            return DataValueType.Text;
        case "Int32":
            return DataValueType.Integer;
        case "Single":
            return DataValueType.Float;
        case "Double":
            return DataValueType.Float;
        case "DateTime":
            return DataValueType.DateTime;
        case "TimeSpan":
            return DataValueType.Time;
        case "Lookup":
            return DataValueType.Lookup;
        case "Int64":
            return DataValueType.Long;
            // TODO Затычки
        case "Date":
            return DataValueType.Date;
        case "Time":
            return DataValueType.Time;
        case "Decimal":
            return DataValueType.Decimal
        case "Numerator":
            return DataValueType.Text
        default:
          throw new Error(`Invalid property type name: ${propertyTypeName}`);
    }
}

export default parseDataValueType;