import { useState, useEffect, useCallback, useMemo } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";

import { selector } from "store";
import { synchroiser } from "synchroiser";
import SavedFilter from "entities/filter/SavedFilter";

import { Button, ButtonStyle, InputSearch } from "components";

import { LoadingState, SavedFilterFolder, StaticGroup, StaticGroupFolder } from "types/entity";

import { ArrowToDown, CloseMaxi, Folder } from "shared";

import styles from "./moving-folder.module.css";
export interface MovingFolderDialog {
	savedFilterFolder: SavedFilterFolder | null;
	staticGroupFolder: StaticGroupFolder | null;
	savedFilter: SavedFilter | null;
	staticGroup: StaticGroup | null;
	onClose: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const MovingFolder = observer(function (props: MovingFolderDialog) {
	const [selected, setSelected] = useState<string | null>(null);
	const [isDisableButton, setDisableButton] = useState<boolean>(false);

	const folder = useMemo(() => props.savedFilterFolder || props.staticGroupFolder, [props.savedFilterFolder, props.staticGroupFolder]);
	const filter = useMemo(() => props.savedFilter || props.staticGroup, [props.savedFilter, props.staticGroup]);

	const filterTree = useMemo(() => {
		if (props.savedFilter || props.savedFilterFolder) {
			return selector.filter.getFilterTree()?.savedFilterTree;
		}
		if (props.staticGroup || props.staticGroupFolder) {
			return selector.filter.getFilterTree()?.staticGroupFolderTree;
		}
	}, [props.savedFilter, props.staticGroup, props.savedFilterFolder, props.staticGroupFolder]);

	const handleKeyDown = (event: KeyboardEvent) => {
		if (event.key === "Enter") {
			event.preventDefault();
			updateFolder();
		}
	};

	useEffect(() => {
		document.addEventListener("keydown", handleKeyDown);
		return () => {
			document.removeEventListener("keydown", handleKeyDown);
		};
	}, [handleKeyDown]);

	// if (!movingFolderDialog.isOpen || movingFolderDialog.folderTree === null /*|| (movingFolderDialog.filterTree !== null && (!(movingFolderDialog.filterTree instanceof SavedFilterFolder && movingFolderDialog.folderTree instanceof SavedFilterFolder) || (!(movingFolderDialog.filterTree instanceof StaticGroupFolder && movingFolderDialog.folderTree instanceof StaticGroupFolder))))*/) {
	//     return null;
	// }

	const updateFolder = useCallback(async () => {
		setDisableButton(true);
		if (filterTree) {
			if (selected && folder) {
				const tempSavedFilterFolder = props.savedFilterFolder ? { ...props.savedFilterFolder, parentFolderId: selected } : null;
				const temptaticGroupFolder = props.staticGroupFolder ? { ...props.staticGroupFolder, parentFolderId: selected } : null;

				await synchroiser.updateFilterFolder(tempSavedFilterFolder, temptaticGroupFolder).then(async () => {
					if (synchroiser.loadingState !== LoadingState.Error) {
						if (tempSavedFilterFolder) {
							await synchroiser.getSavedFilterFolderTree();
						}
						if (temptaticGroupFolder) {
							await synchroiser.getStaticGroupFolderTree();
						}
						props.onClose();
						setSelected(null);
					}
				});
			}
			if (selected && filter) {
				if (props.savedFilter) {
					await synchroiser
						.updateFilter({ ...props.savedFilter, savedFilterFoldId: selected } as SavedFilter, false)
						.then(async () => {
							props.savedFilter!.savedFilterFoldId = selected;
							await synchroiser.getSavedFilterFolderTree();
							handleClose();
						});
				}
				if (props.staticGroup) {
					await synchroiser.updateStaticGroup({ ...props.staticGroup, folderId: selected } as StaticGroup).then(async () => {
						props.staticGroup!.folderId = selected;
						await synchroiser.getStaticGroupFolderTree();
						handleClose();
					});
				}
			}
		}
		setDisableButton(false);
	}, [selected, folder, filter]);

	const handleClose = useCallback(() => {
		setSelected(null);
		props.onClose();
	}, [props.onClose]);

	return (
		<div className={styles.dialog}>
			<div className={styles.header}>
				<span className={styles.title}>Куда переместить?</span>
				{<CloseMaxi className={styles.closeButton} onClick={handleClose} />}
			</div>
			<div className={styles.dialogBody}>
				<div className={styles.filterName}>
					{folder
						? `«${folder.name}»`
						: filter
						? props.savedFilter
							? `«${props.savedFilter.filterName}»`
							: `«${props.staticGroup!.name}»`
						: ""}
				</div>
				<InputSearch classNameInput={styles.search} value={null} placeholder={"Поиск"} onChangeValue={(value: string) => {}} />
				<div className={styles.folderTree}>
					<FolderTree
						movingFolder={folder}
						savedFilterFolder={
							props.savedFilter || props.savedFilterFolder ? selector.filter.getFilterTree()?.savedFilterTree! : null
						}
						staticGroupFolder={
							props.staticGroup || props.staticGroupFolder ? selector.filter.getFilterTree()?.staticGroupFolderTree! : null
						}
						selected={selected}
						setSelected={(value: string | null) => {
							setSelected(value);
						}}
					/>
				</div>
			</div>
			<div className={styles.devider} />
			<div className={styles.dialogFooter}>
				<Button caption={"Отмена"} onClick={handleClose} style={ButtonStyle.Subtle} />
				<Button
					caption={"Переместить"}
					onClick={updateFolder}
					style={ButtonStyle.Primary}
					isDisabled={!selected || isDisableButton}
				/>
			</div>
		</div>
	);
});

const FolderTree = observer(function (props: {
	movingFolder: SavedFilterFolder | StaticGroupFolder | null;
	savedFilterFolder: SavedFilterFolder | null;
	staticGroupFolder: StaticGroupFolder | null;
	selected: string | null;
	setSelected: (value: string | null) => void;
}) {
	const { movingFolder, savedFilterFolder, staticGroupFolder, selected, setSelected } = props;

	const [isOpened, setOpened] = useState(false);

	const folder = useMemo(() => savedFilterFolder || staticGroupFolder, [savedFilterFolder, staticGroupFolder]);

	const handleKeyDown = (event: KeyboardEvent) => {
		if (event.key === "Enter") {
			event.preventDefault();
		}
	};
	useEffect(() => {
		document.addEventListener("keydown", handleKeyDown);
		return () => {
			document.removeEventListener("keydown", handleKeyDown);
		};
	}, [handleKeyDown]);

	const openFolder = useCallback(
		(e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
			e.stopPropagation();
			if (!folder?.isRoot) {
				setOpened(!isOpened);
			}
		},
		[isOpened, folder?.isRoot]
	);

	const selectFolder = useCallback(() => {
		setSelected(folder?.id!);
	}, [folder?.id]);

	if (movingFolder && (movingFolder?.id === savedFilterFolder?.id || movingFolder?.id === staticGroupFolder?.id)) {
		return null;
	}

	const className = classNames(styles.filterFolderName, {
		[`${styles.filterFolderSelected} `]: selected === folder?.id
	});

	const arrowToDownClassNames = classNames(styles.filterFolderArrow, {
		[`${styles.open} `]: isOpened || folder?.isRoot
	});

	const listClassNames = classNames(styles.filterFolderList, {
		[`${styles.visible} `]: isOpened || folder?.isRoot
	});

	const childFoldersMapping = useMemo(() => {
		let childFolders: any[] = [];
		if (savedFilterFolder) {
			childFolders = savedFilterFolder.childFilterFolders;
		}
		if (staticGroupFolder) {
			childFolders = staticGroupFolder.childGroupFolders;
		}

		return (
			<ul className={listClassNames}>
				{childFolders?.map((item, i) => {
					return (
						<li key={i} className={styles.childFilterFolder}>
							<FolderTree
								movingFolder={movingFolder}
								savedFilterFolder={savedFilterFolder ? item : null}
								staticGroupFolder={staticGroupFolder ? item : null}
								selected={selected}
								setSelected={setSelected}
							/>
						</li>
					);
				})}
			</ul>
		);
	}, [selected, listClassNames, savedFilterFolder, staticGroupFolder]);

	return (
		<>
			<div onClick={selectFolder} className={className}>
				{!folder?.isRoot && <ArrowToDown className={arrowToDownClassNames} onClick={openFolder} />}
				<Folder />
				{folder?.name}
			</div>
			{(isOpened || folder?.isRoot) && childFoldersMapping}
		</>
	);
});

export default MovingFolder;
