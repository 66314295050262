import classNames from "classnames";
import { toJS } from "mobx";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { v4 } from "uuid";

import { dispatcher } from "store";

import { modalController, Position } from "features/modals";

import { Dropdown, Hint } from "sale-bridge-ui-kit";

import { ModalType } from "features/modals/viewer/modal-viewer";
import { ItemGroup } from "sale-bridge-ui-kit/dist/components/dropdown/dropdown";
import { StageFullInfo } from "types/entity";

import { EyeHidden, EyeNotHidden } from "shared";

import styles from "./header-group.module.scss";
interface StageTypeHeaderProps {
	stages: Array<StageFullInfo>;
	title: string;
	hiddenCount: number;
	isSingleGroup: boolean;
}

function Item(props: { hidden: boolean; name: string; id: string; disable: boolean }) {
	const handleClick = useCallback(() => {
		if (!props.hidden && props.disable) {
			return;
		}

		if (!props.hidden) {
			modalController.notificationAdd({
				id: v4(),
				type: ModalType.NOTIFICATION,
				position: Position.CENTER,
				layout: <div>{`Стадия «${props.name}» скрыта`}</div>,
				allowTimer: true,
				allowDefaultClick: true,
				withBackdrop: false
			});
		} else {
			modalController.notificationAdd({
				id: v4(),
				type: ModalType.NOTIFICATION,
				position: Position.CENTER,
				layout: <div>{`Стадия «${props.name}» отображена`}</div>,
				allowTimer: true,
				allowDefaultClick: true,
				withBackdrop: false
			});
		}
		props.hidden ? dispatcher.stageModel.viewStage(props.id) : dispatcher.stageModel.hideStage(props.id);
	}, [props.hidden, props.disable, props.name, props.id]);

	return (
		<div onClick={handleClick} className={classNames(styles.dropDownItem, { [`${styles.disable}`]: props.disable })}>
			{props.hidden ? <EyeHidden /> : <EyeNotHidden />}
			<div className={styles.itemName}>{props.name}</div>
		</div>
	);
}

export function HeaderGroup(props: StageTypeHeaderProps) {
	const [isOpen, setIsOpen] = useState(false);
	const buttonRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		document.addEventListener("click", handleClick);
		return () => {
			document.removeEventListener("click", handleClick);
		};
	}, []);

	const stages: Array<ItemGroup> = useMemo(
		() => [
			{
				layout: <></>,
				items: props.stages.map((stage) => (
					<Item
						key={stage.id}
						disable={props.isSingleGroup && !stage.isHidden && props.stages.filter((stage) => !stage.isHidden).length === 1}
						hidden={stage.isHidden}
						name={stage.name}
						id={stage.id}
					/>
				))
			}
		],
		[toJS(props.stages), props.isSingleGroup]
	);

	const hiddenButtonClasses = useMemo(
		() =>
			classNames(styles.openButton, {
				[`${styles.enable}`]: isOpen
			}),
		[isOpen]
	);

	const handleClick = useCallback(
		(event: any) => {
			const node = buttonRef.current;

			if (node && node.contains(event.target as Node)) {
				return;
			}

			setIsOpen(false);
		},
		[isOpen]
	);

	const handleClickHiddenStages = useCallback(
		(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			setIsOpen(!isOpen);
		},
		[isOpen]
	);

	return (
		<>
			<div className={styles.container}>
				<span className={styles.header}>{props.title}</span>
				<Hint hintBody="Скрытые стадии" startPosition="bottom">
					<div ref={buttonRef} className={hiddenButtonClasses} onClick={handleClickHiddenStages}>
						<EyeHidden />
						{props.hiddenCount > 0 && <div className={styles.counter}>({props.hiddenCount})</div>}
					</div>
				</Hint>
				<div className={styles.dropDownContainer}>
					{isOpen && (
						<Dropdown
							size="small"
							variant="button"
							position="down"
							items={stages}
							quantity={props.stages.length}
							hasSearch={false}
						/>
					)}
				</div>
			</div>
			<div className={styles.divider}></div>
		</>
	);
}
