import { Api } from "shared/api/class-api";

class SystemSettings extends Api {
	basicPath: string;
	constructor() {
		super();
		this.basicPath = "/api/SystemSettings";
	}

	getValueByCode = () => {
		this.setPath(`${this.basicPath}/getvaluebycode`);
		return this;
	};
	createSetting = () => {
		this.setPath(`${this.basicPath}/create`);
		return this;
	};
	updateSetting = () => {
		this.setPath(`${this.basicPath}/update`);
		return this;
	};
	deleteSetting = () => {
		this.setPath(`${this.basicPath}/delete`);
		return this;
	};
	getSetting = () => {
		this.setPath(`${this.basicPath}/get`);
		return this;
	};
	getListSettings = () => {
		this.setPath(`${this.basicPath}/list`);
		return this;
	};
	createValue = () => {
		this.setPath(`${this.basicPath}/createValue`);
		return this;
	};
	updateValue = () => {
		this.setPath(`${this.basicPath}/updateValue`);
		return this;
	};
	deleteValue = () => {
		this.setPath(`${this.basicPath}/deleteValue`);
		return this;
	};
	getValue = () => {
		this.setPath(`${this.basicPath}/getValue`);
		return this;
	};
}

const systemSettings = new SystemSettings();

export { systemSettings };
