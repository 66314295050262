import { SyntheticEvent, useCallback, useEffect, useMemo } from "react";
import { action, toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

import { CheckBox, ContextMenu } from "components";
import { contextMenuStore } from "components/context-menu";
import SkeletonGrid from "../skeleton/skeleton-grid";

import { VisibleColumn } from "types/entity";
import { Coordinate } from "types/item";
import { IContextMenuOption } from "../data/data";
import { Value } from "../data";

import { GridHeadDividerVertical, SettingsDefault, SortOldToNew } from "shared";

import styles from "./universal-grid.module.scss";

/**
 * Пропсы грида
 * @param {checkbox | radio} view - варианты отображения строк грида
 * @param {any[]} rows - данные строк
 * @param {string} entityName - название раздела, необходимо для ссылочной колонки, для перехода в запись
 * @param {boolean} isColumnSettingsVisible - параметр, необходимый для того, чтобы определить: отображать ли шестеренку в шапке грида
 * @param {boolean} isCheckboxAllVisible - параметр, необходимый для того, чтобы определить: отображать ли чекбокс в шапке грида
 * @param {boolean} isCheckedAll - состояние чекбокса в шапке грида
 * @param {VisibleColumn[]} visibleColumns - массив колонок грида
 * @param {IContextMenuOption[]} contextMenuOptions - опции контекстного меню
 * @param {boolean} isLoading - параметр, отвечающий за состояние прогрузки грида (скелетона)
 * @param {(columnId: string, spanX: number) => void} onChangeColumnWidth - метод для расширения колонок вручную
 * @param {(row: any) => void} onRowClick - handler клика на строку
 * @param {(column: VisibleColumn) => void} onSortClick - метод сортировки по колонке
 * @param {() => void} onColumnSettingsClick - метод клика на шестеренку в шапке грида
 * @param {(value: boolean) => void} onChangeCheckedAll - метод клика на чекбокс в шапке грида
 * @param {(row: any, value: boolean) => void} onChangeRowChecked - метод клика на чекбокс конкретной строки
 * @param {(rowId: string, event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void} onDoubleRowClick - handler двойного клика по строке
 * @param {() => void} onClickEmpty - handler клика по "создать" в пустом отображении грида
 * */
export type GridProps = {
	view: "checkbox" | "radio";
	rows: any[];
	entityName?: string;
	isColumnSettingsVisible: boolean;
	isCheckboxAllVisible: boolean;
	isCheckedAll?: boolean;
	visibleColumns: VisibleColumn[];
	contextMenuOptions?: IContextMenuOption[];
	isLoading?: boolean;
	onChangeColumnWidth: (columnId: string, spanX: number) => void;
	onRowClick?: (row: any) => void;
	onSortClick?: (column: VisibleColumn) => void;
	onColumnSettingsClick?: () => void;
	onChangeCheckedAll?: (value: boolean) => void;
	onChangeRowChecked?: (row: any, value: boolean) => void;
	onDoubleRowClick?: (rowId: string, event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
	onClickEmpty?: () => void;
};

export type GridHeadProps = {
	view: "checkbox" | "radio";
	columns: VisibleColumn[];
	isColumnSettingsVisible: boolean;
	isCheckboxAllVisible: boolean;
	isCheckedAll?: boolean;
	onChangeColumnWidth: (columnId: string, width: number) => void;
	onColumnSettingsClick?: () => void;
	onChangeCheckedAll?: (checked: boolean) => void;
	onSortClick?: (column: VisibleColumn) => void;
};

export type GridRowProps = {
	view: "checkbox" | "radio";
	row: any;
	isChecked: boolean;
	entityName?: string;
	visibleColumns: VisibleColumn[];
	onRowClick?: (row: any) => void;
	onChangeCheckedAll?: (value: boolean) => void;
	onChangeRowChecked?: (row: any, value: boolean) => void;
	onDoubleRowClick?: (rowId: string, event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

export type ValueProps = {
	row: any;
	column: VisibleColumn;
	width?: string;
	entityName?: string;
};

const MIN_WIDTH_OF_COLUMN = 150;

const GridHead = observer(function (props: GridHeadProps) {
	const resize = useMemo(
		(): {
			pageX: number;
			startCurrentWidth: number;
			currentColumnId: string | null;
		} => ({
			pageX: 0,
			startCurrentWidth: 0,
			currentColumnId: null
		}),
		[]
	);

	const handleMouseDown = useCallback(
		(e: React.MouseEvent<HTMLDivElement, MouseEvent>, currentColumnId: string) => {
			document.addEventListener("mousemove", handleMouseMove);
			document.addEventListener("mouseup", handleMouseUp);

			const width = document.getElementById(currentColumnId)?.offsetWidth;
			if (!width) {
				return;
			}

			document.body.style.cursor = "col-resize";
			document.body.style.userSelect = "none";

			if (document.getElementById("root")) {
				document.getElementById("root")!.style.pointerEvents = "none";
			}

			resize.currentColumnId = currentColumnId;
			resize.pageX = e.pageX;
			resize.startCurrentWidth = width;
		},
		[toJS(props.columns)]
	);

	const handleMouseUp = useCallback(
		(e: MouseEvent) => {
			document.body.style.cursor = "";
			document.body.style.userSelect = "";
			if (document.getElementById("root")) {
				document.getElementById("root")!.style.pointerEvents = "";
			}
			//TODO убрать присваивание новой ширины, если потребуется моментальное отображение результата resize, когда будет реализована подгрузка частями
			if (document.getElementById(resize.currentColumnId!)) {
				props.onChangeColumnWidth(
					resize.currentColumnId!,
					Number(document.getElementById(resize.currentColumnId!)!.style.width.split("px")[0])
				);
			}
			resize.currentColumnId = null;
			resize.pageX = 0;
			resize.startCurrentWidth = 0;
			document.removeEventListener("mousemove", handleMouseMove);
			document.removeEventListener("mouseup", handleMouseUp);
		},
		[resize, props.onChangeColumnWidth, toJS(props.columns)]
	);

	const handleMouseMove = action(
		useCallback(
			(e: MouseEvent) => {
				if (resize.pageX == 0 && resize.startCurrentWidth == 0 && resize.currentColumnId === null) {
					return;
				}
				document.body.style.cursor = "col-resize";

				const direction = e.pageX > resize.pageX ? "right" : "left";

				let diffs = 0;
				let finalCurentWidth = 0;
				if (direction === "right") {
					diffs = e.pageX - resize.pageX;
					finalCurentWidth = diffs + resize.startCurrentWidth;
				} else {
					diffs = resize.pageX - e.pageX;
					finalCurentWidth = resize.startCurrentWidth - diffs;
				}

				if (!document.getElementById(resize.currentColumnId!) || finalCurentWidth < MIN_WIDTH_OF_COLUMN) {
					return;
				}

				document.getElementById(resize.currentColumnId!)!.style.width = `${finalCurentWidth}px`;
				//TODO возможно раскомментить,если потребуется моментальное отображение результата resize, когда будет реализована подгрузка частями
				// props.setColumnWidth(resize.currentColumnId!,finalCurentWidth);
			},
			[resize, toJS(props.columns)]
		)
	);

	const handleChange = useCallback(
		(value: boolean) => {
			props.onChangeCheckedAll?.(value);
		},
		[props.onChangeCheckedAll]
	);

	const handleSettingsGridClick = useCallback(() => {
		props.onColumnSettingsClick?.();
	}, []);

	const settingsClasses = classNames(styles.settingGrid, {
		[`${styles.hide}`]: !props.isColumnSettingsVisible
	});

	const checkboxAllClasses = classNames(styles.checkBoxGrid, {
		[`${styles.hide}`]: !props.isCheckboxAllVisible
	});

	const headMapping = useMemo(
		() =>
			props.columns.map((column: VisibleColumn, index) => {
				const width = column.spanX!;
				return (
					<div
						key={column.columnId}
						id={column.columnId}
						className={styles.headerCell}
						style={{ width: `${width}px`, minWidth: `${MIN_WIDTH_OF_COLUMN}px` }}
					>
						<div className={styles.headerCaption}>{column.columnTitle}</div>
						{props.onSortClick && <SortOldToNew className={styles.sortIcon} onClick={() => props.onSortClick?.(column)} />}
						{index < props.columns.length - 1 && (
							<div
								className={styles.verticalDividerContainer}
								onMouseDown={(e) => {
									handleMouseDown(e, column.columnId);
								}}
							>
								<GridHeadDividerVertical />
							</div>
						)}
					</div>
				);
			}),
		[toJS(props.columns), props.onSortClick]
	);

	return (
		<div className={styles.tableHeader}>
			<SettingsDefault className={settingsClasses} onClick={handleSettingsGridClick} />
			{props.view === "checkbox" ? (
				<CheckBox className={checkboxAllClasses} checked={props.isCheckedAll ?? false} onChangeChecked={handleChange} />
			) : (
				<></>
			)}
			{headMapping}
		</div>
	);
});

export const EmptyGrid = (props: { onClick?: () => void }) => {
	return (
		<div className={styles.emptyWrapper}>
			<div className={styles.emptyTitle}>Ничего не найдено.</div>
			<div className={styles.emptySubTitle}>
				<span className={styles.emptyLink} onClick={props.onClick}>
					Создайте{" "}
				</span>
				запись или проверьте настройки фильтра
			</div>
		</div>
	);
};

export const UniversalGrid = observer(function (props: GridProps) {
	const dataMapping = useMemo(
		() =>
			props.rows.length > 0 &&
			props.visibleColumns.length > 0 &&
			props.rows.map((row) => {
				return (
					<Row
						key={row["id"]}
						view={props.view}
						row={row}
						isChecked={row.checked}
						entityName={props.entityName}
						visibleColumns={props.visibleColumns}
						onRowClick={props.onRowClick}
						onChangeCheckedAll={props.onChangeCheckedAll}
						onChangeRowChecked={props.onChangeRowChecked}
						onDoubleRowClick={props.onDoubleRowClick}
					/>
				);
			}),
		[props, toJS(props.rows), toJS(props.visibleColumns)]
	);

	const setColumnWidth = useCallback(
		(columnId: string, width: number) => {
			props.onChangeColumnWidth?.(columnId, width);
		},
		[props.onChangeColumnWidth, toJS(props.visibleColumns)]
	);

	//TODO подгрузка
	function trackScrolling(e: SyntheticEvent<HTMLDivElement>) {}

	if (props.isLoading) return <SkeletonGrid />;

	return (
		<div className={styles.grid}>
			<GridHead
				view={props.view}
				columns={props.visibleColumns}
				isCheckboxAllVisible={props.isCheckboxAllVisible}
				isCheckedAll={props.isCheckedAll}
				isColumnSettingsVisible={props.isColumnSettingsVisible}
				onColumnSettingsClick={props.onColumnSettingsClick}
				onChangeCheckedAll={props.onChangeCheckedAll}
				onSortClick={props.onSortClick}
				onChangeColumnWidth={setColumnWidth}
			/>
			<div className={styles.table} onScroll={trackScrolling}>
				{dataMapping}
				{(props.rows.length === 0 || props.visibleColumns.length === 0) && <EmptyGrid onClick={props.onClickEmpty} />}
				{props.contextMenuOptions && contextMenuStore.isOpen && <ContextMenu contextMenuOptions={props.contextMenuOptions} />}
			</div>
		</div>
	);
});

const Row = observer(function (props: GridRowProps) {
	const handleChange = useCallback(
		(value: boolean) => {
			props.onChangeRowChecked?.(props.row, value);
		},
		[props]
	);

	const handleClick = useCallback(
		(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			handleChange(!props.isChecked);
			props.onRowClick?.(props.row);
		},
		[props]
	);

	const handleDoubleClick = useCallback(
		(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			props.onDoubleRowClick?.(props.row, event);
		},
		[props]
	);

	const handleClickContextMenu = useCallback(
		(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			event.preventDefault();
			contextMenuStore.reset();
			contextMenuStore.openMenu();
			const newPosition: Coordinate = {
				x: event.pageX,
				y: event.pageY
			};
			contextMenuStore.initContextMenu(newPosition, props.row.id, () => handleChange(false));
			handleChange(true);
		},
		[props.row]
	);

	const rowClassNames = classNames(styles.tableRow, {
		[`${styles.activeTableRow}`]: props.isChecked
	});

	return (
		<div
			id={props.row.id}
			className={rowClassNames}
			onContextMenu={handleClickContextMenu}
			onClick={handleClick}
			onDoubleClick={handleDoubleClick}
		>
			<div className={styles.settingGrid} />
			{props.view === "checkbox" ? (
				<CheckBox className={styles.checkBoxGrid} checked={props.isChecked} onChangeChecked={handleChange} />
			) : (
				<></>
			)}
			{props.visibleColumns.map((column: any) => {
				return (
					<div
						key={column.columnName}
						className={styles.cell}
						style={{ width: `${column.spanX!}px`, minWidth: `${MIN_WIDTH_OF_COLUMN}px` }}
					>
						<Value row={props.row} column={column} width={`${column.spanX!}px`} entityName={props.entityName} />
					</div>
				);
			})}
		</div>
	);
});
