import { observer } from "mobx-react";
import { useCallback } from "react";
import classNames from "classnames";

import { AdditionalInfoOfField } from "../../quick-view-settings/data";

import { Button, ButtonStyle } from "components";

import { Close, DragIcon, Locked } from "shared";

import styles from "./constructor-quick-view-drag-field.module.scss";
import { Hint, Icon } from "sale-bridge-ui-kit";

type ConstructorQuickViewDragFieldProps = {
	gridItemId: string;
	icon: JSX.Element;
	additionalInfoOfField: AdditionalInfoOfField;
	width?: string;
	isFieldBlocked?: boolean;
	className?: string;
	onDeleteField?: (gridItemId: string) => void;
};

export const ConstructorQuickViewDragField = observer((props: ConstructorQuickViewDragFieldProps) => {
	const fieldClassName = classNames(styles.fieldWrapper, {
		[`${styles.fillField} `]: props.additionalInfoOfField.columnTitle?.length ?? false,
		[`${styles.fillBlocked} `]: props.isFieldBlocked,
		[`${props.className} `]: props.className
	});

	const handleMouseDown = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.stopPropagation();
	}, []);

	return (
		<div className={fieldClassName} style={props.width ? { width: props.width } : {}}>
			{!props.isFieldBlocked && <Icon name="Draggable" size="16" />}
			<div className={styles.fieldCaption}>
				{props.icon}
				<span className={styles.fieldCaptionBody}>
					{props.additionalInfoOfField.isRequired && <span className={styles.star}>*</span>}
					{props.additionalInfoOfField.columnTitle ?? ""}
				</span>
			</div>
			{props.isFieldBlocked ? (
				<>
					<Icon size="16" name="Lock" className={styles.fieldLock} />
				</>
			) : (
				<Hint hintBody={"Убрать"} isHintDisplayed={true}>
					<Button
						style={ButtonStyle.Icon}
						firstIcon={<Close />}
						className={styles.deleteButton}
						isVisible={!props.isFieldBlocked}
						onClick={() => props.onDeleteField?.(props.gridItemId)}
						onMouseDown={handleMouseDown}
					/>
				</Hint>
			)}
		</div>
	);
});
