import { dispatcher } from "store/store-dipatcher/dispatcher";
import { ColumnSpecializationType, GridItem, SectionWizzard, TabId } from "types/entity";

export class FullViewController {
	findPanelTitle = (tabId: string) => {
		const additional = dispatcher.sectionWizzard.getAdditionalFromConfig();
		if (additional) {
			const tab = additional[tabId as TabId];
			if (tab) return tab.title;
		} else return "";
	};

	getTabContent = (panelId: string) => {
		if (panelId === TabId.Files) {
			return '<FileUpload entitySchema="value:main:entityName" />  ';
		} else if (panelId === TabId.Comments) {
			return "<СommentsBlock />  ";
		} else if (panelId === TabId.Wysiwyg) {
			return '<WysiwygEditor wysiwygEditorColumn="value:main:wysiwygEditorColumn" />  ';
		} else return `<Label>${this.findPanelTitle(panelId)}</Label>`;
	};

	getFullViewXml = (sectionWizzard?: SectionWizzard | null, onSave?: string) => {
		//TODO поработать с координатами всех полей,групп и деталей, когда эти значение будут выставлены в днд, для корректного отображения на странице
		const viewAdditionalPanel = dispatcher.sectionWizzard.getViewAdditional();
		const onSaveMethod = onSave ?? "func:synchroiser:updateRow";

		if (sectionWizzard) {
			return `
                    <Program>
                        <Module>
                            <Service name="main"/>
                        </Module>

                        <Render direction="column" height="100%">
                            <FullView 
                                entityTitle="value:main:entityTitle" 
                                navigation="/singlePage/{{value:main:entityName}}" 
                                viewColumnId="${sectionWizzard.viewColumnId}" 
                                onSave="${onSaveMethod}"
                                onCancel="func:dispatcher.currentRow:rollback"
                            >                               
                                <Grid columns="${viewAdditionalPanel ? "60fr 40fr" : "1fr"}" rows="1fr" gap="6">
                                    <Content>
                                        <Tabs activeTab="{{value:main:mainTab}}" setActiveTab="func:main:onChangeMainTab">
                                            ${sectionWizzard.reactorConfig.tabs.tabsConfig.map((tabConfig: any) => {
												return `<Tab caption="${tabConfig.tabTitle}" name="${tabConfig.tabName}">
                                                        <Grid columns="1fr 1fr" height="auto" padding="0 48px" rowGap="20" columnGap="40">                                                        
                                                            ${tabConfig.grid.items
																.map((item: GridItem) => {
																	if (item.x === -1 || item.y == -1) {
																		return null;
																	}
																	return (
																		item.fieldConfig &&
																		`<Field field='${JSON.stringify(item)}' labelVariant='gray' />`
																	);
																})
																.filter((item: GridItem | null) => item !== null)}
                                                            
                                                            ${tabConfig.grid.items.map((item: GridItem) => {
																return (
																	item.groupFieldsConfig &&
																	`<FieldGroup title="${item.groupFieldsConfig.title}" x="${item.x}" y="${
																		item.y
																	}" > 
                                                                        ${item.groupFieldsConfig.inner?.items.map((item: GridItem) => {
																			if (item.x === -1 || item.y == -1) {
																				return null;
																			}
																			return (
																				item.fieldConfig &&
																				`<Field field='${JSON.stringify(
																					item
																				)}' labelVariant='gray' />`
																			);
																		})}                                                                
                                                                    </FieldGroup>`
																);
															})}
                                                            ${tabConfig.grid.items.map((item: GridItem) => {
																return (
																	item.detailConfig &&
																	`<Detail                                                                            
                                                                        entityName="value:main:entityName"  
                                                                        entityTitle="value:main:entityTitle" 
                                                                        viewColumnId="${sectionWizzard.viewColumnId}"
                                                                        getRecord="func:dispatcher.currentRow:get"
                                                                        gridInnerItem='${JSON.stringify(item)}'
                                                                    />`
																);
															})}
                                                        </Grid>
                                                    </Tab>`;
											})}
                                            ${Object.keys(sectionWizzard.reactorConfig.tabs.additionalPanel)
												.filter(
													(panel) => (sectionWizzard.reactorConfig.tabs.additionalPanel as any)[panel].state === 0
												)
												.map((panel) => {
													return `<Tab caption="${this.findPanelTitle(panel)}" name="${panel}">
                                                        ${this.getTabContent(panel)}
                                                    </Tab>`;
												})}                        
                                        </Tabs> 
                                    </Content>
                                        
                                    ${
										viewAdditionalPanel &&
										`<Content>
                                            <Tabs activeTab="{{value:main:additionalTab}}" setActiveTab="func:main:onChangeAdditionalTab">
                                                ${Object.keys(sectionWizzard.reactorConfig.tabs.additionalPanel)
													.filter(
														(panel) =>
															(sectionWizzard.reactorConfig.tabs.additionalPanel as any)[panel].state === 1
													)
													.map((panel) => {
														return `<Tab caption="${this.findPanelTitle(panel)}" name="${panel}">
                                                            ${this.getTabContent(panel)}
                                                        </Tab>`;
													})}                        
                                            </Tabs> 
                                        </Content>`
									}
                                </Grid>                                
                            </FullView>               
                        </Render>

                        <Script>
                            (
                                class MainService{
                                    tabsConfig=${JSON.stringify(sectionWizzard.reactorConfig.tabs.tabsConfig)};
                                    mainTab="${sectionWizzard.reactorConfig.tabs.tabsConfig[0].tabName}";
                                    additionalTab="${
										Object.keys(sectionWizzard?.reactorConfig.tabs.additionalPanel).filter(
											(panel) => (sectionWizzard.reactorConfig.tabs.additionalPanel as any)[panel].state === 1
										)[0]
									}";
                                    entityTitle="${sectionWizzard.entityTitle}";
                                    entityName="${sectionWizzard.systemName?.toLowerCase()}";
                                    wysiwygEditorColumn="${
										dispatcher.entity
											.get()
											?.entity.columns.find(
												(item) =>
													item.specializations?.tag === ColumnSpecializationType.WysiwygEditor &&
													item.specializations?.properties["WysiwygEditor"] === "true"
											)?.columnName
									}";

                                    constructor(){
                                    
                                    }
                                
                                    onChange(item){
                                        this.value = item;
                                    }
                                
                                    onChangeMainTab(tabName){
                                        this.mainTab = tabName;
                                    }
                                
                                    onChangeAdditionalTab(tabName){
                                        this.additionalTab = tabName;
                                    }
                                
                                }
                            )
                        </Script>
                    </Program>
                `;
		}
		return "";
	};
}

export const fullViewController = new FullViewController();
