import { observer } from "mobx-react-lite";
import { useMemo } from "react";

import { dispatcher } from "store";

import AccessRightsAdminOperation from "./access-rights-admin-operation/access-rights-admin-operation";
import AccessRightsAdminRecords from "./access-rights-admin-records/access-rights-admin-records";

import styles from "./access-rights.module.css";


const AccessRights = observer(() => {
    const sectionWizzard = useMemo(
        () => {
            return dispatcher.entity.get()?.entity.sectionWizzard;
        }, [dispatcher.entity.get()?.entity.sectionWizzard]
    );    

    return (
        <div className={styles.accessRightsPanel}>
            <div className={styles.accessRightsPanelTitle}>
                Права доступа на объект [{sectionWizzard?.entityTitle}]
            </div>
            <AccessRightsAdminOperation />
            <AccessRightsAdminRecords />
        </div>
    );
});

export default AccessRights;
