import { Key, Ref, useEffect, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react-lite";

import { store } from "../model/store";
import { toJS } from "mobx";

type Props = {
    zoneId: string;
    position: { x: number, y: number };
    placeholderLayout: JSX.Element;
    predictLayout: JSX.Element;
    cellLayout: JSX.Element;
    children: JSX.Element;
    size: { width: number, height: number };
    selectedElementId: string | null;
    elementSize: { width: number, height: number };
    targetCell?: {
        x: number;
        y: number;
    };
    replaceEnable?: boolean;
    targetZone?: string | null;
	matrixSize: {
		width: number;
		height: number;
	};
	r?: string
}

const Cell = observer(function (props: Props) {
	const [predictEnable, setPredictEnable] = useState<boolean>(false);

	const targetCell = useMemo(() => store._targetCell, [toJS(store._targetCell)]);

	const container: Ref<HTMLDivElement> = useRef(null);

	/**
	 * @description Дополнение mask cells элементом
	 */
	useEffect(() => {
		if (container.current) {
			const size = container.current.getBoundingClientRect();
			const hasMask = store._maskCells.find((mask, index) => {
				if (!mask) return;

				if (props?.children?.props?.children?.props?.id) {
					if (mask.id && mask.id === props?.children?.props?.children?.props?.id) {
						store._maskCells[index] = {
							x: props.position.x,
							y: props.position.y,
							id: props.children.props.children.props.id,
							matrixWidth: props.matrixSize.width,
							matrixHeight: props.matrixSize.height,
							startX: size.left,
							startY: size.top,
							endX: size.left + size.width,
							endY: size.top + size.height,
							zoneId: props.zoneId
						};
						return true;
					}
					return false;
				} else {
					if (mask.zoneId === props.zoneId && mask.x === props.position.x && mask.y === props.position.y) {
						store._maskCells[index] = {
							x: props.position.x,
							y: props.position.y,
							id: null,
							matrixWidth: props.matrixSize.width,
							matrixHeight: props.matrixSize.height,
							startX: size.left,
							startY: size.top,
							endX: size.left + size.width,
							endY: size.top + size.height,
							zoneId: props.zoneId
						};
						return true;
					}
				}
			});

			if (!hasMask) {
				store._maskCells.push({
					x: props.position.x,
					y: props.position.y,
					id: props.children?.props?.children?.props?.id ?? null,
					matrixWidth: props.matrixSize.width,
					matrixHeight: props.matrixSize.height,
					startX: size.left,
					startY: size.top,
					endX: size.left + size.width,
					endY: size.top + size.height,
					zoneId: props.zoneId
				});
			}
		}

		return () => {
			store._maskCells.find((mask, index) => {
				if (mask?.id && mask.id === props?.children?.props?.children?.props?.id) {
					store._maskCells = store._maskCells.filter((_, filteredMaskIndex) => filteredMaskIndex !== index);
					return true;
				} else {
					if (mask.zoneId === props.zoneId && mask.x === props.position.x && mask.y === props.position.y) {
						store._maskCells = store._maskCells.filter((_, filteredMaskIndex) => filteredMaskIndex !== index);
					}
				}
			});
		};

	}, [container.current?.getBoundingClientRect(), props.r, props.children.props.children?.props?.id, props.zoneId, container.current?.offsetWidth, props.position.x, props.position.y, props.children.props?.id, props.matrixSize.width, props.matrixSize.height, props]);

	useEffect(() => {
		if (props.selectedElementId) {
			setPredictEnable(false);
		}
		if (store._selectedElementId && targetCell && targetCell.cellX !== -1 && !props.children?.props?.children?.props?.id && store._targetZoneId === props.zoneId) {
			if (props.replaceEnable) { // TODO Не доработана replaceEnable
				setPredictEnable(
					props.position.x <= targetCell.cellX &&
					props.position.x + props.elementSize.width > targetCell.cellX &&
					props.position.y <= targetCell.cellY &&
					props.position.y + props.elementSize.height > targetCell.cellY
				);
			} else {
				if (props.children?.props?.children?.props?.id) {
					setPredictEnable(false);
				} else {
					setPredictEnable(
						targetCell.cellX <= props.position.x &&
                        targetCell.cellX > props.position.x + props.elementSize.width &&
                        targetCell.cellY <= props.position.y &&
                        targetCell.cellY > props.position.y + props.elementSize.height
					);
				}
			}
		} else {
			setPredictEnable(false);
		}
	}, [props.children.props?.children, props.children.props?.children?.props?.id, props.elementSize, props.position.x, props.position.y, props.replaceEnable, props.selectedElementId, targetCell?.cellX, targetCell?.cellY, props.targetZone, props.zoneId, props, targetCell]);

	const placeholderIsEnable = useMemo(() => {
		if (props.placeholderLayout && props.children?.props?.children?.props?.id) {
			return props.children?.props?.children?.props?.id === store._selectedElementId;
		}
		return false;
	}, [props.children?.props?.children?.props?.id, props.placeholderLayout, store._selectedElementId]);

	const styles = useMemo(() => {
		return ({
			width: `${props.size.width}px`,
			height: `${props.size.height}px`,
		});
	}, [props.size.height, props.size.width]);

	return (
		<div ref={container} style={styles}>
			{placeholderIsEnable && props.placeholderLayout}
			{predictEnable ? toJS(store._predictLayout) : props.children}
		</div>
	);
});

export { Cell };