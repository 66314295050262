import classNames from "classnames";
import { ceil } from "lodash";
import { toJS } from "mobx";
import { useCallback, useMemo, useRef, useState } from "react";

import { binding, bindingInXml } from "module/reactor/binding/binding";

import { Button, ButtonStyle, Hint } from "components";

import { ButtonDropdown, Icon } from "sale-bridge-ui-kit";
import { Item } from "types";
import { ITabProps, ITabsProps } from "./types/types";
import { StandartItem, StandartItemGroup } from "sale-bridge-ui-kit/dist/components/button-dropdown/button-dropdown";

import styles from "./tabs.module.scss";

const RIGHT_ACTION_BUTTONS_WIDTH = 60;
const LEFT_ACTION_BUTTON_WIDTH = 40;
const GAP_BETWEEN_TABS = 30;

export function Tab(props: ITabProps) {
	return <>{props.children}</>;
}

function TabsHeader(props: { tabs: ITabProps[]; activeTab: string; onChangeTab: (tab: string) => void }) {
	const itemBarRef = useRef<HTMLDivElement>(null);
	const clearTime = useRef<NodeJS.Timeout>();
	const [isFirstTabVisible, setFirstTabVisible] = useState<boolean>(true);
	const [isLastTabVisible, setLastTabVisible] = useState<boolean>(false);

	const dropDownItems = useMemo((): Item[] => {
		if (itemBarRef.current) {
			setFirstTabVisible(itemBarRef.current!.scrollLeft == 0);
			setLastTabVisible(ceil(itemBarRef.current!.scrollLeft) == itemBarRef.current!.scrollWidth - itemBarRef.current!.clientWidth);
		}
		return props.tabs.map((item, index) => {
			return {
				id: index,
				name: item.caption,
				displayValue: item.caption
			};
		});
	}, [toJS(props.tabs)]);
	const handleClickToTabFromDropdown = useCallback(
		(id: string) => {
			const findedItem = dropDownItems.find((item) => item.id.toString() === id);
			if (findedItem) {
				handleDropDownChangeValue(findedItem);
			}
		},
		[toJS(dropDownItems)]
	);
	const dropdownTabsItems: Array<StandartItemGroup> = useMemo(() => {
		const items: Array<StandartItem> = [];
		dropDownItems.forEach((item) =>
			items.push({
				id: item.id.toString(),
				name: item.name
			})
		);
		return [
			{
				header: "",
				items: items
			}
		];
	}, [toJS(dropDownItems)]);

	const itemClasses = useCallback(
		(tabName: string) => {
			return classNames(styles.headerItem, {
				[`${styles.active} `]: props.activeTab === tabName
			});
		},
		[props.activeTab]
	);

	const handleClick = useCallback(
		(tabName: string, index: number) => {
			props.onChangeTab(tabName);

			if (itemBarRef.current) {
				const childNodes = Array.from(itemBarRef.current.childNodes as NodeListOf<HTMLDivElement>);
				let scrollLeft = 0;
				const sumOfWidthUpCurrentTab = childNodes
					.slice(0, index)
					.reduce((acc, tab: HTMLDivElement) => acc + tab.offsetWidth + GAP_BETWEEN_TABS, 0);
				const isPartOfTabBeyondRightPart =
					sumOfWidthUpCurrentTab + childNodes[index].offsetWidth >
					itemBarRef.current!.clientWidth +
						itemBarRef.current!.scrollLeft -
						LEFT_ACTION_BUTTON_WIDTH -
						RIGHT_ACTION_BUTTONS_WIDTH;

				if (!isPartOfTabBeyondRightPart) {
					const isPartOfTabBeyondLeftPart = sumOfWidthUpCurrentTab < itemBarRef.current!.scrollLeft + LEFT_ACTION_BUTTON_WIDTH;

					if (!isPartOfTabBeyondLeftPart) {
						return;
					}

					scrollLeft = itemBarRef.current!.scrollLeft - sumOfWidthUpCurrentTab + LEFT_ACTION_BUTTON_WIDTH;
					itemBarRef.current.scrollLeft -= scrollLeft;
				} else {
					scrollLeft =
						sumOfWidthUpCurrentTab +
						childNodes[index].offsetWidth -
						(itemBarRef.current!.clientWidth + itemBarRef.current!.scrollLeft - RIGHT_ACTION_BUTTONS_WIDTH);
					itemBarRef.current.scrollLeft += scrollLeft;
				}

				clearTime.current = setTimeout(() => {
					setFirstTabVisible(itemBarRef.current!.scrollLeft == 0);
					setLastTabVisible(
						ceil(itemBarRef.current!.scrollLeft) == itemBarRef.current!.scrollWidth - itemBarRef.current!.clientWidth
					);
					if (clearTime.current) clearTimeout(clearTime.current);
				}, 200);
			}
		},
		[toJS(props.tabs), itemBarRef.current]
	);

	const handleSwitchClick = useCallback(
		(isLeftScroll: boolean) => {
			if (!itemBarRef.current) {
				return null;
			}

			let sumOfWidthsUpFirstOrLastTab = 0;
			let scrollLeft = 0;

			if (isLeftScroll) {
				(itemBarRef.current.childNodes as NodeListOf<HTMLButtonElement>).forEach((tab: HTMLButtonElement, index) => {
					if (
						sumOfWidthsUpFirstOrLastTab + tab.offsetWidth + GAP_BETWEEN_TABS <
						itemBarRef.current!.clientWidth + itemBarRef.current!.scrollLeft - LEFT_ACTION_BUTTON_WIDTH
					) {
						sumOfWidthsUpFirstOrLastTab += tab.offsetWidth + GAP_BETWEEN_TABS;
					} else if (scrollLeft == 0) {
						scrollLeft =
							sumOfWidthsUpFirstOrLastTab +
							tab.offsetWidth -
							(itemBarRef.current!.clientWidth + itemBarRef.current!.scrollLeft - RIGHT_ACTION_BUTTONS_WIDTH);
						if (scrollLeft == 0 && index !== itemBarRef.current!.childNodes.length - 1) {
							sumOfWidthsUpFirstOrLastTab += GAP_BETWEEN_TABS + tab.offsetWidth;
						}
					}
				});
				itemBarRef.current.scrollLeft += scrollLeft;
			} else {
				(itemBarRef.current.childNodes as NodeListOf<HTMLButtonElement>).forEach((tab: HTMLButtonElement) => {
					if (scrollLeft == 0) {
						if (
							sumOfWidthsUpFirstOrLastTab + tab.offsetWidth + GAP_BETWEEN_TABS <
							itemBarRef.current!.scrollLeft + LEFT_ACTION_BUTTON_WIDTH
						) {
							sumOfWidthsUpFirstOrLastTab += tab.offsetWidth + GAP_BETWEEN_TABS;
						} else {
							scrollLeft = itemBarRef.current!.scrollLeft - sumOfWidthsUpFirstOrLastTab + LEFT_ACTION_BUTTON_WIDTH;
						}
					}
				});
				itemBarRef.current.scrollLeft -= scrollLeft;
			}

			clearTime.current = setTimeout(() => {
				setFirstTabVisible(itemBarRef.current!.scrollLeft == 0);
				setLastTabVisible(
					ceil(itemBarRef.current!.scrollLeft) == itemBarRef.current!.scrollWidth - itemBarRef.current!.clientWidth
				);
				if (clearTime.current) clearTimeout(clearTime.current);
			}, 200);
		},
		[toJS(props.tabs), itemBarRef]
	);

	const handleDropDownChangeValue = useCallback(
		(value: Item | null) => {
			if (value) {
				handleClick(value.name, Number(value.id));
			}
		},
		[toJS(props.tabs), itemBarRef.current]
	);

	return (
		<div className={styles.header}>
			<div className={styles.itemBar} ref={itemBarRef}>
				{props.tabs.map((t: ITabProps, index) => {
					return (
						<div key={index}>
							<Button
								key={t.name}
								caption={t.caption}
								className={itemClasses(t.name)}
								onClick={() => {
									handleClick(t.name, index);
								}}
							/>
						</div>
					);
				})}
			</div>
			<div className={styles.leftActionButton}>
				<Hint tooltip="Предыдущие вкладки">
					<Button
						firstIcon={<Icon name="ChevronLeft" size={"16"} />}
						className={styles.arrowToLeftButton}
						style={ButtonStyle.GrayBlue300}
						onClick={() => {
							handleSwitchClick(false);
						}}
						isDisabled={isFirstTabVisible}
					/>
				</Hint>
			</div>
			<div className={styles.rightActionButtons}>
				<Hint tooltip="Следующие вкладки">
					<Button
						firstIcon={<Icon name="ChevronRight" size={"16"} />}
						style={ButtonStyle.GrayBlue300}
						onClick={() => {
							handleSwitchClick(true);
						}}
						isDisabled={isLastTabVisible}
					/>
				</Hint>
				<Hint tooltip="Список вкладок">
					<ButtonDropdown
						size="small"
						position="right-down"
						standartItems={dropdownTabsItems}
						onClickStandartItem={handleClickToTabFromDropdown}
						childrenButton={
							<div className={styles.arrowToRightButton}>
								<Icon size="16" name="List" />
							</div>
						}
					/>
				</Hint>
			</div>
		</div>
	);
}

function Tabs(props: ITabsProps) {
	const tabs = props.children.map((c: any) => c.props);
	const activeTabBind = bindingInXml(props.services, props.activeTab);
	const activeTab = props.children.filter((t: any) => t.props.name === activeTabBind)[0];

	const onChangeTab = binding(props.services, props.setActiveTab);

	return (
		<div className={styles.tabs}>
			<TabsHeader tabs={tabs} activeTab={activeTabBind} onChangeTab={onChangeTab} />
			<div className={styles.activeTab}>{activeTab}</div>
		</div>
	);
}
export default Tabs;
