import { isEqual } from "lodash";

import { dispatcher } from "store";

import { Comment } from "types/entity";

export class CommentsController {
	get = (): Array<Comment> | undefined => {
		return dispatcher.currentRow.get()?.comments;
	};
	set = (comments: Array<Comment>) => {
		if (dispatcher.currentRow.get()) {
			dispatcher.currentRow.get().comments = comments;
		}
	};
	add = (comment: Comment) => {
		if (dispatcher.currentRow.get()?.comments && !dispatcher.currentRow.get()?.comments.find((oldComment: Comment) => oldComment.id === comment.id)) {
			const clone = [...dispatcher.currentRow.get().comments];
			clone.push(comment);
			dispatcher.currentRow.get().comments = clone;
		}
	};
	remove = (commentId: string) => {
		const comments = this.get();
		if (comments) {
			dispatcher.currentRow.get().comments = comments.filter(comment => comment.id !== commentId);
		}
	};

	edit = (commentId: string, text: string, modifiedOn?: string) => {
		const comment = this.get()?.find((comment: Comment) => comment.id === commentId);
		if (!comment) {
			return
		}
		comment.text = text;
		if (modifiedOn) {
			comment.modifiedOn = modifiedOn;
		}

	};

	sort = (func: (a: any, b: any) => 1 | 0 | -1) => {
		const comments = this.get();
		if (comments) {
			if (!isEqual([...comments].sort(func), comments.map(comment => comment))) {
				dispatcher.currentRow.get().comments = [...comments].sort(func);
			}
		}
	};
}

export const commentsController = new CommentsController();