import classNames from "classnames";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { forwardRef, useCallback, useEffect, useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import { Icon } from "sale-bridge-ui-kit";
import { DroppableProvidedProps } from "react-beautiful-dnd";

import { store } from "store";

import { singlePageSynchroiser } from "pages/single-page/single-page-synchroiser/single-page-synchroiser";

import styles from "./column-body.module.scss";

type ColumnBodyProps = {
    quantity: number,
    children: JSX.Element | Array<JSX.Element>,
    isBlocked?: boolean,
    cardsCount?: number;
    id: string;
    isPreview?: boolean; //TODO убрать, когда будут доработки по загрузке карточек или рефакторинг
    droppableProps?: DroppableProvidedProps;
}

/**
 * @description Создание колонки канбана
 * @param props.quantity количество карточек
 * @param props.children карточки внутри колонки
 * @param props.isBlocked параметр для блокирования колонки
 * @param props.cardsCount Кол-во уже погруженных карт, нужно для отключения подгрузки при отсутсвии доп карточек на сервере
 * @param props.id id стадии
 * @param props.isPreview флаг того, что эта стадия используется в превью дизайна карточки
 * @param props.droppableProps - Пропсы библиотеки канбана
 * @returns
 */
export const ColumnBody = observer(forwardRef<HTMLElement, ColumnBodyProps>((props, ref) => {
    const entityNameFromParams = useParams().entityName;
    const container = useRef<HTMLElement | null>(null);
    const savedScrollPosition = useRef<number>(0);
    const entityName = useMemo(() => store.entities?.find((entity: any) => entity.entityName === entityNameFromParams)?.entityName, [toJS(store.entities), entityNameFromParams])

    const columnStyles = classNames(styles.columnBody, {
        [`${styles.columnScrollZone} `]: props.quantity !== 0,
        [styles.isBlocked]: props.isBlocked,
    });

    useEffect(() => {
        if (container.current) {
            savedScrollPosition.current = container.current.scrollTop;
        }
    }, [props.children]);

    const handleScroll = useCallback(() => {
        if (container.current && props.cardsCount !== props.quantity && !singlePageSynchroiser.stageIsLoading(props.id)) {
            const { scrollTop, scrollHeight, clientHeight } = container.current;
            if (scrollTop > 0 && Math.round(scrollTop) + clientHeight >= scrollHeight) {
                singlePageSynchroiser.uploadStageCards(entityName ?? "", props.id, props.cardsCount ?? 0);
            }
        }
    }, [props.cardsCount, props.quantity, props.id, entityName]);

    const setRefNode = useCallback((node: HTMLElement | null) => {
        container.current = node;
        if (typeof ref === 'function') {
            ref(node);
        } else if (ref) {
            ref.current = node;
        }
    }, [ref])

    return (
        <div
            ref={setRefNode}
            {...props.droppableProps}
            onScroll={handleScroll}
            className={columnStyles}
        >
            {props.children}
            {(!props.isPreview && singlePageSynchroiser.stageIsLoading(props.id)) && <div className={styles.loaderContainer}>
                <Icon name="Spinner" size="16" />
                Загрузка
            </div>}
        </div>
    )
}))