import { Multiline } from "./../pages/section-card/lib/inputs/multiline/multiline";
import { Item, User } from "./";
import SavedFilter from "entities/filter/SavedFilter";
import IFilter from "entities/filter/IFilter";
import { VirtualLookup } from "pages/section-wizzard/pages/constructor/configurations/field-configuration/types";

export enum LoadingState {
	NotAsked,
	Loading,
	Successful,
	Error
}

export enum EntityNameType {
	Tables = 0,
	Sections = 1,
	Lookups = 2
}

enum GridColumnsViewType {
	List = 0,
	Tile = 1
}

type City = {
	createdOn: string;
	externalId: string | null;
	id: string;
	modifiedOn: string;
	name: string;
};

type Gender = {
	createdOn: string;
	externalId: string | null;
	id: string;
	modifiedOn: string;
	name: string;
};

type Role = {
	createdOn: string;
	externalId: string | null;
	id: string;
	modifiedOn: string;
	name: string;
	order: number;
};

export enum EntityType {
	Contact = "Contact",
	Account = "Account",
	Lead = "Lead",
	Sale = "Sale"
}

export enum ViewRole {
	Detail = 0,
	Section = 1
}

export type Column = {
	columnId: string;
	columnName: string;
	columnTitle: string;
	columnType: string;
	hasIndex: boolean;
	isLookup: boolean;
	isLink: boolean;
	isRequired: boolean;
	uneditable: boolean;
	isViewColumn: boolean;
	lookupTable: string | null;
	specializations?: {
		tag: ColumnSpecializationType;
		properties: Properties;
	};
};

export interface VisibleColumn extends Column {
	customerColumnTitle: string;
	indexX: number;
	indexY: number;
	spanX: number;
	spanY: number;
}

export type SectionViewPageColumnSettings = {
	columnId: string;
	columnName: string;
	columnTitle: string;
	customerColumnTitle: string;
	indexX: number;
	indexY: number;
	spanX: number;
	spanY: number;
};

export type ViewColumn = {
	id: string;
	columnName: string;
	columnTitle: string;
};

export type SectionViewPageSettings = {
	id: string;
	entityName: string;
	isPagginationActivate: boolean;
	viewType: GridColumnsViewType;
	loadingRecordsCount: number;
	columnSettings: SectionViewPageColumnSettings[];
	forAllUsers: boolean;
	userId: string | null;
	viewRole: ViewRole;
	detailContainerEntityId?: string;
};

// export type Cell = {
//     birthDate: string,
//     cityId: string | null;
//     createdOn: string;
//     email: string;
//     externalId: string | null;
//     genderId: string;
//     givenName: string;
//     id: string;
//     middleName: string | null;
//     modifiedOn: string;
//     name: string;
//     notes: string | null;
//     phone: string;
//     position: string | null;
//     primaryPhone: string | null;
//     site: string | null;
//     ownerId: string | null;
//     inn: string | null;
//     kpp: string | null;
//     ogrn: string | null;
//     categoryId: string | null;
//     industryId: string | null;
//     typeId: string | null;
//     typeOwnershipId: string | null;
//     primaryAccount: string | null;
//     primaryAccountId: string | null;
//     roleId: string;
//     surName: string;
//     city: City | null;
//     gender: Gender;
//     role: Role;
//     owner: User;
//     comments: Array<any>;
// }

export type Comment = {
	id: string;
	entityName: string;
	userId: string;
	entityId?: string;
	text: string;
	userName?: string;
	isOwner?: boolean;
	createdOn: string;
	modifiedOn?: string;
};

type FilterInfo = {
	aggregationColumn: string;
	attribute: string;
	attributeType: number;
	comparisonType: number;
	detail: string;
	detailAggregationType: string;
	detailFilter: string | null;
	excludedIds: string | null;
	filters: Array<Filter>;
	id: string;
	includedIds: string | null;
	isActive: boolean;
	isEnabled: boolean;
	logicalOperation: number;
	rightExpression: null | string;
	schema: string;
	type: number;
};

type FilterFolder = {
	childFilterFolders: Array<FilterFolder>;
	entityName: string | null;
	filters: Array<{
		createdOn: string;
		entityName: string;
		filterInfo: FilterInfo;
		filterName: string;
		id: string;
		isFavorite: boolean;
		modifiedOn: string;
		savedFilterFoldId: string;
		userId: string;
	}>;
	id: string;
	isRoot: boolean;
	name: string;
	parentFolderId: string;
};

export enum OptionPage {
	GlobalSettings = "GlobalSettings",
	Constructor = "Constructor",
	AccessRights = "AccessRights",
	StageModel = "StageModel",
	CardSettings = "CardSettings",
	QuickView = "QuickView",
	BusinessRules = "BusinessRules"
}

export enum DisplayedPanel {
	Main,
	Additional
}

export enum SectionWizzardFields {
	optionPage = "optionPage",
	entityTitle = "entityTitle",
	systemName = "systemName",
	hasStageModel = "hasStageModel",
	kanbanIsEnabled = "kanbanIsEnabled",
	hasCalendar = "hasCalendar",
	hasSpecificFinish = "hasSpecificFinish",
	searchValue = "searchValue",
	displayedPanel = "displayedPanel",
	viewColumnId = "viewColumnId",
	viewAdditionalPanelToggle = "viewAdditionalPanelToggle",
	isValid = "isValid"
}
export enum TabId {
	Files = "files",
	Comments = "comments",
	Chronology = "chronology",
	Wysiwyg = "wysiwyg"
}
export enum TabState {
	EnabledOnPrimary,
	EnabledOnAuxiliary,
	Disabled
}

export enum RecordOperation {
	Read = 0,
	Edit = 1,
	Delete = 2
}

export enum RecordRightLevel {
	Deny = 0,
	Granted = 1
}
/**
 * @param state Состояние таба
 * @param index Индекс таба в массиве вкладок
 * @param title Заголовок таба
 */
export type TabParams = {
	state: TabState;
	index: number;
	title: string;
};
export type AdditionalPanel = {
	comments: TabParams;
	files: TabParams;
	chronology: TabParams;
	wysiwyg: TabParams;
};
export type GroupFieldsConfig = {
	name: string;
	title: string;
	inner: Grid | null;
	groupFieldId: string;
};
export type VirtualDetail = {
	entityName: string;
	entityTitle: string;
	entityLinkColumn: string;
	entityLinkColumnName: string;
	entityLinkColumnTitle: string;
};
export type DetailConfig = {
	entityName: string;
	detailName: string;
	detailTitle: string;
	detailColumn: string;
	entityColumn: string;
	inner: Grid | null;
	virtualDetail?: VirtualDetail | null;
};
export type DetailDesignerType = {
	columnsInfo: Array<Column>;
	detailConfig: DetailConfig | null;
	isSection: Boolean;
};

export enum ItemType {
	Field,
	FieldGroup,
	Detail
}
export enum NumeratorWorkingPeriod {
	WithoutExpirationDate,
	Day,
	Week,
	Month,
	Year
}
export enum MaskState {
	Default,
	Deactivated,
	Condition
}
export enum NumeratorMaskSettingsParameterType {
	Number,
	Text,
	CurrentDay,
	CurrentMonth,
	CurrentYear2,
	CurrentYear4,
	ColumnValue
}

export enum QuickActionType {
	CopyLink,
	OpenInNewTab,
	Flag,
	LogTime,
	Stopwatch
}

export const QuickActionTypeName: Record<QuickActionType, { title: string; description: string }> = {
	[QuickActionType.CopyLink]: { title: "Копировать ссылку", description: "Копирует ссылку на запись в буфер обмена" },
	[QuickActionType.OpenInNewTab]: {
		title: "Открыть в новой вкладке",
		description: "Откроет страницу редактирования записи в новой вкладке"
	},
	[QuickActionType.Flag]: { title: "Поставить флажок", description: "Помечает запись флажком" },
	[QuickActionType.LogTime]: { title: "Залогировать время", description: "Вызывает модальное окно для ввода трудозатрат" },
	[QuickActionType.Stopwatch]: { title: "Включить секундомер", description: "Позволяет засекать время работы над задачей" }
};

export type NumeratorMaskSettingsTextParameter = {
	text: string;
};
export type NumeratorMaskSettingsColumnValueParameter = {
	isLookup: boolean;
	columnName: string;
	columnId: string;
	columnTitle: string;
	lookupTable?: string;
	lookupTableColumnId?: string;
	lookupTableColumnName?: string;
	lookupTableColumnTitle?: string;
};

export type NumeratorMaskSettingsNumberParameter = {
	valueLength: number;
	step: number;
	startValue: string;
};

export type NumeratorMaskSettingsParameter = {
	id: string;
	order: number;
	type: NumeratorMaskSettingsParameterType;
	textParameter?: NumeratorMaskSettingsTextParameter;
	numberParameter?: NumeratorMaskSettingsNumberParameter;
	columnValueParameter?: NumeratorMaskSettingsColumnValueParameter;
};

export type NumeratorMaskItem = {
	id: string;
	name: string;
	maskState: MaskState;
	parameters: Array<NumeratorMaskSettingsParameter>;
};

export type NumeratorMaskConditions = {
	id: string;
	name: string;
	columnTitle: string;
	columnName: string;
	columnValue: any;
	maskId: string;
};
export type NumeratorMaskSettings = {
	items: Array<NumeratorMaskItem>;
	conditions: Array<NumeratorMaskConditions>;
};
export type NumeratorConfig = {
	numeratorWorkingPeriod: NumeratorWorkingPeriod;
	fillWhenPageOpened: boolean;
	maskSettings: NumeratorMaskSettings;
};
export type FieldConfig = {
	columnId: string;
	columnName: string;
	columnTitle: string;
	columnType: string;
	hasIndex: boolean;
	isLookup: boolean;
	isRequired: boolean;
	lookupTable: string | null;
	defaultValue?: string;
	virtualLookup?: VirtualLookup | null;
	numeratorConfig?: NumeratorConfig | null;
	rounding?: string;
	prompt?: string;
	uneditable: boolean;
	specializations?: EntityColumnSpecialization;
	isViewColumn: boolean;
};

export type EntityColumnSpecialization = {
	tag: ColumnSpecializationType;
	properties: Properties;
};

export type Properties = {
	[key: string]: string;
};

export enum ColumnSpecializationType {
	DateTime,
	Price,
	Link,
	Phone,
	Stage,
	Avatar,
	Double,
	Multiline,
	KanbanField,
	WysiwygEditor,
	Flag
}

export type GridItem = {
	x: number;
	y: number;
	gridItemId: string;
	width: number;
	height: number;
	type?: ItemType;
	fieldConfig?: FieldConfig;
	groupFieldsConfig?: GroupFieldsConfig;
	detailConfig?: DetailConfig;
};
export type Grid = {
	items: Array<GridItem>;
};
export type TabsConfig = {
	tabIndex: number;
	tabName: string;
	tabTitle: string;
	grid: Grid;
};

export type Tabs = {
	tabsConfig: TabsConfig[];
	additionalPanel: AdditionalPanel;
	currentTab: number;
};
export type Navbar = {
	left: string;
	center: string;
	right: string;
};

export type ReactorConfig = {
	navbar: Navbar;
	tabs: Tabs;
};

export type AccessRightsConfig = {
	adminByOperation: {
		isEnabled: boolean;
		operationItems: OperationItem[];
	};
	adminByRecords: {
		isEnabled: boolean;
		recordItems: RecordItem[];
	};
};

export type OperationItem = {
	id: string;
	userId: string | null;
	roleId: string | null;
	unitName: string | null;
	canCreate: boolean;
	canEdit: boolean;
	canRead: boolean;
	canDelete: boolean;
	position: number;
};

export type RecordItem = {
	id: string;
	groupRecordsId: string;
	authorUserId: string | null;
	authorRoleId: string | null;
	granteeUserId: string | null;
	granteeRoleId: string | null;
	authorName: string | null;
	granteeName: string | null;
	operation: RecordOperation;
	rightLevel: RecordRightLevel;
	position: number;
};
//TODO исправить обязательность полей, если требуется
export type BusinessRule = {
	id: string;
	ruleName: string;
	ruleDescription: string;
	priority: number;
	enabled: boolean;
	ruleSettings: RuleSetting;
	createdOn: string;
	createdById: string;
	modifiedById: string;
};
//TODO исправить обязательность полей, если требуется
export type RuleSetting = {
	conditions: IFilter | null;
	actions: Array<Action>;
	elseActions: Array<Action>;
};
//TODO исправить обязательность полей, если требуется
export type Action = {
	id: string;
	field: string | null;
	actionType: ActionType | null;
	value: string | Item | null;
};
export enum ActionType {
	SET_VALUE,
	CLEAR_VALUE,
	MAKE_IT_REQUIRED,
	MAKE_IT_OPTIONAL,
	MAKE_EDITABLE,
	MAKE_UNEDITABLE,
	MARK,
	UNCHECK,
	SHOW_ON_PAGE,
	HIDE_FROM_PAGE
}

export const ActionTypeName: Record<ActionType, string> = {
	[ActionType.SET_VALUE]: "Заполнить",
	[ActionType.CLEAR_VALUE]: "Очистить",
	[ActionType.MAKE_IT_REQUIRED]: "Сделать обязательным",
	[ActionType.MAKE_IT_OPTIONAL]: "Сделать не обязательным",
	[ActionType.MAKE_EDITABLE]: "Сделать редактируемым",
	[ActionType.MAKE_UNEDITABLE]: "Сделать нередактируемым",
	[ActionType.MARK]: "Отметить",
	[ActionType.UNCHECK]: "Снять отметку",
	[ActionType.SHOW_ON_PAGE]: "Показывать на странице",
	[ActionType.HIDE_FROM_PAGE]: "Скрыть со страницы"
};

export type SectionWizzard = {
	optionPage: OptionPage | null;
	entityTitle: string;
	systemName: string;
	hasStageModel: boolean;
	kanbanIsEnabled: boolean;
	hasCalendar: boolean;
	hasSpecificFinish: boolean;
	hasFlag: boolean;
	hasTimeLogging: boolean;
	hasTimer: boolean;
	displayedPanel: DisplayedPanel;
	viewColumnId: string | null;
	searchValue: string | null;
	reactorPage: string | null;
	reactorConfig: ReactorConfig;
	accessRightsConfig: AccessRightsConfig;
	hasChanges: boolean;
	stageModelConfig: StageModel | null;
	kanbanConfig: KanbanConfig | null;
	businessRules: Array<BusinessRule> | null;
};

export type StaticGroup = {
	id?: string | null;
	userId: string | null;
	entityName: string | null;
	name: string | null;
	filter: IFilter | null;
	folderId: string | null;
	modifiedOn?: string;
	createdOn?: string;
};

export type ExcludeRecordsFromStaticGroup = {
	staticGroupId: string;
	recordsToExclude: string[];
	recordsToSave: string[];
};

export type Filter = {
	savedFilter: SavedFilter | null;
	staticGroup: StaticGroup | null;
	favoriteFilters: SavedFilter[];
	placeholder: string | null;
};

export type SavedFilterFolder = {
	id?: string | null;
	parentFolderId: string | null;
	name: string | null;
	filters: SavedFilter[];
	childFilterFolders: SavedFilterFolder[];
	isRoot: boolean;
	entityName: string | null;
	userId: string | null;
};

export type StaticGroupFolder = {
	id?: string | null;
	parentFolderId: string | null;
	name: string | null;
	groups: StaticGroup[];
	childGroupFolders: StaticGroupFolder[];
	isRoot: boolean;
	entityName: string | null;
};

export type FilterTree = {
	savedFilterTree: SavedFilterFolder | null;
	staticGroupFolderTree: StaticGroupFolder | null;
};

export interface BasicEntity {
	columns: Array<Column>;
	viewColumn: ViewColumn | null;
	rows: Array<any>;
	filterTree: FilterTree | null;
	quality: number;
	sort: any;
	visibleColumns: Array<VisibleColumn>;
	display: any;
	filter: Filter | null;
	sectionWizzard: SectionWizzard | null;
	oldValueOfSectionWizzard: SectionWizzard | null;
	isCheckedAll: boolean;
	includedIds: Array<any>;
	excludedIds: Array<any>;
	countOfChecked: number;
	sectionViewPageSettings: SectionViewPageSettings | null;
}

export type StageId = string; // Общий id стадии
export type Id = string; // Индивидумальный id еденицы данных

export enum HEXColors {
	Blue = "#A4BCFD",
	Grey = "#B3B8DB",
	Pacific_cyan = "#4FB8D3",
	Fluorescent_cyan = "#41E3DC",
	Spring_bud = "#A6E73C",
	Aureolin = "#FFF126",
	Sunglow = "#FFBE37",
	Pomegranate = "#F072BB",
	Frostbite = "#C6F"
}

export enum ResultHEXColors {
	Successful = "#33AE6C",
	Negative = "#FA8B83",
	Null = "#A4BCFD"
}

export enum ResultType {
	Successful = 0,
	Null = 1,
	Negative = 2
}

/**
 * @description
 * Current - текущая стадия
 *
 * Passed - стадия с левой стороны от current
 *
 * Default - стадия с правой стороны от current
 */
export enum StageType {
	CURRENT = "Current",
	PASSED = "Passed",
	DEFAULT = "Default"
}

export type Stages = {
	id: StageId;
	name: string;
};

export type DetailResult = {
	id: Id;
	stageId: StageId;
	detailResultText: string;
};

export type StageSetting = {
	id: Id;
	stageId: StageId;
	color: HEXColors | string;
	isHidden: boolean;
	order: number;
	resultType: ResultType | null;
};

export type MovingRule = {
	id: Id;
	stageFromId: StageId;
	stageToId: StageId;
};

export type StageModel = {
	stageLookupTitle: string;
	stageLookupName: string;
	detailedResultLookupName: string;
	stages: Array<Stages>;
	detailResults: Array<DetailResult>;
	stageSettings: Array<StageSetting>;
	movingRules: Array<MovingRule>;
};

export type StagesFromAndTo = {
	stagesFrom: Array<string>;
	stagesTo: Array<string>;
};

export type StageFullInfo = {} & Stages & DetailResult & StageSetting & StagesFromAndTo;

export type KanbanConfig = {
	cardDesign: CardDesign;
	quickViewDesign: QuickViewDesign;
};
export type CardDesign = {
	userAvatarEnable: boolean /*Аватары пользователей*/;
	quickActionEnable: boolean /*Быстрые действия*/;
	additionalFields: Array<AdditionalField>;
	userFields: Array<AdditionalField>;
	selectedQuickActions: Array<QuickAction>;
};

export type QuickAction = {
	action: QuickActionType;
	isEnabled: boolean;
	isHidden: boolean;
};

export type QuickViewDesign = {
	quickViewEnable: boolean;
	leftBlockAdditionalFields: Array<AdditionalField>;
	rightBlockAdditionalFields: Array<AdditionalField>;
	commentsEnable: boolean;
};

export type AdditionalField = {
	columnId: string;
	gridItemId: string;
	order: number;
	isBlocked?: boolean;
};

export enum SystemColumns {
	Id = "Id",
	ModifiedOn = "ModifiedOn",
	CreatedOn = "CreatedOn",
	CreatedBy = "CreatedBy",
	ModifiedBy = "ModifiedBy",
	ExternalId = "ExternalId"
}

export const BlockedColumnNames = [
	SystemColumns.Id,
	SystemColumns.ExternalId,
	SystemColumns.CreatedOn,
	SystemColumns.ModifiedOn,
	SystemColumns.CreatedBy,
	SystemColumns.ModifiedBy
];
