import { CSSProperties, useCallback, useMemo, useState } from "react";
import classNames from "classnames";

import IProps from "../../IProps";
import { bindingInXml } from "module/reactor/binding/binding";

import { ArrowToDown } from "shared";

import styles from "./field-group.module.scss";

export interface IFieldGroupProps extends IProps {
	title: string;
	x: string;
	y: string;
}

const FieldGroup = (props: IFieldGroupProps) => {
	const title = bindingInXml(props.services, props.title);
	const x = bindingInXml(props.services, props.x);
	const y = bindingInXml(props.services, props.y);

	const [isOpened, setOpened] = useState(false);

	const gridStyle: CSSProperties = useMemo(
		() => ({
			gridRow: `${y}/${y}`,
			gridColumn: `${x}/3`
		}),
		[x, y]
	);

	const fieldGroupClassName = classNames(`${styles.fieldGroup} `, {
		[`${props.className}`]: props.className
	});

	const onContentHiden = useCallback(() => {
		setOpened(!isOpened);
	}, [isOpened]);

	return (
		<div className={fieldGroupClassName} style={gridStyle}>
			<FieldGroupHeader onContentHiden={onContentHiden} isOpen={isOpened} title={title} />
			{isOpened && props.children.length > 0 && <div className={styles.fieldGroupBody}>{props.children}</div>}
			{isOpened && props.children.length === 0 && <Empty />}
		</div>
	);
};

interface FieldGroupHeaderProps {
	onContentHiden: () => void;
	isOpen: boolean;
	title: string;
}

function FieldGroupHeader(props: FieldGroupHeaderProps) {
	const arrowToDownClassName = classNames({
		[`${styles.icons} `]: true,
		[`${styles.icons} ${styles.iconOpen}`]: !props.isOpen
	});

	return (
		<div className={styles.fieldGroupHeader} onClick={props.onContentHiden}>
			<ArrowToDown className={arrowToDownClassName} />
			<span>{props.title}</span>
		</div>
	);
}

function Empty() {
	return (
		<div className={styles.empty}>
			<span>Нет данных</span>
		</div>
	);
}

export default FieldGroup;
