import { observer } from "mobx-react-lite";
import { ChangeEvent, useEffect, useState } from "react";
import { observable } from "mobx";

import { api } from "shared";

import NavigationTitle from "../NavigationTitle";
import { Button, ButtonStyle, InputSearch } from "components";

import Entity from "entities/Entity";
import { License } from "entities/license/License";
import licenseManagerMainPanelStore from "./LicenseManagerMainPanelStore";

import { InProgress } from "shared";

import styles from "./Licenses.module.css";

export const LicenseManagerMainPanelInProgress = observer(function () {
	return (
		<div className={styles.systemMainPanel}>
			<NavigationTitle />
			<div className={styles.inProgress}>
				<InProgress className={styles.inProgressSvg} />
			</div>
		</div>
	);
});

interface IFileManager {
	DownloadFile: () => void;
}

const FileManager = observable<IFileManager>({
	DownloadFile: async () => {
		// const result = await Api.post("/api/License/sendRequest", {});
		const result = await api.http.license.sendRequest().post(null);

		if (result.data.success === true) {
			var element = document.createElement("a");
			element.setAttribute("href", "data:" + result.data.contentType + ";charset=utf-8," + encodeURIComponent(result.data.text));
			element.setAttribute("download", result.data.name);
			element.style.display = "none";
			document.body.appendChild(element);
			element.click();
			document.body.removeChild(element);
		} else {
			console.log("Something went wrong :(");
			console.log(result.data.message);
		}
	}
});

const LicenseManagerMainPanel = observer(function (props: { onChangeValue: (item: JSX.Element) => void }) {
	let [searchValue, setSearchValue] = useState("");
	const [entity, setEntity] = useState<Entity<License>>();
	const [file, setFile] = useState<File>();
	const f = FileManager;

	useEffect(() => {
		setEntity(new Entity<License>(new License()));
		licenseManagerMainPanelStore.setEntityCount();
	}, []);

	function search(s: string) {
		setSearchValue(s);
	}

	const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			setFile(e.target.files[0]);
		}
	};

	const handleUploadClick = () => {
		if (!file) {
			return;
		}

		// 👇 Uploading the file using the fetch API to the server
		fetch("https://httpbin.org/post", {
			method: "POST",
			body: file,
			// 👇 Set headers manually for single file upload
			headers: {
				"content-type": "application/json",
				"content-length": `${file.size}` // 👈 Headers need to be a string
			}
		})
			.then((res) => res.json())
			.then((data) => console.log(data))
			.catch((err) => console.error(err));
	};

	return (
		<>
			{entity ? (
				<div className={styles.systemMainPanel}>
					<div className={styles.licenseMainPanel}>
						<NavigationTitle />
						<div className={styles.secondTitle}>
							<div className={styles.countLicenses}>
								<div className={styles.licensesNumber}>{licenseManagerMainPanelStore.entityCount}</div>
								<div className={styles.numberTitle}>Загруженных лицензий</div>
							</div>
							<Button
								caption={"Запросить лицензии"}
								onClick={() => {
									FileManager.DownloadFile();
								}}
								style={ButtonStyle.Primary}
								className={styles.newLicenseButton}
							/>
							{/*   
                     
.input {
    min-height: 100%;
    border: none;
    font-family: var(--font-roboto-400);
}
                     
                     <input type="file" onChange={handleFileChange} />
                        <div>{file && `${file.name} - ${file.type}`}</div>
                        <button onClick={handleUploadClick}>Upload</button>
                        */}
						</div>
						<div className={styles.thirdTitle}>
							<div className={styles.searchingTitle}>
								<InputSearch
									placeholder="Фильтр + поиск"
									value={searchValue}
									onChangeValue={search}
									classNameInput={styles.searchLicenseGrid}
								/>
								{licenseManagerMainPanelStore.licenseStore &&
									licenseManagerMainPanelStore.licenseStore.countOfChecked > 0 && (
										<CounterChoosingLicenses counter={licenseManagerMainPanelStore.licenseStore.countOfChecked} />
									)}
							</div>
						</div>

						<div className={styles.grid}>
							{/* <GridDetail maxHeight={`calc(100vh - 260px)`} columns={LicenseColumns} listStore={licenseManagerMainPanelStore.licenseStore!} isCheckBox={true} /> */}
						</div>
					</div>
				</div>
			) : (
				<></>
			)}
		</>
	);
});
export default LicenseManagerMainPanel;

const CounterChoosingLicenses = observer(function (props: { counter: number }) {
	return (
		<div className={styles.counter}>
			<div className={styles.choosingLicenses}>выбрано лицензий</div>
			<div className={styles.counterIcon}>{props.counter}</div>
		</div>
	);
});
