import { action, makeAutoObservable } from 'mobx'

import { type Types } from '../type'

interface PositionsForMatrix {
  x: number
  y: number
}

interface PositionsForHorizontalColumn {
  x: number
}

export interface Element<T> {
  layout: JSX.Element
  id: string
  sourceData?: Record<string, any>
  position: T extends Types.MATRIX ? PositionsForMatrix : PositionsForHorizontalColumn
  size?: T extends Types.MATRIX ? {
    width: number
    height: number
  } : never
  pagePosition?: T extends Types.HORIZONTAL_COLUMN ? {
    x: number
    y: number
    width: number
    height: number
  } : never
}

interface Zone<T> {
  size: {
    startX: number
    startY: number
    endX: number
    endY: number
  }
  config: {
    type: Types
    height?: number
    width?: number
    cellSize?: {
      width: number
      height: number
    }
  }
  elements: Record<string, Element<T>>
}

export enum DirectionRelativeElement {
  'LEFT' = 'LEFT',
  'RIGHT' = 'RIGHT'
}

type Id = string

class Store {
  _zones: Record<Id, Zone<any>>

  _targetPosition: { x: number } | null

  _targetElementId: null | string

  _targetCell: string | null

  _targetZoneId: string | null

  _selectedElementId: string | null

  _elemCenter: { x: number, y: number } | null

  _hasMove: boolean

  _predictLayout: JSX.Element | null

  _maskCells: Array<{
    x: number
    y: number
    id: Id | null
    matrixWidth: number
    matrixHeight: number
    zoneId: Id
    startX: number
    startY: number
    endX: number
    endY: number
  }>

  _canMove: boolean

  _directionRelativeElement: DirectionRelativeElement

  _movingDirection: DirectionRelativeElement

  constructor () {
    this._zones = {}
    this._targetCell = null
    this._targetZoneId = null
    this._targetPosition = null
    this._targetElementId = null
    this._selectedElementId = null
    this._elemCenter = null
    this._hasMove = false
    this._predictLayout = null
    this._maskCells = []
    this._canMove = false
    this._directionRelativeElement = DirectionRelativeElement.LEFT
    this._movingDirection = DirectionRelativeElement.LEFT

    makeAutoObservable(this)
  }

  get zones (): Record<Id, Zone<any>> {
    return this._zones
  }

  setTargetCell = action('set target cell', (value: string) => {
    this._targetCell = value
  })

  getZone = ({ x, y }: { x: number, y: number }): null | string => {
    let result = null

    Object.keys(this._zones).sort((a, b) => {
      if (this._zones[a].size.endY - this._zones[a].size.startY > this._zones[b].size.endY - this._zones[b].size.startY) {
        return 1
      } else if (this._zones[a].size.endY - this._zones[a].size.startY < this._zones[b].size.endY - this._zones[b].size.startY) {
        return -1
      } else {
        return 0
      }
    }).find((zoneId) => {
      const zone = this._zones[zoneId]
      if (x && y) {
        if (zone && zone.size.startX < x && x < zone.size.endX &&
                    zone.size.startY < y && y < zone.size.endY) {
          result = zoneId
          return true
        }
      }
      return null
    })

    return result
  }

  setZone = action('set zone', (id: string, value: Zone<any>) => {
    this._zones[id] = value
  })

  setTargetZoneId = action('set target zone id', (zoneId: string | null) => {
    this._targetZoneId = zoneId
  })

  setZoneSize = action('set zone size', ({ zoneId, startX, startY, width, height }: { zoneId: Id, startX: number, startY: number, width: number, height: number }) => {
    store.setZone(zoneId, {
      size: {
        startX,
        startY,
        endX: startX + width,
        endY: startY + height
      },
      config: this._zones[zoneId].config,
      elements: this._zones[zoneId]?.elements || {}
    })
  })
}

export const store: Store = new Store()
