import styles from "./text-error.module.css";

interface TextErrorProps extends React.HTMLAttributes<HTMLSpanElement>{
    size?:string;
}

function TextError(props:TextErrorProps){
    const spanProps = props as React.HTMLAttributes<HTMLSpanElement>;

    return (
        <span style={{fontSize:props.size}} className={styles.text} {...spanProps}>{props.children}</span>
    )
}

export default TextError;