import { isEmpty } from "lodash";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { useEffect, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { businessRulesEngine } from "features/business-rules-engine";
import Reactor from "module/reactor/Reactor";
import { dispatcher, selector, store } from "store";
import { synchroiser } from "synchroiser";

import { NotFoundPage } from "pages/error-page/error-page";
import { LoadingState } from "types/entity";
import { DetailCard } from "./pages/detail-card/detail-card";
import { Loader } from "pages/single-page/components";

import styles from "./section-card.module.scss";

//TODO доделать отображение согласно координатам и действие "назад"
export const SectionCard = observer(() => {
	const param = useParams<{ entityName: string; id: string }>();
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		navigate(".", { state: null, replace: true });
		const loadRowAsync = async () => {
			if (param) {
				await synchroiser.getRow(param.entityName!, param.id!);
			}
		};
		loadRowAsync();
		return () => {
			businessRulesEngine.dispose();
		};
	}, [location.pathname]);

	useEffect(() => {
		if (
			!location.state &&
			param &&
			param.entityName !== dispatcher.entity.get()?.entityName &&
			synchroiser.loadingState != LoadingState.Loading
		) {
			dispatcher.fullView.switchEntityAndRow(param.entityName!, param.id!);
		}
	}, [location.state, location.pathname, synchroiser.loadingState]);

	const sectionWizzard = useMemo(
		() => dispatcher.entity.get()?.entity.sectionWizzard,
		[toJS(dispatcher.entity.get()?.entity.sectionWizzard), store.currentEntityId]
	);

	const xmlParser = useMemo(() => {
		if (dispatcher.entity.get()?.entityName.toLowerCase() === param.entityName?.toLowerCase()) {
			return selector.fullView.getFullViewXml(businessRulesEngine.virtualSectionWizzard);
		}
		return "";
	}, [toJS(dispatcher.entity.get()?.entityName), param, toJS(businessRulesEngine.virtualSectionWizzard)]);

	if (location.state?.isDetail) {
		return <DetailCard />;
	}

	/* TODO мб сохранить первоначальный 
    результат парсера в reactorPage у sectionWizzard, отбражать его, потом сравнить пришедший и старый, 
    чтобы скелетон не скакал при переходе из детали обратно в запись раздела */
	if (
		(!location.state?.isDetail && !sectionWizzard && synchroiser.loadingState == LoadingState.Loading) ||
		synchroiser.loadingState == LoadingState.Loading ||
		isEmpty(xmlParser)
	) {
		return (
			<div className={styles.contentLoader}>
				<Loader />
			</div>
		);
	}

	if (synchroiser.loadingState == LoadingState.Error) {
		return <NotFoundPage />;
	}

	return <Reactor xml={xmlParser} />;
});
