import { useCallback, useState } from "react";
import classNames from "classnames";

import { ModalType } from "./modal-viewer";

import styles from "./modal-viewer.module.scss";


interface IPopup extends React.HTMLAttributes<HTMLElement> {
	closeFunc?: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
	type: ModalType
}

export function Popup(props: IPopup) {

	const popupClasses = classNames({
		[`${styles.popup}`]: props.type === 'POPUP',
		[`${styles.largePopup}`]: props.type === 'LARGE_POPUP',
		[`${styles.quickViewPopup}`]: props.type === 'QUICK_VIEW',
	});

	const handleClose = useCallback((e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		if (props.closeFunc) {
			props.closeFunc(e);
		}
	}, [props.closeFunc]);

	return (
		<div className={styles.popupWrapper}>
			<div className={styles.backdrop} onClick={handleClose} />
			<div className={popupClasses} >
				{props.children}
			</div>
		</div>

	);
};
