const LIVE_TIME = 5000;

class TemplateStore {
	private items: Map<string, unknown> = new Map();

	set(key: string, value: unknown, liveTime?: number) {
		const item = this.items.get(key);
		const live = liveTime ?? LIVE_TIME;

		this.items.set(key, value);

		setTimeout(() => {
			if (this.items.has(key)) {
				this.items.delete(key);
			}
		}, live);
	}

	get<T>(key: string): T | undefined {
		const value = this.items.get(key);
		if (value)
			setTimeout(() => {
				if (this.items.has(key)) this.items.delete(key);
			}, 100);
		return value as T | undefined;
	}
}

export const templateStore = new TemplateStore();
