import { Dispatch, Reducer, createContext, useContext, useReducer } from "react";
import { isNull } from "lodash";

export type CommentInput = {
    text: string;
    commentId?: string;
    currentCommentText?: string;
}

type CommentAction = { comment: CommentInput }

export type CommentInputContextType = {
    comment: CommentInput;
    updateCommentIdAndText: Dispatch<CommentAction>;
    customUpdate?: (value: string) => void
}

export const CommentContext = createContext<CommentInputContextType | null>(null);

const updateCommentIdAndTextDispatch: Reducer<CommentInput, CommentAction> = (comment: CommentInput, action: CommentAction) => {
    return {
        text: action.comment.text,
        commentId: action.comment.commentId
    }
}

export const useCommentContext = () => {
    const context = useContext(CommentContext);
    if (isNull(context)) {
        throw new Error('useCommentContext must be used within a CommentProvider')
    }
    return context;
}

export const CommentProvider = (props: { children: JSX.Element | JSX.Element[],  customUpdate?:(value:string)=>void}) => {
    const [state, dispatch] = useReducer(updateCommentIdAndTextDispatch, { text: '' })

    const value = { comment: state, updateCommentIdAndText: dispatch, customUpdate:props.customUpdate }
    return <CommentContext.Provider value={value}>{props.children}</CommentContext.Provider>
}
