import { Api } from "shared/api/class-api";

class Comment extends Api {
	basicPath: string;
	constructor() {
		super();
		this.basicPath = "/api/Comment";
	}

	favoriteById = (id: string) => {
		this.setPath(`${this.basicPath}/favorite/${id}`);
		return this;
	};
	favoritesByUserId = (userId: string, sectionId?: string) => {
		if (sectionId) {
			this.setPath(`${this.basicPath}/favorites${userId}/${sectionId}`);
		} else {
			this.setPath(`${this.basicPath}/favorites/${userId}`);
		}
		return this;
	};
	getComments = (entityName?: string, entityId?: string, commentId?: string) => {
		if (entityName && entityId) {
			this.setPath(`${this.basicPath}/comments/${entityName}/${entityId}`);
		} else if (commentId) {
			this.setPath(`${this.basicPath}/comments/${commentId}`);
		}
		return this;
	};

	sendComments = (commentId?: string) => {
		if (commentId) {
			this.setPath(`${this.basicPath}/comments/${commentId}`);
		} else {
			this.setPath(`${this.basicPath}/comments`);
		}
		return this;
	};

	commentById = (commentId: string) => {
		this.setPath(`${this.basicPath}/comments?Id=${commentId}`);

		return this;
	};
}

const comment = new Comment();

export { comment };
