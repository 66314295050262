import styles from "./text.module.css";

interface TextProps extends React.HTMLAttributes<HTMLSpanElement> {
    size?: string;
    bold?: boolean;
}

function Text(props: TextProps) {
    const spanProps = props as React.HTMLAttributes<HTMLSpanElement>;

    let className = styles.text;
    if (props.bold) {
        className += " " + styles.blod;
    }

    return (
        <span style={{ fontSize: props.size }} className={className} {...spanProps}>{props.children}</span>
    )
}

export default Text;