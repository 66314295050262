import { Pictures404 } from "shared";

import styles from "./error-page.module.scss";

export const NotFoundPage = (props: { [key: string]: any }) => {
	const errorHeader = "Cтраница не найдена";
	const errorDescription =
		"Это означает, что запрашиваемая вами страница не существует или была перемещена.\nПроверьте правильность введенного адреса или воспользуйтесь поиском.";
	return (
		<div className={styles.errorPageWrapper}>
			<Pictures404 />
			<div className={styles.errorHeader}>{errorHeader}</div>
			<div className={styles.errorDescription}>{errorDescription}</div>
		</div>
	);
};
