import { v4 } from "uuid";
import { Item } from "types";
import { DeleteIcon } from "shared";
import { useCallback } from "react";
import DetailEntitiesStore from "../core/detail-entities-store";

export enum DetailActionsTypes {
	Export = "ExportDetailAction",
	Customize = "CustomizeDetailAction"
}

export const DetailHeaderActions: Array<Item> = [
	{
		id: DetailActionsTypes.Export,
		name: "Экспортировать записи"
	},
	{
		id: DetailActionsTypes.Customize,
		name: "Настроить деталь"
	}
];

export type DetailBodyType = {
	detailEntitiesStore: DetailEntitiesStore;
	entityName: string;
	entityTitle: string;
	openExportBlock: (value: boolean) => void;
	viewColumnId?: string;
};
