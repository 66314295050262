import { IContextMenuOption } from "components/grid/data/data";
import { observer } from "mobx-react";

import styles from "./context-menu-element.module.scss";
import { Icon } from "sale-bridge-ui-kit";

export const ContextMenuElement = observer((props: { element: IContextMenuOption, handleClick: (element: any) => void, index?: number }) => {
	return (
		<div key={props.index} className={styles.contextMenuElement} onClick={() => props.handleClick(props.element)} >
			<Icon name={props.element.iconName} size={props.element.size} />
			{props.element.caption}
		</div>
	);
});