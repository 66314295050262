import React, { CSSProperties, MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { store } from "../model/store";
import { Id } from "react-beautiful-dnd";
import { observer } from "mobx-react-lite";

type Prop = {
    children: JSX.Element;
	setElemCenter: (value: {x: number, y: number}) => void;
	selectedElementId: string | null;
	setHasMove: (value: boolean) => void;
	setSelectedElementId: (value: string) => void;
	width: number;
	height: number;
	setElementSize: (value: {width: number, height: number}) => void;
	predictLayout?: JSX.Element | null;
	onUp?: ({ elementId }: {elementId: string}) => void;
}

const InnerElement = observer((props: Prop) =>  {
	const [canMove, setCanMove] = useState<boolean>(false);
	const [styles, setStyles] = useState<CSSProperties>({});

	const id = useMemo(() => props.children.props.id, [props.children.props.id]);
	
	const container = useRef<HTMLInputElement>(null);

	/**
     * @description Получение статуса, возможно ли поднятие карточек/elements
     */
	useEffect(() => {
		setCanMove(props.selectedElementId === id);
		setStyles({});
	}, [id, props.selectedElementId]);

	/**
     * @description Стили карточек/elements для перемещения
     */

	useEffect(() => {
		if (!props.selectedElementId) {
			setStyles({});
			setCanMove(false);
		}
	}, [props.selectedElementId]);

	const handleMouseDown = useCallback((event: MouseEvent) => {
		const idTargetCellElement = store._maskCells.sort((a, b) => {
			if (a.endY - a.startY > b.endY - b.startY) {
				return 1;
			} else if (a.endY - a.startY < b.endY - b.startY) {
				return -1;
			} else {
				return 0;
			}}).find((mask) => {
			if (
				mask.startX < event.pageX && event.pageX < mask.endX
				&&
				mask.startY < event.pageY && event.pageY < mask.endY
			) {
				return true;
			}
		})?.id;
		if (idTargetCellElement === props.children.props.id) {
			setCanMove(true);
			props.onUp?.({ elementId: props.children.props.id });
		}
	}, [props]);

	const handleMouseMove = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
		if (canMove && !props.selectedElementId) {
			props.setHasMove(true);
			props.setSelectedElementId(id);
			props.setElementSize({ width: props.children.props.width, height: props.children.props.height });
			store.setPredictLayout(props.predictLayout ?? null);
		}
	}, [canMove, id, props]);

	const handleMouseUp = useCallback(() => {
		setCanMove(false);
	}, []);

	return (
		<div
			ref={container}
			style={styles}
			onMouseDown={handleMouseDown}
			onMouseMove={handleMouseMove}
			onMouseUp={handleMouseUp}
		>
			{props.children}
		</div>
	);
});

export { InnerElement };