
import { ColumnType } from "entities/ColumnType";
import { Item } from "types";
import { NumeratorMaskItem } from "types/entity";

export type AutoFieldByTypeProps = {
    onChange: (value: string | Item) => void,
    viewColumnName: string | null,
    columnId: string | undefined,
    columnValue: any,
    columnType: ColumnType | undefined,
    lookupTable: string | undefined | null,
    isVirtualLookup: boolean,
    virtualLookupValues: Item[]
}

export type EditableConditionType = {
    id: string,
    name: string,
    column: ConditionColumnType | null,
    columnValue: any,
    mask: NumeratorMaskItem | null,
}

export const AllowedTypes = [ColumnType.String, ColumnType.Boolean, ColumnType.Lookup];

export type ConditionColumnType = {
    id: string,
    columnTitle: string,
    columnName: string,
    columnType: ColumnType,
    lookupTable: string | null,
    isVirtualLookup: boolean,
    virtualLookupValues: Item[]
}


export function getFieldTitle(nameField: string) {
    switch (nameField) {
        case 'name': {
            return 'Название условия';
        }
        case 'columnName': {
            return 'Если';
        }
        case 'columnValue': {
            return 'Равно';
        }
        case 'mask': {
            return 'То применить';
        }
    }
}

