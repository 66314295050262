import { observer } from "mobx-react-lite";
import { useCallback, useMemo } from "react";
import classNames from "classnames";
import { Button, ButtonDropdown } from "sale-bridge-ui-kit";

import { BlockCondition } from "../block-condition";

import { IFilterStore } from "entities/filter/FilterStore";
import { FilterType, LogicalOperation } from "entities/filter/IFilter";
import { StandartItemGroup } from "sale-bridge-ui-kit/dist/components/button-dropdown/button-dropdown";
import {
	businessRuleEditorStore,
	BusinessRuleEditorValidation
} from "pages/section-wizzard/pages/business-rules/business-rule-editor-store";

import styles from "./block-group.module.scss";

function getDisplayValue(operation: LogicalOperation) {
	if (operation === LogicalOperation.And) {
		return "И";
	}
	return "ИЛИ";
}

export const BlockGroup = observer(function (props: {
	filter: IFilterStore;
	depth: number;
	initDropdownItems: (filter: IFilterStore) => void;
	dropdownItems: StandartItemGroup[];
	onClickDropDown: (id: string, filter: IFilterStore) => void;
	getValidation?: (id: string) => BusinessRuleEditorValidation | undefined;
}) {
	const conditionGroupClassNames = classNames(styles.conditionGroup, {
		[styles.nestedGroup]: props.depth > 0,
		[styles.nestedGroupMinWidth]: props.depth == 1
	});

	const addDropdown = useMemo((): Array<StandartItemGroup> => {
		return [
			{
				header: "",
				items: [
					{
						id: "addGroup",
						name: "Группа условий",
						icon: "AddGroup",
						iconSize: "16"
					},
					{
						id: "addCondition",
						name: "Условие",
						icon: "Add",
						iconSize: "16"
					}
				]
			}
		];
	}, []);

	const handleClickToDropdown = useCallback(
		(id: string) => {
			if (id === "addGroup") {
				props.filter.addGroup();
				props.filter.filters
					.slice()
					.reverse()
					.find((filter: IFilterStore) => filter.type == FilterType.Group)
					?.addFilter();
				return;
			}
			if (id === "addCondition") {
				props.filter.addFilter();
				return;
			}
		},
		[props.filter]
	);

	return (
		<div className={conditionGroupClassNames}>
			{props.filter.filters.map((item, index) => (
				<>
					<div className={styles.conditionGroupRules}>
						{index == 0 && (
							<div className={styles.conditionGroupOperand}>
								<Button
									text={getDisplayValue(props.filter.logicalOperation)}
									size="small"
									variant="default"
									border
									link={false}
									loading={false}
									onClick={() => {
										props.filter.changeLogicalOperation();
									}}
									isFillParent
								/>
							</div>
						)}

						<BlockCondition
							key={item.id}
							filter={item}
							logicalOperation={index == 0 ? null : getDisplayValue(props.filter.logicalOperation)}
							initDropdownItems={props.initDropdownItems}
							dropdownItems={props.dropdownItems}
							handleChangeDropDown={props.onClickDropDown}
							validation={props.getValidation?.(item.id)}
							getValidation={props.getValidation}
							setConditionField={(filter, value) => {
								businessRuleEditorStore.setConditionField(filter, value);
							}}
							setConditionComparator={(filter, value) => {
								businessRuleEditorStore.setConditionComparator(filter, value);
							}}
							setRightExpression={(filter, value) => {
								businessRuleEditorStore.setRightExpression(filter, value);
							}}
						/>
					</div>
				</>
			))}

			{props.depth == 2 ? (
				<Button
					text="Добавить условие"
					onClick={() => props.filter.addFilter()}
					size="small"
					variant="backless"
					leftIcon="Add"
					link={false}
					loading={false}
					border={false}
				/>
			) : (
				<ButtonDropdown
					size="small"
					position="down"
					standartItems={addDropdown}
					onClickStandartItem={handleClickToDropdown}
					childrenButton={
						<Button
							text="Добавить условие"
							onClick={() => {}}
							size="small"
							variant="backless"
							leftIcon="Add"
							rightIcon="Dropdown"
							link={false}
							loading={false}
							border={false}
						/>
					}
				/>
			)}
		</div>
	);
});
