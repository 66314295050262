import { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";

import { v4 } from "uuid";
import { lowerCase } from "lodash";

import detailMasterState from "features/detail-master/detail-master-state";
import detailFieldConfigurationPopupState, { FieldStoreEnums } from "./detail-field-configuration-popup-state";
import {
	validateIsNumberAndRequired,
	validateRequired,
} from "entities/Validation";

import { Field } from "sale-bridge-ui-kit";
import { BasicSettings, LookupConfiguration, CheckboxesBlock } from "./components";

import FilterColumnType, { ColumnType } from "entities/ColumnType";
import { ColumnSpecializationType } from "types/entity";
import IFilter, { ComparisonType, DataValueType } from "entities/filter/IFilter";
import { ILookup } from "entities/Entity";
import { ILookupData } from "pages/settings/data/Fields";
import { LookupType } from "pages/section-wizzard/data/data";
import { getInfoTitle } from "pages/section-wizzard/pages/constructor/configurations/field-configuration/types";

import styles from "./detail-field-configuration-popup.module.scss";

interface DetailFieldConfigurationBodyProps {
	fieldType?: ColumnType;
	columnId?: string;
}

export const DetailFieldConfigurationBody = observer((props: DetailFieldConfigurationBodyProps) => {

	useEffect(() => {
		if (props.fieldType) {
			detailFieldConfigurationPopupState.setValueWithoutTrackingChanges(FieldStoreEnums.fieldType, props.fieldType);
		}
		if (props.columnId) {
			detailFieldConfigurationPopupState.getConfigurationById(props.columnId, detailMasterState.gridItems);
		}
		else {
			detailFieldConfigurationPopupState.setValueWithoutTrackingChanges(FieldStoreEnums.columnId, v4());
		}
	}, []);

	const configurationContent = useMemo(() => {
		return (
			<>
				{detailFieldConfigurationPopupState.lookupType === LookupType.NewLookup ?
					<LookupConfiguration /> : <DefaultConfiguration />
				}
			</>
		);
	}, [detailFieldConfigurationPopupState.lookupType]);

	return configurationContent;
});

const DefaultConfiguration = observer(() => {
	const [defValues, setDefValues] = useState<ILookup[]>([]);
	const [searchValue, setSearchValue] = useState('');
	const [searchValueDefault, setSearchValueDefault] = useState('');

	const fieldTypeIsLookup = useMemo(() => {
		return detailFieldConfigurationPopupState.fieldType === ColumnType.Lookup;
	}, [detailFieldConfigurationPopupState.fieldType]);

	const fieldTypeIsDecemal = useMemo(() => {
		return detailFieldConfigurationPopupState.fieldType === ColumnType.Decimal;
	}, [detailFieldConfigurationPopupState.fieldType]);

	const handleLookupValuesLoad = useCallback(async (value: string | null) => {
		try {
			const items = await detailFieldConfigurationPopupState.loadLookups();
			if (items) {
				const newItems: ILookupData[] = [];
				items.filter((lookup: any) => {
					const entityTitle = lowerCase(lookup.entityTitle);
					if (entityTitle.includes(lowerCase(value!)))
						newItems.push(lookup);
				});
				detailFieldConfigurationPopupState.setLookups(newItems);
				return newItems.length
			}
			else return -1
		}
		catch (error) {
			console.error(error)
		}
	}, []);

	useEffect(() => {
		async function load() {
			await handleLookupValuesLoad(null);
		}
		if (detailFieldConfigurationPopupState.fieldType === ColumnType.Lookup) {
			load()
		}
	}, []);

	const handleRoundingChange = useCallback((value: string) => {
		detailFieldConfigurationPopupState.setValue(FieldStoreEnums.rounding, value);
		detailFieldConfigurationPopupState.setSpecialization(FieldStoreEnums.specializations, ColumnSpecializationType.Double, { "rounding": value });
		validateIsNumberAndRequired(
			detailFieldConfigurationPopupState.rounding,
			detailFieldConfigurationPopupState.validation.rounding,
		);
	}, []);

	const handleLookupChange = useCallback(async (value: any) => {
		detailFieldConfigurationPopupState.setValue(FieldStoreEnums.selectedLookup, value);
		//Не подгружаем значения для разделов
		if (detailFieldConfigurationPopupState.selectedLookup?.entityName && !detailFieldConfigurationPopupState.selectedLookup.isSection) {
			await handleDefaultValuesLoad(null);
		}

		detailFieldConfigurationPopupState.setValue(FieldStoreEnums.selectedLookupDefaultValue, null);
		validateRequired(
			detailFieldConfigurationPopupState.selectedLookup,
			detailFieldConfigurationPopupState.validation.lookup,
			true
		);
	}, []);

	const handleLookupDefaultValueChange = useCallback(async (value: any) => {
		detailFieldConfigurationPopupState.setValue(FieldStoreEnums.selectedLookupDefaultValue, value);
		if (value !== null) {
			detailFieldConfigurationPopupState.setValue(FieldStoreEnums.defaultValue, detailFieldConfigurationPopupState.selectedLookupDefaultValue!.id);
		} else {
			detailFieldConfigurationPopupState.setValue(FieldStoreEnums.defaultValue, value);
		}
	}, []);

	const handleDefaultValueChange = useCallback((value: string) => {
		detailFieldConfigurationPopupState.setValue(FieldStoreEnums.defaultValue, value);
		detailFieldConfigurationPopupState.validateDefaultValue();
	}, []);

	const roundingField = fieldTypeIsDecemal && <div className={styles.roundingField}>
		<Field
			type='number'
			size='small'
			label='Количество возможных знаков после запятой'
			labelPosition="vertical"
			textVariant='outlined'
			subType="integer"
			placeholder="0"
			info='Столько знаков после запятой сможет ввести пользователь'
			value={detailFieldConfigurationPopupState.specializations.properties["rounding"] ?? ''}
			onChange={(value: string) => {
				handleRoundingChange(value);
			}}
			alert={detailFieldConfigurationPopupState.validation.rounding.isInvalid ? 'error' : undefined}
		/>
		<span className={styles.infoRoundingField}>Максимальное значение: 15</span>
	</div>;

	const lookupItems = useMemo(() => {		
		return [
			{
				layout: <></>,
				items: detailFieldConfigurationPopupState.lookups
				.filter(lookup =>
                    lookup.entityTitle?.toLowerCase().includes(searchValue?.toLowerCase())
                )
				.map(item =>
					<div className={styles.selectItem} onClick={() => handleLookupChange(item)}>
						<span>{item.entityTitle}</span>
					</div>
				)
			}
		]
	}, [toJS(detailFieldConfigurationPopupState.lookups), searchValue]);

	const lookupField = useMemo(() => {
		if (fieldTypeIsLookup) {
			return (
				<Field
					type='select'
					size='small'
					label='Объект'
					labelPosition="vertical"
					textVariant='outlined'
					info='Список всех справочников и разделов в системе'
					value={detailFieldConfigurationPopupState.selectedLookup?.entityTitle ?? ''}
					items={lookupItems}
					onChange={() => { }}
					alert={detailFieldConfigurationPopupState.validation.lookup.isInvalid ? 'error' : undefined}
					searchValue={searchValue}
					onChangeSearchValue={setSearchValue}
				/>
			);
		}
		return null;
	}, [fieldTypeIsLookup, detailFieldConfigurationPopupState.selectedLookup, detailFieldConfigurationPopupState.lookups, searchValue]);


	const handleDefaultValuesLoad = useCallback(async (value: string | null) => {
		let filter: IFilter | null = null;
		if (value && detailFieldConfigurationPopupState.selectedLookup) {
			filter = {
				schema: detailFieldConfigurationPopupState.selectedLookup.entityName,
				isEnabled: true,
				type: 1,
				comparisonType: ComparisonType.Contain,
				attribute: "Name",
				attributeType: FilterColumnType.String,
				rightExpression: {
					type: 0,
					parameter: {
						dataValueType: DataValueType.Text,
						value: value
					}
				},
				filters: [],
				logicalOperation: 0,
				detail: ""
			};
		}
		try {
			if (detailFieldConfigurationPopupState.selectedLookup) {
				const items = await detailFieldConfigurationPopupState.loadLookupData(detailFieldConfigurationPopupState.selectedLookup.entityName, filter);
				if (items) {
					setDefValues(items);
					return items.length
				}
				else return -1
			}
		}
		catch (error) {
			console.error(error)
		}
	}, [defValues, detailFieldConfigurationPopupState.selectedLookup]);

	const defaultValItems = useMemo(() => {
		return [
			{
				layout: <></>,
				items: defValues.map(item =>
					<div className={styles.selectItem} onClick={() => handleLookupDefaultValueChange(item)}>
						<span>{item.name}</span>
					</div>
				)
			}
		]
	}, [toJS(defValues)]);

	const defaultValueField = useMemo(() => {
		return fieldTypeIsLookup &&
			detailFieldConfigurationPopupState.fieldType !== ColumnType.DateTime &&
			!detailFieldConfigurationPopupState.selectedLookup?.isSection &&
			(
				<Field
					type='select'
					size='small'
					label='Значение по умолчанию'
					labelPosition="vertical"
					textVariant='outlined'
					info='Значение, которым заполнится поле при создании записи'
					value={detailFieldConfigurationPopupState.selectedLookupDefaultValue?.name ?? ''}
					items={defaultValItems}
					onChange={() => { }}
					searchValue={searchValueDefault}
					onChangeSearchValue={setSearchValueDefault}
				/>
			);
	}, [fieldTypeIsLookup, detailFieldConfigurationPopupState.fieldType, detailFieldConfigurationPopupState.selectedLookup,
		toJS(defaultValItems), detailFieldConfigurationPopupState.selectedLookupDefaultValue, searchValueDefault]);

	const defaultValueFieldNonLookup = useMemo(() => {
		if (!fieldTypeIsLookup &&
			detailFieldConfigurationPopupState.fieldType !== ColumnType.DateTime &&
			detailFieldConfigurationPopupState.fieldType !== ColumnType.Date &&
			detailFieldConfigurationPopupState.fieldType !== ColumnType.Time &&
			detailFieldConfigurationPopupState.fieldType !== ColumnType.Boolean
		) {
			return (
				<Field
					type='text'
					size='small'
					label='Значение по умолчанию'
					labelPosition="vertical"
					textVariant='outlined'
					info='Значение, которым заполнится поле при создании записи'
					value={detailFieldConfigurationPopupState.defaultValue}
					onChange={handleDefaultValueChange}
					alert={detailFieldConfigurationPopupState.validation.defaultValue.isInvalid ? 'error' : undefined}
				/>
			);
		}
		return null;
	}, [fieldTypeIsLookup, detailFieldConfigurationPopupState.fieldType, detailFieldConfigurationPopupState.defaultValue,
		detailFieldConfigurationPopupState.validation.defaultValue.isInvalid]);

	return (
		<>
			{getInfoTitle()}
			<div className={styles.fieldsBlock}>
				<BasicSettings />
				{roundingField}
				{lookupField}
				{defaultValueField}
				{defaultValueFieldNonLookup}
				<Field
					type='text'
					size='small'
					label='Подсказка'
					labelPosition="vertical"
					textVariant='outlined'
					info='То, что вы читаете прямо сейчас 🙂'
					value={detailFieldConfigurationPopupState.prompt}
					onChange={(value: string) => {
						detailFieldConfigurationPopupState.setValue(FieldStoreEnums.prompt, value);
					}}
				/>
				<CheckboxesBlock />
			</div>
		</>
	);
});




