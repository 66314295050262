import { Route, Routes } from "react-router-dom";
import { observer } from "mobx-react-lite";

import authStore from "AuthStore";

import { privateRoutes, publicRoutes } from "./routes";

export const AppRouter = observer(() => {
	return authStore.loggedIn ? (
		<Routes>
			{privateRoutes.map((route, index) => (
				<Route key={index} path={route.path} element={route.component} />
			))}
		</Routes>
	) : (
		<Routes>
			{publicRoutes.map((route, index) => (
				<Route key={index} path={route.path} element={route.component} />
			))}
		</Routes>
	);
});
