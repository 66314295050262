import ISort from "entities/ISort";
import { action } from "mobx";
import { Entity, store } from "store";
import { BasicEntity } from "types";
import { TabsConfig } from "types/entity";

export class EntityController {
	//TODO сделать методы action, чтобы изменть observable переменные

	set = (entity: Entity) => {
		const entityWithStore = store.entities?.find((oldEntity: Entity) => oldEntity?.id === entity.id);
		if (entityWithStore) {
			action("setting entity", () => {
				store.entities = store.entities.map((oldEntity: Entity) => oldEntity.id === entity.id ? entity : oldEntity);
			})();
		} else {
			action("add entity", () => {
				store.entities.push(entity);
			})();
		}
	};

	setIsNotNew = action("set isNew to false", () => {
		if (this.get()?.isNew) Reflect.set(this.get()!, "isNew", false);
	});

	setAll = action("set All entity", (entities: Array<Entity>) => {
		store.entities = entities;
	});

	switchById = (entityId: string) => {
		action("setting current entity id", () => {
			store.currentEntityId = entityId;
		})();
	};

	switchSectionByName = (name: string) => {
		action("setting current entity id", () => {
			store.currentEntityId = store.sections.find((section: any) => section.entityName.toLowerCase() === name.toLowerCase())?.id;
		})()
	};

	switchEntityByName = (name: string) => {
		action("switchEntityByName", () => {
		store.currentEntityId = store.entities.find((entity: any) => entity.entityName.toLowerCase() === name.toLowerCase())?.id;
	})()
};

	get = () => {
		return store.entities?.find((entity: any) => entity.id === store.currentEntityId);
	};

	getSection = () => {
		return store.sections?.find((entity: any) => entity.id === store.currentEntityId);
	};


	getGridItemsFromConfig = (tabName: string) => {
		return store.entities?.find((entity: any) => entity.id === store.currentEntityId)
			?.entity.sectionWizzard
			?.reactorConfig.tabs.tabsConfig
			.find(tab => tab.tabName === tabName)?.grid.items;
	};

	getAdditionalFromConfig = () => {
		return store.entities?.find((entity: any) => entity.id === store.currentEntityId)
			?.entity.sectionWizzard?.reactorConfig.tabs.additionalPanel;
	};

	setTabsToTabsConfig = (tabs: TabsConfig[]) => {
		if (this.get()?.entity.sectionWizzard?.reactorConfig.tabs)
			Reflect.set(this.get()?.entity.sectionWizzard?.reactorConfig.tabs!, "tabsConfig", tabs);
	};

	setBasicEntity = (entity: BasicEntity) => {
		const currentEntity = this.get();
		if (currentEntity?.entity) {
			action("setting basic entity", () => {
				currentEntity.entity = entity;
            })();
        }
	};

	setVisibleColumnWidth = action((columnId: string, width: number) => {
		const column = this.get()?.entity.visibleColumns.find(column => column.columnId === columnId);
		const sectionViewPageColumn = this.get()?.entity.sectionViewPageSettings?.columnSettings.find(column => column.columnId === columnId);
		if (column) {
			column.spanX = width;
		}
		if (sectionViewPageColumn) {
			sectionViewPageColumn.spanX = width;
		}
	});

	@action setSort = (sort: ISort | null) => {		
		const entity = this.get()?.entity;
		if (entity) {
			entity.sort = sort;
		}
	}

	onChangeChecked = action((check: boolean, row: any, entity?: BasicEntity) => {
		const result: Array<any> = [];
		const currentEntity = entity ?? this.get()?.entity;
		if (!currentEntity) return;
		const list = currentEntity.isCheckedAll ? currentEntity.excludedIds : currentEntity.includedIds;

		if (list.length > 0) {
			list.forEach(item => {
				if (item !== row && item.id !== row.id)
					result.push(item);
			});
		}
		if (check && !currentEntity.isCheckedAll) {
			result.push(row);
		}

		if (!check && currentEntity.isCheckedAll) {
			result.push(row);
			currentEntity.countOfChecked = (currentEntity.rows.length - result.length);
		}
		else {
			currentEntity.countOfChecked = result.length;
		}

		if (currentEntity.isCheckedAll) {
			currentEntity.excludedIds = result;
		}
		else {
			currentEntity.includedIds = result;
		}
	});

	onChangeCheckedAll = action((check: boolean, entity?: BasicEntity) => {
		const currentEntity = entity ?? this.get()?.entity;
		if (!currentEntity) return;
		currentEntity.isCheckedAll = check;
		currentEntity.excludedIds = [];
		currentEntity.includedIds = [];
		if (check) currentEntity.countOfChecked = currentEntity.rows.length;
		else currentEntity.countOfChecked = 0;
	});
}

export const entityController = new EntityController();
