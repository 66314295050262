import IProps from "../../IProps";

interface GridProps extends IProps {
    gap?: string;
    rowGap?: string;
    columnGap?: string;
    columns?: string;
    rows?: string;
    height?: string;
    alignItems?: "center" | "flex-end" | "flex-start";
    padding?: string;
}

function Grid(props: GridProps) {
    return (
        <div style={{
            gap: props.gap + "px",
            rowGap: props.rowGap + "px",
            columnGap: props.columnGap + "px",
            gridTemplateColumns: props.columns,
            gridTemplateRows: props.rows,
            display: "grid",
            height: props.height ?? '100%',
            alignItems: props.alignItems,
            padding: props.padding
        }}>
            {props.children}
        </div>
    )
}

export default Grid;