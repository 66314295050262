import { bindingInXml, binding } from "module/reactor/binding/binding";
import IProps from "../../IProps";

import styles from "./Button.module.css";

interface ButtonProps extends IProps {
    click?: string;
    style?: string;
}
export enum ButtonStyle {
    Primary = "primary",
    Subtle = "subtle",
    Danger = "danger",
}



function Button(props: ButtonProps) {
    const value = bindingInXml(props.services, props.value);

    function getStyle() {
        switch (props.style) {
            case (ButtonStyle.Danger): {
                return styles.danger
            }
            case (ButtonStyle.Subtle): {
                return styles.subtle
            }
            default: {
                return styles.primary
            }
        }

    }

    const className = styles.button + " " + getStyle() + " " + props.className;

    return (
        <button className={className} onClick={binding(props.services, props.click)}>{value ?? props.children}</button>
    )
}

export default Button;