import { isArray } from "lodash";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo, useState } from "react";

import { Entity, dispatcher } from "store";

import { Button, ButtonStyle } from "components";
import { MainInfo, SectionDisplayModes, SpecialFeatures, Stages } from "./components";

import styles from "./global-settings.module.scss";

export const HeadFieldsBlock = (props: { caption: string }) => {
	return (
		<span className={styles.headerBlockFields}>{props.caption}</span>
	);
};

export const FieldIsland = observer((props: { children: JSX.Element | JSX.Element[], fieldInfo?: string }) => {

	const body = useMemo(() => isArray(props.children) ?
		<>
			{props.children.map((child, index) => index < ((props.children as JSX.Element[]).length - 1) ? [child, <div key={index} className={styles.fieldIslandDivider} />] : child).flat()}
		</>
		: <>{props.children}</>
		, [props.children])

	return (
		<div className={styles.fieldIsland}>
			<div className={styles.fieldIslandBody}>
				{body}
			</div>
			{props.fieldInfo && <span className={styles.fieldInfo}>{props.fieldInfo}</span>}
		</div>
	);
});

//TODO в новом дизайне ее нет, пока оставляю на будущее
const ExistingSectionSettings = observer(() => {

	//TODO дописать метод для кнопки "выбрать раздел"
	const chooseSection = useCallback(() => {

	}, []);

	return (
		<div className={styles.blockSettings}>
			<HeadFieldsBlock caption="Настройка по существующему разделу" />
			<div className={styles.fiedsBlock}>
				<span className={styles.fieldCaption}>Создайте новый раздел по образу уже существующего в системе.</span>
				<Button
					caption="Выбрать раздел..."
					className={styles.chooseSectionButton}
					style={ButtonStyle.LightBlue}
					onClick={chooseSection}
				/>
			</div>
		</div >
	);
});

export const GlobalSettingsPanel = observer((props: {
	checkWarningForSetting: () => void,
	createPriorityLookup: () => void,
	deletePriorityLookup: () => void,
	createPositionField: () => void,
	deletePositionField: () => void
}) => {
	const [entity, setEntity] = useState<Entity>();

	useEffect(() => {
		const dispEntity = dispatcher.entity.get();
		setEntity(dispEntity);
	}, [dispatcher.entity.get()]);

	if (entity?.entity?.sectionWizzard)
		return (
			<div className={styles.wrapper}>
				<MainInfo checkWarningForSetting={props.checkWarningForSetting} />
				<Stages
					createPriorityLookup={props.createPriorityLookup}
					deletePriorityLookup={props.deletePriorityLookup}
					createPositionField={props.createPositionField}
					deletePositionField={props.deletePositionField}
				/>
				<SectionDisplayModes />
				<SpecialFeatures />
			</div >
		);
	else return <></>;
});