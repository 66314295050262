import { observer } from "mobx-react-lite";
import { Field } from "sale-bridge-ui-kit";

import { toBoolean } from "shared";

export type Props = {
	columnName: string | undefined;
	columnTitle?: string | undefined;
	prompt?: string | undefined;
	isRequired?: boolean | undefined;
	rounding?: string | undefined;
	multiline?: string | undefined;
	value: string;
	isDisabled?: boolean;
	onChange: (value: string) => void;
	variant: "standart" | "outlined";
	labelPosition?: "horizontal" | "vertical";
	labelVariant?: "black" | "gray";
};

export const String = observer(function (props: Props) {
	if (toBoolean(props.multiline)) {
		return (
			<Field
				type="multiline"
				size="small"
				label={props.columnTitle ?? ""}
				labelVariant={props.labelVariant}
				labelPosition={props.labelPosition ?? "vertical"}
				isRequired={props.isRequired}
				value={props.value ?? ""}
				info={props.prompt}
				onChange={props.onChange}
				isDisabled={props.isDisabled}
				textVariant={props.variant}
				counter={200}
				isClearing
			/>
		);
	} else {
		return (
			<Field
				type="text"
				size="small"
				label={props.columnTitle ?? ""}
				labelPosition={props.labelPosition ?? "vertical"}
				labelVariant={props.labelVariant}
				isRequired={props.isRequired}
				value={props.value ?? ""}
				info={props.prompt}
				onChange={props.onChange}
				isDisabled={props.isDisabled}
				textVariant={props.variant}
			/>
		);
	}
});
