import { action, makeAutoObservable, runInAction } from "mobx";
import axios from "axios";

import { synchroiser } from "synchroiser";
import authStore from "AuthStore";
import { api } from "shared";

import { FieldValidationState } from "types";
import { LoadingState } from "types/entity";
import { ERROR_4XX, ERROR_5XX, USERNAME_OR_PASSWORD_IS_INCORRECT } from "./login-consts";

class LoginState {
	userName: string = "";
	password: string = "";
	userId: string = "";
	needChangePassword: boolean = false;
	errorStatus: string = "";
	loadingState: LoadingState = LoadingState.NotAsked;

	validation: { [key: string]: FieldValidationState };

	constructor() {
		this.validation = {
			username: {
				isInvalid: false,
				error: undefined
			},
			password: {
				isInvalid: false,
				error: undefined
			}
		};
		makeAutoObservable(this, { logIn: action });
	}

	get isInvalid() {
		this.validation = {
			username: {
				isInvalid: false,
				error: undefined
			},
			password: {
				isInvalid: false,
				error: undefined
			}
		};
		if (this.userName.length === 0) {
			this.validation.username.error = "Поле обязательно для заполнения";
			this.validation.username.isInvalid = true;
			return true;
		}

		if (this.password.length === 0) {
			this.validation.password.error = "Поле обязательно для заполнения";
			this.validation.password.isInvalid = true;
			return true;
		}

		return false;
	}

	setPassword = (value: string) => {
		this.password = value;
	};

	setUsername = (value: string) => {
		this.userName = value;
	};

	keyDown = (event: any) => {
		if (event.code === "Enter") {
			this.logIn();
		}
	};

	logIn = async () => {
		try {
			this.loadingState = LoadingState.Loading;
			this.errorStatus = "";
			// const result = await api.post("/api/User/authenticate", {
			//     name: this.username,
			//     password: this.password,
			// });
			const result = await api.http.user.userAuthenticate().post({
				name: this.userName,
				password: this.password
			});

			// TODO Убрать запрос выше( user.userAuthenticate()), ПОСЛЕ ПОЛНОЙ ЗАМЕНЫ НА НОВЫЙ STORE
			await synchroiser.auth(this.userName, this.password);

			const data = result.data;

			if (data.success) {
				this.needChangePassword = data.changePassword;
				if (this.needChangePassword) {
					this.userId = data.id;
					this.loadingState = LoadingState.Successful;
					return;
				}

				const token = data.accessToken;
				const expires = data.expires;
				authStore.logIn(token, expires);
				this.reset();
			} else {
				if (result.status >= 500) {
					runInAction(() => {
						this.errorStatus = ERROR_5XX;
						this.loadingState = LoadingState.Error;
					});
				} else {
					runInAction(() => {
						this.errorStatus = ERROR_4XX;
						this.validation = {
							username: {
								isInvalid: true,
								error: USERNAME_OR_PASSWORD_IS_INCORRECT
							},
							password: {
								isInvalid: true,
								error: USERNAME_OR_PASSWORD_IS_INCORRECT
							}
						};
						this.loadingState = LoadingState.Error;
					});
				}
			}
		} catch (error: any) {
			if (axios.isAxiosError(error)) {
				if (error.response?.status === 400) {
					this.validation = {
						username: {
							isInvalid: true,
							error: USERNAME_OR_PASSWORD_IS_INCORRECT
						},
						password: {
							isInvalid: true,
							error: USERNAME_OR_PASSWORD_IS_INCORRECT
						}
					};
					this.errorStatus = ERROR_4XX;
				} else if (error.response?.status.toString()[0] === "5") {
					this.errorStatus = ERROR_5XX;
				}
				this.loadingState = LoadingState.Error;
			}
		}
	};

	reset() {
		this.password = "";
		this.userName = "";
		this.userId = "";
		this.needChangePassword = false;
		this.errorStatus = "";
		this.loadingState = LoadingState.NotAsked;
		this.validation = {
			username: {
				isInvalid: false,
				error: undefined
			},
			password: {
				isInvalid: false,
				error: undefined
			}
		};
	}
}

export const loginState = new LoginState();
