import { useCallback, useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { v4 } from 'uuid';
import { toJS } from 'mobx';

import { modalController } from 'features/modals';
import fieldConfigurationStore from '../../../field-configuration-store';

import { Button } from 'sale-bridge-ui-kit';
import { CancelModal, MaskElement, MaskSettingsPopup } from './components';
import { EditNumeratorPopup } from '../edit-numerator-popup';

import styles from './masks.module.scss'

export const Masks = observer((props: { isNewNumerator?: boolean }) => {
    const [modalId] = useState<string>(v4());
    const [warningModalId] = useState<string>(v4());

    useEffect(() => {
        fieldConfigurationStore.numeratorConfig.updateMasksState();
    }, []);

    const masks = useMemo(() => {
        return fieldConfigurationStore.numeratorConfig.maskItems;
    }, [toJS(fieldConfigurationStore.numeratorConfig.maskItems)]);

    const closeCancelModalConfirm = useCallback(() => {
        modalController.modalRemove(warningModalId);
    }, [warningModalId]);


    const closeConfirm = useCallback((maskId: string, isNeedDelete?: boolean, e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e?.stopPropagation();
        if (isNeedDelete) {
            const hasChanges = fieldConfigurationStore.numeratorConfig.isMaskChanged(maskId);
            if (hasChanges) {
                modalController.popupAdd({
                    id: warningModalId,
                    layout: <CancelModal closeConfirm={closeCancelModalConfirm} onCancel={() => {
                        fieldConfigurationStore.numeratorConfig.deleteMask(maskId);
                        modalController.modalRemove(modalId);
                    }}
                    />,
                    closeFunc: closeCancelModalConfirm
                });
                return;
            }
            fieldConfigurationStore.numeratorConfig.deleteMask(maskId);
        }
        modalController.modalRemove(modalId);
    }, []);

    const handleAddMask = useCallback(() => {
        const newMaskId = v4();
        const newMask = fieldConfigurationStore.numeratorConfig.getDefaultMask(newMaskId);
        fieldConfigurationStore.numeratorConfig.addMask(newMask);

        modalController.popupAdd({
            id: modalId,
            layout:
                <EditNumeratorPopup close={() => { closeConfirm(newMask.id, true) }} >
                    <MaskSettingsPopup isNew maskId={newMask.id} close={(isNeedDelete) => { closeConfirm(newMask.id, isNeedDelete) }} />
                </EditNumeratorPopup>,
            closeFunc: (e) => { closeConfirm(newMask.id, true, e) }
        });
    }, [modalId]);


    return <div className={styles.maskList}>
        {masks.map(item => <MaskElement key={item.id} item={item} isNewNumerator={props.isNewNumerator} />)}
        <Button
            text='Добавить маску'
            size='small'
            variant='secondary'
            border={false}
            link={false}
            loading={false}
            leftIcon='PlusSquareFill'
            onClick={handleAddMask}
        />
    </div>
})
