import { useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';

import importStore from 'pages/import/core/import-store';

import { FileUpload } from 'features/file-upload';
import SearchSelect from 'components/select/search-select/search-select';
import StatusBar from 'components/status-bar/status-bar';

import {
  BigFileSizeError,
  ChooseOnefileError,
  ext,
  formats,
  HeadTable,
  IncorrectFormatError,
  Timeout,
  TwentyMBInBytes,
} from 'pages/import/data/import-constants';

import styles from './select-data.module.css';

function SelectData() {
  const [error, setError] = useState<boolean>(false);
  let errorMessage = useRef('');

  function set(entity: any) {
    if (entity === null) importStore.setEntity(null);
    else {
      let value = importStore.entities.find((e) => e.name === entity.name);
      if (value) importStore.setEntity(value);
    }
  }

  function selectFile(files: File[]) {
    let form = ext(files[0].name);
    if (files.length > 1) {
      setError(true);
      errorMessage.current = ChooseOnefileError;
      setTimeout(() => {
        setError(false);
      }, Timeout);
    } else if (files[0].size > TwentyMBInBytes) {
      setError(true);
      errorMessage.current = BigFileSizeError;
      setTimeout(() => {
        setError(false);
      }, Timeout);
    } else if (!form || !formats.includes('.' + form!)) {
      setError(true);
      errorMessage.current = IncorrectFormatError;
      setTimeout(() => {
        setError(false);
      }, Timeout);
    } else importStore.setFile(files[0]);
  }

  return (
    <div className={styles.wrapper}>
      <span className={styles.title}>{HeadTable}</span>
      <SearchSelect
        value={importStore.entity}
        items={importStore.entities ?? []}
        placeholder="Выберите таблицу..."
        onChangeValue={(item) => {
          set(item);
        }}
        onItemsLoad={async (value) => {
          importStore.loadTables(value);
          return 1;
        }}
        className={styles.select}
        isInput={true}
      />
      <FileUpload
        files={importStore.file}
        onSelectFile={selectFile}
        onClearFile={() => {
          importStore.file = null;
        }}
        formats={formats}
      />
      <StatusBar
        isError={true}
        eventName={'Не удалось загрузить файл'}
        isOpen={error}
        errorMessage={errorMessage.current}
      />
    </div>
  );
}

export default observer(SelectData);
