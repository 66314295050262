import { action } from "mobx";
import { Item } from "types";
import { LookupInfo } from "./lookupInfo/LookupInfo";
import { isString } from "lodash";

export interface FieldValidationState {
	isInvalid: boolean;
	error?: string;
}

export const validateRounding = action(
	(value: string | null | undefined, state: FieldValidationState): boolean => {
		if ((value as unknown as number) > 15) {
			state.isInvalid = true;
			state.error = "Введите число меньше 15";
			return false;
		}

		state.isInvalid = false;
		state.error = "";
		return true;
	}
);

export const validateIsNumberAndRequired = action(
	(value: string | null | undefined, state: FieldValidationState): boolean => {
		if (value === null || typeof value !== "string" || value.trim() === "") {
			state.isInvalid = true;
			state.error = "Введите число";
			return false;
		}

		if (/^\d+$/.test(value)) {
			state.isInvalid = false;
			return true;
		}

		state.isInvalid = true;
		state.error = "Введите корректное целое число";
		return false;
	}
);

export const validateRequired = action(
	(
		value: string | null | undefined | Item | Date | any,
		state: FieldValidationState,
		isSelect?: boolean,
		isFiltered?: boolean
	) => {
		if (!value || (typeof value === "string" && (value as string)?.length === 0)) {
			state.isInvalid = true;
			state.error =
				isSelect && !isFiltered
					? "Выберите данные"
					: isSelect && isFiltered
						? "Некорректные данные"
						: "Введите данные";
			return false;
		} else {
			state.isInvalid = false;
			state.error = "";
			return true;
		}
	}
);

export const validateSchema = action(
	(name: string | null, state: FieldValidationState, fields?: LookupInfo[]) => {
		if (name === null || !/^([a-zA-Z0-9_-]+)$/g.test(name)) {
			state.isInvalid = true;
			state.error = "Некорректное системное название";
			return false;
		}
		if (
			fields &&
			(fields.filter((e) => e.columnName === name).length > 1 || name === "Name")
		) {
			state.isInvalid = true;
			state.error = "Такое системное название уже существует";
			return false;
		}
		state.isInvalid = false;
		state.error = "";
		return true;
	}
);

export const validatePassword = action(
	(password: string | null, state: FieldValidationState) => {
		if (password === null || password.length === 0) {
			state.isInvalid = true;
			state.error = "Некорректный пароль";
			return false;
		}
		state.isInvalid = false;
		state.error = "";
		return true;
	}
);

export const validateEmail = action(
	(mail: string | null, state: FieldValidationState) => {
		if (mail === null || !/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(mail)) {
			state.isInvalid = true;
			state.error = "Некорректный email";
			return false;
		}
		state.isInvalid = false;
		state.error = "";
		return true;
	}
);

export const validatePhoneNumber = action(
	(phone: string | null, state: FieldValidationState) => {
		// if (phone === null || !(/^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/).test(phone)) {
		if (
			phone === null ||
			!/^(\+7|7|8)?[\s\-]?\(?[0-9]{3}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/.test(
				phone
			)
		) {
			state.isInvalid = true;
			state.error = "Некорректный номер телефона";
			return false;
		}
		state.isInvalid = false;
		state.error = "";
		return true;
	}
);
export const validateLink = action(
	(link: string | null, state?: FieldValidationState) => {
		if (
			link === null ||
			!/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm.test(
				link
			)
		) {
			if (state) {
				state.isInvalid = true;
				state.error = "Некорректная ссылка";
			}
			return false;
		}

		if (state) {
			state.isInvalid = false;
			state.error = "";
		}
		return true;
	}
);

// function validateRegex(pattern: string): boolean {
//     if(!pattern.includes("[") || !pattern.includes("]"))
//         return false;
//     let parts = pattern.split('/'),
//         regex = pattern,
//         options = "";
//     if (parts.length > 1) {
//         regex = parts[1];
//         options = parts[2];
//     }
//     try {
//         new RegExp(regex, options);
//         return true;
//     }
//     catch(e) {
//         return false;
//     }
// }

// export function validateRegexp(value: string | null, state:FieldValidationState) {
//     if(value == null || !validateRegex(value)) {
//         state.isInvalid = true;
//         state.error = "Необходимо указать корректное регулярное выражение";
//         return false;
//     } else {
//         state.isInvalid = false;
//         return true;
//     }
// }

// // Function to check letters and numbers
// export function alphanumeric(input: string): boolean {
//     const letterNumber = /^[0-9a-zA-Z]+$/;
//     return input.match(letterNumber) != null;
// }

// export function alphanumericLower(input: string): boolean {
//     const letterNumber = /^[0-9a-z]+$/;
//     return input.match(letterNumber) != null;
// }

// export function validateAlphanumeric(value: string | null | ILookup, state:FieldValidationState) {
//     if(value == null || !alphanumeric(value as string)) {
//         state.isInvalid = true;
//         state.error = "Используйте только латинские буквы и цифры"
//         return false;
//     }
//     state.isInvalid = false;
//     return true;
// }

// export function validateAlphanumericLower(value: string | null | ILookup, state:FieldValidationState) {
//     if(value == null || !alphanumericLower(value as string)) {
//         state.isInvalid = true;
//         state.error = "Используйте только малые латинские буквы и цифры"
//         return false;
//     }
//     state.isInvalid = false;
//     return true;
// }

// export function validateRGBColor(value: string, state:FieldValidationState) {
//     const pattern = new RegExp("^#([a-fA-F0-9]){3}$|[a-fA-F0-9]{6}$");

//     if(!pattern.test(value)) {
//         state.isInvalid = true;
//         state.error = "Цвет должен быть задан в формате #rrggbb или #rgb"
//         return false;
//     }
//     state.isInvalid = false;
//     return true;
// }

// export function validatePositiveAmount(value: number | null, state:FieldValidationState) {
//     if(value == null || value <= 0) {
//         state.isInvalid = true;
//         state.error = "Количество должно быть больше нуля";
//         return false;
//     } else {
//         state.isInvalid = false;
//         return true;
//     }
// }

// export function validateNotNegativeAmount(value: number | null, state:FieldValidationState) {
//     if(value == null || value < 0) {
//         state.isInvalid = true;
//         state.error = "Количество не должно быть меньше нуля";
//         return false;
//     }
//     state.isInvalid = false;
//     return true;
// }

// export async function validateEmailTemplate(html: string | null, state:FieldValidationState) : Promise<boolean> {
//     if (html === null || html === "") {
//         state.isInvalid = true;
//         state.error = "Создайте шаблон письма";
//         return false;
//     }
//     try {
//         const response = await api.post("/template/check", {
//             html: html
//         });
//         if (response && response.data) {
//             state.isInvalid = false;
//             return true;
//         }
//         state.isInvalid = true;
//         state.error = "Ошибка при формировании письма из шаблона, заполните шаблон корректно";
//         return false;
//     }
//     catch(e) {
//         return false;
//     }
// }
// export function validateLink(link: string | null, state:FieldValidationState): boolean
// {
//    if (link !== null && !link.startsWith("https://")) {
//        state.isInvalid = true;
//        state.error = "Ссылка должна начинаться с https://";
//        return false;
//    }
//    state.isInvalid = false;
//    return true;
// }
// export function validateEmailWithNull(mail: string | null, state:FieldValidationState): boolean
// {
//    if (mail !== null && !(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i).test(mail!)) {
//        state.isInvalid = true;
//        state.error = "Введите корректный email";
//        return false;
//    }
//    state.isInvalid = false;
//    return true;
// }

// export function validatePrice(value: number | null, state:FieldValidationState) {
//     if(value == null || value <= 0) {
//         state.isInvalid = true;
//         state.error = "Цена должна быть больше нуля";
//         return false;
//     } else {
//         state.isInvalid = false;
//         return true;
//     }
// }
