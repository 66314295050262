import React, { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { useParams } from "react-router-dom";
import { v4 } from "uuid";

import { dispatcher, store } from "store";
import { singlePageSynchroiser } from "pages/single-page/single-page-synchroiser/single-page-synchroiser";
import { modalController } from "features/modals";
import exportStore from "features/export-block/core/export-store";

import { Button, ButtonDropdown } from "sale-bridge-ui-kit";
import { Button as ButtonComponent, ButtonStyle } from "components";
import ExportBlock from "features/export-block/export-block";
import SavedFilter from "entities/filter/SavedFilter";

import { ActionsComponent, FavoriteFiltersItems } from "../dropdown-components";
import { ExportFormats } from "pages/settings/data/Fields";
import { SectionWizzardMode } from "pages/section-wizzard/section-wizzard";

import { Warning } from "shared";

import styles from "../single-page-head.module.scss";

export const SinglePageBottomRightHead = observer(function (props: {
	isVisibleChevron: boolean;
	size: "small" | "medium" | "large";
	setOpenDeleteBlock: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	const { defaultActions, actionsIfChecked } = ActionsComponent();
	const [idExportModal] = useState<string>(v4());
	const [idModal] = useState<string>(v4());
	const [searchValueForFavoriteFilters, setSearchValueForFavoriteFilters] = useState<string>("");
	const { entityName } = useParams();

	const { favoriteFiltersItems } = FavoriteFiltersItems({ searchValueForFavoriteFilters });

	const entity = useMemo(() => {
		return dispatcher.entity.get();
	}, [dispatcher.entity.get()]);

	const closeConfirm = useCallback((event?: React.MouseEvent<HTMLElement, MouseEvent>) => {
		event?.stopPropagation();
		modalController.modalRemove(idModal);
	}, []);

	const closeExport = useCallback(() => {
		closeConfirm();
		modalController.modalRemove(idExportModal);
		dispatcher.entity.onChangeCheckedAll(false);
		exportStore.clearCheckedColumns();
	}, [idExportModal]);

	const warningExportConfirm = useMemo(() => {
		return (
			<div className={styles.warningDialog}>
				<div className={styles.warningHeader}>
					<span className={styles.warningTitle}>Внимание</span>
					<Warning />
				</div>
				<div className={styles.warningDialogBody}>
					<span className={styles.warningText}>
						{`Вы действительно хотите отменить экспорт?`}
						<br />
						{`Текущий прогресс будет утерян.`}
					</span>
				</div>
				<div className={styles.dialogFooter}>
					<ButtonComponent caption="Вернуться к экспорту" onClick={closeConfirm} style={ButtonStyle.Subtle} isDisabled={false} />
					<ButtonComponent caption="Отменить" onClick={closeExport} style={ButtonStyle.Danger} isDisabled={false} />
				</div>
			</div>
		);
	}, [closeConfirm, closeExport]);

	const openExportWarning = useCallback(() => {
		modalController.popupAdd({ id: idModal, layout: warningExportConfirm, closeFunc: closeExport });
	}, [idModal, warningExportConfirm]);

	const openExport = useCallback(() => {
		exportStore.initSignalR();
		modalController.popupAdd({
			id: idExportModal,
			layout: (
				<ExportBlock
					schema={entity?.entityName!}
					pageTitle={entity?.entityTitle!}
					formats={ExportFormats}
					title={`Экспорт данных ${entity?.entityTitle}`}
					choosingCount={entity?.entity.countOfChecked!}
					allCount={entity?.entity.quality!}
					onClickClose={openExportWarning}
					close={closeExport}
				/>
			),
			closeFunc: openExportWarning
		});
	}, [idExportModal, entity?.entityName, entity?.entityTitle, entity?.entity.countOfChecked, entity?.entity.quality]);

	const handleClickToAction = useCallback(
		(id: string) => {
			switch (id) {
				case "exportAll": {
					dispatcher.entity.onChangeCheckedAll(true);
					openExport();
					return;
				}
				case "import": {
					//TODO реализовать импорт
					return;
				}
				case "editSection": {
					window.open(
						`/sectionMaster/${SectionWizzardMode.ExistSection}/${dispatcher.entity.get()?.entityName.toLowerCase()}`,
						"_blank"
					);
					return;
				}
				case "exportWithCount": {
					openExport();
					return;
				}
				case "delete": {
					props.setOpenDeleteBlock(true);
					return;
				}
			}
		},
		[props.setOpenDeleteBlock]
	);

	const applyFavoriteFilter = useCallback(
		async (favoriteFilter: SavedFilter) => {
			// TODO Фикс бага с пустым фильтром. Когда Артем скажет, надо фиксить основательно
			if (favoriteFilter?.filterInfo?.filters[0]?.rightExpression) {
				dispatcher.filter.setSavedFilter(favoriteFilter);
				singlePageSynchroiser.applyFilter(entityName ?? "");
			}
			setSearchValueForFavoriteFilters("");
		},
		[entityName, dispatcher.filter]
	);

	const handleClickToChevron = useCallback(
		(id: string) => {
			setSearchValueForFavoriteFilters("");
			const findFilter = store.favoriteFilters?.find((filter) => filter.id === id);
			if (findFilter) {
				applyFavoriteFilter(findFilter);
			}
		},
		[toJS(store.favoriteFilters)]
	);

	return (
		<div className={styles.rightBlock}>
			{props.isVisibleChevron && (
				<>
					<div className={styles.divider}></div>
					<ButtonDropdown
						size={props.size}
						position="right-down"
						standartItems={favoriteFiltersItems}
						onClickStandartItem={handleClickToChevron}
						hasSearch
						searchValue={searchValueForFavoriteFilters}
						onChangeSearchValue={(value: string) => setSearchValueForFavoriteFilters(value)}
						childrenButton={
							<Button
								text=""
								size={props.size}
								variant="default"
								border
								leftIcon="ChevronDoubleRight"
								link={false}
								loading={false}
								onClick={() => {}}
							/>
						}
					/>
				</>
			)}
			<ButtonDropdown
				size={props.size}
				position="right-down"
				standartItems={entity?.entity.countOfChecked! > 0 ? actionsIfChecked : defaultActions}
				onClickStandartItem={handleClickToAction}
				childrenButton={
					<Button
						text="Действия"
						onClick={() => {}}
						size="small"
						variant="default"
						leftIcon="KebabVertical"
						rightIcon="Dropdown"
						link={false}
						loading={false}
						border
					/>
				}
			/>
		</div>
	);
});
