import classNames from 'classnames';

import { IFieldHorizontalProps } from './horizontal-field';

import styles from './field.module.css';


function QuickViewField(props: IFieldHorizontalProps) {
    const wrapperClasses = classNames({
        [`${styles.quickViewField} `]: true,
        [`${props.className}`]: props.className
    });

    const captionClasses = classNames({
        [`${styles.quickCaptionText} `]: true,
        [`${props.classNameCaption}`]: props.classNameCaption,
    });

    return (
        <div className={wrapperClasses} style={props.style}>
            <label className={styles.captionHorizontal} htmlFor={props.name} >
                <span className={captionClasses}>{props.caption}
                    {props.required &&
                        <span className={styles.star}>*</span>}
                </span>
            </label>
            {props.children}
        </div>
    );
}



export default QuickViewField;