import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import CloudStoreModal from "./ui/CloudStoreModal";
import { Input, FlexColumn, Info, HorizontalField, InputStyleName, TextError, FlexRow, Button, ButtonStyle } from "components";
import { Swith, SwithItem } from "../../components/helpers/swith";

import { CloudStoreState } from "../../core/CloudStoreService";
import cloudStoreService from "../../core/CloudStoreService";

import styles from "./CloudStore.module.css";

function CloudStore(props: { isActive: boolean }) {
	const [isModalOpen, setModalOpen] = useState<boolean>(false);
	const store = cloudStoreService;

	let storeLoading = store.loadResult;

	let fildsClassNames = styles.filds;
	if (store.state == CloudStoreState.Connecting || store.state === CloudStoreState.Switch || storeLoading.state === 0) {
		fildsClassNames += " " + styles.disabled;
	}

	function onSwithStore(isMoveFile: boolean) {
		store.swithStore(isMoveFile);
		setModalOpen(false);
	}

	async function openModal() {
		await store.uploadSettings();
		if (store.state === CloudStoreState.Connected) {
			setModalOpen(true);
		}
	}
	useEffect(() => {
		const storedEndpoint = window.localStorage.getItem("Endpoint");
		const storedAccessKey = window.localStorage.getItem("AccessKey");
		const storedSecretKey = window.localStorage.getItem("SecretKey");
		const storedBucketName = window.localStorage.getItem("BucketName");
		if (storedEndpoint) {
			store.setEndpoint(storedEndpoint);
		}
		if (storedAccessKey) {
			store.setAccessKey(storedAccessKey);
		}
		if (storedSecretKey) {
			store.setSecretKey(storedSecretKey);
		}
		if (storedBucketName) {
			store.setBucketName(storedBucketName);
		}
	}, []);

	return (
		<FlexColumn gap={10}>
			<Info title={"Дополнительная информация"} text={"Хранение файлов в базе облачного хранилища S3."} />
			<FlexColumn gap={15}>
				<div className={styles.headerBody}>
					<span className={styles.headerTitle}>Настройки подключения</span>
					{storeLoading.state === 0 ? <LoadStatus percent={storeLoading.percent} /> : <ConnectStatus state={store.state} />}
				</div>
				<div className={fildsClassNames}>
					<HorizontalField classNameCaption={styles.captionTextHorizontal} caption={"Endpoint"} name={"Endpoint"}>
						<Input
							id="Endpoint"
							autoComplete={false}
							value={store.endpoint}
							placeholder={""}
							onChangeValue={(value) => {
								window.localStorage.setItem("Endpoint", value);
								store.setEndpoint(value);
							}}
							inputStyle={InputStyleName.FullViewInput}
							classNameParent={styles.input}
						/>
					</HorizontalField>
					<HorizontalField classNameCaption={styles.captionTextHorizontal} caption={"AccessKey"} name={"AccessKey"}>
						<Input
							id="AccessKey"
							autoComplete={false}
							isPassword
							value={store.accessKey}
							placeholder={""}
							onChangeValue={(value) => {
								window.localStorage.setItem("AccessKey", value);
								store.setAccessKey(value);
							}}
							inputStyle={InputStyleName.FullViewInput}
							classNameParent={styles.input}
						/>
					</HorizontalField>
					<HorizontalField classNameCaption={styles.captionTextHorizontal} caption={"SecretKey"} name={"SecretKey"}>
						<Input
							id="SecretKey"
							autoComplete={false}
							isPassword
							value={store.secretKey}
							placeholder={""}
							onChangeValue={(value) => {
								window.localStorage.setItem("SecretKey", value);
								store.setSecretKey(value);
							}}
							inputStyle={InputStyleName.FullViewInput}
							classNameParent={styles.input}
						/>
					</HorizontalField>
					<HorizontalField classNameCaption={styles.captionTextHorizontal} caption={"BucketName"} name={"BucketName"}>
						<Input
							id="BucketName"
							autoComplete={false}
							value={store.bucketName}
							placeholder={""}
							onChangeValue={(value) => {
								window.localStorage.setItem("BucketName", value);
								store.setBucketName(value);
							}}
							inputStyle={InputStyleName.FullViewInput}
							classNameParent={styles.input}
						/>
					</HorizontalField>
					{store.state === CloudStoreState.Error && (
						<TextError>Не удалось установить подключение. Проверьте настройки и попробуйте еще раз.</TextError>
					)}
					{props.isActive ? (
						<FlexRow gap={10}>
							<Button caption={"Проверить подключение"} style={ButtonStyle.Primary} onClick={() => store.uploadSettings()} />
						</FlexRow>
					) : (
						<FlexRow gap={10}>
							<Button caption={"Переключиться на это хранилище"} style={ButtonStyle.Primary} onClick={openModal} />
							<Button caption={"Проверить подключение"} style={ButtonStyle.Link} onClick={() => store.uploadSettings()} />
						</FlexRow>
					)}
				</div>
			</FlexColumn>
			<CloudStoreModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} onSwithStore={onSwithStore} />
		</FlexColumn>
	);
}

function ConnectStatus(props: { state: CloudStoreState }) {
	let connectCircleClassName = styles.connectCircle;
	let connectTextClassName = styles.connectText;

	if (props.state === CloudStoreState.Connecting || props.state === CloudStoreState.Switch) {
		connectCircleClassName += " " + styles.connecting;
		connectTextClassName += " " + styles.connecting;
	}
	if (props.state === CloudStoreState.Connected) {
		connectCircleClassName += " " + styles.connected;
		connectTextClassName += " " + styles.connected;
	}

	return (
		<div className={styles.connectBody}>
			<div className={connectCircleClassName}></div>
			<Swith activeName={props.state}>
				<SwithItem name={CloudStoreState.NotConnecting}>
					<span className={connectTextClassName}>подключение не настроено</span>
				</SwithItem>
				<SwithItem name={CloudStoreState.Error}>
					<span className={connectTextClassName}>подключение не настроено</span>
				</SwithItem>
				<SwithItem name={CloudStoreState.Connecting}>
					<ConnectingText className={connectTextClassName} />
				</SwithItem>
				<SwithItem name={CloudStoreState.Switch}>
					<span className={connectTextClassName}>Выполняется переключение хранилища...</span>
				</SwithItem>
				<SwithItem name={CloudStoreState.Connected}>
					<span className={connectTextClassName}>подключение Активно</span>
				</SwithItem>
			</Swith>
		</div>
	);
}

function ConnectingText(props: { className: string }) {
	return (
		<div>
			<span className={props.className}>проверка подключения...</span>
		</div>
	);
}

export function LoadStatus(props: { percent: number }) {
	return (
		<div className={styles.connectBody}>
			<div className={styles.connectCircle + " " + styles.connecting}></div>
			<div className={styles.connectText + " " + styles.loading}>
				<span>{`Выполняется переключение хранилища: перенос файлов — ${props.percent}%`}</span>
			</div>
		</div>
	);
}

export default observer(CloudStore);
