import { toJS } from "mobx";
import { observer } from "mobx-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { v4 } from "uuid";

import { dispatcher, selector } from "store";

import { ConstructorQuickView } from "../constructor-quick-view";
import { QuickViewSettingPanel } from "../quick-view-settings-panel";

import TypeIcon from "features/type-icon/type-icon";

import { AdditionalInfoOfField } from "./data";
import { AdditionalField, TabState } from "types/entity";

export const QuickViewSettings = observer(() => {
    const [leftBlockAdditionalFieldsZoneId] = useState(v4());
    const [rightBlockAdditionalFieldsZoneId] = useState(v4());
    const [previewIsOpen, setPreviewIsOpen] = useState();

    useEffect(() => {
        dispatcher.kanbanQuickView.init();
    }, [])
        

    const sectionWizzard = useMemo(() => {
        return dispatcher.sectionWizzard.getSectionWizzard();
    }, [dispatcher.sectionWizzard.getSectionWizzard()]);

    const isCommentsEnableVisible = useMemo(() => {
        return dispatcher.sectionWizzard.getAdditionalFromConfig()?.comments.state == TabState.EnabledOnPrimary || 
        dispatcher.sectionWizzard.getAdditionalFromConfig()?.comments.state == TabState.EnabledOnAuxiliary
    }, [dispatcher.sectionWizzard.getAdditionalFromConfig()]);

    const isCommentsEnableQuickView = useMemo(() => {
        return selector.kanbanQuickView.getQuickViewDesign()!.commentsEnable
    }, [selector.kanbanQuickView.getQuickViewDesign()!.commentsEnable]);

    const header = useMemo(() => {
        return selector.kanbanQuickView.getHeader();
    }, [selector.kanbanQuickView.getHeader()]);

    const leftBlockAdditionalFields = useMemo(() => {
        return sectionWizzard?.kanbanConfig?.quickViewDesign.leftBlockAdditionalFields;
    }, [toJS(sectionWizzard?.kanbanConfig?.quickViewDesign.leftBlockAdditionalFields)]);

    const rightBlockAdditionalBlockedFields = useMemo(() => {
        const blockedFields: AdditionalField[] = [];

        const priorityField = selector.sectionWizzard.getPriorityFieldBySpecialization();
        if (priorityField) {
            const priorityBlockedField = {
                columnId: priorityField.fieldConfig?.columnId ?? v4(),
                gridItemId: priorityField.gridItemId,
                order: 0,
                isBlocked: true,
            }
            blockedFields.push(priorityBlockedField);
        }

        const stageField = selector.sectionWizzard.getStageFieldBySpecialization();
        if (stageField) {
            const stageBlockedField = {
                columnId: stageField.fieldConfig?.columnId ?? v4(),
                gridItemId: stageField.gridItemId,
                order: 0,
                isBlocked: true,
            }
            blockedFields.push(stageBlockedField);
        }
        return blockedFields;
    }, [toJS(sectionWizzard?.kanbanConfig?.quickViewDesign.rightBlockAdditionalFields)]);

    const rightBlockAdditionalDragFields = useMemo(() => {
        return selector.kanbanQuickView.getRightFieldsWithoutBlocked();
    }, [toJS(sectionWizzard?.kanbanConfig?.quickViewDesign.rightBlockAdditionalFields)]);

    const commentsEnable = useMemo(() => {
        return sectionWizzard?.kanbanConfig?.quickViewDesign.commentsEnable;
    }, [sectionWizzard?.kanbanConfig?.quickViewDesign.commentsEnable]);

    const quickViewEnable = useMemo(() => {
        return sectionWizzard?.kanbanConfig?.quickViewDesign.quickViewEnable;
    }, [sectionWizzard?.kanbanConfig?.quickViewDesign.quickViewEnable]);

    
    const resetQuickViewSettingsEnable = useMemo(() =>{ 
        return dispatcher.kanbanQuickView.hasChanges
    }, [dispatcher.kanbanQuickView.hasChanges]);

    const sourcePanelElements = useMemo(() => {
        const existFields = selector.kanbanQuickView.getExistFields().map(field => {
            if (field.name.toLocaleLowerCase() === "createdon" || field.name.toLocaleLowerCase() === "modifiedon") {
                return {
                    ...field,
                    icon: <TypeIcon type={field.columnType} />,
                    isDisabled: false,
                    isLocked: false
                }
            }
            return {
                ...field,
                icon: <TypeIcon type={field.columnType} />
            }
        })
        return existFields;
    }, [toJS(leftBlockAdditionalFields), toJS(rightBlockAdditionalDragFields)]);

    const createdOnTitle = useMemo(() => sourcePanelElements.find(sourcePanelElement => sourcePanelElement.name.toLocaleLowerCase() === "createdon")?.displayValue ?? "", [toJS(sourcePanelElements)]);

    const modifiedOnTitle = useMemo(() => sourcePanelElements.find(sourcePanelElement => sourcePanelElement.name.toLocaleLowerCase() === "modifiedon")?.displayValue ?? "", [toJS(sourcePanelElements)]);

    const getAdditionalInfoOfField = useCallback((columnId: string): AdditionalInfoOfField => {
        const findedElement = sourcePanelElements.find(sourcePanelElement => sourcePanelElement.columnId === columnId)
        return {
            columnType: findedElement?.columnType,
            columnTitle: findedElement?.displayValue,
            isRequired: findedElement?.isRequired
        }
    }, [toJS(sourcePanelElements)]);

    //TODO onClickClose
    return (
        <>
            <ConstructorQuickView
                header={header}
                createdOnTitle={createdOnTitle}
                modifiedOnTitle={modifiedOnTitle}
                quickViewEnable={quickViewEnable ?? false}
                commentsEnable={commentsEnable ?? false}
                leftBlockAdditionalFields={leftBlockAdditionalFields}
                rightBlockAdditionalDragFields={rightBlockAdditionalDragFields}
                rightBlockAdditionalBlockedFields={rightBlockAdditionalBlockedFields}
                leftBlockAdditionalFieldsZoneId={leftBlockAdditionalFieldsZoneId}
                rightBlockAdditionalFieldsZoneId={rightBlockAdditionalFieldsZoneId}
                onChangeAdditionalFields={dispatcher.kanbanQuickView.updateAdditionalField}
                onDeleteFieldFromBlock={dispatcher.kanbanQuickView.deleteField}
                getAdditionalInfoOfField={getAdditionalInfoOfField}
            />

            <QuickViewSettingPanel
                isCommentsEnableVisible={isCommentsEnableVisible}
                isCommentsEnableQuickView={isCommentsEnableQuickView}
                fields={sourcePanelElements}
                leftBlockAdditionalFieldsZoneId={leftBlockAdditionalFieldsZoneId}
                rightBlockAdditionalFieldsZoneId={rightBlockAdditionalFieldsZoneId}
                quickViewEnable={quickViewEnable ?? false}
                resetQuickViewSettingsEnable={resetQuickViewSettingsEnable}
                onSetLeftBlockAdditionalFields={(field: AdditionalField, newPosition: number) => { dispatcher.kanbanQuickView.setAdditionalField(field, newPosition, true) }}
                onSetRightBlockAdditionalFields={dispatcher.kanbanQuickView.setAdditionalField}
                onSetCommentsEnable={dispatcher.kanbanQuickView.setCommentsEnable}
                onQuickViewDisable={dispatcher.kanbanQuickView.setQuickViewEnable}

                onResetQuickViewSettings={dispatcher.kanbanQuickView.resetQuickViewSettings}

                setPreviewIsOpen={(value: boolean) => { }}
            />
        </>
        //TODO for quickView popup
        // <div className={styles.wrapper}>
        //     <div className={styles.header}>
        //         <div className={styles.leftHeader}>
        //             <span>{sectionWizzard?.entityTitle}</span>
        //             <ArrowToRight />
        //             <span>{header}</span>
        //         </div>
        //         <div className={styles.rightHeader}>
        //             <Close className={styles.iconHead} /*onClick={props.close}*/ />
        //         </div>
        //     </div>
        // </div>
    );
});