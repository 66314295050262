import { StandartItem } from "sale-bridge-ui-kit/dist/components/button-dropdown/button-dropdown"
import { MaskState } from "types/entity"

export const copyMaskAction: StandartItem =
{
    id: 'copy',
    name: 'Дублировать',
    icon: 'Copy',
    iconSize: 'small',
}
export const editMaskAction: StandartItem = {
    id: 'edit',
    name: 'Редактировать',
    icon: 'Edit',
    iconSize: 'small',
}
export function setDefaultAction(maskState: MaskState): StandartItem {
    return {
        id: 'setDefault',
        name: 'Установить по умолчанию',
        icon: 'ButtonSuccessCheck',
        iconSize: 'small',
        isDisable: maskState === MaskState.Default
    }
}
export function deleteAction(maskState: MaskState): StandartItem {
    return {
        id: 'delete',
        name: 'Удалить',
        icon: 'Delete',
        iconSize: 'small',
        isRed: true,
        isDisable: maskState === MaskState.Default
    }
}