const month: Record<string, string> = {
    ['март']: 'марта',
    ['май']: 'мая',
    ['июнь']: 'июня',
    ['июль']: 'июля',
}

export function getModifiedOn(date: string) {
    const startOfCurrentDay = new Date().setHours(0, 0, 0, 0);
    const endOfCurrentDay = new Date().setHours(23, 59, 59, 999);
    const startOfYesterday = startOfCurrentDay - 86400000;
    const endOfYesterday = endOfCurrentDay - 86400000;
    const dateNow = Date.now();
    const propsDate = Date.parse(date);

    const time = dateNow - propsDate;
    
    if (time < 60000) {
        return `${new Date(time).getSeconds()} с назад`;
    }
    else {
        if ((time >= 60000) && (time < 3600000)) {
            //минуты
            const result = Math.trunc(time / 60000);
            return `${result} мин назад`;
        }
        else {
            if ((propsDate >= startOfCurrentDay) && (propsDate <= endOfCurrentDay)) {
                //сегодня
                return `${new Date(date).getHours()}:${new Date(date).getMinutes()}`;
            }
            else {
                if ((propsDate >= startOfYesterday) && (propsDate <= endOfYesterday)) {
                    //Вчера
                    return `Вчера, ${new Date(date).getHours()}:${new Date(date).getMinutes()}`;
                }
                else {
                    const shortMonth = new Date(date).toLocaleString('default', { month: 'short' });
                    const beautifyMonth = shortMonth.split('.').length > 1 ? shortMonth.split('.')[0] : month[shortMonth.split('.')[0]];
                    const propsDateYear = new Date(date).getFullYear();

                    if (new Date(Date.now()).getFullYear() !== propsDateYear) {
                        //если год не совпадает с текущим
                        return `${new Date(date).getDate()} ${beautifyMonth} ${propsDateYear}`;
                    }

                    //Позавчера и старше
                    return `${new Date(date).getDate()} ${beautifyMonth}, ${new Date(date).getHours()}:${new Date(date).getMinutes()}`;
                }
            }
        }

    }
}