import { observer } from "mobx-react";
import { useCallback, useState } from "react";
import { v4 } from "uuid";

import { singlePageSynchroiser } from "pages/single-page/single-page-synchroiser/single-page-synchroiser";

import { Position, modalController } from "features/modals";
import { ModalType } from "features/modals/viewer/modal-viewer";

import { Button, Field } from "sale-bridge-ui-kit";

import { CloseMaxi } from "shared";

import styles from "./flag-popup.module.scss";

type FlagPopupProps = {
	recordId: string;
	mode: "add" | "remove";
	close: () => void;
};

export const FlagPopup = observer((props: FlagPopupProps) => {
	const [idNotification] = useState<string>(v4());
	const [flagComment, setFlagComment] = useState<string>(props.mode === "add" ? "Добавлен флажок." : "Флажок убран.");

	const handleCancel = useCallback(() => {
		props.close();
	}, [props.close]);

	const handleChange = useCallback(
		(value: string) => {
			setFlagComment(value);
		},
		[props.close]
	);

	const handleSave = useCallback(() => {
		const textWithoutLineBreaks = flagComment.replace(/(?:\r\n|\r|\n)/g, "\\n");
		singlePageSynchroiser
			.setFlag(props.recordId, textWithoutLineBreaks, props.mode)
			.then(() => {
				modalController.notificationAdd({
					id: idNotification,
					position: Position.CENTER,
					layout: <>{props.mode === "add" ? "Флажок установлен" : "Флажок убран"}</>,
					allowDefaultClick: true,
					allowTimer: true
				});
			})
			.catch(() => {
				modalController.notificationAdd({
					id: idNotification,
					position: Position.CENTER,
					type: ModalType.ERROR_NOTIFICATION,
					layout: <>Что-то пошло не так. Проверьте интернет-соединение и попробуйте ещё раз</>
				});
			});

		handleCancel();
	}, [props, flagComment]);

	return (
		<div className={styles.dialog}>
			<div className={styles.header}>
				<span className={styles.title}>{props.mode === "add" ? "Поставить флажок" : "Убрать флажок"}</span>
				<CloseMaxi className={styles.closeButton} onClick={handleCancel} />
			</div>
			<div className={styles.dialogBody}>
				<span className={styles.info}>Если нужно, то оставьте комментарий. Он будет опубликован на вкладке «Комментарии».</span>
				<Field type="multiline" size="small" textVariant="outlined" value={flagComment} onChange={handleChange} />
			</div>
			<div className={styles.dialogFooter}>
				<Button text="Отменить" size="small" variant="default" border link={false} loading={false} onClick={handleCancel} />
				<Button text="Сохранить" size="small" variant="primary" border link={false} loading={false} onClick={handleSave} />
			</div>
		</div>
	);
});
