import { observer } from "mobx-react";
import { memo, useMemo } from "react";
import { Draggable } from "react-beautiful-dnd";
import { toJS } from "mobx";

import { Card } from "components";

import { KanbanPageCardProps } from "../data";

import styles from "./kanban-page-card.module.scss";

export const KanbanPageCard = memo(observer(function (props: {
    onOpen: (recordId: string) => void,
    priority: string,
} & KanbanPageCardProps) {
    const quickActions = useMemo(() => props.kanbanConfig?.cardDesign?.selectedQuickActions?.filter(quickAction => quickAction.isEnabled).map(quickAction => quickAction.action), [toJS(props.kanbanConfig.cardDesign.selectedQuickActions)])
    return (
        <Draggable draggableId={props.id} index={props.index}>
            {(provided, snapshot) => {
                return (
                    <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        className={styles.card}
                        key={props.id}
                    >
                        <Card
                            priority={props.priority}
                            isDragging={snapshot.isDragging}
                            name={props.name}
                            id={props.id}
                            color={props.color}
                            size={props.size}
                            flagged={props.sysFlag}
                            showActions={props.kanbanConfig.cardDesign.quickActionEnable}
                            isSelected={props.isSelected}
                            isReturned={props.isReturned}
                            onOpen={props.onOpen}
                            onFlaged={() => { }}
                            fields={props.fields}
                            users={props.userFields}
                            quickActions={quickActions}
                            draggingCardId={props.draggingCardId}
                            setDraggingCardId={props.setDraggingCardId}
                        />
                    </div>
                );
            }}
        </Draggable>

    );
}));