import { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { autorun } from "mobx";

import detailFieldConfigurationPopupState from "./detail-field-configuration-popup-state";

import { DetailFieldConfigurationBody } from "./detail-field-configuration-body";
import { ChoiceLookupType } from "./components/choice-lookup-type";
import { Button } from "sale-bridge-ui-kit";

import { ConfigurationStep } from "pages/section-wizzard/pages/constructor/configurations/field-configuration/types";
import { ColumnType } from "entities/ColumnType";
import { GridItem } from "types/entity";

import { CloseMaxi } from "shared";

import styles from "./detail-field-configuration-popup.module.scss";

interface DetailFieldConfigurationPopupProps {
	columnId?: string;
	fieldType?: ColumnType;
	close: () => void;
	closeAll: () => void;
	position: {
		cellX: number;
		cellY: number;
	};
	targetZone?: string | null;
	onSave: (newItem: GridItem) => void;
}

export const DetailFieldConfigurationPopup = observer((props: DetailFieldConfigurationPopupProps) => {
	const [isConfigurationValid, setIsConfigurationValid] = useState(false);
	const [step, setStep] = useState(ConfigurationStep.InitialLookupType);

	useEffect(() => {
		if (props.fieldType !== ColumnType.Lookup) {
			setStep(ConfigurationStep.MainSettings);
		}
	}, [props.fieldType]);

	useEffect(() => {
		detailFieldConfigurationPopupState.setValueWithoutTrackingChanges("x", props.position.cellX);
		detailFieldConfigurationPopupState.setValueWithoutTrackingChanges("y", props.position.cellY);
	}, [props.position.cellX, props.position.cellY]);

	useEffect(() => {
		const validateConfiguration = () => {
			const isValid = detailFieldConfigurationPopupState.isConfigurationValid();
			setIsConfigurationValid(isValid);
		};
		validateConfiguration();
		const disposer = autorun(() => {
			validateConfiguration();
		});
		return () => {
			disposer();
		};
	}, [detailFieldConfigurationPopupState]);

	const handleStepChange = useCallback(() => {
		setStep(ConfigurationStep.MainSettings);
	}, []);

	const handleClose = useCallback(() => {
		if (detailFieldConfigurationPopupState.hasChanges) {
			props.close();
		} else {
			props.closeAll();
		}
	}, [props.close]);

	const handleSave = useCallback(() => {
		if (isConfigurationValid) {
			const newItem = detailFieldConfigurationPopupState.getNewFieldConfiguration();
			props.onSave(newItem);
			props.closeAll();
		}
	}, [props.onSave, isConfigurationValid]);

	const dialogFooterButtons = useMemo(() => {
		if (props.columnId) {
			setStep(ConfigurationStep.MainSettings);
		}

		return (
			<div className={styles.dialogFooter}>
				{step !== ConfigurationStep.InitialLookupType && <div className={styles.dialogFooterDivider} />}
				<div className={styles.footerButtonsBlock}>
					<Button text="Отменить" size="small" variant="backless" border link={false} loading={false} onClick={handleClose} />
					{step === ConfigurationStep.InitialLookupType && (
						<Button
							text="Продолжить"
							size="small"
							variant="primary"
							border
							link={false}
							loading={false}
							onClick={handleStepChange}
						/>
					)}
					{step === ConfigurationStep.MainSettings && (
						<Button
							text="Сохранить"
							size="small"
							variant={isConfigurationValid ? "primary" : "disabled"}
							border
							link={false}
							loading={false}
							onClick={handleSave}
						/>
					)}
				</div>
			</div>
		);
	}, [handleClose, step, handleStepChange, handleSave, isConfigurationValid]);

	const dialogContent = useMemo(() => {
		if (props.columnId) {
			return <DetailFieldConfigurationBody columnId={props.columnId} />;
		}
		if (step === ConfigurationStep.InitialLookupType) {
			return props.fieldType === ColumnType.Lookup ? (
				<ChoiceLookupType fieldType={props.fieldType} size={"medium"} />
			) : (
				<DetailFieldConfigurationBody fieldType={props.fieldType} />
			);
		} else if (step === ConfigurationStep.MainSettings) {
			return <DetailFieldConfigurationBody fieldType={props.fieldType} />;
		}

		return null;
	}, [step, props.fieldType, props.columnId]);

	const titleModal = useMemo(() => {
		switch (props.fieldType) {
			case ColumnType.Numerator: {
				return "Нумератор";
			}
			case ColumnType.String: {
				return "Текстовое поле";
			}
			case ColumnType.DateTime: {
				return "Поле с датой и временем";
			}
			case ColumnType.Date: {
				return "Поле с датой";
			}
			case ColumnType.Time: {
				return "Поле с временем";
			}
			case ColumnType.Lookup: {
				return "Справочное поле";
			}
			case ColumnType.Boolean: {
				return "Логическое поле";
			}
			case ColumnType.Integer: {
				return "Поле с целым числом";
			}
			case ColumnType.Decimal: {
				return "Поле с дробным числом";
			}
		}
	}, [props.fieldType]);

	return (
		<>
			{detailFieldConfigurationPopupState.fieldIsLoading && (
				<div className={styles.modalOverlay}>
					<svg className={styles.circleLoader} viewBox="0 0 50 50">
						<circle
							className={styles.circle}
							cx="25"
							cy="25"
							r="20"
							fill="none"
							stroke={`var(--color-gray-300)`}
							strokeWidth="3"
						/>
					</svg>
				</div>
			)}
			<div className={styles.headerModal}>
				<span className={styles.titleModal}>{titleModal}</span>
				<CloseMaxi className={styles.closeButton} onClick={handleClose} />
			</div>
			<div className={styles.dialogBody}>{dialogContent}</div>
			{dialogFooterButtons}
		</>
	);
});
