import { useState } from "react";
import { Dialog, FlexColumn, FlexRow, Toggle, Text, TextPrompt, Button, ButtonStyle } from "components";
import Separator from "pages/settings/fileStore/components/separator/separator";


interface DataBaseStoreModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSwithStore: (isMoveFiles: boolean) => void;
}

function DataBaseStoreModal(props: DataBaseStoreModalProps) {
    const [checked, setCheck] = useState<boolean>(true);

    function onCheck(val: boolean) {
        setCheck(val);
    }

    function onSwithStore() {
        props.onSwithStore(checked);
        props.onClose();
    }

    return (
        <Dialog title={"Переключение хранилища"} isOpen={props.isOpen} onClick={props.onClose}>
            <FlexColumn gap={20}>
                <FlexColumn gap={10}>
                    <FlexRow justify="space-between">
                        <Text>Перенести существующие файлы</Text>
                        <Toggle checked={checked} onCheck={(val) => onCheck(val)} />
                    </FlexRow>
                    <Separator />
                    <TextPrompt>Система перенесёт ранее загруженные файлы в новое хранилище.</TextPrompt>
                </FlexColumn>

                <Text bold>Подтвердите переключение на хранилище базы данных.</Text>
                <FlexRow justify="flex-end" gap={10}>
                    <Button style={ButtonStyle.Subtle} caption={"Отменить"} onClick={props.onClose} />
                    <Button style={ButtonStyle.Primary} caption={"Переключить хранилище"} onClick={onSwithStore} />
                </FlexRow>
            </FlexColumn>
        </Dialog>
    )
}

export default DataBaseStoreModal;