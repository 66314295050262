import { action, observable, observe } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import InputNumber from "components/input/input-number";
import { Item } from "types";
import Notes from "components/notes/notes";
import SearchSelect from "components/select/search-select/search-select";
import QuickViewField from "components/field/quick-view-field";
import { InputStyleName } from "components/input/input-styles";
import { SelectStyleName } from "components/select/select-styles";
import Input from "components/input/input";

import Entity, { IEntityStore } from "entities/Entity";
import { validateRequired } from "entities/Validation";
import { IQuickViewFilld, QuickViewFilldType } from "../constants/constants";

import styles from "./quick-view-content.module.css";


interface IFieldProps {
  field: IQuickViewFilld;
  entityStore: Entity<IEntityStore>;
  isChange: boolean;
  isDisabled?: boolean;
}

// данная настройка отвечает за отслеживание изменённых полей
interface ILeftContentState {
  editableKey: string[];
}

const ContentState = observable<ILeftContentState>({
  editableKey: []
})

const setEditableKey = action((newEitableKey: string[]) => {
  ContentState.editableKey = newEitableKey;
});

export const LeftContent = observer((props: { fields: IQuickViewFilld[], entityStore: Entity<IEntityStore> }) => {
  let f = ContentState;
  useEffect(() => {
    observe(props.entityStore.entity, (change) => {
      if (!f.editableKey.some(r => r == change.name)) {
        f.editableKey.push(change.name as string);
      }
    })
  }, [])

  useEffect(() => {
    if (!props.entityStore.hasChanges) {
      setEditableKey([])
      // f.editableKey = [];
    }
  }, [props.entityStore.hasChanges])

  return (
    <>
      {props.fields.map((field, i) => {
        const isChange = f.editableKey.some(key => key === field.name) && props.entityStore.hasChanges;

        if (field.isNew && !props.entityStore.isNew) return null;

        switch (field.type) {
          case QuickViewFilldType.Select:
            return (
              <SelectField key={field.name + i} field={field} entityStore={props.entityStore} isChange={isChange} />
            );
          case QuickViewFilldType.Input:
            return (
              <InputField key={field.name + i} field={field} entityStore={props.entityStore} isChange={isChange} />
            );
          case QuickViewFilldType.InputNumber:
            return (
              <InputNumberField key={field.name + i} field={field} entityStore={props.entityStore} isChange={isChange} />
            );
          case QuickViewFilldType.SelectFiltering:
            return (
              <SelectFilteringField key={field.name + i} field={field} entityStore={props.entityStore} isChange={isChange} />
            );
          case QuickViewFilldType.Note:
            return (
              <NoteField key={field.name + i} field={field} entityStore={props.entityStore} isChange={isChange} />
            );
          default:
            return null;
        }
      })}
    </>
  );
});

export const RightContent = observer(function (props: { fields: IQuickViewFilld[], entity: Entity<IEntityStore> }) {
  let f = ContentState;

  return (
    <div className={styles.rightFieldsBlock}>
      {props.fields.map((field, i) => {
        const isChange = f.editableKey.some(key => key === field.name) && props.entity.hasChanges;
        switch (field.type) {
          case QuickViewFilldType.Select:
            return (
              <SelectField key={field.name + i} field={field} entityStore={props.entity} isChange={isChange} />
            );
          case QuickViewFilldType.SelectFiltering:
            return (
              <SelectFilteringField key={field.name + i} field={field} entityStore={props.entity} isChange={isChange} />
            );
          case QuickViewFilldType.Date:
            return (
              <InputField key={field.name + i} field={field} entityStore={props.entity} isChange={isChange} />
            );

          default:
            return (
              <div className={styles.fieldBlock}>
                <span className={styles.titleField}>{field.caption}</span>
                <span className={styles.valueField}>{Reflect.get(props.entity.entity, field.name)}</span>
              </div>
            );
        }
      })}

    </div>
  );

})


export const SelectField = observer((props: IFieldProps) => {
  const { field, entityStore } = props;
  const entity = entityStore.entity;
  let val: Item = Reflect.get(entity, field.name);
  const isInvalid = props.field.isNotValidation ? false : entityStore.entity.validation[field.name].isInvalid;
  const isDisabled = props.field.isDisabled;
  return (
    <>
      <QuickViewField key={field.name} name={field.name} caption={field.caption} required={field.required}>
        <SearchSelect
          items={entityStore.lookups[field.lookupName!]}
          onChangeValue={(value) => {
            Reflect.set(entity, field.name, value!);
            if (!field.isNotValidation) {
              validateRequired(value, entityStore.entity.validation[field.name]);
            }
          }}
          inputStyle={InputStyleName.QuickViewInput}
          selectStyle={SelectStyleName.QuickViewSelect}
          isInput={true}
          onItemsLoad={async () => {
            return await entityStore.loadLookups(field.lookupName!, undefined, null, field.isSorted);
          }}
          isListDelay={true}
          isDisabled={isDisabled}
          isDisabledLocked={isDisabled}
          value={val}
          isInvalid={isInvalid}
          onFocusOut={() => {
            if (!field.isNotValidation) {
              validateRequired(val, entityStore.entity.validation[field.name])
            }
          }}

        />

      </QuickViewField>
    </>

  );
})

export const SelectFilteringField = observer((props: IFieldProps) => {
  const { field, entityStore } = props;
  const entity = entityStore.entity;
  let val: Item = Reflect.get(entity, field.name);
  const isInvalid = props.field.isNotValidation ? false : entityStore.entity.validation[field.name].isInvalid;
  const isDisabled = props.field.isDisabled;
  return (
    <>
      <QuickViewField key={field.name} name={field.name} caption={field.caption} required={field.required}>
        <SearchSelect
          items={entityStore.lookups[field.lookupName!]}
          onChangeValue={(value) => {
            Reflect.set(entity, field.name, value!);
            if (!field.isNotValidation) {
              validateRequired(value, entityStore.entity.validation[field.name]);
            }
          }}
          inputStyle={InputStyleName.QuickViewInput}
          selectStyle={SelectStyleName.QuickViewSelect}
          isInput={true}
          onItemsLoad={async () => {
            return await entityStore.loadLookups(field.lookupName!, undefined, null, field.isSorted);
          }}
          isListDelay={true}
          isDisabled={isDisabled}
          isDisabledLocked={isDisabled}
          value={val}
          isInvalid={isInvalid}
          onFocusOut={() => {
            if (!field.isNotValidation) {
              validateRequired(val, entityStore.entity.validation[field.name])
            }
          }}

        />
      </QuickViewField>
    </>


  );
})

export const InputField = observer((props: IFieldProps) => {
  const { field, entityStore } = props;
  const entity = entityStore.entity;
  let val: string = Reflect.get(entity, field.name);
  const isDisabled = props.field.isDisabled;

  function getDate(val: any) {
    let date = new Date(Date.parse(val));
    return date.toLocaleDateString("ru-Ru")
  }

  if (field.type === QuickViewFilldType.Date)
    val = getDate(val)

  return (
    <>
      <QuickViewField key={field.name} name={field.name} caption={field.caption} required={field.required}>
        <div className={styles.parentBaseInput}>
          <Input
            value={val}
            placeholder={""}
            onChangeValue={(value: string) => { Reflect.set(entity, field.name, value) }}
            inputStyle={InputStyleName.QuickViewInput}
            isDisabled={isDisabled}
            isDisabledLocked={isDisabled}
          />
        </div>
      </QuickViewField>
    </>

  );
})

export const InputNumberField = observer((props: IFieldProps) => {
  const { field, entityStore } = props;
  const entity = entityStore.entity;
  let val: string = Reflect.get(entity, field.name);
  const isDisabled = props.field.isDisabled;
  return (
    <>
      <QuickViewField key={field.name} name={field.name} caption={field.caption} required={field.required}>
        <div className={styles.parentBaseInput}>
          {/* <BaseInput
            value={val}
            placeholder={""}
            onChangeValue={(value: string) => { Reflect.set(entity, field.name, value) }}
            inputStyle={InputStyles.QuickViewInput}
            isDisabled={isDisabled}
            isDisabledLocked={isDisabled}
            isOnlyNumber={true}
          /> */}
          <InputNumber
            value={val}
            placeholder={""}
            onChangeValue={(value: string) => { Reflect.set(entity, field.name, value) }}
            className={styles.inputNumberWrapper}
            classNameInputFocus={styles.focusInput}
            isDisabled={isDisabled}
            isDisabledLocked={isDisabled}
          />
        </div>
      </QuickViewField>
    </>

  );
})

export const NoteField = observer((props: IFieldProps) => {
  const { field, entityStore } = props;
  const entity = entityStore.entity;
  let val: string = Reflect.get(entity, field.name);
  return (
    <>
      <QuickViewField key={field.name} name={field.name} caption={field.caption} required={field.required}>
        <Notes
          value={val}
          placeholder={"Любая другая важная информация..."}
          onChangeValue={(value: string) => { Reflect.set(entity, field.name, value) }}
        />
      </QuickViewField>

    </>

  );
})

function Point() {
  return (
    <div className={styles.point} />
  )
}