import { isUndefined } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { toJS } from "mobx";

import { loadLookup } from "app/services/lookup/LookupService";

import { Field } from "sale-bridge-ui-kit";

import { Item } from "types";
import { ColumnType } from "entities/ColumnType";

import { FieldProps } from "sale-bridge-ui-kit/dist/components/field/fields";
import { BaseSelectProps } from "sale-bridge-ui-kit/dist/components/field/fields/fields";
import { AutoFieldByTypeProps, fieldPropsUiKitByType, getFieldTitle } from "./data";

import styles from "./auto-field-by-type.module.scss";

export function AutoFieldByType(props: AutoFieldByTypeProps) {
	const [items, setItems] = useState<Array<Item>>([]);
	const [searchValue, setSearchValue] = useState("");

	const columnValueItems = useMemo(() => {
		return [
			{
				layout: <></>,
				items: items
					.filter(
						(item) =>
							item.displayValue?.toLowerCase().includes(searchValue.toLowerCase()) ||
							item.name.toLowerCase().includes(searchValue.toLowerCase())
					)
					.map((item) => (
						<div
							className={styles.item}
							onClick={() => {
								props.onChange(item);
							}}
						>
							<span>{item.displayValue ?? item.name}</span>
						</div>
					))
			}
		];
	}, [toJS(items), searchValue]);

	const fieldPropsByType: FieldProps | null = useMemo(() => {
		if (props.columnType) {
			if (props.columnType !== ColumnType.Numerator) {
				const fieldProps = { ...fieldPropsUiKitByType[props.columnType] } as FieldProps;
				if (props.columnType === ColumnType.Lookup) {
					(fieldProps as BaseSelectProps).items = columnValueItems;
					(fieldProps as BaseSelectProps).searchValue = searchValue;
					(fieldProps as BaseSelectProps).onChangeSearchValue = setSearchValue;
				}
				return fieldProps;
			}
		}
		return null;
	}, [props.columnType, toJS(columnValueItems), searchValue]);

	const load = useCallback(async () => {
		if (props.lookupTable && !props.isVirtualLookup) {
			const items = await loadLookup(props.lookupTable, null);
			if (items) {
				setItems(items);
			}
		}
		if (props.isVirtualLookup && props.virtualLookupValues) {
			setItems(props.virtualLookupValues);
		}
	}, [props.lookupTable, props.isVirtualLookup, toJS(props.virtualLookupValues)]);

	useEffect(() => {
		load();
	}, [props.columnId]);

	return (
		<>
			{fieldPropsByType ? (
				<Field
					{...fieldPropsByType}
					label={props.isVisibleLabel ? getFieldTitle("columnValue") : undefined}
					labelPosition="vertical"
					size="small"
					value={props.columnValue.displayValue ?? props.columnValue.name ?? props.columnValue ?? ""}
					onChange={props.onChange}
					isDisabled={props.isDisabled}
					isRequired
					alert={props.columnValueIsInvalid || props.validation?.isInvalid ? "error" : undefined}
					tooltipBody={props.validation?.error}
					startTooltipPosition="top left"
					tooltipTrigger="hover"
					isTooltipDisplayed={props.validation?.isInvalid}
				/>
			) : (
				<Field
					type="text"
					size="small"
					label={props.isVisibleLabel ? "Равно" : undefined}
					labelPosition="vertical"
					textVariant="outlined"
					placeholder="Значение"
					value=""
					onChange={props.onChange}
					isRequired
					isDisabled
					alert={props.validation?.isInvalid ? "error" : undefined}
					tooltipBody={props.validation?.error}
					startTooltipPosition="top left"
					tooltipTrigger="hover"
					isTooltipDisplayed={props.validation?.isInvalid}
				/>
			)}
		</>
	);
}
