import { Api } from "shared/api/class-api";

class SystemDesigner extends Api {
	basicPath: string;
	constructor() {
		super();
		this.basicPath = "/api/SystemDesigner";
	}

	createSection = () => {
		this.setPath(`${this.basicPath}`);
		return this;
	};
	updateSection = () => {
		this.setPath(`${this.basicPath}`);
		return this;
	};
	getSystemDesigner = (entityName: string, sectionId?: string) => {
		if (sectionId) {
			this.setPath(`${this.basicPath}?sectionId=${sectionId}`);
			return this;
		}
		this.setPath(`${this.basicPath}?entityName=${entityName}`);
		return this;
	};
	//TODO реализовать удаление секции id - стопер бэк
	deleteSectionById = (sectionId: string) => {
		this.setPath(`${this.basicPath}/section/delete`);
		return this;
	};
}

const systemDesigner = new SystemDesigner();

export { systemDesigner };
