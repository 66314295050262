import {
    FullViewController,
    KanbanCardDesignController,
    KanbanQuickViewController,
    SectionWizzardController,
    FilterController,
    StageModels,
    stageModels,
    filterController,
    fullViewController,
    kanbanCardDesignController,
    kanbanQuickViewController,
    sectionWizzardController,
} from "./selectors";

export class Selector {
    stageModels: StageModels;
    filter: FilterController;
    fullView: FullViewController;
    kanbanQuickView: KanbanQuickViewController;
    kanbanCardDesign: KanbanCardDesignController;
    sectionWizzard: SectionWizzardController;

    constructor() {
        this.stageModels = stageModels;
        this.filter = filterController;
        this.fullView = fullViewController;
        this.kanbanQuickView = kanbanQuickViewController;
        this.kanbanCardDesign = kanbanCardDesignController;
        this.sectionWizzard = sectionWizzardController;
    }
}

export const selector = new Selector();