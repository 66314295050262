import React, { CSSProperties, ReactNode } from "react";

import Modal from "components/modal/modal";

// import { CloseMaxi } from "shared";
import { CloseMaxi } from "shared";

import styles from "./dialog.module.css";

interface IDialogProps {
	title: string;
	isOpen: boolean;
	dialogFooterButtons?: JSX.Element;
	onClick?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
	onClickOnDialog?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
	close?: boolean;
	className?: string;
	style?: CSSProperties;
	children?: ReactNode;
}

function Dialog(props: IDialogProps) {
	if (!props.isOpen) {
		return null;
	}

	let className = `${styles.dialog} `;

	if (props.className) className += props.className;

	return (
		<Modal>
			<div className={styles.backdrop} />
			<div className={className} style={props.style} onClick={props.onClickOnDialog}>
				<div className={styles.header}>
					<span className={styles.title}>{props.title}</span>
					{props.close ? null : <CloseMaxi className={styles.closeButton} onClick={props.onClick} />}
				</div>
				<div className={styles.dialogBody}>
					{props.children}
					<div className={styles.dialogFooter}>{props.dialogFooterButtons}</div>
				</div>
			</div>
		</Modal>
	);
}

export default Dialog;
