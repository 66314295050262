import { observer } from "mobx-react";

import { InfoIcon } from "shared";

import styles from "./loader.module.css";

const Loader = observer((props: { currentPercent: number }) => {
	let currentPercent = props.currentPercent;

	return (
		<div className={styles.wrapper}>
			<div className={styles.progressBarBlock}>
				<span className={styles.loadTitle}>Выполняется импорт...</span>
				<span className={styles.importTitle}>Импортировано: {currentPercent}%</span>
				<ProgressBar width={currentPercent} />
			</div>
			<div className={styles.advice}>
				<div className={styles.head}>
					<InfoIcon className={styles.infoIcon} />
					<span className={styles.headInfo}>Совет</span>
				</div>
				<span className={styles.infoText}>Вы можете продолжать работу: импорт продолжается в фоновом режиме.</span>
			</div>
		</div>
	);
});

function ProgressBar(props: { width: number }) {
	const fillerStyles = {
		width: `${props.width}%`
	};

	return (
		<div className={styles.progressBar}>
			<div className={styles.filler} style={fillerStyles} />
		</div>
	);
}

export default Loader;
