import { useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { Field } from 'sale-bridge-ui-kit'
import { isString } from 'lodash'

export type Props = {
	columnName: string | undefined
	columnTitle?: string | undefined
	prompt?: string | undefined
	isRequired?: boolean | undefined
	rounding?: string | undefined
	value: string | boolean
	isDisabled?: boolean
	onChange: (value: boolean) => void
	variant: 'standart' | 'outlined'
	labelPosition?: 'horizontal' | 'vertical';
	labelVariant?: 'black' | 'gray';
}

export const Boolean = observer(function (props: Props) {
	const handleChange = useCallback(
		(value: boolean) => {
			props.onChange(value)
		},
		[props],
	)

	return (
		<Field
			type='boolean'
			size='small'
			label={props.columnTitle ?? ''}
			title={typeof (props.value) === 'boolean' ? '' : props.value}
			labelPosition={props.labelPosition ?? "vertical"}
			labelVariant={props.labelVariant}
			isRequired={props.isRequired}
			value={(props.value === "true") || isString(props.value) ? false : props.value}
			info={props.prompt}
			onChange={props.onChange}
			isDisabled={props.isDisabled}
			textVariant={props.variant}
		/>
	)
})
