import { Api } from "shared/api/class-api";

class ModuleSection extends Api {
	basicPath: string;
	constructor() {
		super();
		this.basicPath = "/api/ModuleSection";
	}

	moduleById = (id: string) => {
		this.setPath(`${this.basicPath}/module/${id}`);
		return this;
	};
	modules = () => {
		this.setPath(`${this.basicPath}/modules`);
		return this;
	};
}

const moduleSection = new ModuleSection();

export { moduleSection };
