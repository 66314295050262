import { makeAutoObservable } from "mobx";

import { api } from "shared";
import { eventBus } from "app/services";

import FileStoreEvents from "../types/FileStoreEvents";
import { FileStorages } from "entities/FileStorages";

class FileStoreService {
	currentStore: FileStorages | undefined;
	activePage: FileStorages | undefined;
	isSwithing: boolean = false;

	constructor() {
		makeAutoObservable(this);
		eventBus.registerHandler(FileStoreEvents.Switch, this.switchStore.bind(this));
		eventBus.unregisterHandler(FileStoreEvents.Switch, this.switchStore.bind(this));
		this.getSettings();
		// this.switchCheck();
	}

	switchStore(currentStore: FileStorages) {
		this.currentStore = currentStore;
	}

	setActivePage(page: FileStorages) {
		this.activePage = page;
	}

	async getSettings() {
		const response = await api.http.file.sourceInfo().get();
		if (response.status === 200) {
			this.currentStore = window.localStorage.getItem("currentStore") ?? response.data.currentFileSource;
			this.activePage = window.localStorage.getItem("currentStore") ?? response.data.currentFileSource;
		} else {
			console.error("Ошибка при запросе данных: ", response);
		}
	}

	async switchCheck() {
		const response = await api.http.file.taskrunning().get();

		try {
			if (response.data.success && response.data) {
				setTimeout(() => this.switchCheck(), 3000 + Math.random() * 5000);
			}

			if (response.data.success) {
				eventBus.dispatchEvent(FileStoreEvents.Swithing, response.data);
				this.isSwithing = response.data;
			}
		} catch (error) {
			console.error(error);
		}
	}
}

const fileStoreService = new FileStoreService();

export default fileStoreService;
