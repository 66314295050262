import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { Hint } from "sale-bridge-ui-kit";

import styles from "./column.module.scss";


type ColumnProps = {
    name: string,
    quantity: number,
    children: JSX.Element | Array<JSX.Element>,
    size: "small" | "medium" | "large",
    position: "first" | "intermediate" | "last",
    isPreview?: boolean; //TODO убрать, когда будут доработки по загрузке карточек или рефакторинг
}

/**
 * @description Создание колонки канбана
 * @param props.name назание колонки
 * @param props.quantity количество карточек
 * @param props.children карточки внутри колонки
 * @param props.size размер шрифта
 * @param props.position положение карточки
 * @param props.isPreview флаг того, что эта стадия используется в превью дизайна карточки
 * @returns
 */
export const Column = observer(function (props: ColumnProps) {

    const columnClassName = classNames(styles.column, {
        [styles[props.position]]: props.position,
        [styles.scrollableColumn]: props.isPreview,
    });

    const headerCaptionClassName = classNames(styles.headerCaption, {
        [styles[props.size]]: props.size,
    });

    const nameClass = classNames(styles.name, {
        [styles[props.size]]: props.size,
    });

    const numberClass = classNames(styles.number, {
        [styles[props.size]]: props.size,
    });

    return (
        <div className={columnClassName}>
            <div className={styles.header}>
                <div className={headerCaptionClassName}>
                    <Hint hintBody={props.name} startPosition="top" >
                        <div className={nameClass}>{props.name}</div>
                    </Hint>
                    <div className={numberClass}>{props.quantity}</div>
                </div>
                <div className={styles.divider} />
            </div>
            {props.children}
        </div>
    )
})