import { ArrowToDown } from "shared";
import { useRef, useState } from "react";

import { Button, ButtonStyle, InputSearch, SelectMiniWithSearchStyleName } from "components";

import SelectMiniWithSearchStyles, { ISelectStyles } from "./core/styles-system";
import { ISelectItem } from "pages/import/data/import-constants";
import TypeIcon from "features/type-icon/type-icon";
import { SelectWithSearchMiniProps } from "../types";

import styles from "./select-with-search-mini.module.css";
import stylesBase from "../select.module.css";

function defaultRender(value: ISelectItem): JSX.Element {
	if (!value.value) return <span className={styles.displayValue}>{value.displayValue}</span>;
	else return <span className={styles.displayValue}>{value.displayValue + " [" + value.value + "]"}</span>;
}

function SelectWithSearchMini(props: SelectWithSearchMiniProps) {
	const wrapperRef = useRef<HTMLDivElement>(null);
	const [showItems, setShowItems] = useState(false);

	const [search, setSearch] = useState<string>("");

	const dropdownStyles: ISelectStyles = SelectMiniWithSearchStyles[props.styles ?? SelectMiniWithSearchStyleName.Primary];
	const renderFunction = props.renderFunction ?? defaultRender;

	let classNames = `${stylesBase.wrapper} ${props.className}`;
	let listClassNames = `${stylesBase.baseList} ${styles.list}`;
	let inputClassNames = dropdownStyles.input.classNames;
	let listItemClassNames = `${stylesBase.listItem} `;

	if (props.items && props.items!.length > 0 && showItems) {
		listClassNames += " " + stylesBase.visible;
	}

	if (showItems) {
		inputClassNames += dropdownStyles.input.focus;
	}

	function hideMenu() {
		setShowItems(false);
		document.removeEventListener("click", handleClick);
	}

	function handleClick(event: Event) {
		if (wrapperRef.current != null && !wrapperRef.current.contains(event.target as Node)) {
			hideMenu();
		}
	}

	const handleItemClick = (selectedItem: ISelectItem) => {
		setShowItems(false);
		props.onChange(selectedItem);
	};

	function onSelectOpen() {
		if (props.onItemsLoad) props.onItemsLoad();
		setShowItems(true);
		document.addEventListener("click", handleClick);
	}

	const onButtonClick = () => {
		if (showItems) {
			hideMenu();
		} else {
			onSelectOpen();
		}
	};

	return (
		<div ref={wrapperRef} className={classNames}>
			<div className={inputClassNames} onClick={onButtonClick}>
				{props.value.columnType && <TypeIcon type={props.value.columnType} />}
				<div className={styles.item}>{renderFunction(props.value)}</div>
				<Button
					onClick={onButtonClick}
					firstIcon={<ArrowToDown />}
					style={ButtonStyle.Icon}
					className={showItems ? stylesBase.selectButton + " " + stylesBase.close : stylesBase.selectButton}
				/>
			</div>
			<ul className={listClassNames}>
				<InputSearch
					value={search}
					placeholder={"Поиск"}
					focus={showItems}
					onChangeValue={(value) => {
						setSearch(value);
					}}
					className={styles.searchWrapper}
					classNameInput={styles.searchInput}
				/>
				<div className={styles.itemsContainer}>
					{props.items
						?.filter((i) => i.displayValue.startsWith(search))
						.map((item: ISelectItem) => (
							<li key={item.value} className={listItemClassNames} onClick={() => handleItemClick(item)}>
								{renderFunction(item)}
							</li>
						))}
				</div>
			</ul>
		</div>
	);
}

export default SelectWithSearchMini;
