import { Api } from "shared/api/class-api";

class FavoriteSection extends Api {
	basicPath: string;
	constructor() {
		super();
		this.basicPath = "/api/FavoriteSection";
	}

	sections = () => {
		this.setPath(`${this.basicPath}/sections`);
		return this;
	};
	favoriteById = (id: string) => {
		this.setPath(`${this.basicPath}/favorite/${id}`);
		return this;
	};
	favoritesByUserId = (userId: string, sectionId?: string) => {
		if (sectionId) {
			this.setPath(`${this.basicPath}/favorites${userId}/${sectionId}`);
		} else {
			this.setPath(`${this.basicPath}/favorites/${userId}`);
		}
		return this;
	};
	favoriteSections = (id?: string) => {
		if (id) {
			this.setPath(`${this.basicPath}/favorites/${id}`);
		} else {
			this.setPath(`${this.basicPath}/favorites`);
		}
		return this;
	};
}

const favoriteSection = new FavoriteSection();

export { favoriteSection };
