import { dispatcher } from "store";

import { ModalData, ModalType } from "../viewer/modal-viewer";

export enum Position {
	LEFT = "LEFT",
	CENTER = "CENTER",
}

class ModalController {
	/**
	 * 
	 * @param param 
	 * @param param.position Расположение модалки: слева/по-центру
	 * @param param.type Тип модалки: попап/нотификация 
	 * @param param.layout Верстка компонента 
	 * @param param.allowTimer Разрешить скрытие модалки по таймеру
	 * @param param.allowDefaultClick Разрешить скрытие модалки по клику
	 * @param param.withBackdrop Засветление фона
	 * @example Создание нотифиции слева 
	 * setIdModal(modalController.notificationAdd({id:idModal,  position: Position.LEFT, layout: (<div>Я notification</div>) }));
	 * @example Создание нотифиции по центру с отключением таймера и действий по наведению мыши и клику 
	 * setIdModal(modalController.notificationAdd({id:idModal,  position: Position.CENTER, layout: (<div>Я notification Center</div>), allowTimer: false, allowDefaultClick: true }));
	 * @example Создание попапа со сложным layout и подтверждением confirm 
	 * modalController.popupAdd({id:idModal,  layout: layoutPopup, closeFunc: closeFuncWithConfirm });
	 * @example Создание confirm с закрытием без подтверждения
	 * modalController.popupAdd({id:idModal,  layout: layoutConfirm, closeFunc: closeFuncWithOutConfirm });
	 * @example Удаление модалки
	 * modalController.modalRemove(idModal);
	 */



	notificationAdd(modal: ModalData) {
		return dispatcher.modal.add({
			id: modal.id,
			type: modal.type ?? ModalType.NOTIFICATION,
			position: modal.position ?? Position.CENTER,
			layout: modal.layout,
			allowTimer: modal.allowTimer,
			allowDefaultClick: modal.allowDefaultClick,
			withBackdrop: modal.withBackdrop,
		});
	};

	modalRemove(id: string) {
		dispatcher.modal.remove(id);
	}

	popupAdd({ id, layout, closeFunc, type }: ModalData) {
		return dispatcher.modal.add({
			id: id,
			type: type ?? ModalType.POPUP,
			layout: layout,
			closeFunc: closeFunc,
		});
	};
}

export const modalController = new ModalController();
