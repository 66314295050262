import { Api } from "shared/api/class-api";

class Reactor extends Api {
	basicPath: string;
	constructor() {
		super();
		this.basicPath = "/api/reactor";
	}

	getPage = () => {
		this.setPath(`${this.basicPath}/getpage`);
		return this;
	};

	getStyle = () => {
		this.setPath(`${this.basicPath}/getstyle`);
		return this;
	};
}

const reactor = new Reactor();

export { reactor };
