import { isNull } from "lodash";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { useCallback, useMemo, useRef, useState } from "react";

import { synchroiser } from "synchroiser";

import { Button, ButtonGroup, Dropdown, Hint } from "sale-bridge-ui-kit";

import { SortDirection } from "entities/ISort";
import { ItemGroup } from "sale-bridge-ui-kit/dist/components/dropdown/dropdown";
import { Item } from "types";

import styles from "./comments-header.module.scss";


type CommentsHeader = {
    userFromFilter: Item | null;    
    sortDirection: SortDirection;
    onSetUserToFilter: (user: Item | null) => void;
    onSort: () => void;
}

export const CommentsHeader = observer(function (props: CommentsHeader) {
    const [isOpened, setOpened] = useState(false);
    const buttonRef = useRef<HTMLDivElement>(null);
    const [quantity, setQuantity] = useState<number>(0);
    const [allUsers, setAllUsers] = useState<Item[]>([]);
    const [users, setUsers] = useState<Item[]>([]);
    const [searchValue, setSearchValue] = useState<string>('');
    
    const handleSearch = useCallback((searchValue: string) => {
        setSearchValue(searchValue);
        const filteredUsers = allUsers.filter(user => user.name.toLowerCase().includes(searchValue.toLowerCase()));
        setUsers(filteredUsers);
    }, [toJS(allUsers)]);

    const getUserList = useCallback(async () => {
        const users = await synchroiser.getUsersList();
        setAllUsers(users);
        setUsers(users);
        setQuantity(users.length);
    }, [isOpened]);

    const handleClick = useCallback((event: globalThis.MouseEvent) => {
        const node = buttonRef.current;
        if (node && node.contains(event.target as Node)) return;
        setOpened(false);
    }, [isOpened, buttonRef.current]);

    const handleClickButton = useCallback(async () => {
        setOpened(!isOpened);
        if (!isOpened) {
            await getUserList();
            document.addEventListener("click", handleClick);
        } else {
            document.removeEventListener("click", handleClick);
        }
    }, [isOpened]);

    const handleItemClick = useCallback((user: Item | null) => {
        props.onSetUserToFilter(user);
        setOpened(false);
    }, []);

    const usersList = useMemo((): Array<ItemGroup> => [
        {
            layout: <></>,
            items: users?.map(user => { return <div className={styles.userItem} onClick={() => { handleItemClick(user) }}>{user.name}</div> })
        }
    ], [toJS(users)]);

    const buttonDropdown = useMemo((): JSX.Element =>
        <div ref={buttonRef}>
            <Button
                text={props.userFromFilter ? props.userFromFilter.name : "Все пользователи"}
                size="small"
                variant={props.userFromFilter ? 'secondary' : 'default'}
                link={false}
                loading={false}
                leftIcon={props.userFromFilter ? undefined : "Person"}
                rightIcon={props.userFromFilter ? undefined : "Dropdown"}
                border={isNull(props.userFromFilter)}
                onClick={handleClickButton}
            />
            {isOpened &&
                <Dropdown
                    size='small'
                    variant='button'
                    position='down'
                    items={usersList}
                    quantity={quantity}
                    hasSearch={quantity > 10}
                    searchValue={searchValue}
                    onChangeSearchValue={handleSearch}
                />
            }
        </div>, [props.userFromFilter, quantity, searchValue, isOpened, toJS(usersList)]);

    return (
        <>
            <Button
                text={props.sortDirection == SortDirection.Descending ? "От новых к старым" : "От старых к новым"}
                size="small"
                variant="default"
                link={false}
                loading={false}
                leftIcon={props.sortDirection == SortDirection.Descending ? 'Descending' : 'Ascending'}
                onClick={props.onSort}
                border
            />
            {props.userFromFilter ?
                <ButtonGroup>
                    <Hint hintBody='Сбросить фильтр' startPosition="top">
                        <Button
                            text=''
                            size="small"
                            variant='secondary'
                            border={false}
                            leftIcon='Clear'
                            link={false}
                            loading={false}
                            onClick={() => { handleItemClick(null) }}
                        />
                    </Hint>
                    {buttonDropdown}
                </ButtonGroup>
                :
                buttonDropdown
            }
        </>
    );
});
