import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react'
import { v4 } from 'uuid'

import { stopwatchStore } from '../store'
import { modalController } from 'features/modals'

import { Button, ButtonGroup, Hint } from 'sale-bridge-ui-kit'
import { TimeLoggingPopup } from 'pages/single-page/components'
import { ControlButtons } from './control-buttons'

type StopwatchProps = {
	recordId: string,
	recordTitle : string,
	view: 'small' | 'large'
}

/**
 * Stopwatch компонент для отображения и управления секундомером.
 * @param {string} props.recordId - Уникальный идентификатор записи, для которой ведется отсчет времени.
 * @param {string} props.recordTitle - Заголовок записи, как правило ViewColumn.
 * @param {'small' | 'large'} view - Вариант отображения таймера.
 * @returns {JSX.Element} Компонент Stopwatch.
 */

export const Stopwatch: React.FC<StopwatchProps> = observer(({ recordId, view , recordTitle}) => {
	const [time, setTime] = useState(stopwatchStore.stopwatches.get(recordId)?.getTime() || 0);
	const [idModal] = useState<string>(v4());

	const isRunning = useMemo(() =>
		stopwatchStore.stopwatches.get(recordId)?.isRunning ?? false
		, [stopwatchStore.stopwatches.get(recordId)?.isRunning]);

	const isPausing = useMemo(() =>
		(stopwatchStore.stopwatches.get(recordId) && !isRunning) ?? false
		, [isRunning]);

	const getSeconds = useMemo(() => {
		const seconds = Math.trunc(time / 1000);
		if (seconds === 0) {
			return '00'
		} else {
			if (seconds < 10) {
				return `0${seconds}`
			}
			if (seconds > 60) {
				const mins = Math.trunc(seconds / 60);
				const newSeconds = seconds - (60 * mins);
				if (newSeconds < 10) {
					return `0${newSeconds}`
				}
				return newSeconds;
			}
		}
		return seconds;
	}, [time]);

	const getMinutes = useMemo(() => {
		const seconds = Math.trunc(time / 1000);
		const mins = Math.trunc(seconds / 60);
		if (mins === 0) {
			return '00'
		} else {
			if (mins < 10) {
				return `0${mins}`
			}
		}
		return mins;
	}, [time]);

	useEffect(() => {
		const intervalId = setInterval(() => {
			const stopwatch = stopwatchStore.stopwatches.get(recordId)
			if (stopwatch) {
				setTime(stopwatch.getTime())
			}
		}, 1000)
		return () => clearInterval(intervalId)
	}, [recordId])

	const closeConfirm = useCallback((e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
		e?.stopPropagation();
		modalController.modalRemove(idModal);
	}, []);

	/**
	 * Обработчик для старта секундомера.
	 */
	const handleStart = () => {
		stopwatchStore.startStopwatch(recordId);
	}

	/**
	 * Обработчик для паузы секундомера.
	 */
	const handlePause = () => {
		stopwatchStore.pauseStopwatch(recordId);
	}

	/**
	 * Обработчик для остановки секундомера. Возвращает затраченное время в минутах.
	 */
	const handleStop = useCallback(() => {
		const resultInMinutesFromStopwatch = stopwatchStore.stopStopwatch(recordId)
		stopwatchStore.removeStopwatch(recordId)
		setTime(0)
		modalController.popupAdd({
			id: idModal,
			layout: <TimeLoggingPopup
				recordId={recordId}
				recordTitle={recordTitle}
				timeFromStopwatch={resultInMinutesFromStopwatch.toString()}
				close={closeConfirm}
			/>,
			closeFunc: closeConfirm
		})
	}, [recordId, idModal])

	/**
	 * Обработчик метода нажатия кнопки small отображения таймера.
	 */
	const handleClickToSmallButtonStopwatch = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.stopPropagation();
		if (isRunning) {
			handleStop();
		} else {
			handleStart();
		}
	}, [isRunning])

	const viewing = useMemo(() => {
		if (view === 'small') {
			return <Hint hintBody={isRunning ? 'Остановить и залогировать' : 'Возобновить'}>
				<Button
					text={time > 0 ? `${getMinutes}:${getSeconds}` : '00:00'}
					leftIcon={isRunning ? 'StopCircleFill' : 'PlayCircleFill'}
					iconSize='16'
					size='small'
					variant={isRunning ? 'secondary' : 'cautionSecondary'}
					border={false}
					link={false}
					loading={false}
					onClick={handleClickToSmallButtonStopwatch} />
			</Hint>
		}
		if (view === 'large') {
			return <ControlButtons
				time={time}
				minutes={getMinutes}
				seconds={getSeconds}
				isRunning={isRunning}
				isPausing={isPausing}
				onPause={handlePause}
				onStart={handleStart}
				onStop={handleStop}
			/>
		}
		return <></>
	}, [view, time, isRunning, isPausing, getMinutes, getSeconds])

	return (
		<ButtonGroup isFillParent={view === 'large'}>
			{viewing}
		</ButtonGroup>
	)
})