import { observer } from "mobx-react";
import { useEffect, useMemo, useState } from "react";
import classNames from "classnames";

import { Button, ButtonStyle } from "components";
import TextEditor from "features/text-editor";
import CommentItem from "./comment-item/comment-item";

import { TextEditorProviderWithoutStartText, useEditorApi } from "features/text-editor/config/context";
import { ReactionWrapper } from "modules/subscribeReactions";
import { dispatcher, store } from "store";
import { synchroiser } from "synchroiser";
import authStore from "AuthStore";

import { SortOldToNew } from "shared";
import { SortNewToOld } from "shared";
import { EmptyPhrase } from "shared";

import styles from "./comments-block.module.css";

export interface IComments {
	className?: string;
	classNameTabContent?: string;
	classNameCommentItem?: string;
	classNameSort?: string;
	classNameTextEditor?: string;
	commentsAndInputClassName?: string;
}

type Comment = {
	id: string;
	text: string;
	entityName: string;
	userName: string;
	createdOn: string;
	modifiedOn: string;
	userId: string;
	isOwner: boolean;
};

export const Comments = observer(function (props: IComments) {
	const [commentId, setCommentId] = useState<string>("");
	const [isSortNewToOld, setIsSortNewToOld] = useState<boolean>(true);
	const [isEditInput, setEditInput] = useState<boolean>(false);
	const [editableText, setEditableText] = useState<string>("");

	const [comments, setComments] = useState<Array<Comment>>([]);

	useEffect(() => {
		synchroiser.getComments();
	}, [store.currentRowId]);
	useEffect(() => {
		// @ts-ignore
		setComments(dispatcher.comments.get() || []);
	}, [dispatcher.comments.get()]);

	const styleForCommentsAndInput = useMemo(() => (comments.length === 0 ? { maxHeight: "100%" } : {}), [comments.length]);
	let wrapperClassNames = classNames({
		[`${props.className} `]: props.className,
		[`${styles.wrapper} `]: !props.className
	});
	let sortClassNames = classNames({
		[`${styles.wrapperSort} ` + props.classNameSort]: props.classNameSort,
		[`${styles.wrapperSort} `]: true
	});
	let commentsAndInputClassNames = classNames({
		[`${props.commentsAndInputClassName} `]: props.commentsAndInputClassName,
		[`${styles.commentsAndInput} `]: !props.commentsAndInputClassName
	});
	let tabContentClassNames = classNames({
		[`${props.classNameTabContent} `]: props.classNameTabContent,
		[`${styles.tabContent} `]: !props.classNameTabContent
	});

	const sort = () => {
		setIsSortNewToOld(!isSortNewToOld);
	};

	const handleClickComment = async (text: string) => {
		if (text === "<p></p>") {
			return;
		}
		if (isEditInput) {
			synchroiser.editComments(commentId, text);
		} else {
			synchroiser.addComments(text);
		}
		setEditInput(false);
		setCommentId("");
	};

	function updateComment(id: string, userId: string) {
		let editableText = comments.filter((x) => x.id === id).find((t) => t.text)?.text;
		if (editableText) {
			if (authStore.loggedIn && authStore.userId === userId) {
				setEditInput(true);
				setCommentId(id);
				setEditableText(editableText);
			}
		}
	}

	const commentsElements = useMemo(() => {
		if (isSortNewToOld) {
			dispatcher.comments.sort((a: any, b: any) => {
				if (a.modifiedOn > b.modifiedOn) {
					return 1;
				} else if (a.modifiedOn < b.modifiedOn) {
					return -1;
				} else {
					return 0;
				}
			});
		} else {
			dispatcher.comments.sort((a: any, b: any) => {
				if (a.modifiedOn < b.modifiedOn) {
					return 1;
				} else if (a.modifiedOn > b.modifiedOn) {
					return -1;
				} else {
					return 0;
				}
			});
		}

		// TODO Сделать нормальную фикцациию scroll

		return comments.map((comment) => (
			<ReactionWrapper uuid={comment.id} type="message" key={comment.id}>
				<CommentItem
					key={comment.id}
					id={comment.id}
					entityName={comment.entityName}
					classNameCommentItem={props.classNameCommentItem}
					onDeleteComments={() => {
						// @ts-ignore
						synchroiser.deleteComment(comment.id); /*props.commentEntity.delete(comment.id!, comment.userId); */
					}}
					onUpdateComments={updateComment}
					createdOn={comment.createdOn}
					modifiedOn={comment.modifiedOn}
					text={comment.text}
					userId={comment.userId}
					userName={comment.userName}
					isOwner={comment.isOwner}
				/>
			</ReactionWrapper>
		));
	}, [comments.map((comment) => comment), store.reactionSubscribers, isSortNewToOld]); // TODO Без колхоза почему-то не работает

	return (
		<div className={wrapperClassNames}>
			<div className={commentsAndInputClassNames} style={styleForCommentsAndInput}>
				<TextEditorProviderWithoutStartText>
					{comments.length > 0 ? (
						<>
							<div className={sortClassNames}>
								<div className={styles.sortValue}>Сортировка:</div>
								<Button
									className={styles.buttonFilter}
									firstIcon={!isSortNewToOld ? <SortOldToNew /> : <SortNewToOld />}
									classNameFirstIcon={styles.sort}
									caption={!isSortNewToOld ? "от новых к старым" : "от старых к новым"}
									onClick={() => sort()}
								/>
							</div>
							<div className={tabContentClassNames}>{commentsElements}</div>
						</>
					) : (
						<EmptyBlock />
					)}
					<TextEditor
						send={handleClickComment}
						cancelEdit={() => {
							setCommentId("");
							setEditInput(false);
						}}
						edit={isEditInput}
						editableText={editableText}
						classNameTextEditor={props.classNameTextEditor}
					/>
				</TextEditorProviderWithoutStartText>
			</div>
		</div>
	);
});

const EmptyBlock = observer(function () {
	const { moveFocusToEnd } = useEditorApi();
	return (
		<div className={styles.emptyState}>
			<EmptyPhrase />
			<div className={styles.textEmptyBlock}>
				<span className={styles.firstTitle}>Комментариев нет</span>
				<span className={styles.secondTitle}>Пока что никто ничего не написал</span>
			</div>
			<Button
				style={ButtonStyle.Link}
				caption={"Написать комментарий"}
				onClick={() => {
					moveFocusToEnd();
				}}
			/>
		</div>
	);
});
