import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";
import { toJS } from "mobx";

import { modalController } from "features/modals";
import ListStore from "entities/ListStore";
import Entity from "entities/Entity";
import { default as lookupEntity, Lookup } from "entities/lookup/Lookup";

import NavigationTitle from "../NavigationTitle";
import { Button, ButtonStyle, InputSearch, Select, EditableGrid, Dialog, Field, Input, WarningDialog } from "components";
import exportStore from "features/export-block/core/export-store";
import ExportBlock from "features/export-block/export-block";

import { actionEnums, ExportFormats, getPath, LookupsActions, SystemEnums } from "../data/Fields";
import currentLookupPanelStore from "./CurrentLookupPanelStore";
import lookupMainPanelStore from "./LookupMainPanelStore";
import { Item } from "types";

import { ArrowToDownMini, Warning } from "shared";
import { ChangeView } from "shared";
import { Export } from "shared";
import { CheckMarker } from "shared";
import { CrossInRoundMini } from "shared";
import { DeleteIcon } from "shared";
import { EditProfile } from "shared";

import styles from "./Lookups.module.css";

const defaultLimit = 30;

const CurrentLookupPanel = observer(function (props: { columns: any[]; listStore: ListStore }) {
	const [idExportModal] = useState<string>(v4());
	const [idWarningModal] = useState<string>(v4());
	const [searchValue, setSearchValue] = useState("");
	currentLookupPanelStore.setValue(props.listStore, "listStore");
	const newEntity = new Entity<Lookup>(new Lookup());
	const navigate = useNavigate();

	const LookupsActionsIfChecked = [
		{
			id: actionEnums.ChangeView,
			name: "Изменить отображение...",
			icon: <ChangeView />
		},
		{
			id: actionEnums.Export,
			name: "Экспортировать...",
			icon: <Export />
		},
		{
			id: actionEnums.ChooseAll,
			name: "Выделить все",
			icon: <CheckMarker />
		},
		{
			id: actionEnums.Cancel,
			name: `Отменить выделение (${currentLookupPanelStore.listStore!.countOfChecked})`,
			icon: <CrossInRoundMini className={styles.crossInRoundMini} />
		},
		{
			id: actionEnums.Delete,
			name: `Удалить (${currentLookupPanelStore.listStore!.countOfChecked})`,
			icon: <DeleteIcon />,
			isSeparator: true
		},
		{
			id: actionEnums.DeleteLookup,
			name: "Удалить справочник",
			icon: <DeleteIcon />,
			isRed: true
		}
	];
	useEffect(() => {
		currentLookupPanelStore.setClickedNull();
		currentLookupPanelStore.setValue(false, "clickedNewValue");
	}, []);

	function search(s: string) {
		setSearchValue(s);
	}

	function createColumnTitles(columns: any[]) {
		let columnTitles: any[] = [];
		columns.map((column) => {
			column.width = 100 / columns.length;
			if (column.columnName === "Order") column.columnTitle = "Порядковый номер";
			columnTitles.push(column);
		});
		return columnTitles;
	}
	function cancelNewEntity() {
		currentLookupPanelStore.setValue(false, "clickedNewValue");
		newEntity.cancelChanges();
	}

	const closeConfirm = useCallback((event?: React.MouseEvent<HTMLElement, MouseEvent>) => {
		event?.stopPropagation();
		modalController.modalRemove(idWarningModal);
	}, []);

	const closeExport = useCallback(() => {
		closeConfirm();
		modalController.modalRemove(idExportModal);
		currentLookupPanelStore.listStore?.onChangeCheckedAll(false);
		exportStore.clearCheckedColumns();
	}, [idExportModal]);

	const warningExportConfirm = useMemo(() => {
		return (
			<div className={styles.warningDialog}>
				<div className={styles.warningHeader}>
					<span className={styles.warningTitle}>Внимание</span>
					<Warning />
				</div>
				<div className={styles.warningDialogBody}>
					<span
						className={styles.warningText}
					>{`Вы действительно хотите отменить экспорт?\nТекущий прогресс будет утерян.`}</span>
				</div>
				<div className={styles.dialogFooter}>
					<Button caption="Вернуться к экспорту" onClick={closeConfirm} style={ButtonStyle.Subtle} isDisabled={false} />
					<Button caption="Отменить" onClick={closeExport} style={ButtonStyle.Danger} isDisabled={false} />
				</div>
			</div>
		);
	}, [closeConfirm, closeExport]);

	const openExportWarning = useCallback(() => {
		modalController.popupAdd({ id: idWarningModal, layout: warningExportConfirm, closeFunc: closeExport });
	}, [idWarningModal, warningExportConfirm]);

	const openExportBlock = useCallback(() => {
		modalController.popupAdd({
			id: idExportModal,
			layout: (
				<ExportBlock
					schema={currentLookupPanelStore.listStore!.schema}
					pageTitle={lookupMainPanelStore.nameLookup}
					formats={ExportFormats}
					title={"Экспорт данных: " + lookupMainPanelStore.nameLookup}
					choosingCount={currentLookupPanelStore.listStore?.countOfChecked!}
					allCount={currentLookupPanelStore.listStore?.count!}
					onClickClose={openExportWarning}
					close={closeExport}
				/>
			),
			closeFunc: openExportWarning
		});
	}, [
		idExportModal,
		currentLookupPanelStore.listStore!.schema,
		lookupMainPanelStore.nameLookup,
		toJS(ExportFormats),
		currentLookupPanelStore.listStore?.countOfChecked,
		currentLookupPanelStore.listStore?.count
	]);

	async function onChangeActions(value: Item | null) {
		if (value?.id === actionEnums.ChangeView) {
			//изменить отображение
		}
		if (value?.id === actionEnums.Export) {
			currentLookupPanelStore.listStore!.onChangeCheckedAll(true);
			openExportBlock();
			exportStore.initSignalR();
		}
		if (value?.id === actionEnums.ChooseAll) {
			//выбрать все
			currentLookupPanelStore.setClickedNull();
			currentLookupPanelStore.listStore!.onChangeCheckedAll(true);
		}
		if (value?.id === actionEnums.Cancel) {
			//отменить выбор
			currentLookupPanelStore.setClickedNull();
			currentLookupPanelStore.listStore!.onChangeCheckedAll(false);
		}
		if (value?.id === actionEnums.Delete) {
			//удалить
			currentLookupPanelStore.setValue(true, "openedDeleteValueWarningDialog");
		}
		if (value?.id === actionEnums.DeleteLookup) {
			//удалить справочник
			currentLookupPanelStore.setValue(true, "openedDeleteTableWarningDialog");
		}
	}

	async function onClickDelete() {
		await currentLookupPanelStore.listStore?.deleteTable();
		lookupMainPanelStore.loadLookups();
		lookupMainPanelStore.setValue("", "nameLookup");
		currentLookupPanelStore.setValue(false, "openedDeleteTableWarningDialog");
		navigate(getPath(SystemEnums.Lookups));
	}

	function onClickEdit() {
		currentLookupPanelStore.setClickedNull();
		currentLookupPanelStore.setValue(false, "clickedNewValue");
		currentLookupPanelStore.setValue(true, "openedEditDialod");
		lookupEntity.entity.setValue("", "displaySchema");
	}

	async function deleteItems() {
		try {
			let promises: Promise<boolean>[] = [];
			if (currentLookupPanelStore.listStore!.includedIds.length > 0)
				promises = currentLookupPanelStore.listStore!.includedIds.map(async (element) => {
					const deleted = await lookupEntity.delete(props.listStore.schema, element.id);
					return deleted;
				});
			else if (currentLookupPanelStore.listStore!.isCheckedAll) {
				if (currentLookupPanelStore.listStore!.excludedIds.length > 0)
					promises = currentLookupPanelStore.listStore!.data.map(async (item) => {
						let index = currentLookupPanelStore.listStore!.excludedIds.findIndex((exc) => exc.id === item.id);
						let deleted = true;
						if (index === -1) deleted = await lookupEntity.delete(props.listStore.schema, item.id);
						return deleted;
					});
				else
					promises = currentLookupPanelStore.listStore!.data.map(async (item) => {
						const deleted = await lookupEntity.delete(props.listStore.schema, item.id);
						return deleted;
					});
			}
			const uploadFiles = await Promise.all(promises);
			await currentLookupPanelStore.listStore!.load(
				currentLookupPanelStore.listStore!.filter,
				currentLookupPanelStore.listStore?.staticGroupId,
				defaultLimit,
				currentLookupPanelStore.sort
			);
		} catch (error: any) {
			if (error.response.status === 401) {
				console.log(error.response);
			}
		} finally {
			currentLookupPanelStore.setClickedNull();
			currentLookupPanelStore.setValue(false, "openedDeleteValueWarningDialog");
		}
	}

	return (
		<div className={styles.systemMainPanel}>
			<NavigationTitle nameLookup={lookupMainPanelStore.nameLookup} />
			<div className={styles.mainTitle}>
				{lookupMainPanelStore.nameLookup}
				<EditProfile className={styles.editButton} onClick={onClickEdit} />
				<Dialog
					title="Переименовать справочник"
					isOpen={currentLookupPanelStore.openedEditDialod}
					dialogFooterButtons={
						<>
							<Button
								caption={"Отмена"}
								onClick={() => {
									lookupEntity.cancelChanges();
									currentLookupPanelStore.setValue(false, "openedEditDialod");
								}}
								style={ButtonStyle.Subtle}
							/>
							<Button
								caption={"Переименовать"}
								onClick={() => {
									currentLookupPanelStore.renameTable();
									currentLookupPanelStore.setValue(false, "openedEditDialod");
									lookupMainPanelStore.setValue(currentLookupPanelStore.newNameTable, "nameLookup");
								}}
								style={ButtonStyle.Primary}
							/>
						</>
					}
					onClick={() => {
						lookupEntity.cancelChanges();
						currentLookupPanelStore.setValue(false, "openedEditDialod");
					}}
				>
					{<LookupEditNameFilling entity={lookupEntity} displaySchema={lookupMainPanelStore.nameLookup} />}
				</Dialog>
			</div>
			<ButtonsTitle
				newEntityId={newEntity.entity.id}
				searchValue={searchValue}
				search={search}
				listStore={currentLookupPanelStore.listStore!}
				clickedId={currentLookupPanelStore.clickedId}
				onChangeActions={onChangeActions}
				LookupsActionsIfChecked={LookupsActionsIfChecked}
			/>

			<div className={styles.grid}>
				<EditableGrid
					columns={createColumnTitles(props.columns)}
					listStore={currentLookupPanelStore.listStore!}
					isCheckBox={true}
					entityName={props.listStore.schema}
					entityTitle={lookupMainPanelStore.nameLookup}
					clickedNew={currentLookupPanelStore.clickedNewValue}
					cancelNewEntity={cancelNewEntity}
					clickedId={currentLookupPanelStore.clickedId}
					sort={currentLookupPanelStore.sort}
				/>
			</div>
			<WarningDialog
				value={"Вы действительно хотите удалить справочник?"}
				valueReturn={"Отмена"}
				valueDelete={"Удалить"}
				isOpen={currentLookupPanelStore.openedDeleteTableWarningDialog}
				onBackClick={() => {
					currentLookupPanelStore.setValue(false, "openedDeleteTableWarningDialog");
				}}
				onCancelClick={onClickDelete}
			/>
			<WarningDialog
				value={"Выделенные записи будут удалены без возможности восстановления.\nУдалить?"}
				valueReturn={"Отмена"}
				valueDelete={"Удалить"}
				isOpen={currentLookupPanelStore.openedDeleteValueWarningDialog}
				onBackClick={() => {
					currentLookupPanelStore.setValue(false, "openedDeleteValueWarningDialog");
				}}
				onCancelClick={deleteItems}
			/>
		</div>
	);
});

const ButtonsTitle = observer(function (props: {
	newEntityId: string;
	searchValue: string;
	search: (s: string) => void;
	listStore: ListStore;
	onChangeActions: (value: Item | null) => Promise<void>;
	LookupsActionsIfChecked: any;
	clickedId: string;
}) {
	let [searchValue, setSearchValue] = useState(props.searchValue);
	let listStore = props.listStore;

	return (
		<div className={styles.buttonsTitle}>
			<div className={styles.searchingTitle}>
				<Button
					caption={"Добавить значение"}
					onClick={() => {
						currentLookupPanelStore.setClickedNull();
						currentLookupPanelStore.setValue(true, "clickedNewValue");
						currentLookupPanelStore.setValue(props.newEntityId, "clickedId");
					}}
					style={ButtonStyle.Primary}
					className={styles.addLookupButton}
				/>

				<InputSearch
					placeholder="Поиск"
					value={searchValue}
					onChangeValue={props.search}
					classNameInput={styles.searchLookupGrid}
				/>
			</div>

			<div className={styles.actionsButton}>
				<Select
					name={"Действия"}
					items={!listStore.isCheckedAll && listStore.includedIds.length === 0 ? LookupsActions : props.LookupsActionsIfChecked}
					onChangeValue={props.onChangeActions}
					secondIcon={<ArrowToDownMini />}
					isRotateSecondIcon={true}
					styles={ButtonStyle.Link}
				/>
			</div>
		</div>
	);
});

export const LookupEditNameFilling = observer((props: { entity: Entity<Lookup>; displaySchema: string }) => {
	let entity = props.entity;

	return (
		<>
			<Field name={"oldValue"} caption={"Старое значение"} required={false}>
				<Input
					value={props.displaySchema}
					placeholder={""}
					onChangeValue={() => {}}
					style={{
						background: `var(--color-gray-100)`
					}}
					isDisabled={true}
				/>
			</Field>
			<Field name={"newValue"} caption={"Новое значение"} required={true}>
				<Input
					value={entity.entity.displaySchema}
					placeholder={""}
					onChangeValue={(value: string) => {
						entity.entity.setValue(value, "displaySchema");
						currentLookupPanelStore.setValue(value, "newNameTable");
					}}
				/>
			</Field>
		</>
	);
});
export default CurrentLookupPanel;
