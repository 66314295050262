import { observer } from "mobx-react";
import { useCallback, useState } from "react";

import { Button, Field } from "sale-bridge-ui-kit";

import { ValidationState } from "pages/section-wizzard/data/data";
import { validateRequired } from "entities/Validation";

import { CloseMaxi } from "shared";

import styles from "./create-business-rule-popup.module.scss";

type Props = {
	onSave: (ruleName: string, comment: string) => void;
	onClose: () => void;
};

export const CreateBusinessRulePopup = observer((props: Props) => {
	const [ruleName, setRuleName] = useState<string>("");
	const [comment, setComment] = useState<string>("");
	const [disabledSaveButton, setDisabledSaveButton] = useState<boolean>(true);

	const [ruleNameValidation, setRuleNameValidation] = useState<ValidationState>({
		isInvalid: false,
		error: ""
	});

	const handleCancel = useCallback(() => {
		props.onClose();
	}, [props.onClose]);

	const handleChangeName = useCallback((value: string) => {
		setRuleName(value);
	}, []);

	const handleChangeComment = useCallback((value: string) => {
		setComment(value);
	}, []);

	const handleFocusOutName = useCallback(() => {
		const validation: ValidationState = { ...ruleNameValidation };
		validateRequired(ruleName, validation);
		setRuleNameValidation(validation);
		setDisabledSaveButton(validation.isInvalid);
	}, [ruleNameValidation, ruleName]);

	const handleSave = useCallback(() => {
		props.onSave(ruleName, comment);
		props.onClose();
	}, [props.onClose, props.onSave, ruleName, comment]);

	return (
		<div className={styles.dialog}>
			<div className={styles.header}>
				<span className={styles.title}>Новое бизнес-правило</span>
				<CloseMaxi className={styles.closeButton} onClick={handleCancel} />
			</div>
			<div className={styles.dialogBody}>
				<Field
					type="text"
					size="small"
					label="Название"
					textVariant="outlined"
					value={ruleName}
					onChange={handleChangeName}
					alert={ruleNameValidation.isInvalid ? "error" : undefined}
					onBlur={handleFocusOutName}
					isClearing
					isRequired
				/>
				<Field
					type="multiline"
					size="small"
					label="Комментарий"
					textVariant="outlined"
					placeholder="Добавьте описание для бизнес-правила"
					counter={200}
					value={comment}
					onChange={handleChangeComment}
				/>
			</div>
			<div className={styles.dialogFooter}>
				<div className={styles.divider} />
				<div className={styles.buttonsFooter}>
					<Button text="Отменить" size="small" variant="default" border link={false} loading={false} onClick={handleCancel} />
					<Button
						text="Сохранить"
						size="small"
						variant={disabledSaveButton ? "disabled" : "primary"}
						border
						link={false}
						loading={false}
						onClick={handleSave}
					/>
				</div>
			</div>
		</div>
	);
});
