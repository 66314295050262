import { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { isEmpty, isNull, isUndefined } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 } from "uuid";

import { dispatcher } from "store";
import { NEW_RECORD } from "synchroiser/synchroiser";
import { modalController } from "features/modals";

import { LowFirst } from "shared";

import { binding, bindingInXml } from "module/reactor/binding/binding";

import exportStore from "features/export-block/core/export-store";
import ExportBlock from "features/export-block/export-block";
import { ExportFormats } from "pages/settings/data/Fields";

import DetailBody from "./detail-body/detail-body";
import Field from "./ui/detail-fields";

import { Button, ButtonStyle, CheckBox, Select, Hint } from "components";

import { GridItem } from "types/entity";
import { Item } from "types";
import IProps from "../../IProps";
import DetailEntitiesStore, { IEntity } from "./core/detail-entities-store";
import { DetailActionsTypes, DetailHeaderActions } from "./data/detail-data";

import { ArrowToDown, MoreEllipsisVertical, Plus, Warning } from "shared";

import styles from "./detail.module.css";

//TODO почистить, когда все будет сделано
export interface IDetailDropDownProps extends IProps {
	getRecord: string;
	//TODO старые поля, мб пригодятся
	// detailEntityName: string;
	// detailColumn: string;
	// entityColumn: string;
	// title: string;
	viewColumnId?: string;
	entityName: string;
	entityTitle: string;
	gridInnerItem: string;
}

//TODO почистить, когда все будет сделано
const Detail = (props: IDetailDropDownProps) => {
	const navigate = useNavigate();
	const location = useLocation();
	const [idExportModal] = useState<string>(v4());
	const [idWarningModal] = useState<string>(v4());
	const [isOpened, setOpened] = useState(false);
	const [checkedList, setCheked] = useState<any[]>([]);
	const [isAddMode, setAddMode] = useState<boolean>(false);

	const field = JSON.parse(bindingInXml(props.services, props.gridInnerItem)) as GridItem;
	const detailConfig = field.detailConfig;
	// const detailEntityName = bindingInXml(props.services, props.detailEntityName);
	// const title = bindingInXml(props.services, props.title);
	const record: () => any = binding(props.services, props.getRecord);
	// const detailColumn = bindingInXml(props.services, props.detailColumn);
	// const entityColumn = bindingInXml(props.services, props.entityColumn);
	const viewColumnId = bindingInXml(props.services, props.viewColumnId);
	const entityName = binding(props.services, props.entityName);
	const entityTitle = binding(props.services, props.entityTitle);

	const detailEntityName = detailConfig!.entityName;
	const title = detailConfig!.detailTitle;
	const detailColumn = detailConfig!.detailColumn;
	const entityColumn = detailConfig!.entityColumn;

	const detailStore = useRef<DetailEntitiesStore>(
		new DetailEntitiesStore(detailEntityName, record()[entityColumn.toLowerCase()], detailColumn)
	);

	// const properties: string[] = binding(props.services, props.properties);

	useEffect(() => {
		//TODO запросы для получения entity отправляются каждый раз, когда поль-ль переходит в section-card. Возможно потом не надо, чтобы они отсылались, но пока так.
		if (record()?.id !== NEW_RECORD) {
			detailStore.current.load();
		}
	}, [record()?.id]);

	function onContentHiden() {
		if (record()?.id !== NEW_RECORD) {
			setOpened(!isOpened);
		}
	}

	//TODO старые методы, возможно пригодятся
	// function checkElement(value: any) {
	//     const valueIndex = checkedList.indexOf(value);
	//     if (valueIndex === -1) {
	//         setCheked([...checkedList, value]);
	//     }
	//     else {
	//         setCheked(checkedList.filter(item => item != value));
	//     }
	// }

	// function onCreateRow() {
	//     detailStore.current.createRow();
	//     setAddMode(false);
	// }

	// function addRow() {
	//     if (!isAddMode) detailStore.current.addEntity();
	//     setAddMode(true);
	// }

	// function onDeleteRow(entity: IEntity[]) {
	//     detailStore.current.deleteRow(entity);
	// }

	// function onHidenAddRow() {
	//     setAddMode(false);
	// }

	const addRow = useCallback(() => {
		if (detailStore.current.entity) {
			const viewColumnName = LowFirst(
				dispatcher.entity.get()?.entity?.columns.find((column) => column.columnId === viewColumnId)!.columnName!
			);
			const title = dispatcher.currentRow.get()[viewColumnName];

			const entityViewColumn = {
				displayValue: title,
				id: dispatcher.currentRow.get()?.id
			};

			//TODO подумать что делать с подставляемым значением в новой детали, когда будут кейсы, что связь детали и текущего раздела происходит не по id
			const state = {
				isDetail: true,
				isSectionDetail: detailStore.current.isSectionDetail,
				detailRowId: NEW_RECORD,
				detailEntityName: detailStore.current.entityName,
				entityViewColumn: entityViewColumn,
				entityName: entityName,
				entityTitle: entityTitle,
				detailLinkColumn: detailColumn,
				entityColumn: entityColumn
			};
			dispatcher.entity.set(detailStore.current.entity);
			dispatcher.entity.switchById(detailStore.current.entity.id);

			//TODO изменить в дальнейшем, поскольку нарушается последовательность шагов назад.
			const replace: boolean = !isEmpty(location.state) && !isNull(location.state) && !isUndefined(location.state);
			navigate(".", { state: state, replace: replace });
		}
	}, [detailStore.current.entity]);

	const closeConfirm = useCallback((event?: React.MouseEvent<HTMLElement, MouseEvent>) => {
		event?.stopPropagation();
		modalController.modalRemove(idWarningModal);
	}, []);

	const closeExport = useCallback(() => {
		closeConfirm();
		modalController.modalRemove(idExportModal);
		dispatcher.entity.onChangeCheckedAll(false, detailStore.current.entity?.entity);
		exportStore.clearCheckedColumns();
	}, [idExportModal, detailStore.current.entity?.entity]);

	const warningExportConfirm = useMemo(() => {
		return (
			<div className={styles.warningDialog}>
				<div className={styles.warningHeader}>
					<span className={styles.warningTitle}>Внимание</span>
					<Warning />
				</div>
				<div className={styles.warningDialogBody}>
					<span
						className={styles.warningText}
					>{`Вы действительно хотите отменить экспорт?\nТекущий прогресс будет утерян.`}</span>
				</div>
				<div className={styles.dialogFooter}>
					<Button caption="Вернуться к экспорту" onClick={closeConfirm} style={ButtonStyle.Subtle} isDisabled={false} />
					<Button caption="Отменить" onClick={closeExport} style={ButtonStyle.Danger} isDisabled={false} />
				</div>
			</div>
		);
	}, [closeConfirm, closeExport]);

	const openExportWarning = useCallback(() => {
		modalController.popupAdd({ id: idWarningModal, layout: warningExportConfirm, closeFunc: closeExport });
	}, [idWarningModal, warningExportConfirm]);

	const openExportBlock = useCallback(() => {
		if (detailStore.current.entity?.entity.countOfChecked && detailStore.current.entity?.entity.countOfChecked > 0) {
			modalController.popupAdd({
				id: idExportModal,
				layout: (
					<ExportBlock
						schema={detailStore.current.entity?.entityName!}
						pageTitle={detailStore.current.entity?.entityTitle!}
						formats={ExportFormats}
						title={"Экспорт данных: " + detailStore.current.entity?.entityTitle!}
						choosingCount={detailStore.current.entity?.entity.countOfChecked!}
						allCount={detailStore.current.entity?.entity.quality!}
						onClickClose={openExportWarning}
						close={closeExport}
					/>
				),
				closeFunc: openExportWarning
			});
		}
	}, [idExportModal, detailStore.current.entity]);

	const handleActionClick = useCallback(
		(value: Item | null, e?: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
			e?.stopPropagation();
			if (value) {
				switch (value.id) {
					case DetailActionsTypes.Export:
						dispatcher.entity.onChangeCheckedAll(true, detailStore.current.entity?.entity);
						openExportBlock();
						exportStore.initSignalR();
						break;
					case DetailActionsTypes.Customize:
						if (detailStore.current.isSectionDetail) {
							window.open(`/sectionMaster/edit/${detailStore.current.entity?.entityName.toLowerCase()}`, "_blank");
						} else {
							window.open(`/detailMaster/edit/${detailStore.current.entity?.entityName.toLowerCase()}`, "_blank");
						}
						break;
				}
			}
		},
		[detailStore.current.entity?.entityName]
	);

	const gridStyle: CSSProperties = useMemo(
		() => ({
			gridRow: `${field.y}/${field.y}`,
			gridColumn: `${field.x}/3`
		}),
		[field.x, field.y]
	);

	const detailClassName = classNames(styles.detail, {
		[`${props.className}`]: props.className
	});

	return (
		<>
			<div className={detailClassName} style={gridStyle}>
				<DetailHeader
					onContentHiden={onContentHiden}
					isOpen={isOpened}
					selectCount={detailStore.current.entity?.entity.countOfChecked ?? 0}
					title={title}
					onCreate={addRow}
					onChangeValue={handleActionClick}
					isDisabled={record().id === NEW_RECORD}
				/>
				{/* //TODO старое, возможно пригодится
            <ErrorWrapper isError={detailStore.current.error}> */}

				{
					isOpened &&
						detailStore.current.entity &&
						detailStore.current.entity.entity.visibleColumns.length > 0 &&
						detailStore.current.entity.entity.rows.length > 0 && (
							<DetailBody
								detailEntitiesStore={detailStore.current}
								openExportBlock={openExportBlock}
								viewColumnId={viewColumnId}
								entityName={entityName}
								entityTitle={entityTitle}
							/>
						)

					//TODO старое, возможно пригодится
					// && (detailStore.current.entitiesNotEmpty || isAddMode) &&
					//     <DetailBody
					//         titles={detailStore.current.titles!}
					//         onChecked={checkElement}
					//         checkedList={checkedList}
					//         setCheked={setCheked}
					//         entities={detailStore.current.entities!}
					//         store={detailStore.current}
					//         properties={properties}
					//         isAddMode={isAddMode}
					//         onCreateRow={onCreateRow}
					//         onDeleteRow={onDeleteRow}
					//         newRow={detailStore.current.newRow}
					//         onHidenAddRow={onHidenAddRow}
					//         onSaveRow={(entity: IEntity[]) => detailStore.current.updateRow(entity)}
					//     />
				}

				{isOpened && (!detailStore.current.entity || detailStore.current.entity.entity.rows.length == 0) && <Empty />}
				{/* {
                    isOpened && !detailStore.current.entitiesNotEmpty && !isAddMode &&
                    <Empty />
                } */}
				{/* </ErrorWrapper> */}
			</div>
		</>
	);
};

function ErrorWrapper(props: { isError: boolean; children: any }) {
	if (props.isError) {
		return <Error />;
	}

	return props.children;
}

interface DetailHeaderProps {
	onContentHiden: () => void;
	onCreate: () => void;
	isOpen: boolean;
	selectCount: number;
	title: string;
	isDisabled: boolean;
	onChangeValue: (value: Item | null, e?: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
}

function DetailHeader(props: DetailHeaderProps) {
	const detailHeaderClassName = classNames(styles.detailHeader, {
		[`${styles.disabledHeader}`]: props.isDisabled
	});

	const arrowToDownClassName = classNames(styles.icons, {
		[`${styles.iconOpen}`]: !props.isOpen
	});

	function onCreate(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
		event.stopPropagation();
		props.onCreate();
		if (!props.isOpen) props.onContentHiden();
	}

	const header = (
		<div className={detailHeaderClassName} onClick={props.onContentHiden}>
			<div className={styles.detailHeaderTitle}>
				<ArrowToDown className={arrowToDownClassName} />
				<span>{props.title}</span>
			</div>
			<div className={styles.detailHeaderTools}>
				<Button firstIcon={<Plus />} style={ButtonStyle.Icon} className={styles.addButton} onClick={onCreate} />

				<Select
					firstIcon={<MoreEllipsisVertical />}
					onChangeValue={props.onChangeValue}
					classNameButton={styles.actionButton}
					items={DetailHeaderActions}
					onClick={(e) => {
						e.stopPropagation();
					}}
				/>
			</div>
		</div>
	);

	return (
		<>
			{props.isDisabled ? (
				<Hint className={styles.disabledTooltip} tooltip="Пожалуйста, сначала сохраните запись">
					{header}
				</Hint>
			) : (
				header
			)}
		</>
	);
}

//TODO почистить, когда все будет сделано
interface DetailBodyProps {
	detailEntitiesStore: DetailEntitiesStore;
	openExportBlock: (value: boolean) => void;
	// titles: string[];
	// onChecked: (value: any) => void;
	// checkedList: string[];
	// setCheked: (values: any[]) => void;
	// entities: IEntity[][];
	// store: DetailEntitiesStore;
	// properties: string[];
	// isAddMode: boolean;
	// onCreateRow: () => void;
	// newRow: IEntity[];
	// onDeleteRow: (entity: IEntity[]) => void;
	// onHidenAddRow: () => void;
	// onSaveRow: (entity: IEntity[]) => void;
}

//TODO почистить, когда все будет сделано
// const DetailBody = observer((props: DetailBodyProps) => {
//     const titles = props.titles;
//     const [rowSelect, setRowSelect] = useState<number | null>(null);
//     const wrapperRef = useRef<HTMLDivElement>(null);

//     useEffect(() => {
//         window.addEventListener("click", handleClick);

//         return () => window.removeEventListener("click", handleClick);
//     })

//     function selectAll() {
//         if (props.checkedList.length === props.entities.length) {
//             props.setCheked([]);
//         }
//         else {
//             props.setCheked(props.entities.map(e => e[0].value));
//         }
//     }

//     function handleClick(event: Event) {
//         const target = event.target as HTMLElement;

//         // Проверяем, находится ли цель события вне элемента wrapperRef
//         if (wrapperRef.current && !wrapperRef.current.contains(target)) {
//             setRowSelect(null);
//         }
//     }

//     function selectRow(index: number) {
//         setTimeout(() => {
//             setRowSelect(index);
//             props.onHidenAddRow();
//         }, 50);
//     }

//     return (
//         <div className={styles.detailBody} ref={wrapperRef}>
//             {props.entities.map((entity) => {
//                 return entity.map((ent, i) => {
//                     if (ent.title !== "Id")
//                         return <BaseField name={ent.title} caption={ent.title} className={styles.meansOfCommunicationField} /*isMain={true}*/>
//                             {typeof ent.value === 'object' ?
//                                 <span>{ent.value?.toString()}</span>
//                                 : <Input
//                                     value={ent.value as string}
//                                     placeholder={"Выберите значение"}
//                                     onChangeValue={(value: string) => {
//                                         // ent.setValue(value, "site");
//                                     }}
//                                     inputStyle={InputStyleName.FullViewInput}
//                                 />
//                             }
//                         </BaseField>

//                 })
//             })}

//         </div>

//         // <div className={styles.detailBody} ref={wrapperRef}>
//         //     <div className={styles.detailBodyTitles}>
//         //         <CheckBox checked={props.checkedList.length === props.entities.length} onChangeChecked={selectAll} />
//         //         {titles.map(title => <span>{title}</span>)}
//         //     </div>

//         //     <div>
//         //         {props.isAddMode && <EditableDetailRow entity={props.newRow} onSave={props.onCreateRow} onDelete={props.onHidenAddRow} />}
//         //         {props.entities.map((entity, i) => rowSelect == i && !props.isAddMode ?
//         //             <EditableDetailRow
//         //                 entity={entity}
//         //                 onSave={() => {
//         //                     setRowSelect(null);
//         //                     props.onSaveRow(entity)
//         //                 }}
//         //                 onDelete={() => {
//         //                     props.onDeleteRow(entity);
//         //                     setRowSelect(null)
//         //                 }}
//         //             /> :
//         //             <div onClick={() => selectRow(i)}>
//         //                 <DetailRow
//         //                     onChecked={props.onChecked}
//         //                     entity={entity}
//         //                     isCheck={props.checkedList.some(l => l === entity[0].value)} />
//         //             </div>
//         //         )}
//         //     </div>
//         //     {props.store.CanLoadMore && <LoadMore onLoad={() => props.store.loadMore(props.properties)} />}
//         // </div>
//     )
// });

function LoadMore(props: { onLoad: () => void }) {
	return (
		<div className={styles.loadMore} onClick={props.onLoad}>
			<span>Показать ещё...</span>
		</div>
	);
}

function DetailRow(props: { onChecked: (value: any) => void; entity: IEntity[]; isCheck: boolean }) {
	return (
		<div className={styles.detailBodyValues}>
			<CheckBox checked={props.isCheck} onChangeChecked={() => props.onChecked(props.entity[0].value)} />
			{props.entity.slice(1, props.entity.length).map((e) => (
				<Field value={e.value} type={e.type} />
			))}
		</div>
	);
}

function Empty() {
	return (
		<div className={styles.empty}>
			<span>Нет данных</span>
		</div>
	);
}

function Error() {
	return (
		<div className={styles.error}>
			<span>Ошибка</span>
		</div>
	);
}

export default Detail;
