import { PADDINGS_COUNT, COLUMNS_GAP, MIN_WIDTH_COLUMN } from "../data";

export function getWidthColumn(containerWidth: number, count: number, padding?: number) {
    const widthWithoutPaddings = containerWidth - PADDINGS_COUNT * (padding ?? 0);
    const gaps = (count - 1) * COLUMNS_GAP;
    const availableWidth = widthWithoutPaddings - gaps;
    if (containerWidth > availableWidth) {
        return availableWidth / count;
    } else {
        return MIN_WIDTH_COLUMN;
    }
}