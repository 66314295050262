import styles from "./skeleton-grid.module.css";

function Card() {
    const elems = [<div className={styles.itemShort}></div>, <div className={styles.item}></div>, <div className={styles.itemLong}></div>]

    return (
        <div className={styles.card}>
            <div className={styles.checkBox}> </div>
            {[...Array(7)].map((_, index) => (
            <div key={index}>
                {elems[Math.floor(Math.random() * elems.length)]}
            </div>
        ))}
        </div>
    )
}

function Header() {
    return (
        <>
            <div className={styles.checkBox}> </div>
            <div className={styles.item}> </div>
            <div className={styles.itemLong}> </div>
            <div className={styles.itemLong}> </div>
            <div className={styles.item}> </div>
            <div className={styles.itemShort}> </div>
            <div className={styles.itemShort}> </div>
            <div className={styles.itemVeryLong}> </div>
        </>
    )
}

function SkeletonGrid() {
    const itemHeight = 40;
    const docHeight = document.documentElement.clientHeight;
    const itemCount = Math.floor((docHeight / itemHeight * 0.8));
    
    return (
        <div className={`${styles.skeletonBody} ${styles.skeleton}`}>
            <div className={styles.columnHeader}><Header /></div>
            <div className={styles.cards}>
                {[...Array(itemCount)].map((index) => <Card key={index}/>)}
            </div>
        </div >
    )
}

export default SkeletonGrid;