import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { isArray, isNull, isUndefined } from "lodash";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { Button, ButtonStyle, Input, InputSearch, InputStyleName, SelectStyleName, SelectStyles, CheckBox, Hint } from "components";
import { debounce } from "shared";

import { Item } from "types";
import { LoadingState } from "types/entity";

import { ArrowToDown, Cross, Locked } from "shared";

import styles from "./dropdown.module.css";

interface IDropdownProps extends React.HTMLAttributes<HTMLElement> {
	name?: string;
	value?: Item | Item[] | null;
	items: Item[];
	count?: number;

	isMultiSelect?: boolean;
	isDisabled?: boolean;
	isInvalid?: boolean;
	isInput?: boolean;
	isLightning?: boolean;
	invalidMessage?: string;
	isListDelay?: boolean;

	isRightIcon?: boolean;
	firstIcon?: JSX.Element;
	iconOpened?: JSX.Element;
	secondIcon?: JSX.Element;
	isRotateFirstIcon?: boolean;
	isRotateSecondIcon?: boolean;
	classNameIcon?: string;

	styles?: ButtonStyle;
	classNameInput?: string;
	classNameButton?: string;
	classNameOpenList?: string;
	classNameList?: string;
	onChangeValue: (value: Item | null, checkedValue?: boolean) => void;
	onFocusOut?: () => void;
	onItemsLoad?: (value: string | null, limit?: number) => any[] | any;
	notTranslate?: boolean;
	inputStyle?: InputStyleName;
	selectStyle?: SelectStyleName;
	tooltip?: string;
	inputLink?: string;
	linkTarget?: string;

	placeholder?: string;
}

const loadingLimit: number = 30;

function Dropdown(props: IDropdownProps) {
	const [isOpened, setOpened] = useState(false);
	const [noMatches, setNoMatches] = useState(false);
	const [ulElement, setUlElement] = useState<HTMLElement>();
	const [liSelected, setLiSelected] = useState<HTMLLIElement>();
	const [isTab, setIsTab] = useState(false);
	const [isCountVisible, setIsCountVisible] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const [count, setCount] = useState(props.count);
	const [loadingState, setLoadingState] = useState(LoadingState.NotAsked);

	let index = useRef<number>(-1);

	const wrapperRef = useRef<HTMLDivElement>(null);
	const inputElement = useRef<HTMLDivElement>(null);
	const linkRef = useRef<HTMLAnchorElement>(null);

	const selectStyle = SelectStyles[props.selectStyle ?? SelectStyleName.Base];
	const inputStyle = props.inputStyle ?? InputStyleName.BaseWithoutBorder;

	const clearButtonStyle = useMemo(() => {
		return props.isListDelay ? { marginRight: `10px` } : {};
	}, [props.isListDelay]);
	const clearButtonVisible = useMemo(() => {
		return props.value ? true : false;
	}, [props.value]);

	const firstIcon = useMemo(() => {
		if (props.iconOpened && isOpened) return props.iconOpened;
		else return props.firstIcon;
	}, [props.iconOpened, props.firstIcon, isOpened]);

	const wrapperClassNames = classNames(`${selectStyle.classNames} `, {
		[`${props.className} `]: props.className
	});

	const listClassNames = classNames(`${selectStyle.list} ${styles.dontScrollList}`, {
		[`${props.classNameList} `]: props.classNameList,
		[`${styles.dontScrollListWithCounter} `]: isCountVisible,
		[`${styles.visible} `]: isOpened,
		[`${styles.isButtonList} `]: !props.isInput && !props.inputLink
	});
	const listItemCaptionClassNames = classNames(`${styles.listItemCaption}`, {
		[`${styles.width230} `]: !props.isInput && !inputElement.current,
		[`${styles.multiSelectClass} `]: props.isMultiSelect
	});
	const wrapperInputClassNames = classNames(`${selectStyle.input} `, {
		[`${props.classNameInput} `]: props.classNameInput,
		[`${selectStyle.disabledInput} `]: props.isDisabled,
		[`${selectStyle.invalid} `]: props.isInvalid && !isOpened,
		[`${selectStyle.focusInput} `]: isOpened,
		[`${props.classNameOpenList} `]: props.classNameOpenList && isOpened
	});
	const inputClassNames = classNames(`${styles.inputSelect}`, {
		[`${props.classNameInput}`]: props.classNameInput
	});
	const buttonClassNames = classNames({
		[`${props.classNameButton} `]: props.classNameButton,
		[`${props.classNameOpenList} `]: props.classNameOpenList && isOpened
	});
	const firstIconClassNames = classNames(`${styles.iconButton} `, {
		[`${styles.close} `]: props.isRotateFirstIcon && isOpened,
		[`${props.classNameIcon} `]: props.classNameIcon
	});
	const secondIconClassNames = classNames(`${styles.iconButton} `, {
		[`${styles.close} `]: props.isRotateSecondIcon && isOpened
	});
	const dropdownClassNames = classNames(`${styles.selectButton} `, {
		[`${styles.close}`]: isOpened
	});
	const inputLinkWrapper = classNames(`${styles.linkWrapper}`, {
		[` ${styles.inputDisabled} `]: props.isDisabled
	});
	const inputLink = classNames(`${styles.linkStyle}`, {
		[`${styles.inputDisabled}`]: props.isDisabled
	});
	const arrowButton = classNames(`${styles.selectButton}`, {
		[`${styles.close} ${styles.listItemDisabled}`]: isOpened
	});

	let error = null;
	if (props.isInvalid && !isOpened) {
		if (props.invalidMessage !== undefined && props.invalidMessage.length > 1) {
			error = <div className={selectStyle.errorMessage}>{props.invalidMessage}</div>;
		}
	}

	const hideMenu = useCallback(() => {
		setSearchValue("");
		setOpened(false);
		if (props.onFocusOut) props.onFocusOut();
		document.removeEventListener("click", handleClick);
		setLiSelected(undefined);
	}, [props.onFocusOut]);

	useEffect(() => {
		window.addEventListener("scroll", hideMenu);
		return () => {
			window.removeEventListener("scroll", hideMenu);
		};
	}, []);

	useEffect(() => {
		if (props.count) {
			setCount(props.count);
		}
	}, [props.count]);

	//TODO дописать то как выглядит инпут с множественным выбором
	const getValue = useMemo(() => {
		if (Array.isArray(props.value)) {
			let result = "";
			props.value.map((item, index) => {
				if (index === 0) {
					result += item.displayValue ?? item.title;
				} else {
					result += `, ${item.displayValue ?? item.title}`;
				}
			});
			return result;
		} else {
			return props.value
				? props.value.title
					? props.value.title
					: props.value.displayValue
					? props.value.displayValue
					: props.value.name
				: props.name ?? "";
		}
	}, [props.value]);

	const search = useMemo(() => {
		return async (value: string) => {
			if (props.onItemsLoad !== undefined) {
				props.onItemsLoad(value, loadingLimit).then(function (response: any) {
					const newCount = response;
					if (newCount === -1) {
						setLoadingState(LoadingState.Error);
					} else {
						setCount(newCount);
						if (value.length > 0 && newCount == 0) {
							setNoMatches(true);
						} else {
							setNoMatches(false);
						}
						setLoadingState(LoadingState.Successful);
					}
				});
			}
		};
	}, [props]);

	const debouncedHandleSearch = useCallback(debounce(search, 250), [search]);

	const handleSearch = useCallback(
		(value: string) => {
			setLoadingState(LoadingState.Loading);
			setSearchValue(value);
			debouncedHandleSearch(value);
		},
		[debouncedHandleSearch]
	);

	const handleClick = useCallback(
		(event: Event) => {
			if (wrapperRef.current != null && !wrapperRef.current.contains(event.target as Node)) {
				hideMenu();
			}
		},
		[wrapperRef.current]
	);

	const openMenu = useCallback(async () => {
		if (props.isDisabled) return;
		setOpened(true);
		if (props.onItemsLoad !== undefined) {
			setLoadingState(LoadingState.Loading);
			const loadItemsCount = await props.onItemsLoad(null, loadingLimit);
			if (loadItemsCount === -1) {
				setLoadingState(LoadingState.Error);
			} else {
				setIsCountVisible(props.count ? props.count > 10 : loadItemsCount > 10);
				if (!props.count) {
					setCount(loadItemsCount);
				}
				setLoadingState(LoadingState.Successful);
			}
		}
	}, [props.onItemsLoad, props]);

	const onClick = useCallback(() => {
		if (props.isDisabled) return;
		setIsTab(false);
		if (!isOpened) {
			index.current = -1;
			openMenu();
			document.addEventListener("click", handleClick);
		} else {
			hideMenu();
			document.removeEventListener("click", handleClick);
		}
	}, [props.isDisabled, isOpened, openMenu, handleClick, hideMenu]);

	const selectItem = useCallback(
		(item: Item | null, value?: boolean) => {
			if (!props.isMultiSelect) {
				if (!item?.isDisabled && !item?.isLocked) {
					props.onChangeValue(item, value);
					hideMenu();
				}
			} else {
				if (!item?.isDisabled && !item?.isLocked) {
					props.onChangeValue(item, value);
				}
			}
		},
		[props, hideMenu]
	);

	const keyup = useCallback(
		(event: React.KeyboardEvent<HTMLDivElement>) => {
			if (props.isDisabled) return;
			if (event.key === "Tab") {
				setIsTab(true);
				if (isOpened) {
					hideMenu();
				} else openMenu();
			} else {
				setIsTab(false);
				if (event.key === "Enter") {
					if (liSelected) {
						props.items.forEach((item) => {
							if (liSelected.id.toString() === item.id.toString()) selectItem(item);
						});
					}
				}
			}
		},
		[props.isDisabled, isOpened, liSelected, hideMenu, openMenu, props.items, selectItem]
	);

	const itemKeydown = useCallback(
		(event: React.KeyboardEvent<HTMLUListElement>) => {
			if (ulElement) {
				const maxLength = ulElement.getElementsByTagName("li").length;
				const firstElementFromList = ulElement.getElementsByTagName("li")[0];
				let nextElementFromList = null;
				if (event.key === "ArrowDown") {
					index.current = index.current + 1;
					if (liSelected) {
						nextElementFromList = ulElement.getElementsByTagName("li")[index.current];
						if (typeof nextElementFromList && index.current <= maxLength - 1!) {
							if (
								Math.round(nextElementFromList.offsetTop + nextElementFromList.offsetHeight) >=
								ulElement?.clientHeight + ulElement?.scrollTop
							)
								ulElement?.scrollBy({ top: nextElementFromList.offsetHeight });
							setLiSelected(nextElementFromList);
						} else {
							ulElement?.scrollTo(0, 0);
							index.current = 0;
							setLiSelected(firstElementFromList);
						}
					} else {
						setLiSelected(firstElementFromList);
					}
				}

				if (event.key === "ArrowUp") {
					index.current = index.current - 1;
					if (liSelected) {
						nextElementFromList = ulElement!.getElementsByTagName("li")[index.current];

						if (typeof nextElementFromList !== undefined && index.current >= 0) {
							if (nextElementFromList.offsetTop <= ulElement?.scrollTop)
								ulElement?.scrollBy({ top: -nextElementFromList.offsetHeight });
							setLiSelected(nextElementFromList);
						} else {
							ulElement?.scrollTo(0, ulElement.scrollHeight);
							index.current = maxLength - 1;
							setLiSelected(ulElement.getElementsByTagName("li")[index.current]);
						}
					} else {
						index.current = maxLength - 1;
						setLiSelected(ulElement.getElementsByTagName("li")[maxLength - 1]);
						ulElement?.scrollTo(0, ulElement.scrollHeight);
					}
				}
			}
		},
		[ulElement, liSelected, index.current]
	);

	const setPositionsContextMenu = useCallback(
		(element: HTMLUListElement) => {
			if (element && wrapperRef.current !== null) {
				const position = element.getBoundingClientRect();
				if (!props.notTranslate) {
					let winh = window.innerHeight;
					let raz = winh - position.bottom - 15;
					if (position.bottom > winh) {
						element.style.transform =
							"translate(" + wrapperRef.current!.children?.item(0)!.getBoundingClientRect().width + "px, " + raz + "px)";
					}
					if (position.right > window.innerWidth) {
						element.style.left = -position.width + wrapperRef.current?.getBoundingClientRect().width! + "px";
					}
				}
				if (inputElement.current) {
					const wrapperPosition = inputElement.current.getBoundingClientRect();
					element.style.width = wrapperPosition.width + "px";
				}
			}
			setUlElement(element);
		},
		[wrapperRef.current, props.notTranslate, inputElement.current]
	);

	//TODO возможно понадобится проверка isTab далее в этом месте
	const handleOnBlur = useCallback(
		(event: React.FocusEvent<HTMLDivElement, Element>) => {
			// if (isTab)
			if (event.relatedTarget) {
				if (!event.currentTarget.contains(event.relatedTarget)) {
					hideMenu();
				}
			}
		},
		[hideMenu]
	);

	const listItem = useCallback(
		(item: Item) => {
			return (
				<>
					{item.icon && <div className={styles.icon}>{item.icon}</div>}
					<div className={listItemCaptionClassNames}>
						<span className={styles.listItemName}>
							{item.title ? item.title : item.displayValue ? item.displayValue : item.name}
						</span>
						{item.note && <span className={styles.listItemNote}>{item.note}</span>}
						{props.isMultiSelect && (
							<CheckBox
								className={styles.checkBox}
								checked={item.isChecked!}
								onChangeChecked={(value: boolean) => selectItem(item, value)}
							/>
						)}
					</div>
				</>
			);
		},
		[props.isMultiSelect, listItemCaptionClassNames, selectItem]
	);

	const counter = useMemo(() => {
		if (count)
			return (
				<>
					<span className={styles.count}>Найдено: {count}</span>
					<div className={styles.divider}></div>
				</>
			);
		return <></>;
	}, [count]);

	const itemsMapping = useMemo(() => {
		if (props.items && props.items.length > 0)
			return props.items.map((item, i) => {
				const listItemClassNames = classNames(`${styles.listItem} `, {
					[`${styles.listItemDisabled} `]: item.isDisabled || item.isLocked,
					[`${styles.exit} `]: item.isRed,
					[`${item.classNames} `]: item.classNames,
					[`${styles.choose} `]: liSelected && liSelected.id.toString() === item.id.toString()
				});

				return (
					<li
						key={`${item.id}`}
						id={`${item.id}`}
						className={listItemClassNames}
						onClick={() => {
							selectItem(item, !item.isChecked);
						}}
					>
						{listItem(item)}
					</li>
				);
			});
		else return <></>;
	}, [selectItem, listItem, props.items, liSelected]);

	const dropdownList = useMemo(() => {
		const scrollClassNames = classNames(`${styles.scroll} `, {
			[`${styles.scrollWithCounter} `]: isCountVisible
		});
		return (
			<ul className={listClassNames} ref={setPositionsContextMenu} onKeyDown={itemKeydown}>
				{loadingState === LoadingState.Error ? (
					<span className={styles.noMatches}>Не найдено</span>
				) : (
					isCountVisible && (
						<InputSearch
							key="InputSearch"
							value={searchValue}
							placeholder="Поиск"
							onChangeValue={handleSearch}
							className={styles.inputSearch}
							focus={isOpened}
						/>
					)
				)}
				{loadingState === LoadingState.Loading ? (
					<div className={styles.loading}>
						<svg className={styles.circleLoader} viewBox="0 0 50 50">
							<circle
								className={styles.circle}
								cx="25"
								cy="25"
								r="20"
								fill="none"
								stroke={`var(--color-gray-300)`}
								strokeWidth="3"
							/>
						</svg>
					</div>
				) : noMatches ? (
					<span className={styles.noMatches}>Не найдено</span>
				) : (
					<>
						{isCountVisible && counter}
						<div className={scrollClassNames}>{itemsMapping}</div>
					</>
				)}
			</ul>
		);
	}, [
		listClassNames,
		searchValue,
		isOpened,
		counter,
		itemsMapping,
		noMatches,
		loadingState,
		setPositionsContextMenu,
		itemKeydown,
		handleSearch,
		isCountVisible
	]);

	const tooltip = useMemo(() => {
		return props.tooltip ? (
			<Hint tooltip={props.tooltip}>
				<Button
					classNameFirstIcon={firstIconClassNames}
					classNameSecondIcon={secondIconClassNames}
					className={buttonClassNames}
					firstIcon={firstIcon}
					secondIcon={props.secondIcon}
					caption={getValue}
					iconOnRight={props.isRightIcon}
					style={props.styles}
					onClick={onClick}
					onFocus={props.onFocus}
					onBlur={props.onBlur}
					selected={isOpened}
				/>
			</Hint>
		) : (
			<></>
		);
	}, [props, isOpened, getValue, firstIcon]);

	const onClickWithoutClickToLink = useCallback(
		(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			if (!linkRef.current?.contains(event.target as Node)) {
				onClick();
			}
		},
		[onClick, linkRef.current]
	);

	const linkInput = useMemo(() => {
		return props.inputLink && !props.isInput ? (
			<div onClick={onClickWithoutClickToLink} ref={inputElement} className={inputLinkWrapper}>
				{!isNull(props.value) && !isUndefined(props.value) && JSON.stringify(props.value)?.length > 2 ? (
					<Link ref={linkRef} to={props.inputLink} className={inputLink} target={props.linkTarget}>
						{!isNull(props.value) && !isUndefined(props.value)
							? !isArray(props.value) && !isUndefined(props.value.title)
								? props.value.title
								: !isArray(props.value) && !isUndefined(props.value.displayValue)
								? props.value.displayValue
								: !isArray(props.value)
								? props.value.name
								: ""
							: ""}
					</Link>
				) : (
					<span className={styles.placeholder}>{props.placeholder ?? "Выберите значение..."}</span>
				)}
				{props.isDisabled ? (
					<Locked className={styles.iconResult} />
				) : (
					<Button onClick={onClick} firstIcon={<ArrowToDown />} style={ButtonStyle.Icon} className={arrowButton} />
				)}
			</div>
		) : (
			<></>
		);
	}, [props, inputLink, onClick, onClickWithoutClickToLink]);

	return (
		<div ref={wrapperRef} className={wrapperClassNames} onKeyUp={keyup} onKeyDown={keyup} onBlur={handleOnBlur}>
			{props.isInput && !props.inputLink ? (
				<div ref={inputElement} className={wrapperInputClassNames}>
					<Input
						tabIndex={-1}
						value={getValue}
						placeholder={props.placeholder ?? "Выберите значение..."}
						onChangeValue={() => {}}
						onClick={onClick}
						className={inputClassNames}
						isInvalid={props.isInvalid}
						isLightning={props.isLightning}
						inputStyle={inputStyle}
					/>
					{isOpened && (
						<Button
							onClick={(e) => {
								props.onChangeValue(null);
							}}
							firstIcon={<Cross />}
							style={ButtonStyle.Icon}
							className={styles.clearButton}
							styleButton={clearButtonStyle}
							isVisible={clearButtonVisible}
						/>
					)}

					{!props.isListDelay && (
						<Button firstIcon={<ArrowToDown />} style={ButtonStyle.Icon} className={dropdownClassNames} onClick={onClick} />
					)}
				</div>
			) : props.tooltip ? (
				tooltip
			) : props.inputLink && !props.isInput ? (
				linkInput
			) : (
				<Button
					classNameFirstIcon={firstIconClassNames}
					classNameSecondIcon={secondIconClassNames}
					className={buttonClassNames}
					firstIcon={firstIcon}
					secondIcon={props.secondIcon}
					caption={getValue}
					iconOnRight={props.isRightIcon}
					style={props.styles}
					onClick={onClick}
					onFocus={props.onFocus}
					onBlur={props.onBlur}
					selected={isOpened}
				/>
			)}
			{isOpened && dropdownList}
			{error}
		</div>
	);
}

export default Dropdown;
