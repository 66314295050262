import { IFilterItem } from "entities/search";
import { EntityType } from "types/entity";

const filterTable: IFilterItem[] = [
  {
    table: EntityType.Contact,
    title: "Контакты",
  },
  {
    table: EntityType.Account,
    title: "Контрагенты",
  },
  {
    table: EntityType.Sale,
    title: "Продажи",
  },
  {
    table: EntityType.Lead,
    title: "Лиды",
  },
];

export default filterTable;
