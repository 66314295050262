import { action, makeObservable, observable } from "mobx";

import authStore from "AuthStore";
import { api } from "shared";

import { SavingState } from "entities/Entity";
import { LoadingState } from "entities/ListStore";
import { ICommentData } from "features/comments/old-comments/types/types";
import { SignalRService } from "shared";

interface ICommentEntity {
	id: string | null;
	entityName: string;
	data: ICommentData[];
	signalRService: SignalRService | null;
}

export class CommentEntity implements ICommentEntity {
	id: string | null;
	entityName: string;
	data: ICommentData[];
	loadingState: LoadingState;
	savingState: SavingState;
	signalRService: SignalRService | null = null;

	constructor(entityName: string, id: string) {
		makeObservable(this, {
			id: observable,
			entityName: observable,
			data: observable,

			loadingState: observable,
			savingState: observable,

			setValue: action,
			loadComments: action,
			sendComment: action,
			delete: action
		});

		this.entityName = entityName;
		this.id = id;
		this.data = [];

		this.loadingState = LoadingState.NotAsked;
		this.savingState = SavingState.NotAsked;
	}

	get isLoading(): boolean {
		return this.loadingState === LoadingState.Loading;
	}

	get isLoaded(): boolean {
		return this.loadingState === LoadingState.Loaded;
	}

	get isError(): boolean {
		return this.loadingState === LoadingState.Failed;
	}

	get isSaving(): boolean {
		return this.savingState === SavingState.Saving;
	}

	get isSaved(): boolean {
		return this.savingState === SavingState.Saved;
	}

	setValue(value: any, fieldName: string) {
		Reflect.set(this, fieldName, value);
	}

	InitSignalR() {
		this.signalRService = new SignalRService("SubscribeToFullView", this.id!);
		this.signalRService.startConnection();

		this.signalRService.addEventListener("FullViewCreated", (fullViewId, entityJson) => {
			console.log(`FullView(${this.entityName}) Comment created:`, entityJson);
			//Вся сущность в entityJson, можно отрисовать добавленный коммент без запроса
		});

		this.signalRService.addEventListener("FullViewUpdated", (fullViewId, updatedCommentId, updatedFieldsJson) => {
			console.log(`FullView(${this.entityName}) Comment by id: ${updatedCommentId} updated. Updated fields:`, updatedFieldsJson);
			// Обновление соответствующих полей записи или модалка
		});

		this.signalRService.addEventListener("FullViewDelited", (fullViewId, deletedRecordId) => {
			console.log(`FullView(${this.entityName})  Comment with id: ${deletedRecordId} delited`);
			//Можо удалить коммент из блока без запроса
		});
	}

	stopSignalRConnection() {
		this.signalRService?.stopConnection();
	}

	async loadComments(firstTime?: boolean) {
		try {
			if (firstTime) {
				this.setValue(LoadingState.Loading, "loadingState");
			}

			// const response = await api.get(`/api/Comment/comments/${this.entityName}/${this.id}`);
			const response = await api.http.comment.getComments(this.entityName, this.id!).get();

			if (response.data.success && response.data !== null) {
				if (firstTime) {
					this.setValue(LoadingState.Loaded, "loadingState");
				}
				this.data = response.data.data;
			} else {
				if (firstTime) {
					this.setValue(LoadingState.Failed, "loadingState");
				}
				console.log("Something went wrong :(");
				console.log(response.data.message);
			}
		} catch (error: any) {
			var message = error.response.message;
			console.error("An error occurred:", error);
		} finally {
		}
	}

	async sendComment(commentId: string, text: string) {
		try {
			const hasId = this.data.some((x) => x.id === commentId);
			if (authStore.loggedIn) {
				if (text !== "") {
					if (hasId) {
						await api.http.comment.sendComments(commentId).post({ text: text });
					} else {
						await api.http.comment.sendComments(commentId).post({
							entityName: this.entityName,
							entityId: this.id,
							userId: authStore.userId,
							text: text
						});
					}
				}
				await this.loadComments();
			} else {
				return;
			}
		} catch (error: any) {
			var message = error.response.data.message;
			console.error("An error occurred:", error);
		} finally {
		}
	}

	async delete(id: string, userId: string) {
		try {
			if (authStore.loggedIn && authStore.userId === userId) {
				const response = await api.http.comment.commentById(id).delete();
				if (response.data.success === true) {
					await this.loadComments();
				}
			}
		} catch (error: any) {
			var message = error.response.data.message;
			console.error("An error occurred:", error);
		}
	}

	handleError(error: string): void {
		if (error === "name_is_empty") {
			// this.validName = false;
			// this.errorName = "";
		}
	}
}

export default CommentEntity;
