import { AxiosError } from "axios";

/**
 * @class QueueManager - Менеджер очереди запросов
 */
export class QueueManager {
	private isRefreshing: boolean = false;
	private failedQueue: {
		resolve: (token: string) => void;
		reject: (error: AxiosError) => void;
	}[] = [];

	/**
	 * Обрабатывает очередь запросов после успешного или неудачного обновления токена
	 * @param {AxiosError | null} error - Ошибка, если есть
	 * @param {string | null} token - Токен, если успешно обновлен
	 */
	processQueue(error: AxiosError | null, token: string | null = null) {
		this.failedQueue.forEach((prom) => {
			if (error) {
				prom.reject(error);
			} else {
				prom.resolve(token!);
			}
		});

		this.failedQueue = [];
	}

	addToQueue(resolve: (token: string) => void, reject: (error: AxiosError) => void) {
		this.failedQueue.push({ resolve, reject });
	}

	setRefreshing(isRefreshing: boolean) {
		this.isRefreshing = isRefreshing;
	}

	getRefreshing() {
		return this.isRefreshing;
	}
}
