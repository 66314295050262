import { v4 } from "uuid";

import { store } from "store";

import { ModalData } from "features/modals/viewer/modal-viewer";
import {action} from "mobx";

export class ModalController {
	add = action(("add modal"), (modal : ModalData) => {
        store.modals.push({id: modal.id, modal: modal});
		return modal.id;
	});

	remove = action(("remove modal"),(id: string) => {
		store.modals = store.modals.filter((modal) => modal.id !== id);
	});
}

export const modalController = new ModalController();