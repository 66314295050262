import { action, makeAutoObservable } from "mobx";
import { isEmpty, lowerFirst, upperFirst } from "lodash";
import { stringify } from "flatted";
import { v4 } from "uuid";

import { dispatcher, selector, store, sessionStore } from "store";
import { synchroiser } from "synchroiser";
import { api } from "shared";

import { getPriorityColumnName, getStagesColumnName } from "synchroiser/lib";
import { getKanbanColumns } from "./get-kanban-columns";
import { LowFirst } from "shared";

import IFilter, { ComparisonType, DataValueType } from "entities/filter/IFilter";
import { FilterStore } from "entities/filter/FilterStore";
import FilterColumnType from "entities/ColumnType";
import ISort from "entities/ISort";
import { ViewMode } from "../type";
import { STAGE_UPLOADING_QUANTITY_COMPENSATOR, UPLOAD_QUANTITY } from "../constants";
import { SubscribeType } from "types";
import ResponseService from "entities/Response";
import { AxiosResponse } from "axios";
import authStore from "AuthStore";

type StageId = string;

class SinglePageSynchroiser {
	private _sectionLoader: boolean;
	private _viewLoader: boolean;
	private _kanbanLoader: Array<StageId>;
	private readonly _stageQuantity: { [key: StageId]: number };
	private _viewMode: ViewMode;
	private _lastSort: ISort | null;
	private _draggedRecord: string | null;
	private _cardCanceledId: string | null;

	constructor() {
		this._sectionLoader = true;
		this._viewLoader = true;
		this._kanbanLoader = [];
		this._stageQuantity = {};
		this._viewMode = ViewMode.GRID;
		this._lastSort = null;
		this._draggedRecord = null;
		this._cardCanceledId = null;

		makeAutoObservable(this);
	}

	public setDraggedRecord(recordId: string): void {
		action(() => {
			this._draggedRecord = recordId;
			setTimeout(() => {
				if (this._draggedRecord === recordId) {
					this._draggedRecord = null;
				}
			}, 3000);
		})();
	}

	public resetDraggedRecord(): void {
		action(() => {
			this._draggedRecord = null;
		})();
	}

	public get draggedRecord(): string | null {
		return this._draggedRecord;
	}

	public set setLastSort(sort: ISort) {
		this._lastSort = sort;
	}

	public get lastSort(): ISort | null {
		return this._lastSort;
	}

	public get cardCanceledId() {
		return this._cardCanceledId;
	}

	public set setCardCanceledId(cardId: string | null) {
		this._cardCanceledId = cardId;
	}

	/**
	 * @description stage set view mode status true
	 */
	public viewLoading() {
		this._viewLoader = true;
	}

	/**
	 * @description stage set view mode status false
	 */
	public viewLoaded() {
		singlePageSynchroiser._viewLoader = false; // ПОЧЕМУТО THIS НЕ ОТРАБАТЫВАЕТ, он undefined
	}

	public get sectionLoader(): boolean {
		return this._sectionLoader;
	}

	public get viewLoader(): boolean {
		return this._viewLoader;
	}

	public stageIsLoading(stageId: string): boolean {
		return this._kanbanLoader.includes(stageId);
	}

	public addStageLoadingStatus(stageId: string): void {
		action("stage set loading status true", () => {
			this._kanbanLoader.push(stageId);
		})();
	}

	/**
	 * @description stage set loading status false
	 */
	public removeStageLoadingStatus(stageId: string): void {
		this._kanbanLoader = this._kanbanLoader.filter((comparisonStageId) => comparisonStageId !== stageId);
	}

	public setStageQuantityRecords(columnId: StageId, quantity: number): void {
		this._stageQuantity[columnId] = quantity;
	}

	public getStageQuantityRecords(columnId: StageId): number {
		return this._stageQuantity[columnId];
	}

	async init(
		entityName: string,
		callbacks: {
			viewModeCallback: (viewMode: ViewMode) => void;
		}
	) {
		const currentEntityName = store.sections.find(
			(section) => section.entityName.toLowerCase() === entityName.toLowerCase()
		)?.entityName;
		this._sectionLoader = true;
		await synchroiser.getPureEntity(entityName);

		const sectionWizzard = await synchroiser.switchSectionWizzard(entityName);
		action("SECTION LOADER FALSE", () => {
			this._sectionLoader = false;
		})();
		action("LOADING STATUS TRUE", () => {
			this._viewLoader = true;
		})();

		const gridItems = dispatcher.sectionWizzard.getAllGridItems();
		const priorityFieldsName = getPriorityColumnName(gridItems);

		if (sectionWizzard?.kanbanIsEnabled) {
			if ((sessionStore.getBufferedViewMode(currentEntityName ?? entityName) ?? ViewMode.KANBAN) === ViewMode.KANBAN) {
				this._viewMode = ViewMode.KANBAN;
				sessionStore.setBufferedViewMode(currentEntityName ?? entityName, ViewMode.KANBAN);
				callbacks.viewModeCallback(ViewMode.KANBAN);

				await getKanbanColumns(entityName);

				await synchroiser.getPriority(priorityFieldsName ?? "");
				action("LOADING STATUS FALSE", () => {
					this._viewLoader = false;
				})();
			} else {
				this._viewMode = ViewMode.GRID;
				sessionStore.setBufferedViewMode(currentEntityName ?? entityName, ViewMode.GRID);
				callbacks.viewModeCallback(ViewMode.GRID);

				await synchroiser.getEntity();
				action("LOADING STATUS FALSE", () => {
					this._viewLoader = false;
				})();
			}
		} else {
			this._viewMode = ViewMode.GRID;
			sessionStore.setBufferedViewMode(currentEntityName ?? entityName, ViewMode.GRID);
			callbacks.viewModeCallback(ViewMode.GRID);
			await synchroiser.getEntity();
			action("VIEW LOADER FALSE", () => {
				this._viewLoader = false;
			})();
		}
	}

	async uploadStageCards(entityName: string, stageId: string, startPosition: number, limit?: number): Promise<void> {
		const stageName = selector.sectionWizzard.getStageFieldBySpecialization()?.fieldConfig?.columnName ?? "";
		const gridItems = dispatcher.sectionWizzard.getAllGridItems();
		if (
			sessionStore.getSort(entityName) &&
			stringify(dispatcher.entity.get()?.entity?.sort) !== stringify(sessionStore.getSort(entityName))
		) {
			dispatcher.entity.setSort(sessionStore.getSort(entityName));
		}
		const sort = dispatcher.entity.get()?.entity?.sort;
		const staticGroupId = dispatcher.entity.get()?.entity.filter?.staticGroup?.id;
		const stageFieldsName = getStagesColumnName(gridItems) as string;
		const priorityFieldsName = getPriorityColumnName(gridItems) as string;
		let needColumns: Array<string> = [];
		if (
			dispatcher.sectionWizzard.getSectionWizzard()?.kanbanConfig?.cardDesign.additionalFields &&
			dispatcher.sectionWizzard.getSectionWizzard()?.kanbanConfig?.cardDesign.additionalFields
		) {
			needColumns =
				dispatcher.sectionWizzard
					.getSectionWizzard()
					?.kanbanConfig?.cardDesign.additionalFields.map(
						(item) =>
							gridItems.find((gridItem) => gridItem.fieldConfig?.columnId === item.columnId)?.fieldConfig?.columnName ?? ""
					) ?? [];
			needColumns = [
				...needColumns,
				...(dispatcher.sectionWizzard
					.getSectionWizzard()
					?.kanbanConfig?.cardDesign.userFields.map(
						(item) =>
							gridItems.find((gridItem) => gridItem.fieldConfig?.columnId === item.columnId)?.fieldConfig?.columnName ?? ""
					) ?? [])
			];
		}

		const stageFieldName = getStagesColumnName(gridItems);

		const currentEntityName = store.sections.find((section: any) => section.id === store.currentEntityId)?.entityName;

		const filter = new FilterStore(
			currentEntityName!,
			(dispatcher.entity.get()?.entity.filter?.savedFilter?.filterInfo as IFilter) ?? null,
			null
		);
		filter.addFilter();
		filter.filters[filter.filters.length - 1].attribute = upperFirst(stageFieldName ?? "");
		filter.filters[filter.filters.length - 1].attributeType = FilterColumnType.Lookup;
		filter.filters[filter.filters.length - 1].comparisonType = ComparisonType.Equal;
		filter.filters[filter.filters.length - 1].rightExpression = filter.buildRightExpression(
			{
				id: stageId,
				name: stageName
			},
			DataValueType.Lookup
		);

		needColumns.push(stageFieldsName);
		needColumns.push(priorityFieldsName);

		needColumns = Array.from(new Set(needColumns));

		this.addStageLoadingStatus(stageId);
		api.http.entity
			.recordsListWithColumns()
			.post({
				entityName: dispatcher.entity.get()?.entityName,
				columnNames: needColumns,
				offset: startPosition,
				limit: limit ?? UPLOAD_QUANTITY,
				filter: filter.serialize(),
				sort: sort ?? null,
				staticGroupId: staticGroupId ?? null
			})
			.then((response) => {
				const existingRecords = dispatcher.entity.get()?.entity.rows;
				if (existingRecords && response.data.data.records) {
					action("UPLOADED RECORDS", () => {
						dispatcher.entity.get()!.entity.rows = Array.prototype.concat(existingRecords, response.data.data.records);
					})();
					this.removeStageLoadingStatus(stageId);
				}
			});
	}

	public async stageMovementCompensator(entityName: string, stageId: string, startPosition: number) {
		// TODO добавить debounce
		this.addStageLoadingStatus(stageId);
		await this.uploadStageCards(entityName, stageId, startPosition, STAGE_UPLOADING_QUANTITY_COMPENSATOR);
		this.removeStageLoadingStatus(stageId);
	}

	async applyFilter(entityName: string): Promise<void> {
		const gridItems = dispatcher.sectionWizzard.getAllGridItems();
		const priorityFieldsName = getPriorityColumnName(gridItems);

		this._viewLoader = true;
		if (this._viewMode === ViewMode.KANBAN) {
			await synchroiser.getPriority(priorityFieldsName ?? "");
			await getKanbanColumns(entityName);
		} else {
			await synchroiser.getEntityWithFilter();
		}
		this._viewLoader = false;
	}

	/**
	 * @description Метод для сохранения/удаления объекта сортировки в LocalStorage
	 * @param sort - сохраняемый объект сортировки
	 * @param entityName - названия раздела
	 */
	setSortInLocalStorage(sort: ISort | null, entityName?: string): void {
		if (entityName) {
			sessionStore.setSort(entityName, sort);
		}
	}

	async setFlag(recordId: string, commentText: string, mode: "add" | "remove"): Promise<void> {
		const uuid = v4();
		const currentEntityName = dispatcher.entity.get()?.entityName;
		const date = new Date().toISOString();
		if (!currentEntityName) {
			return;
		}

		dispatcher.subscribers.add(uuid);
		dispatcher.reactions.set(uuid, SubscribeType.PENDING_SEND);

		const data = {
			flagValue: mode === "add" ? true : false,
			comment: {
				text: commentText,
				id: uuid,
				userId: store.user.id,
				entityId: recordId,
				entityName: currentEntityName
			}
		};

		dispatcher.comments.add({
			id: uuid,
			userId: store.user.id,
			entityId: recordId,
			entityName: currentEntityName,
			text: commentText,
			userName: store.user.userName,
			isOwner: true,
			createdOn: date,
			modifiedOn: date
		});

		await api.http.flag
			.updateFlag()
			.post(data)
			.then((response: any) => {
				dispatcher.reactions.reset(uuid);
				if (!response || response.status >= 400 || !response.data.success) {
					return Promise.reject();
				}
				const record = dispatcher.entity.get()?.entity.rows.find((row) => row.id === recordId);
				const sysColumnNameFlag = selector.sectionWizzard.getSysFlagColumn()?.fieldConfig?.columnName;
				const sysColumnNameFlagDate = selector.sectionWizzard.getSysFlagDateColumn()?.fieldConfig?.columnName;
				if (sysColumnNameFlag && sysColumnNameFlagDate) {
					record[LowFirst(sysColumnNameFlag)] = mode === "add" ? true : false;
					store.oldRowValue[LowFirst(sysColumnNameFlag)] = mode === "add" ? true : false;
					record[LowFirst(sysColumnNameFlagDate)] = mode === "add" ? new Date(Date.now()).toISOString() : null;
					store.oldRowValue[LowFirst(sysColumnNameFlagDate)] = mode === "add" ? new Date(Date.now()).toISOString() : null;
				}
			});
	}

	/**
	 * Залогировать время для записи
	 * @param recordId
	 * @param commentText
	 * @returns
	 */
	async timeLogging(recordId: string, minutes: string, commentText: string): Promise<void> {
		const TIME_LOGGING_SECTION_NAME = "SysTimeLogging";
		const currentEntityName = dispatcher.entity.get()?.entityName;

		if (!currentEntityName) {
			return;
		}

		const columnName = `${lowerFirst(currentEntityName)}Id`;
		const currentDate = new Date().toISOString();
		const requestData: any = {
			entityName: TIME_LOGGING_SECTION_NAME,
			values: [
				{
					propertyName: "CommentText",
					propertyValue: commentText
				},
				{
					propertyName: "User",
					propertyValue: authStore.userId
				},
				{
					propertyName: "Minutes",
					propertyValue: minutes
				},
				{
					propertyName: "Date",
					propertyValue: currentDate
				},
				{
					propertyName: columnName,
					propertyValue: recordId
				}
			]
		};

		await api.http.entity
			.createEntity()
			.post(requestData)
			.then((response: any) => {
				if (!response || response.status >= 400 || !response.data.success) {
					return Promise.reject();
				}
			});
	}
}

export const singlePageSynchroiser = new SinglePageSynchroiser();
