import { observer } from "mobx-react-lite";
import { useLocation, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";

import { ButtonStyle } from "components/button/button";
import AccessRightsLink from "./AccessRightsLink";
import NavigationTitle from "../NavigationTitle";
import InputSearch from "components/input/input-search/input-search";
import Select from "components/select/select";
import DataGrid from "components/grid/data-grid";

import { UsersSettings } from "../data/Fields";
import accessStore, { AccessItems, IAccessRight, accessItemsIds } from "entities/accessRights/AccessRights";
import accessRightLink from "entities/accessRights/AccessRightLinkStore";

import { TriangleToDown } from "shared";

import styles from "./AccessRights.module.css";
import ISort from "entities/ISort";

const AccessRightsMainPanel = observer(function () {
	const [tab, setTab] = useState<JSX.Element>(<></>);
	const navigate = useNavigate();
	const location = useLocation();
	let [clicked, setClick] = useState<boolean>(false);

	const navigateTo = useCallback((path: string) => {
		const subStr = path.split("/");
		subStr.shift();
		if (subStr.length === 4) {
			navigate(path);
			setTab(<AccessRightsLink entityId={accessStore.entityId} title={accessStore.entityTitle} />);
		} else {
			setClick(false);
		}
	}, []);

	useEffect(() => {
		accessStore.getValueSelect(AccessItems.filter((item) => item.id === accessItemsIds.All)[0]);
		accessStore.getDataToGridSettings();
	}, []);

	useEffect(() => {
		navigateTo(location.pathname);
	}, [location.pathname]);

	const loadLookupsWithSort = useCallback((sort?: ISort | undefined) => {
		accessStore.loadRightSettings(sort);
	}, []);

	const onClick = useCallback((row: IAccessRight) => {
		navigateTo(location.pathname + "/" + row.id);
		accessRightLink.getOperationRights(row.entityId);
		accessRightLink.getRecordRights(row.entityId);
		accessRightLink.getCheckOperation(row.entityId);
		accessRightLink.getCheckRecord(row.entityId);
		accessStore.setValue(row.entityId, "entityId");
		accessStore.setValue(row.title, "entityTitle");
		setClick(true);
	}, []);

	return (
		<>
			<div className={styles.systemMainPanel}>
				{clicked ? (
					<>{tab}</>
				) : (
					<>
						<NavigationTitle />
						<div className={styles.wrapperGrid}>
							<div className={styles.sectionTitle}>{UsersSettings[3].name}</div>
							<div className={styles.searchWrapper}>
								<InputSearch
									classNameInput={styles.search}
									className={styles.search}
									value={null}
									placeholder={"Поиск"}
									onChangeValue={() => {}}
								/>
								<Select
									name={accessStore.valueSelect}
									secondIcon={<TriangleToDown />}
									styles={ButtonStyle.IconIndigo}
									isRotateSecondIcon={true}
									onChangeValue={(value: any) => {
										accessStore.getValueSelect(value);
									}}
									classNameButton={styles.moreButton}
									classNameOpenList={styles.moreButtonOpened}
									items={AccessItems}
								/>
							</div>
							<div className={styles.grid}>
								<DataGrid
									classNameHeader={styles.header}
									checked={true}
									onClick={onClick}
									columns={accessStore.ColumnsAccessMainPanel}
									otherData={accessStore.settingsRightsDataToGrid}
									loadLookup={loadLookupsWithSort}
								/>
							</div>
						</div>
					</>
				)}
			</div>{" "}
		</>
	);
});

export default AccessRightsMainPanel;
