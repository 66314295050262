import "draft-js/dist/Draft.css";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";

import { Editor } from "draft-js";
import { TextEditorProviderWithStartText, useEditorApi } from "./config/context";
import ToolPanel from "./tool-panel";
import { NotEditableText } from "./not-editable-text";
import { Button, ButtonStyle } from "components";
import { CUSTOM_STYLE_MAP } from "./config/config";

import { AttachFileGrey, CrossInRound, Edit, Send, SuccessInCircle } from "shared";

import styles from "./text-editor.module.css";
import "./editor-styles.css";

export interface ITextEditorProps {
	send?: (text: string) => void;
	cancelEdit?: () => void;
	edit?: boolean;
	editableText?: string;
	classNameTextEditor?: string;
}

const TextEditor = observer(function (props: ITextEditorProps) {
	const { editorState, onChange, toHtml, reset, newLine, fromHtml } = useEditorApi();
	const [visibleTool, setVisibleTool] = useState<boolean>(false);
	const [fixedToolPanel, setFixedToolPanel] = useState<boolean>(true);
	const editor = useRef(null);
	let wrapper = classNames({
		[`${styles.textEditor} ` + props.classNameTextEditor]: props.classNameTextEditor,
		[`${styles.textEditor} `]: true
	});

	useEffect(() => {
		if (props.edit) fromHtml(props.editableText!);
		else reset();
	}, [props.edit]);

	useEffect(() => {
		var selectionState = editorState.getSelection();
		if (selectionState.getEndOffset() - selectionState.getStartOffset() >= 1) {
			setVisibleTool(true);
		} else {
			setVisibleTool(false);
		}
	}, [editorState]);

	function send() {
		if (editorState.getCurrentContent().getPlainText()?.trim()) {
			var text = toHtml();
			props.send!(text);
			reset();
		}
	}

	const handleReturn = (event: any) => {
		if (event.keyCode == 13) {
			if (event.nativeEvent.shiftKey) {
				newLine();
				return "handled";
			} else {
				send();
				return "handled";
			}
		}
		return "not-handled";
	};

	const focusEditor = () => {
		// @ts-ignore
		editor.current?.focus();
	};

	const onBlur = useCallback(() => {
		setFixedToolPanel(false);
	}, []);

	return (
		<div className={wrapper}>
			<div className={styles.clickableEditor}>
				{props.edit && <EditBlock cancelEdit={props.cancelEdit} editableText={props.editableText} />}
				<div className={styles.messageInput}>
					<Button firstIcon={<AttachFileGrey />} style={ButtonStyle.Icon} className={styles.attachFile} />
					<div className={styles.editorWrapper}>
						<ToolPanel
							focusEditor={focusEditor}
							visible={visibleTool}
							setVisible={setVisibleTool}
							fixedToolPanel={fixedToolPanel}
							setFixedToolPanel={setFixedToolPanel}
						/>
						<Editor
							ref={editor}
							placeholder="Написать комментарий..."
							editorState={editorState}
							onChange={onChange}
							customStyleMap={CUSTOM_STYLE_MAP}
							handleReturn={handleReturn}
							onBlur={onBlur}
						/>
					</div>
					{!props.edit ? (
						<Button onClick={send} firstIcon={<Send />} style={ButtonStyle.Icon} className={styles.send} />
					) : (
						<Button onClick={send} firstIcon={<SuccessInCircle />} style={ButtonStyle.Icon} className={styles.send} />
					)}
				</div>
			</div>
		</div>
	);
});

export default TextEditor;

const EditBlock = observer(function (props: { cancelEdit?: () => void; editableText?: string }) {
	function onCancelEdit() {
		if (props.cancelEdit) {
			props.cancelEdit();
		}
	}

	return (
		<div className={styles.editMessagePanel}>
			<TextEditorProviderWithStartText text={props.editableText}>
				<Button firstIcon={<Edit className={styles.editIcon} />} style={ButtonStyle.Icon} />
				<div className={styles.editTextPanel}>
					<div className={styles.editableTextHeader}>Изменить сообщение</div>
					<div className={styles.editableText}>
						<NotEditableText className={styles.editTextBlock} />
					</div>
				</div>
				<Button onClick={onCancelEdit} firstIcon={<CrossInRound />} style={ButtonStyle.Icon} />
			</TextEditorProviderWithStartText>
		</div>
	);
});
