import { MultiLineField } from "components/multiline-field";
import { BlockWithFields } from "../../block-with-fields";
import { SIZE } from "components/multiline-field/multiline-field";

import styles from "./comments.module.scss";

export const Comments = () => {

    return (
        <BlockWithFields headerTitle="Комментарии">
            <div className={styles.comments}>
            <MultiLineField size={SIZE.Small} maxSymbols={200} value={""} onChange={(value: string)=>{}} placeholder="Поле ввода комментария" disabled/>
       </div> 
       </BlockWithFields>
    );
};