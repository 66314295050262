import { useCallback, useEffect, useState } from "react";
import { v4 } from "uuid";
import classNames from "classnames";

import detailFieldConfigurationPopupState, { FieldStoreEnums } from "../detail-field-configuration-popup-state";

import { Item, RadioGroup, RadioItem } from "sale-bridge-ui-kit";

import { LookupType } from "pages/section-wizzard/data/data";
import { ColumnType } from "entities/ColumnType";

import styles from './choice-lookup-type.module.scss'


interface ChoiceLookupTypeProps {
    fieldType: ColumnType;
    size: 'small' | 'medium' | 'large',
}

export const ChoiceLookupType = (props: ChoiceLookupTypeProps) => {
    const [selectedOption, setSelectedOption] = useState<LookupType>(LookupType.NewLookup);

    useEffect(() => {
        detailFieldConfigurationPopupState.setValueWithoutTrackingChanges(FieldStoreEnums.lookupType, LookupType.NewLookup);
        detailFieldConfigurationPopupState.setValueWithoutTrackingChanges(FieldStoreEnums.fieldType, props.fieldType);
        detailFieldConfigurationPopupState.setValueWithoutTrackingChanges(FieldStoreEnums.columnId, v4());
    }, []);

    const handleOptionSelect = useCallback((selectedId: string) => {
        detailFieldConfigurationPopupState.setValueWithoutTrackingChanges(FieldStoreEnums.lookupType, selectedId as LookupType);
        setSelectedOption(selectedId as LookupType);
    }, []);

    const divider = classNames(styles.divider,
        { [styles[`${props.size}Divider`]]: props.size, }
    );

    return (
        <RadioGroup size={props.size} value={selectedOption} onSelect={handleOptionSelect}>
            <RadioItem id={LookupType.NewLookup} size={props.size} isSelected={LookupType.NewLookup === selectedOption} variant="default" onSelect={handleOptionSelect}>
                <Item size={props.size} text="По новому справочнику" description="Создать таблицу в базе данных и связанное с ней справочное поле" />
            </RadioItem>
            <div className={divider}/>
            <RadioItem id={LookupType.ExistLookup} size={props.size} isSelected={LookupType.ExistLookup === selectedOption} variant="default" onSelect={handleOptionSelect}>
                <Item size={props.size} text="По существующему справочнику" description="Создать поле, в котором значения раскрывающегося списка — значения из существующего справочника, в том числе из другого раздела" />
            </RadioItem>
        </RadioGroup>
    );
};


