import classNames from "classnames";

import { binding, bindingInXml } from "module/reactor/binding/binding";

import IProps from "module/reactor/components/IProps";

import { ArrowToRightStretch } from "shared";

import styles from "../full-view/full-view.module.scss";

interface FullViewProps extends IProps {
	entityTitle: string;
	viewColumn: string;
}

function PreviewFullView(props: FullViewProps) {
	const entityTitle = binding(props.services, props.entityTitle);
	const viewColumn = bindingInXml(props.services, props.viewColumn);
	return (
		<div className={styles.fullView} style={{ gridTemplateRows: `auto 1fr` }}>
			<div className={classNames(styles.fullViewHeader, styles.fullViewTitlePreview)}>
				<div className={styles.fullViewTitle}>
					<span className={styles.fullViewTitleSection}>{entityTitle}</span>
					<ArrowToRightStretch />
					<span className={styles.fullViewTitleDetail}>{viewColumn}</span>
				</div>
			</div>
			<div className={styles.fullViewContent}>{props.children}</div>
		</div>
	);
}

export default PreviewFullView;
