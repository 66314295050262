import { makeAutoObservable } from "mobx";

import { store } from "store";
import { api } from "shared";
import { dispatcher } from "store/store-dipatcher/dispatcher";
import { synchroiser } from "synchroiser";

import NodeElement from "module/reactor/data/Node";
import locContainer, { ServiceType } from "module/reactor/loc/Loc";
import XmlToJson from "module/reactor/xmlParser/xmlParser";

class ReactorStore {
	render?: NodeElement;
	modules?: NodeElement;
	script?: NodeElement;

	services: Map<string, unknown> | null = null;
	styles: JSX.Element[] = [];

	constructor(xml: string) {
		const obj = XmlToJson(xml);

		this.render = obj.children.find((o) => o.type === "Render");
		this.modules = obj.children.find((o) => o.type === "Module");
		this.script = obj.children.find((o) => o.type === "Script");

		// this.initServices();

		makeAutoObservable(this);
	}

	// private initServices() {
	//     locContainer.register("httpClient", HttpClient, ServiceType.Scoped);
	// }

	async loadStyles() {
		const styles: JSX.Element[] = [];

		if (this.modules) {
			for (let module of this.modules.children) {
				if (module.type === "Style") {
					// let data = await api.post("/api/reactor/getstyle", { key: module.attribute["name"] });
					let data = await api.http.reactor.getStyle().post({ key: module.attribute["name"] });
					styles.push(<style>{data.data.code}</style>);
				}
			}
		}

		this.styles = styles;
	}

	loadServices(param: any, props?: any) {
		const services = new Map();
		const promises: Promise<any>[] = [];
		services.set("param", param);

		if (this.script && this.script.value) {
			locContainer.remove("main");
			locContainer.register("main", eval(this.script.value), ServiceType.Scoped);
		}
		if (this.modules) {
			for (let module of this.modules.children) {
				if (module.type === "Service") {
					let obj = locContainer.get(module.attribute["name"]);

					if (Reflect.has(obj, "__init")) {
						if (props.props) obj.__init(props.props);
					}
					services.set(module.attribute["name"], obj);
				}
				services.set("store", store);
				services.set("dispatcher", dispatcher);
				services.set("synchroiser", synchroiser);

				// if (module.type === "Store") {
				//     const entityName = bindingInXml(services, module.attribute["entity"]);
				//     const id = bindingInXml(services, module.attribute["id"]);
				//     const store = new AutoStore(entityName, id);
				//     const promise = store.load();
				//     promise.then((d) => { if (d) services.set(entityName, d) });
				//     promises.push(promise);
				// }
			}
		}

		Promise.all(promises).then(() => (this.services = services));
	}
}

export default ReactorStore;
