
import { useCallback, useState } from 'react'

type CopiedValue = string | null

type CopyFn = (text: string) => Promise<boolean>

export function useCopyToClipboard(): [CopiedValue, CopyFn, boolean] {
  const [copiedText, setCopiedText] = useState<CopiedValue>(null)
  const [isCopied, setIsCopied] = useState<boolean>(false)

  const copy: CopyFn = useCallback(async text => {
    if (!navigator?.clipboard) {
      console.warn('Clipboard not supported')
      return false
    }

    try {
      await navigator.clipboard.writeText(text)
      setCopiedText(text)

      setIsCopied(true)
      setTimeout(() => {
        setIsCopied(false)
      }, 3000); 

      return true
    } catch (error) {
      console.warn('Copy failed', error)
      setCopiedText(null)
      setIsCopied(false)
      return false
    }
  }, [])

  return [copiedText, copy, isCopied]
}