import { observer } from "mobx-react";
import { useState } from "react";

import { debounce } from "shared/model";

import { Button, ButtonStyle, CustomGrid, ICustomColumn, InputSearch, RadioButton } from "components";
import { Header } from "../header/header";
import { Column } from "../column/column";

import exportStore, { ResultStates } from "features/export-block/core/export-store";

import { CloseMaxi } from "shared";

import styles from "../../export-block.module.css";

const start = debounce(() => {
	exportStore.startExport();
}, 100);

export const NotStartedExport = observer(function (props: {
	pageTitle: string;
	schema: string;
	title: string;
	choosingCount: number;
	allCount: number;
	close: () => void;
}) {
	async function startExport() {
		exportStore.setValue({ state: ResultStates.Progress, percent: 0 }, "exportResult");
		await start();
	}

	return (
		<div className={styles.wrapper}>
			<div className={styles.header}>
				<span className={styles.title}>{props.title}</span>
				<CloseMaxi className={styles.closeButton} onClick={props.close} />
			</div>
			<span className={styles.count}>
				Количество записей для экспорта: {props.choosingCount} из {props.allCount}
			</span>
			<div className={styles.dialogBody}>
				<div className={styles.bodyExport}>
					<LeftBlockExport />
					<div className={styles.divider} />
					<RightBlockExport />
				</div>
				<div className={styles.dialogFooter}>
					<Button caption="Отменить" onClick={props.close} style={ButtonStyle.Subtle} />
					<Button
						caption="Экспортировать"
						onClick={startExport}
						style={ButtonStyle.Primary}
						isDisabled={exportStore.checkedColumns.length < 1}
					/>
				</div>
			</div>
		</div>
	);
});

const LeftBlockExport = observer(function () {
	return (
		<div className={styles.leftBlockExport}>
			<span className={styles.titleBodyBlock}>Формат файла</span>
			<RadioButton elements={exportStore.formats} />
		</div>
	);
});

const RightBlockExport = observer(function () {
	let [searchValue, setSearchValue] = useState("");
	let [checkedAll, setCheckedAll] = useState(false);

	function onChangeAll(value: boolean) {
		setCheckedAll(value);
		exportStore.clearCheckedColumns();
		if (value) exportStore.setCheckedAll();
	}
	function onChange(value: any) {
		let elem = exportStore.columns.filter((e) => e.columnName === value.column)[0];
		if (value.value) {
			exportStore.checkedColumns.push(elem);
		} else {
			let index = exportStore.checkedColumns.indexOf(elem);
			if (index !== -1) {
				exportStore.checkedColumns.splice(index, 1);
			}
		}
	}

	let columnTitles: ICustomColumn[] = [
		{
			name: "columnName",
			caption: "",
			width: "48px",
			head: <Header onChangeAll={onChangeAll} />,
			onChange: onChange,
			render: (column: ICustomColumn, value: any) => {
				return <Column column={column} value={value} checkedAll={checkedAll} />;
			}
		},
		{
			name: "columnTitle",
			caption: "Колонки в разделе [" + exportStore.pageTitle + "]"
		}
	];

	function search(s: string) {
		setSearchValue(s);
	}

	return (
		<div className={styles.rightBlockExport}>
			<span className={styles.titleBodyBlock}>Выберите необходимые поля</span>
			<InputSearch placeholder="Поиск" value={searchValue} onChangeValue={search} classNameInput={styles.search} />
			<CustomGrid columns={columnTitles} datas={exportStore.columns} className={styles.grid} />
		</div>
	);
});
