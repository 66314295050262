import React from 'react';

import classNames from 'classnames';

import styles from './field.module.css';


export interface IFieldHorizontalProps extends React.HTMLAttributes<HTMLElement> {
    name: string;
    caption: string;
    classNameCaption?: string;
    required?: boolean;
    infoCaption?: string
}

function HorizontalField(props: IFieldHorizontalProps) {
    const wrapperStyle = classNames({
        [`${styles.fieldHorizontal} `]: true,
        [`${props.className} `]: props.className
    });
    const captionStyle = classNames({
        [`${styles.captionTextHorizontal} ${styles.captionHorizontal} `]: true,
        [`${props.classNameCaption} `]: props.classNameCaption
    });

    return (
        <div className={wrapperStyle} style={props.style}>
            <label className={captionStyle} htmlFor={props.name} >
                <span >{props.caption} {props.required && <span className={styles.star}>*</span>}</span>
                <span className={styles.infoCaption}>{props.infoCaption} </span>
            </label>
            {props.children}
        </div>
    );
}



export default HorizontalField;