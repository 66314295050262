import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { useCallback, useMemo } from "react";
import { isNull, isUndefined } from "lodash";

import { dispatcher } from "store";

import { SelectProperty } from "./components";
import { Button, ButtonDropdown } from "sale-bridge-ui-kit";
import { AutoFieldByType } from "features/auto-field-by-type";

import { Action, ActionType, ActionTypeName, BlockedColumnNames, ColumnSpecializationType, FieldConfig, SystemColumns } from "types/entity";
import { Item } from "types";
import { initDropdownData } from "../data";
import { ColumnType } from "entities/ColumnType";
import { BusinessRuleEditorValidation } from "pages/section-wizzard/pages/business-rules/business-rule-editor-store";

import styles from "./block-condition.module.scss";

export const ActionBlock = observer(
	(props: {
		action: Action;
		setField: (value: Item | null, elseActionId: string) => void;
		setActionType: (value: Item | null, elseActionId: string, needResetValidation?: boolean) => void;
		setValue: (value: string | Item | null, actionId: string, needResetValidation?: boolean) => void;
		handleChangeDropDown: (id: string, action: Action) => void;
		validation?: BusinessRuleEditorValidation;
	}) => {
		const allGridItems = useMemo(
			() => dispatcher.sectionWizzard.getFieldConfigOfAllGridItems(),
			[toJS(dispatcher.sectionWizzard.getFieldConfigOfAllGridItems)]
		);

		const fieldInfo = useMemo(
			() => allGridItems.find((fieldItem) => fieldItem.columnId === props.action.field) ?? null,
			[props.action.field, toJS(allGridItems)]
		);

		const isViewColumnField = useCallback(
			(fieldId: string) => dispatcher.sectionWizzard.getViewColumnId() === fieldId,
			[toJS(dispatcher.sectionWizzard.getViewColumnId())]
		);
		const isPositionColumnField = useCallback(
			(gridItem: FieldConfig) =>
				gridItem.specializations?.tag === ColumnSpecializationType.KanbanField &&
				gridItem.specializations.properties.sysOrderField === "true",
			[]
		);

		const fieldItems = useMemo(() => {
			const array: Item[] = [];
			allGridItems.forEach((gridItem) => {
				if (!isViewColumnField(gridItem.columnId) && !isPositionColumnField(gridItem)) {
					array.push({
						id: gridItem.columnId,
						name: gridItem.columnName,
						displayValue: gridItem.columnTitle
					});
				}
			});
			return array;
		}, [toJS(allGridItems), isViewColumnField]);

		const isStandartField = useMemo(() => {
			const isColumnBlocked = BlockedColumnNames.includes(fieldInfo?.columnName as SystemColumns);
			const isFlagField = fieldInfo?.specializations?.tag === ColumnSpecializationType.Flag;
			const isNumerator = Boolean(fieldInfo?.numeratorConfig ?? false);
			return isColumnBlocked || isFlagField || isNumerator;
		}, [toJS(fieldInfo)]);

		const isPriorityOrStageField = useMemo(
			() => (fieldInfo ? dispatcher.sectionWizzard.isPriorityOrStageField(fieldInfo) : false),
			[toJS(fieldInfo)]
		);

		const actionTypeItems = useMemo((): Item[] => {
			if (isStandartField) {
				return Object.entries(ActionTypeName)
					.filter(
						(item) =>
							item[0].toString() === ActionType.SHOW_ON_PAGE.toString() ||
							item[0].toString() === ActionType.HIDE_FROM_PAGE.toString()
					)
					.map(([key, value]) => ({
						id: key,
						name: value,
						displayValue: value
					}));
			} else if (isPriorityOrStageField) {
				return Object.entries(ActionTypeName)
					.filter((item) => item[0].toString() === ActionType.SET_VALUE.toString())
					.map(([key, value]) => ({
						id: key,
						name: value,
						displayValue: value
					}));
			} else if (fieldInfo?.columnType === ColumnType.Boolean) {
				return Object.entries(ActionTypeName)
					.filter(
						(item) =>
							item[0].toString() === ActionType.MARK.toString() ||
							item[0].toString() === ActionType.UNCHECK.toString() ||
							item[0].toString() === ActionType.MAKE_EDITABLE.toString() ||
							item[0].toString() === ActionType.MAKE_UNEDITABLE.toString()
					)
					.map(([key, value]) => ({
						id: key,
						name: value,
						displayValue: value
					}));
			}
			return Object.entries(ActionTypeName)
				.filter(
					(item) =>
						item[0].toString() === ActionType.SET_VALUE.toString() ||
						item[0].toString() === ActionType.CLEAR_VALUE.toString() ||
						item[0].toString() === ActionType.MAKE_IT_REQUIRED.toString() ||
						item[0].toString() === ActionType.MAKE_IT_OPTIONAL.toString() ||
						item[0].toString() === ActionType.MAKE_EDITABLE.toString() ||
						item[0].toString() === ActionType.MAKE_UNEDITABLE.toString()
				)
				.map(([key, value]) => ({
					id: key,
					name: value,
					displayValue: value
				}));
		}, [toJS(fieldInfo), isStandartField, isPriorityOrStageField]);

		const setEmptyValue = useCallback(
			(needResetValidation: boolean = false) => {
				if (fieldInfo?.isLookup) {
					props.setValue(
						{
							id: "",
							displayValue: "",
							name: ""
						},
						props.action.id,
						needResetValidation
					);
				} else {
					props.setValue("", props.action.id, needResetValidation);
				}
			},
			[fieldInfo?.isLookup, props.action.id, props.setValue]
		);

		const changeField = useCallback(
			(value: Item | null) => {
				props.setField(value, props.action.id);
				props.setActionType(null, props.action.id);
				setEmptyValue();
			},
			[props.setField, props.setActionType]
		);

		const changeType = useCallback(
			(value: Item | null) => {
				props.setActionType(value, props.action.id, true);
				setEmptyValue(true);
			},
			[props.setActionType]
		);

		const changeValue = useCallback(
			(value: Item | string | null) => {
				if (value) {
					props.setValue(value, props.action.id, true);
				} else {
					setEmptyValue(true);
				}
			},
			[props.setValue]
		);

		const autoField = useMemo(() => {
			let disabled = false;

			if (isNull(props.action.field) || isNull(props.action.actionType)) {
				disabled = true;
			}
			if (
				!disabled &&
				((fieldInfo?.columnType !== ColumnType.Boolean &&
					props.action.actionType?.toString() !== ActionType.SET_VALUE.toString()) ||
					(fieldInfo?.columnType === ColumnType.Boolean && props.action.actionType?.toString() !== ActionType.MARK.toString()))
			) {
				return <></>;
			}
			const virtualLookupValues =
				fieldInfo?.virtualLookup?.virtualLookupValues.map((virtualLookupValue) => ({
					id: virtualLookupValue.id,
					name: virtualLookupValue.name
				})) ?? [];
			return (
				<AutoFieldByType
					onChange={changeValue}
					columnId={props.action.field ?? undefined}
					columnValue={props.action.value}
					columnType={fieldInfo?.columnType as ColumnType}
					lookupTable={fieldInfo?.lookupTable}
					isVirtualLookup={!isUndefined(fieldInfo?.virtualLookup) && !isNull(fieldInfo?.virtualLookup)}
					virtualLookupValues={virtualLookupValues}
					columnValueIsInvalid={false}
					isVisibleLabel={false}
					isDisabled={disabled}
					validation={props.validation?.value}
				/>
			);
		}, [toJS(props.action), toJS(fieldInfo)]);

		return (
			<div className={styles.actionWrap}>
				<span className={styles.conditionGroupLogicalOperation}>И</span>
				<div className={styles.conditionBody}>
					<div className={styles.conditionBodyProperty}>
						<SelectProperty
							value={fieldInfo?.columnTitle ?? ""}
							items={fieldItems}
							onChange={(value) => changeField(value)}
							size="small"
							placeholder="Поле"
							validation={props.validation?.field}
						/>
						<SelectProperty
							value={!isNull(props.action.actionType) ? ActionTypeName[props.action.actionType] : ""}
							items={actionTypeItems}
							onChange={(value) => changeType(value)}
							size="small"
							placeholder="Действие"
							isDisabled={!props.action?.field}
							validation={props.validation?.comparison}
						/>
					</div>
					<div className={styles.conditionBodyValue}>{autoField}</div>
					<ButtonDropdown
						size="small"
						position="right-down"
						standartItems={initDropdownData}
						onClickStandartItem={(id) => {
							props.handleChangeDropDown(id, props.action);
						}}
						childrenButton={
							<Button
								text=""
								onClick={() => {}}
								size="small"
								variant="backless"
								leftIcon="KebabVertical"
								link={false}
								loading={false}
								border={false}
							/>
						}
					/>
				</div>
			</div>
		);
	}
);
