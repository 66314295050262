import React from 'react';
import classNames from 'classnames';

import styles from './stage-progress-bar.module.css';


interface StageProgressBarProps {
    percentage: number;
    isLastAndSuccessful: boolean;
    isLastAndFail: boolean;
}

const StageProgressBar: React.FC<StageProgressBarProps> = ({ percentage, isLastAndSuccessful, isLastAndFail }) => {
    let color: 'success' | 'fail' | 'default' = 'default';

    if (isLastAndSuccessful) {
        color = 'success';
    } else if (isLastAndFail) {
        color = 'fail';
    }

    const progressFillStyle = {
        width: `${percentage}%`,
    };

    return <div className={styles.progressBar}>
        <div className={classNames(styles.progressFill, styles[color])} style={progressFillStyle} />
    </div>;
};


export default StageProgressBar