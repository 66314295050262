import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 } from 'uuid';

import { modalController } from 'features/modals';
import fieldConfigurationStore from '../../../../../field-configuration-store';

import { ResetModal, SaveWithChangesModal } from './warning-popups';
import { Button, Field, ButtonDropdown } from 'sale-bridge-ui-kit';
import { MaskParameterContainer, PreviewMaskSettings } from './components';

import { NumeratorMaskSettingsParameter, NumeratorMaskSettingsParameterType } from 'types/entity';
import { PreviewItem, datesPreview, parametersItems } from './data';
import { DefaultMaskParametrs } from '../../../../numerator-configuration-state';

import styles from './edit-mask-settings-popup.module.scss';


type NewMaskSettingsPopupProps = {
    isNew?: boolean,
    maskId: string,
    close: (isNeedDelete: boolean) => void,
}

export const MaskSettingsPopup = observer((props: NewMaskSettingsPopupProps) => {
    const [modalId] = useState<string>(v4());
    const [invalidParameters, setInvalidParameters] = useState<string[]>([]);

    const masks = useMemo(() => {
        return fieldConfigurationStore.numeratorConfig.maskItems;
    }, [toJS(fieldConfigurationStore.numeratorConfig.maskItems)]);

    useEffect(() => {
        fieldConfigurationStore.numeratorConfig.initOldMaskItems();
        fieldConfigurationStore.numeratorConfig.startTrackingChanges(props.maskId);

        return () => { fieldConfigurationStore.numeratorConfig.dispose(); }
    }, []);

    const currentMask = useMemo(() => masks.find(item => item.id === props.maskId), [toJS(masks.find(item => item.id === props.maskId))]);

    const resetButtonEnable = useMemo(() => {
        const currentParametrs = JSON.stringify(currentMask?.parameters);
        const defaultMaskParametrs = JSON.stringify(DefaultMaskParametrs);

        return currentParametrs !== defaultMaskParametrs;
    }, [toJS(currentMask)]);

    const startingValuesOfMasks: PreviewItem[] = useMemo(() => {
        return currentMask?.parameters.map(parameter => (
            {
                order: parameter.order,
                name: parameter.columnValueParameter ?
                    parameter.columnValueParameter!.isLookup ? parameter.columnValueParameter!.lookupTableColumnTitle ?
                        `${parameter.columnValueParameter!.columnTitle}.${parameter.columnValueParameter!.lookupTableColumnTitle}`
                        : ""
                        : parameter.columnValueParameter!.columnTitle
                    : parameter.numberParameter?.startValue ?? parameter.textParameter?.text ?? datesPreview[parameter.type]

            }
        )) ?? [];
    }, [toJS(currentMask?.parameters)]);

    /**@description 
     * @property {"Number" | "Text" | "CurrentDay" | "CurrentMonth" | "CurrentYear2" | "CurrentYear4" | "ColumnValue" } idType - id выбранного значения в dropdown "добавить параметр" (сохранено в данном формате).
     * */
    const handleClickToAddParameter = useCallback((idType: any) => {
        const order = currentMask?.parameters.length! + 1;
        const type: keyof typeof NumeratorMaskSettingsParameterType = idType;

        switch (NumeratorMaskSettingsParameterType[type]) {
            case NumeratorMaskSettingsParameterType.Number:
                return;
            case NumeratorMaskSettingsParameterType.Text: {
                const param: NumeratorMaskSettingsParameter = {
                    id: v4(),
                    order: order,
                    type: NumeratorMaskSettingsParameterType.Text,
                    textParameter: {
                        text: ''
                    },
                }
                currentMask?.parameters.push(param);
                break;
            }
            case NumeratorMaskSettingsParameterType.CurrentDay: {
                const param: NumeratorMaskSettingsParameter = {
                    id: v4(),
                    order: order,
                    type: NumeratorMaskSettingsParameterType.CurrentDay,
                }
                currentMask?.parameters.push(param);
                break;
            }
            case NumeratorMaskSettingsParameterType.CurrentMonth: {
                const param: NumeratorMaskSettingsParameter = {
                    id: v4(),
                    order: order,
                    type: NumeratorMaskSettingsParameterType.CurrentMonth,
                }
                currentMask?.parameters.push(param);
                break;
            }
            case NumeratorMaskSettingsParameterType.CurrentYear2: {
                const param: NumeratorMaskSettingsParameter = {
                    id: v4(),
                    order: order,
                    type: NumeratorMaskSettingsParameterType.CurrentYear2,
                }
                currentMask?.parameters.push(param);
                break;
            }
            case NumeratorMaskSettingsParameterType.CurrentYear4: {
                const param: NumeratorMaskSettingsParameter = {
                    id: v4(),
                    order: order,
                    type: NumeratorMaskSettingsParameterType.CurrentYear4,
                }
                currentMask?.parameters.push(param);
                break;
            }
            case NumeratorMaskSettingsParameterType.ColumnValue: {
                const param: NumeratorMaskSettingsParameter = {
                    id: v4(),
                    order: order,
                    type: NumeratorMaskSettingsParameterType.ColumnValue,
                    columnValueParameter: {
                        isLookup: false,
                        columnName: '',
                        columnId: '',
                        columnTitle: '',
                    },
                }
                currentMask?.parameters.push(param);
                break
            }
        }
    }, [toJS(currentMask?.parameters)]);

    const handleTitleChange = useCallback((value: string) => {
        if (currentMask) {
            currentMask.name = value;
        }
    }, [currentMask]);

    const closeConfirm = useCallback(() => {
        modalController.modalRemove(modalId);
    }, []);

    const handleResetClick = useCallback(() => {
        modalController.popupAdd({
            id: modalId,
            layout: <ResetModal closeConfirm={closeConfirm} onReset={handleReset} />,
            closeFunc: closeConfirm
        });
    }, [modalId]);

    const handleReset = useCallback(() => {
        const defaultMask = fieldConfigurationStore.numeratorConfig.getDefaultMask(currentMask?.id!, currentMask?.name)
        fieldConfigurationStore.numeratorConfig.editMask(defaultMask);
    }, [currentMask]);

    const handleSave = useCallback(() => {
        const invalidId = fieldConfigurationStore.numeratorConfig.getIdOfInvalidParameters(currentMask?.id!);
        setInvalidParameters(invalidId);
        if (invalidId.length > 0) {
            return;
        }
        const hasChanges = fieldConfigurationStore.numeratorConfig.isMaskChanged(currentMask?.id!);
        const isOldNumeratorField = fieldConfigurationStore.isOldNumeratorField();
        if (isOldNumeratorField && hasChanges) {
            modalController.popupAdd({
                id: modalId,
                layout: <SaveWithChangesModal closeConfirm={closeConfirm} onSave={() => {
                    fieldConfigurationStore.numeratorConfig.initOldMaskItems();
                    props.close(false);
                }}
                />,
                closeFunc: closeConfirm
            });
            return;
        }
        fieldConfigurationStore.numeratorConfig.initOldMaskItems();
        props.close(false);
    }, [props, currentMask, modalId]);

    const handleCancelClick = useCallback(() => {
        props.close(true);
    }, []);

    return <>
        <Field
            type='text'
            size='small'
            label='Название маски'
            labelPosition="vertical"
            textVariant='outlined'
            placeholder=""
            value={currentMask?.name ?? ""}
            onChange={handleTitleChange}
            isClearing
            isRequired
        />
        <PreviewMaskSettings
            maskPreviewItems={startingValuesOfMasks}
            numberParameter={currentMask?.parameters.find(param => param.type == NumeratorMaskSettingsParameterType.Number)?.numberParameter}
        />
        <div className={styles.divider} />
        <MaskParameterContainer mask={currentMask} idOfInvalidParameters={invalidParameters} />
        {/* TODO хинт не отображается конкретно в этом попапе из-за z-index я думаю, не в ButtonDropdown проблема*/}
        <ButtonDropdown
            size='small'
            position='down'
            standartItems={parametersItems}
            onClickStandartItem={handleClickToAddParameter}
            isHintDisplayed={startingValuesOfMasks.length == 10}
            hintBody='Максимум 10 параметров'
            startHintPosition='top'
            isDisabled={startingValuesOfMasks.length === 10}
            childrenButton={
                <Button
                    text='Добавить параметр'
                    size='small'
                    variant={startingValuesOfMasks.length == 10 ? 'disabled' : 'secondary'}
                    border={false}
                    link={false}
                    loading={false}
                    leftIcon='PlusSquareFill'
                    rightIcon='Dropdown'
                    onClick={() => { }}
                />
            }
        />
        <div className={styles.footerMaskSettings}>
            <div className={styles.divider} />
            <div className={styles.buttonContainer}>
                <Button
                    text='Сбросить'
                    size='small'
                    variant={resetButtonEnable ? 'default' : 'disabled'}
                    border
                    link={false}
                    loading={false}
                    onClick={handleResetClick}
                />
                <div className={styles.rightFooterButtons}>
                    <Button
                        text='Отменить'
                        size='small'
                        variant='default'
                        border
                        link={false}
                        loading={false}
                        onClick={handleCancelClick}
                    />
                    <Button
                        text='Сохранить маску'
                        size='small'
                        variant='secondary'
                        border={false}
                        link={false}
                        loading={false}
                        onClick={handleSave}
                    />
                </div>
            </div>
        </div>
    </>


})