import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { InputStyleName, InputStyles } from 'components';
import { IInputProps } from "./input";

import GetFormattedPhone from 'entities/GetFormattedPhone';

import styles from './input.module.css';

function InputPhone(props: IInputProps) {
    const [focusOut, setFocusOut] = useState<boolean>(false);
    const [focus, setFocus] = useState<boolean>(false);
    const PATTERN = /\D/g; // все символы, которые не числа
    const ref = useRef<HTMLInputElement>(null);
    const inputStyle = InputStyles[props.inputStyle ?? InputStyleName.Base];

    const wrapperClassNames = classNames({
        [`${inputStyle.classNames} `]: true,
        [`${props.className} `]: props.className,
        [`${inputStyle.focus} `]: focus,
        [`${inputStyle.invalid} `]: props.isInvalid,
    });
    const inputClassNames = classNames({
        [`${inputStyle.input} `]: true,
        [`${styles.delay} `]: props.isPlaceholderDelay,
        [`${inputStyle.disabled} `]: props.isDisabled,
        [`${props.classNameInput} `]: props.classNameInput,
    });

    let error = null;
    if (props.isInvalid === true) {
        if (props.invalidMessage !== undefined && props.invalidMessage.length > 1) {
            let invalidMessageClasses = `${styles.errorMessage} `;
            error = <div className={invalidMessageClasses}>{props.invalidMessage}</div>
        }
    }

    useEffect(() => {
        if (props.focus)
            ref.current!.focus();
    }, [props.focus]);

    function handleOnBlur() {
        if (props.onFocusOut !== undefined)
            props.onFocusOut();
        if (props.isInvalid === false || (props.value !== null && props.value.length > 0)) {

            setFocusOut(true);
        }
        setFocus(false);
    }

    function handleClick(event: React.MouseEvent<HTMLInputElement, MouseEvent>) {
        setFocusOut(false);
        setFocus(true);
        if (props.onClick !== undefined)
            props.onClick(event);
    }

    function handleFocus(event: React.FocusEvent<HTMLInputElement, Element>) {
        setFocusOut(false);
        setFocus(true);
    }

    const getInputNumbersValue = (value: string) => {
        return value.replace(PATTERN, '');
    };

    const handlePhoneInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChangeValue(event.target.value);
    };

    const handlePhoneKeyDown = (
        event: React.KeyboardEvent<HTMLInputElement>
    ) => {
        const input = event.target as HTMLInputElement;

        if (
            event.key === "Backspace" &&
            getInputNumbersValue(input.value).length === 1
        ) {
            input.value = "";
            props.onChangeValue("");

        }

        return input;
    };

    const handlePhonePaste = (
        event: React.ClipboardEvent<HTMLInputElement>
    ) => {
        const pasted = event.clipboardData
        const input = event.target as HTMLInputElement;
        const inputNumbersValue = getInputNumbersValue(input.value);

        if (pasted) {
            const pastedText = pasted.getData("Text");
            if (PATTERN.test(pastedText)) {
                input.value = inputNumbersValue;
            }
        }
    };


    return (
        <div className={inputStyle.parentInput + " " + props.classNameParent}>
            <div className={wrapperClassNames}>
                <input
                    ref={ref}
                    name={props.name}
                    type={"text"}
                    className={inputClassNames}
                    maxLength={18}
                    onInput={handlePhoneInput}
                    onKeyDown={handlePhoneKeyDown}
                    onPaste={handlePhonePaste}
                    placeholder={props.placeholder}
                    onBlur={handleOnBlur}
                    value={props.value ? GetFormattedPhone(props.value) : ""}
                    disabled={props.isDisabled}
                    onClick={handleClick}
                    onFocus={handleFocus}
                />
            </div>
            {error}
        </div>
    );
}
export default InputPhone;