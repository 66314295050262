export class CacheItem<T> {
	private value: T | null = null;
	private clearTime: NodeJS.Timeout | null = null;

	setValue(value: T, liveMs: number) {
		this.clear();
		this.value = value;
		this.clearTime = setTimeout(this.clear, liveMs);
	}

	getValue(): T | null {
		return this.value;
	}

	clear() {
		this.value = null;
		if (this.clearTime) clearTimeout(this.clearTime);
	}
}
