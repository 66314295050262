import { CSSProperties, useCallback, useEffect, useMemo, useState } from "react";

import { ColumnType } from "entities/ColumnType";
import { InputTypes, ParsedProps, Props, inputs } from "pages/section-card/lib/data/data";
import { Item } from "types";
import { LowFirst } from "shared";
import { BlockedColumnNames } from "types/entity";

//TODO в ColumnType.Date и ColumnType.Time сделать привязку к тогла про значание по умолчанию
function getValue(type: ColumnType): any {
	switch (type) {
		case ColumnType.Guid: {
			return "Значение текстового поля";
		}
		case ColumnType.String: {
			return "Значение текстового поля";
		}
		case ColumnType.DateTime: {
			return new Date().toISOString();
		}
		case ColumnType.Date: {
			return undefined;
		}
		case ColumnType.Time: {
			return undefined;
		}
		case ColumnType.Lookup: {
			return "Значение справочного поля";
		}
		case ColumnType.Boolean: {
			return false;
		}
		case ColumnType.Integer: {
			return 100000;
		}
		case ColumnType.Decimal: {
			return 100000.01;
		}
		case ColumnType.Double: {
			return 100000.01;
		}
	}
}

function PreviewField(props: Props) {
	const [fieldValue, setFieldValue] = useState<any>();
	const parsedProps: ParsedProps = JSON.parse(props.field);

	const UsageInput: InputTypes | undefined = useMemo(
		() => parsedProps.fieldConfig?.columnType && inputs[parsedProps.fieldConfig?.columnType],
		[parsedProps.fieldConfig?.columnType]
	);

	const styles: CSSProperties = useMemo(
		() => ({
			gridRow: parsedProps.y,
			gridColumn: parsedProps.x
		}),
		[parsedProps.y, parsedProps.x]
	);

	useEffect(() => {
		const value = getValue(parsedProps.fieldConfig?.columnType as ColumnType);
		setFieldValue(value);
	}, []);

	const handleChange = useCallback(
		(value: string | Item | null | boolean) => {
			setFieldValue(value);
		},
		[parsedProps?.fieldConfig?.columnName]
	);

	const { fieldConfig } = parsedProps;
	const cellTitle = LowFirst(fieldConfig?.columnName ?? "");

	const isDisabled = useMemo(
		() =>
			BlockedColumnNames.some((columnName) => columnName.toLowerCase() === cellTitle.toLowerCase()) ??
			parsedProps.fieldConfig?.uneditable,
		[cellTitle]
	);

	return (
		<div style={styles}>
			{UsageInput ? (
				<UsageInput
					columnName={fieldConfig?.columnName}
					columnTitle={fieldConfig?.columnTitle}
					value={fieldValue}
					onChange={handleChange}
					isDisabled={isDisabled}
					isRequired={fieldConfig?.isRequired}
					prompt={fieldConfig?.prompt}
					rounding={fieldConfig?.specializations?.properties.rounding}
					variant={props.variant ?? "standart"}
					labelPosition={props.labelPosition ?? "vertical"}
					lookupTable={fieldConfig?.lookupTable}
				/>
			) : (
				<div />
			)}
		</div>
	);
}

export default PreviewField;
