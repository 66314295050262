import { toJS } from 'mobx';
import { useMemo } from 'react';

import { NumeratorMaskSettingsNumberParameter, NumeratorMaskSettingsParameter, NumeratorMaskSettingsParameterType } from 'types/entity';
import { PreviewItem } from '../data';

import styles from '../edit-mask-settings-popup.module.scss';

const UniqValueCounter = (props: {
    valueLength: number,
    step: number,
    startValue: number,
}) => {

    function calculateUniqueValues(valueLength: number, step: number, startValue: number): number {
        const maxValue = Math.pow(10, valueLength) - 1;
        const uniqueValues = Math.floor((maxValue - startValue) / step) + 1;
        return uniqueValues;
    }

    const uniqValues = calculateUniqueValues(props.valueLength, props.step, props.startValue);
    return (
        <span className={styles.uniqValueCounterWrap}>
            {`Уникальных значений: ${uniqValues.toLocaleString('en-US')}`}
        </span>
    );
};

const Argument = (props: { index: number, text: string }) => {
    return <div className={styles.argumentWrap}>
        <span className={styles.argumentIndex}>{props.index}</span>
        <span className={styles.argumentText}>{`{${props.text}}`}</span>
    </div>
}
const NumeratorMaskViewer = (props: { maskItems: Array<PreviewItem> }) => {

    const sortedItems = useMemo(() => props.maskItems.sort((a, b) => a.order < b.order ? -1 : a.order > b.order ? 1 : 0).map((item, index) =>
        <Argument key={index} index={index + 1} text={item.name} />
    ), [toJS(props.maskItems)])

    return (
        <div className={styles.numeratorMaskViewer}>
            {sortedItems}
        </div>
    );
};

export const PreviewMaskSettings = (props: { maskPreviewItems: PreviewItem[], numberParameter: NumeratorMaskSettingsNumberParameter | undefined }) => {
    return (
        <div className={styles.previewAndCounter}>
            {props.numberParameter && <UniqValueCounter
                valueLength={props.numberParameter.valueLength}
                step={props.numberParameter.step}
                startValue={Number(props.numberParameter.startValue)}
            />}
            <NumeratorMaskViewer maskItems={props.maskPreviewItems} />
        </div>
    );
};