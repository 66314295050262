import { observer } from "mobx-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { v4 } from "uuid";
import { toJS } from "mobx";

import { AdditionalInfoOfField } from "../../../quick-view-settings/data";
import { BlockWithFields } from "../../block-with-fields";
import { ConstructorQuickViewDragField } from "../../constructor-quick-view-drag-field";

import TypeIcon from "features/type-icon/type-icon";

import { Element } from "modules/DND/element";
import { Zone } from "modules/DND/zone";

import { AdditionalField } from "types/entity";
import { Types } from "modules/DND/type";

import styles from "./right-block-drag-zone.module.scss";

type RightBlockDragZoneProps = {
    rightBlockAdditionalBlockedFields: Array<AdditionalField>;
    rightBlockAdditionalDragFields: Array<AdditionalField>;
    rightBlockAdditionalFieldsZoneId: string;
    onChangeRightBlockAdditionalFields: (sourceZone: string | null, targetZone: string | null, additionalField: AdditionalField, newPosition: number) => void;
    onDeleteFieldFromRightBlock: (gridItemId: string, isLeftFieldBlock?: boolean) => void;
    getAdditionalInfoOfField: (columnId: string) => AdditionalInfoOfField;
}

const CELL_HEIGHT = 44;

const MINIMAL_ROW_QUALITY = 1;

export const RightBlockDragZone = observer((props: RightBlockDragZoneProps) => {
    const [id] = useState<string>(v4());

    const [targetElementViewLayout, setTargetElementViewLayout] = useState<JSX.Element>(<></>);
    const [cellLayoutWidth, setCellLayoutWidth] = useState(0);
    const [rowQualityByZoneList, setRowQualityByZoneList] = useState(MINIMAL_ROW_QUALITY);
    const [rowQualityByScreen, setRowQualityByScreen] = useState(MINIMAL_ROW_QUALITY);

    const rightBlockAdditionalFields = useMemo(() => props.rightBlockAdditionalDragFields, [toJS(props.rightBlockAdditionalDragFields)]);

    const heightCount = useMemo(() => {
        return Math.max(rowQualityByZoneList, rowQualityByScreen, MINIMAL_ROW_QUALITY);
    }, [rowQualityByZoneList, rowQualityByScreen]);

    const blockedElements = useMemo(() => {
        return props.rightBlockAdditionalBlockedFields?.map((element) => {
            return <ConstructorQuickViewDragField
                gridItemId={element.gridItemId}
                additionalInfoOfField={props.getAdditionalInfoOfField(element.columnId)}
                icon={<TypeIcon type={props.getAdditionalInfoOfField(element.columnId).columnType} />}
                isFieldBlocked
            />
        })
    }, [toJS(props.rightBlockAdditionalBlockedFields), cellLayoutWidth]);

    const zoneElements = useMemo(() => {
        return rightBlockAdditionalFields?.map((element) => {
            return <Element
                key={element.gridItemId}
                id={element.gridItemId}
                x={1}
                y={element.order}
                width={1}
                height={1}
            >
                <ConstructorQuickViewDragField
                    width={`${cellLayoutWidth}px`}
                    gridItemId={element.gridItemId}
                    additionalInfoOfField={props.getAdditionalInfoOfField(element.columnId)}
                    icon={<TypeIcon type={props.getAdditionalInfoOfField(element.columnId).columnType} />}
                    onDeleteField={props.onDeleteFieldFromRightBlock}
                />
            </Element>

        })
    }, [toJS(rightBlockAdditionalFields), cellLayoutWidth]);

    useEffect(() => {
        const updateContainerSize = () => {
            if (document.getElementById(id)) {
                setRowQualityByScreen((Math.floor(document.getElementById(id)!.getBoundingClientRect().height / (CELL_HEIGHT))));
                setCellLayoutWidth(document.getElementById(id)!.getBoundingClientRect().width);
            }
        };
        updateContainerSize();
        window.addEventListener('resize', updateContainerSize);
        return () => {
            window.removeEventListener('resize', updateContainerSize);
        };
    }, [document.getElementById(id)?.getBoundingClientRect().width]);

    useEffect(() => {
        if (rightBlockAdditionalFields && rightBlockAdditionalFields.length > 0 && rightBlockAdditionalFields.find((item) => item.order !== -1)) {
            const maxYElement = rightBlockAdditionalFields.reduce((maxOrderElement, currentElement) => maxOrderElement.order > currentElement.order ? maxOrderElement : currentElement);
            setRowQualityByZoneList(maxYElement.order + 1);
        }
        if (rightBlockAdditionalFields && rightBlockAdditionalFields.length == 0) {
            setRowQualityByZoneList(MINIMAL_ROW_QUALITY);
        }
    }, [toJS(rightBlockAdditionalFields)]);

    const handleUp = useCallback(({ elementId }: { elementId: string }) => {
        if (rightBlockAdditionalFields) {
            const zoneItem = rightBlockAdditionalFields.find((item) => item.gridItemId === elementId);
            if (zoneItem) {
                setTargetElementViewLayout(
                    <ConstructorQuickViewDragField
                        width={`${cellLayoutWidth}px`}
                        gridItemId={zoneItem.gridItemId}
                        additionalInfoOfField={props.getAdditionalInfoOfField(zoneItem.columnId)}
                        icon={<TypeIcon type={props.getAdditionalInfoOfField(zoneItem.columnId).columnType} />}
                    />
                );
            }
        }
    }, [cellLayoutWidth, toJS(rightBlockAdditionalFields)]);

    const handleDrop = useCallback(({
        elementId,
        sourceZone,
        targetZone,
        MATRIX
    }: {
        elementId: string | null;
        sourceZone: string | null;
        targetZone: string | null;
        type: Types;
        sourceData?: any;
        [key: string]: any;
    }) => {
        if (MATRIX.cellY === heightCount) {
            setRowQualityByZoneList(heightCount + 1);
        }
        if (rightBlockAdditionalFields) {

            const dragElement = rightBlockAdditionalFields.find(({ gridItemId }) => gridItemId === elementId);
            if (dragElement) {
                const additionalField: AdditionalField = {
                    ...dragElement,
                    order: MATRIX.cellY,
                }
                props.onChangeRightBlockAdditionalFields(sourceZone, targetZone, additionalField, MATRIX.cellY);
            }
        }
    }, [toJS(rightBlockAdditionalFields), heightCount]);

    const cellLayout = useMemo(() => {
        return (
            <div style={{ width: `${cellLayoutWidth}px`, height: `${CELL_HEIGHT}px` }} >
                <div className={styles.fieldWrapper} />
            </div>
        );
    }, [cellLayoutWidth]);

    const zoneConfig = useMemo(() => {
        return {
            targetElementViewLayout: targetElementViewLayout,
            cellLayout: cellLayout,
            placeholderLayout: cellLayout,
            predictLayout: <div className={styles.predictLayout} />,
            width: 1,
            height: heightCount,
        };
    }, [targetElementViewLayout, cellLayout, heightCount]);

    return (
        <BlockWithFields headerTitle='Вспомогательные поля'>
            <>
                {blockedElements}
                <div id={id} style={{ height: "100%" }}>
                    <Zone
                        id={props.rightBlockAdditionalFieldsZoneId}
                        config={zoneConfig}
                        type={Types.MATRIX}
                        onDrop={handleDrop}
                        onUp={handleUp}
                    >
                        {zoneElements || <></>}
                    </Zone>
                </div>
            </>
        </BlockWithFields>
    );
});