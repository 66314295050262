import { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { v4 } from "uuid";

import { dispatcher } from "store";

import { modalController } from "features/modals";

import { RadioButton, Button, ButtonStyle } from "components";

import { ColumnType } from "entities/ColumnType";
import { IFormat } from "entities/IItem";
import { IGridItem } from "components/select/types";

import { CloseMaxi, DragIcon, ArrowToRight } from "shared";

import styles from "./constructor-title.module.css";
import { SystemColumns } from "types/entity";
import { toJS } from "mobx";

const LayoutPopup = observer(
	(props: { closeFuncWithOutConfirm: () => void; columnNames: IFormat[]; checkWarningForSetting: () => void }) => {
		const [isDisabled, setIsDisabled] = useState<boolean>(true);

		const handleClickRadioButton = useCallback(
			(item: string) => {
				props.columnNames.forEach((columnName) => {
					columnName.checked = false;
					if (columnName.name === item) {
						columnName.checked = true;
						setIsDisabled(false);
					}
				});
			},
			[props.columnNames, isDisabled]
		);

		const setDisplayedColumn = useCallback(() => {
			props.columnNames.forEach((columnName) => {
				if (columnName.checked) dispatcher.sectionWizzard.changeObserver(dispatcher.sectionWizzard.setViewColumnId(columnName.id));
			});
			props.closeFuncWithOutConfirm();
			props.checkWarningForSetting();
		}, [props.columnNames, props.checkWarningForSetting, toJS(dispatcher.sectionWizzard.getSectionWizzard()?.viewColumnId)]);

		return (
			<>
				<div className={styles.header}>
					<span className={styles.title}>Выберите поле-название записи</span>
					{<CloseMaxi className={styles.closeButton} onClick={props.closeFuncWithOutConfirm} />}
				</div>
				<div className={styles.dialogBody}>
					<div className={styles.fields}>
						<span className={styles.columnNamesCaption}>Текстовые поля в разделе</span>
						<RadioButton
							elements={props.columnNames}
							className={styles.columnsRadioButton}
							buttonCaptionClassName={styles.buttonCaptionClassName}
							isDivider={true}
							onClickRadiobutton={handleClickRadioButton}
						/>
					</div>
					<div className={styles.dialogFooter}>
						<>
							<Button caption="Отменить" onClick={props.closeFuncWithOutConfirm} style={ButtonStyle.Subtle} />
							<Button caption="Сохранить" onClick={setDisplayedColumn} style={ButtonStyle.Success} isDisabled={isDisabled} />
						</>
					</div>
				</div>
			</>
		);
	}
);

const ConstructorTitle = observer((props: { columns: Array<IGridItem>; checkWarningForSetting: () => void }) => {
	const [idModal] = useState<string>(v4());

	const sectionWizzard = useMemo(() => {
		return dispatcher.entity.get()?.entity.sectionWizzard;
	}, [dispatcher.entity.get()?.entity.sectionWizzard]);

	const dispatcherDisplayedColumn = useMemo(() => {
		return dispatcher.entity.get()?.entity.sectionWizzard?.viewColumnId;
	}, [dispatcher.entity.get()?.entity.sectionWizzard?.viewColumnId]);

	const columnNames = useMemo(() => {
		const data: IFormat[] = [];
		props.columns.forEach((column) => {
			if (
				(column.columnType === ColumnType.String || (column.columnType === ColumnType.Numerator && column.columnId)) &&
				column.name !== SystemColumns.ExternalId
			) {
				data.push({
					name: `${column.displayValue!} [${column.name}]`,
					id: column.columnId as string,
					checked: column.columnId !== dispatcherDisplayedColumn ? false : true
				});
			}
		});
		return data;
	}, [props.columns.map((item) => item), dispatcherDisplayedColumn]);

	const displayedColumn = useMemo(() => {
		let newValue = "Название записи";
		columnNames.forEach((columnName) => {
			if (columnName.id === dispatcherDisplayedColumn) newValue = columnName.name;
		});
		return newValue;
	}, [dispatcherDisplayedColumn]);

	const displayedColumnIFormat = useMemo(() => {
		let newValue;
		columnNames.forEach((columnName) => {
			if (columnName.id === dispatcherDisplayedColumn) newValue = columnName;
		});
		return newValue;
	}, [dispatcherDisplayedColumn]);

	const closeFuncWithOutConfirm = useCallback(() => {
		modalController.modalRemove(idModal);
	}, [idModal]);

	const handleClickToName = useCallback(() => {
		modalController.popupAdd({
			id: idModal,
			layout: (
				<LayoutPopup
					closeFuncWithOutConfirm={closeFuncWithOutConfirm}
					columnNames={columnNames}
					checkWarningForSetting={props.checkWarningForSetting}
				/>
			),
			closeFunc: closeFuncWithOutConfirm
		});
	}, [LayoutPopup, columnNames]);

	return (
		<div className={styles.mainWizardPanelNavigation}>
			<Button
				caption="Закрыть"
				firstIcon={<DragIcon style={{ fill: "var(--color-gray-300)", stroke: "var(--color-gray-300)" }} />}
				style={ButtonStyle.DragGrayButton}
			/>
			<div className={styles.navigationTitle}>
				<span className={styles.nameTitle}>{sectionWizzard?.entityTitle}</span>
				<ArrowToRight style={{ width: "5%" }} />
				<Button
					caption={displayedColumn}
					className={styles.nameTitleButton}
					classNameCaption={styles.nameTitleButtonCaption}
					onClick={handleClickToName}
				/>
			</div>
			<Button
				caption="Действия"
				firstIcon={<DragIcon style={{ fill: "var(--color-gray-300)", stroke: "var(--color-gray-300)" }} />}
				style={ButtonStyle.DragGrayButton}
			/>
		</div>
	);
});

export default ConstructorTitle;
