import { ChangeEvent, useState } from 'react';
import { isUndefined } from 'lodash';

import classNames from 'classnames';

import styles from './multiline-field.module.scss'

export const SIZE = {
    Small: "Small",
    Medium: "Medium",
    Large: "Large",
};

export interface Props {
    size: string,
    disabled?: boolean,
    maxSymbols?: number,
    value: string,
    placeholder?: string,
    onChange: (value: string) => void,
}

/**
 * @description Компонент Многострочное поле
 * @param size размер текста
 * @param disabled заблокирован ли компонент для ввода
 * @param maxSymbols максимальное число символов, доступных для введения
 * @param value значение в поле
 * @param placeholder placeholder поля
 * @param onChange функция изменения значения
 * @example <MultiLineField size={SIZE.Large} maxSymbols={200} value={value} onChange={(value: string)=>{setValue(value);}}/>
 */
export const MultiLineField = (props: Props) => {
    const [rows, setRows] = useState(2);

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const inputValue = event.target.value;
        if (isUndefined(props.maxSymbols) || inputValue.length <= props.maxSymbols) {
            props.onChange(inputValue)
        }
        const lineBreaks = (inputValue.match(/\n/g) || []).length;
        setRows(Math.min(15, lineBreaks + 2));
    };

    const textareaClasses = classNames(styles.textarea, {
        [styles[props.size]]: props.size,
        [styles.disabled]: props.disabled,
    });

    return (
        <div className={styles.textareaContainer}>
            <textarea
                className={textareaClasses}
                value={props.value ?? ""}
                onChange={handleChange}
                disabled={props.disabled}
                rows={rows}
                maxLength={props.maxSymbols}
                placeholder={props.placeholder ?? "Значение поля"}
            />
            {props.maxSymbols && !props.disabled && (
                <div className={styles.counter}>{props.value ? props.maxSymbols - props.value?.length : props.maxSymbols}</div>
            )}
        </div>
    );
};
