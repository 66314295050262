import { useMemo } from "react";

import { Icon } from "sale-bridge-ui-kit";
import { useCommentContext } from "features/comments";

import styles from "./editable-comment.module.scss";




export const EditableCommentBlock = () => {
    const { comment } = useCommentContext();

    const text = useMemo(() => {
        const array = comment.text.split('\\n');
        return array[0];
    }, [comment.text]);

    return (
        <div className={styles.editableCommentBlockWrapper}>
            <div className={styles.editableCommentBlockIconContainer}>
            <Icon name='Pencil' size="24" className={styles.editableCommentBlockIcon} />
            </div>
            <div className={styles.editableCommentBlockBody}>
                <span className={styles.editableCommentBlockTitle}>Редактирование комментария</span>
                <span className={styles.editableCommentBlockText}>
                   {text}
                </span>
            </div>
        </div>
    );
}
