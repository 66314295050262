import { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import { toJS } from "mobx";
import { v4 } from "uuid";
import { isUndefined } from "lodash";

import { UpFirst } from "shared";
import fieldConfigurationStore, { FieldStoreEnums } from "../field-configuration-store";

import { Button, ButtonStyle } from "components";
import { Button as LibButton, Field, Clue } from "sale-bridge-ui-kit";
import { BasicSettings } from "./basic-settings";
import { CheckboxesBlock } from "./checkboxes-block";

import { NewLookupTabs, getInfoTitle } from "../types";

import styles from "../field-configuration.module.scss";

export const LookupConfiguration = observer(() => {
	const [tab, setTab] = useState(NewLookupTabs.LookupTab);
	const [selected, setSelected] = useState<number>(0);
	const [searchValue, setSearchValue] = useState("");

	const handleDefValueClick = useCallback(
		(value: any) => {
			fieldConfigurationStore.setValue(FieldStoreEnums.selectedLookupDefaultValue, value);
			if (value) {
				fieldConfigurationStore.setValue(FieldStoreEnums.defaultValue, fieldConfigurationStore.selectedLookupDefaultValue!.id);
			} else {
				fieldConfigurationStore.setValue(FieldStoreEnums.defaultValue, value);
			}
		},
		[fieldConfigurationStore.selectedLookupDefaultValue]
	);

	const defValues = useMemo(() => {
		if (fieldConfigurationStore.virtualLookup.virtualLookupValues) {
			return [
				{
					layout: <></>,
					items: fieldConfigurationStore.virtualLookup.virtualLookupValues.map((item) => (
						<div className={styles.selectItem} onClick={() => handleDefValueClick(item)}>
							<span>{item.name}</span>
						</div>
					))
				}
			];
		}
		return [];
	}, [toJS(fieldConfigurationStore.virtualLookup?.virtualLookupValues)]);

	const selectedLookupDefaultValueName = useMemo(() => {
		return fieldConfigurationStore.selectedLookupDefaultValue?.name ?? "";
	}, [fieldConfigurationStore.selectedLookupDefaultValue?.name]);

	const handleClearDefaultValue = useCallback(() => {
		fieldConfigurationStore.setValue(FieldStoreEnums.selectedLookupDefaultValue, null);
	}, [fieldConfigurationStore.selectedLookupDefaultValue]);

	const virtualLookupValues = useMemo(() => {
		return [
			{
				layout: <></>,
				items: fieldConfigurationStore.virtualLookup.virtualLookupValues.map((item) => (
					<div className={styles.selectItem} onClick={() => handleDefValueClick(item)}>
						<span>{item.name}</span>
					</div>
				))
			}
		];
	}, [toJS(fieldConfigurationStore.virtualLookup.virtualLookupValues)]);

	const defaultVirtualValueField = useMemo(() => {
		return (
			<Field
				type="select"
				size="small"
				label="Значение по умолчанию"
				labelPosition="vertical"
				textVariant="outlined"
				info="Значение, которым заполнится поле при создании записи"
				value={selectedLookupDefaultValueName}
				items={virtualLookupValues}
				onChange={handleClearDefaultValue}
				isClearing
				searchValue={searchValue} //TODO чтобы заработал поиск нужно в items сделать filter по searchValue
				onChangeSearchValue={setSearchValue}
			/>
		);
	}, [toJS(defValues), fieldConfigurationStore.selectedLookupDefaultValue, searchValue]);

	const removeBadVirtualLookupValues = () => {
		fieldConfigurationStore.virtualLookup.virtualLookupValues = fieldConfigurationStore.virtualLookup.virtualLookupValues.filter(
			(item) => item.name.trim() !== ""
		);
	};

	const handleChangeTab = (tabIndex: NewLookupTabs) => {
		//TODO раскомментировать если потребуется такая проверка при новых кейсах
		// if (tab === NewLookupTabs.LookupTab) {
		// 	if (fieldConfigurationStore.virtualLookup.entityTitle.trim() === ""
		// 		|| fieldConfigurationStore.virtualLookup.systemName.trim() === "") {
		// 		return;
		// 	}
		// }
		removeBadVirtualLookupValues();
		setSelected(tabIndex);
		setTab(tabIndex);
	};

	const handleAddVirtualLookupValue = () => {
		fieldConfigurationStore.virtualLookup.virtualLookupValues.push({ id: v4(), name: "" });
		fieldConfigurationStore.hasChanges = true;
	};

	const onChangeVirtualLookupValue = (value: string, index: number) => {
		fieldConfigurationStore.virtualLookup.virtualLookupValues[index].name = value;
	};

	const handleTitleChange = useCallback(
		async (value: string) => {
			fieldConfigurationStore.virtualLookup.entityTitle = value;
			fieldConfigurationStore.hasChanges = true;
			await fieldConfigurationStore.validateNewLookupTitle();
		},
		[fieldConfigurationStore.virtualLookup.entityTitle]
	);

	const handleVirtualLookupSystemNameChange = useCallback(
		(value: string) => {
			fieldConfigurationStore.virtualLookup.systemName = UpFirst(value);
			fieldConfigurationStore.hasChanges = true;
			fieldConfigurationStore.validateNewLookupName();
		},
		[fieldConfigurationStore.virtualLookup.systemName]
	);

	const handleRemoveVirtualLookupValue = (index: number) => {
		const valueToRemove = fieldConfigurationStore.virtualLookup.virtualLookupValues[index];

		if (valueToRemove.id === fieldConfigurationStore.defaultValue) {
			return;
		}
		fieldConfigurationStore.virtualLookup.virtualLookupValues.splice(index, 1);
	};

	const itemClasses = useCallback(
		(i: number) => {
			return classNames(`${styles.tabButton} `, {
				[`${styles.selected} `]: selected === i
			});
		},
		[selected]
	);

	const valuesMapping = useMemo(() => {
		return fieldConfigurationStore.virtualLookup.virtualLookupValues.map((item, index) => (
			<div className={styles.item} key={index}>
				<Field
					type="text"
					size="small"
					label=""
					textVariant="outlined"
					isRequired
					value={item.name}
					placeholder="Значение поля"
					info="Название, которое будет отображаться в базе данных"
					counter={50}
					isClearing
					onChange={(value: string) => {
						onChangeVirtualLookupValue(value, index);
					}}
				/>
				<LibButton
					text=""
					size="small"
					variant="dangerSecondary"
					border={false}
					link={false}
					loading={false}
					leftIcon="Delete"
					onClick={(e) => handleRemoveVirtualLookupValue(index)}
				/>
			</div>
		));
	}, [toJS(fieldConfigurationStore.virtualLookup.virtualLookupValues)]);

	return (
		<>
			{getInfoTitle()}
			<div className={styles.tabContainer}>
				<Button
					key="newLookupSettingTab"
					caption="Справочник"
					onClick={() => handleChangeTab(NewLookupTabs.LookupTab)}
					className={itemClasses(NewLookupTabs.LookupTab)}
					style={ButtonStyle.DragGrayButton}
				/>
				<Button
					key="settingFieldTab"
					caption="Настройки поля"
					onClick={() => handleChangeTab(NewLookupTabs.FieldSettingsTab)}
					className={itemClasses(NewLookupTabs.FieldSettingsTab)}
					style={ButtonStyle.DragGrayButton}
				/>
			</div>
			{tab === NewLookupTabs.LookupTab ? (
				<>
					<Field
						type="text"
						size="small"
						label="Название справочника"
						labelPosition="vertical"
						textVariant="outlined"
						isRequired
						value={!fieldConfigurationStore.fieldIsLoading ? fieldConfigurationStore.virtualLookup.entityTitle : ""}
						placeholder="Название справочника"
						info="Название нового справочника"
						counter={50}
						isClearing
						onChange={handleTitleChange}
						alert={fieldConfigurationStore.validation.virtualLookupTitle.isInvalid ? "error" : undefined}
					/>
					<div className={styles.fieldsPadding} />
					<Field
						type="text"
						size="small"
						label="Системное название"
						labelPosition="vertical"
						textVariant="outlined"
						isRequired
						value={!fieldConfigurationStore.fieldIsLoading ? fieldConfigurationStore.virtualLookup.systemName : ""}
						placeholder="Название таблицы в базе данных"
						info="Название, которое будет отображаться в базе данных"
						counter={50}
						isClearing
						onChange={handleVirtualLookupSystemNameChange}
						isDisabled={fieldConfigurationStore.getIsDisabledForSystemName()}
						alert={fieldConfigurationStore.validation.virtualLookupSystemName.isInvalid ? "error" : undefined}
					/>
					<div className={styles.lookupValuesTitleWrap}>
						<span className={styles.lookupValuesTitle}>Значения справочника</span>
						<span className={styles.lookupValuesInfoCaption}>
							Наполните справочник. Обращаем внимание, что будут добавлены только текстовые значения.
						</span>
						<div className={styles.clue}>
							<Clue textClue="Как добавить не текстовые значения" size="small" />
						</div>
					</div>
					<div className={styles.valueItemsInLookup}>{fieldConfigurationStore.fieldIsLoading ? <></> : valuesMapping}</div>
					<LibButton
						text="Добавить"
						size="small"
						variant="secondary"
						border={false}
						link={false}
						loading={false}
						leftIcon="PlusSquareFill"
						onClick={handleAddVirtualLookupValue}
					/>
				</>
			) : (
				<>
					<div className={styles.fieldsSettingsLookup}>
						<BasicSettings />
						{defaultVirtualValueField}
						<Field
							type="text"
							size="small"
							label="Подсказка"
							labelPosition="vertical"
							textVariant="outlined"
							info="То, что вы читаете прямо сейчас 🙂"
							value={fieldConfigurationStore.prompt}
							onChange={(value: string) => {
								fieldConfigurationStore.setValue(FieldStoreEnums.prompt, value);
							}}
						/>
					</div>
					<CheckboxesBlock />
				</>
			)}
		</>
	);
});
