import { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { v4 } from "uuid";
import { toJS } from "mobx";

import { dispatcher } from "store";

import { modalController } from "features/modals";
import NewOperationItem from "./new-operation-item/new-operation-item";

import { Button, ButtonStyle, DataGrid, Toggle } from "components";
import FilterColumnType from "entities/ColumnType";

import styles from "../access-rights.module.css";


const AccessRightsAdminOperation = observer(() => {
    const [idModal] = useState<string>(v4());

    const sectionWizzard = useMemo(
        () => {
            return dispatcher.entity.get()?.entity.sectionWizzard;
        }, [dispatcher.entity.get()?.entity.sectionWizzard]
    );

    const adminByOperation = useMemo(() => {
        return sectionWizzard?.accessRightsConfig.adminByOperation;
    }, [dispatcher.entity.get()?.entity.sectionWizzard?.accessRightsConfig.adminByOperation]);

    const operationItems = useMemo(() => {
        return dispatcher.entity.get()?.entity.sectionWizzard?.accessRightsConfig.adminByOperation.operationItems ?? [];
    }, [toJS(dispatcher.entity.get()?.entity.sectionWizzard?.accessRightsConfig.adminByOperation.operationItems)]);

    const columnsOperation = useMemo(() => [
        {
            name: "unitName",
            caption: "Роль/Пользователь",
            width: 30,
            type: FilterColumnType.String,
            sortColumn: "unitName",
        },
        {
            name: "canCreate",
            caption: "Создание",
            width: 15,
            type: FilterColumnType.Boolean,
            sortColumn: "canCreate",
            check: true,
            onChecked: (entityId: any, checked: boolean, id: string) => { dispatcher.sectionWizzard.setOperationActions(id, "canCreate", checked); }
        },
        {
            name: "canRead",
            caption: "Чтение",
            width: 15,
            type: FilterColumnType.Boolean,
            sortColumn: "canRead",
            check: true,
            onChecked: (entityId: any, checked: boolean, id: string) => { dispatcher.sectionWizzard.setOperationActions(id, "canRead", checked); }
        },
        {
            name: "canEdit",
            caption: "Редактирование",
            width: 15,
            type: FilterColumnType.Boolean,
            sortColumn: "canEdit",
            check: true,
            onChecked: (entityId: any, checked: boolean, id: string) => { dispatcher.sectionWizzard.setOperationActions(id, "canEdit", checked); }
        },
        {
            name: "canDelete",
            caption: "Удаление",
            width: 25,
            type: FilterColumnType.Boolean,
            sortColumn: "canDelete",
            check: true,
            onChecked: (entityId: any, checked: boolean, id: string) => { dispatcher.sectionWizzard.setOperationActions(id, "canDelete", checked); }
        }
    ], [operationItems.map(item => item)]);

    const handleSwitchEnableOperation = useCallback((value: boolean) => {
        dispatcher.sectionWizzard.setEnableAdminByOperation(value);
    }, []);

    const closeFuncWithOutConfirm = useCallback(() => {
        modalController.modalRemove(idModal);
    }, []);

    const handleAddClick = useCallback(() => {
        modalController.popupAdd({
            id: idModal, 
            layout: <NewOperationItem close={closeFuncWithOutConfirm} />, 
            closeFunc: closeFuncWithOutConfirm
        });
    }, []);

    return (
        <div className={styles.accessRightsBlock}>
            <div className={styles.toogleHeader}>
                <Toggle checked={adminByOperation?.isEnabled ?? false} onCheck={handleSwitchEnableOperation} />
                <span>Использовать доступ по операциям</span>
            </div>
            <div className={styles.grid}>
                <DataGrid columns={columnsOperation} otherData={operationItems} />
            </div>
            <Button style={ButtonStyle.Indigo} className={styles.addButton} onClick={handleAddClick} caption="Добавить" />
        </div>
    );
});

export default AccessRightsAdminOperation;
