import styles from "./custom-grid.module.css";

interface CustomGridProps {
    columns: ICustomColumn[];
    datas: any[];
    className?: string;
}

export interface ICustomColumn {
    caption: string;
    name: string;
    width?: any;
    head?: JSX.Element;
    onChange?: (value: any) => void;
    render?: (column: ICustomColumn, value: any) => JSX.Element;
}

function CustomGrid(props: CustomGridProps) {
    const columns = { gridTemplateColumns: props.columns.map(c => c.width ?? "1fr").join(" ") };
    let classNames = `${styles.wrapper} `;

    if (props.className) classNames += props.className;

    return (
        <div className={classNames}>
            <div className={styles.header} style={columns}>
                {props.columns.map((c, index) =>
                    <>{c.head ??
                        <span key={c.name + index}>{c.caption}</span>
                    }</>
                )}
            </div>
            <div className={styles.body}>
                {props.datas.map((data) => <Row key={JSON.stringify(data)} columns={props.columns} data={data} />)}
            </div>
        </div >
    )
}

function Row(props: { columns: ICustomColumn[], data: any }) {
    const columns = { gridTemplateColumns: props.columns.map(c => c.width ?? "1fr").join(" ") };

    return (
        <div className={styles.row} style={columns}>
            {props.columns.map(c => {
                const column = c.render ?? ((column: ICustomColumn, value: any) => <Column key={column.name} column={column} value={value} />);

                return (
                    column(c, Reflect.get(props.data, c.name) ?? props.data.caption)
                );
            })}
        </div>
    );
}

function Column(props: { column: ICustomColumn, value: any }) {
    return (
        <div className={styles.column} >
            <span>{props.value}</span>
        </div>
    )
}

export default CustomGrid;