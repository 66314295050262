import classNames from "classnames";
import { isUndefined } from "lodash";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { v4 } from "uuid";

import { dispatcher, store } from "store";
import {
	DetailConfiguration,
	FieldGroupConfiguration,
	detailConfigurationStore,
	fieldConfigurationStore,
	fieldGroupConfigurationStore
} from "pages/section-wizzard/pages/constructor";

import { Element } from "modules/DND/element";
import { Types } from "modules/DND/type";
import { Zone } from "modules/DND/zone";

import { modalController } from "features/modals";

import { Button, ButtonStyle } from "components";

import { DEFAULT_WARNING_TEXT, POST_INIT_TIME } from "pages/section-wizzard/data/data";
import { BlockedColumnNames, ColumnSpecializationType, GridItem, SystemColumns } from "types/entity";

import { ColumnType } from "entities/ColumnType";

import { ChangeView, Close, DragIcon, FieldGroupIcon, Rename, Warning } from "shared";
import TypeIcon from "features/type-icon/type-icon";

import styles from "./drag-blocks.module.scss";

export interface DragFieldBlockProps {
	element: GridItem;
	icon: JSX.Element;
	width: string;
	boxColumns?: GridItem[];
	onEditField: (columnId: string) => void;
	onDeleteField: (gridItemId: string) => void;
}
/**
 * @description Задержка перед повторной инициализацией zone, нужно для пост инициализации после проигрывания анимации
 */
export const DragFieldBlock = observer((props: DragFieldBlockProps) => {
	const editButtonClasses = classNames(`${styles.editButton} `);
	const deleteButtonClasses = classNames(`${styles.deleteButton} `);

	const isVisibleEditButton = useMemo(() => {
		const { element, boxColumns } = props;
		const { fieldConfig } = element;

		if (!fieldConfig) {
			return true;
		}

		const isColumnBlocked = BlockedColumnNames.includes(fieldConfig.columnName as SystemColumns);
		const isColumnInBoxColumns = boxColumns?.some((column) => column.fieldConfig?.columnId === fieldConfig.columnId);
		const isStageField = dispatcher.sectionWizzard.isSpecificField(fieldConfig, ColumnSpecializationType.KanbanField, {
			stageField: "true"
		});
		const isFlagField = dispatcher.sectionWizzard.isSpecificField(fieldConfig, ColumnSpecializationType.Flag, {
			sysFlagColumn: "true"
		});
		const isFlagDateField = dispatcher.sectionWizzard.isSpecificField(fieldConfig, ColumnSpecializationType.Flag, {
			sysFlagDateColumn: "true"
		});

		return !(isColumnBlocked || isColumnInBoxColumns || isStageField || isFlagField || isFlagDateField);
	}, [props.element, props.boxColumns]);

	const handleMouseDown = useCallback((e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
		e.stopPropagation();
	}, []);

	const fieldClassName = classNames(styles.fieldWrapper, {
		[`${styles.fillField} `]: props.element?.fieldConfig?.columnTitle?.length ?? false
	});

	return (
		<div style={{ width: props.width }} className={fieldClassName}>
			<DragIcon style={{ stroke: "var(--color-gray-300)" }} />
			{props.icon}
			<span className={styles.fieldCaption}>
				{props.element.fieldConfig?.isRequired && <span className={styles.star}>* </span>}
				{props.element.fieldConfig?.columnTitle ?? ""}
			</span>
			<div className={styles.buttonsInField}>
				<Button
					style={ButtonStyle.Icon}
					firstIcon={<Rename />}
					className={editButtonClasses}
					onClick={() => props.onEditField(props.element.fieldConfig?.columnId!)}
					onMouseDown={handleMouseDown}
					isVisible={isVisibleEditButton}
				/>
				<Button
					style={ButtonStyle.Icon}
					firstIcon={<Close />}
					className={deleteButtonClasses}
					onClick={() => props.onDeleteField(props.element.gridItemId)}
					onMouseDown={handleMouseDown}
				/>
			</div>
		</div>
	);
});

export interface DragFieldGroupBlockProps {
	id: string;
	elements: Array<GridItem>;
	groupName: string;
	groupField: GridItem;
	targetZone?: string;
	rowQuality?: number;
	onEditField: (columnId: string) => void;
	onDeleteField: (gridItemId: string) => void;
}

//TODO прикрутить еще стрелочку дропдаун
export const DragFieldGroupBlock = observer((props: DragFieldGroupBlockProps) => {
	const [gridItems, setGridItems] = useState<GridItem[]>([]);

	const [targetElementViewLayout, setTargetElementViewLayout] = useState<JSX.Element>(<></>);
	const [cellLayoutWidth, setCellLayoutWidth] = useState(0);

	const editButtonClasses = classNames(`${styles.editButton} `);
	const deleteButtonClasses = classNames(`${styles.deleteButton} `);

	const [idModal] = useState<string>(v4());
	const [idConfirm] = useState<string>(v4());
	const WARNING_TEXT = "Поля внутри группы тоже будут удалены.\nУдалить группу полей?";

	const sectionWizzard = useMemo(() => {
		return dispatcher.entity.get()?.entity.sectionWizzard;
	}, [dispatcher.entity.get()?.entity.sectionWizzard]);

	const isNew = useMemo(() => {
		return dispatcher.entity.get()?.isNew;
	}, [dispatcher.entity.get()?.isNew]);

	const currentTabIndex = useMemo(() => {
		return sectionWizzard?.reactorConfig.tabs.currentTab ?? 0;
	}, [sectionWizzard?.reactorConfig.tabs.currentTab]);

	const container = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const updateContainerSize = () => {
			if (container.current) {
				setCellLayoutWidth(container.current.getBoundingClientRect().width / 2);
			}
		};
		updateContainerSize();
		window.addEventListener("resize", updateContainerSize);
		return () => {
			window.removeEventListener("resize", updateContainerSize);
		};
	}, []);

	useEffect(() => {
		if (JSON.stringify(props.elements) !== JSON.stringify(gridItems)) {
			setGridItems([...props.elements]);
		}
	}, [toJS(props.elements)]);

	const handleDrop = useCallback(
		({
			elementId,
			sourceZone,
			targetZone,
			MATRIX
		}: {
			elementId: string | null;
			sourceZone: string | null;
			targetZone: string | null;
			type: Types;
			sourceData?: any;
			[key: string]: any;
		}) => {
			const inFieldGroup = props.elements.find(
				({ gridItemId: id, groupFieldsConfig }) => id ?? groupFieldsConfig?.groupFieldId === targetZone
			);
			if (inFieldGroup) {
				const dragElement = props.elements.find(({ gridItemId }) => gridItemId === elementId);
				const innerDragElement = inFieldGroup?.groupFieldsConfig?.inner?.items.find(({ gridItemId }) => gridItemId === elementId);
				if (dragElement) {
					const gridItem: GridItem = {
						...dragElement,
						x: MATRIX.cellX,
						y: MATRIX.cellY,
						gridItemId: dragElement?.gridItemId,
						width: dragElement?.width,
						height: dragElement?.height
					};
					dispatcher.sectionWizzard.autoPut({
						tabIndex: currentTabIndex ?? 0,
						targetId: targetZone || "",
						sourceId: sourceZone,
						item: gridItem
					});
				} else {
					if (innerDragElement) {
						const gridItem: GridItem = {
							...innerDragElement,
							x: MATRIX.cellX,
							y: MATRIX.cellY,
							gridItemId: innerDragElement?.gridItemId,
							width: innerDragElement?.width,
							height: innerDragElement?.height
						};
						dispatcher.sectionWizzard.autoPut({
							tabIndex: currentTabIndex ?? 0,
							targetId: targetZone || "",
							sourceId: sourceZone,
							item: gridItem
						});
					}
				}
			} else {
				const dragElement = props.elements.find(({ gridItemId }) => gridItemId === elementId);
				if (dragElement) {
					const gridItem: GridItem = {
						...dragElement,
						x: MATRIX.cellX,
						y: MATRIX.cellY,
						gridItemId: dragElement?.gridItemId,
						width: dragElement?.width,
						height: dragElement?.height
					};
					dispatcher.sectionWizzard.autoPut({
						tabIndex: currentTabIndex ?? 0,
						targetId: targetZone || "",
						sourceId: sourceZone,
						item: gridItem
					});
				}
			}
		},
		[toJS(props.elements), currentTabIndex]
	);

	const handleUp = useCallback(
		({ elementId }: { elementId: string }) => {
			if (props.elements) {
				const item = props.elements.find((element) => element.gridItemId === elementId);

				if (item) {
					const fieldClassName = classNames(`${styles.fieldWrapper} ${styles.targetElementViewLayout} `, {
						[`${styles.fillField} `]: item?.fieldConfig?.columnTitle?.length ?? false
					});

					setTargetElementViewLayout(
						<div style={{ width: `calc(${cellLayoutWidth}px * 0.4)` }} key={item.gridItemId} className={fieldClassName}>
							<DragIcon />
							<TypeIcon type={item?.fieldConfig?.columnType ?? ColumnType.FieldGroup} />
							<span className={styles.targetElementTitle}>{item.fieldConfig?.columnTitle}</span>
						</div>
					);
				}
			}
		},
		[cellLayoutWidth, toJS(props.elements)]
	);

	const handleMouseDown = useCallback((e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
		e.stopPropagation();
	}, []);

	const closeConfirm = useCallback(() => {
		modalController.modalRemove(idConfirm);
	}, []);

	const closeFuncWithConfirm = useCallback(() => {
		modalController.popupAdd({ id: idConfirm, layout: warningConfirm, closeFunc: closeConfirm });
	}, [idConfirm]);

	const closeAllModals = useCallback(() => {
		store.modals.map((modal) => {
			modalController.modalRemove(modal.id);
		});
		fieldConfigurationStore.resetConfiguration();
		detailConfigurationStore.resetConfiguration();
		fieldGroupConfigurationStore.resetConfiguration();
	}, [store.modals]);

	const handleDeleteGroupClick = useCallback(() => {
		dispatcher.sectionWizzard.deleteFieldGroupFromTab(props.groupField.gridItemId, isNew);
		closeAllModals();
	}, [props.groupField.gridItemId, isNew]);

	const warningDeleteConfirm = useMemo(() => {
		return (
			<div className={styles.warningDialog}>
				<div className={styles.warningHeader}>
					<span className={styles.warningTitle}>Внимание</span>
					<Warning />
				</div>
				<div className={styles.warningDialogBody}>
					<span className={styles.title}>{WARNING_TEXT}</span>
				</div>
				<div className={styles.dialogFooter}>
					<Button caption="Вернуться к редактированию" onClick={closeConfirm} style={ButtonStyle.Subtle} />
					<Button caption="Удалить" onClick={handleDeleteGroupClick} style={ButtonStyle.Danger} />
				</div>
			</div>
		);
	}, [closeAllModals, closeConfirm, sectionWizzard]);

	const warningConfirm = useMemo(() => {
		return (
			<div className={styles.warningDialog}>
				<div className={styles.warningHeader}>
					<span className={styles.warningTitle}>Внимание</span>
					<Warning />
				</div>
				<div className={styles.warningDialogBody}>
					<span className={styles.title}>{DEFAULT_WARNING_TEXT}</span>
				</div>
				<div className={styles.dialogFooter}>
					<Button caption="Вернуться к редактированию" onClick={closeConfirm} style={ButtonStyle.Subtle} />
					<Button caption="Да, отменить" onClick={closeAllModals} style={ButtonStyle.Danger} />
				</div>
			</div>
		);
	}, [closeAllModals, closeConfirm, sectionWizzard]);

	const handleDeleteClick = useCallback(() => {
		modalController.popupAdd({ id: idConfirm, layout: warningDeleteConfirm, closeFunc: closeConfirm });
	}, [idConfirm]);

	const closeOnFocusModal = () => {
		if (fieldGroupConfigurationStore.hasChanges) {
			closeFuncWithConfirm();
		} else {
			closeAllModals();
		}
	};

	const handleEditClick = useCallback(() => {
		modalController.popupAdd({
			id: idModal,
			layout: (
				<FieldGroupConfiguration
					position={{ cellX: -1, cellY: -1 }}
					fieldGroupId={props.groupField.gridItemId}
					close={closeFuncWithConfirm}
					closeAll={closeAllModals}
				/>
			),
			closeFunc: closeOnFocusModal
		});
	}, [closeAllModals, closeFuncWithConfirm, idModal, props.groupField.gridItemId, container.current]);

	const innerGrid = useMemo(
		() =>
			gridItems.map((gridItem: GridItem) => {
				if (isUndefined(gridItem.fieldConfig?.columnType)) {
					return <></>;
				}
				return (
					<Element
						key={gridItem.gridItemId}
						id={gridItem.gridItemId}
						x={gridItem.x}
						y={gridItem.y}
						width={gridItem.width}
						height={gridItem.height}
					>
						<DragFieldBlock
							element={gridItem}
							icon={<TypeIcon type={gridItem.fieldConfig?.columnType} />}
							width={`calc(${cellLayoutWidth} - 20px)`}
							onEditField={props.onEditField}
							onDeleteField={props.onDeleteField}
						/>
					</Element>
				);
			}),
		[toJS(gridItems), cellLayoutWidth, props.onDeleteField, props.onEditField]
	);

	const cellLayout = useMemo(() => {
		const fixedHeight = 48;
		const width = `calc(${cellLayoutWidth} - 10px)`;
		const hardStyle = {
			width: width,
			height: `${fixedHeight} px`
		};
		return (
			<div style={hardStyle}>
				<div className={styles.fieldWrapper}></div>
			</div>
		);
	}, [cellLayoutWidth]);

	const zoneConfig = useMemo(() => {
		return {
			targetElementViewLayout: targetElementViewLayout,
			cellLayout: cellLayout,
			placeholderLayout: cellLayout,
			predictLayout: <div className={styles.predictLayout} />,
			width: 2,
			height: props.rowQuality
		};
	}, [targetElementViewLayout, cellLayout, props.rowQuality]);

	return (
		<div ref={container} className={styles.fieldGroupWrap}>
			<div className={styles.fieldGroupHead}>
				<DragIcon style={{ stroke: "var(--color-gray-300)" }} />
				<FieldGroupIcon style={{ stroke: "var(--color-indigo-800)" }} />
				<span className={styles.indigo800TitleGroup}>Группа полей: </span>
				<span className={styles.fieldCaption}>{props.groupName} </span>
				<div className={styles.buttonsInGroupField}>
					<Button
						style={ButtonStyle.Icon}
						firstIcon={<Rename />}
						className={editButtonClasses}
						onClick={handleEditClick}
						onMouseDown={handleMouseDown}
						isVisible
					/>
					<Button
						style={ButtonStyle.Icon}
						firstIcon={<Close />}
						className={deleteButtonClasses}
						onClick={handleDeleteClick}
						onMouseDown={handleMouseDown}
					/>
				</div>
			</div>
			<Zone id={props.id} config={zoneConfig} type={Types.MATRIX} postInitTime={POST_INIT_TIME} onDrop={handleDrop} onUp={handleUp}>
				{innerGrid}
			</Zone>
		</div>
	);
});

export const DragDetailBlock = (props: { element: GridItem }) => {
	const WARNING_TEXT = "Вы действительно хотите удалить деталь?";
	const editButtonClasses = classNames(`${styles.editButton} `);
	const deleteButtonClasses = classNames(`${styles.deleteButton} `);
	const [idModal] = useState<string>(v4());
	const [idConfirm] = useState<string>(v4());

	const sectionWizzard = useMemo(() => {
		return dispatcher.entity.get()?.entity.sectionWizzard;
	}, [dispatcher.entity.get()?.entity.sectionWizzard]);

	const currentTabIndex = useMemo(() => {
		return sectionWizzard?.reactorConfig.tabs.currentTab ?? 0;
	}, [sectionWizzard?.reactorConfig.tabs.currentTab]);

	const closeConfirm = useCallback(() => {
		modalController.modalRemove(idConfirm);
	}, []);

	const closeFuncWithConfirm = useCallback(() => {
		modalController.popupAdd({ id: idConfirm, layout: warningConfirm, closeFunc: closeConfirm });
	}, [idConfirm]);

	const closeAllModals = useCallback(() => {
		store.modals.map((modal) => {
			modalController.modalRemove(modal.id);
		});
		fieldConfigurationStore.resetConfiguration();
		detailConfigurationStore.resetConfiguration();
		fieldGroupConfigurationStore.resetConfiguration();
	}, [store.modals]);

	const handleDeleteDetailClick = useCallback(() => {
		dispatcher.sectionWizzard.deleteGridItemFromTab(props.element.gridItemId, currentTabIndex);
		closeAllModals();
	}, [currentTabIndex, props.element]);

	const warningDeleteConfirm = useMemo(() => {
		return (
			<div className={styles.warningDialog}>
				<div className={styles.warningHeader}>
					<span className={styles.warningTitle}>Внимание</span>
					<Warning />
				</div>
				<div className={styles.warningDialogBody}>
					<span className={styles.title}>{WARNING_TEXT}</span>
				</div>
				<div className={styles.dialogFooter}>
					<Button caption="Отменить" onClick={closeConfirm} style={ButtonStyle.Subtle} />
					<Button caption="Удалить" onClick={handleDeleteDetailClick} style={ButtonStyle.Danger} />
				</div>
			</div>
		);
	}, [closeAllModals, closeConfirm, sectionWizzard]);

	const warningConfirm = useMemo(() => {
		return (
			<div className={styles.warningDialog}>
				<div className={styles.warningHeader}>
					<span className={styles.warningTitle}>Внимание</span>
					<Warning />
				</div>
				<div className={styles.warningDialogBody}>
					<span className={styles.title}>{DEFAULT_WARNING_TEXT}</span>
				</div>
				<div className={styles.dialogFooter}>
					<Button caption="Вернуться к редактированию" onClick={closeConfirm} style={ButtonStyle.Subtle} />
					<Button caption="Да, отменить" onClick={closeAllModals} style={ButtonStyle.Danger} />
				</div>
			</div>
		);
	}, [closeAllModals, closeConfirm, sectionWizzard]);

	const handleDeleteClick = useCallback(() => {
		modalController.popupAdd({ id: idConfirm, layout: warningDeleteConfirm, closeFunc: closeConfirm });
	}, [idConfirm]);

	const closeOnFocusModal = () => {
		if (fieldGroupConfigurationStore.hasChanges) {
			closeFuncWithConfirm();
		} else {
			closeAllModals();
		}
	};

	const handleEditClick = useCallback(() => {
		modalController.popupAdd({
			id: idModal,
			layout: (
				<DetailConfiguration
					position={{ cellX: -1, cellY: -1 }}
					detailId={props.element.gridItemId}
					close={closeFuncWithConfirm}
					closeAll={closeAllModals}
				/>
			),
			closeFunc: closeOnFocusModal
		});
	}, [closeAllModals, closeFuncWithConfirm, idModal, props.element.gridItemId, props.element.fieldConfig?.columnType]);

	const handleMouseDown = useCallback((e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
		e.stopPropagation();
	}, []);

	return (
		<div className={styles.detailWrap}>
			<div className={styles.detailHead}>
				<DragIcon style={{ stroke: "var(--color-gray-300)" }} />
				<ChangeView style={{ stroke: "var(--color-indigo-800)" }} />
				<span className={styles.indigo800Title}>Деталь: </span>
				<span className={styles.fieldCaption}>{props.element.detailConfig?.detailTitle} </span>
				<div className={styles.buttonsInGroupField}>
					<Button
						style={ButtonStyle.Icon}
						firstIcon={<Rename />}
						className={editButtonClasses}
						onClick={handleEditClick}
						onMouseDown={handleMouseDown}
						isVisible={true}
					/>
					<Button
						style={ButtonStyle.Icon}
						firstIcon={<Close />}
						className={deleteButtonClasses}
						onClick={handleDeleteClick}
						onMouseDown={handleMouseDown}
					/>
				</div>
			</div>
		</div>
	);
};
