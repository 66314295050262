import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";
import { toJS } from "mobx";
import { v4 } from "uuid";

import { dispatcher, store } from "store";
import ImportStore from "entities/import/ImportStore";
import { ModalType } from "features/modals/viewer/modal-viewer";

import { Upload } from "../upload/upload";
import ProgressBar from "../progress-bar/progress-bar";
import StatusBar from "components/status-bar/status-bar";
import ViewingFiles from "../viewingFiles/viewing-files";
import ErrorDownload from "../error-download/error-download";
import DisplayModeType from "../progress-bar/display-mode-type";
import { NEW_RECORD } from "synchroiser/synchroiser";
import { modalController, Position } from "features/modals";

import styles from "./files.module.css";

const TEXT = "Загружаем...";

const Files = observer((props: { importStore: ImportStore }) => {
	const [dragOver, setDragOver] = useState(false);
	const [isUpload, setUpload] = useState<boolean>(false);
	const row = useMemo(() => dispatcher.currentRow.get()!, [toJS(dispatcher.currentRow.get())]);
	const isNewRow = useMemo(() => row?.id === NEW_RECORD, [row?.id]);

	useEffect(() => {
		if (store.currentRowId && !isNewRow) {
			props.importStore.load(store.currentRowId);
		}
	}, []);

	const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
		if (e.type === "dragenter" || e.type === "dragover") {
			setDragOver(true);
		} else if (e.type === "dragleave") {
			setDragOver(false);
		}
	};

	const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
		setDragOver(false);
		if (store.currentRowId) {
			props.importStore.uploadFiles(store.currentRowId, e.dataTransfer.files);
		}
	};

	return (
		<div onDragEnter={handleDrag} className={styles.files}>
			{dragOver && !isNewRow ? (
				<div
					className={styles.dragOver}
					onDragOver={handleDrag}
					onDrop={handleDrop}
					onDragEnter={handleDrag}
					onDragLeave={handleDrag}
				>
					Перетащите файл сюда
				</div>
			) : (
				<>
					{props.importStore.isUploadStep && <ViewingFiles importStore={props.importStore} entityId={store.currentRowId} />}
					{<ProgressBar text={TEXT} displayMode={props.importStore.isLoading ? DisplayModeType.VIEW : DisplayModeType.HIDDEN} />}
					{props.importStore.isReadyToUpload && !props.importStore.isLoading && (
						<Upload importStore={props.importStore} isNewRow={isNewRow} errorCode={props.importStore.errorCode} />
					)}
					{props.importStore.errorUploadFile.length > 1 && <ErrorDownload importStore={props.importStore} />}
					<StatusBar eventName={"Файл загружен"} isOpen={isUpload} changeOpen={(value) => setUpload(value)} />
				</>
			)}
			{<NotificationComponent importStore={props.importStore} />}
		</div>
	);
});
const NotificationComponent = (props: { importStore: ImportStore }) => {
	useEffect(() => {
		if (props.importStore.error) {
			modalController.notificationAdd({
				id: v4(),
				type: ModalType.ERROR_NOTIFICATION,
				position: Position.CENTER,
				layout: <div>{props.importStore.error || "Не удалось загрузить файлы"}</div>,
				allowTimer: true,
				allowDefaultClick: true,
				withBackdrop: false
			});
		}
	}, [props.importStore.error]);

	return null;
};
export default Files;
