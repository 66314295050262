import Button from "../components/core/button/Button";
import Content from "../components/core/content/Content";
import Dropdown from "../components/core/dropdown/Dropdown";
import Input from "../components/core/input/Input";
import Label from "../components/core/label/Label";
import SelectWithSearchMini from "../components/core/selectWithSearchMini/SelectWithSearchMini";
import Tabs, { Tab } from "../components/core/tabs/Tabs";
import СommentsBlock from "../components/features/comments-block/comments-block";
import Detail from "../components/features/detail/detail";
import FieldGroup from "../components/features/field-group/field-group";
import FileUploadReactor from "../components/features/file-upload/file-upload";
import PreviewField from "../components/features/preview-field/preview-field";
import WysiwygEditor from "../components/features/wysiwyg-editor/wysiwyg-editor";

import Flex from "../components/layers/flex/Flex";
import Grid from "../components/layers/grid/Grid";
import DetailFullView from "../components/templates/detail-full-view/detail-full-view";
import FullView from "../components/templates/full-view/full-view";
import PreviewFullView from "../components/templates/preview-full-view/preview-full-view";
import FOR from "../components/utils/for/FOR";
import IF from "../components/utils/if/IF";

import { AutoInputByType } from "pages/section-card/lib";



const componentLibary = {
	["Flex"]: Flex,
	["Grid"]: Grid,

	["IF"]: IF,
	["FOR"]: FOR,

	["Button"]: Button,
	["Render"]: Flex,
	["Select"]: SelectWithSearchMini,
	["Label"]: Label,
	["Content"]: Content,
	["Tabs"]: Tabs,
	["Tab"]: Tab,
	["Input"]: Input,

	["FullView"]: FullView,
	["DetailFullView"]: DetailFullView,
	["PreviewFullView"]: PreviewFullView,

	["Field"]: AutoInputByType,
	["PreviewField"]: PreviewField,
	["Detail"]: Detail,
	["FieldGroup"]: FieldGroup,
	["FileUpload"]: FileUploadReactor,
	["СommentsBlock"]: СommentsBlock,
	["WysiwygEditor"]: WysiwygEditor,

	["Dropdown"]: Dropdown,
};

export default componentLibary;