import { action } from "mobx";

import { LowFirst } from "shared";
import { dispatcher, store } from "store";

import { businessRulesEngine } from "features/business-rules-engine";

export class RowController {
	get = () => {
		return dispatcher.entity.get()?.entity?.rows.find((row: any) => {
			return row.id === store.currentRowId;
		});
	};
	set = action((fieldName: string, value: any, trackChanges: boolean = true) => {
		if (!businessRulesEngine.isDisposerActive && !businessRulesEngine.observableRrecordId !== this.get().id) {
			businessRulesEngine.startTrackingChanges(this.get().id);
		}
		if (trackChanges) {
			store.hasChanges = true;
		}

		const cell = this.get()!;
		cell[fieldName] = value;

		store.setChangedField(fieldName, value);
	});
	add = () => {
		console.log(store.currentRowId);
	};

	getTitle = action("getTitle", () => {
		const cell = this.get()!;
		const titleColumnName = this.getViewColumnName()!;
		const title = this.getCellTitle(cell, titleColumnName);
		return title;
	});

	getTitleByRowId = action("getTitleByRowId", (rowId: string) => {
		const cell = dispatcher.entity.get()?.entity?.rows.find((row: any) => {
			return row.id === rowId;
		});

		const titleColumnName = this.getViewColumnName()!;
		const title = this.getCellTitle(cell, titleColumnName);

		return title;
	});

	reset = () => {
		store.currentRowId = "";
		store.resetChangedFields();
	};
	switch = action("switch", (id: string) => {
		store.currentRowId = id;
		store.resetChangedFields();
	});
	rollback = action("rollback", () => {
		const index = dispatcher.entity.get()!.entity!.rows.findIndex((row) => row.id === store.currentRowId);
		dispatcher.entity.get()!.entity!.rows[index] = { ...store.oldRowValue };
		businessRulesEngine.rollback();
		store.hasChanges = false;
	});

	private getViewColumnName(): string | null {
		const viewColumnId = dispatcher.sectionWizzard.getViewColumnId()!;
		const entity = dispatcher.entity.get()!;

		const column = entity.entity.columns.find((column) => column.columnId === viewColumnId);
		return column ? LowFirst(column.columnName) : null;
	}

	private getCellTitle(cell: any, targetColumn: string): string {
		return cell?.[targetColumn]?.length > 0 ? cell[targetColumn] : "Новая запись";
	}
}

export const rowController = new RowController();
