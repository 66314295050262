import classNames from "classnames";
import { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { v4 } from "uuid";

import { dispatcher, selector } from "store";
import { synchroiser } from "synchroiser";
import { singlePageSynchroiser } from "pages/single-page/single-page-synchroiser/single-page-synchroiser";

import { SavedFilter } from "entities/filter/SavedFilter";

import { Position, modalController } from "features/modals";
import { LayoutDeleteConfirm, RenameLayout } from "features/advanced-filter/filter-popups";
import MovingFolder from "features/advanced-filter/advanced-filter-tree/moving-folder/moving-folder";
import { AdvancedFilterConst } from "features/section-head/data/constants";

import { Select, ButtonStyle } from "components";

import { Item } from "types";
import { ItemActionEnum } from "../../utils/data";
import { LoadingState } from "types/entity";

import { DeleteIcon, Duplicate, EditFilter, FavouriteStar, FolderMove, MoreEllipsisVertical, Rename } from "shared";

import styles from "./filter-folder-tree-item.module.css";

const FilterFolderTreeItem = observer(function (props: { item: SavedFilter }) {
	const { item } = props;
	const [idModal] = useState<string>(v4());
	const [idNotification] = useState<string>(v4());

	const itemClassNames = classNames(styles.listItem, {
		[` ${styles.listItemSelected}`]: selector.filter.getFilter()?.savedFilter?.id === item.id
	});

	const favouriteStarClassNames = classNames(styles.favStar, {
		[`${styles.activeFavStar}`]: selector.filter.getFilter()?.favoriteFilters.find(({ id }) => id === item.id)
	});

	const filterActions = useMemo(
		() => [
			{
				id: ItemActionEnum.EditItem,
				name: "Редактировать",
				icon: <EditFilter />
			},
			{
				id: ItemActionEnum.FavouriteItem,
				name: item.isFavorite ? "Убрать из Избранного" : "Добавить в Избранное",
				icon: <FavouriteStar className={styles.favStarInActions} />
			},
			{
				id: ItemActionEnum.MoveItem,
				name: "Переместить в...",
				icon: <FolderMove />
			},
			{
				id: ItemActionEnum.DuplicateItem,
				name: "Дублировать",
				icon: <Duplicate />
			},
			{
				id: ItemActionEnum.RenameItem,
				name: "Переименовать",
				isSeparator: true,
				icon: <Rename />
			},
			{
				id: ItemActionEnum.DeleteItem,
				name: "Удалить",
				isRed: true,
				icon: <DeleteIcon />
			}
		],
		[item.isFavorite]
	);

	const closeConfirm = useCallback((e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
		e?.stopPropagation();
		modalController.modalRemove(idModal);
	}, []);

	const handleChangeValue = useCallback(
		(value: Item | null) => {
			if (value) {
				switch (value.id) {
					case ItemActionEnum.EditItem:
						const savedFilter = new SavedFilter(item.entityName!);
						savedFilter.deserialize(item);

						AdvancedFilterConst.setAdvancedFilter(savedFilter);
						AdvancedFilterConst.setOpenAdvancedFilterTree(true);
						break;
					case ItemActionEnum.FavouriteItem:
						addToFavorite();
						break;
					case ItemActionEnum.MoveItem:
						modalController.popupAdd({
							id: idModal,
							layout: (
								<MovingFolder
									savedFilterFolder={null}
									staticGroupFolder={null}
									savedFilter={item}
									staticGroup={null}
									onClose={closeConfirm}
								/>
							),
							closeFunc: closeConfirm
						});
						break;
					case ItemActionEnum.DuplicateItem:
						duplicate();
						break;
					case ItemActionEnum.RenameItem:
						if (item && item.filterName) {
							modalController.popupAdd({
								id: idModal,
								layout: (
									<RenameLayout
										dialogTitle="Переименовать фильтр"
										startName={item.filterName}
										onRename={reName}
										onClose={closeConfirm}
									/>
								),
								closeFunc: closeConfirm
							});
						}
						break;
					case ItemActionEnum.DeleteItem:
						if (item && item.filterName) {
							modalController.popupAdd({
								id: idModal,
								layout: (
									<LayoutDeleteConfirm
										delete={deleteFilter}
										dialogBody={`Фильтр «${item.filterName}» будет удален без возможности восстановления.`}
										closeConfirm={closeConfirm}
									/>
								),
								closeFunc: closeConfirm
							});
						}
						break;
				}
			}
		},
		[item]
	);

	const selectFilter = useCallback(async () => {
		if (item.id && selector.filter.getFilter()?.savedFilter?.id !== item.id) {
			dispatcher.filter.setStaticGroup(null);
			await synchroiser.getFilter(item.id);
			singlePageSynchroiser.applyFilter(dispatcher.entity.get()?.entityName ?? "");
		}
	}, [item.id]);

	const addToFavorite = useCallback(
		async (e?: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
			if (e) {
				e.stopPropagation();
			}
			if (item && item.id) {
				await synchroiser.updateFilter({ ...item, isFavorite: !item.isFavorite } as SavedFilter, true).then(async () => {
					if (synchroiser.loadingState != LoadingState.Error) {
						item.isFavorite = !item.isFavorite;
					}
				});
			}
		},
		[item, synchroiser.loadingState]
	);

	const duplicate = useCallback(async () => {
		if (item && item.id) {
			const newFilter = new SavedFilter(item.entityName!);
			const { id, ...filterCopy } = item;
			newFilter.deserialize(filterCopy as SavedFilter);
			newFilter.setValue(item.filterName + " (копия)", "filterName");
			newFilter.setValue(false, "isFavorite");

			await synchroiser.saveFilter(newFilter).then(async () => {
				await synchroiser.getSavedFilterFolderTree();

				const layoutNotification = <div className={styles.notification}>Фильтр дублирован</div>;

				modalController.notificationAdd({
					id: idNotification,
					position: Position.CENTER,
					layout: layoutNotification,
					allowDefaultClick: true,
					allowTimer: true
				});
			});
		}
	}, [item]);

	const reName = useCallback(
		async (filterName: string | null) => {
			if (filterName && item && item.id) {
				const updatedFilter = { ...item, filterName: filterName } as SavedFilter;
				await synchroiser.updateFilter(updatedFilter, false).then(async () => {
					await synchroiser.getSavedFilterFolderTree();
					await synchroiser.getFiltersList();
					item.filterName = filterName;
				});
			}
		},
		[item]
	);

	const deleteFilter = useCallback(async () => {
		if (item && item.id) {
			await synchroiser.deleteFilter(item.id);
		}
	}, [item]);

	return (
		<>
			<li key={item.id} className={itemClassNames} onClick={selectFilter}>
				<span className={styles.filterName}>{item.filterName}</span>
				<Select
					items={filterActions}
					onChangeValue={handleChangeValue}
					styles={ButtonStyle.IconIndigo}
					firstIcon={<MoreEllipsisVertical />}
					className={styles.selectFolder}
					classNameButton={styles.selectFolderButton}
					isPositionLeft={true}
					onClick={(e) => {
						e.stopPropagation();
					}}
				/>
				<FavouriteStar className={favouriteStarClassNames} onClick={addToFavorite} />
			</li>
		</>
	);
});

export default FilterFolderTreeItem;
