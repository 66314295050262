import { observer } from 'mobx-react-lite'
import { Checkbox, Hint } from 'sale-bridge-ui-kit'
import { useMemo } from 'react'
import { toJS } from 'mobx'

import { ColumnType } from 'entities/ColumnType'
import { NumeratorConfigurationEnums } from '../numerator-configuration'

import { dispatcher } from 'store'
import fieldConfigurationStore, {
	FieldStoreEnums,
} from '../field-configuration-store'


export const RenderViewColumnCheckbox = observer(() => {

	const getCurrentViewColumnName = useMemo((): string => {
		return dispatcher.sectionWizzard.getViewColumnTitle();
	}, [toJS(dispatcher.sectionWizzard.getViewColumnTitle())])

	const getCurrentViewColumnId = useMemo((): string | null => {
		return dispatcher.sectionWizzard.getViewColumnId();
	}, [toJS(dispatcher.sectionWizzard.getViewColumnId())])

	const isViewColumnSetterDisabled = useMemo((): boolean => {
		const currentColumnId = fieldConfigurationStore.columnId;
		const currentViewColumnId = getCurrentViewColumnId;
		return currentViewColumnId !== null && currentColumnId !== currentViewColumnId;
	}, [fieldConfigurationStore.columnId, toJS(dispatcher.sectionWizzard.getViewColumnId())])

	const handleChangeChecked = (value: boolean) => {
		fieldConfigurationStore.setValue(FieldStoreEnums.isViewColumn, value)
		if (value) {
			fieldConfigurationStore.setValue(FieldStoreEnums.isRequired, true)

			if (fieldConfigurationStore.fieldType === ColumnType.Numerator) {
				fieldConfigurationStore.setNumeratorConfig(NumeratorConfigurationEnums.FillWhenPageOpened, true)
			}
		}
	}

	const hintText = `Ссылочное поле для нумератора уже выбрано: #${getCurrentViewColumnName}#`

	return (
		<>
			<Hint
				hintBody={hintText}
				isHintDisplayed={isViewColumnSetterDisabled}
			>
				<Checkbox
					size='small'
					value={fieldConfigurationStore.isViewColumn}
					onChangeChecked={handleChangeChecked}
					disabled={isViewColumnSetterDisabled}
					variant='default'
					description='Отметьте, чтобы значение отображалось как ссылочное, и по клику открывалась страница записи'
					title='Значение поля - ссылка на запись'
				/>
			</Hint>
		</>
	)
})
