import styles from "./select.module.css";

export enum SelectStyleName {
    Base,
    BaseWithoutBorder,
    LookupValue,
    QuickViewSelect,
    SimpleFilterSelect
}

export const SelectStyles = {
    [SelectStyleName.Base]: {
        classNames: `${styles.wrapper} `,
        list: `${styles.selectList} `,
        focusInput: `${styles.focus} `,
        input: `${styles.inputBaseWrapper} `,
        disabledInput: `${styles.inputDisabled} `,
        invalid: `${styles.invalid} `,
        errorMessage: `${styles.errorMessage} `,

    },
    [SelectStyleName.BaseWithoutBorder]: {
        classNames: `${styles.wrapper} `,
        list: `${styles.selectList} `,
        focusInput: `${styles.focus} `,
        input: `${styles.inputWrapper} `,
        disabledInput: `${styles.inputDisabled} `,
        invalid: `${styles.invalid} `,
        errorMessage: `${styles.errorMessage} `,

    },
    [SelectStyleName.LookupValue]: {
        classNames: `${styles.wrapper} `,
        list: `${styles.selectList} `,
        focusInput: ``,
        input: `${styles.inputLookupValueWrapper} `,
        disabledInput: ``,
        invalid: ``,
        errorMessage: ``,

    },
    [SelectStyleName.QuickViewSelect]: {
        classNames: `${styles.inputQuickViewWrapper} `,
        list: `${styles.selectList} `,
        focusInput: ` ${styles.inputQuickViewFocus} `,
        input: `${styles.inputQuickView} `,
        disabledInput: `${styles.inputDisabled} `,
        invalid: ``,
        errorMessage: ``,

    },
    [SelectStyleName.SimpleFilterSelect]: {
        classNames: `${styles.wrapper} `,
        list: `${styles.selectList} `,
        focusInput: `${styles.focus} `,
        input: `${styles.inputBaseWrapper} `,
        disabledInput: `${styles.simpleFilterInputDisabled} `,
        invalid: `${styles.invalid} `,
        errorMessage: `${styles.errorMessage} `,

    },
    
}
