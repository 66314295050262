import { isNull, isUndefined } from "lodash";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { v4 } from "uuid";
import classNames from "classnames";

import { dispatcher } from "store";
import fieldConfigurationStore from "../../../../../field-configuration-store";
import { modalController } from "features/modals";
import { validateRequired } from "entities/Validation";

import { Button, Field, Tooltip } from "sale-bridge-ui-kit";
import { WarningDialog } from "../../../warning-dialog/warning-dialog";
import { AutoFieldByType } from "features/auto-field-by-type";

import { ColumnType } from "entities/ColumnType";
import { MaskState, NumeratorMaskItem } from "types/entity";
import { ItemGroup } from "sale-bridge-ui-kit/dist/components/dropdown/dropdown";
import { AllowedTypes, ConditionColumnType, EditableConditionType, getFieldTitle } from "./data";
import { Item } from "types";

import numeratorStyles from "../../../../numerator-configuration.module.scss";
import styles from "./edit-numerator-condition.module.scss";

type EditNumeratorPopupProps = {
	conditionId?: string;
	close: () => void;
};

const WarningBody = observer(() => {
	const invalids = useMemo(() => {
		const invalids = Object.entries(fieldConfigurationStore.numeratorConfig.validation.condition);
		const invalidsFiltered = invalids.filter((item) => item[1].isInvalid);
		return invalidsFiltered;
	}, [toJS(fieldConfigurationStore.numeratorConfig.validation.condition)]);

	const mapping = useMemo(() => {
		return invalids.map((item, index) => <span>{`• Поле ${index + 1}: ${getFieldTitle(item[0])}`}</span>);
	}, [toJS(invalids)]);

	return (
		<>
			<div className={styles.warningFirstTitle}>
				<span>Не удалось сохранить условие.</span>
				<span>Пожалуйста, проверьте правильность заполнения полей:</span>
			</div>
			<div className={styles.warningParams}>
				<span>Вкладка [По условию]</span>
				{mapping}
			</div>
		</>
	);
});

export const EditNumeratorConditionPopupBody = observer((props: EditNumeratorPopupProps) => {
	const [idConfirm] = useState<string>(v4());
	const [viewColumnName, setViewColumnName] = useState<string | null>(null);
	const [searchValueColumn, setSearchValueColumn] = useState("");
	const [searchValue, setSearchValue] = useState("");
	const [columnNames, setColumnNames] = useState<ConditionColumnType[]>([]);
	const [condition, setCondition] = useState<EditableConditionType>(
		props.conditionId
			? {
					id: props.conditionId,
					name: "",
					column: null,
					columnValue: "",
					mask: null
			  }
			: {
					id: v4(),
					name: `Условие ${fieldConfigurationStore.numeratorConfig.conditions.length + 1}`,
					column: null,
					columnValue: "",
					mask: null
			  }
	);

	useEffect(() => {
		const items: ConditionColumnType[] = [];
		toJS(dispatcher.sectionWizzard.getAllGridItems())
			.filter((gridItem) => AllowedTypes.find((allowedType) => allowedType === gridItem.fieldConfig?.columnType))
			.forEach((gridItem) => {
				if (gridItem.fieldConfig) {
					items.push({
						id: gridItem.fieldConfig.columnId,
						columnTitle: gridItem.fieldConfig.columnTitle,
						columnName: gridItem.fieldConfig.columnName,
						columnType: gridItem.fieldConfig.columnType as ColumnType,
						lookupTable: gridItem.fieldConfig.lookupTable,
						isVirtualLookup: !isUndefined(gridItem.fieldConfig.virtualLookup) && !isNull(gridItem.fieldConfig.virtualLookup),
						virtualLookupValues:
							gridItem.fieldConfig.virtualLookup?.virtualLookupValues.map((virtualLookupValue) => ({
								id: virtualLookupValue.id,
								name: virtualLookupValue.name
							})) ?? []
					});
					if (gridItem.fieldConfig.columnId === dispatcher.sectionWizzard.getSectionWizzard()?.viewColumnId) {
						setViewColumnName(gridItem.fieldConfig.columnName);
					}
				}
			});
		setColumnNames(items);

		if (props.conditionId) {
			const findedCondition = fieldConfigurationStore.numeratorConfig.conditions.find(
				(condition) => condition.id === props.conditionId
			);
			if (findedCondition) {
				const findColumn = items.find((column) => column.columnName === findedCondition.columnName);
				if (findColumn) {
					const findMask = fieldConfigurationStore.numeratorConfig.maskItems.find((mask) => mask.id === findedCondition.maskId);
					if (findMask) {
						const convertedCondition: EditableConditionType = {
							id: findedCondition.id,
							name: findedCondition.name,
							column: findColumn,
							columnValue: findedCondition.columnValue,
							mask: findMask
						};
						setCondition(convertedCondition);
					}
				}
			}
		}
	}, []);

	const isDisabledMaskName = useMemo(() => {
		return (
			isUndefined(condition.columnValue) ||
			isNull(condition.columnValue) ||
			condition.columnValue?.length == 0 ||
			fieldConfigurationStore.numeratorConfig.validation.condition.columnName.isInvalid
		);
	}, [condition.columnValue, fieldConfigurationStore.numeratorConfig.validation.condition.columnName.isInvalid]);

	const configMaskItems = useMemo(() => {
		return fieldConfigurationStore.numeratorConfig.maskItems;
	}, [toJS(fieldConfigurationStore.numeratorConfig.maskItems)]);

	const handleCloseWarning = useCallback(() => {
		modalController.modalRemove(idConfirm);
	}, [idConfirm]);

	const handleSave = useCallback(() => {
		if (fieldConfigurationStore.numeratorConfig.conditionValidation(condition)) {
			fieldConfigurationStore.numeratorConfig.saveCondition(props.conditionId ? true : false, condition);
			props.close();
		} else {
			modalController.popupAdd({
				id: idConfirm,
				layout: <WarningDialog children={<WarningBody />} close={handleCloseWarning} />,
				closeFunc: handleCloseWarning
			});
		}
	}, [condition, props]);

	const handleCancel = useCallback(() => {
		fieldConfigurationStore.numeratorConfig.resetConditionSettings();
		props.close();
	}, [props.close]);

	const handleColumnValueChange = useCallback(
		(value: string | Item) => {
			setCondition({
				...condition,
				columnValue: value,
				mask: null
			});
			fieldConfigurationStore.numeratorConfig.conditionValidationNotUniq(condition.column?.columnName!, value, props.conditionId);
		},
		[condition, props.conditionId]
	);

	const handleColumnChange = useCallback(
		(value: ConditionColumnType | "") => {
			if (value === "") {
				setCondition({
					...condition,
					column: null,
					columnValue: "",
					mask: null
				});
			} else {
				setCondition({
					...condition,
					column: value,
					columnValue: "",
					mask: null
				});
			}
		},
		[condition, fieldConfigurationStore.numeratorConfig.validation.condition.columnName]
	);

	const columnNameItems: ItemGroup[] = useMemo(
		() => [
			{
				layout: <></>,
				items: columnNames.map((item) => (
					<div className={numeratorStyles.item} onClick={() => handleColumnChange(item)}>
						<span>{item.columnTitle}</span>
					</div>
				))
			}
		],
		[toJS(columnNames)]
	);

	const columnField = useMemo(
		() => (
			<Field
				type="select"
				size="small"
				label={getFieldTitle("columnName")}
				labelPosition="vertical"
				textVariant="outlined"
				placeholder="Выберите колонку раздела"
				items={columnNameItems}
				value={condition.column?.columnTitle ?? ""}
				onChange={handleColumnChange}
				alert={fieldConfigurationStore.numeratorConfig.validation.condition.columnName.isInvalid ? "error" : undefined}
				isClearing
				isRequired
				searchValue={searchValueColumn} //ToDo чтобы заработал поиск нужно в items сделать filter по searchValue
				onChangeSearchValue={setSearchValueColumn}
			/>
		),
		[
			toJS(columnNameItems),
			condition.column?.columnTitle,
			fieldConfigurationStore.numeratorConfig.validation.condition.columnName,
			searchValueColumn
		]
	);

	const handleNameChange = useCallback(
		(value: string) => {
			setCondition({
				...condition,
				name: value
			});
		},
		[condition]
	);

	const handleMaskChange = useCallback(
		(value: NumeratorMaskItem | "") => {
			if (value === "") {
				setCondition({
					...condition,
					mask: null
				});
			} else {
				setCondition({
					...condition,
					mask: value
				});
			}
		},
		[condition]
	);

	const maskItems = useMemo(
		() => [
			{
				layout: <></>,
				items: toJS(configMaskItems)
					.filter((mask) => mask.name.toLowerCase().includes(searchValue.toLowerCase()))
					.map((mask) => {
						const classes = classNames(numeratorStyles.item, {
							[`${numeratorStyles.disableItem}`]: mask.maskState === MaskState.Default
						});
						function handleMaskClick() {
							if (mask.maskState !== MaskState.Default) {
								handleMaskChange(mask);
							}
						}
						return (
							<div className={classes} onClick={handleMaskClick}>
								<span>{mask.name}</span>
							</div>
						);
					})
			}
		],
		[condition, toJS(configMaskItems), searchValue]
	);

	return (
		<>
			<Field
				type="text"
				size="small"
				label={getFieldTitle("name")}
				labelPosition="vertical"
				textVariant="outlined"
				placeholder=""
				value={condition.name}
				onChange={handleNameChange}
				onBlur={() => validateRequired(condition.name, fieldConfigurationStore.numeratorConfig.validation.condition.name)}
				alert={fieldConfigurationStore.numeratorConfig.validation.condition.name.isInvalid ? "error" : undefined}
				isClearing
				isRequired
			/>
			<div className={styles.divider} />
			<div className={styles.condition}>
				{fieldConfigurationStore.numeratorConfig.validation.condition.columnName.isInvalid ? (
					<Tooltip
						tooltipBody={fieldConfigurationStore.numeratorConfig.validation.condition.columnName.error ?? ""}
						startPosition="right middle"
						trigger="hover"
					>
						{columnField}
					</Tooltip>
				) : (
					columnField
				)}
				<AutoFieldByType
					onChange={handleColumnValueChange}
					columnId={condition.column?.id}
					columnValue={condition.columnValue}
					columnType={condition.column?.columnType}
					lookupTable={condition.column?.lookupTable}
					isVirtualLookup={condition.column?.isVirtualLookup ?? false}
					virtualLookupValues={condition.column?.virtualLookupValues ?? []}
					columnValueIsInvalid={fieldConfigurationStore.numeratorConfig.validation.condition.columnValue.isInvalid}
					isVisibleLabel={true}
					isDisabled={isUndefined(condition.column?.columnType)}
				/>
				<Field
					type="select"
					size="small"
					label={getFieldTitle("mask")}
					labelPosition="vertical"
					textVariant="outlined"
					placeholder="Выберите маску заполнения"
					value={condition.mask?.name ?? ""}
					onChange={handleMaskChange}
					items={maskItems}
					isDisabled={isDisabledMaskName}
					alert={fieldConfigurationStore.numeratorConfig.validation.condition.mask.isInvalid ? "error" : undefined}
					isClearing
					isRequired
					searchValue={searchValue}
					onChangeSearchValue={setSearchValue}
				/>
			</div>
			<div className={styles.divider} />
			<div className={styles.dialogFooter}>
				<Button size="small" onClick={handleCancel} text="Отменить" variant="default" border link={false} loading={false} />
				<Button
					size="small"
					onClick={handleSave}
					text="Сохранить условие"
					variant="secondary"
					border={false}
					link={false}
					loading={false}
				/>
			</div>
		</>
	);
});
