import React, { useMemo } from "react";
import classNames from "classnames";

import { GridItem } from "types/entity";
import { AutoInputByType } from "pages/section-card/lib";

import styles from "./fields.module.scss";
interface FieldsProps {
	items: GridItem[];
	blockPosition: "left" | "right";
}

export const Fields = ({ items, blockPosition }: FieldsProps) => {
	const fieldsWrapperClassNames = classNames({
		[`${styles.leftBlock}`]: blockPosition === "left",
		[`${styles.rightBlock}`]: blockPosition === "right"
	});
	const labelPosition = useMemo(() => (blockPosition === "left" ? "horizontal" : "vertical"), [blockPosition]);

	return (
		<div className={fieldsWrapperClassNames}>
			{items &&
				items.map((item) => (
					<AutoInputByType
						variant="outlined"
						labelPosition={labelPosition}
						field={`${JSON.stringify(item)}`}
						labelVariant="black"
					/>
				))}
		</div>
	);
};
