import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { isUndefined } from "lodash";

import { dispatcher } from "store";
import { entity } from "shared";

import { MaskValidationKeys } from "../../../../../numerator-configuration-state";

import { fieldConfigurationStore } from "pages/section-wizzard/pages/constructor";

import { Field } from "sale-bridge-ui-kit";

import {
	FieldConfig,
	GridItem,
	NumeratorMaskSettingsColumnValueParameter,
	NumeratorMaskSettingsParameter,
	NumeratorMaskSettingsParameterType
} from "types/entity";
import { ItemGroup } from "sale-bridge-ui-kit/dist/components/dropdown/dropdown";
import { numeratorTypes } from "../data";
import { Item } from "types";

import styles from "../edit-mask-settings-popup.module.scss";

const NumberParameterFields = observer((props: { parameter: NumeratorMaskSettingsParameter }) => {
	const valueLengthValidation = useMemo(
		() => fieldConfigurationStore.numeratorConfig.validation.mask[`${props.parameter.id}${MaskValidationKeys.valueLength}`],
		[toJS(fieldConfigurationStore.numeratorConfig.validation.mask[`${props.parameter.id}${MaskValidationKeys.valueLength}`])]
	);
	const stepValidation = useMemo(
		() => fieldConfigurationStore.numeratorConfig.validation.mask[`${props.parameter.id}${MaskValidationKeys.step}`],
		[toJS(fieldConfigurationStore.numeratorConfig.validation.mask[`${props.parameter.id}${MaskValidationKeys.step}`])]
	);
	const isDisableStartValue = useMemo(
		() => valueLengthValidation?.isInvalid || stepValidation?.isInvalid,
		[valueLengthValidation?.isInvalid, stepValidation?.isInvalid]
	);

	const [uniqValues, setUniqValues] = useState(
		calculateUniqueValues(
			props.parameter.numberParameter?.valueLength!,
			props.parameter.numberParameter?.step!,
			Number(props.parameter.numberParameter?.startValue)
		)
	);

	useEffect(() => {
		const length = props.parameter.numberParameter?.valueLength!;
		const initStep = props.parameter.numberParameter?.step!;
		const startValue = Number(props.parameter.numberParameter?.startValue);

		const newUniqValues = calculateUniqueValues(length, initStep, startValue);

		setUniqValues(newUniqValues);
	}, [
		props.parameter.numberParameter,
		props.parameter.id,
		props.parameter.numberParameter?.valueLength,
		props.parameter.numberParameter?.startValue
	]);

	function calculateUniqueValues(valueLength: number, St: number, S: number): number {
		const L = Math.pow(10, valueLength) - 1;
		const X = Math.floor((L - S) / St) + 1;
		return X;
	}

	const handleLengthChange = useCallback(
		(value: number | string) => {
			const length = value as number;
			props.parameter.numberParameter!.valueLength = length;
			if (valueLengthValidation?.isInvalid) {
				fieldConfigurationStore.numeratorConfig.validateByType(
					NumeratorMaskSettingsParameterType.Number,
					`${props.parameter.id}${MaskValidationKeys.valueLength}`,
					props.parameter.numberParameter!.valueLength.toString()
				);
			}
		},
		[props.parameter.numberParameter, valueLengthValidation?.isInvalid, props.parameter.numberParameter!.valueLength]
	);

	const handleStepChange = useCallback(
		(value: number | string) => {
			let step: number | string = value;
			if (typeof step === "string") {
				step = step.replace(/\D/g, "");
			}

			if (Number(step) < uniqValues) {
				props.parameter.numberParameter!.step = Number(step);
				if (stepValidation?.isInvalid) {
					fieldConfigurationStore.numeratorConfig.validateByType(
						NumeratorMaskSettingsParameterType.Number,
						`${props.parameter.id}${MaskValidationKeys.step}`,
						props.parameter.numberParameter!.step.toString()
					);
				}
			}
		},
		[props.parameter.numberParameter, props.parameter.numberParameter?.step, stepValidation?.isInvalid, props.parameter.id, uniqValues]
	);

	const handleBlurStep = () => {
		fieldConfigurationStore.numeratorConfig.validateByType(
			NumeratorMaskSettingsParameterType.Number,
			`${props.parameter.id}${MaskValidationKeys.step}`,
			props.parameter.numberParameter!.step.toString()
		);
		const length = props.parameter.numberParameter?.valueLength!;
		const initStep = props.parameter.numberParameter?.step! === 0 ? 1 : props.parameter.numberParameter?.step!;
		const startValue = Number(props.parameter.numberParameter?.startValue);
		const newUniqValues = calculateUniqueValues(length, initStep, startValue);

		setUniqValues(newUniqValues);
	};

	const handleStartPositionChange = useCallback(
		(value: string) => {
			const numericValue = value.replace(/\D/g, "");

			if (!isNaN(parseInt(numericValue))) {
				props.parameter.numberParameter!.startValue = numericValue;
			} else {
				props.parameter.numberParameter!.startValue = "";
			}
		},
		[props.parameter.numberParameter, props.parameter.id]
	);

	function updateStartValueWithZeroSymbol() {
		const currentStartValue = props.parameter.numberParameter?.startValue?.toString().replace(/^0+/, "");
		const valueLength = props.parameter.numberParameter!.valueLength;
		if (currentStartValue || currentStartValue === "") {
			const paddedStartValue = currentStartValue.padStart(valueLength, "0");
			props.parameter.numberParameter!.startValue = paddedStartValue;
		}
	}

	const handleOnBlurValueLength = useCallback(() => {
		fieldConfigurationStore.numeratorConfig.validateByType(
			NumeratorMaskSettingsParameterType.Number,
			`${props.parameter.id}${MaskValidationKeys.valueLength}`,
			props.parameter.numberParameter!.valueLength.toString()
		);

		updateStartValueWithZeroSymbol();
	}, [props.parameter.numberParameter, props.parameter.numberParameter?.step, props.parameter.id]);

	return (
		<>
			<Field
				type="number"
				value={props.parameter.numberParameter!.valueLength.toString() ?? ""}
				labelPosition="vertical"
				subType="integer"
				size="small"
				label="Длина значения"
				textVariant="outlined"
				placeholder="0"
				max={50}
				min={0}
				onChange={handleLengthChange}
				alert={valueLengthValidation?.isInvalid ? "error" : undefined}
				onBlur={handleOnBlurValueLength}
				tooltipBody={valueLengthValidation?.error}
				startTooltipPosition="left middle"
				tooltipTrigger="hover&focus"
				isTooltipDisplayed={valueLengthValidation?.isInvalid ?? false}
				isRequired
			/>
			<Field
				type="number"
				value={props.parameter.numberParameter!.step.toString() ?? ""}
				labelPosition="vertical"
				subType="integer"
				size="small"
				label="Шаг"
				textVariant="outlined"
				placeholder="0"
				max={50}
				min={0}
				onChange={handleStepChange}
				alert={stepValidation?.isInvalid ? "error" : undefined}
				onBlur={handleBlurStep}
				tooltipBody={stepValidation?.error}
				startTooltipPosition="left middle"
				tooltipTrigger="hover&focus"
				isTooltipDisplayed={stepValidation?.isInvalid ?? false}
				isRequired
			/>
			{/* TODO если все таки понадобится тип поля number */}
			{/* <Field
                type="number"
                value={props.numberParameter?.startValue?.toString() ?? ""}
                labelPosition="vertical"
                subType="integer"
                size="small"
                label="Начальное значение"
                textVariant='outlined'
                placeholder="0"
                max={props.numberParameter.valueLength}
                onChange={handleStartPositionChange}
                isRequired
                isDisabled={isDisableStartValue}
            /> */}
			<Field
				type="text"
				value={props.parameter.numberParameter?.startValue?.toString() ?? ""}
				labelPosition="vertical"
				size="small"
				label="Начальное значение"
				isRequired
				textVariant="outlined"
				placeholder="0"
				onBlur={updateStartValueWithZeroSymbol}
				counter={props.parameter.numberParameter!.valueLength}
				isClearing
				onChange={handleStartPositionChange}
				isDisabled={isDisableStartValue}
			/>
		</>
	);
});

const TextParameterFields = observer((props: { parameter: NumeratorMaskSettingsParameter }) => {
	const infoText = "Вводите префикс, цифры или любые специальные символы в роли разделителей";
	const textValidation = useMemo(
		() => fieldConfigurationStore.numeratorConfig.validation.mask[props.parameter.id],
		[toJS(fieldConfigurationStore.numeratorConfig.validation.mask[props.parameter.id])]
	);

	const handleChange = useCallback(
		(value: string) => {
			props.parameter.textParameter!.text = value;
			if (textValidation?.isInvalid) {
				fieldConfigurationStore.numeratorConfig.validateByType(
					NumeratorMaskSettingsParameterType.Text,
					props.parameter.id,
					props.parameter.textParameter?.text
				);
			}
		},
		[props.parameter, textValidation?.isInvalid]
	);

	return (
		<div className={styles.textParameterFields}>
			<Field
				type="text"
				value={props.parameter?.textParameter?.text ?? " "}
				labelPosition="vertical"
				size="small"
				label="Значение"
				textVariant="outlined"
				placeholder="Значение поля"
				counter={50}
				onChange={handleChange}
				alert={textValidation?.isInvalid ? "error" : undefined}
				onBlur={() => {}}
				tooltipBody={textValidation?.error}
				startTooltipPosition="left middle"
				tooltipTrigger="hover&focus"
				isTooltipDisplayed={textValidation?.isInvalid ?? false}
				isRequired
				isClearing
			/>
			<span className={styles.infoTextParameter}>{infoText}</span>
		</div>
	);
});

const ColumnValueParameterFields = (props: { parameter: NumeratorMaskSettingsParameter }) => {
	const [columns, setColumns] = useState<GridItem[]>([]);
	const [lookupTableColumns, setLookupTableColumns] = useState<Item[]>([]);
	const [searchValueColumn, setSearchValueColumn] = useState("");
	const [searchValueField, setSearchValueField] = useState("");
	const lookupTable = useRef<string | undefined>(props.parameter.columnValueParameter?.lookupTable);

	const resetedColumnValue: NumeratorMaskSettingsColumnValueParameter = useMemo(
		() => ({
			isLookup: false,
			columnName: "",
			columnId: "",
			columnTitle: "",
			lookupTable: undefined,
			lookupTableColumnId: undefined,
			lookupTableColumnName: undefined,
			lookupTableColumnTitle: undefined
		}),
		[]
	);

	const columnInvalidKey = useMemo(() => `${props.parameter.id}${MaskValidationKeys.column}`, []);
	const lookupTableColumnInvalidKey = useMemo(() => `${props.parameter.id}${MaskValidationKeys.lookupTableColumn}`, []);

	const columnValidation = useMemo(
		() => fieldConfigurationStore.numeratorConfig.validation.mask[columnInvalidKey],
		[toJS(fieldConfigurationStore.numeratorConfig.validation.mask[columnInvalidKey])]
	);
	const lookupTableColumnValidation = useMemo(
		() => fieldConfigurationStore.numeratorConfig.validation.mask[lookupTableColumnInvalidKey],
		[toJS(fieldConfigurationStore.numeratorConfig.validation.mask[lookupTableColumnInvalidKey])]
	);

	useEffect(() => {
		setColumns(dispatcher.sectionWizzard.getAllGridItems());
		if (
			props.parameter.columnValueParameter!.lookupTable &&
			lookupTable.current !== props.parameter.columnValueParameter!.lookupTable
		) {
			lookupTableLoad(props.parameter.columnValueParameter!.lookupTable);
		}
	}, [props.parameter.columnValueParameter]);

	const lookupTableLoad = useCallback(
		async (entityName: string) => {
			setLookupTableColumns([]);
			lookupTable.current = entityName;
			await entity
				.entityInfo()
				.post({ entityName: entityName })
				.then((req) => {
					const columns: any[] = req?.data.data.entityInfoResultItems;
					const lookupTableColumns: Item[] = columns.map((column) => ({
						id: column.columnId,
						name: column.columnName,
						displayValue: column.columnTitle
					}));

					setLookupTableColumns(lookupTableColumns);
				});
		},
		[props.parameter.columnValueParameter, lookupTable.current]
	);

	const handleChangeColumnName = useCallback(
		async (value?: FieldConfig) => {
			if (!value) {
				return;
			}

			const columnValue = {
				...resetedColumnValue,
				columnId: value.columnId,
				columnName: value.columnName,
				columnTitle: value.columnTitle
			};

			if (value.isLookup) {
				columnValue.isLookup = value.isLookup;
				columnValue.lookupTable = value.lookupTable!;

				if (value.lookupTable !== lookupTable.current || isUndefined(lookupTable.current)) {
					await lookupTableLoad(value.lookupTable!);
				}
			}

			Object.assign(props.parameter.columnValueParameter!, columnValue);

			fieldConfigurationStore.numeratorConfig.validateByType(
				NumeratorMaskSettingsParameterType.ColumnValue,
				columnInvalidKey,
				props.parameter.columnValueParameter?.columnId
			);
		},
		[props.parameter.columnValueParameter, lookupTable.current]
	);

	const handleChangeLookupTableColumnValue = useCallback(
		async (item: Item | any) => {
			const columnValue = {
				...props.parameter.columnValueParameter!,
				lookupTableColumnId: item.id?.toString(),
				lookupTableColumnName: item.name,
				lookupTableColumnTitle: item.displayValue
			};
			Object.assign(props.parameter.columnValueParameter!, columnValue);

			fieldConfigurationStore.numeratorConfig.validateByType(
				NumeratorMaskSettingsParameterType.ColumnValue,
				columnInvalidKey,
				props.parameter.columnValueParameter?.columnId
			);

			fieldConfigurationStore.numeratorConfig.validateByType(
				NumeratorMaskSettingsParameterType.ColumnValue,
				lookupTableColumnInvalidKey,
				props.parameter.columnValueParameter?.lookupTableColumnId
			);
		},
		[props.parameter.columnValueParameter!]
	);

	const handleResetColumnValue = useCallback(
		(value: string) => {
			lookupTable.current = undefined;
			Object.assign(props.parameter.columnValueParameter!, resetedColumnValue);
			fieldConfigurationStore.numeratorConfig.validateByType(
				NumeratorMaskSettingsParameterType.ColumnValue,
				columnInvalidKey,
				props.parameter.columnValueParameter?.columnId
			);
		},
		[props.parameter.columnValueParameter]
	);

	const columnNameItems = useMemo(() => {
		return [
			{
				layout: <></>,
				items: columns.map((item) => (
					<div
						key={item.gridItemId}
						className={styles.parameterItem}
						onClick={() => {
							handleChangeColumnName(item.fieldConfig);
						}}
					>
						<span>{item.fieldConfig?.columnTitle}</span>
					</div>
				))
			}
		];
	}, [toJS(columns)]);

	const lookupTableColumnsItems = useMemo(() => {
		return [
			{
				layout: <></>,
				items: lookupTableColumns.map((item) => (
					<div
						key={item.id}
						className={styles.parameterItem}
						onClick={() => {
							handleChangeLookupTableColumnValue(item);
						}}
					>
						<span>{item.displayValue}</span>
					</div>
				))
			}
		];
	}, [toJS(lookupTableColumns)]);

	const lookupTableField = useMemo(
		() => (
			<>
				{props.parameter.columnValueParameter?.isLookup && (
					<Field
						type="select"
						size="small"
						textVariant="outlined"
						placeholder="Выберите колонку справочника"
						items={lookupTableColumnsItems}
						value={props.parameter.columnValueParameter?.lookupTableColumnTitle ?? ""}
						onChange={handleChangeLookupTableColumnValue}
						alert={lookupTableColumnValidation?.isInvalid ? "error" : undefined}
						tooltipBody={lookupTableColumnValidation?.error}
						startTooltipPosition="right middle"
						tooltipTrigger="hover&focus"
						isTooltipDisplayed={lookupTableColumnValidation?.isInvalid ?? false}
						isClearing
						searchValue={searchValueColumn} //ToDo чтобы заработал поиск нужно в items сделать filter по searchValue
						onChangeSearchValue={setSearchValueColumn}
					/>
				)}
			</>
		),
		[
			toJS(lookupTableColumnsItems),
			props.parameter.columnValueParameter?.isLookup,
			props.parameter.columnValueParameter?.lookupTableColumnTitle,
			searchValueColumn
		]
	);

	return (
		<>
			<Field
				type="select"
				size="small"
				label="Значение"
				labelPosition="vertical"
				textVariant="outlined"
				placeholder="Значение поля"
				items={columnNameItems}
				value={props.parameter.columnValueParameter?.columnTitle ?? ""}
				onChange={handleResetColumnValue}
				alert={columnValidation?.isInvalid ? "error" : undefined}
				tooltipBody={columnValidation?.error}
				startTooltipPosition="right middle"
				tooltipTrigger="hover&focus"
				isTooltipDisplayed={columnValidation?.isInvalid ?? false}
				isClearing
				isRequired
				searchValue={searchValueField} //ToDo чтобы заработал поиск нужно в items сделать filter по searchValue
				onChangeSearchValue={setSearchValueField}
			/>
			{lookupTableField}
		</>
	);
};

const DefaultField = (props: { item: NumeratorMaskSettingsParameter }) => {
	const findedValue = useMemo(() => {
		return numeratorTypes.find((item) => item.id === props.item.type)?.name;
	}, [toJS(props.item.type)]);

	const parametersItems: Array<ItemGroup> = useMemo(
		() => [
			{
				layout: <></>,
				items: [
					<div
						className={`${styles.parameterItem} ${styles.disable}`}
						onClick={() => handleClickToAddParameter(NumeratorMaskSettingsParameterType.Number)}
					>
						<span>{numeratorTypes.find((item) => item.id === NumeratorMaskSettingsParameterType.Number)?.name}</span>
					</div>,
					<div
						className={styles.parameterItem}
						onClick={() => handleClickToAddParameter(NumeratorMaskSettingsParameterType.Text)}
					>
						<span>{numeratorTypes.find((item) => item.id === NumeratorMaskSettingsParameterType.Text)?.name}</span>
					</div>,
					<div
						className={styles.parameterItem}
						onClick={() => handleClickToAddParameter(NumeratorMaskSettingsParameterType.CurrentDay)}
					>
						<span>{numeratorTypes.find((item) => item.id === NumeratorMaskSettingsParameterType.CurrentDay)?.name}</span>
					</div>,
					<div
						className={styles.parameterItem}
						onClick={() => handleClickToAddParameter(NumeratorMaskSettingsParameterType.CurrentMonth)}
					>
						<span>{numeratorTypes.find((item) => item.id === NumeratorMaskSettingsParameterType.CurrentMonth)?.name}</span>
					</div>,
					<div
						className={styles.parameterItem}
						onClick={() => handleClickToAddParameter(NumeratorMaskSettingsParameterType.CurrentYear2)}
					>
						<span>{numeratorTypes.find((item) => item.id === NumeratorMaskSettingsParameterType.CurrentYear2)?.name}</span>
					</div>,
					<div
						className={styles.parameterItem}
						onClick={() => handleClickToAddParameter(NumeratorMaskSettingsParameterType.CurrentYear4)}
					>
						<span>{numeratorTypes.find((item) => item.id === NumeratorMaskSettingsParameterType.CurrentYear4)?.name}</span>
					</div>,
					<div
						className={styles.parameterItem}
						onClick={() => handleClickToAddParameter(NumeratorMaskSettingsParameterType.ColumnValue)}
					>
						<span>{numeratorTypes.find((item) => item.id === NumeratorMaskSettingsParameterType.ColumnValue)?.name}</span>
					</div>
				]
			}
		],
		[toJS(props.item)]
	);

	const handleClickToAddParameter = useCallback(
		(type: NumeratorMaskSettingsParameterType) => {
			const newTypeOfNumeration: NumeratorMaskSettingsParameter = {
				id: props.item.id,
				order: props.item.order,
				type: props.item.type,
				textParameter: undefined,
				numberParameter: undefined,
				columnValueParameter: undefined
			};
			switch (type) {
				case NumeratorMaskSettingsParameterType.Number:
					return;
				case NumeratorMaskSettingsParameterType.Text: {
					newTypeOfNumeration.type = NumeratorMaskSettingsParameterType.Text;
					newTypeOfNumeration.textParameter = {
						text: ""
					};
					break;
				}
				case NumeratorMaskSettingsParameterType.CurrentDay: {
					newTypeOfNumeration.type = NumeratorMaskSettingsParameterType.CurrentDay;
					break;
				}
				case NumeratorMaskSettingsParameterType.CurrentMonth: {
					newTypeOfNumeration.type = NumeratorMaskSettingsParameterType.CurrentMonth;
					break;
				}
				case NumeratorMaskSettingsParameterType.CurrentYear2: {
					newTypeOfNumeration.type = NumeratorMaskSettingsParameterType.CurrentYear2;
					break;
				}
				case NumeratorMaskSettingsParameterType.CurrentYear4: {
					newTypeOfNumeration.type = NumeratorMaskSettingsParameterType.CurrentYear4;
					break;
				}
				case NumeratorMaskSettingsParameterType.ColumnValue: {
					newTypeOfNumeration.type = NumeratorMaskSettingsParameterType.ColumnValue;
					newTypeOfNumeration.columnValueParameter = {
						isLookup: false,
						columnName: "",
						columnId: "",
						columnTitle: ""
					};
					break;
				}
			}
			Object.assign(props.item, newTypeOfNumeration);
		},
		[toJS(props.item)]
	);

	return (
		<Field
			type="select"
			value={findedValue ?? ""}
			labelPosition="vertical"
			size="small"
			label="Тип нумерации"
			textVariant="outlined"
			placeholder="Выберите значение"
			isDisabled={props.item.type === NumeratorMaskSettingsParameterType.Number}
			items={parametersItems}
			onChange={() => {}}
		/>
	);
};

export const MaskParameterBodyByType = (props: { item: NumeratorMaskSettingsParameter }) => {
	const defaultField = useMemo(() => <DefaultField item={props.item} />, [toJS(props.item)]);

	const fieldsByType = useMemo(() => {
		switch (props.item.type) {
			case NumeratorMaskSettingsParameterType.Number: {
				return (
					<>
						<div className={styles.divider} />
						<NumberParameterFields parameter={props.item} />
					</>
				);
			}
			case NumeratorMaskSettingsParameterType.Text: {
				return (
					<>
						<div className={styles.divider} />
						<TextParameterFields parameter={props.item} />
					</>
				);
			}
			case NumeratorMaskSettingsParameterType.ColumnValue: {
				return (
					<>
						<div className={styles.divider} />
						<ColumnValueParameterFields parameter={props.item} />
					</>
				);
			}
			default: {
				return <></>;
			}
		}
	}, [toJS(props.item), toJS(fieldConfigurationStore.numeratorConfig.validation.mask)]);

	return (
		<>
			{defaultField}
			{fieldsByType}
		</>
	);
};
