import { observer } from "mobx-react-lite";
import { useCallback, useMemo } from "react";

import { UpFirst } from "shared";
import fieldConfigurationStore, { FieldStoreEnums } from "../field-configuration-store";

import { Field } from "sale-bridge-ui-kit";

import styles from "./choice-lookup-type.module.scss";

export const BasicSettings = observer(() => {
	const handleTitleChange = useCallback((value: string) => {
		fieldConfigurationStore.setValue(FieldStoreEnums.title, value);
		fieldConfigurationStore.validateTitle();
	}, []);

	const handleSystemNameChange = useCallback((value: string) => {
		fieldConfigurationStore.setValue(FieldStoreEnums.systemName, value);
		fieldConfigurationStore.validateSystemName();
	}, []);

	const captionErrorMessage = "Заполните это поле.";
	const systemErrorMessage = (
		<div className={styles.systemErrorMessage}>
			{`Заполните это поле.\nДопустимые символы: латинские буквы без пробелов, цифры, символ подчеркивания и дефис.`}
		</div>
	);

	const captionField = (
		<Field
			type="text"
			size="small"
			label="Заголовок"
			labelPosition="vertical"
			textVariant="outlined"
			placeholder="Название поля на странице"
			value={!fieldConfigurationStore.fieldIsLoading ? fieldConfigurationStore.title : ""}
			info="Название поля, которое вы будете видеть на странице"
			counter={50}
			isClearing
			onChange={handleTitleChange}
			alert={fieldConfigurationStore.validation.title.isInvalid ? "error" : undefined}
			tooltipBody={captionErrorMessage}
			startTooltipPosition="left middle"
			tooltipTrigger="hover&focus"
			isTooltipDisplayed={fieldConfigurationStore.validation.title.isInvalid ?? false}
			isRequired
		/>
	);

	const systemNameField = (
		<Field
			type="text"
			size="small"
			label="Системное название"
			labelPosition="vertical"
			textVariant="outlined"
			placeholder="Название колонки в базе данных"
			value={!fieldConfigurationStore.fieldIsLoading ? fieldConfigurationStore.systemName : ""}
			info="Название колонки, которое будет отображаться в базе данных"
			counter={50}
			isClearing
			onChange={(value: string) => {
				handleSystemNameChange(UpFirst(value));
			}}
			isDisabled={fieldConfigurationStore.getIsDisabledForSystemName()}
			alert={fieldConfigurationStore.validation.systemName.isInvalid ? "error" : undefined}
			tooltipBody={systemErrorMessage}
			startTooltipPosition="left middle"
			tooltipTrigger="hover&focus"
			isTooltipDisplayed={fieldConfigurationStore.validation.systemName.isInvalid ?? false}
			isRequired
		/>
	);

	return (
		<>
			{captionField}
			{systemNameField}
		</>
	);
});
