import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo, useState } from "react";
import { isUndefined } from "lodash";

import { FlexColumn, FlexRow } from "components/layer";
import MainPanel from "components/main-panel/main-panel";

import BigButton from "./components/bigButton/BigButton";
import Separator from "./components/separator/separator";
import { Swith, SwithItem } from "./components/helpers/swith";
import { UnavailableFileService } from "./components/unavailable-file-service/unavailable-file-service";

import DataBaseStore from "./ui/dataBaseStore/DataBaseStore";
import { FileStorages } from "entities/FileStorages";
import fileStoreService from "./core/FileStoresService";
import cloudStoreService, { LoadStates } from "../fileStore/core/CloudStoreService";
import { DataSettings, Menu, MenuItems, SettingsPath, systemDesigner } from "../data/Fields";
import CloudStore from "./ui/cloudStore/CloudStore";

import { ArrowToUpThin } from "shared";

import styles from "./FileStores.module.css";

function FileStores() {
	const store = fileStoreService;
	let loadState = cloudStoreService.loadResult.state;

	if (!isUndefined(store.currentStore)) {
		window.localStorage.setItem("currentStore", store.currentStore);
	}
	const [lastValidStore, setLastValidStore] = useState<string | null>(FileStorages.Db);
	const localCurrentStorage = window.localStorage.getItem("currentStore");

	useEffect(() => {
		if (localCurrentStorage) {
			setLastValidStore(localCurrentStorage);
		}
	}, [localCurrentStorage]);

	return (
		<MainPanel>
			<FlexColumn gap={15}>
				<NavPath />
				{store.currentStore && store.activePage ? (
					<>
						<Title
							activePage={store.activePage}
							setActivePage={store.setActivePage.bind(store)}
							isActiveStore={localCurrentStorage ?? store.currentStore}
							isSwithing={store.isSwithing}
							loadState={loadState}
						/>
						<Content activePage={store.activePage} activeStore={store.currentStore} />
					</>
				) : (
					lastValidStore && <UnavailableFileService currentStore={lastValidStore} />
				)}
			</FlexColumn>
		</MainPanel>
	);
}

export default observer(FileStores);
function Title(props: {
	activePage: FileStorages;
	setActivePage: (value: FileStorages) => void;
	isActiveStore: FileStorages | string;
	isSwithing: boolean;
	loadState?: LoadStates;
}) {
	let className = "";
	const handleClickDB = useCallback(() => {
		props.setActivePage(FileStorages.Db);
	}, []);
	const handleClickCloud = useCallback(() => {
		props.setActivePage(FileStorages.S3);
	}, []);
	if (props.isSwithing || props?.loadState === 0) {
		className += " " + styles.disabled;
	}

	return (
		<FlexColumn gap={15}>
			<span className={styles.title}>Хранение файлов</span>
			<div className={className}>
				<FlexRow gap={15}>
					<BigButton
						title={"База данных"}
						onClick={handleClickDB}
						isActive={props.isActiveStore === FileStorages.Db}
						isSelected={props.activePage === FileStorages.Db}
					/>
					<BigButton
						title={"Облачное хранилище S3"}
						onClick={handleClickCloud}
						isActive={props.isActiveStore === FileStorages.S3}
						isSelected={props.activePage === FileStorages.S3}
					/>
					<BigButton title={"Sharepoint (другое)"} onClick={() => {}} />
				</FlexRow>
			</div>
			<Separator />
		</FlexColumn>
	);
}

function Content(props: { activePage: FileStorages; activeStore: FileStorages }) {
	return (
		<div>
			<Swith activeName={props.activePage}>
				<SwithItem name={FileStorages.Db}>
					<DataBaseStore isActive={FileStorages.Db === props.activeStore} />
				</SwithItem>
				<SwithItem name={FileStorages.S3}>
					<CloudStore isActive={FileStorages.S3 === props.activeStore} />
				</SwithItem>
			</Swith>
		</div>
	);
}

const MENU_ITEMS_DATA_SETTING = 4;
const DATA_SETTINGS_STORE = 1;

function NavPath() {
	const navigate = useNavigate();
	const handleClickNavigation = useCallback(() => {
		navigate(SettingsPath);
	}, []);
	const handleClickMenu = useCallback(() => {
		navigate(SettingsPath + "/" + MenuItems.DataSettings);
	}, []);
	return (
		<div className={styles.firstTitle}>
			<div className={styles.adminTitle} onClick={handleClickNavigation}>
				{systemDesigner}
			</div>
			<div className={styles.arrowHead}>
				<ArrowToUpThin />
			</div>
			<div className={styles.adminTitle} onClick={handleClickMenu}>
				{Menu[MENU_ITEMS_DATA_SETTING].name}
			</div>
			<div className={styles.arrowHead}>
				<ArrowToUpThin />
			</div>
			<div className={styles.userTitle}>{DataSettings[DATA_SETTINGS_STORE].name}</div>
		</div>
	);
}
