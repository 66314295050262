import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import classNames from "classnames";

import { dispatcher, store } from "store";
import SavedFilter from "entities/filter/SavedFilter";
import { singlePageSynchroiser } from "pages/single-page/single-page-synchroiser/single-page-synchroiser";
import { Button } from "sale-bridge-ui-kit";

import styles from "../single-page-head.module.scss";

export const FavoriteFilters = observer((props: {
    visibleFilters: SavedFilter[],
    size: 'small' | 'medium' | 'large',
    buttonRefs: React.MutableRefObject<(HTMLDivElement | null)[]>
}) => {
    const { entityName } = useParams();
    const label = classNames(styles.label, {
        [styles[`label${props.size}`]]: props.size,
    });

    const entity = useMemo(() => {
        return dispatcher.entity.get();
    }, [dispatcher.entity.get()?.entity.filter?.savedFilter]);

    const applyFavoriteFilter = useCallback(async (favoriteFilter: SavedFilter) => {
        if (favoriteFilter?.filterInfo?.filters[0]?.rightExpression) {
            dispatcher.filter.setSavedFilter(favoriteFilter);
            singlePageSynchroiser.applyFilter(entityName ?? "");
        }
    }, [entityName]);
    
    return (
        <div className={styles.favoriteFilters}>
            {store.favoriteFilters?.length === 0 ? (
                <div className={label}>Избранных фильтров пока нет</div>
            ) : (
                props.visibleFilters.map((favoriteFilter, i) => (
                    <div
                        key={i}
                        ref={(el) => (props.buttonRefs.current[i] = el)}
                    >
                        <Button
                            text={favoriteFilter.filterName ?? ""}
                            size={props.size}
                            variant={entity?.entity.filter?.savedFilter?.id === favoriteFilter.id ? 'secondary' : 'default'}
                            border={entity?.entity.filter?.savedFilter?.id !== favoriteFilter.id}
                            link={false}
                            loading={false}
                            onClick={() => { applyFavoriteFilter(favoriteFilter); }}
                        />
                    </div>
                ))
            )}
        </div>
    );
});

