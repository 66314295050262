import { Entity } from "store";
import { Column, VisibleColumn } from "types/entity";

export enum Click {
    Single = 1,
    Double = 2
}
export interface IGeneralizedColumn extends Column {
    columnName: string;
    columnTitle: string;
    customerColumnTitle: string;
    indexX: number;
    indexY: number;
    spanX: number;
    spanY: number;
}

export type IContextMenuOption = {
    caption: string,
    iconName:string,
    size: "small" | "medium" | "large" | "12" | "16" | "20" | "24" | "28" | "32" | "64" | "96",
    action: () => void,
    isDivider: boolean
}
export type GeneralizedGridProps = {
    entity: Entity;
    contextMenuOptions?: IContextMenuOption[],
    onChangeCheckedAll: (value: boolean) => void,
    onDoubleRowClick?: (rowId: string, event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
    isDetailGrid?: boolean,
    sysFlagField?: string
}

export type GridHeadProps = {
    entity: Entity,
    columns: IGeneralizedColumn[],
    checkedAll: boolean,
    onChangeChecked: (checked: boolean) => void,
    onSortClick: (column: IGeneralizedColumn) => void,
    setColumnWidth: (columnId: string, width: number) => void
    isDetailGrid?: boolean,
}

export type GridRowProps = {
    entity: Entity,
    row: any,
    visibleColumns: VisibleColumn[],
    isDetailGrid?: boolean,
    onClick?: (row: any) => void,
    onChangeCheckedAll: (value: boolean) => void,
    onDoubleRowClick?: (rowId: string, event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
    sysFlagField?: string
}

export type ValueProps = {
    row: any,
    column: IGeneralizedColumn,
    width?: string,
    entityName?: string
}