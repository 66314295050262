import { observer } from "mobx-react-lite";

import StageProgressBar from "./stage-progress-bar";

import IStage from "entities/IStage";

import styles from "./stage-indication.module.css";

interface IStageIndication {
    stages?: IStage[];
    stage: IStage;
    classname?: string;
}

const StageIndication = observer(function (props: IStageIndication) {

    let className = `${styles.stage} `;
    if (props.classname)
        className += props.classname;
   
    let sortedStages = props.stages!.slice().sort((a, b) => a.order > b.order ? 1 : -1);
    let isLastAndSuccessfulStage = props.stage.end && props.stage.successful;
    let isLastAndFailStage = props.stage.end && !props.stage.successful;

    const getStageNameStyle = () => {
       return isLastAndSuccessfulStage ? styles.stageCaption + " " + styles.won 
       : isLastAndFailStage ? styles.stageCaption + " " + styles.lost : styles.stageCaption
      };

    const calculateProgressBarPercentage = () => {
        if(isLastAndSuccessfulStage){
            return 100;
        }
        const currentIndex = sortedStages.findIndex(stage => stage.id === props.stage.id);
        if (currentIndex === -1) return 0;
        return (currentIndex + 1) / sortedStages.length * 100;
    };

    const getIndicatorStyle = (item:IStage)=>{
    
        if(isLastAndSuccessfulStage){
            return styles.indication + " " + styles.success
        } 
        if(isLastAndFailStage){
            return styles.indication + " " + styles.fail
        } 

        if(item.id == props.stage.id){
            return styles.indication + " " + styles.inProcess
        }else{
            if(item.order < props.stage.order){
                return styles.indication + " " + styles.completed 
            }else{
                return styles.indication
            }
        }
    }

    return (
        <div className={styles.stage}>
            <span className={getStageNameStyle()}>{props.stage.name}</span>
            <div className= {styles.progressBarContainer}>
            <StageProgressBar
                    percentage={calculateProgressBarPercentage()}
                    isLastAndSuccessful={isLastAndSuccessfulStage}
                    isLastAndFail={isLastAndFailStage}
                />
            </div>
        </div>
    );

    //Старый вариант  по кубикам
    //   return (
    //     <div className= {styles.stage}>
    //         <span className={getStageNameStyle()}>{props.stage.name}</span>
    //         <div className={styles.indications}>
    //             {
    //                 sortedStages.map((item)=>{
    //                     return(
    //                         <div className={getIndicatorStyle(item)}></div>
    //                     )
    //                 })
    //             }
    //         </div>
    //     </div>
    //   );
})

export default StageIndication;