import { observer } from 'mobx-react-lite'
import { Field } from 'sale-bridge-ui-kit'

export type Props = {
	columnName: string | undefined;
	columnTitle?: string | undefined;
	prompt?: string | undefined;
	isRequired?: boolean | undefined;
	rounding?: string | undefined;
	value: string;
	isDisabled?: boolean;
	onChange: (value: string) => void;
	variant: 'standart' | 'outlined';
	labelPosition?: 'horizontal' | 'vertical';
	labelVariant?: 'black' | 'gray';
}

export const NumberField = observer(function (props: Props) {
	return (
		<Field
			type='number'
			subType='integer'
			size='small'
			label={props.columnTitle ?? ''}
			labelPosition={props.labelPosition ?? "vertical"}
			labelVariant={props.labelVariant}
			isRequired={props.isRequired}
			value={props.value?.toString() ?? ''}
			info={props.prompt}
			onChange={props.onChange}
			isDisabled={props.isDisabled}
			placeholder={'0'}
			textVariant={props.variant}
		/>
	)
})
