import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo, useState } from "react";

import { dispatcher } from "store";

import { Toggle } from "sale-bridge-ui-kit";
import { FieldIsland, HeadFieldsBlock } from "../global-settings";

import styles from "../global-settings.module.scss";

export const SpecialFeatures = observer(() => {
    const [isFlagDisabled, setFlagDisabled] = useState(false);

    const sectionWizzard = useMemo(() => {
        return dispatcher.entity.get()?.entity.sectionWizzard;
    }, [dispatcher.entity.get()?.entity.sectionWizzard]);

    const hasFlag = useMemo(() => {
        return sectionWizzard?.hasFlag;
    }, [sectionWizzard?.hasFlag]);    

    const hasTimeLogging = useMemo(() => {
        return sectionWizzard?.hasTimeLogging;
    }, [sectionWizzard?.hasTimeLogging]);    

    const hasTimer = useMemo(() => {
        return sectionWizzard?.hasTimer;
    }, [sectionWizzard?.hasTimer]);  

    useEffect(() => {
        if ((!dispatcher.entity.get()?.isNew && dispatcher.entity.get()?.entity.oldValueOfSectionWizzard?.hasFlag)) {
            setFlagDisabled(true);
        }
    }, [dispatcher.entity.get()?.isNew, hasFlag])

    const handleChangeFlagToggle = useCallback((value: boolean) => {
        if (value) {
            dispatcher.sectionWizzard.createFlagFieldsInConfig();
        } else {
            //TODO доделать, когда будет предусмотрен кейс отключения тогла, когда любое из полей флага находится на холсте
            // const flagColumnFinded = selector.sectionWizzard.getSysFlagColumn();
            // const flagDateColumnFinded = selector.sectionWizzard.getSysFlagDateColumn();
            // if (finded && finded.x > -1 && finded.y > -1) {
            // 	modalController.popupAdd({
            // 		id: idModal,
            // 		layout: <DisableStageModelConfirm disable={() => {
            // 			setStageModel(value);
            // 			dispatcher.sectionWizzard.setHasStageModel(value);
            // 			props.deletePriorityLookup();
            // 			props.deletePositionField();
            // 		}}
            // 			close={closeConfirm}
            // 		/>,
            // 		closeFunc: closeConfirm
            // 	})
            // 	return;
            // }
            // else {
            dispatcher.sectionWizzard.deleteFlagFieldsFromConfig();
            // }
        }
        dispatcher.sectionWizzard.setHasFlag(value);
    }, []);



    const handleChangeTimeLoggingToggle = useCallback((value: boolean) => {
        if(!value){
            dispatcher.sectionWizzard.setHasTimer(value);
        }
        
        dispatcher.sectionWizzard.setHasTimeLogging(value);
    }, []);


    
    const handleChangeTimerToggle = useCallback((value: boolean) => {

        dispatcher.sectionWizzard.setHasTimer(value);
    }, []);





    return (
        <div className={styles.blockSettings}>
            <HeadFieldsBlock caption="Специальные возможности" />
            <FieldIsland fieldInfo="После включения в раздел будут добавлены две виртуальные колонки: [Флажок] и [Дата флажка] — это нужно для последующей корректной работы действия. При сохранении раздела они добавятся в базу данных системы. 
            Чтобы отображать действие при наведении на карточку kanban-доски, перейдите в «Дизайн карточки» → «Быстрые действия».">
                <Toggle
                    checked={hasFlag ?? false}
                    onCheck={handleChangeFlagToggle}
                    title="Установка флажка для записей"
                    description="Действие позволяет отметить запись, дальнейшая работа по которой невозможна до выполнения зависимых задач."
                    size="small"
                    togglePosition='right'
                    isLocked={isFlagDisabled}
                />
            </FieldIsland>

            <FieldIsland fieldInfo="При сохранении раздела мы создадим новый раздел [Логирование времени] и свяжем его с текущим. Если раздел [Логирование времени] уже существует, то в него будет добавлена колонка-связка. Добавьте деталь по существующему объекту, чтобы видеть логи времени на странице редактирования записи. Чтобы отображать действия при наведении на карточку kanban-доски, перейдите в «Дизайн карточки» → «Быстрые действия».">
                <Toggle
                    checked={hasTimeLogging ?? false}
                    onCheck={handleChangeTimeLoggingToggle}
                    title="Логирование времени"
                    description="Позволяет фиксировать затраченное время на задачу."
                    size="small"
                    togglePosition='right'
                    isLocked={false}
                />
                   <Toggle
                    checked={hasTimer ?? false}
                    onCheck={handleChangeTimerToggle}
                    title="Секундомер"
                    description="Добавляет возможность включать секундомер."
                    size="small"
                    togglePosition='right'
                    isLocked={!hasTimeLogging}
                />
            </FieldIsland>
        </div >
    );
});