import classNames from "classnames";

import { Icon } from "sale-bridge-ui-kit";

import styles from "./profile-item.module.scss";

interface ProfileItemProps {
    value: string,
    icon: string,
    isDisable?: boolean,
    size: 'small' | 'medium' | 'large',
    onClick: () => void
}

export function ProfileItem(props: ProfileItemProps) {
    const classes = classNames(`${styles.item} `, {
        [`${styles.disableItem}`]: props.isDisable,
    });

    return <div className={classes} onClick={() => !props.isDisable && props.onClick()}>
        <Icon name={props.icon} size={props.size} />
        <span>{props.value}</span>
    </div>
}
