import { useCallback, useMemo, useRef, useState } from "react";

import { filterFinally, filterIntermediate, filterVisibly, sortByOrder } from "./lib";

import { StageGroup } from "./components/stage-group";

import { StageFullInfo } from "types/entity";
import { MenuState } from "pages/section-wizzard/data/data";

import styles from "./stage-model-settings.module.css"

interface StageModelSettingsProps {
    onChooseStage: (stage: any) => void
    stages: Array<StageFullInfo>,
    selectedStageId: string,
    handleMoveIntermediateStage: (oldPosition: number, newPosition: number) => void;
    handleMoveFinallyStage: (oldPosition: number, newPosition: number) => void;
}

const leftPanelWidth = 300;
const scrollZonePercent = 0.2;
const scrollStep = 20;

export function StageModelSettings(props: StageModelSettingsProps) {
    const [uppedStage, setUppedStage] = useState<null | StageFullInfo>(null);
    const stageContainer = useRef<HTMLDivElement>(null);

    const isLeftMenuOpened = useMemo(() => Number(localStorage.getItem("openedLeftPanel")) === MenuState.open, [localStorage.getItem("openedLeftPanel")]);
    const sortedStages = useMemo(() => sortByOrder(props.stages), [props.stages]);
    const intermediateStages = useMemo(() => filterIntermediate(sortedStages), [sortedStages]);
    const finallyStages = useMemo(() => filterFinally(sortedStages), [sortedStages]);

    /*
    * @description событие onMouseMove при попадании курсора на границы контейнера при перетаскивании стадии
    */
    const handleMouseMove = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (uppedStage) {
            if (stageContainer.current?.getBoundingClientRect().width) {
                const scrollZone = stageContainer.current?.getBoundingClientRect().width * scrollZonePercent;
                let cursorX = event.clientX;
                let rightBorder = stageContainer.current?.getBoundingClientRect().width;
                if (isLeftMenuOpened) {
                    rightBorder = rightBorder + leftPanelWidth;
                    /*
                    * @description попали в скролл зону левую с открытыми настройкам слева
                    */
                    if (cursorX < (scrollZone + leftPanelWidth) && cursorX > leftPanelWidth) {
                        stageContainer.current.scrollLeft -= scrollStep;
                    }
                } else {
                    /*
                    * @description попали в скролл зону левую без открытой панели настроек слева
                    */
                    if (cursorX < scrollZone && cursorX > 0) {
                        stageContainer.current.scrollLeft -= scrollStep;
                    }
                }
                /*
                 * @description попали в скролл зону правую
                 */
                if (cursorX > rightBorder - scrollZone && cursorX < rightBorder) {
                    stageContainer.current.scrollLeft += scrollStep;
                }
            }
        }
    }, [uppedStage, stageContainer.current, isLeftMenuOpened, props.selectedStageId]);

    return (
        <div
            ref={stageContainer}
            className={styles.wrapper}
            onMouseMove={handleMouseMove}
        >
            <StageGroup
                isSingleGroup={!filterVisibly(finallyStages).length}
                stages={intermediateStages}
                header="Промежуточные стадии"
                onOpenSettingPanel={props.onChooseStage}
                onMoveStage={props.handleMoveIntermediateStage}
                selectedStageId={props.selectedStageId}
                stagesCounter={sortedStages.length}
                uppedStage={uppedStage}
                setUppedStage={setUppedStage}
            />
            <StageGroup
                isSingleGroup={!filterVisibly(intermediateStages).length}
                stages={finallyStages}
                header="Результирующие стадии"
                onOpenSettingPanel={props.onChooseStage}
                onMoveStage={props.handleMoveFinallyStage}
                selectedStageId={props.selectedStageId}
                stagesCounter={sortedStages.length}
                uppedStage={uppedStage}
                setUppedStage={setUppedStage}
            />
        </div>
    );
}