import { observer } from "mobx-react";
import { useCallback } from "react";

import { CloseMaxi } from "shared";

import styles from "./warning-dialog.module.scss";

type EditNumeratorPopupProps = {
	children: JSX.Element;
	close: () => void;
};

export const WarningDialog = observer((props: EditNumeratorPopupProps) => {
	const handleCancel = useCallback(() => {
		props.close();
	}, [props.close]);

	return (
		<>
			<div className={styles.headerModal}>
				<span className={styles.titleModal}>Внимание</span>
				<CloseMaxi className={styles.closeButton} onClick={handleCancel} />
			</div>
			<div className={styles.dialogBody}>{props.children}</div>
		</>
	);
});
