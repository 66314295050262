export function dataToItem(data: any, array?: any[]) {
    if (data == null) return { displayValue: "", value: undefined }
    return { displayValue: data.name ?? data.title ?? data.displayValue, value: data.id };
}

export function itemToLookup(item: any) {
    if (item == null){
        return { id: "", name: "" }
    } 
    const name = item.displayValue ? item.displayValue : item.name;
    
    if (item.title) {
        return { id: item.id, name: name, title: item.title };
    } else {
        return { id: item.id, name: name };
    }

}

export function getValue(data: any) {
    if (data == null) return "";
    return data.name ?? data.title ?? data.displayValue;
}
