import { CheckBox } from "components"
import { ColumnTypeName } from "../data/column-type-name"
import { Item } from "types"

function TextField(props: { value: string }) {
    return (
        <span>{props.value}</span>
    )
}

function DateField(props: { value: Date }) {
    return (
        <span>{props.value.getMonth() + 1}/{props.value.getDate()}/{props.value.getFullYear()}</span>
    )
}


function LookupField(props: { value: Item | null }) {
    return (
        <span>{props.value?.name}</span>
    )
}

function BooleanField(props: { value: boolean }) {
    return (
        <CheckBox style={{ height: "16px", width: "16px" }} checked={props.value} onChangeChecked={() => { }} />
    )
}

function Field(props: { value: any, type: string }) {
    if (props.type == ColumnTypeName.Logical) {
        return <BooleanField value={props.value} />
    }
    else if (props.type == ColumnTypeName.Lookup) {
        return <LookupField value={props.value} />
    }
    else if (props.type == ColumnTypeName.DateTime) {
        return <DateField value={new Date(props.value)} />
    }
    else {
        return <TextField value={props.value} />
    }
}

export default Field;
