import { makeAutoObservable, toJS } from "mobx";

import { Entity, dispatcher, store } from "store";

import { SectionViewPageColumnSettings, SectionViewPageSettings, VisibleColumn } from "types/entity";
import { IFormat } from "entities/IItem";
import { synchroiser } from "synchroiser";

class GridParametersState {
    visibleColumns: VisibleColumn[] = [];
    orderedColumns: VisibleColumn[] = [];
    checkedColumns: IFormat[] = [];
    duplicateColumn: VisibleColumn | null = null;
    entity: Entity | undefined = undefined;

    constructor() {
        makeAutoObservable(this);
    }

    get isNoneSelected() {
        return this.checkedColumns.find(checkedColumn => checkedColumn.checked) === undefined;
    }

    initColumns(visibleColumns: VisibleColumn[], entity: Entity | undefined) {
        this.visibleColumns = visibleColumns;
        this.entity = entity;
        this.checkedColumns = entity ? entity.entity.columns.sort((a, b) => {
            if (a.columnTitle.toLowerCase() < b.columnTitle.toLowerCase()) {
                return -1;
            }
            if (a.columnTitle.toLowerCase() > b.columnTitle.toLowerCase()) {
                return 1;
            }
            return 0;
        }).map((column) => {
            const checked = this.visibleColumns.find((visibleColumn) => visibleColumn.columnId === column.columnId) !== undefined;
            return {
                name: column.columnTitle,
                checked: checked,
                id: column.columnId
            }
        }) : [];
    }

    initOrderedColumns() {
        this.orderedColumns = this.visibleColumns.map((visibleColumn, index) => {
            return {
                ...visibleColumn,
                indexX: index + 1
            }
        });
        this.sortOrderedColumns();
    }

    sortOrderedColumns() {
        /**
        * @description если значение проставленного индекса = 0
        */
        const zeroIndex = this.orderedColumns.findIndex(orderedColumn => orderedColumn.indexX === 0);
        /**
         * @description если значение проставленного индекса повторяется
         */
        const originalColumnIndex = this.orderedColumns.findIndex(orderedColumn => orderedColumn.indexX === this.duplicateColumn?.indexX && orderedColumn.columnId !== this.duplicateColumn?.columnId)
        const duplicateColumnIndex = this.orderedColumns.findIndex(orderedColumn => orderedColumn.columnId === this.duplicateColumn?.columnId);
        /**
         * @description если значение проставленного индекса больше количества колонок
         */
        const exceedingIndex = this.orderedColumns.findIndex(orderedColumn => orderedColumn.indexX > this.orderedColumns.length);
        /**
         * @description если значение проставленного индекса - пустое
         */

        const emptyIndex = this.orderedColumns.findIndex(orderedColumn => orderedColumn.indexX === -1);
        if (zeroIndex > -1) {
            const newIndexX: VisibleColumn = {
                ...this.orderedColumns[zeroIndex],
                indexX: 1
            }
            this.orderedColumns.splice(zeroIndex, 1, newIndexX);
        }
        if (exceedingIndex > -1 || emptyIndex > -1) {
            const newIndexX: VisibleColumn = {
                ...this.orderedColumns[exceedingIndex > -1 ? exceedingIndex : emptyIndex],
                indexX: this.orderedColumns.length
            }
            this.orderedColumns.splice(exceedingIndex > -1 ? exceedingIndex : emptyIndex, 1, newIndexX);
        }

        this.orderedColumns = this.orderedColumns.map((orderedColumn, index) => {
            if (
                (zeroIndex > -1 && index < zeroIndex) ||
                (
                    originalColumnIndex > -1 &&
                    duplicateColumnIndex > -1 &&
                    index < duplicateColumnIndex
                    && index >= originalColumnIndex
                    && duplicateColumnIndex > originalColumnIndex
                )
            ) {
                return {
                    ...orderedColumn,
                    indexX: orderedColumn.indexX + 1
                }
            }
            if ((exceedingIndex > -1 && index > exceedingIndex) ||
                (emptyIndex > -1 && index > emptyIndex) ||
                (
                    originalColumnIndex > -1 &&
                    duplicateColumnIndex > -1 &&
                    index > duplicateColumnIndex
                    && index <= originalColumnIndex
                    && duplicateColumnIndex < originalColumnIndex
                )
            ) {
                return {
                    ...orderedColumn,
                    indexX: orderedColumn.indexX - 1
                }
            }
            return orderedColumn;
        });

        this.orderedColumns.sort((a, b) => (a.indexX > b.indexX) ? 1 : ((b.indexX > a.indexX) ? -1 : 0));
        this.duplicateColumn = null;
    }


    setValue(fieldName: string, value: string | boolean | null | number | VisibleColumn[]) {
        Reflect.set(this, fieldName, value);
    }

    async save() {

        if (!this.entity?.entity.sectionViewPageSettings) {
            return;
        }

        if (this.orderedColumns.length > 0) {
            this.orderedColumns = this.orderedColumns.map((orderedColumn) => {
                return {
                    ...orderedColumn,
                    indexX: orderedColumn.indexX - 1
                }
            });
        }
        let columnSettings: SectionViewPageColumnSettings[] = [];
        if (JSON.stringify(this.visibleColumns) === JSON.stringify(this.orderedColumns) || this.orderedColumns.length == 0) {
            this.visibleColumns= this.visibleColumns.map((visibleColumn,index) => {
                return {
                    ...visibleColumn,
                    indexX: index
                }
            });
            columnSettings = this.visibleColumns.map((visibleColumn) => {
                return {
                    columnId: visibleColumn.columnId,
                    columnName: visibleColumn.columnName,
                    columnTitle: visibleColumn.columnTitle,
                    customerColumnTitle: visibleColumn.customerColumnTitle,
                    indexX: visibleColumn.indexX,
                    indexY: visibleColumn.indexY,
                    spanX: visibleColumn.spanX,
                    spanY: visibleColumn.spanY
                }
            });
            this.entity!.entity.visibleColumns = [...this.visibleColumns];
        }
        else {
            columnSettings = this.orderedColumns.map((orderedColumn) => {
                return {
                    columnId: orderedColumn.columnId,
                    columnName: orderedColumn.columnName,
                    columnTitle: orderedColumn.columnTitle,
                    customerColumnTitle: orderedColumn.customerColumnTitle,
                    indexX: orderedColumn.indexX,
                    indexY: orderedColumn.indexY,
                    spanX: orderedColumn.spanX,
                    spanY: orderedColumn.spanY
                }
            });
            this.entity!.entity.visibleColumns = [...this.orderedColumns];
        }

        const body: SectionViewPageSettings = {
            ...this.entity!.entity.sectionViewPageSettings!,
            columnSettings: columnSettings
        }

        await synchroiser.updateSectionViewPageSettings(body, this.entity);
        this.reset();
    }

    reset() {
        this.visibleColumns = [];
        this.orderedColumns = [];
        this.checkedColumns = [];
        this.duplicateColumn = null;
    }

}

const gridParametersState = new GridParametersState();

export default gridParametersState;
