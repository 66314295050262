import React, { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router";
import { v4 } from "uuid";
import { toJS } from "mobx";
import { lowerFirst } from "lodash";

import { dispatcher, Entity, selector } from "store";
import { synchroiser } from "synchroiser";
import { useCopyToClipboard } from "shared/hooks/use-copy-to-clipboard";
import exportStore from "features/export-block/core/export-store";
import { singlePageSynchroiser } from "../../single-page-synchroiser/single-page-synchroiser";
import { modalController, Position } from "features/modals";
import { stopwatchStore } from "features/stopwatch/store";

import { Button, ButtonStyle, GeneralizedGrid } from "components";
import StaticGroupBlock from "features/advanced-filter/static-group-block/static-group-block";
import { FlagPopup } from "pages/single-page/components/flag-popup";
import { TimeLoggingPopup } from "pages/single-page/components/time-logging-popup";
import ExportBlock from "features/export-block/export-block";

import { ExportFormats } from "../../../settings/data/Fields";
import { IContextMenuOption } from "components/grid/data/data";
import { ExcludeRecordsFromStaticGroup, QuickActionType, QuickActionTypeName } from "types/entity";

import { Warning } from "shared";

import stylesSectionHeader from "features/section-head/section-head.module.css";

const WARNING_TEXT = "Вы действительно хотите удалить выбранные записи?\nЭто действие невозможно отменить";

export const Grid = observer(() => {
	const entity = useMemo(
		() => dispatcher.entity.get() as Entity,
		[dispatcher.entity.get()?.entity.rows.length, toJS(dispatcher.entity.get()?.entity.visibleColumns)]
	);
	const navigate = useNavigate();
	const [idModal] = useState<string>(v4());
	const [idExportModal] = useState<string>(v4());
	const [idNotification] = useState<string>(v4());
	const [copiedText, copyFn, isCopied] = useCopyToClipboard();
	const sysFlagField = useMemo(
		() => lowerFirst(selector.sectionWizzard.getSysFlagColumn()?.fieldConfig?.columnName),
		[selector.sectionWizzard.getSysFlagColumn()]
	);
	const sectionWizzard = useMemo(
		() => dispatcher.entity.get()?.entity.sectionWizzard,
		[toJS(dispatcher.entity.get()?.entity.sectionWizzard)]
	);

	const oneCheckedId = useMemo(() => {
		if (entity?.entity.includedIds.length === 1) return entity?.entity.includedIds[0].id ?? "";
		return "";
	}, [entity?.entity.includedIds && entity?.entity.includedIds[0] ? entity?.entity.includedIds[0].id : ""]);

	const isExistStopwatchForCurrentId = useMemo(() => {
		if (oneCheckedId.length > 0) return stopwatchStore.stopwatches.get(oneCheckedId) ?? false;
		else false;
	}, [oneCheckedId ? stopwatchStore.stopwatches.get(oneCheckedId) : ""]);

	const excludeRecordsFromGroup = useCallback(async () => {
		const includedIds: string[] = entity?.entity.includedIds ? entity!.entity.includedIds.map(({ id }) => id) : [];

		const excludedIds: string[] = entity?.entity.excludedIds ? entity!.entity.excludedIds.map(({ id }) => id) : [];

		const data: ExcludeRecordsFromStaticGroup = {
			staticGroupId: selector.filter.getFilter()?.staticGroup!.id!,
			recordsToExclude: includedIds,
			recordsToSave: excludedIds
		};

		await synchroiser.excludeRecordsFromStaticGroup(data);
	}, [entity]);

	const openRecordInNewPage = useCallback(() => {
		if (entity?.entity.includedIds && entity?.entity.includedIds.length > 0) window.open(window.location.href + "/" + oneCheckedId);
	}, [entity?.entity.includedIds, oneCheckedId]);

	const openRecord = useCallback(() => {
		if (entity?.entity.includedIds && entity?.entity.includedIds.length > 0) navigate(oneCheckedId);
	}, [entity?.entity.includedIds, oneCheckedId, navigate]);

	const closeConfirm = useCallback(
		(event?: React.MouseEvent<HTMLElement, MouseEvent>) => {
			event?.stopPropagation();
			modalController.modalRemove(idModal);
		},
		[idModal]
	);

	const deleteRecords = useCallback(async () => {
		if (dispatcher.entity.get()?.entity) {
			singlePageSynchroiser.viewLoading();
			await synchroiser.deleteRecords();
			await synchroiser.getEntity();
			singlePageSynchroiser.viewLoaded();
		}
		closeConfirm();
	}, [closeConfirm]);

	const warningConfirm = useMemo(() => {
		return (
			<div className={stylesSectionHeader.warningDialog}>
				<div className={stylesSectionHeader.warningHeader}>
					<span className={stylesSectionHeader.warningTitle}>Внимание</span>
					<Warning />
				</div>
				<div className={stylesSectionHeader.warningDialogBody}>
					<span className={stylesSectionHeader.warningText}>{WARNING_TEXT}</span>
				</div>
				<div className={stylesSectionHeader.dialogFooter}>
					<Button caption="Отмена" onClick={closeConfirm} style={ButtonStyle.Subtle} isDisabled={false} />
					<Button caption="Удалить" onClick={deleteRecords} style={ButtonStyle.Danger} isDisabled={false} />
				</div>
			</div>
		);
	}, [closeConfirm, deleteRecords]);

	const handleDelete = useCallback(() => {
		modalController.popupAdd({ id: idModal, layout: warningConfirm, closeFunc: closeConfirm });
	}, [warningConfirm, idModal, closeConfirm]);

	const copyLink = useCallback(async () => {
		if (entity?.entity.includedIds && entity?.entity.includedIds.length > 0) {
			await copyFn(window.location.href + "/" + oneCheckedId);

			const layoutNotification = (
				<>
					<div>Ссылка скопирована в буфер обмена</div>
				</>
			);
			modalController.notificationAdd({
				id: idNotification,
				position: Position.CENTER,
				layout: layoutNotification,
				allowDefaultClick: true,
				allowTimer: true
			});
		}
	}, [entity?.entity.includedIds, oneCheckedId]);

	const closeExport = useCallback(() => {
		closeConfirm();
		modalController.modalRemove(idExportModal);
		dispatcher.entity.onChangeCheckedAll(false);
		exportStore.clearCheckedColumns();
	}, [idExportModal, closeConfirm]);

	const warningExportConfirm = useMemo(() => {
		return (
			<div className={stylesSectionHeader.warningDialog}>
				<div className={stylesSectionHeader.warningHeader}>
					<span className={stylesSectionHeader.warningTitle}>Внимание</span>
					<Warning />
				</div>
				<div className={stylesSectionHeader.warningDialogBody}>
					<span
						className={stylesSectionHeader.warningText}
					>{`Вы действительно хотите отменить экспорт?\nТекущий прогресс будет утерян.`}</span>
				</div>
				<div className={stylesSectionHeader.dialogFooter}>
					<Button caption="Вернуться к экспорту" onClick={closeConfirm} style={ButtonStyle.Subtle} isDisabled={false} />
					<Button caption="Отменить" onClick={closeExport} style={ButtonStyle.Danger} isDisabled={false} />
				</div>
			</div>
		);
	}, [closeConfirm, closeExport]);

	const openExportWarning = useCallback(() => {
		modalController.popupAdd({ id: idModal, layout: warningExportConfirm, closeFunc: closeExport });
	}, [idModal, warningExportConfirm, closeExport]);

	const openExportBlock = useCallback(() => {
		exportStore.initSignalR();
		modalController.popupAdd({
			id: idExportModal,
			layout: (
				<ExportBlock
					schema={entity?.entityName!}
					pageTitle={entity?.entityTitle!}
					formats={ExportFormats}
					title={"Экспорт данных: " + entity?.entityTitle!}
					choosingCount={entity?.entity.countOfChecked!}
					allCount={entity?.entity.quality!}
					onClickClose={openExportWarning}
					close={closeExport}
				/>
			),
			closeFunc: openExportWarning
		});
	}, [
		idExportModal,
		entity?.entityName,
		entity?.entityTitle,
		entity?.entity.countOfChecked,
		entity?.entity.quality,
		openExportWarning,
		closeExport
	]);

	const addToGroup = useCallback(() => {
		if (selector.filter.getFilter()?.staticGroup) {
			excludeRecordsFromGroup();
		} else {
			modalController.popupAdd({
				id: idModal,
				layout: <StaticGroupBlock onCancel={closeConfirm} />,
				closeFunc: closeConfirm
			});
		}
	}, [excludeRecordsFromGroup, idModal, closeConfirm]);

	const handleCheckAll = useCallback((value: boolean) => {
		dispatcher.entity.onChangeCheckedAll(value);
	}, []);

	const recordTitle = useMemo(() => {
		return dispatcher.currentRow.getTitleByRowId(oneCheckedId);
	}, [oneCheckedId]);

	const handleFlagClick = useCallback(() => {
		let currentFlagValue = entity?.entity.includedIds[0][sysFlagField];
		modalController.popupAdd({
			id: idModal,
			layout: <FlagPopup recordId={oneCheckedId} mode={currentFlagValue ? "remove" : "add"} close={closeConfirm} />,
			closeFunc: closeConfirm
		});
	}, [entity?.entity.includedIds ? stopwatchStore.stopwatches.get(entity?.entity.includedIds[0]) : "", sysFlagField, oneCheckedId]);

	const handleTimeClick = useCallback(() => {
		modalController.popupAdd({
			id: idModal,
			layout: <TimeLoggingPopup recordId={oneCheckedId} recordTitle={recordTitle} close={closeConfirm} />,
			closeFunc: closeConfirm
		});
	}, [oneCheckedId]);

	const handleStopwatchStartClick = useCallback(() => {
		stopwatchStore.startStopwatch(oneCheckedId);
	}, [oneCheckedId]);

	const handleStopwatchPauseClick = useCallback(() => {
		stopwatchStore.pauseStopwatch(oneCheckedId);
	}, [oneCheckedId]);

	const handleStopwatchStopClick = useCallback(() => {
		const resultInMinutesFromStopwatch = stopwatchStore.stopStopwatch(oneCheckedId);
		stopwatchStore.removeStopwatch(oneCheckedId);
		modalController.popupAdd({
			id: idModal,
			layout: (
				<TimeLoggingPopup
					recordId={oneCheckedId}
					recordTitle={recordTitle}
					timeFromStopwatch={resultInMinutesFromStopwatch.toString()}
					close={closeConfirm}
				/>
			),
			closeFunc: closeConfirm
		});
	}, [oneCheckedId]);

	const flagActionCaption = useMemo(() => {
		if (sysFlagField && entity?.entity.includedIds.length > 0) {
			let currentFlagValue = entity?.entity.includedIds[0][sysFlagField];
			if (currentFlagValue) {
				return "Снять флажок";
			}
		}
		return QuickActionTypeName[QuickActionType.Flag].title;
	}, [toJS(entity?.entity.includedIds), sysFlagField]);

	const flagActionIcon = useMemo(() => {
		if (sysFlagField && entity?.entity.includedIds.length > 0) {
			let currentFlagValue = entity?.entity.includedIds[0][sysFlagField];
			if (currentFlagValue) {
				return "FlagSolid";
			}
		}
		return "Flag";
	}, [toJS(entity?.entity.includedIds), sysFlagField]);

	const contextMenuWhenOneCheckedElement: IContextMenuOption[] = useMemo(() => {
		const isDividerInCopy = sectionWizzard?.hasFlag || sectionWizzard?.hasTimeLogging ? false : true;
		const isDividerInFlag = sectionWizzard?.hasTimeLogging ? false : true;
		const isDividerInTimeLog = sectionWizzard?.hasTimer && !isExistStopwatchForCurrentId ? false : true;
		const isDividerInStopwatch = isExistStopwatchForCurrentId ? false : true;
		const actions: IContextMenuOption[] = [
			{
				caption: "Открыть запись",
				iconName: "OpenInWindow",
				size: "16",
				action: openRecord,
				isDivider: false
			},
			{
				caption: "Открыть в новой вкладке",
				iconName: "OpenNewWindow",
				size: "small",
				action: openRecordInNewPage,
				isDivider: true
			},
			{
				caption: QuickActionTypeName[QuickActionType.CopyLink].title,
				iconName: "Link",
				size: "small",
				action: copyLink,
				isDivider: false
			},
			{
				caption: "Дублировать",
				iconName: "Copy",
				size: "small",
				action: () => {}, //TODO реализовать дублирование
				isDivider: isDividerInCopy
			}
		];
		if (sectionWizzard?.hasFlag) {
			actions.push({
				caption: flagActionCaption,
				iconName: flagActionIcon,
				size: "16",
				action: handleFlagClick,
				isDivider: isDividerInFlag
			});
		}
		if (sectionWizzard?.hasTimeLogging) {
			actions.push({
				caption: QuickActionTypeName[QuickActionType.LogTime].title,
				iconName: "Time",
				size: "small",
				action: handleTimeClick,
				isDivider: isDividerInTimeLog
			});
		}
		if (sectionWizzard?.hasTimer) {
			if (!isExistStopwatchForCurrentId) {
				actions.push({
					caption: QuickActionTypeName[QuickActionType.Stopwatch].title,
					iconName: "Stopwatch",
					size: "16",
					action: handleStopwatchStartClick,
					isDivider: isDividerInStopwatch
				});
			} else {
				if (isExistStopwatchForCurrentId.isRunning) {
					actions.push({
						caption: "Поставить на паузу",
						iconName: "PauseCircleFill",
						size: "16",
						action: handleStopwatchPauseClick,
						isDivider: false
					});
				} else {
					actions.push({
						caption: "Возобновить",
						iconName: "PlayCircleFill",
						size: "16",
						action: handleStopwatchStartClick,
						isDivider: false
					});
				}
				actions.push({
					caption: "Остановить и залогировать...",
					iconName: "StopCircleFill",
					size: "16",
					action: handleStopwatchStopClick,
					isDivider: true
				});
			}
		}
		actions.push({
			caption: "Удалить",
			iconName: "Trash",
			size: "16",
			action: handleDelete,
			isDivider: false
		});
		return actions;
	}, [
		flagActionCaption,
		flagActionIcon,
		entity?.entity.includedIds ? stopwatchStore.stopwatches.get(entity?.entity.includedIds[0]) : "",
		sectionWizzard?.hasFlag,
		sectionWizzard?.hasTimeLogging,
		sectionWizzard?.hasTimer,
		isExistStopwatchForCurrentId,
		isExistStopwatchForCurrentId ? isExistStopwatchForCurrentId.isRunning : ""
	]);

	const contextMenuWhenMoreCheckedElements: IContextMenuOption[] = useMemo(() => {
		const selectedItems = !entity?.entity.isCheckedAll ? `(${entity?.entity.includedIds.length})` : "";
		return [
			{
				caption: `Экспортировать...${selectedItems}`,
				iconName: "Export",
				size: "16",
				action: openExportBlock,
				isDivider: false
			},
			{
				caption: `Поместить в группу...${selectedItems}`,
				iconName: "Folder",
				size: "small",
				action: addToGroup,
				isDivider: true
			},
			{
				caption: `Удалить ${selectedItems}`,
				iconName: "Trash",
				size: "16",
				action: handleDelete,
				isDivider: false
			}
		];
	}, [openExportBlock, addToGroup, handleDelete, entity?.entity.includedIds.length]);

	const contextMenuOptions: IContextMenuOption[] = useMemo(() => {
		if (entity?.entity.isCheckedAll || (entity?.entity.includedIds && entity?.entity.includedIds.length! > 1)) {
			return contextMenuWhenMoreCheckedElements;
		} else {
			return contextMenuWhenOneCheckedElement;
		}
	}, [entity?.entity.isCheckedAll, entity?.entity.includedIds.map((item) => item)]);

	return (
		<GeneralizedGrid
			entity={entity}
			contextMenuOptions={contextMenuOptions}
			onChangeCheckedAll={handleCheckAll}
			sysFlagField={sysFlagField}
		/>
	);
});
