import { api } from "shared";

import { dispatcher } from "store/store-dipatcher/dispatcher";

export class SystemDesigner {
	async createNewSection() {
		const sectionFields = dispatcher.entity.get()?.entity.sectionWizzard;

		const data = {
			entityTitle: sectionFields?.entityTitle,
			systemName: sectionFields?.systemName,
			hasStageModel: sectionFields?.hasStageModel,
			kanbanIsEnabled: sectionFields?.kanbanIsEnabled,
			hasCalendar: sectionFields?.hasCalendar,
			hasSpecificFinish: sectionFields?.hasSpecificFinish,
			hasFlag: sectionFields?.hasFlag,
			hasTimeLogging: sectionFields?.hasTimeLogging,
			hasTimer: sectionFields?.hasTimer,
			viewColumnId: sectionFields?.viewColumnId,
			reactorPage: sectionFields?.reactorPage ?? "",
			reactorConfig: sectionFields?.reactorConfig,
			accessRightsConfig: sectionFields?.accessRightsConfig,
			stageModelConfig: sectionFields?.stageModelConfig,
			kanbanConfig: sectionFields?.kanbanConfig,
			businessRules: sectionFields?.businessRules
		};
		const response = await api.http.systemDesigner.createSection().post(data);
		return response;
	}
	async updateSection() {
		const sectionFields = dispatcher.entity.get()?.entity.sectionWizzard;

		const data = {
			entityTitle: sectionFields?.entityTitle,
			systemName: sectionFields?.systemName,
			hasStageModel: sectionFields?.hasStageModel,
			kanbanIsEnabled: sectionFields?.kanbanIsEnabled,
			hasCalendar: sectionFields?.hasCalendar,
			hasSpecificFinish: sectionFields?.hasSpecificFinish,
			hasFlag: sectionFields?.hasFlag,
			hasTimeLogging: sectionFields?.hasTimeLogging,
			hasTimer: sectionFields?.hasTimer,
			viewColumnId: sectionFields?.viewColumnId,
			reactorPage: sectionFields?.reactorPage ?? "",
			reactorConfig: sectionFields?.reactorConfig,
			accessRightsConfig: sectionFields?.accessRightsConfig,
			stageModelConfig: sectionFields?.stageModelConfig,
			kanbanConfig: sectionFields?.kanbanConfig,
			businessRules: sectionFields?.businessRules
		};
		return await api.http.systemDesigner.updateSection().put(data);
	}
}

export const systemDesigner = new SystemDesigner();
