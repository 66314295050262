import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";

import { Button, ButtonStyle, Field, Input, InputStyleName } from "components";

import { CloseMaxi, Warning } from "shared";

import styles from "./filter-popups.module.css";

export type RestoreLayoutType = {
	restore: () => void;
	closeConfirm: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

const RestoreLayout = observer((props: RestoreLayoutType) => {
	const restoreFilter = useCallback(async () => {
		props.restore();
		props.closeConfirm();
	}, []);

	return (
		<>
			<div className={styles.header}>
				<span className={styles.title}>Внимание</span>
				<CloseMaxi
					className={styles.closeButton}
					onClick={() => {
						props.closeConfirm();
					}}
				/>
			</div>
			<div className={styles.dialogBody}>
				Фильтр будет восстановлен до исходной версии.
				<br /> Несохранённые изменения будут утеряны.
			</div>
			<div className={styles.dialogFooter}>
				<Button caption="Вернуться к редактированию" onClick={props.closeConfirm} style={ButtonStyle.Subtle} />
				<Button caption="Восстановить" onClick={restoreFilter} style={ButtonStyle.Primary} />
			</div>
		</>
	);
});

export default RestoreLayout;
