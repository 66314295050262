import { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";

import { InputSearch } from "components";
import { ExistFieldsBlock, NewFieldsBlock } from "./blocks";

import { IGridItem } from "components/select/types";
import { GridItem } from "types/entity";

import styles from "./detail-master-right-panel.module.css";

interface Props {
	idDropZone?: string | null,
	newFields: Array<IGridItem>,
	existsColumns: Array<IGridItem>,
	onSaveNewField: (newItem: GridItem) => void
}
export const DetailMasterRightPanel = observer((props: Props) => {
	const [searchValue, setSearchValue] = useState('');

	const handleSearchChange = useCallback((value: string) => {
		setSearchValue(value)
	}, [searchValue]);

	const newFields = useMemo(() => {
		if (searchValue) {
			return props.newFields.filter(column => column.displayValue.includes(searchValue))
		} else {
			return props.newFields;
		}

	}, [toJS(props.newFields), searchValue]);

	const existsColumns = useMemo(() => {
		if (searchValue) {
			return props.existsColumns.filter(column => column.displayValue.includes(searchValue))
		} else {
			return props.existsColumns;
		}
	}, [toJS(props.existsColumns), searchValue]);

	return (
		<div className={styles.designerRightPanel}>
			<InputSearch
				value={searchValue}
				placeholder={"Поиск"}
				onChangeValue={handleSearchChange}
				className={styles.search}
				classNameInput={styles.search}
			/>
			<div className={styles.dragFields}>
				<NewFieldsBlock
					items={newFields}
					onSaveNewField={props.onSaveNewField}
				/>
				{existsColumns.length > 0 &&
					<ExistFieldsBlock items={existsColumns} />
				}
			</div>
		</div >
	);
});
