import { observer } from "mobx-react";
import { useMemo } from "react";

import { Icon } from "sale-bridge-ui-kit";
import { FileStorages, FileStoragesNames } from "entities/FileStorages";

import styles from "./unavailable-file-service.module.scss";

const UNAVAILABLE_SERVICE = "Сервис файлов недоступен";
const LAST_STORAGE = "Последнее используемое хранилище: ";

export const UnavailableFileService = observer((props: { currentStore: FileStorages | string }) => {
	function getStorageNameById(storageId: string): string {
		switch (storageId) {
			case FileStorages.Db:
				return FileStoragesNames.Db;
			case FileStorages.S3:
				return FileStoragesNames.S3;
			default:
				return FileStoragesNames.OTHER;
		}
	}

	const currentStoreName = useMemo(() => getStorageNameById(props.currentStore), [props.currentStore]);

	return (
		<div className={styles.uploadWrapper}>
			<div className={styles.upload}>
				<Icon name="DataStorage" size="64" />
				<div className={styles.text}>
					<span className={styles.header}>{UNAVAILABLE_SERVICE}</span>
					<span className={styles.main}>{LAST_STORAGE}</span>
					<span className={styles.main}>{currentStoreName}</span>
				</div>
			</div>
		</div>
	);
});
