import { observer } from "mobx-react-lite";
import { useCallback, useState } from "react";

import { Button, ButtonStyle } from "components";

import { Warning } from "shared";

import styles from "./filter-popups.module.css";

export type LayoutDeleteConfirmType = {
	dialogBody: string | JSX.Element;
	delete: () => Promise<void>;
	closeConfirm: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

const LayoutDeleteConfirm = observer((props: LayoutDeleteConfirmType) => {
	const [isDisableButton, setDisableButton] = useState<boolean>(false);

	const deleteFilter = useCallback(async () => {
		setDisableButton(true);
		await props.delete().then(() => {
			props.closeConfirm();
			setDisableButton(false);
		});
	}, []);

	return (
		<>
			<div className={styles.header}>
				<span className={styles.deleteTitle}>Внимание</span>
				<Warning className={styles.closeButton} />
			</div>
			<div className={styles.dialogBody}>{props.dialogBody}</div>
			<div className={styles.dialogFooter}>
				<Button caption="Отмена" onClick={props.closeConfirm} style={ButtonStyle.Subtle} />
				<Button caption="Удалить" onClick={deleteFilter} isDisabled={isDisableButton} style={ButtonStyle.Danger} />
			</div>
		</>
	);
});

export default LayoutDeleteConfirm;
