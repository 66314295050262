import classNames from "classnames";
import { observer } from "mobx-react";
import { MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { v4 } from "uuid";
import { toJS } from "mobx";

import { dispatcher, store } from "store";
import { synchroiser } from "synchroiser";
import { NEW_RECORD } from "synchroiser/synchroiser";
import { modalController } from "features/modals";

import Avatar from "features/avatar/avatar-generator";
import { Button, Field, Icon } from "sale-bridge-ui-kit";
import { CommentBodyVariant, useCommentContext } from "..";
import { EditableCommentBlock } from "./components/editable-comment";

import styles from "./comment-input.module.scss";

export const CommentInput = observer((props: { blockVariant: CommentBodyVariant }) => {
	const { comment, updateCommentIdAndText, customUpdate } = useCommentContext();
	const textareaRef = useRef<HTMLTextAreaElement | null>(null);
	const [isInputInFocus, setInputInFocus] = useState<boolean>(false);
	const [text, setText] = useState<string>("");
	const [bufferedText, setBufferedText] = useState<string>("");
	const [idModal] = useState<string>(v4());

	const commentInputWrapperClassNames = classNames(styles.commentInputWrapper, {
		[`${styles.commentInputInFullview}`]: props.blockVariant == CommentBodyVariant.FullView,
		[`${styles.commentInputInQuickView}`]: props.blockVariant == CommentBodyVariant.QuickView
	});
	const row = useMemo(() => dispatcher.currentRow.get()!, [toJS(dispatcher.currentRow.get())]);
	const isNew = useMemo(() => row?.id === NEW_RECORD, [row?.id]);

	const commentInputBodyFooterClassNames = classNames(styles.commentInputBodyFooter, {
		[`${styles.footerVisible}`]: isInputInFocus
	});

	useEffect(() => {
		const finded = dispatcher.comments.get()?.find((getComment) => getComment.id === comment.commentId);
		if (!finded) {
			updateCommentIdAndText({
				comment: {
					commentId: "",
					text: ""
				}
			});
			setText("");
			if (textareaRef.current) {
				textareaRef.current.style.height = "0px";
				textareaRef.current.blur();
			}
			setInputInFocus(false);
		}
	}, [dispatcher.comments.get()?.length]);

	useEffect(() => {
		if (comment.commentId) {
			if (text.length > 0) {
				setBufferedText(text);
			}
			textareaRef.current?.focus();
			const textWithBreaks = comment.text.replace(/\\n/g, "\n");
			setText(textWithBreaks);
			customUpdate?.(textWithBreaks);
		} else {
			setText(bufferedText);
			customUpdate?.(bufferedText);
			setBufferedText("");
		}
	}, [comment.commentId, comment.text]);

	const handleSetText = useCallback(
		(value: string) => {
			setText(value);
			customUpdate?.(value);
		},
		[comment]
	);

	const backToComment = useCallback(() => {
		modalController.modalRemove(idModal);
	}, []);

	const cancelEdit = useCallback(() => {
		backToComment();
		handleSetText("");
		updateCommentIdAndText({ comment: { text: "" } });
	}, []);

	const confirm = useMemo(() => {
		return (
			<div className={styles.confirm}>
				<div className={styles.confirmHeader}>
					<span className={styles.confirmTitle}>Внимание</span>
					<div onClick={backToComment}>
						<Icon name="Close" size="medium" />
					</div>
				</div>
				<div className={styles.confirmDialogBody}>
					<span className={styles.confirmText}>{`Есть не добавленный комментарий.`}</span>
				</div>
				<div className={styles.confirmFooter}>
					<Button
						text="Отменить редактирование"
						size="small"
						variant="default"
						border
						link={false}
						loading={false}
						onClick={cancelEdit}
					/>
					<Button
						text="Вернуться"
						size="small"
						variant="primary"
						border={false}
						link={false}
						loading={false}
						onClick={backToComment}
					/>
				</div>
			</div>
		);
	}, [backToComment, cancelEdit]);

	const openConfirm = useCallback(() => {
		modalController.popupAdd({ id: idModal, layout: confirm, closeFunc: cancelEdit });
	}, [idModal, confirm]);

	const handleClickCancel = useCallback(
		(event?: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
			event?.stopPropagation();
			if (comment.commentId) {
				const textWithoutLineBreaks = text.replace(/(?:\r\n|\r|\n)/g, "\\n");
				if (textWithoutLineBreaks !== comment.text) {
					openConfirm();
					return;
				}
			} else {
				const textWithoutSpaceLength = text.replace(/\s/g, "").length;
				if (text.length !== 0 && textWithoutSpaceLength !== 0) {
					openConfirm();
					return;
				}
			}

			updateCommentIdAndText({ comment: { text: "" } });
			if (bufferedText.length > 0) {
				return;
			}
			textareaRef.current?.blur();
			setInputInFocus(false);
		},
		[comment, bufferedText, text]
	);

	const handleClickAddComment = useCallback(
		(event?: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
			const textWithoutLineBreaks = text.replace(/(?:\r\n|\r|\n)/g, "\\n");
			const textWithoutSpaceLength = text.replace(/\s/g, "").length;
			event?.stopPropagation();
			if (text.length !== 0 && textWithoutSpaceLength !== 0 && textWithoutLineBreaks !== comment.text) {
				if (comment.commentId) {
					synchroiser.editComments(comment.commentId, textWithoutLineBreaks);
				} else {
					synchroiser.addComments(textWithoutLineBreaks);
				}
			}
			handleSetText("");
			updateCommentIdAndText({ comment: { text: "" } });
			if (bufferedText.length > 0) {
				return;
			}
			if (textareaRef.current) {
				textareaRef.current.style.height = "0px";
				textareaRef.current.blur();
			}
			setInputInFocus(false);
		},
		[text, comment.commentId, bufferedText, textareaRef.current, handleClickCancel]
	);

	const keydown = useCallback(
		(event: React.KeyboardEvent<HTMLDivElement>) => {
			if (!isInputInFocus) {
				return;
			}
			if (event.key === "Enter" && !event.shiftKey) {
				handleClickAddComment();
				return;
			}
			if (event.key === "Escape") {
				event.stopPropagation();
				handleBlur();
				textareaRef.current?.blur();
				return;
			}
		},
		[text, isInputInFocus, handleClickAddComment]
	);

	function handleFocus() {
		setInputInFocus(true);
	}

	function handleBlur() {
		setInputInFocus(false);
	}

	//Для предотвращения onBlur у textarea
	const handleMouseDown = useCallback(
		(event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
			event.preventDefault();
		},
		[textareaRef.current]
	);

	return (
		<div className={commentInputWrapperClassNames}>
			{comment.commentId && <EditableCommentBlock />}
			<div className={styles.commentInputContainer}>
				<div>
					<Avatar name={store.user.userName ?? ""} size="large" />
				</div>
				<div className={styles.commentInputBody} onKeyDown={keydown}>
					<Field
						type="multiline"
						size="small"
						value={text}
						textVariant="outlined"
						placeholder={isNew ? "Комментарий можно добавить после сохранения записи" : "Написать комментарий..."}
						onChange={handleSetText}
						onBlur={handleBlur}
						onFocus={handleFocus}
						multilineRef={textareaRef}
						isDisabled={isNew}
					/>
					<div className={commentInputBodyFooterClassNames}>
						<div className={styles.commentInputButtons} onMouseDown={handleMouseDown}>
							<Button
								text="Добавить"
								size="small"
								variant="primary"
								link={false}
								loading={false}
								onClick={handleClickAddComment}
								border
							/>
							<Button
								text="Отменить"
								size="small"
								variant="default"
								link={false}
								loading={false}
								onClick={handleClickCancel}
								border
							/>
						</div>
						<span className={styles.commentInputAdvice}>Enter — добавить</span>
					</div>
				</div>
			</div>
		</div>
	);
});
