import { StandartItem, StandartItemGroup } from "sale-bridge-ui-kit/dist/components/button-dropdown/button-dropdown";

export const enum DropdownItemsId {
	Duplicate = "duplicate",
	ConvertToGroup = "convertToGroup",
	ConvertToCondition = "convertToCondition",
	Regroup = "regroup",
	Delete = "delete"
}

export const duplicateStandartItem: StandartItem = {
	id: DropdownItemsId.Duplicate,
	name: "Дублировать",
	icon: "Copy",
	iconSize: "small"
};

export const convertToGroupStandartItem: StandartItem = {
	id: DropdownItemsId.ConvertToGroup,
	name: "Преобразовать в группу",
	icon: "TextEditorRedo",
	iconSize: "16"
};

export const convertToConditionStandartItem: StandartItem = {
	id: DropdownItemsId.ConvertToCondition,
	name: "Преобразовать в условие",
	icon: "TextEditorUndo",
	iconSize: "16"
};

export const regroupStandartItem: StandartItem = {
	id: DropdownItemsId.Regroup,
	name: "Разгруппировать",
	icon: "TextEditorUndo",
	iconSize: "16"
};

export const deleteStandartItem: StandartItem = {
	id: DropdownItemsId.Delete,
	name: "Удалить",
	icon: "Trash",
	iconSize: "16"
};

export const initDropdownData: StandartItemGroup[] = [
	{
		header: "",
		items: [duplicateStandartItem]
	},
	{
		header: "",
		items: [deleteStandartItem]
	}
];
