import React, { useCallback, useMemo } from 'react'
import { Button } from 'sale-bridge-ui-kit'

import styles from './action-panel-buttons.module.scss'

interface SaveCancelButtonsProps {
	handleSave: () => void
	handleCancel: () => void
	hasChanges: boolean
}

export const SaveCancelButtons: React.FC<SaveCancelButtonsProps> = ({
	handleSave,
	handleCancel,
	hasChanges,
}) => {

	return (
		<div className={styles.buttonContainer}>
			<Button
				text='Сохранить запись'
				size='small'
				variant={hasChanges ? 'primary' : 'disabled'}
				border
				leftIcon='Save'
				link={false}
				loading={false}
				onClick={() => {
					if (hasChanges) {
						handleSave();
					}
				}}
			/>
			<Button
				text='Отменить изменения'
				size='small'
				variant={hasChanges ? 'default' : 'disabled'}
				border
				leftIcon='Undo'
				link={false}
				loading={false}
				onClick={handleCancel}
			/>
		</div>
	)
}
