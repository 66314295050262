import { action, toJS } from "mobx";

import { dispatcher, store } from "store";

import { getNeedColumns } from "./get-need-columns";
import { getRecordsListByStageName } from "./get-records-list-by-stage-name";

import { FilterStore } from "entities/filter/FilterStore";
import IFilter from "entities/filter/IFilter";
import { lowerFirst } from "lodash";


export async function getRecordsListForOneStage(fieldStageId: string, stageId: string) {
    const currentEntityName = store.sections.find((section: any) => section.id === store.currentEntityId)?.entityName;
    const sort = dispatcher.entity.get()?.entity?.sort;
    const staticGroupId = dispatcher.entity.get()?.entity.filter?.staticGroup?.id;

    if (!dispatcher.entity.get()?.entity) {
        return null;
    }
    const filter = new FilterStore(currentEntityName!, dispatcher.entity.get()?.entity.filter?.savedFilter?.filterInfo as IFilter ?? null, null);

    const allGridItems = dispatcher.sectionWizzard.getAllGridItems();
    const stageFieldsName = allGridItems.find((gridItem) => gridItem.fieldConfig?.specializations?.properties.stageField)?.fieldConfig?.columnName ?? null;

    if (dispatcher.entity.get()?.entity.rows) {
        dispatcher.entity.get()!.entity.rows = dispatcher.entity.get()?.entity.rows.filter((record) => record[lowerFirst(stageFieldsName!)].id !== fieldStageId)
            ?? dispatcher.entity.get()!.entity.rows;
    }

    const needColumns = getNeedColumns();
    getRecordsListByStageName(needColumns ?? [], currentEntityName, filter, stageFieldsName, fieldStageId, sort, staticGroupId)
        .then((req: any) => {
            if (req?.data?.success) {
                action("add rows for kanban", () => {                    
                    if (dispatcher.entity.get()?.entity.rows) {
                        dispatcher.entity.get()!.entity.rows = [...dispatcher.entity.get()!.entity.rows, ...req.data.data.records.map((i: any) => i)];
                    }
                })();
            }
        });
}