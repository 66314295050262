import { action } from "mobx";
import { lowerFirst } from "lodash";

import { dispatcher } from "store";

import { SYS_POSITION } from "../../../../../../constants";

export function recalculatePositionFromStage(stageId: string, indexRecord: number) {
    const allGridItems = dispatcher.sectionWizzard.getAllGridItems();
    const stageFieldsName = allGridItems.find((gridItem) => gridItem.fieldConfig?.specializations?.properties.stageField)?.fieldConfig?.columnName;

    action(() => {
        const recordsStoreProxy = dispatcher.entity.get();
        if (recordsStoreProxy) {
            recordsStoreProxy.entity.rows = [
                ...(dispatcher.entity.get()?.entity.rows
                .filter((record) => record[lowerFirst(stageFieldsName)].id === stageId)
                .sort((aCard, bCard) => {
                    if (aCard[SYS_POSITION] > bCard[SYS_POSITION]) {
                        return 1;
                    } else if (aCard[SYS_POSITION] < bCard[SYS_POSITION]) {
                        return -1;
                    } else {
                        return 0;
                    }
                })
                .map((record, index) => {
                    if (index <= indexRecord) {
                        record[SYS_POSITION] = index + 1;
                    } else {
                        record[SYS_POSITION] = index;
                    }
                    return record;
                }) ?? []),

                ...(dispatcher.entity.get()?.entity.rows
                    .filter((record) => record[lowerFirst(stageFieldsName)].id !== stageId) ?? [])
            ]
        }
    })();
}


export function recalculatePositionOneStage(stageId: string, indexNewRecord: number, recordMovement: any) {
    const allGridItems = dispatcher.sectionWizzard.getAllGridItems();
    const stageFieldsName = allGridItems.find((gridItem) => gridItem.fieldConfig?.specializations?.properties.stageField)?.fieldConfig?.columnName;

    let directionFlag = false;
    const oldSysPosition = recordMovement[SYS_POSITION]
    if (oldSysPosition > indexNewRecord) {
        directionFlag = true;
    }

    action(() => {
        const recordsStoreProxy = dispatcher.entity.get();
        if (recordsStoreProxy) {
            recordsStoreProxy.entity.rows = [
                ...(dispatcher.entity.get()?.entity.rows
                .filter((record) => record[lowerFirst(stageFieldsName)].id === stageId)
                .sort((aCard, bCard) => {
                    if (aCard[SYS_POSITION] > bCard[SYS_POSITION]) {
                        return 1;
                    } else if (aCard[SYS_POSITION] < bCard[SYS_POSITION]) {
                        return -1;
                    } else {
                        return 0;
                    }
                })
                .map((record, index) => {
                    if(!directionFlag) {
                        if (index >= oldSysPosition && index <= indexNewRecord) {
                            record[SYS_POSITION] = index - 1;
                        } else {
                            record[SYS_POSITION] = index;
                        }
                        return record;
                    }
                    else {
                        if (index <= oldSysPosition && index >= indexNewRecord) {
                            record[SYS_POSITION] = index + 1;
                        } else {
                            record[SYS_POSITION] = index;
                        }
                        return record;
                    }
                }) ?? []),

                ...(dispatcher.entity.get()?.entity.rows
                    .filter((record) => record[lowerFirst(stageFieldsName)].id !== stageId) ?? [])
            ]
        }
    })();
}
