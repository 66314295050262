import { observer } from "mobx-react-lite";
import classNames from "classnames";

import styles from './card-designer-block.module.scss'


export const CardDesignerBlock = observer((props: {
    name: string,
    children: JSX.Element | Array<JSX.Element>,
    className?:string
}) => {

    const wrapperClassName = classNames(styles.wrapper, {
        [`${props.className}`]: props.className,
    });

    return (
        <div className={wrapperClassName}>
            <span>{props.name}</span>
                {props.children}
        </div>
    );
});
