import { store } from "store/store";
import { dispatcher } from "store/store-dipatcher/dispatcher";

export class FullViewController {

    switchEntityAndRow = (entityName:string, id:string) => {
        if (store.entities.find((entity: any) => entity.entityName.toLowerCase() === entityName.toLowerCase())) {
            dispatcher.entity.switchEntityByName(entityName);
            if (dispatcher.entity.get()?.entity?.rows.find((row: any) => row.id === id)) {
                dispatcher.currentRow.switch(id);
            }
        }
    }
}

export const fullViewController = new FullViewController();