import { validateRequired, validateSchema } from "entities/Validation";
import { makeAutoObservable } from "mobx";
import { ERROR_TAB_EXIST_NAME, ERROR_TAB_EXIST_TITLE, ValidationState } from "pages/section-wizzard/data/data";

import { dispatcher } from "store";

import { FieldValidationState } from "types";
import { TabsConfig } from "types/entity";

export enum FieldNameFromStore {
    name = 'name',
    systemName = 'systemName',
    isDidabledSaveButton = 'isDidabledSaveButton'
}

class NewTabConfigurationStore {
    name: string = '';
    systemName: string = '';

    isDidabledSaveButton: boolean = true;

    validation: { [key: string]: ValidationState };

    constructor() {
        makeAutoObservable(this);

        this.validation = {
            name: {
                isInvalid: false,
                isNotUnique: false,
                error: '',
            },
            systemName: {
                isInvalid: false,
                error: '',
            },
        };
    }

    setValue(value: string, fieldName: string) {
        Reflect.set(this, fieldName, value);
    }

    createNewTabInMainPanel(index: number) {
        dispatcher.sectionWizzard.createNewTab(this.systemName, this.name, index);
    }

    validateTitle() {
        const isNotUnique = dispatcher.sectionWizzard.checkExistTabTitle(this.name);
        this.validation.name.isNotUnique = isNotUnique;
        if (isNotUnique) {
            this.validation.name.isInvalid = false;
            this.validation.name.error = ERROR_TAB_EXIST_TITLE;
        }
        else {
            validateRequired(this.name, this.validation.name);
        }
    }

    validateSystemName() {
        const isNotUnique = dispatcher.sectionWizzard.checkExistTabName(this.systemName);
        if (isNotUnique) {
            this.validation.systemName.isInvalid = isNotUnique;
            this.validation.systemName.error = ERROR_TAB_EXIST_NAME;
        }
        else if (validateSchema(this.systemName, this.validation.systemName)) {
            this.validation.systemName.isInvalid = false;
            this.validation.systemName.error = "";
        }
    }

    validTabConfig() {
        let nameValid = false;
        let systemName = false;
        if (!this.validation.name.isInvalid && this.name.length > 0) {
            nameValid = true
        }
        if (!this.validation.systemName.isInvalid && this.systemName.length > 0) {
            systemName = true
        }

        if (nameValid && systemName) {
            this.isDidabledSaveButton = false;
        }
        else {
            this.isDidabledSaveButton = true;
        }
    }


    resetConfiguration() {
        this.name = '';
        this.systemName = '';
        this.isDidabledSaveButton = true;

        for (const key in this.validation) {
            this.validation[key].isInvalid = false;
            this.validation[key].error = '';
        }
    }
}

const newTabConfigurationStore = new NewTabConfigurationStore();

export default newTabConfigurationStore;
