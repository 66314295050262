import { StandartItem } from "sale-bridge-ui-kit/dist/components/button-dropdown/button-dropdown";
import { QuickActionType, QuickActionTypeName } from "types/entity";

export const copyLinkAction: StandartItem =
{
    id: 'link',
    name: QuickActionTypeName[QuickActionType.CopyLink].title,
    icon: 'Link',
    iconSize: 'small'
}
export const copyEntityAction: StandartItem =
{
    id: 'copy',
    name: 'Дублировать',
    icon: 'Copy',
    iconSize: 'small',
    isDisable: true
}
export const logTimeAction: StandartItem =
{
    id: 'logTime',
    name: 'Залогировать время...',
    icon: 'Clock',
    iconSize: '16'
}
export const startStopwatchAction: StandartItem =
{
    id: 'startStopwatch',
    name: QuickActionTypeName[QuickActionType.Stopwatch].title,
    icon: 'Stopwatch',
    iconSize: '16'
}
export const stopStopwatchAction: StandartItem =
{
    id: 'stopStopwatch',
    name: 'Остановить и залогировать...',
    icon: 'StopCircleFill',
    iconSize: '16'
}
export const pauseStopwatchAction: StandartItem =
{
    id: 'pauseStopwatch',
    name: 'Поставить на паузу',
    icon: 'PauseCircleFill',
    iconSize: '16'
}
export const resumeStopwatchAction: StandartItem =
{
    id: 'startStopwatch',
    name: 'Возобновить',
    icon: 'PlayCircleFill',
    iconSize: '16'
}
export const deleteEntityAction: StandartItem =
{
    id: 'delete',
    name: 'Удалить',
    icon: 'Trash',
    iconSize: '16',
    isDisable: true,
    isRed: true,
}