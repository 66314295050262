import { type MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { action } from 'mobx'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'

import { DirectionRelativeElement, store } from '../../model/store'
import { calculateTargetElement } from './lib'

import { type OnDrop } from '../../HOC/HOC'

import s from './style.module.css'

interface Props {
    children: JSX.Element
    id: string
    movementElement: JSX.Element
    placeholder: JSX.Element
    predict?: JSX.Element
    predictWidth?: number
    zoneId: string
    onDrop: OnDrop
    onUp?: (value: string) => void
}

const LAZYING_UP = 5

export const HorizontalColumnElement = observer((props: Props) => {
    const [lazyUpCount, setLazyUpCount] = useState(0)
    const [waitMove, setWaitMove] = useState(false)
    const [canMove, setCanMove] = useState(false)
    const [elementMouseShifting, setElementMouseShifting] = useState({ x: 0, y: 0 })

    const [dragElementPosition, setDragElementPosition] = useState<{ x: number, y: number } | null>(null)

    const dragElement = useRef<HTMLDivElement>(null)

    const globalMove = useCallback((event: any) => {
        const targetZone = store.getZone({ x: event.pageX, y: event.pageY })

        if (targetZone && store.zones[targetZone]?.elements && store._canMove) {
            action('set target element id', () => store._targetElementId = calculateTargetElement({
                x: event.pageX,
                shifting: elementMouseShifting.x,
                upendElementId: props.id,
                elements: store.zones[targetZone ?? ""]?.elements
            }))()

            if (store._targetElementId !== null) {
                store.setTargetCell(store._targetElementId)
            }
        }
        setDragElementPosition({ x: event.pageX, y: event.pageY })
    }, [props.id, elementMouseShifting.x])

    /**
     * @description Наблюдение за позицией и размерами элемента
     */
    useEffect(() => {
        if (store._targetElementId) {
            return;
        }
        const elementSize = dragElement.current?.getBoundingClientRect()
        if (elementSize && store.zones[props.zoneId]?.elements[props.id]?.pagePosition) {
            action("set element page position", () => {
                if (elementSize.width) {
                    store.zones[props.zoneId].elements[props.id].pagePosition = {
                        x: elementSize.x,
                        y: elementSize.y,
                        width: elementSize.width,
                        height: elementSize.height
                    }
                }
            })();
        }
    }, [props.id, props.zoneId, dragElement.current?.getBoundingClientRect()])

    const globalMouseUp = useCallback((event: any) => {
        const targetZone = store.getZone({ x: event.pageX, y: event.pageY });
        action(() => store._targetElementId = null)()

        const storeElements = store._zones[targetZone ?? ""]?.elements;

        if (storeElements) {
            const targetElement = storeElements[calculateTargetElement({
                x: event.pageX,
                shifting: elementMouseShifting.x,
                upendElementId: props.id,
                elements: store.zones[targetZone ?? ""]?.elements
            }) ?? ""]?.position?.x + (store._directionRelativeElement === DirectionRelativeElement.RIGHT ? 1 : 0) as number;

            props.onDrop({
                zoneId: targetZone ?? "",
                currentElementIndex: store._zones[props.zoneId].elements[props.id]?.position?.x,
                targetElementIndex: store._movingDirection === DirectionRelativeElement.RIGHT ? targetElement - 1 : targetElement,
            })
        }

        setCanMove(false)
        setElementMouseShifting({ x: 0, y: 0 })
        setWaitMove(false)
        setLazyUpCount(0)
        setDragElementPosition(null)
        action('set target element', () => {
            store._targetElementId = null
        })()
        action('set can move', () => {
            store._canMove = false
        })()
    }, [props, store._zones[props.zoneId]?.elements, elementMouseShifting.x])

    useEffect(() => {
        if (canMove) {
            window.removeEventListener('mousemove', globalMove)
            window.removeEventListener('mouseup', globalMouseUp)
            window.addEventListener('mousemove', globalMove)
            window.addEventListener('mouseup', globalMouseUp)
            action(() => store._targetElementId = "test")()
        } else {
            window.removeEventListener('mousemove', globalMove)
            window.removeEventListener('mouseup', globalMouseUp)
        }
        return () => {
            window.removeEventListener('mousemove', globalMove)
            window.removeEventListener('mouseup', globalMouseUp)
        }
    }, [canMove, waitMove, globalMouseUp, globalMove])

    const handleMouseUp = useCallback(() => {
        setCanMove(false)
        setElementMouseShifting({ x: 0, y: 0 })
        setWaitMove(false)
        setLazyUpCount(0)
        action('set can move false', () => {
            store._canMove = false
        })()
        return null
    }, [])

    const handleMouseDown = useCallback(() => {
        setWaitMove(true)
        setLazyUpCount(0)
        props?.onUp?.(props.id)
    }, [props])

    const handleMoseMove = useCallback((event: MouseEvent) => {
        event.preventDefault()
        if (waitMove) {
            if (lazyUpCount >= LAZYING_UP) {
                setCanMove(true)
                action('set can move true', () => {
                    store._canMove = true
                })()
            } else {
                setLazyUpCount(Math.abs(lazyUpCount) + (Math.abs(event.movementX) + Math.abs(event.movementY)))
                const elementSize = dragElement.current?.getBoundingClientRect()

                setElementMouseShifting({
                    x: event.pageX - (elementSize?.x ?? 0),
                    y: event.pageY - (elementSize?.y ?? 0)
                })
            }
        }
        return null
    }, [lazyUpCount, waitMove])

    const predictLeft = useMemo(() => store._targetElementId && (store._targetElementId === props.id) && props.predict, [store._targetElementId, props.id, props.predict])
    const predictRight = useMemo(() => store._targetElementId && (store._targetElementId === props.id) && props.predict, [store._targetElementId, props.id, props.predict])

    const classnames = useMemo(() =>
        classNames(s.dragElement, {
            [s.draggingElement]: waitMove,
            [s.draggingElementPosition]: canMove
        }), [canMove, waitMove])

    return (
        <>
            {store._canMove && store._directionRelativeElement === DirectionRelativeElement.LEFT && predictLeft}
            {dragElementPosition &&
                <>
                    <div
                        style={dragElementPosition
                            ? {
                                top: dragElementPosition.y - elementMouseShifting.y,
                                left: dragElementPosition.x - elementMouseShifting.x,
                                position: 'fixed',
                                display: 'inline-flex',
                                zIndex: 2
                            }
                            : {}}
                    >
                        {props.movementElement}
                    </div>
                    <div>
                        {props.placeholder}
                    </div>
                </>
            }
            <div style={{ display: 'inline-flex' }}>
                <div
                    style={{
                        display: dragElementPosition ? 'none' : 'block'
                    }}
                    ref={dragElement}
                    className={classnames}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onMouseMove={handleMoseMove}
                >
                    {props.children}
                </div>
            </div>
            {store._canMove && (store._directionRelativeElement === DirectionRelativeElement.RIGHT) && predictRight}
        </>
    )
})
