import { useCallback, useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";

import TreeView from "features/tree-view/tree-view";
import { Toggle, DataGrid, Button, ButtonStyle, InputSearch, Select } from "components";
import NavigationTitle from "../NavigationTitle";
import { userStore } from "entities/user/UsersSectionStore";
import { rootUserRoles } from "entities/userRole/RootUserRoles";

import { CloseMaxi, TriangleToDown } from "shared";

import baseStyles from "./AccessRights.module.css";
import styles from "./AccessRightsLink.module.css";
import accessRightLink, { RightsItems, rightsItemsIds } from "entities/accessRights/AccessRightLinkStore";

const AccessRightsLink = observer(function (props: { entityId: string; title: string | undefined }) {
	useEffect(() => {
		accessRightLink.onSelectRole(RightsItems.filter((item) => item.id === rightsItemsIds.Users)[0]);
	}, []);

	useEffect(() => {
		accessRightLink.getOperationRights(props.entityId);
		accessRightLink.getRecordRights(props.entityId);
	}, [props.entityId]);

	useEffect(() => {
		accessRightLink.getCheckRecord(props.entityId);
	}, [accessRightLink.checkRecord]);

	useEffect(() => {
		accessRightLink.getCheckOperation(props.entityId);
	}, [accessRightLink.checkOperation]);

	const onCheck = useCallback(
		(value: boolean) => {
			accessRightLink.checkByOperation(value, props.entityId);
		},
		[props.entityId]
	);

	const onAdd = useCallback(() => {
		accessRightLink.activeModalEnable(true);
	}, [props.entityId]);

	return (
		<div className={styles.systemMainPanel}>
			<NavigationTitle nameLookup={`Права доступа на объект [${props.title}]`} />
			<div className={styles.wrapper}>
				<div className={baseStyles.sectionTitle}>{`Права доступа на объект [${props.title}]`}</div>
				<div className={styles.operationBlock}>
					<div className={styles.toogleHeader}>
						<Toggle checked={accessRightLink.checkOperation} onCheck={onCheck} />
						<span>Использовать доступ по операциям</span>
					</div>
					<div className={styles.grid}>
						<DataGrid
							maxHeight={`calc(100vh - 260px)`}
							classNameHeader={styles.header}
							columns={accessRightLink.ColumnsOperation}
							otherData={accessRightLink.dataGridOperation}
						/>
					</div>
					<Button className={styles.addButton} onClick={onAdd} caption={"Добавить"} />
				</div>
				<div className={styles.recordBlock}>
					<div className={styles.toogleHeader}>
						<Toggle
							checked={accessRightLink.checkRecord}
							onCheck={(val) => accessRightLink.checkByRecords(val, props.entityId)}
						/>
						<span>Использовать доступ по записям</span>
					</div>
					<span className={styles.recordTitle}>Раздача прав в зависимости от автора записи</span>
					<div className={styles.grid}>
						<DataGrid
							classNameHeader={styles.header}
							columns={accessRightLink.ColumnsRecord}
							otherData={accessRightLink.dataGridRecord}
						/>
					</div>
					<Button className={styles.addButton} onClick={() => accessRightLink.openModalRecord(true)} caption={"Добавить"} />
				</div>
			</div>
			<RolesAndUsersDialog active={accessRightLink.isActiveModal} entityId={props.entityId} />
			<RightsDialog entityId={props.entityId} activeRecord={accessRightLink.modalRecordActive} />
		</div>
	);
});

const RolesAndUsersDialog = observer(function (props: { active: any; entityId: string }) {
	useEffect(() => {
		rootUserRoles.loadByType(accessRightLink.userRoleType);
	}, [accessRightLink.valueSelectRole]);

	useEffect(() => {
		userStore.load(null);
	}, []);

	const onUserClick = useCallback((user: any) => {
		accessRightLink.handleValueClick(user);
	}, []);

	const users = useMemo(() => {
		return accessRightLink.userNameList.map((user, index) => {
			const userItemClassName = classNames({
				[`${styles.userList}`]: true,
				[`${styles.userListSelected}`]: accessRightLink.selectedUserId === user.id
			});
			return (
				<div key={index} className={userItemClassName} onClick={() => onUserClick(user)}>
					<span>{user.name}</span>
				</div>
			);
		});
	}, [accessRightLink.userNameList, accessRightLink.selected]);

	const close = useCallback(() => {
		accessRightLink.activeModalEnable(false);
	}, []);
	const onChangeValue = useCallback((value: any) => {
		accessRightLink.onSelectRole(value);
	}, []);
	const onRoleOrUserClick = useCallback((user: any) => {
		accessRightLink.handleSelectUserRole(user);
		accessRightLink.onClick(user);
	}, []);
	const cancel = useCallback(() => {
		accessRightLink.activeModalEnable(false);
	}, []);
	const next = useCallback(() => {
		accessRightLink.createOperation(props.entityId);
	}, [props.entityId]);

	return (
		props.active && (
			<div className={styles.modal}>
				<div className={styles.content}>
					<div className={styles.headerModal}>
						<div className={styles.text}>Выбрать роль и пользователя</div>
						<Button onClick={close} style={ButtonStyle.Icon} className={styles.buttonClose} firstIcon={<CloseMaxi />} />
					</div>
					<div className={styles.searchWrapper}>
						<InputSearch
							classNameInput={styles.search}
							className={styles.search}
							value={null}
							placeholder={"Поиск"}
							onChangeValue={(value: string) => {}}
						/>
						<Select
							name={accessRightLink.valueSelectRole}
							secondIcon={<TriangleToDown />}
							styles={ButtonStyle.IconIndigo}
							isRotateSecondIcon={true}
							onChangeValue={onChangeValue}
							classNameButton={baseStyles.moreButton}
							classNameOpenList={baseStyles.moreButtonOpened}
							items={RightsItems}
						/>
					</div>
					<div className={styles.divider}></div>
					<div className={styles.treeWrapper}>
						{!accessRightLink.userGrid ? (
							<TreeView
								type={accessRightLink.userRoleType}
								isOpen={true}
								onClick={onRoleOrUserClick}
								isHeader={false}
								choosingItem={accessRightLink.userRole}
								isActionsVisible={false}
							/>
						) : (
							<>{users}</>
						)}
					</div>
					<div className={styles.closeButton}>
						<Button className={styles.cancel} caption={"Отменить"} onClick={cancel} />
						<Button className={styles.choose} caption={"Выбрать"} onClick={next} />
					</div>
				</div>
			</div>
		)
	);
});

const RightsDialog = observer(function (props: { entityId: string; activeRecord: any }) {
	const close = useCallback(() => {
		accessRightLink.openModalRecord(false);
	}, []);

	const onAuthorClick = useCallback(() => {
		accessRightLink.activeModalEnable(true);
		accessRightLink.openModalAuthor(true);
	}, []);
	const onRecipientClick = useCallback(() => {
		accessRightLink.activeModalEnable(true);
		accessRightLink.openModalReciever(true);
	}, []);
	const cancel = useCallback(() => {
		accessRightLink.openModalRecord(false);
	}, []);
	const next = useCallback(() => {
		accessRightLink.createRecord(props.entityId);
	}, [props.entityId]);
	return (
		props.activeRecord && (
			<div className={styles.modalRight}>
				<div className={styles.contentRights}>
					<div className={styles.headerModal}>
						<div className={styles.text}>Добавить права</div>
						<Button onClick={close} style={ButtonStyle.Icon} className={styles.buttonClose} firstIcon={<CloseMaxi />} />
					</div>
					<div className={styles.recordAuthorBlock}>
						<div className={styles.label}>Автор записи</div>
						<input
							placeholder="Выберите значение..."
							className={styles.input}
							value={(accessRightLink.selectedValueAuthor as string) ?? ""}
							onClick={onAuthorClick}
						/>
					</div>
					<div className={styles.recipientBlock}>
						<div className={styles.label}>Получатель прав</div>
						<input
							placeholder="Выберите значение..."
							className={styles.input}
							value={(accessRightLink.selectedValueReciev as string) ?? ""}
							onClick={onRecipientClick}
						/>
					</div>
					<div className={styles.closeButton}>
						<Button className={styles.cancel} caption={"Отменить"} onClick={cancel} />
						<Button className={styles.choose} caption={"Выбрать"} onClick={next} />
					</div>
				</div>
			</div>
		)
	);
});

export default AccessRightsLink;
