import authStore from "AuthStore";

const REFRESH_TOKEN_LIFE_TIME_IN_MINUTES = 1;
let timeoutId: NodeJS.Timeout;
export const setAutoLogoutTimeout = (expiresDate: string) => {
	let date = new Date(expiresDate);
	const expireInmilliseconds = date.getTime();
	const currentTime = Date.now();
	const expiresInMinutes = Math.ceil((expireInmilliseconds - currentTime) / (1000 * 60)) + REFRESH_TOKEN_LIFE_TIME_IN_MINUTES;

	console.info(`Вылетим из системы через ${expiresInMinutes} мин.`);
	const timeout = expiresInMinutes * 60 * 1000; // expiresInMinutes
	clearTimeout(timeoutId);
	timeoutId = setTimeout(() => {
		authStore.logOut();
	}, timeout);
};
