import { makeAutoObservable } from "mobx";
import { v4 } from "uuid";
import { isEmpty } from "lodash";

import { api } from "shared";
import { synchroiser } from "synchroiser";
import { validateRequired, validateSchema } from "entities/Validation";

import { ILookup } from "entities/Entity";
import { ILookupData } from "pages/settings/data/Fields";
import { ColumnType } from "entities/ColumnType";
import {
	ColumnSpecializationType,
	EntityColumnSpecialization,
	EntityNameType,
	FieldConfig,
	GridItem,
	ItemType,
	Properties
} from "types/entity";
import {
	DEFAULT_ERROR_VALUE,
	ERROR_LOOKUP_EXIST_NAME,
	ERROR_LOOKUP_EXIST_TITLE,
	ERROR_VALUE_EXIST_NAME,
	HIDE_ELEMENT_POSITION,
	ERROR_VALUE_EXIST_TITLE,
	LookupType,
	ValidationState
} from "pages/section-wizzard/data/data";
import IFilter from "entities/filter/IFilter";
import { VirtualLookup } from "pages/section-wizzard/pages/constructor/configurations/field-configuration/types";

export enum FieldStoreEnums {
	columnId = "columnId",
	fieldType = "fieldType",
	defaultValue = "defaultValue",
	systemName = "systemName",
	title = "title",
	hasIndex = "hasIndex",
	isRequired = "isRequired",
	isSetDefaultData = "isSetDefaultData",
	prompt = "prompt",
	rounding = "rounding",
	lookupType = "lookupType",
	selectedLookupDefaultValue = "selectedLookupDefaultValue",
	selectedLookup = "selectedLookup",
	specializations = "specializations",
	uneditable = "uneditable",
	virtualLookupValues = "virtualLookupValues"
}
const initialVirtualLookup = {
	entityTitle: "",
	systemName: "",
	columnInfo: [
		{
			columnId: v4(),
			columnName: "Name",
			columnTitle: "Название",
			columnType: ColumnType.String,
			isLookup: false,
			isLink: false,
			lookupTable: null,
			isRequired: true,
			uneditable: false,
			hasIndex: false,
			specializations: null
		}
	],
	isLookup: true,
	virtualLookupValues: []
};
class DetailFieldConfigurationPopupState {
	fieldIsLoading: boolean = false;
	hasChanges: boolean = false;

	columnId: string = "";
	fieldType: ColumnType = ColumnType.String;
	defaultValue: any = "";

	x: number = HIDE_ELEMENT_POSITION;
	y: number = HIDE_ELEMENT_POSITION;

	systemName: string = "";
	title: string = "";
	hasIndex: boolean = false;
	isRequired: boolean = false;
	uneditable: boolean = false;
	isViewColumn: boolean = false;
	isSetDefaultData: boolean = false;
	prompt: string = "";
	specializations: EntityColumnSpecialization = { tag: ColumnSpecializationType.Double, properties: {} };

	rounding: string = "";

	validation: { [key: string]: ValidationState };

	lookupType: LookupType | string = "";

	//Настройки выпадающих списоков
	lookups: ILookupData[] = [];
	selectedLookup: ILookupData | null = null;

	selectedLookupData: ILookup[] = [];
	selectedLookupDefaultValue: ILookup | null = null;

	//Виртуальные справочники
	virtualLookup: VirtualLookup;

	constructor() {
		makeAutoObservable(this);
		this.virtualLookup = initialVirtualLookup;
		this.validation = {
			title: {
				isInvalid: false,
				isNotUnique: false,
				error: ""
			},
			systemName: {
				isInvalid: false,
				error: ""
			},

			lookup: {
				isInvalid: false,
				error: ""
			},
			virtualLookupSystemName: {
				isInvalid: false,
				error: ""
			},
			virtualLookupTitle: {
				isInvalid: false,
				isNotUnique: false,
				error: ""
			},

			defaultValue: {
				isInvalid: false,
				error: ""
			},
			rounding: {
				isInvalid: false,
				error: ""
			}
		};
	}

	getNewFieldConfiguration() {
		const fieldConfig: FieldConfig = {
			columnId: this.columnId,
			columnName: this.systemName,
			columnType: this.fieldType,
			columnTitle: this.title,
			defaultValue: this.defaultValue,
			rounding: this.rounding,
			prompt: this.prompt,
			specializations: this.specializations,
			virtualLookup: this.lookupType === LookupType.NewLookup ? this.virtualLookup : null,
			lookupTable: this.lookupType === LookupType.NewLookup ? this.virtualLookup.systemName : this.selectedLookup?.entityName!,
			isLookup: this.fieldType === ColumnType.Lookup,
			hasIndex: this.hasIndex,
			isRequired: this.isRequired,
			uneditable: this.uneditable,
			isViewColumn: this.isViewColumn
		};
		const gridItem: GridItem = {
			x: this.x,
			y: this.y,
			gridItemId: v4(),
			width: 1,
			height: 1,
			type: ItemType.Field,
			fieldConfig: fieldConfig
		};
		this.resetConfiguration();
		return gridItem;
	}

	setValue(fieldName: string, value: string | boolean | null | number | ILookup[]) {
		this.hasChanges = true;
		Reflect.set(this, fieldName, value);
	}

	setValueWithoutTrackingChanges(fieldName: string, value: string | boolean | null | number) {
		Reflect.set(this, fieldName, value);
	}

	setSpecialization(fieldName: string, tag: ColumnSpecializationType, properties: Properties) {
		this.hasChanges = true;
		Reflect.set(this, fieldName, { tag, properties });
	}

	isValidDate(date: string) {
		return !isNaN(Date.parse(date));
	}

	isValidGuidValue(value: string): boolean {
		const guidPattern = /^[{]?[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}[}]?$/;
		return guidPattern.test(value);
	}

	isConfigurationValid(): boolean {
		const isTitleValid = !this.validation.title.isInvalid && this.title.length !== 0;
		const isSystemNameValid = !this.validation.systemName.isInvalid && this.systemName.length !== 0;

		if (this.fieldType === ColumnType.Decimal) {
			const roundingValid = !this.validation.rounding.isInvalid && this.rounding.length !== 0;
			return isTitleValid && isSystemNameValid && this.validation.defaultValue && roundingValid;
		}

		if (this.fieldType === ColumnType.Lookup) {
			const lookupValid = !this.validation.lookup.isInvalid;

			if (this.lookupType === LookupType.ExistLookup) {
				return isTitleValid && isSystemNameValid && this.validation.defaultValue && lookupValid && this.selectedLookup !== null;
			} else if (this.lookupType === LookupType.NewLookup) {
				const virtualLookupValid =
					!this.validation.virtualLookupSystemName.isInvalid && !this.validation.virtualLookupTitle.isInvalid;
				return isTitleValid && isSystemNameValid && this.validation.defaultValue && virtualLookupValid;
			}
		}
		return isTitleValid && isSystemNameValid && !this.validation.defaultValue.isInvalid;
	}

	findExistField(param: "title" | "name", name: string, gridItems: GridItem[]) {
		if (param === "title") {
			return gridItems.find((item) => item.fieldConfig?.columnTitle === name) ? true : false;
		} else if (param === "name") {
			return gridItems.find((item) => item.fieldConfig?.columnName === name) ? true : false;
		}
		return false;
	}

	validateTitle(gridItems: GridItem[]) {
		const isNotUnique = this.findExistField("title", this.title, gridItems);
		this.validation.title.isNotUnique = isNotUnique;
		if (isNotUnique) {
			this.validation.title.isInvalid = false;
			this.validation.title.error = ERROR_VALUE_EXIST_TITLE;
		} else {
			validateRequired(this.title, this.validation.title);
		}
	}

	validateSystemName(gridItems: GridItem[]) {
		const isNotUnique = this.findExistField("name", this.systemName, gridItems);
		if (isNotUnique) {
			this.validation.systemName.isInvalid = isNotUnique;
			this.validation.systemName.error = ERROR_VALUE_EXIST_NAME;
		} else if (validateSchema(this.systemName, this.validation.systemName)) {
			this.validation.systemName.isInvalid = false;
			this.validation.systemName.error = "";
		}
	}

	async validateNewLookupTitle() {
		if (this.lookupType === LookupType.NewLookup) {
			const isNotUnique = await synchroiser.checkExistEntityTitle(this.virtualLookup.entityTitle, EntityNameType.Lookups);
			this.validation.virtualLookupTitle.isNotUnique = isNotUnique;
			if (isNotUnique) {
				this.validation.virtualLookupTitle.isInvalid = false;
				this.validation.virtualLookupTitle.error = ERROR_LOOKUP_EXIST_TITLE;
			} else {
				validateRequired(this.virtualLookup.entityTitle, this.validation.virtualLookupTitle);
			}
		} else {
			this.validation.virtualLookupTitle.isNotUnique = false;
			validateRequired(this.virtualLookup.entityTitle, this.validation.virtualLookupTitle);
		}
	}

	async validateNewLookupName() {
		if (this.lookupType === LookupType.NewLookup) {
			const isNotUnique = await synchroiser.checkExistEntityName(this.virtualLookup.systemName);
			if (isNotUnique) {
				this.validation.virtualLookupSystemName.isInvalid = isNotUnique;
				this.validation.virtualLookupSystemName.error = ERROR_LOOKUP_EXIST_NAME;
			} else if (validateSchema(this.virtualLookup.systemName, this.validation.virtualLookupSystemName)) {
				this.validation.systemName.isInvalid = false;
				this.validation.systemName.error = "";
			}
		} else {
			validateSchema(this.virtualLookup.systemName, this.validation.virtualLookupSystemName);
		}
	}

	setInvalidDefaultValue(value: boolean) {
		if (value) {
			this.validation.defaultValue.isInvalid = value;
			this.validation.defaultValue.error = DEFAULT_ERROR_VALUE;
		} else {
			this.validation.defaultValue.isInvalid = false;
			this.validation.defaultValue.error = "";
		}
	}

	validateDefaultValue() {
		switch (this.fieldType) {
			case ColumnType.String:
				if (typeof this.defaultValue !== "string") {
					this.setInvalidDefaultValue(true);
				} else {
					this.setInvalidDefaultValue(false);
				}
				break;
			case ColumnType.Integer:
				const isPureNumber = /^\d+$/.test(this.defaultValue);
				if (isPureNumber || isEmpty(this.defaultValue)) {
					this.setInvalidDefaultValue(false);
				} else {
					this.setInvalidDefaultValue(true);
				}
				break;
			case ColumnType.Decimal:
				const validDfaultValue = /^-?\d+(\.\d+)?$/.test(this.defaultValue) || this.defaultValue === "";
				if (validDfaultValue) {
					this.setInvalidDefaultValue(false);
				} else {
					this.setInvalidDefaultValue(true);
				}

				break;
			case ColumnType.Lookup:
				if (!this.isValidGuidValue(this.defaultValue)) {
					this.setInvalidDefaultValue(false);
				} else {
					this.setInvalidDefaultValue(true);
				}
				break;
			case ColumnType.DateTime:
				if (!this.isValidDate(this.defaultValue)) {
					this.setInvalidDefaultValue(false);
				} else {
					this.setInvalidDefaultValue(true);
				}
				break;
			case ColumnType.Boolean:
				if (this.defaultValue === "true" || this.defaultValue === "false" || this.defaultValue === "") {
					this.setInvalidDefaultValue(false);
				} else {
					this.setInvalidDefaultValue(true);
				}
				break;
			default:
				this.setInvalidDefaultValue(true);
		}
	}

	convertToLookupDataArray(dataArray: any): ILookup[] {
		return dataArray.map((dataItem: any) => ({
			id: dataItem.id,
			name: dataItem.name,
			title: dataItem.displayValue ?? dataItem.name
		}));
	}

	setLookups(data: ILookupData[]) {
		this.lookups = data;
	}

	/**
	 * Метод подгружает справочники и разделы для источника данных у справочного поля
	 */
	async loadLookups() {
		let req = await api.http.entity.entitySectionAndLookupDataList().get();
		this.setLookups(req?.data.data);
		return req?.data.data;
	}

	setLookupData(data: any) {
		let convertedData = this.convertToLookupDataArray(data);
		this.selectedLookupData = convertedData;
	}

	/**
	 * Метод подгружает значение справочника для того что отобразить в выпадающем списке
	 * @param lookupName Системное название справочника
	 */
	async loadLookupData(lookupName: string, filter: IFilter | null) {
		let req = await api.http.entity.recordsListWithColumns().post({
			entityName: lookupName,
			columnNames: [],
			filter: filter
		});
		this.setLookupData(req?.data.data.records);
		return req?.data.data.records;
	}

	async getConfigurationById(columnId: string, gridItems: GridItem[]) {
		this.fieldIsLoading = true;
		const column = gridItems.find((item) => item.fieldConfig?.columnId === columnId);

		if (column && column.fieldConfig) {
			this.columnId = columnId;
			this.systemName = column.fieldConfig.columnName ?? "";
			this.fieldType = column.fieldConfig.columnType as ColumnType;
			this.title = column.fieldConfig.columnTitle ?? "";
			this.hasIndex = column.fieldConfig.hasIndex ?? "";
			this.isRequired = column.fieldConfig.isRequired ?? "";
			this.defaultValue = column.fieldConfig.defaultValue;
			this.rounding = column.fieldConfig.rounding ?? "";
			this.prompt = column.fieldConfig.prompt ?? "";
			this.specializations = column.fieldConfig.specializations ?? { tag: ColumnSpecializationType.Double, properties: {} };

			if (this.fieldType === ColumnType.Lookup) {
				const isVirtualLookup = column.fieldConfig.virtualLookup !== null;

				await this.loadLookups();
				if (!isVirtualLookup) {
					await this.loadLookupData(column.fieldConfig?.lookupTable!, null);
				}

				this.selectedLookup = this.lookups.find((lookup) => lookup.entityName === column.fieldConfig?.lookupTable) ?? null;
				this.virtualLookup = column.fieldConfig.virtualLookup!;
				this.selectedLookupDefaultValue = this.getSelectedLookupData(isVirtualLookup);
				this.lookupType = isVirtualLookup ? LookupType.NewLookup : "";
			}
		}
		if (column) {
			this.hasChanges = false;
		}
		this.fieldIsLoading = false;
	}

	/**
	 * Метод предназначен для того чтобы получить значение по умолчанию для справочного поля буд то по существующему или по новому справочнику.
	 */
	getSelectedLookupData(isVirtualLookup: boolean): ILookup | null {
		const selectedData = isVirtualLookup
			? this.virtualLookup.virtualLookupValues.find((item) => item.id === this.defaultValue)
			: this.selectedLookupData.find((item) => item.id === this.defaultValue);
		return selectedData || null;
	}

	resetConfiguration() {
		this.hasChanges = false;
		this.fieldType = ColumnType.String;
		this.defaultValue = "";
		this.systemName = "";
		this.title = "";
		this.hasIndex = false;
		this.isRequired = false;
		this.isSetDefaultData = false;
		this.prompt = "";
		this.lookupType = "";
		this.rounding = "";

		this.validation.defaultValue.isInvalid = false;
		this.validation.defaultValue.error = "";

		this.selectedLookup = null;
		this.selectedLookupData = [];
		this.selectedLookupDefaultValue = null;

		this.virtualLookup = initialVirtualLookup;

		for (const key in this.validation) {
			this.validation[key].isInvalid = false;
			this.validation[key].isNotUnique = false;
			this.validation[key].error = "";
		}
	}
}

const detailFieldConfigurationPopupState = new DetailFieldConfigurationPopupState();

export default detailFieldConfigurationPopupState;
