interface SwithProps{
    children:any;
    activeName:string;
}

interface SwithItemProps{
    children:any;
    name:string;
}

export function Swith(props:SwithProps){
    const activeElement = props.children.find((c:any) => c.props.name === props.activeName);
    return(
        <>
            {activeElement}
        </>
    )
}

export function SwithItem(props:SwithItemProps){
    return(
        <>
            {props.children}
        </>
    )
}

