import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

import { dispatcher, store } from 'store';

import { Button } from 'sale-bridge-ui-kit';
import { FavoriteFilters } from '../favorite-filters';

import { AdvancedFilterConst } from 'features/section-head/data/constants';
import SavedFilter from 'entities/filter/SavedFilter';

import styles from '../single-page-head.module.scss';

export const SinglePageBottomLeftHead = observer(function (props: {
    size: 'small' | 'medium' | 'large',
    onAllClick: () => void,
    buttonRefs: React.MutableRefObject<(HTMLDivElement | null)[]>,
    visibleFilters: SavedFilter[]
}) {

    const entity = useMemo(() => {
        return dispatcher.entity.get()
    }, [dispatcher.entity.get()]);

    return (
        <div className={styles.leftBlock} >
            <div className={styles.block}>
                <Button
                    text='Все фильтры'
                    size={props.size}
                    variant={AdvancedFilterConst.isOpenAdvancedFilterTree ? 'secondary' : 'default'}
                    border
                    leftIcon={AdvancedFilterConst.isOpenAdvancedFilterTree ? 'Clear' : 'Folder'}
                    link={false}
                    loading={false}
                    onClick={props.onAllClick}
                />
            </div>
            <div className={styles.divider}></div>
            <div style={{ visibility: 'hidden', position: 'absolute', display: "flex" }}>
                {store.favoriteFilters?.map((favoriteFilter, i) => (
                    <div key={i} ref={(el) => (props.buttonRefs.current[i] = el)}>
                        <Button
                            text={favoriteFilter.filterName ?? ""}
                            size={props.size}
                            variant={entity?.entity.filter?.savedFilter?.id === favoriteFilter.id ? 'secondary' : 'default'}
                            border={entity?.entity.filter?.savedFilter?.id !== favoriteFilter.id}
                            link={false}
                            loading={false}
                            onClick={() => { }}
                        />
                    </div>
                ))}
            </div>
            <FavoriteFilters visibleFilters={props.visibleFilters} size={props.size} buttonRefs={props.buttonRefs} />
        </div>
    );
});
