export function CopyText(text: string) {
    if (text) {
        if (navigator.clipboard)
            navigator.clipboard.writeText(text).catch(e => {
                console.warn(e);
            });
        else {
            var textarea = document.createElement("textarea");
            textarea.textContent = text;
            textarea.style.position = "fixed";
            document.body.appendChild(textarea);
            textarea.select();
            try {
                document.execCommand("copy");
            }
            catch (ex) {
                console.warn("Copy to clipboard failed.", ex);
            }
            finally {
                document.body.removeChild(textarea);
            }
        }
    }
}

function CopyHref(id?: string) {
    let href = id ? window.location.href + "/" + id : window.location.href;
    if (navigator.clipboard)
        navigator.clipboard.writeText(href).catch(e => {
            console.warn(e);
        });
    else {
        var textarea = document.createElement("textarea");
        textarea.textContent = href;
        textarea.style.position = "fixed";
        document.body.appendChild(textarea);
        textarea.select();
        try {
            document.execCommand("copy");
        }
        catch (ex) {
            console.warn("Copy to clipboard failed.", ex);
        }
        finally {
            document.body.removeChild(textarea);
        }
    }
}

export default CopyHref