import { type ReactElement } from 'react'

import { ZoneObserver } from '../zone-observer'
import { type Types } from '../type'

export type OnDrop = (value: {
  zoneId: string
  currentElementIndex: number
  targetElementIndex: number
}) => void

interface Props {
  children: JSX.Element[]
  id: string
  movementElement: JSX.Element
  placeholder: JSX.Element
  predict: JSX.Element
  type: Types
  onDrop: OnDrop
  onUp?: (value: string) => void
}

export function HOC (props: Props): ReactElement {
  return (
        <ZoneObserver {...props} children={props.children}/>
  )
}
