import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { v4 } from "uuid";
import { toJS } from "mobx";

import { modalController } from "features/modals";
import detailMasterState from "./detail-master-state";

import { Button, ButtonStyle } from "components";
import { DetailMasterDragZone, DetailMasterHeader, DetailMasterRightPanel, DetailMasterTitle } from "./components";

import { ColumnType } from "entities/ColumnType";
import { IGridItem } from "components/select/types";
import { DetailConfig, GridItem } from "types/entity";

import { TextIcon, DateTimeIcon, DateIcon, Clocks, SectionMasterLookupIcon, LogicalIcon, Numbers, DecimalIcon, Warning } from "shared";

import styles from "./detail-master.module.css";

interface IDetailDesigner {
	close?: () => void;
	detailConfig?: DetailConfig;
	boxFields?: GridItem[];
	onSave?: (grid: GridItem[]) => void;
}

const NewFields: Array<IGridItem> = [
	{
		id: v4(),
		name: ColumnType.String,
		displayValue: "Текстовое",
		icon: <TextIcon />
	},
	{
		id: v4(),
		name: ColumnType.DateTime,
		displayValue: "Дата и время",
		icon: <DateTimeIcon />
	},
	{
		id: v4(),
		name: ColumnType.Date,
		displayValue: "Дата",
		icon: <DateIcon />
	},
	{
		id: v4(),
		name: ColumnType.Time,
		displayValue: "Время",
		icon: <Clocks />
	},
	{
		id: v4(),
		name: ColumnType.Lookup,
		displayValue: "Справочное",
		icon: <SectionMasterLookupIcon />
	},
	{
		id: v4(),
		name: ColumnType.Boolean,
		displayValue: "Логическое",
		icon: <LogicalIcon />
	},
	{
		id: v4(),
		name: ColumnType.Integer,
		displayValue: "Целое число",
		icon: <Numbers />
	},
	{
		id: v4(),
		name: ColumnType.Decimal,
		displayValue: "Дробное число",
		icon: <DecimalIcon />
	}
];

const WARNING_TEXT = "Вы действительно хотите покинуть дизайнер детали?\nНесохранённые изменения будут утеряны.";

export const DetailMaster = observer((props: IDetailDesigner) => {
	const [idConfirm] = useState<string>(v4());
	const [isNew, setNew] = useState<boolean>(false);
	const param = useParams<any>();

	const gridItems = useMemo(() => {
		return detailMasterState.gridItems;
	}, [toJS(detailMasterState.gridItems)]);

	const existColumns = useMemo(() => {
		const resultColumns: IGridItem[] = [];
		detailMasterState.existColumns.forEach((column) => {
			const findGridItem = detailMasterState.gridItems.find((gridItem) => gridItem.fieldConfig?.columnId === column.columnId);
			if (findGridItem) {
				if (findGridItem.x > 0 && findGridItem.y > 0) {
					resultColumns.push({
						gridItemId: findGridItem.gridItemId,
						columnId: findGridItem.fieldConfig?.columnId,
						name: findGridItem.fieldConfig?.columnName!,
						displayValue: findGridItem.fieldConfig?.columnTitle!,
						columnType: findGridItem.fieldConfig?.columnType,
						isRequired: findGridItem.fieldConfig?.isRequired,
						isDisabled: false,
						isLocked: false
					});
				} else {
					resultColumns.push({
						gridItemId: findGridItem.gridItemId,
						columnId: findGridItem.fieldConfig?.columnId,
						name: findGridItem.fieldConfig?.columnName!,
						displayValue: findGridItem.fieldConfig?.columnTitle!,
						columnType: findGridItem.fieldConfig?.columnType,
						isRequired: findGridItem.fieldConfig?.isRequired,
						isDisabled: true,
						isLocked: true
					});
				}
			}
		});
		return resultColumns;
	}, [toJS(detailMasterState.existColumns)]);

	useEffect(() => {
		if (window.location.href.includes("sectionMaster")) {
			if (props.detailConfig) {
				detailMasterState.loadCreateData(props.detailConfig);
				detailMasterState.setBoxFields(props.boxFields ?? []);
			}
			setNew(true);
		} else if (param.mode === "edit" && param.entityName) {
			detailMasterState.loadEditData(param.entityName);
			setNew(false);
		}
	}, []);

	const handleSaveNewField = useCallback((newItem: GridItem) => {
		const newExist: IGridItem = {
			gridItemId: newItem.gridItemId,
			columnId: newItem.fieldConfig?.columnId,
			name: newItem.fieldConfig?.columnName!,
			displayValue: newItem.fieldConfig?.columnTitle!,
			columnType: newItem.fieldConfig?.columnType
		};
		detailMasterState.saveNewField(newItem, newExist);
	}, []);

	const closeConfirm = useCallback(() => {
		modalController.modalRemove(idConfirm);
	}, [idConfirm]);

	const exitDetailMaster = useCallback(() => {
		if (props.close) {
			props.close();
		} else {
			window.close();
		}
		closeConfirm();
	}, [props.close]);

	const warningConfirm = useMemo(() => {
		return (
			<div className={styles.warningDialog}>
				<div className={styles.warningHeader}>
					<span className={styles.warningTitle}>Внимание</span>
					<Warning />
				</div>
				<div className={styles.warningDialogBody}>
					<span className={styles.title}>{WARNING_TEXT}</span>
				</div>
				<div className={styles.dialogFooter}>
					<Button caption="Вернуться" onClick={closeConfirm} style={ButtonStyle.Subtle} isDisabled={false} />
					<Button caption="Покинуть" onClick={exitDetailMaster} style={ButtonStyle.Danger} isDisabled={false} />
				</div>
			</div>
		);
	}, [props.close, closeConfirm]);

	const handleCloseDetailMaster = useCallback(() => {
		modalController.popupAdd({ id: idConfirm, layout: warningConfirm, closeFunc: closeConfirm });
	}, []);

	const handleSaveDetailMaster = useCallback(async () => {
		if (props.onSave) {
			props.onSave(gridItems);
		} else {
			const response = await detailMasterState.updateDetail();
			if (response) {
				window.close();
			}
		}
	}, [toJS(gridItems)]);

	return (
		<div className={styles.wrapper}>
			<DetailMasterHeader
				onSaveClick={handleSaveDetailMaster}
				onCancelClick={handleCloseDetailMaster}
				title={detailMasterState.detailTitle}
			/>
			<div className={styles.detailDesignerBody}>
				<div className={styles.centralPanel}>
					<DetailMasterTitle title={detailMasterState.detailTitle} />
					<DetailMasterDragZone grid={gridItems} isNew={isNew} />
				</div>
				<DetailMasterRightPanel newFields={NewFields} existsColumns={existColumns} onSaveNewField={handleSaveNewField} />
			</div>
		</div>
	);
});
