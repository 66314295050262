import { observer } from "mobx-react";

import styles from "./block-with-fields.module.scss";

type BlockWithFieldsProps = {
    headerTitle: string;
    children: JSX.Element;
}

export const BlockWithFields = observer((props: BlockWithFieldsProps) => {
    return (
        <div className={styles.header}>
            <span className={styles.headerTitle}>{props.headerTitle}</span>
            {props.children}
        </div>
    );
});