import { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { v4 } from "uuid";
import { isNull } from "lodash";

import { dispatcher } from "store";
import { modalController, Position } from "features/modals";
import { ModalType } from "features/modals/viewer/modal-viewer";

import { Field } from "sale-bridge-ui-kit";
import { ButtonStyle } from "components";
import { ColorPanel } from "components/color-panel";
import { CheckboxWithOptions } from "pages/section-wizzard/components/checkbox-with-options/checkbox-with-options";
import { MovingRules } from "./moving-rules";
import { FieldSelect } from "pages/section-wizzard/components";

import { HEXColors, ResultHEXColors, ResultType, StageFullInfo } from "types/entity";
import { FieldValidationState, validateRequired } from 'entities/Validation';

import { SettingPanelProps } from "../settings-panel";

import styles from "../settings-panel.module.scss";

export const SettingsBody = observer((props: SettingPanelProps) => {
    const colors = Object.values(HEXColors);
    const [allStages, setAllStages] = useState<StageFullInfo[]>(props.allStages);
    const [validation, setValidation] = useState<FieldValidationState>({
        isInvalid: false,
        error: "Заполните поле"
    });

    useEffect(() => {
        setAllStages(props.allStages)
    }, [props.allStages]);

    const currentStage = useMemo(() => {
        return allStages.find((stage: StageFullInfo) => stage.id === props.idStage)
    }, [toJS(allStages), props.idStage]);

    useEffect(() => {
        if (currentStage?.name !== '') {
            setValidation({
                isInvalid: false,
                error: "Заполните поле",
            })
        }
        else {
            setValidation({ isInvalid: true })
        }
    }, [currentStage?.name, setValidation]);

    const isDisabledColors = useMemo(() => {
        return (!isNull(currentStage?.resultType));
    }, [currentStage]);

    const handleChangeColor = useCallback((value: string) => {
        if (currentStage) {
            dispatcher.stageModel.setColor(currentStage.id, value as HEXColors);
        }
    }, [currentStage?.id]);


    //TODO перепроверить этот кейс с changeStageNames, пока что он ломает апдейт имени иногда
    const handleChangeStageName = useCallback((value: string) => {
        if (currentStage) {
            dispatcher.stageModel.setStageName(currentStage.id, value);
            // dispatcher.stageModel.changeStageNames();
        }
    }, [currentStage?.id]);

    useEffect(() => {
        if (currentStage) {
            dispatcher.stageModel.setColor(props.idStage, currentStage.color);
            dispatcher.stageModel.setStageName(props.idStage, currentStage.name);
        }
    }, [props.idStage]);

    const validateSet = useCallback(() => {
        const isValidRes = validateRequired(currentStage!.name, validation)
        if ((!isValidRes || currentStage?.name.length == 0)) {
            setValidation(prevValidation => (
                {
                    ...prevValidation,
                    isInvalid: (!isValidRes || currentStage?.name.length == 0),
                    error: "Заполните поле",
                }));
        } else if (props.allStages.filter(stage => stage.name === currentStage?.name).length > 1) {
            setValidation(prevValidation => (
                {
                    ...prevValidation,
                    isInvalid: (props.allStages.filter(stage => stage.name === currentStage?.name).length > 1),
                    error: "Такая стадия уже существует",
                }));
        }
    }, [currentStage?.name, validation]);

    return (
        <>
            <Field
                type="text"
                value={currentStage?.name ?? ''}
                labelPosition="vertical"
                size="small"
                label="Название стадии"
                isRequired={true}
                textVariant='outlined'
                placeholder="Введите значение"
                counter={50}
                alert={(validation.isInvalid) ? 'error' : undefined}
                onChange={handleChangeStageName}
            />
                <ColorPanel
                    value={currentStage?.color}
                    onChange={handleChangeColor}
                    colorPanel={colors}
                    isDisabled={isDisabledColors}
                />
            <MovingRules allStages={allStages} idStage={props.idStage} />
        </>
    );
});

enum ResultStageOptionsNames {
    Neutral = "Нейтральный",
    Positive = "Положительный",
    Negative = "Отрицательный"
}

export const AdditionalBody = observer((props: {
    idStage: string;
    allStages: StageFullInfo[]
}) => {
    const [isResultStage, setResultStage] = useState<boolean>(false);
    const defaultResultStageOptions = [
        { id: ResultStageOptionsNames.Neutral, name: ResultStageOptionsNames.Neutral, info: 'Например, «Задача выполнена»', type: ResultType.Null, checked: true },
        { id: ResultStageOptionsNames.Positive, name: ResultStageOptionsNames.Positive, info: 'Например, лид: «Перевод в продажи»', type: ResultType.Successful, checked: false },
        { id: ResultStageOptionsNames.Negative, name: ResultStageOptionsNames.Negative, info: 'Например, лид: «Дисквалифицирован»', type: ResultType.Negative, checked: false }
    ];

    const currentStage = useMemo(() => {
        return props.allStages.find((stage: StageFullInfo) => stage.id === props.idStage)
    }, [toJS(props.allStages), toJS(props.allStages), props.idStage]);

    const handleResultStageChange = useCallback((value: boolean) => {
        setResultStage(value);
        if (currentStage) {
            if (value) {
                dispatcher.stageModel.setStageType(currentStage.id, ResultType.Null);
                dispatcher.stageModel.setColor(currentStage.id, ResultHEXColors.Null);
                modalController.notificationAdd({
                    id: v4(),
                    type: ModalType.NOTIFICATION,
                    position: Position.CENTER,
                    layout: <div>{`Стадия «${currentStage.name}» перенесена в результирующие стадии`}</div>,
                    allowTimer: true,
                    allowDefaultClick: true,
                    withBackdrop: false,
                })
            } else {
                dispatcher.stageModel.setStageType(currentStage.id, null);
                dispatcher.stageModel.setColor(currentStage.id, HEXColors.Blue);
                modalController.notificationAdd({
                    id: v4(),
                    type: ModalType.NOTIFICATION,
                    position: Position.CENTER,
                    layout: <div>{`Стадия «${currentStage.name}» перенесена в промежуточные стадии`}</div>,
                    allowTimer: true,
                    allowDefaultClick: true,
                    withBackdrop: false,
                })
            }
        }
    }, [props.idStage, currentStage]);

    const handleResultStageSelect = useCallback((value: string) => {
        if (currentStage) {
            dispatcher.stageModel.setStageType(currentStage.id, defaultResultStageOptions.find(stage => stage.name === value)?.type ?? null);
            if (value === ResultStageOptionsNames.Neutral) {
                dispatcher.stageModel.setColor(currentStage.id, ResultHEXColors.Null as string);
            } else if (value === ResultStageOptionsNames.Negative) {
                dispatcher.stageModel.setColor(currentStage.id, ResultHEXColors.Negative as string);
            } else {
                dispatcher.stageModel.setColor(currentStage.id, ResultHEXColors.Successful as string);
            }
        }
    }, [currentStage]);

    const checkedResultStageOptions = useMemo(() => {
        return defaultResultStageOptions.map(option => ({
            ...option,
            checked: currentStage?.resultType === option.type,
        }))
    }, [currentStage, currentStage?.resultType]);

    useEffect(() => {
        if (currentStage) {
            setResultStage(!isNull(currentStage.resultType));
        }
    }, [props.idStage]);

    return (
        <FieldSelect
            name='Дополнительно'
            buttonStyle={ButtonStyle.Settings}
            className={styles.fieldSelectInMovingRules}
        >
            <CheckboxWithOptions
                caption="Стадия является результатом"
                description="Отметьте, если требуется конечная стадия"
                onClickCheckbox={handleResultStageChange}
                checkboxValue={isResultStage}
                onSelect={handleResultStageSelect}
                options={checkedResultStageOptions}
            />
        </FieldSelect>
    );
});