import { dispatcher } from "store/store-dipatcher/dispatcher";
import { v4 } from "uuid";
import { action, makeAutoObservable } from "mobx";

import { AdditionalField, CardDesign, KanbanConfig, QuickAction, QuickActionType } from "types/entity";
import { selector } from "store";

export class KanbanCardDesignController {

    oldKanbanCardDesignConfig: CardDesign | null = null;
    hasChanges: boolean;

    constructor() {
        makeAutoObservable(this);
        this.hasChanges = false;
    }

    /**
     * @description Инициализация конфига канбана и запись старого значения.
     */
    init = (): void => {
        const sectionWizzard = dispatcher.sectionWizzard.getSectionWizzard();
        if (sectionWizzard && !sectionWizzard.kanbanConfig) {
            const basicKanbanModel: KanbanConfig = {
                cardDesign: {
                    userAvatarEnable: false,
                    quickActionEnable: false,
                    additionalFields: [],
                    userFields: [],
                    selectedQuickActions: [
                        {
                            action: QuickActionType.CopyLink,
                            isEnabled: true,
                            isHidden: false
                        },
                        {
                            action: QuickActionType.OpenInNewTab,
                            isEnabled: true,
                            isHidden: false
                        },
                        {
                            action: QuickActionType.Flag,
                            isEnabled: false,
                            isHidden: !dispatcher.sectionWizzard.getSectionWizzard()?.hasFlag
                        },
                        {
                            action: QuickActionType.LogTime,
                            isEnabled: false,
                            isHidden: !dispatcher.sectionWizzard.getSectionWizzard()?.hasTimeLogging
                        },
                        {
                            action: QuickActionType.Stopwatch,
                            isEnabled: false,
                            isHidden: !dispatcher.sectionWizzard.getSectionWizzard()?.hasTimer
                        },
                    ]
                },
                quickViewDesign: {
                    quickViewEnable: true,
                    leftBlockAdditionalFields: [],
                    rightBlockAdditionalFields: [],
                    commentsEnable: false
                }
            }
            sectionWizzard.kanbanConfig = basicKanbanModel;
        }

        //TODO Нужно в случае если мигратор не отработает
        // this.initSelectedQuickActionsInConfig();
        this.oldKanbanCardDesignConfig = (JSON.parse(JSON.stringify(selector.kanbanCardDesign.getCardDesign())) as CardDesign) ?? null;
    };

    /**
     * @description Добавление selectedQuickActions в существующий конфиг
     */
    initSelectedQuickActionsInConfig = (): void => {
        const sectionWizzard = dispatcher.sectionWizzard.getSectionWizzard();
        if (
            sectionWizzard &&
            sectionWizzard.kanbanConfig &&
            sectionWizzard.kanbanConfig.cardDesign &&
            (
                !sectionWizzard.kanbanConfig.cardDesign.selectedQuickActions ||
                (
                    sectionWizzard.kanbanConfig.cardDesign.selectedQuickActions &&
                    sectionWizzard.kanbanConfig.cardDesign.selectedQuickActions.length < 5
                )
            )
        ) {
            const selectedQuickActions: QuickAction[] = [
                {
                    action: QuickActionType.CopyLink,
                    isEnabled: true,
                    isHidden: false
                },
                {
                    action: QuickActionType.OpenInNewTab,
                    isEnabled: true,
                    isHidden: false
                },
                {
                    action: QuickActionType.Flag,
                    isEnabled: false,
                    isHidden: !dispatcher.sectionWizzard.getSectionWizzard()?.hasFlag
                },
                {
                    action: QuickActionType.LogTime,
                    isEnabled: false,
                    isHidden: !dispatcher.sectionWizzard.getSectionWizzard()?.hasTimeLogging
                },
                {
                    action: QuickActionType.Stopwatch,
                    isEnabled: false,
                    isHidden: !dispatcher.sectionWizzard.getSectionWizzard()?.hasTimer
                },
            ]

            sectionWizzard.kanbanConfig = {
                ...sectionWizzard.kanbanConfig,
                cardDesign: {
                    ...sectionWizzard.kanbanConfig.cardDesign,
                    selectedQuickActions: selectedQuickActions
                }
            };
        }
    };

    /**
     * @description Метод для деактивации/активации списка быстрых действий CardDesign (карточки канбана)
     * @param value
     */
    setQuickActionEnable = action((value: boolean): void => {
        if (selector.kanbanCardDesign.getCardDesign()) {
            if (selector.kanbanCardDesign.getCardDesign()!.quickActionEnable !== value) {
                selector.kanbanCardDesign.getCardDesign()!.quickActionEnable = value;
                this.hasChanges = true;
            }
        }
    })

    /**
     * @description Метод для деактивации/активации быстрых действий из списка быстрых действий CardDesign (карточки канбана)
     * @param value
     */
    setQuickActionsEnable = action((value: boolean, action: QuickActionType): void => {
        const findedAction = selector.kanbanCardDesign.getCardDesign()?.selectedQuickActions.find(selectedQuickAction => selectedQuickAction.action == action)
        if (findedAction) {
            findedAction.isEnabled = value;
        }
    })

    /**
     * @description Метод для деактивации/активации списка аватаров пользователей CardDesign (карточки канбана)
     * @param value 
     */
    setUserAvatarEnable = action((value: boolean, firstUser: AdditionalField): void => {
        if (selector.kanbanCardDesign.getCardDesign()) {
            selector.kanbanCardDesign.getCardDesign()!.userAvatarEnable = value;

            if (value && selector.kanbanCardDesign.getCardDesign()!.userFields.length == 0) {
                const user = {
                    ...firstUser,
                    gridItemId: v4(),
                    order: 1,
                    isBlocked: false
                }
                selector.kanbanCardDesign.getCardDesign()!.userFields.push(user);
            }
            if (!value) {
                this.setQuickActionEnable(false);
            }
            this.hasChanges = true;
        }
    })

    /**
     * @description Метод для деактивации/активации аватара из списка пользователей в CardDesign (карточки канбана)
     * @param value 
     * @param columnId - id пользователя из fieldConfig для работы с ним
     * @param users - список всех пользователей, которые есть в разделе
     */
    setUserAvatarsEnable = action((value: boolean, columnId: string, users: Array<AdditionalField>): void => {
        const findedUser = users.find(user => user.columnId === columnId);
        if (!findedUser || !selector.kanbanCardDesign.getCardDesign()) {
            return;
        }

        if (value && selector.kanbanCardDesign.getCardDesign()!.userFields.length < 5) {
            const user: AdditionalField = {
                ...findedUser,
                order: (selector.kanbanCardDesign.getCardDesign()!.userFields.length + 1),
                gridItemId: v4(),
                isBlocked: false
            }
            selector.kanbanCardDesign.getCardDesign()!.userFields.push(user);
            this.hasChanges = true;
        }
        if (!value) {
            const findedIndex = selector.kanbanCardDesign.getCardDesign()!.userFields.findIndex(user => user.columnId === columnId);
            selector.kanbanCardDesign.getCardDesign()!.userFields.splice(findedIndex, 1);
            selector.kanbanCardDesign.getCardDesign()!.userFields = selector.kanbanCardDesign.getCardDesign()!.userFields.map((userField, index) => ({
                ...userField,
                order: (index + 1),
            }))
            this.hasChanges = true;
        }
    })

    /**
     * @description Метод для деактивации/активации аватара из списка пользователей в CardDesign (карточки канбана)
     * @param value 
     */
    setPositionsUsersAvatars = action((params: AdditionalField[]): void => {
        if (!selector.kanbanCardDesign.getCardDesign()) {
            return;
        }
        selector.kanbanCardDesign.getCardDesign()!.userFields = params
    })

    /**
     * @description Метод для обновления полей в CardDesign (карточки канбана)
     * @param field - новое/обновленное поле, которое необходимо добавить/обновить
     * @param newPosition - позиция, на которую необходимо поставить поле
     */
    updateAdditionalField = action((field: AdditionalField, newPosition: number): void => {
        let additionalFields = selector.kanbanCardDesign.getCardDesign()?.additionalFields;
        if (!additionalFields) {
            return;
        }
        const findedIndex = additionalFields.findIndex(item => item.gridItemId === field.gridItemId);
        const duplicatePosition = additionalFields.findIndex(item => item.order === newPosition);


        if (findedIndex !== -1) {
            const oldPosition = additionalFields[findedIndex].order;

            additionalFields = additionalFields.map((leftField, index) => {
                if (oldPosition < newPosition) {
                    if ((duplicatePosition > -1 && index > (oldPosition - 1) && index <= (newPosition - 1))
                        || (index > (oldPosition - 1) && index < (newPosition - 1))
                    ) {
                        const newField: AdditionalField = {
                            ...leftField,
                            order: leftField.order - 1
                        }
                        return newField
                    }
                }
                else {
                    if ((duplicatePosition > -1 && index >= (newPosition - 1) && index < (oldPosition - 1))
                        || (index > (newPosition - 1) && index < (oldPosition - 1))) {
                        const newField: AdditionalField = {
                            ...leftField,
                            order: leftField.order + 1
                        }
                        return newField
                    }
                } return leftField;
            })
            additionalFields[findedIndex] = field;
        }
        else {
            if (duplicatePosition == -1) {
                additionalFields.push(field);
            }

        }
        this.hasChanges = true;
        additionalFields.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
        selector.kanbanCardDesign.getCardDesign()!.additionalFields = additionalFields;

    })

    /**
     * @description Метод для создания дубля поля в CardDesign (карточки канбана)
     * @param field - поле, которое необходимо добавить в качестве дубля
     * @param newPosition - позиция, на которую необходимо поставить дубль
     */
    createDuplicateAdditionalField = action((field: AdditionalField, newPosition: number): void => {
        let additionalFields = selector.kanbanCardDesign.getCardDesign()?.additionalFields;
        if (!additionalFields) {
            return;
        }
        const duplicatePosition = additionalFields.findIndex(item => item.order === newPosition);
        const duplicateAdditionalField: AdditionalField = {
            columnId: field.columnId!,
            gridItemId: v4(),
            order: newPosition
        }
        if (duplicatePosition > -1) {
            additionalFields = additionalFields.map((leftField, index) => {
                if (index >= duplicatePosition) {
                    const newField: AdditionalField = {
                        ...leftField,
                        order: leftField.order + 1
                    }
                    return newField
                }
                return leftField;
            })

        }
        additionalFields.push(duplicateAdditionalField);
        additionalFields.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));

        selector.kanbanCardDesign.getCardDesign()!.additionalFields = additionalFields;

    })

    /**
     * @description Метод для создания/обновления полей или создания дубля в CardDesign (карточки канбана)
     * @param field - новое/обновленное поле, которое необходимо добавить/обновить/создать дубль
     * @param newPosition - позиция, на которую необходимо поставить поле
     */
    setAdditionalField = (field: AdditionalField, newPosition: number): void => {
        const additionalFields = selector.kanbanCardDesign.getCardDesign()?.additionalFields;
        if (!additionalFields) {
            return;
        }
        this.hasChanges = true;
        if (additionalFields.find(item => item.columnId === field.columnId && (item.order > 0))) {
            this.createDuplicateAdditionalField(field, newPosition);
        } else {
            this.updateAdditionalField(field, newPosition);
        }

    }

    /**
     * @description Метод для удаления поля из CardDesign (карточки канбана)
     * @param gridItemId - id поля для удаления
     */
    deleteField = action((gridItemId: string): void => {
        const additionalFields = selector.kanbanCardDesign.getCardDesign()?.additionalFields;
        if (!additionalFields) {
            return;
        }
        const findedIndex = additionalFields.findIndex(item => item.gridItemId === gridItemId);
        if (findedIndex > -1) {
            this.hasChanges = true;
            additionalFields.splice(findedIndex, 1);
        }
    })

    /**
     * @description Метод для сброса изменний в CardDesign (карточки канбана)
     */
    resetCardDesignSettings = (): void => {
        const sectionWizzard = dispatcher.sectionWizzard.getSectionWizzard();

        if (sectionWizzard?.kanbanConfig && this.oldKanbanCardDesignConfig) {
            sectionWizzard.kanbanConfig.cardDesign = JSON.parse(JSON.stringify(this.oldKanbanCardDesignConfig));
            this.resetHasChanges();
        }
    }

    resetHasChanges = action((): void => {
        this.hasChanges = false;
        const sectionWizzard = dispatcher.sectionWizzard.getSectionWizzard();
        if (sectionWizzard?.kanbanConfig && this.oldKanbanCardDesignConfig) {
            this.oldKanbanCardDesignConfig = JSON.parse(JSON.stringify(sectionWizzard.kanbanConfig.cardDesign));
        }
    })

}

export const kanbanCardDesignController = new KanbanCardDesignController();