import { observer } from "mobx-react";

import { selector } from "store";

import { AdvancedFilterConst } from "features/section-head/data/constants";
import FilterFolderTree, { FavoriteFilterFolder } from "./folder/filter-folder";

import { InputSearch } from "components";

import { CloseAdvancedFilterPanel } from "shared";

import styles from "./advanced-filter-tree.module.css";
import StaticGroupFolderTree from "./folder/static-group-folder/static-group-folder-tree";

const AdvancedFilterTree = observer(function (props: { isOpen: boolean }) {
	const { isOpen } = props;
	if (!isOpen) {
		return null;
	}

	return (
		<div className={styles.advancedFilterTree}>
			<div className={styles.advancedFilterTreeTitle}>
				Все фильтры
				<CloseAdvancedFilterPanel
					className={styles.advancedFilterTreeClose}
					onClick={() => {
						AdvancedFilterConst.setOpenAdvancedFilterTree(false, true);
					}}
				/>
			</div>
			<InputSearch classNameInput={styles.search} value={null} placeholder={"Поиск"} onChangeValue={(value: string) => {}} />
			{selector.filter.getFilterTree() &&
				selector.filter.getFilterTree()?.savedFilterTree !== null &&
				selector.filter.getFilterTree()?.staticGroupFolderTree !== null && (
					<div className={styles.filterFolder}>
						<FavoriteFilterFolder />
						<div className={styles.staticAndDynamicFilters}>
							<StaticGroupFolderTree staticGroupFolder={selector.filter.getFilterTree()!.staticGroupFolderTree!} />
							<FilterFolderTree savedFilterFolder={selector.filter.getFilterTree()!.savedFilterTree!} />
						</div>
					</div>
				)}
		</div>
	);
});

export default AdvancedFilterTree;
