import classNames from 'classnames';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import SectionRoute from 'entities/SectionRoute';

import { Button, WarningDialog } from 'components';
import { Icon} from "sale-bridge-ui-kit";
import { NavigationDotsItem } from '../navigation-dots-item';
import { NavigationSelect } from '../navigation-select';
import { SectionSelect } from '../section-select/section-select';

import { DotsItemType, FavoriteSection, ISectionSelectItem } from 'features/navigation/data/navigation-data';
import { AdvancedFilterConst } from 'features/section-head/data/constants';
import { SectionWizzardMode } from 'pages/section-wizzard/section-wizzard';
import { ItemGroup } from 'sale-bridge-ui-kit/dist/components/dropdown/dropdown';

import navigationStyles from '../../navigation-panel.module.scss';


type Props = {
	sections: ISectionSelectItem[]
	favorites: FavoriteSection[],
	handleClickToFavorites: (item: ISectionSelectItem) => void,
}

function SectionButtons(props: Props) {
	const warningText = 'Есть несохраненные изменения\nОтменить редактирование фильтра?'
	const boxSections = Object.values(SectionRoute);
	const [openedBackWarningDialog, setOpenedBackWarningDialog] = useState(false);
	const location = useLocation();
	const [currentSection, setCurrentSection] = useState("");

	const navigate = useNavigate();

	const workspaceClassNames = classNames(navigationStyles.buttonNavigation, navigationStyles.buttonNavigationPressed);
	const workspaceContentClassNames = classNames(navigationStyles.buttonContentNavigation, navigationStyles.buttonContentNavigationPressed);
	const workspaceTextClasses = classNames(navigationStyles.textButton, navigationStyles.textButtonPressed);

	function closeFilter() {
		AdvancedFilterConst.setOpenAdvancedFilterTree(
			!AdvancedFilterConst.isOpenAdvancedFilterTree,
			true,
		)
	}
	const getPath = useCallback((section: string) => {
		return `/singlePage/${section}`
	}, [boxSections])

	const goToSection = useCallback((section?: string) => {
		let path = getPath(section?.toLowerCase() ?? currentSection.toLowerCase())
		if (!section) {
			closeFilter()
		}
		navigate(path);
	}, [currentSection, getPath, navigate])

	const changeSection = useCallback((section: string) => {
		var filterIsActive = AdvancedFilterConst.isOpenAdvancedFilter
		if (filterIsActive) {
			setCurrentSection(section)
			setOpenedBackWarningDialog(true)
		} else goToSection(section)
	}, [goToSection])

	const favorites = useMemo(() => {
		return props.favorites.length > 0 &&
			props.favorites.map((favorite: FavoriteSection, i) => {
				const arrayOfLocation = location.pathname.split("/");
				const entity = arrayOfLocation[2] ? arrayOfLocation[2].toLowerCase() : null;

				const favoritesClasses = classNames(`${navigationStyles.buttonNavigation}`, {
					[`${navigationStyles.buttonNavigationPressed}`]: entity && entity === favorite?.section.entityName.toLowerCase()
				});
				const favoritesContentClasses = classNames(`${navigationStyles.buttonContentNavigation}`, {
					[`${navigationStyles.buttonContentNavigationPressed}`]: entity && entity === favorite?.section.entityName.toLowerCase()
				});
				const favoritesTextClasses = classNames(`${navigationStyles.textButton}`, {
					[`${navigationStyles.textButtonPressed}`]: entity && entity === favorite?.section.entityName.toLowerCase()
				});

				return (
					<div key={i}>
						<Button
							onClick={() => changeSection(favorite.section.entityName)}
							caption={favorite.section.displayValue}
							className={favoritesClasses}
							classNameContent={favoritesContentClasses}
							classNameCaption={favoritesTextClasses}
						/>

						<WarningDialog
							value={warningText}
							valueReturn='Вернуться к редактированию'
							valueDelete='Да, отменить'
							isOpen={openedBackWarningDialog}
							onBackClick={() => {
								setOpenedBackWarningDialog(false)
							}}
							onCancelClick={() => {
								setOpenedBackWarningDialog(false)
								goToSection()
							}}
						/>
					</div>
				)
			})
	}, [openedBackWarningDialog, toJS(props.favorites), location.pathname, changeSection, goToSection]);

	return <div className={navigationStyles.defaultContainer}>
		<SectionSelect
			size='small'
			name="Разделы"
			secondIcon={<Icon name='Dropdown' size='small' />}
			isRotateSecondIcon
			items={props.sections}
			handleClickToFavorites={props.handleClickToFavorites}
		/>
		{location.pathname.includes('workspace') && (
			<Link to='/workspace' className={navigationStyles.linkContainer}>
				<Button
					caption='Рабочий стол'
					className={workspaceClassNames}
					classNameContent={workspaceContentClassNames}
					classNameCaption={workspaceTextClasses}
				/>
			</Link>
		)}
		{favorites}
	</div>
}

export const LeftNavigation = observer((props: Props) => {
	const history = useNavigate();

	const handleClickDotsItem = useCallback((value: string) => {
		if (value === DotsItemType.Workspace) {
			history('/workspace')
		} else if (value === DotsItemType.SectionWizard) {
			window.open(`/sectionMaster/${SectionWizzardMode.NewSection}/new`, "_blank");
		} else if (value === DotsItemType.Sales) {
			//TODO Sales
		} else if (value === DotsItemType.Docs) {
			//TODO Docs
		}
	}, [])

	const dotsListItems = useMemo((): Array<ItemGroup> => {
		return [
			{
				layout: <></>,
				items: [
					<NavigationDotsItem
						firstHead={DotsItemType.Workspace}
						secondHead="Настройте свое рабочее пространство"
						icon='House'
						size='small'
						onClick={() => handleClickDotsItem(DotsItemType.Workspace)}
						isDisable
					/>,
					<NavigationDotsItem
						firstHead={DotsItemType.SectionWizard}
						secondHead="Создайте новый пользовательский раздел"
						icon='Tools'
						size='20'
						onClick={() => handleClickDotsItem(DotsItemType.SectionWizard)}
					/>,
				]
			},
			{
				layout: <></>,
				items: [
					<NavigationDotsItem
						firstHead={DotsItemType.Sales}
						secondHead="Управляйте полным циклом продаж"
						icon='CashStack'
						size='medium'
						onClick={() => handleClickDotsItem(DotsItemType.Sales)}
						isDisable
					/>,
					<NavigationDotsItem
						firstHead={DotsItemType.Docs}
						secondHead="Организуйте документооборот в вашей организации"
						icon='FileEarmarkText'
						size='small'
						onClick={() => handleClickDotsItem(DotsItemType.Docs)}
						isDisable
					/>
				]
			}
		];
	}, [handleClickDotsItem]);

	return (
		<div className={navigationStyles.defaultNavigationBlock}>
			<div className={navigationStyles.defaultContainer}>
				<NavigationSelect
					size='small'
					position='down'
					iconName='NineDots'
					items={dotsListItems}
				/>

				<div className={navigationStyles.defaultContainer}>
					<Icon name='Logo' size='small' />
				</div>
			</div>
			<SectionButtons {...props} />
		</div>
	)
})