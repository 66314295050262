import styles from "./text-prompt.module.css";

interface TextPromptProps extends React.HTMLAttributes<HTMLSpanElement> {
    size?: string;
    bold?: boolean;
}

function TextPrompt(props: TextPromptProps) {
    const spanProps = props as React.HTMLAttributes<HTMLSpanElement>;
    let className = styles.text;
    if (props.bold) {
        className += " " + styles.blod;
    }

    return (
        <span style={{ fontSize: props.size }} className={className} {...spanProps}>{props.children}</span>
    )
}

export default TextPrompt;