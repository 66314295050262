import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import importStore from "pages/import/core/import-store";

import SelectWithSearchMini from "components/select/select-with-search-mini/select-with-search-mini";
import { SelectMiniWithSearchStyleName } from "components/select/select-with-search-mini/core/styles-system";

import { ISelectItem } from "pages/import/data/import-constants";

import styles from "./data-mapping-grid.module.css";

interface DataMappingGridProps {
    items: string[]
}

function DataMappingGrid(props: DataMappingGridProps) {
    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <span>{"Колонка в разделе [" + importStore.entity!.displayValue + "]"}</span>
                <span>Колонка в файле</span>
            </div>
            <div className={styles.body}>
                {props.items.map((item, index) => <Row key={item + index} name={item} />)}
            </div>
        </div>
    )
}

const Row = observer((props: { name: string }) => {
    const nullValue = { value: undefined, displayValue: "Выбрать колонку", columnType: "null" };
    const [value, setValue] = useState<ISelectItem>(nullValue)
    const items = importStore.properties.filter(p => !importStore.result.mappingProperties.some(m => m.columnInModel === p.columnName)
        && p.columnName !== "Id").map(p => { return { value: p.columnName, displayValue: p.columnTitle, columnType: p.columnType } });

    useEffect(() => {
        getItemToSelect();
    }, [])

    function getItemToSelect() {
        const item = importStore.result.mappingProperties.find(m => m.columnInFile === props.name);
        if (item) {
            let el = importStore.properties.find(i => i.columnName == item?.columnInModel);
            if (el) {
                setValue({ value: el.columnName, displayValue: el.columnTitle, columnType: el.columnType });
                return;
            }
        }
        setValue(nullValue);
    }

    function onChange(value: ISelectItem) {
        setValue(value);
        const item = importStore.result.mappingProperties.find(m => m.columnInFile === props.name);
        if (item) {
            item.columnInModel = value.value;
        }
        else {
            importStore.result.mappingProperties.push({ columnInFile: props.name, columnInModel: value.value });
        }
    }

    function clear() {
        setValue(nullValue)
        const item = importStore.result.mappingProperties.findIndex(m => m.columnInFile === props.name);
        importStore.result.mappingProperties.splice(item, 1);
    }

    return (
        <div className={styles.row}>
            <SelectWithSearchMini
                className={styles.select}
                styles={SelectMiniWithSearchStyleName.Opacity}
                value={value}
                items={items}
                onChange={onChange}
            />
            <div className={styles.rowName}>
                {props.name}
            </div>
            {value.value != nullValue.value && <span className={styles.clear} onClick={clear}>Сбросить</span>}
        </div>
    );
});

export default DataMappingGrid;