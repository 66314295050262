import { ViewMode } from "pages/single-page/type";
import { SwitchElement } from "sale-bridge-ui-kit/dist/components/switcher/switcher";

export type Props = {
    size: 'small' | 'medium' | 'large';
    hasKanban: boolean;
    onSwitchViewMode: (value: ViewMode) => void;
    viewModeValue: ViewMode;
}

export enum VIEW {
    GRID = 'Список',
    KANBAN = 'Канбан',
}

export const switchingElements: Array<SwitchElement> = [{
    id: ViewMode.GRID,
    text: VIEW.GRID,
    icon: 'ListSwitcher',
    disabled: false
}, {
    id: ViewMode.KANBAN,
    text: VIEW.KANBAN,
    icon: 'Kanban',
    disabled: false
}];