import moment from "moment";
import { isString } from "lodash";

function format(str: string) {
    if (!isString(str)) {
        return str;
    }
    const s = str.length;
    const chars = str.split('');
    const strWithSpaces = chars.reduceRight((acc, char, i) => {
        const spaceOrNothing = ((((s - i) % 3) === 0) ? ' ' : '');
        return (spaceOrNothing + char + acc);
    }, '');

    return ((strWithSpaces[0] === ' ') ? strWithSpaces.slice(1) : strWithSpaces);
}

export function fieldValueOfTypeParser(type: string, value: string | null): string {
    if (type === "DateTime") {
        if (value === null || value === '') {
            return "";
        }
        return moment(value).format("DD.MM.YYYY hh:mm");
    } else if (type === "Date") {
        if (value === null || value === '') {
            return "";
        }
        return moment(value).format("DD.MM.YYYY");
    } else if (type === "Time" || value === '') {
        if (value === null) {
            return "";
        }
        return value?.slice(0, 5) ?? "";
    } else if (type === "Decimal") {
        return value ?? "";
    } else if (type === "Boolean") {
        return value?.toString() === "true"  ? "Да" : "Нет";
    } else if (type === "Int32") {
        return format(value ?? "");
    } else if (type === "Lookup") {

        return value ?? "";
    } else {
        return value ?? ""
    }
}