import { observer } from "mobx-react-lite";
import { useCallback, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";

import authStore from "AuthStore";
import userEntity from "entities/user/User";
import SectionRoute from "entities/SectionRoute";

import { GlobalSearch } from "widgets/globalSearch";
import Avatar from "features/avatar/avatar-generator";
import { NavigationButton } from "../navigation-button";
import { NavigationSelect } from "../navigation-select";
import { ProfileItem } from "../profile-item";

import { ItemGroup } from "sale-bridge-ui-kit/dist/components/dropdown/dropdown";
import { ProfileItemType } from "features/navigation/data/navigation-data";

import navigationStyles from "../../navigation-panel.module.scss";

export const RightNavigation = observer(function (props: { userName: string; userEmail: string }) {
	const history = useNavigate();

	const handleClickProfileItem = useCallback((value: string) => {
		if (value === ProfileItemType.PersonalSettings) {
		} else if (value === ProfileItemType.Profile) {
			history(`/${SectionRoute.User}/${userEntity.entity.id}`);
		} else if (value === ProfileItemType.Logout) {
			authStore.logOut();
		}
	}, []);

	const profileListItems = useMemo((): Array<ItemGroup> => {
		return [
			{
				layout: <></>,
				items: [
					<ProfileItem
						value={ProfileItemType.PersonalSettings}
						icon="Palette"
						size="small"
						onClick={() => handleClickProfileItem(ProfileItemType.PersonalSettings)}
						isDisable
					/>,
					<ProfileItem
						value={ProfileItemType.Profile}
						icon="Person"
						size="small"
						onClick={() => handleClickProfileItem(ProfileItemType.Profile)}
						isDisable
					/>
				]
			},
			{
				layout: <></>,
				items: [
					<ProfileItem
						value={ProfileItemType.Logout}
						icon="Logout"
						size="small"
						onClick={() => handleClickProfileItem(ProfileItemType.Logout)}
					/>
				]
			}
		];
	}, [handleClickProfileItem]);

	return (
		<div className={navigationStyles.defaultNavigationBlock}>
			<div style={{ display: "none" }}>
				<GlobalSearch />
			</div>
			<div className={navigationStyles.defaultContainer}>
				<NavigationButton size="small" leftIcon="Bell" onClick={() => {}} caption="" />
				<Link to="/settings">
					<NavigationButton size="small" leftIcon="Settings" onClick={() => {}} caption="" />
				</Link>
				<NavigationSelect
					size="small"
					position="right-down"
					items={profileListItems}
					childrenButton={<Avatar id="avatar" name={props.userName} size="small" className={navigationStyles.profileAvatar} />}
				/>
			</div>
		</div>
	);
});
