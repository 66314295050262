import { store } from "store";
import { SubscribeType } from "types";

export class SubscribersController {
	add = (uuid: string) => {
		if (!store.reactionSubscribers[uuid]) {
			store.reactionSubscribers[uuid] = SubscribeType.NONE;
		}
	};
	remove = (uuid: string) => {
		if (store.reactionSubscribers[uuid]) {
			delete store.reactionSubscribers[uuid];
		} else {
			console.error(`uuid: ${uuid} в store>reactionSubscribers не существует [removeReactionSubscribers]`);
		}
	};
}

export const subscribersController = new SubscribersController();