import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { useMemo, useState } from "react";
import { Field } from "sale-bridge-ui-kit";

import { FieldValidationState, Item } from "types";

import styles from "./select-property.module.scss";

type SelectPropertyProps = {
	size: "small" | "medium" | "large";
	placeholder: string;
	isDisabled?: boolean;
	value: string;
	items: Item[];
	onChange: (value: Item | null) => void;
	validation?: FieldValidationState;
};

export const SelectProperty = observer((props: SelectPropertyProps) => {
	const [searchValue, setSearchValue] = useState("");

	const dropdownItems = useMemo(() => {
		return [
			{
				layout: <></>,
				items: props.items
					.filter((item) =>
						item.displayValue
							? item.displayValue?.toLowerCase().includes(searchValue?.toLowerCase())
							: item.name?.toLowerCase().includes(searchValue?.toLowerCase())
					)
					.map((item) => (
						<span key={item.id} className={styles.item} onClick={() => props.onChange(item)}>
							{item.displayValue ?? item.name}
						</span>
					))
			}
		];
	}, [toJS(props.items), searchValue]);

	return (
		<Field
			type="select"
			size={props.size}
			placeholder={props.placeholder}
			textVariant="outlined"
			value={props.value}
			onChange={props.onChange}
			items={dropdownItems}
			searchValue={searchValue}
			onChangeSearchValue={setSearchValue}
			isDisabled={props.isDisabled}
			alert={props.validation?.isInvalid ? "error" : undefined}
			tooltipBody={props.validation?.error}
			startTooltipPosition="top left"
			tooltipTrigger="hover"
			isTooltipDisplayed={props.validation?.isInvalid}
		/>
	);
});
