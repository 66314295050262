import { observer } from "mobx-react-lite";
import { v4 } from "uuid";

import { Card } from "components/kanban-components/components/card/card";
import { Column, ColumnBody } from "components";

import styles from './kanban-preview.module.scss';
import { QuickActionType } from "types/entity";

type KanbanPreviewProps = {
    name: string; // Имя компонента-заголовка
    additionFields: Array<string>; // Список названий полей
    usersAvatar: Array<string> // Список названий полей списокв
    quickActions: Array<QuickActionType>;// Список быстрых действий
    quickActionEnable: boolean;
}

export const KanbanPreview = observer((props: KanbanPreviewProps) => {

    const additionFieldsObject: Record<string, string> = {};
    props.additionFields.forEach(additionField => {
        additionFieldsObject[additionField] = `Значение поля [${additionField}]`;
    });

    const usersObject: Record<string, string> = {};
    props.usersAvatar.forEach(userAvatar => {
        usersObject[userAvatar] = "Имя пользователя";
    });

    return (
        <div className={styles.kanbanPreviewWrapper}>
            {props.quickActionEnable && <span className={styles.adviceForQuickActions}>Совет: прицельтесь на карточку, чтобы увидеть дополнительные элементы</span>}
            <Column
                name="Стадия"
                quantity={2}
                size="small"
                position="intermediate"
                isPreview
            >
                <ColumnBody
                    quantity={2}
                    id={v4()}
                    isPreview
                >
                    <Card
                        priority="Средний"
                        name={`[${props.name}]`}
                        id={v4()}
                        color="f79009"
                        size="small"
                        flagged={false}
                        showActions={props.quickActionEnable}
                        isSelected={false}
                        onOpen={() => { }}
                        onFlaged={() => { }}
                        fields={additionFieldsObject}
                        users={usersObject}
                        quickActions={props.quickActions}
                    />
                    <Card
                        priority="Средний"
                        name={`[${props.name}]`}
                        id={v4()}
                        color="f79009"
                        size="small"
                        flagged={false}
                        showActions={props.quickActionEnable}
                        isSelected={false}
                        onOpen={() => { }}
                        onFlaged={() => { }}
                        fields={additionFieldsObject}
                        users={usersObject}
                        quickActions={props.quickActions}
                    />
                </ColumnBody>

            </Column>
            {/* <Column name="Стадия" quantity={2} children={
                <>
                    <Card
                        priority="Средний"
                        name={`[${props.name}]`}
                        id={v4()}
                        color="f79009"
                        size="small"
                        flagged={false}
                        showActions={props.quickActionEnable}
                        isSelected={false}
                        onOpen={() => { }}
                        onFlaged={() => { }}
                        fields={additionFieldsObject}
                        users={usersObject}
                        quickActions={props.quickActions}
                    />
                    <Card
                        priority="Средний"
                        name={`[${props.name}]`}
                        id={v4()}
                        color="f79009"
                        size="small"
                        flagged={false}
                        showActions={props.quickActionEnable}
                        isSelected={false}
                        onOpen={() => { }}
                        onFlaged={() => { }}
                        fields={additionFieldsObject}
                        users={usersObject}
                        quickActions={props.quickActions}
                    />
                </>
            }
                id={v4()}
                size="small"
                position="intermediate"
                isPreview
            /> */}
        </div>
    );
});