import { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { action, toJS } from "mobx";

import { dispatcher } from "store";

import gridParametersState from "../grid-parameters-state";

import { debounce } from "shared";
import { InputSearch } from "components";
import { InputChecker } from "components/input-for-section-designer";

import { VisibleColumn } from "types/entity";
import { IFormat } from "entities/IItem";

import styles from "./displayed-columns.module.scss";

export const DisplayedColumns = observer(() => {
	const [searchValue, setSearchValue] = useState<string | null>(null);
	const [permanentColumns, setPermanentColumns] = useState<IFormat[]>([]);

	useEffect(() => {
		setPermanentColumns([...gridParametersState.checkedColumns]);
	}, [gridParametersState.visibleColumns]);

	const search = useMemo(() => {
		return (value: string) => {
			const replacedValue = value.toLowerCase().replace(/"|'|`/g, "");
			if (replacedValue.length == 0) {
				gridParametersState.checkedColumns = permanentColumns;
			}
			const filteredColumns = permanentColumns.filter(
				(column) =>
					column.name.toLocaleLowerCase().includes(replacedValue) ||
					column.name.split(" ").find((name) => name.includes(replacedValue))
			);
			gridParametersState.checkedColumns = filteredColumns;
		};
	}, [permanentColumns]);

	const debouncedHandleSearch = useCallback(debounce(search, 250), [search]);

	const handleSearch = useCallback(
		(value: string) => {
			setSearchValue(value);
			debouncedHandleSearch(value);
		},
		[debouncedHandleSearch]
	);

	const handleCheck = action(
		useCallback(
			(value: boolean, checkedColumn: IFormat) => {
				gridParametersState.checkedColumns.find((column) => column.id === checkedColumn.id)!.checked = value;
				if (value) {
					const findedColumn = gridParametersState.entity?.entity.columns.find((column) => column.columnId === checkedColumn.id);
					if (findedColumn) {
						const newVisibleColumn: VisibleColumn = {
							...findedColumn,
							customerColumnTitle: "",
							indexX: gridParametersState.visibleColumns.length,
							indexY: 0,
							spanX: 20,
							spanY: 0
						};
						gridParametersState.visibleColumns.push(newVisibleColumn);
					}
				} else {
					const findedVisibleColumnIndex = gridParametersState.visibleColumns.findIndex(
						(column) => column.columnId === checkedColumn.id
					);
					if (findedVisibleColumnIndex > -1) {
						gridParametersState.visibleColumns.splice(findedVisibleColumnIndex, 1);
					}
				}
			},
			[
				toJS(gridParametersState.checkedColumns),
				toJS(gridParametersState.visibleColumns),
				toJS(dispatcher.entity.get()?.entity.columns)
			]
		)
	);

	return (
		<div className={styles.displayedColumns}>
			<div className={styles.search}>
				<span className={styles.description}>Отметьте колонки, которые необходимо отображать в таблице.</span>
				<InputSearch value={searchValue} placeholder="Поиск" onChangeValue={handleSearch} />
			</div>
			<div className={styles.divider} />
			<div className={styles.displayedColumnsBody}>
				{gridParametersState.checkedColumns?.map((column) => {
					return (
						<InputChecker
							startValue={column.checked}
							text={column.name}
							onChangeValue={(value: boolean) => {
								handleCheck(value, column);
							}}
						/>
					);
				})}
				{gridParametersState.checkedColumns.length === 0 && <span className={styles.notFound}>Ничего не найдено.</span>}
			</div>
		</div>
	);
});
