import { Button, ButtonStyle, Select } from "components";
import classNames from "classnames";

import { Item } from "types";
import { ITab } from "pages/section-wizzard/data/data";

import { Icon } from "sale-bridge-ui-kit";

import styles from "./tab.module.scss";

interface IPropsTab {
    tab: ITab;
    index: number;
    moreItems: Item[];
    styleButton?: ButtonStyle;
    getItemClass: (i: number) => string;
    onClick: (index: number) => void;
    onClickToMoreInRound: (value: Item | null) => void;
    handleClickToSelect: (value: ITab, index: number, e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    isSelected: boolean;
}

export function Tab(props: IPropsTab) {

    const itemClasses = classNames(`${styles.item} `, {
        [`${styles.selected} `]: props.isSelected,
    });

    return (
        <div key={props.tab.tabName} id={props.tab.tabName} className={itemClasses}>
            <Icon name="Draggable" size="16" className={styles.dragIcon} />
            <Button
                key={props.index}
                caption={props.tab.caption}
                className={props.getItemClass(props.index)}
                style={props.styleButton}
                onClick={() => props.onClick(props.index)}
            />
            <Select
                id={props.tab.tabName}
                firstIcon={<Icon name="KebabHorizontal" size="16" className={styles.kebab} />}
                classNameButton={styles.itemTabButton}
                iconOpened={<Icon name="KebabHorizontal" size="16" className={styles.kebabActive} />}
                onChangeValue={props.onClickToMoreInRound}
                items={props.moreItems}
                classNameList={styles.moreList}
                onClick={(e) => props.handleClickToSelect(props.tab, props.index, e)}
            />
        </div>
    );
}