import {
	reactionsController,
	subscribersController,
	rowController,
	modalController,
	entityController,
	commentsController,
	sectionWizzardController,
	stageModelController,
	filterController,
	SubscribersController,
	ReactionsController,
	RowController,
	ModalController,
	EntityController,
	CommentsController,
	FilterController,
	SectionWizzardController,
	StageModelController,
	FullViewController,
	fullViewController,
	KanbanQuickViewController,
	kanbanQuickViewController,
	KanbanCardDesignController,
	kanbanCardDesignController
} from "./dispatchers";

export class Dispatcher {
	subscribers: SubscribersController;
	reactions: ReactionsController;
	currentRow: RowController;
	modal: ModalController;
	entity: EntityController;
	comments: CommentsController;
	sectionWizzard: SectionWizzardController;
	kanbanCardDesign: KanbanCardDesignController;
	kanbanQuickView: KanbanQuickViewController;
	stageModel: StageModelController;
	filter: FilterController;
	fullView: FullViewController;

	constructor() {
		this.subscribers = subscribersController;
		this.reactions = reactionsController;
		this.currentRow = rowController;
		this.modal = modalController;
		this.entity = entityController;
		this.comments = commentsController;
		this.sectionWizzard = sectionWizzardController;
		this.kanbanCardDesign = kanbanCardDesignController;
		this.kanbanQuickView = kanbanQuickViewController;
		this.stageModel = stageModelController;
		this.filter = filterController;
		this.fullView = fullViewController;
	}
}

export const dispatcher = new Dispatcher();