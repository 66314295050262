import { useCallback, useMemo, useState } from "react"
import { observer } from "mobx-react-lite"
import { toJS } from "mobx"
import { v4 } from "uuid"

import { Zone as ZoneMatrix } from "modules/DND/zone"
import { Types as TypesMatrix } from "modules/DND/type"
import { Element } from "modules/DND/element"

import { DraggingAvatars } from "../dragging-avatars"
import { AdditionalFieldForCard ,DragFieldBlock} from "../fields-for-card"

import { AdditionalInfoOfField } from "pages/section-wizzard/pages/quick-view-designer/components/quick-view-settings/data"
import { ColumnType } from "entities/ColumnType"
import { AdditionalField } from "types/entity"

import styles from "./card-designer.module.scss"

export type Props = {
    cardHeader: string;
    additionalFields: Array<AdditionalField>;
    additionalInfoOfField: Map<string, AdditionalInfoOfField>
    usersAvatarEnabled: boolean;
    setPositionsAdditionalFields: (field: AdditionalField, newPosition: number) => void;
    usersAvatars: AdditionalField[];
    setPositionsUsersAvatars: (params: AdditionalField[]) => void;
    onDeleteField: (gridItemId: string) => void;
}

const POST_INIT_TIME = 500;

/**
 * @description Элемент дизайна карточки
 * @param props.cardHeader название записи 
 * @param props.additionalFields вспомогательные поля
 * @param props.additionalInfoOfField дополнительная информация для отображения вспомогательных полей
 * @param props.usersAvatarEnabled отображаются ли аватары
 * @param props.setPositionAdditionalField функция, устанавливающая позицию для вспомогательного поля 
 * @param props.usersAvatars аватары пользователей
 * @param props.setPositionsUsersAvatars функция, устанавливающая позиции для аватаров пользователей  
 * @param props.onDeleteField функция, удаляющая элемент с карточки
 * @returns
 * @example <CardDesigner 
                cardHeader={displayedColumnIFormat()} //import from "../kanban-settings/lib";
                additionalFields={additionalFields} 
                setPositionsAdditionalFields={setPositionsAdditionalFields}		
                usersAvatars={usersAvatars}				
                setPositionsUsersAvatars={setPositionsUsersAvatars}	
            />
        const [additionalFields, setPositionsAdditionalFields] = useState<Array<{order: number} & GridItem>>([
        {order: 0, x: 1, y: 1, gridItemId: "gridItemId1", width: 1, height: 1, type: 0, 
            fieldConfig: { columnId: "columnId1", columnName: "columnName1", columnTitle: "columnTitle1",
                columnType: "String", hasIndex: true, isLookup: false, isRequired: true, lookupTable: null,	}
        },]);		
        const [usersAvatars, setPositionsUsersAvatars] = useState([
        { order: 0, columnId: "column0", name: "User 0" },]);
 */
export const CardDesigner = observer(function (props: Props) {
    const [zoneId] = useState(v4());
    const [placeholderNewField, setPlaceholderNewField] = useState<JSX.Element>(<></>);
    const [targetElementViewLayoutNewField, setTargetElementViewLayoutNewField] = useState<JSX.Element>(<></>);

    /**
     * @description срабатывает при нажатии на крестик во вспомогательном поле
     **/
    const closeField = useCallback((elementId: string) => {
        props.onDeleteField(elementId)
    }, [props.additionalFields]);
    
    /**
     * @description срабатывает при поднятии карточки вспомогательного поля
     **/
    const handleUpField = useCallback(({ elementId }: {
        elementId: string
    }) => {
        const item = props.additionalFields.find((field) => (field.gridItemId ?? field.columnId) === elementId);
        if (item) {

            const fieldInfo = props.additionalInfoOfField.get(item.columnId);

            /**
             * @description откуда взяли элемент
             **/
            setPlaceholderNewField(
                <AdditionalFieldForCard
                    type={fieldInfo?.columnType}
                    columnTitle={fieldInfo?.columnTitle}
                    isRequired={fieldInfo?.isRequired}
                    className={styles.placeholderNewField}
                    closeField={closeField}
                />);
            /**
             * @description наклонная тянучка
             **/
            setTargetElementViewLayoutNewField(
                <AdditionalFieldForCard
                    type={fieldInfo?.columnType}
                    columnTitle={fieldInfo?.columnTitle}
                    isRequired={fieldInfo?.isRequired}
                    className={styles.targetField}
                    closeField={closeField}
                />
            );
        }
    }, [props.additionalFields, placeholderNewField]);

    /**
     * @description срабатывает при отпускании карточки вспомогательного поля
     **/
    const handleDropField = useCallback(({ elementId, MATRIX }: {
        elementId: string | null;
        sourceData?: any;
        [key: string]: any;
    }) => {
        const dragElement = props.additionalFields.find(({ gridItemId }) => gridItemId === elementId);
        if (dragElement) {
            const dragElement = props.additionalFields.find(({ gridItemId }) => gridItemId === elementId);
            if (dragElement) {
                const additionalField: AdditionalField = {
                    ...dragElement,
                    order: MATRIX.cellY,
                }
                props.setPositionsAdditionalFields(additionalField, MATRIX.cellY);
            }
        }
    }, [toJS(props.additionalFields)]);

    const zoneConfig = useMemo(() => {
        return {
            targetElementViewLayout: targetElementViewLayoutNewField,
            placeholderLayout: placeholderNewField,
            predictLayout: <div className={styles.predictLayout} />,
            cellLayout: <div className={styles.cellLayout} />,
            width: 1,
            height: 3
        };
    }, [targetElementViewLayoutNewField, placeholderNewField]);

    return (
        <div>
            <div className={styles.card}>
                <div className={styles.lockedField}>
                    <DragFieldBlock type={ColumnType.Lookup} columnTitle="Приоритет" />
                </div>

                <div className={styles.text}>Название записи</div>
                <div className={styles.lockedField}>
                    <DragFieldBlock type={ColumnType.String} columnTitle={props.cardHeader} />
                </div>

                <div className={styles.text}>Вспомогательные поля</div>
                <ZoneMatrix
                    id={zoneId}
                    config={zoneConfig}
                    type={TypesMatrix.MATRIX}
                    onDrop={handleDropField}
                    postInitTime={POST_INIT_TIME}
                    onUp={handleUpField}
                    cursor="grabbing"
                >
                    {props.additionalFields.filter((field) => field.order !== -1).map((field) => {
                        const fieldInfo = props.additionalInfoOfField.get(field.columnId);
                        return (
                            <Element
                                key={field.gridItemId}
                                id={field.gridItemId}
                                x={1}
                                y={field.order}
                                width={1}
                                height={1}
                            >
                                <AdditionalFieldForCard
                                    type={fieldInfo?.columnType}
                                    columnTitle={fieldInfo?.columnTitle}
                                    isRequired={fieldInfo?.isRequired}
                                    fieldId={field.gridItemId ?? field.columnId}
                                    closeField={closeField}
                                />
                            </Element>)
                    })}
                </ZoneMatrix>
                <DraggingAvatars {...props} />
            </div>
        </div >
    )
});