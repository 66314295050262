import { useEffect, useState } from "react";

import { Button, ButtonStyle } from "components";

import styles from "./tabs.module.css";


export interface ITab {
    caption?: string;
    tooltipCaption?: string;
    icon?: JSX.Element;
    action?: (tabStore?: ITabStore) => void;
    leaveAction?: () => void;
}

export interface ITabStore {
    tab: JSX.Element | null,
    tabSelectedId: number
}

function Tabs(props: {
    items: ITab[], className?: string, tabStore?: ITabStore,
    styleButton?: ButtonStyle,
}) {
    const [selected, setSelected] = useState<number>(0);

    useEffect(() => {

        props.items[0].action?.call(null, props.tabStore);

    }, []);

    function onClick(index: number) {

        if (selected !== index) props.items[selected].leaveAction?.call(null);

        setSelected(index);

        props.items[index].action?.call(null, props.tabStore);
    }

    let className = `${styles.tab} `;
    if (props.className) {
        className += props.className;
    }

    return (
        <div className={className}>
            <div className={styles.itemBar}>
                {
                    props.items.map(
                        (el: ITab, i: number) => {
                            return (
                                <Button
                                    key={i}
                                    caption={el.caption}
                                    firstIcon={el.icon}
                                    className={selected === i ? styles.item + " " + styles.selected : styles.item}
                                    style={props.styleButton}
                                    onClick={() => { onClick(i) }}
                                />
                            );
                        })
                }
            </div>
        </div>
    );
}

export default Tabs;