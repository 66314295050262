import { store } from "store";
import { SubscribeType } from "types";

export class ReactionsController {
	set = (uuid: string, reaction: SubscribeType) => {
		if (store.reactionSubscribers[uuid]) {
			store.reactionSubscribers[uuid] = reaction;
		} else {
			console.error(`uuid: ${uuid} в store>reactionSubscribers не существует [setReaction]`);
		}
	};
	reset = (uuid: string) => {
		if (store.reactionSubscribers[uuid]) {
			store.reactionSubscribers[uuid] = SubscribeType.NONE;
		} else {
			console.error(`uuid: ${uuid} в store>reactionSubscribers не существует [resetReaction]`);
		}
	};
	remove = () => {};
}

export const reactionsController = new ReactionsController();