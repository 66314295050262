import { Api } from "shared/api/class-api";

class Stage extends Api {
	basicPath: string;
	constructor() {
		super();
		this.basicPath = "/api/Stage";
	}

	getSectionFromDesigner = (entityName: string) => {
		this.setPath(`${this.basicPath}?entityName=${entityName}`);
		return this;
	};
}

const stage = new Stage();

export { stage };
