import { ErrorTriangle } from "shared";
import styles from "./ErrorMessage.module.css";
import { ERROR_4XX, ERROR_5XX, INVATION_EXPIRED } from "../login-consts";

function ErrorMessage(props: { errorStatus: string }) {
	const error4 = props.errorStatus === ERROR_4XX;
	const error5 = props.errorStatus === ERROR_5XX;
	const invationExpired = props.errorStatus === INVATION_EXPIRED;

	return (
		<div className={styles.ErrorBody}>
			<ul className={styles.title}>
				{/* <li>Не удалось войти в систему</li> */}
				{invationExpired ? <li>Ссылка больше не действительна</li> : <li>Не удалось войти в систему</li>}
			</ul>
			<div>
				{error4 && (
					<ul className={styles.infoUl}>
						<li className={styles.info}>Проверьте правильность введенного адреса электронной почты и пароля;</li>
						<li className={styles.info}>Возможно, ваша учетная запись деактивирована.</li>
					</ul>
				)}
				{error5 && (
					<ul className={styles.infoUl}>
						<li className={styles.info}>Что-то пошло не так</li>
					</ul>
				)}
			</div>
		</div>
	);
}

export default ErrorMessage;
