import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import { toJS } from 'mobx';

import importStore from 'pages/import/core/import-store';

import { DataMappingGrid, RequiredFieldsBlock } from 'pages/import/features';

import styles from './mapping-values.module.css';

const idColumnName = 'Id';

function Mapping() {
    const [choosing, setChoosing] = useState<number>(0);

    const requiredList = useMemo(() => {
        return importStore.properties.filter(
            (property) => property.isRequired && property.columnName !== idColumnName
        );
    }, [toJS(importStore.properties)]);

    const usingColumns = useMemo(() => {
        return importStore.result.mappingProperties.map((property) => {
            return property.columnInModel;
        });
    }, [toJS(importStore.result.mappingProperties)]);

    useEffect(() => {
        const filteredList = requiredList.filter((column) => usingColumns.includes(column.columnName))
        setChoosing(filteredList.length);
    }, [toJS(usingColumns), toJS(requiredList)]);

    return (
        <div className={styles.wrapper}>
            <span className={styles.title}>Укажите соответствие колонок</span>
            <div className={styles.bodyMapping}>
                {importStore.requiredFields && (
                    <RequiredFieldsBlock
                        requiredList={requiredList}
                        usingColumns={usingColumns}
                        choosing={choosing}
                    />
                )}
                <DataMappingGrid items={importStore.fileInfo.map((info) => info.value)} />
            </div>
        </div>
    );
}

export default observer(Mapping);
