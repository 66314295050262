import { observer } from "mobx-react";
import { useEffect, useMemo, useState } from "react";
import { toJS } from "mobx";

import { dispatcher, selector } from "store";

import { CardDesignerBlock } from "pages/section-wizzard/components/card-designer-block/card-designer-block";
import { CardDesigner, CardDesignerSettingPanel, KanbanPreview } from "./components";
import TypeIcon from "features/type-icon/type-icon";
import { userAvatarCardDesignerAdapter, additionalInfoOfFieldsCardDesigner, gridItemsCardDesignerAdapter, additionalFieldsCardDesignerPreviewAdapter } from "./lib";
import { GridItem } from "types/entity";

import styles from './card-designer-constructor.module.scss';


export const CardDesignerConstructor = observer(() => {
    const lookupTableUser = "User";
    /**
    * @description hasUsersAvatar | Признак наличия отображения аватаров - логическое значение
    */
    const [hasUsersAvatar, setHasUsersAvatar] = useState(false);

    /**
    * @description hasQuickActions | Признак наличия отображения быстрых действий - логическое значение
    */
    const [hasQuickActions, setHasQuickActions] = useState(false);

    useEffect(() => {
        dispatcher.kanbanCardDesign.init();
    }, [])

    /**
    * @description userAvatarEnable - флаг, отвечающий за отображение аватаров
    */
    const userAvatarEnable = useMemo(() => {
        return selector.kanbanCardDesign.getCardDesign()?.userAvatarEnable ?? false;
    }, [toJS(selector.kanbanCardDesign.getCardDesign()?.userAvatarEnable)]);

    /**
    * @description quickActionEnable - флаг, отвечающий за отображение быстрых действий
    */
    const quickActionEnable = useMemo(() => {
        return selector.kanbanCardDesign.getCardDesign()?.quickActionEnable ?? false;
    }, [toJS(selector.kanbanCardDesign.getCardDesign()?.quickActionEnable)]);

    /**
    * @description fields | Модели полей - данные на основании которых мы отображаем информацию в source-panel 
    * - это gridItems из sectionWizzard
    */
    const fields = useMemo(() => {
        return dispatcher.sectionWizzard.getAllGridItems();
    }, [toJS(dispatcher.sectionWizzard.getAllGridItems())]);

    /**
   * @description existfields | Модели полей - данные на основании которых мы отображаем информацию о сущестующих полях в source-panel 
   * - это IGridItem[], преобразованные gridItems из sectionWizzard
   */
    const cardHeader = useMemo(() => {
        const viewColumnId = dispatcher.sectionWizzard.getSectionWizzard()?.viewColumnId
        return fields.find(field => field?.fieldConfig?.columnId === viewColumnId)?.fieldConfig?.columnTitle ?? "Название записи";
    }, [toJS(fields)]);

    /**
    * @description usersColumn | Модель колонок с пользователями - извлеченные из gridItems 
    * справочные поля с lookupTitle === “Users”
    */
    const usersColumn = useMemo(() => {
        const result: GridItem[] = [];
        fields.forEach((item) => {
            if (item.fieldConfig?.lookupTable === lookupTableUser) {
                result.push(item);
                return;
            }
            item.groupFieldsConfig?.inner?.items.forEach(innerItem => {
                if (innerItem.fieldConfig?.lookupTable === lookupTableUser) {
                    result.push(innerItem);
                    return;
                }
            })
        });
        return result;
    }, [toJS(fields)]);

    /**
    * @description additionFields | Модели дизайнера карточки - специфичные данные дизайнера карточки, 
    * такие как additionalFields(Список вспомогательных полей карточки), additionFields { order, columnId }
    */
    const additionFields = useMemo(() => {
        return selector.kanbanCardDesign.getCardDesign()?.additionalFields ?? [];
    }, [toJS(selector.kanbanCardDesign.getCardDesign()?.additionalFields)]);

    /**
    * @description usersAvatar | Модель отображения аватарок пользователя - список idшников колонок справочных полей 
    * из модели колонок с пользователями
    */
    const userAvatarsInConfig = useMemo(() => {
        return selector.kanbanCardDesign.getCardDesign()?.userFields ?? [];
    }, [toJS(usersColumn), toJS(selector.kanbanCardDesign.getCardDesign()?.userFields)]);

    /**
    * @description usersAvatar | Модель отображения cписка пользователей в "поля с пользователями"
    * из модели колонок с пользователями
    */
    const userAvatars = useMemo(() => {
        return userAvatarCardDesignerAdapter(gridItemsCardDesignerAdapter(usersColumn), userAvatarsInConfig);
    }, [toJS(usersColumn), toJS(userAvatarsInConfig)]);

    /**
     * @description existfields | Модели полей - данные на основании которых мы отображаем информацию о сущестующих полях в source-panel 
     * - это IGridItem[], преобразованные gridItems из sectionWizzard
     */
    const existFields = useMemo(() => {
        const priorityField = selector.sectionWizzard.getPriorityFieldBySpecialization()?.fieldConfig?.columnId;
        const existFields = gridItemsCardDesignerAdapter(fields).map(field => {
            const updatedField = field;
            const viewColumnId = dispatcher.sectionWizzard.getSectionWizzard()?.viewColumnId;

            if (additionFields.find(additionField => additionField.columnId === field.columnId)
                || userAvatarsInConfig.find(userField => userField.columnId === field.columnId)
                || field.columnId === priorityField
                || field.columnId === viewColumnId
            ) {
                updatedField.isLocked = false;
                updatedField.isDisabled = false;
            }
            return {
                ...updatedField,
                icon: <TypeIcon type={field.columnType} />
            }
        })
        return existFields;
    }, [toJS(fields), toJS(additionFields), toJS(userAvatarsInConfig)]);

    /**
    * @description demoData | Демо данные для предпоказа - генерировать их надо на основе текущих заполненных данных, 
    * которые помещаются в утилиту demoDataGenerator({additionFields, userAvatar, hasUserAvatar, hasQueckActions})
    */
    const demoData = useMemo(() => {
        // return demoDataGenerator();
    }, []);

    /**
    * @description additionalInfoOfField | Дополнительная информация, необходимая для отображения полей из CardDesign
    */
    const additionalInfoOfField = useMemo(() => {
        return additionalInfoOfFieldsCardDesigner(existFields);
    }, [toJS(existFields)]);

    /**
    * @description additionFieldsPreview | Список вспомогательных полей для Preview 
    */
    const additionFieldsPreview = useMemo(() => {
        return additionalFieldsCardDesignerPreviewAdapter(existFields, additionFields);
    }, [toJS(additionFields)]);

    /**
    * @description userFieldsPreview | Список полей юзеров для Preview 
    */
    const userFieldsPreview = useMemo(() => {
        return userAvatarEnable ? additionalFieldsCardDesignerPreviewAdapter(existFields, userAvatarsInConfig) : []
    }, [userAvatarEnable, toJS(userAvatarsInConfig)]);

    const quickActionsPreview = useMemo(() => selector.kanbanCardDesign.getCardDesign()?.selectedQuickActions
        ?.filter(quickAction => quickAction.isEnabled).map(quickAction => quickAction.action) ?? []
        , [toJS(selector.kanbanCardDesign.getCardDesign()?.selectedQuickActions)]);

    const resetQuickViewSettingsEnable = useMemo(() => {
        return dispatcher.kanbanCardDesign.hasChanges;
    }, [dispatcher.kanbanCardDesign.hasChanges]);

    //TODO раскомментировать, когда будут готовы компоненты
    return (
        <>
            <div className={styles.cardDesignerBody}>
                <CardDesignerBlock name='Дизайн'>
                    <CardDesigner
                        cardHeader={cardHeader}
                        usersAvatarEnabled={userAvatarEnable}
                        additionalFields={additionFields}
                        additionalInfoOfField={additionalInfoOfField}
                        usersAvatars={userAvatarsInConfig}
                        setPositionsAdditionalFields={dispatcher.kanbanCardDesign.updateAdditionalField}
                        onDeleteField={dispatcher.kanbanCardDesign.deleteField}
                        setPositionsUsersAvatars={dispatcher.kanbanCardDesign.setPositionsUsersAvatars}
                    />
                </CardDesignerBlock>
                <CardDesignerBlock name='Предпросмотр' className={styles.cardBlockPreview}>
                    <KanbanPreview
                        name={cardHeader}
                        additionFields={additionFieldsPreview}
                        usersAvatar={userFieldsPreview}
                        quickActions={quickActionsPreview}
                        quickActionEnable={quickActionEnable}
                    />
                </CardDesignerBlock>
            </div>
            <CardDesignerSettingPanel
                fields={existFields}
                usersAvatars={userAvatars}
                additionalInfoOfField={additionalInfoOfField}
                usersAvatarEnabled={userAvatarEnable}
                quickActionsEnabled={quickActionEnable}
                resetQuickViewSettingsEnable={resetQuickViewSettingsEnable}
                addField={dispatcher.kanbanCardDesign.setAdditionalField}
                setUserAvatarsEnable={dispatcher.kanbanCardDesign.setUserAvatarsEnable}
                resetSettings={dispatcher.kanbanCardDesign.resetCardDesignSettings}
                onQuickActionEnable={dispatcher.kanbanCardDesign.setQuickActionEnable}
                onUserAvatarEnable={dispatcher.kanbanCardDesign.setUserAvatarEnable}
            />
        </>
    );

});
