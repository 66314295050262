import styles from "./Flex.module.css";
import IProps from "../../IProps";
import { bindingInXml } from "module/reactor/binding/binding";

interface FlexProps extends IProps {
    direction?: "column" | "row"
    gap?: number;
    paddingLeft?: number;
    paddingTop?: number;
    paddingRight?: number;
    paddingBottom?: number;
    marginTop?: number;
    borderSize?: number;
    borderColor?: string;
    borderRadius?: number;
    alignItems?: "center" | "flex-end" | "flex-start";
    height?: string;
}

function Flex(props: FlexProps) {
    const gap = props.gap ? props.gap + "px" : undefined;
    const paddingLeft = props.paddingLeft ? props.paddingLeft + "px" : undefined;
    const paddingTop = props.paddingTop ? props.paddingTop + "px" : undefined;
    const paddingRight = props.paddingRight ? props.paddingRight + "px" : undefined;
    const paddingBottom = props.paddingBottom ? props.paddingBottom + "px" : undefined;
    const borderSize = props.borderSize ? props.borderSize + "px" : undefined;
    const borderRadius = props.borderRadius ? props.borderRadius + "px" : undefined;
    const marginTop = props.marginTop ? props.marginTop + "px" : undefined;
    const height = props.height ? props.height : undefined;
    const borderColor = bindingInXml(props.services, props.borderColor);

    return (
        <div
            className={styles.body}
            style={{
                flexDirection: props.direction,
                gap: gap,
                paddingLeft: paddingLeft,
                paddingTop: paddingTop,
                paddingRight: paddingRight,
                paddingBottom: paddingBottom,
                alignItems: props.alignItems,
                height: height,
                border: `${borderSize} solid ${borderColor}`,
                borderRadius: `${borderRadius}`,
                marginTop: `${marginTop}`
            }}
        >
            {props.children}
        </div>
    )
}

export default Flex;