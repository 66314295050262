import { isNull, isUndefined, lowerFirst } from "lodash";
import { toJS } from "mobx";
import { CSSProperties, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 } from "uuid";

import { stopwatchStore } from "features/stopwatch/store";
import { binding, bindingInXml } from "module/reactor/binding/binding";
import { dispatcher, selector, store } from "store";
import { NEW_RECORD } from "synchroiser/synchroiser";
import { ClosePopup } from "./warning-popup";

import { Position, modalController } from "features/modals";
import { ModalType } from "features/modals/viewer/modal-viewer";
import { useCopyToClipboard } from "shared/hooks/use-copy-to-clipboard";

import { StagePanel, StageParams } from "features/stage-panel";
import { FlagPopup } from "pages/single-page/components/flag-popup";
import { TimeLoggingPopup } from "pages/single-page/components/time-logging-popup";
import { Button } from "sale-bridge-ui-kit";
import { ActionButtonsFullView } from "./action-buttons-full-view/action-buttons-full-view";
import { ActionsDropdownFullView } from "./actions-dropdown-full-view/actions-dropdown-full-view";

import IProps from "module/reactor/components/IProps";
import { MovingRule, StageId, StageSetting, StageType, Stages } from "types/entity";

import { ArrowToRightStretch } from "shared";

import styles from "./full-view.module.scss";

interface FullViewProps extends IProps {
	entityTitle: string;
	navigation: string;
	viewColumnId: string;
	onSave?: string;
	onCancel?: string;
	isChange?: string;
	setCurrentStage: () => void;
	currentStage: StageId;
}

function FullView(props: FullViewProps) {
	const [idModal] = useState<string>(v4());
	const navigate = useNavigate();
	const location = useLocation();
	const [copiedText, copyFn, isCopied] = useCopyToClipboard();

	const entityTitle = binding(props.services, props.entityTitle);
	const navigation = bindingInXml(props.services, props.navigation);
	const viewColumnId = bindingInXml(props.services, props.viewColumnId);

	const [currentStageId, setCurrentStage] = useState<string | undefined>();

	const isChange = store.hasChanges;

	const entity = useMemo(() => dispatcher.entity.get()!, [dispatcher.entity.get(), viewColumnId]);
	const row = useMemo(() => dispatcher.currentRow.get()!, [toJS(dispatcher.currentRow.get()), viewColumnId]);

	const isNew = useMemo(() => row?.id === NEW_RECORD, [row?.id]);

	const title = dispatcher.currentRow.getTitle();

	//TODO оставила, вдруг пригодится информация о последнем изменении записи
	// const modified = useMemo(() => (row && row[LowFirst(SystemColumns.ModifiedOn)]) ?
	//     <div className={styles.modifiedOn}>изменено: {getModifiedOn(row[LowFirst(SystemColumns.ModifiedOn)])}</div>
	//     : <></>
	//     , [row]);

	const sectionWizzard = useMemo(
		() => dispatcher.entity.get()?.entity.sectionWizzard,
		[toJS(dispatcher.entity.get()?.entity.sectionWizzard)]
	);
	const singlePagePath = useMemo(
		() => `/singlePage/${dispatcher.entity.get()?.entity.sectionWizzard?.systemName}`.toLowerCase(),
		[toJS(dispatcher.entity.get()?.entity.sectionWizzard)]
	);

	const sysFlagField = useMemo(
		() => lowerFirst(selector.sectionWizzard.getSysFlagColumn()?.fieldConfig?.columnName),
		[selector.sectionWizzard.getSysFlagColumn()]
	);

	const convertToStageParams = useCallback(
		(stages: Stages[], movingRules: MovingRule[], stageSettings: StageSetting[]) => {
			const currentStageSetting =
				sectionWizzard!.stageModelConfig?.stageSettings.find((stageSetting) => stageSetting.stageId === currentStageId) ??
				sectionWizzard!.stageModelConfig?.stageSettings.find((stageSetting) => stageSetting.order === 0);

			if (isNull(currentStageSetting)) return [];
			return stages.map((stage) => {
				const matchingRule = movingRules.filter((rule) => rule.stageFromId === stage.id);
				const matchingSetting = stageSettings.find((setting) => setting.stageId === stage.id);

				const stagesTo: Array<string> = [];
				matchingRule?.forEach((rule) => {
					stagesTo.push(rule.stageToId);
				});

				const stageParams: StageParams = {
					id: v4(),
					name: stage.name,
					stageId: stage.id,
					color: matchingSetting!.color,
					order: matchingSetting!.order,
					allowStages: stagesTo,
					disabled: stage.id === currentStageId ? true : undefined,
					stageType:
						stage.id === currentStageId
							? StageType.CURRENT
							: matchingSetting!.order > currentStageSetting!.order
							? StageType.DEFAULT
							: StageType.PASSED,
					resultType: matchingSetting ? matchingSetting.resultType : null,
					isHidden: matchingSetting?.isHidden ?? false
				};

				return stageParams;
			});
		},
		[toJS(sectionWizzard!.stageModelConfig?.stageSettings), currentStageId]
	);

	const intermediateStages = useMemo(
		() =>
			sectionWizzard?.stageModelConfig &&
			convertToStageParams(
				sectionWizzard?.stageModelConfig.stages ?? [],
				sectionWizzard?.stageModelConfig.movingRules ?? [],
				sectionWizzard?.stageModelConfig.stageSettings ?? []
			)
				.filter((x) => x.resultType === null)
				.sort((a, b) => a.order - b.order),
		[sectionWizzard?.stageModelConfig]
	);

	const resultStages = useMemo(
		() =>
			sectionWizzard?.stageModelConfig &&
			convertToStageParams(
				sectionWizzard?.stageModelConfig.stages ?? [],
				sectionWizzard?.stageModelConfig.movingRules ?? [],
				sectionWizzard?.stageModelConfig.stageSettings ?? []
			).filter((x) => x.resultType !== null),
		[sectionWizzard?.stageModelConfig]
	);

	useEffect(() => {
		if (location?.state?.entityColumn?.toLowerCase() !== "id") {
			return;
		}
		if (sectionWizzard?.stageModelConfig && sectionWizzard.hasStageModel && dispatcher.currentRow.get()) {
			const stageId = dispatcher.currentRow.get()[lowerFirst(sectionWizzard?.stageModelConfig?.stageLookupName)];
			if (isUndefined(stageId)) {
				dispatcher.currentRow.set(lowerFirst(sectionWizzard?.stageModelConfig?.stageLookupName), currentStageId, false);
			}
		}
		const currentRow = dispatcher.currentRow.get();
		const entityColumnName = Object.keys(currentRow).find(
			(key) => key.toLowerCase() === `${location?.state?.detailLinkColumn}`.toLowerCase()
		);

		if (entityColumnName) {
			const defaultValue = {
				id: location?.state?.entityViewColumn?.id,
				name: location?.state?.entityViewColumn?.displayValue,
				displayValue: location?.state?.entityViewColumn?.displayValue
			};
			dispatcher.currentRow.set(entityColumnName, defaultValue);
		}
	}, []);

	/**
	 * @description отслеживаем изменение стадии
	 * */
	useEffect(() => {
		let current = dispatcher.currentRow.get()
			? dispatcher.currentRow.get()[lowerFirst(sectionWizzard?.stageModelConfig?.stageLookupName)]?.id
			: sectionWizzard?.stageModelConfig?.stageSettings.find((stage) => stage.order === 0)?.stageId;
		if (isUndefined(current) && intermediateStages) {
			const currentStage = {
				id: intermediateStages[0].stageId,
				name: intermediateStages[0].name
			};
			dispatcher.currentRow.set(lowerFirst(sectionWizzard?.stageModelConfig?.stageLookupName), currentStage);
			current = currentStage.id;
		}
		setCurrentStage(current);
	}, [
		toJS(dispatcher.currentRow.get() ? dispatcher.currentRow.get()[lowerFirst(sectionWizzard?.stageModelConfig?.stageLookupName)] : {})
	]);

	const fullViewStyle: CSSProperties = useMemo(
		() =>
			intermediateStages || resultStages
				? {
						gridTemplateRows: `auto auto 1fr`
				  }
				: {
						gridTemplateRows: `auto 1fr`
				  },
		[intermediateStages, resultStages]
	);

	const onSave: () => void = () => {
		const response = binding(props.services, props.onSave)();
		response
			.then((recordId: any) => {
				if (isNew) {
					navigate(`${singlePagePath}/${recordId}`);
				}
				modalController.notificationAdd({
					id: v4(),
					type: ModalType.NOTIFICATION,
					position: Position.CENTER,
					layout: <div>Запись сохранена</div>,
					allowTimer: true,
					allowDefaultClick: true,
					withBackdrop: false
				});
			})
			.catch((error: any) => {
				console.error(`SinglePage onSave error: ${error}`);
				modalController.notificationAdd({
					id: v4(),
					type: ModalType.ERROR_NOTIFICATION,
					position: Position.CENTER,
					layout: <>Что-то пошло не так</>
				});
			});
	};

	const onCancel: () => void = binding(props.services, props.onCancel);

	const closeConfirm = useCallback((e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
		e?.stopPropagation();
		modalController.modalRemove(idModal);
	}, []);

	const handleChangeCurrentStageId = useCallback(
		(stageId: string) => {
			if (!sectionWizzard?.stageModelConfig?.stageLookupName) {
				return;
			}
			const stage =
				intermediateStages?.find((stage) => stage.stageId === stageId) ?? resultStages?.find((stage) => stage.stageId === stageId);

			const selectedStage = {
				id: stage?.stageId,
				name: stage?.name
			};

			dispatcher.currentRow.set(lowerFirst(sectionWizzard?.stageModelConfig?.stageLookupName), selectedStage);
		},
		[intermediateStages, resultStages, sectionWizzard?.stageModelConfig?.stageLookupName]
	);

	const handleCancel = useCallback(() => {
		if (isChange && !isNew) {
			onCancel();
			return;
		}
		if (isChange && isNew) {
			modalController.popupAdd({
				id: idModal,
				layout: <ClosePopup onClose={navigateBack} closeConfirm={closeConfirm} />,
				closeFunc: closeConfirm
			});
			return;
		}
		navigateBack();
	}, [isChange, isNew, onCancel]);

	const navigateToEntity = () => {
		const arrayOfNavigation = navigation.split("/");
		arrayOfNavigation[arrayOfNavigation.length - 1] = arrayOfNavigation[arrayOfNavigation.length - 1].toLowerCase();

		navigate(arrayOfNavigation.join("/"));
		if (isNew) {
			dispatcher.entity.get()?.entity.rows.pop();
		}
	};

	const navigateBack = () => {
		if (isNew) {
			const index = dispatcher.entity.get()?.entity.rows.indexOf(dispatcher.currentRow.get());
			if (index && index > -1) {
				//TODO почему-то это не убирает элемент из массива
				dispatcher.entity.get()!.entity.rows.splice(index, 1);
			}
		}
		navigate(singlePagePath);
	};

	const handleCopyLink = useCallback(async () => {
		if (!isNew) {
			await copyFn(window.location.href);
		}
	}, [isNew]);

	const handleFlagClick = useCallback(() => {
		if (!isNew) {
			let currentFlagValue = row[sysFlagField];
			modalController.popupAdd({
				id: idModal,
				layout: <FlagPopup recordId={row.id} mode={currentFlagValue ? "remove" : "add"} close={closeConfirm} />,
				closeFunc: closeConfirm
			});
		}
	}, [isNew, row, row[sysFlagField]]);

	const handleLogTimeClick = useCallback(() => {
		if (!isNew) {
			modalController.popupAdd({
				id: idModal,
				layout: <TimeLoggingPopup recordId={row.id} recordTitle={title} close={closeConfirm} />,
				closeFunc: closeConfirm
			});
		}
	}, [isNew, row, row[sysFlagField]]);

	const handleStopwatchPlayClick = useCallback(() => {
		if (!isNew) {
			stopwatchStore.startStopwatch(row.id);
		}
	}, [isNew, row.id]);

	const handleStopwatchPauseClick = useCallback(() => {
		if (!isNew) {
			stopwatchStore.pauseStopwatch(row.id);
		}
	}, [isNew, row.id]);

	const handleStopwatchStopClick = useCallback(() => {
		if (!isNew) {
			const resultInMinutesFromStopwatch = stopwatchStore.stopStopwatch(row.id);
			stopwatchStore.removeStopwatch(row.id);
			modalController.popupAdd({
				id: idModal,
				layout: (
					<TimeLoggingPopup
						recordId={row.id}
						recordTitle={title}
						timeFromStopwatch={resultInMinutesFromStopwatch.toString()}
						close={closeConfirm}
					/>
				),
				closeFunc: closeConfirm
			});
		}
	}, [isNew, row.id]);

	if (!entity || !row) {
		return null;
	}

	return (
		<div className={styles.fullView} style={fullViewStyle}>
			<div className={styles.fullViewHeader}>
				<div className={styles.fullViewHeaderTop}>
					<div className={styles.fullViewTitle}>
						<span className={styles.fullViewTitleSection} onClick={navigateToEntity}>
							{entityTitle}
						</span>
						<ArrowToRightStretch />
						<span className={styles.fullViewTitleDetail}>{title as string}</span>
					</div>
					{/* TODO вернуть, если понадобится информация о последнем изменении записи */}
					{/* {modified} */}
					<ActionButtonsFullView
						isCopied={isCopied}
						copyLink={handleCopyLink}
						flagClick={handleFlagClick}
						logTimeClick={handleLogTimeClick}
						stopwatchClick={handleStopwatchPlayClick}
					/>
				</div>
				<div className={styles.fullViewHeaderFooter}>
					<div className={styles.buttons}>
						<Button
							text={isChange && !isNew ? "Отменить" : "Закрыть"}
							onClick={handleCancel}
							leftIcon={isChange && !isNew ? "Undo" : "Close"}
							size="small"
							variant="default"
							link={false}
							loading={false}
							border
						/>
						<Button
							text="Сохранить запись"
							onClick={onSave}
							size="small"
							variant={!store.requiredFieldsFilled || !isChange ? "disabled" : "primary"}
							leftIcon="SaveSolid"
							iconSize="16"
							link={false}
							loading={false}
							border
						/>
					</div>
					{/* ButtonDropdown Действия */}
					<ActionsDropdownFullView
						copyLink={handleCopyLink}
						flagClick={handleFlagClick}
						logTimeClick={handleLogTimeClick}
						stopwatchStartClick={handleStopwatchPlayClick}
						stopwatchPauseClick={handleStopwatchPauseClick}
						stopwatchStopClick={handleStopwatchStopClick}
					/>
				</div>
			</div>
			{sectionWizzard?.stageModelConfig && (
				<StagePanel
					intermediate={intermediateStages!}
					result={resultStages!}
					onChange={handleChangeCurrentStageId}
					current={currentStageId ?? (intermediateStages ? intermediateStages[0].stageId : "")}
				/>
			)}
			<div className={styles.fullViewContent}>{props.children}</div>
		</div>
	);
}

export default FullView;
