import { useState } from "react";
import { observer } from "mobx-react";

import { Dialog, Button, ButtonStyle, DataGrid } from "components";
import { ErrorDownloadColumns } from "../data/error-download-columns";

import ImportStore from "entities/import/ImportStore";

import styles from "./error-download.module.css";

const ErrorDownload = observer((props: { importStore: ImportStore }) => {
    const [open, setOpen] = useState<boolean>(true);
    const dialogClose = () => {
        setOpen(false);
        props.importStore.errorUploadFile = [];
    }

    return (<Dialog title="Некоторые файлы не были загружены" isOpen={open} onClick={() => { setOpen(false) }} className={styles.dialog}
        dialogFooterButtons={
            <Button caption={'Понятно'} style={ButtonStyle.Primary} onClick={() => { dialogClose() }} />
        }
    >
        <div>
            <DataGrid columns={ErrorDownloadColumns} otherData={props.importStore.errorUploadFile} />
        </div>
    </Dialog>)
})

export default ErrorDownload;