import { observer } from "mobx-react";
import { useCallback } from "react";

import { CloseMaxi } from "shared";

import styles from "./edit-numerator-popup.module.scss";

type EditNumeratorPopupProps = {
	children: JSX.Element;
	close: () => void;
};

export const EditNumeratorPopup = observer((props: EditNumeratorPopupProps) => {
	const handleCancel = useCallback(() => {
		props.close();
	}, [props.close]);

	return (
		<div className={styles.dialog}>
			<div className={styles.header}>
				<span className={styles.title}>Редактирование</span>
				<CloseMaxi className={styles.closeButton} onClick={handleCancel} />
			</div>
			<div className={styles.dialogBody}>{props.children}</div>
		</div>
	);
});
