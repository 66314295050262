import { CSSProperties, useCallback, useEffect, useMemo, useState } from "react";
import { toJS } from "mobx";

import { LowFirst } from "shared";
import { dispatcher } from "store";

import { Item } from "types";
import { InputTypes, ParsedProps, Props, inputs } from "./data/data";
import { BlockedColumnNames } from "types/entity";

import styles from "./autoInputByType.module.scss";

export function AutoInputByType(props: Props) {
	const parsedProps: ParsedProps = JSON.parse(props.field);
	const UsageInput: InputTypes | undefined = useMemo(
		() => parsedProps.fieldConfig?.columnType && inputs[parsedProps.fieldConfig?.columnType],
		[parsedProps.fieldConfig?.columnType]
	);

	const style: CSSProperties = useMemo(
		() => ({
			gridRow: parsedProps.y,
			gridColumn: parsedProps.x
		}),
		[parsedProps.y, parsedProps.x]
	);

	const entity = useMemo(() => dispatcher.currentRow.get(), [dispatcher.currentRow.get()]);
	const { fieldConfig } = parsedProps;
	const cellTitle = LowFirst(fieldConfig?.columnName ?? "");
	const isLocked = useMemo(
		() => BlockedColumnNames.some((columnName) => columnName.toLowerCase() === cellTitle.toLowerCase()) || fieldConfig?.uneditable,
		[cellTitle, fieldConfig?.uneditable]
	);

	const [value, setValue] = useState<any>();

	useEffect(() => {
		if (entity) {
			setValue(entity[cellTitle]);
		}
	}, [toJS(entity[cellTitle])]);

	const handleChange = useCallback(
		(value: string | Item | boolean | null) => {
			setValue(value);
			dispatcher.currentRow.set(LowFirst(fieldConfig?.columnName ?? ""), value);
		},
		[fieldConfig?.columnName]
	);

	return (
		<div style={style} className={styles.usageInputContainer}>
			{UsageInput ? (
				<UsageInput
					columnName={fieldConfig?.columnName}
					columnTitle={fieldConfig?.columnTitle}
					value={value}
					onChange={handleChange}
					isRequired={fieldConfig?.isRequired}
					prompt={fieldConfig?.prompt}
					rounding={fieldConfig?.specializations?.properties.rounding}
					lookupTable={fieldConfig?.lookupTable}
					isDisabled={isLocked}
					multiline={fieldConfig?.specializations?.properties.multiline}
					variant={props.variant ?? "standart"}
					labelPosition={props.labelPosition ?? "vertical"}
					labelVariant={props.labelVariant}
					linkTarget="_self"
				/>
			) : (
				<div />
			)}
		</div>
	);
}
