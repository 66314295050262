import classNames from "classnames";
import { observer } from "mobx-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { toJS } from "mobx";

import authStore from "AuthStore";
import { dispatcher, store } from "store";
import { synchroiser } from "synchroiser";
import { ReactionWrapper } from "modules/subscribeReactions";
import { useSortableData } from "shared/hooks/use-sortable-data";

import { CommentItem, CommentsHeader } from "./components";
import { useCommentContext } from "..";

import { SortDirection } from "entities/ISort";
import { Item } from "types";
import { Comment } from "types/entity";

import styles from "./comments-body.module.scss";

export enum CommentBodyVariant {
	QuickView,
	FullView
}

type CommentBody = {
	blockVariant: CommentBodyVariant;
	entityName?: string;
	recordId?: string;
};

export const CommentsBody = observer(function (props: CommentBody) {
	const { updateCommentIdAndText } = useCommentContext();
	const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.Descending);
	const [userToFilter, setUserToFilter] = useState<Item | null>(null);
	const [comments, setComments] = useState<Array<Comment>>([]);

	useEffect(() => {
		synchroiser.getComments(props.entityName, props.recordId);
		setComments(dispatcher.comments.get() || []);
		updateCommentIdAndText({ comment: { text: "" } });
	}, [props.recordId]);

	useEffect(() => {
		setComments(dispatcher.comments.get() || []);
	}, [toJS(dispatcher.comments.get())]);

	const headerClassNames = classNames(styles.header, {
		[`${styles.fullViewHeader}`]: props.blockVariant == CommentBodyVariant.FullView
	});

	const commentsBodyClassNames = classNames(styles.commentsBody, {
		[`${styles.fullViewCommentsBody}`]: props.blockVariant == CommentBodyVariant.FullView
	});

	const handleChangeSort = useCallback(() => {
		setSortDirection(sortDirection == SortDirection.Descending ? SortDirection.Ascending : SortDirection.Descending);
	}, [sortDirection, toJS(comments)]);

	const handleChangeUserToFilter = useCallback((user: Item | null) => {
		setUserToFilter(user);
	}, []);

	const updateComment = useCallback(
		(id: string) => {
			const editableText = comments.slice().find((comment) => comment.id === id)?.text;
			if (editableText) {
				if (authStore.loggedIn && authStore.userId) {
					updateCommentIdAndText({ comment: { text: editableText, commentId: id } });
				}
			}
		},
		[toJS(comments), authStore.loggedIn, authStore.userId]
	);

	const commentsElements = useMemo(() => {
		let filteredComments = useSortableData(comments, "createdOn", sortDirection);

		if (userToFilter) {
			filteredComments = filteredComments.filter((comment) => comment.userId === userToFilter.id);
		}

		return filteredComments.map((comment) => (
			<ReactionWrapper uuid={comment.id} type="message" key={comment.id}>
				<CommentItem
					key={comment.id}
					id={comment.id}
					entityName={comment.entityName}
					onDeleteComment={synchroiser.deleteComment}
					onUpdateComments={updateComment}
					createdOn={comment.createdOn}
					modifiedOn={comment.modifiedOn}
					text={comment.text}
					userId={comment.userId}
					userName={comment.userName}
					isOwner={comment.isOwner}
				/>
			</ReactionWrapper>
		));
	}, [toJS(comments), toJS(store.reactionSubscribers), userToFilter, sortDirection]);

	return (
		<div className={styles.wrapper}>
			<div className={headerClassNames}>
				<CommentsHeader
					userFromFilter={userToFilter}
					sortDirection={sortDirection}
					onSetUserToFilter={handleChangeUserToFilter}
					onSort={handleChangeSort}
				/>
			</div>
			<div className={commentsBodyClassNames}>
				{commentsElements.length == 0 ? <span className={styles.commentsBodyEmpty}>Комментариев нет</span> : commentsElements}
			</div>
		</div>
	);
});
