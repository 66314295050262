import classNames from "classnames";

import styles from "./BigButton.module.css";

interface BigButtonProps {
    title: string;
    className?: string;
    onClick: () => void;

    isSelected?: boolean;
    isActive?: boolean;
}

function BigButton(props: BigButtonProps) {
    const classNamesWrapper = classNames({
        [`${styles.body} ${styles.vars} `]: true,
        [`${props.className} `]: props.className,
        [`${styles.active} `]: props.isActive,
        [`${styles.selected} `]: props.isSelected,

    });

    return (
        <div className={classNamesWrapper} onClick={props.onClick}>
            <span>{props.title}</span>
        </div>
    );
}

export default BigButton;