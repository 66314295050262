import { FormatValue } from "./format-value";
import { NavLink } from "react-router-dom";

import { ValueProps } from "./data";
import { ColumnType } from "entities/ColumnType";

import styles from '../universal-grid/universal-grid.module.scss'

export const Value = (props: ValueProps) => {
    let text: JSX.Element = <>-</>;
    const value = props.row[props.column.columnName.charAt(0).toLowerCase() + props.column.columnName.slice(1, props.column.columnName.length)];

    if (value !== null && value !== undefined) {
        text = FormatValue(value, props.column.columnType, props.column?.specializations ?? null);
    }

    if (props.column.isLink && value) {
        const id = (props.column.columnType === ColumnType.Lookup) ? value.id : props.row["id"];

        if (props.column.lookupTable && props.column.isLookup) {
            return (
                <NavLink className={styles.linkColumn} to={`/singlePage/${props.column.lookupTable.toLocaleLowerCase()}/${id}`}>{text}</NavLink>
            );
        }

        const toPath = location.pathname === `/singlePage/${props.entityName}` ? `${id}` : `/singlePage/${props.entityName?.toLocaleLowerCase()}/${id}`;
        return (
            <NavLink className={styles.linkColumn} to={toPath}>{text}</NavLink>
        );
    }

    return (
        <>
            {text}
        </>

    );
}