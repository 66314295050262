import classNames from "classnames";
import { CSSProperties, useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { v4 } from "uuid";

import authStore from "AuthStore";
import { dispatcher, selector } from "store";
import { synchroiser } from "synchroiser";
import { SavedFilter } from "entities/filter/SavedFilter";

import FilterFolderTreeItem from "./filter-folder-tree-item";

import { Position, modalController } from "features/modals";
import MovingFolder from "../../moving-folder/moving-folder";
import { LayoutDeleteConfirm, RenameLayout } from "features/advanced-filter/filter-popups";

import { Select, ButtonStyle } from "components";

import { FolderActionEnum, FilterFolderActions } from "../utils/data";
import { LoadingState, SavedFilterFolder } from "types/entity";
import { Item } from "types";

import { ArrowToDown, Folder, MoreEllipsisVertical } from "shared";

import styles from "./filter-folder-tree.module.css";

const FilterFolderTree = observer(function (props: { savedFilterFolder: SavedFilterFolder }) {
	const [idModal] = useState<string>(v4());
	const [idNotification] = useState<string>(v4());
	const [isOpened, setOpened] = useState(false);
	const [selectStyle, setSelectStyle] = useState<CSSProperties | undefined>(undefined);

	const arrowToDownClassNames = classNames(styles.folderArrow, {
		[`${styles.open} `]: isOpened
	});

	const listClassNames = classNames(styles.folderList, {
		[`${styles.visible}`]: isOpened
	});

	const addErrorNotification = useCallback(() => {
		if (synchroiser.loadingState === LoadingState.Error && synchroiser.errorMessage) {
			const layoutNotificationWithError = <div className={styles.notification}>{synchroiser.errorMessage}</div>;

			modalController.notificationAdd({
				id: idNotification,
				position: Position.CENTER,
				layout: layoutNotificationWithError,
				allowDefaultClick: true,
				allowTimer: true
			});
		}
	}, [synchroiser.errorMessage]);

	useEffect(() => {
		if (props.savedFilterFolder.isRoot) {
			setOpened(true);
		}
	}, [props.savedFilterFolder.isRoot]);

	const closeConfirm = useCallback((e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
		e?.stopPropagation();
		modalController.modalRemove(idModal);
	}, []);

	const onMouseEnter = useCallback(() => {
		setSelectStyle({ visibility: "visible" });
	}, []);

	const onMouseLeave = useCallback(() => {
		setSelectStyle({ visibility: "hidden" });
	}, []);

	const openFolder = useCallback(() => {
		setOpened(!isOpened);
	}, [isOpened]);

	const handleChangeValue = useCallback(
		(value: Item | null) => {
			if (value) {
				switch (value.id) {
					case FolderActionEnum.CreateFolder:
						modalController.popupAdd({
							id: idModal,
							layout: (
								<RenameLayout
									dialogTitle="Новая папка"
									captionSuccessfulButton="Сохранить"
									startName={null}
									onRename={createFolder}
									onClose={closeConfirm}
								/>
							),
							closeFunc: closeConfirm
						});
						break;
					case FolderActionEnum.MoveFolder:
						modalController.popupAdd({
							id: idModal,
							layout: (
								<MovingFolder
									savedFilterFolder={props.savedFilterFolder}
									staticGroupFolder={null}
									savedFilter={null}
									staticGroup={null}
									onClose={closeConfirm}
								/>
							),
							closeFunc: closeConfirm
						});
						break;
					case FolderActionEnum.RenameFolder:
						modalController.popupAdd({
							id: idModal,
							layout: (
								<RenameLayout
									dialogTitle="Переименовать папку"
									startName={props.savedFilterFolder.name}
									onRename={updateFolder}
									onClose={closeConfirm}
								/>
							),
							closeFunc: closeConfirm
						});
						break;
					case FolderActionEnum.DeleteFolder:
						modalController.popupAdd({
							id: idModal,
							layout: (
								<LayoutDeleteConfirm
									delete={deleteFolder}
									dialogBody={
										<>
											Вы уверены, что хотите удалить «{props.savedFilterFolder.name}»?
											<br />
											Содержимое папки также будет удалено.
										</>
									}
									closeConfirm={closeConfirm}
								/>
							),
							closeFunc: closeConfirm
						});
						break;
				}
			}
		},
		[props.savedFilterFolder]
	);

	const createFolder = useCallback(
		async (folderName: string | null) => {
			const savedFilterFolder: SavedFilterFolder = {
				parentFolderId: props.savedFilterFolder.id!,
				name: folderName,
				filters: [],
				childFilterFolders: [],
				isRoot: false,
				entityName: dispatcher.entity.get()!.entityName,
				userId: authStore.userId
			};

			await synchroiser.saveFilterFolder(savedFilterFolder, null).then(() => {
				if (synchroiser.loadingState !== LoadingState.Error) {
					props.savedFilterFolder.childFilterFolders.push(savedFilterFolder);
				}
			});
			addErrorNotification();
		},
		[props.savedFilterFolder, synchroiser.loadingState]
	);

	const updateFolder = useCallback(
		async (folderName: string | null) => {
			const updatedFolder = { ...props.savedFilterFolder, name: folderName } as SavedFilterFolder;
			await synchroiser.updateFilterFolder(updatedFolder, null).then(() => {
				if (synchroiser.loadingState !== LoadingState.Error) {
					props.savedFilterFolder.name = folderName;
				}
			});
			addErrorNotification();
		},
		[props.savedFilterFolder, synchroiser.loadingState]
	);

	const flatten = useCallback((savedFilterFolder: SavedFilterFolder): SavedFilter[] => {
		return savedFilterFolder.childFilterFolders.reduce<SavedFilter[]>((acc, item) => {
			return [...item.filters, ...flatten(item)];
		}, []);
	}, []);

	const deleteFolder = useCallback(async () => {
		if (props.savedFilterFolder.id) {
			await synchroiser.deleteFolder(props.savedFilterFolder, null).then(async () => {
				if (synchroiser.loadingState !== LoadingState.Error) {
					const filtersArray =
						props.savedFilterFolder.filters.length > 0
							? [...props.savedFilterFolder.filters, ...flatten(props.savedFilterFolder)]
							: flatten(props.savedFilterFolder);
					if (filtersArray.find(({ id }) => id === selector.filter.getFilter()?.savedFilter?.id)) {
						dispatcher.filter.setSavedFilter(null);
						dispatcher.filter.setPlaceholder();
						await synchroiser.getEntityWithFilter();
						addErrorNotification();
					}
				}
			});
		}
	}, [props.savedFilterFolder, synchroiser.loadingState]);

	return (
		<>
			<div onClick={openFolder} className={styles.folderName} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
				<ArrowToDown className={arrowToDownClassNames} />
				<Folder style={{ minWidth: "fit-content" }} />
				<span>{props.savedFilterFolder.name}</span>
				<Select
					items={props.savedFilterFolder.isRoot ? [FilterFolderActions[0]] : FilterFolderActions}
					onChangeValue={handleChangeValue}
					styles={ButtonStyle.IconIndigo}
					firstIcon={<MoreEllipsisVertical />}
					className={styles.selectFolder}
					classNameButton={styles.selectFolderButton}
					isPositionLeft={true}
					style={selectStyle}
					onClick={(e) => {
						e.stopPropagation();
					}}
				/>
			</div>
			{isOpened && (
				<ul className={listClassNames}>
					{props.savedFilterFolder.filters !== undefined &&
						props.savedFilterFolder.filters.map((item) => {
							return <FilterFolderTreeItem item={item} />;
						})}
					{props.savedFilterFolder.childFilterFolders?.map((item, i) => {
						return (
							<li key={i} className={styles.childFolder}>
								<FilterFolderTree savedFilterFolder={item} />
							</li>
						);
					})}
				</ul>
			)}
		</>
	);
});

export default FilterFolderTree;
