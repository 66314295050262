import { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import { toJS } from "mobx";

import { store } from "store";
import { Zone } from "modules/DND/zone";
import { Element } from "modules/DND/element";

import { ButtonStyle } from "components";
import { FieldSelect } from "pages/section-wizzard/components";

import { FieldsTitle } from "pages/section-wizzard/data/data";
import { Types } from "modules/DND/type";
import { DragIcon } from "shared";
import { IGridItem } from "components/select/types";

import styles from "./fields-block.module.scss";
import { Icon } from "sale-bridge-ui-kit";

type DNDOnDropEvent = {
	elementId: string | null;
	sourceZone: string | null;
	targetZone: string | null;
	type: Types;
	sourceData?: any;
	[key: string]: any;
};

export const ExistFieldsBlock = observer(
	(props: { items: Array<IGridItem>; predictLayoutClassNames?: string; onDrop: (value: DNDOnDropEvent) => void }) => {
		const [placeholderExistField, setPlaceholderExistField] = useState<JSX.Element>(<></>);
		const [targetElementViewLayoutExistField, setTargetElementViewLayoutExistField] = useState<JSX.Element>(<></>);

		const onZoneDropExistField = useCallback(
			(value: DNDOnDropEvent) => {
				props.onDrop(value);
			},
			[props.onDrop]
		);

		const onZoneUpExistField = useCallback(
			({ elementId }: { elementId: string }) => {
				const item = props.items.find((innerItem) => (innerItem.columnId ?? innerItem.id) === elementId);
				if (item) {
					const fieldClassNames = classNames(`${styles.field} `, {
						[`${item.classNames} `]: item.classNames
					});

					setPlaceholderExistField(
						<li
							style={{ borderRadius: "8px", background: "var(--color-grayBlue-50)" }}
							key={item.columnId}
							className={fieldClassNames}
						>
							<Icon name="Draggable" size="16" />
							{item.icon}
							<span>{item.displayValue}</span>
						</li>
					);

					setTargetElementViewLayoutExistField(
						<div
							style={{
								transform: "rotate(-3deg) translateX(-12px) translateY(-20px)",
								padding: "0px 8px",
								alignItems: "center",
								gap: "8px",
								flexShrink: 0,
								borderRadius: "8px",
								background: "#FFF",
								boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.14)",
								maxWidth: "400px"
							}}
							key={item.id}
							className={fieldClassNames}
						>
							<Icon name="Draggable" size="16" />
							{item.icon}
							<span
								style={{
									overflow: "hidden",
									textOverflow: "ellipsis",
									width: "90px"
								}}
							>
								{item.displayValue}
							</span>
						</div>
					);
				}
			},
			[toJS(props.items), placeholderExistField]
		);

		const zoneConfig = useMemo(() => {
			const fieldClassName = classNames(styles.predictLayout, {
				[`${props.predictLayoutClassNames} `]: props.predictLayoutClassNames
			});

			return {
				targetElementViewLayout: targetElementViewLayoutExistField,
				placeholderLayout: placeholderExistField,
				predictLayout: <div className={fieldClassName} />,
				cellLayout: <div style={{ height: "2em" }}></div>,
				width: 1,
				height: 1
			};
		}, [targetElementViewLayoutExistField, placeholderExistField]);

		const fieldsMapping = useMemo(() => {
			const unDuplicatedItems: Array<IGridItem> = [];

			props.items.forEach((item) => {
				if (item.columnId && !unDuplicatedItems.find((equalItem) => equalItem.columnId === item.columnId)) {
					unDuplicatedItems.push(item);
				}
			});

			return (
				unDuplicatedItems &&
				unDuplicatedItems.map((item, index) => {
					const fieldClassNames = classNames(`${styles.field} `, {
						[`${styles.fieldDisabled} `]: item.isDisabled || item.isLocked,
						[`${item.classNames} `]: item.classNames
					});
					return (
						<Zone
							key={`${item.columnId ?? item.gridItemId ?? item.id}-source-zone`}
							onDrop={onZoneDropExistField}
							onUp={onZoneUpExistField}
							id={`${item.columnId ?? item.gridItemId ?? item.id}-source-zone`}
							config={zoneConfig}
							type={Types.MATRIX}
						>
							<Element
								key={item.id}
								id={`${item.columnId ?? item.gridItemId ?? item.id}`}
								x={1}
								y={1}
								width={1}
								height={1}
								sourceData={{
									title: item.displayValue,
									icon: item.icon,
									id: item.id,
									type: item.columnType ?? item.name
								}}
							>
								<li key={item.columnId ?? item.gridItemId} className={fieldClassNames}>
									<Icon name="Draggable" size="16" />
									{item.icon}
									<span>
										{item.isRequired && <span className={styles.star}>* </span>}
										{item.displayValue}
									</span>
								</li>
							</Element>
						</Zone>
					);
				})
			);
		}, [zoneConfig, toJS(props.items)]);

		return (
			<FieldSelect name={FieldsTitle} buttonStyle={ButtonStyle.Settings}>
				{!store.options.isDisabledConstructorInSectionWizzard ? fieldsMapping : <></>}
			</FieldSelect>
		);
	}
);
