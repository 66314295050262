import { v4 } from "uuid";

import { ButtonStyle } from "components";

import { ColumnType } from "entities/ColumnType";
import { IGridItem } from "components/select/types";
import {
	DateIcon,
	LogicalIcon,
	Numbers,
	DecimalIcon,
	ChangeView,
	SectionWizardText,
	SectionWizardDataAndTime,
	SectionWizardTime,
	SectionWizardGroup,
	SectionWizardSearch
} from "shared";
import { ColumnSpecializationType, EntityColumnSpecialization } from "types/entity";
import { Icon } from "sale-bridge-ui-kit";

export const HIDE_ELEMENT_POSITION = -1;

export const POST_INIT_TIME = 500;

export const MAIN_TAB_NAME = "mainInfo";
export const DEFAULT_WARNING_TEXT = "Вы действительно хотите отменить настройки?\nТекущий прогресс будет утерян";
export const DEFAULT_ERROR_VALUE = "Значение не соответствует типу";

export const ERROR_VALUE_EXIST_SECTION_TITLE = "Раздел с таким названием уже существует. Выберите другое название";
export const ERROR_VALUE_EXIST_SECTION_NAME = "Таблица в базе данных с таким названием  уже существует. Выберите другое системное название";

export const ERROR_VALUE_EXIST_TITLE = "Поле с таким заголовком уже существует";
export const ERROR_VALUE_EXIST_NAME = "Поле с таким системным названием уже существует";

export const ERROR_LOOKUP_EXIST_TITLE = "Справочник с таким названием уже существует";
export const ERROR_LOOKUP_EXIST_NAME = "Справочник с таким системным названием уже существует";

export const ERROR_GROUP_EXIST_TITLE = "Группа полей с таким названием уже существует";
export const ERROR_GROUP_EXIST_NAME = "Группа полей с таким системным названием уже существует";

export const ERROR_TAB_EXIST_TITLE = "Вкладка с таким названием уже существует";
export const ERROR_TAB_EXIST_NAME = "Вкладка с таким системным названием уже существует";

export const ERROR_DETAIL_EXIST_TITLE = "Деталь с таким заголовком уже существует";
export const ERROR_DETAIL_EXIST_NAME = "Деталь с таким системным названием уже существует";

export const NewElemTitle = "новый элемент";
export const FieldsTitle = "существующие поля";
export const MoreTitle = "дополнительные вкладки";

export const AdditionalTitle = "дополнительно";

export const UserFieldsTitle = "поля с пользователями";
export const QuickActionsTitle = "Быстрые действия";

export enum MenuState {
	open,
	hide
}
export interface ValidationState {
	isInvalid: boolean;
	isNotUnique?: boolean;
	error?: string;
}
export interface IFieldSelectProps extends React.HTMLAttributes<HTMLElement> {
	name: string;
	buttonStyle?: ButtonStyle;
}

export const NewFields: Array<IGridItem> = [
	{
		id: v4(),
		gridItemId: v4(),
		name: ColumnType.Numerator,
		displayValue: "Нумератор",
		icon: <Icon name={ColumnType.Numerator} size="small" />
	},
	{
		id: v4(),
		gridItemId: v4(),
		name: ColumnType.String,
		displayValue: "Текстовое",
		icon: <Icon name={ColumnType.String} size="small" />
	},
	{
		id: v4(),
		gridItemId: v4(),
		name: ColumnType.DateTime,
		displayValue: "Дата и время",
		icon: <Icon name={ColumnType.DateTime} size="small" />
	},
	{
		id: v4(),
		gridItemId: v4(),
		name: ColumnType.Date,
		displayValue: "Дата",
		icon: <Icon name={ColumnType.Date} size="small" />
	},
	{
		id: v4(),
		gridItemId: v4(),
		name: ColumnType.Time,
		displayValue: "Время",
		icon: <Icon name={ColumnType.Time} size="small" />
	},
	{
		id: v4(),
		gridItemId: v4(),
		name: ColumnType.Lookup,
		displayValue: "Справочное",
		icon: <Icon name={ColumnType.Lookup} size="small" />
	},
	{
		id: v4(),
		gridItemId: v4(),
		name: ColumnType.Boolean,
		displayValue: "Логическое",
		icon: <Icon name={ColumnType.Boolean} size="small" />
	},
	{
		id: v4(),
		gridItemId: v4(),
		name: ColumnType.Integer,
		displayValue: "Целое число",
		icon: <Icon name="Integer" size="small" />
	},
	{
		id: v4(),
		gridItemId: v4(),
		name: ColumnType.Decimal,
		displayValue: "Дробное число",
		icon: <Icon name={ColumnType.Decimal} size="small" />
	},
	{
		id: v4(),
		gridItemId: v4(),
		name: ColumnType.Detail,
		displayValue: "Деталь",
		icon: <Icon name={ColumnType.Detail} size="small" />
	},
	{
		id: v4(),
		gridItemId: v4(),
		name: ColumnType.FieldGroup,
		displayValue: "Группа полей",
		icon: <Icon name={ColumnType.FieldGroup} size="small" />
	}
];

export enum SettingName {
	Files = "Файлы",
	Comments = "Комментарии",
	Chronology = "Хронология",
	Wysiwyg = "WYSIWYG-редактор"
}

export interface ISettings {
	id: string;
	displayValue: string;
	checked: boolean;
}
export interface ITab {
	tabIndex: number;
	tabName: string;
	caption: string;
	tooltipCaption?: string;
	action: () => void;
}

export enum LookupType {
	NewLookup = "newLookup",
	ExistLookup = "existLookup"
}

/*
 *@description сюда вписываются специализации, по которым необходимо отфильтровать gridItems
 */
export const excludedSpecializations: EntityColumnSpecialization[] = [
	{
		tag: ColumnSpecializationType.KanbanField,
		properties: {
			sysOrderField: "true"
		}
	}
];
