import { observer } from "mobx-react-lite";
import { useEffect, useMemo } from "react";
import { useToggle } from "usehooks-ts";

import { dispatcher } from "store";

import { Toggle } from "sale-bridge-ui-kit";
import { FieldIsland, HeadFieldsBlock } from "../global-settings";

import styles from "../global-settings.module.scss";

export const SectionDisplayModes = observer(() => {
	const sectionWizzard = useMemo(() => {
		return dispatcher.entity.get()?.entity.sectionWizzard;
	}, [dispatcher.entity.get()?.entity.sectionWizzard]);

	const hasStageModel = useMemo(() => {
		return sectionWizzard?.hasStageModel;
	}, [sectionWizzard?.hasStageModel]);
	const kanbanIsEnabled = useMemo(() => {
		return sectionWizzard?.kanbanIsEnabled;
	}, [sectionWizzard?.kanbanIsEnabled]);
	const hasCalendar = useMemo(() => {
		return sectionWizzard?.hasCalendar;
	}, [sectionWizzard?.hasCalendar]);
	const hasSpecificFinish = useMemo(() => {
		return sectionWizzard?.hasSpecificFinish;
	}, [sectionWizzard?.hasSpecificFinish]);

	const [isKanban, kanbanToggle, setKanban] = useToggle(kanbanIsEnabled ?? false);
	const [isCalendar, calendarToggle, setCalendar] = useToggle(hasCalendar ?? false);
	const [isSpecificFinish, analyticsToggle, setAnalytics] = useToggle(hasSpecificFinish ?? false);

	const [isKanbanDisabled, kanbanDisabledToggle, setKanbanDisabled] = useToggle(true);
	const [isCalendarDisabled, calendarDisabledToggle, setCalendarDisabled] = useToggle(true);
	const [isAnalyticsDisabled, analyticsDisabledToggle, setAnalyticsDisabled] = useToggle(true);

	useEffect(() => {
		dispatcher.sectionWizzard.setKanbanIsEnabled(isKanban);
		if (isKanban) {
			dispatcher.sectionWizzard.generateKanbanConfig();
		}
	}, [isKanban]);

	useEffect(() => {
		if (!hasStageModel) {
			dispatcher.sectionWizzard.setKanbanIsEnabled(false);
			setKanban(false);
			setKanbanDisabled(true);
		} else {
			setKanbanDisabled(false);
		}
	}, [hasStageModel]);

	//TODO пока не используется
	// useEffect(() => {
	// 	dispatcher.sectionWizzard.setHasCalendar(isCalendar);
	// }, [isCalendar]);
	// useEffect(() => {
	// 	dispatcher.sectionWizzard.setHasSpecificFinish(isSpecificFinish);
	// }, [hasSpecificFinish]);

	return (
		<div className={styles.blockSettings}>
			<HeadFieldsBlock caption="Режимы отображения для раздела" />
			<FieldIsland>
				<Toggle
					checked={isKanban}
					onCheck={kanbanToggle}
					title="Доска Kanban"
					description="Классическая Kanban доска"
					size="small"
					togglePosition='right'
					isLocked={isKanbanDisabled}
				/>
				<Toggle
					checked={isCalendar}
					onCheck={calendarToggle}
					title="Календарь"
					description="Как в Outlook’е"
					size="small"
					togglePosition='right'
					isLocked={isCalendarDisabled}
				/>
				<Toggle
					checked={isSpecificFinish}
					onCheck={analyticsToggle}
					title="Итоги"
					description="Настраиваемые дашборды для аналитки"
					size="small"
					togglePosition='right'
					isLocked={isAnalyticsDisabled}
				/>
			</FieldIsland>
		</div >
	);
});