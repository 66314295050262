import { Api } from "shared/api/class-api";

class Numerator extends Api {
	basicPath: string;
	constructor() {
		super();
		this.basicPath = "/api/Numerator";
	}

	getNumeratorResult = () => {
		this.setPath(`${this.basicPath}/getNumeratorResult`);
		return this;
	};
}

const numerator = new Numerator();

export { numerator };
