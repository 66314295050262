import { UpFirst } from "shared";
import { v4 } from "uuid";
import { action, makeObservable, observable, runInAction, toJS } from "mobx";
import { isEmpty, isNull, isObject, isUndefined, lowerCase, lowerFirst, toLower, upperFirst } from "lodash";

import { dispatcher, DISPLAY, Entity, selector, sessionStore, store } from "store";
import { DisplayEntity } from "../store/store";

import { Item, SubscribeType } from "types";

import authStore from "../AuthStore"; // TODO Временный костыль для частичного общения store
import {
	BasicEntity,
	Column,
	DetailDesignerType,
	DisplayedPanel,
	EntityNameType,
	ExcludeRecordsFromStaticGroup,
	Filter,
	LoadingState,
	OptionPage,
	SavedFilterFolder,
	SectionViewPageSettings,
	SectionWizzard,
	StaticGroup,
	StaticGroupFolder,
	TabId,
	ViewColumn,
	ViewRole
} from "types/entity";
import {
	api,
	entity,
	savedFilter,
	sectionViewPageSettings,
	staticGroup,
	systemDesigner,
	systemDetailDesigner,
	user,
	LowFirst
} from "shared";
import SavedFilter from "entities/filter/SavedFilter";
import { sortArray } from "shared";
import { fieldValueOfTypeParser, getColumnValueAndTypeByColumnId, getPriorityColumnName, getStagesColumnName } from "./lib";
import { KanbanData, KanbanPageCardProps, KanbanPageStage } from "pages/single-page/views/kanban/components/data";
import { DEFAULT_SORT } from "../pages/single-page/constants";
import { businessRulesEngine } from "features/business-rules-engine";
import { ColumnType } from "entities/ColumnType";

// TODO Коменты при low 3g не отображаются отправленные, до загрузки всех
// TODO ADD в коментах, которые добавили мы

const FIELD_DEFAULT_VALUE = "defaultValue";
export const NEW_RECORD = "new";
interface UpdateRowRequest {
	entityName: string;
	values: Array<{ propertyName: string; propertyValue: string }>;
}

class Synchroiser {
	currentCommentsSubscribe: any;
	loadingState: LoadingState;
	errorMessage: string | null;

	constructor() {
		makeObservable(this, {
			loadingState: observable,
			errorMessage: observable,
			getEntityWithFilter: action,
			getRow: action,
			getDetailFromConfig: action
		});
		this.currentCommentsSubscribe = null;
		this.loadingState = LoadingState.NotAsked;
		this.errorMessage = null;
	}

	auth = async (name: string, password: string) => {
		if (sessionStore.checkAuth()) {
			const authData = sessionStore.getAuth();
			if (!authData) {
				console.error("Ошибка synchroiser auth");
			} else {
				store.user = {
					expires: authData.expires,
					id: authData.id,
					message: authData.message,
					username: authData.userName
				};

				store.session.accessToken = authData.tokens.accessToken;
				store.session.refreshToken = authData.tokens.refreshToken;
			}
		} else {
			await user
				.userAuthenticate()
				.post({ name: name, password: password })
				?.then((req: any) => {
					// TODO Скрыть токен, ну или допилить процессы по записыванию
					if (!req.data.changePassword) {
						store.user = {
							expires: req.data.expires,
							id: req.data.id,
							message: req.data.message,
							userName: req.data.username
						};

						store.session.accessToken = req.data.accessToken;
						store.session.refreshToken = req.data.refreshToken;

						sessionStore.setAuth({
							expires: req.data.expires,
							id: req.data.id,
							message: req.data.message,
							userName: req.data.username,
							tokens: {
								accessToken: req.data.accessToken,
								refreshToken: req.data.refreshToken
							}
						});

						this.postSuccessfulAuthSync();
					}
				});
		}
	};

	// refreshToken = () => {

	// 	api.http.httpApi.entity.recordsList().get();
	// 	const removeCookie = (name: string) => {
	// 		document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
	// 	};

	// 	// @ts-ignore
	// 	api.http.httpApi.user.refreshToken().post(null).then((req: any) => {
	// 		store.session.accessToken = req.data.accessToken;
	// 		store.session.tokenExpiration = req.data.expires;
	// 		store.session.tokenExpired = false;
	// 	}).catch((error: any) => {
	// 		const err = error as AxiosError;
	// 		if (err.response && err.response.status === 401) {
	// 			store.session.tokenExpired = true;
	// 			removeCookie("refreshToken");
	// 		} else {
	// 			removeCookie("refreshToken");
	// 			authStore.logOut();
	// 		}
	// 		console.error(error);
	// 	});
	// };

	getSectionsList = async () => {
		const sectionsList = await api.http.section.entitySectionDataList().get();
		const newSections = sectionsList?.data.data;
		const favoriteSections = await api.http.favoriteSection.favoriteSections().get();
		const favorites = favoriteSections.data.data;

		if (favorites && newSections)
			favorites.forEach((favoriteSection: any) => {
				newSections.forEach((section: any) => {
					if (section.id === favoriteSection.sectionId) {
						section.isFavorite = true;
					}
				});
			});

		action("setting sections", () => {
			store.sections = newSections;
		})();
	};

	getUser = (userId: string) => {
		api.http.user
			.userById(userId)
			.get()
			?.then((req: any) => {
				store.users.push(req.data);
			});
	};

	getUsersList = async (): Promise<Item[]> => {
		let usersList: Item[] = [];
		await api.http.user
			.userList()
			.get()
			?.then((response: any) => {
				if (response.status == 200) {
					usersList = response.data.map((user: any) => ({
						id: user.id,
						name: user.name
					}));
				}
			});
		return usersList;
	};

	entityRowReactions = (prevStoreEntityRow: any[], newStoreEntityRow: any) => {};

	setWithoutSessionStoreValues = () => {
		/**
		 * @description На этом этапе он нас авторизует на основе store
		 */
		if (sessionStore.checkAuth()) {
			store.user = sessionStore.getAuth();
			// @ts-ignore
			store.session.accessToken = sessionStore.getAuth().tokens.accessToken;
			// @ts-ignore
			store.session.tokenExpiration = sessionStore.getAuth().tokens.accessToken;
			store.session.tokenExpired = false;
			this.getSectionsList();
		}
	};

	getEntity = async () => {
		action("setting loading state - is loading", () => {
			this.loadingState = LoadingState.Loading;
		})();
		let currentEntityName = store.sections.find((section: any) => section.id === store.currentEntityId)?.entityName;

		if (currentEntityName) {
			const sort = store.entities?.find((entity: any) => entity.id === store.currentEntityId)?.entity.sort || DEFAULT_SORT;
			const newEntity: {
				columns: Array<Column>;
				viewColumn: ViewColumn | null;
				rows: [];
				quality: number;
				visibleColumns: any[];
				display: Array<DisplayEntity>;
				filterTree: null;
				sort: {};
				filter: Filter | null;
				comments: [];
				sectionWizzard: SectionWizzard | null;
				oldValueOfSectionWizzard: SectionWizzard | null;
				isCheckedAll: boolean;
				includedIds: [];
				excludedIds: [];
				countOfChecked: number;
				sectionViewPageSettings: SectionViewPageSettings | null;
			} = {
				columns: [],
				viewColumn: null,
				rows: [],
				filterTree: null,
				quality: 0,
				sort: sort,
				visibleColumns: [],
				display: [],
				filter: null,
				comments: [],
				sectionWizzard: dispatcher.sectionWizzard.getSectionWizzard(),
				oldValueOfSectionWizzard: dispatcher.sectionWizzard.getSectionWizzard(),
				isCheckedAll: false,
				includedIds: [],
				excludedIds: [],
				countOfChecked: 0,
				sectionViewPageSettings: null
			};

			newEntity.display = DISPLAY[currentEntityName as keyof typeof DISPLAY];

			await entity
				.entityData()
				.post({
					entityName: currentEntityName
				})
				?.then((req: any) => {
					newEntity.columns = req.data.data.columns.map((i: any) => i);
					newEntity.viewColumn = req.data.data.viewColumn;
				});

			let filterForList = null;
			const filter = sessionStore.getFilter(currentEntityName);
			newEntity.filter = filter;

			if (newEntity.filter && newEntity.filter.savedFilter && newEntity.filter.savedFilter.filterInfo) {
				filterForList = newEntity.filter.savedFilter.filterInfo;
			}

			const getViewPageSettings = {
				userId: authStore.userId,
				entityName: currentEntityName,
				viewRole: 1
			};
			await sectionViewPageSettings
				.getSectionByUserAndEntity()
				?.post(getViewPageSettings)
				?.then((req: any) => {
					let combinedColumns: any[] = [];
					newEntity.visibleColumns = [];
					if (req.data.success && req.data.data) {
						newEntity.sectionViewPageSettings = req.data.data;
						combinedColumns = req.data.data.columnSettings.map((columnSettings: any) => {
							const column = {
								...newEntity.columns.find(
									(column) => column.columnName.toLowerCase() === columnSettings.columnName.toLowerCase()
								)
							} as Column | undefined;
							if (column && column.columnName) {
								return {
									...columnSettings,
									...column
								};
							}
							return { ...columnSettings };
						});
					}
					newEntity.visibleColumns = combinedColumns;
				})
				.catch((error) => {
					this.loadingState = LoadingState.Error;
					console.error(error);
				});

			const dispatcherSort = dispatcher.entity.get()?.entity?.sort;

			const sectionWizzard = dispatcher.sectionWizzard.getAllGridItems();

			let otherNeedColumns: Array<string> = [];
			if (dispatcher.sectionWizzard.getSectionWizzard()?.kanbanConfig?.cardDesign.additionalFields) {
				otherNeedColumns =
					dispatcher.sectionWizzard
						.getSectionWizzard()
						?.kanbanConfig?.cardDesign.additionalFields.map(
							(item) =>
								sectionWizzard.find((gridItem) => gridItem.fieldConfig?.columnId === item.columnId)?.fieldConfig
									?.columnName ?? ""
						) ?? [];
				otherNeedColumns = [
					...otherNeedColumns,
					...(dispatcher.sectionWizzard
						.getSectionWizzard()
						?.kanbanConfig?.cardDesign.userFields.map(
							(item) =>
								sectionWizzard.find((gridItem) => gridItem.fieldConfig?.columnId === item.columnId)?.fieldConfig
									?.columnName ?? ""
						) ?? [])
				];
			}

			otherNeedColumns = otherNeedColumns.filter(
				(column) => !newEntity.visibleColumns.map((visibleColumn) => visibleColumn.columnName).includes(column)
			);
			const sysFlagName = selector.sectionWizzard.getSysFlagColumn()?.fieldConfig?.columnName;

			if (
				sysFlagName &&
				isUndefined(newEntity.visibleColumns.find((column) => column.columnName?.toLowerCase() === sysFlagName.toLowerCase()))
			) {
				otherNeedColumns.push(sysFlagName);
			}

			const rows = entity
				.recordsListWithColumns()
				.post({
					canbanColumn: null,
					columnNames: [...newEntity.visibleColumns.map((visibleColumn) => visibleColumn.columnName), ...otherNeedColumns],
					entityName: currentEntityName,
					filter: filterForList,
					offset: 0,
					sort: isEmpty(dispatcherSort) ? DEFAULT_SORT : dispatcherSort,
					staticGroupId: newEntity.filter?.staticGroup?.id || null
				})
				?.then((req: any) => {
					if (req?.data?.success) {
						newEntity.rows = req.data.data.records.map((i: any) => i);
					}
				});

			const columns = entity
				.entityCount()
				.post({
					entityName: currentEntityName,
					filter: filterForList,
					staticGroupId: newEntity.filter?.staticGroup?.id || null
				})
				?.then((req: any) => {
					newEntity.quality = req.data.data;
				});

			const filtersList = this.getFiltersList(newEntity, currentEntityName);
			const savedFilterFolderTree = this.getSavedFilterFolderTree(newEntity, currentEntityName);
			const staticGroupFolderTree = this.getStaticGroupFolderTree(newEntity, currentEntityName);

			await Promise.all([rows, columns, filtersList, savedFilterFolderTree, staticGroupFolderTree]).then(() => {
				this.entityRowReactions(dispatcher.entity.get()?.entity?.rows || [], newEntity.rows);
				const currentSection = store.sections.find((section) => section.id === store.currentEntityId);
				if (currentSection) {
					dispatcher.entity.set({
						id: store.currentEntityId,
						entityName: currentSection.entityName,
						entityTitle: currentSection.displayValue,
						entity: newEntity,
						isKanban: currentSection.hasCanban,
						isNew: false
					});
					sessionStore.setEntities(store.entities);
					action("setting loading state - is successful", () => {
						this.loadingState = LoadingState.Successful;
					})();
				} else {
					this.loadingState = LoadingState.Error;
					console.error("ERROR FOR SYNC FIELD SECTIONS[CURRENT_ENTITY_ID] IS EMPTY");
				}
			});
		} else {
			this.loadingState = LoadingState.Error;
			console.error("ERROR FOR SYNC FIELD SECTIONS[CURRENT_ENTITY_ID] IS EMPTY");
		}
	};

	switchSection = async (name: string) => {
		if (store.sections && store.sections.find((section: any) => section.entityName.toLowerCase() === name.toLowerCase())) {
			dispatcher.entity.switchSectionByName(name);
			await this.switchSectionWizzard(name);
			await this.getEntity();
		} else {
			this.loadingState = LoadingState.Error;
			console.error(`${name} в sections не существует`);
		}
	};

	// switchEntity = async (name: string) => {
	// 	if (store.sections && store.entities.find((section: any) => section.entityName.toLowerCase() === name.toLowerCase())) {
	// 		dispatcher.entity.switchEntityByName(name);
	// 		await this.getEntity();
	// 	} else {
	// 		this.loadingState = LoadingState.Error;
	// 		console.error(`${name} в sections не существует`);
	// 	}
	// };

	getPureEntity = async (entityName: string) => {
		this.loadingState = LoadingState.Loading;
		const currentEntity = store.sections.find((section) => toLower(section.entityName) === toLower(entityName)); // Для безопасности
		let currentEntityName = currentEntity?.entityName;

		if (currentEntityName) {
			const sort = store.entities?.find((entity) => entity.id === currentEntity?.entityInfoId)?.entity.sort ?? null;
			const newEntity: BasicEntity = {
				columns: [],
				viewColumn: null,
				rows: [],
				filterTree: null,
				quality: 0,
				sort: sort,
				visibleColumns: [],
				display: [],
				filter: null,
				sectionWizzard: null,
				isCheckedAll: false,
				includedIds: [],
				excludedIds: [],
				countOfChecked: 0,
				oldValueOfSectionWizzard: null,
				sectionViewPageSettings: null
			};

			newEntity.display = DISPLAY[(currentEntityName ?? "sales") as "sales" | "leads" | "contacts" | "accounts"];

			await entity
				.entityData()
				.post({
					entityName: currentEntityName
				})
				?.then((req: any) => {
					newEntity.columns = req.data.data.columns.map((i: any) => i);
					newEntity.viewColumn = req.data.data.viewColumn;
				});

			let filterForList = null;
			const filter = sessionStore.getFilter(currentEntityName);
			newEntity.filter = filter;

			if (newEntity.filter && newEntity.filter.savedFilter && newEntity.filter.savedFilter.filterInfo) {
				filterForList = newEntity.filter.savedFilter.filterInfo;
			}

			const getViewPageSettings = {
				userId: authStore.userId,
				entityName: currentEntityName,
				viewRole: ViewRole.Section
			};

			await api.http.sectionViewPageSettings
				.getSectionByUserAndEntity()
				?.post(getViewPageSettings)
				?.then((req: any) => {
					let combinedColumns: any[] = [];
					newEntity.visibleColumns = [];
					if (req.data.success && req.data.data) {
						newEntity.sectionViewPageSettings = req.data.data;
						combinedColumns = req.data.data.columnSettings.map((columnSettings: any) => {
							const column = {
								...newEntity.columns.find(
									(column) => column.columnName.toLowerCase() === columnSettings.columnName.toLowerCase()
								)
							} as Column | undefined;
							if (column && column.columnName) {
								return {
									...columnSettings,
									...column
								};
							}
							return { ...columnSettings };
						});
					}
					newEntity.visibleColumns = combinedColumns;
				})
				.catch((error) => {
					this.loadingState = LoadingState.Error;
					console.error(error);
				});

			const columns = entity
				.entityCount()
				.post({
					entityName: currentEntityName,
					filter: filterForList,
					staticGroupId: newEntity.filter?.staticGroup?.id || null
				})
				?.then((req: any) => {
					newEntity.quality = req.data.data;
				});

			const filtersList = this.getFiltersList(newEntity, currentEntityName);
			const savedFilterFolderTree = this.getSavedFilterFolderTree(newEntity, currentEntityName);
			const staticGroupFolderTree = this.getStaticGroupFolderTree(newEntity, currentEntityName);

			await Promise.all([columns, filtersList, savedFilterFolderTree, staticGroupFolderTree]).then(() => {
				this.entityRowReactions(dispatcher.entity.get()?.entity?.rows || [], newEntity.rows);
				const currentSection = store.sections.find((section) => toLower(section.entityName) === entityName);
				if (currentSection) {
					dispatcher.entity.set({
						id: currentSection.id,
						entityName: currentSection.entityName,
						entityTitle: currentSection.displayValue,
						entity: newEntity,
						isKanban: currentSection.hasCanban,
						isNew: false
					});
					sessionStore.setEntities(store.entities);
					action("setting loading state", () => {
						this.loadingState = LoadingState.Successful;
					})();
				} else {
					action("setting loading state", () => {
						this.loadingState = LoadingState.Error;
					})();
					console.error("ERROR FOR SYNC FIELD SECTIONS[CURRENT_ENTITY_ID] IS EMPTY");
				}
			});
		} else {
			this.loadingState = LoadingState.Error;
			console.error("ERROR FOR SYNC FIELD SECTIONS[CURRENT_ENTITY_ID] IS EMPTY");
		}
	};

	switchSectionWizzard = async (name: string): Promise<SectionWizzard | undefined> => {
		let sectionWiz = dispatcher.sectionWizzard.generateNew();
		if (store.sections && store.sections.find((section: any) => section.entityName.toLowerCase() === name.toLowerCase())) {
			dispatcher.entity.switchSectionByName(name);
			const entity = dispatcher.entity.get();
			if (!entity) {
				await this.getPureEntity(name);
			}

			let newEntity = {
				...dispatcher.entity.get()?.entity!
			};

			const sectionWizzard = await systemDesigner
				.getSystemDesigner(dispatcher.entity.getSection()?.entityName!)
				.get()
				?.then((req: any) => {
					return req.data.data;
				});

			newEntity = {
				...newEntity,
				sectionWizzard: {
					...sectionWizzard,
					optionPage: OptionPage.GlobalSettings,
					displayedPanel: DisplayedPanel.Main,
					currentTab: 0
				}
			};
			if (!sectionWizzard?.accessRightsConfig && newEntity.sectionWizzard) {
				newEntity.sectionWizzard = {
					...newEntity.sectionWizzard,
					accessRightsConfig: {
						adminByOperation: {
							isEnabled: false,
							operationItems: []
						},
						adminByRecords: {
							isEnabled: false,
							recordItems: []
						}
					}
				};
			}
			newEntity.oldValueOfSectionWizzard = structuredClone(newEntity.sectionWizzard);
			dispatcher.entity.setBasicEntity(newEntity);

			return newEntity.sectionWizzard || undefined;
		} else {
			const id = v4();
			const currentEntity: BasicEntity = {
				columns: [],
				viewColumn: null,
				rows: [],
				filterTree: null,
				quality: 0,
				sort: {},
				visibleColumns: [],
				display: {},
				filter: null,
				sectionWizzard: sectionWiz,
				oldValueOfSectionWizzard: sectionWiz,
				isCheckedAll: false,
				includedIds: [],
				excludedIds: [],
				countOfChecked: 0,
				sectionViewPageSettings: null
			};
			const newSection = {
				id: id,
				entityName: "",
				entityTitle: "",
				entity: currentEntity,
				isKanban: false,
				visibleColumns: [],
				isNew: true
			};

			dispatcher.entity.set(newSection);
			dispatcher.entity.switchById(id);
		}
	};

	startSyncWithComments = (rowId: string) => {
		if (this.currentCommentsSubscribe) {
			// this.currentCommentsSubscribe.stopConnection();
		}

		const subscale = new api.ws.SignalRService("SubscribeToFullView", rowId);
		subscale.startConnection();
		subscale.addEventListener("FullViewCreated", (id: any, req: any) => {
			const parsedRequest = JSON.parse(req);
			const date = new Date().toISOString();

			api.http.user
				.userById(parsedRequest.UserId)
				.get()
				?.then((req: any) => {
					dispatcher.comments.add({
						// @ts-ignore
						id: parsedRequest.Id,
						// @ts-ignore
						userId: parsedRequest.UserId,
						entityId: dispatcher.entity.get()!.id,
						entityName: dispatcher.entity.get()!.entityName, // TODO Допилить
						// @ts-ignore
						text: parsedRequest.Text,
						userName: req.data.name, // TODO Допилить
						isOwner: store.user.id === parsedRequest.UserId,
						createdOn: date,
						modifiedOn: date
					});

					dispatcher.subscribers.add(parsedRequest.Id);
					dispatcher.reactions.set(parsedRequest.Id, SubscribeType.ADD);
				});
		});
		subscale.addEventListener("FullViewUpdated", (fullViewId: any, updatedCommentId: any, updatedFieldsJson: any) => {
			const date = new Date().toISOString();
			dispatcher.comments.edit(updatedCommentId, JSON.parse(updatedFieldsJson).Text, date);
		});
		subscale.addEventListener("FullViewDelited", (rowId: string, req: string) => {
			dispatcher.comments.remove(req);
		});

		this.currentCommentsSubscribe = subscale;
	};

	getComments = (entityName?: string, recordId?: string) => {
		let currentEntityName = entityName ?? store.sections.find((section: any) => section.id === store.currentEntityId)?.entityName;
		if (currentEntityName) {
			api.http.comment
				.getComments(currentEntityName, recordId ?? store.currentRowId)
				.get()
				?.then((req: any) => {
					dispatcher.comments.set(req.data?.data);
					this.startSyncWithComments(store.currentRowId);
				});
		} else {
			console.error("store section is empty");
		}
	};

	sendEntityRow = (rowProperties: Array<{ propertyName: string; propertyValue: string }>) => {
		const currentEntityName = store.sections.find((section: any) => section.id === store.currentEntityId)?.entityName;

		this.entityRowReactions(
			dispatcher.entity.get()?.entity?.rows || [],
			dispatcher.entity
				.get()
				?.entity?.rows.map((row: any) =>
					row.id !==
					rowProperties.find((newRow: { propertyName: string; propertyValue: string }) => newRow.propertyName === "Id")
						?.propertyValue
						? row
						: rowProperties
				) || []
		);

		entity
			.updateEntity()
			.post({
				entityName: currentEntityName,
				values: rowProperties
			})
			?.then((req: any) => {});
	};

	setRow = (body: any) => {
		api.http.entity
			.createEntity()
			.post(body)
			?.then((req) => {
				store.oldRowValue = body;
			})
			.catch((error) => {
				console.error(error);
				dispatcher.currentRow.rollback();
			});
	};

	/**
	  * Обновляет или создает запись в соответствии с заданными значениями.
	  *
	  * @async
	  * @param {UpdateRowRequest} body - Объект запроса для обновления записи.
	  * @param {Array<{ propertyName: string, propertyValue: string }>} body.values - Массив значений для обновления строки.

	  * @returns {Promise<any>} Результат выполнения запроса на обновление строки.
	  */

	updateRow = async () => {
		//TODO Пока не понятно как передавать параметр в функцию из реактора. Буду получать прям здесь
		const body = this.getUpdateBody();
		if (!body) {
			return null;
		}
		try {
			const currentRecordId = store.currentRowId;
			if (!currentRecordId || currentRecordId === NEW_RECORD) {
				const response = await entity.createEntity().post(body);

				if (response && response.data.success) {
					let recordId = response?.data.data;

					const newRow = { ...dispatcher.currentRow.get(), id: recordId };
					//Обновление записи на фронте
					dispatcher.entity
						.get()
						?.entity.rows.splice(dispatcher.entity.get()?.entity.rows.findIndex((row) => row.id === NEW_RECORD)!, 1, newRow);
					dispatcher.currentRow.switch(recordId);
				} else {
					return Promise.reject();
				}
			} else {
				body.values.push({ propertyName: "Id", propertyValue: currentRecordId });
				await entity
					.updateEntity()
					.post(body)
					.then((response) => {
						if (!response || response.data.status >= 400) {
							return Promise.reject();
						}
					});
			}

			let oldvalue = dispatcher.currentRow.get();
			store.oldRowValue = oldvalue;

			store.hasChanges = false;
			store.resetChangedFields();

			return store.currentRowId;
		} catch (err) {
			console.error(err);
			return Promise.reject();
		}
	};

	/*
	 * Фильтрация row - только поля
	 * Временное решение проблемы с тем, что в row попадают названия табов
	 */
	filterValues(): Record<string, any> {
		const row: Record<string, any> = dispatcher.currentRow.get()!;
		let values: Record<string, any> = [];
		Object.entries(row).forEach(([fieldName, value]) => {
			const arrTabIds = Object.entries(TabId);
			if (!arrTabIds.find((arr) => arr[0].toLowerCase() === fieldName.toLowerCase())) {
				values.push([UpFirst(fieldName), value]);
			}
		}, []);
		return values;
	}
	/*
	 * Формирует тело запроса на обновление или создание записи
	 *
	 * @returns {UpdateRowRequest} Тело запроса для обновления или создания записи
	 */
	getUpdateBody(): UpdateRowRequest | null {
		const nameOfSection = store.sections.find((section: any) => section.id === store.currentEntityId)?.entityName;
		const nameOfEntity = store.entities.find((entity: any) => entity.id === store.currentEntityId)?.entityName;
		const currentEntityName = nameOfSection ?? nameOfEntity;
		const currentRecordId = store.currentRowId;

		//TODO возможно сделать добавление сущности в массив store.sections или store.entities
		if (!currentEntityName) {
			return null;
		}

		let values: Array<{ propertyName: string; propertyValue: string }> = [];

		if (!currentRecordId || currentRecordId === NEW_RECORD) {
			const row = this.filterValues(); /* Временное решение проблемы с тем, что в row попадают названия табов */
			values = row.reduce((acc: { propertyName: string; propertyValue: any }[], [fieldName, value]: any) => {
				if (fieldName.toLowerCase() !== "id") {
					let propertyValue = value;
					if (value?.toLocaleString().length == 0) {
						propertyValue = null;
					}
					if (typeof value === "object" && !isNull(value) && value.hasOwnProperty("id")) {
						propertyValue = value.id;
					}
					acc.push({
						propertyName: UpFirst(fieldName),
						propertyValue: propertyValue
					});
				}
				return acc;
			}, [] as Array<{ propertyName: string; propertyValue: string }>);
		} else {
			values = store.changedFields.map((item) => {
				return {
					propertyName: UpFirst(item.fieldName),
					propertyValue: item.value?.toLocaleString().length == 0 ? null : item.value
				};
			});
		}

		let updateRequestBody: UpdateRowRequest = {
			entityName: currentEntityName,
			values: values
		};

		return updateRequestBody;
	}

	getRow = async (entityName: string, id: string, columns?: string[]) => {
		this.loadingState = LoadingState.Loading;
		let currentEntity = store.entities.find((entity) => entity.entityName.toLowerCase() === entityName.toLowerCase());

		if (!currentEntity) {
			//TODO подумать над тем, что тут могут быть не только разделы
			await this.switchSection(entityName);
			this.loadingState = LoadingState.Loading;
			currentEntity = dispatcher.entity.get();
		}
		if (!isUndefined(currentEntity) && currentEntity.id !== store.currentEntityId) {
			dispatcher.entity.switchById(currentEntity!.id);
		}

		let entityInfoId = "";

		await entity
			.entityData()
			.post({ entityName: currentEntity?.entityName })
			?.then((req: any) => {
				currentEntity!.entity.columns = req.data.data.columns.map((i: any) => i);
				currentEntity!.entity.viewColumn = req.data.data.viewColumn;
				entityInfoId = req.data.data.id;
			});

		await this.loadSystemDesigner(currentEntity!);

		businessRulesEngine.setSectionWizzard(currentEntity?.entity.sectionWizzard ?? null);

		if (id === NEW_RECORD) {
			const newRow = await this.createNewRecord(currentEntity!, null, entityInfoId);

			currentEntity!.entity.rows.push(newRow);

			dispatcher.entity.set(currentEntity!);
			dispatcher.currentRow.switch(newRow.id);

			store.oldRowValue = newRow;
			this.loadingState = LoadingState.Successful;
		} else {
			await this.loadExistingRecord(currentEntity!, id, columns);
		}
	};

	/**
	 * Подгружает в текущую сущность информацию о разделе, стадиях и правил перехода
	 * @param currentEntity Текущая сущность, раздел.
	 */
	private async loadSystemDesigner(currentEntity: Entity) {
		await api.http.systemDesigner
			.getSystemDesigner(currentEntity!.entityName)
			.get()
			?.then((response) => {
				if (currentEntity && response && response.data.success) {
					currentEntity.entity.sectionWizzard = response.data.data;
				}
			});

		if (currentEntity && currentEntity.entity.sectionWizzard?.stageModelConfig) {
			await api.http.stage
				.getSectionFromDesigner(currentEntity!.entityName)
				.get()
				?.then((response) => {
					if (currentEntity && currentEntity.entity.sectionWizzard && response && response.data.success) {
						currentEntity.entity.sectionWizzard.stageModelConfig!.movingRules = response.data.data.movingRules;
						currentEntity.entity.sectionWizzard.stageModelConfig!.stages = response.data.data.stages;
					}
				});
		}
	}

	private convertObjectToArray = (obj: { [key: string]: any }) => {
		return Object.keys(obj).map((key) => ({
			propertyName: key,
			propertyValue: obj[key]
		}));
	};

	private numeratorProcessing = async (requestData: any) => {
		const numeratorResult = await api.http.numerator.getNumeratorResult().post(requestData);

		return numeratorResult.data.data;
	};

	getDefaultRowValue = async (data: any, defaultValues = {}) => {
		const values: { [key: string]: any } = defaultValues;

		const getDefaultLookupValue = async (fieldName: string) => {
			await api.http.entity
				.getRecordWithColumns()
				.post({
					entityName: data["lookupTable"],
					entityId: data[FIELD_DEFAULT_VALUE],
					columnNames: []
				})
				?.then((req) => {
					values[fieldName] = req.data.data;
				})
				.catch((error) => {
					console.error(error);
				});
		};

		if (data) {
			await Promise.all(
				Object.keys(data).map(async (item) => {
					if (item === "columnName") {
						if (
							data["columnType"] === ColumnType.Lookup &&
							!isNull(data[FIELD_DEFAULT_VALUE]) &&
							data[FIELD_DEFAULT_VALUE].length > 0
						) {
							await getDefaultLookupValue(LowFirst(data[item]));
						} else {
							values[LowFirst(data[item])] = data[FIELD_DEFAULT_VALUE];
						}
					} else if (isObject(data[item])) {
						await this.getDefaultRowValue(data[item], values);
					}
				})
			);
		}
		return values;
	};

	/**
	 * @description получение json детали
	 * @param entityName - системное название детали
	 * @returns объект детали
	 */
	getDetailConfig = async (entityName: string): Promise<DetailDesignerType | null> => {
		const systemDetailDesignerResponse = await systemDetailDesigner.getSystemDetailDesigner(entityName).get();

		if (!systemDetailDesignerResponse || !systemDetailDesignerResponse.data.success) {
			return null;
		}

		const result: DetailDesignerType = {
			columnsInfo: systemDetailDesignerResponse.data.data.columnsInfo.map((item: any) => item),
			detailConfig: this.replaceKeys(JSON.parse(systemDetailDesignerResponse.data.data.detailConfig)),
			isSection: systemDetailDesignerResponse.data.data.isSection
		};
		return result;
	};

	/**
	 * @description ВРЕМЕННОЕ РЕШЕНИЕ! метод для замены первой буквы у всех ключей в json детали на нижний регистр
	 * @param value - объект детали/из детали
	 * @returns json детали, у которой все ключи начинаются с нижнего регистра
	 */
	replaceKeys = (value: any): any => {
		return value instanceof Array
			? value.map((valueItem) => {
					if (valueItem instanceof Object) {
						return this.replaceKeys(valueItem);
					}
					return LowFirst(valueItem);
			  })
			: value instanceof Object
			? Object.fromEntries(Object.entries(value).map((n) => [LowFirst(n[0]), this.replaceKeys(n[1])]))
			: value;
	};

	/**
	 * @description получение json детали и ее записи
	 * @param id - id записи для получения
	 * @returns объект детали
	 */
	getDetailFromConfig = async (id: string): Promise<DetailDesignerType | null> => {
		this.loadingState = LoadingState.Loading;
		const currentEntity = dispatcher.entity.get();
		const currentEntityInfoId = store.sections.find(
			(section) => toLower(section.entityName) === toLower(currentEntity?.entityName)
		)?.entityInfoId;
		if (!currentEntity) {
			this.loadingState = LoadingState.Error;
			return null;
		}
		const systemDetailDesignerResponse = await this.getDetailConfig(currentEntity.entityName);

		if (!systemDetailDesignerResponse) {
			return null;
		}

		currentEntity.entity.columns = systemDetailDesignerResponse.columnsInfo;

		await this.loadSystemDesigner(currentEntity!);

		if (id === NEW_RECORD) {
			const newRow = await this.createNewRecord(currentEntity, systemDetailDesignerResponse, currentEntityInfoId);

			currentEntity!.entity.rows.push(newRow);

			dispatcher.entity.set(currentEntity!);
			dispatcher.currentRow.switch(newRow.id);

			store.oldRowValue = newRow;
			this.loadingState = LoadingState.Successful;
		} else {
			if (!systemDetailDesignerResponse.isSection) {
				await this.loadExistingRecord(currentEntity, id);
			}
		}
		return systemDetailDesignerResponse;
	};

	/**
	 * Метод предназначен для создания новой записи. Он создает новый экземпляр row, заполняет дефолтными значениями для сущности, устанавливает нумератор если он присутствует.
	 * @param currentEntity Текущая сущность, раздел.
	 * @param detailConfig  Конфиг детали, в случае если создаем запись в детали, которая является разделом.
	 * @param entityInfoId 	Id сущности из таблицы EntityInfo
	 * @returns { [key: string]} Новый экземпляр записи.
	 */
	private createNewRecord = async (
		currentEntity: Entity,
		detailConfig: DetailDesignerType | null = null,
		entityInfoId?: string
	): Promise<{ [key: string]: any }> => {
		const columns = currentEntity.entity.columns;
		const newRow: { [key: string]: any } = {};

		const defaultValues = detailConfig
			? await this.getDefaultRowValue(detailConfig.detailConfig)
			: await this.getDefaultRowValue(currentEntity.entity.sectionWizzard);

		columns.forEach((column) => {
			if (column.columnType === ColumnType.Boolean) {
				newRow[LowFirst(column.columnName)] = false;
			} else {
				newRow[LowFirst(column.columnName)] = defaultValues[LowFirst(column.columnName)] ?? null;
			}
		});

		newRow.id = NEW_RECORD;

		const sectionWizzard = dispatcher.sectionWizzard.getSectionWizzard();
		const numeratorColumns = sectionWizzard?.reactorConfig.tabs.tabsConfig.flatMap((x) =>
			x.grid.items.filter((item) => item.fieldConfig?.numeratorConfig)
		);

		if (numeratorColumns && numeratorColumns.length > 0) {
			for (const numeratorColumn of numeratorColumns) {
				if (numeratorColumn.fieldConfig?.numeratorConfig?.fillWhenPageOpened) {
					const columnId = numeratorColumn.fieldConfig.columnId;
					const columnName = numeratorColumn.fieldConfig.columnName;

					const properties = this.convertObjectToArray(newRow);

					const requestData = {
						entityId: entityInfoId,
						columnId: columnId,
						properties: properties
					};

					const numeratorValue = await this.numeratorProcessing(requestData);
					if (numeratorValue) {
						newRow[LowFirst(columnName)] = numeratorValue;
					}
				}
			}
		}

		return newRow;
	};

	/**
	 * Подгружаем существующую запись
	 * @param currentEntity Текущая сущность, раздел.
	 * @param recordId id записи
	 */

	private async loadExistingRecord(currentEntity: Entity, recordId: string, columns?: string[]) {
		try {
			let newRow: any = null;
			if (columns) {
				await api.http.entity
					.getRecordWithColumns()
					.post({
						columnNames: columns,
						entityName: currentEntity?.entityName,
						entityId: recordId
					})
					?.then((req) => {
						newRow = req.data.data;
					});
			} else {
				await api.http.entity
					.getRecord()
					.post({
						entityName: currentEntity?.entityName,
						entityId: recordId
					})
					?.then((req) => {
						newRow = req.data.data;
					});
			}
			if (isEmpty(newRow)) {
				this.loadingState = LoadingState.Error;
				return;
			}

			const existingRowIndex = currentEntity?.entity.rows.findIndex((row) => row.id === newRow.id);
			if (existingRowIndex !== -1) {
				currentEntity?.entity.rows.splice(existingRowIndex, 1, newRow);
			} else {
				currentEntity?.entity.rows.push(newRow);
			}

			dispatcher.entity.set(currentEntity!);
			dispatcher.currentRow.switch(newRow.id);
			store.oldRowValue = newRow;
			this.loadingState = LoadingState.Successful;
		} catch (error) {
			console.error(error);
			dispatcher.currentRow.rollback();
			this.loadingState = LoadingState.Error;
		}
	}

	/**
	 * @description удаление записи из таблицы
	 * @param entityName - название таблицы в БД
	 * @param entityId - id записи, которую необходимо удалить
	 **/
	deleteRecord = async (entityName: string, entityId: string) => {
		try {
			const deleted = await api.http.entity.deleteRecord().post({
				entityName: entityName,
				entityId: entityId
			});
			return deleted.data.success;
		} catch (error: any) {
			if (error.response.status === 401) {
				console.error(error.response);
			}
		}
	};

	/**
	 * @description удаление выделенных записей в GeneralizedGrid
	 **/
	deleteRecords = async (entity?: Entity) => {
		try {
			let promises: Promise<boolean>[] = [];
			const entityOfDeleteItems = entity ?? dispatcher.entity.get();
			if (entityOfDeleteItems) {
				const includedIds = entityOfDeleteItems.entity.includedIds;
				const isCheckedAll = entityOfDeleteItems.entity.isCheckedAll;
				const excludedIds = entityOfDeleteItems.entity.excludedIds;
				if (includedIds.length > 0)
					promises = includedIds.map(async (element) => {
						const deleted = await this.deleteRecord(entityOfDeleteItems.entityName, element.id);
						return deleted;
					});
				else if (isCheckedAll) {
					if (excludedIds.length > 0)
						promises = entityOfDeleteItems.entity.rows.map(async (item) => {
							let index = excludedIds.findIndex((exc) => exc.id === item.id);
							let deleted = true;
							if (index === -1) deleted = await this.deleteRecord(entityOfDeleteItems.entityName, item.id);
							return deleted;
						});
					else
						promises = entityOfDeleteItems.entity.rows.map(async (item) => {
							const deleted = await this.deleteRecord(entityOfDeleteItems.entityName, item.id);
							return deleted;
						});
				}
				await Promise.all(promises);
			}
		} catch (error: any) {
			if (error.response) {
				console.error(error.response);
			}
		}
	};

	/**
	 * @description дублировать запись в таблице
	 * @param entityName - название таблицы в БД
	 * @param entityId - id записи, которую необходимо дублировать
	 **/
	dublicateRecord = async (entityName: string, recordId: string) => {
		try {
			const response = await api.http.entity.dublicateRecord().post({
				entityName: entityName,
				recordId: recordId
			});
			return response.data.success;
		} catch (error: any) {
			if (error.response.status === 401) {
				console.error(error.response);
			}
		}
	};

	/**
	 * @description дублирование выделенных записей в GeneralizedGrid
	 */
	duplicateRecords = async () => {
		try {
			let promises: Promise<boolean>[] = [];
			const entity = dispatcher.entity.get();
			if (entity) {
				const includedIds = entity.entity.includedIds;
				const isCheckedAll = entity.entity.isCheckedAll;
				const excludedIds = entity.entity.excludedIds;
				if (includedIds.length > 0)
					promises = includedIds.map(async (element) => {
						const response = await this.dublicateRecord(entity.entityName, element.id);
						return response;
					});
				else if (isCheckedAll) {
					if (excludedIds.length > 0)
						promises = entity.entity.rows.map(async (item) => {
							let index = excludedIds.findIndex((exc) => exc.id === item.id);
							let response = true;
							if (index === -1) response = await this.dublicateRecord(entity.entityName, item.id);
							return response;
						});
					else
						promises = entity.entity.rows.map(async (item) => {
							const response = await this.dublicateRecord(entity.entityName, item.id);
							return response;
						});
				}
				await Promise.all(promises);
			}
		} catch (error: any) {
			if (error.response) {
				console.error(error.response);
			}
		}
	};

	/**
	 * @description Метод для применения фильтра
	 */
	getEntityWithFilter = async () => {
		this.loadingState = LoadingState.Loading;
		const currentEntity = { ...dispatcher.entity.get() } as Entity;
		try {
			if (!currentEntity) {
				this.loadingState = LoadingState.Error;
				//TODO переделать в будущем на генератор ошибок
				this.errorMessage = "Не удалось применить фильтр";
				console.error("ERROR FOR SYNC FIELD SECTIONS[CURRENT_ENTITY_ID] IS EMPTY");
				return;
			}
			const sectionWizzard = dispatcher.sectionWizzard.getAllGridItems();

			let otherNeedColumns: Array<string> = [];
			if (dispatcher.sectionWizzard.getSectionWizzard()?.kanbanConfig?.cardDesign.additionalFields) {
				otherNeedColumns =
					dispatcher.sectionWizzard
						.getSectionWizzard()
						?.kanbanConfig?.cardDesign.additionalFields.map(
							(item) =>
								sectionWizzard.find((gridItem) => gridItem.fieldConfig?.columnId === item.columnId)?.fieldConfig
									?.columnName ?? ""
						) ?? [];
			}

			otherNeedColumns = otherNeedColumns.filter(
				(column) => !currentEntity.entity.visibleColumns.map((visibleColumn) => visibleColumn.columnName).includes(column)
			);

			const rows = entity
				.recordsListWithColumns()
				.post({
					canbanColumn: null,
					columnNames: Array.from(
						new Set(
							[
								...currentEntity.entity.visibleColumns.map((visibleColumn: any) => visibleColumn.columnName),
								...otherNeedColumns
							].map((columnName: string) => upperFirst(columnName))
						)
					),
					entityName: currentEntity.entityName,
					filter: currentEntity.entity.filter?.savedFilter?.filterInfo?.serialize() || null,
					offset: 0,
					sort: dispatcher.entity.get()?.entity?.sort || DEFAULT_SORT,
					staticGroupId: currentEntity.entity.filter?.staticGroup?.id || null
				})
				?.then((response: any) => {
					currentEntity.entity!.rows = response.data.data.records.map((i: any) => i);
				});

			const columns = entity
				.entityCount()
				.post({
					entityName: currentEntity.entityName,
					filter: currentEntity.entity.filter?.savedFilter?.filterInfo?.serialize() ?? null,
					staticGroupId: currentEntity.entity.filter?.staticGroup?.id || null
				})
				?.then((req: any) => {
					currentEntity.entity.quality = req.data.data;
				});

			await Promise.all([rows, columns])
				.then(() => {
					this.entityRowReactions(dispatcher.entity.get()?.entity?.rows || [], currentEntity.entity.rows);
					const currentSection = store.sections.find((section) => section.id === store.currentEntityId);
					if (currentSection) {
						dispatcher.filter.setPlaceholder();
						dispatcher.entity.setBasicEntity(currentEntity.entity);
						sessionStore.setEntities(store.entities);
						sessionStore.setFilter(currentSection.entityName, currentEntity.entity.filter || null);
						this.loadingState = LoadingState.Successful;
					} else {
						this.loadingState = LoadingState.Error;
						console.error("ERROR FOR SYNC FIELD SECTIONS[CURRENT_ENTITY_ID] IS EMPTY");
					}
				})
				.catch((error) => {
					this.loadingState = LoadingState.Error;
					//TODO переделать в будущем на генератор ошибок
					this.errorMessage = "Не удалось применить фильтр";
					console.error(error);
				});
		} catch (error) {
			this.loadingState = LoadingState.Error;
			//TODO переделать в будущем на генератор ошибок
			this.errorMessage = "Не удалось применить фильтр";
			console.error(error);
		}
	};

	/**
	 * @description Метод для сохрапнения фильтра
	 * @param filter - фильтр для сохранения
	 */
	saveFilter = async (filter: SavedFilter) => {
		const currentEntity = dispatcher.entity.get();
		try {
			if (!currentEntity) {
				this.loadingState = LoadingState.Error;
				//TODO переделать в будущем на генератор ошибок
				this.errorMessage = "Не удалось сохранить фильтр";
				console.error("ERROR FOR SYNC FIELD SECTIONS[CURRENT_ENTITY_ID] IS EMPTY");
				return;
			}

			const response = await savedFilter.filterCreate().post(filter.serialize());
			if (response && response.data.success) {
				filter.setValue(response.data.data, "id");
				filter.setValue(filter.filterInfo ? filter.serialize() : null, "oldFilter");

				if (filter.isFavorite) {
					dispatcher.filter.updateFavoriteFilters(filter);
				}
			} else {
				this.loadingState = LoadingState.Error;
				//TODO переделать в будущем на генератор ошибок
				this.errorMessage = "Не удалось сохранить фильтр";
				console.error(response.data.error);
			}
		} catch (error) {
			this.loadingState = LoadingState.Error;
			//TODO переделать в будущем на генератор ошибок
			this.errorMessage = "Не удалось сохранить фильтр";
			console.error(error);
		}
	};

	/**
	 * @description Метод для обновления фильтра
	 * @param filter - обновленный фильтр
	 */
	updateFilter = async (filter: SavedFilter, needWorkWithFavoriteFilters: boolean) => {
		const currentEntity = dispatcher.entity.get();
		try {
			if (!currentEntity) {
				this.loadingState = LoadingState.Error;
				//TODO переделать в будущем на генератор ошибок
				this.errorMessage = "Не удалось обновить фильтр";
				console.error("ERROR FOR SYNC FIELD SECTIONS[CURRENT_ENTITY_ID] IS EMPTY");
				return;
			}

			const serializedFilter = filter.disposer ? filter.serialize() : filter;

			const response = await savedFilter.filterUpdate().post(serializedFilter);
			if (response && response.data.success) {
				filter.oldFilter = filter.disposer ? (filter.filterInfo ? filter.filterInfo.serialize() : filter.filterInfo) : null;
				if (selector.filter.getFilter()?.savedFilter?.id === filter.id) {
					dispatcher.filter.setSavedFilter(filter);
				} else if (filter.isFavorite) {
					dispatcher.filter.setFilterInFavoriteFilters(filter);
				}

				if (needWorkWithFavoriteFilters) {
					dispatcher.filter.updateFavoriteFilters(filter);
				}
				dispatcher.filter.setPlaceholder();
				sessionStore.setFilter(currentEntity.entityName, currentEntity.entity.filter || null);
			} else {
				this.loadingState = LoadingState.Error;
				//TODO переделать в будущем на генератор ошибок
				this.errorMessage = "Не удалось обновить фильтр";
				console.error(response.data.error);
			}
		} catch (error) {
			this.loadingState = LoadingState.Error;
			//TODO переделать в будущем на генератор ошибок
			this.errorMessage = "Не удалось обновить фильтр";
			console.error(error);
		}
	};

	/**
	 * @description Метод для удаления фильтра
	 * @param filterId - id фильтра для удаления
	 */
	deleteFilter = async (filterId: string) => {
		try {
			await savedFilter
				.filterDelete(filterId)
				.delete()
				.then(async (response) => {
					if (response && response.data.success) {
						dispatcher.filter.deleteFromFavoriteFilters(filterId);
						await this.getSavedFilterFolderTree();
						if (filterId === selector.filter.getFilter()?.savedFilter?.id) {
							dispatcher.filter.setSavedFilter(null);
							dispatcher.filter.setPlaceholder();
							await synchroiser.getEntityWithFilter();
						}
					}
				});
		} catch (error) {
			this.loadingState = LoadingState.Error;
			console.error(error);
		}
	};

	/**
	 * @description Метод для получения сохраненных фильтров и обновления списка избранных фильтров
	 * @param basicEntity - объект BasicEntity (для первой инициализации, когда вызывается getEntity())
	 * @param entityNameOfNewEntity - системное название раздела (для первой инициализации, когда вызывается getEntity())
	 * @returns массив сохраненных фильтров
	 */
	getFiltersList = async (basicEntity?: BasicEntity, entityNameOfNewEntity?: string): Promise<Item[]> => {
		const currentEntity = basicEntity || dispatcher.entity.get()?.entity;
		try {
			if (!currentEntity) {
				this.loadingState = LoadingState.Error;
				console.error("ERROR FOR SYNC FIELD SECTIONS[CURRENT_ENTITY_ID] IS EMPTY");
				return [];
			}
			const entityName = entityNameOfNewEntity || dispatcher.entity.get()?.entityName;
			let resultItems: Item[] = [];
			await savedFilter
				.filtersList(authStore.userId!, entityName)
				.get()
				.then((response) => {
					if (response.data.success) {
						let items = (response.data.data as SavedFilter[]).map((item: any) => {
							return {
								...item,
								createdOn: new Date(Date.parse(item.createdOn)),
								modifiedOn: new Date(Date.parse(item.modifiedOn))
							};
						});
						items = sortArray(items, "modifiedOn", 1);
						resultItems = items.map((item: any) => {
							return { id: item.id, name: item.filterName };
						});
						if (!currentEntity.filter) {
							dispatcher.filter.initFilter(currentEntity);
						}
						currentEntity.filter!.favoriteFilters = items.filter((item) => item.isFavorite);
					}
				});
			return resultItems;
		} catch (error) {
			this.loadingState = LoadingState.Error;
			console.error(error);
			return [];
		}
	};

	/**
	 * @description Метод для получения и применения фильтра по его id
	 * @param filterId - id фильтра
	 */
	getFilter = async (filterId: string): Promise<void> => {
		const currentEntity = dispatcher.entity.get();
		try {
			if (!currentEntity) {
				this.loadingState = LoadingState.Error;
				console.error("ERROR FOR SYNC FIELD SECTIONS[CURRENT_ENTITY_ID] IS EMPTY");
				return;
			}

			const response = await savedFilter.getFilter(filterId).get();
			if (response) {
				if (response.data.success) {
					dispatcher.filter.setSavedFilter(response.data.data);
					await this.getEntityWithFilter();
				} else {
					this.loadingState = LoadingState.Error;
					//TODO переделать в будущем на генератор ошибок
					this.errorMessage = "Не удалось применить фильтр";
				}
			}
		} catch (error) {
			this.loadingState = LoadingState.Error;
			console.error(error);
		}
	};

	/**
	 * @description Метод для сохранения статической группы
	 * @param group - статическая группа
	 */
	saveStaticGroup = async (group: StaticGroup): Promise<void> => {
		const currentEntity = dispatcher.entity.get();
		try {
			if (!currentEntity) {
				this.loadingState = LoadingState.Error;
				console.error("ERROR FOR SYNC FIELD SECTIONS[CURRENT_ENTITY_ID] IS EMPTY");
				return;
			}

			const response = await staticGroup.staticGroupCreate().post(group);
			if (response && response.data.success) {
				group.id = response.data.data;
			}
		} catch (error) {
			this.loadingState = LoadingState.Error;
			console.error(error);
		}
	};

	/**
	 * @description Метод для обновления статической группы
	 * @param group - статическая группа
	 */
	updateStaticGroup = async (group: StaticGroup): Promise<void> => {
		const currentEntity = dispatcher.entity.get();
		try {
			if (!currentEntity) {
				this.loadingState = LoadingState.Error;
				console.error("ERROR FOR SYNC FIELD SECTIONS[CURRENT_ENTITY_ID] IS EMPTY");
				return;
			}

			const response = await staticGroup.staticGroupUpdate().post(group);
			if (response && response.data.success) {
				if (selector.filter.getFilter()?.staticGroup?.id === group.id) {
					dispatcher.filter.setStaticGroup(group);
				}
				dispatcher.filter.setPlaceholder();
				sessionStore.setFilter(currentEntity.entityName, currentEntity.entity.filter || null);
			}
		} catch (error) {
			this.loadingState = LoadingState.Error;
			console.error(error);
		}
	};

	/**
	 * @description Метод для удаления статической группы
	 * @param staticGroupId - id статической группы
	 */
	deleteStaticGroup = async (staticGroupId: string): Promise<void> => {
		try {
			await staticGroup
				.staticGroupDelete(staticGroupId)
				.delete()
				.then(async (response) => {
					if (response && response.data.success) {
						await this.getStaticGroupFolderTree();
						if (staticGroupId === selector.filter.getFilter()?.staticGroup?.id) {
							dispatcher.filter.setStaticGroup(null);
							dispatcher.filter.setPlaceholder();
							await synchroiser.getEntityWithFilter();
						}
					}
				});
		} catch (error) {
			this.loadingState = LoadingState.Error;
			console.error(error);
		}
	};

	/**
	 * @description Метод для исключения записей статической группы
	 * @param data - данные, необходимые для исключения
	 */
	excludeRecordsFromStaticGroup = async (data: ExcludeRecordsFromStaticGroup): Promise<void> => {
		this.loadingState = LoadingState.Loading;
		try {
			await staticGroup
				.excludeRecords()
				.post(data)
				.then(async (response) => {
					if (response && response.data.success) {
						await this.getEntityWithFilter();
					} else {
						this.loadingState = LoadingState.Error;
						console.error(response?.data?.error);
					}
				});
		} catch (error) {
			this.loadingState = LoadingState.Error;
			console.error(error);
		}
	};

	/**
	 * @description Метод для получения дерева динамических фильтров
	 * @param basicEntity - объект BasicEntity (для первой инициализации, когда вызывается getEntity())
	 * @param entityNameOfNewEntity - системное название раздела (для первой инициализации, когда вызывается getEntity())
	 */
	getSavedFilterFolderTree = async (basicEntity?: BasicEntity, entityNameOfNewEntity?: string): Promise<void> => {
		const currentEntity = basicEntity || dispatcher.entity.get()?.entity;
		try {
			if (!currentEntity) {
				this.loadingState = LoadingState.Error;
				console.error("ERROR FOR SYNC FIELD SECTIONS[CURRENT_ENTITY_ID] IS EMPTY");
				return;
			}
			const entityName = entityNameOfNewEntity || dispatcher.entity.get()!.entityName;
			await savedFilter
				.getFilterTree(authStore.userId!, entityName)
				.get()
				.then((response) => {
					if (response.data.success) {
						if (!currentEntity.filterTree) {
							if (basicEntity) {
								currentEntity.filterTree = selector.filter.getNewFilterTree(entityName);
							} else {
								dispatcher.filter.generateNewFilterTree();
							}
						}
						currentEntity.filterTree!.savedFilterTree = response.data.data;

						if (!basicEntity) {
							sessionStore.setFilter(dispatcher.entity.get()!.entityName, currentEntity.filter || null);
						}
					}
				});
		} catch (error) {
			this.loadingState = LoadingState.Error;
			console.error(error);
		}
	};

	/**
	 * @description Метод для получения дерева динамических фильтров
	 * @param basicEntity - объект BasicEntity (для первой инициализации, когда вызывается getEntity())
	 * @param entityNameOfNewEntity - системное название раздела (для первой инициализации, когда вызывается getEntity())
	 */
	getStaticGroupFolderTree = async (basicEntity?: BasicEntity, entityNameOfNewEntity?: string): Promise<void> => {
		const currentEntity = basicEntity || dispatcher.entity.get()?.entity;
		try {
			if (!currentEntity) {
				this.loadingState = LoadingState.Error;
				console.error("ERROR FOR SYNC FIELD SECTIONS[CURRENT_ENTITY_ID] IS EMPTY");
				return;
			}
			const entityName = entityNameOfNewEntity || dispatcher.entity.get()!.entityName;
			await staticGroup
				.getFilterTree(authStore.userId!, entityName)
				.get()
				.then((response) => {
					if (response.data.success) {
						if (!currentEntity.filterTree) {
							if (basicEntity) {
								currentEntity.filterTree = selector.filter.getNewFilterTree(entityName);
							} else {
								dispatcher.filter.generateNewFilterTree();
							}
						}
						currentEntity.filterTree!.staticGroupFolderTree = response.data.data;
						if (!basicEntity) {
							sessionStore.setFilter(dispatcher.entity.get()!.entityName, currentEntity.filter || null);
						}
					}
				});
		} catch (error) {
			this.loadingState = LoadingState.Error;
			console.error(error);
		}
	};

	/**
	 * @description Метод для сохранения папки фильтра/статической группы
	 * @param savedFilterFolder - папка фильтра для сохранения
	 * @param staticGroupFolder - папка статической группы для сохранения
	 */
	saveFilterFolder = async (savedFilterFolder: SavedFilterFolder | null, staticGroupFolder: StaticGroupFolder | null): Promise<void> => {
		const currentEntity = dispatcher.entity.get()?.entity;
		try {
			if (!currentEntity) {
				this.loadingState = LoadingState.Error;
				console.error("ERROR FOR SYNC FIELD SECTIONS[CURRENT_ENTITY_ID] IS EMPTY");
				return;
			}
			let response = null;
			if (savedFilterFolder) {
				response = await savedFilter.createFolder().post(savedFilterFolder);
				if (response && response.data.success) {
					savedFilterFolder.id = response.data.data;
				}
			}
			if (staticGroupFolder) {
				response = await staticGroup.createFolder().post(staticGroupFolder);
				if (response && response.data.success) {
					staticGroupFolder.id = response.data.data;
				}
			}
			if (!response || !response.data.success) {
				this.loadingState = LoadingState.Error;
				//TODO переделать в будущем на генератор ошибок
				this.errorMessage = "Не удалось сохранить папку";
			}
		} catch (error) {
			this.loadingState = LoadingState.Error;
			//TODO переделать в будущем на генератор ошибок
			this.errorMessage = "Не удалось сохранить папку";
			console.error(error);
		}
	};

	/**
	 * @description Метод для обновления папки фильтра/статической группы
	 * @param savedFilterFolder - папка фильтра для обновления
	 * @param staticGroupFolder - папка статической группы для обновления
	 */
	updateFilterFolder = async (
		savedFilterFolder: SavedFilterFolder | null,
		staticGroupFolder: StaticGroupFolder | null
	): Promise<void> => {
		const currentEntity = dispatcher.entity.get()?.entity;
		try {
			if (!currentEntity) {
				this.loadingState = LoadingState.Error;
				//TODO переделать в будущем на генератор ошибок
				this.errorMessage = "Не удалось обновить папку";
				console.error("ERROR FOR SYNC FIELD SECTIONS[CURRENT_ENTITY_ID] IS EMPTY");
				return;
			}
			let response = null;
			if (savedFilterFolder) {
				response = await savedFilter.updateFolder().post(savedFilterFolder);
			}
			if (staticGroupFolder) {
				response = await staticGroup.updateFolder().post(staticGroupFolder);
			}
			if (!response || !response.data.success) {
				this.loadingState = LoadingState.Error;
				//TODO переделать в будущем на генератор ошибок
				this.errorMessage = "Не удалось обновить папку";
			}
		} catch (error) {
			this.loadingState = LoadingState.Error;
			//TODO переделать в будущем на генератор ошибок
			this.errorMessage = "Не удалось обновить папку";
			console.error(error);
		}
	};

	/**
	 * @description Метод для удаления папки фильтра/статической группы
	 * @param folderId - id папки для удаления
	 */
	deleteFolder = async (savedFilterFolder: SavedFilterFolder | null, staticGroupFolder: StaticGroupFolder | null) => {
		try {
			let response = null;
			if (savedFilterFolder) {
				response = await savedFilter.deleteFolder(savedFilterFolder.id!).delete();
			}
			if (staticGroupFolder) {
				response = await staticGroup.deleteFolder(staticGroupFolder.id!).delete();
			}
			if (response && response.data.success) {
				if (savedFilterFolder) {
					await this.getSavedFilterFolderTree();
				}
				if (staticGroupFolder) {
					await this.getStaticGroupFolderTree();
				}
			}
		} catch (error) {
			this.loadingState = LoadingState.Error;
			console.error(error);
		}
	};

	/**
	 * Обновляет запись в sectionViewPageSettings.
	 * @param body - тело запроса на обновление записи
	 */
	updateSectionViewPageSettings = async (body: SectionViewPageSettings, entity: Entity | undefined) => {
		if (!body) {
			return null;
		}
		try {
			if (body.userId === null) {
				const { id, ...newBody } = { ...body };
				const createBody = {
					...newBody,
					userId: authStore.userId
				};
				await sectionViewPageSettings
					.sectionCreate()
					.post(createBody)
					.then((request) => {
						if (request.data.success && entity) {
							entity.entity.sectionViewPageSettings = {
								...createBody,
								id: request.data.data
							};
						}
					});

				return;
			}
			await sectionViewPageSettings.sectionUpdate().put(body);
		} catch (err) {
			console.error(err);
		}
	};

	postSuccessfulAuthSync = async () => {
		if (store.user.id) {
			if (sessionStore.checkSections()) {
				store.sections = sessionStore.getSections();
			}
			if (sessionStore.checkEntities()) {
				dispatcher.entity.setAll(sessionStore.getEntities());
			}
			await this.getSectionsList();
		} else {
			console.error("ERROR FOR SYNC FIELD USER IS EMPTY");
		}
	};

	deleteComment = (commentId: string) => {
		dispatcher.comments.remove(commentId);
		api.http.comment.commentById(commentId).delete();
	};

	addComments = (comment: string) => {
		const uuid = v4();
		const currentEntityName = UpFirst(dispatcher.entity.get()!.entityName);
		dispatcher.subscribers.add(uuid);
		dispatcher.reactions.set(uuid, SubscribeType.PENDING_SEND);

		const date = new Date().toISOString();

		dispatcher.comments.add({
			id: uuid,
			userId: store.user.id,
			entityId: store.currentRowId,
			entityName: currentEntityName,
			text: comment,
			userName: store.user.userName,
			isOwner: true,
			createdOn: date,
			modifiedOn: date
		});
		api.http.comment
			.sendComments()
			.post({
				text: comment,
				id: uuid,
				userId: store.user.id,
				entityId: store.currentRowId,
				entityName: currentEntityName
			})
			?.then(() => {
				dispatcher.reactions.set(uuid, SubscribeType.NONE);
			});
		dispatcher.reactions.reset(uuid);
	};

	editComments = (id: string, comment: string) => {
		const date = new Date().toISOString();
		dispatcher.comments.edit(id, comment, date);
		api.http.comment.sendComments(id).post({
			text: comment
		});
	}; // TODO Доработать

	getCurrentTarget = (pathFragments: Array<string>) => {
		this.getSectionsList()?.then(() => {
			dispatcher.entity.switchSectionByName(pathFragments[1]);
			this.getEntity().then(() => {
				if (pathFragments[2]) {
					dispatcher.currentRow.switch(pathFragments[2]);
				}
			});
		});
	};

	/**
	 * @description Проверяет системное название таблицы на дублирование в системе.
	 */
	async checkExistEntityName(tableName: string) {
		try {
			const response = await api.http.entity.checkExistEntityName(tableName).get();
			let data = response.data;
			if (data.success) {
				return data.data;
			} else {
				console.error(data.message);
			}
		} catch (error: any) {
			console.error("An error occurred:", error);
		}
	}
	/**
	 * @description Проверяет название таблицы на дублирование в системе.
	 */
	async checkExistEntityTitle(tableTitle: string, entityNameType: EntityNameType) {
		try {
			const response = await api.http.entity.checkExistEntityTitle(tableTitle, entityNameType).get();
			let data = response.data;
			if (data.success) {
				return data.data;
			} else {
				console.error(data.message);
			}
		} catch (error: any) {
			console.error("An error occurred:", error);
		}
	}

	/**
	 * @description Получает стадии и правила их перехода.
	 */
	async getSectionFromDesigner(entityName: string) {
		try {
			const response = await api.http.stage.getSectionFromDesigner(entityName).get();
			let data = response.data;
			if (data.success) {
				return data.data;
			} else {
				console.error(data.message);
			}
		} catch (error: any) {
			console.error("An error occurred:", error);
		}
	}

	async getPriority(priorityFieldName: string) {
		try {
			const response = await entity.recordsListWithColumns().post({
				entityName: priorityFieldName,
				columnNames: ["order", "hextextcolor"]
			});
			const data = response.data;

			if (!data.success) {
				console.error(data.message);
				return;
			}

			const entityStore = dispatcher.entity.get();
			if (entityStore) {
				action("setting priorities", () => {
					entityStore.priorities = data.data.records;
				})();
			}
			return data.data;
		} catch (error: any) {
			console.error("An error occurred:", error);
		}
	}

	getKanbanData(): KanbanData {
		const rows = dispatcher.entity.get()?.entity.rows ?? null;
		const sectionWizzard = dispatcher.sectionWizzard.getSectionWizzard() ?? null;
		const stagesConfig = selector.stageModels.getAll() ?? null;
		const gridItems = dispatcher.sectionWizzard.getAllGridItems();
		const kanbanConfig = sectionWizzard?.kanbanConfig ?? null;
		const stageFieldsName = getStagesColumnName(gridItems);
		const priorityFieldsName = getPriorityColumnName(gridItems);
		const inStorePriorities = dispatcher.entity.get()?.priorities;

		const stagesData =
			stagesConfig?.map((stageConfig) => ({
				id: stageConfig.id,
				name: stageConfig.name,
				color: stageConfig.color,
				isVisible: stageConfig.isHidden,
				allowStages: stageConfig.stagesTo
			})) ?? [];

		const stagesCards: Array<KanbanPageStage> = stagesData.map((stage) => {
			const cards =
				rows?.filter((row) => {
					return row[lowerFirst(`${stageFieldsName}`)]?.id === stage.id;
				}) ?? [];

			const uniqueCardIds = new Set<string>();

			let mappedCards: Array<KanbanPageCardProps> = cards
				.map((card) => {
					if (uniqueCardIds.has(card.id)) {
						return null; // Если ID уже существует, пропускаем эту карточку
					}
					uniqueCardIds.add(card.id);

					const filteredCard: KanbanPageCardProps = {} as KanbanPageCardProps;
					filteredCard.id = card.id;

					filteredCard.name =
						card[
							lowerFirst(
								gridItems.find((gridItem) => gridItem.fieldConfig?.columnId === sectionWizzard?.viewColumnId)?.fieldConfig
									?.columnName
							) ?? ""
						];
					filteredCard.fields = {};

					cards?.find((row) => {
						if (row.id === card.id) {
							const currentPriorityId = row[lowerFirst(`${priorityFieldsName}`)]?.id;

							const currentPriority = inStorePriorities?.find(
								(priority: { id: string }) => priority.id === currentPriorityId
							);

							if (!currentPriority) {
								return false;
							}

							filteredCard.sysPosition = card?.sysPosition;
							filteredCard.color = currentPriority?.hEXTextColor;
							filteredCard.priority = currentPriority?.name;

							return true;
						}
					});

					kanbanConfig?.cardDesign.additionalFields.forEach((field) => {
						const row = getColumnValueAndTypeByColumnId(rows ?? [], gridItems, card.id, field.columnId);
						if (row === null) {
							return;
						}
						const fieldTitle =
							dispatcher.sectionWizzard
								.getAllGridItems()
								.find((gridItem) => gridItem.fieldConfig?.columnId === field.columnId)?.fieldConfig?.columnTitle ?? "";
						const fieldValue = fieldValueOfTypeParser(row.type, row.value ?? "");
						filteredCard.fields[fieldTitle] = fieldValue ?? "";
					});
					filteredCard.userFields = {};
					kanbanConfig?.cardDesign.userFields.forEach((field) => {
						const fieldName = lowerFirst(
							dispatcher.sectionWizzard
								.getAllGridItems()
								.find((gridItem) => gridItem.fieldConfig?.columnId === field.columnId)?.fieldConfig?.columnName ?? ""
						);
						const fieldTitle =
							dispatcher.sectionWizzard
								.getAllGridItems()
								.find((gridItem) => gridItem.fieldConfig?.columnId === field.columnId)?.fieldConfig?.columnTitle ?? "";
						const searchedRowFieldTitle = card[fieldName ?? ""]?.displayValue ?? card[fieldName ?? ""]?.name;
						if (searchedRowFieldTitle) {
							filteredCard.userFields[fieldTitle] = searchedRowFieldTitle;
						}
					});

					const sysColumnNameFlagLowFirst = LowFirst(selector.sectionWizzard.getSysFlagColumn()?.fieldConfig?.columnName ?? "");
					filteredCard.sysFlag = selector.sectionWizzard.getSysFlagColumn()?.fieldConfig?.columnName
						? card[sysColumnNameFlagLowFirst]
						: false;
					return filteredCard;
				})
				.filter((card: KanbanPageCardProps | null) => !isNull(card)) as Array<KanbanPageCardProps>;

			if (!dispatcher.entity.get()?.entity.sort) {
				mappedCards = mappedCards.sort((aCard, bCard) => {
					if (aCard.sysPosition > bCard.sysPosition) {
						return 1;
					} else if (aCard.sysPosition < bCard.sysPosition) {
						return -1;
					} else {
						return 0;
					}
				});
			}

			return {
				id: stage.id,
				name: stage.name,
				color: stage.color,
				isVisible: stage.isVisible,
				allowTo: stage.allowStages,
				cards: mappedCards
			};
		});

		return {
			stages: stagesCards ?? [],
			allowQuickActions: sectionWizzard?.kanbanConfig?.cardDesign.quickActionEnable ?? false,
			quickView: sectionWizzard?.kanbanConfig?.quickViewDesign ?? null
		};
	}

	/**
	 * @description Метод для обновления ширнины отображаемой колонки
	 */
	async updateVisibleColumnWidth(columnId: string, width: number): Promise<void> {
		dispatcher.entity.setVisibleColumnWidth(columnId, width);
		const body = {
			...dispatcher.entity.get()?.entity.sectionViewPageSettings,
			forAllUsers: false,
			userId: authStore.userId
		};

		if (isNull(dispatcher.entity.get()?.entity.sectionViewPageSettings?.userId)) {
			const { id, ...createBody } = body;
			api.http.sectionViewPageSettings
				.sectionCreate()
				.post(createBody)
				.then((response) => {
					dispatcher.entity.get()!.entity.sectionViewPageSettings!.id = response.data.data;
					dispatcher.entity.get()!.entity.sectionViewPageSettings!.forAllUsers = false;
					dispatcher.entity.get()!.entity.sectionViewPageSettings!.userId = authStore.userId;
				});
		} else {
			await api.http.sectionViewPageSettings.sectionUpdate().put(body);
		}
	}
}

export const synchroiser = new Synchroiser();
