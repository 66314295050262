import { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { upperFirst } from "lodash";
import classNames from "classnames";
import { toJS } from "mobx";

import { dispatcher, store } from "store";
import { synchroiser } from "synchroiser";
import { singlePageSynchroiser } from "./single-page-synchroiser/single-page-synchroiser";
import { sessionStore } from "store";
import { businessRulesEngine } from "features/business-rules-engine";

import { ViewMode } from "./type";
import { LoadingState } from "types/entity";
import { Loader, QuickView } from "./components";
import { SkeletonGrid } from "components";

import { NotFoundPage } from "../error-page/error-page";
import { ControlPanel } from "./control-panel";
import { Grid, Kanban, EmptyContent } from "./views";

import { getKanbanColumns } from "./single-page-synchroiser/get-kanban-columns";

import { AdvancedFilterConst } from "features/section-head/data/constants";
import AdvancedFilterTree from "features/advanced-filter/advanced-filter-tree/advanced-filter-tree";
import AdvancedFilterElement from "features/advanced-filter/advanced-filter";

import styles from "./single-page.module.scss";

export const SinglePage = observer(() => {
	const entityName = useParams().entityName;
	const location = useLocation();
	const navigate = useNavigate();

	const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.GRID);
	const [selectedRecordId, setSelectedRecordId] = useState<null | string>(null);

	useEffect(() => {
		async function init() {
			await singlePageSynchroiser.init(entityName ?? "", {
				viewModeCallback: (viewMode: ViewMode) => {
					setViewMode(viewMode);
				}
			});
		}
		init();
		return () => {
			businessRulesEngine.dispose();
		};
	}, [entityName]);

	const hasFilters = useMemo(
		() => Boolean(dispatcher.entity.get()?.entity.filter?.placeholder),
		[Boolean(dispatcher.entity.get()?.entity.filter?.placeholder)]
	);

	const hasRecords = useMemo(
		() => Boolean(dispatcher.entity.get()?.entity.rows.length),
		[Boolean(dispatcher.entity.get()?.entity.rows.length)]
	);

	const quickViewEnabled = useMemo(
		() => dispatcher.sectionWizzard.getSectionWizzard()?.kanbanConfig?.quickViewDesign.quickViewEnable,
		[dispatcher.sectionWizzard.getSectionWizzard()?.kanbanConfig?.quickViewDesign.quickViewEnable]
	);

	const selectedNameRecord = useMemo(
		() => (selectedRecordId ? dispatcher.entity.get()?.entity.rows.find((record) => selectedRecordId === record.id)?.name : ""),
		[selectedRecordId]
	);

	const handleSwitchViewMode = useCallback(
		(nextMode: ViewMode) => {
			const entityName = dispatcher.entity.get()?.entityName;
			singlePageSynchroiser.viewLoading();
			if (nextMode === ViewMode.GRID) {
				synchroiser.getEntity().then(singlePageSynchroiser.viewLoaded);
				singlePageSynchroiser.setLastSort = dispatcher.entity.get()?.entity.sort ?? null;
			} else {
				dispatcher.entity.setSort(singlePageSynchroiser.lastSort);
				singlePageSynchroiser.setSortInLocalStorage(null, entityName);
				getKanbanColumns(entityName ?? "").then(singlePageSynchroiser.viewLoaded);
			}
			setViewMode(nextMode);
			sessionStore.setBufferedViewMode(entityName ?? "", nextMode);
		},
		[dispatcher.entity.get()?.entityName]
	);

	const handleOpenRecordInQuickView = useCallback(
		(recordId: string) => {
			if (!quickViewEnabled) {
				const toPath =
					location.pathname === `/singlePage/${entityName}`
						? `${recordId}`
						: `/singlePage/${entityName?.toLocaleLowerCase()}/${recordId}`;
				navigate(toPath);
			}

			setSelectedRecordId(recordId ?? null);
			// businessRulesEngine.setSectionWizzard(dispatcher.sectionWizzard.getSectionWizzard() ?? null);
		},
		[quickViewEnabled, location.pathname, entityName, navigate, toJS(dispatcher.sectionWizzard.getSectionWizzard())]
	);

	const handleCloseQuickView = useCallback(() => {
		setSelectedRecordId(null);
		dispatcher.currentRow.rollback();
		businessRulesEngine.dispose();
	}, []);

	const view = useMemo(() => {
		if (synchroiser.loadingState === LoadingState.Loading) {
			if (viewMode === ViewMode.GRID) {
				return <SkeletonGrid />;
			} else {
				return <Loader />;
			}
		} else if (hasRecords) {
			if (viewMode === ViewMode.GRID) {
				return <Grid />;
			} else {
				return <Kanban onOpenQuickView={handleOpenRecordInQuickView} />;
			}
		} else {
			return <EmptyContent hasFilters={hasFilters} />;
		}
	}, [viewMode, handleOpenRecordInQuickView, hasRecords, hasFilters, synchroiser.loadingState]);

	const centralPanel = useMemo(() => {
		const centralPanelClasses = classNames(styles.sectionPage, {
			[`${styles.hideFilter}`]: !(AdvancedFilterConst.isOpenAdvancedFilterTree || AdvancedFilterConst.isOpenAdvancedFilter)
		});

		return (
			<div className={centralPanelClasses}>
				<AdvancedFilterTree isOpen={AdvancedFilterConst.isOpenAdvancedFilterTree} />
				<AdvancedFilterElement
					isOpen={AdvancedFilterConst.isOpenAdvancedFilter && AdvancedFilterConst.filter !== null}
					advancedFilter={AdvancedFilterConst.filter!}
				/>
				<div className={styles.sectionHeadContent}>
					<ControlPanel setViewMode={handleSwitchViewMode} viewMode={viewMode} />
					{singlePageSynchroiser.viewLoader ? (
						<div className={styles.contentLoader}>
							<Loader />
						</div>
					) : (
						<>{view}</>
					)}
				</div>
			</div>
		);
	}, [
		AdvancedFilterConst.isOpenAdvancedFilterTree,
		AdvancedFilterConst.isOpenAdvancedFilter,
		AdvancedFilterConst.filter,
		singlePageSynchroiser.viewLoader,
		viewMode,
		view
	]);

	// TODO Сюда докидывать обработчики ошибок, если они добавяться
	const hasError = useMemo(() => !entityName || store.sections?.length === 0, [entityName]);

	if (hasError) {
		return <NotFoundPage />;
	}

	return (
		<>
			{synchroiser.loadingState === LoadingState.Error && !synchroiser.errorMessage ? (
				<NotFoundPage />
			) : (
				<>
					{selectedRecordId && quickViewEnabled && (
						<QuickView
							onClose={handleCloseQuickView}
							schema={upperFirst(entityName ?? "")}
							recordId={selectedRecordId ?? ""}
							stageId=""
							recordName={selectedNameRecord}
						/>
					)}
					{singlePageSynchroiser.sectionLoader ? <Loader /> : centralPanel}
				</>
			)}
		</>
	);
});
