import { SortDirection } from "entities/ISort";

export const DEFAULT_SORT = {
	columnPath: "CreatedOn",
	direction: SortDirection.Descending
};

export const UPLOAD_QUANTITY = 5;

export const STAGE_UPLOADING_QUANTITY_COMPENSATOR = 1;

export const SYS_POSITION = "sysPosition";
