import { useCallback, useState } from "react";
import classNames from "classnames";

import { Button } from "components";

import { IFieldSelectProps } from "pages/section-wizzard/data/data";

import { Icon } from "sale-bridge-ui-kit";

import styles from "./field-select.module.scss";

export function FieldSelect(props: IFieldSelectProps) {
	const [isOpened, setOpened] = useState(true);

	const listClassNames = classNames(`${styles.list} `, {
		[`${styles.visible} `]: isOpened,
	});
	const wrapClassNames = classNames(`${styles.wrapper} `, {
		[`${props.className} `]: props.className,
	});

	const buttonClassNames = classNames(`${styles.button} `);
	const secondIconClassNames = classNames(`${styles.secondIcon} `, {
		[`${styles.close} `]: isOpened,
	});

	const openMenu = useCallback(() => {
		setOpened(!isOpened);
	}, [isOpened]);

	return (
		<div className={wrapClassNames}>
			<Button
				classNameSecondIcon={secondIconClassNames}
				className={buttonClassNames}
				secondIcon={<Icon size='16' name='ChevronDown' />}
				caption={props.name}
				onClick={openMenu}
				style={props.buttonStyle}
				selected={isOpened}
			/>
			<div className={listClassNames}>
				{props.children}
			</div>
		</div>
	);
}

