import React, { useEffect, useMemo, useRef, useState } from "react";
import { cloneDeep } from "lodash";

import { Cell } from "../../../cell";

import { InnerElement } from "../../../inner-element";

import styles from "./style.module.css";

type Props = {
	config: any;
	id: string;
	selectedElement: string | null;
	zone: { [key: string]: any; };
	targetCell: {
		x: number;
		y: number;
	};
	setElemCenter: (value: { x: number, y: number }) => void;
	setHasMove: (value: boolean) => void;
	setSelectedElementId: (value: string) => void;
	replaceEnable?: boolean;
	targetZone?: string | null;
	setElementSize: (value: { width: number, height: number }) => void;
	elementSize: { width: number, height: number };
	onUp?: ({ elementId }: { elementId: string }) => void;
}

const EMPTY_CELL = "EMPTY_CELL";

function Matrix(props: Props) {
	const [grid, setGrid] = useState<Array<Array<any>>>([]);

	const matrixContainer = useRef<HTMLInputElement>(null);

	/**
	 * @description Наполнение grid-матрицы элементами, пустыми->replace->существующие
	 */
	useEffect(() => {
		const inZoneElements = props.zone.elements;
		if (inZoneElements) {
			const newGrid: Array<Array<any>> = [];
			for (let row = 0; props.config.height !== row; row++) {
				newGrid.push([]);
				for (let column = 0; props.config.width !== column; column++) {
					newGrid[row].push(null);
				}
			}
			Object.keys(inZoneElements).forEach((elementId, index) => {
				const element = inZoneElements[elementId];
				if (!element) {
					return;
				}
				// TODO начинаем с 1, а не с 0
				if (element.position.x < 0 || element.position.y < 0) {
					return;
				}

				try {


					newGrid[element?.position?.y || 0][element?.position?.x || 0] = element;
					for (let y = 0; y < element.size.height || 0; y++) {
						for (let x = 0; x < element.size.width || 0; x++) {
							if (y !== 0 || x !== 0) {
								if (element.position.x < 0 || element.position.y < 0) {
									return;
								}
								newGrid[(element?.position?.y + y) || 0][(element?.position?.x + x) || 0] = EMPTY_CELL;
							}
						}
					}
				} catch (error) {
					console.error(`
					Элемент находится вне zone ${props.id}
					Размеры зоны
					widthZone: ${props.config.width}
					heightZone: ${props.config.height}
					Элемент: ${element.id}
					xElement: ${element.size.x}
					yElement: ${element.size.y}
					widthElement: ${element.size.width}
					heightElement: ${element.size.height}
					`);

					throw error;
				}
			});
			setGrid(newGrid);
		}
	}, [props.config.height, props.config.width, props.id, props.zone.elements]);

	const elements = useMemo(() => grid.map((row, rowIndex) => <div key={rowIndex}>{row.map((element, columnIndex) => {
		if (!element?.fragment) {
			if (element?.layout) {
				return (<Cell
					matrixSize={{ width: element.layout.props.width, height: element.layout.props.height }}
					zoneId={props.id}
					position={{ x: element.position.x, y: element.position.y }}
					key={`X:${element.position.x} Y:${element.position.y}`}
					size={{ width: props.config.cellSize.width * element.size.width, height: props.config.cellSize * element.size.height }}
					placeholderLayout={props.config.placeholderLayout}
					predictLayout={props.config.predictLayout}
					cellLayout={props.config.cellLayout}
					selectedElementId={props.selectedElement}
					targetCell={props.targetCell}
					targetZone={props.targetZone}
					elementSize={props.elementSize}
				>
					<InnerElement
						predictLayout={props.config.predictLayout}
						setElemCenter={props.setElemCenter}
						selectedElementId={props.selectedElement}
						setHasMove={props.setHasMove}
						setSelectedElementId={props.setSelectedElementId}
						width={element.size.width}
						height={element.size.height}
						setElementSize={props.setElementSize}
						onUp={props.onUp}
					>
						{element.layout}
					</InnerElement>
				</Cell>);
			} else if (element === EMPTY_CELL) {
				<></>;
			} else {
				return (<Cell
					matrixSize={{ width: 1, height: 1 }}
					zoneId={props.id}
					position={{ x: columnIndex, y: rowIndex }}
					key={`X:${rowIndex} Y:${columnIndex} empty cell`}
					size={{ width: props.config.cellSize.width * 1, height: props.config.cellSize * 1 }}
					placeholderLayout={props.config.placeholderLayout}
					predictLayout={props.config.predictLayout}
					cellLayout={props.config.cellLayout}
					selectedElementId={props.selectedElement}
					targetCell={props.targetCell}
					replaceEnable={props.replaceEnable}
					targetZone={props.targetZone}
					elementSize={props.elementSize}
					r="NEW"
				>
					{props.config.cellLayout}
				</Cell>);
			}
		} else {
			return (<div key={Math.random()} style={{ width: `${element.size.width}px`, height: `${element.size.height}px` }}>{ }</div>);
		}
	})}</div>), [
		grid, props.config.cellLayout, props.config.cellSize, props.config.placeholderLayout,
		props.config.predictLayout, props.elementSize, props.id,
		props.onUp, props.replaceEnable, props.selectedElement, props.setElemCenter,
		props.setElementSize, props.setHasMove, props.setSelectedElementId, props.targetCell,
		props.targetZone
	]);

	// TODO grind is not updating with react strict mode
	return (
		<div
			ref={matrixContainer}
			className={styles.matrix}
		>
			{elements}
		</div>
	);
};

export { Matrix };
