import { eventBus } from "app/services";
import { api } from "shared";

import FileStoreEvents from "../types/FileStoreEvents";
import { FileStorages } from "entities/FileStorages";
class DataBaseStore {
	async swithStore(isMoveFile: boolean) {
		const response = await api.http.file.editSource().put({ sourceId: FileStorages.Db, needTransferFiles: isMoveFile });
		eventBus.dispatchEvent(FileStoreEvents.Switch, FileStorages.Db);
	}
}

const dataBaseStore = new DataBaseStore();

export default dataBaseStore;
