import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";

import { dispatcher } from "store";

import { Icon } from "sale-bridge-ui-kit";

import { Position } from "../../controller";
import { ModalData } from "../modal-viewer";

import styles from "./../modal-viewer.module.scss";

const TIME_FOR_HIDDEN = 10000;
const TIME_FOR_DELETE_AFTER_HIDDING = 12000;
const TIME_FOR_DELETE = 2000;

export function NotificationError({ id, position, layout, withBackdrop }: ModalData) {
    const [isOpen, setIsOpen] = useState<boolean>(true);
    const [timer, setTimer] = useState<NodeJS.Timeout | null>();

    const styleName = classNames(`${styles.notification} ${styles.error}`, {
        [`${styles.center} `]: position === Position.CENTER,
        [`${styles.left} `]: position === Position.LEFT,
        [`${styles.closeNotification} `]: !isOpen,
    });

    useEffect(() => {
        setTimer(setTimeout(() => {
            setIsOpen(false);
            setTimeout(() => {
                dispatcher.modal.remove(id);
            }, TIME_FOR_DELETE_AFTER_HIDDING);
        }, TIME_FOR_HIDDEN));

        if (timer) {
            clearTimeout(timer);
        }
    }, []);

    const handleClick = useCallback(() => {
        if (timer) {
            clearTimeout(timer);
        }
        setIsOpen(false);
        setTimeout(() => {
            dispatcher.modal.remove(id);
        }, TIME_FOR_DELETE);
    }, [timer]);

    return (
        <>
            {withBackdrop && <div className={styles.backdropWhite} />}
            <div className={styleName}>
                <div className={styles.layoutErrorNotification}>
                    {layout}
                </div>
                <div className={styles.closingPartOfErrorNotification} onClick={handleClick}>
                    <div className={styles.dividerOfErrorNotification} />
                    <Icon name="Close" size='12' className={styles.closeErrorNotification} />
                </div>
            </div>
        </>
    );
};