import IImportStage from '../types/IImportStage';
import DeduplicationRule from '../widgets/deduplication-rule/deduplication-rule';
import Finish from '../widgets/finish-stage/finish-stage';
import Mapping from '../widgets/mapping/mapping-values';
import SelectData from '../widgets/select-data/select-data';

import importStore, { ImportStore } from './import-store';

class SelectDataStage implements IImportStage {
    stageName: string = 'Выбор файла';
    context: ImportStore;
    constructor(context: ImportStore) {
        this.context = context;
    }

    render() {
        return <SelectData />;
    }
    async nextStage() {
        await this.context.loadProperties();
        await this.context.loadInfoOnFile();
        await this.context.initSignalR();
        this.context.stage = new MappingStage(this.context);
    }
    backStage() { }

    valid(): boolean {
        return (
            !(this.context.result.tableName == null || this.context.result.tableName.length === 0) &&
            this.context.file != null
        );
    }
}

class MappingStage implements IImportStage {
    stageName: string = 'Настройка колонок';
    context: ImportStore;
    constructor(context: ImportStore) {
        this.context = context;
    }

    render() {
        return <Mapping />;
    }
    nextStage() {
        this.context.stage = new DeduplicationRuleStage(this.context);
    }
    backStage() {
        importStore.result.mappingProperties = [];
        this.context.stage = new SelectDataStage(this.context);
    }

    valid(): boolean {
        let requiredList = importStore.properties.filter(
            (p) => p.isRequired === true && p.columnName !== 'Id'
        );
        let usingColumns = importStore.result.mappingProperties.map((m) => {
            return m.columnInModel;
        });
        const choosing = requiredList.filter((column) =>
            usingColumns.includes(column.columnName)
        ).length;

        let rule = importStore.requiredFields ? choosing === requiredList.length : choosing >= 1;
        return rule;
    }
}

class DeduplicationRuleStage implements IImportStage {
    stageName: string = 'Параметры дублей';
    context: ImportStore;
    constructor(context: ImportStore) {
        this.context = context;
    }

    render() {
        return <DeduplicationRule />;
    }
    nextStage() {
        this.context.stage = new FinishStage(this.context);
    }
    backStage() {
        this.context.stage = new MappingStage(this.context);
    }

    valid(): boolean {
        return this.context.result.upsertSettings.keyFields.size > 0;
    }
}

class FinishStage implements IImportStage {
    stageName: string = 'Импорт...';
    context: ImportStore;
    constructor(context: ImportStore) {
        this.context = context;
    }

    render() {
        return <Finish />;
    }
    nextStage() { }
    backStage() {
        this.context.stage = new DeduplicationRuleStage(this.context);
    }

    valid(): boolean {
        return true;
    }
}

export { SelectDataStage, MappingStage, DeduplicationRuleStage, FinishStage };
