import { useRef, useState } from "react";

import { Button, ButtonStyle, HierarchicalStyles } from "components";
import Styles, { IHierarchicalSelectStyles } from "../core/styles-system";
import HierarchicalItem from "./hierarchical-item";

import { Item } from "types";

import { ArrowToDownGrey } from "shared";

import stylesBase from "../../select.module.css";
import styles from "./hierarchical-select.module.css";

interface HierarchicalSelectProps {
	items: Item[] | undefined;
	value: Item;
	onChange: (selectedItem: Item) => void;
	onItemsLoad: () => void;
	className?: string;
	isInvalid?: boolean;
	styles?: HierarchicalStyles;
	placeholder?: string;
}

function HierarchicalSelect(props: HierarchicalSelectProps) {
	const wrapperRef = useRef<HTMLDivElement>(null);
	const [showItems, setShowItems] = useState(false);

	const hierarchicalStyles: IHierarchicalSelectStyles = Styles[props.styles ?? HierarchicalStyles.Primary];

	let classNames = `${stylesBase.wrapper} ${props.className}`;
	let listClassNames = `${stylesBase.list} `;
	let inputClassNames = hierarchicalStyles.value.classNames;
	let listItemClassNames = `${stylesBase.listItem} `;

	if (props.items && props.items!.length > 0 && showItems) {
		listClassNames += " " + stylesBase.visible;
	}

	if (showItems) {
		inputClassNames += hierarchicalStyles.value.focus;
	}

	function hideMenu() {
		setShowItems(false);
		document.removeEventListener("click", handleClick);
	}

	function handleClick(event: Event) {
		if (wrapperRef.current != null && !wrapperRef.current.contains(event.target as Node)) {
			hideMenu();
		}
	}

	const handleItemClick = (selectedItem: Item) => {
		setShowItems(false);
		props.onChange(selectedItem);
	};

	function onSelectOpen() {
		props.onItemsLoad();
		setShowItems(true);
		document.addEventListener("click", handleClick);
	}

	const onButtonClick = () => {
		if (showItems) {
			hideMenu();
		} else {
			onSelectOpen();
		}
	};

	return (
		<div ref={wrapperRef} className={classNames}>
			<div className={inputClassNames}>
				<div onClick={onSelectOpen} className={styles.item}>
					{props.value.name !== "" ? (
						<span className={styles.displayValue}>{props.value.name}</span>
					) : (
						<span className={styles.placeholder}>{props.placeholder ?? ""}</span>
					)}
				</div>
				<Button
					onClick={onButtonClick}
					firstIcon={<ArrowToDownGrey />}
					style={ButtonStyle.Icon}
					className={showItems ? stylesBase.selectButton + " " + stylesBase.close : stylesBase.selectButton}
				/>
			</div>
			<ul className={listClassNames}>
				{props.items?.map((item: Item) => (
					<li key={item.id} className={listItemClassNames}>
						<HierarchicalItem
							value={item}
							onClick={(item: Item) => {
								handleItemClick(item);
							}}
							isCanOpen={showItems}
						/>
					</li>
				))}
			</ul>
		</div>
	);
}

export default HierarchicalSelect;
