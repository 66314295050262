import { Icon } from "sale-bridge-ui-kit";

import styles from "./loader.module.scss";

export function Loader() {
    return (
        <div className={styles.loader}>
            <div>
                {/* TODO брать as large, когда полностю перейдем на новую метрику в ui-kit для size */}
                <Icon name="HourglassBottom3" size={"96" as "large"}/>
            </div>
        </div>
    )
}
