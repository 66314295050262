import { useCallback, useEffect, useMemo, useState } from "react";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { isNull } from "lodash";

import { store } from "../../store";

import { Stage } from './components/stage'
import { Button, ButtonDropdown } from "sale-bridge-ui-kit";

import { HEXColors, ResultHEXColors, ResultType, StageType } from "types/entity";
import { Item } from "types";
import { StandartItemGroup } from "sale-bridge-ui-kit/dist/components/button-dropdown/button-dropdown";

import styles from "./stage-panel.module.scss"

export interface StageParams extends Item {
    name: string;
    stageId: string;
    color: HEXColors | string;
    allowStages?: string[],
    resultType?: ResultType | null,
    stageType?: StageType,
    order: number,
    disabled?: boolean,
    opacity?: number,
    hoverForDefault?: boolean;
    isHidden?: boolean;
}

export type StagePanelProps = {
    intermediate: StageParams[],
    result: StageParams[],
    onChange: (currentStage: string) => void,
    current: string;
};

/**
 * @description Компонент Стадийная панель
 * @param intermediate массив промежуточных стадий
 * @param result массив результирующих стадий
 * @param current id текущей стадии
 * @param onChange handler смены стадии
 */
export const StagePanel = observer((props: StagePanelProps) => {
    const [finalStage, setFinalStage] = useState<StageParams>(props.result[0] ?? props.intermediate[props.intermediate.length - 1]);
    const [resultColor, setResultColor] = useState<ResultHEXColors | null>(null);
    const [stages, setStages] = useState<StageParams[]>([]);
    const [permanentStages, setPermanentStages] = useState<StageParams[]>([]);
    const [finalStages, setFinalStages] = useState<StageParams[]>([]);

    /**
     * @description установить текущую стадию
     */
    const handleChangeCurrent = useCallback((stageId: string, isDisable?: boolean) => {
        if (isDisable) {
            return;
        }
        store.hasChanges = true;
        props.onChange(stageId);
    }, [props]);

    /**
     * @description установить стадиям свойство disabled в зависимости от стадий, разрешенных для перехода
     */
    const setDisable = useCallback((currentStageId: string) => {
        const allStages: StageParams[] = [];
        const allowStages = toJS([...props.intermediate, ...props.result])
            .find((stage: StageParams) => stage.stageId === currentStageId)?.allowStages;

        toJS([...props.intermediate, ...props.result]).forEach((stage: StageParams) => {
            let isFlag = false;

            if (stage.stageId === currentStageId) {
                isFlag = true;
            } else {
                allowStages?.forEach((allowStageId) => {
                    if (allowStageId === stage.stageId) {
                        isFlag = true;
                    }
                });
            }

            stage.disabled = !isFlag;
            allStages.push(stage);
        });

        const sortedStages = allStages.sort((a, b) => a.order - b.order);
        const filteredStages = sortedStages.filter(stage => !stage.isHidden);

        setStages(prevState => prevState
            .map(stage => ({ ...stage, disabled: filteredStages.find(filteredStage => filteredStage.stageId === stage.stageId)?.disabled ?? false })));
        setPermanentStages(prevState => prevState
            .map(stage => ({ ...stage, disabled: filteredStages.find(filteredStage => filteredStage.stageId === stage.stageId)?.disabled ?? false })));
        setFinalStages(filteredStages.filter((stage) => !isNull(stage.resultType)));
    }, [props.intermediate, props.result, finalStages]);

    /**
     * @description меняет тип промежуточных и результирующих стадий
     */
    const handleChangeStageType = useCallback(() => {
        const allStages: StageParams[] = [];
        let isCurrentFind = false;
        let isFinal = false;
        let currentStageColor: string | null = null;

        [...props.intermediate, ...props.result].forEach((stage: StageParams) => {
            if (stage.stageId === props.current && !isNull(stage.resultType)) {
                isFinal = true;
            }
        });

        const sortedIntermediateStages = toJS(props.intermediate).sort((a, b) => a.order - b.order);
        const sortedResultStages = toJS(props.result).sort((a, b) => a.order - b.order);

        const combinedSortedStages = [...sortedIntermediateStages, ...sortedResultStages];

        combinedSortedStages.forEach((stage: StageParams) => {
            if (stage.stageId === props.current) {
                currentStageColor = stage.color;
                stage.stageType = StageType.CURRENT;
                isCurrentFind = true;
            } else {
                if (!isCurrentFind || !isNull(stage.resultType)) {
                    stage.stageType = StageType.PASSED;
                    stage.opacity = 1;
                } else {
                    stage.stageType = StageType.DEFAULT;
                    stage.opacity = 0.3;
                }
            }

            if (!isNull(stage.resultType) && !isFinal) {
                stage.stageType = StageType.DEFAULT;
            }
            allStages.push(stage);
        });

        if (!isFinal) {
            setResultColor(finalStage.color as ResultHEXColors || null);
        }

        const sortedStages = (structuredClone(allStages) as StageParams[]).sort((a, b) => a.order - b.order);

        sortedStages.forEach(stage => {
            if (stage.stageType === StageType.PASSED) {
                stage.color = currentStageColor!;
            }
        });

        const filteredStages = sortedStages.filter(stage => !stage.isHidden);

        setStages(filteredStages);
        setPermanentStages((structuredClone(allStages) as StageParams[]).sort((a, b) => a.order - b.order).filter(stage => !stage.isHidden));
        setFinalStages(filteredStages.filter((stage) => !isNull(stage.resultType)));
    }, [props.result, props.current, props.intermediate, finalStages]);


    useEffect(() => {
        handleChangeStageType();
        setDisable(props.current);
    }, [props.current]);

    /**
     * @description при выборе результирующей стадии устанавливает цвет для всех стадий в зависимости от результата
     */
    const handleDropDownChangeValue = useCallback((value: any) => {
        const stage = finalStages.find(finalStage => finalStage.id === value.id || finalStage.stageId === value.id);
        if (stage) {
            setFinalStage({ ...stage, color: stage.color, disabled: stage.disabled });
            handleChangeCurrent(stage.stageId, false);

            stages.map(x => x.stageType = StageType.PASSED);

            switch (stage.resultType) {
                case ResultType.Successful:
                    setResultColor(ResultHEXColors.Successful);
                    break;
                case ResultType.Negative:
                    setResultColor(ResultHEXColors.Negative);
                    break;
                case ResultType.Null:
                    setResultColor(ResultHEXColors.Null);
                    break;
                default:
                    setResultColor(ResultHEXColors.Null);
                    break;
            }
        }
    }, [stages, handleChangeCurrent, setFinalStage, setResultColor]);
    const handleMouseEnter = useCallback((stageId: string) => {
        const temporaryStages: StageParams[] = structuredClone(stages);
        const hoveredStageIndex = permanentStages.findIndex(stage => stage.stageId === stageId);
        const hoveredStage = permanentStages[hoveredStageIndex];

        if (!hoveredStage) {
            return;
        }

        for (let stageInd = 0; stageInd <= hoveredStageIndex; stageInd++) {
            temporaryStages[stageInd].color = hoveredStage.color;
        }

        if (hoveredStage.resultType != null) {
            for (let stageInd = hoveredStageIndex + 1; stageInd < temporaryStages.length; stageInd++) {
                temporaryStages[stageInd].color = hoveredStage.color;
            }
        }

        setStages(temporaryStages);
    }, [toJS(permanentStages), toJS(stages)]);

    const handleMouseLeave = useCallback(() => {
        const temporaryStages: StageParams[] = structuredClone(stages);
        const curentStage = permanentStages.find(stage => stage.stageType === StageType.CURRENT);
        if (!curentStage) {
            return;
        }
        temporaryStages.forEach(stage => {
            if (stage.stageType === StageType.PASSED) {
                stage.color = curentStage.color;
            }
        })
        setStages(temporaryStages);

    }, [toJS(permanentStages)]);

    const finalStagesForDropdown = useMemo((): Array<StandartItemGroup> => {
        const items: Array<StandartItemGroup> = [];
        finalStages.filter(stage => stage.stageId !== finalStage.stageId)
            .forEach(stage => items.push({
                header: '',
                items: [{
                    id: stage.id.toString(),
                    name: stage.name
                }]
            })
            )
        return items
    }, [toJS(finalStages), finalStage]);

    const handleClickToDropdown = useCallback((id: string) => {
        const findedStage = finalStages.find(stage => stage.id.toString() === id);
        if (findedStage) {
            handleDropDownChangeValue(findedStage);
        }
    }, [toJS(finalStages)]);

    /**
     * @description если нет результирующих стадий, то последняя по порядку стадия - последняя в массиве intermediate
     */
    return (
        <div className={styles.stagePanelWrapper}>
            {stages.map((stage) => (
                isNull(stage.resultType)
                && <Stage
                    id={stage.stageId}
                    disabled={stage.disabled}
                    isPassedOrCurrent={stage.stageType === StageType.PASSED || stage.stageType === StageType.CURRENT}
                    key={stage.stageId}
                    name={stage.name}
                    color={stage.color}
                    onClick={() => handleChangeCurrent(stage.stageId, stage.disabled)}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                />)
            )}
            {finalStages.length > 1 ? (
                <Stage
                    id={finalStage.stageId ?? finalStages[0].stageId}
                    name={finalStage.name ?? finalStages[0].name}
                    isPassedOrCurrent={finalStages[0].stageType !== StageType.DEFAULT}
                    disabled={finalStages[0].disabled}
                    color={resultColor ?? finalStages[0].color}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => handleDropDownChangeValue(finalStage)}
                >
                    <ButtonDropdown
                        size='small'
                        position='right-down'
                        standartItems={finalStagesForDropdown}
                        onClickStandartItem={handleClickToDropdown}
                        childrenButton={
                            <Button
                                text={finalStage.name}
                                onClick={() => { }}
                                size='small'
                                variant='backless'
                                rightIcon="Dropdown"
                                link={false}
                                loading={false}
                                border={false}
                            />
                        }
                    />
                </Stage>
            ) : (
                finalStages.length === 1 && (
                    <Stage
                        id={finalStages[0].stageId}
                        name={finalStages[0].name}
                        isPassedOrCurrent={finalStages[0].stageType !== StageType.DEFAULT}
                        disabled={finalStages[0].disabled}
                        color={resultColor ?? finalStages[0].color}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        onClick={() => handleDropDownChangeValue(finalStages[0])}
                    />
                )
            )}

        </div>
    );
});