import { observer } from "mobx-react";
import classNames from "classnames";
import { useCallback, useMemo, useRef } from "react";

import { LeftAdditionalFieldsBlock } from "./constructor-quick-view-left-block";
import { RightAdditionalFieldsBlock } from "./constructor-quick-view-right-block";

import { AdditionalInfoOfField } from "../quick-view-settings/data";
import { AdditionalField } from "types/entity";

import styles from "./constructor-quick-view.module.scss";

type ContructorQuickViewProps = {
    header: AdditionalField | null;
    commentsEnable: boolean;
    quickViewEnable: boolean;
    createdOnTitle: string;
    modifiedOnTitle: string;
    leftBlockAdditionalFields: Array<AdditionalField> | undefined;
    rightBlockAdditionalDragFields: Array<AdditionalField> | undefined;
    rightBlockAdditionalBlockedFields: Array<AdditionalField>;
    leftBlockAdditionalFieldsZoneId: string; // Нужно чтобы определять в какой из блоков дополнительных полей мы хотим положить новый элемент.
    rightBlockAdditionalFieldsZoneId: string; // Нужно чтобы определять в какой из блоков дополнительных полей мы хотим положить новый элемент.
    onChangeAdditionalFields: (field: AdditionalField, newPosition: number, isLeftFieldBlock?: boolean) => void;
    onDeleteFieldFromBlock: (gridItemId: string, isLeftFieldBlock?: boolean) => void;
    getAdditionalInfoOfField: (columnId: string) => AdditionalInfoOfField;
}

export const ConstructorQuickView = observer((props: ContructorQuickViewProps) => {
    const ref = useRef<HTMLDivElement>(null);

    const containerClassNames = classNames(styles.constructorContainer, {
        [`${styles.disableConstructor}`]: !props.quickViewEnable
    });

    const handleDropField = useCallback((sourceZone: string | null, targetZone: string | null, additionalField: AdditionalField, newPosition: number) => {
        const isFromLeftFieldBlock = sourceZone === props.leftBlockAdditionalFieldsZoneId;
        const isToLeftFieldBlock = targetZone === props.leftBlockAdditionalFieldsZoneId;

        if (sourceZone !== targetZone) {
            props.onDeleteFieldFromBlock(additionalField.gridItemId, isFromLeftFieldBlock)
        }
        props.onChangeAdditionalFields(additionalField, newPosition, isToLeftFieldBlock);
    }, [props.leftBlockAdditionalFieldsZoneId, props.rightBlockAdditionalFieldsZoneId]);

    const getMaxHeight = useMemo(() => ref.current?.getBoundingClientRect().height, [ref.current?.getBoundingClientRect().height]);

    return (
        <div className={containerClassNames} ref={ref}>
            {/* TODO сделать возможно через CardDesignerBlock */}
            <span className={styles.constructorContainerTitle}>Дизайн</span>
            <div className={styles.constructorBody}>
                {(props.leftBlockAdditionalFields && props.header) &&
                    <LeftAdditionalFieldsBlock
                        header={props.header}
                        commentsEnable={props.commentsEnable}
                        leftBlockAdditionalFields={props.leftBlockAdditionalFields}
                        leftBlockAdditionalFieldsZoneId={props.leftBlockAdditionalFieldsZoneId}
                        onChangeLeftBlockAdditionalFields={handleDropField}
                        onDeleteFieldFromLeftBlock={props.onDeleteFieldFromBlock}
                        getAdditionalInfoOfField={props.getAdditionalInfoOfField}
                        height={getMaxHeight}
                    />
                }
                <div className={styles.verticalDivider} />
                {props.rightBlockAdditionalDragFields &&
                    <RightAdditionalFieldsBlock
                        rightBlockAdditionalDragFields={props.rightBlockAdditionalDragFields}
                        rightBlockAdditionalBlockedFields={props.rightBlockAdditionalBlockedFields}
                        rightBlockAdditionalFieldsZoneId={props.rightBlockAdditionalFieldsZoneId}
                        onChangeRightBlockAdditionalFields={handleDropField}
                        onDeleteFieldFromRightBlock={props.onDeleteFieldFromBlock}
                        createdOnTitle={props.createdOnTitle}
                        modifiedOnTitle={props.modifiedOnTitle}
                        getAdditionalInfoOfField={props.getAdditionalInfoOfField}
                    />
                }
            </div>
        </div>
    );
});