import { useCallback, useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames';
import { v4 } from 'uuid';

import fieldConfigurationStore, { FieldStoreEnums } from '../field-configuration-store';

import { Button, ButtonStyle } from "components";
import { Conditions, FieldSettings, Masks } from './tabs';

import { NumeratorTabs } from '../types';
import { ColumnType } from 'entities/ColumnType';

import styles from './numerator-configuration.module.scss'
import wrapStyles from '../field-configuration.module.scss'

interface NumeratorConfigurationProps {
    columnId?: string;
}

export const NumeratorConfiguration = observer((props: NumeratorConfigurationProps) => {
    const [selectedTab, setSelectedTab] = useState<number>(0);

    useEffect(() => {
        fieldConfigurationStore.setValueWithoutTrackingChanges(FieldStoreEnums.fieldType, ColumnType.Numerator);
        if (props.columnId) {
            fieldConfigurationStore.getConfigurationById(props.columnId);
        }
        else {
            const newId = v4();
            fieldConfigurationStore.setValueWithoutTrackingChanges(FieldStoreEnums.columnId, newId);
            fieldConfigurationStore.initNumerator();
        }
    }, [])

    const handleChangeTab = useCallback((tabIndex: NumeratorTabs) => {
        setSelectedTab(tabIndex);
    }, [selectedTab]);

    const itemClasses = useCallback((i: number) => {
        return classNames(`${wrapStyles.tabButton} `, {
            [`${wrapStyles.selected} `]: selectedTab === i,
        });
    }, [selectedTab]);

    const getTab = useMemo(() =>
        selectedTab === NumeratorTabs.FieldSettings ?
            <FieldSettings />
            : selectedTab === NumeratorTabs.Masks ?
                <Masks isNewNumerator={!props.columnId} />
                : <Conditions />
        , [selectedTab, props.columnId]
    );

    return <div className={styles.numeratorBody}>
        <span className={styles.headerText}>Нумератор позволяет автоматически формировать значение поля по заданной маске.</span>
        <div className={wrapStyles.tabContainer}>
            <Button
                key="fieldSettings"
                caption="Настройка поля"
                onClick={() => handleChangeTab(NumeratorTabs.FieldSettings)}
                className={itemClasses(NumeratorTabs.FieldSettings)}
                style={ButtonStyle.DragGrayButton}
            />
            <Button
                key="masks"
                caption="Маска заполнения"
                onClick={() => handleChangeTab(NumeratorTabs.Masks)}
                className={itemClasses(NumeratorTabs.Masks)}
                style={ButtonStyle.DragGrayButton}
            />
            <Button
                key="conditions"
                caption="По условию"
                onClick={() => handleChangeTab(NumeratorTabs.Conditions)}
                className={itemClasses(NumeratorTabs.Conditions)}
                style={ButtonStyle.DragGrayButton}
            />
        </div>
        {getTab}
    </div>
})
