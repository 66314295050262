import { observer } from "mobx-react-lite";

import NavigationTitle from "../NavigationTitle";

import { InProgress } from "shared";

import styles from "./Currents.module.css";

const CurrentMainPanel = observer(function () {
	return (
		<div className={styles.systemMainPanel}>
			<NavigationTitle />
			<div className={styles.inProgress}>
				<InProgress className={styles.inProgressSvg} />
			</div>
		</div>
	);
});

export default CurrentMainPanel;
