import styles from './detail-master-title.module.css';

export const DetailMasterTitle = (props: { title: string }) => {

    return (
        <div className={styles.tabTitleWrapper}>
            <div className={styles.title}>
                <span className={styles.nameTitle}>Деталь: </span>
                <span className={styles.nameTitle}>{props.title}</span>
            </div>
        </div>
    );
};
