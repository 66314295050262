import React, { useCallback, useMemo, useState } from "react";
import { v4 } from "uuid";
import { dispatcher, selector } from "store";
import { Button } from "sale-bridge-ui-kit";
import { observer } from "mobx-react";
import { toJS } from "mobx";

import { useOpenInNewTab } from "shared/hooks/use-open-in-new-tab";
import { useCopyToClipboard } from "shared/hooks/use-copy-to-clipboard";
import { modalController } from "features/modals";
import { LowFirst } from "shared";

import { FlagPopup } from "../../flag-popup";
import { TimeLoggingPopup } from "../../time-logging-popup";

import styles from "./action-panel-buttons.module.scss";

interface CopyOpenButtonsProps {
	recordId: string;
}

export const ActionButtons: React.FC<CopyOpenButtonsProps> = observer(({ recordId }) => {
	const [idModal] = useState<string>(v4());
	const [copiedText, copyFn, isCopied] = useCopyToClipboard();

	const sectionWizzard = useMemo(
		() => dispatcher.entity.get()?.entity.sectionWizzard,
		[toJS(dispatcher.entity.get()?.entity.sectionWizzard)]
	);

	const record = useMemo(
		() => dispatcher.entity.get()?.entity.rows.find((row) => row.id === recordId),
		[toJS(dispatcher.entity.get()?.entity.rows), recordId]
	);

	const sysColumnNameFlag = useMemo(
		() => selector.sectionWizzard.getSysFlagColumn()?.fieldConfig?.columnName,
		[selector.sectionWizzard.getSysFlagColumn()?.fieldConfig?.columnName]
	);

	const isFlagged: boolean = useMemo(
		() => (sysColumnNameFlag ? record[LowFirst(sysColumnNameFlag)] : false),
		[sysColumnNameFlag, sysColumnNameFlag ? record[LowFirst(sysColumnNameFlag)] : null]
	);

	const recordUrl: string = useMemo(() => window.location.href + "/" + recordId, [recordId]);

	const handleOpenInNewTab = useOpenInNewTab(recordUrl);

	const handleCopyLink = useCallback(async () => {
		await copyFn(recordUrl);
	}, [recordUrl]);

	const closeConfirm = useCallback((e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
		e?.stopPropagation();
		modalController.modalRemove(idModal);
	}, []);

	const recordTitle = useMemo(() => {
		return dispatcher.currentRow.getTitleByRowId(recordId);
	}, [recordId]);

	const handlePutUpFlag = useCallback(() => {
		modalController.popupAdd({
			id: idModal,
			layout: <FlagPopup recordId={recordId} mode={isFlagged ? "remove" : "add"} close={closeConfirm} />,
			closeFunc: closeConfirm
		});
	}, [recordId, isFlagged]);

	const handlePutUpTimeLogging = useCallback(() => {
		modalController.popupAdd({
			id: idModal,
			layout: <TimeLoggingPopup recordId={recordId} recordTitle={recordTitle} close={closeConfirm} />,
			closeFunc: closeConfirm
		});
	}, [recordId]);

	const contextMenuWhenOneCheckedElement = useMemo(
		() => (
			<>
				{sectionWizzard?.hasFlag && (
					<Button
						text={isFlagged ? "Убрать флажок..." : "Поставить флажок..."}
						size="small"
						variant="default"
						border
						iconSize="16"
						leftIcon={isFlagged ? "FlagSolid" : "Flag"}
						link={false}
						loading={false}
						onClick={handlePutUpFlag}
					/>
				)}

				{sectionWizzard?.hasTimeLogging && (
					<Button
						text="Залогировать время..."
						size="small"
						variant="default"
						border
						iconSize="16"
						leftIcon="Clock"
						link={false}
						loading={false}
						onClick={handlePutUpTimeLogging}
					/>
				)}
			</>
		),
		[sysColumnNameFlag, isFlagged, sectionWizzard?.hasFlag, sectionWizzard?.hasTimeLogging]
	);

	return (
		<div className={styles.buttonContainer}>
			<Button
				text={isCopied ? "Ссылка скопирована!" : "Копировать ссылку"}
				size="small"
				variant={isCopied ? "successSecondary" : "default"}
				border
				leftIcon={isCopied ? "ButtonSuccessCheck" : "Link"}
				link={false}
				loading={false}
				onClick={handleCopyLink}
			/>
			<Button
				text="Открыть в новой вкладке"
				size="small"
				variant="default"
				border
				iconSize="16"
				leftIcon="OpenNewWindow"
				link={false}
				loading={false}
				onClick={handleOpenInNewTab}
			/>
			{contextMenuWhenOneCheckedElement}
		</div>
	);
});
