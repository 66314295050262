import { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import classNames from "classnames";
import { v4 } from "uuid";

import { FieldSelect } from "pages/section-wizzard/components";

import { ExistFieldsBlock } from "pages/section-wizzard/components/blocks";
import { Position, modalController } from "features/modals";

import { Button, Checkbox, Field } from "sale-bridge-ui-kit";
import { ButtonStyle, InputSearch } from "components";

import { AdditionalTitle } from "pages/section-wizzard/data/data";
import { Types } from "modules/DND/type";
import { IGridItem } from "components/select/types";
import { AdditionalField } from "types/entity";

import { CloseMaxi } from "shared";

import styles from "./quick-view-settings-panel.module.scss";

interface QuickViewSettingPanelProps {
	fields: Array<IGridItem>;
	quickViewEnable: boolean; //для деактивации/активаци quickView
	resetQuickViewSettingsEnable: boolean; //для активации кнопки "Сбросить настройки"
	isCommentsEnableVisible: boolean; //для активации чекбокс-группы «Комментарии»
	isCommentsEnableQuickView: boolean; //для активации комментариев» в QuickView
	leftBlockAdditionalFieldsZoneId: string; // Нужно чтобы определять в какой из блоков дополнительных полей мы хотим положить новый элемент.
	rightBlockAdditionalFieldsZoneId: string; // Нужно чтобы определять в какой из блоков дополнительных полей мы хотим положить новый элемент.
	onSetLeftBlockAdditionalFields: (field: AdditionalField, newPosition: number) => void; // Метод нужен для перетаскивание новых полей в центральную панель настроек, в левую ее часть.
	onSetRightBlockAdditionalFields: (field: AdditionalField, newPosition: number) => void; // Метод нужен для перетаскивание новых полей в центральную панель настроек, в правую ее часть.
	onSetCommentsEnable: (value: boolean) => void; // Метод нужен для отключения, влючения комментариев.
	onQuickViewDisable: (value: boolean) => void; // Метод нужен для деактивации quickView.
	onResetQuickViewSettings: () => void; // Метод нужен для сброса настроек quickView.
	setPreviewIsOpen: (value: boolean) => void; // Нужен для открытия preview.
}

type DNDOnDropEvent = {
	elementId: string | null;
	sourceZone: string | null;
	targetZone: string | null;
	type: Types;
	sourceData?: any;
	[key: string]: any;
};

const AdditionalSettings = observer(
	(props: { onSetCommentsEnable: (value: boolean) => void; isCommentsEnableVisible: boolean; isCommentsEnableQuickView: boolean }) => {
		const [commentsEnable, setCommentsEnable] = useState(props.isCommentsEnableQuickView);

		const handleCommentsEnableChange = useCallback(
			(value: boolean) => {
				setCommentsEnable(value);
				props.onSetCommentsEnable(value);
			},
			[props]
		);

		useEffect(() => {
			if (!props.isCommentsEnableVisible) {
				props.onSetCommentsEnable(false);
			}
		}, [props.isCommentsEnableVisible]);

		if (!props.isCommentsEnableVisible) {
			return null;
		}

		return (
			<FieldSelect name={AdditionalTitle} buttonStyle={ButtonStyle.Settings} className={styles.fieldSelectContainer}>
				<div className={styles.fieldSelectContainerBody}>
					<div className={styles.checkboxGroup}>
						{props.isCommentsEnableVisible && (
							<Checkbox
								size="small"
								value={commentsEnable}
								onChangeChecked={handleCommentsEnableChange}
								title="Комментарии"
								description="Отметьте, если нужно отображать и писать комментарии"
							/>
						)}
					</div>
				</div>
			</FieldSelect>
		);
	}
);

const QuickViewSettingPanel = observer((props: QuickViewSettingPanelProps) => {
	const [searchValue, setSearchValue] = useState("");
	const [fields, setFields] = useState(props.fields);
	const [idModal] = useState<string>(v4());
	const [idNotification] = useState<string>(v4());

	const wrapperClassNames = classNames(styles.wrapper, {
		[`${styles.disableWrapper}`]: !props.quickViewEnable
	});

	const layoutNotificationQuickViewEnable = useMemo(
		() => <div className={styles.notification}>Быстрый просмотр {props.quickViewEnable ? "деактивирован" : "активирован"}</div>,
		[props.quickViewEnable]
	);

	useEffect(() => {
		setFields(props.fields);
	}, [props.fields]);

	const closeWarning = useCallback(() => {
		modalController.modalRemove(idModal);
	}, [idModal]);

	const handleDeactivate = useCallback(() => {
		if (props.quickViewEnable) {
			modalController.popupAdd({ id: idModal, position: Position.CENTER, layout: deactivateLayout, closeFunc: closeWarning });
		} else {
			props.onQuickViewDisable(true);
			modalController.notificationAdd({
				id: idNotification,
				position: Position.CENTER,
				layout: layoutNotificationQuickViewEnable,
				allowTimer: true
			});
		}
	}, [props]);

	const disableQuickView = useCallback(() => {
		props.onQuickViewDisable(false);
		closeWarning();
		modalController.notificationAdd({
			id: idNotification,
			position: Position.CENTER,
			layout: layoutNotificationQuickViewEnable,
			allowTimer: true
		});
	}, [props]);

	const handleSearchChange = useCallback(
		(value: string) => {
			setSearchValue(value);
			setFields(props.fields.filter((field) => field.displayValue.toLocaleLowerCase().includes(value.toLocaleLowerCase())));
		},
		[searchValue, toJS(props.fields)]
	);

	const handlePreviewClick = useCallback(() => {
		props.setPreviewIsOpen(true);
	}, [props]);

	const handleResetQuickViewSettings = useCallback(() => {
		modalController.popupAdd({ id: idModal, position: Position.CENTER, layout: resetLayout, closeFunc: closeWarning });
	}, [props]);

	const resetQuickViewSettings = useCallback(() => {
		props.onResetQuickViewSettings();
		closeWarning();
	}, [props]);

	const handleDropField = useCallback(
		(value: DNDOnDropEvent) => {
			const findedItem = props.fields.find((item) => item.columnId === value.elementId);
			if (findedItem) {
				const additionalField: AdditionalField = {
					columnId: findedItem.columnId!,
					gridItemId: findedItem.gridItemId!,
					order: value.MATRIX.cellY
				};
				if (value.targetZone === props.leftBlockAdditionalFieldsZoneId) {
					props.onSetLeftBlockAdditionalFields(additionalField, value.MATRIX.cellY);
					return;
				}
				if (value.targetZone === props.rightBlockAdditionalFieldsZoneId) {
					props.onSetRightBlockAdditionalFields(additionalField, value.MATRIX.cellY);
				}
			}
		},
		[toJS(props.fields)]
	);

	const deactivateLayout = useMemo(() => {
		return (
			<div className={styles.dialog}>
				<div className={styles.header}>
					<span className={styles.title}>Внимание</span>
					<CloseMaxi className={styles.closeButton} onClick={closeWarning} />
				</div>
				<span className={styles.dialogBody}>
					Вы собираетесь деактивировать быстрый просмотр — это значит, что при двойном клике на карточку доски Kanban будет
					открываться страница редактирования записи.
				</span>
				<span className={styles.dialogBody}>Если были внесены изменения, то после сохранения раздела они будут утеряны.</span>
				<div className={styles.dialogFooter}>
					<Button
						text="Деактивировать"
						size="small"
						variant="default"
						border
						link={false}
						loading={false}
						onClick={disableQuickView}
					/>
					<Button
						text="Оставить как есть"
						size="small"
						variant="primary"
						border
						link={false}
						loading={false}
						onClick={closeWarning}
					/>
				</div>
			</div>
		);
	}, [props]);

	const resetLayout = useMemo(() => {
		return (
			<div className={styles.dialog}>
				<div className={styles.header}>
					<span className={styles.title}>Сбросить настройки?</span>
					<CloseMaxi className={styles.closeButton} onClick={closeWarning} />
				</div>
				<span className={styles.dialogBody}>Это действие невозможно отменить.</span>
				<div className={styles.dialogFooter}>
					<Button text="Отменить" size="small" variant="default" border link={false} loading={false} onClick={closeWarning} />
					<Button
						text="Сбросить"
						size="small"
						variant="primary"
						border
						link={false}
						loading={false}
						onClick={resetQuickViewSettings}
					/>
				</div>
			</div>
		);
	}, [props]);

	return (
		<div className={wrapperClassNames}>
			<div className={styles.deactivateController}>
				<Button
					text={props.quickViewEnable ? "Деактивировать" : "Активировать"}
					size="small"
					variant={props.quickViewEnable ? "default" : "successSecondary"}
					border
					link={false}
					loading={false}
					onClick={handleDeactivate}
				/>
			</div>
			<div className={styles.searchController}>
				<Field
					type="text"
					value={searchValue}
					size="small"
					textVariant="outlined"
					placeholder="Поиск существующих полей"
					isClearing
					onChange={handleSearchChange}
					leftIcon="Search"
					isDisabled={!props.quickViewEnable}
				/>
			</div>
			{searchValue.length > 0 && <span className={styles.numberOfFields}>Найдено: {fields.length}</span>}
			<div className={styles.dividerContainer}>
				<div className={styles.divider}></div>
			</div>
			<div className={styles.paddingFieldsContainer}>
				<div className={styles.fieldsContainer}>
					<div className={styles.fieldSelectContainer} style={{ width: `100%` }}>
						<ExistFieldsBlock items={fields} predictLayoutClassNames={styles.predictLayout} onDrop={handleDropField} />
					</div>
					{searchValue.length == 0 && (
						<AdditionalSettings
							onSetCommentsEnable={props.onSetCommentsEnable}
							isCommentsEnableVisible={props.isCommentsEnableVisible}
							isCommentsEnableQuickView={props.isCommentsEnableQuickView}
						/>
					)}
				</div>
			</div>
			<div className={styles.dividerContainer}>
				<div className={styles.divider}></div>
			</div>

			<div className={styles.previewAndResetController}>
				<Button
					text="Открыть предпросмотр"
					size="small"
					variant={props.quickViewEnable && false ? "secondary" : "disabled"} //TODO после реализации "Предпросмотра" убрать false
					border={false}
					link={false}
					loading={false}
					onClick={handlePreviewClick}
				/>
				<Button
					text="Сбросить настройки"
					size="small"
					variant={props.resetQuickViewSettingsEnable && props.quickViewEnable ? "default" : "disabled"}
					border
					link={false}
					loading={false}
					onClick={handleResetQuickViewSettings}
				/>
			</div>
		</div>
	);
});

export default QuickViewSettingPanel;
