import { observer } from "mobx-react-lite";
import { useMemo, useState } from "react";

import { Button, Field } from "sale-bridge-ui-kit";

import styles from "../automatization-template.module.scss";


export const AutomatizationHeader = observer((props: {
    title: string,
    onAdd: () => void
}) => {
    const [searchValue, setSearchValue] = useState<string>('');

    return (
        <div className={styles.headerWrap}>
            <div className={styles.leftHeader}>
                <Button
                    text='Добавить'
                    size="small"
                    variant="secondary"
                    leftIcon="Add"
                    border={false}
                    link={false}
                    loading={false}
                    onClick={props.onAdd}
                />
                <span className={styles.headerTitle}>{props.title}</span>
            </div>
            <Field
                type="text"
                value={searchValue}
                size='small'
                textVariant='outlined'
                placeholder="Поиск"
                leftIcon="Search"
                isClearing
                onChange={(value) => setSearchValue(value)}              
            />
        </div>
    );
});
