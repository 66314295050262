import { observer } from "mobx-react";

import DisplayModeType from "./display-mode-type";

import styles from "./progress-bar.module.css";
import classNames from "classnames";

const ProgressBar = observer((props: { text: string, displayMode?: DisplayModeType }) => {
	let loaderClass = classNames({
		[`${styles.hidden} ${styles.loaderWrapper} `]: props.displayMode === DisplayModeType.HIDDEN,
		[`${styles.loaderWrapper} `]: true,
	});

	return (
		<div className={loaderClass}>
			<div className={styles.loadSubtitle}>{props.text}</div>
			<div className={styles.rotate}>
				<svg className={styles.circleLoader} viewBox="0 0 50 50">
					<circle
						className={styles.circle}
						cx="25"
						cy="25"
						r="20"
						fill="none"
						stroke="#444ce7"
						strokeWidth="3" />
				</svg>
			</div>
		</div>

	);
});
export default ProgressBar;

