import classNames from "classnames";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { useCallback, useMemo, useState, useEffect } from "react";
import { v4 } from "uuid";

import { dispatcher } from "store";
import { modalController } from "features/modals";
import { singlePageSynchroiser } from "../../../../pages/single-page/single-page-synchroiser/single-page-synchroiser";
import { contextMenuStore } from "components/context-menu";
import { stopwatchStore } from "features/stopwatch/store";

import { useCopyToClipboard } from "shared/hooks/use-copy-to-clipboard";

import Avatar from "features/avatar/avatar-generator";
import { Hint } from "sale-bridge-ui-kit";
import { FlagPopup } from "pages/single-page/components/flag-popup";
import { TimeLoggingPopup } from "pages/single-page/components";

import { QuickActionType } from "types/entity";
import { CardQuickActions } from "../card-quick-actions/card-quick-actions";
import { Coordinate } from "types/item";

import styles from "./card.module.scss";

export interface IMessage {
	id: string;
	message: string;
}

interface ICard {
	name: string;
	id: string; // ID записи
	color: string;
	size: "small" | "medium" | "large";
	flagged: boolean;
	showActions: boolean;
	isSelected: boolean;
	isReturned?: boolean;
	onOpen: (id: string, name?: string | null) => void;
	onFlaged: (message: IMessage) => void;
	link?: string; // Если указанно (showAction: true)
	fields: Record<string, string>;
	users: Record<string, string>; //TODO изменить на User
	quickActions: Array<QuickActionType>;
	isDragging?: boolean;
	priority: string;
	draggingCardId?: string;
	setDraggingCardId?: React.Dispatch<React.SetStateAction<string>>;
}

export const Card = observer((props: ICard) => {
	const [idModal] = useState<string>(v4());
	const [copiedText, copyFn, isCopied] = useCopyToClipboard();
	const sectionWizzard = dispatcher.entity.get()?.entity.sectionWizzard;
	const avatarEnabled = sectionWizzard?.kanbanConfig?.cardDesign.userAvatarEnable;

	const wrapperClasses = classNames(styles.cardWrapper, {
		[`${styles.selectedNotFlaggedCard} `]: props.isSelected && !props.flagged,
		[`${styles.selectedFlaggedCard} `]: props.isSelected && props.flagged,
		[`${styles.notSelectedFlaggedCard} `]: !props.isSelected && props.flagged,
		[`${styles.draggingCard} `]: props.isDragging,
		[`${styles.draggedCard} `]: singlePageSynchroiser.draggedRecord === props.id || singlePageSynchroiser.cardCanceledId === props.id
	});

	const priorityClasses = classNames({
		[`${styles.smallPriorityHeader} `]: props.size === "small",
		[`${styles.mediumPriorityHeader} `]: props.size === "medium",
		[`${styles.largePriorityHeader} `]: props.size === "large"
	});

	const cardNameClasses = classNames(styles.cardName, {
		[`${styles.smallCardNameText} `]: props.size === "small",
		[`${styles.mediumCardNameText} `]: props.size === "medium",
		[`${styles.largeCardNameText} `]: props.size === "large"
	});

	const closeConfirm = useCallback((e?: any) => {
		e?.stopPropagation();
		modalController.modalRemove(idModal);
	}, []);

	const fieldsMapping = useMemo(() => {
		const fieldValueClasses = classNames(styles.fieldValue, {
			[`${styles.fieldValueSmall} `]: props.size === "small",
			[`${styles.fieldValueMedium} `]: props.size === "medium",
			[`${styles.fieldValueLarge} `]: props.size === "large"
		});

		return (
			<div className={styles.fieldsBlock}>
				{Object.entries(props.fields).map(([fieldName, fieldValue], index) => {
					const fieldText = fieldValue === "" ? "Не заполнено" : fieldValue;
					return (
						<Hint key={index} hintBody={`${fieldName}: ${fieldText}`} startPosition="top">
							<span key={index} className={fieldValueClasses}>
								{fieldText}
							</span>
						</Hint>
					);
				})}
			</div>
		);
	}, [toJS(props.fields), props.size]);

	const recordTitle = useMemo(() => {
		return dispatcher.currentRow.getTitleByRowId(props.id);
	}, [props.id]);

	const handleActionButtonClick = useCallback(
		async (event: any, action: QuickActionType) => {
			event.stopPropagation();

			if (action == QuickActionType.CopyLink) {
				await copyFn(window.location.href + "/" + props.id);
				return;
			}
			if (action == QuickActionType.OpenInNewTab) {
				window.open(window.location.href + "/" + props.id);
				return;
			}
			if (action == QuickActionType.Flag) {
				modalController.popupAdd({
					id: idModal,
					layout: <FlagPopup recordId={props.id} mode={props.flagged ? "remove" : "add"} close={closeConfirm} />,
					closeFunc: closeConfirm
				});
				return;
			}
			if (action == QuickActionType.LogTime) {
				modalController.popupAdd({
					id: idModal,
					layout: <TimeLoggingPopup recordId={props.id} recordTitle={recordTitle} close={closeConfirm} />,
					closeFunc: closeConfirm
				});
				return;
			}
			if (action == QuickActionType.Stopwatch) {
				stopwatchStore.startStopwatch(props.id);
				return;
			}
		},
		[props]
	);

	const hoverOn = useCallback(() => {
		if (singlePageSynchroiser.draggedRecord === props.id) {
			singlePageSynchroiser.resetDraggedRecord();
		}
	}, []);

	const hoverOff = useCallback(() => {
		if (props.setDraggingCardId && props.draggingCardId === props.id) {
			setTimeout(() => {
				props.setDraggingCardId?.("");
			}, 3000);
		}
	}, [props.size, props.draggingCardId, props.setDraggingCardId, props.id]);

	useEffect(() => {
		if (singlePageSynchroiser.cardCanceledId === props.id) {
			setTimeout(() => {
				singlePageSynchroiser.setCardCanceledId = null;
			}, 3000);
		}
	}, [singlePageSynchroiser.cardCanceledId, props.id]);

	const handleClickContextMenu = useCallback(
		(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			event.preventDefault();
			contextMenuStore.reset();
			contextMenuStore.openMenu();
			const newPosition: Coordinate = {
				x: event.pageX,
				y: event.pageY
			};
			contextMenuStore.initContextMenu(newPosition, props.id);
		},
		[props.id]
	);

	const avatars = useMemo(() => {
		if (!avatarEnabled) {
			return null;
		}

		return (
			<div className={styles.avatars}>
				{Object.entries(props.users).map(([fieldName, fieldValue], index) => {
					return (
						<Hint key={index} hintBody={`${fieldName}: ${fieldValue}`} startPosition="top">
							<div key={index} className={styles.avatar}>
								<Avatar name={fieldValue} size={props.size} />
							</div>
						</Hint>
					);
				})}
			</div>
		);
	}, [toJS(props.users), props.size]);

	return (
		<div
			className={wrapperClasses}
			onClick={(event) => props.onOpen(props.id, props.name)}
			onContextMenu={handleClickContextMenu}
			onMouseEnter={hoverOn}
			onMouseLeave={hoverOff}
		>
			<span className={priorityClasses} style={{ color: `#${props.color}` }}>
				{props.priority}
			</span>
			<span className={cardNameClasses}>{props.name}</span>

			{fieldsMapping}
			<div className={styles.footerCard}>
				{avatars}
				{props.showActions && (
					<CardQuickActions
						cardId={props.id}
						cardTitle={recordTitle}
						size={props.size}
						isCopied={isCopied}
						flagged={props.flagged}
						quickActions={props.quickActions}
						onActionButtonClick={handleActionButtonClick}
					/>
				)}
			</div>
		</div>
	);
});
