import { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { v4 } from "uuid";

import { Position, modalController } from "features/modals";

import { FieldSelect } from "pages/section-wizzard/components";
import { Button, Checkbox, Field, Hint } from "sale-bridge-ui-kit";
import { ExistFieldsBlock } from "pages/section-wizzard/components/blocks";
import { dispatcher } from "store";

import { ButtonStyle, InputSearch } from "components";
import { AdditionalSettings, EnabledQuickActions, EnabledUsers } from "./components";

import { AdditionalField, QuickActionType } from "types/entity";
import { IGridItem } from "components/select/types";
import { AdditionalTitle, UserFieldsTitle } from "pages/section-wizzard/data/data";
import { Types } from "modules/DND/type";
import { AdditionalInfoOfField } from "pages/section-wizzard/pages/quick-view-designer/components/quick-view-settings/data";

import { CloseMaxi } from "shared";

import styles from "./card-designer-settings-panel.module.scss";

interface CardDesignerSettingsPanelProps {
	fields: Array<IGridItem>;
	usersAvatars: Array<AdditionalField>;
	additionalInfoOfField: Map<string, AdditionalInfoOfField>;
	usersAvatarEnabled: boolean;
	quickActionsEnabled: boolean;
	resetQuickViewSettingsEnable: boolean; //для активации кнопки "Сбросить настройки"
	addField: (field: AdditionalField, newPosition: number) => void;
	setUserAvatarsEnable: (value: boolean, columnId: string, users: AdditionalField[]) => void;
	resetSettings: () => void;
	onQuickActionEnable: (value: boolean) => void;
	onUserAvatarEnable: (value: boolean, firstUser: AdditionalField) => void;
}
type DNDOnDropEvent = {
	elementId: string | null;
	sourceZone: string | null;
	targetZone: string | null;
	type: Types;
	sourceData?: any;
	[key: string]: any;
};

export const CardDesignerSettingPanel = observer((props: CardDesignerSettingsPanelProps) => {
	const [searchValue, setSearchValue] = useState("");
	const [fields, setFields] = useState(props.fields);
	const [idModal] = useState<string>(v4());

	useEffect(() => {
		setFields(props.fields);
	}, [props.fields]);

	const handleSearchChange = useCallback(
		(value: string) => {
			setSearchValue(value);
			setFields(props.fields.filter((field) => field.displayValue.toLocaleLowerCase().includes(value.toLocaleLowerCase())));
		},
		[searchValue, toJS(props.fields)]
	);

	const handleDropField = useCallback(
		(value: DNDOnDropEvent) => {
			const findedItem = props.fields.find((item) => item.columnId === value.elementId);

			if (findedItem && !value.targetZone?.includes("source-zone")) {
				const additionalField: AdditionalField = {
					columnId: findedItem.columnId!,
					gridItemId: findedItem.gridItemId!,
					order: value.MATRIX.cellY
				};
				props.addField(additionalField, value.MATRIX.cellY);
			}
		},
		[toJS(props.fields)]
	);

	const closeWarning = useCallback(() => {
		modalController.modalRemove(idModal);
	}, [idModal]);

	const handleResetQuickViewSettings = useCallback(() => {
		modalController.popupAdd({ id: idModal, position: Position.CENTER, layout: resetLayout, closeFunc: closeWarning });
	}, [props]);

	const resetQuickViewSettings = useCallback(() => {
		props.resetSettings();
		closeWarning();
	}, [props]);

	const resetLayout = useMemo(() => {
		return (
			<div className={styles.dialog}>
				<div className={styles.header}>
					<span className={styles.title}>Сбросить настройки?</span>
					<CloseMaxi className={styles.closeButton} onClick={closeWarning} />
				</div>
				<span className={styles.dialogBody}>Это действие невозможно отменить.</span>
				<div className={styles.dialogFooter}>
					<Button text="Отменить" size="small" variant="default" border link={false} loading={false} onClick={closeWarning} />
					<Button
						text="Сбросить"
						size="small"
						variant="primary"
						border
						link={false}
						loading={false}
						onClick={resetQuickViewSettings}
					/>
				</div>
			</div>
		);
	}, [props]);

	return (
		<div className={styles.wrapper}>
			<div className={styles.searchContainer}>
				<Field
					type="text"
					value={searchValue}
					size="small"
					textVariant="outlined"
					placeholder="Поиск существующих полей"
					isClearing
					onChange={handleSearchChange}
					leftIcon="Search"
				/>
			</div>
			{searchValue.length > 0 && <span className={styles.numberOfFields}>Найдено: {fields.length}</span>}
			<div className={styles.dividerContainer}>
				<div className={styles.divider}></div>
			</div>
			<div className={styles.paddingFieldsContainer}>
				<div className={styles.fieldsContainer}>
					<div className={styles.fieldSelectContainer} style={{ width: `100%` }}>
						<ExistFieldsBlock items={fields} predictLayoutClassNames={styles.predictLayout} onDrop={handleDropField} />
					</div>
					{searchValue.length === 0 && (
						<>
							<AdditionalSettings
								usersAvatarEnabled={props.usersAvatarEnabled}
								quickActionsEnabled={props.quickActionsEnabled}
								usersAvatars={props.usersAvatars}
								onQuickActionEnable={props.onQuickActionEnable}
								onUserAvatarEnable={props.onUserAvatarEnable}
							/>
							{props.usersAvatarEnabled && (
								<EnabledUsers
									usersAvatars={props.usersAvatars}
									additionalInfoOfField={props.additionalInfoOfField}
									setUserAvatarsEnable={props.setUserAvatarsEnable}
								/>
							)}
							{props.quickActionsEnabled && <EnabledQuickActions />}
						</>
					)}
				</div>
			</div>
			<div className={styles.dividerContainer}>
				<div className={styles.divider}></div>
			</div>

			<div className={styles.resetContainer}>
				<Button
					text="Сбросить настройки"
					size="small"
					variant={props.resetQuickViewSettingsEnable ? "default" : "disabled"}
					border={true}
					link={false}
					loading={false}
					onClick={handleResetQuickViewSettings}
				/>
			</div>
		</div>
	);
});
