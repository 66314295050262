import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";

import { dispatcher, store } from "store";
import { synchroiser } from "synchroiser";

import { Dialog, WarningDialog, Button as ButtonComponent, ButtonStyle } from "components";
import { SinglePageBottomLeftHead, SinglePageBottomRightHead, SinglePageTopLeftHead, SortButtonKanbanSwitcher } from "./components";

import { Props } from "./constants";
import SavedFilter from "entities/filter/SavedFilter";
import ISort, { SortDirection } from "entities/ISort";
import { ViewMode } from "pages/single-page/type";
import { AdvancedFilterConst } from "features/section-head/data/constants";

import styles from "./single-page-head.module.scss";

export const SinglePageHead = observer(function (props: Props) {
	const history = useNavigate();
	const [columnTitle, setColumnTitle] = useState<string>("");
	const [isOpenDeleteBlock, setOpenDeleteBlock] = useState(false);
	const [isOpenDialog, setOpenDialog] = useState(false);
	const [visibleFilters, setVisibleFilters] = useState<SavedFilter[]>([]);
	const [isVisibleChevron, setIsVisibleChevron] = useState<boolean>(false);
	const buttonRefs = useRef<(HTMLDivElement | null)[]>([]);
	const levelRef = useRef<HTMLDivElement>(null);
	const dialogContent = "Удаление невозможно — ID выбранных записей используется в других записях системы.";
	const { entityName } = useParams();

	const sort = useRef<ISort>({
		columnPath: "createdOn",
		direction: SortDirection.Ascending
	});

	const entity = useMemo(() => {
		return dispatcher.entity.get();
	}, [dispatcher.entity.get()]);

	const handleExtendedFilter = useCallback(() => {
		AdvancedFilterConst.setAdvancedFilter(new SavedFilter(entity?.entityName!));
		AdvancedFilterConst.setOpenAdvancedFilterTree(!AdvancedFilterConst.isOpenAdvancedFilter);
	}, [entity?.entityName]);

	const handleAllFilters = useCallback(() => {
		AdvancedFilterConst.setOpenAdvancedFilterTree(!AdvancedFilterConst.isOpenAdvancedFilterTree, true);
	}, []);

	const handleAddSection = useCallback(() => {
		if (entity) {
			history("new");
		}
	}, [entity]);

	const refreshData = useCallback(async () => {
		if (entityName) {
			await synchroiser.switchSection(entityName!).then(() => {
				const dispatcherEntity = dispatcher.entity.get()!;
				dispatcher.entity.set(dispatcherEntity);
			});
		}
	}, [entityName]);

	useEffect(() => {
		if (props.viewModeValue === ViewMode.GRID) {
			refreshData();
		}
	}, [props.viewModeValue]);

	const deleteRecords = useCallback(async () => {
		if (dispatcher.entity.get()?.entity) {
			await synchroiser.deleteRecords();
			await refreshData();
		}
	}, [toJS(dispatcher.entity.get()?.entity), refreshData]);

	const updateVisibleFilters = useCallback(() => {
		const sideElementsWidth = 310;
		const levelWidth = levelRef.current?.getBoundingClientRect().width ?? 1000;
		const containerWidth = levelWidth - sideElementsWidth;
		let totalWidth = 86;
		const visible: SavedFilter[] = [];

		store.favoriteFilters?.forEach((favoriteFilter, i) => {
			const buttonElement = buttonRefs.current[i] as HTMLElement;
			if (buttonElement) {
				const buttonWidth = buttonElement.offsetWidth;
				if (totalWidth + buttonWidth <= containerWidth) {
					visible.push(favoriteFilter);
				}
				totalWidth += buttonWidth + 6;
			}
		});

		if (store.favoriteFilters?.length) {
			setIsVisibleChevron(visible.length < store.favoriteFilters.length);
		}
		setVisibleFilters(visible);
	}, [
		toJS(store.favoriteFilters),
		toJS(buttonRefs.current.map((i) => i?.offsetWidth)),
		levelRef.current?.getBoundingClientRect().width,
		Math.round(levelRef?.current?.getBoundingClientRect?.()?.width ?? 0)
	]);

	useEffect(() => {
		window.addEventListener("resize", updateVisibleFilters);
		return () => {
			window.removeEventListener("resize", updateVisibleFilters);
		};
	}, []);

	useEffect(() => {
		if (store.favoriteFilters?.length) {
			setVisibleFilters(store.favoriteFilters);
			setTimeout(updateVisibleFilters, 0);
		}
	}, [store.favoriteFilters?.map((favoriteFilter) => favoriteFilter.filterName).join("")]);

	return (
		<div className={styles.headerContainer}>
			<div className={styles.level}>
				<SinglePageTopLeftHead size={props.size} onAddClick={handleAddSection} onExtendedFilterClick={handleExtendedFilter} />
				{props.hasKanban && (
					<SortButtonKanbanSwitcher
						size={props.size}
						onSwitchViewMode={props.onSwitchViewMode}
						viewModeValue={props.viewModeValue}
						sort={sort}
						columnTitle={columnTitle}
						setColumnTitle={setColumnTitle}
					/>
				)}
			</div>
			<div className={styles.level} ref={levelRef}>
				<SinglePageBottomLeftHead
					size={props.size}
					onAllClick={handleAllFilters}
					buttonRefs={buttonRefs}
					visibleFilters={visibleFilters}
				/>
				<SinglePageBottomRightHead isVisibleChevron={isVisibleChevron} size={props.size} setOpenDeleteBlock={setOpenDeleteBlock} />
			</div>
			<WarningDialog
				value={`Вы действительно хотите удалить выбранные записи?\nЭто действие невозможно отменить.`}
				valueReturn="Отмена"
				valueDelete="Удалить"
				isOpen={isOpenDeleteBlock}
				onBackClick={() => {
					dispatcher.entity.onChangeCheckedAll(false);
					setOpenDeleteBlock(false);
				}}
				onCancelClick={() => {
					deleteRecords();
					setOpenDeleteBlock(false);
					dispatcher.entity.onChangeCheckedAll(false);
				}}
			/>
			<Dialog
				title="Не удалось удалить записи"
				isOpen={isOpenDialog}
				dialogFooterButtons={
					<ButtonComponent
						caption="Понятно"
						onClick={() => {
							setOpenDialog(false);
						}}
						style={ButtonStyle.Primary}
					/>
				}
				onClick={() => {
					setOpenDialog(false);
				}}
			>
				<div className={styles.dialogContent}>{dialogContent}</div>
			</Dialog>
		</div>
	);
});
