import { useCallback, useMemo } from 'react'
import { observer } from 'mobx-react-lite'

import { Checkbox } from 'sale-bridge-ui-kit'

import { ColumnType } from 'entities/ColumnType'
import { ColumnSpecializationType } from 'types/entity'
import fieldConfigurationStore, {
	FieldStoreEnums,
} from '../field-configuration-store'

import { RenderViewColumnCheckbox } from './view-column-checkbox'
import { RenderRequiredCheckbox } from './is-required-checkbox'

import styles from '../field-configuration.module.scss'

// Checkbox Components
const IndexCheckbox = () => {
	return (
		<>
			<Checkbox
				size='small'
				value={fieldConfigurationStore.hasIndex}
				onChangeChecked={(value: boolean) =>
					fieldConfigurationStore.setValue(FieldStoreEnums.hasIndex, value)
				}
				variant='default'
				description='Отметьте, чтобы поиск по этому полю выполнялся быстрее'
				title='Индексируемое поле'
			/>
		</>
	)
}

const MultilineCheckbox = () => {
	const handleMultilineChange = useCallback(
		(value: boolean) => {
			fieldConfigurationStore.setSpecialization(
				FieldStoreEnums.specializations,
				ColumnSpecializationType.Multiline,
				{ multiline: value.toString() },
			)
		},
		[fieldConfigurationStore.specializations.properties.multiline],
	)

	return (
		<Checkbox
			size='small'
			value={
				fieldConfigurationStore.specializations.properties.multiline === 'true'
			}
			onChangeChecked={handleMultilineChange}
			variant='default'
			description='Отметьте, если предполагается, что значение поля будет занимать более 5 слов'
			title='Многострочное поле'
		/>
	)
}

const UneditableCheckbox = () => {
	const handleUneditableChange = useCallback(
		(value: boolean) => {
			fieldConfigurationStore.setValue(FieldStoreEnums.uneditable, value)
		},
		[fieldConfigurationStore.uneditable],
	)

	return (
		<Checkbox
			size='small'
			value={fieldConfigurationStore.uneditable}
			onChangeChecked={handleUneditableChange}
			disabled={false}
			variant='default'
			description='Отметьте, чтобы запретить ручное редактирование'
			title='Нередактируемое поле'
		/>
	)
}

export const CheckboxesBlock = observer(() => {
	const defaultCheckboxes = useMemo(
		() => (
			<>
				<RenderRequiredCheckbox />
				<div className={styles.checkboxesDivider} />
				<UneditableCheckbox />
				<div className={styles.checkboxesDivider} />
				<IndexCheckbox />
				{fieldConfigurationStore.fieldType !== ColumnType.DateTime &&
					fieldConfigurationStore.fieldType !== ColumnType.Date &&
					fieldConfigurationStore.fieldType !== ColumnType.Time &&
					fieldConfigurationStore.fieldType !== ColumnType.Lookup && (
						<div className={styles.checkboxesDivider} />
					)}
			</>
		),
		[
			fieldConfigurationStore.fieldType,
			fieldConfigurationStore.uneditable,
			fieldConfigurationStore.hasIndex,
			fieldConfigurationStore.specializations.properties.multiline,
		],
	)

	const content = useMemo(() => {
		switch (fieldConfigurationStore.fieldType) {
			case ColumnType.Boolean: {
				return <IndexCheckbox />
			}
			case ColumnType.String: {
				return (
					<>
						<RenderViewColumnCheckbox />
						<div className={styles.checkboxesDivider} />
						{defaultCheckboxes}
						<MultilineCheckbox />
					</>
				)
			}
			default: {
				return defaultCheckboxes
			}
		}
	}, [fieldConfigurationStore.fieldType, defaultCheckboxes])

	return <div className={styles.checkboxesBlock}>{content}</div>
})
