import classNames from "classnames";

import styles from "./avatar-generator.module.scss";

export default function Avatar(props: {
    id?: string,
    name: string | null,
    size?: 'small' | 'medium' | 'large',
    className?: string
}) {
    const size = props.size ?? 'medium'

    const avatarClasses = classNames(styles.avatar, {
        [`${props.className} `]: props.className,
        [`${styles.smallAvatarSize} `]: size === 'small',
        [`${styles.mediumAvatarSize} `]: size === 'medium',
        [`${styles.largeAvatarSize} `]: size === 'large',
    });

    if (props.name) {
        let avatar = props.name.split(" ");
        let initials;

        if (avatar.length > 1) {
            initials = `${avatar[0][0].toUpperCase()}${avatar[1][0].toUpperCase()}`;
        } else {
            initials = `${avatar[0][0].toUpperCase()}${avatar[0][1] ? avatar[0][1].toUpperCase() : ''}`;
        }

        return (
            <div id={props.id} className={avatarClasses}>
                {initials}
            </div>
        );
    }

    return (
        <div id={props.id} className={avatarClasses} />
    );
}