import { toJS } from "mobx";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";
import { lowerFirst } from "lodash";

import { stopwatchStore } from "features/stopwatch/store";
import { contextMenuStore } from "components/context-menu";
import { useCopyToClipboard } from "shared/hooks/use-copy-to-clipboard";
import { dispatcher, selector } from "store";
import { Position, modalController } from "features/modals";
import { synchroiser } from "synchroiser";
import { singlePageSynchroiser } from "pages/single-page/single-page-synchroiser/single-page-synchroiser";

import { Button, ButtonStyle } from "components";
import { FlagPopup } from "pages/single-page/components/flag-popup";
import { TimeLoggingPopup } from "pages/single-page/components/time-logging-popup";

import { QuickActionType, QuickActionTypeName } from "types/entity";
import { IContextMenuOption } from "components/grid/data/data";

import { Warning } from "shared";

import stylesSectionHeader from "features/section-head/section-head.module.css";

const WARNING_TEXT = "Вы действительно хотите удалить выбранные записи?\nЭто действие невозможно отменить";

export const KanbanContextMenuActions = () => {
	const navigate = useNavigate();
	const [idModal] = useState<string>(v4());
	const [idNotification] = useState<string>(v4());
	const [copiedText, copyFn, isCopied] = useCopyToClipboard();
	const sysFlagField = useMemo(
		() => lowerFirst(selector.sectionWizzard.getSysFlagColumn()?.fieldConfig?.columnName),
		[selector.sectionWizzard.getSysFlagColumn()]
	);
	const sectionWizzard = useMemo(
		() => dispatcher.entity.get()?.entity.sectionWizzard,
		[toJS(dispatcher.entity.get()?.entity.sectionWizzard)]
	);

	const isExistStopwatchForCurrentId = useMemo(() => {
		if (contextMenuStore.recordId) return stopwatchStore.stopwatches.get(contextMenuStore.recordId) ?? false;
		else false;
	}, [contextMenuStore.recordId, contextMenuStore.recordId ? stopwatchStore.stopwatches.get(contextMenuStore.recordId) : ""]);

	const openRecordInNewPage = useCallback(() => {
		if (contextMenuStore.recordId) window.open(window.location.href + "/" + contextMenuStore.recordId);
	}, [contextMenuStore.recordId]);

	const openRecord = useCallback(() => {
		if (contextMenuStore.recordId) navigate(contextMenuStore.recordId);
	}, [contextMenuStore.recordId]);

	const closeConfirm = useCallback(
		(event?: React.MouseEvent<HTMLElement, MouseEvent>) => {
			event?.stopPropagation();
			modalController.modalRemove(idModal);
		},
		[idModal]
	);

	const deleteRecords = useCallback(async () => {
		if (dispatcher.entity.get()?.entity) {
			singlePageSynchroiser.viewLoading();
			await synchroiser.deleteRecords();
			await synchroiser.getEntity();
			singlePageSynchroiser.viewLoaded();
		}
		closeConfirm();
	}, [closeConfirm]);

	const warningConfirm = useMemo(() => {
		return (
			<div className={stylesSectionHeader.warningDialog}>
				<div className={stylesSectionHeader.warningHeader}>
					<span className={stylesSectionHeader.warningTitle}>Внимание</span>
					<Warning />
				</div>
				<div className={stylesSectionHeader.warningDialogBody}>
					<span className={stylesSectionHeader.warningText}>{WARNING_TEXT}</span>
				</div>
				<div className={stylesSectionHeader.dialogFooter}>
					<Button caption="Отмена" onClick={closeConfirm} style={ButtonStyle.Subtle} isDisabled={false} />
					<Button caption="Удалить" onClick={deleteRecords} style={ButtonStyle.Danger} isDisabled={false} />
				</div>
			</div>
		);
	}, [closeConfirm, deleteRecords]);

	const handleDelete = useCallback(() => {
		modalController.popupAdd({ id: idModal, layout: warningConfirm, closeFunc: closeConfirm });
	}, [warningConfirm, idModal, closeConfirm]);

	const copyLink = useCallback(async () => {
		if (contextMenuStore.recordId) {
			await copyFn(window.location.href + "/" + contextMenuStore.recordId);

			const layoutNotification = <div>Ссылка скопирована в буфер обмена</div>;
			modalController.notificationAdd({
				id: idNotification,
				position: Position.CENTER,
				layout: layoutNotification,
				allowDefaultClick: true,
				allowTimer: true
			});
		}
	}, [contextMenuStore.recordId]);

	const handleFlagClick = useCallback(() => {
		if (!contextMenuStore.recordId) {
			return;
		}
		const currentFlagValue = dispatcher.entity.get()?.entity.rows.find((row) => row.id === contextMenuStore.recordId)[sysFlagField];
		modalController.popupAdd({
			id: idModal,
			layout: <FlagPopup recordId={contextMenuStore.recordId} mode={currentFlagValue ? "remove" : "add"} close={closeConfirm} />,
			closeFunc: closeConfirm
		});
	}, [contextMenuStore.recordId, toJS(dispatcher.entity.get()?.entity.rows), sysFlagField]);

	const handleTimeClick = useCallback(() => {
		if (!contextMenuStore.recordId) {
			return;
		}
		modalController.popupAdd({
			id: idModal,
			layout: <TimeLoggingPopup recordId={contextMenuStore.recordId} recordTitle={recordTitle} close={closeConfirm} />,
			closeFunc: closeConfirm
		});
	}, [contextMenuStore.recordId]);

	const recordTitle = useMemo(() => {
		return dispatcher.currentRow.getTitleByRowId(contextMenuStore.recordId!);
	}, [contextMenuStore.recordId, toJS(dispatcher.entity.get()?.entity.rows)]);

	const flagActionCaption = useMemo(() => {
		if (sysFlagField && contextMenuStore.recordId) {
			const currentFlagValue = dispatcher.entity.get()?.entity.rows.find((row) => row.id === contextMenuStore.recordId)[sysFlagField];
			if (currentFlagValue) {
				return "Снять флажок";
			}
		}
		return QuickActionTypeName[QuickActionType.Flag].title;
	}, [contextMenuStore.recordId, toJS(dispatcher.entity.get()?.entity.rows), sysFlagField]);

	const flagActionIcon = useMemo(() => {
		if (sysFlagField && contextMenuStore.recordId) {
			const currentFlagValue = dispatcher.entity.get()?.entity.rows.find((row) => row.id === contextMenuStore.recordId)[sysFlagField];
			if (currentFlagValue) {
				return "FlagSolid";
			}
		}
		return "Flag";
	}, [contextMenuStore.recordId, toJS(dispatcher.entity.get()?.entity.rows), sysFlagField]);

	const handleStopwatchStartClick = useCallback(() => {
		if (contextMenuStore.recordId) {
			stopwatchStore.startStopwatch(contextMenuStore.recordId);
		}
	}, [contextMenuStore.recordId]);

	const handleStopwatchPauseClick = useCallback(() => {
		if (contextMenuStore.recordId) {
			stopwatchStore.pauseStopwatch(contextMenuStore.recordId);
		}
	}, [contextMenuStore.recordId]);

	const handleStopwatchStopClick = useCallback(() => {
		if (contextMenuStore.recordId) {
			const resultInMinutesFromStopwatch = stopwatchStore.stopStopwatch(contextMenuStore.recordId);
			stopwatchStore.removeStopwatch(contextMenuStore.recordId);
			modalController.popupAdd({
				id: idModal,
				layout: (
					<TimeLoggingPopup
						recordId={contextMenuStore.recordId}
						recordTitle={recordTitle}
						timeFromStopwatch={resultInMinutesFromStopwatch.toString()}
						close={closeConfirm}
					/>
				),
				closeFunc: closeConfirm
			});
		}
	}, [contextMenuStore.recordId]);

	const kanbanContextMenuActions: IContextMenuOption[] = useMemo(() => {
		const isDividerInCopy = sectionWizzard?.hasFlag || sectionWizzard?.hasTimeLogging ? false : true;
		const isDividerInFlag = sectionWizzard?.hasTimeLogging ? false : true;
		const isDividerInTimeLog = sectionWizzard?.hasTimer && !isExistStopwatchForCurrentId ? false : true;
		const isDividerInStopwatch = isExistStopwatchForCurrentId ? false : true;
		const actions: IContextMenuOption[] = [
			{
				caption: "Открыть запись",
				iconName: "OpenInWindow",
				size: "16",
				action: openRecord,
				isDivider: false
			},
			{
				caption: "Открыть в новой вкладке",
				iconName: "OpenNewWindow",
				size: "small",
				action: openRecordInNewPage,
				isDivider: true
			},
			{
				caption: QuickActionTypeName[QuickActionType.CopyLink].title,
				iconName: "Link",
				size: "small",
				action: copyLink,
				isDivider: false
			},
			{
				caption: "Дублировать",
				iconName: "Copy",
				size: "small",
				action: () => {}, //TODO реализовать дублирование
				isDivider: isDividerInCopy
			}
		];
		if (sectionWizzard?.hasFlag) {
			actions.push({
				caption: flagActionCaption,
				iconName: flagActionIcon,
				size: "16",
				action: handleFlagClick,
				isDivider: isDividerInFlag
			});
		}
		if (sectionWizzard?.hasTimeLogging) {
			actions.push({
				caption: QuickActionTypeName[QuickActionType.LogTime].title,
				iconName: "Time",
				size: "small",
				action: handleTimeClick,
				isDivider: isDividerInTimeLog
			});
		}
		if (sectionWizzard?.hasTimer) {
			if (!isExistStopwatchForCurrentId) {
				actions.push({
					caption: QuickActionTypeName[QuickActionType.Stopwatch].title,
					iconName: "Stopwatch",
					size: "16",
					action: handleStopwatchStartClick,
					isDivider: isDividerInStopwatch
				});
			} else {
				if (isExistStopwatchForCurrentId.isRunning) {
					actions.push({
						caption: "Поставить на паузу",
						iconName: "PauseCircleFill",
						size: "16",
						action: handleStopwatchPauseClick,
						isDivider: false
					});
				} else {
					actions.push({
						caption: "Возобновить",
						iconName: "PlayCircleFill",
						size: "16",
						action: handleStopwatchStartClick,
						isDivider: false
					});
				}
				actions.push({
					caption: "Остановить и залогировать...",
					iconName: "StopCircleFill",
					size: "16",
					action: handleStopwatchStopClick,
					isDivider: true
				});
			}
		}
		actions.push({
			caption: "Удалить",
			iconName: "Trash",
			size: "16",
			action: handleDelete,
			isDivider: false
		});
		return actions;
	}, [
		flagActionCaption,
		flagActionIcon,
		sectionWizzard?.hasFlag,
		sectionWizzard?.hasTimeLogging,
		sectionWizzard?.hasTimer,
		isExistStopwatchForCurrentId
	]);

	return { kanbanContextMenuActions };
};
