import { GlobalEvents } from "./event-consts";
import { useEffect, useState } from "react";

import { EventDialog } from "./show-dialog";
import { eventBus } from "app/services";
import { DialogWindow } from "./dialog-window";

export function DialogHandler() {
	const [dialog, setDialog] = useState<JSX.Element>();

	useEffect(() => {
		function handleEvent(event: EventDialog) {
			setDialog(
				<DialogWindow
					style={event.style}
					title={event.title}
					value={event.value}
					okButton={{ title: event.okButton.title, action: event.okButton.action }}
					cancelButton={{ title: event.cancelButton.title, action: event.cancelButton.action }}
				/>
			);
		}
		eventBus.registerHandler(GlobalEvents.ShowDialog, handleEvent);
		return () => {
			eventBus.unregisterHandler(GlobalEvents.ShowDialog, handleEvent);
		};
	}, []);
	return <>{dialog}</>;
}
