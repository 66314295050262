import authStore from "AuthStore";

enum SectionRoute {
    Settings = "settings",
    Workspace = "workspace",
    User = "user",
    Import = "import",
    SinglePage = "singlePage"
}

export default SectionRoute;