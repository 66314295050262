import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { observer } from "mobx-react";
import classNames from "classnames";

import { synchroiser } from "synchroiser";
import { Entity } from "store/store";
import { dispatcher } from "store/store-dipatcher/dispatcher";
import { templateStore } from "app";

import TemplateStoreKeys from "entities/TemplateStoreKeys";

import DisplayTabs from "features/display-tabs/display-tabs";
import exportStore from "features/export-block/core/export-store";
import { Actions, ActionsIds, AdvancedFilterConst, MaxWidth } from "features/section-head/data/constants";
import SavedFilter from "entities/filter/SavedFilter";

import { Button, ButtonStyle, Dialog, ITab, Select, WarningDialog } from "components";

import { Item } from "types";
import { SectionWizzardMode } from "pages/section-wizzard/section-wizzard";

import {
	AddTag,
	AddToGroup,
	ArrowToDownMini,
	ChangeDisplay,
	DeleteIcon,
	ExcludeFromGroup,
	Export,
	FilterExtended,
	Import,
	Unite,
	VerticalDividerShort
} from "shared";

import styles from "features/section-head/section-head.module.css";

const getImportEntity = (location: any) => {
	return null;
};

const RightSectionHead = observer(function (props: {
	entity: Entity | undefined;
	tabs: ITab[] | null;
	openExportBlock: (value: boolean) => void;
	openAddToGroupModal: () => void;
}) {
	const location = useLocation();
	const history = useNavigate();
	const ref = useRef<HTMLDivElement>(null);
	const updateMaxWidth = MaxWidth;
	const [isOpenUniteBlock, setOpenUniteBlock] = useState(false);
	const [isOpenDeleteBlock, setOpenDeleteBlock] = useState(false);
	const [isOpenDialog, setOpenDialog] = useState(false);
	const dialogContent = "Удаление невозможно — ID выбранных записей используется в других записях системы.";
	const importEntity = useMemo(() => getImportEntity(location), [location.pathname]);
	const [isDoneUnite, setIsDoneUnite] = useState<boolean>(false);

	const advancedFilterClassName = classNames(styles.advancedFilter, {
		[`${styles.advancedFilterNotSelected}`]: !AdvancedFilterConst.isOpenAdvancedFilter
	});

	const observer = useRef(
		new ResizeObserver((entries) => {
			const { width: containerWidth } = entries[0].contentRect;
			updateMaxWidth.setMaxWidth(containerWidth);
		})
	);

	useEffect(() => {
		if (ref.current) {
			updateMaxWidth.setMaxWidth(ref.current.getBoundingClientRect().width);
		}
	}, []);

	useEffect(() => {
		if (ref.current) {
			observer.current.observe(ref.current);
		}
	}, [observer]);

	const actionsIfChecked: Item[] = [
		{
			id: ActionsIds.Unite,
			name: "Объединить записи",
			icon: <Unite />,
			isLocked: props.entity?.entity.countOfChecked! < 2
		},
		{
			id: ActionsIds.Export,
			name: "Экспортировать (" + props.entity?.entity.countOfChecked + ")",
			icon: <Export />
		},
		{
			id: ActionsIds.ChangeDisplay,
			name: "Изменить отображение",
			icon: <ChangeDisplay />,
			isLocked: true
		},
		{
			id: ActionsIds.Import,
			name: "Импортировать",
			icon: <Import />,
			isSeparator: true
		},
		{
			id: ActionsIds.AddToGroup,
			name: props.entity?.entity.filter?.staticGroup
				? "Исключить из группы (" + props.entity.entity.countOfChecked + ")"
				: "Добавить в группу...",
			icon: props.entity?.entity.filter?.staticGroup ? <ExcludeFromGroup /> : <AddToGroup />
		},
		{
			id: ActionsIds.AddTag,
			name: "Добавить тег",
			icon: (
				<AddTag
					style={{
						width: "17px",
						height: "17px"
					}}
				/>
			),
			isLocked: true,
			isSeparator: true
		},
		{
			id: ActionsIds.Delete,
			name: "Удалить (" + props.entity?.entity.countOfChecked + ")",
			icon: (
				<DeleteIcon
					style={{
						width: "17px",
						height: "17px"
					}}
				/>
			),
			isRed: true,
			isLocked: props.entity?.entity.countOfChecked! < 1
		}
	];

	//TODO реализовать удаление записей
	async function deleteRecords() {
		if (dispatcher.entity.get()?.entity) synchroiser.deleteRecords();
	}

	function onClickAction(item: Item | null) {
		if (item) {
			if (item.id === ActionsIds.Import && importEntity) {
				templateStore.set(TemplateStoreKeys.import, importEntity);
				history("/import");
			} else if (item.id === ActionsIds.AddToGroup) {
				props.openAddToGroupModal();
			} else if (item.id === ActionsIds.Unite) {
				setOpenUniteBlock(true);
			} else if (item.id === ActionsIds.ChangeDisplay) {
			} else if (item.id === ActionsIds.Delete) {
				setOpenDeleteBlock(true);
			} else if (item.id === ActionsIds.Export) {
				props.openExportBlock(true);
				exportStore.initSignalR();
			} else if (item.id === ActionsIds.AddTag) {
			} else if (item.id === ActionsIds.ExportAll) {
				dispatcher.entity.onChangeCheckedAll(true);
				props.openExportBlock(true);
				exportStore.initSignalR();
			} else if (item.id === ActionsIds.SectionWizzard) {
				window.open(
					`/sectionMaster/${SectionWizzardMode.ExistSection}/${dispatcher.entity.get()?.entityName.toLowerCase()}`,
					"_blank"
				);
			}
		}
	}

	return (
		<div className={styles.sectionBlock} ref={ref} id="RightSectionHead">
			<div className={styles.rightButtons}>
				{props.tabs !== null && (
					<>
						<DisplayTabs items={props.tabs} styleButton={ButtonStyle.Icon} useLocalStorage={true} localStorageKey={"display"} />
						<VerticalDividerShort className={styles.divider} />
					</>
				)}
			</div>
			<Button
				caption={"Расширенный фильтр"}
				className={advancedFilterClassName}
				style={ButtonStyle.IndigoSelect}
				firstIcon={<FilterExtended />}
				styleButton={{ width: "fit-content" }}
				onClick={() => {
					AdvancedFilterConst.setAdvancedFilter(new SavedFilter(props.entity?.entityName!));
					AdvancedFilterConst.setOpenAdvancedFilterTree(!AdvancedFilterConst.isOpenAdvancedFilter);
				}}
				selected={AdvancedFilterConst.isOpenAdvancedFilter}
			/>
			<VerticalDividerShort className={styles.divider} />
			<Select
				name={"Действия"}
				items={props.entity?.entity.countOfChecked! > 0 ? actionsIfChecked : Actions}
				onChangeValue={onClickAction}
				secondIcon={<ArrowToDownMini />}
				isRotateSecondIcon={true}
				styles={ButtonStyle.Link}
				classNameButton={styles.actionButton}
			/>
			{/* TODO реализовать объединение*/}
			{/* <UniteBlock
                // columns={props.columns}
                // listStore={props.listStore}
                entity={props.entity}
                isOpen={isOpenUniteBlock}
                onClick={() => { setOpenUniteBlock(false); }}
                setDone={setIsDoneUnite}
            /> */}
			{/* TODO реализовать статусбар удачного объединения */}
			{/* <StatusBar
                isOpen={isDoneUnite}
                path={props.entity.entity.id}
                eventName={"Записи объединены в одну"}
            /> */}
			<WarningDialog
				value={"Вы действительно хотите удалить выбранные записи?\nЭто действие невозможно отменить."}
				valueReturn={"Отмена"}
				valueDelete={"Удалить"}
				isOpen={isOpenDeleteBlock}
				onBackClick={() => {
					dispatcher.entity.onChangeCheckedAll(false);
					setOpenDeleteBlock(false);
				}}
				onCancelClick={() => {
					deleteRecords();
					setOpenDeleteBlock(false);
					dispatcher.entity.onChangeCheckedAll(false);
				}}
			/>
			<Dialog
				title={"Не удалось удалить записи"}
				isOpen={isOpenDialog}
				dialogFooterButtons={
					<Button
						caption={"Понятно"}
						onClick={() => {
							setOpenDialog(false);
						}}
						style={ButtonStyle.Primary}
					/>
				}
				onClick={() => {
					setOpenDialog(false);
				}}
			>
				<div className={styles.dialogContent}>{dialogContent}</div>
			</Dialog>
		</div>
	);
});

export default RightSectionHead;
