import { isNull } from "lodash";

import { ConstructorQuickViewDragField } from "../../constructor-quick-view-drag-field";
import { BlockWithFields } from "../../block-with-fields";

import TypeIcon from "features/type-icon/type-icon";

import { AdditionalField } from "types/entity";
import { AdditionalInfoOfField } from "../../../quick-view-settings/data";

type LeftBlockHeaderProps = {
    header: AdditionalField | null;
    additionalInfoOfField: AdditionalInfoOfField;
}

export const LeftBlockHeader = (props: LeftBlockHeaderProps) => {
    if (isNull(props.header)) {
        return null;
    }

    return (
        <BlockWithFields headerTitle="Название записи" >
            <ConstructorQuickViewDragField
                gridItemId={props.header.gridItemId}
                additionalInfoOfField={props.additionalInfoOfField}
                icon={<TypeIcon type={props.additionalInfoOfField.columnType} />}
                isFieldBlocked
            />
        </BlockWithFields>
    );
};