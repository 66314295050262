import { IGridItem } from "components/select/types";
import { AdditionalField, GridItem } from "types/entity";

export function gridItemsCardDesignerAdapter(gridItems: Array<GridItem>): Array<IGridItem> {
    const rightColumns: Array<IGridItem> = [];
    gridItems.forEach(item => {
        let find = rightColumns.find(col => col.columnId === item.fieldConfig?.columnId);
        if (find) {
            let findIndex = rightColumns.findIndex(col => col.columnId === item.fieldConfig?.columnId);
            if (findIndex !== -1) {
                find = {
                    ...find,
                    isDisabled: false,
                    isLocked: false
                };
                rightColumns[findIndex] = find;
            }
        }
        else {
            if (item && item.fieldConfig) {
                rightColumns.push({
                    gridItemId: item.gridItemId,
                    columnId: item.fieldConfig.columnId,
                    name: item.fieldConfig.columnName,
                    displayValue: item.fieldConfig.columnTitle,
                    columnType: item.fieldConfig.columnType,
                    isDisabled: true,
                    isLocked: true,
                    isRequired: item.fieldConfig.isRequired
                });
            }
        }

        if (item.groupFieldsConfig?.inner?.items) {
            item.groupFieldsConfig.inner.items.forEach(innerItem => {
                if (!innerItem.fieldConfig) {
                    return;
                }
                let find = rightColumns.find(col => col.columnId === innerItem.fieldConfig?.columnId);
                if (find) {
                    let findIndex = rightColumns.findIndex(col => col.gridItemId === innerItem.gridItemId);
                    find = {
                        ...find,
                        isDisabled: false,
                        isLocked: false
                    };
                    rightColumns[findIndex] = find;
                }
                else {
                    rightColumns.push({
                        gridItemId: innerItem.gridItemId,
                        columnId: innerItem.fieldConfig?.columnId,
                        name: innerItem.fieldConfig.columnName,
                        displayValue: innerItem.fieldConfig.columnTitle,
                        columnType: innerItem.fieldConfig.columnType,
                        isDisabled: true,
                        isLocked: true,
                        isRequired: innerItem.fieldConfig.isRequired
                    });
                }
            });
        }
    });

    return rightColumns;
}