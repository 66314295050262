import { createElement } from "react";
import NodeElement from "../data/Node";
import { observer } from "mobx-react-lite";

interface IComponentLibary {
    [key: string]: React.FunctionComponent<any>
}

function componentBuild(node: NodeElement, services: Map<string, any>, componentLibary: IComponentLibary): React.ReactNode {
    try {
        return createElement(
            observer(componentLibary[node.type]),
            { ...node.attribute, value: node.value, services: services },
            node.children.map(n => componentBuild(n, services, componentLibary))
        );
    }
    catch (ex) {
        console.error(ex);
        console.error("Не удалось отрендерить " + node.type);
    }
}

export default componentBuild;