import { isUndefined } from "lodash";
import { action, makeObservable, observable } from "mobx";
import { v4 as uuidv4 } from "uuid";

import { dispatcher } from "store";

import { IColumn } from "components/grid/grid";
import FilterColumnType from "entities/ColumnType";
import { Property } from "features/filter/simpleFilter/core/TemplateEngine/TemplateEngine";
import {
	AggregationType,
	ComparisonType,
	DataValueType,
	ExpressionType,
	FilterType,
	IExpression,
	IFilter,
	LogicalOperation,
	MacrosType
} from "./IFilter";
import { getIcon } from "features/filter/simpleFilter/data/IconSetting";
import ComparisonsGroup from "features/filter/simpleFilter/data/ComparisonsGroup";
import { ValueType, dataTypeToColumnType } from "app";
import defaultValue from "features/filter/simpleFilter/data/DefaultValue";
import { Item } from "types";
import parseDataValueType from "features/filter/simpleFilter/converters/ParseDataValueType";
import { itemToLookup } from "shared";

export interface ISelection {
	type: FilterType;
	caption: string;
	column?: IColumn;
	// detail?: IDetail;
}

export interface IComparator {
	caption: string;
	comparator: ComparisonType;
}

export interface IAggregator {
	caption: string;
	aggregator: AggregationType;
}

export interface IFilterStore {
	parent: IFilterStore | null;
	id: string;
	schema: string;
	isEnabled: boolean;
	type: FilterType;

	// group fields
	filters: IFilterStore[];
	logicalOperation: LogicalOperation;

	comparisonType: ComparisonType | null;
	attribute: string;
	attributeType?: FilterColumnType;
	rightExpression?: IExpression;

	//detail fields
	detail: string;
	detailAggregationType?: AggregationType;
	detailFilter?: IFilterStore;

	aggregationColumn?: string;

	isActive: boolean;

	//ui fields
	depth: number;
	property: Property;
	readonly details: ISelection[];
	comparators: Item[];
	readonly aggregators: IAggregator[];
	readonly isValid: boolean;

	readonly isSingleAggregatorType: boolean;
	readonly isMathAggregatorType: boolean;

	enable: (isEnabled: boolean) => void;
	changeLogicalOperation: () => void;
	changeActive: () => void;
	addGroup: (index?: number) => void;
	addFilter: (index?: number) => void;

	openAttributeOrDetailList: () => void;
	openAggregationColumn: () => void;
	selectAttributeOrDetail: (item: Item | null) => void;
	selectAggregationColumn: (selection: ISelection) => void;

	openComparatorList: () => void;
	openAggregatorList: () => void;
	selectComparator: (comparator: Item | null) => void;
	selectAggregator: (selection: IAggregator) => void;
	setRightExpression: (rightExpressionValue: any) => void;
	hideMenu: () => void;
	serialize: () => IFilter;
	delete: () => void;
	dublicate: () => void;
	getNumericColumns: (schema: string) => ISelection[];
}

export interface IMacrosElement {
	caption: string;
	type: MacrosType | null;
}

export class FilterStore implements IFilterStore {
	parent: IFilterStore | null;
	id: string;
	schema: string;
	isEnabled: boolean;
	type: FilterType;

	// group fields
	filters: IFilterStore[];
	logicalOperation: LogicalOperation;

	comparisonType: ComparisonType | null;
	attribute: string;
	attributeType?: FilterColumnType;
	rightExpression?: IExpression;

	//detail fields
	detail: string;
	detailAggregationType?: AggregationType;
	detailFilter?: IFilterStore;

	aggregationColumn?: string;

	isActive: boolean;

	depth: number = 0;
	property: Property;
	comparators: Item[];
	// comparison: Comparison;
	// value: Value;

	constructor(schema: string, filter: IFilter | null, parent: IFilterStore | null) {
		makeObservable(this, {
			id: observable,
			isEnabled: observable,
			type: observable,
			filters: observable,
			logicalOperation: observable,
			comparisonType: observable,
			attribute: observable,
			rightExpression: observable,
			detail: observable,
			detailAggregationType: observable,
			detailFilter: observable,
			aggregationColumn: observable,
			isActive: observable,
			property: observable,
			comparators: observable,

			changeLogicalOperation: action,
			changeActive: action,
			addFilter: action,
			setDetailFilter: action,
			addGroup: action,
			openAttributeOrDetailList: action,
			openAggregationColumn: action,
			selectAttributeOrDetail: action,
			selectAggregationColumn: action,
			openComparatorList: action,
			openAggregatorList: action,
			hideMenu: action,
			selectComparator: action,
			selectAggregator: action,
			setRightExpression: action,
			delete: action,
			dublicate: action
		});
		this.parent = parent;
		if (parent) {
			this.depth = parent.depth + 1;
		}
		this.id = uuidv4();
		this.schema = schema;
		this.isActive = true;

		if (parent?.property.items && parent?.property.items.length > 0) {
			this.property = {
				items: parent?.property.items,
				propertyValue: {
					value: null
				},
				proprtiesInfo: parent?.property.proprtiesInfo
			};
		} else {
			this.property = {
				items: [],
				propertyValue: {
					value: null
				},
				proprtiesInfo: []
			};
			const entityColumns = dispatcher.entity.get()?.entity.columns;
			if (entityColumns) {
				entityColumns.forEach((column) => {
					this.property.proprtiesInfo.push({
						id: column.columnId,
						type: parseDataValueType(column.columnType),
						lookupTable: column.lookupTable ?? undefined,
						displayValue: column.columnTitle,
						name: column.columnName
					});
				});

				this.property.items = entityColumns.map((column) => {
					return {
						id: column.columnId,
						displayValue: column.columnTitle,
						name: column.columnName,
						icon: getIcon(this.property.proprtiesInfo[entityColumns.indexOf(column)]?.type)
					};
				});
			}
		}

		this.comparators = [];

		if (filter !== null) {
			this.isEnabled = filter.isEnabled;
			this.isActive = filter.isActive ?? true;
			this.type = filter.type;
			this.logicalOperation = filter.logicalOperation;

			// if (filter.attribute !== "" //&& filter.attribute !== undefined
			// ) {
			//     // const attributePath = filter.attribute.replace(CustomFieldsPrefix, "");
			//     const column = this.property.items.find(item => item.name === filter.attribute);;
			//     if (column) {
			//         this.attribute = column;
			//     }
			// } else {
			this.attribute = filter.attribute;
			// }

			const properties = this.property.items.find((item) => item.name === filter.attribute);
			// console.log("properties", properties, this.property);

			this.selectAttributeOrDetail(properties ?? null);

			this.comparisonType = filter.comparisonType;
			// this.setComparison(comparison);

			this.detailAggregationType = filter.detailAggregationType;
			// if (filter.detail !== undefined) {
			// const detail = this.getDetailByPath(filter.detail);
			// if (detail) {
			//     this.detail = detail;
			//     this.detailFilter = filter.detailFilter !== undefined ? new FilterStore(schema, filter.detailFilter, this) : undefined;
			// }
			// }else{
			this.detail = filter.detail;
			this.detailFilter =
				filter.detailFilter !== undefined && filter.detailFilter !== null
					? new FilterStore(schema, filter.detailFilter, this)
					: undefined;
			// }

			this.rightExpression =
				filter.rightExpression !== undefined && filter.rightExpression !== null
					? this.buildRightExpression(
							filter.rightExpression!.parameter.value,
							filter.rightExpression.parameter.dataValueType,
							filter.rightExpression.macros
					  )
					: undefined;

			this.filters =
				filter.filters === undefined
					? []
					: filter.filters.length > 0
					? filter.filters.map((f) => new FilterStore(schema, f, this))
					: [];
		} else {
			this.detail = "";
			this.aggregationColumn = "";
			this.isEnabled = true;
			this.type = FilterType.Group;
			this.filters = [];
			this.logicalOperation = LogicalOperation.And;
			this.comparisonType = ComparisonType.Equal;
			this.attribute = "";
			this.detailAggregationType = AggregationType.Exists;
			this.isActive = true;
		}

		// this.comparison = {
		//     items: [],
		//     value: null,
		// };
		// this.value = {
		//     value: null,
		//     displayValue: null,
		//     type: ValueTypes.Between
		// };
	}
	// getColumnByPath(path: string): IColumn | undefined {
	//     const attributes = path.split('.');
	//     let column = this.schema.columns.find(c => c.name === attributes[0]);
	//     if (attributes.length > 1) {
	//         for (let i = 1; i < attributes.length; i++) {
	//             const schema: ISchema = metadataStore.meta[column!.lookup!];
	//             const child = schema.columns.find(c => c.name === attributes[i]);
	//             column = {
	//                 name: `${column!.name}.${child!.name}`,
	//                 displayName: `${column!.displayName}.${child!.displayName}`,
	//                 type: child!.type,
	//                 lookup: child!.lookup,
	//                 isCustom: child!.isCustom
	//             }
	//         }
	//     }

	//     return column;
	// }

	// getDetailByPath(path: string): IDetail | undefined {
	//     const attributes = path.split('.');
	//     if (attributes.length > 1) {
	//         let column = this.schema.columns.find(c => c.name === attributes[0]);
	//         for (let i = 1; i < attributes.length - 1; i++) {
	//             const schema: ISchema = metadataStore.meta[column!.lookup!];
	//             const child = schema.columns.find(c => c.name === attributes[i]);
	//             column = {
	//                 name: `${column!.name}.${child!.name}`,
	//                 displayName: `${column!.displayName}.${child!.displayName}`,
	//                 type: child!.type,
	//                 lookup: child!.lookup,
	//                 isCustom: child!.isCustom
	//             }
	//         }
	//         const schema: ISchema = metadataStore.meta[column!.lookup!];
	//         const detail = schema.details.find(c => c.name === attributes[attributes.length - 1]);
	//         return {
	//             name: `${column!.name}.${detail!.name}`,
	//             displayName: `${column!.displayName}.${detail!.displayName}`,
	//             schema: detail!.schema!
	//         }
	//     } else {
	//         return this.schema.details.find(c => c.name === attributes[0]);
	//     }
	// }

	getNumericColumns(schema: string): ISelection[] {
		//     const currentSchema = metadataStore.meta[schema];
		let result: ISelection[] = [];
		//     currentSchema.columns.map(c => {
		//         if (c.type === ColumnType.Float || c.type === ColumnType.Integer) {
		//             result.push({
		//                 type: FilterType.Attribute,
		//                 column: c,
		//                 caption: c.displayName,
		//             })
		//         }
		//     });
		return result;
	}

	get details(): ISelection[] {
		// return this.schema.details.map(d => {
		//     return {
		//         type: FilterType.Detail,
		//         caption: d.displayName,
		//         detail: d
		//     }
		// });
		return [];
	}

	// get comparators(): IComparator[] {
	// if (this.type === FilterType.Attribute) {
	//     const columnType = this.attribute!.type;
	//     switch (columnType) {
	//         case ColumnType.Boolean:
	//             return [Comparators[ComparisonType.Equal]];
	//         case ColumnType.Lookup:
	//             return [
	//                 Comparators[ComparisonType.Equal],
	//                 Comparators[ComparisonType.NotEqual],
	//                 Comparators[ComparisonType.IsNotNull],
	//                 Comparators[ComparisonType.IsNull]
	//             ];
	//         case ColumnType.Entity:
	//             return [
	//                 Comparators[ComparisonType.Equal],
	//                 Comparators[ComparisonType.NotEqual],
	//                 Comparators[ComparisonType.IsNotNull],
	//                 Comparators[ComparisonType.IsNull]
	//             ];
	//         case ColumnType.String:
	//             return [
	//                 Comparators[ComparisonType.Equal],
	//                 Comparators[ComparisonType.NotEqual],
	//                 Comparators[ComparisonType.Contain],
	//                 Comparators[ComparisonType.NotContain],
	//                 Comparators[ComparisonType.StartWith],
	//                 Comparators[ComparisonType.NotStartWith],
	//                 Comparators[ComparisonType.EndWith],
	//                 Comparators[ComparisonType.NotEndWith],
	//                 Comparators[ComparisonType.IsNotNull],
	//                 Comparators[ComparisonType.IsNull]
	//             ];
	//         case ColumnType.Integer:
	//         case ColumnType.Float:
	//             return [
	//                 Comparators[ComparisonType.Equal],
	//                 Comparators[ComparisonType.NotEqual],
	//                 Comparators[ComparisonType.Less],
	//                 Comparators[ComparisonType.LessOrEqual],
	//                 Comparators[ComparisonType.Greater],
	//                 Comparators[ComparisonType.GreaterOrEqual]
	//             ];
	//         case ColumnType.DateTime:
	//         case ColumnType.Date:
	//         case ColumnType.Time:
	//             let res = [
	//                 Comparators[ComparisonType.Equal],
	//                 Comparators[ComparisonType.NotEqual],
	//                 Comparators[ComparisonType.Less],
	//                 Comparators[ComparisonType.LessOrEqual],
	//                 Comparators[ComparisonType.Greater],
	//                 Comparators[ComparisonType.GreaterOrEqual],
	//             ];
	//             if (this.attribute!.name !== "CreatedOn") {
	//                 res.push(Comparators[ComparisonType.IsNotNull], Comparators[ComparisonType.IsNull]);
	//             }
	//             return res;
	//         case ColumnType.Guid:
	//             return [
	//                 Comparators[ComparisonType.Equal],
	//                 Comparators[ComparisonType.NotEqual],
	//                 Comparators[ComparisonType.IsNotNull],
	//                 Comparators[ComparisonType.IsNull]
	//             ];
	//     }
	// } else if (this.type === FilterType.Detail) {
	//     return [
	//         Comparators[ComparisonType.Equal],
	//         Comparators[ComparisonType.NotEqual],
	//         Comparators[ComparisonType.Less],
	//         Comparators[ComparisonType.LessOrEqual],
	//         Comparators[ComparisonType.Greater],
	//         Comparators[ComparisonType.GreaterOrEqual]
	//     ];
	// }
	//     return [];
	// }

	get aggregators(): IAggregator[] {
		//     let result = [
		// Aggregators[AggregationType.NotExists],
		// Aggregators[AggregationType.Exists],
		// Aggregators[AggregationType.Count],
		// ];
		// if (this.getNumericColumns(this.detail!.schema).length > 0) {
		//     result = result.concat([
		//         Aggregators[AggregationType.Sum],
		//         Aggregators[AggregationType.Average],
		//         Aggregators[AggregationType.Min],
		//         Aggregators[AggregationType.Max]
		//     ]);
		// }

		// return result;
		return [];
	}

	get isValid(): boolean {
		switch (this.type) {
			case FilterType.Group:
				return true;
			case FilterType.Attribute:
				if (!this.attribute) {
					return false;
				}
				if (this.comparisonType !== ComparisonType.IsNull && this.comparisonType !== ComparisonType.IsNotNull) {
					if (!this.rightExpression) {
						return false;
					}
					if (this.rightExpression.type === ExpressionType.Const) {
						if (!this.rightExpression.parameter) {
							return false;
						}
						if (
							this.rightExpression.parameter.value === null ||
							this.rightExpression.parameter.value === undefined ||
							this.rightExpression.parameter.value === "" ||
							this.rightExpression.parameter.value?.start === null ||
							this.rightExpression.parameter.value?.end === null
						) {
							return false;
						}
						if (
							(this.comparisonType === ComparisonType.Between || this.comparisonType === ComparisonType.NotBetween) &&
							this.rightExpression.parameter.value?.start > this.rightExpression.parameter.value?.end
						) {
							return false;
						}
					} else if (this.rightExpression.type === ExpressionType.Macros) {
						if (
							this.rightExpression.macros === MacrosType.EveryYearTodayPlusDaysOffset ||
							this.rightExpression.macros === MacrosType.EveryYearNextNDays ||
							this.rightExpression.macros === MacrosType.EveryYearPreviousNDays ||
							this.rightExpression.macros == MacrosType.PreviousDay
						) {
							if (!this.rightExpression.parameter) {
								return false;
							}
							if (this.rightExpression.parameter.value == null) {
								return false;
							}
						}
					}
				}
				break;
			case FilterType.Detail:
				if (!this.detail) return false;
				if (this.detailAggregationType !== AggregationType.Exists && this.detailAggregationType !== AggregationType.NotExists) {
					if (
						!this.rightExpression ||
						!this.rightExpression.parameter ||
						this.rightExpression.parameter.value === null ||
						this.rightExpression.parameter.value === undefined
					) {
						return false;
					}
				}
				break;
		}

		return true;
	}

	enable(isEnabled: boolean) {
		this.isEnabled = isEnabled;
	}

	changeLogicalOperation() {
		if (this.logicalOperation === LogicalOperation.And) {
			this.logicalOperation = LogicalOperation.Or;
		} else {
			this.logicalOperation = LogicalOperation.And;
		}
	}

	changeActive() {
		this.isActive = !this.isActive;
	}

	addFilter(index?: number) {
		const newFilter = new FilterStore(
			this.schema,
			{
				detail: "",
				aggregationColumn: "",
				schema: this.schema,
				isEnabled: true,
				type: FilterType.Attribute,
				filters: [],
				logicalOperation: LogicalOperation.And,
				comparisonType: ComparisonType.Equal,
				attribute: "",
				detailAggregationType: AggregationType.Exists,
				isActive: true
			},
			this
		);
		if (!isUndefined(index)) {
			this.filters.splice(index, 0, newFilter);
			return;
		}
		this.filters.push(newFilter);
	}

	setDetailFilter() {
		// this.detailFilter = new FilterStore({
		//     detail: "",
		//     schema: this.detail!.schema,
		//     isEnabled: true,
		//     type: FilterType.Group,
		//     filters: [],
		//     logicalOperation: LogicalOperation.And,
		//     comparisonType: ComparisonType.Equal,
		//     attribute: "",
		//     attributeType: ColumnType.String,
		//     detailAggregationType: AggregationType.Exists,
		//     aggregationColumn: ""
		// }, this);
	}

	addGroup(index?: number) {
		const group = new FilterStore(
			this.schema,
			{
				detail: "",
				aggregationColumn: "",
				schema: this.schema,
				isEnabled: true,
				type: FilterType.Group,
				filters: [],
				logicalOperation: LogicalOperation.And,
				comparisonType: ComparisonType.Equal,
				attribute: "",
				detailAggregationType: AggregationType.Exists,
				isActive: true
			},
			this
		);

		if (index) {
			this.filters.splice(index, 0, group);
			return;
		}
		this.filters.push(group);
	}

	openAttributeOrDetailList() {
		// this.selectAttributeOpened = true;
	}

	openAggregationColumn() {
		// this.selectAggregationColumnOpened = true;
	}

	selectAttributeOrDetail(item: Item | null) {
		if (item) {
			const property = this.property;
			const info = this.property.proprtiesInfo[property.items.indexOf(item)];
			const type = info.type;
			property.propertyValue.type = type;
			property.propertyValue.lookupTable = info.lookupTable;
			property.propertyValue.name = info.name;
			if (type > -1) {
				this.comparisonType = Number(ComparisonsGroup[type][0].id);
				this.comparators = ComparisonsGroup[type].slice();
			}
			this.property.propertyValue.value = item;

			this.attribute = item.name;
			this.attributeType = dataTypeToColumnType(this.property.propertyValue.type!);
			this.rightExpression = this.buildRightExpression(defaultValue[type], this.property.propertyValue.type!);
		} else {
			this.property = {
				items: this.property.items,
				propertyValue: {
					value: null
				},
				proprtiesInfo: this.property.proprtiesInfo
			};
			this.comparators = [];
			this.comparisonType = null;
			this.rightExpression = this.buildRightExpression(null, this.property.propertyValue.type!);
			this.attribute = "";
			this.attributeType = FilterColumnType.String;
		}
	}

	buildRightExpression(value: ValueType, dataValueType: DataValueType, macros?: MacrosType) {
		const result: IExpression = {
			type: macros ? ExpressionType.Macros : ExpressionType.Const,
			parameter: {
				dataValueType: dataValueType,
				value: value
			},
			macros: macros
		};

		return result;
	}

	buildMacros(macros: MacrosType) {
		const result: IExpression = {
			type: ExpressionType.Macros,
			parameter: {
				dataValueType: this.rightExpression!.parameter.dataValueType,
				value: undefined
			},
			macros: macros
		};
		return result;
	}

	selectAggregationColumn(selection: ISelection) {
		// this.aggregationColumn = selection.column!;
		// this.selectAggregationColumnOpened = false;
	}

	get isSingleAggregatorType(): boolean {
		return this.detailAggregationType === AggregationType.NotExists || this.detailAggregationType === AggregationType.Exists;
	}

	get isMathAggregatorType(): boolean {
		return (
			this.detailAggregationType === AggregationType.Sum ||
			this.detailAggregationType === AggregationType.Average ||
			this.detailAggregationType === AggregationType.Min ||
			this.detailAggregationType === AggregationType.Max
		);
	}

	// createRightExpressionForDetail(): IExpressionStore {
	//     return new ExpressionStore(
	//         ExpressionType.Const,
	//         {
	//             dataValueType: DataValueType.Integer,
	//             value: null
	//         });
	// }

	openComparatorList() {
		// this.selectComparatorOpened = true;
	}

	openAggregatorList() {
		// this.selectAggregatorOpened = true;
	}

	hideMenu() {
		// this.selectComparatorOpened = false;
		// this.selectAggregatorOpened = false;
		// this.selectAttributeOpened = false;
		// this.selectAggregationColumnOpened = false;
		// if (!this.attribute && !this.detail) {
		//     this.delete();
		// }
	}

	selectComparator(comparator: Item | null) {
		this.comparisonType = comparator ? Number(comparator.id) : null;
		let value = null;
		if (comparator) {
			if (comparator.id === ComparisonType.Between || comparator.id === ComparisonType.NotBetween) {
				value = { start: null, end: null };
			} else if (
				this.property.propertyValue.type === DataValueType.Entity ||
				this.property.propertyValue.type === DataValueType.Lookup
			) {
				value = itemToLookup(null);
			}
		}
		this.rightExpression = this.buildRightExpression(value, this.property.propertyValue.type!);
	}

	selectAggregator(aggregator: IAggregator) {
		this.detailAggregationType = aggregator.aggregator;
		// this.selectAggregatorOpened = false;
	}

	setRightExpression(rightExpressionValue: ValueType) {
		if (
			rightExpressionValue !== null &&
			(this.rightExpression!.parameter.dataValueType === DataValueType.DateTime ||
				this.rightExpression!.parameter.dataValueType === DataValueType.Date) &&
			typeof (rightExpressionValue as Item).id === "number" &&
			(rightExpressionValue as Item).id !== undefined
		) {
			this.rightExpression = this.buildMacros(Number((rightExpressionValue as Item).id));
			//дата равно отправляется тоже двумя запросами из-за времени
			//дата между обработать
		} else {
			this.rightExpression = this.buildRightExpression(rightExpressionValue, this.property.propertyValue.type!);
			// if (this.rightExpression?.type === ExpressionType.Macros) {
			// 	this.rightExpression = this.buildRightExpression(rightExpressionValue, this.property.propertyValue.type!);;
			// }
			// else {
			// 	this.rightExpression!.parameter.value = rightExpressionValue;
			// }
		}
	}

	serialize(): IFilter {
		let serialized: any = {
			schema: this.schema,
			isEnabled: this.isEnabled,
			isActive: this.isActive,
			type: this.type,
			filters: this.filters.length > 0 ? this.filters.map((f) => f.serialize()) : [],
			logicalOperation: this.logicalOperation,
			comparisonType: this.comparisonType,
			rightExpression: this.rightExpression,
			detail: this.detail,
			detailAggregationType: this.detailAggregationType,
			aggregationColumn: this.aggregationColumn,
			detailFilter: this.detailFilter ? this.detailFilter.serialize() : undefined,
			attribute: this.attribute
		};
		if (this.attribute) {
			// serialized.attribute = this.attribute;
			serialized.attributeType = this.attributeType;
		}

		return serialized;
	}

	delete() {
		if (this.parent !== null) {
			const index = this.parent.filters.indexOf(this);
			if (index > -1) {
				this.parent.filters.splice(index, 1);
			}
		}
	}

	dublicate() {
		if (this.parent !== null) {
			const newDublicateFilterWithParent = new FilterStore(this.schema, this as IFilter, this.parent);
			const index = this.parent.filters.indexOf(this);
			this.parent.filters.splice(index, 0, newDublicateFilterWithParent);
		} else {
			const newDublicateFilter = new FilterStore(this.schema, this as IFilter, this);
			const index = this.filters.indexOf(this);
			this.filters.splice(index, 0, newDublicateFilter);
		}
	}
}
