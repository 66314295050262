import { useCallback, useMemo, useRef, useState } from "react";
import { isNull, isUndefined } from "lodash";
import { v4 } from "uuid";

import { dispatcher } from "store";

import { Types } from "modules/DND/horizontal-column/type";
import { Zone, Element } from "modules/DND/horizontal-column";
import { modalController, Position } from "features/modals";
import { ModalType } from "features/modals/viewer/modal-viewer";

import { Stage, stageActionPropsArray } from "components";

import { ConfirmModal, HeaderGroup } from "./components";
import { filterVisibly, sortByOrder } from "../../lib";

import { ResultType, StageFullInfo } from "types/entity";

import styles from '../../stage-model-settings.module.css';

type Props = {
    header: string;
    stages: Array<StageFullInfo>;
    selectedStageId: string;
    onOpenSettingPanel: (id: string) => void;
    isSingleGroup: boolean;
    onMoveStage: (oldPosition: number, newPosition: number) => void;
    stagesCounter: number;
    uppedStage: StageFullInfo | null;
    setUppedStage: (stage: StageFullInfo | null) => void;
}

export function StageGroup(props: Props) {
    const [idModal] = useState(v4());
    const [idZone] = useState(v4());

    const container = useRef<HTMLDivElement>(null);

    const allSortedStages = useMemo(() => sortByOrder(props.stages), [props.stages]);
    const visiblyStages = useMemo(() => filterVisibly(allSortedStages), [allSortedStages]);

    const handleMoveStage = useCallback((value: {
        zoneId: string,
        currentElementIndex: number,
        targetElementIndex: number
    }) => {
        if (idZone !== value.zoneId) {
            return;
        }
        let oldPosition = null;
        if (!isUndefined(props.stages[value.currentElementIndex]?.order)) {
            oldPosition = props.stages[value.currentElementIndex].order;
        } else {
            if (value.targetElementIndex > (props.stages.length / 2)) {
                oldPosition = props.stages[props.stages.length - 1].order;
            } else {
                oldPosition = props.stages[0].order;
            }
        }

        let newPosition = null;
        if (!isUndefined(props.stages[value.targetElementIndex]?.order) ) {
            newPosition = props.stages[value.targetElementIndex].order;
        } else {
            if (value.targetElementIndex > (props.stages.length / 2)) {
                newPosition = props.stages[props.stages.length - 1].order;
            } else {
                newPosition = props.stages[0].order;
            }
        }

        props.onMoveStage(oldPosition, newPosition);
        props.setUppedStage(null);
    }, [idZone, props])

    const stageElements = useMemo(() => visiblyStages.map((stage, index) => <Element
        key={stage.id}
        id={stage.id}
        x={index}
    >
        <Stage
            stageActionProps={stageActionPropsArray}
            name={stage.name}
            color={stage.color}
            isVisibleDelete={visiblyStages.length > 1 || !props.isSingleGroup}
            isVisiblyHide={visiblyStages.length > 1 || !props.isSingleGroup}
            selectedStageId={props.selectedStageId}
            onHidden={() => {
                props.setUppedStage(null);
                dispatcher.stageModel.hideStage(stage.id);
                if (props.selectedStageId === stage.id) {
                    props.onOpenSettingPanel('');
                }
                modalController.notificationAdd({
                    id: v4(),
                    type: ModalType.NOTIFICATION,
                    position: Position.CENTER,
                    layout: <div>{`Стадия «${stage.name}» скрыта`}</div>,
                    allowTimer: true,
                    allowDefaultClick: true,
                    withBackdrop: false,
                })
            }}
            onView={() => {
                props.setUppedStage(null);
                dispatcher.stageModel.viewStage(stage.id)
            }}
            onDelete={(event: MouseEvent) => {
                props.setUppedStage(null);
                modalController.popupAdd({
                    id: idModal,
                    layout: <ConfirmModal
                        close={() => {
                            modalController.modalRemove(idModal)
                        }}
                        delete={() => {
                            if (stage.id === props.selectedStageId) {
                                props.onOpenSettingPanel("");
                            }
                            modalController.notificationAdd({
                                id: v4(),
                                type: ModalType.NOTIFICATION,
                                position: Position.CENTER,
                                layout: <div className={styles.confirm}>{`Стадия «${stage.name}» удалена`}</div>,
                                allowTimer: true,
                                allowDefaultClick: true,
                                withBackdrop: false,
                            })
                            modalController.modalRemove(idModal)
                            dispatcher.stageModel.deleteStage(stage.id)
                        }}
                    />,
                    closeFunc: () => {
                        modalController.modalRemove(idModal)
                    }
                });
            }}
            onAdd={() => {
                props.setUppedStage(null);
                modalController.notificationAdd({
                    id: v4(),
                    type: ModalType.NOTIFICATION,
                    position: Position.CENTER,
                    layout: <div className={styles.confirm}>Стадия добавлена</div>,
                    allowTimer: true,
                    allowDefaultClick: true,
                    withBackdrop: false,
                });
                const resultTypeForNewStage = !isNull(stage.resultType) ? ResultType.Null : null;

                const newStageId = dispatcher.stageModel.addStage(stage.order + 1, resultTypeForNewStage, props.stagesCounter);
                props.onOpenSettingPanel(newStageId);
            }}
            onClick={() => {
                props.setUppedStage(null);
                props.onOpenSettingPanel(stage.id);
            }}
            id={stage.id}
        />
    </Element>
    ), [visiblyStages, idModal, props, props.selectedStageId, props.stagesCounter])

    const handleStageUp = useCallback((id: string) => {
        const uppedStage = props.stages.find((stage) => stage.id === id) ?? null;
        props.setUppedStage(uppedStage);
    }, [props.stages, props.setUppedStage]);

    const uppedStageLayout = useMemo(() => <Stage
        stageActionProps={stageActionPropsArray}
        name={props.uppedStage?.name}
        color={props.uppedStage?.color}
        height={(container.current?.getBoundingClientRect().height ?? 100) - 48}
        id="placeholder"
        singleViewStage
    />, [props.uppedStage, container.current?.getBoundingClientRect().height])

    const handleViewOnlyOneStageView = useCallback((event: any) => {
        event.preventDefault();
        dispatcher.stageModel.viewStage(allSortedStages[0].id)
    }, [allSortedStages[0]?.id])

    const handleViewOnlyOneStageHide = useCallback((event: any) => {
        event.preventDefault();
        dispatcher.stageModel.hideStage(allSortedStages[0].id)
    }, [allSortedStages[0]?.id])

    const handleViewOnlyOneStageDelete = useCallback((event: any) => {
        event.preventDefault();
        dispatcher.stageModel.deleteStage(allSortedStages[0].id);
    }, [allSortedStages[0]?.id])

    const handleViewOnlyOneStageAdd = useCallback((event: any) => {
        event.preventDefault();
        dispatcher.stageModel.addStage(allSortedStages[0].order + 1, allSortedStages[0].resultType);
    }, [allSortedStages])

    const handleViewOnlyOneStageClick = useCallback((event: any) => {
        event.preventDefault();
        props.onOpenSettingPanel(allSortedStages[0].id)
    }, [allSortedStages[0]?.id])

    if (!props.stages.length) {
        return (<></>)
    }

    return (
        <div className={styles.stageContainer}>
            <HeaderGroup
                stages={props.stages} // TODO ДОПИСАТЬ КОМПОНЕНТ ВНУТРИ, НУЖЕН АДАПТЕР
                title={props.header}
                hiddenCount={props.stages.filter(stage => stage.isHidden).length}
                isSingleGroup={props.isSingleGroup}
            />
            <div ref={container} className={styles.zoneContainer}>
                {!visiblyStages.length ?
                    <Stage
                        key={allSortedStages[0].id}
                        stageActionProps={stageActionPropsArray}
                        name={allSortedStages[0].name}
                        color={allSortedStages[0].color}
                        isVisibleDelete={!props.isSingleGroup}
                        isVisiblyHide={!props.isSingleGroup}
                        selectedStageId={props.selectedStageId}
                        onView={handleViewOnlyOneStageView}
                        onHidden={handleViewOnlyOneStageHide}
                        onDelete={handleViewOnlyOneStageDelete}
                        onAdd={handleViewOnlyOneStageAdd}
                        onClick={handleViewOnlyOneStageClick}
                        id={allSortedStages[0].id}
                        singleViewStage
                    />
                    :
                    <Zone
                        onUp={handleStageUp}
                        id={idZone}
                        movementElement={uppedStageLayout}
                        placeholder={uppedStageLayout}
                        predict={<div className={styles.divider} />}
                        type={Types.HORIZONTAL_COLUMN}
                        onDrop={handleMoveStage}
                    >
                        {stageElements}
                    </Zone>
                }
            </div>
        </div>
    )
}