import { useEffect, useState } from "react";
import { getListWithColumns } from "../lib";
import SectionRoute from "entities/SectionRoute";
const RECORDS_COUNT = 0;

export function useLinkSelect(lookupTable: string | null, valueId: string | undefined) {
	const [isLinkSelect, setIsLinkSelect] = useState(false);

	useEffect(() => {
		if (lookupTable) {
			getListWithColumns(lookupTable, RECORDS_COUNT).then((req) => {
				setIsLinkSelect(req?.data.data.isSection);
			});
		}
	}, [lookupTable]);

	function getSectionPath(sectionName: string): string {
		const boxSections: Array<string> = Object.values(SectionRoute);
		if (boxSections.includes(sectionName?.toLowerCase())) {
			return `/section/${sectionName.toLowerCase()}`;
		}
		return `/singlePage/${sectionName.toLowerCase()}`;
	}

	const link = isLinkSelect && lookupTable && valueId ? `${getSectionPath(lookupTable)}/${valueId}` : undefined;

	return link;
}
