import { observer } from "mobx-react-lite";
import { useCallback, useMemo, useState } from "react";
import classNames from "classnames";

import { AdditionalBody, SettingsBody } from "./components";
import { Button } from "components";

import { HEXColors, ResultType, StageFullInfo, StageModel } from "types/entity";
import { MenuState } from "pages/section-wizzard/data/data";

import { ChevronLeft } from "shared";

import styles from "./settings-panel.module.scss";

export type SettingPanelProps = {
	idStage: string;
	allStages: StageFullInfo[];
	onCloseSettingsPanel: () => void;
	stage: StageModel;
	color?: HEXColors;
	name?: string;
	stageToId?: string;
	stageFromId?: string;
	resultType?: ResultType;
	useLocalStorage?: boolean;
	localStorageKey?: string;
};

export const SettingsPanel = observer((props: SettingPanelProps) => {
	const [menuState, setMenuState] = useState(MenuState.open);

	const stagePanelClasses = classNames(`${styles.settingsPanelWrapper} `, {
		[`${styles.open} `]: menuState === MenuState.open
	});

	const hideButtonClasses = classNames(`${styles.hideButton} `, {
		[`${styles.openButton} `]: menuState === MenuState.open
	});

	const hideButtonIcon = useMemo(() => {
		const iconClassName = classNames(styles.leftPanelIcon, {
			[`${styles.openIcon} `]: menuState === MenuState.hide
		});
		return (
			<div className={iconClassName}>
				<ChevronLeft />
			</div>
		);
	}, [menuState]);

	const onClickHideOrOpenMenu = useCallback(() => {
		const newValue = menuState === MenuState.open ? MenuState.hide : MenuState.open;
		setMenuState(newValue);
		if (props.useLocalStorage && props.localStorageKey) {
			if (localStorage.getItem(props.localStorageKey)) localStorage.removeItem(props.localStorageKey);
			localStorage.setItem(props.localStorageKey, newValue as unknown as string);
		}
	}, [menuState, props]);

	return (
		<div className={stagePanelClasses}>
			<Button firstIcon={hideButtonIcon} className={hideButtonClasses} onClick={onClickHideOrOpenMenu} />
			{menuState === MenuState.open ? (
				<div className={styles.body}>
					<SettingsBody
						idStage={props.idStage}
						allStages={props.allStages}
						onCloseSettingsPanel={props.onCloseSettingsPanel}
						stage={props.stage}
					/>
					<AdditionalBody idStage={props.idStage} allStages={props.allStages} />
				</div>
			) : (
				<></>
			)}
		</div>
	);
});
