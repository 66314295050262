import classNames from "classnames";

import { Icon } from "sale-bridge-ui-kit";

import styles from "./navigation-dots-item.module.scss";

interface ProfileItemProps {
    firstHead: string,
    secondHead: string,
    icon: string,
    isDisable?: boolean,
    size: "small" | "medium" | "large" | "12" | "16" | "20" | "24" | "28" | "32" | "64" | "96",
    onClick: () => void
}

export function NavigationDotsItem(props: ProfileItemProps) {
    const classes = classNames(`${styles.item} `, {
        [`${styles.disableItem}`]: props.isDisable,
    });

    return <div className={classes} onClick={props.onClick}>
        <div className={styles.itemHeader}>
            <Icon name={props.icon} size={props.size} />
            <span>{props.firstHead}</span>
        </div>
        <span className={styles.secondHeader}>{props.secondHead}</span>
    </div>
}
