import { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { v4 } from "uuid";

import { modalController } from "features/modals";

import Avatar from "features/avatar/avatar-generator";
import { Button, ButtonGroup, Hint } from "sale-bridge-ui-kit";
import { DeleteCommentPopup } from "../popups";

import { Comment } from "types/entity";

import styles from "./comment-item.module.scss";

export type CommentItemType = {
    onDeleteComment: (id: string) => void;
    onUpdateComments: (id: string) => void;
} & Comment;

export const CommentItem = observer((props: CommentItemType) => {
    const [idModal] = useState<string>(v4());

    const text = useMemo(() => {
        const array = props.text?.split('\\n');
        return array.map(item => <>{item}<br /></>);
    }, [props.text]);

    const closeConfirm = useCallback((e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e?.stopPropagation();
        modalController.modalRemove(idModal);
    }, [idModal]);

    const handleDelete = useCallback(() => {
        modalController.popupAdd({
            id: idModal,
            layout: <DeleteCommentPopup onDelete={() => props.onDeleteComment(props.id)} closeConfirm={closeConfirm} />,
            closeFunc: closeConfirm
        });
    }, [idModal, props.id, closeConfirm, props.onDeleteComment])
    
    return <div className={styles.commentItemWrapper}>
        <div className={styles.avatar}>
            <Avatar name={props.userName ?? ""} size="large" />
        </div>
        <div className={styles.commentItem}>
            <CommentItemHeader modifiedOn={props.modifiedOn!} createdOn={props.createdOn!} userName={props.userName ?? ""} />
            <span className={styles.commentItemText}>
                {text}
            </span>
            {props.isOwner && (
                <ButtonGroup>
                    <Button
                        text="Изменить"
                        size="small"
                        variant="default"
                        link={false}
                        loading={false}
                        leftIcon="Edit"
                        onClick={() => props.onUpdateComments(props.id)}
                        border
                    />
                    <Button
                        text="Удалить"
                        size="small"
                        variant="default"
                        link={false}
                        loading={false}
                        leftIcon="Trash"
                        iconSize="16"
                        onClick={handleDelete}
                        border
                    />
                </ButtonGroup>
            )}

        </div>
    </div>
})


const CommentItemHeader = observer((props: { modifiedOn: string, createdOn: string, userName: string }) => {
    const editText = 'ред.';
    const createdDate = new Date(props.createdOn);
    const modifiedDate = new Date(props.modifiedOn);
    const createdDateHeader = `${createdDate.toLocaleString('ru-Ru', { year: "numeric", month: "short", day: "numeric" }).slice(0, -3)}, ${createdDate.toLocaleString('ru-Ru', { hour: 'numeric', hour12: false, minute: '2-digit' })}`;
    const modifiedDateHeader = `${modifiedDate.toLocaleString('ru-Ru', { year: "numeric", month: "short", day: "numeric" }).slice(0, -3)}, ${modifiedDate.toLocaleString('ru-Ru', { hour: 'numeric', hour12: false, minute: '2-digit' })}`;

    const hintBody = useMemo(() => {
        return `Создано: ${createdDateHeader}\nИзменено: ${modifiedDateHeader}`;
    }, [createdDateHeader, modifiedDateHeader]);

    const editDate = useMemo(() =>
        <>
            <Hint hintBody={hintBody} startPosition='top'>
                <span>{editText}</span>
            </Hint>
            <span>{createdDateHeader}</span>
        </>
        , [createdDateHeader, modifiedDateHeader]
    );

    return (
        <div className={styles.header}>
            <div className={styles.userName}>
                {props.userName}
            </div>

            <div className={styles.dateComment}>
                {createdDate.toISOString() === modifiedDate.toISOString() ?
                    createdDateHeader
                    :
                    editDate
                }
            </div>
        </div>
    )
})
