import { ColumnType } from "entities/ColumnType";
import { EntityColumnSpecialization } from "types/entity";
import fieldConfigurationStore from "./field-configuration-store";

import styles from './field-configuration.module.scss'

export const INFO_TEXT_FIRST = 'Используйте это поле ввода для коротких текстовых значений, а также для значений, состоящих из цифр, но не обозначающих сумму. Например, мобильный телефон или ИНН.'
export const INFO_TEXT_SECOND = 'Для ввода более 5 слов отметьте признак «Многострочное поле».'

export const INFO_LOOKUP_FIRST = 'Справочное поле позволяет выбирать значение из раскрывающегося списка.'
export const INFO_LOOKUP_SECOND = 'В полях ниже укажите, как должно называться поле в разделе.'

export const INFO_DETAIL = 'Деталь — это связанная таблица, значения в которой отфильтрованы по идентификатору открытой записи. Похоже на функцию ВПР в Excel.'


export enum ConfigurationStep {
    InitialLookupType = 0,
    MainSettings = 1,
}

export enum NewLookupTabs {
    LookupTab = 0,
    FieldSettingsTab = 1
}
export interface VirtualLookupValue {
    name: string;
}

export interface EntityInfoItem {
    columnId: string,
    columnName: string,
    columnTitle: string,
    columnType: ColumnType,
    isLookup: boolean,
    isLink: boolean,
    lookupTable?: string | null,
    isRequired: boolean,
    hasIndex: boolean,
    specializations?: EntityColumnSpecialization | null
}
export interface VirtualLookup {
    entityTitle: string,
    systemName: string,
    isLookup: boolean,
    columnInfo: EntityInfoItem[],
    virtualLookupValues: Array<VirtualLookupValues>
}
export type VirtualLookupValues = {
    id: string,
    name: string,
    [key: string]: any
}

export enum NumeratorTabs {
    FieldSettings = 0,
    Masks = 1,
    Conditions = 2
}

export function getInfoTitle() {
    switch (fieldConfigurationStore.fieldType) {
        case ColumnType.String: {
            return <div className={styles.infoContainer}>
                <span className={styles.infoCaption}>{INFO_TEXT_FIRST}</span>
                <span className={styles.infoCaption}>{INFO_TEXT_SECOND}</span>
            </div>
        }
        case ColumnType.DateTime: {
            return <div className={styles.infoContainer}>
                <span className={styles.infoCaption}>Описание поля с датой и временем.</span>
            </div>
        }
        case ColumnType.Date: {
            return <div className={styles.infoContainer}>
                <span className={styles.infoCaption}>Описание поля с датой.</span>
            </div>
        }
        case ColumnType.Time: {
            return <div className={styles.infoContainer}>
                <span className={styles.infoCaption}>Описание поля с временем.</span>
            </div>
        }
        case ColumnType.Lookup: {
            return <div className={styles.infoContainer}>
                <span className={styles.infoCaption}>{INFO_LOOKUP_FIRST}</span>
                <span className={styles.infoCaption}>{INFO_LOOKUP_SECOND}</span>
            </div>
        }
        case ColumnType.Boolean: {
            return <div className={styles.infoContainer}>
                <span className={styles.infoCaption}>Описание логического поля.</span>
            </div>
        }
        case ColumnType.Integer: {
            return <div className={styles.infoContainer}>
                <span className={styles.infoCaption}>Описание поля с целым числом.</span>
            </div>
        }
        case ColumnType.Decimal:
        case ColumnType.Double: {
            return <div className={styles.infoContainer}>
                <span className={styles.infoCaption}>Описание поля с дробным числом.</span>
            </div>
        }
    }
}