import { NumeratorMaskSettingsParameterType } from 'types/entity';
import { StandartItemGroup } from 'sale-bridge-ui-kit/dist/components/button-dropdown/button-dropdown';

export interface NumeratorParametersType {
    id: NumeratorMaskSettingsParameterType,
    name: string
}

export const datesPreview: Record<string, string> = {
    [NumeratorMaskSettingsParameterType.CurrentDay]: 'ДД',
    [NumeratorMaskSettingsParameterType.CurrentMonth]: 'ММ',
    [NumeratorMaskSettingsParameterType.CurrentYear2]: 'ГГ',
    [NumeratorMaskSettingsParameterType.CurrentYear4]: 'ГГГГ'
}

export type PreviewItem= {
    name:string,
    order:number
}

export const numeratorTypes: Array<NumeratorParametersType> = [
    {
        id: NumeratorMaskSettingsParameterType.Number,
        name: 'Порядковый номер'
    },
    {
        id: NumeratorMaskSettingsParameterType.Text,
        name: 'Текст'
    },
    {
        id: NumeratorMaskSettingsParameterType.CurrentDay,
        name: 'Текущий день'
    },
    {
        id: NumeratorMaskSettingsParameterType.CurrentMonth,
        name: 'Текущий месяц'
    },
    {
        id: NumeratorMaskSettingsParameterType.CurrentYear2,
        name: 'Текущий год (2 знака)'
    },
    {
        id: NumeratorMaskSettingsParameterType.CurrentYear4,
        name: 'Текущий год (4 знака)'
    },
    {
        id: NumeratorMaskSettingsParameterType.ColumnValue,
        name: 'Значение колонки'
    },
]

export const parametersItems: Array<StandartItemGroup> =  [
    {
        header: '',
        items: [
            {
                id: 'Number',
                name: numeratorTypes.find(item => item.id === NumeratorMaskSettingsParameterType.Number)?.name!,
                isDisable: true
            },
            {
                id: 'Text',
                name: numeratorTypes.find(item => item.id === NumeratorMaskSettingsParameterType.Text)?.name!,
            },
            {
                id: 'CurrentDay',
                name: numeratorTypes.find(item => item.id === NumeratorMaskSettingsParameterType.CurrentDay)?.name!,
            },
            {
                id: 'CurrentMonth',
                name: numeratorTypes.find(item => item.id === NumeratorMaskSettingsParameterType.CurrentMonth)?.name!,
            },
            {
                id: 'CurrentYear2',
                name: numeratorTypes.find(item => item.id === NumeratorMaskSettingsParameterType.CurrentYear2)?.name!,
            },
            {
                id: 'CurrentYear4',
                name: numeratorTypes.find(item => item.id === NumeratorMaskSettingsParameterType.CurrentYear4)?.name!,
            },
            {
                id: 'ColumnValue',
                name: numeratorTypes.find(item => item.id === NumeratorMaskSettingsParameterType.ColumnValue)?.name!,
            }
        ]
    }
];