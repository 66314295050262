import { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { HEXColors } from 'types/entity';

import styles from './stage.module.scss';

type Props = {
    id: string;
    name: string;
    children?: JSX.Element;
    color: string;
    isPassedOrCurrent?: boolean,
    disabled?: boolean;
    onClick?: () => void;
    onMouseEnter: (id: string) => void;
    onMouseLeave: () => void;
}

export const Stage = observer((props: Props) => {
    const ref = useRef<HTMLDivElement>(null);
    const containerClass = classNames(styles.container, props.isPassedOrCurrent && styles.passedOrCurrent, {
        [`${styles.cursorNotAllowed} `]: props.disabled,
        [`${styles.cursorPointer} `]: !props.disabled,
    });
    const spanClass = classNames(styles.name, !props.isPassedOrCurrent && styles.default, {
        [`${styles.pointerEventsNone} `]: props.disabled,
        [`${styles.pointerEventsAuto} `]: !props.disabled,
    });

    useEffect(() => {
        if (props.color === HEXColors.Aureolin) {
            ref.current?.style.setProperty('--stage-color-default', '#DACE20');
        }
        else {
            ref.current?.style.setProperty('--stage-color-default', props.color);
        }
    }, [ref.current, props.color])

    return <div
        ref={ref}
        onClick={props?.onClick}
        className={containerClass}
        onMouseEnter={() => { props.onMouseEnter(props.id) }}
        onMouseLeave={props.onMouseLeave}
    >
        {props.children ?? <span className={spanClass}>
            {props.name}
        </span >}
    </div>
});