import { DataValueType } from "entities/filter/IFilter";

type IDefaultValue = {
    [key:number]: any;
};

export const booleanItems = [
    { id: "1", displayValue: "Да", name: "true" },
    { id: "2", displayValue: "Нет", name: "false" },
]

const defaultValue : IDefaultValue = {
    [DataValueType.Guid] : null,
    [DataValueType.Text] : null,
    [DataValueType.DateTime] : {id:"-1" , name:""},
    [DataValueType.Date] : {id:"-1" , name:""},
    [DataValueType.Time] : {id:"-1" , name:""},
    [DataValueType.Entity] : {displayValue:"", value:null},
    [DataValueType.Lookup] : null,
    [DataValueType.Float] : null,
    [DataValueType.Long] : null,
    [DataValueType.Decimal] : null,
    [DataValueType.Integer] : null,
    [DataValueType.Boolean] : "true"
}

export default defaultValue;