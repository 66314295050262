import { isNull } from "lodash";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useToggle } from "usehooks-ts";
import { v4 } from "uuid";

import { dispatcher, selector } from "store";

import { modalController } from "features/modals";

import { Toggle } from "sale-bridge-ui-kit";
import { DisableStageModelConfirm } from "../disable-stage-model-confirm";
import { FieldIsland, HeadFieldsBlock } from "../global-settings";

import styles from "../global-settings.module.scss";

export const Stages = observer((props: {
	createPriorityLookup: () => void,
	deletePriorityLookup: () => void,
	createPositionField: () => void,
	deletePositionField: () => void
}) => {

	const [idModal] = useState(v4());

	const sectionWizzard = useMemo(() => {
		return dispatcher.entity.get()?.entity.sectionWizzard;
	}, [dispatcher.entity.get()?.entity.sectionWizzard]);

	const hasStageModel = useMemo(() => {
		return sectionWizzard?.hasStageModel;
	}, [sectionWizzard?.hasStageModel]);

	const [isStageModel, stageModelToggle, setStageModel] = useToggle(hasStageModel)
	const [isStageModelDisabled, stageModelDisabledToggle, setStageModelDisabled] = useToggle(false);

	useEffect(() => {
		if ((!dispatcher.entity.get()?.isNew && hasStageModel && !isNull(sectionWizzard?.stageModelConfig))) {
			setStageModelDisabled(true);
		}
	}, [dispatcher.entity.get()?.isNew, hasStageModel])

	const closeConfirm = useCallback(() => {
		modalController.modalRemove(idModal);
	}, [idModal]);

	const handleChangeStageToggle = useCallback((value: boolean) => {
		if (value) {
			props.createPriorityLookup();
			props.createPositionField();
		} else {
			const finded = selector.sectionWizzard.getPriorityFieldBySpecialization();
			if (finded && finded.x > -1 && finded.y > -1) {
				modalController.popupAdd({
					id: idModal,
					layout: <DisableStageModelConfirm disable={() => {
						setStageModel(value);
						dispatcher.sectionWizzard.setHasStageModel(value);
						props.deletePriorityLookup();
						props.deletePositionField();
					}}
						close={closeConfirm}
					/>,
					closeFunc: closeConfirm
				})
				return;
			}
			else {
				props.deletePriorityLookup();
				props.deletePositionField();
			}
		}
		setStageModel(value);
		dispatcher.sectionWizzard.setHasStageModel(value);
	}, [props]);

	return (
		<div className={styles.blockSettings}>
			<HeadFieldsBlock caption='Cтадии' />
			<FieldIsland fieldInfo={"После включения фоном будет создан виртуальный справочник приоритетов — это нужно для их корректного отображения. " +
				"При сохранении раздела справочник приоритетов добавится в базу данных системы и его значения можно будет отредактировать."} >
				<Toggle
					checked={isStageModel}
					onCheck={handleChangeStageToggle}
					title="Стадийная модель"
					description="Отметьте, если требуется настройка стадий"
					size="small"
					togglePosition='right'
					isLocked={isStageModelDisabled}
				/>
				{/* <HorizontalField
					name="sectionName"
					caption="Стадийная модель"
					infoCaption='Отметьте, если требуется настройка стадий'
					className={styles.fieldHorizontal}
					classNameCaption={styles.fieldCaption}
				>
					<Toggle
						checked={isStageModel}
						onCheck={handleChangeStageToggle}
						isDisabled={isStageModelDisabled}
					/>
				</HorizontalField> */}
			</FieldIsland>
		</div >
	);
});