import { useCallback, useState } from 'react'
import { Item, RadioGroup, RadioItem , Button} from 'sale-bridge-ui-kit'
import classNames from 'classnames'

import { LookupType } from 'pages/section-wizzard/data/data'

import styles from './configurator-stage-model.module.scss'
interface ConfigurationStageModelProps {
	onNextStep: (option: LookupType) => void
	size: 'small' | 'medium' | 'large'
}

export const ConfigurationStageModel = (props: ConfigurationStageModelProps) => {
	const [selectedOption, setSelectedOption] = useState(LookupType.NewLookup)

	const handleNextStep = useCallback(() => {
		props.onNextStep(selectedOption)
	}, [selectedOption])

	const handleOptionSelect = useCallback((selectedId: string) => {
		setSelectedOption(selectedId as LookupType)
	}, [])

	const divider = classNames(styles.divider, {
		[styles[`${props.size}Divider`]]: props.size,
	})

	return (
		<div className={styles.lookupStepWrapper}>
			<div className={styles.titleBlock}>
				<span className={styles.title}>Новая стадийная модель</span>
			</div>
            <div className={styles.lookupRadioButtonBlock}>
			<RadioGroup
				size={props.size}
				value={selectedOption}
				onSelect={handleOptionSelect}
			>
				<RadioItem
					id={LookupType.NewLookup}
					size={props.size}
					isSelected={LookupType.NewLookup === selectedOption}
					variant='default'
					onSelect={handleOptionSelect}
				>
					<Item
						size={props.size}
						text='Создать новую'
						description='Создать новый справочник и перейти к настройке стадийной модели'
					/>
				</RadioItem>
				<div className={divider} />
				<RadioItem
					id={LookupType.ExistLookup}
					size={props.size}
					isSelected={LookupType.ExistLookup === selectedOption}
					variant='default'
					onSelect={handleOptionSelect}
					isDisabled
				>
					<Item
						size={props.size}
						text='Выбрать существующую'
						description='Выбрать справочник, по которому нужно построить стадийную модель'
					/>
				</RadioItem>
			</RadioGroup>
            </div>
            <div className={styles.buttonBlock}>
			<Button
                size={props.size}
				text={'Построить стадийную модель'}
				onClick={handleNextStep}
                variant={"primary"}
                link={false}
                loading={false}
                border
			/>
            </div>
		</div>
        
	)
}

// <div className={styles.titleBlock}>
//                 <span className={styles.title}>Настройки стадийной модели</span>
//             </div>
//             <div className={styles.lookupRadioButtonBlock}>
//                 <ChoiceOption
//                     name={LookupType.NewLookup}
//                     checked={selectedOption === LookupType.NewLookup}
//                     onSelect={handleOptionSelect}
//                     icon={getRadioButtonIcon(LookupType.NewLookup)}
//                     title="Создать новую"
//                     description="Создать новый справочник и перейти к настройке стадийной модели"
//                     className={styles.choiceOption}
//                 />
//                 <ChoiceOption
//                     name={LookupType.ExistLookup}
//                     checked={selectedOption === LookupType.ExistLookup}
//                     disabled
//                     onSelect={handleOptionSelect}
//                     icon={getRadioButtonIcon(LookupType.ExistLookup)}
//                     title="Выбрать существующую"
//                     description="Выбрать справочник, по которому нужно построить стадийную модель"
//                     className={styles.choiceOption}
//                 />
//             </div>
