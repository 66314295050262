import { IGridItem } from "components/select/types";
import { AdditionalField } from "types/entity";

export function userAvatarCardDesignerAdapter(gridItems: Array<IGridItem>, existUsers: Array<AdditionalField>): Array<AdditionalField> {
    return gridItems.map(gridItem => {
        const userField = existUsers.find(existUser => existUser.columnId === gridItem.columnId);
        if (userField) {
            return {
                ...userField,
                isBlocked: false
            };
        }
        return {
            columnId: gridItem.columnId ?? "",
            gridItemId: gridItem.gridItemId ?? "",
            order: -1,
            isBlocked: true
        };
    })
}