import { useCallback, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { v4 } from "uuid";
import { toJS } from "mobx";
import { isUndefined } from "lodash";

import { dispatcher, selector } from "store";

import { Button, Icon, ButtonDropdown } from "sale-bridge-ui-kit";

import { StandartItemGroup } from "sale-bridge-ui-kit/dist/components/button-dropdown/button-dropdown";
import { StageActions, StageActionProps } from "./stage-actions";
import { HEXColors, ResultHEXColors } from "types/entity";

import styles from "./stage-actions.module.scss";

/**
 * @description Создание карточки стадии
 * @param props.stageActionProps массив действий стадии
 * @param props.isError есть ли ошибка в стадии
 * @param props.name название стадии
 * @param props.color основной цвет стадии из палитры
 * @param props.isVisibleDelete параметр, отвечающий за видимость кнопки удаления стадии
 * @param props.isVisiblyHide параметр, отвечающий за видимость кнопки сокрытия стадии
 * @param props.onHidden функция происходит по клику на скрытие карточки
 * @param props.onDelete функция происходит по клику на удаление карточки
 * @param props.onAdd функция происходит по клику на добавление карточки
 * @param props.className накладываемый поверх стиль
 * @param props.height настраиваемая высота
 * @param props.onClick функция происходит по клику на карточку
 * @param props.id id
 * @param props.singleViewStage Включает полупрозрачность 50% для кейса со всеми скрытыми
 * @param props.selectedStageId id выбранной стадии
 * @returns
 */
export function Stage(props: {
    stageActionProps: StageActionProps[],
    isError?: boolean,
    [key: string]: any
}) {

    const [id] = useState<string>(v4());

    const stageClasses = classNames(styles.stageWrapper, {
        [props.className]: props.className,
        [styles.blue_StageWrapper]: props.color === HEXColors.Blue || props.color === ResultHEXColors.Null,
        [styles.grey_StageWrapper]: props.color === HEXColors.Grey,
        [styles.pacific_cyan_StageWrapper]: props.color === HEXColors.Pacific_cyan,
        [styles.fluorescent_cyan_StageWrapper]: props.color === HEXColors.Fluorescent_cyan,
        [styles.spring_bud_StageWrapper]: props.color === HEXColors.Spring_bud,
        [styles.aureolin_StageWrapper]: props.color === HEXColors.Aureolin,
        [styles.sunglow_StageWrapper]: props.color === HEXColors.Sunglow,
        [styles.pomegranate_StageWrapper]: props.color === HEXColors.Pomegranate,
        [styles.frostbite_StageWrapper]: props.color === HEXColors.Frostbite,
        [styles.successful_StageWrapper]: props.color === ResultHEXColors.Successful,
        [styles.negative_StageWrapper]: props.color === ResultHEXColors.Negative
    });

    const stageHeaderClasses = classNames(`${styles.header}`, {
        [`${styles.headerError}`]: !props.name.length || selector.stageModels.getAll().filter(stage => stage.name === props.name).length > 1 || props.isError
    });
    const iconAndTitleClasses = classNames(`${styles.iconAndTitle}`, {
        [styles.blue_iconAndTitle]: props.color === HEXColors.Blue || props.color === ResultHEXColors.Null,
        [styles.grey_iconAndTitle]: props.color === HEXColors.Grey,
        [styles.pacific_cyan_iconAndTitle]: props.color === HEXColors.Pacific_cyan,
        [styles.fluorescent_cyan_iconAndTitle]: props.color === HEXColors.Fluorescent_cyan,
        [styles.spring_bud_iconAndTitle]: props.color === HEXColors.Spring_bud,
        [styles.aureolin_iconAndTitle]: props.color === HEXColors.Aureolin,
        [styles.sunglow_iconAndTitle]: props.color === HEXColors.Sunglow,
        [styles.pomegranate_iconAndTitle]: props.color === HEXColors.Pomegranate,
        [styles.frostbite_iconAndTitle]: props.color === HEXColors.Frostbite,
        [styles.successful_iconAndTitle]: props.color === ResultHEXColors.Successful,
        [styles.negative_iconAndTitle]: props.color === ResultHEXColors.Negative
    });
    const titleClasses = classNames(`${styles.headerText}`, {
        [styles.blue_headerText]: props.color === HEXColors.Blue || props.color === ResultHEXColors.Null,
        [styles.grey_headerText]: props.color === HEXColors.Grey,
        [styles.pacific_cyan_headerText]: props.color === HEXColors.Pacific_cyan,
        [styles.fluorescent_cyan_headerText]: props.color === HEXColors.Fluorescent_cyan,
        [styles.spring_bud_headerText]: props.color === HEXColors.Spring_bud,
        [styles.aureolin_headerText]: props.color === HEXColors.Aureolin,
        [styles.sunglow_headerText]: props.color === HEXColors.Sunglow,
        [styles.pomegranate_headerText]: props.color === HEXColors.Pomegranate,
        [styles.frostbite_headerText]: props.color === HEXColors.Frostbite,
        [styles.successful_headerText]: props.color === ResultHEXColors.Successful,
        [styles.negative_headerText]: props.color === ResultHEXColors.Negative
    });

    const isNew = useMemo(() => {
        return dispatcher.entity.get()?.isNew;
    }, [dispatcher.entity.get()?.isNew]);

    const stageSettings = useMemo(() => {
        return dispatcher.entity.get()?.entity.sectionWizzard?.stageModelConfig?.stageSettings;
    }, [toJS(dispatcher.entity.get()?.entity.sectionWizzard?.stageModelConfig?.stageSettings)]);

    const stagesCount = useMemo(() => {
        return dispatcher.entity.get()?.entity.sectionWizzard?.stageModelConfig?.stages.length ?? 0;
    }, [dispatcher.entity.get()?.entity.sectionWizzard?.stageModelConfig?.stages.length]);

    const delIsDisable = useMemo(() => {
        const intermediateVisible = stageSettings?.filter(setting => !setting.isHidden && !setting.resultType) ?? [];
        const isStageIntermediate = !isUndefined(intermediateVisible.find(setting => setting.stageId === props.id));

        const resultVisible = stageSettings?.filter(setting => !setting.isHidden && setting.resultType) ?? [];
        const isStageResult = !isUndefined(resultVisible.find(setting => setting.stageId === props.id));

        return (isStageIntermediate && intermediateVisible.length === 1) || (isStageResult && resultVisible.length === 1);
    }, [toJS(stageSettings), props.id]);

    const hiddenIsDisable = useMemo(() => {
        const intermediateVisible = stageSettings?.filter(setting => !setting.isHidden && !setting.resultType) ?? [];
        const resultVisible = stageSettings?.filter(setting => !setting.isHidden && setting.resultType) ?? [];
        return (intermediateVisible.length === 1 && resultVisible.length === 0) || (intermediateVisible.length === 0 && resultVisible.length === 1);
    }, [toJS(stageSettings)]);

    const styleWrap = useMemo(() => {
        return {
            height: `${props.height}px`,
            opacity: props.singleViewStage ? "0.5" : 1
        }
    }, [props.height, props.singleViewStage]);

    const cardActions = useMemo((): Array<StandartItemGroup> => {
        const items: Array<StandartItemGroup> = [
            {
                header: '',
                items: [
                    {
                        id: 'edit',
                        name: 'Редактировать',
                        icon: 'Edit',
                        iconSize: 'small'
                    },
                    {
                        id: 'addRight',
                        name: 'Добавить справа',
                        icon: 'ArrowBarRight',
                        iconSize: '16'
                    },
                    {
                        id: 'hide',
                        name: 'Скрыть',
                        icon: 'EyeClosed',
                        iconSize: 'small',
                        isDisable: stagesCount === 1 || hiddenIsDisable
                    }
                ]
            }]
        if (isNew) {
            items.push({
                header: '',
                items: [
                    {
                        id: 'delete',
                        name: 'Удалить',
                        icon: 'Trash',
                        iconSize: '16',
                        isRed: true,
                        isDisable: stagesCount === 1 || delIsDisable
                    }
                ]
            })
        }
        return items;
    }, [isNew, stagesCount, delIsDisable, hiddenIsDisable]);

    const handleClickToAction = useCallback((id: string) => {
        switch (id) {
            case 'edit': {
                props.onClick();
                return;
            }
            case 'addRight': {
                props.onAdd();
                return;
            }
            case 'hide': {
                props.onHidden();
                return;
            }
            case 'delete': {
                props.onDelete();
                return;
            }
        }
    }, [props]);

    /**
     * @description Метод предназначен для того, чтобы настройки стадии не открывались по нажатию на выпадающий список.
     */
    const handleParentClick = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if ((event.target as HTMLElement).closest(`#buttonDropdownId`)) {
            return;
        }
        props.onClick();
    }, [props.onClick]);


    return (
        <div id={id} className={stageClasses} style={styleWrap} onClick={handleParentClick}>
            <div className={stageHeaderClasses} >
                <div className={iconAndTitleClasses}>
                    <Icon name='Draggable' size='16' />
                    <span className={titleClasses}>{props.name}</span>
                </div>
                <div id="buttonDropdownId">
                    <ButtonDropdown
                        size={props.size}
                        position='right-down'
                        standartItems={cardActions}
                        onClickStandartItem={handleClickToAction}
                        childrenButton={
                            <Button
                                text=''
                                size="small"
                                variant="secondary"
                                border={false}
                                leftIcon="ChevronDown"
                                iconSize="16"
                                link={false}
                                loading={false}
                                onClick={() => { }}
                            />
                        }
                    />
                </div>
            </div>
            <hr />
            <div className={styles.stageBody}>
                <div className={styles.stageActionsWrapper}>
                    <StageActions stageActionProps={props.stageActionProps} />
                </div>
            </div>
        </div>
    );

};
