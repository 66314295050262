import { makeAutoObservable } from "mobx";

import { api } from "shared";

import UserRole from "entities/userRole/UserRole";
import IFilter from "entities/filter/IFilter";
class OrgStructureMainPanelStore {
	filteredUserStore: any[];
	funcRolesStore: any[];
	funcLeaderRolesStore: any[];
	leaderStore: any[];
	dialogData: any[];

	userRole: UserRole | null = null;
	userFilter: IFilter | null = null;
	LDAPsynchronize: boolean = false;
	LDAPelem: any;

	constructor() {
		makeAutoObservable(this);
		this.filteredUserStore = [];
		this.funcRolesStore = [];
		this.funcLeaderRolesStore = [];
		this.leaderStore = [];
		this.dialogData = [];
	}

	async setValue(value: any, fieldName: string) {
		Reflect.set(this, fieldName, value);
	}

	async loadFuncRoles(id: string) {
		const request = await api.http.administration.getAllFuncRoleByOrgRole(id).get();
		if (request.data.success) {
			this.funcRolesStore = request.data.data;
		}
	}
	async loadFuncLeaderRoles(id: string) {
		const request = await api.http.administration.getAllFuncRoleByOrgRole(id).get();
		if (request.data.success) {
			this.funcLeaderRolesStore = request.data.data;
		}
	}
	async setUsersById(id: string) {
		this.filteredUserStore = await this.getUsersByRoleId(id);
	}
	async setLeadersById(id: string) {
		this.leaderStore = await this.getUsersByRoleId(id);
	}

	async getUsersByRoleId(roleId: string) {
		const request = await api.http.administration.usersByRole(roleId).get();
		if (request.data.success) {
			return request.data.data;
		}
	}

	async addUserInRole(id: string, roles: string[]) {
		let data = {
			userId: id,
			roleIds: roles
		};
		const request = await api.http.administration.includeUserInPole().post(data);
		if (request.data.success) {
			this.setUsersById(this.userRole?.data?.id!);
			if (this.userRole?.data?.hasLeadRole) this.setLeadersById(this.userRole?.data?.leadRole.id!);
			return true;
		} else return false;
	}

	async linkOrgAndFuncRole(orgRoleId: string, funcRoleId: string) {
		const request = await api.http.administration.linkOrgAndFuncRole(orgRoleId, funcRoleId).post(null);
		if (request.data.success) {
			this.loadFuncRoles(this.userRole?.data?.id!);
			if (this.userRole?.data?.hasLeadRole) this.loadFuncLeaderRoles(this.userRole?.data?.leadRole.id!);
			return true;
		} else return false;
	}

	async linkOrgAndFuncRoles(orgRoleId: string, funcRoleIds: string[]) {
		if (funcRoleIds.length > 0) {
			let data = {
				orgRoleId: orgRoleId,
				funcRoleIds: funcRoleIds
			};
			const request = await api.http.administration.linkOrgAndFuncRoles().post(data);
			if (request.data.success) {
				this.loadFuncRoles(this.userRole?.data?.id!);
				if (this.userRole?.data?.hasLeadRole) this.loadFuncLeaderRoles(this.userRole?.data?.leadRole.id!);
				return true;
			} else return false;
		} else this.linkOrgAndFuncRole(orgRoleId, funcRoleIds[0]);
	}
}

const orgStructureMainPanelStore = new OrgStructureMainPanelStore();

export default orgStructureMainPanelStore;
