import { observer } from "mobx-react";
import { useCallback, useMemo } from "react";
import { toJS } from "mobx";

import { dispatcher, store } from "store";
import { synchroiser } from "synchroiser";

import { Actions, Dates } from ".";
import { Fields } from "..";

import { ColumnSpecializationType, EntityColumnSpecialization, GridItem } from "types/entity";

import styles from "./right-block.module.scss";

/**
 *@description сюда вписываются специализации, по которым необходимо отфильтровать gridItems
 */
export const findedSpecializations: EntityColumnSpecialization[] = [
	{
		tag: ColumnSpecializationType.KanbanField,
		properties: {
			stageField: "true",
			priorityField: "true"
		}
	}
];

export const RightBlock = observer((props: { fields: GridItem[]; onClose: () => void }) => {
	const recordId = store.currentRowId;

	const handleSaveMock = useCallback(async () => {
		await synchroiser.updateRow();
		props.onClose();
	}, [props]);

	const handleCancelMock = () => {
		dispatcher.currentRow.rollback();
	};

	const hasChangesMock = useMemo(() => {
		return store.hasChanges;
	}, [store.hasChanges]);

	const entity = useMemo(() => dispatcher.currentRow.get(), [dispatcher.currentRow.get()]);
	const cellTitles = { "Создано: ": "createdOn", "Обновлено: ": "modifiedOn" };

	const items = useMemo(
		() => [
			...dispatcher.sectionWizzard.findGridItemsWithSpecializations(
				dispatcher.sectionWizzard.getAllGridItems(),
				findedSpecializations
			),
			...props.fields
		],
		[toJS(props.fields)]
	);

	return (
		<div className={styles.rightBlockContainer}>
			<Actions handleSave={handleSaveMock} handleCancel={handleCancelMock} hasChanges={hasChangesMock} recordId={recordId} />
			<div className={styles.divider} />
			<Fields items={items} blockPosition="right" />
			<Dates entity={entity} cellTitles={cellTitles} />
		</div>
	);
});
