import { isNull } from "lodash";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import { businessRuleEditorStore } from "../../business-rule-editor-store";

import { ActionsBuilder, BusinessRuleRecordHeader, ConditionBuilder } from "./components";

import { Item } from "types";

import styles from "./business-rule-editor.module.scss";

export const BusinessRuleEditor = observer(() => {
	useEffect(() => {
		if (businessRuleEditorStore.businessRule) {
			businessRuleEditorStore.init();
		}
	}, []);

	return (
		<div className={styles.businessRuleEditor}>
			<BusinessRuleRecordHeader />
			{businessRuleEditorStore.businessRule && (
				<>
					<ConditionBuilder getValidation={(id) => businessRuleEditorStore.validation?.get(id)} />
					<ActionsBuilder
						block="action"
						actions={businessRuleEditorStore.businessRule.ruleSettings.actions}
						setField={(value: Item | null, actionId: string) => businessRuleEditorStore.setFieldToAction(value, actionId)}
						setActionType={(value: Item | null, actionId: string, needResetValidation?: boolean) =>
							businessRuleEditorStore.setActionTypeToAction(value, actionId, needResetValidation)
						}
						setValue={(value: string | Item | null, actionId: string, needResetValidation?: boolean) =>
							businessRuleEditorStore.setValueToAction(value, actionId, needResetValidation)
						}
						onAdd={() => businessRuleEditorStore.addNewEmptyAction("actions")}
						getValidation={(id) => businessRuleEditorStore.validation?.get(id)}
					/>
					<ActionsBuilder
						block="elseAction"
						actions={businessRuleEditorStore.businessRule.ruleSettings.elseActions}
						setField={(value: Item | null, actionId: string) => businessRuleEditorStore.setFieldToElseAction(value, actionId)}
						setActionType={(value: Item | null, actionId: string, needResetValidation?: boolean) =>
							businessRuleEditorStore.setActionTypeToElseAction(value, actionId, needResetValidation)
						}
						setValue={(value: string | Item | null, actionId: string, needResetValidation?: boolean) =>
							businessRuleEditorStore.setValueToElseAction(value, actionId, needResetValidation)
						}
						onAdd={() => businessRuleEditorStore.addNewEmptyAction("elseActions")}
						getValidation={(id) => businessRuleEditorStore.validation?.get(id)}
					/>
				</>
			)}
		</div>
	);
});
