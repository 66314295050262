import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import classNames from "classnames";

import Select from "components/select/select";

import IStage from "entities/IStage";
import { Item } from "types";

import { ArrowToDown } from "shared";

import styles from "./stage-indication-panel.module.css";

export interface IStageIndicationPanel {
	stages?: IStage[];
	stage: IStage;
	notModifiedStage: IStage;
	classname?: string;
	onChangeStage?: (value: Item) => void;
	onChangeFinishedStage: (value: Item | null) => void;
}

const StageIndicationPanel = observer(function (props: IStageIndicationPanel) {
	const sortedStages = useMemo(() => {
		return props.stages!.slice().sort((a, b) => (a.order > b.order ? 1 : -1));
	}, [props.stages]);
	const isLastAndSuccessfulStage = useMemo(() => {
		return props.stage.end && props.stage.successful;
	}, [props.stage]);
	const isLastAndFailStage = useMemo(() => {
		return props.stage.end && !props.stage.successful;
	}, [props.stage]);
	const finishedItems = useMemo(() => {
		return sortedStages.filter((s) => s.end === true);
	}, [sortedStages]);
	const selectName = useMemo(() => {
		return props.stage.end ? props.stage.name : "Завершена";
	}, [props.stage]);

	const wrapperClasses = classNames({
		[`${styles.stage} `]: true,
		[`${props.classname}`]: props.classname
	});
	const selectClasses = classNames({
		[`${styles.largeIndicationEnd}`]: true,
		[`${styles.successful}`]: isLastAndSuccessfulStage,
		[`${styles.fail}`]: isLastAndFailStage
	});
	const largeStageClasses = classNames({
		[`${styles.largeIndicationEnd} ${styles.largeIndicationEndButton}`]: true,
		[`${styles.successful}`]: isLastAndSuccessfulStage,
		[`${styles.fail}`]: isLastAndFailStage
	});

	return (
		<div className={wrapperClasses}>
			<div className={styles.largeIndications}>
				{sortedStages
					.filter((s) => s.end === false)
					.map((item) => {
						const stageClasses = classNames({
							[`${styles.largeIndication} `]: true,
							[`${styles.inProcess} `]: item.id == props.stage.id,
							[`${styles.completed} `]: item.order < props.stage.order,
							[`${styles.largeIndication} ${styles.successful} `]: isLastAndSuccessfulStage,
							[`${styles.largeIndication} ${styles.fail}  `]: isLastAndFailStage
						});
						const largeStageClasses = classNames({
							[`${styles.largeStageCaption} `]: true,
							[`${styles.inProcess} `]: item.id === props.stage.id,
							[`${styles.completed}`]: item.order < props.stage.order
						});
						function onClick() {
							if (props.onChangeStage && !props.notModifiedStage.end) props.onChangeStage(item);
						}

						return (
							<div className={stageClasses} onClick={onClick}>
								<span className={largeStageClasses}>{item.name}</span>
							</div>
						);
					})}
				{!props.notModifiedStage.end ? (
					<Select
						items={finishedItems}
						onChangeValue={props.onChangeFinishedStage}
						secondIcon={<ArrowToDown />}
						isRotateSecondIcon={true}
						className={selectClasses}
						classNameButton={styles.largeIndicationEndButton}
						name={selectName}
						isListAdjustOfButton={true}
					/>
				) : (
					<div className={largeStageClasses}>
						<span className={styles.largeStageCaption}>{props.stage.name}</span>
					</div>
				)}
			</div>
		</div>
	);
});

export default StageIndicationPanel;
