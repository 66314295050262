import { action } from "mobx";
import { isNumber } from "lodash";
import { stringify } from 'flatted';

import { dispatcher, selector, sessionStore, store } from "store";

import { synchroiser } from "synchroiser";
import { getPriorityColumnName, getStagesColumnName } from "synchroiser/lib";
import { singlePageSynchroiser } from "./single-page-synchroiser";

import { FilterStore } from "entities/filter/FilterStore";
import IFilter from "entities/filter/IFilter";
import { getStageRecordsQuantity } from "./get-stage-records-quantity";
import { getRecordsListByStageName } from "./get-records-list-by-stage-name";
import { getNeedColumns } from "./get-need-columns";


export async function getKanbanColumns(entityName: string) {
    const stageModel = selector.stageModels.getAll();
    const gridItems = dispatcher.sectionWizzard.getAllGridItems();
    const currentEntityName = store.sections.find((section: any) => section.id === store.currentEntityId)?.entityName;
    
    if (currentEntityName && sessionStore.getSort(currentEntityName) && stringify(dispatcher.entity.get()?.entity?.sort) !== stringify(sessionStore.getSort(currentEntityName ?? ""))) {
        dispatcher.entity.setSort(sessionStore.getSort(currentEntityName));
    }
    const sort = dispatcher.entity.get()?.entity?.sort;
    const staticGroupId = dispatcher.entity.get()?.entity.filter?.staticGroup?.id;
    const entity = dispatcher.entity.get()?.entity;

    if (!entity) {
        return null;
    }

    action("set entity rows", () => {
        entity.rows = [];
    })();

    const priorityFieldsName = getPriorityColumnName(gridItems) as string;
    const stageFieldName = getStagesColumnName(gridItems);

    const needColumns = getNeedColumns() ?? [];

    if (!dispatcher.entity.get()?.priorities) {
        await synchroiser.getPriority(priorityFieldsName ?? "")
    }
    selector.stageModels.getAll()
    const requests: Array<Promise<void>> = [];

    stageModel.forEach((stage) => {
        getStageRecordsQuantity(entityName, stage.id).then((result) => {
            if (isNumber(result.data.data)) {
                singlePageSynchroiser.setStageQuantityRecords(stage.id, result.data.data);
            }
        });
    });

    const filter = new FilterStore(currentEntityName!, entity.filter?.savedFilter?.filterInfo as IFilter ?? null, null);

    stageModel.forEach((stage) => {
        requests.push(getRecordsListByStageName(needColumns, currentEntityName, filter, stageFieldName, stage.id, sort, staticGroupId)
            .then((req: any) => {
                if (req?.data?.success) {
                    action("add rows for kanban", () => {
                        entity.rows = [...entity.rows, ...req.data.data.records.map((i: any) => i)];
                    })();
                }
            }));
    });

    await Promise.all(requests).then(() => {
        sessionStore.setFilter(currentEntityName!, (entity.filter?.savedFilter || entity.filter?.staticGroup) ? entity.filter : null);
    });
}