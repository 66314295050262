import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { toJS } from "mobx";

import { getSectionPath } from "shared";
import { Dropdown, Icon } from "sale-bridge-ui-kit";
import { NavigationButton } from "../navigation-button";

import { ItemGroup } from "sale-bridge-ui-kit/dist/components/dropdown/dropdown";
import { SelectItemProps, SelectProps } from "./data";

import styles from "./section-select.module.scss";

export function SectionSelectItem(props: SelectItemProps) {
	const [hoverStar, setHoverStar] = useState<boolean>(false);
	const path = getSectionPath(props.item.entityName ?? "");

	const favStarClassNames = classNames(styles.itemIcon, {
		[`${styles.activeItemIcon}`]: props.item.IsFavorite,
		[`${styles.emptyItemIcon}`]: !props.item.IsFavorite
	});

	const onClickToFavorite = useCallback(
		(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			event.stopPropagation();
			event.preventDefault();
			props.handleClickToFavorites(props.item);
		},
		[props.handleClickToFavorites]
	);

	const handleMouseEnter = useCallback(() => {
		setHoverStar(true);
	}, []);

	const handleMouseLeave = useCallback(() => {
		setHoverStar(false);
	}, []);

	return (
		<div className={styles.item}>
			<Link key={props.item.id} to={path} onClick={() => props.setOpenedSectionSelectDropdown(false)} className={styles.link}>
				<span>{props.item.displayValue}</span>
			</Link>
			<div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className={favStarClassNames} onClick={onClickToFavorite}>
				<Icon name={props.item.IsFavorite || hoverStar ? "FillStar" : "EmptyStar"} size={props.size} />
			</div>
		</div>
	);
}

export function SectionSelect(props: SelectProps) {
	const buttonRef = useRef<HTMLDivElement>(null);
	const [openedDropdown, setOpenedDropdown] = useState<boolean>(false);
	const [quantity, setQuantity] = useState<number>(0);

	useEffect(() => {
		setQuantity(props.items.length);
	}, [props.items]);

	const listItems = useMemo((): Array<ItemGroup> => {
		if (props.items) {
			return [
				{
					layout: <></>,
					items: props.items.map((item) => {
						return (
							<SectionSelectItem
								item={item}
								size={props.size}
								setOpenedSectionSelectDropdown={setOpenedDropdown}
								handleClickToFavorites={props.handleClickToFavorites}
							/>
						);
					})
				}
			];
		}
		return [];
	}, [toJS(props.items)]);

	const handleClick = useCallback(
		(event: Event) => {
			const node = document.getElementById("section-select-dropdown");
			if (node && node.contains(event.target as Node)) return;
			setOpenedDropdown(false);
		},
		[openedDropdown]
	);

	const handleClickButton = useCallback(() => {
		if (!openedDropdown) {
			document.addEventListener("click", handleClick);
		} else {
			document.removeEventListener("click", handleClick);
		}
		setOpenedDropdown(!openedDropdown);
	}, [openedDropdown]);

	return (
		<div id="section-select-dropdown">
			<div ref={buttonRef}>
				<NavigationButton
					size={props.size}
					rightIcon="Dropdown"
					caption="Разделы"
					isSelected={openedDropdown}
					onClick={handleClickButton}
					className={styles.borderButton}
				/>
			</div>
			{openedDropdown && listItems.length > 0 && (
				<Dropdown
					size="small"
					variant="button"
					position="down"
					items={listItems}
					quantity={quantity}
					hasSearch={false}
					parentWidth={0}
					parentHeight={0}
				/>
			)}
		</div>
	);
}
