import classNames from "classnames";

import { ColorPanelItem } from "./color-panel-item";

import styles from "components/color-panel/color-panel.module.scss";

/**
 * @example
 * const [color, setColor] = useState<string>('#EAECF5');
 * const colors = Object.values(PanelColors);
 * <ColorPanel value={color} onChange={(value:string)=>setColor(value)} colorPanel={colors}/>
 */
export const ColorPanel = (props: { [key: string]: any }) => {
	const colorPanelWrapper = classNames(styles.colorPanelWrapper,
		{ [`${props.className}`]: props.className, }
	);

	return (
		<div className={colorPanelWrapper} >
			{props.colorPanel.map((color: string, index: number) =>
				<ColorPanelItem
					key={index}
					color={color}
					onClick={() => props.onChange(color)}
					isChecked={color === props.value}
					isDisabled={props.isDisabled}
				/>
			)}
		</div>
	);

};