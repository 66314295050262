import { isNull, isUndefined, lowerFirst } from "lodash";
import { IReactionDisposer, makeAutoObservable, reaction } from "mobx";

import { dispatcher, store } from "store";
import { useSortableData } from "shared";

import { Action, ActionType, BusinessRule, FieldConfig, GridItem, RuleSetting, SectionWizzard } from "types/entity";
import IFilter, { ComparisonType, FilterType, LogicalOperation } from "entities/filter/IFilter";
import { SortDirection } from "entities/ISort";
class BusinessRulesEngine {
	recordId: string | null = null;
	virtualSectionWizzard: SectionWizzard | null = null;
	virtualOldSectionWizzard: SectionWizzard | null = null;

	disposer: IReactionDisposer | null = null;
	constructor() {
		this.disposer?.();
		makeAutoObservable(this);
	}

	get isDisposerActive() {
		return !isNull(this.disposer);
	}

	get observableRrecordId() {
		return this.recordId;
	}

	get virtualGridItems(): GridItem[] {
		if (isNull(this.virtualSectionWizzard)) {
			return [];
		}
		return this.virtualSectionWizzard.reactorConfig?.tabs.tabsConfig.reduce<GridItem[]>((acc, tab) => {
			tab.grid.items
				.reduce<GridItem[]>((acc, item) => {
					if (item.groupFieldsConfig?.inner?.items) {
						item.groupFieldsConfig?.inner?.items.forEach((innerItem) => {
							if (innerItem.fieldConfig) {
								acc.push(innerItem);
							}
						});
					}
					if (item.fieldConfig) {
						acc.push(item);
					}

					return acc;
				}, [])
				.forEach((item) => acc.push(item));
			return acc;
		}, []);
	}

	setSectionWizzard(sectionWizzard: SectionWizzard | null) {
		this.virtualSectionWizzard = JSON.parse(JSON.stringify(sectionWizzard)) as SectionWizzard | null;
		this.virtualOldSectionWizzard = JSON.parse(JSON.stringify(sectionWizzard)) as SectionWizzard | null;
	}

	dispose() {
		this.disposer?.();
		this.disposer = null;
		this.recordId = null;
		this.virtualSectionWizzard = null;
		this.virtualOldSectionWizzard = null;
	}

	getBusinessRulesField(condition: IFilter): string[] {
		if (condition.type === FilterType.Group && condition.filters) {
			return condition.filters.reduce<string[]>((acc, conditionFilter) => {
				const conditionFields = this.getBusinessRulesField(conditionFilter);
				conditionFields.forEach((conditionField) => acc.push(conditionField));
				return acc;
			}, []);
		}
		if (condition.type === FilterType.Attribute) {
			return [lowerFirst(condition.attribute)];
		}
		return [];
	}

	startTrackingChanges(rowId: string) {
		this.disposer?.();

		const businessRules = dispatcher.sectionWizzard.getBusinessRules();
		if (!businessRules || businessRules.length == 0) {
			return;
		}

		this.recordId = rowId;

		const sortedBusinessRules = useSortableData(businessRules, "priority", SortDirection.Ascending) as BusinessRule[];

		const businessRulesConditions = sortedBusinessRules.reduce<IFilter[]>((acc, businessRule) => {
			if (businessRule.ruleSettings.conditions) {
				acc.push(businessRule.ruleSettings.conditions);
			}
			return acc;
		}, []);

		const fieldsOfConditions = businessRulesConditions.reduce<string[]>((acc, conditionFilter) => {
			const conditionFields = this.getBusinessRulesField(conditionFilter);
			conditionFields.forEach((conditionField) => {
				if (isUndefined(acc.find((accValue) => accValue.toLowerCase() === conditionField.toLowerCase()))) {
					acc.push(conditionField);
				}
			});
			return acc;
		}, []);

		const fieldsOfActions = sortedBusinessRules.reduce<string[]>((acc, businessRule) => {
			businessRule.ruleSettings.actions.forEach((action) => action.field && acc.push(action.field));
			businessRule.ruleSettings.elseActions.forEach((elseAction) => elseAction.field && acc.push(elseAction.field));
			return acc;
		}, []);

		this.disposer = reaction(
			() =>
				dispatcher.entity.get()?.entity.rows.reduce((acc, row) => {
					if (row.id === this.recordId) {
						fieldsOfConditions.forEach((field) => (acc[field] = row[field]));
						acc["id"] = row.id;
					}
					return acc;
				}, {}),
			(observableRecord) => {
				const record = dispatcher.entity.get()?.entity.rows.find((row) => row.id === observableRecord.id);

				const fieldConfigOfActions = this.virtualGridItems
					.filter((gridItem) => !isUndefined(fieldsOfActions.find((field) => gridItem.fieldConfig!.columnId === field)))
					.map((gridItem) => gridItem.fieldConfig!);

				sortedBusinessRules.forEach((businessRule) => {
					this.applyBusinessRule(businessRule.ruleSettings, record, fieldConfigOfActions);
				});
			},
			{ fireImmediately: true }
		);
	}

	/**
	 * @description Метод для применения БП
	 * @param businessRuleSettings - настройки БП
	 * @param record - запись, в которой произошел триггер БП
	 * @param fieldConfigOfConditions - массив fieldConfig полей, выбранных в блоках "То" и "Иначе"
	 */
	applyBusinessRule(businessRuleSettings: RuleSetting, record: any, fieldConfigOfConditions: FieldConfig[]) {
		if (!businessRuleSettings.conditions) {
			return;
		}
		const isConditionExecuted = this.getLogicalCondition(businessRuleSettings.conditions, record);
		if (isConditionExecuted) {
			businessRuleSettings.actions.forEach((action) => {
				this.applyAction(
					record,
					action,
					fieldConfigOfConditions.find((fieldConfig) => fieldConfig.columnId === action.field)
				);
			});
		} else {
			businessRuleSettings.elseActions.forEach((action) =>
				this.applyAction(
					record,
					action,
					fieldConfigOfConditions.find((fieldConfig) => fieldConfig.columnId === action.field)
				)
			);
		}
	}

	/**
	 * @description Метод для проверки выполнения всего блока "Если"
	 * @param condition - условие/группа уловий
	 * @param row - запись, в которой произошел триггер БП
	 * @returns true - условия из блока "Если" выполняются, false - нет
	 */
	getLogicalCondition(condition: IFilter, row: any): boolean {
		switch (condition.logicalOperation) {
			case LogicalOperation.And:
				return (
					condition.filters?.every((condition) => {
						if (condition.type == FilterType.Group && condition.filters) {
							if (condition.filters.length > 0) {
								return this.getLogicalCondition(condition, row);
							} else {
								return false;
							}
						}
						return this.evaluateCondition(condition, row);
					}) ?? false
				);
			case LogicalOperation.Or:
				return (
					condition.filters?.some((condition) => {
						if (condition.type == FilterType.Group && condition.filters) {
							if (condition.filters.length > 0) {
								return this.getLogicalCondition(condition, row);
							} else {
								return false;
							}
						}
						return this.evaluateCondition(condition, row);
					}) ?? false
				);
		}
	}

	/**
	 * @description Метод для проверки выполнения конкреного условия из блока "Если"
	 * @param condition - условие/группа уловий
	 * @param row - запись, в которой произошел триггер БП
	 * @returns true - условие из блока "Если" выполняется, false - нет
	 */
	evaluateCondition = (condition: IFilter, row: any): boolean => {
		const rowValue = row[lowerFirst(condition.attribute)];
		const rightExpressionValue = condition.rightExpression?.parameter.value;

		const value = !isNull(rowValue) && !isUndefined(rowValue) ? rowValue?.id ?? rowValue?.toString() : rowValue;
		const conditionValue = rightExpressionValue?.id ?? rightExpressionValue;

		switch (condition.comparisonType) {
			case ComparisonType.Between:
				return value >= conditionValue.start && value <= conditionValue.end;
			case ComparisonType.IsNull:
				return !Boolean(value);
			case ComparisonType.IsNotNull:
				return Boolean(value);
			case ComparisonType.Equal:
				return value === conditionValue.toString();
			case ComparisonType.NotEqual:
				return value !== conditionValue.toString();
			case ComparisonType.Less:
				return value < conditionValue.toString();
			case ComparisonType.LessOrEqual:
				return value <= conditionValue.toString();
			case ComparisonType.Greater:
				return value > conditionValue.toString();
			case ComparisonType.GreaterOrEqual:
				return value >= conditionValue.toString();
			case ComparisonType.Contain:
				return (value as string).includes(conditionValue);
			case ComparisonType.NotContain:
				return !(value as string).includes(conditionValue);
			case ComparisonType.NotBetween:
				return value <= conditionValue.start && value >= conditionValue.end;
			default:
				return false;
		}
	};

	/**
	 * @description Метод для применения условия из блоков "То" и "Иначе"
	 * @param row - запись, в которой происходит изменение
	 * @param action - условие
	 * @param fieldConfig - fieldConfig поля, выбранного в условии
	 * @returns
	 */
	applyAction(row: any, action: Action, fieldConfig?: FieldConfig) {
		switch (action.actionType) {
			case ActionType.SET_VALUE:
				row[lowerFirst(fieldConfig?.columnName)] = action.value;
				store.setChangedField(lowerFirst(fieldConfig?.columnName), action.value);
				break;
			case ActionType.CLEAR_VALUE:
				row[lowerFirst(fieldConfig?.columnName)] = null;
				store.setChangedField(lowerFirst(fieldConfig?.columnName), null);
				break;
			case ActionType.MAKE_IT_REQUIRED:
				if (fieldConfig) {
					fieldConfig.isRequired = true;
				}
				break;
			case ActionType.MAKE_IT_OPTIONAL:
				if (fieldConfig) {
					fieldConfig.isRequired = false;
				}
				break;
			case ActionType.MAKE_EDITABLE:
				if (fieldConfig) {
					fieldConfig.uneditable = false;
				}
				break;
			case ActionType.MAKE_UNEDITABLE:
				if (fieldConfig) {
					fieldConfig.uneditable = true;
				}
				break;
			case ActionType.MARK:
				row[lowerFirst(fieldConfig?.columnName)] = true;
				store.setChangedField(lowerFirst(fieldConfig?.columnName), true);
				break;
			case ActionType.UNCHECK:
				row[lowerFirst(fieldConfig?.columnName)] = false;
				store.setChangedField(lowerFirst(fieldConfig?.columnName), false);
				break;
			//TODO реализовать после новой сетки конструктора
			case ActionType.SHOW_ON_PAGE:
				break;
			case ActionType.HIDE_FROM_PAGE:
				break;
		}
	}

	rollback() {
		this.virtualSectionWizzard = JSON.parse(JSON.stringify(this.virtualOldSectionWizzard)) as SectionWizzard | null;
	}
}

export const businessRulesEngine = new BusinessRulesEngine();
