import classNames from "classnames";
import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";

import { getNoun } from "shared";
import searchStore from "./core/SearchStore";

import InputSearch from "components/input/input-search/input-search";
import SearchCard from "./ui/search-card";

import filterTable from "./data/FilterTable";

import styles from "./global-search.module.scss";

export const GlobalSearch = observer(function () {
    const [searchValue, setSearchValue] = useState<string>("");
    const [openedCard, setOpenCard] = useState<boolean>(false);
    const [isFilterAll, setFilterAll] = useState<boolean>(true);

    useEffect(() => {
        function click(event: any) {
            if (!event.target.closest('#GlobalSearch')) {
                setOpenCard(false);
            }
        }
        document.addEventListener("click", click);
        return () => document.removeEventListener("click", click);
    }, [])

    const search = useCallback((value: string) => {
        setSearchValue(value);
        setOpenCard(true);
        searchStore.load(value);
    }, []);

    const onSearchClick = useCallback(() => {
        setOpenCard(true);
    }, []);

    const handleChangeFilter = useCallback((table: string, value: boolean) => {
        const result: any[] = [];
        for (let filter of searchStore.filtres) {
            if (filter !== table)
                result.push(filter);
        }
        if (value) {
            result.push(table);
        }

        setFilterAll(false);
        searchStore.filtres = result;
        searchStore.load(searchValue, result);
    }, [toJS(searchStore.filtres), searchValue]);

    const handleFilterAll = useCallback((value: boolean) => {
        if (value) {
            let currentFiltres = filterTable.map(f => f.table);
            searchStore.filtres = currentFiltres;
            searchStore.load(searchValue, currentFiltres);
        }
        else {
            searchStore.filtres = [];
            searchStore.load(searchValue, []);
        }
        setFilterAll(value);
    }, [toJS(searchStore.filtres), searchValue, toJS(filterTable)]);

    const getCount = useCallback((count: number) => {
        if (count === 0) {
            return "Нет совпадений"
        }
        else {
            return `${getNoun(count, "Найдена", "Найдено", "Найдено")}: ${count} ${getNoun(count, "запись", "записи", "записей")}`
        }
    }, []);

    const trackScrolling = useCallback((e: SyntheticEvent<HTMLDivElement>) => {
        const wrappedElement = e.target as HTMLDivElement;
        if (Math.round(wrappedElement.scrollHeight - wrappedElement.scrollTop) <= wrappedElement.clientHeight + 1 && searchStore.isLoadedBatch) {
            searchStore.loadMore(searchValue)
        }
    }, [searchStore.isLoadedBatch, searchValue]);

    const className = classNames(styles.search, {
        [`${styles.searchActive}`]: openedCard,
    });

    return (
        <div id="GlobalSearch">
            <InputSearch
                placeholder="Поиск"
                value={searchValue}
                onChangeValue={search}
                onClick={onSearchClick}
                classNameInput={className}
            />
            {openedCard &&
                <SearchCard
                    entities={searchStore.entities}
                    filtres={filterTable}
                    onFilterChange={handleChangeFilter}
                    onFilterAll={handleFilterAll}
                    isCheckedFilterAll={isFilterAll} filtresActive={searchStore.filtres}
                    isLoaded={searchStore.isLoaded} count={getCount(searchStore.count)}
                    onScroll={trackScrolling}
                />
            }
        </div>

    )
});
