import { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { v4 } from "uuid";

import { Position, modalController } from "features/modals";

import { FieldSelect } from "pages/section-wizzard/components";
import { Button, Checkbox, Hint } from "sale-bridge-ui-kit";
import { ExistFieldsBlock } from "pages/section-wizzard/components/blocks";
import { dispatcher } from "store";

import { ButtonStyle, InputSearch } from "components";

import { AdditionalField } from "types/entity";
import { IGridItem } from "components/select/types";
import { AdditionalTitle, UserFieldsTitle } from "pages/section-wizzard/data/data";
import { Types } from "modules/DND/type";
import { AdditionalInfoOfField } from "pages/section-wizzard/pages/quick-view-designer/components/quick-view-settings/data";

import { CloseMaxi } from "shared";

import styles from "../../card-designer-settings-panel.module.scss";

export const EnabledUsers = observer(
	(props: {
		usersAvatars: Array<AdditionalField>;
		additionalInfoOfField: Map<string, AdditionalInfoOfField>;
		setUserAvatarsEnable: (value: boolean, columnId: string, users: AdditionalField[]) => void;
	}) => {
		const suffix = "Id";

		const handleUserAvatarsEnable = useCallback(
			(value: boolean, columnId: string) => {
				props.setUserAvatarsEnable(value, columnId, props.usersAvatars);
			},
			[toJS(props.usersAvatars)]
		);

		const firstDisabled = useMemo(() => props.usersAvatars.filter((user) => !user.isBlocked).length == 1, [toJS(props.usersAvatars)]);
		const maxCountIsThree = useMemo(
			() =>
				dispatcher.sectionWizzard.getSectionWizzard()?.hasFlag && props.usersAvatars.filter((user) => !user.isBlocked).length == 3,
			[toJS(props.usersAvatars)]
		);
		const maxCountIsFive = useMemo(() => props.usersAvatars.filter((user) => !user.isBlocked).length == 5, [toJS(props.usersAvatars)]);

		const checkBox = useCallback((user: AdditionalField, disabled: boolean) => {
			let description = props.additionalInfoOfField.get(user.columnId)?.columnName;
			const formattedDescription = description && description.endsWith(suffix) ? description : `${description ?? "User"}${suffix}`;
			description = `[${formattedDescription}]`;
			return (
				<Checkbox
					size="small"
					value={!user.isBlocked}
					onChangeChecked={(value: boolean) => handleUserAvatarsEnable(value, user.columnId)}
					title={props.additionalInfoOfField.get(user.columnId)?.columnTitle}
					description={description}
					disabled={disabled}
				/>
			);
		}, []);

		return (
			<FieldSelect name={UserFieldsTitle} buttonStyle={ButtonStyle.Settings} className={styles.fieldSelectContainer}>
				<div className={styles.fieldSelectContainerBody}>
					{props.usersAvatars.map((user) =>
						!user.isBlocked && firstDisabled ? (
							<div className={styles.checkboxGroup}>
								<Hint
									hintBody="Для неотображения аватаров кликните по настройке «Аватары пользователей» выше"
									startPosition="top"
								>
									{checkBox(user, true)}
								</Hint>
							</div>
						) : user.isBlocked && (maxCountIsThree || maxCountIsFive) ? (
							<div className={styles.checkboxGroup}>
								<Hint hintBody={`Максимум ${maxCountIsThree ? "3 пользователя" : "5 пользователей"}`} startPosition="top">
									{checkBox(user, true)}
								</Hint>
							</div>
						) : (
							<div className={styles.checkboxGroup}>{checkBox(user, false)}</div>
						)
					)}
				</div>
			</FieldSelect>
		);
	}
);
