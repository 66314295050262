import { observer } from "mobx-react";
import { useCallback, useState } from "react";

import { FieldSelect } from "pages/section-wizzard/components";
import { Checkbox } from "sale-bridge-ui-kit";

import { ButtonStyle } from "components";

import { AdditionalTitle } from "pages/section-wizzard/data/data";
import { AdditionalField } from "types/entity";

import styles from "../../card-designer-settings-panel.module.scss";

export const AdditionalSettings = observer((props: {
    usersAvatarEnabled: boolean;
    quickActionsEnabled: boolean;
    usersAvatars: AdditionalField[];
    onQuickActionEnable: (value: boolean) => void;
    onUserAvatarEnable: (value: boolean, firstUser: AdditionalField) => void;
}) => {
    const [avatarsEnabled, setAvatarsEnabled] = useState(props.usersAvatarEnabled);
    const [actionsEnabled, setActionsEnabled] = useState(props.quickActionsEnabled);

    const handleQuickActionEnable = useCallback((value: boolean) => {
        setActionsEnabled(value);
        props.onQuickActionEnable(value);
    }, [actionsEnabled]);

    const handleUserAvatarEnable = useCallback((value: boolean) => {
        setAvatarsEnabled(value);
        props.onUserAvatarEnable(value, props.usersAvatars[0]);
        if (value) {
            setActionsEnabled(true);
            props.onQuickActionEnable(true);
        } else {
            setActionsEnabled(false);
            props.onQuickActionEnable(false);
        }
    }, [props.usersAvatars])

    return (
        <FieldSelect
            name={AdditionalTitle}
            buttonStyle={ButtonStyle.Settings}
            className={styles.fieldSelectContainer}
        >
            <div className={styles.fieldSelectContainerBody}>
                <div className={styles.checkboxGroup}>
                    <Checkbox
                        size="small"
                        value={avatarsEnabled}
                        onChangeChecked={handleUserAvatarEnable}
                        title="Аватары пользователей"
                        description="Связанные с пользователями поля показываются в виде аватаров"
                        disabled={props.usersAvatars.length == 0}
                    />
                </div>
                <div className={styles.checkboxGroup}>
                    <Checkbox
                        size="small"
                        value={actionsEnabled}
                        onChangeChecked={handleQuickActionEnable}
                        title="Быстрые действия"
                        description="Отображаются, когда курсор находится над карточкой"
                        disabled={!props.usersAvatarEnabled}
                    />
                </div>
            </div>
        </FieldSelect>
    );
});
