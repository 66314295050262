import { observer } from "mobx-react-lite";

import RadioButton from "components/radio-button/radio-button";
import { IUniteData } from "features/section-head/data/constants";

import { IColumn } from "../grid";
import { ISort } from "entities/ISort";
import { IFormat } from "entities/IItem";

import styles from "./union-grid.module.css";


const GridHead = observer(function (props: {
    columns: IColumn[],
}) {
    let classNames = `${styles.tableHeader} `;

    return (
        <div className={classNames} >
            {
                props.columns.map((column) => {
                    return (
                        <>
                            <div key={column.name} id={column.caption} className={styles.headerCell}  >
                                <div className={styles.headerCaption}>{column.caption}</div>
                            </div>
                        </>
                    );
                })
            }
        </div>
    );
});


const UnionGrid = observer(function (props: {
    columns: IColumn[],
    data: IUniteData[],
    loadLookup?: (sort?: ISort) => {},
    onClick?: (row: any) => void,
    onClickRadiobutton?: (item: string) => void,
    isCheckBox?: boolean,
    checked?: boolean,
    maxHeight?: any,
}) {


    return (
        <div className={styles.wrapper} >
            <div className={styles.grid}  >
                {<GridHead columns={props.columns} />}
                <div className={styles.table} >
                    {props.data.map((column, i) => {
                        return (
                            <Column
                                key={i}
                                rowsinColumn={column.data}
                                onClick={() => { }}
                                onClickRadiobutton={props.onClickRadiobutton}
                            />

                        )
                    })}
                </div>
            </div>
        </div>
    );
})

const Column = observer(function (props: {
    rowsinColumn: IFormat[],
    onClick?: (row: any) => void,
    onClickRadiobutton?: (item: string) => void,
}) {

    let classNames = `${styles.tableColumn} `;

    return (
        <div className={classNames}>
            <div className={styles.cell}>
                <RadioButton elements={props.rowsinColumn} className={styles.columnsRadioButton} onClickRadiobutton={props.onClickRadiobutton} />
            </div>
        </div>
    );
});

export default UnionGrid;
