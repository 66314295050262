import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";

import { store } from "../model/store";

type Props = {
    id: string;
    x?: number;
    y?: number;
    width?: number;
    height?: number
    onUp?: (DNDEvent: any) => void;
    onMove?: (DNDEvent: any) => void;
    onDrop?: (DNDEvent: any) => void;
    children: JSX.Element;
    sourceData?: any;
}

/**
 * @param props
 * @param props.children - ЭЛЕМЕНТ. <b color="red">НЕЛЬЗЯ ПЕРЕДАВАТЬ МАССИВ ЭЛЕМЕНТОВ</b>
 */

const Element = observer((props: Props) => {
	return (
		<div style={store._selectedElementId === props.id ? { display: "none" } : {}}>
			{props.children}
		</div>
	);
});

export { Element };