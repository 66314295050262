import styles from "./progress-bar.module.css";


export function ProgressBar(props: { percent: number }) {
    const fillerStyles = {
        width: `${Math.trunc(props.percent)}%`
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.progressBarBlock}>
                <span className={styles.exportTitle}>Экспорт файла...</span>

                <div className={styles.progressBar}>
                    <div className={styles.filler} style={fillerStyles} />
                </div>
                <span className={styles.percentTitle}>{Math.trunc(props.percent)}%</span>
            </div>
        </div>
    )
}

