import { memo, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Droppable } from "react-beautiful-dnd";

import { singlePageSynchroiser } from "../../../../single-page-synchroiser/single-page-synchroiser";

import { Column, ColumnBody } from "components";

import { KanbanPageCard } from "../kanban-page-card";

import { KanbanPageColumnProps } from "../data";

import styles from "./kanban-page-column.module.scss";

export const KanbanPageColumn = memo(observer(function (props: {
    onOpen: (recordId: string) => void;
    id: string;
} & KanbanPageColumnProps) {

    /**
     * @description это необходимо, чтобы библиотека грамотно работала с режимом StrictMode
     */
    const [enabled, setEnabled] = useState(false);

    useEffect(() => {
        const animation = requestAnimationFrame(() => setEnabled(true));
        return () => {
            cancelAnimationFrame(animation);
            setEnabled(false);
        };
    }, []);

    if (!enabled) {
        return null;
    }
    return (

        <Column
            name={props.column.name ?? ""}
            quantity={singlePageSynchroiser.getStageQuantityRecords(props.id) ?? 0}
            size={props.size}
            position={props.position}
        >
            <Droppable
                key={props.column.id}
                droppableId={props.column.id}
                isDropDisabled={props.isDropDisabled}
            >
                {(provided) => {
                    return <ColumnBody
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        quantity={singlePageSynchroiser.getStageQuantityRecords(props.id) ?? 0}
                        id={props.id}
                        cardsCount={props.column.cards.length}
                        isBlocked={props.isDropDisabled}
                    >
                        <>
                            {props.kanbanConfig && props.column.cards.map((card: any, index) =>
                                <KanbanPageCard
                                    key={card.id}
                                    priority={card.priority}
                                    onOpen={props.onOpen}
                                    index={index}
                                    name={card.name}
                                    id={card.id}
                                    color={card.color}
                                    fields={card.fields}
                                    sysFlag={card.sysFlag}
                                    isSelected={card.isSelected}
                                    isReturned={card.isReturned}
                                    sysPosition={card.sysPosition}
                                    userFields={card.userFields}
                                    size={props.size}
                                    kanbanConfig={props.kanbanConfig}
                                    draggingCardId={props.draggingCardId}
                                    setDraggingCardId={props.setDraggingCardId}
                                />
                            )}
                            {provided.placeholder}
                        </>
                    </ColumnBody>
                }}
            </Droppable>
        </Column>
    );
}));