import { useCallback, useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx';
import { Checkbox, Field } from 'sale-bridge-ui-kit';
import { isString } from 'lodash';

import fieldConfigurationStore, { FieldStoreEnums } from '../../field-configuration-store';
import { NumeratorConfigurationEnums } from '../numerator-configuration-state';
import { BasicSettings } from '../../components/basic-settings';
import { itemsForPeriodDropdown } from './data';
import { NumeratorWorkingPeriod } from 'types/entity';
import { RenderViewColumnCheckbox } from '../../components/view-column-checkbox';
import { RenderRequiredCheckbox } from '../../components/is-required-checkbox';

import styles from '../numerator-configuration.module.scss'


const NumeratorCheckboxesBlock = observer((function () {


    const uneditableIsDisabled = useMemo((): boolean => {       
        return  !fieldConfigurationStore.numeratorConfig.fillWhenPageOpened;
    },[fieldConfigurationStore.numeratorConfig.fillWhenPageOpened])


    const handleChangeFillWhenPageOpenedChecked = (value: boolean) => {
        fieldConfigurationStore.setNumeratorConfig(NumeratorConfigurationEnums.FillWhenPageOpened, value)
        if(!value){
            fieldConfigurationStore.setValue(FieldStoreEnums.uneditable, true);
        }
};



    return <div className={styles.checkboxesBlock}>
        {<RenderViewColumnCheckbox/>}
        <div className={styles.divider} />
        <RenderRequiredCheckbox/>
        <div className={styles.divider} />

        <Checkbox
            size="small"
            value={fieldConfigurationStore.numeratorConfig.fillWhenPageOpened}
            onChangeChecked={handleChangeFillWhenPageOpenedChecked}
            disabled={fieldConfigurationStore.isViewColumn || fieldConfigurationStore.isRequired}
            variant="default"
            description='Значение поля будет сгенерировано сразу при попытке создания новой записи'
            title='Заполнять при открытии страницы создания записи'
        />

        <div className={styles.divider} />
        <Checkbox
            size="small"
            value={fieldConfigurationStore.uneditable}
            onChangeChecked={(value: boolean) => { fieldConfigurationStore.setValue(FieldStoreEnums.uneditable, value) }}
            disabled={uneditableIsDisabled}
            variant="default"
            description='Отметьте, чтобы запретить ручное редактирование'
            title='Нередактируемое поле'
        />
        <div className={styles.divider} />
        <Checkbox
            size="small"
            value={fieldConfigurationStore.hasIndex}
            onChangeChecked={(value: boolean) => { fieldConfigurationStore.setValue(FieldStoreEnums.hasIndex, value) }}
            disabled={false}
            variant="default"
            description='Отметьте, чтобы поиск по этому полю выполнялся быстрее'
            title='Индексируемое поле'
        />
    </div>
}));


export const FieldSettings = observer((props: {}) => {
    const [searchValue, setSearchValue] = useState('');
    const periodValue = useMemo(() => {
        return itemsForPeriodDropdown.find(item => item.id === fieldConfigurationStore.numeratorConfig.numeratorWorkingPeriod)?.text;
    }, [fieldConfigurationStore.numeratorConfig.numeratorWorkingPeriod]);

    const calculateDate = (workingPeriod: NumeratorWorkingPeriod): string => {
        const currentDate = new Date();
        let expirationDate = new Date();

        switch (workingPeriod) {
            case NumeratorWorkingPeriod.Day:
                expirationDate.setDate(currentDate.getDate() + 1);
                break;
            case NumeratorWorkingPeriod.Week:
                expirationDate.setDate(currentDate.getDate() + 7);
                break;
            case NumeratorWorkingPeriod.Month:
                expirationDate.setMonth(currentDate.getMonth() + 1);
                break;
            case NumeratorWorkingPeriod.Year:
                expirationDate.setFullYear(currentDate.getFullYear() + 1);
                break;
            default:
                return 'неподдерживаемый период';
        }
        const formattedExpirationDate = `${expirationDate.toLocaleDateString('ru-RU')} ${expirationDate.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' })}`
        return formattedExpirationDate;
    };

    const periodText = useMemo(() => {
        const period = itemsForPeriodDropdown.find(item => item.id === fieldConfigurationStore.numeratorConfig.numeratorWorkingPeriod);
        if (period) {
            if (period.id === NumeratorWorkingPeriod.WithoutExpirationDate)
                return 'Нумератор будет отключен, когда закончится ресурс уникальных значений'
            else {
                const expirationDate = calculateDate(period.id)
                return `Нумератор будет отключен ${expirationDate}, если ресурс уникальных значений не закончится раньше срока`
            }
        }
    }, [fieldConfigurationStore.numeratorConfig.numeratorWorkingPeriod]);

    const handlePeriodChange = useCallback((value: string | NumeratorWorkingPeriod) => {
        if (isString(value) && value.length <= 0) {
            fieldConfigurationStore.setNumeratorConfig(NumeratorConfigurationEnums.NumeratorWorkingPeriod, NumeratorWorkingPeriod.WithoutExpirationDate);
        }
        else {
            fieldConfigurationStore.setNumeratorConfig(NumeratorConfigurationEnums.NumeratorWorkingPeriod, value);
        }
    }, []);

    const dropdownItems = useMemo(() => {
        return [
            {
                layout: <></>,
                items: itemsForPeriodDropdown.map(item =>
                    <div className={styles.item} onClick={() => handlePeriodChange(item.id)}>
                        <span>{item.text}</span>
                    </div>
                )
            }
        ]
    }, [toJS(itemsForPeriodDropdown)]);

    return <div className={styles.fieldsBody}>
        <BasicSettings />
        <div className={styles.periodBlock}>
            <Field
                type='select'
                size='small'
                label='Период работы'
                labelPosition="vertical"
                textVariant='outlined'
                value={periodValue ?? ''}
                onChange={handlePeriodChange}
                items={dropdownItems}                
                searchValue={searchValue} //TODO чтобы заработал поиск нужно в items сделать filter по searchValue
                onChangeSearchValue={setSearchValue}
                isClearing
                isRequired
            />
            <span className={styles.periodPrompt}>{periodText}</span>
        </div>
        <Field
            type='text'
            size='small'
            label='Подсказка'
            labelPosition="vertical"
            textVariant='outlined'
            value={fieldConfigurationStore.prompt}
            info='То, что вы читаете прямо сейчас 🙂'
            onChange={(value: string) => {
                fieldConfigurationStore.setValue(FieldStoreEnums.prompt, value);
            }}
            isClearing
        />
        <NumeratorCheckboxesBlock />
    </div>
})
