import styles from "./separator.module.css";

function Separator() {

    return (
        <div className={styles.separator}></div>
    );
}

export default Separator
