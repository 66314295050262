import { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { autorun } from "mobx";
import classNames from "classnames";

import fieldConfigurationStore from "./field-configuration-store";

import { Button } from "sale-bridge-ui-kit";
import { FieldConfiguration } from "./field-configuration";
import { NumeratorConfiguration } from "./numerator-configuration";
import { ChoiceLookupType } from "./components";

import { ConfigurationStep } from "./types";
import { ColumnType } from "entities/ColumnType";

import { CloseMaxi } from "shared";

import styles from "./field-configuration.module.scss";

interface FieldConfigurationWrapperProps {
	columnId?: string;
	fieldType?: ColumnType;
	close: () => void;
	closeAll: () => void;
	position: {
		cellX: number;
		cellY: number;
	};
	targetZone?: string | null;
	onSave?: () => void;
}

export const FieldConfigurationWrapper = observer((props: FieldConfigurationWrapperProps) => {
	const [isConfigurationValid, setIsConfigurationValid] = useState(false);
	const [step, setStep] = useState(ConfigurationStep.InitialLookupType);
	const popupRef = useRef<HTMLDivElement>(null);
	const [style, setStyle] = useState<CSSProperties>({ height: `0px`, width: `0px` });

	useEffect(() => {
		if (style.height !== popupRef.current?.getBoundingClientRect().height) {
			setStyle({
				height: `${popupRef.current?.getBoundingClientRect().height}px`,
				width: `${popupRef.current?.getBoundingClientRect().width}px`
			});
		}
	}, [popupRef.current?.getBoundingClientRect().height]);

	useEffect(() => {
		if (props.fieldType !== ColumnType.Lookup) {
			setStep(ConfigurationStep.MainSettings);
		}
	}, [props.fieldType]);

	useEffect(() => {
		fieldConfigurationStore.setValueWithoutTrackingChanges("x", props.position.cellX);
		fieldConfigurationStore.setValueWithoutTrackingChanges("y", props.position.cellY);
	}, [props.position.cellX, props.position.cellY]);

	useEffect(() => {
		const validateConfiguration = () => {
			const isValid = fieldConfigurationStore.isConfigurationValid();
			setIsConfigurationValid(isValid);
		};
		validateConfiguration();
		const disposer = autorun(() => {
			validateConfiguration();
		});
		return () => {
			disposer();
		};
	}, [fieldConfigurationStore]);

	const handleStepChange = useCallback(() => {
		setStep(ConfigurationStep.MainSettings);
	}, []);

	const handleClose = useCallback(() => {
		if (fieldConfigurationStore.hasChanges) {
			props.close();
		} else {
			props.closeAll();
		}
	}, [fieldConfigurationStore.hasChanges, props.close]);

	const handleSave = useCallback(() => {
		// TODO кейс на пустой props
		if (isConfigurationValid) {
			fieldConfigurationStore.saveConfiguration({
				targetZone: props.targetZone,
				columnId: props.columnId ?? ""
			});
			props.closeAll();
			if (props.onSave) {
				props.onSave();
			}
		}
	}, [props, isConfigurationValid]);

	const dialogFooterButtons = useMemo(() => {
		if (props.columnId) {
			setStep(ConfigurationStep.MainSettings);
		}

		return (
			<div className={styles.dialogFooter}>
				{step !== ConfigurationStep.InitialLookupType && <div className={styles.dialogFooterDivider} />}
				<div className={styles.footerButtonsBlock}>
					<Button text="Отменить" size="small" variant="backless" border link={false} loading={false} onClick={handleClose} />
					{step === ConfigurationStep.InitialLookupType && (
						<Button
							text="Продолжить"
							size="small"
							variant="primary"
							border
							link={false}
							loading={false}
							onClick={handleStepChange}
						/>
					)}
					{step === ConfigurationStep.MainSettings && (
						<Button
							text={props.fieldType === ColumnType.Numerator ? "Сохранить нумератор" : "Сохранить"}
							size="small"
							variant={isConfigurationValid ? "primary" : "disabled"}
							border
							link={false}
							loading={false}
							onClick={handleSave}
						/>
					)}
				</div>
			</div>
		);
	}, [handleClose, step, handleStepChange, handleSave, isConfigurationValid]);

	const dialogContent = useMemo(() => {
		if (props.columnId) {
			if (props.fieldType === ColumnType.Numerator) {
				return <NumeratorConfiguration columnId={props.columnId} />;
			} else {
				return <FieldConfiguration columnId={props.columnId} />;
			}
		}
		if (step === ConfigurationStep.InitialLookupType) {
			return props.fieldType === ColumnType.Lookup ? (
				<ChoiceLookupType fieldType={props.fieldType} size="small" />
			) : (
				<FieldConfiguration fieldType={props.fieldType} />
			);
		} else if (step === ConfigurationStep.MainSettings) {
			if (props.fieldType === ColumnType.Numerator) {
				return <NumeratorConfiguration />;
			} else {
				return <FieldConfiguration fieldType={props.fieldType} />;
			}
		}

		return null;
	}, [step, props]);

	const titleModal = useMemo(() => {
		switch (props.fieldType) {
			case ColumnType.Numerator: {
				return "Нумератор";
			}
			case ColumnType.String: {
				return "Текстовое поле";
			}
			case ColumnType.DateTime: {
				return "Поле с датой и временем";
			}
			case ColumnType.Date: {
				return "Поле с датой";
			}
			case ColumnType.Time: {
				return "Поле с временем";
			}
			case ColumnType.Lookup: {
				return "Справочное поле";
			}
			case ColumnType.Boolean: {
				return "Логическое поле";
			}
			case ColumnType.Integer: {
				return "Поле с целым числом";
			}
			case ColumnType.Decimal: {
				return "Поле с дробным числом";
			}
		}
	}, [props.fieldType]);

	const loadingClasses = classNames(styles.modalOverlay, {
		[`${styles.modalOverlayVisible}`]: fieldConfigurationStore.fieldIsLoading
	});

	return (
		<div ref={popupRef} className={styles.fieldConfigurationModal}>
			<div className={loadingClasses} style={style}>
				<svg className={styles.circleLoader} viewBox="0 0 50 50">
					<circle className={styles.circle} cx="25" cy="25" r="20" />
				</svg>
			</div>
			<div className={styles.headerModal}>
				<span className={styles.titleModal}>{titleModal}</span>
				<CloseMaxi className={styles.closeButton} onClick={handleClose} />
			</div>
			<div className={styles.dialogBody}>{dialogContent}</div>
			{dialogFooterButtons}
		</div>
	);
});
