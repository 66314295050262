import { observer } from "mobx-react-lite";
import { useCallback, useMemo, useState } from "react";

import { businessRuleEditorStore } from "pages/section-wizzard/pages/business-rules/business-rule-editor-store";

import { Button, ButtonGroup, Icon } from "sale-bridge-ui-kit";

import styles from "./business-rule-record-header.module.scss";

export const BusinessRuleRecordHeader = observer(() => {
	const variant = useMemo(() => (businessRuleEditorStore.isNew ? "create" : "edit"), [businessRuleEditorStore.isNew]);

	const handleClickSave = useCallback(() => {
		businessRuleEditorStore.saveBusinessRuleToConfig();
	}, []);

	const handleCloseEditor = useCallback(() => {
		//TODO notification
		businessRuleEditorStore.resetConfiguration();
	}, []);

	const enableButton = useMemo(
		() => (
			<Button
				text={businessRuleEditorStore.businessRule?.enabled ? "Отключить" : "Включить"}
				size="small"
				variant="default"
				leftIcon={businessRuleEditorStore.businessRule?.enabled ? "ToggleOff" : "ToggleOn"}
				iconSize="16"
				border
				link={false}
				loading={false}
				onClick={() => businessRuleEditorStore.setEnabledBusinessRule(!businessRuleEditorStore.businessRule?.enabled)}
			/>
		),
		[businessRuleEditorStore.businessRule?.enabled]
	);

	return (
		<div className={styles.headerWrap}>
			<div className={styles.level}>
				<div className={styles.left}>
					<div className={styles.titleBlock}>
						<span className={styles.sectionTitle}>Бизнес-правила</span>
						<Icon name="ChevronRight" size="small" />
						<span className={styles.nameTitle}>{businessRuleEditorStore.businessRule?.ruleName}</span>
					</div>
				</div>
				{variant === "edit" && <div className={styles.right}>{enableButton}</div>}
			</div>
			<div className={styles.level}>
				<div className={styles.left}>
					<ButtonGroup
						children={
							<>
								<Button
									text="Закрыть"
									size="small"
									variant="default"
									leftIcon="Close"
									border
									link={false}
									loading={false}
									onClick={handleCloseEditor}
								/>
								<Button
									text="Сохранить"
									size="small"
									variant="success"
									leftIcon="Save"
									border
									link={false}
									loading={false}
									onClick={handleClickSave}
								/>
							</>
						}
					/>
				</div>
				<div className={styles.right}>
					{variant === "create" ? (
						enableButton
					) : (
						<ButtonGroup
							children={
								<>
									<Button
										text="Восстановить"
										size="small"
										variant="default"
										leftIcon="Undo"
										border
										link={false}
										loading={false}
										onClick={() => businessRuleEditorStore.setOldVariantBusinessRule()}
									/>
									<Button
										text="Удалить"
										size="small"
										variant="default"
										leftIcon="Trash"
										iconSize="16"
										border
										link={false}
										loading={false}
										onClick={() => businessRuleEditorStore.deleteBusinessRuleFromConfig()}
									/>
								</>
							}
						/>
					)}
				</div>
			</div>
		</div>
	);
});
