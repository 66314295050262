import { NavLink } from "react-router-dom";

import styles from "./header.module.scss";

export function Header(props: { header: string, schema: string, id: string }) {

    const toPath = location.pathname === `/singlePage/${props.schema}` ? `${props.id}` : `/singlePage/${props.schema?.toLocaleLowerCase()}/${props.id}`;

    return (
        <div className={styles.wrapHeader}>
            <div className={styles.header}>
                <NavLink className={styles.linkColumn} to={toPath}>{props.header?.length > 0 ? props.header : 'Название записи'}</NavLink>
            </div>
            <div className={styles.divider} />
        </div>
    );

};

