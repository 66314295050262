import React, { useMemo } from 'react';

import { Button, ButtonStyle, Modal } from '../../../components'

import styles from './warning-message.module.css';
import dialogStyles from '../dialog.module.css';


interface IWarningMessageProps extends React.HTMLAttributes<HTMLElement> {
    isOpen: boolean;
    onBackClick: () => void,
    valueReturn?: string,
    value: string
}

function WarningMessage(props: IWarningMessageProps) {

    const caption = useMemo(() => {
        return props.valueReturn == null ? 'Понятно' : props.valueReturn
    }, [props.valueReturn])

    if (!props.isOpen) {
        return null;
    }

    return (
        <Modal>
            <div className={styles.backdrop} />
            <div className={styles.dialog} style={props.style}>
                <div className={styles.header}>
                    <span className={styles.title}>Не удалось удалить элемент</span>
                </div>
                <div className={styles.dialogBody}>
                    {props.value}
                </div>
                <div className={dialogStyles.dialogFooter} style={{ marginTop: `12px` }}>
                    <Button caption={caption} onClick={props.onBackClick} style={ButtonStyle.Primary} />

                </div>

            </div>
        </Modal>
    );
}

export default WarningMessage;