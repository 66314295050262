const getInputNumbersValue = (value: string) => {
    // return only numbers
    return value.replace(/\D/g, '');
};

export default function GetFormattedPhone(phone: string, selectionStart?: number | null) {
    // let formattedValue = "+" + phone;
    // formattedValue = formattedValue.substring(0, 2) + " (" + formattedValue.substring(2, 5) + ") " +
    //     formattedValue.substring(5, 8) + "-" + formattedValue.substring(8, 10) + "-" + formattedValue.substring(10)

    let inputNumbersValue = (phone === undefined || phone === null) ? "" : getInputNumbersValue(phone);
    let formattedValue = "";

    if (!inputNumbersValue) {
        return (formattedValue = "");
    }

    if (selectionStart && phone.length !== selectionStart) {
        return;
    }

    if (["7", "8", "9"].indexOf(inputNumbersValue[0]) > -1) {
        // Russian phone number
        if (inputNumbersValue[0] === "9") {
            inputNumbersValue = "7" + inputNumbersValue;
        }

        const firstSymbols = inputNumbersValue[0] === "8" ? "+7" : "+7";
        formattedValue = firstSymbols + " ";

        if (inputNumbersValue.length > 1) {
            formattedValue += "(" + inputNumbersValue.substring(1, 4);
        }
        if (inputNumbersValue.length >= 5) {
            formattedValue += ") " + inputNumbersValue.substring(4, 7);
        }
        if (inputNumbersValue.length >= 8) {
            formattedValue += "-" + inputNumbersValue.substring(7, 9);
        }
        if (inputNumbersValue.length >= 10) {
            formattedValue += "-" + inputNumbersValue.substring(9, 11);
        }
    }
    else {
        // Not Russian phone number
        formattedValue = "+" + inputNumbersValue.substring(0, 16);
    }

    return formattedValue;
}