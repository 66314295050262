import { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";

import { lowerCase } from "lodash";
import { UpFirst } from "shared";

import { dispatcher } from "store";
import detailConfigurationStore, { DetailStoreField } from "../detail-configuration-store";
import { validateRequired, validateSchema } from "entities/Validation";

import { Field, Button } from "sale-bridge-ui-kit";

import { Item } from "types";
import { FieldConfig } from "types/entity";

import { CloseMaxi } from "shared";

import styles from "../detail-configuration.module.scss";

export const NewObjectConfiguration = observer((props: { close: () => void; closeAll: () => void; onSave: () => void }) => {
	const [searchValue, setSearchValue] = useState("");
	useEffect(() => {
		const loadLookups = async () => {
			await detailConfigurationStore.loadAllObjects();
		};
		loadLookups();
		detailConfigurationStore.setCurrentSection();
		detailConfigurationStore.setNames();
	}, []);

	const sectionWizzard = useMemo(() => {
		return dispatcher.entity.get()?.entity.sectionWizzard;
	}, [dispatcher.entity.get()?.entity.sectionWizzard]);

	const tabsConfig = useMemo(() => {
		return sectionWizzard?.reactorConfig.tabs.tabsConfig ?? [];
	}, [sectionWizzard?.reactorConfig.tabs.tabsConfig.map((tab) => tab)]);

	const currentTabIndex = useMemo(() => {
		return sectionWizzard?.reactorConfig.tabs.currentTab ?? 0;
	}, [sectionWizzard?.reactorConfig.tabs.currentTab]);

	const getIdColumnConfig = useMemo(() => {
		let idConfig: FieldConfig | undefined;
		tabsConfig.forEach((tab) => {
			const finded = tab.grid.items.find((item) => item.fieldConfig?.columnName === "Id");
			if (finded) {
				idConfig = finded.fieldConfig;
				return;
			}
		});
		return idConfig;
	}, [tabsConfig, currentTabIndex]);

	const handleCancel = useCallback(() => {
		if (detailConfigurationStore.hasChanges) {
			props.close();
		} else {
			props.closeAll();
		}
		detailConfigurationStore.resetNewObjectConfiguration();
	}, [props.close, props.closeAll]);

	const handleSave = useCallback(async () => {
		if (getIdColumnConfig) {
			const column: Item = {
				id: getIdColumnConfig.columnId as string,
				name: getIdColumnConfig.columnName,
				displayValue: getIdColumnConfig.columnTitle
			};
			detailConfigurationStore.saveNewObject(column);
			props.close();
			if (!(await detailConfigurationStore.isDetailSection())) {
				detailConfigurationStore.setValue(DetailStoreField.isVisibleFieldSettingsButton, true);
			}
			props.onSave();
		}
	}, [props.close, tabsConfig, getIdColumnConfig, props.onSave]);

	const dialogFooterButtons = useMemo(() => {
		return (
			<>
				<Button text="Отменить" size="small" variant="backless" border link={false} loading={false} onClick={handleCancel} />
				<Button
					text="Сохранить"
					size="small"
					variant={detailConfigurationStore.isDisabledSaveNewObjButton ? "disabled" : "primary"}
					border
					link={false}
					loading={false}
					onClick={handleSave}
				/>
			</>
		);
	}, [detailConfigurationStore.isDisabledSaveNewObjButton]);

	const handleObjectChange = useCallback((value: string) => {
		detailConfigurationStore.setValue(DetailStoreField.newObjectTitle, value);
		validateRequired(detailConfigurationStore.newObjectTitle, detailConfigurationStore.validation.newObjectTitle);
		detailConfigurationStore.validNewObjectDetail();
	}, []);

	const handleDetailTitleChange = useCallback((value: string) => {
		detailConfigurationStore.setValue(DetailStoreField.newDetailTitle, value);
		validateRequired(detailConfigurationStore.newDetailTitle, detailConfigurationStore.validation.newDetailTitle);
		detailConfigurationStore.validNewObjectDetail();
	}, []);

	const handleDetailNameChange = useCallback((value: string) => {
		detailConfigurationStore.setValue(DetailStoreField.newDetailName, UpFirst(value));
		validateSchema(detailConfigurationStore.newDetailName, detailConfigurationStore.validation.newDetailName);
		detailConfigurationStore.validNewObjectDetail();
	}, []);

	const handleObjectNameChange = useCallback((value: string) => {
		detailConfigurationStore.setValue(DetailStoreField.newObjectName, UpFirst(value));
		validateSchema(detailConfigurationStore.newObjectName, detailConfigurationStore.validation.newObjectName);
		detailConfigurationStore.validNewObjectDetail();
	}, []);

	const mainContent = useMemo(() => {
		return (
			<div className={styles.fieldsBlock}>
				<Field
					type="text"
					size="small"
					label="Название объекта"
					labelPosition="vertical"
					textVariant="outlined"
					// placeholder=""
					info="Название новой таблицы для детали"
					isRequired
					value={detailConfigurationStore.newObjectTitle}
					counter={50}
					isClearing
					onChange={handleObjectChange}
					alert={detailConfigurationStore.validation.newObjectTitle.isInvalid ? "error" : undefined}
				/>
				<Field
					type="text"
					size="small"
					label="Системное название объекта"
					labelPosition="vertical"
					textVariant="outlined"
					placeholder="Название объекта в базе данных"
					info="Название, которое будет отображаться в базе данных"
					isRequired
					value={detailConfigurationStore.newObjectName}
					counter={50}
					isClearing
					onChange={handleObjectNameChange}
					alert={detailConfigurationStore.validation.newObjectName.isInvalid ? "error" : undefined}
				/>
				<Field
					type="text"
					size="small"
					label="Название детали"
					labelPosition="vertical"
					textVariant="outlined"
					placeholder="Название детали на странице"
					info="Название детали, которое будет отображаться на странице записи"
					isRequired
					value={detailConfigurationStore.newDetailTitle}
					counter={50}
					isClearing
					onChange={handleDetailTitleChange}
					alert={detailConfigurationStore.validation.newDetailTitle.isInvalid ? "error" : undefined}
				/>
				<Field
					type="text"
					size="small"
					label="Системное название детали"
					labelPosition="vertical"
					textVariant="outlined"
					placeholder="Название таблицы детали в базе данных"
					info="Название, которое будет отображаться в базе данных"
					isRequired
					value={detailConfigurationStore.newDetailName}
					counter={50}
					isClearing
					onChange={handleDetailNameChange}
					alert={detailConfigurationStore.validation.newDetailName.isInvalid ? "error" : undefined}
				/>
			</div>
		);
	}, [
		detailConfigurationStore.newObjectTitle,
		detailConfigurationStore.validation.newObjectTitle.isInvalid,
		detailConfigurationStore.newDetailTitle,
		detailConfigurationStore.validation.newDetailTitle.isInvalid,
		detailConfigurationStore.newDetailName,
		detailConfigurationStore.validation.newDetailName.isInvalid,
		detailConfigurationStore.newObjectName,
		detailConfigurationStore.validation.newObjectName.isInvalid
	]);

	const handleLookupChange = useCallback((value: Item | null) => {
		detailConfigurationStore.setValue(DetailStoreField.linkWithLookup, value);
		validateRequired(detailConfigurationStore.linkWithLookup, detailConfigurationStore.validation.linkWithLookup, true);
		detailConfigurationStore.validNewObjectDetail();
	}, []);

	const handleLookupColumnTitleChange = useCallback((value: string) => {
		detailConfigurationStore.setValue(DetailStoreField.newLookupColumnTitle, value);
		validateRequired(detailConfigurationStore.newLookupColumnTitle, detailConfigurationStore.validation.newLookupColumnTitle);
		detailConfigurationStore.validNewObjectDetail();
	}, []);

	const handleLookupColumnNameChange = useCallback((value: string) => {
		detailConfigurationStore.setValue(DetailStoreField.newLookupColumnName, UpFirst(value));
		validateSchema(detailConfigurationStore.newLookupColumnName, detailConfigurationStore.validation.newLookupColumnName);
		detailConfigurationStore.validNewObjectDetail();
	}, []);

	const lookupItems = useMemo(() => {
		const items: Item[] = [];
		detailConfigurationStore.lookupList.forEach((item: any) => {
			const displayValue = lowerCase(item.displayValue);
			if (displayValue.includes(lowerCase(searchValue))) items.push(item);
		});
		return [
			{
				layout: <></>,
				items: items.map((item) => (
					<div className={styles.selectItem} onClick={() => handleLookupChange(item)}>
						<span>{item.displayValue}</span>
					</div>
				))
			}
		];
	}, [toJS(detailConfigurationStore.lookupList), searchValue]);

	const linkWithLookup = useMemo(() => {
		return (
			<div className={styles.columnsDetailBlock}>
				<span className={styles.sectionTitle}>Как связать деталь с записью</span>
				<Field
					type="select"
					size="small"
					label="Связать по справочнику"
					labelPosition="vertical"
					textVariant="outlined"
					info="Таблица, с которой будет связана новая деталь"
					value={detailConfigurationStore.linkWithLookup?.displayValue ?? ""}
					placeholder="Значение поля"
					items={lookupItems}
					onChange={() => {}}
					isRequired
					alert={detailConfigurationStore.validation.linkWithLookup.isInvalid ? "error" : undefined}
					searchValue={searchValue}
					onChangeSearchValue={setSearchValue}
				/>
				<Field
					type="text"
					size="small"
					label="Заголовок справочной колонки"
					labelPosition="vertical"
					textVariant="outlined"
					placeholder="Значение поля"
					info="Название справочной колонки, которое будет отображаться на странице записи"
					isRequired
					value={detailConfigurationStore.newLookupColumnTitle}
					counter={50}
					isClearing
					onChange={handleLookupColumnTitleChange}
					alert={detailConfigurationStore.validation.newLookupColumnTitle.isInvalid ? "error" : undefined}
				/>
				<Field
					type="text"
					size="small"
					label="Системное название справочной колонки"
					labelPosition="vertical"
					textVariant="outlined"
					placeholder="Значение поля"
					info="Название, которое будет отображаться в базе данных"
					isRequired
					value={detailConfigurationStore.newLookupColumnName}
					counter={50}
					isClearing
					onChange={handleLookupColumnNameChange}
					alert={detailConfigurationStore.validation.newLookupColumnName.isInvalid ? "error" : undefined}
				/>
			</div>
		);
	}, [
		toJS(lookupItems),
		detailConfigurationStore.linkWithLookup,
		detailConfigurationStore.validation.linkWithLookup.isInvalid,
		detailConfigurationStore.newLookupColumnName,
		detailConfigurationStore.validation.newLookupColumnName.isInvalid,
		detailConfigurationStore.newLookupColumnTitle,
		detailConfigurationStore.validation.newLookupColumnTitle.isInvalid,
		searchValue
	]);

	return (
		<div className={styles.dialog}>
			<div className={styles.headerModal}>
				<span className={styles.titleModal}>Настройки новой детали</span>
				<CloseMaxi className={styles.closeButton} onClick={handleCancel} />
			</div>
			<div className={styles.dialogBody}>
				{mainContent}
				{linkWithLookup}
			</div>
			<div className={styles.dialogFooter}>
				<div className={styles.dialogFooterDivider} />
				<div className={styles.footerButtonsBlock}>
					<div className={styles.rightFooterButtonsBlock}>{dialogFooterButtons}</div>
				</div>
			</div>
		</div>
	);
});
