import { observer } from "mobx-react-lite";
import classNames from "classnames";

import { Button, ButtonStyle } from "components";
import { Hint, Icon } from "sale-bridge-ui-kit";

import TypeIcon from "features/type-icon/type-icon";

import { Close } from "shared";

import styles from "../card-designer/card-designer.module.scss";

type Props = {
	type: string | undefined;
	columnTitle?: string;
	isRequired?: boolean;
	fieldId?: string;
	className?: string;
	closeField: (elementId: string) => void;
};
/**
 * @description вспомогательное поле
 */
export const AdditionalFieldForCard = observer((props: Props) => {
	const fieldWrapper = classNames(styles.fieldWrapper, {
		[`${props.className}`]: props.className
	});
	const deleteButtonClasses = classNames(`${styles.deleteButton} `);

	return (
		<div className={fieldWrapper}>
			<Icon name="Draggable" size="16" className={styles.dragIcon} />
			<TypeIcon type={props.type} />
			<span className={styles.fieldCaptionFilled}>
				{props.isRequired ? <span className={styles.star}>* </span> : <></>}
				{props.columnTitle ?? ""}
			</span>

			<Hint hintBody="Убрать" startPosition="top">
				<Button
					style={ButtonStyle.Icon}
					firstIcon={<Close />}
					className={deleteButtonClasses}
					onMouseDown={() => {
						if (props.fieldId) {
							props.closeField(props.fieldId);
						}
					}}
				/>
			</Hint>
		</div>
	);
});

/**
 * @description залоченное поле
 */
export const DragFieldBlock = observer((props: { type: string; columnTitle: string }) => {
	return (
		<div className={styles.fieldWrapper} style={{ cursor: "default" }}>
			<TypeIcon type={props.type} />
			<span className={styles.fieldCaption}>
				<span className={styles.star}>* </span>
				{props.columnTitle ?? ""}
			</span>
			<Icon name="Locker" size="small" className={styles.lockerIcon} />
		</div>
	);
});
