import { useCallback, useMemo, useState } from 'react'
import { v4 } from 'uuid';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

import { modalController } from 'features/modals';
import fieldConfigurationStore from '../../../../../field-configuration-store';

import { Button, ButtonDropdown } from 'sale-bridge-ui-kit';
import { MaskLabel } from '../mask-label/mask-label';
import { MaskSettingsPopup } from '../new-mask-settings-popup/edit-mask-settings-popup';
import { EditNumeratorPopup } from '../../../edit-numerator-popup';
import { CancelModal } from '../new-mask-settings-popup';
import { DeleteMaskModal } from '../delete-mask-modal/delete-mask-modal';

import { MaskState, NumeratorMaskItem } from 'types/entity';
import { datesPreview } from '../new-mask-settings-popup/data';
import { StandartItemGroup } from 'sale-bridge-ui-kit/dist/components/button-dropdown/button-dropdown';
import { copyMaskAction, deleteAction, editMaskAction, setDefaultAction } from '../data';

import styles from '../../masks.module.scss'

export const MaskElement = observer((props: { item: NumeratorMaskItem, isNewNumerator?: boolean }) => {
    const [modalId] = useState<string>(v4());
    const [warningModalId] = useState<string>(v4());

    const previewValuesOfMasks: string = useMemo(() => {
        const names = props.item?.parameters.map(parameter => parameter.columnValueParameter ?
            parameter.columnValueParameter!.isLookup ? parameter.columnValueParameter!.lookupTableColumnTitle ?
                `[${parameter.columnValueParameter!.columnTitle}.${parameter.columnValueParameter!.lookupTableColumnTitle}]`
                : ""
                : `[${parameter.columnValueParameter!.columnTitle}]`
            : parameter.numberParameter?.startValue ?? parameter.textParameter?.text ?? datesPreview[parameter.type]
        ) ?? [];
        return names.join("");
    }, [toJS(props.item?.parameters)]);

    const closeCancelModalConfirm = useCallback(() => {
        modalController.modalRemove(warningModalId);
    }, [warningModalId]);

    const closeEditMaskConfirm = useCallback((isNeedDelete?: boolean, e?: React.MouseEvent<HTMLElement, MouseEvent> | React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        e?.stopPropagation();
        if (isNeedDelete) {
            const hasChanges = fieldConfigurationStore.numeratorConfig.isMaskChanged(props.item.id);
            if (hasChanges) {
                modalController.popupAdd({
                    id: warningModalId,
                    layout: <CancelModal closeConfirm={closeCancelModalConfirm} onCancel={() => {
                        fieldConfigurationStore.numeratorConfig.resetMaskSettings();
                        modalController.modalRemove(modalId);
                    }}
                    />,
                    closeFunc: closeCancelModalConfirm
                });
                return;
            }
            fieldConfigurationStore.numeratorConfig.resetMaskSettings();
        }
        modalController.modalRemove(modalId);
    }, []);

    const closeConfirm = useCallback((e?: React.MouseEvent<HTMLElement, MouseEvent> | React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        e?.stopPropagation();
        modalController.modalRemove(modalId);
    }, []);

    const handleSetDefault = useCallback(() => {
        if (props.item.maskState === MaskState.Default) {
            return;
        }
        fieldConfigurationStore.numeratorConfig.setDefaultMaskState(props.item.id);
    }, [props.item.id]);

    const handleDuplicateMask = useCallback(() => {
        const newMaskId = v4();
        const defaultMask = fieldConfigurationStore.numeratorConfig.getDefaultMask(newMaskId);
        const newMask: NumeratorMaskItem = { ...defaultMask, parameters: structuredClone(toJS(props.item.parameters)) };
        fieldConfigurationStore.numeratorConfig.addMask(newMask);
    }, [modalId]);

    const handleEditMask = useCallback((event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (event && (event.target as HTMLElement).closest(`#buttonDropdownId`)) {
            return;
        }
        //TODO возможно всплывёт и понадобится, пока что не актуально, т.к. в ButtonDropdown не передаётся больше id элемента
        // const node = document.getElementById(props.item.id);
        // if (node && node.contains(event?.target as Node)) return;
        modalController.popupAdd({
            id: modalId,
            layout:
                <EditNumeratorPopup close={() => { closeEditMaskConfirm(true) }} >
                    <MaskSettingsPopup maskId={props.item.id} close={(isNeedDelete) => { closeEditMaskConfirm(isNeedDelete) }} />
                </EditNumeratorPopup>,
            closeFunc: (e) => { closeEditMaskConfirm(true, e) }
        });
    }, [toJS(props.item), modalId]);

    const handleDeleteMask = useCallback(() => {
        fieldConfigurationStore.numeratorConfig.deleteMask(props.item.id);
        closeEditMaskConfirm();
    }, [props.item.id]);

    const handleDeleteMaskPopupAdd = useCallback(() => {
        if (props.item.maskState === MaskState.Default) {
            return;
        }
        modalController.popupAdd({
            id: modalId,
            layout: <DeleteMaskModal item={props.item} deleteMask={handleDeleteMask} backClick={closeConfirm}/>,
            closeFunc: closeConfirm
        });
    }, [modalId, props.item, props.item.maskState]);

    const kebabItems: Array<StandartItemGroup> = useMemo(() => [
        {
            header: '',
            items: [
                setDefaultAction(props.item.maskState),
                copyMaskAction,
                editMaskAction
            ]
        },
        {
            header: '',
            items: [deleteAction(props.item.maskState)]
        }
    ], [props.item.maskState]);

    const handleKebabClick = useCallback((id: string) => {
        switch (id) {
            case 'setDefault': {
                handleSetDefault();
                return;
            }
            case 'copy': {
                handleDuplicateMask();
                return;
            }
            case 'edit': {
                handleEditMask();
                return;
            }
            case 'delete': {
                handleDeleteMaskPopupAdd();
                return;
            }
        }
    }, []);

    return <div className={styles.maskElement} onClick={handleEditMask}>
        <div className={styles.bodyMaskElement} >
            <MaskLabel type={props.item.maskState} />
            <div className={styles.nameAndParameters}>
                <span className={styles.maskName}>{props.item.name}</span>
                <span className={styles.maskParams}>{previewValuesOfMasks}</span>
                {!props.isNewNumerator && <span className={styles.maskParams}>Занято: 80% - тестовые данные</span>}
            </div>
        </div>
        <div id="buttonDropdownId">
            <ButtonDropdown
                size='small'
                position='right-down'
                standartItems={kebabItems}
                onClickStandartItem={handleKebabClick}
                childrenButton={
                    <Button
                        text=''
                        size='small'
                        variant='default'
                        border={false}
                        link={false}
                        loading={false}
                        leftIcon='KebabVertical'
                        onClick={() => { }}
                    />
                }
            />
        </div>
    </div>
});

