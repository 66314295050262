import { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import { lowerCase } from "lodash";

import detailConfigurationStore, { DetailStoreField } from "../detail-configuration-store";
import { validateRequired } from "entities/Validation";

import { Field } from "sale-bridge-ui-kit";

import { Item } from "types";

import styles from "../detail-configuration.module.scss";

export const DetailLinkField = observer((props: {}) => {
    const [searchValue, setSearchValue] = useState('');
	const [searchValueColumn, setSearchValueColumn] = useState('');
    //TODO метод понадобится для реализации поиска среди элементов
    const handleSectionColumnsLoad = useCallback(async (value: string | null) => {
        try {
            await detailConfigurationStore.loadSectionColumns();
            if (detailConfigurationStore.columnList) {
                const items: Item[] = [];
                if (value) {
                    detailConfigurationStore.columnList.forEach((item: any) => {
                        const displayValue = lowerCase(item.displayValue);
                        if (displayValue.includes(lowerCase(value)))
                            items.push(item);
                    });
                    detailConfigurationStore.setValue(DetailStoreField.columnList, items);
                    return items.length
                }
                else return detailConfigurationStore.columnList.length
            }
            else return -1
        }
        catch (error) {
            console.error(error)
        }
    }, [detailConfigurationStore.columnList]);

    const handleDetailColumnChange = useCallback((value: Item | null) => {
        detailConfigurationStore.setValue(DetailStoreField.detailColumn, value);
        validateRequired(detailConfigurationStore.detailColumn, detailConfigurationStore.validation.detailColumn);
        detailConfigurationStore.validDetail();
    }, []);

    const handleSectionColumnChange = useCallback((value: Item | null) => {
        detailConfigurationStore.setValue(DetailStoreField.entityColumn, value);
        validateRequired(detailConfigurationStore.entityColumn, detailConfigurationStore.validation.entityColumn);
        detailConfigurationStore.validDetail();
    }, []);

    const detailColumnItems = useMemo(() => {
        return [
            {
                layout: <></>,
                items: detailConfigurationStore.columnDetailList.map(item =>
                    <div className={styles.selectItem} onClick={() => handleDetailColumnChange(item)}>
                        <span>{item.displayValue}</span>
                    </div>
                )
            }
        ]
    }, [toJS(detailConfigurationStore.columnDetailList)]);

    const sectionColumnItems = useMemo(() => {
        return [
            {
                layout: <></>,
                items: detailConfigurationStore.columnList.map(item =>
                    <div className={styles.selectItem} onClick={() => handleSectionColumnChange(item)}>
                        <span>{item.displayValue}</span>
                    </div>
                )
            }
        ]
    }, [toJS(detailConfigurationStore.columnList)]);

    return (
        <div className={styles.columnsDetailBlock}>
            <span className={styles.sectionTitle}>Какие записи показать на странице?</span>
            <Field
                type='select'
                size='small'
                label='У которых колонка детали'
                labelPosition="vertical"
                textVariant='outlined'
                info='Список колонок в таблице детали. Выберите ту колонку, по которой нужно связать деталь и раздел'
                value={detailConfigurationStore.detailColumn?.displayValue ?? ''}
                items={detailColumnItems}
                onChange={() => { }}
                isRequired
                alert={detailConfigurationStore.validation.detailColumn.isInvalid ? 'error' : undefined}
                searchValue={searchValueColumn} //ToDo чтобы заработал поиск нужно в items сделать filter по searchValue
				onChangeSearchValue={setSearchValueColumn}
            />
            <Field
                type='select'
                size='small'
                label='Равна колонке страницы'
                labelPosition="vertical"
                textVariant='outlined'
                value={detailConfigurationStore.entityColumn?.displayValue ?? ''}
                items={sectionColumnItems}
                onChange={() => { }}
                isRequired
                alert={detailConfigurationStore.validation.entityColumn.isInvalid ? 'error' : undefined}
                searchValue={searchValue} //ToDo чтобы заработал поиск нужно в items сделать filter по searchValue
				onChangeSearchValue={setSearchValue}
            />
        </div>
    );
});
