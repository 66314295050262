import { observer } from "mobx-react";
import { useCallback } from "react";

import { CloseMaxi } from "shared";

import styles from "./disable-stage-model-confirm.module.scss";
import { Button } from "sale-bridge-ui-kit";

type DisableStageModelConfirmProps = {
	disable: () => void;
	close: () => void;
};

export const DisableStageModelConfirm = observer((props: DisableStageModelConfirmProps) => {
	const handleDisable = useCallback(() => {
		props.disable();
		props.close();
	}, [props.disable, props.close]);

	const handleCancel = useCallback(() => {
		props.close();
	}, [props.close]);

	return (
		<>
			<div className={styles.dialogHeader}>
				<span className={styles.dialogTitle}>Внимание</span>
				<CloseMaxi className={styles.dialogCloseButton} onClick={handleCancel} />
			</div>
			<div className={styles.dialogBody}>
				<span>
					Созданное по виртуальному справочнику приоритетов поле [Приоритет] уже добавлено в конструкторе в сетку полей во вкладке
					«Основная информация».
				</span>
				<span>
					В случае отключения стадийной модели это поле будет удалено и режим отображения «Доска Kanban» будет недоступен.
				</span>
				<span>Отключить стадийную модель для раздела?</span>
			</div>
			<div className={styles.dialogFooter}>
				<Button text="Отключить" size="small" variant="default" border link={false} loading={false} onClick={handleDisable} />
				<Button text="Отменить" size="small" variant="primary" border link={false} loading={false} onClick={handleCancel} />
			</div>
		</>
	);
});
