import { ColumnType } from "entities/ColumnType";
import { EntityColumnSpecialization } from "types/entity";

import styles from '../universal-grid/universal-grid.module.scss'

export const FormatValue = (value: any, type: string, specialization: EntityColumnSpecialization | null): JSX.Element => {
    let text: JSX.Element | null = null;
    let element: JSX.Element | null = null;
    switch (type) {
        case ColumnType.String:
        case ColumnType.Guid:
        case ColumnType.Integer:
        case ColumnType.Numerator:
            text = value;
            break;
        case ColumnType.DateTime:
            const dateTime = new Date(Date.parse(value));
            text = <>{dateTime.toLocaleString("ru-Ru", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "numeric"
            })}</>;
            break;
        case ColumnType.Date:
            const date = new Date(Date.parse(value));
            text = <>{date.toLocaleDateString("ru-Ru")}</>;
            break;
        case ColumnType.Time:
            const time = value.toString().split(":");
            text = <>{`${time[0]}:${time[1]}`}</>;
            break;
        case ColumnType.Lookup:
            text = value.displayValue;
            break;
        case ColumnType.Double:
        case ColumnType.Decimal:
            text = <>{value.toLocaleString("ru-RU", { maximumFractionDigits: specialization?.properties["rounding"] })}</>;
            break;
        case ColumnType.Boolean:
            if ((value as boolean)) {
                text = <>Да</>;
            }
            else {
                text = <>Нет</>;
            }
            break;
    }
    return (
        <>
            {element !== null && element}
            {text !== null &&
                <span className={styles.value}>
                    {text}
                </span>
            }
        </>);
}