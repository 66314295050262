import { makeAutoObservable, runInAction } from "mobx";

import { api } from "shared";

import UserRole from "entities/userRole/UserRole";

class FunctionalRolesMainPanelStore {
	filteredUserStore: any[];
	orgRolesStore: any[];
	userRole: UserRole | null = null;
	dialogData: any[];

	LDAPsynchronize: boolean = false;
	LDAPelem: any;

	constructor() {
		makeAutoObservable(this);
		this.filteredUserStore = [];
		this.orgRolesStore = [];
		this.dialogData = [];
	}

	async setValue(value: any, fieldName: string) {
		Reflect.set(this, fieldName, value);
	}

	async setUsersById(id: string) {
		this.filteredUserStore = await this.getUsersByRoleId(id);
	}

	async getUsersByRoleId(id: string) {
		const request = await api.http.administration.usersByRole(id).get();
		if (request.data.success) {
			return request.data.data;
		}
	}
	async loadOrgRoles(id: string) {
		const request = await api.http.administration.getAllOrgRoleByFuncRole(id).get();
		if (request.data.success) {
			this.orgRolesStore = request.data.data;
		}
	}
	async addUserInRole(id: string, roles: string[]) {
		let data = {
			userId: id,
			roleIds: roles
		};
		const request = await api.http.administration.includeUserInPole().post(data);
		if (request.data.success) {
			this.setUsersById(this.userRole?.data?.id!);
			return true;
		} else return false;
	}
	async linkOrgAndFuncRole(orgRoleId: string, funcRoleId: string) {
		const request = await api.http.administration.linkOrgAndFuncRole(orgRoleId, funcRoleId).post(null);
		if (request.data.success) {
			this.loadOrgRoles(funcRoleId);
			return true;
		} else return false;
	}
	async linkFuncAndOrgRoles(funcRoleId: string, orgRoleIds: string[]) {
		if (orgRoleIds.length > 0) {
			let data = {
				funcRoleId: funcRoleId,
				orgRoleIds: orgRoleIds
			};
			console.log(data);

			const request = await api.http.administration.linkOrgAndFuncRoles().post(data);
			if (request.data.success) {
				this.loadOrgRoles(funcRoleId);
				return true;
			} else return false;
		} else this.linkOrgAndFuncRole(orgRoleIds[0], funcRoleId);
	}
}

const functionalRolesMainPanelStore = new FunctionalRolesMainPanelStore();

export default functionalRolesMainPanelStore;
