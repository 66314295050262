import { App } from "app";
import Invite from "pages/invite/invite";
import Login from "pages/login/login";

export const privateRoutes = [
	{ path: "/", component: <App /> },
	{ path: "/*", component: <App /> }
];

export const publicRoutes = [
	{ path: "/login", component: <Login /> },
	{ path: "/*", component: <Login /> },
	{ path: "/invite/:id", component: <Invite /> }
];
