import { Icon } from "sale-bridge-ui-kit";

import { dispatcher, sessionStore } from "store";
import { singlePageSynchroiser } from "pages/single-page/single-page-synchroiser/single-page-synchroiser";

import styles from "../kanban-page.module.scss";

export const NotificationRecordWithSort = (props: { entityName: string | undefined }) => {

    function handleManualSort(entityName: string | undefined) {
        dispatcher.entity.setSort(null);
        singlePageSynchroiser.setSortInLocalStorage(null, dispatcher.entity.get()?.entityName);
        singlePageSynchroiser.applyFilter(entityName ?? "");
    };

    return <>
        <div className={styles.notificationText}>Чтобы ранжировать записи вручную, включите ручную сортировку
        </div>
        <div className={styles.verticalDivider} />
        <div className={styles.notificationCancel} onClick={() => handleManualSort(props.entityName)}>Включить</div>
        <div className={styles.verticalDivider} />
        <Icon name="Close" size="12" className={styles.notificationClose} />
    </>
};