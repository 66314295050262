import classNames from "classnames";
import { toJS } from "mobx";
import { useCallback, useEffect, useMemo, useState } from "react";
import { isUndefined } from "lodash";

import { Hint, Icon } from "sale-bridge-ui-kit";
import { MaskParameterBodyByType } from "./mask-parameter-body-by-type";

import { NumeratorMaskItem, NumeratorMaskSettingsParameter, NumeratorMaskSettingsParameterType } from "types/entity";
import { numeratorTypes } from "../data";

import { DragIcon } from "shared";

import styles from "../edit-mask-settings-popup.module.scss";

type MaskParameterProps = {
	parameter: NumeratorMaskSettingsParameter;
	idOfInvalidParameters: string[];
	isDraggable?: boolean;
	deleteParameter: (order: number) => void;
	onDragStart?: (e: React.DragEvent<HTMLDivElement>) => void;
	onDragOver?: (e: React.DragEvent<HTMLDivElement>) => void;
	onDragEnd?: (e: React.DragEvent<HTMLDivElement>) => void;
};

const MaskParameter = (props: MaskParameterProps) => {
	const [isOpened, setOpened] = useState<boolean>(false);

	const maskParameterClassName = classNames(styles.maskParameter, {
		[`${styles.maskParameterDragging}`]: props.isDraggable
	});

	const deleteClasses = classNames(styles.maskParameterButtonDefault, {
		[`${styles.maskParameterDeleteButton}`]: props.parameter.type !== NumeratorMaskSettingsParameterType.Number,
		[`${styles.maskParameterButtonDisable}`]: props.parameter.type === NumeratorMaskSettingsParameterType.Number
	});

	useEffect(() => {
		const isInvalid = !isUndefined(props.idOfInvalidParameters.find((invlidParam) => invlidParam === props.parameter.id));
		if (isInvalid && !isOpened) {
			setOpened(true);
		}
	}, [toJS(props.idOfInvalidParameters)]);

	const handleClickToHeader = useCallback(
		(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			e.stopPropagation();
			setOpened(!isOpened);
		},
		[isOpened]
	);

	const handleDelete = useCallback(
		(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			e.stopPropagation();
			if (props.parameter.type !== NumeratorMaskSettingsParameterType.Number) {
				props.deleteParameter(props.parameter.order);
			}
		},
		[props.parameter, props.deleteParameter]
	);

	const handleDragStart = useCallback(
		(e: React.DragEvent<HTMLDivElement>) => {
			if (isOpened) {
				setOpened(false);
			}
			props.onDragStart?.(e);
		},
		[isOpened]
	);

	return (
		<div
			className={maskParameterClassName}
			id={props.parameter.id}
			data-drag-index={props.parameter.order}
			draggable
			onDragStart={handleDragStart}
			onDragOver={props.onDragOver}
			onDragEnd={props.onDragEnd}
		>
			<div className={styles.parameterHeader} onClick={handleClickToHeader}>
				<DragIcon />
				<Icon name={isOpened ? "Dropdown" : "ChevronRight"} size="small" />
				<span className={styles.parameterTitle}>
					{`[${props.parameter.order}] ${numeratorTypes.find((item) => item.id === props.parameter.type)?.name ?? ""}`}
				</span>
				<div className={styles.maskParameterIconsContainer}>
					<div className={styles.maskParameterVerticalDivider} />
					<Hint hintBody="Удалить">
						<div className={deleteClasses} onClick={handleDelete}>
							<Icon name="Clear" size="small" />
						</div>
					</Hint>
				</div>
			</div>
			{isOpened && (
				<div className={styles.maskParameterBody}>
					<div className={styles.maskParameterBodyDivider} />
					<div className={styles.parameterFieldsBody}>
						<MaskParameterBodyByType item={props.parameter} />
					</div>
				</div>
			)}
		</div>
	);
};

export const MaskParameterContainer = (props: { mask: NumeratorMaskItem | undefined; idOfInvalidParameters: string[] }) => {
	const [dragState, setDragState] = useState<{
		draggedOrder: number;
		overOrder: number;
		overZone: string | null;
		placeholderOrder: number;
	}>({
		draggedOrder: -1,
		overOrder: -1,
		overZone: null,
		placeholderOrder: -1
	});
	const { draggedOrder, overOrder, overZone, placeholderOrder } = dragState;

	const parameters = useMemo(() => {
		const key = "order";
		const sorted = props.mask?.parameters.slice().sort((param1, param2) => (param1[key] > param2[key] ? 1 : -1));
		return sorted;
	}, [toJS(props.mask?.parameters)]);

	const deleteParameter = useCallback(
		(order: number) => {
			props.mask?.parameters?.splice(order - 1, 1);
			props.mask?.parameters?.filter((item) => item.order > order).forEach((parameter) => parameter.order--);
		},
		[toJS(props.mask?.parameters)]
	);

	const handleDragStart = useCallback(
		(e: React.DragEvent<HTMLDivElement>) => {
			const order = parseInt(e.currentTarget.dataset.dragIndex ?? "", 10);
			if (isNaN(order)) {
				return;
			}
			setDragState({ ...dragState, draggedOrder: order });
		},
		[dragState, toJS(parameters)]
	);

	const handleDragOver = useCallback(
		(e: React.DragEvent<HTMLDivElement>) => {
			const rect = e.currentTarget.getBoundingClientRect();
			const y = e.clientY - rect.top; // y position within the element.

			const newOverOrder = parseInt(e.currentTarget.dataset.dragIndex ?? "", 10);
			if (isNaN(newOverOrder)) {
				return;
			}
			const newOverZone = y <= rect.height / 2 ? "top" : "bottom";

			const newState = { ...dragState, overOrder: newOverOrder, overZone: newOverZone };
			let newPlaceholderOrder = newOverZone === "top" ? newOverOrder : newOverOrder + 1;

			// if placeholder is just before (==draggedIndex) or just after (===draggedindex + 1) there is not need to show it because we're not moving anything
			if (newPlaceholderOrder === draggedOrder || newPlaceholderOrder === draggedOrder + 1) {
				newPlaceholderOrder = -1;
			}

			const nonFonctionalConditionOnlyForDisplay = overOrder !== newOverOrder || overZone !== newOverZone;

			// only update if placeholderIndex hasChanged
			if (placeholderOrder !== newPlaceholderOrder || nonFonctionalConditionOnlyForDisplay) {
				newState.placeholderOrder = newPlaceholderOrder;
				setDragState({ ...newState });
			}
		},
		[dragState, toJS(parameters)]
	);

	const handleDragEnd = useCallback(
		(e: React.DragEvent<HTMLDivElement>) => {
			if (!parameters) {
				return;
			}
			// we know that much: no more dragged item, no more placeholder
			if (placeholderOrder !== -1) {
				const draggbleParameter = parameters.find((parameter) => parameter.id === e.currentTarget.id);
				if (!draggbleParameter) {
					return;
				}

				// mutate parameters, move item at dragged Order to placeholderOrder
				if (placeholderOrder > draggedOrder) {
					// inserting after so removing the elem first and shift insertion index by -1
					props.mask?.parameters.splice(draggedOrder - 1, 1);
					props.mask?.parameters.splice(placeholderOrder - 2, 0, draggbleParameter);
					props.mask?.parameters
						?.filter((item) => item.order < placeholderOrder && item.order > draggedOrder)
						.forEach((parameter) => parameter.order--);
					draggbleParameter.order = placeholderOrder - 1;
				} else {
					// inserting before, so do not shift
					props.mask?.parameters.splice(draggedOrder - 1, 1);
					props.mask?.parameters.splice(placeholderOrder - 1, 0, draggbleParameter);
					props.mask?.parameters
						?.filter((item) => item.order >= placeholderOrder && item.order < draggedOrder)
						.forEach((parameter) => parameter.order++);
					draggbleParameter.order = placeholderOrder;
				}
			}
			const updater = { draggedOrder: -1, placeholderOrder: -1, overOrder: -1, overZone: null };
			setDragState({ ...updater });
		},
		[dragState, toJS(parameters), toJS(props.mask?.parameters)]
	);

	const renderedRows = useMemo(() => {
		const parametersArray = parameters?.map((parameter) => (
			<MaskParameter
				key={parameter.id}
				parameter={parameter}
				idOfInvalidParameters={props.idOfInvalidParameters}
				isDraggable={parameter.order == draggedOrder}
				deleteParameter={deleteParameter}
				onDragStart={handleDragStart}
				onDragOver={handleDragOver}
				onDragEnd={handleDragEnd}
			/>
		));
		if (placeholderOrder !== -1) {
			parametersArray?.splice(placeholderOrder - 1, 0, <div className={styles.dragSeparator} />);
		}
		return parametersArray;
	}, [toJS(parameters), placeholderOrder]);

	return <div className={styles.maskParameterContainer}>{renderedRows}</div>;
};
