import { makeAutoObservable } from "mobx";
import { v4 } from "uuid";

import authStore from "AuthStore";
import { DirectorSimpleFilter, ValueType } from "app";
import { api } from "shared";
import { Entity, store } from "store";

import { EntityData } from "entities/EntityInfoDto";
import ResponseService from "entities/Response";
import { ComparisonType, DataValueType } from "entities/filter/IFilter";
import { ColumnTypeName } from "../data/column-type-name";
import { LowFirst } from "shared";
import { Item } from "types";
import { BasicEntity, ViewRole } from "types/entity";
import { SortDirection } from "entities/ISort";

export interface IEntity {
	value: string | boolean | Item | null | Date;
	// displayTitle: string;
	title: string;
	type: string;
	table: string;
}

class DetailEntitiesStore {
	entityName: string;
	filterColumn: string;
	filterValue: string;
	entity: Entity | null = null;
	entities: IEntity[][] | null = null;
	nextEntities: IEntity[][] | null = null;
	titles: string[] | null = null;
	propertyInfoMap: Map<string, any> = new Map<string, any>();
	error: boolean = false;
	newRow: IEntity[] = [];
	isSectionDetail: boolean = false;

	offset: number = 0;
	LIMIT: number = 10;

	constructor(entityName: string, filterValue: string, filterColumn: string) {
		this.entityName = entityName;
		this.filterValue = filterValue;
		this.filterColumn = filterColumn;
		makeAutoObservable(this);
	}

	get CanLoadMore(): boolean {
		return this.nextEntities !== null && this.nextEntities.length > 0;
	}

	get entitiesNotEmpty(): boolean {
		return this.entities != null && this.entities.length !== 0;
	}

	private async apiWrraper(funcApi: () => any): Promise<any | null> {
		const response: ResponseService = await funcApi();

		if (response.data.success) {
			return response.data.data;
		} else {
			this.error = true;
			return null;
		}
	}

	private loadEntityInfo() {
		return this.apiWrraper(() => {
			return api.http.entity.entityData().post({ entityName: this.entityName });
		});
	}

	private loadRecords(offset: number, columnNames: string[], dataValueType: DataValueType) {
		let value: ValueType = this.filterValue;

		if (dataValueType === DataValueType.Entity || dataValueType === DataValueType.Lookup) {
			value = { id: this.filterValue, name: "" };
		}

		return this.apiWrraper(() => {
			const data = {
				canbanColumn: null,
				columnNames: columnNames,
				entityName: this.entityName,
				filter: DirectorSimpleFilter.CreateAttribute(
					value,
					ComparisonType.Equal,
					{ Name: this.filterColumn /* + "Id"*/, Type: dataValueType },
					this.entityName
				),
				offset: offset,
				limit: this.LIMIT,
				sort: null
			};

			return api.http.entity.recordsListWithColumns().post(data);

			//TODO старый запрос, возможно пригодится
			// const data = {
			//     canbanColumn: null,
			//     entityName: this.entityName,
			//     filter: DirectorSimpleFilter.CreateAttribute(this.filterValue, ComparisonType.Equal, { Name: this.filterColumn + "Id", Type: DataValueType.Guid }, this.entityName),
			//     offset: offset,
			//     limit: this.LIMIT,
			//     sort: null
			// };

			// return entity.recordsList().post(data);
		});
	}

	private loadColumnsLength(columnType: DataValueType) {
		let value: ValueType = this.filterValue;

		if (columnType === DataValueType.Entity || columnType === DataValueType.Lookup) {
			value = { id: this.filterValue, name: "" };
		}
		return this.apiWrraper(() => {
			const data = {
				entityName: this.entityName,
				filter: DirectorSimpleFilter.CreateAttribute(
					value,
					ComparisonType.Equal,
					{ Name: this.filterColumn, Type: columnType },
					this.entityName
				)
			};

			//TODO старый запрос, возможно пригодится
			// const data = {
			//     entityName: this.entityName,
			//     filter: DirectorSimpleFilter.CreateAttribute(this.filterValue, ComparisonType.Equal, { Name: this.filterColumn + "Id", Type: DataValueType.Guid }, this.entityName),
			// };

			return api.http.entity.entityCount().post(data);
		});
	}

	private loadSectionViewPageSettings() {
		return this.apiWrraper(() => {
			const getViewPageSettings = {
				userId: authStore.userId,
				entityName: this.entityName,
				viewRole: ViewRole.Detail,
				detailContainerEntityId: store.currentEntityId
			};
			return api.http.sectionViewPageSettings.getSectionByUserAndEntity()?.post(getViewPageSettings);
		});
	}

	private async loadData(): Promise<DataLoadingInfo> {
		const sectionViewPageSettings = await this.loadSectionViewPageSettings();
		const entityData: EntityData | null = await this.loadEntityInfo();

		if (!sectionViewPageSettings || !entityData) {
			return {
				data: null,
				error: true
			};
		}

		const columnNames = sectionViewPageSettings?.columnSettings?.map((column: any) => column.columnName) ?? [];
		const columnTypeFilterColumn =
			entityData.columns.find((column) => column.columnName.toLowerCase() === this.filterColumn.toLowerCase())?.columnType ===
			"String"
				? "Text"
				: entityData.columns.find((column) => column.columnName.toLowerCase() === this.filterColumn.toLowerCase())?.columnType;

		if (!columnTypeFilterColumn) {
			return {
				data: null,
				error: true
			};
		}

		const enums = Object.keys(DataValueType).filter((type) => columnTypeFilterColumn === type);

		const dataValueType =
			/* this.entityName==="SectionForSDF" ? DataValueType.Guid : */ DataValueType[enums[0] as keyof typeof DataValueType];

		const records: Promise<any> = this.loadRecords(this.offset, columnNames, dataValueType);
		// const nextEntities: Promise<any> = this.loadRecords(this.offset + this.LIMIT, columnNames!);
		const columnsLength: Promise<any> = this.loadColumnsLength(DataValueType[enums[0] as keyof typeof DataValueType]);
		const data = await Promise.all([records /*, nextEntities*/, entityData, sectionViewPageSettings, columnsLength]);

		return {
			data: data,
			error: this.error
		};
	}
	private getColumnType(columnName: string, propertyInfo: Map<string, any>): string {
		return propertyInfo.get(columnName)!.columnType;
	}
	// private getColumnDisplayTitle(columnName: string, propertyInfo: Map<string, any>): string {
	//     return propertyInfo.get(columnName)!.columnTitle;
	// }
	private processing(entities: any[], property: string[], propertyInfo: Map<string, any>) {
		const result = [];

		for (let entity of entities) {
			const row: IEntity[] = [];
			row.push({ value: entity.id, title: "Id", /*displayTitle: 'Id', */ type: ColumnTypeName.Guid, table: this.entityName });
			for (let columnName of property) {
				const value = Reflect.get(entity, LowFirst(columnName)!);
				row.push({
					value: value,
					title: columnName,
					/* displayTitle: this.getColumnDisplayTitle(columnName, propertyInfo),*/ type: this.getColumnType(
						columnName,
						propertyInfo
					),
					table: propertyInfo.get(columnName).lookupTable ?? this.entityName
				});
			}
			result.push(row);
		}

		return result;
	}

	async load() {
		if (this.entityName) {
			const basicEntity: BasicEntity = {
				columns: [],
				viewColumn: null,
				rows: [],
				quality: 0,
				sort: { columnPath: "createdOn", direction: SortDirection.Descending },
				visibleColumns: [],
				display: [],
				filter: null,
				sectionWizzard: null,
				oldValueOfSectionWizzard: null,
				isCheckedAll: false,
				includedIds: [],
				excludedIds: [],
				countOfChecked: 0,
				filterTree: null,
				sectionViewPageSettings: null
			};

			const newEntity: Entity = {
				id: "",
				entityName: "",
				entityTitle: "",
				entity: basicEntity,
				isKanban: false,
				isNew: false
			};

			const loadInfo = await this.loadData();

			if (loadInfo.error) return;

			const [records, entityData, sectionViewPageSettings, columnsLength] = loadInfo.data;

			basicEntity.rows = records.records.map((i: any) => i);

			basicEntity.quality = columnsLength;

			newEntity.id = entityData.id;
			newEntity.entityName = entityData.entityName;
			newEntity.entityTitle = entityData.entityTitle;
			basicEntity.columns = entityData.columns.map((i: any) => i);
			basicEntity.viewColumn = entityData.viewColumn;

			basicEntity.sectionViewPageSettings = {
				...sectionViewPageSettings,
				viewRole: ViewRole.Detail,
				detailContainerEntityId: store.currentEntityId
			};
			basicEntity.visibleColumns = sectionViewPageSettings
				? sectionViewPageSettings.columnSettings.map((columnSettings: any) => {
						const column = basicEntity.columns.find(
							(column) => column.columnName.toLowerCase() === columnSettings.columnName.toLowerCase()
						);
						if (column) {
							return {
								...columnSettings,
								...column
							};
						}
						return { ...columnSettings };
				  })
				: [];

			this.entity = newEntity;
			this.isSectionDetail = records.isSection;
		}

		//TODO старый метод, возможно пригодится
		// this.titles = entityInfo.entityInfoResultItems.filter((e: any) => property.some(p => p === e.columnName)).map((e: any) => e.columnTitle);
		// this.propertyInfoMap = new Map<string, any>();

		// for (let e of entityInfo.entityInfoResultItems) {

		//     this.propertyInfoMap.set(e.columnName, e);
		// }
		// this.entities = this.processing(entities, property, this.propertyInfoMap);
		// this.nextEntities = this.processing(nextEntities, property, this.propertyInfoMap);

		// this.offset += this.LIMIT;
	}

	async loadMore(property: string[]) {
		const loadInfo = await this.loadData();

		if (loadInfo.error) return;

		const [entities, nextEntities, entityInfo] = loadInfo.data;

		for (let e of entityInfo.entityInfoResultItems) {
			this.propertyInfoMap.set(e.columnName, e);
		}

		this.entities = [...this.entities!, ...entities];
		this.nextEntities = this.processing(nextEntities, property, this.propertyInfoMap);

		this.offset += this.LIMIT;
	}

	async addEntity() {
		this.newRow = this.generateEmptyObject();
	}

	async createRow() {
		const values = this.generatePropertiesDto(this.newRow);

		values.push({ propertyName: "ContactId", propertyValue: this.filterValue });

		//TODO доделать потом
		// await api.post('/api/entity/create', { entityName: this.entityName, values: values });

		if (!this.entities) this.entities = [];
		if (this.entities.length < this.LIMIT * this.offset) this.entities.push(this.newRow);
	}

	async updateRow(entity: IEntity[]) {
		const values = this.generatePropertiesDto(entity);
		//TODO доделать потом
		// await api.post('/api/entity/update', { entityName: this.entityName, values: values });
	}

	async deleteRow(entity: IEntity[]) {
		if (this.entities) {
			//TODO доделать потом
			// await api.post('/api/entity/delete', { entityName: this.entityName, entityId: entity[0].value });

			const index = this.entities.indexOf(entity);
			this.entities.splice(index, 1);
		}
	}

	deleteRows(ids: string[]) {
		for (let id of ids) {
			if (this.entities) {
				const index = this.entities.findIndex((entity) => entity[0].value === id);
				this.entities.splice(index, 1);
				//TODO доделать потом
				// api.post('/api/entity/delete', { entityName: this.entityName, entityId: id });
			}
		}
	}

	private generatePropertiesDto(entity: IEntity[]) {
		return entity.map((column) => {
			if (column.type === ColumnTypeName.Lookup) {
				return { propertyName: column.title + "Id", propertyValue: (column.value as Item).id };
			}

			return { propertyName: column.title, propertyValue: column.value };
		});
	}

	private generateEmptyObject(): IEntity[] {
		const entity: IEntity[] = [];
		const entitiesInfo = Array.from(this.propertyInfoMap.values());

		entity.push({ value: v4(), title: "Id", type: ColumnTypeName.Guid, table: this.entityName });
		for (let i = 0; this.titles!.length > i; i++) {
			const type = entitiesInfo[i].columnType;
			const isBoolean = type == ColumnTypeName.Logical;
			const value = isBoolean ? false : "";

			entity.push({
				value: value,
				type: type,
				title: entitiesInfo[i].columnName,
				table: entitiesInfo[i].lookupTable ?? this.entityName
			});
		}

		return entity;
	}
}

interface DataLoadingInfo {
	data: any;
	error: boolean;
}

export default DetailEntitiesStore;
