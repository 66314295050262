import classNames from "classnames";

import { Locker } from "shared";

import styles from "./toggle.module.scss";

interface ToggleProps {
	checked: boolean;
	onCheck: (value: boolean) => void;
	isDisabled?: boolean;
}

function Toggle(props: ToggleProps) {
	const classNameSwith = classNames(styles.swith, {
		[`${styles.checkedSwith} `]: props.checked
	});
	const classNameSlider = classNames(styles.slider, {
		[`${styles.checked} `]: props.checked
	});
	const classNameDisabledIcon = classNames(styles.disabledIcon, {
		[`${styles.checkedDisabledIcon} `]: props.checked
	});
	function onChange() {
		if (!props.isDisabled) props.onCheck(!props.checked);
	}

	return (
		<div className={classNameSwith} onClick={onChange}>
			{props.isDisabled && <Locker className={classNameDisabledIcon} />}
			<div className={classNameSlider} />
		</div>
	);
}

export default Toggle;
