import { log } from "console";
import NodeElement from "../data/Node";

function XmlToJson(xmlStr: string) {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlStr, "text/xml");
    
    const result = parseNode(xmlDoc.children[0] as HTMLElement);
    return result;
}

function parseNode(node:HTMLElement) : NodeElement{
    const result = new NodeElement();
    result.type = node.tagName;

    const attributes = node.attributes;
    for(let i = 0; attributes.length > i; i++){
        result.attribute[attributes[i].name] = attributes[i].value;
    }

    const children = node.children;
    for(let i = 0; children.length > i; i++){
        result.children.push(parseNode(children[i] as HTMLElement))
    }

    result.value = children.length === 0 ? node.innerHTML : undefined;
    return result;
}

export default XmlToJson;