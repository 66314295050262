import { makeAutoObservable } from "mobx";
import * as signalR from "@microsoft/signalr";

import { api, UpFirst, getSignalRUrl } from "shared";
import { dispatcher } from "store";

import { IFormat } from "entities/IItem";
import FilterBuilder from "app/services/filter/FilterBuilder";
import { ColumnTypeName } from "pages/settings/data/Fields";
import IFilter from "entities/filter/IFilter";

export enum ResultStates {
	Progress = "Progress",
	Success = "Success",
	NotStarted = "NotStarted"
}

export interface IExportResult {
	state: ResultStates;
	percent: number;
}

class ExportStore {
	pageTitle: string = "";
	schema: string = "";
	formats: IFormat[] = [];
	columns: any[] = [];
	checkedColumns: any[] = [];
	checkedAll: boolean = false;
	exportResult: IExportResult | null = { state: ResultStates.NotStarted, percent: 0 };
	connection: signalR.HubConnection | null = null;

	constructor() {
		makeAutoObservable(this);
		this.checkedColumns = [];
		this.initSignalR();
	}

	setValue(value: any, fieldName: string) {
		Reflect.set(this, fieldName, value);
	}

	clearCheckedColumns() {
		this.checkedColumns = [];
	}
	setCheckedAll() {
		this.checkedColumns = this.columns;
	}
	getActiveFormat() {
		return this.formats.filter((e) => e.checked === true)[0];
	}
	getNameColumns() {
		let newColumns: string[] = [];
		this.checkedColumns.forEach((checkedColumn) => {
			if (checkedColumn.type) {
				if (
					checkedColumn.type === 6 ||
					checkedColumn.type === 7 ||
					checkedColumn.type === 9 ||
					checkedColumn.type === 11 ||
					checkedColumn.type === 12
				)
					newColumns.push(UpFirst(checkedColumn.name + "Id"));
				else newColumns.push(UpFirst(checkedColumn.name));
			} else if (checkedColumn.columnType) {
				//для справочников
				if (checkedColumn.columnType === ColumnTypeName.Lookup) newColumns.push(UpFirst(checkedColumn.columnName + "Id"));
				else newColumns.push(UpFirst(checkedColumn.columnName));
			}
		});

		return newColumns;
	}

	async initSignalR() {
		let url = getSignalRUrl();
		this.connection = new signalR.HubConnectionBuilder()
			.withUrl(`${url}/exporthub`)
			//.configureLogging(signalR.LogLevel.Trace) don't remove
			.build();

		await this.connection.start();
		this.connection.on("ExportProgress", (progress, state) => {
			exportStore.exportResult = { state: state, percent: progress };
		});
	}

	async stopSignalR() {
		this.connection?.stop();
	}

	/**
	 * Старт экспорта
	 * @description При старте экспорта мы достаем отмеченные записи в гриде и фильтр.
	 */
	async startExport() {
		try {
			let filters = new FilterBuilder(this.schema);

			if (dispatcher.entity.get()?.entity.filter?.savedFilter?.filterInfo as IFilter) {
				filters.filter = dispatcher.entity.get()?.entity.filter?.savedFilter?.filterInfo as IFilter;
			}

			filters.filter.includedIds = dispatcher.entity.get()?.entity.includedIds.map((element) => {
				return element.id;
			});
			filters.filter.excludedIds = dispatcher.entity.get()?.entity.excludedIds.map((element) => {
				return element.id;
			});

			let data = {
				entityName: this.schema,
				fileType: this.getActiveFormat().name,
				column: this.getNameColumns(),
				filters: filters.filter
			};
			const fileName = "ExportFile" + this.getActiveFormat().name;
			let response = await api.http.exportEntity.exportEntity().downloadFile(data, fileName);
		} catch (e) {
			console.log(e);
		} finally {
			this.checkedColumns = [];
			this.checkedAll = false;
		}
	}

	async initColumnsByEntity() {
		try {
			let response = await api.http.entity.entityInfo().post({ entityName: `${this.schema}` });
			let columns = response.data.data.entityInfoResultItems;
			this.columns = columns;
		} catch (e) {
			console.log(e);
		}
	}
}

const exportStore = new ExportStore();

export default exportStore;
