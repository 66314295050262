import {observer} from "mobx-react-lite";

import {dispatcher} from "store";

import {SinglePageHead} from "../components/single-page-head/single-page-head";

import {ViewMode} from "../type";

import style from "./control-panel.module.scss";

export const ControlPanel = observer((
    props:
        {
            viewMode: ViewMode;
            setViewMode: (value: ViewMode) => void;
        }
) => {
    return (
        <div className={style.sectionHeadContent}>
            <SinglePageHead
                hasKanban={Boolean(dispatcher.sectionWizzard.getSectionWizzard()?.kanbanIsEnabled)}
                size="small"
                onSwitchViewMode={props.setViewMode}
                viewModeValue={props.viewMode}
            />
        </div>
    )
})