import { observer } from "mobx-react-lite";
import styles from "./settings.module.scss";
import { ArrowToUpThin } from "shared";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getTitleOfMenu, getTitleOfPage, MenuItems, SettingsPath, systemDesigner, SystemEnums, UserEnums } from "./data/Fields";

const NavigationTitle = observer(function (props: { nameLookup?: string }) {
	const location = useLocation();
	const navigate = useNavigate();
	let loc = location.pathname.split("/");
	loc.shift();
	let [subStrings, setSubStrings] = useState<string[]>(loc);

	useEffect(() => {
		let loc = location.pathname.split("/");
		loc.shift();
		setSubStrings(loc);
	}, [location.pathname]);

	function navigateTo(value: string) {
		let settingsPath = SettingsPath;
		switch (value) {
			case settingsPath.slice(1): {
				navigate(SettingsPath);
				break;
			}
			case MenuItems.UserSettings: {
				navigate(SettingsPath + "/" + value);
				break;
			}
			case MenuItems.SystemSettings: {
				navigate(SettingsPath + "/" + value);
				break;
			}
			case MenuItems.AutomationSettings: {
				navigate(SettingsPath + "/" + value);
				break;
			}
			case MenuItems.AccountSettings: {
				navigate(SettingsPath + "/" + value);
				break;
			}
			case SystemEnums.Lookups: {
				navigate(SettingsPath + "/" + MenuItems.SystemSettings + "/" + value);
				break;
			}
			case UserEnums.AccessRights: {
				navigate(SettingsPath + "/" + MenuItems.UserSettings + "/" + value);
				break;
			}
			case UserEnums.AccesRightsLink: {
				navigate(SettingsPath + "/" + MenuItems.UserSettings + "/" + UserEnums.AccessRights + value);
				break;
			}
			default: {
				break;
			}
		}
	}

	return (
		<div className={styles.firstTitle}>
			<TitleComponent subStrings={subStrings} navigateTo={navigateTo} nameLookup={props.nameLookup!} />
		</div>
	);
});
const TitleComponent = observer(function (props: { subStrings: string[]; navigateTo: (value: string) => void; nameLookup: string }) {
	return (
		<>
			{props.subStrings.map((e, index) => {
				return (
					<>
						{index > 0 && (
							<div className={styles.arrowHead}>
								<ArrowToUpThin />
							</div>
						)}

						<div
							className={index !== props.subStrings.length - 1 || index === 0 ? styles.lightGrayTitle : styles.darkGrayTitle}
							onClick={() => {
								if (index !== props.subStrings.length - 1) {
									props.navigateTo(e);
								}
							}}
						>
							{index === 0 && systemDesigner}
							{index === 1 && getTitleOfMenu(e)}
							{index === 2 && getTitleOfPage(props.subStrings[1], e)}
							{index === 3 && props.nameLookup}
						</div>
					</>
				);
			})}
		</>
	);
});

export default NavigationTitle;
