import { observer } from "mobx-react";
import { useCallback, useRef } from "react";
import classNames from "classnames";

import { store } from "store";
import ImportStore from "entities/import/ImportStore";
import { uploadFileHelper } from "../core/upload-file-helper";

import { Button, Icon } from "sale-bridge-ui-kit";

import styles from "./upload.module.scss";

const textDisableUpload = "Файлы можно будет добавить после сохранения записи";
const textEnableUploadAdd = "Прикрепите файл или перетяните его сюда";
const textEnableUploadSize = "Максимальный размер: 10Мб";
const noAvailableService = "Файлы временно недоступны";
const errorAdvice = "Обратитесь к администратору";

export const Upload = observer((props: { importStore: ImportStore; isNewRow: boolean; errorCode?: string }) => {
	const inputRef = useRef<HTMLInputElement>(null);
	const onClickHandler = useCallback(() => {
		if (inputRef.current) {
			inputRef.current.click();
		}
	}, [inputRef.current]);

	const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		uploadFileHelper(store.currentRowId, e.target.files, props.importStore);
	};

	const inputClassName = classNames(styles.input, {
		[styles.isDisableInput]: props.isNewRow
	});

	return (
		<div className={styles.uploadWrapper}>
			{props.errorCode ? (
				<div className={styles.upload}>
					<Icon name="DataStorage" size="64" />
					<div className={styles.text}>
						<span className={styles.header}>{noAvailableService}</span>
						<span className={styles.main}>{errorAdvice}</span>
					</div>
				</div>
			) : (
				<div className={styles.upload}>
					<Icon name="FilesEmptyState" size="64" />
					<div className={styles.text}>
						<span className={styles.header}>Здесь пусто</span>
						{props.isNewRow ? (
							<span className={styles.main}>{textDisableUpload}</span>
						) : (
							<>
								<span className={styles.main}>{textEnableUploadAdd}</span>
								<span className={styles.main}>{textEnableUploadSize}</span>
							</>
						)}
					</div>
					<Button
						text="Прикрепить файл"
						size="medium"
						variant={props.isNewRow ? "disabled" : "secondary"}
						border={false}
						link={false}
						loading={false}
						leftIcon="Attach"
						iconSize="16"
						onClick={props.isNewRow ? () => {} : onClickHandler}
					/>
					<input type="file" ref={inputRef} title="" className={inputClassName} onChange={onChangeHandler} />
				</div>
			)}
		</div>
	);
});
