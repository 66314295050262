import { useEffect, useMemo, useRef } from 'react'
import { action } from "mobx";
import { observer } from 'mobx-react-lite'

import { HorizontalColumnElement } from '../inner-element'
import { autoSyncZoneWithStore } from '../model/dispatcher'

import { store } from '../model/store'

import { type Types } from '../type'
import { type OnDrop } from '../HOC/HOC'

interface Props {
    children: JSX.Element[]
    id: string
    movementElement: JSX.Element
    placeholder: JSX.Element
    predict: JSX.Element
    type: Types
    onDrop: OnDrop
    onUp?: (value: string) => void
}

export const ZoneObserver = observer((props: Props) => {
    const container = useRef<HTMLDivElement>(null)

    useEffect(() => {
        action("set zone size", () => {
            store.zones[props.id] = {
                size: {
                    /*
                    * @description создание, без подстановки данных
                     */
                    startY: 0,
                    startX: 0,
                    endY: 0,
                    endX: 0
                },
                config: {
                    type: props.type,
                },
                elements: {}
            }
        })()

        return () => {
            action("delete zone", () => {
                delete store.zones[props.id]
            })()
        }
    }, [props.id, props.type])

    useEffect(() => {
        autoSyncZoneWithStore(props.id, props.type, props.children)
    }, [props.children, props.id, props.type])

    useEffect(() => {
        if (container.current) {
            const size = container.current.getBoundingClientRect()
            store.setZoneSize({
                zoneId: props.id,
                height: size.height,
                width: size.width,
                startX: size.x,
                startY: size.y
            })
        }
    }, [props.id, container.current?.getBoundingClientRect()])

    const wrappedElements = useMemo(() =>
        props.children
            .concat()
            .sort((a, b) => {
                if (a.props.x > b.props.x) {
                    return 1
                } else if (a.props.x < b.props.x) {
                    return -1
                } else {
                    return 0
                }
            })
            .map((element, index) => <HorizontalColumnElement
                zoneId={props.id}
                id={element.props.id}
                key={index}
                predict={props.predict}
                placeholder={props.placeholder}
                movementElement={props.movementElement}
                onDrop={props.onDrop}
                onUp={props.onUp}
            >
                {element}
            </HorizontalColumnElement>), [props.children, props.id, props.movementElement, props.placeholder, props.predict, props.onDrop])
    return (
        <div ref={container} style={{ display: 'flex', height: `100%` }}>
            {wrappedElements || <></>}
        </div>
    )
})
