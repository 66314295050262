import { useCallback, useMemo, useState } from "react";
import { v4 } from "uuid";
import { toJS } from "mobx";

import { dispatcher } from "store";
import { Zone } from "modules/DND/horizontal-column";
import { Types } from "modules/DND/horizontal-column/type";

import { ButtonStyle } from "components";
import { Element } from "modules/DND/element";
import { Tab } from "./components/tab";

import { ITab } from "pages/section-wizzard/data/data";
import { Item } from "types";
import { TabId, TabsConfig } from "types/entity";

import { Replacer } from "shared";

import styles from "./drag-tabs.module.css";

interface IDragTabs {
	items: ITab[];
	moreItems: Item[];
	styleButton?: ButtonStyle;
	getItemClass: (i: number) => string;
	onClick: (index: number) => void;
	onClickToMoreInRound: (value: Item | null) => void;
	setCurrentEditableTab: (value: ITab, index: number, e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export function DragTabs(props: IDragTabs) {
	const [zoneId] = useState(v4());
	const [dragTab, setDragTab] = useState<string>("");

	const sectionWizzard = useMemo(() => {
		return dispatcher.sectionWizzard.getSectionWizzard();
	}, [dispatcher.sectionWizzard.getSectionWizzard]);

	const tabsConfig = useMemo(() => {
		return sectionWizzard?.reactorConfig.tabs.tabsConfig ?? [];
	}, [sectionWizzard?.reactorConfig.tabs.tabsConfig.map((tab) => tab)]);

	const getTabTitleById = useMemo(() => {
		if (dispatcher.sectionWizzard.isBoxTab(dragTab)) {
			const additionalTabs = dispatcher.sectionWizzard.getAdditionalFromConfig();
			if (additionalTabs) {
				const additionalTabsAsArray = Object.entries(additionalTabs);
				const findedBoxTab = additionalTabsAsArray.find(([key, value]) => key === dragTab);
				if (findedBoxTab) {
					const tab = additionalTabs[findedBoxTab[0] as TabId];
					return tab.title;
				}
			}
		} else {
			const tab = sectionWizzard?.reactorConfig.tabs.tabsConfig.find((tab) => tab.tabName === dragTab);
			return tab?.tabTitle;
		}
		return "";
	}, [sectionWizzard?.reactorConfig.tabs.tabsConfig.map((tab) => tab), dragTab]);

	/**
	 * @description проставляет индексы табов
	 * @param newItems - массив табов
	 **/
	const setIndexesInTabs = useCallback(
		(tabsArray: ITab[]) => {
			tabsArray.forEach((tab, index) => {
				if (dispatcher.sectionWizzard.isBoxTab(tab.tabName)) {
					const additional = dispatcher.sectionWizzard.getAdditionalFromConfig();
					if (additional) {
						dispatcher.sectionWizzard.setAdditionalTabsOrder(tab.tabName as TabId, index);
					}
				} else {
					const finded = tabsConfig.find((tabConfig) => tabConfig.tabName === tab.tabName);
					if (finded) {
						const newTab: TabsConfig = {
							...finded,
							tabIndex: index
						};
						dispatcher.sectionWizzard.setTabByName(newTab);
					}
				}
			});
		},
		[dispatcher.sectionWizzard.getAdditionalFromConfig(), tabsConfig, sectionWizzard]
	);

	/**
	 * @description меняет табы местами
	 * @param currentTabIndex - index перемещаемого таба
	 * @param targetTabIndex - index, на который необходимо вставить перемещаемый таб
	 **/
	const replaceTabs = useCallback(
		(currentTabIndex: number, targetTabIndex: number) => {
			const newItems = props.items;
			const removedTab = newItems.splice(currentTabIndex, 1)[0];
			if (targetTabIndex === 0) {
				newItems.unshift(removedTab);
				setIndexesInTabs(newItems);
			} else {
				newItems.splice(targetTabIndex - 1, 0, removedTab);
				setIndexesInTabs(newItems);
			}
		},
		[toJS(props.items)]
	);

	const handleDrop = useCallback(
		(value: { zoneId: string; currentElementIndex: number; targetElementIndex: number }) => {
			replaceTabs(value.currentElementIndex, value.targetElementIndex);
		},
		[replaceTabs]
	);

	const handleUp = useCallback((tabId: string) => {
		setDragTab(tabId);
	}, []);

	const handleClick = useCallback(
		(index: number) => {
			props.onClick(index);
		},
		[props.onClick]
	);

	const placeholderTab = useMemo(() => {
		return (
			<div className={styles.placeholderWrap}>
				<span className={styles.placeholderTitle}>{getTabTitleById}</span>
			</div>
		);
	}, [dragTab, getTabTitleById]);

	const movementElement = useMemo(() => {
		return (
			<div className={styles.dragElementWrap}>
				<span className={styles.dragElementTitle}>{getTabTitleById}</span>
			</div>
		);
	}, [dragTab, getTabTitleById]);

	const currentTabIndex = useMemo(() => {
		return sectionWizzard?.reactorConfig.tabs.currentTab ?? 0;
	}, [sectionWizzard?.reactorConfig.tabs.currentTab]);

	return (
		<Zone
			onDrop={handleDrop}
			onUp={handleUp}
			id={zoneId}
			type={Types.HORIZONTAL_COLUMN}
			predict={<Replacer />}
			placeholder={placeholderTab}
			movementElement={movementElement}
		>
			{props.items.map((tab: ITab, index: number) => {
				return (
					<Element
						key={tab.tabName}
						id={tab.tabName}
						x={index}
						sourceData={{
							title: tab.caption,
							id: tab.tabName
						}}
					>
						<Tab
							tab={tab}
							index={index}
							moreItems={props.moreItems}
							getItemClass={props.getItemClass}
							onClick={handleClick}
							onClickToMoreInRound={props.onClickToMoreInRound}
							handleClickToSelect={props.setCurrentEditableTab}
							isSelected={index === currentTabIndex}
						/>
					</Element>
				);
			})}
		</Zone>
	);
}
