import { CSSProperties } from "react";

export const Reactions = new Map<string, {
    ADD: CSSProperties,
    REMOVE: CSSProperties,
    PENDING_SEND: CSSProperties,
    NONE: CSSProperties,
}>();

Reactions.set("message", {
	ADD: { background: "var(--indigo-25, #F5F8FF)", transitionDuration: "1s" },
	REMOVE: { backgroundColor: "var(--color-white)" },
	PENDING_SEND: { color: "rgba(224,224,224,0.21) !important" },
	NONE: {}
});

export enum SubscribeType {
    CUSTOM = "CUSTOM",
    PENDING_SEND = "PENDING_SEND",
    REMOVED = "REMOVED",
    ADD = "ADD",
    EDITION = "EDITION",
    NONE = "NONE",
}