import { api } from "shared";

type Request = Promise<{
	data: {
		data: {
			canbanColumn: null;
			isLookup: boolean;
			isSection: boolean;
			records: Array<{
				displayValue: string;
				id: string;
				name: string;
			}>;
			error: string | null;
			success: boolean;
		};
	};
}>;

export function getListWithColumns(entityName: string, limit?: number): Request {
	return api.http.entity.recordsListWithColumns().post({
		canbanColumn: null,
		columnNames: [],
		filter: null,
		offset: 0,
		limit: limit,
		staticGroupId: null,
		entityName: entityName,
		sort: null
	}) as Request;
}

type GetWithColumnsResponse<T extends string> = Promise<{
	data: {
		success: boolean;
		data: {
			id: string;
			[key: string]: string;
		} & Record<T, string>;
		error: string | null;
	};
}>;

export function getRecordWithColumns<T extends string>(entityName: string, entityId: string): GetWithColumnsResponse<T> {
	return api.http.entity.getRecordWithColumns().post({
		entityName: entityName,
		entityId: entityId,
		columnNames: []
	}) as GetWithColumnsResponse<T>;
}
