import { useState } from "react";

import importStore from "pages/import/core/import-store";

import CheckBox from "components/check-box/check-box";
import { ICustomColumn } from "components/grid/custom-grid/custom-grid";
import { MaxColumnsCount } from "pages/import/data/import-constants";

import styles from "./deduplication-rule-column.module.css";

function Column(props: { column: ICustomColumn, value: any }) {
    const [checked, setCheck] = useState<boolean>(false);
    const onChange = props.column.onChange!;
    return (
        <div className={styles.checkBoxContainer}>
            <CheckBox checked={checked} onChangeChecked={(value) => {
                if ((importStore.result.upsertSettings.keyFields.size < MaxColumnsCount) ||
                 (value === false && importStore.result.upsertSettings.keyFields.size === MaxColumnsCount)) {
                    onChange({ value: value, column: props.value });
                    setCheck(value);
                }
            }} />
        </div>
    )
}

export default Column;