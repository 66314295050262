import { toJS } from "mobx";
import { observer } from "mobx-react";
import { useCallback, useMemo } from "react";


import { FieldSelect } from "pages/section-wizzard/components";
import { Checkbox, Hint } from "sale-bridge-ui-kit";
import { dispatcher, selector } from "store";

import { ButtonStyle } from "components";

import { QuickActionsTitle } from "pages/section-wizzard/data/data";

import { QuickActionType, QuickActionTypeName } from "types/entity";

import styles from "../../card-designer-settings-panel.module.scss";

export const EnabledQuickActions = observer(() => {

    const selectedQuickActions = useMemo(() => selector.kanbanCardDesign.getCardDesign()?.selectedQuickActions?.filter(selectedQuickAction => !selectedQuickAction.isHidden)
        , [toJS(selector.kanbanCardDesign.getCardDesign()?.selectedQuickActions)])

    const firstDisabled = useMemo(() => selectedQuickActions?.filter(selectedQuickAction => selectedQuickAction.isEnabled).length == 1, [toJS(selectedQuickActions)])
    const maxCountDisabled = useMemo(() => selectedQuickActions?.filter(selectedQuickAction => selectedQuickAction.isEnabled).length == 4, [toJS(selectedQuickActions)])

    const handleQuickActionEnable = useCallback((value: boolean, action: QuickActionType) => {
        dispatcher.kanbanCardDesign.setQuickActionsEnable(value, action);
    }, [toJS(selectedQuickActions)])

    const checkBoxes = useMemo(() => (
        selectedQuickActions?.map(quickAction =>
            (quickAction.isEnabled && firstDisabled) ?
                <div className={styles.checkboxGroup}>
                    <Hint hintBody="Для неотображения быстрых действий кликните по настройке «Быстрые действия» выше" startPosition="top" >
                        <Checkbox
                            size="small"
                            value={quickAction.isEnabled}
                            onChangeChecked={(value: boolean) => handleQuickActionEnable(value, quickAction.action)}
                            title={QuickActionTypeName[quickAction.action].title}
                            description={QuickActionTypeName[quickAction.action].description}
                            disabled
                        />
                    </Hint>
                </div>
                :
                (!quickAction.isEnabled && maxCountDisabled) ?
                    <div className={styles.checkboxGroup}>
                        <Hint hintBody="Максимум 4 быстрых действий" startPosition="top" >
                            <Checkbox
                                size="small"
                                value={quickAction.isEnabled}
                                onChangeChecked={(value: boolean) => handleQuickActionEnable(value, quickAction.action)}
                                title={QuickActionTypeName[quickAction.action].title}
                                description={QuickActionTypeName[quickAction.action].description}
                                disabled
                            />
                        </Hint>
                    </div>
                    :
                    <div className={styles.checkboxGroup}>
                        <Checkbox
                            size="small"
                            value={quickAction.isEnabled}
                            onChangeChecked={(value: boolean) => handleQuickActionEnable(value, quickAction.action)}
                            title={QuickActionTypeName[quickAction.action].title}
                            description={QuickActionTypeName[quickAction.action].description}
                        />
                    </div>
        )
    ), [toJS(selectedQuickActions)])

    return (
        <FieldSelect
            name={QuickActionsTitle}
            buttonStyle={ButtonStyle.Settings}
            className={styles.fieldSelectContainer}
        >
            <div className={styles.fieldSelectContainerBody}>
                {checkBoxes}
            </div>
        </FieldSelect>
    );
});