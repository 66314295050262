import { Api } from "shared/api/class-api";

class SectionViewPageSettings extends Api {
	basicPath: string;
	constructor() {
		super();
		this.basicPath = "/api/SectionViewPageSettings";
	}

	sectionViewById = (id: string) => {
		this.setPath(`${this.basicPath}/getById/${id}`);
		return this;
	};
	sectionCreate = () => {
		this.setPath(`${this.basicPath}/create`);
		return this;
	};
	sectionUpdate = () => {
		this.setPath(`${this.basicPath}/update`);
		return this;
	};
	sectionDelete = () => {
		this.setPath(`${this.basicPath}/delete`);
		return this;
	};
	getSectionByUserAndEntity = () => {
		this.setPath(`${this.basicPath}/getByUserAndEntity`);
		return this;
	};
}

const sectionViewPageSettings = new SectionViewPageSettings();

export { sectionViewPageSettings };
