import { observer } from "mobx-react";

import { CloseMaxi } from "shared";

import styles from "./modificate-section.module.css";

interface SectionProps {
	closeAction: () => void;
	children?: JSX.Element[] | JSX.Element;
}

export const ModificateSection = observer((props: SectionProps) => {
	return (
		<div className={styles.sectionWrapper}>
			<div className={styles.sectionHeader}>
				<CloseMaxi className={styles.close} onClick={props.closeAction} />
			</div>
			{props.children}
		</div>
	);
});
