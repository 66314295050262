import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { useCallback, useMemo } from "react";
import { Button, ButtonDropdown } from "sale-bridge-ui-kit";
import { StandartItemGroup } from "sale-bridge-ui-kit/dist/components/button-dropdown/button-dropdown";

import RightExpression from "features/advanced-filter/filter-conditions/right-expression/right-expression";
import { BlockGroup } from "../block-group";
import { SelectProperty } from "./components";

import FilterColumnType from "entities/ColumnType";
import { IFilterStore } from "entities/filter/FilterStore";
import { ComparisonType, FilterType } from "entities/filter/IFilter";
import { BusinessRuleEditorValidation } from "pages/section-wizzard/pages/business-rules/business-rule-editor-store";
import { Item } from "types";

import styles from "./block-condition.module.scss";

export const BlockCondition = observer(
	(props: {
		filter: IFilterStore;
		logicalOperation: string | null;
		initDropdownItems: (filter: IFilterStore) => void;
		dropdownItems: StandartItemGroup[];
		handleChangeDropDown: (id: string, filter: IFilterStore) => void;
		setConditionField: (filter: IFilterStore, value: Item | null) => void;
		setConditionComparator: (filter: IFilterStore, value: Item | null) => void;
		setRightExpression?: (filter: IFilterStore, value: any) => void;
		validation?: BusinessRuleEditorValidation;
		getValidation?: (id: string) => BusinessRuleEditorValidation | undefined;
	}) => {
		const filterMode = useMemo(() => {
			if (props.filter) {
				return {
					propertyValue: props.filter.property.propertyValue.value?.displayValue ?? "",
					propertyItems: props.filter.property.items,
					comparatorValue: props.filter.comparators.find((item) => item.id === props.filter!.comparisonType)?.name,
					comparatorItems: props.filter.comparators
				};
			}
		}, [toJS(props.filter)]);

		const selectAttributeOrDetail = useCallback(
			(value: Item | null) => {
				props.setConditionField(props.filter, value);
			},
			[toJS(props.filter)]
		);

		const selectComparator = useCallback(
			(value: Item | null) => {
				props.setConditionComparator(props.filter, value);
			},
			[toJS(props.filter)]
		);

		if (props.filter && props.filter.type === FilterType.Group) {
			return (
				<>
					{props.logicalOperation && <span className={styles.conditionGroupLogicalOperation}>{props.logicalOperation}</span>}
					<BlockGroup
						depth={props.filter.depth}
						filter={props.filter}
						initDropdownItems={props.initDropdownItems}
						dropdownItems={props.dropdownItems}
						onClickDropDown={props.handleChangeDropDown}
						getValidation={props.getValidation}
					/>
					<ButtonDropdown
						size="small"
						position="right-down"
						standartItems={props.dropdownItems}
						onClickStandartItem={(id) => {
							props.handleChangeDropDown(id, props.filter!);
						}}
						childrenButton={
							<Button
								text=""
								onClick={() => {
									props.initDropdownItems(props.filter!);
								}}
								size="small"
								variant="backless"
								leftIcon="KebabVertical"
								link={false}
								loading={false}
								border={false}
							/>
						}
					/>
				</>
			);
		}

		return (
			<>
				{props.logicalOperation && <span className={styles.conditionGroupLogicalOperation}>{props.logicalOperation}</span>}
				<div className={styles.conditionBody}>
					<div className={styles.conditionBodyProperty}>
						<SelectProperty
							value={filterMode?.propertyValue ?? ""}
							items={filterMode?.propertyItems ?? []}
							onChange={selectAttributeOrDetail}
							size="small"
							placeholder="Поле"
							validation={props.validation?.field}
						/>
						{props.filter?.attributeType !== FilterColumnType.Boolean && (
							<SelectProperty
								value={filterMode?.comparatorValue ?? ""}
								items={filterMode?.comparatorItems ?? []}
								onChange={selectComparator}
								size="small"
								placeholder="Условие"
								isDisabled={!filterMode?.propertyValue}
								validation={props.validation?.comparison}
							/>
						)}
					</div>
					<div className={styles.conditionBodyValue}>
						{props.filter?.comparisonType !== ComparisonType.IsNotNull &&
							props.filter?.comparisonType !== ComparisonType.IsNull && (
								<RightExpression
									isDisabled={!props.filter.property.propertyValue.value || props.filter.comparisonType === null}
									filter={props.filter}
									size="small"
									validation={props.validation?.value}
									setRightExpression={props.setRightExpression}
								/>
							)}
					</div>
					<ButtonDropdown
						size="small"
						position="right-down"
						standartItems={props.dropdownItems}
						onClickStandartItem={(id) => {
							props.handleChangeDropDown(id, props.filter!);
						}}
						childrenButton={
							<Button
								text=""
								onClick={() => {
									props.initDropdownItems(props.filter!);
								}}
								size="small"
								variant="backless"
								leftIcon="KebabVertical"
								link={false}
								loading={false}
								border={false}
							/>
						}
					/>
				</div>
			</>
		);
	}
);
