import { observer } from "mobx-react-lite";

import { ColumnType } from "entities/ColumnType";

import { Icon } from "sale-bridge-ui-kit";

const TypeIcon = observer((props: { type: string | null | undefined }) => {
    switch (props.type) {
        case ColumnType.Numerator:
            return <Icon name={ColumnType.Numerator} size='small' />
        case ColumnType.String:
            return <Icon name={ColumnType.String} size='small' />

        case "Id":
        case ColumnType.Guid:
            return <Icon name={ColumnType.Guid} size='small' />

        case "Integer":
        case ColumnType.Integer:
            return <Icon name="Integer" size='small' />

        case ColumnType.Double:
        case ColumnType.Decimal:
            return <Icon name={ColumnType.Decimal} size='small' />

        case "Logical":
        case ColumnType.Boolean:
            return <Icon name={ColumnType.Boolean} size='small' />

        case ColumnType.DateTime:
            return <Icon name={ColumnType.DateTime} size='small' />

        case ColumnType.Date:
            return <Icon name={ColumnType.Date} size='small' />

        case ColumnType.Time:
            return <Icon name={ColumnType.Time} size='small' />

        case ColumnType.Lookup:
            return <Icon name={ColumnType.Lookup} size='small' />

        case ColumnType.Detail:
            return <Icon name={ColumnType.Detail} size='small' />

        case ColumnType.FieldGroup:
            return <Icon name={ColumnType.FieldGroup} size='small' />

        default:
            return <></>
    }

});

export default TypeIcon;